import React, { useEffect, useMemo, useState } from "react"
import i18n from "../../../../../i18n"
import { BaseModal } from "../../../../../components"
import { useDispatch } from "react-redux"
import { reuseRequest } from "../../../../../redux/actions/Saloon/CommunicationsMarketingCampaign"
import { ErrorHelper, SuccessHelper } from "../../../../../helpers"
import { Checkbox } from "@material-ui/core"
import BasicTable from "../../../../../components/Core/BasicTable"
import "./CampaignReusePopup.css"
import PrimaryButton from "../../../../../components/Core/PrimaryButton"

const defaultStatusMap = {
  Sent: true,
  Delivered: true,
  Opened: true,
  Booked: true,
  Failed: false,
}
const CampaignReusePopup = ({ open, setOpen, active }) => {
  const dispatch = useDispatch()
  const [statusMap, setStatusMap] = useState(defaultStatusMap)

  const onReuse = (noStatuses) => {
    const statuses = !noStatuses
      ? Object.keys(statusMap).filter((status) => statusMap[status])
      : null
    dispatch(
      reuseRequest(
        { campaignId: active._id, statuses },
        {
          success: () => {
            SuccessHelper.handleSuccess(i18n.t("successfully_added"), true)
            setOpen(false)
          },
          failure: () => {
            ErrorHelper.handleErrors(i18n.t("failed_to_add"), true)
          },
        }
      )
    )
  }

  useEffect(() => {
    if (open) {
      setStatusMap(defaultStatusMap)
      if (active.status != "Complete") {
        onReuse(true)
        setOpen(false)
      }
    }
  }, [open])

  const tableEntries = useMemo(() => {
    return Object.keys(statusMap).map((status) => {
      return {
        status,
        selected: statusMap[status],
      }
    })
  }, [statusMap])

  const allSelected = useMemo(() => {
    const notSelected = tableEntries.find((element) => !element.selected)
    return !notSelected
  }, [tableEntries])

  if (active?.status && active.status != "Complete") {
    return null
  }
  return (
    <BaseModal
      open={open}
      onClose={() => setOpen(false)}
      title={<div>{i18n.t("reuse")}</div>}
      containerClassName={"half-container"}
      content={
        <div className="CampaignReusePopup">
          <BasicTable
            maxContentWidth={false}
            rows={tableEntries}
            onRowClick={(val) => {
              setStatusMap({
                ...statusMap,
                [val.status]: !statusMap[val.status],
              })
            }}
            schema={[
              {
                key: "action",
                style: { width: 40, textAlign: "center" },
                header: () => {
                  return (
                    <Checkbox
                      edge="start"
                      checked={allSelected}
                      tabIndex={-1}
                      onClick={() => {
                        const map = { ...statusMap }
                        const mapFunc = allSelected
                          ? (element) => {
                              map[element.status] = false
                            }
                          : (element) => {
                              map[element.status] = true
                            }
                        tableEntries.map(mapFunc)
                        setStatusMap(map)
                      }}
                    />
                  )
                },
                render: (val) => {
                  return (
                    <Checkbox
                      edge="start"
                      checked={val.selected || false}
                      tabIndex={-1}
                    />
                  )
                },
              },
              {
                header: i18n.t("status"),
                field: "status",
              },
            ]}
          />
        </div>
      }
      actions={
        <div className="row justify-content-end my-3 pr-3">
          <PrimaryButton
            className="w-128"
            label={i18n.t("save")}
            onClick={() => onReuse()}
          />
        </div>
      }
    />
  )
}

export default CampaignReusePopup
