import { connect } from "react-redux";
import React, { Component } from "react";
import { Translation } from "react-i18next";
import i18n from "../../../i18n";
import { Images } from "../../../theme";

import "./styles.css";
import PrimaryButton from "../../Core/PrimaryButton";
import { TextField } from "@material-ui/core";
import Select from "../../Core/Select/index.jsx";
import DollarInput from "../../DollarInput/index.jsx";
import PercentInput from "../../PercentInput/index.jsx";
import ToggleSelect from "../../ToggleSelect";
import SecondaryButton from "../../Core/SecondaryButton/SecondaryButton";
class ProductForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onChange = (event, key) => {
    this.props.setValue({ [key]: event.target.value });
  };
  onChange1 = (event, key) => {
    this.props.setValue({ [key]: event });
  };
  increase = (event, key) => {
    this.props.setValue({ [key]: parseInt(event) + 1 });
  };
  decrease = (event, key) => {
    this.props.setValue({ [key]: parseInt(event) - 1 });
  };
  onChangeSalesCommissions = (value, key) => {
    this.props.setValue({ [key]: value });
  };

  fileSelectedHandler = (event, type) => {
    const file = URL.createObjectURL(event.target.files[0]);
    this.props.setValue({ image: event.target.files[0], imageUrl: file });
  };

  renderUploadPicture = () => {
    const { imageUrl } = this.props.values;
    return (
      <div className="col-12 d-md-flex align-items-md-center text-center justify-content-center mb-5">
        <input
          type="file"
          style={{ display: "none" }}
          onChange={(event) => this.fileSelectedHandler(event)}
          ref={(fileInput) => (this.fileInput = fileInput)}
          accept="image/*"
        />
        <span
          onClick={() => this.fileInput.click()}
          style={{
            backgroundImage: `url(${imageUrl ? imageUrl : Images.saloon_upload_media_icon})`,
            backgroundSize: imageUrl ? "cover" : "24px",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat"
          }}
          id="saloon-edit-profile"
        />
      </div>
    );
  };

  renderCounter = (id, label) => {
    const { values = {}, formErrors = {} } = this.props;
    const _value = isNaN(values[id]) ? 0 : parseInt(values[id]);
    return (
      <div className="my-3">
        <label className="font-weight-bold mobile-w-100" style={{ width: '140px' }}>{label}</label>
        <PrimaryButton
          className="w-34 mr-2"
          onClick={() => { this.decrease(_value, id) }}
        >
          <div style={{ fontSize: 30 }}>-</div>
        </PrimaryButton>
        <TextField
          readOnly
          type={"number"}
          value={_value}
          onChange={(text) => this.onChange(text, id)}
          helperText={formErrors[id]}
          variant="outlined"
          id={id}
          className="textAlign"
          style={{ width: 100 }}
        />
        <PrimaryButton
          className="w-34 ml-2"
          onClick={() => this.increase(_value, id)}
        >
          <div style={{ fontSize: 30 }}>+</div>
        </PrimaryButton>
      </div>
    )
  }

  renderInput = (cols, id, lable, inputType, placeholder) => {
    const { values = {}, formErrors = {} } = this.props;

    return (
      <div className={`${cols} px-0 d-sm-flex align-items-center`}>
        <label className="font-weight-bold mobile-w-100" style={{ width: '140px' }}>{lable}</label>
        <TextField
          type={inputType}
          value={values[id]}
          onChange={(text) => this.onChange(text, id)}
          error={formErrors[id] ? true : false}
          helperText={formErrors[id]}
          id={id}
          variant="outlined"
          className="w-50"
        />
      </div>
    );
  };
  renderSalesPrice = (cols, id, lable, inputType) => {
    const { values = {}, formErrors = {} } = this.props;

    return (
      <div className={`${cols} px-0 my-2 px-md-3`}>
        <DollarInput
          type={inputType}
          label={lable}
          value={values[id]}
          onChange={(text) => this.onChangeSalesCommissions(text, id)}
          error={formErrors[id] ? true : false}
          helperText={formErrors[id]}
          id={id}
        />
      </div>
    );
  };
  renderCommissions = (cols, id, lable, inputType) => {
    const { values = {}, formErrors = {} } = this.props;

    return (
      <div className={`${cols} px-0 my-2 px-md-3`}>
        <PercentInput
          type={inputType}
          label={lable}
          value={values[id]}
          onChange={(text) => this.onChangeSalesCommissions(text, id)}
          error={formErrors[id] ? true : false}
          helperText={formErrors[id]}
          id={id}
        />
      </div>
    );
  };

  renderYesNoSelect = (cols, id, lable, placeholder) => {
    const { values = {}, formErrors = {} } = this.props;
    return (
      <div className="row align-items-center form-group px-0">
        <label className="font-weight-bold mobile-w-100" style={{ width: '140px' }}>{lable}</label>
        <ToggleSelect
          value={values['taxable']}
          onChange={(text) => this.props.setValue({ "taxable": text })}
          active="yes"
          inactive="no"
        />
      </div>
    );
  };

  render() {
    const { isEdit } = this.props;
    const successCallback = () => {
      this.props.setValue({
        name: "",
        description: "",
        cost: 0,
        charge: 0,
        quantity: 0,
        reorderTrigger: 0,
        taxable: false,
        allow_backorder: false,
        image: "",
        imageUrl: "",
      });
    };
    const { values = {}, formErrors = {} } = this.props;
    return (
      <>
        <div
          className="col-12 px-0 pb-4 bg-white"
          style={{ borderRadius: "1rem" }}
        >
          <div className="form-row mx-0 mb-3">
            {this.renderUploadPicture()}
            <div className="col-md-6">
              {this.renderInput(
                "col-12 mb-3",
                "name",
                <Translation>{(t) => t("name")}</Translation>,
                "text",
                i18n.t("name")
              )}
              {!isEdit &&
                this.renderCounter('cost', i18n.t("cost"))
              }
              {!isEdit &&
                this.renderCounter('quantity', i18n.t("quantity"))
              }
              <div className="row align-items-center form-group px-0">
                <label className="font-weight-bold mobile-w-100" style={{ width: '140px' }}>{i18n.t("taxable")}</label>
                <ToggleSelect
                  value={values['taxable'] || values['taxable'] === "true" ? true : false}
                  onChange={(text) => this.props.setValue({ "taxable": text || text === "true" ? true : false })}
                  active="yes"
                  inactive="no"
                />
              </div>
              {this.renderCounter('commission', i18n.t("commission"))}
              {this.renderInput(
                "col-12",
                "category",
                <Translation>{(t) => t("category")}</Translation>,
                "text",
                i18n.t("category")
              )}
            </div>

            <div className="col-md-6">
              {this.renderInput(
                "col-12",
                "description",
                <Translation>{(t) => t("description")}</Translation>,
                "text",
                i18n.t("description")
              )}
              {this.renderCounter('charge', i18n.t("sale_price"))}
              {this.renderCounter('reorderTrigger', i18n.t("reorder_trigger"))}
              <div className="row align-items-center form-group px-0">
                <label className="font-weight-bold mobile-w-100" style={{ width: '140px' }}>{i18n.t("allow_backorder")}</label>
                <ToggleSelect
                  value={values['allow_backorder'] || values['allow_backorder'] === "true" ? true : false}
                  onChange={(text) => this.props.setValue({ "allow_backorder": text })}
                  active="yes"
                  inactive="no"
                />
              </div>
              {this.renderInput(
                "col-12",
                "barCode",
                <Translation>{(t) => t("bar_code")}</Translation>,
                "text",
                i18n.t("bar_code")
              )}
            </div>
          </div>
          <div className="col-12 px-0 text-right">
            <div className="form-button-query">
              <PrimaryButton
                onClick={() => this.props.checkValidation(successCallback)}
                type="button"
                className="w-128"
              >
                <Translation>
                  {(t) => t("save")}
                </Translation>
              </PrimaryButton>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(ProductForm);
