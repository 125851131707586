import crudBuilder from "../../../builders/CrudBuilder/CrudBuilder"
import actions from "../../../redux/actions/Saloon/IntakeType"
const { upsertRequest, getRequest, removeRequest } = actions

const IntakeType = crudBuilder.build({
  type: "intakeType",
  title: "intake_types",
  actionMap: {
    upsert: upsertRequest,
    get: getRequest,
    remove: removeRequest,
  },
})

export default IntakeType
