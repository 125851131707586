import React, { useEffect, useRef, useState } from "react"
import BasicTable from "../../../components/Core/BasicTable"
import { customerStats } from "../../../config/simpleApiCalls"
import ageGroupActions from "../../../redux/actions/Saloon/CustomerAgeGroup"
import originActions from "../../../redux/actions/Saloon/CustomerOrigin"
import districtActions from "../../../redux/actions/Saloon/CustomerDistrict"
import statusActions from "../../../redux/actions/Saloon/CustomerStatus"
import { useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { DotsLoader } from "../../../components"
import i18n from "../../../i18n"
import PrimaryButton from "../../../components/Core/PrimaryButton"
import { Menu, MenuItem } from "@material-ui/core"
import { Pie } from "react-chartjs-2"
import CustomerStatsFilterPopup from "./components/CustomerStatsFilterPopup"
import { dynamicColors } from "../../../util/colorMethods"
import { exportTableToPDF, exportImgToPDF } from "../../../util/exportToPDF"
import ToggleSelect from "../../../components/ToggleSelect"

const arrayToMap = (array) => {
  const map = {}
  array.map(({ _id, name }) => {
    map[_id] = name
  })
  return map
}

function CustomerStatsTables({ stats, showTable, onExport }) {
  const [statusGrouping, ageGroupGrouping, originGrouping, districtGrouping] =
    stats
  if (showTable !== true) {
    return null
  }
  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-column">
        <div className="row justify-content-between align-items-center my-3">
          <h5 className="mb-0 font-weight-bolder">{i18n.t("statuses")}</h5>
          <div className="row gap-2">
            <PrimaryButton
              className="w-128"
              style={{ maxHeight: 28 }}
              label={i18n.t("export_to_pdf")}
              onClick={() =>
                exportTableToPDF(["#status_table"], i18n.t("statuses"))
              }
            />
            <PrimaryButton
              className="w-128"
              style={{ maxHeight: 28 }}
              label={i18n.t("export_to_excel")}
              onClick={() => onExport("status")}
            />
          </div>
        </div>
        <BasicTable
          id="status_table"
          maxContentWidth={false}
          rows={[{}]}
          schema={statusGrouping.map((element) => ({
            header: element.name,
            field: element._id,
            render: () => {
              return <div>{element.count}</div>
            },
          }))}
        />
      </div>
      <div className="d-flex flex-column">
        <div className="row justify-content-between align-items-center mb-3">
          <h5 className="mb-0 font-weight-bolder">{i18n.t("age_groups")}</h5>
          <div className="row gap-2">
            <PrimaryButton
              className="w-128"
              style={{ maxHeight: 28 }}
              label={i18n.t("export_to_pdf")}
              onClick={() =>
                exportTableToPDF(["#age_table"], i18n.t("age_groups"))
              }
            />
            <PrimaryButton
              className="w-128"
              style={{ maxHeight: 28 }}
              label={i18n.t("export_to_excel")}
              onClick={() => onExport("ageGroup")}
            />
          </div>
        </div>
        <BasicTable
          id="age_table"
          maxContentWidth={false}
          rows={[{}]}
          schema={ageGroupGrouping.map((element) => ({
            header: element.name,
            field: element._id,
            render: () => {
              return <div>{element.count}</div>
            },
          }))}
        />
      </div>
      <div className="d-flex flex-column">
        <div className="row justify-content-between align-items-center mb-3">
          <h5 className="mb-0 font-weight-bolder">{i18n.t("origins")}</h5>
          <div className="row gap-2">
            <PrimaryButton
              className="w-128"
              style={{ maxHeight: 28 }}
              label={i18n.t("export_to_pdf")}
              onClick={() =>
                exportTableToPDF(["#origin_table"], i18n.t("origins"))
              }
            />
            <PrimaryButton
              className="w-128"
              style={{ maxHeight: 28 }}
              label={i18n.t("export_to_excel")}
              onClick={() => onExport("origin")}
            />
          </div>
        </div>
        <BasicTable
          id="origin_table"
          maxContentWidth={false}
          rows={[{}]}
          schema={originGrouping.map((element) => ({
            header: element.name,
            field: element._id,
            render: () => {
              return <div>{element.count}</div>
            },
          }))}
        />
      </div>
      <div className="d-flex flex-column">
        <div className="row justify-content-between align-items-center mb-3">
          <h5 className="mb-0 font-weight-bolder">{i18n.t("districts")}</h5>
          <div className="row gap-2">
            <PrimaryButton
              className="w-128"
              style={{ maxHeight: 28 }}
              label={i18n.t("export_to_pdf")}
              onClick={() =>
                exportTableToPDF(["#district_table"], i18n.t("districts"))
              }
            />
            <PrimaryButton
              className="w-128"
              style={{ maxHeight: 28 }}
              label={i18n.t("export_to_excel")}
              onClick={() => onExport("district")}
            />
          </div>
        </div>
        <BasicTable
          id="district_table"
          maxContentWidth={false}
          rows={[{}]}
          schema={districtGrouping.map((element) => ({
            header: element.name,
            field: element._id,
            render: () => {
              return <div>{element.count}</div>
            },
          }))}
        />
      </div>
    </div>
  )
}

function CustomerStatsCharts({ stats, view, onExport, setLoading }) {
  const ref = useRef()
  const exportChartToPDF = (element, name) => {
    setLoading(true)
    exportImgToPDF(element, name).finally(() => setLoading(false))
  }
  const [statusGrouping, ageGroupGrouping, originGrouping, districtGrouping] =
    useMemo(() => {
      const chartFormatter = (label, entries) => {
        const data = {
          label,
          labels: [],
        }
        const dataset = {
          data: [],
          backgroundColor: [],
        }
        entries.map((element, index) => {
          data.labels.push(element.name)
          dataset.data.push(element.count)
          dataset.backgroundColor.push(dynamicColors())
        })

        data.datasets = [dataset]
        return data
      }
      const [
        statusGrouping,
        ageGroupGrouping,
        originGrouping,
        districtGrouping,
      ] = stats
      return [
        chartFormatter(i18n.t("statuses"), statusGrouping),
        chartFormatter(i18n.t("age_groups"), ageGroupGrouping),
        chartFormatter(i18n.t("origins"), originGrouping),
        chartFormatter(i18n.t("districts"), districtGrouping),
      ]
    }, [stats])

  if (view != "chart") {
    return null
  }
  return (
    <div className="row">
      <div className="d-flex flex-column col-xs-12 col-md-6 px-0">
        <div className="dashboard-box">
          <div className="row align-items-center justify-content-between my-3 mr-3">
            <h5 className="mb-0 font-weight-bolder">{i18n.t("statuses")}</h5>
            <div className="row gap-2">
              <PrimaryButton
                className="w-128"
                style={{ maxHeight: 28 }}
                label={i18n.t("export_to_pdf")}
                onClick={() =>
                  exportChartToPDF(
                    document.querySelector("#status_chart"),
                    i18n.t("statuses")
                  )
                }
              />
              <PrimaryButton
                className="w-128"
                style={{ maxHeight: 28 }}
                label={i18n.t("export_to_excel")}
                onClick={() => onExport("status")}
              />
            </div>
          </div>
        </div>
        <Pie id="status_chart" data={statusGrouping} />
      </div>
      <div className="d-flex flex-column col-xs-12 col-md-6 px-0">
        <div className="dashboard-box">
          <div className="row align-items-center justify-content-between my-3 ml-3">
            <h5 className="mb-0 font-weight-bolder">{i18n.t("age_groups")}</h5>
            <div className="row gap-2">
              <PrimaryButton
                className="w-128"
                style={{ maxHeight: 28 }}
                label={i18n.t("export_to_pdf")}
                onClick={() =>
                  exportChartToPDF(
                    document.querySelector("#age_chart"),
                    i18n.t("age_groups")
                  )
                }
              />
              <PrimaryButton
                className="w-128"
                style={{ maxHeight: 28 }}
                label={i18n.t("export_to_excel")}
                onClick={() => onExport("ageGroup")}
              />
            </div>
          </div>
        </div>
        <Pie id="age_chart" ref={ref} data={ageGroupGrouping} />
      </div>
      <div className="d-flex flex-column col-xs-12 col-md-6 px-0">
        <div className="dashboard-box">
          <div className="row align-items-center justify-content-between my-3 mr-3">
            <h5 className="mb-0 font-weight-bolder">{i18n.t("origins")}</h5>
            <div className="row gap-2">
              <PrimaryButton
                className="w-128"
                style={{ maxHeight: 28 }}
                label={i18n.t("export_to_pdf")}
                onClick={() =>
                  exportChartToPDF(
                    document.querySelector("#origin_chart"),
                    i18n.t("origins")
                  )
                }
              />
              <PrimaryButton
                className="w-128"
                style={{ maxHeight: 28 }}
                label={i18n.t("export_to_excel")}
                onClick={() => onExport("origin")}
              />
            </div>
          </div>
        </div>
        <Pie id="origin_chart" data={originGrouping} />
      </div>
      <div className="d-flex flex-column col-xs-12 col-md-6 px-0">
        <div className="dashboard-box">
          <div className="row align-items-center justify-content-between my-3 ml-3">
            <h5 className="mb-0 font-weight-bolder">{i18n.t("districts")}</h5>
            <div className="row gap-2">
              <PrimaryButton
                className="w-128"
                style={{ maxHeight: 28 }}
                label={i18n.t("export_to_pdf")}
                onClick={() =>
                  exportChartToPDF(
                    document.querySelector("#distric_chart"),
                    i18n.t("districts")
                  )
                }
              />
              <PrimaryButton
                className="w-128"
                style={{ maxHeight: 28 }}
                label={i18n.t("export_to_excel")}
                onClick={() => onExport("district")}
              />
            </div>
          </div>
        </div>
        <Pie id="distric_chart" data={districtGrouping} />
      </div>
    </div>
  )
}

export default function CustomerStats() {
  const dispatch = useDispatch()
  const [stats, setStats] = useState({})
  const [filters, setFilters] = useState({})
  const [filterPopupOpen, setFilterPopupOpen] = useState(false)
  const [view, setView] = useState("chart")
  const [showTable, setShowTable] = useState(false)
  const [viewMenuAnchor, setViewMenuAnchor] = useState(null)
  const [loading, setLoading] = useState(false)
  const ageGroups = useSelector((state) => state.customerAgeGroup.data)
  const districts = useSelector((state) => state.customerDistrict.data)
  const origins = useSelector((state) => state.customerOrigin.data)
  const statuses = useSelector((state) => state.customerStatus.data)

  const getStats = (type) => {
    setLoading(true)
    setFilterPopupOpen(false)
    customerStats({ filters, type, exportToXlsx: type })
      .then(({ data }) => {
        if (data) {
          setStats(data.data)
        }
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    dispatch(ageGroupActions.getRequest())
    dispatch(originActions.getRequest())
    dispatch(districtActions.getRequest())
    dispatch(statusActions.getRequest())
    getStats()
  }, [])

  const [ageGroupsMap, districtsMap, originsMap, statusesMap] = useMemo(() => {
    return [
      arrayToMap(ageGroups),
      arrayToMap(districts),
      arrayToMap(origins),
      arrayToMap(statuses),
    ]
  }, [ageGroups, districts, origins, statuses])
  const _stats = useMemo(() => {
    const formatter = (array, map = {}) => {
      return (array || []).map((element) => {
        return {
          ...element,
          name: map[element._id],
        }
      })
    }
    return [
      formatter(stats.statusGrouping, statusesMap),
      formatter(stats.ageGroupGrouping, ageGroupsMap),
      formatter(stats.originGrouping, originsMap),
      formatter(stats.districtGrouping, districtsMap),
    ]
  }, [stats, ageGroupsMap, districtsMap, originsMap, statusesMap])

  const onExport = (type) => {
    getStats(type)
  }

  return (
    <div className="content-container p-4">
      <Menu
        id="simple-menu"
        anchorEl={viewMenuAnchor}
        keepMounted
        open={Boolean(viewMenuAnchor)}
        onClose={() => setViewMenuAnchor()}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        getContentAnchorEl={null}
      >
        {["chart", "table"].map((item) => (
          <MenuItem
            key={item}
            onClick={() => {
              setViewMenuAnchor()
              setView(item)
            }}
          >
            {i18n.t(item)}
          </MenuItem>
        ))}
      </Menu>
      <CustomerStatsFilterPopup
        filters={filters}
        setFilters={setFilters}
        onFilter={() => getStats()}
        open={filterPopupOpen}
        setOpen={setFilterPopupOpen}
      />
      <DotsLoader isloading={loading} />
      <div className="d-sm-flex justify-content-between">
        <h1 className="saloon-dashboard-heading mb-3">
          {i18n.t("customer_stats")}
        </h1>
        <ToggleSelect
          value={showTable}
          onChange={(e) => {
            setShowTable(e), setView(e ? "table" : "chart")
          }}
          active="Table"
          inactive="Chart"
        />
      </div>
      <CustomerStatsTables
        stats={_stats}
        view={view}
        showTable={showTable}
        onExport={onExport}
      />
      <CustomerStatsCharts
        stats={_stats}
        view={view}
        onExport={onExport}
        setLoading={setLoading}
      />
    </div>
  )
}
