import { take, put, call, fork } from "redux-saga/effects"

import * as types from "../actions/ActionTypes"
import { success, failure } from "../actions/ResetPassword"
import { reset_password_Api } from "../../config/WebServices"
import { ErrorHelper } from "../../helpers"
import axios from "axios"

function callRequest(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(reset_password_Api, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response.data)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}
function* watchRequest() {
  while (true) {
    const { payload } = yield take(types.RESET_PASSWORD.REQUEST)
    try {
      const response = yield call(callRequest, payload)
      yield put(success(response))
    } catch (err) {
      yield put(failure(err))
      ErrorHelper.handleErrors(err, true)
    }
  }
}

export default function* root() {
  yield fork(watchRequest)
}
