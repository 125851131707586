import React, {
  createContext,
  useContext,
  useState,
  useRef,
  useEffect,
} from "react"
import useActiveMenuType from "../ActiveMenuType"
import useCache from "../LocalCache"
import { BaseModal, DotsLoader } from "../../components"
import i18n from "../../i18n"
import {
  SelectValidator,
  TextValidator,
  ValidatorForm,
} from "react-material-ui-form-validator"
import {
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Tooltip,
  ClickAwayListener,
  Popper,
  makeStyles
} from "@material-ui/core"
import CustomerInput from "../../components/Saloon/CustomerInput/CustomerInput"
import Tabs from "../../components/Core/Tabs/Tabs"
import TouchList from "../../components/Partner/CreateAppointment/Support/TouchList"
import actions from "../../redux/actions/Saloon/Intake"
import intakeTypeActions from "../../redux/actions/Saloon/IntakeType"
import { useDispatch } from "react-redux"
import PrimaryButton from "../../components/Core/PrimaryButton"
import { ErrorHelper, SuccessHelper } from "../../helpers"
import { useMemo } from "react"
import "./IntakeCustomer.css"
import { useSelector } from "react-redux"
import Select from "../../components/Core/Select"
import Autocomplete from "react-autocomplete"
import { AccountCircle, Add, AddCircle, AddCircleTwoTone, Info, TouchApp } from "@material-ui/icons"
import CustomerSearchUp from "../../components/TouchCustomerSeachUp/CustomerSearchUp"
import CustomerEdit from "../../components/Saloon/Customer/CustomerEdit"
import Close from "@material-ui/icons/Close"
import FormHelperText from "@material-ui/core/FormHelperText";
const { upsertRequest } = actions
const { getRequest } = intakeTypeActions
import { getAvailableEventsRequest, getByCustomerRequest, setCustomersRequest } from "../../redux/actions/Saloon/ActivityScheduleEvent"
import ActivityActions from "../../redux/actions/Saloon/Activity"
import ActivityCategoryActions from "../../redux/actions/Saloon/ActivityCategory"
const { getRequest: getActivities } = ActivityActions
const { getRequest: getActivityCategories } = ActivityCategoryActions
import moment from "moment"
import { deleteCustomer } from "../../config/simpleApiCalls"
import restoreConfirmation from "../../helpers/RestoreHelper"

const IntakeCustomerContext = createContext({
  open: false,
  setOpen: () => { },
})

const useStyles = makeStyles((theme) => ({
  paper: {
    border: '1px solid',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
}));


export function IntakeCustomerProvider({
  children,
  open,
  setOpen,
  setBookingOpen,
}) {
  const { type } = useActiveMenuType()
  const dispatch = useDispatch()
  const { getResource } = useCache()
  const companyId = getResource(
    (cache) => cache.employee.companies?.[0]?.companyId?._id
  )

  const classes = useStyles();
  const [loading, setLoading] = useState(false)
  const intakeCategories = getResource((cache) => {
    if (type != "employee") {
      return cache.company.intakeCategories
    } else {
      return cache.employee.intakeCategories
    }
  })
  const products = getResource((cache) => {
    if (type != "employee") {
      return cache.company.products
    } else {
      return companyId ? cache.employee.productMap?.[companyId] : []
    }
  })
  const services = getResource((cache) => {
    if (type != "employee") {
      return cache.company.services
    } else {
      return companyId ? cache.employee.productMap?.[companyId] : []
    }
  })
  const intakeTypes = useSelector((state) => state.intakeType.data)

  const customerActivityScheduleEvents = useSelector((s) => s.activityScheduleEvent.customerHistory || [])
  const isSalon = useMemo(() => {
    return type != "employee"
  }, [type])
  const [customers, inactiveCustomers] = getResource((cache) => {
    let data
    if (isSalon) {
      data = cache.company.customers
    } else {
      data = cache.employee.customers
    }
    return [data?.filter(customer => customer.isActive), data?.filter(customer => !customer.isActive)]
  })
  const form = useRef()
  const [data, setData] = useState({})
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [selectProductMap, setSelectedProductMap] = useState({})
  const [selectServiceMap, setSelectedServiceMap] = useState({})
  const [selectedCategoryItemMap, setSelectedCategoryItemMap] = useState({})
  const [category, setCategory] = useState(null)
  const [selectedActivityMap, setSelectedActivityMap] = useState({})
  const [activityCategory, setActivityCategory] = useState(null)
  const [formErrors, setFormErrors] = useState({})
  const [search, setSearch] = useState("")
  const [touchSearchOpen, setTouchSearchOpen] = useState(false)
  const [showCustomerModal, setShowCustomerModal] = useState(false)
  const [showActivitiesTab, setShowActivitiesTab] = useState()
  const [disableRipple, setDisableRipple] = useState(false)
  const availableEvents = useSelector((s) => s.activityScheduleEvent.availableEvents)
  const activities = useSelector((s) => s.activity.data)
  const activityCategories = useSelector((s) => s.activityCategory.data)
  const [activityTooltips, setActivityTooltips] = useState({})

  const validateCustomerField = () => {
    const { name, phoneNo, email } = data
    const formErrors = {}
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const telephoneRegex = /[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]/g
    if (email && !email.match(emailRegex)) {
      formErrors.emailError = i18n.t("invalid_email")
    }
    if (!name) {
      formErrors.nameError = i18n.t("name_required")
    }
    if (phoneNo && !phoneNo.replace(/\s/g, "").match(telephoneRegex)) {
      formErrors.phoneNumberError = i18n.t("invalid_phone_number")
    }
    if (!phoneNo) {
      formErrors.phoneNumberError = i18n.t(
        "container.employe_valid_phone_number"
      )
    }
    setFormErrors(formErrors)
    if (Object.keys(formErrors).length) {
      return true
    }
    return false
  }
  const onSubmit = () => {
    if (!validateCustomerField()) {

      const submit = () => {
        const activities = []
        Object.keys(selectedActivityMap).map(selectedActivity => {
          const event = availableEvents.find(({ _id }) => _id === selectedActivity)
          activities.push(event?.activityId || selectedActivity)
        })

        dispatch(
          upsertRequest(
            {
              ...data,
              services: Object.keys(selectServiceMap),
              products: Object.keys(selectProductMap),
              activities: activities,
              intakeCategoryItemId: Object.keys(selectedCategoryItemMap),
              companyCustomerId: selectedCustomer?._id,
            },
            {
              success() {
                SuccessHelper.handleSuccess(i18n.t("successfully_added"), true)
                setOpen(false)
              },
              failure() {
                ErrorHelper.handleErrors(i18n.t("failed_to_add"), true)
              },
            }
          )
        )
      }

      const customer = inactiveCustomers.find(customer => customer.phoneNo === data.phoneNo)

      if (customer?.isActive === false) {
        restoreConfirmation(() => deleteCustomer({ ids: customer._id })
          .then((res) => {
            if (res.data.success) {
              submit()
            } else if (!res.data.success) {
              ErrorHelper.handleErrors(res.data.msg, true)

            }
          })
          .catch((error) => {
            console.log(error, "errorrrrrrrrrrrrrrrrrrr")
          }), i18n.t("this_is_inactive", { name: i18n.t("customer").toLowerCase() }))
      } else {
        submit()
      }


    }
  }
  const handleChange = (newData) => {
    setData({ ...data, ...newData })
  }

  useEffect(() => {
    dispatch(getAvailableEventsRequest({ start: new Date() }))
    dispatch(getActivities())
    dispatch(getActivityCategories())
    setData({})
    setSelectedCustomer(null)
    setSelectedProductMap({})
    setSelectedServiceMap({})
    setSelectedActivityMap({})
    setSelectedCategoryItemMap({})
    setFormErrors({})
  }, [open])

  useEffect(() => {
    dispatch(getRequest())
  }, [])


  useEffect(() => {
    dispatch(getByCustomerRequest({ id: selectedCustomer?._id }))
  }, [selectedCustomer])
  const [anchorEl, setAnchorEl] = React.useState(null);

  const filteredActivities = useMemo(() => {
    const items = []
    const NoSessionsActivities = activities.filter((activity) => {
      if (!availableEvents.find(({ activityId }) => activity._id == activityId)) {
        return activity
      }
    })

    availableEvents.concat(NoSessionsActivities).map((event) => {
      if (activityCategory) {
        if (activityCategory == event.activityCategoryId) {
          items.push(event)
        }
        return
      }
      items.push(event)
    })
    return items
  }, [activityCategory, activities, availableEvents])

  const filteredIntakeCategory = useMemo(() => {
    const items = []
    intakeCategories.map((_category) => {
      if (category) {
        if (category == _category._id) {
          items.push(..._category.items)
        }
        return
      }
      items.push(..._category.items)
    })
    return items
  }, [category, intakeCategories])

  const [filteredProducts, filteredServices, filteredCategories, filteredAvailableEvents] = useMemo(() => {
    const filterFunction = ({ name }) => {

      return name.toUpperCase().includes(search.toUpperCase())
    }

    return [products.filter(filterFunction), services.filter(filterFunction), filteredIntakeCategory.filter(filterFunction), filteredActivities.filter(filterFunction)]
  }, [search, products, services, filteredIntakeCategory, filteredActivities])

  useEffect(() => {
    const tooltips = {}
    filteredAvailableEvents.map(event => {
      tooltips[event._id] = false
    })

    setActivityTooltips({ ...tooltips })
  }, [filteredAvailableEvents])

  return (
    <IntakeCustomerContext.Provider value={{ open, setOpen, activityTooltips, setActivityTooltips }}>
      <DotsLoader isloading={loading} />
      <BaseModal
        title={i18n.t("intake_customer")}
        onClose={() => {
          setOpen(false)
        }}
        open={open}
        content={
          <>
            {showCustomerModal ? (
              <CustomerEdit
                open={showCustomerModal}
                setOpen={() => {
                  setShowCustomerModal(null)
                  setShowActivitiesTab()
                }}
                active={selectedCustomer || { phoneNo: data.phoneNo }}
                setAccountBalance={() => { }}
                openTab={showActivitiesTab}
                isGym={false}
                handleEdit={() => { }}
                handleDrawerClose={() => {
                  setOpen(false)
                  setShowCustomerModal(false)
                }}
                onUpdate={(val) => {
                  setSelectedCustomer(val)
                  setData({ ...data, phoneNo: val.phoneNo })
                  setShowCustomerModal(false)
                }}
              />
            ) : null}
            <ValidatorForm
              onSubmit={onSubmit}
              ref={form}
              onError={validateCustomerField}
            >
              <div className="row mx-0 negativeMargins IntakeCustomer">
                <div className="col-md-6 col-xs-12 pl-0 pr-0 pr-md-3">
                  {customerActivityScheduleEvents.length ?
                    <div class="d-flex justify-content-end">
                      <PrimaryButton
                        label={i18n.t("activities")}
                        style={{ marginLeft: "auto" }}
                        onClick={() => {
                          setShowActivitiesTab(i18n.t("activity_and_registration"))
                          setShowCustomerModal(true)
                        }}
                      />
                    </div> : null}
                  <div className="d-sm-flex align-items-center mb-3">
                    <label className="font-weight-bold mobile-w-100" style={{ minWidth: '140px' }}>{i18n.t("type")}</label>
                    <div className="w-100">
                      <SelectValidator
                        className="w-100"
                        onChange={(e) => {
                          handleChange({ intakeTypeId: e.target.value })
                        }}
                        value={data.intakeTypeId || ""}
                        validators={["required"]}
                        errorMessages={[i18n.t("this_field_is_required")]}
                        select
                        variant="outlined"
                      >
                        {intakeTypes.map((intakeType) => {
                          return (
                            <MenuItem value={intakeType._id}>
                              {intakeType.name}
                            </MenuItem>
                          )
                        })}
                      </SelectValidator>
                    </div>
                  </div>
                  <div className="form-label-group mb-1 col-12 p-0">
                    <div className="d-sm-flex align-items-center mb-3">
                      <label className="font-weight-bold mobile-w-100" style={{ minWidth: '140px' }}>{i18n.t("phone_number")}</label>
                      <div className="w-100 row align-items-center px-0 flex-nowrap">
                        <Autocomplete
                          placeholder={i18n.t(
                            "enter_phone_number_placeholder_type"
                          )}
                          renderInput={(props) => {
                            return (
                              <TextField
                                id="phoneNumber"
                                variant="outlined"
                                {...props}
                                {...{
                                  InputProps: {
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        {Boolean(selectedCustomer?._id) && (
                                          <>
                                            <Close
                                              fontSize="medium"
                                              style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                setSelectedCustomer(null)
                                                setData({
                                                  ...data,
                                                  phoneNo: "",
                                                  name: ""
                                                })
                                              }}
                                            />
                                            <AccountCircle
                                              fontSize="medium"
                                              style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                setShowCustomerModal(true)
                                              }}
                                            />
                                          </>
                                        )}
                                        {!Boolean(selectedCustomer?._id) && (
                                          <Add
                                            fontSize="medium"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                              setSelectedCustomer(null)
                                              setShowCustomerModal(true)
                                            }}
                                          />
                                        )}
                                      </InputAdornment>
                                    ),
                                    ...props.InputProps,
                                  },
                                }}
                              />
                            )
                          }}
                          items={data.phoneNo ? customers : []}
                          shouldItemRender={(item, value) =>
                            item.phoneNo
                              .toLowerCase()
                              .indexOf(value.toLowerCase()) > -1 ||
                            item.name
                              .toLowerCase()
                              .indexOf(value.toLowerCase()) > -1 ||
                            item.firstName?.toLowerCase().indexOf(value.toLowerCase()) > -1
                          }
                          getItemValue={(item) => item.phoneNo}
                          renderItem={(item, highlighted) => (
                            <MenuItem key={item._id}>{(`${item.firstName ? item.firstName : ""} ${item.name}`).trim()}</MenuItem>
                          )}
                          menuStyle={{
                            zIndex: "9998",
                            position: "absolute",
                            background: "white",
                            top: 64,
                            left: 0,
                          }}
                          inputProps={{
                            className: "full-width",
                            label: i18n.t("container.employe_phone_number"),
                            error: !!formErrors?.phoneNumberError,
                          }}
                          wrapperStyle={{
                            width: "100%",
                          }}
                          renderMenu={(children) => {
                            return (
                              <div className="menu">
                                {children.slice(0, 15)}
                              </div>
                            )
                          }}
                          value={data.phoneNo || ""}
                          onChange={(e) =>
                            handleChange({ phoneNo: e.target.value })
                          }
                          onSelect={(value, _data) => {
                            const { phoneNo, email, firstName, name } = _data
                            setSelectedCustomer(_data)
                            handleChange({ phoneNo, email, name: (`${firstName ? firstName : ""} ${name}`).trim() })
                          }}
                        />
                        <IconButton
                          aria-label="search"
                          className="ml-2 p-2"
                          style={{ backgroundColor: "#3f51b5" }}
                          onClick={() => setTouchSearchOpen(true)}
                        >
                          <TouchApp
                            fontSize="medium"
                            color="white"
                            style={{ color: "white" }}
                          />
                        </IconButton>
                      </div>
                    </div>
                    <div className="col-12 px-0">
                      {!!formErrors?.phoneNumberError && (
                        <FormHelperText error>
                          {formErrors?.phoneNumberError}
                        </FormHelperText>
                      )}
                    </div>
                  </div>
                  <div className="d-sm-flex align-items-center mb-3">
                    <label className="font-weight-bold mobile-w-100" style={{ minWidth: '140px' }}>{i18n.t("name")}</label>
                    <div className="w-100">
                      <Autocomplete
                        placeholder={i18n.t(
                          i18n.t("name")
                        )}
                        renderInput={(props) => {
                          return (
                            <TextField
                              variant="outlined"
                              id="name"
                              {...props}

                            />
                          )
                        }}
                        items={data.name ? customers : []}
                        shouldItemRender={(item, value) =>
                          item.name
                            .toLowerCase()
                            .indexOf(value.toLowerCase()) > -1 ||
                          item.firstName?.toLowerCase().indexOf(value.toLowerCase()) > -1
                        }
                        getItemValue={(item) => item.name}
                        renderItem={(item, highlighted) => (
                          <MenuItem key={item._id}>{(`${item.firstName ? item.firstName : ""} ${item.name}`).trim()}</MenuItem>
                        )}
                        menuStyle={{
                          zIndex: "9998",
                          position: "absolute",
                          background: "white",
                          top: 64,
                          left: 0,
                        }}
                        inputProps={{
                          className: "full-width",
                          label: i18n.t("name"),
                          error: !!formErrors?.nameError,
                        }}
                        wrapperStyle={{
                          width: "100%",
                        }}
                        renderMenu={(children) => {
                          return (
                            <div className="menu">
                              {children.slice(0, 15)}
                            </div>
                          )
                        }}
                        value={data.name || ""}
                        onChange={(e) =>
                          handleChange({ name: e.target.value })
                        }
                        onSelect={(value, _data) => {
                          const { phoneNo, email, firstName, name } = _data
                          setSelectedCustomer(_data)
                          handleChange({ phoneNo, email, name: (`${firstName ? firstName : ""} ${name}`).trim() })
                        }}
                      />
                    </div>

                  </div>
                  <div className="col-12 px-0">
                    {!!formErrors?.nameError && (
                      <FormHelperText error>
                        {formErrors?.nameError}
                      </FormHelperText>
                    )}
                  </div>
                  <div className="d-sm-flex align-items-center mb-3">
                    <label className="font-weight-bold mobile-w-100" style={{ minWidth: '140px' }}>{i18n.t("email")}</label>
                    <TextField
                      id="email"
                      label={i18n.t("container.profile_email")}
                      value={selectedCustomer?.email || ""}
                      placeholder={i18n.t("enter_email_placeholder")}
                      inputProps={{ readOnly: true }}
                      variant="outlined"
                    />
                  </div>
                  <CustomerSearchUp
                    open={touchSearchOpen}
                    setOpen={setTouchSearchOpen}
                    customers={customers || []}
                    onDone={(phoneNo, selected) => {
                      if (selected) {
                        setSelectedCustomer(selected)
                        handleChange({ ...data, ...selected })
                      } else {
                        handleChange({ phoneNo })
                      }
                      phoneNoLookUp(phoneNo)
                      setTouchSearchOpen(false)
                    }}
                  />
                  <div className="col-12 d-sm-flex align-items-start mb-3 px-0">
                    <label className="font-weight-bold mobile-w-100" style={{ minWidth: '140px' }}>{i18n.t("notes")}</label>
                    <div className="w-100">
                      <TextValidator
                        id="notes"
                        value={data.notes || ""}
                        label={i18n.t("notes")}
                        multiline
                        rows={5}
                        placeholder={i18n.t("enter_notes_placeholder")}
                        onChange={(e) => handleChange({ notes: e.target.value })}
                        variant="outlined"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-xs-12 custom-tab pl-0 pr-0 pl-md-3">
                  <Tabs
                    tabs={[
                      {
                        title: (
                          <div>
                            {i18n.t("category")}
                            <div className="count">
                              {filteredCategories.length}
                            </div >
                          </div >
                        ),
                        content: () => (
                          <>
                            <div className="col-12 d-flex align-items-center mb-3 px-0">
                              <label className="font-weight-bold mr-3">{i18n.t("notes")}</label>
                              <Select
                                label={i18n.t("category")}
                                options={intakeCategories.map(
                                  ({ _id, name }) => ({
                                    name,
                                    value: _id,
                                  })
                                )}
                                onChange={(e) => {
                                  setCategory(e.target.value)
                                }}
                                variant="outlined"
                              />
                            </div>
                            <TouchList
                              options={filteredCategories}
                              onChange={(val, e) => {
                                if (selectedCategoryItemMap[val._id]) {
                                  delete selectedCategoryItemMap[val._id]
                                  setSelectedCategoryItemMap({
                                    ...selectedCategoryItemMap,
                                  })
                                } else {
                                  setSelectedCategoryItemMap({
                                    ...selectedCategoryItemMap,
                                    [val._id]: true,
                                  })
                                }
                              }}
                              selectedMap={selectedCategoryItemMap}
                              noOptionsMessage="no_items"
                              style={{
                                overflow: "auto",
                              }}
                            />
                          </>
                        ),
                      },
                      {
                        title: (
                          <div>
                            {i18n.t("products")}
                            <div className="count">
                              {filteredProducts.length}
                            </div>
                          </div>
                        ),
                        content: () => (
                          <>
                            <div className="col-12 d-flex align-items-center mb-3 px-0">
                              <label className="font-weight-bold mr-3">{i18n.t("search")}</label>
                              <TextField
                                label={i18n.t("search")}
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                variant="outlined"
                              />
                            </div>
                            <TouchList
                              options={filteredProducts}
                              onChange={(val, e) => {
                                if (selectProductMap[val._id]) {
                                  delete selectProductMap[val._id]
                                  setSelectedProductMap({ ...selectProductMap })
                                } else {
                                  setSelectedProductMap({
                                    ...selectProductMap,
                                    [val._id]: true,
                                  })
                                }
                              }}
                              selectedMap={selectProductMap}
                              noOptionsMessage="no_products"
                              style={{
                                overflow: "auto",
                              }}
                              renderChip={(val) => {
                                let children = []
                                if (val.charge) {
                                  children.push(
                                    <div className="h6">{`${i18n.t(
                                      "price"
                                    )}: $${val.charge}`}</div>
                                  )
                                }
                                if (val.description) {
                                  children.push(
                                    <div className="h6">{`${i18n.t(
                                      "description"
                                    )}: ${val.description}`}</div>
                                  )
                                }
                                if (val.quantity) {
                                  children.push(
                                    <div className="h6">{`${i18n.t(
                                      "quantity"
                                    )}: ${val.quantity}`}</div>
                                  )
                                }
                                return (
                                  <div className="row flex-nowrap align-items-center">
                                    <div className="mr-2">{val.name}</div>
                                    <Tooltip title={children} aria-label="info">
                                      <Info />
                                    </Tooltip>
                                  </div>
                                )
                              }}
                            />
                          </>
                        ),
                      },
                      {
                        title: (
                          <div>
                            {i18n.t("services")}
                            <div className="count">
                              {filteredServices.length}
                            </div>
                          </div>
                        ),
                        content: () => (
                          <>
                            <div className="col-12 d-flex align-items-center mb-3 px-0">
                              <label className="font-weight-bold mr-3">{i18n.t("search")}</label>
                              <TextField
                                label={i18n.t("search")}
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                variant="outlined"
                              />
                            </div>
                            <TouchList
                              options={filteredServices}
                              onChange={(val, e) => {
                                if (selectServiceMap[val._id]) {
                                  delete selectServiceMap[val._id]
                                  setSelectedServiceMap({ ...selectServiceMap })
                                } else {
                                  setSelectedServiceMap({
                                    ...selectServiceMap,
                                    [val._id]: true,
                                  })
                                }
                              }}
                              selectedMap={selectServiceMap}
                              noOptionsMessage="no_services"
                              style={{
                                overflow: "auto",
                              }}
                              renderChip={(val) => {
                                let children = []
                                if (val.price) {
                                  children.push(
                                    <div className="h6">{`${i18n.t(
                                      "price"
                                    )}: $${val.price}`}</div>
                                  )
                                }
                                if (val.depositAmount) {
                                  children.push(
                                    <div className="h6">{`${i18n.t(
                                      "deposit_amount"
                                    )}: $${val.depositAmount}`}</div>
                                  )
                                }
                                if (val.duration) {
                                  children.push(
                                    <div className="h6">{`${i18n.t(
                                      "duration"
                                    )}: ${val.duration} minutes`}</div>
                                  )
                                }
                                if (val.description) {
                                  children.push(
                                    <div className="h6">{`${i18n.t(
                                      "description"
                                    )}: ${val.description}`}</div>
                                  )
                                }
                                if (val.quantity) {
                                  children.push(
                                    <div className="h6">{`${i18n.t(
                                      "quantity"
                                    )}: ${val.quantity}`}</div>
                                  )
                                }
                                return (
                                  <div className="row flex-nowrap align-items-center">
                                    <div className="mr-2">{val.name}</div>

                                    <Tooltip title={children} aria-label="info">
                                      <Info />
                                    </Tooltip>
                                  </div>
                                )
                              }}
                            />
                          </>
                        ),
                      },
                      {
                        title: (
                          <div>
                            {i18n.t("activities")}
                            <div className="count">
                              {filteredAvailableEvents.length}
                            </div>
                          </div>
                        ),
                        content: () => (
                          <>
                            <div className="col-12 d-flex align-items-center mb-3 px-0">
                              <label className="font-weight-bold mr-3">{i18n.t("search")}</label>
                              <TextField
                                label={i18n.t("search")}
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                variant="outlined"
                              />
                            </div>
                            <div className="col-12 d-flex align-items-center mb-3 px-0">
                              <label className="font-weight-bold mr-3">{i18n.t("category")}</label>
                              <Select
                                label={i18n.t("category")}
                                options={activityCategories.map(
                                  ({ _id, name }) => ({
                                    name,
                                    value: _id,
                                  })
                                )}
                                onChange={(e) => {
                                  setActivityCategory(e.target.value)
                                }}
                                value={activityCategory}
                                className="mb-2"
                                variant="outlined"
                              />
                            </div>

                            <TouchList
                              disableRipple={disableRipple}
                              options={filteredAvailableEvents}
                              onChange={(val, e) => {

                                if (!e.target.closest("[data-activity]")) {
                                  if (selectedActivityMap[val._id]) {
                                    delete selectedActivityMap[val._id]
                                    setSelectedActivityMap({ ...selectedActivityMap })
                                  } else {
                                    setSelectedActivityMap({
                                      ...selectedActivityMap,
                                      [val._id]: true,
                                    })
                                  }
                                }

                              }}

                              selectedMap={selectedActivityMap}
                              noOptionsMessage="no_services"
                              style={{
                                maxHeight: "calc(100vh - 380px)",
                                overflow: "auto",
                              }}
                              renderChip={(val) => {
                                let children = []
                                const joined = (id) => customerActivityScheduleEvents.find(({ _id }) => _id === id)
                                let joinAll = false
                                const joinAllStyle = {}
                                val.sessions?.map((session, i) => {

                                  const style = {}

                                  const data = { _id: session._id }
                                  if (!joined(session._id)) {
                                    style.backgroundColor = "green"
                                    joinAll = true
                                    data.toAdd = [selectedCustomer]
                                  } else {
                                    data.toRemove = [selectedCustomer]
                                  }
                                  children.push(<div className="row m-1 gap-1 intake-activity">
                                    <div style={{ fontSize: "0.9rem" }} >{`Session ${i + 1} : ${moment(session.startDate).format("MM-DD-YYYY")} ${moment(session.startDate).format(("HH:mm"))} - ${moment(session.endDate).format(("HH:mm a"))}`} </div>
                                    {selectedCustomer ?
                                      <PrimaryButton
                                        size="small"
                                        style={style}
                                        onClick={() => {
                                          setLoading(true)
                                          dispatch(
                                            setCustomersRequest(
                                              data,
                                              {
                                                success: (data) => {
                                                  dispatch(getByCustomerRequest({ id: selectedCustomer?._id }))
                                                  setLoading(false)
                                                },
                                                failure: () => {
                                                  ErrorHelper.handleErrors(i18n.t("failed_to_update"), true)
                                                  setLoading(false)
                                                },
                                              }
                                            )
                                          )
                                        }}>
                                        {joined(session._id) ? i18n.t("remove") : i18n.t("enroll")}
                                      </PrimaryButton>
                                      : null}
                                  </div>

                                  )

                                })

                                if (joinAll) {
                                  joinAllStyle.backgroundColor = "green"
                                }

                                {
                                  selectedCustomer ?
                                    children.push(
                                      <div className="row m-1 mt-2 justify-content-end">
                                        <PrimaryButton size="small" style={joinAllStyle} onClick={() => {
                                          setLoading(true)
                                          val.sessions.map(({ _id }, i) => {
                                            const data = { _id: _id }
                                            if (joinAll && !joined(_id)) {
                                              data.toAdd = [selectedCustomer]
                                            } else if (!joinAll && joined(_id)) {
                                              data.toRemove = [selectedCustomer]
                                            }
                                            dispatch(
                                              setCustomersRequest(
                                                data,
                                                {
                                                  success: (data) => {
                                                    if (i === val.sessions.length - 1) {
                                                      dispatch(getByCustomerRequest({ id: selectedCustomer?._id }))
                                                      setLoading(false)
                                                    }
                                                  },
                                                  failure: () => {
                                                    ErrorHelper.handleErrors(i18n.t("failed_to_update"), true)
                                                    setLoading(false)
                                                  },
                                                }
                                              )
                                            )
                                          })

                                        }}>{joinAll ? i18n.t("enroll_all") : i18n.t("remove_all")}
                                        </PrimaryButton>
                                      </div>
                                    )
                                    : null
                                }
                                return (
                                  <div className="row flex-nowrap align-items-center">
                                    <div className="mr-2">{val.name}</div>
                                    {val.sessions ?
                                      <div data-activity>
                                        <IconButton size="small" onClick={(e) => {
                                          setDisableRipple(true)
                                          activityTooltips[val._id] = true
                                          setActivityTooltips({ ...activityTooltips })
                                          setAnchorEl(e.currentTarget)

                                        }}> <AddCircle
                                            fontSize="small"
                                            style={{ color: '#ff3600' }}
                                          /></IconButton>

                                        <Popper style={{ zIndex: 1302 }}
                                          open={activityTooltips[val._id]}
                                          container={document.querySelector("#intake_customer")}
                                          anchorEl={anchorEl}>
                                          <ClickAwayListener onClickAway={(e) => {

                                            if (!e.target.closest("[data-activity]")) {
                                              setDisableRipple(false)
                                            }

                                            activityTooltips[val._id] = false
                                            setActivityTooltips({ ...activityTooltips })
                                          }}>
                                            <div data-activity className={classes.paper} >
                                              {children}

                                            </div>
                                          </ClickAwayListener>

                                        </Popper>
                                      </div>
                                      : null}
                                  </div>
                                )
                              }}
                            />
                          </>
                        ),
                      },
                    ]}
                  />
                </div >
              </div>
              <div className="col-12 row justify-content-between my-4">
                <PrimaryButton
                  label={i18n.t("book")}
                  className="w-128"
                  onClick={() => {
                    setOpen(false)
                    setBookingOpen(true)
                  }}
                />
                <PrimaryButton
                  label={i18n.t("submit")}
                  className="w-128"
                  props={{
                    type: "submit",
                  }}
                />
              </div>
            </ValidatorForm>
          </>
        }
      />
      {children}
    </IntakeCustomerContext.Provider>
  )
}

//Hook
function useIntakeCustomer() {
  return useContext(IntakeCustomerContext)
}

export default useIntakeCustomer
