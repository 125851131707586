import React, { useEffect, useState } from "react"
import i18n from "../../../i18n"
import { Images } from "../../../theme"
import { deleteTax, upsertTax } from "../../../config/simpleApiCalls.js"
import BaseModal from "../../../components/BaseModal/index"
import SuccessHelper from "../../../helpers/SuccessHelper"
import ErrorHelper from "../../../helpers/ErrorHelper"
import PrimaryButton from "../../../components/Core/PrimaryButton"
import { useHistory } from "react-router"
import { ButtonWrapper } from "./taxes.styles"
import DotsLoader from "../../../components/DotsLoader/index"
import AddEditForm from "./AddEditForm"
import BaseTable from "../../../components/Core/BasicTable/index"
import DeleteIcon from "../../../components/Core/BasicTable/Actions/DeleteIcon"
import EditIcon from "../../../components/Core/BasicTable/Actions/EditIcon"
import useCache from "../../../context/LocalCache"

export default function AllTaxes() {
  const [taxEdit, setTaxEdit] = useState({
    showEditTaxCard: false,
    showBookingHistory: false,
    name: "",
    percentage: "",
    isActive: "",
    editId: "",
    index: null,
  })
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const { getResource } = useCache()
  const list = getResource((cache) => cache.company.taxes)

  const openEditTaxCard = (val, index) => {
    setTaxEdit({
      showEditTaxCard: true,
      showBookingHistory: false,
      name: val.name,
      percentage: val.percentage,
      isActive: val.isActive,
      editId: val._id,
      index,
    })
  }

  const closeEditTaxCard = () => {
    setTaxEdit({
      showEditTaxCard: false,
      name: "",
      percentage: 0,
      isActive: true,
      editId: "",
      index: undefined,
    })
  }

  const handleSaloonUpdateTax = () => {
    setIsLoading(true)
    const { name, percentage, isActive, editId } = taxEdit

    const payload = {
      name,
      percentage,
      isActive,
      _id: editId,
    }
    upsertTax(payload)
      .then((res) => {
        if (res.data.success) {
          setTaxEdit({
            ...taxEdit,
            showEditTaxCard: false,
          })
          setIsLoading(false)

          SuccessHelper.handleSuccess("Taxe successfully updated.", true)
        }
        if (!res.data.success) {
          ErrorHelper.handleErrors(res.data.msg, true)
          setIsLoading(false)
        }
      })
      .catch((error) => {
        ErrorHelper.handleErrors("An error occured", true)
        setIsLoading(false)
        console.log(error, "errorrrrrrrrrrrrrrrrrrr")
      })
  }
  const handleName = (a) => {
    setTaxEdit({
      ...taxEdit,
      name: a.toUpperCase(),
    })
  }

  const handlePercentage = (percentage) => {
    setTaxEdit({
      ...taxEdit,
      percentage,
    })
  }

  const handleIsActive = (isActive) => {
    setTaxEdit({
      ...taxEdit,
      isActive,
    })
  }

  const handleSaloonDeleteTax = (id, ind) => {
    setIsLoading(true)
    deleteTax({ ids: id })
      .then((res) => {
        if (res.data.success) {
          SuccessHelper.handleSuccess(
            "Your tax was successfully deleted.",
            true
          )
          setIsLoading(false)
        } else if (!res.data.success) {
          ErrorHelper.handleErrors(res.data.msg, true)
          setIsLoading(false)
        }
      })
      .catch((error) => {
        setIsLoading(false)
        console.log(error, "errorrrrrrrrrrrrrrrrrrr")
      })
  }
  const { showEditTaxCard, name, percentage, isActive } = taxEdit
  return (
    <div className="content-container p-4">
      <DotsLoader isloading={isLoading} />
      <div className="d-flex pb-4 flex-column flex-md-row gap-2 justify-content-between align-items-center">
        <h1 className="saloon-dashboard-heading">
          {i18n.t("container.taxes")}
        </h1>
        <PrimaryButton
          className="w-128"
          label={i18n.t("add_tax")}
          onClick={() => history.push("saloon-add-tax")}
        />
      </div>
      <div className="table-responsive pb-2">
        <BaseTable
          maxContentWidth={false}
          rows={list}
          schema={[
            {
              header: i18n.t("name"),
              field: "name",
            },
            {
              header: i18n.t("percentage"),
              field: "percentage",
            },
            {
              header: i18n.t("is_active"),
              field: "isActive",
              accessor: (val) => (val.isActive ? i18n.t("yes") : i18n.t("no")),
            },
            {
              key: "action",
              header: i18n.t("actions"),
              render: (val, ind) => {
                return (
                  <>
                    <EditIcon onClick={() => openEditTaxCard(val, ind)} />
                    <DeleteIcon
                      onClick={() => handleSaloonDeleteTax(val._id, ind)}
                    />
                  </>
                )
              },
            },
          ]}
        />
      </div>
      <BaseModal
        open={showEditTaxCard}
        containerClassName={"small-container"}
        {...{
          title: (
            <div
              dangerouslySetInnerHTML={{ __html: i18n.t("container.taxes") }}
            />
          ),
          content: (
            <AddEditForm
              {...{
                name,
                percentage,
                isActive,
                handleName,
                handlePercentage,
                handleIsActive,
              }}
              handleSubmit={handleSaloonUpdateTax}
            />
          ),
          onClose: () => {
            closeEditTaxCard()
          },
        }}
      />
    </div>
  )
}
