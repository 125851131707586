import React, { createContext, useState, useEffect, useContext } from "react"

export const ScreenSizeContext = createContext({ addOpen: false })

export function ScreenSizeProvider({ children }) {
  const [screenSize, setScreenSize] = useState("desktop")

  useEffect(() => {
    function getScreenSize(w) {
      if (w > 1200) {
        return "desktop"
      } else if (w > 480) {
        return "tablet"
      } else {
        return "mobile"
      }
    }

    const listener = () => {
      setScreenSize(getScreenSize(window.innerWidth))
    }

    window.addEventListener("resize", listener)
    listener()

    return () => window.removeEventListener("resize", listener)
  }, [])

  return (
    <ScreenSizeContext.Provider value={{ screenSize }}>
      <div className={screenSize}>{children}</div>
    </ScreenSizeContext.Provider>
  )
}

//Hook
function useScreenSize() {
  return useContext(ScreenSizeContext)
}

export default useScreenSize
