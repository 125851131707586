import { take, put, call, fork, takeLatest } from "redux-saga/effects"

import * as types from "../actions/ActionTypes"
import { success, failure, request } from "../actions/GetNotifications"

import axios from "axios"
import { notification } from "../../config/WebServices"
import { ErrorHelper } from "../../helpers"

function callRequest() {
  return axios.get(notification)
}

function* sendRequest(action) {
  try {
    const response = yield call(callRequest, action.payload)
    if (response.data.success) {
      yield put(success(response.data.data))
    } else {
      yield put(failure())
    }
  } catch (err) {
    yield put(failure(err))
  }
}

export default function* root() {
  yield takeLatest(types.GET_NOTIFICATIONS.REQUEST, sendRequest)
}
