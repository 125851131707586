import React, { createContext, useState, useEffect, useContext } from "react"
import { gapi } from "gapi-script"
import { BaseModal } from "../components"
import i18n from "../i18n"
import moment from "moment"
import TextField from "@material-ui/core/TextField/TextField"
import {
  getEmployeeDetails,
  getGoogleApiRefreshToken,
  editUser,
} from "../config/simpleApiCalls"
import GoogleButton from "react-google-button"
import { Sync, SyncDisabled } from "@material-ui/icons"
import SharedStorage from "../helpers/Storage"
import useScreenSize from "./ScreenSize"
import PrimaryButton from "../components/Core/PrimaryButton"
const GoogleCalendarContext = createContext({
  googleEvents: null,
  getEventsOnClick: () => {},
  disconnectGoogle: () => {},
})

export function GoogleCalendarProvider({ children }) {
  const [googleEvents, setGoogleEvents] = useState([])
  const [viewGoogleEvent, setViewGoogleEvent] = useState()
  const [isGoogleSignedIn, setIsGoogleSignedIn] = useState(false)
  const [isGoogleChecked, setIsGoogleChecked] = useState(true)
  const [employeeIdForGoogle, setEmployeeIdForGoogle] = useState()
  const [employeeForGoogle, setEmployeeForGoogle] = useState()
  const [timeMin, setTimeMin] = useState()
  const [timeMax, setTimeMax] = useState()
  const SCOPES = "https://www.googleapis.com/auth/calendar"

  const disconnectGoogle = () => {
    gapi.auth2?.getAuthInstance()?.signOut()
    setIsGoogleSignedIn(false)
    setGoogleEvents([])
    removeRefreshToken()
  }
  const removeRefreshToken = () => {
    employeeForGoogle.googleRefreshToken = null
    SharedStorage.setItem("employee", JSON.stringify(employeeForGoogle))
    editUser({ googleRefreshToken: null })
  }
  const initClient = () => {
    gapi.client.init({
      clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      scope: SCOPES,
    })
  }
  useEffect(() => {
    gapi.load("client:auth2", initClient)
  }, [])

  useEffect(() => {
    if (employeeForGoogle) {
      getEmployeeDetails({ user: employeeForGoogle }).then(({ data }) => {
        setEmployeeIdForGoogle(data.data.details._id)
        getEventsWithoutClick()
      })
    }
  }, [employeeForGoogle, timeMin, timeMax])

  const getEventsWithoutClick = () => {
    if (employeeForGoogle?.googleRefreshToken) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
          client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
          refresh_token: employeeForGoogle.googleRefreshToken,
          grant_type: "refresh_token",
        }),
      }
      //Get new access token
      fetch("https://www.googleapis.com/oauth2/v4/token", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          gapi.client.setToken({ access_token: data.access_token })
          gapi.client.load("calendar", "v3", listUpcomingEvents)
        })
    }
  }
  const getEventsOnClick = () => {
    gapi.auth2
      ?.getAuthInstance()
      .grantOfflineAccess()
      .then((code) => {
        getGoogleApiRefreshToken(code).then((res) => {
          if (res.data.success) {
            editUser({
              googleRefreshToken: res.data.tokens.refresh_token,
            }).then((response) => {
              if (response.data.success) {
                employeeForGoogle.googleRefreshToken =
                  res.data.tokens.refresh_token
                SharedStorage.setItem(
                  "employee",
                  JSON.stringify(employeeForGoogle)
                )
                gapi.client.setToken({
                  access_token: res.data.tokens.access_token,
                })
                gapi.client.load("calendar", "v3", listUpcomingEvents)
              }
            })
          }
        })
      })
  }
  const listUpcomingEvents = () => {
    var request = gapi.client.calendar.calendarList.list({})
    request.execute(function (resp) {
      if (!resp.code) {
        setIsGoogleSignedIn(true)
        let events = []
        for (var i = 0; i < resp.items.length; i++) {
          if (
            !resp.items[i].id.includes("addressbook") &&
            !resp.items[i].id.includes("holiday")
          ) {
            gapi.client.calendar.events
              .list({
                // Fetch events from user's calendars
                calendarId: resp.items[i].id,
                showDeleted: false,
                singleEvents: true,
                timeMin: timeMin,
                timeMax: timeMax,
              })
              .then(function (response) {
                events = [...response.result.items, ...events]
                if (events.length > 0) {
                  setGoogleEvents(formatEvents(events))
                }
              })
          }
        }
      } else {
        removeRefreshToken()
      }
    })
  }

  const formatEvents = (list) => {
    return list.map((item) => ({
      title: item.summary || item.title,
      start: item.start?.dateTime || item.start?.date,
      end: item.end?.dateTime || item.end?.date,
      extendedProps: { ...item, type: "googleCalendar" },
      backgroundColor: "#0F9D58",
      resourceId: employeeIdForGoogle,
    }))
  }

  return (
    <GoogleCalendarContext.Provider
      value={{
        googleEvents,
        isGoogleSignedIn,
        setGoogleEvents,
        setIsGoogleSignedIn,
        getEventsOnClick,
        disconnectGoogle,
        setViewGoogleEvent,
        isGoogleChecked,
        setIsGoogleChecked,
        setEmployeeForGoogle,
        setTimeMax,
        setTimeMin,
      }}
    >
      <BaseModal
        open={Boolean(viewGoogleEvent)}
        onClose={() => {
          setViewGoogleEvent(null)
        }}
        title={i18n.t("google_calendar")}
        content={
          <div className="row p-2">
            <div className={"form-label-group mb-3 col-12"}>
              <TextField
                id="dueDate"
                label={i18n.t("textfield.title")}
                value={viewGoogleEvent?.summary}
                inputProps={{
                  style: { color: "black", borderBottom: "1px solid black" },
                }}
                disabled
              />
            </div>
            <div className={"form-label-group mb-3 col-12"}>
              <TextField
                id="dueDate"
                label={i18n.t("start_time")}
                value={moment(viewGoogleEvent?.start.dateTime).format(
                  "MMMM Do YYYY h:mm a"
                )}
                inputProps={{
                  style: { color: "black", borderBottom: "1px solid black" },
                }}
                disabled
              />
            </div>
            <div className={"form-label-group mb-3 col-12"}>
              <TextField
                id="dueDate"
                label={i18n.t("end_time")}
                value={moment(viewGoogleEvent?.end.dateTime).format(
                  "MMMM Do YYYY h:mm a"
                )}
                inputProps={{
                  style: { color: "black", borderBottom: "1px solid black" },
                }}
                disabled
              />
            </div>
            <div className="row col-12">
              {i18n.t("description")}
              <textarea
                className="pt-1 mt-2 w-100 "
                value={
                  viewGoogleEvent?.description
                    ? viewGoogleEvent.description
                    : ""
                }
                style={{
                  border: "1px solid black",
                  backgroundColor: "white",
                  color: "black",
                }}
                disabled
              ></textarea>
            </div>
          </div>
        }
      />
      {children}
    </GoogleCalendarContext.Provider>
  )
}

//Hook
function useGoogleCalendar() {
  return useContext(GoogleCalendarContext)
}

function googleButton() {
  const { getEventsOnClick, isGoogleSignedIn, disconnectGoogle } =
    useGoogleCalendar()
  const { screenSize } = useScreenSize()
  return (
    <>
      {screenSize !== "mobile" ? (
        <GoogleButton
          label={isGoogleSignedIn ? <SyncDisabled /> : <Sync />}
          className="googleButton mt-1"
          onClick={() => {
            isGoogleSignedIn ? disconnectGoogle() : getEventsOnClick()
          }}
        />
      ) : (
        <PrimaryButton
          className="w-100"
          onClick={() => {
            isGoogleSignedIn ? disconnectGoogle() : getEventsOnClick()
          }}
        >
          <div className="d-flex gap-2 align-items-center">
            <span>{isGoogleSignedIn ? <SyncDisabled /> : <Sync />}</span>
            <div>
              {isGoogleSignedIn
                ? i18n.t("unsync_from_google")
                : i18n.t("sync_with_google")}
            </div>
          </div>
        </PrimaryButton>
      )}
    </>
  )
}

export default useGoogleCalendar
export { googleButton }
