import { connect } from "react-redux"
import React, { useEffect, useRef, useState } from "react"
import i18n from "../../i18n"
import BaseModal from "../BaseModal"
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator"
import {
  insertItemsRequest,
  removeItemsRequest,
  setActive,
  insertRequest,
  updateRequest,
} from "../../redux/actions/Packages"
import MenuItem from "@material-ui/core/MenuItem"
import PrimaryButton from "../Core/PrimaryButton"
import DatePicker from "react-datepicker"
import AppBar from "@material-ui/core/AppBar"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Checkbox from "@material-ui/core/Checkbox"
import { getNestedValue } from "../../util/objectMethods"
import moment from "moment"
import TabPanel from "../Core/Tabs/TabPanel"
import ToggleSelect from "../ToggleSelect"
import CounterInput from "../CounterInput"
import useScreenSize from "../../context/ScreenSize"
import { error } from "jquery"
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

function Packages({
  open,
  onClose,
  onSave,
  allServices = [],
  active = { status: "Active" },
  removeItemsRequest,
  insertItemsRequest,
  setActive,
}) {
  const form = useRef()
  const [data, setData] = useState({})
  const [itemMap, setItemMap] = useState({})
  const { screenSize } = useScreenSize()
  const [errors, setErrors] = useState({})
  const init = (data) => {
    const newItemMap = {}
    getNestedValue(data, "services", []).map((item) => {
      newItemMap[item.serviceId] = true
    })
    setData({ ...data })
    setItemMap(newItemMap)
    setTab(0)
  }
  useEffect(() => {
    init(active)
  }, [])
  useEffect(() => {
    if (active?._id != data?._id) {
      init(active)
    }
  }, [active])
  useEffect(() => {
    if (!open) {
      setData({})
    }
  }, [open])
  const handleChange = (key, value) => {
    data[key] = value
    setData({ ...data })
  }
  const handleSubmit = () => {
    validate("numberOfSessions", 1, data.numberOfSessions, "number_of_session")
    validate("price", 0, data.price, "price")
    if (Object.keys(errors).length === 0) {
      onSave(data)
    }
  }
  const [tab, setTab] = React.useState(-1)

  const handleTabChange = (event, newValue) => {
    setTab(newValue)
  }

  const toggleServiceSelection = (serviceId) => {
    const newItemMap = { ...itemMap }
    if (newItemMap[serviceId]) {
      delete newItemMap[serviceId]
    } else {
      newItemMap[serviceId] = true
    }
    setItemMap(newItemMap)
  }

  const onSaveItems = () => {
    const itemMapCopy = { ...itemMap }
    const toRemove = []
    getNestedValue(active, "services", []).map((active) => {
      if (!itemMapCopy[active.serviceId]) {
        toRemove.push(active._id)
      } else {
        delete itemMapCopy[active.serviceId]
      }
    })
    insertItemsRequest(
      {
        packageId: data._id,
        services: Object.keys(itemMapCopy),
      },
      {
        success: () => {
          removeItemsRequest(
            {
              packageId: data._id,
              services: toRemove,
            },
            {
              success: ({ data }) => {
                setActive(data)
              },
            }
          )
        },
      }
    )
  }

  const validate = (key, minValue, value, name) => {
    if (!value) {
      errors[key] = i18n.t(`${name}_is_required`)
    } else if (value < minValue) {
      errors[key] = i18n.t(`${name}_min_${minValue}`)
    } else {
      delete errors[key]
    }

    setErrors({ ...errors })
  }

  return (
    <div>
      {open ? (
        <BaseModal
          open={open}
          title={data._id ? i18n.t("edit_package") : i18n.t("add_package")}
          containerClassName="half-container"
          content={
            <>
              <AppBar position="static" className="custom-tab">
                <Tabs
                  value={tab}
                  onChange={handleTabChange}
                  aria-label="simple tabs example"
                >
                  <Tab label={i18n.t("details")} {...a11yProps(0)} />
                  <Tab
                    label={i18n.t("services")}
                    {...a11yProps(1)}
                    disabled={!data._id}
                  />
                </Tabs>
              </AppBar>
              <TabPanel value={tab} index={0}>
                <div className="d-flex">
                  <ValidatorForm
                    onSubmit={handleSubmit}
                    ref={form}
                    className="w-100"
                    onError={(error) => {
                      validate(
                        "numberOfSessions",
                        1,
                        data.numberOfSessions,
                        "number_of_session"
                      )
                      validate("price", 0, data.price, "price")
                    }}
                  >
                    <div className="d-sm-flex align-items-center mb-3">
                      <label
                        className="font-weight-bold mobile-w-100"
                        style={{ minWidth: "140px" }}
                      >
                        {i18n.t("name")}
                      </label>
                      <div style={{ width: "100%" }}>
                        <TextValidator
                          label={i18n.t("name")}
                          onChange={(e) => handleChange("name", e.target.value)}
                          value={data.name}
                          validators={["required"]}
                          errorMessages={[i18n.t("name_is_required")]}
                          variant="outlined"
                        />
                      </div>
                    </div>
                    <div className="d-sm-flex align-items-center mb-3">
                      <label
                        className="font-weight-bold mobile-w-100"
                        style={{ minWidth: "140px" }}
                      >
                        {i18n.t("description")}
                      </label>
                      <div style={{ width: "100%" }}>
                        <TextValidator
                          label={i18n.t("description")}
                          onChange={(e) =>
                            handleChange("description", e.target.value)
                          }
                          value={data.description}
                          validators={["required"]}
                          errorMessages={[i18n.t("description_is_required")]}
                          rows={2}
                          variant="outlined"
                        />
                      </div>
                    </div>
                    <div className="d-sm-flex align-items-center mb-3">
                      <label
                        className="font-weight-bold mobile-w-100"
                        style={{ minWidth: "140px" }}
                      >
                        {i18n.t("status")}
                      </label>
                      <ToggleSelect
                        value={data.status === "Active"}
                        onChange={(e) =>
                          handleChange("status", e ? "Active" : "Inactive")
                        }
                        active="Active"
                        inactive="Inactive"
                      />
                    </div>
                    <div className="d-sm-flex align-items-center mb-3">
                      <label
                        className="font-weight-bold mobile-w-100"
                        style={{ minWidth: "140px" }}
                      >
                        {i18n.t("expiry_date")}
                      </label>
                      <DatePicker
                        wrapperClassName="w-100"
                        selected={
                          data.expiryDate
                            ? moment(data.expiryDate).toDate()
                            : ""
                        }
                        onChange={(e) => handleChange("expiryDate", e)}
                        value={
                          data.expiryDate
                            ? moment(data.expiryDate).toDate()
                            : ""
                        }
                        customInput={
                          <TextValidator
                            label={i18n.t("expiry_date")}
                            onChange={(e) =>
                              handleChange("expiryDate", e.target.value)
                            }
                            value={data.expiryDate}
                            variant="outlined"
                          />
                        }
                        placeholderText={i18n.t("expiry_date")}
                      />
                    </div>
                    <div className="d-sm-flex align-items-center mb-3">
                      <label
                        className="font-weight-bold mobile-w-100"
                        style={{ minWidth: "140px" }}
                      >
                        {i18n.t("number_of_sessions")}
                      </label>
                      <CounterInput
                        value={data.numberOfSessions}
                        label={i18n.t("number_of_sessions")}
                        onChange={(e) => {
                          handleChange("numberOfSessions", e)
                          validate(
                            "numberOfSessions",
                            1,
                            e,
                            "number_of_session"
                          )
                        }}
                        error={errors?.numberOfSessions}
                        errorMessages={errors.numberOfSessions}
                      />
                    </div>
                    <div className="d-sm-flex align-items-center mb-3">
                      <label
                        className="font-weight-bold mobile-w-100"
                        style={{ minWidth: "140px" }}
                      >
                        {i18n.t("time_to_use")}
                      </label>
                      <div className="w-100">
                        <SelectValidator
                          label={i18n.t("time_to_use")}
                          onChange={(e) => {
                            handleChange("timeToUse", e.target?.value)
                          }}
                          value={data.timeToUse}
                          variant="outlined"
                          select
                        >
                          <MenuItem value="" disabled={true}>
                            {i18n.t("time_to_use")}
                          </MenuItem>
                          <MenuItem value={0}>{i18n.t("no_limit")}</MenuItem>
                          <MenuItem value={7}>{i18n.t("1_week")}</MenuItem>
                          <MenuItem value={14}>{i18n.t("2_week")}</MenuItem>
                          <MenuItem value={30}>{i18n.t("1_month")}</MenuItem>
                          <MenuItem value={90}>{i18n.t("3_months")}</MenuItem>
                          <MenuItem value={180}>{i18n.t("6_months")}</MenuItem>
                          <MenuItem value={270}>{i18n.t("9_months")}</MenuItem>
                          <MenuItem value={365}>{i18n.t("1_year")}</MenuItem>
                        </SelectValidator>
                      </div>
                    </div>
                    <div className="d-sm-flex align-items-center mb-3">
                      <label
                        className="font-weight-bold mobile-w-100"
                        style={{ minWidth: "140px" }}
                      >
                        {i18n.t("price")}
                      </label>
                      <CounterInput
                        value={data.price}
                        label={i18n.t("price")}
                        onChange={(e) => {
                          handleChange("price", e)
                          validate("price", 0, e, "price")
                        }}
                        error={errors?.price}
                        errorMessages={errors.price}
                      />
                    </div>
                    <div className="d-sm-flex align-items-center mb-3">
                      <label
                        className="font-weight-bold mobile-w-100"
                        style={{ minWidth: "140px" }}
                      >
                        {i18n.t("bar_code")}
                      </label>
                      <div style={{ width: "100%" }}>
                        <TextValidator
                          className="mb-1"
                          label={i18n.t("bar_code")}
                          onChange={(e) =>
                            handleChange("barCode", e.target.value)
                          }
                          value={data.barCode}
                          variant="outlined"
                        />
                      </div>
                    </div>
                    <div className="row mx-0 px-0 justify-content-end my-4">
                      <PrimaryButton
                        className="w-128"
                        props={{
                          type: "submit",
                        }}
                        label={data._id ? i18n.t("save") : i18n.t("create")}
                      />
                      {data._id ? (
                        <div className="ml-2">
                          <PrimaryButton
                            className="w-128"
                            label={i18n.t("add_services")}
                            onClick={() => {
                              setTab(1)
                            }}
                          />
                        </div>
                      ) : null}
                    </div>
                  </ValidatorForm>
                </div>
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns:
                      screenSize === "mobile" ? "1fr" : "1fr 1fr",
                  }}
                >
                  {allServices.map((service) => {
                    return (
                      <MenuItem
                        key={service._id}
                        onClick={() => toggleServiceSelection(service._id)}
                        disableGutters
                        className="p-0"
                      >
                        <div className="row mx-0 px-0"></div>
                        <Checkbox
                          checked={Boolean(itemMap?.[service._id])}
                          onChange={() => toggleServiceSelection(service._id)}
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                        {service.name} | $ {service.price ? service.price : 0}
                      </MenuItem>
                    )
                  })}
                </div>
                <div className="row my-3 mx-0 px-0 justify-content-end">
                  <PrimaryButton
                    className="w-128"
                    label={i18n.t("save")}
                    onClick={onSaveItems}
                  />
                </div>
              </TabPanel>
            </>
          }
          onClose={onClose}
        />
      ) : null}
    </div>
  )
}

const mapStateToProps = (state) => ({
  active: state.packages.active,
  loading: state.packages.isFetching,
})

const action = {
  removeItemsRequest,
  insertItemsRequest,
  setActive,
  insertRequest,
  updateRequest,
}

export default connect(mapStateToProps, action)(Packages)
