import React, { useState, useEffect } from "react"
import { WorkingDay } from "./employeeSection.styles"
import TextField from "@material-ui/core/TextField"
import i18n from "../../../i18n"

export default function WorkingHour({
  daysOfWeek,
  day,
  readonly,
  handleWorkingHours,
}) {
  const [error, setError] = useState("")

  const handleInput = (e, value) => {
    const { name } = e.target
    let newWorkingHours = { ...day, [name]: value }
    if (name == "availableStatus") {
      if (!value) {
        newWorkingHours = {
          ...newWorkingHours,
          checkIn: "",
          checkOut: "",
        }
      } else if (day.availableStatus == 0) {
        newWorkingHours = {
          ...newWorkingHours,
          checkIn: "09:00",
          checkOut: "17:00",
        }
      }
    }
    const error = validateInput(newWorkingHours)
    if (error) {
      setError(i18n.t("schedule_time_error"))
    } else {
      setError("")
    }
    handleWorkingHours({
      ...newWorkingHours,
      error,
    })
  }
  const validateInput = (newWorkingShift) => {
    if (
      newWorkingShift.checkIn &&
      newWorkingShift.checkOut &&
      newWorkingShift.checkIn >= newWorkingShift.checkOut
    ) {
      return true
    }
  }

  return (
    day && (
      <>
        <WorkingDay>
          <div className="font-weight-bold">{daysOfWeek[day.dayOfWeek]}</div>
          <div className="d-flex justify-content-center">
            <input
              style={{ transform: "scale(1.8)" }}
              type="checkbox"
              checked={day.availableStatus}
              onChange={(e) => handleInput(e, !day.availableStatus ? 1 : 0)}
              name="availableStatus"
              disabled={readonly}
            />
          </div>

          <TextField
            value={day.checkIn}
            type="time"
            name="checkIn"
            onChange={(e) => handleInput(e, e.target.value)}
            disabled={readonly}
            error={error}
            helperText={error}
            variant="outlined"
            style={{ backgroundColor: "white" }}
          />
          <TextField
            value={day.checkOut}
            type="time"
            onChange={(e) => handleInput(e, e.target.value)}
            name="checkOut"
            disabled={readonly}
            error={error}
            helperText={error}
            variant="outlined"
            style={{ backgroundColor: "white" }}
          />
        </WorkingDay>
      </>
    )
  )
}
