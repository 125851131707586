import { connect } from "react-redux"
import React, { Component } from "react"
import i18n from "../../../i18n"
import {
  getRequest,
  removeRequest,
  updateRequest,
  insertRequest,
  setDefaultRequest,
} from "../../../redux/actions/CompanyCard"
import { getRequest as getEasy1Products } from "../../../redux/actions/Saloon/Easy1Products"
import { getRequest as getSubscriptions } from "../../../redux/actions/Saloon/Subscription"
import { DotsLoader } from "../../../components"
import CardManagment from "../../../components/Card/CardManagment"
import SubscriptionManagement from "../../../components/SubscriptionManagement"
import AppBar from "@material-ui/core/AppBar"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import { ErrorHelper } from "../../../helpers"
import SharedStorage from "../../../helpers/Storage"
import PrimaryButton from "../../../components/Core/PrimaryButton"
// import Sytles from "./sytles";
class AccountSettings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: 0,
      accessCode: "",
      showModal: false,
    }
  }

  componentDidMount = async () => {
    const saloonData = await JSON.parse(await SharedStorage.getItem("saloon"))
    this.setState(
      {
        email: saloonData.email,
        phoneNo: saloonData.phoneNo,
      },
      () => {
        this.retrieveData()
      }
    )
  }

  retrieveData = () => {
    this.setState({ isloading: true })
    Promise.all([
      new Promise((success, failure) => {
        this.props.getEasy1Products({}, { success, failure })
      }),
      new Promise((success, failure) => {
        this.props.getSubscriptions({}, { success, failure })
      }),
      new Promise((success, failure) => {
        this.props.getRequest({}, { success, failure })
      }),
    ])
      .then(() => {
        this.setState({ isloading: false })
      })
      .catch((err) => {
        this.setState({ isloading: false })
        ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true)
      })
  }

  renderLoader = () => {
    const { isloading } = this.state
    return <DotsLoader isloading={isloading} />
  }

  a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    }
  }

  handleClosePopup = () => {
    this.setState({ showUnauthorizedModal: false })
  }

  renderSubscriptionTab = (value, index) => {
    const { productMap, subscription, history } = this.props
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        className="bg-white"
      >
        {value === index && (
          <SubscriptionManagement
            subscriptions={subscription}
            history={history}
            productMap={productMap}
            showAddModal={this.state.showModal}
            setShowAddModal={(val) => this.setState({ showModal: val })}
          />
        )}
      </div>
    )
  }

  renderCardTab = (value, index) => {
    const {
      getRequest,
      removeRequest,
      updateRequest,
      insertRequest,
      defaultCard,
      cards,
      setDefaultRequest,
    } = this.props
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        className="bg-white"
      >
        {value === index && (
          <CardManagment
            removeDefault={false}
            defaultCard={defaultCard}
            cards={cards}
            getRequest={getRequest}
            removeRequest={removeRequest}
            updateRequest={updateRequest}
            insertRequest={insertRequest}
            setDefaultRequest={setDefaultRequest}
            showAddModal={this.state.showModal}
            setShowAddModal={(val) => this.setState({ showModal: val })}
          />
        )}
      </div>
    )
  }

  render() {
    const { activeTab } = this.state
    const { history } = this.props
    return (
      <div>
        {this.renderLoader()}
        <div className="content-container p-4">
          <div className="row gap-2 mx-0">
            <div className="col-12 d-flex flex-column flex-md-row align-items-center justify-content-between">
              <span className="saloon-dashboard-heading pt-0 mb-4">
                {i18n.t("container.account_settings")}
              </span>
              <div className="row mx-0 justify-content-end">
                <PrimaryButton
                  onClick={() => {
                    activeTab === 0
                      ? this.setState({ showModal: true })
                      : history.push("saloon-saloon-pricing-plains")
                  }}
                  className="w-128"
                >
                  {activeTab === 0 ? i18n.t("add") : i18n.t("upgrade")}
                </PrimaryButton>
              </div>
            </div>
            <span className="w-100">
              {/* Will be required when we add more to this */}

              <AppBar className="custom-tab" position="static">
                <Tabs
                  value={activeTab}
                  onChange={(ev, activeTab) => this.setState({ activeTab })}
                  aria-label="simple tabs example"
                >
                  <Tab label={i18n.t("cards")} {...this.a11yProps(0)} />
                  <Tab label={i18n.t("subscriptions")} {...this.a11yProps(1)} />
                </Tabs>
              </AppBar>
              {this.renderCardTab(activeTab, 0)}
              {this.renderSubscriptionTab(activeTab, 1)}
            </span>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const products = state.easy1Products.data
  const productMap = {}
  Object.values(products).map((group) =>
    group.map((product) => {
      productMap[product.id] = product
    })
  )
  return {
    cards: state.companyCard.data,
    defaultCard: state.companyCard.defaultCard,
    subscription: state.subscription.data,
    productMap,
  }
}

const action = {
  getRequest,
  removeRequest,
  updateRequest,
  insertRequest,
  getSubscriptions,
  getEasy1Products,
  setDefaultRequest,
}

export default connect(mapStateToProps, action)(AccountSettings)
