import React, { useState, useRef, useEffect } from "react"
import useCache from "../../LocalCache"
import { BaseModal } from "../../../components"
import i18n from "../../../i18n"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import PrimaryButton from "../../../components/Core/PrimaryButton"
import { Autocomplete } from "@material-ui/lab"
import moment from "moment"
import ReactDatePicker from "react-datepicker"
import { createStaffMeeting } from "../../../config/simpleApiCalls"
import { ErrorHelper, SuccessHelper } from "../../../helpers"
import useScreenSize from "../../ScreenSize"
const maxDate = moment(moment.now()).toDate()
export default function CreateStaffMeeting({
  addOpen,
  setLoading,
  setAddOpen,
  get,
}) {
  const form = useRef()
  const { getResource } = useCache()
  const [data, setData] = useState({})
  const { screenSize } = useScreenSize()
  useEffect(() => {
    setData({})
  }, [addOpen])
  const employees = getResource((cache) => cache.company.employees).filter(
    ({ isActive }) => isActive
  )

  return (
    <BaseModal
      open={addOpen}
      onClose={() => {
        setAddOpen(false)
      }}
      title={i18n.t("staff_meeting")}
      containerClassName="half-container"
      showCloseBtn={screenSize !== "mobile" ? true : false}
      content={
        <div className="row mx-0">
          <ValidatorForm
            onSubmit={async () => {
              const payload = {
                ...data,
                employees: data.employees.map(
                  (employee) => employee.employee._id
                ),
              }
              setLoading(true)
              createStaffMeeting(payload)
                .then((data) => {
                  SuccessHelper.handleSuccess(
                    i18n.t("successfully_added"),
                    true
                  )
                  get()
                  setAddOpen(false)
                })
                .catch((e) => {
                  ErrorHelper.handleErrors(i18n.t("failed_to_add"), true)
                })
                .finally(() => {
                  setLoading(false)
                })
            }}
            ref={form}
            className="row col-12 mx-0 px-0"
            onError={(error) => {
              console.log(error)
            }}
          >
            <div className="d-sm-flex col-12 px-0 pb-4 align-items-center inline-custom-form inline-custom-form1">
              <label className="font-weight-bold">{i18n.t("date")}</label>
              <div className="w-100">
                <ReactDatePicker
                  wrapperClassName="col-12 w-100 mb-1 px-0"
                  selected={data.startDate}
                  dateFormat="MM/dd/yyyy"
                  onChange={(val) => {
                    setData({ ...data, startDate: val })
                  }}
                  minDate={maxDate}
                  customInput={
                    <TextValidator
                      label={i18n.t("date")}
                      value={data.startDate || ""}
                      validators={["required"]}
                      errorMessages={[i18n.t("this_field_is_required")]}
                      readOnly={true}
                      variant="outlined"
                    />
                  }
                />
              </div>
            </div>
            <div className="d-sm-flex col-12 px-0 pb-4 align-items-center inline-custom-form inline-custom-form1">
              <label className="font-weight-bold">{i18n.t("time")}</label>
              <div className="w-100">
                <TextValidator
                  onChange={(e) =>
                    setData({ ...data, startTime: e.target.value })
                  }
                  value={data.startTime || ""}
                  validators={["required"]}
                  errorMessages={[i18n.t("this_field_is_required")]}
                  type="Time"
                  variant="outlined"
                />
              </div>
            </div>
            <div className="d-sm-flex col-12 px-0 pb-4 align-items-center inline-custom-form inline-custom-form1">
              <label className="font-weight-bold">{i18n.t("employees")}</label>
              <div className="w-100">
                <Autocomplete
                  multiple
                  options={employees}
                  getOptionLabel={(option) =>
                    option.employeeId.userId.firstName
                  }
                  value={data.employees || []}
                  onChange={(e, value = []) => {
                    setData({ ...data, employees: value })
                  }}
                  disableCloseOnSelect={true}
                  renderInput={(params) => {
                    return (
                      <TextValidator
                        {...params}
                        value={data.employees}
                        label={i18n.t("employees")}
                        validators={["required", "hasArrayElements"]}
                        errorMessages={[
                          i18n.t("this_field_is_required"),
                          i18n.t("select_atleast_1_item"),
                        ]}
                        variant="outlined"
                      />
                    )
                  }}
                />
              </div>
            </div>
            <div className="d-sm-flex col-12 px-0 pb-4 align-items-center inline-custom-form inline-custom-form1">
              <label className="font-weight-bold">
                {i18n.t("duration_in_minutes")}
              </label>
              <div className="w-100">
                <TextValidator
                  onChange={(e) =>
                    setData({ ...data, duration: e.target.value })
                  }
                  onBlur={(e) => {
                    const x = parseInt(e.target.value)
                    if (isNaN(x) || x < 0) {
                      setData({ ...data, duration: 0 })
                    } else {
                      setData({ ...data, duration: Math.ceil(x / 5) * 5 })
                    }
                  }}
                  value={data.duration || ""}
                  validators={["required"]}
                  errorMessages={[i18n.t("this_field_is_required")]}
                  type="number"
                  variant="outlined"
                />
              </div>
            </div>
            <div className="d-sm-flex col-12 px-0 pb-4 align-items-center inline-custom-form inline-custom-form1">
              <label className="font-weight-bold">
                {i18n.t("description")}
              </label>
              <div className="w-100">
                <TextValidator
                  label={i18n.t("description")}
                  onChange={(e) =>
                    setData({ ...data, description: e.target.value })
                  }
                  value={data.description || ""}
                  validators={["required"]}
                  errorMessages={[i18n.t("this_field_is_required")]}
                  variant="outlined"
                />
              </div>
            </div>

            <div className="row mx-0 px-0 justify-content-center gap-2 justify-content-md-end my-4 col-12">
              {screenSize === "mobile" && (
                <PrimaryButton
                  className="w-128"
                  label={i18n.t("close")}
                  onClick={() => setAddOpen(false)}
                />
              )}

              <PrimaryButton
                className="w-128"
                props={{
                  type: "submit",
                }}
                label={i18n.t("create")}
              />
            </div>
          </ValidatorForm>
        </div>
      }
    />
  )
}
