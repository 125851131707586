import React, { useState, useEffect } from "react"
import { ServicesWrapper } from "./employeeSection.styles"
import PrimaryButton from "../../../components/Core/PrimaryButton/index"
import i18n from "../../../i18n"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"
import useCache from "../../../context/LocalCache"

export default function EmployeeServices({ employee, handleSave }) {
  const { getResource } = useCache()
  const allServices = getResource((cache) => cache.company.services)
  const [services, setServices] = useState(null)
  useEffect(() => {
    if (employee) {
      let activeServices = allServices.filter(
        (service) => service.isActive === 1 && service
      )
      let employeeServiceCategories = employee.employee.services.map(
        (serviceCategory) => {
          return serviceCategory.serviceId
        }
      )
      let employeeAvailableServices = activeServices.filter((service) => {
        return employeeServiceCategories.includes(service.serviceId["_id"])
      })

      let employeeCompanyServices = []
      employeeAvailableServices.forEach((service) => {
        let newService = {}
        let res = employee.companyServices.find((ser) => {
          return ser.serviceId === service["_id"]
        })
        if (res !== undefined) {
          newService = { ...res }
          newService.available = true
          newService.service = service
        } else {
          newService.serviceId = service["_id"]
          newService.service = service
          newService.price = service.price
          newService.available = false
        }
        employeeCompanyServices.push(newService)
      })
      setServices(employeeCompanyServices)
    }
  }, [employee, allServices])

  const handleFormChanges = (e, checked) => {
    if (checked !== undefined) {
      let newServices = [...services]
      newServices.find((s) => s.serviceId === e).available = checked
      setServices(newServices)
    }
  }

  const updateEmployeeServices = () => {
    const companyServices = services
      .filter((service) => service.available)
      .map(({ serviceId, price }) => ({ serviceId, price }))
    handleSave({ companyServices })
  }

  const handleSelectAll = () => {
    setServices(services.map((service) => ({ ...service, available: 1 })))
  }

  const handleUnSelectAll = () => {
    setServices(services.map((service) => ({ ...service, available: 0 })))
  }

  return (
    <>
      <div className="row justify-content-center justify-content-md-start mb-3 gap-2">
        <PrimaryButton
          className="w-128 "
          label={i18n.t("select_all")}
          onClick={handleSelectAll}
        />
        <PrimaryButton
          className="w-128"
          label={i18n.t("unselect_all")}
          onClick={handleUnSelectAll}
        />
      </div>
      <ServicesWrapper>
        {services &&
          services.map((service) => {
            return (
              <FormControlLabel
                control={
                  <Switch
                    value={service.available}
                    checked={service.available}
                    type="checkbox"
                    onChange={(e) =>
                      handleFormChanges(service.serviceId, !service.available)
                    }
                    color="primary"
                  />
                }
                label={service.service.name}
              />
            )
          })}
      </ServicesWrapper>
      <div className="row justify-content-center justify-content-md-end my-3 mx-0">
        <PrimaryButton
          className="w-128"
          onClick={updateEmployeeServices}
          label="update services"
        />
      </div>
    </>
  )
}
