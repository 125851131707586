import crudBuilder from "../../../builders/CrudBuilder/CrudBuilder"
import actions from "../../../redux/actions/Saloon/CustomerReference"
const { upsertRequest, getRequest, removeRequest } = actions

const CustomerReference = crudBuilder.build({
  type: "customerReference",
  title: "customer_references",
  actionMap: {
    upsert: upsertRequest,
    get: getRequest,
    remove: removeRequest,
  },
})

export default CustomerReference
