import React, { useEffect, useRef, useState } from "react"
import {
  SelectValidator,
  ValidatorForm,
} from "react-material-ui-form-validator"
import MenuItem from "@material-ui/core/MenuItem"
import i18n from "../../../../i18n"
import { useSelector } from "react-redux"
import { BaseModal } from "../../../../components"
import PrimaryButton from "../../../../components/Core/PrimaryButton"

export default function ApplyTemplate({ open, setOpen, onApply }) {
  const form = useRef()
  const [selectedTemplate, setSelectedTemplate] = useState()

  const templates = useSelector((s) => s.activityScheduleTemplate.data)

  useEffect(() => {
    if (open) {
      setSelectedTemplate()
    }
  }, [open])

  const handleConfirm = () => {
    onApply(selectedTemplate)
  }

  return (
    <BaseModal
      open={open}
      style={{ zIndex: 1304 }}
      title={i18n.t("apply_template")}
      onClose={() => setOpen(false)}
      containerClassName={"half-container"}
      content={
        <ValidatorForm
          onSubmit={handleConfirm}
          ref={form}
          className="row col-12 px-0 mx-0"
          onError={(error) => {
            console.log(error)
          }}
        >
          <div className="col-12 d-flex align-items-center mb-3 px-0">
            <label className="font-weight-bold mr-3">
              {i18n.t("templates")}
            </label>
            <SelectValidator
              containerProps={{ className: "w-100" }}
              label={i18n.t("templates")}
              onChange={(e) => {
                setSelectedTemplate(e.target.value)
              }}
              value={selectedTemplate || ""}
              select
              validators={["required"]}
              errorMessages={[i18n.t("this_field_is_required")]}
              variant="outlined"
            >
              {templates.map((template) => {
                return (
                  <MenuItem key={template._id} value={template._id}>
                    {template.name}
                  </MenuItem>
                )
              })}
            </SelectValidator>
          </div>

          <div className="row mx-0 px-0 justify-content-end mt-4 col-12">
            <PrimaryButton
              className="w-128"
              props={{
                type: "submit",
              }}
              label={i18n.t("apply")}
            />
          </div>
        </ValidatorForm>
      }
    />
  )
}
