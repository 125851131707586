import React, { Component } from "react"
import Pagination from "react-js-pagination"
import i18n from "../../../i18n"
import {
  getEmployeeCustomers,
  getCustomerById,
  getCompanyThatEmployeeAdd,
  getCustomerBookingHistory,
} from "../../../config/simpleApiCalls"
import { Images } from "../../../theme"
import { DotsLoader } from "../../../components"
import NumberFormat from "react-number-format"
import TextField from "@material-ui/core/TextField"
import { ErrorHelper } from "../../../helpers"
import { getNestedValue } from "../../../util/objectMethods"
import Avatar from "react-avatar"
import moment from "moment"

import "./styles.css"
import Sytles from "./sytles"
import EditIcon from "../../../components/Core/BasicTable/Actions/EditIcon.jsx"
import CustomerEdit from "../../../components/Saloon/Customer/CustomerEdit"
import SearchIcon from "@material-ui/icons/Search"
import Tabs from "../../../components/Core/Tabs/Tabs"
class AllCustomers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: null,
      isloading: false,
      showEditDelete: "",
      searchText: "",
      activePage: 1,
      usersList: null,
      selectedEmployeeId: null,
      formErrors: {},
      tabIndex: 0,
      bookingStauses: [
        { text: "Waiting", value: 1, styles: { color: "#aa43b5" } },
        { text: "Now Serving", value: 3, styles: { color: "#FFE478" } },
        { text: "Cancel", value: 2, styles: { color: "#FF7082" } },
        { text: "Done", value: 4, styles: { color: "#95DA47" } },
      ],
      filterUsersList: undefined,
      allSaloonMap: {},
    }
  }

  componentDidMount = async () => {
    this.setState({
      isloading: true,
    })
    this.getEmployeeCustomers()
  }

  getEmployeeCustomers = () => {
    getEmployeeCustomers().then((res) => {
      this.setState({
        usersList: res,
        activeUsers: res.filter((user) => user.isActive),
        archivedUsers: res.filter((user) => !user.isActive),
        isloading: false,
      })
    })
  }

  onChangeSearchText = (text) => {
    const { tabIndex, archivedUsers, activeUsers } = this.state
    let searchText = text.toUpperCase()

    const users = tabIndex === 0 ? activeUsers : archivedUsers
    let filterUsersList =
      users &&
      (searchText
        ? users.filter((val) => {
            let name = (val.name || "").toUpperCase()
            let email = (val.email || "").toUpperCase()
            let phoneNo = `${val.phoneNo}`
            return (
              name.indexOf(searchText) > -1 ||
              phoneNo.indexOf(searchText) > -1 ||
              email.indexOf(searchText) > -1
            )
          })
        : undefined)
    this.setState({
      filterUsersList,
      searchText: text,
      activePage: 1,
    })
  }

  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber })
  }

  renderLoader = () => {
    const { isloading } = this.state
    return <DotsLoader isloading={isloading} />
  }

  renderSearchBar = () => {
    const { searchText } = this.state
    return (
      <div className="my-4 row justify-content-between align-items-center w-100">
        <div className="col-12 col-lg-4 col-md-4 ">
          <span>
            <span className="saloon-dashboard-heading">Customers</span>
          </span>
        </div>
        <div className="mt-3 mt-md-0 col-12 col-lg-8 col-md-8 justify-content-end row">
          <TextField
            type="text"
            placeholder="Search by name, phone and email"
            onChange={(text) => this.onChangeSearchText(text.target.value)}
            value={searchText}
            InputProps={{
              startAdornment: (
                <SearchIcon style={{ color: "#b1b1b1" }} className="mr-1" />
              ),
            }}
          />
        </div>
      </div>
    )
  }

  renderTableBody = (usersList) => {
    const { activePage, filterUsersList } = this.state
    let previousPageNumber = activePage - 1
    const list = filterUsersList ? filterUsersList : usersList
    let startDay = null
    let endDay = null
    let currentWorkingHr = { checkIn: "", checkOut: "" }
    return (
      <tbody>
        {list &&
          list.map((val, ind) => {
            if (ind >= previousPageNumber * 10 && ind < activePage * 10) {
              return (
                <tr key={val._id} style={{ borderTop: "3px solid #F7F7F7" }}>
                  <td>
                    <div className="d-flex align-items-center">
                      {val && (
                        <span className="appointment-details-employee-other">
                          {`${val.firstName || ""} ${val.name}`.trim()}
                        </span>
                      )}
                    </div>
                  </td>
                  <td className="Table_data ">
                    <div className="d-flex align-items-center mt-1">
                      {val && (
                        <span className="appointment-details-employee-other">
                          <NumberFormat
                            value={val.phoneNo}
                            displayType={"text"}
                            format="### ### #### ####"
                          />
                          <div>{val.email}</div>
                        </span>
                      )}
                    </div>
                  </td>
                  <td style={{ width: "45px" }}>
                    <div className="row justify-content-center mx-0">
                      {val.isActive ? (
                        <EditIcon
                          onClick={() => {
                            getCustomerById({ _id: val._id })
                              .then(({ data }) => {
                                this.openEditCustomerCard(data.data, ind)
                              })
                              .catch(() => {
                                ErrorHelper.handleErrors(
                                  i18n.t("something_went_wrong"),
                                  true
                                )
                              })
                          }}
                        />
                      ) : null}
                    </div>
                  </td>
                </tr>
              )
            }
          })}
      </tbody>
    )
  }

  openEditCustomerCard = (val, index, readOnly = false) => {
    this.setState({
      showEditCustomerCard: true,
      showBookingHistory: false,
      active: val,
      index,
      readOnly,
    })
  }

  renderEditCustomerCard = () => {
    const { showEditCustomerCard, active, usersList, index } = this.state
    return (
      <CustomerEdit
        active={active}
        open={showEditCustomerCard}
        setOpen={() => this.setState({ showEditCustomerCard: false })}
        onUpdate={() => {
          this.getEmployeeCustomers()
        }}
        setAccountBalance={(accountBalance) => {
          usersList[index] = { ...usersList[index], accountBalance }
          this.setState({ usersList })
        }}
        allCustomers={usersList}
      />
    )
  }

  closeEditCustomerCard = () => {
    this.setState({
      showEditCustomerCard: false,
      index: undefined,
    })
  }

  renderUserTable = (users) => {
    return (
      <div className="col-12 p-3">
        <div className="table-responsive">
          <table className="table table-borderless appointment-details-table">
            <thead>
              <tr>
                <th scope="col">
                  <span className="appointment-details-th-text">Name</span>
                </th>
                <th scope="col">
                  <span className="appointment-details-th-text">
                    {i18n.t("details")}
                  </span>
                </th>
                <th scope="col">
                  <span className="appointment-details-th-text">Actions</span>
                </th>
              </tr>
            </thead>
            {this.renderTableBody(users)}
          </table>
        </div>
      </div>
    )
  }

  renderPagination = (tabIndex) => {
    const { searchText, filterUsersList, activeUsers, archivedUsers } =
      this.state
    const users = tabIndex === 0 ? activeUsers : archivedUsers
    return (
      <div className="col-12 py-2 mb-3 d-flex justify-content-center">
        <Pagination
          hideNavigation
          activePage={this.state.activePage}
          itemsCountPerPage={10}
          totalItemsCount={
            searchText ? filterUsersList.length : users?.length || 0
          }
          // pageRangeDisplayed={3}
          onChange={(val) => this.handlePageChange(val)}
          firstPageText={
            <img src={Images.pagination_back} style={{ width: 15 }} />
          }
          lastPageText={
            <img src={Images.pagination_forward} style={{ width: 15 }} />
          }
          itemClass="page-item"
          linkClass="page-link"
        />
      </div>
    )
  }

  render() {
    const { searchText, tabIndex, activeUsers, archivedUsers } = this.state

    const tabs = [
      {
        title: i18n.t("Active"),
        content: () => <> {this.renderUserTable(activeUsers)}</>,
      },
      {
        title: i18n.t("Inactive"),
        content: () => <> {this.renderUserTable(archivedUsers)}</>,
      },
    ]
    return (
      <div>
        {this.renderLoader()}
        <div className="p-3 mb-5 mb-md-0">
          <div className="row mx-0">
            {this.renderSearchBar()}
            <Tabs
              className
              tabs={tabs}
              activeTab={(value) => {
                this.setState({ tabIndex: value }, () => {
                  this.onChangeSearchText(searchText)
                })
              }}
            />
            {this.renderPagination(tabIndex)}
            {this.renderEditCustomerCard()}
          </div>
        </div>
      </div>
    )
  }
}

export default AllCustomers
