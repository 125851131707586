import { connect } from "react-redux"
import React, { Component } from "react"

import { DotsLoader, Core } from "../../../components"
import { SuccessHelper, ErrorHelper } from "../../../helpers"

import i18n from "../../../i18n"
import {
  updateRefundPolicy,
  getSaloonById,
} from "../../../config/simpleApiCalls"
import TextField from "@material-ui/core/TextField"
import "./styles.css"

import styled from "styled-components"
const { Select, PrimaryButton } = Core

const TextArea = styled(TextField)`
  .MuiInputBase-root {
    padding: 12px 14px;
    width: 50%;
  }
  .MuiInputBase-root legend {
    display: none;
  }
  @media (max-width: 576px) {
    .MuiInputBase-root {
      width: 100%;
    }
  }
`
class RefundPolicy extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      initialized: false,
      fee: 1,
    }
  }

  async componentDidMount() {
    this.setState({ isLoading: true })
    Promise.all([
      getSaloonById()
        .then(({ data }) => {
          const { refundPolicy, name, noShowFeePercentage } = data.data
          this.setState({
            noShowFeePercentage,
            refundPolicy,
            companyName: name,
          })
        })
        .catch(() => {
          this.setState({ isLoading: false })
        }),
    ]).then(() => {
      this.setState({ isLoading: false, initialized: true })
    })
    this.increaseFee()
    this.decreaseFee()
  }

  increaseFee() {
    this.setState({ noShowFeePercentage: this.state.noShowFeePercentage + 1 })
  }

  decreaseFee() {
    this.setState({ noShowFeePercentage: this.state.noShowFeePercentage - 1 })
  }

  renderLoader = () => {
    const { isLoading } = this.state
    return <DotsLoader isloading={isLoading} />
  }

  handleSettingsSave = async () => {
    const { refundPolicy, noShowFeePercentage } = this.state
    this.setState({ isLoading: true })
    try {
      updateRefundPolicy({
        refundPolicy,
        noShowFeePercentage,
      })
        .then(({ data }) => {
          if (data.success) {
            SuccessHelper.handleSuccess(i18n.t("successfully_updated"), true)
          }
        })
        .finally(() => {
          this.setState({ isLoading: false })
        })
    } catch (e) {
      ErrorHelper.handleErrors(e, true)
      this.setState({ isLoading: false })
    }
  }

  FeePercentForm = () => {
    return (
      <div className="d-flex justify-content-center justify-content-md-start align-items-center mt-3">
        <label className="font-weight-bold">
          {i18n.t("no_show_percent_fee")}
        </label>
        <div className="d-flex align-items-center ml-3">
          <PrimaryButton
            label={"-"}
            style={{
              color: "var(--white-color)",
              width: "34px",
              height: "34px",
              minWidth: "auto",
            }}
            className="refund-btn"
            onClick={() => this.decreaseFee()}
          />
          <TextField
            value={this.state.noShowFeePercentage}
            onChange={(e) =>
              this.setState({ noShowFeePercentage: e.target.value })
            }
            className="refund-input"
            variant="outlined"
            style={{
              width: "100px",
            }}
          />
          <PrimaryButton
            label={"+"}
            style={{
              color: "var(--white-color)",
              width: "34px",
              height: "34px",
              minWidth: "auto",
            }}
            className="refund-btn"
            onClick={() => this.increaseFee()}
          />
        </div>
      </div>
    )
  }

  render() {
    const { refundPolicy, initialized, noShowFeePercentage } = this.state

    return (
      <div className="content-container">
        {this.renderLoader()}
        <div className="p-3">
          <div>
            <h1 className="saloon-dashboard-heading pb-3">
              {i18n.t("refund_policy")}
            </h1>
          </div>

          {initialized && (
            <div>
              <div className="row">
                <div className="col-12 p-0 mt-3">
                  <TextArea
                    value={refundPolicy}
                    onChange={(e) => {
                      this.setState({ refundPolicy: e.target.value })
                    }}
                    multiline
                    rows={5}
                    variant="outlined"
                  />
                </div>
                <div className="col-12 p-0 mt-3">{this.FeePercentForm()}</div>
              </div>
              <div className="row justify-content-center justify-content-md-start mx-0 mt-4">
                <PrimaryButton
                  className="w-128"
                  label={i18n.t("save")}
                  onClick={this.handleSettingsSave}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

const action = {}

export default connect(mapStateToProps, action)(RefundPolicy)
