import { connect } from "react-redux"
import React, { Component } from "react"

import i18n from "../../../i18n"
import { DotsLoader } from "../../../components"
import {
  createSaloonSchedule,
  editSaloonSchedule,
  getSaloonSchedule,
} from "../../../config/simpleApiCalls"
import { SuccessHelper, ErrorHelper } from "../../../helpers"
import EditEmployeeWorkingHours from "../EmployeeSection/EditEmployeeWorkingHours"
import "./styles.css"
import { ScreenSizeContext } from "../../../context/ScreenSize"

const weekNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednessday",
  "Thursday",
  "Friday",
  "Saturday",
]
class AddWorkingHours extends Component {
  constructor(props) {
    super(props)
    this.state = {
      employeesWorkingHoursData: [
        { dayName: "Sunday", dayOfWeek: 1, availableStatus: 0 },
        { dayName: "Monday", dayOfWeek: 2, availableStatus: 0 },
        { dayName: "Tuesday", dayOfWeek: 3, availableStatus: 0 },
        { dayName: "Wednesday", dayOfWeek: 4, availableStatus: 0 },
        { dayName: "Thursday", dayOfWeek: 5, availableStatus: 0 },
        { dayName: "Friday", dayOfWeek: 6, availableStatus: 0 },
        { dayName: "Saturday", dayOfWeek: 7, availableStatus: 0 },
      ],
      isVerified: false,
      employeesWorkingHoursError: false,
      hasSchedule: false,
      employee: {},
    }
  }

  async componentDidMount() {
    this.handleGetSaloonSchedule()
  }

  onChangeCheckbox = (e, ind) => {
    const { employeesWorkingHoursData } = this.state
    if (e.target.checked) {
      employeesWorkingHoursData[ind].availableStatus = 1
      this.setState({ employeesWorkingHoursData })
    } else {
      employeesWorkingHoursData[ind].availableStatus = 0
      this.setState({ employeesWorkingHoursData })
    }
  }

  onChangeCheckIn = (text, ind) => {
    const { employeesWorkingHoursData } = this.state
    if (text.target.value) {
      employeesWorkingHoursData[ind].checkIn = text.target.value
    }
    this.setState({ employeesWorkingHoursData })
  }

  onChangeCheckOut = (text, ind) => {
    const { employeesWorkingHoursData } = this.state
    if (text.target.value) {
      employeesWorkingHoursData[ind].checkOut = text.target.value
    }
    this.setState({ employeesWorkingHoursData })
  }

  checkValidation = () => {
    let { employeesWorkingHoursData, isVerified, hasSchedule } = this.state
    for (let i = 0; i < employeesWorkingHoursData.length; i++) {
      if (employeesWorkingHoursData[i].availableStatus === 1) {
        if (!employeesWorkingHoursData[i].checkIn) {
          isVerified = false
          this.setState({
            isVerified,
            employeesWorkingHoursError: true,
          })
        } else if (!employeesWorkingHoursData[i].checkOut) {
          isVerified = false
          this.setState({
            isVerified: false,
            employeesWorkingHoursError: true,
          })
        } else {
          isVerified = true
          this.setState({
            isVerified,
            employeesWorkingHoursError: false,
          })
        }
      }
    }
    if (isVerified && !hasSchedule) {
      this.handleCreateSaloonSchedule()
    } else if (isVerified && hasSchedule) {
      this.handleEditSaloonSchedule()
    }
  }

  handleGetSaloonSchedule = () => {
    this.setState({ isloading: true })
    getSaloonSchedule()
      .then((res) => {
        if (res.data.success && res.data.data) {
          for (let i = 0; i < res.data.data.weekPlans.length; i++) {
            res.data.data.weekPlans[i].dayName = weekNames[i]
          }
          this.setState({
            isloading: false,
            hasSchedule: true,
            employeesWorkingHoursData: res.data.data.weekPlans,
            employee: res.data.data,
          })
        } else {
          this.setState({ isloading: false })
        }
      })
      .catch((error) => {
        console.log(error, "error")
      })
  }

  handleCreateSaloonSchedule = () => {
    this.setState({ isloading: true })
    const { employeesWorkingHoursData } = this.state
    const employeesWorkingHoursSorted = employeesWorkingHoursData.map(function (
      obj
    ) {
      delete obj["dayName"] // Delete old key
      delete obj["_id"] // Delete old key
      return obj
    })
    const payload = { weekPlans: employeesWorkingHoursSorted }
    createSaloonSchedule(payload)
      .then((res) => {
        if (res.data.success) {
          this.handleGetSaloonSchedule()
        } else {
          this.setState({ isloading: false })
        }
      })
      .catch((error) => {
        console.log(error, "error")
      })
  }

  handleEditSaloonSchedule = () => {
    this.setState({ isloading: true })
    const { employeesWorkingHoursData } = this.state
    const employeesWorkingHoursSorted = employeesWorkingHoursData.map(function (
      obj
    ) {
      if (obj.availableStatus === 0) {
        delete obj["checkIn"] // Delete old key
        delete obj["checkOut"] // Delete old key
      }
      delete obj["dayName"] // Delete old key
      delete obj["_id"] // Delete old key
      return obj
    })
    const payload = { weekPlans: employeesWorkingHoursSorted }

    editSaloonSchedule(payload)
      .then((res) => {
        if (res.data.success) {
          SuccessHelper.handleSuccess(
            i18n.t("successfully_updated_working_hours"),
            true
          )
          this.handleGetSaloonSchedule()
        } else {
          this.setState({ isloading: false })
        }
      })
      .catch((error) => {
        ErrorHelper.handleErrors(i18n.t("someting_went_wrong"), true)
        console.log(error, "error")
      })
  }

  renderLoader = () => {
    const { isloading } = this.state
    return <DotsLoader isloading={isloading} />
  }

  saveSchedule = async (newSchedule) => {
    await this.setState({
      employeesWorkingHoursData: newSchedule.weekPlans,
    })
    this.checkValidation()
  }

  render() {
    return (
      <div className={this.context.screenSize === "mobile" ? "" : "p-4"}>
        {this.renderLoader()}

        <EditEmployeeWorkingHours
          employee={this.state.employee}
          handleSave={this.saveSchedule}
          history={this.props.history}
        />
      </div>
    )
  }
}

AddWorkingHours.contextType = ScreenSizeContext

const mapStateToProps = (state) => ({})

const action = {}

export default connect(mapStateToProps, action)(AddWorkingHours)
