import { connect } from "react-redux"
import React, { Component, useState } from "react"
import Rating from "react-rating"
import { Images } from "../../../theme"
import { DotsLoader } from "../../../components"
import { Translation } from "react-i18next"
import i18n from "../../../i18n"
import EmployeeRatings from "../../Saloon/Rating/EmployeeRatings"
import "./styles.css"
import ReactStars from "react-rating-stars-component"
import { getEmployeeRatings } from "../../../config/simpleApiCalls"

class MyRating extends Component {
  constructor(props) {
    super(props)
    this.state = {
      companyEmployee: null,
    }
  }

  componentDidMount = () => {
    this.getRatings()
  }

  getRatings() {
    this.setState({ isloading: true })

    getEmployeeRatings()
      .then((res) => {
        if (res.data) {
          res.data.data &&
            this.setState({ companyEmployee: res.data.data, isloading: false })
        }
      })
      .catch((err) => {
        // console.log(err);
        this.setState({ isloading: false })
      })
  }

  handleNavigation = (route) => {
    this.props.history.push(route)
  }

  renderLoader = () => {
    const { isloading } = this.state
    return <DotsLoader isloading={isloading} />
  }

  renderEmployeeDashboardHeading = () => {
    return (
      <div className="col-12 p-3">
        <h1 className="saloon-dashboard-heading">
          {i18n.t("employee_ratings")} :
        </h1>
      </div>
    )
  }

  renderRatingHeader = () => {
    const { companyEmployee } = this.state
    const user = companyEmployee?.employeeId?.userId
    const roundedNumber = Math.round(companyEmployee?.averageRating * 10) / 10

    // Convert to a string without ".0" if it's an integer
    const rating = Number.isInteger(roundedNumber)
      ? String(roundedNumber)
      : roundedNumber.toFixed(1)
    return (
      <>
        <div
          className="row bg-white pr-md-5 pl-md-5 pl-0 pr-0 pt-5 ml-0"
          style={{ width: "100%" }}
        >
          <div className="col-md-6">
            <div class="d-flex gap-2">
              <div class="">
                <img
                  src={user ? user.profile_img : Images.pic1}
                  class="rounded-circle"
                  height="100px"
                  width="100px"
                  alt="avatar"
                />
              </div>
              <div class="ml-2 mt-3 row align-items-center">
                <h4>
                  {user && user.firstName !== null
                    ? `${user.firstName || null} ${user.lastName || null}`
                    : user?.userName}
                </h4>
              </div>
            </div>
          </div>
          <div className="col-md-6 d-flex justify-content-center">
            <div class="">
              <h6>
                <strong>{i18n.t("average_score")}</strong>
              </h6>
              <p class="font-size-45 row align-items-center gap-2">
                {companyEmployee ? `(${rating})` : "(0)"}
                <ReactStars
                  key={rating}
                  size={24}
                  count={5}
                  value={rating || 0}
                  edit={false}
                  isHalf={true}
                  halfIcon={<i className="fa fa-star-half-alt"></i>}
                />
              </p>
            </div>
          </div>
        </div>
      </>
    )
  }

  render() {
    const { companyEmployee } = this.state
    return (
      <div>
        {this.renderLoader()}
        <div className="content-container">
          <div className="row mx-0 mb-4">
            {this.renderEmployeeDashboardHeading()}
            {this.renderRatingHeader()}
          </div>
          {companyEmployee && (
            <EmployeeRatings companyEmployee={companyEmployee} />
          )}
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({})

const action = {}

export default connect(mapStateToProps, action)(MyRating)
