// @flow

import { UPDATE_NOTIFICATION, LOGOUT } from "./ActionTypes"

export function request(payload) {
  return {
    payload,
    type: UPDATE_NOTIFICATION.REQUEST,
  }
}

export function success(data) {
  return {
    data,
    type: UPDATE_NOTIFICATION.SUCCESS,
  }
}

export function failure(errorMessage) {
  return {
    errorMessage,
    type: UPDATE_NOTIFICATION.FAILURE,
  }
}
