import React, { Component } from "react";
import InputOptions from "./InputOptions.component";
import Field from "./Field.component";
import SelectInput from "./SelectInput.component";
import PrimaryButton from "../../../components/Core/PrimaryButton";
import "../Styles/fieldGenerator.sass";
import i18n from "../../../i18n";
import Required from "./Required.component";
import DetailsInput from "./DetailsInput.component";
class FieldGenerator extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    let toSet = {};
    const { toEdit } = nextProps;
    if (toEdit && toEdit.id != prevState.fieldId) {
      toSet = {
        _id: toEdit._id,
        id: toEdit.id,
        inputType: toEdit.type,
        fields: { fieldTitle: toEdit.fieldName },
        fieldId: toEdit.id,
        fieldOptions: toEdit.fieldOptions,
        required: toEdit.required,
        details: toEdit.details,
      };
    }
    return toSet;
  }

  state = {
    _id: "",
    id: "",
    inputType: "",
    fields: { fieldTitle: "" },
    fieldId: null,
    fieldOptions: "",
    inputTypeError: "",
    fieldTitleError: "",
    fieldOptionsError: "",
    required: false,
    details: false,
  };
  handleChange = (e) => {
    if (e.target) {
      this.setState({
        fields: { ...this.state.fields, [e.target.name]: e.target.value },
      });
    }
  };

  handleSelect = (e) => {
    this.setState({ inputType: e.target.value });
  };

  handlefield = () => {
    const { _id, id, inputType, fields, fieldOptions, required, details } =
      this.state;
    const { handleGeneratedField, toEdit, handleCommitEdit } = this.props;

    let inputData = {};
    if (!fields.fieldTitle) {
      this.setState({ fieldTitleError: "This field is required" });
      return;
    }
    if (!inputType) {
      this.setState({ fieldTitleError: "" });
      this.setState({ inputTypeError: "This field is required" });
      return;
    }

    if (
      !fieldOptions &&
      (inputType == "radio" ||
        inputType == "select" ||
        inputType == "multipleChoice")
    ) {
      this.setState({ fieldOptionsError: "This field is required" });
      return;
    }

    inputData.type = inputType;
    inputData.fieldName = fields.fieldTitle;
    inputData.required = required;
    inputData.details = details;
    if (
      inputType &&
      fields.fieldTitle &&
      inputType != "radio" &&
      inputType != "select" &&
      inputType != "multipleChoice"
    ) {
      if (_id) {
        handleCommitEdit({ ...inputData, id, _id });
      } else if (id) {
        handleCommitEdit({ ...inputData, id });
      } else {
        handleGeneratedField(inputData);
      }
      this.setState({
        fieldTitleError: "",
        inputTypeError: "",
        inputType: null,
        fields: { fieldTitle: "" },
        _id: "",
        id: "",
        required: false,
        fieldId: null,
        details: false,
      });
    } else if (
      inputType != "" &&
      fields != { fieldTitle: "" } &&
      fieldOptions != ""
    ) {
      inputData.fieldOptions = fieldOptions;

      this.setState({ fieldOptionsError: "" });

      if (_id) {
        handleCommitEdit({ ...inputData, id: _id, _id });
      } else {
        handleGeneratedField(inputData);
      }
      this.setState({
        fieldTitleError: "",
        inputTypeError: "",
        inputType: null,
        fields: { fieldTitle: "" },
        fieldOptions: null,
        _id: "",
        id: "",
        required: false,
        fieldId: null,
        details: false,
      });
    }
  };

  handleFieldOptions = (fieldOptions) => {
    this.setState({ fieldOptions: fieldOptions });
  };
  handleRequired = (e) => {
    this.setState({ required: e.target.checked });
  };

  handleDetails = (e) => {
    this.setState({ details: e.target.checked });
  };

  handlePreview = () => this.props.handlePreview();
  render() {
    const {
      fields,
      inputType,
      inputTypeError,
      fieldOptions,
      fieldOptionsError,
      fieldTitleError,
      _id,
      id,
      required,
      details,
    } = this.state;
    return (
      <div className="fieldGenerator">
        {/* Asks User for input field title  */}
        <Field
          handleChange={(e) => this.handleChange(e)}
          fieldError={fieldTitleError}
          field={fields.fieldTitle}
          name="fieldTitle"
        />

        {/* Asks user to select the type of form field */}
        <div className="mt-3">
          <SelectInput
            handleChange={(e) => this.handleSelect(e)}
            inputTypeError={inputTypeError}
            inputType={inputType}
            selectLabel="Type"
          />
        </div>
        {/* Options for user selected input.
            for example if the user select radio button as input, we need to have options to for that
            so a box appears and ask the user for the options.
        */}
        <InputOptions
          handleFieldOptions={(e) => this.handleFieldOptions(e)}
          inputType={inputType}
          fieldOptionsError={fieldOptionsError}
          fieldOptions={fieldOptions}
        />
        {inputType !== "section" && (
          <>
            <Required onChange={this.handleRequired} value={required} />
            <DetailsInput onChange={this.handleDetails} value={details} />
          </>
        )}

        {_id || id ? (
          <div className="row">
            <div className="col-6">
              <PrimaryButton
                className="addFieldButtonsss"
                variant="contained"
                color="primary"
                onClick={this.handlefield}
              >
                {i18n.t("update")}
              </PrimaryButton>
            </div>
            <div className="col-6">
              <PrimaryButton
                className="addFieldButtonsss"
                variant="contained"
                color="primary"
                onClick={() => {
                  this.setState({
                    _id: "",
                    id: "",
                    inputType: "",
                    fields: { fieldTitle: "" },
                    fieldId: null,
                    fieldOptions: "",
                    required: false,
                    details: false,
                  });
                }}
              >
                {i18n.t("cancel")}
              </PrimaryButton>
            </div>
          </div>
        ) : (
          <>
            <PrimaryButton
              className="addFieldButtonsss"
              onClick={() => this.handlefield()}
              variant="contained"
              color="primary"
            >
              {i18n.t("add")}
            </PrimaryButton>
            <PrimaryButton
              className="addFieldButtonsss"
              onClick={() => this.handlePreview()}
              variant="contained"
              color="primary"
            >
              {this.props.preview == true
                ? i18n.t("hide_Preview_form")
                : i18n.t("Preview_form")}
            </PrimaryButton>
          </>
        )}
      </div>
    );
  }
}

export default FieldGenerator;
