import { connect } from "react-redux"
import React from "react"
import { Router } from "react-router-dom"
import { createBrowserHistory } from "history"

import UserRoutes from "./userRoutes"
import SaloonRoutes from "./saloonRoutes"
import EmployeeRoutes from "./employeeRoutes"
import CustomerRoutes from "./customerRoutes"

import { setDocked } from "../redux/actions/Sidebar"
import AppWrapper from "../components/AppWrapper/AppWrapper"
import { InvoiceProvider } from "../context/Invoice"
import { BookIntake } from "../context/BookIntake"
import { StaffMettingProvider } from "../context/StaffMeeting/StaffMeeting"

const customHistory = createBrowserHistory()
customHistory.listen((_) => {
  window.scrollTo(0, 0)
})

const mql = window.matchMedia("(min-width: 1200px)")

// Routes For Navigation
class MyRoutes extends React.Component {
  constructor(props) {
    super(props)

    this.mediaQueryChanged = this.mediaQueryChanged.bind(this)
    props.setDocked(mql.matches)
  }

  componentWillMount() {
    mql.addListener(this.mediaQueryChanged)
  }

  componentWillUnmount() {
    mql.removeListener(this.mediaQueryChanged)
  }

  mediaQueryChanged() {
    this.props.setDocked(mql.matches)
  }

  render() {
    return (
      <Router history={customHistory}>
        <BookIntake>
          <InvoiceProvider>
            <StaffMettingProvider>
              <AppWrapper>
                <CustomerRoutes history={customHistory} />
                <SaloonRoutes history={customHistory} />
                <EmployeeRoutes history={customHistory} />
                <UserRoutes history={customHistory} />
              </AppWrapper>
            </StaffMettingProvider>
          </InvoiceProvider>
        </BookIntake>
      </Router>
    )
  }
}

const mapStateToProps = (state) => ({})

const action = { setDocked }

export default connect(mapStateToProps, action)(MyRoutes)
