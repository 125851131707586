import React, { useState } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import BaseModal from "../../../BaseModal";
import i18n from "../../../../i18n";
import TextField from "@material-ui/core/TextField";
import DollarInput from "../../../DollarInput";
import { insertRequest } from "../../../../redux/actions/Employee/EmployeeFundsRequest";
import { ErrorHelper, SuccessHelper } from "../../../../helpers";

function AddModal({ open, handleClose, available, insertRequest }) {
  const [amount, setAmount] = useState(available);
  const [note, setNote] = useState("");
  const [formErrors, setFormErrors] = useState({});
  // const [amount, setAmount] = useState(available)

  const onSaveClick = () => {
    let newErrors = {};
    if (!amount) {
      newErrors.amount = i18n.t("amount_is_required");
    }
    if (amount > available) {
      newErrors.amount = i18n.t("not_greater_than_available", { available });
    }
    setFormErrors(newErrors);
    if (!Object.keys(newErrors).length) {
      insertRequest(
        { amount, note },
        {
          success() {
            SuccessHelper.handleSuccess(i18n.t("successfully_added"), true);
            setAmount(0);
            handleClose();
          },
          failure() {
            ErrorHelper.handleErrors(i18n.t("failed_to_add"), true);
          },
        }
      );
    }
  };

  const calculatePercent = (percent) => {
    setAmount(
      Math.round(((percent / 100) * available + Number.EPSILON) * 100) / 100
    );
  };
  return (
    <>
      <BaseModal
        open={open}
        containerClassName={"small-modal"}
        {...{
          title: i18n.t("request_funds"),
          actions: (
            <div className="row justify-content-end mx-0">
              <Button className="primary-button" onClick={onSaveClick}>
                {i18n.t("save")}
              </Button>
            </div>
          ),
          content: (
            <div className="row mx-0">
              <DollarInput
                value={amount}
                onChange={setAmount}
                error={formErrors.amount}
              />
              <div className="row mx-0 justify-content-between mt-2 w-100">
                <Button
                  size="small"
                  className="primary-button"
                  onClick={() => calculatePercent(25)}
                >
                  25%
                </Button>
                <Button
                  size="small"
                  className="primary-button"
                  onClick={() => calculatePercent(50)}
                >
                  50%
                </Button>
                <Button
                  size="small"
                  className="primary-button"
                  onClick={() => calculatePercent(75)}
                >
                  75%
                </Button>
                <Button
                  size="small"
                  className="primary-button"
                  onClick={() => calculatePercent(100)}
                >
                  100%
                </Button>
              </div>
              <TextField
                type="numeric"
                label={i18n.t("note")}
                onChange={(e) => setNote(e.target.value)}
                value={note}
              />
            </div>
          ),
        }}
        onClose={handleClose}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  accountDetails: state.employeeFundsRequest.accountDetails,
});

const action = { insertRequest };

export default connect(mapStateToProps, action)(AddModal);
