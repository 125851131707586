import { connect } from "react-redux"
import React, { useEffect, useState } from "react"
import i18n from "../../../i18n"
import {
  getRequest,
  upsertRequest,
} from "../../../redux/actions/Saloon/CommunicationSettings"
import "suneditor/dist/css/suneditor.min.css"

import PrimaryButton from "../../../components/Core/PrimaryButton"
import { DotsLoader } from "../../../components"
import { ErrorHelper, SuccessHelper } from "../../../helpers"
import TextField from "@material-ui/core/TextField"
import AppBar from "@material-ui/core/AppBar"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Select from "../../../components/Core/Select"
import TabPanel from "../../../components/TabPanel/TabPanel.component"
import CustomTextInput from "../../../components/Saloon/CommunicationSettings/CustomTextInput.component"
import CounterInput from "../../../components/CounterInput"
import ToggleSelect from "../../../components/ToggleSelect"
import "./styles.css"
import useScreenSize from "../../../context/ScreenSize"
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

const Expense = ({
  getRequest,
  upsertRequest,
  loading: propsLoading,
  communicationSettings,
}) => {
  const [typeMap, setTypeMap] = useState({
    td: {
      label: "transfer_details",
    },
    bc: {
      label: "booking_confirmation",
    },
    bcd: {
      label: "booking_confirmation_with_deposit",
    },
    br: {
      label: "booking_reminder",
    },
    pr: {
      label: "payment_reminder",
    },
    dr: {
      label: "deposit_reminder",
    },
    tr: {
      label: "transfer_reminder",
    },
    in: {
      label: "invoice_message",
    },
    fs: {
      label: "form_send",
    },
    rt: {
      label: "rating",
    },
  })
  const [loading, setLoading] = useState(false)
  const [bookingReminderTimes, setBookingReminderTimes] = useState({})
  const [invoiceMemo, setInvoiceMemo] = useState("")
  const [communicationLanguage, setCommunicationLanguage] = useState("en")
  const [remindSubscriptionInXDays, setRemindSubscriptionInXDays] = useState(14)
  const { screenSize } = useScreenSize()
  useEffect(() => {
    const [first = 24, second = 3] =
      communicationSettings?.sendRemindersXHoursBeforeBooking || []
    setBookingReminderTimes([Math.round(first / 24), second])
    Object.keys(communicationSettings?.customMessageMap || {}).map((key) => {
      typeMap[key].value = communicationSettings?.customMessageMap[key]
    })
    setTypeMap({ ...typeMap })
    setInvoiceMemo(communicationSettings.invoiceMemo)
    setCommunicationLanguage(
      communicationSettings.communicationLanguage || communicationLanguage
    )
    setRemindSubscriptionInXDays(
      communicationSettings.remindSubscriptionInXDays || 14
    )
  }, [communicationSettings])
  useEffect(() => {
    setLoading(true)
    Promise.all([
      new Promise((resolve) => {
        getRequest(
          {},
          {
            success: resolve,
          }
        )
      }),
    ]).then(() => {
      setLoading(false)
    })
  }, [])
  const onSaveReminderSettings = () => {
    setLoading(true)
    upsertRequest(
      {
        sendRemindersXHoursBeforeBooking: bookingReminderTimes,
        invoiceMemo,
        _id: communicationSettings?._id,
        communicationLanguage,
        remindSubscriptionInXDays,
      },
      {
        success: () => {
          SuccessHelper.handleSuccess(i18n.t("successfully_updated"), true)
          setLoading(false)
        },
        failure: () => {
          ErrorHelper.handleErrors(i18n.t("failed_to_update"), true)
          setLoading(false)
        },
      }
    )
  }
  const onSaveCustomMessages = () => {
    setLoading(true)
    const customMessageMap = {}
    Object.keys(typeMap).map((key) => {
      if (typeMap[key].value) {
        customMessageMap[key] = typeMap[key].value
      }
    })
    upsertRequest(
      {
        customMessageMap,
        _id: communicationSettings?._id,
      },
      {
        success: () => {
          SuccessHelper.handleSuccess(i18n.t("successfully_updated"), true)
          setLoading(false)
        },
        failure: () => {
          ErrorHelper.handleErrors(i18n.t("failed_to_update"), true)
          setLoading(false)
        },
      }
    )
  }
  const [tab, setTab] = React.useState(0)
  const handleTabChange = (event, newValue) => {
    setTab(newValue)
  }
  const className =
    screenSize === "mobile" ? "flex-column gap-2 align-items-start" : ""

  return (
    <div className="content-container p-4">
      <DotsLoader isloading={loading || propsLoading} />
      <h1 className="saloon-dashboard-heading mb-4">
        {i18n.t("container.communication_settings")}
      </h1>
      <AppBar position="static" className="custom-tab">
        <Tabs
          value={tab}
          onChange={handleTabChange}
          aria-label="simple tabs example"
        >
          <Tab label={i18n.t("general")} {...a11yProps(0)} />
          <Tab label={i18n.t("custom_messages")} {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel
        value={tab}
        index={0}
        style={{ padding: 0 }}
        className="custom-panel"
      >
        <fieldset className={className}>
          <legend className="mb-0">{i18n.t("first_reminder_time")}</legend>
          <Select
            value={`${bookingReminderTimes[0]}`}
            options={[
              { value: 0, name: i18n.t("no_reminder") },
              { value: 1, name: i18n.t("1_day") },
              { value: 2, name: i18n.t("number_of_days", { number: 2 }) },
              { value: 3, name: i18n.t("number_of_days", { number: 3 }) },
              { value: 4, name: i18n.t("number_of_days", { number: 4 }) },
              { value: 5, name: i18n.t("number_of_days", { number: 5 }) },
              { value: 6, name: i18n.t("number_of_days", { number: 6 }) },
              { value: 7, name: i18n.t("number_of_days", { number: 7 }) },
              { value: 8, name: i18n.t("number_of_days", { number: 8 }) },
              { value: 9, name: i18n.t("number_of_days", { number: 9 }) },
              { value: 10, name: i18n.t("number_of_days", { number: 10 }) },
            ]}
            onChange={(e) => {
              setBookingReminderTimes([e.target.value, bookingReminderTimes[1]])
            }}
            variant="outlined"
          />
        </fieldset>
        <fieldset>
          <legend className="mb-0">{i18n.t("second_reminder_time")}</legend>
          <CounterInput
            value={bookingReminderTimes[1]}
            label={i18n.t("starting_price")}
            onChange={(e) =>
              setBookingReminderTimes([bookingReminderTimes[0], e])
            }
          />
        </fieldset>
        <fieldset className={className}>
          <legend className="mb-0">{i18n.t("invoice_memo")}</legend>
          <TextField
            value={invoiceMemo}
            onChange={(e) => {
              setInvoiceMemo(e.target.value)
            }}
            className={screenSize === "mobile" ? "w-100" : "w-25"}
            variant="outlined"
          />
        </fieldset>
        <fieldset>
          <legend className="mb-0">{i18n.t("communication_language")}</legend>
          <ToggleSelect
            value={communicationLanguage === "en" ? false : true}
            onChange={(e) => setCommunicationLanguage(e ? "fr" : "en")}
            active="Français"
            inactive="English"
          />
        </fieldset>
        <fieldset>
          <legend className="mb-0">{i18n.t("remind_subscription")}</legend>
          <CounterInput
            value={remindSubscriptionInXDays}
            label={i18n.t("remind_subscription")}
            onChange={(e) => setRemindSubscriptionInXDays(e)}
          />
        </fieldset>
        <div className="mt-5">
          <PrimaryButton
            className="w-128"
            label={i18n.t("save")}
            onClick={onSaveReminderSettings}
          />
        </div>
      </TabPanel>
      <TabPanel value={tab} index={1}>
        {tab === 1 ? (
          <>
            <div
              style={
                screenSize === "mobile"
                  ? { height: "430px", overflowY: "auto" }
                  : { height: "600px", overflowY: "auto" }
              }
            >
              {Object.keys(typeMap).map((key) => {
                return (
                  <CustomTextInput
                    label={typeMap[key].label}
                    key={key}
                    value={typeMap[key].value}
                    onChange={(value) => {
                      typeMap[key].value = value
                      setTypeMap({ ...typeMap })
                    }}
                  />
                )
              })}
            </div>
            <div className="row mx-0 px-0 justify-content-center justify-content-md-end my-3">
              <div className="form-button-query">
                <PrimaryButton
                  className="w-128"
                  label={i18n.t("save")}
                  onClick={onSaveCustomMessages}
                />
              </div>
            </div>
          </>
        ) : null}
      </TabPanel>
    </div>
  )
}

const mapStateToProps = (state) => ({
  communicationSettings: state.communicationSettings.data,
  loading: state.packages.isFetching,
})

const action = {
  getRequest,
  upsertRequest,
}

export default connect(mapStateToProps, action)(Expense)
