import { put, takeEvery } from "redux-saga/effects"

import * as types from "../../actions/ActionTypes"
import {
  insert,
  get,
  remove,
  addTransaction,
  reverseTransaction,
  requestFailure,
  setStudentEnrollment,
} from "../../actions/Saloon/ProgramEnrollment"

import axios from "axios"
import { program_enrollment } from "../../../config/WebServices"

function* getSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.get(program_enrollment, {
      params: payload,
    })
    data = data.data

    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data)
      }
      yield put(get(data.data))
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data)
      }
      yield put(requestFailure(data))
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* insertSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.post(program_enrollment, payload)
    data = data.data
    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data)
      }
      yield put(insert(data.data))
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data)
      }
      yield put(requestFailure(data))
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* removeSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.delete(program_enrollment, {
      params: payload,
    })
    data = data.data
    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data)
      }
      yield put(remove(data.data))
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data)
      }
      yield put(requestFailure(data))
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* addTransactionSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.post(`${program_enrollment}/addTransaction`, payload)
    data = data.data
    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data)
      }
      yield put(addTransaction(data.data))
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data)
      }
      yield put(requestFailure(data))
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* reverseTransactionSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.post(
      `${program_enrollment}/reverseTransaction`,
      payload
    )
    data = data.data
    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data)
      }
      yield put(reverseTransaction(data.data))
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data)
      }
      yield put(requestFailure(data))
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* setStudentEnrollmentSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.post(`${program_enrollment}/setEnrollment`, payload)
    data = data.data
    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data)
      }
      yield put(setStudentEnrollment(data.data))
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data)
      }
      yield put(requestFailure(data))
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

export default function* root() {
  yield takeEvery(types.PROGRAM_ENROLLMENT.GET_SAGA, getSaga)
  yield takeEvery(types.PROGRAM_ENROLLMENT.INSERT_SAGA, insertSaga)
  yield takeEvery(types.PROGRAM_ENROLLMENT.DELETE_SAGA, removeSaga)
  yield takeEvery(
    types.PROGRAM_ENROLLMENT.REVERSE_TRANSACTION_SAGA,
    reverseTransactionSaga
  )
  yield takeEvery(
    types.PROGRAM_ENROLLMENT.ADD_TRANSACTION_SAGA,
    addTransactionSaga
  )
  yield takeEvery(
    types.PROGRAM_ENROLLMENT.SET_STUDENT_ENROLLMENT_SAGA,
    setStudentEnrollmentSaga
  )
}
