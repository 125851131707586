import { connect } from "react-redux"
import React, { useEffect, useRef, useState } from "react"
import i18n from "../../../i18n"
import BaseModal from "../../BaseModal"
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator"
import PrimaryButton from "../../Core/PrimaryButton"
import ColorPicker from "../../Core/ColorPicker/ColorPicker"
import "./ClassUpsert.scss"
import ClassGrades from "./ClassGrades"
import CounterInput from "../../CounterInput"
import useOutsideClick from "../../Core/ClickOutside/useOutsideClick"
const colorRegex = /^#([0-9a-f]{3}|[0-9a-f]{6})$/i

function ClassUpsert({ open, onClose, onSave, active = {} }) {
  const form = useRef()
  const [data, setData] = useState({})
  const [showPicker, setShowPicker] = useState(false)
  const init = (data) => {
    setData({ ...data })
  }
  useEffect(() => {
    init(active)
  }, [])
  useEffect(() => {
    if (active._id !== data._id) {
      init(active)
    }
  }, [active])
  const handleChange = (key, value) => {
    data[key] = value
    setData({ ...data })
  }
  const handleSubmit = () => {
    onSave(data)
  }

  const colorPicker = useRef()

  useOutsideClick(colorPicker, () => {
    setShowPicker(false)
  })
  return (
    <div className="class-upsert">
      {open ? (
        <BaseModal
          open={open}
          title={data._id ? i18n.t("edit_class") : i18n.t("add_class")}
          containerClassName={"small-container"}
          content={
            <>
              <div className="d-flex">
                <ValidatorForm
                  onSubmit={handleSubmit}
                  ref={form}
                  className="w-100 row mx-0 p-0"
                  onError={(error) => {
                    if (!error.length) {
                      onSave(data)
                    }
                  }}
                >
                  <div className="col-12 flex-column flex-md-row px-0 d-flex align-items-md-center mb-3">
                    <label
                      className="font-weight-bold"
                      style={{ minWidth: "150px" }}
                    >
                      {i18n.t("name")}
                    </label>
                    <div style={{ width: "100%" }}>
                      <TextValidator
                        className="w-100"
                        label={i18n.t("name")}
                        onChange={(e) => handleChange("name", e.target.value)}
                        value={data.name || ""}
                        validators={["required"]}
                        errorMessages={[i18n.t("name_is_required")]}
                        variant="outlined"
                      />
                    </div>
                  </div>
                  <div className="col-12 px-0 d-flex align-items-center mb-3">
                    <label
                      className="font-weight-bold"
                      style={{ minWidth: "150px" }}
                    >
                      {i18n.t("duration_in_hours")}
                    </label>
                    <CounterInput
                      value={isNaN(data.duration) ? 0 : data.duration}
                      label={i18n.t("duration")}
                      onChange={(e) => handleChange("duration", e)}
                    />
                  </div>
                  <div className="col-12 px-0 d-flex align-items-center mb-3">
                    <label
                      className="font-weight-bold"
                      style={{ minWidth: "150px" }}
                    >
                      {i18n.t("passing_grade_in_percent")}
                    </label>
                    <CounterInput
                      value={isNaN(data.passingGrade) ? 0 : data.passingGrade}
                      label={i18n.t("passing_grade_in_percent")}
                      onChange={(e) => handleChange("passingGrade", e)}
                    />
                  </div>
                  <div className="col-12 flex-column flex-md-row px-0 d-flex align-items-md-center mb-3">
                    <label
                      className="font-weight-bold"
                      style={{ minWidth: "150px" }}
                    >
                      {i18n.t("description")}
                    </label>
                    <div style={{ width: "100%" }}>
                      <TextValidator
                        className="w-100"
                        label={i18n.t("description")}
                        onChange={(e) =>
                          handleChange("description", e.target.value)
                        }
                        value={data.description || ""}
                        validators={["required"]}
                        variant="outlined"
                      />
                    </div>
                  </div>
                  <div className="col-12 px-0 d-flex align-items-center mb-3">
                    <label
                      className="font-weight-bold"
                      style={{ minWidth: "150px" }}
                    >
                      {i18n.t("price")}
                    </label>
                    <CounterInput
                      value={isNaN(data.price) ? 0 : data.price}
                      label={i18n.t("price")}
                      onChange={(e) => handleChange("price", e)}
                    />
                  </div>
                  <div className="col-12 px-0 d-flex align-items-center mb-3">
                    <label
                      className="font-weight-bold"
                      style={{ minWidth: "150px" }}
                    >
                      {i18n.t("color")}
                    </label>
                    <div ref={colorPicker} className="w-100">
                      <ColorPicker
                        value={data.color}
                        defaultValue={data.color || "#000000"}
                        label={i18n.t("color")}
                        onChange={(e) => handleChange("color", e)}
                        showPicker={showPicker}
                        setShowPicker={setShowPicker}
                        onBlur={(e) => {
                          let color = e.target.value
                          if (!color.match(colorRegex)) {
                            handleChange("color", "#000000")
                          }
                        }}
                        InputProps={{ value: data.color || "#000000" }}
                        variant="outlined"
                      />
                    </div>
                  </div>
                  <div className="row mx-0 px-0 justify-content-end my-5 col-12">
                    <PrimaryButton
                      className="w-128"
                      props={{
                        type: "submit",
                      }}
                      label={data._id ? i18n.t("save") : i18n.t("create")}
                    />
                  </div>
                </ValidatorForm>
              </div>
              {data._id ? (
                <ClassGrades _class={data} updateClass={setData} />
              ) : null}
            </>
          }
          onClose={onClose}
        />
      ) : null}
    </div>
  )
}

const mapStateToProps = (state) => ({
  expenses: state.expense.data,
  loading: state.vendor.isFetching,
})

const action = {}

export default connect(mapStateToProps, action)(ClassUpsert)
