import crudBuilder from "../../../builders/CrudBuilder/CrudBuilder"
import actions from "../../../redux/actions/Saloon/CustomerAgeGroup"
const { upsertRequest, getRequest, removeRequest } = actions

const CustomerAgeGroup = crudBuilder.build({
  type: "customerAgeGroup",
  title: "age_group",
  actionMap: {
    upsert: upsertRequest,
    get: getRequest,
    remove: removeRequest,
  },
})

export default CustomerAgeGroup
