import React, { useEffect, useState } from "react"
import { Button } from "@material-ui/core"
import { connect } from "react-redux"
import i18n from "../../../../i18n"
import DotsLoader from "../../../DotsLoader"
import { insertRequest } from "../../../../redux/actions/BookingTransaction"
import ErrorHelper from "../../../../helpers/ErrorHelper"
import InsertPayment from "../InsertPayment"
import toastr from "../../../../helpers/Toastr"

const CollectPaymentButton = ({
  insertRequest,
  bookingId,
  isloading,
  defaultAmount,
  amount,
  toPayMap,
  successCallback = () => {},
  includeTaxes,
  openCollectModal,
  transactionDetailsProps,
  onChangeTip,
  tip,
  total,
  accountBalance,
  giftCardBalance,
  setCustomer = () => {},
  customerId,
}) => {
  const [isAddTransactionOpen, setIsAddTransactionOpen] = useState(false)
  const onSubmit = async (body, manualPaymentMap, callback = () => {}) => {
    if (manualPaymentMap && Object.keys(manualPaymentMap).length) {
      toPayMap = manualPaymentMap
    }

    insertRequest(
      {
        ...body,
        bookingId,
        toPayMap,
        includeTaxes,
      },
      {
        success: (data) => {
          setIsAddTransactionOpen(false)
          successCallback(data)
          callback()
          let resultMap = {
            success: 0,
            failure: [],
          }
          data.data.map((element) => {
            if (element.success) {
              resultMap.success++
            } else {
              resultMap.failure.push(element)
            }
            return
          })
          if (resultMap.failure.length) {
            resultMap.failure.map((element) => {
              toastr.error(
                `${element.error.data.inventory.name}: ${i18n.t(
                  "insufficient_quantities",
                  { remainder: element.error.data.remainder }
                )}`
              )
            })
          }

          if (resultMap.success) {
            toastr.success(i18n.t("successfully_added"))
          }
        },
        failure: ({ code, data }) => {
          if (code) {
            ErrorHelper.handleErrors(i18n.t(code, data), true)
          } else {
            ErrorHelper.handleErrors(i18n.t("failed_to_add"), true)
          }
        },
      }
    )
  }
  useEffect(() => {
    if (openCollectModal) {
      setIsAddTransactionOpen(true)
    }
  }, [openCollectModal])
  return (
    <>
      <DotsLoader isloading={isloading} />
      {amount >= 0 ? (
        <Button
          className="collect-payment-btn"
          style={{
            width: "100%",
            borderRadius: "7px",
            backgroundColor: "#F6943B",
            color: "#FFF",
            textTransform: "none",
          }}
          onClick={() => setIsAddTransactionOpen(true)}
        >
          {`${i18n.t("collect_payment")} ${
            amount ? `: $${amount.toFixed(2)}` : ""
          }`}
        </Button>
      ) : (
        <Button
          className="primary-button"
          onClick={() => setIsAddTransactionOpen(true)}
        >
          {`${i18n.t("refund")}: ${(-1 * amount).toFixed(2)}`}
        </Button>
      )}
      {isAddTransactionOpen && (
        <InsertPayment
          defaultAmount={amount}
          open={isAddTransactionOpen}
          onSubmit={onSubmit}
          handleClose={() => setIsAddTransactionOpen(false)}
          transactionDetailsProps={transactionDetailsProps}
          type={"manual"}
          onChangeTip={onChangeTip}
          tip={tip}
          total={total}
          accountBalance={accountBalance.toFixed(2)}
          giftCardBalance={giftCardBalance}
          setCustomer={setCustomer}
          customerId={customerId}
        />
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  isloading: state.bookingTransaction.isFetching,
})

const action = { insertRequest }

export default connect(mapStateToProps, action)(CollectPaymentButton)
