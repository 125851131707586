import React from "react";
import { TextField } from "@material-ui/core";
import { LinkItem, LinkIcon } from "./styles";

export default function SocialMediaItem({
  icon,
  urlPlaceHolder,
  handleChange,
  link,
  name,
}) {
  return (
    <LinkItem>
      <LinkIcon src={icon} />
      <TextField
        value={link ? link : urlPlaceHolder}
        name={name}
        onChange={(e) => handleChange(e)}
        variant={"outlined"}
      />
    </LinkItem>
  );
}
