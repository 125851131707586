import React, { useEffect, useRef, useState } from "react"
import i18n from "../../../../i18n"
import PrimaryButton from "../../../../components/Core/PrimaryButton"
import { BaseModal } from "../../../../components"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import { ErrorHelper, SuccessHelper } from "../../../../helpers"
import {
  deleteIntakeCategoryItem,
  upsertIntakeCategories,
} from "../../../../config/simpleApiCalls"
import Tabs from "../../../../components/Core/Tabs/Tabs"
import IntakeCategoryItemUpsertPopup from "./IntakeCategoryItemUpsertPopup"
import BasicTable from "../../../../components/Core/BasicTable"
import moment from "moment"
import EditIcon from "../../../../components/Core/BasicTable/Actions/EditIcon"
import DeleteIcon from "../../../../components/Core/BasicTable/Actions/DeleteIcon"
import deleteConfirmation from "../../../../helpers/DeleteHelper"
import useScreenSize from "../../../../context/ScreenSize"
const IntakeCategoriesUpsertPopup = ({ open, setOpen, active, setLoading }) => {
  const form = useRef()
  const [data, setData] = useState({})
  const [itemUpsertOpen, setItemUpsertOpen] = useState(false)
  const [activeItem, setActiveItem] = useState(null)
  const title = data._id ? "update_intake_category" : "create_intake_category"

  const { screenSize } = useScreenSize()
  const onSubmit = (e) => {
    const successMessage = data._id
      ? "successfully_updated"
      : "successfully_added"
    const failureMessage = data._id ? "failed_to_update" : "failed_to_add"
    setLoading(true)
    upsertIntakeCategories(data)
      .then(({ data: res }) => {
        setData(res)
        SuccessHelper.handleSuccess(i18n.t(successMessage), true)
        setOpen(false)
      })
      .catch(() => {
        ErrorHelper.handleErrors(i18n.t(failureMessage), true)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (open) {
      const data = { ...(active || {}) }
      setData({
        ...data,
      })
    }
  }, [open])

  const handleDelete = (id) => {
    setLoading(true)
    deleteIntakeCategoryItem({ id })
      .then(() => {
        SuccessHelper.handleSuccess(i18n.t("successfully_deleted"), true)
      })
      .catch((e) => {
        console.error({ e })
        ErrorHelper.handleErrors(i18n.t("failed_to_delete"), true)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <BaseModal
      open={open}
      onClose={() => setOpen(false)}
      title={i18n.t(title)}
      contentClassName={"custom-tab"}
      containerClassName="half-container"
      showCloseBtn={screenSize !== "mobile" ? true : false}
      content={
        <Tabs
          tabs={[
            {
              title: i18n.t("details"),
              content: () => (
                <ValidatorForm
                  onSubmit={onSubmit}
                  ref={form}
                  className="d-flex align-items-end w-100"
                >
                  <div className="validate-flex col-12 px-0 d-flex align-items-center justify-content-between mb-3">
                    <label className="font-weight-bold mr-3">
                      {i18n.t("name")}
                    </label>
                    <div
                      className="validate-input"
                      style={{ width: "calc(100% - 220px)" }}
                    >
                      <TextValidator
                        label={i18n.t("name")}
                        onChange={(e) =>
                          setData({ ...data, name: e.target.value })
                        }
                        value={data.name || ""}
                        validators={["required"]}
                        errorMessages={[i18n.t("this_field_is_required")]}
                        variant="outlined"
                      />
                    </div>
                    <div
                      className={
                        screenSize !== "mobile"
                          ? ""
                          : "d-flex gap-2 justify-content-center w-100"
                      }
                    >
                      {screenSize === "mobile" && (
                        <PrimaryButton
                          className="w-128"
                          onClick={() => setOpen(false)}
                          label={i18n.t("cancel")}
                        />
                      )}

                      <PrimaryButton
                        className="w-128"
                        props={{
                          type: "submit",
                        }}
                        label={i18n.t("save")}
                      />
                    </div>
                  </div>
                </ValidatorForm>
              ),
            },
            {
              title: <div>{i18n.t("items")}</div>,
              hidden: !data._id,
              content: () => (
                <div>
                  <IntakeCategoryItemUpsertPopup
                    open={itemUpsertOpen}
                    setOpen={setItemUpsertOpen}
                    intakeCategoryId={data._id}
                    active={activeItem}
                  />
                  <BasicTable
                    rows={active?.items || []}
                    schema={[
                      {
                        header: i18n.t("name"),
                        field: "name",
                      },
                      {
                        header: i18n.t("created_on"),
                        field: "createdDate",
                        accessor: ({ createdDate }) => {
                          return moment(createdDate).format("MM-DD-YYYY HH:mm")
                        },
                      },
                      {
                        key: "action",
                        header: i18n.t("actions"),
                        style: { width: 160 },
                        render: (val) => {
                          return (
                            <>
                              <EditIcon
                                onClick={() => {
                                  setActiveItem(val)
                                  setItemUpsertOpen(true)
                                }}
                              />
                              <DeleteIcon
                                onClick={() =>
                                  deleteConfirmation(() =>
                                    handleDelete(val._id)
                                  )
                                }
                              />
                            </>
                          )
                        },
                      },
                    ]}
                  />
                  <div
                    className={
                      screenSize !== "mobile"
                        ? "row justify-content-end mt-5 mb-3"
                        : "d-flex gap-2 justify-content-center w-100"
                    }
                  >
                    {screenSize === "mobile" && (
                      <PrimaryButton
                        className="w-128"
                        onClick={() => setOpen(false)}
                        label={i18n.t("cancel")}
                      />
                    )}
                    <PrimaryButton
                      className="w-128"
                      label={i18n.t("create")}
                      onClick={() => {
                        setItemUpsertOpen(true)
                      }}
                    />
                  </div>
                </div>
              ),
            },
          ]}
        />
      }
    />
  )
}

export default IntakeCategoriesUpsertPopup
