import React, { useState, useEffect } from "react"
import { Translation } from "react-i18next"
import { useDispatch } from "react-redux"
import {
  addCustomBooking,
  createWaitingList,
} from "../../../../config/simpleApiCalls"
import useBooking from "../../../../context/Booking"
import { ErrorHelper, SuccessHelper } from "../../../../helpers"
import i18n from "../../../../i18n"
import CollapseTouchList from "../Support/CollapseTouchList"
import { request as getAvailableTimeslots } from "../../../../redux/actions/GetAvailableTimeslots.js"
import { getNestedValue } from "../../../../util/objectMethods"
import moment from "moment"
import Swal from "sweetalert2"
import EmployeeDateAndTime from "../Support/EmployeeDateAndTime"
import PrimaryButton from "../../../Core/PrimaryButton"
import TouchChip from "../Support/TouchChip"
import useCache from "../../../../context/LocalCache"

export function EmployeeBookingDetails({ formErrors }) {
  const dispatch = useDispatch()
  const { getResource } = useCache()
  const [allSaloons, setAllSaloons] = useState([])
  const {
    lockFields,
    setLockFields,
    onChange,
    data,
    categoryList,
    setCategoryList,
    serviceList,
    setServiceList,
    allSaloonMap,
    setAllSaloonMap,
    currentOpen,
    setCurrentOpen,
    setLoading,
    addOpen,
  } = useBooking()
  const {
    selectedCategory,
    selectSalon,
    employeeId,
    newTime,
    newDate,
    selectServices,
    enableWaitingList,
    userPackageId,
  } = data
  useEffect(() => {
    if (!selectServices) {
      setCurrentOpen("category")
    }
  }, [selectServices])
  const timeSlotLength = selectSalon?.companyId?.timeSlotLength
  const saloons = getResource((cache) => cache.employee.companies)
  const categoryServiceMap = getResource(
    (cache) => cache.employee.categoryServiceMap
  )
  const categoryMap = getResource((cache) => cache.employee.categoryMap)
  const [enableRecurringBooking, setEnableRecurringBooking] = useState(false)
  useEffect(() => {
    if (addOpen) {
      setCurrentOpen("salon")
      const allSaloons = saloons
        .filter((salon) => !!salon.companyId)
        .map((salon) => ({ ...salon, name: salon.companyId.name }))
      const newAllSaloonMap = {}
      allSaloons.map((saloon) => {
        newAllSaloonMap[saloon.companyId._id] = saloon
      })
      setAllSaloonMap(newAllSaloonMap)
      setAllSaloons(allSaloons)
    }
  }, [addOpen])
  useEffect(() => {
    if (allSaloons && allSaloons.length == 1) {
      onChangeSelectSalon(allSaloons[0])
    }
  }, [allSaloons])

  const removeLock = (e) => {
    onChange({
      selectedTime: "",
      newTime: "",
      availableTimeslots: {},
    })
    setLockFields(false)
  }

  const onChangeSelectSalon = (salon, e) => {
    if (e) {
      e.stopPropagation()
    }
    const { companyId } = salon
    const { employeeId } = allSaloonMap[companyId._id] || {}
    setCurrentOpen("category")
    onChange({
      selectSalon: salon,
      employeeId,
      selectServices: "",
      ...(lockFields
        ? {}
        : {
          selectDateAndTime: "",
          newDate: "",
          selectedTime: undefined,
          availableTimeslots: {},
        }),
    })
    setCategoryList(categoryMap[companyId._id])
  }

  const onChangeSelectCategory = (val, e) => {
    e.stopPropagation()
    const { companyId } = selectSalon
    onChange({
      selectedCategory: val,
      selectServices: "",
      ...(lockFields
        ? {}
        : {
          selectDateAndTime: "",
          newDate: "",
          selectedTime: undefined,
          availableTimeslots: {},
        }),
    })
    setServiceList(categoryServiceMap[companyId._id][val._id])
    setCurrentOpen("service")
  }

  const onChangeSelectService = async (val, e) => {
    e.stopPropagation()
    const { isBlackListed } = data
    const { price, depositAmount, requireDeposit, depositTarget } = val
    let amount = 0
    if (
      requireDeposit &&
      depositAmount &&
      (depositTarget == "everyone" ||
        (depositTarget == "blacklisted" && isBlackListed))
    ) {
      amount = depositAmount
    }
    onChange({
      selectServices: val,
      selectEmployee: "",
      selectedEmployee: "",
      discountCode: "",
      availableEmployees: [],
      totalAmount: price,
      depositAmount: amount,
      discount: undefined,
      ...(lockFields
        ? {}
        : {
          selectDateAndTime: "",
          newDate: "",
          selectedTime: undefined,
          availableTimeslots: {},
        }),
    })
    const serviceData = { ...val }
    const {
      companyId: { _id: companyId },
    } = selectSalon
    setCurrentOpen("dateTime")
    try {
      if (lockFields) {
        let date = newDate
        setLoading(true)
        dispatch(
          getAvailableTimeslots({
            data: {
              companyId,
              companyServiceId: serviceData._id,
              companyEmployeeId: employeeId,
              date,
            },
            resolve: ({ data: timeslots }) => {
              setLoading(false)
              const duration = parseInt(
                getNestedValue(serviceData, "duration", timeSlotLength)
              )
              const date = moment(`${newDate} ${newTime}`, "MM-DD-YYYY HH:mm")
              const key = `${date.format("HH:mm")} - ${date
                .add(duration, "m")
                .format("HH:mm")}`
              const slot = getNestedValue(timeslots, [key], [])
              if (!slot.length) {
                Swal.fire({
                  title: i18n.t("you_are_not_available"),
                  confirmButtonText: i18n.t("remove_lock"),
                }).then((e) => {
                  removeLock()
                })
              }
            },
          })
        )
      }
    } catch (error) {
      ErrorHelper.handleErrors(
        "Aww somthing went wrong for getting Employees",
        true
      )
    }
  }
  return (
    <>
      <div
        style={{
          fontSize: 18,
          marginTop: 10,
          marginBottom: 10,
          fontWeight: "bold",
        }}
        className="row justify-content-between mx-0"
      >
        <Translation>
          {(t) => t("container.enter_appointment_details")}
        </Translation>
        {lockFields && (
          <PrimaryButton label={i18n.t("remove_lock")} onClick={removeLock} />
        )}
      </div>
      <div className="row mx-0 mb-3">

        {!lockFields ? (
          <>
            {!enableRecurringBooking ?
              <div className="col-md-6 px-1">
                <TouchChip
                  label={i18n.t("enable_waiting_list")}
                  onClick={(e) => {
                    onChange({ enableWaitingList: !enableWaitingList })
                  }}
                  selected={enableWaitingList}
                />
              </div>
              : null}

            <div className="col-md-6 px-1">
              <TouchChip
                label={i18n.t("enable_recurring_booking")}
                onClick={(e) => {
                  setEnableRecurringBooking(!enableRecurringBooking)
                  onChange({ enableWaitingList: false, enableRecurringBooking: !enableRecurringBooking })
                  if (!enableRecurringBooking) {
                    Swal.fire({
                      title: i18n.t("enable_double_booking_for_recurring_booking"),
                      showCancelButton: false,
                      confirmButtonText: "Ok",
                    })
                  }

                }}
                selected={enableRecurringBooking}
              />
            </div>
          </>) : null}

      </div>
      {allSaloons.length == 1 ? null : (
        <CollapseTouchList
          options={allSaloons}
          onChange={onChangeSelectSalon}
          selected={selectSalon}
          noOptionsMessage={"no_salons"}
          open={currentOpen == "salon"}
          setOpen={() => setCurrentOpen("salon")}
          placeholder={i18n.t("select_salon")}
          locked={userPackageId}
        />
      )}
      {selectSalon ? (
        <CollapseTouchList
          options={categoryList}
          onChange={onChangeSelectCategory}
          selected={selectedCategory}
          noOptionsMessage={"no_categories"}
          open={currentOpen == "category"}
          setOpen={() => setCurrentOpen("category")}
          placeholder={i18n.t("select_category")}
          error={formErrors.categoryError}
          locked={userPackageId}
        />
      ) : null}
      {selectedCategory ? (
        <CollapseTouchList
          options={serviceList}
          onChange={onChangeSelectService}
          selected={selectServices}
          noOptionsMessage={"no_services"}
          open={currentOpen == "service"}
          setOpen={() => setCurrentOpen("service")}
          placeholder={i18n.t("select_service")}
          error={formErrors.selectServicesError}
          locked={userPackageId}
          enableSearch
        />
      ) : null}
      {selectServices || lockFields ? (
        <EmployeeDateAndTime
          data={data}
          onChange={onChange}
          open={currentOpen == "dateTime"}
          setOpen={() => setCurrentOpen("dateTime")}
          formErrors={formErrors}
          enableRecurringBooking={enableRecurringBooking}
        />
      ) : null}
    </>
  )
}

export default EmployeeBookingDetails

export function validate(data) {
  const {
    name,
    email,
    phoneNumber = "",
    selectedCategory,
    selectServices,
    formattedDate,
    recurringBookingDates,
    selectedTime,
    newTime,
    enableWaitingList,
    startTime,
    endTime,
    functionRole,
  } = data
  const formErrors = {}
  const nameRegex =
    /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{2,}$/u
  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const telephoneRegex = /[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]/g
  if (email && !email.match(emailRegex)) {
    formErrors.emailError = i18n.t("invalid_email")
  }
  if (phoneNumber && !phoneNumber.replace(/\s/g, "").match(telephoneRegex)) {
    formErrors.phoneNumberError = i18n.t("invalid_phone_number")
  }
  if (!phoneNumber) {
    formErrors.phoneNumberError = i18n.t("container.employe_valid_phone_number")
  }
  if (!name) {
    formErrors.nameError = i18n.t("name_required")
  }
  if (!selectedCategory) {
    formErrors.categoryError = i18n.t("select_category")
  }
  if (!selectServices) {
    formErrors.selectServicesError = i18n.t("select_service")
  }

  if (!formattedDate && (!recurringBookingDates || !recurringBookingDates?.length)) {
    formErrors.selectDateAndTimeError = i18n.t("select_date")
  }
  if (!enableWaitingList && !selectedTime) {
    formErrors.selectDateAndTimeError = i18n.t("select_time")
  }
  if (
    !enableWaitingList &&
    (!newTime || !newTime.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/))
  ) {
    formErrors.selectedTimeError = i18n.t("invalid_time")
  }
  if (enableWaitingList && !email) {
    formErrors.emailError = i18n.t("email_required")
  }
  if (
    enableWaitingList &&
    (!startTime || !startTime.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/))
  ) {
    formErrors.startTimeError = i18n.t("invalid_time")
  }
  if (
    enableWaitingList &&
    (!endTime || !endTime.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/))
  ) {
    formErrors.endTimeError = i18n.t("invalid_time")
  }
  return formErrors
}
export async function book(data, setLoading, resetFunction, viewBooking, sendBookingConfirmation, setShowCustomerCurrentBookings, clearAppointmentDetails) {
  const {
    name,
    email,
    phoneNumber,
    totalAmount,
    selectedCategory,
    selectServices,
    selectEmployee,
    selectSalon,
    newTime,
    formattedDate,
    recurringBookingDates,
    notes,
    enableDoubleBooking,
    depositAmount,
    employeeId,
    enableWaitingList,
    startTime,
    endTime,
    adjustedTime,
    userPackageId,
    functionRole,
    discount,
  } = data
  const {
    companyId: { _id: companyId },
  } = selectSalon

  const service = {
    serviceId: selectServices._id,
    categoryId: selectedCategory._id,
    date: formattedDate,
    time: newTime,
    employeeId,
  }

  if (!enableWaitingList) {
    const book = (depositRequired) => {
      if (formattedDate) {
        return createBooking(depositRequired, service)
      } else {
        recurringBookingDates.map((date, index, recurringBookingDates) => {
          delete service.date
          createBooking(depositRequired, { ...service, date }, true)
          if (recurringBookingDates.length - 1 === index) {
            if (resetFunction) {
              SuccessHelper.handleSuccess("Booking Created", true)
            } else {
              setShowCustomerCurrentBookings(true)
            }
            clearAppointmentDetails()
          }

        })
      }
    }
    const createBooking = (depositRequired, service, recurring = false) => {
      const payload = {
        companyId,
        userName: name,
        email,
        phoneNo: phoneNumber,
        status: 1,
        totalAmount,
        notes,
        forceBooking: !!enableDoubleBooking,
        depositRequired,
        adjustedTime,
        userPackageId,
        discountId: discount?._id,
        sendBookingConfirmation: !recurring ? sendBookingConfirmation : false,
      }
      return addCustomBooking({ ...payload, services: [service] })
        .then((res) => {
          if (res.data.success) {
            if (!recurring) {
              if (resetFunction) {
                SuccessHelper.handleSuccess("Booking Created", true)
                resetFunction()
              } else {
                viewBooking(res.data.data.booking._id)
              }
            }
          } else {
            ErrorHelper.handleErrors("Something went wrong", true)
          }
        })

        .catch((error) => {
          if (error.response) {
            // Alert.alert("Error", error.response.data.msg);
            ErrorHelper.handleErrors(error.response.data.msg, true)

            // Request made and server responded
          } else if (error.request) {
            ErrorHelper.handleErrors("Something Went Wrong", true)

            // The request was made but no response was received
          } else {
            ErrorHelper.handleErrors(error.msg, true)
          }
        })
    }
    if (depositAmount) {
      return await Swal.fire({
        title: i18n.t("require_deposit", { depositAmount }),
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonText: "Yes",
      }).then((e) => {
        setLoading(true)
        const { dismiss, isConfirmed, isDismissed } = e
        if (!isDismissed || dismiss != "backdrop") {
          book(isConfirmed)
        }
      })
    } else {
      book(false)
    }
  } else {
    const payload = {
      companyId,
      email,
      phoneNo: phoneNumber,
      status: 1,
      serviceId: selectServices._id,
      date: formattedDate,
      startTime: startTime,
      endTime: endTime,
      employeeId,
    }
    setLoading(true)
    return createWaitingList({ ...payload })
      .then((res) => {
        if (res.data.success) {
          SuccessHelper.handleSuccess("Added to waiting list", true)
        } else {
          ErrorHelper.handleErrors("Something went wrong", true)
        }
      })

      .catch((error) => {
        if (error.response) {
          ErrorHelper.handleErrors(error.response.data.msg, true)
        } else if (error.request) {
          ErrorHelper.handleErrors("Something Went Wrong", true)
        } else {
          ErrorHelper.handleErrors(error.msg, true)
        }
      })
  }
}
