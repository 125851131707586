// @flow
import Immutable from "seamless-immutable"
import * as types from "../actions/ActionTypes"

const initialState = Immutable({
  isOpen: false,
  isDocked: false,
})

export default (state = initialState, action) => {
  switch (action.type) {
  case types.SIDEBAR.SET_DOCKED:
    return Immutable.merge(state, {
      isDocked: action.isDocked,
    })
  case types.SIDEBAR.SET_OPENED:
    return Immutable.merge(state, {
      isOpen: action.isOpen,
    })
  default:
    return state
  }
}
