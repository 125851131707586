import { customer_origin } from "../../../config/WebServices"
import SagaBuilder from "../../builders/SagaBuilder"
import actions from "../../actions/Saloon/CustomerOrigin"

const customerOrigin = SagaBuilder.build({
  type: "CUSTOMER_ORIGIN",
  endpoint: customer_origin,
  actions,
})

export default customerOrigin
