import { connect } from "react-redux"
import React, { Component } from "react"
import i18n from "../../../i18n"
import { Images } from "../../../theme"
import { DotsLoader } from "../../../components"
import { employeePostUpload } from "../../../config/simpleApiCalls"
import { SuccessHelper, ErrorHelper } from "../../../helpers"
import {
  getEmployeePosts,
  employeeDeletePost,
} from "../../../config/simpleApiCalls"
import "./styles.css"
import Sytles from "./sytles"
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox"
import BaseModal from "../../../components/BaseModal/index.jsx"
import PrimaryButton from "../../../components/Core/PrimaryButton"
import deleteConfirmation from "../../../helpers/DeleteHelper"
class UploadMedia extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mediaType: null,
      imageUrl: null,
      imageFile: null,
      imageName: null,
      imageTitle: null,
      imageDescription: null,
      isloading: false,
    }
  }

  async componentDidMount() {
    this.getAllData()
  }

  getAllData = () => {
    this.setState({ isloading: true })
    getEmployeePosts()
      .then((res) => {
        if (res.data.success) {
          this.setState({
            employeePortfolioImages: res.data.data,
            isloading: false,
          })
        }
      })
      .catch((error) => {
        this.setState({ isloading: false })
      })
  }
  onChangeTitle = (text) => this.setState({ imageTitle: text.target.value })
  onChangeDescription = (text) =>
    this.setState({ imageDescription: text.target.value })

  handleNavigation = (route) => {
    this.props.history.push(route)
  }

  fileSelectedHandler = (event) => {
    const file = URL.createObjectURL(event.target.files[0])
    this.setState({
      mediaType: event.target.files[0].type.split("/")[0],
      imageUrl: file,
      imageFile: event.target.files[0],
      imageName: event.target.files[0].name,
      imageTitle: event.target.files[0].name,
      imageDescription: event.target.files[0].name,
    })
  }
  handleDeleteImage = (val) => {
    const { employeePortfolioImages } = this.state
    this.setState({ isloading: true })
    employeeDeletePost({ id: val._id })
      .then((res) => {
        if (res.data.success) {
          let arr = employeePortfolioImages.filter(
            (value) => value._id !== val._id
          )

          this.setState({ employeePortfolioImages: arr, isloading: false })
          this.getAllData()
          SuccessHelper.handleSuccess("Deleted Succesfully", true)
        } else {
          ErrorHelper.handleErrors("Failed to delete image", true)
          this.setState({ isloading: false })
        }
      })
      .catch((error) => {
        ErrorHelper.handleErrors("Failed to delete image", true)
        this.setState({ isloading: false })
      })
  }
  handleDelete = (e) => {
    this.setState({
      imageUrl: null,
      imageFile: null,
      imageName: null,
      imageTitle: null,
      imageDescription: null,
    })
  }

  handleUploadMedia = () => {
    this.setState({ isloading: true })
    const { mediaType, imageFile, imageTitle, imageDescription } = this.state
    let payloadFormdata = new FormData()
    payloadFormdata.append(
      `${mediaType === "image" ? "image" : "video"}`,
      imageFile
    )
    payloadFormdata.append("title", imageTitle)
    payloadFormdata.append("description", imageDescription)

    employeePostUpload(payloadFormdata)
      .then((res) => {
        if (res.data.success) {
          SuccessHelper.handleSuccess(
            "Your image/video has been successfully added",
            true
          )
          this.setState({
            mediaType: null,
            imageUrl: null,
            imageFile: null,
            imageName: null,
            imageTitle: null,
            imageDescription: null,
            isloading: false,
          })
          this.getAllData()
        }
      })
      .catch((error) => {
        console.log(error, "errorrrrrrrrrrrrrrrrrrr")
      })
  }

  renderLoader = () => {
    const { isloading } = this.state
    return <DotsLoader isloading={isloading} />
  }

  renderSaloonDashboardHeading = () => {
    return (
      <div className="col-12 p-3">
        <h1 className="saloon-dashboard-heading">
          {i18n.t("container.dashboard_upload_videos")}
        </h1>
      </div>
    )
  }
  renderSaloonMediaCard = () => {
    const { employeePortfolioImages, isloading, imageFile } = this.state
    return (
      <SRLWrapper>
        <div class="row" style={{ marginTop: "10px" }}>
          {employeePortfolioImages &&
            imageFile === null &&
            employeePortfolioImages.map((val) => {
              return (
                <>
                  {!!val.images && (
                    <div
                      key={val._id}
                      class="imageParent"
                      style={{ marginTop: "8px" }}
                    >
                      {!!val.images && (
                        <>
                          <img className="img-fluid1" src={val.images.url} />
                          <div
                            class="middle"
                            onClick={() => {
                              deleteConfirmation(() =>
                                this.handleDeleteImage(val)
                              )
                            }}
                          >
                            <div class="text">{i18n.t("delete")}</div>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                  {!!val.videos && (
                    <div
                      key={val._id}
                      class="col-lg-3 col-md-4 imageParent"
                      style={{ marginTop: "8px" }}
                    >
                      {!!val.videos && (
                        <>
                          <video width="100%" height="145px" controls>
                            <source src={val.videos.url} />
                          </video>
                          <div
                            class="middle"
                            onClick={() => {
                              deleteConfirmation(() =>
                                this.handleDeleteImage(val)
                              )
                            }}
                          >
                            <div class="text">{i18n.t("delete")}</div>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </>
              )
            })}
        </div>
      </SRLWrapper>
    )
  }
  renderUploadFileCard = () => {
    return (
      <div className="col-12">
        <div
          className="col-12 bg-white py-2 p-md-3"
          style={{ border: "1px dashed #707070", borderRadius: "7px" }}
        >
          <div className="col-12 col-md-10 mx-auto">
            {/* <p id="upload-file-text">
              {i18n.t("container.dashboard_upload_files")}
            </p> */}
            <input
              type="file"
              style={{ display: "none" }}
              onChange={this.fileSelectedHandler}
              ref={(fileInput) => (this.fileInput = fileInput)}
              accept="image/*,video/*"
            />
            <div
              className="col-12 p-3 mt-4"
              id="upload-box"
              onClick={() => this.fileInput.click()}
            >
              <img
                src={Images.saloon_upload_media_icon}
                style={{ width: 128 }}
              />
              <p id="drop-file-text">
                {i18n.t("container.dashboard_drop_files")}{" "}
                <span>
                  <u className="browse-text">
                    {i18n.t("container.dashboard_browse")}
                  </u>
                </span>
              </p>
            </div>
          </div>
          {this.renderSaloonMediaCard()}
        </div>
      </div>
    )
  }

  renderFileSection = () => {
    const { mediaType } = this.state
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: " center",
        }}
      >
        {mediaType === "image"
          ? this.renderImageFile()
          : this.renderVideoFile()}
        <input
          type="file"
          style={{ display: "none" }}
          onChange={this.fileSelectedHandler}
          ref={(fileInput) => (this.fileInput = fileInput)}
          accept="image/*,video/*"
        />
        {this.renderFileAddBtn()}
      </div>
    )
  }

  renderImageFile = () => {
    const { imageUrl } = this.state
    return (
      <div
        className=""
        style={{
          backgroundImage: `url(${imageUrl})`,
          width: "80%",
          height: "50vh",
          margin: "1rem",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "size",
          borderRadius: "0.5rem",
          border: "2px solid #448EF7",
          backgroundSize: "contain",
        }}
      />
    )
  }

  renderVideoFile = () => {
    const { imageUrl } = this.state
    return (
      <div
        className=""
        style={{
          overflow: "hidden",
          width: 200,
          height: 200,
          margin: "1rem",
          borderRadius: "0.5rem",
          border: "2px solid #448EF7",
        }}
      >
        <video style={{ width: "200px", height: "200px" }} controls>
          <source src={imageUrl} />
        </video>
      </div>
    )
  }

  renderFileAddBtn = () => {
    return (
      <div
        onClick={() => this.fileInput.click()}
        style={{
          width: "30%",
          height: "3em",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "1rem",
          backgroundColor: "#EEEEEE ",
          borderRadius: "0.5rem",
          cursor: "pointer",
        }}
      >
        {i18n.t("edit_uploaded_file")}
      </div>
    )
  }

  renderPreviewSection = () => {
    return (
      <div className="col-md-3 p-3" style={{ backgroundColor: "#EEEEEE" }}>
        {this.renderAttachmentDetails()}
        {this.renderForm()}
        <div className="mt-3">
          <button
            type="button"
            id="saloon-upload"
            onClick={() => this.handleUploadMedia()}
            className="btn btn-light btn-sm ml-1 saloon-category-service-btn saloon-upload"
          >
            Upload
          </button>
        </div>
      </div>
    )
  }
  closeAddModal = () => {
    this.setState({
      imageFile: null,
    })
  }

  renderAttachmentDetails = () => {
    const { imageName, mediaType } = this.state
    return (
      <>
        <h6>Attachment Details</h6>
        <div className="col-12 pt-3">
          <div className="row">
            {mediaType === "image"
              ? this.renderPreviewImageFile()
              : this.renderPreviewVideoFile()}
            <div className="col-6">
              <p className="saloon-image-type-text">{imageName}</p>
              <div className="d-flex pt-1">
                <img
                  onClick={() => this.handleDelete()}
                  src={Images.saloon_working_hour_delete}
                  style={{ width: 25 }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  renderPreviewImageFile = () => {
    const { imageUrl } = this.state
    return (
      <div
        className=""
        style={{
          backgroundImage: `url(${imageUrl})`,
          width: 130,
          height: 130,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          borderRadius: "0.5rem",
          border: "2px solid #448EF7",
        }}
      />
    )
  }

  renderPreviewVideoFile = () => {
    const { imageUrl } = this.state
    return (
      <div
        className=""
        style={{
          overflow: "hidden",
          width: 130,
          height: 130,
          borderRadius: "0.5rem",
          border: "2px solid #448EF7",
        }}
      >
        <video style={{ width: "130px", height: "130px" }} controls>
          <source src={imageUrl} />
        </video>
      </div>
    )
  }

  renderForm = () => {
    const { imageTitle, imageDescription } = this.state
    return (
      <div className="col-12 pl-0">
        <h6 className="mt-2" style={{ color: "#424242" }}>
          {i18n.t("title")}
        </h6>
        <input
          className="form-control saloon-link-social-input"
          onChange={this.onChangeTitle}
          value={imageTitle}
        />
        <h6 className="mt-4" style={{ color: "#424242" }}>
          {i18n.t("description")}
        </h6>
        <textarea
          className="form-control saloon-link-social-input"
          rows="4"
          onChange={this.onChangeDescription}
          value={imageDescription}
        />
        <div className="mt-3"></div>
      </div>
    )
  }

  renderAddedFilesCard = () => {
    return (
      <div
        className="col-12 px-0"
        style={{ borderRadius: "1rem", overflow: "hidden" }}
      >
        <div className="row">
          {this.renderFileSection()}
          {this.renderPreviewSection()}
        </div>
      </div>
    )
  }

  render() {
    const { imageFile } = this.state
    return (
      <div>
        {this.renderLoader()}
        <div className="container">
          <div className="row">
            {this.renderSaloonDashboardHeading()}
            {imageFile ? (
              <BaseModal
                open={imageFile}
                onClose={this.closeAddModal}
                {...{
                  title: <div>{i18n.t("upload_file")}</div>,
                  content: this.renderFileSection(),
                  actions: (
                    <div
                      className="row mx-2 px-0 justify-content-end"
                      style={{ width: "95%" }}
                    >
                      <PrimaryButton
                        style={{ marginRight: "1.5em" }}
                        onClick={() => this.closeAddModal()}
                        label={i18n.t("cancel")}
                      />
                      <PrimaryButton
                        onClick={() => this.handleUploadMedia()}
                        label={i18n.t("save")}
                      />
                    </div>
                  ),
                }}
              />
            ) : (
              this.renderUploadFileCard()
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default UploadMedia
