// @flow

import { ACTIVITY_SCHEDULE_EVENT } from "../ActionTypes"

export function getRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: ACTIVITY_SCHEDULE_EVENT.GET_SAGA,
  }
}

export function get(data) {
  return {
    data,
    type: ACTIVITY_SCHEDULE_EVENT.GET,
  }
}
export function getByCustomerRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: ACTIVITY_SCHEDULE_EVENT.GET_BY_CUSTOMER_SAGA,
  }
}

export function getByCustomer(data) {
  return {
    data,
    type: ACTIVITY_SCHEDULE_EVENT.GET_BY_CUSTOMER,
  }
}
export function addTransactionRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: ACTIVITY_SCHEDULE_EVENT.ADD_TRANSACTION_SAGA,
  }
}

export function addTransaction(data) {
  return {
    data,
    type: ACTIVITY_SCHEDULE_EVENT.ADD_TRANSACTION,
  }
}

export function reverseTransactionRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: ACTIVITY_SCHEDULE_EVENT.REVERSE_TRANSACTION_SAGA,
  }
}

export function reverseTransaction(data) {
  return {
    data,
    type: ACTIVITY_SCHEDULE_EVENT.REVERSE_TRANSACTION,
  }
}

export function getByActivityReferenceRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: ACTIVITY_SCHEDULE_EVENT.GET_BY_ACTIVITY_REFERENCE_SAGA,
  }
}

export function getAvailableEvents(data) {
  return {
    data,
    type: ACTIVITY_SCHEDULE_EVENT.GET_AVAILABLE_EVENTS,
  }
}

export function getAvailableEventsRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: ACTIVITY_SCHEDULE_EVENT.GET_AVAILABLE_EVENTS_SAGA,
  }
}

export function getByActivityReference(data) {
  return {
    data,
    type: ACTIVITY_SCHEDULE_EVENT.GET_BY_ACTIVITY_REFERENCE,
  }
}
export function removeEventRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: ACTIVITY_SCHEDULE_EVENT.DELETE_EVENT_SAGA,
  }
}

export function removeEvent(data) {
  return {
    data,
    type: ACTIVITY_SCHEDULE_EVENT.DELETE_EVENT,
  }
}

export function upsertEventRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: ACTIVITY_SCHEDULE_EVENT.UPSERT_EVENT_SAGA,
  }
}

export function upsertEvent(data) {
  return {
    data,
    type: ACTIVITY_SCHEDULE_EVENT.UPSERT_EVENT,
  }
}

export function applyTemplateRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: ACTIVITY_SCHEDULE_EVENT.APPLY_TEMPLATE_SAGA,
  }
}

export function applyTemplate(data) {
  return {
    data,
    type: ACTIVITY_SCHEDULE_EVENT.APPLY_TEMPLATE,
  }
}

export function setCustomersRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: ACTIVITY_SCHEDULE_EVENT.SET_CUSTOMERS_SAGA,
  }
}

export function setCustomers(data) {
  return {
    data,
    type: ACTIVITY_SCHEDULE_EVENT.SET_CUSTOMERS,
  }
}

export function setAttendanceRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: ACTIVITY_SCHEDULE_EVENT.SET_ATTENDANCE_SAGA,
  }
}

export function setAttendance(data) {
  return {
    data,
    type: ACTIVITY_SCHEDULE_EVENT.SET_ATTENDANCE,
  }
}

export function categoryReportRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: ACTIVITY_SCHEDULE_EVENT.CATEGORY_REPORT_SAGA,
  }
}

export function categoryReport(data) {
  return {
    data,
    type: ACTIVITY_SCHEDULE_EVENT.CATEGORY_REPORT,
  }
}

export function attendanceReportRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: ACTIVITY_SCHEDULE_EVENT.ATTENDANCE_REPORT_SAGA,
  }
}

export function attendanceReport(data) {
  return {
    data,
    type: ACTIVITY_SCHEDULE_EVENT.ATTENDANCE_REPORT,
  }
}

export function activityReportRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: ACTIVITY_SCHEDULE_EVENT.ACTIVITY_REPORT_SAGA,
  }
}

export function activityReport(data) {
  return {
    data,
    type: ACTIVITY_SCHEDULE_EVENT.ACTIVITY_REPORT,
  }
}
export function requestFailure() {
  return {
    type: ACTIVITY_SCHEDULE_EVENT.REQUEST_FAILURE,
  }
}
