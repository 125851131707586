import { take, put, call, fork } from "redux-saga/effects"

import * as types from "../actions/ActionTypes"
import { success, failure } from "../actions/LogOut"

import axios from "axios"
import { logout_Api } from "../../config/WebServices"
import { ErrorHelper } from "../../helpers"
import SharedStorage from "../../helpers/Storage"

async function callRequest(data) {
  const gcm_id = await SharedStorage.getItem("messaging_token")
  return axios.post(`${logout_Api}`, { gcm_id })
}

function* watchRequest() {
  while (true) {
    const { payload } = yield take(types.LOGOUT.REQUEST)
    try {
      const response = yield call(callRequest, payload)
      yield put(success(response))
    } catch (err) {
      yield put(failure(err))
      ErrorHelper.handleErrors(err, true)
    }
  }
}

export default function* root() {
  yield fork(watchRequest)
}
