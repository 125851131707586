import React from "react"
import { Route, Switch } from "react-router-dom"

import Login from "../containers/Login"
import Register from "../containers/Register"
import ForgotPassword from "../containers/ForgotPassword"
import ResetPassword from "../containers/ResetPassword"
import VerifyResetCode from "../containers/VerifyResetCode"
import VerifyCode from "../containers/Otp"
import UpdatePassword from "../containers/UpdatePassword"

import Home from "../containers/Home"
import Services from "../containers/Services"
import AvailableService from "./../containers/AvailableService/index"
import PrivacyPolicy from "./../containers/Policies/PrivacyPolicy"
import LegalInfo from "./../containers/Policies/LegalInfo"
import CookiePolicy from "./../containers/Policies/CookiePolicy"
import Faqs from "./../containers/Policies/Faqs"
import DemonstrationReservation from "./../containers/DemonstrationReservation"
import EmployeeProfile from "./../containers/EmployeeProfile"
// partner login/registrations
import SalonRegister from "../containers/Saloon/Register"
import SalonForgotPassword from "../containers/Saloon/ForgotPassword"
import SalonOtp from "../containers/Saloon/Otp"
import SalonUpdatePassword from "../containers/Saloon/UpdatePassword"
import EmployeeRegister from "../containers/Employee/Register"
import EmployeeForgotPassword from "./../containers/Employee/ForgotPassword"
import EmployeeVerifyOtp from "./../containers/Employee/Otp"
import EmployeeUpdatePassword from "./../containers/Employee/UpdatePassword/index"
import FillForm from "../components/FormBuilder/Components/FillForm"
import { Capacitor } from "@capacitor/core"
import RatingSurvey from "../containers/Saloon/RatingForm/RatingSurvey"
// User Routes For User Navigation
const UserRoutes = () => (
  <Switch>
    <Route
      exact
      path="/"
      component={Capacitor.isNativePlatform() ? Login : Home}
    ></Route>
    <Route exact path="/our-services" component={Services}></Route>
    <Route exact path="/login" component={Login}></Route>
    <Route exact path="/register" component={Register}></Route>
    <Route exact path="/forgot-password" component={ForgotPassword}></Route>
    <Route exact path="/reset-password" component={ResetPassword}></Route>
    <Route exact path="/verify-reset-code" component={VerifyResetCode}></Route>
    <Route exact path="/available-service" component={AvailableService}></Route>
    <Route exact path="/verify-code" component={VerifyCode}></Route>
    <Route exact path="/update-password" component={UpdatePassword}></Route>
    <Route exact path="/update-password" component={UpdatePassword}></Route>
    <Route exact path="/privacy-policy" component={PrivacyPolicy}></Route>
    <Route exact path="/legal-info" component={LegalInfo}></Route>
    <Route exact path="/cookies-policy" component={CookiePolicy}></Route>
    <Route exact path="/faqs" component={Faqs}></Route>
    <Route exact path="/careers.html" component={null}></Route>
    <Route
      exact
      path="/demonstration-reservation"
      component={DemonstrationReservation}
    ></Route>
    <Route
      exact
      path="/employee-profile/:employeeId"
      component={EmployeeProfile}
    ></Route>
    <Route
      exact
      path="/saloon-forgot-password"
      component={SalonForgotPassword}
    ></Route>
    <Route exact path="/saloon-verify-otp" component={SalonOtp}></Route>
    <Route
      exact
      path="/saloon-update-password"
      component={SalonUpdatePassword}
    ></Route>
    <Route exact path="/saloon-register" component={SalonRegister}></Route>

    <Route exact path="/employee-register" component={EmployeeRegister}></Route>
    <Route
      exact
      path="/employee-forgot-password"
      component={EmployeeForgotPassword}
    ></Route>
    <Route
      exact
      path="/employe-verify-code"
      component={EmployeeVerifyOtp}
    ></Route>
    <Route exact path="/form-fill/:id" component={FillForm}></Route>
    <Route
      exact
      path="/evaluation/:companyShortenedId/:bookingShortenedId"
      component={RatingSurvey}
    ></Route>
    <Route
      exact
      path="/employe-update-password"
      component={EmployeeUpdatePassword}
    ></Route>
  </Switch>
)

export default UserRoutes
