import React, { Component, useState } from "react";
import { connect } from "react-redux";
import {
  getRequest,
  removeRequest,
  updateRequest,
  insertRequest,
  setDefaultRequest,
} from "../../../redux/actions/Card";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import Button from "@material-ui/core/Button";
import i18n from "../../../i18n";
import BaseModal from "../../BaseModal";
import { SuccessHelper } from "../../../helpers";
import { ErrorHelper } from "../../../helpers";
import Swal from "sweetalert2";
import PlacesAutocomplete from "../../PlacesAutocomplete";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";

import { getNestedValue } from "../../../util/objectMethods";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const AddCard = ({ open, insertRequest, getRequest, handleClose }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [completed, setCompleted] = useState(false);
  const [name, setName] = useState("");
  const [addressMap, setAddressMap] = useState({});
  const [errorMap, setErrorMap] = useState({});

  const createIntentForAdd = () => {
    const newErrorMap = {};
    if (Object.keys(addressMap).length < 2) {
      newErrorMap.address = i18n.t("full_address_is_required");
    }
    if (!completed) {
      newErrorMap.card = true;
    }
    if (!name) {
      newErrorMap.name = i18n.t("name_is_required");
    }
    if (Object.keys(newErrorMap) == 0) {
      insertRequest(
        {},
        {
          success: async (data) => {
            const result = await stripe
              .confirmCardSetup(data.client_secret, {
                payment_method: {
                  card: elements.getElement(CardElement),
                  billing_details: {
                    name,
                    address: {
                      city: addressMap.city,
                      country: addressMap.country_abbr,
                      line1: addressMap.address,
                      line2: addressMap.address_line_2,
                      postal_code: addressMap.postalCode,
                      state: addressMap.province_abbr,
                    },
                  },
                },
              })
              .then(() => {
                getRequest({});
                SuccessHelper.handleSuccess(i18n.t("successfully_added"), true);
                handleClose(false);
              });
          },
        }
      );
    }
    setErrorMap(newErrorMap);
  };

  return (
    <BaseModal
      open={open}
      {...{
        title: i18n.t("add_card"),
        actions: (
          <div className="row justify-content-end mx-0">
            <Button
              className="primary-button"
              onClick={() => {
                createIntentForAdd();
              }}
            >
              {i18n.t("save")}
            </Button>
          </div>
        ),
        content: (
          <div className="row mx-0">
            <TextField
              id="name"
              className="col-12 col-md-6 px-0 px-md-1"
              label={i18n.t("name")}
              value={name}
              onChange={(e) => setName(e.target.value)}
              helperText={errorMap.name}
              error={!!errorMap.name}
            />
            <PlacesAutocomplete
              className="pt-2 pt-md-0 col-12 col-md-6 px-0 px-md-1"
              addressSelected={(newMap) =>
                setAddressMap({
                  ...newMap,
                  address_line_2: getNestedValue(
                    addressMap,
                    ["address_line_2"],
                    ""
                  ),
                })
              }
              helperText={errorMap.address}
              error={!!errorMap.address}
            />
            <TextField
              id="address_line_32"
              className="pt-2 col-12 col-md-6 px-0 px-md-1"
              label={i18n.t("address_line_2")}
              value={getNestedValue(addressMap, ["address_line_2"], "")}
              onChange={(e) =>
                setAddressMap({ ...addressMap, address_line_2: e.target.value })
              }
              helperText={errorMap.address_line_2}
              error={!!errorMap.address_line_2}
            />
            <FormControl
              className="pt-3 col-12 col-md-6 px-0 px-md-1 align-self-end"
              error={!!errorMap.card}
            >
              <CardElement
                options={CARD_ELEMENT_OPTIONS}
                onChange={(e) => {
                  setCompleted(e.complete);
                }}
              />
              {!!errorMap.card && (
                <FormHelperText>{i18n.t("card_is_required")}</FormHelperText>
              )}
            </FormControl>
          </div>
        ),
      }}
      onClose={() => handleClose(false)}
    />
  );
};

const mapStateToProps = (state) => ({});

const action = { getRequest, insertRequest };

export default connect(mapStateToProps, action)(AddCard);
