import { useSelector } from "react-redux"
import React, { useEffect, useState } from "react"
import i18n from "../../../../i18n"
import BasicTable from "../../../Core/BasicTable"
import PrimaryButton from "../../../Core/PrimaryButton"
import { DotsLoader } from "../../.."
import { removeRequest } from "../../../../redux/actions/Saloon/CompanyCustomerSubscription"
import { getRequest as getCompanySubscriptions } from "../../../../redux/actions/Saloon/CompanySubscription"

import { useDispatch } from "react-redux"
import { ErrorHelper, SuccessHelper } from "../../../../helpers"
import DeleteIcon from "../../../Core/BasicTable/Actions/DeleteIcon"
import EditIcon from "../../../Core/BasicTable/Actions/EditIcon"
import Swal from "sweetalert2"
import moment from "moment"
import CustomerSubscriptionUpsert from "./Support/CustomerSubscriptionUpsert"
import useCache from "../../../../context/LocalCache"
import CustomerSubscriptionOverduePopup from "./Support/CustomerSubscriptionOverduePopup"

export default function CustomerSubscription({ customer }) {
  const dispatch = useDispatch()
  const { getResource } = useCache()
  const allServices = getResource((cache) => cache.company.services)
  const [addSubscripitionOpen, setAddSubscripitionOpen] = useState(false)
  const [collectSubscriptionOpen, setCollectSubscriptionOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [active, setActive] = useState(null)
  const subscriptions = useSelector((s) => {
    return s.companyCustomerSubscription.data
  })

  useEffect(() => {
    dispatch(getCompanySubscriptions({}))
  }, [])

  const handleDelete = (ids) => {
    Swal.fire({
      title: i18n.t("are_you_sure_delete"),
      text: i18n.t("cannmot_reverse"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ff3600",
      cancelButtonColor: "#354e68",
      confirmButtonText: i18n.t("yes"),
    }).then((result) => {
      if (result.value) {
        setLoading(true)
        dispatch(
          removeRequest(
            { ids },
            {
              success: () => {
                setLoading(false)
                SuccessHelper.handleSuccess(
                  i18n.t("successfully_deleted"),
                  true
                )
              },
              failure: () => {
                setLoading(false)
                ErrorHelper.handleErrors(i18n.t("failed_to_delete"), true)
              },
            }
          )
        )
      }
    })
  }

  return (
    <>
      <DotsLoader isloading={loading} />
      <div className="row mx-0 justify-content-end mb-1">
        <PrimaryButton
          className="mb-4"
          label={i18n.t("add_subscription")}
          onClick={() => {
            setActive(null)
            setAddSubscripitionOpen(true)
          }}
        />
      </div>
      <BasicTable
        rows={subscriptions || []}
        schema={[
          {
            header: i18n.t("price"),
            field: "make",
            accessor: ({ price }) => {
              return `$${price.toFixed(2)}`
            },
          },
          {
            header: i18n.t("frequency"),
            field: "frequency",
            accessor: ({ frequency }) => {
              return i18n.t(frequency)
            },
          },
          {
            header: i18n.t("next_payment_due_on"),
            field: "nextPaymentDueOn",
            accessor: ({ nextPaymentDueOn }) => {
              return moment(nextPaymentDueOn).format("MM-DD-YYYY")
            },
          },
          {
            header: i18n.t("numberOfSessionsPerWeek"),
            field: "numberOfSessionsPerWeek",
          },
          {
            header: i18n.t("status"),
            field: "status",
            accessor: ({ status }) => {
              return i18n.t(status)
            },
          },
          {
            header: i18n.t("overdue"),
            field: "overdue",
            accessor: ({ overdue }) => {
              return overdue ? i18n.t("yes") : i18n.t("no")
            },
          },
          {
            field: "actions",
            style: { width: 200 },
            render: (row, index) => {
              return (
                <div className="row mr-5 justify-content-end">
                  {row.overdue && (
                    <PrimaryButton
                      label={i18n.t("collect")}
                      onClick={() => {
                        setActive(row)
                        setCollectSubscriptionOpen(true)
                      }}
                    />
                  )}
                  <EditIcon
                    onClick={() => {
                      setActive(row)
                      setAddSubscripitionOpen(true)
                    }}
                  />
                  <DeleteIcon onClick={() => handleDelete(row._id)} />
                </div>
              )
            },
          },
        ]}
      />
      <CustomerSubscriptionUpsert
        open={addSubscripitionOpen}
        setOpen={setAddSubscripitionOpen}
        customer={customer}
        setLoading={setLoading}
        active={active}
        allServices={allServices}
      />
      <CustomerSubscriptionOverduePopup
        open={collectSubscriptionOpen}
        setOpen={setCollectSubscriptionOpen}
        customer={customer}
        active={active}
      />
    </>
  )
}
