import React, { useState } from "react"
import { DotsLoader } from "../../../components"
import i18n from "../../../i18n"
import ActivityCategoryReportFilters from "./components/ActivityCategoryReportFilters/ActivityCategoryReportFilters"
import ActivityCategoryReportTables from "./components/ActivityCategoryReportTables"
import { categoryReportRequest } from "../../../redux/actions/Saloon/ActivityScheduleEvent"
import { useDispatch, useSelector } from "react-redux"
import { ErrorHelper } from "../../../helpers"
import { useEffect } from "react"
import actions from "../../../redux/actions/Saloon/ActivityCategory"
import moment from "moment"
import PrimaryButton from "../../../components/Core/PrimaryButton"
const { getRequest } = actions

const startDate = moment().subtract(7, "d").toDate()
const endDate = moment().toDate()

export default function ActivityCategoryReport() {
  const dispatch = useDispatch()
  const loading = useSelector((s) => s.activityScheduleEvent.isFetching)
  const [filters, setFilters] = useState({ startDate, endDate })

  useEffect(() => {
    onFilter()
    dispatch(getRequest())
  }, [])

  const onFilter = (exportToXlsx) => {
    const params = { filters }
    if (exportToXlsx) {
      params.exportToXlsx = true
    }
    console.log({ params })
    dispatch(
      categoryReportRequest(params, {
        failure() {
          ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true)
        },
      })
    )
  }

  return (
    <div className="content-container p-4">
      <DotsLoader isloading={loading} />
      <div className="d-sm-flex justify-content-sm-between align-items-center mb-4">
        <h1 className="saloon-dashboard-heading pt-0">
          {i18n.t("activity_category_report")}
        </h1>
        <div className="d-flex px-0 justify-content-sm-end gap-1 mt-3">
          <PrimaryButton
            className="px-3"
            label={i18n.t("export_to_excel")}
            onClick={() => onFilter(true)}
          />
          <PrimaryButton
            className="w-98"
            label={i18n.t("submit")}
            onClick={() => onFilter()}
          />
        </div>
      </div>
      <ActivityCategoryReportFilters
        filters={filters}
        setFilters={setFilters}
        onFilter={onFilter}
      />
      <ActivityCategoryReportTables />
    </div>
  )
}
