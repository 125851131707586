// @flow
import Immutable from "seamless-immutable"
import * as types from "../../actions/ActionTypes"

const initialState = Immutable({
  isFetching: false,
  errorMessage: "",
  data: [],
})

export default (state = initialState, action) => {
  switch (action.type) {
  case types.PROGRAM.GET_SAGA:
    return Immutable.merge(state, {
      isFetching: true,
    })
  case types.PROGRAM.GET:
    return Immutable.merge(state, {
      isFetching: false,
      data: action.data,
    })
  case types.PROGRAM.UPSERT_SAGA:
    return Immutable.merge(state, {
      isFetching: true,
    })
  case types.PROGRAM.UPSERT: {
    const newState = { ...state, isFetching: false, data: [...state.data] }
    const { _id } = action.data
    const index = state.data.findIndex((element) => element._id == _id)
    if (index != -1) {
      newState.data[index] = action.data
    } else {
      newState.data.push(action.data)
    }
    return newState
  }
  case types.PROGRAM.DELETE_SAGA:
    return Immutable.merge(state, {
      isFetching: true,
    })
  case types.PROGRAM.DELETE: {
    const newState = { ...state, isFetching: false, data: [...state.data] }
    const { _id } = action.data
    const index = state.data.findIndex((element) => element._id == _id)
    if (index != -1) {
      newState.data.splice(index, 1)
    }
    return newState
  }

  case types.PROGRAM.ADD_CLASS_SAGA:
  case types.PROGRAM.DELETE_CLASS_SAGA:
    return Immutable.merge(state, {
      isFetching: true,
    })
  case types.PROGRAM.ADD_CLASS:
  case types.PROGRAM.DELETE_CLASS: {
    const newState = { ...state, isFetching: false, data: [...state.data] }
    const { _id } = action.data
    const index = state.data.findIndex((element) => element._id == _id)
    if (index != -1) {
      newState.data[index] = action.data
    }
    return newState
  }
  case types.PROGRAM_ENROLLMENT.DELETE: {
    const data = [...state.data]
    const { _id, courseId } = action.data
    const index = data.findIndex((element) => element._id == courseId)
    if (index != -1) {
      if (data[index].enrollments) {
        const nestedIndex = data[index].enrollments?.findIndex(
          (enrollment) => enrollment._id == _id
        )

        if (nestedIndex != -1) {
          const enrollments = [...data[index].enrollments]
          enrollments.splice(nestedIndex, 1)
          data[index] = { ...data[index], enrollments }
        }
      }
    }
    return Immutable.merge(state, { data })
  }

  case types.PROGRAM.REQUEST_FAILURE:
    return Immutable.merge(state, {
      isFetching: false,
    })

  case types.LOGOUT:
    return initialState
  case types.CLEAR_LOGOUT:
    return initialState
  default:
    return state
  }
}
