import { connect } from "react-redux"
import React, { Component } from "react"
import i18n from "../../../i18n"
import { getTaxReports } from "../../../config/simpleApiCalls"
import { BaseModal, DotsLoader } from "../../../components"
import Button from "@material-ui/core/Button"
import moment from "moment"
import DatePicker from "react-datepicker"
import "./styles.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import PrimaryButton from "../../../components/Core/PrimaryButton"
import SecondaryButton from "../../../components/Core/SecondaryButton/SecondaryButton"
import { exportTableToPDF } from "../../../util/exportToPDF"
import { ScreenSizeContext } from "../../../context/ScreenSize"
class TaxReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: null,

      showEditDelete: "",
      activePage: 1,
      usersList: [],
      startDate: new Date(),
      endDate: new Date(),
      // employeeList: [],
      filterUsersList: [],
      isLoading: false,
    }
  }

  componentDidMount() {
    this.retrieveReports()
  }
  formatReportData = (data) => {
    const masterTaxMap = {}
    let formatted = data.map((element) => {
      const taxMap = {
        total: 0,
      }
      element.taxes.map((tax) => {
        if (tax.type) {
          taxMap[tax.type] = tax.amount
          taxMap.total += tax.amount
          if (!masterTaxMap[tax.type]) {
            masterTaxMap[tax.type] = 0
          }
          masterTaxMap[tax.type] += tax.amount
        }
      })
      return { ...element, taxMap }
    })
    this.setState({ masterTaxMap })
    return formatted
  }

  setStartDate = (val) => {
    const { endDate } = this.state
    if (val > endDate) {
      this.setState({
        startDate: endDate,
        endDate: val,
      })
    } else {
      this.setState({
        startDate: val,
      })
    }
  }

  setEndDate = (val) => {
    const { startDate } = this.state
    if (val < startDate) {
      this.setState({
        startDate: val,
        endDate: startDate,
      })
    } else {
      this.setState({
        endDate: val,
      })
    }
  }

  TemplateOption = () => {
    const { startDate, endDate } = this.state
    return (
      <div className="d-sm-flex align-items-center gap-2 mb-3">
        <label className="font-weight-bolder">{i18n.t("date_range")}</label>
        <div className="d-flex gap-2">
          <DatePicker
            className="form-control saloon-form-input"
            placeholderText="Select Start Date"
            selected={startDate}
            onChange={(data) => this.setStartDate(data)}
            dateFormat="MM/dd/yyyy"
            variant="outlined"
          />
          <DatePicker
            className="form-control saloon-form-input"
            placeholderText="Select End Date"
            selected={endDate}
            onChange={(data) => this.setEndDate(data)}
            dateFormat="MM/dd/yyyy"
          />
        </div>
      </div>
    )
  }
  renderButtons = () => {
    const { screenSize } = this.context
    return (
      <div className="d-flex flex-column flex-md-row gap-2">
        <PrimaryButton
          className={screenSize !== "mobile" ? "w-128" : ""}
          onClick={() => exportTableToPDF(["#table"], i18n.t("tax_report"))}
        >
          {i18n.t("export_to_pdf")}
        </PrimaryButton>
        <PrimaryButton
          className={screenSize !== "mobile" ? "w-128" : ""}
          onClick={() => this.retrieveReports(true)}
        >
          {i18n.t("export_to_excel")}
        </PrimaryButton>
      </div>
    )
  }

  renderSearchBar = () => {
    const { searchText } = this.state
    const { screenSize } = this.context
    return (
      <div className="col-12 px-0 d-sm-flex align-items-center justify-content-between mb-4">
        <span className="saloon-dashboard-heading pt-0">
          {i18n.t("tax_report")}
        </span>
        {screenSize !== "mobile" ? <>{this.renderButtons()}</> : null}
      </div>
    )
  }

  renderTableBody = () => {
    const { data, isLoading, masterTaxMap } = this.state

    if (!isLoading) {
      if (data) {
        return (
          <tbody>
            <tr
              style={{
                position: "sticky",
                top: 35,
                backgroundColor: "white",
                zIndex: 1,
              }}
            >
              <td>
                <strong>{i18n.t("summary")}: </strong>
              </td>
              {Object.keys(masterTaxMap).map((tax) => {
                return (
                  <td className="px-0">
                    <Button
                      disabled={true}
                      style={{
                        backgroundColor: "#F6943B",
                        color: "white",
                        width: "7rem",
                      }}
                    >
                      $ {masterTaxMap[tax].toFixed(2)}
                    </Button>
                  </td>
                )
              })}
              <td className="px-0">
                <Button
                  disabled={true}
                  style={{
                    backgroundColor: "#F6943B",
                    color: "white",
                    width: "7rem",
                  }}
                >
                  ${" "}
                  {Object.values(masterTaxMap)
                    .reduce((accum, element) => accum + element, 0)
                    .toFixed(2)}
                </Button>
              </td>
            </tr>
            {data.map((val, ind) => {
              return (
                <tr
                  key={val._id}
                  style={{
                    backgroundColor: ind % 2 == 0 && "#F4F6F8",
                    borderTop: "1px solid #E5EBF1",
                    borderBottom: data.length - 1 == ind && "1px solid #E5EBF1",
                  }}
                >
                  <td className=" Table_data">
                    <div className="d-flex align-items-center">
                      <span className="appointment-details-employee-name">
                        {moment(val.createdDate).format("MM-DD-YYYY hh:mm a")}
                      </span>
                    </div>
                  </td>
                  {Object.keys(masterTaxMap).map((tax) => {
                    return (
                      <td className=" Table_data">
                        <div className="d-flex align-items-center mt-1">
                          <span className="appointment-details-employee-name">
                            $ {val.taxMap[tax] ? val.taxMap[tax].toFixed(2) : 0}
                          </span>
                        </div>
                      </td>
                    )
                  })}
                  <td className=" Table_data">
                    <div className="d-flex align-items-center mt-1">
                      <span className="appointment-details-employee-name">
                        $ {val.taxMap.total.toFixed(2)}
                      </span>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        )
      }
    } else {
      return <DotsLoader isloading={this.state.isloading} />
    }
  }

  renderLoader = () => {
    const { isLoading } = this.state
    return <DotsLoader isloading={isLoading} />
  }

  renderUserTable = () => {
    const { masterTaxMap = {}, data } = this.state
    const { screenSize } = this.context
    if (data && data.length) {
      return (
        <div className="col-12 px-0 mt-5">
          <div
            className="table-responsive"
            style={
              screenSize === "mobile" ? { height: "70vh" } : { height: "80vh" }
            }
          >
            <table
              id="table"
              className="table table-borderless appointment-details-table"
            >
              <thead>
                <tr
                  style={{
                    position: "sticky",
                    top: 0,
                    backgroundColor: "white",
                    zIndex: 1,
                  }}
                >
                  <th scope="col">{i18n.t("date")}</th>
                  {Object.keys(masterTaxMap).map((tax) => {
                    return (
                      <th scope="col">
                        <div
                          className="row"
                          style={{ flexDirection: "column" }}
                        >
                          {tax}
                        </div>
                      </th>
                    )
                  })}
                  <th scope="col">
                    <div className="row" style={{ flexDirection: "column" }}>
                      {i18n.t("total")}
                    </div>
                  </th>
                </tr>
              </thead>
              {this.renderTableBody()}
            </table>
          </div>
        </div>
      )
    } else {
      return (
        <div className="row full-width px-0 justify-content-center mt-5">
          {i18n.t("nothing_found")}
        </div>
      )
    }
  }

  renderButton = () => {
    return (
      <div
        style={{
          justifyContent: "flex-end",
          flex: 1,
          display: "flex",
        }}
      >
        <span onClick={() => alert("")} className="generateButton">
          Generate Report
        </span>
      </div>
    )
  }

  // getDataAgain = () =>{

  // }
  setSelectedValue = (val) => {
    let currentDate = moment(Date.now())
    if (val == 1) {
      const startOfMonth = moment(currentDate).startOf("month")
      const endOfMonth = moment(currentDate).endOf("month")
      this.setState({
        startDate: startOfMonth.toDate(),
        endDate: endOfMonth.toDate(),
      })
    } else if (val == 2) {
      const startOfQuarter = moment(currentDate).startOf("quarter")
      const endOfQuarter = moment(currentDate).endOf("quarter")
      this.setState({
        startDate: startOfQuarter.toDate(),
        endDate: endOfQuarter.toDate(),
      })
    } else if (val == 3) {
      const convertedStart = moment(`01-01-${currentDate.year()}`, "MM-DD-YYYY")
      const convertedEnd = moment(`12-31-${currentDate.year()}`, "MM-DD-YYYY")
      this.setState({
        startDate: convertedStart.toDate(),
        endDate: convertedEnd.toDate(),
      })
    }
  }

  retrieveReports = (exportToXlsx) => {
    this.setState({ isLoading: true }, () => {})
    const { startDate, endDate } = this.state
    let payload = {
      start: moment(startDate).format("MM-DD-YYYY"),
      end: moment(endDate).format("MM-DD-YYYY"),
      exportToXlsx,
    }
    getTaxReports(payload)
      .then((res) => {
        this.setState({ isLoading: false })
        this.setState({ data: this.formatReportData(res.data.data) }, () => {})
      })
      .catch((err) => {
        this.setState({ isLoading: false })
      })
  }

  ShowMenuValues = () => {
    return (
      <div className="row align-items-center px-0 mx-0 ml-sm-3 gap-2">
        <label className="font-weight-bolder">
          {i18n.t("container.quick_filter")}
        </label>
        <div className="d-flex px-0 gap-2 flex-wrap">
          <SecondaryButton onClick={() => this.setSelectedValue(1)}>
            {i18n.t("container.week")}
          </SecondaryButton>
          <SecondaryButton onClick={() => this.setSelectedValue(2)}>
            {i18n.t("container.month")}
          </SecondaryButton>
          <SecondaryButton onClick={() => this.setSelectedValue(3)}>
            {i18n.t("container.year")}
          </SecondaryButton>
          <PrimaryButton
            className="w-98"
            onClick={() => this.retrieveReports()}
          >
            {i18n.t("search")}
          </PrimaryButton>
        </div>
      </div>
    )
  }

  retrieveButton = () => {
    return (
      <div class="px-0 row mx-0 justify-content-end mb-2">
        <PrimaryButton
          type="button"
          className="btn-accept mr-2"
          onClick={() => exportTableToPDF(["#table"], i18n.t("tax_report"))}
        >
          PDF
        </PrimaryButton>
        <PrimaryButton
          type="button"
          className="btn-accept mr-2"
          onClick={() => this.retrieveReports(true)}
        >
          {i18n.t("export")}
          <FontAwesomeIcon className="ml-1" icon={["far", "file-excel"]} />
        </PrimaryButton>
        <PrimaryButton
          type="button"
          className="btn-light"
          onClick={() => this.retrieveReports()}
        >
          {i18n.t("search")}
        </PrimaryButton>
      </div>
    )
  }

  render() {
    const { isLoading } = this.state
    const { screenSize } = this.context
    return (
      <>
        {isLoading ? this.renderLoader() : null}

        <div>
          <div className="content-container p-4">
            <div className="row">
              <div className="full-width">
                {this.renderSearchBar()}
                {screenSize !== "mobile" ? (
                  <div className="row">
                    {this.TemplateOption()}
                    {this.ShowMenuValues()}
                  </div>
                ) : (
                  <div className="row justify-content-center gap-2">
                    <PrimaryButton
                      className="w-128"
                      onClick={() => this.setState({ openFiltersModal: true })}
                    >
                      {i18n.t("filters")}
                    </PrimaryButton>
                    <PrimaryButton
                      className="w-128"
                      onClick={() => this.setState({ openOptionsModal: true })}
                    >
                      Options
                    </PrimaryButton>
                  </div>
                )}
              </div>
              {this.renderUserTable()}
            </div>
          </div>
          <BaseModal
            open={this.state.openFiltersModal}
            onClose={() => this.setState({ openFiltersModal: false })}
            title={i18n.t("filters")}
            content={
              <>
                {this.TemplateOption()}
                {this.ShowMenuValues()}
              </>
            }
          ></BaseModal>
          <BaseModal
            open={this.state.openOptionsModal}
            onClose={() => this.setState({ openOptionsModal: false })}
            title="Options"
            content={<>{this.renderButtons()}</>}
          ></BaseModal>
        </div>
      </>
    )
  }
}

TaxReport.contextType = ScreenSizeContext

const mapStateToProps = (state) => ({})

const action = {}

export default connect(mapStateToProps, action)(TaxReport)
