import { connect } from "react-redux";
import React, { Component } from "react";

import { DotsLoader, Navbar, Footer } from "./../../components";
import DatePicker from "react-datepicker";
import { SuccessHelper, ErrorHelper } from "./../../helpers";
import {
  getDemostrationSlots,
  createDemostrationReservation,
} from "../../config/simpleApiCalls";
import moment from "moment";

import "./styles.css";

class DemonstrationReservation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      name: "",
      companyName: "",
      date: "",
      time: undefined,
      availabilities: {},
      isloading: false,

      formErrors: {
        emailError: "",
        nameError: "",
        dateError: "",
        companyNameError: "",
        timeError: "",
        phoneNoError: "",
      },
      lang: "fr",
      labels: {
        fr: {
          success: "Succès",
          successMessage:
            "Vous avez réservé une place de démonstration avec succès.<br/><br/>La suite?<br/><br/>Nous vous enverrons un courriel de confirmation avec des informations supplémentaires.",
          companyName: "Entrez le Nom de Votre Compagnie",
          name: "Entrez Votre Nom",
          phoneNo: "Entrez Votre Numéro de Téléphone",
          email: "Entrez Votre Adresse e-mail",
          date: "Cliquez Pour Sélectionner la Date",
          time: "Sélectionnez une Heure",
          bookNow: "Reservez Maintenant",
          title: "Réservez Votre Démonstration Virtuelle",
          nameError: "Nom est Obligatoire",
          companyError: "Nom de la Compagnie est Obligatoire",
          phoneNoError: "Numéro de Téléphone est Obligatoire",
          phoneNoInvalid: "Entrer un Valide Numéro de Téléphone",
          emailError: "Adresse e-mail est Obligatoire",
          emailInvalid: "Entrer un Valide Adresse e-mail",
          dateError: "Date est Obligatoire",
          timeError: "Temps est Obligatoire",
        },
        en: {
          success: "Success",
          successMessage:
            "You have successfully reserved a demonstration spot. <br/><br/>What now? <br/><br/>We will send you and email on selected date with additional information",
          companyName: "Enter Your Company Name",
          name: "Enter Your Name",
          phoneNo: "Enter Your Phone Number",
          email: "Enter Your e-mail",
          date: "Click To Select a Date",
          time: "Select a Time",
          bookNow: "Book Now",
          title: "Book Your Virtual Demonstration",
          nameError: "Name is Required",
          companyError: "Company Name is Required",
          phoneNoError: "Phone Number is Required",
          phoneNoInvalid: "Enter a valid telephone number",
          emailError: "Email is Required",
          emailInvalid: "Enter a valid e-mail",
          dateError: "Date is Required",
          timeError: "Time is Required",
        },
      },
    };
  }

  async componentDidMount() {
    let { lang } = this.state;
    let newLang = new URLSearchParams(this.props.location.search).get("lang");
    if (newLang) {
      this.setState({ lang: newLang });
    }
  }

  onChangeEmail = (text) => this.setState({ email: text.target.value });
  onChangePhoneNo = (text) => this.setState({ phoneNo: text.target.value });
  onChangeName = (text) => this.setState({ name: text.target.value });
  onChangeCompanyName = (text) =>
    this.setState({ companyName: text.target.value });
  onChangeDate = (unformattedDate) => {
    let date = moment(unformattedDate).format("MM-DD-YYYY");
    this.setState({
      date,
      unformattedDate,
      time: undefined,
      availabilities: {},
      isloading: true,
    });
    getDemostrationSlots({ date }).then((res) => {
      const availabilities = { ...res };
      this.setState({ isloading: false, availabilities });
    });
  };
  setLanguage = (lang) => {
    this.setState({ lang });
    this.props.history.replace({
      search: `?lang=${lang}`,
    });
  };

  checkValidation() {
    const { name, email, companyName, date, time, phoneNo, labels, lang } =
      this.state;
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const telephoneRegex =
      /[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]/g;
    if (!name) {
      this.setState({
        formErrors: {
          nameError: labels[lang].nameError,
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            nameError: "",
          },
        });
      }, 8000);
    } else if (!companyName) {
      this.setState({
        formErrors: {
          companyNameError: labels[lang].companyError,
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            companyNameError: "",
          },
        });
      }, 8000);
    } else if (!phoneNo) {
      this.setState({
        formErrors: {
          phoneNoError: labels[lang].phoneNoError,
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            phoneNoError: "",
          },
        });
      }, 8000);
    } else if (!phoneNo.replace(/\D/g, "").match(telephoneRegex)) {
      this.setState({
        formErrors: {
          phoneNoError: labels[lang].phoneNoInvalid,
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            phoneNoError: "",
          },
        });
      }, 8000);
    } else if (!email) {
      this.setState({
        formErrors: {
          emailError: labels[lang].emailError,
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            emailError: "",
          },
        });
      }, 8000);
    } else if (!email.match(emailRegex)) {
      this.setState({
        formErrors: {
          emailError: labels[lang].emailInvalid,
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            emailError: "",
          },
        });
      }, 8000);
    } else if (!date) {
      this.setState({
        formErrors: {
          dateError: labels[lang].dateError,
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            dateError: "",
          },
        });
      }, 8000);
    } else if (!time) {
      this.setState({
        formErrors: {
          timeError: labels[lang].timeError,
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            timeError: "",
          },
        });
      }, 8000);
    } else {
      this.handleLogin();
    }
  }

  handleLogin = () => {
    const { name, email, companyName, date, time, phoneNo, labels, lang } =
      this.state;
    const payload = {
      name,
      email,
      companyName,
      date,
      time,
      phoneNo: phoneNo.replace(/\D/g, ""),
    };
    createDemostrationReservation(payload).then((res) => {
      if (res.success) {
        SuccessHelper.handleSuccess(
          true,
          true,
          labels[lang].success,
          () => {
            this.props.history.replace("/");
          },
          {
            html: labels[lang].successMessage,
          }
        );
      } else {
        ErrorHelper.handleErrors(
          "This time frame is no longer available",
          true
        );
        this.setState({ isloading: true });
        getDemostrationSlots({ date }).then((res) => {
          const availabilities = { ...res };
          this.setState({ isloading: false, availabilities });
        });
      }
    });
  };

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  renderEnterName = () => {
    const { formErrors, labels, lang } = this.state;
    return (
      <div className="form-label-group mb-4 ">
        {/* <label for="inputname">Email</label> */}
        <input
          type="text"
          id="inputname"
          className="form-control mt-1"
          placeholder={labels[lang].name}
          onChange={(text) => this.onChangeName(text)}
          value={this.state.name}
        />
        {formErrors.nameError ? (
          <small className="form-text text-danger ml-3">
            {formErrors.nameError}
          </small>
        ) : null}
      </div>
    );
  };

  renderEnterCompanyName = () => {
    const { formErrors, labels, lang } = this.state;
    return (
      <div className="form-label-group mb-4 ">
        {/* <label for="inputname">Email</label> */}
        <input
          type="text"
          id="inputname"
          className="form-control mt-1"
          placeholder={labels[lang].companyName}
          onChange={(text) => this.onChangeCompanyName(text)}
          value={this.state.companyName}
        />
        {formErrors.companyNameError ? (
          <small className="form-text text-danger ml-3">
            {formErrors.companyNameError}
          </small>
        ) : null}
      </div>
    );
  };

  renderEnterTelephoneNo = () => {
    const { formErrors, labels, lang } = this.state;
    return (
      <div className="form-label-group mb-4 ">
        {/* <label for="inputname">Email</label> */}
        <input
          type="text"
          id="inputname"
          className="form-control mt-1"
          placeholder={labels[lang].phoneNo}
          type={"numeric"}
          onChange={(text) => this.onChangePhoneNo(text)}
          value={this.state.phoneNo}
        />
        {formErrors.phoneNoError ? (
          <small className="form-text text-danger ml-3">
            {formErrors.phoneNoError}
          </small>
        ) : null}
      </div>
    );
  };

  renderEmail = () => {
    const { formErrors, labels, lang } = this.state;
    return (
      <div className="form-label-group mb-4 ">
        {/* <label for="inputname">Email</label> */}
        <input
          type="text"
          id="inputname"
          className="form-control mt-1"
          placeholder={labels[lang].email}
          onChange={(text) => this.onChangeEmail(text)}
          value={this.state.email}
        />
        {formErrors.emailError ? (
          <small className="form-text text-danger ml-3">
            {formErrors.emailError}
          </small>
        ) : null}
      </div>
    );
  };

  renderDate = () => {
    const { formErrors, labels, lang } = this.state;
    return (
      <div className="form-label-group mb-4 fullwidth">
        {/* <label for="inputname">Email</label> */}
        <DatePicker
          className="form-control mt-1"
          minDate={new Date()}
          selected={this.state.unformattedDate}
          onChange={(date) => this.onChangeDate(date)}
          dayClassName={(date) => {
            if (date.getDay() == 0) {
              return "disabled-date";
            }
          }}
          placeholderText={labels[lang].date}
        />
        {formErrors.dateError ? (
          <small className="form-text text-danger ml-3">
            {formErrors.dateError}
          </small>
        ) : null}
      </div>
    );
  };

  renderTime = () => {
    const { formErrors, time, unformattedDate, availabilities, labels, lang } =
      this.state;
    const timeSlots = [
      { value: "9", text: "9AM - 10AM" },
      { value: "10", text: "10AM - 11AM" },
      { value: "11", text: "11AM - 12PM" },
      { value: "12", text: "12PM - 1PM" },
      { value: "1", text: "1PM - 2PM" },
      { value: "2", text: "2PM - 3PM" },
      { value: "3", text: "3PM - 4PM" },
      { value: "5", text: "4PM - 5PM" },
      ...(unformattedDate && unformattedDate.getDay() != 6
        ? [
            { value: "6", text: "6PM - 7PM" },
            { value: "7", text: "7PM - 8PM" },
            { value: "8", text: "8PM - 9PM" },
          ]
        : []),
    ];
    return (
      <div className="form-label-group mb-4 fullwidth">
        <select
          id="function-role"
          class="form-control mt-1"
          onChange={(e) => this.setState({ time: e.target.value })}
        >
          <option selected={!time} value={""}>
            {labels[lang].time}
          </option>
          {timeSlots.map((slot) => {
            if (availabilities[slot.value] < 3) {
              return (
                <option selected={time == slot.value} value={slot.value}>
                  {slot.text}
                </option>
              );
            }
          })}
        </select>
        {formErrors.timeError ? (
          <small className="form-text text-danger ml-3">
            {formErrors.timeError}
          </small>
        ) : null}
      </div>
    );
  };

  renderBookingBtn = () => {
    const { labels, lang } = this.state;
    return (
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <button
          className="btn btn-lg"
          type="submit"
          onClick={() => this.checkValidation()}
        >
          {labels[lang].bookNow}
        </button>
      </div>
    );
  };

  renderLoginForm = () => {
    return (
      <form action="javascript:void(0)" className="form-signin  px-4">
        {this.renderEnterName()}
        {this.renderEnterCompanyName()}
        {this.renderEnterTelephoneNo()}
        {this.renderEmail()}
        {this.renderDate()}
        {this.renderTime()}
        {this.renderBookingBtn()}
      </form>
    );
  };

  renderLoginCard = () => {
    const { lang, labels } = this.state;
    return (
      <div className="card card-signin my-5">
        <div className="card-body px-0">
          <h5 className="card-title text-center">{labels[lang].title}</h5>
          <div className="row" style={{ justifyContent: "center" }}>
            <input
              type="radio"
              id="fr"
              name="lang"
              value="fr"
              class="mx-2 mt-1"
              checked={lang == "fr"}
              onChange={() => this.setLanguage("fr")}
            />
            <label for="fr">Français</label>
            <input
              type="radio"
              id="en"
              name="lang"
              value="en"
              class="mx-2 mt-1"
              checked={lang == "en"}
              onChange={() => this.setLanguage("en")}
            />
            <label for="en">English</label>
          </div>
          <div className="row">
            <hr className="my-4 mx-3 " style={{ width: "100%" }} />
          </div>
          {this.renderLoginForm()}
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="container-fluid px-0">
        {this.renderLoader()}
        <Navbar {...this.props} isBackgroundColor={true} />
        <div className="container" style={{ backgroundColor: "#F8FDFF" }}>
          <div className="row mt-5">
            <div className="col-sm-11 mx-auto mt-5">
              {this.renderLoginCard()}
            </div>
          </div>
        </div>
        <Footer {...this.props} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(DemonstrationReservation);
