/* eslint-disable no-use-before-define */
import React, { useEffect, useState, useRef } from "react"
import TextField from "@material-ui/core/TextField"
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import { Checkbox } from "@material-ui/core"

const MultiSelect = ({
  items,
  selectAllLabel,
  noOptionsText,
  limitTags,
  onChange,
  renderInput,
  data,
  open,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([])
  const allSelected = items.length === selectedOptions.length
  const handleToggleOption = (selectedOptions) =>
    setSelectedOptions(selectedOptions)
  const handleClearOptions = () => setSelectedOptions([])
  const getOptionLabel = (option) => `${option.name}`
  const handleSelectAll = (isSelected) => {
    if (isSelected) {
      setSelectedOptions(items)
    } else {
      handleClearOptions()
    }
  }
  const [loadSelectedServices, setLoadSelectedServices] = useState(false)
  useEffect(() => {
    if (data?.services && !loadSelectedServices) {
      setSelectedOptions(data.services)
      setLoadSelectedServices(true)
    }
  }, [data])
  useEffect(() => {
    if (!open) {
      setLoadSelectedServices(false)
    }
  }, [open])
  const handleToggleSelectAll = () => {
    handleSelectAll && handleSelectAll(!allSelected)
  }

  const handleChange = (event, selectedOptions, reason) => {
    if (reason === "select-option" || reason === "remove-option") {
      if (selectedOptions.find((option) => option.value === "select-all")) {
        handleToggleSelectAll()

        let result = []
        if (event.target.checked) {
          result = items.filter((el) => el.value !== "select-all")
        }

        return onChange({ ...data, services: result })
      } else {
        handleToggleOption && handleToggleOption(selectedOptions)
        return onChange({ ...data, services: selectedOptions })
      }
    } else if (reason === "clear") {
      handleClearOptions && handleClearOptions()
      return onChange({ ...data, services: [] })
    }
  }
  const optionRenderer = (option, { selected }) => {
    const selectAllProps =
      option.value === "select-all" // To control the state of 'select-all' checkbox
        ? { checked: allSelected }
        : {}
    return (
      <>
        <Checkbox
          color="primary"
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          style={{ marginRight: 8 }}
          checked={selected}
          {...selectAllProps}
        />
        {getOptionLabel(option)}
      </>
    )
  }

  const getOptionSelected = (option, anotherOption) =>
    option.value === anotherOption.value
  const filter = createFilterOptions()
  return (
    <Autocomplete
      multiple
      limitTags={limitTags}
      options={items}
      value={selectedOptions}
      disableCloseOnSelect
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      noOptionsText={noOptionsText}
      filterOptions={(options, params) => {
        const filtered = filter(options, params)
        return [{ name: selectAllLabel, value: "select-all" }, ...filtered]
      }}
      onChange={handleChange}
      renderOption={optionRenderer}
      renderInput={renderInput}
    />
  )
}

MultiSelect.defaultProps = {
  limitTags: 5,
  items: [],
  selectedValues: [],
  getOptionLabel: (value) => value,
}

export default MultiSelect
