import { useDispatch } from "react-redux"
import React, { useEffect, useState } from "react"
import i18n from "../../../i18n"
import PrimaryButton from "../../../components/Core/PrimaryButton"
import { DotsLoader } from "../../../components"
import { ErrorHelper, SuccessHelper } from "../../../helpers"
import ActivityUpsert from "./Components/ActivityUpsert"
import ActivityTable from "./Components/ActivityTable"
import { useSelector } from "react-redux"
import actions from "../../../redux/actions/Saloon/Activity"
import activityCategoryActions from "../../../redux/actions/Saloon/ActivityCategory"
const { getRequest: getCategoryRequest } = activityCategoryActions
const { upsertRequest, getRequest } = actions
import useScreenSize from "../../../context/ScreenSize"
export default function Activity({}) {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [active, setActive] = useState(false)
  const data = useSelector((s) => s.activity.data || [])
  const loading = useSelector((s) => s.activity.isFetching)
  const { screenSize } = useScreenSize()
  useEffect(() => {
    dispatch(getRequest({}))
    dispatch(getCategoryRequest({}))
  }, [])
  const onClose = () => {
    setOpen(false)
    setActive({})
  }
  const onSave = (data) => {
    dispatch(
      upsertRequest(data, {
        success: ({ data }) => {
          SuccessHelper.handleSuccess(
            i18n.t(data._id ? "successfully_updated" : "successfully_added"),
            true
          )
          setActive(data)
        },
        failure: () => {
          ErrorHelper.handleErrors(
            i18n.t(data._id ? "failed_to_update" : "failed_to_add"),
            true
          )
        },
      })
    )
  }
  const onEdit = (data) => {
    setActive(data)
    setOpen(true)
  }
  return (
    <div className="content-container p-4">
      <DotsLoader isloading={loading} />
      <div
        style={
          screenSize === "mobile"
            ? {
                position: "sticky",
                top: "0",
                zIndex: "1",
                backgroundColor: "white",
                paddingBottom: "1rem",
              }
            : {}
        }
        className="d-flex flex-column flex-md-row justify-content-between align-items-center"
      >
        <h1 className="saloon-dashboard-heading pb-4">
          {i18n.t("activities")}
        </h1>
        <PrimaryButton
          className="w-128"
          label={i18n.t("create")}
          onClick={() => {
            setOpen(true)
          }}
        />
      </div>
      <ActivityTable rows={data} onEdit={onEdit} />

      <ActivityUpsert
        open={open}
        active={active}
        onClose={onClose}
        onSave={onSave}
      />
    </div>
  )
}
