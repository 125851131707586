import { customer_district } from "../../../config/WebServices"
import SagaBuilder from "../../builders/SagaBuilder"
import actions from "../../actions/Saloon/CustomerDistrict"

const customerDistrict = SagaBuilder.build({
  type: "CUSTOMER_DISTRICT",
  endpoint: customer_district,
  actions,
})

export default customerDistrict
