import React from "react"
import BaseModal from "../BaseModal"
import "./style.scss"

export const AmnouncementModal = ({
  isModalOpen,
  setModalOpen,
  title,
  content,
}) => {
  return (
    <BaseModal
      open={isModalOpen}
      {...{
        title: <div dangerouslySetInnerHTML={{ __html: title }} />,
        content: (
          <>
            {content ? (
              <div
                className="h-100"
                style={{ color: "black" }}
                dangerouslySetInnerHTML={{ __html: content }}
              />
            ) : (
              <div />
            )}
          </>
        ),
      }}
      onClose={() => setModalOpen(false)}
    />
  )
}

export default AmnouncementModal
