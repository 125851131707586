import { useDispatch } from "react-redux"
import React, { useEffect, useState } from "react"
import i18n from "../../../i18n"
import {
  getRequest,
  upsertRequest,
} from "../../../redux/actions/Saloon/CompanySubscription"
import PrimaryButton from "../../../components/Core/PrimaryButton"
import { DotsLoader } from "../../../components"
import { ErrorHelper, SuccessHelper } from "../../../helpers"
import { useSelector } from "react-redux"
import SubscriptionUpsert from "./Components/SubscriptionUpsert"
import SubscriptionTable from "./Components/SubscriptionTable"
import useCache from "../../../context/LocalCache"
import useScreenSize from "../../../context/ScreenSize"
export default function Subscription({}) {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const { getResource } = useCache()
  const allServices = getResource((cache) => cache.company.services)
  const [active, setActive] = useState(false)
  const customerGroups = useSelector((s) => s.companySubscription.data || [])
  const loading = useSelector((s) => s.companySubscription.isFetching)
  const { screenSize } = useScreenSize()
  useEffect(() => {
    dispatch(getRequest({}))
  }, [])
  const onClose = () => {
    setOpen(false)
    setActive({})
  }
  const onSave = (data) => {
    dispatch(
      upsertRequest(data, {
        success: ({ data }) => {
          SuccessHelper.handleSuccess(
            i18n.t(data._id ? "successfully_updated" : "successfully_added"),
            true
          )
          setActive(data)
        },
        failure: () => {
          ErrorHelper.handleErrors(
            i18n.t(data._id ? "failed_to_update" : "failed_to_add"),
            true
          )
        },
      })
    )
  }
  const onEdit = (data) => {
    setActive(data)
    setOpen(true)
  }
  return (
    <div className="content-container p-4">
      <DotsLoader isloading={loading} />
      <div
        style={
          screenSize === "mobile"
            ? {
                position: "sticky",
                top: 0,
                zIndex: 1,
                backgroundColor: "white",
              }
            : {}
        }
        className="d-flex flex-column flex-md-row gap-2 justify-content-between align-items-center mb-4"
      >
        <span className="saloon-dashboard-heading">
          {i18n.t("subscriptions")}
        </span>
        <PrimaryButton
          className="w-128"
          label={i18n.t("create")}
          onClick={() => {
            setOpen(true)
          }}
        />
      </div>
      <SubscriptionTable rows={customerGroups} onEdit={onEdit} />

      <SubscriptionUpsert
        open={open}
        active={active}
        onClose={onClose}
        onSave={onSave}
        allServices={allServices}
      />
    </div>
  )
}
