import React from "react";
import Button from "@material-ui/core/Button";

export default ({
  label,
  onClick = () => { },
  props,
  style = {
    maxHeight: 34,
  },
  className = "",
  children,
  ...more
}) => {
  const defaultStyle = {
    backgroundColor: "#F6943B",
    color: "white",
    borderRadius: 7
  };
  return (
    <Button
      {...props}
      {...more}
      onClick={onClick}
      style={{ ...defaultStyle, ...style }}
      className={className}
    >
      <span className="primaryButtonLabel">{label || children}</span>
    </Button>
  );
};
