import axios from "axios"
import i18n from "i18next"
import FileDownload from "js-file-download"
import {
  get_saloon_plan_Api,
  get_saloon_payment_method_Api,
  get_social_links_Api,
  get_social_employee_link_api,
  update_saloon_social_links_Api,
  get_all_employees,
  create_saloon_schedule,
  get_company_by_employee,
  update_social_employee_link_api,
  delete_salon_by_employee,
  edit_saloon_schedule,
  add_custom_booking_api,
  get_saloon_schedule,
  get_employee_services_Api,
  create_social_employee_link_api,
  create_employee_profile_Api,
  update_employee_profile_Api,
  hire_employee_by_company,
  get_all_employees_associate_with_company,
  get_all_company_employees,
  delete_employee_company,
  saloon_get_categories_Api,
  saloon_create_service_Api,
  saloon_get_all_service_Api,
  saloon_edit_service_Api,
  saloon_update_multiple_services_api,
  saloon_update_multiple_product_api,
  saloon_delete_service_Api,
  get_user_Api,
  edit_user_Api,
  get_template_Api,
  company_update_template_Api,
  company_post_upload_Api,
  company_post_get_Api,
  company_post_delete_Api,
  saloon_edit_company_Api,
  get_saloon_user_id_Api,
  get_saloon_plan_id,
  edit_saloon_plan_Api,
  get_Payment_Method_By_Saloon_Api,
  user_create_profile_picture_Api,
  edit_user_payment_method_Api,
  saloon_nearby_Api,
  edit_saloon_company_logo,
  get_all_booking_by_saloon,
  get_all_saloon_Api,
  get_saloon_template_data,
  get_all_booking_by_employee_Api,
  edit_company_employe,
  get_services,
  employee_post_upload,
  employee_get_posts,
  employee_delete_post,
  edit_employee_profile,
  get_employee_details,
  get_company_employee,
  get_employees_not_associate_with_company,
  add_Companies_By_Employee,
  get_company_portfolio,
  edit_company_user_Api,
  get_employee_by_service_company,
  confirm_booking,
  create_booking,
  edit_booking,
  git_waiting_appoinments,
  edit_customer_profile,
  get_all_bookings_by_user,
  get_all_bookings_by_id,
  get_booking_by_reference_code,
  quick_cancel_booking,
  dismiss_cancelled_booking,
  get_all_bookings_by_saloon,
  get_get_saloon_categories,
  get_saloon_services,
  get_employees_by_saloon_and_service,
  get_home_page_info,
  get_wallet,
  place_Autocomplete_URL,
  forget_password_Api,
  verify_reset_code_Api,
  reset_password_Api,
  get_saloons_by_category_api,
  search_by_name,
  get_home_service_info,
  get_privacy_policy,
  get_cookie_policy,
  get_legal_info,
  get_social_links_for_home,
  get_salon_plains,
  subcribe_email,
  get_faqs,
  add_card_info,
  get_card_info,
  get_methods,
  getAllBundles,
  palns_payment,
  create_Update_template,
  get_customer_template,
  getCompanyBundles,
  updateBundle,
  get_booking_report,
  get_sales_report,
  get_employee_reports,
  get_report,
  google_Api,
  get_ratings_by_company,
  get_ratings_by_employee,
  get_employee_unavailibities,
  get_company_unavailibities,
  employee_unavailibities,
  company_unavailibities,
  get_company_customers,
  get_employee_customers,
  get_demonstration_slots,
  demonstration,
  partner_login,
  live_display_settings,
  company_customer,
  booking_note,
  update_site,
  company_taxes,
  waiting_list,
  products,
  packages,
  giftCards,
  cart,
  add_extra_to_booking,
  remove_extra_to_booking,
  company_checkin_settings,
  inventory_transaction,
  inventory_transaction_increase,
  inventory_transaction_reduce,
  get_employee_by_id,
  company_financial_access,
  one_time_pay,
  stripe_account,
  toggle_online_payments,
  update_interac_transfer_settings,
  update_refund_policy,
  employee_adjust_hours,
  employee_hours_report,
  employee_hours,
  get_company_employee_by_id,
  remove_from_black_list,
  add_to_black_list,
  update_saloon_black_list_Api,
  get_black_list_Api,
  communication_settings,
  form_builder,
  resend_confirmation,
  send_invoice,
  get_product_report,
  get_package_report,
  get_gift_card_report,
  get_commission_report,
  create_saloon_social_links_Api,
  car,
  save_ai_settings,
  company_update_rating_link,
  discount_code,
  intake_category,
  staff_meeting,
  get_priorities,
  booking_reminder,
  get_google_api_refresh_token,
  get_square_api_refresh_token,
  rating,
  get_all_saloons_for_employee,
  get_availables_spots,
} from "./WebServices"
import { google_secret_Key } from "./SecretKeys"
import { getNestedValue } from "../util/objectMethods"

export const objectCrawler = (obj) => {
  if (obj && typeof obj == "object") {
    try {
      if ("translations" in obj) {
        obj.name = getNestedValue(
          obj,
          ["translations", i18n.language],
          obj.name
        )
      }
      Object.keys(obj).map((key) => {
        if (obj[key] && typeof obj[key] == "object") {
          if (Array.isArray(obj[key])) {
            obj[key] = obj[key].map((element) => objectCrawler(element))
          } else {
            obj[key] = objectCrawler(obj[key])
          }
        }
      })
    } catch (e) {}
  }
  return obj
}

axios.interceptors.response.use((res) => objectCrawler(res))

// import moment from "moment";
// import qs from "qs";

// export const Header = {
//     headers: {
//         "Content-Type": "application/x-www-form-urlencoded"
//     }
// };

export const getBookingReports = (params = {}) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_booking_report}`, {
        params,
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getPriority = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_priorities}`)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getSaloonPlan = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_saloon_plan_Api}`)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getCompanyRatings = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_ratings_by_company}`, { params })
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export const getEmployeeRatings = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_ratings_by_employee}`)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getSaloonPaymentMethod = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_saloon_payment_method_Api}`)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getPlains = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_saloon_plan_Api}`)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getSocialLinks = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_social_links_Api}`)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getEmployeeSocialLinks = (companyId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_social_employee_link_api}`, {
        params: {
          companyId: companyId,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const UpdateSaloonSocialMedia = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${update_saloon_social_links_Api}`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else {
          reject(response.data)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export const UpdateSaloonBlackList = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${update_saloon_black_list_Api}`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else {
          reject(response.data)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export const UpdateEmployeeSocialMediaLink = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${update_social_employee_link_api}`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else {
          reject(response.data)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getAllEmployee = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_all_employees}`)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const editCustomerProfile = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${edit_customer_profile}`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export const googleLogin = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${google_Api}`, data, {})
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export const createSaloonSchedule = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${create_saloon_schedule}`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export const upsertRatingForm = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${rating}/upsertRatingForm`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const answerRatingForm = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${rating}/answerRatingForm`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export const getRatingFormByCompanyId = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${rating}/getRatingFormByCompany`, { params })
      .then((response) => {
        if (response.status == 200) {
          resolve(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const addCustomBooking = (data) => {
  console.log("addCustomBooking -> data", data)
  return new Promise((resolve, reject) => {
    axios
      .post(`${add_custom_booking_api}`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response, "responseresponseresponse")
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        console.log(error, "errorerrorerror")
        reject(error)
      })
  })
}

export const getSaloonCategories = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${get_get_saloon_categories}`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export const createEmployeeSocialMediaLink = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${create_social_employee_link_api}`, data, {
        headers: {
          // 'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const createSaloonSocialMediaLink = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${create_saloon_social_links_Api}`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getSaloonServices = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${get_saloon_services}`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getEmployeesBySaloonAndService = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${get_employees_by_saloon_and_service}`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const addCardInfo = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${add_card_info}`, data)
      .then((response) => {
        console.log("add_card_info -> response", response)
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const addCompanyByEmployee = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${add_Companies_By_Employee}`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// add_Companies_By_Employee

export const editSaloonSchedule = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${edit_saloon_schedule}`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const hireCompanyEmployee = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${hire_employee_by_company}`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const deleteEmployeeFromCompany = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      method: "delete",
      // data,
      url: `${delete_employee_company}?employeeId=${data.employeeId}`,
    })
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export const deleteCompanyPost = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      method: "get",
      url: `${company_post_delete_Api}?id=${data._id}`,
    })
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getAllEmployeeAssociatedWithCompany = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_all_employees_associate_with_company}`)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getEmployeeNotAssociatedWithCompany = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_employees_not_associate_with_company}`)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const addPlansPayment = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${palns_payment}`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getCardUserInfo = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_card_info}`)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export const getAllBooking = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_all_booking_by_saloon}`)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getCompletedBooking = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_all_bookings_by_saloon}`, {
        params,
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getSaloonSchedule = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_saloon_schedule}`)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getEmployeeServices = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_employee_services_Api}`)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const createEmployeeProfile = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${create_employee_profile_Api}`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const updateEmployeeProfile = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${update_employee_profile_Api}`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const forgetPassword = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${forget_password_Api}`, data, {})
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const verifyCode = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${verify_reset_code_Api}`, data, {})
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const resetPassword = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${reset_password_Api}`, data, {})
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const saloonGetCategories = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${saloon_get_categories_Api}`)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getCompaniesBundle = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${getCompanyBundles}`)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const saloonCreateService = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${saloon_create_service_Api}`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const saloonGetAllService = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${saloon_get_all_service_Api}`)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const saloonEditService = (data, serviceId) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${saloon_edit_service_Api}?serviceId=${serviceId}`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const updateMultipleService = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${saloon_update_multiple_services_api}`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const updateMultipleProducts = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${saloon_update_multiple_product_api}`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const saloonDeleteService = (serviceId) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${saloon_delete_service_Api}?serviceId=${serviceId}`)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export const deleteSalonByEmployee = (companyId) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${delete_salon_by_employee}`, {
        data: {
          companyId: companyId,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// Get User
export const getUser = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_user_Api}`)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

//Edit User
export const editUser = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${edit_user_Api}`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const editCompanyUser = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${edit_company_user_Api}`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const companyPostUpload = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${company_post_upload_Api}`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getEmployeeeReports = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${get_employee_reports}`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getReports = (data) => {
  const additional = {}
  if (data.exportToXlsx) {
    additional.responseType = "blob"
  }
  return new Promise((resolve, reject) => {
    axios
      .post(`${get_report}`, data, {
        ...additional,
      })
      .then((response) => {
        if (data.exportToXlsx) {
          FileDownload(response.data, "report.xlsx")
          return resolve({})
        }
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getTemplates = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_template_Api}`)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export const getCompanyPost = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${company_post_get_Api}`)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getAllBookingsByUser = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_all_bookings_by_user}`)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getBookingById = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_all_bookings_by_id}`, {
        params,
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const quickCancelBooking = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${quick_cancel_booking}`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const dismissCancelledBooking = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${dismiss_cancelled_booking}`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const companyUpdateTemplate = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${company_update_template_Api}`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getCompanyEmployeeInfo = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${get_company_employee}`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// Edit Company
export const editCompany = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${saloon_edit_company_Api}`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// Get Saloon By User Id
export const getSaloonById = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_saloon_user_id_Api}`)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// Get Saloon Plan By Id
export const getSaloonPlanById = (companyId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_saloon_plan_id}?companyId=${companyId}`)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const editSaloonPlan = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${edit_saloon_plan_Api}`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getPaymentMethodBySaloon = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_Payment_Method_By_Saloon_Api}`)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getWallet = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_wallet}`)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const userCreateProfilePicture = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${user_create_profile_picture_Api}`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const editUserPaymentMethod = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${edit_user_payment_method_Api}`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const saloonNearby = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${saloon_nearby_Api}`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// Edit Saloon Company Logo
export const editCompanyLogo = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${edit_saloon_company_logo}`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// Edit Company Employe
export const editCompanyEmploye = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${edit_company_employe}`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// Edit Booking
export const editBooking = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${edit_booking}`, data)
      .then((response) => {
        console.log("editCompanyEmploye -> response", response)
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// Edit waiting Booking
export const gitWaitingAppoinments = (params = {}) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${git_waiting_appoinments}`, {
        params,
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const gitAllSaloonAppoinments = (params = {}) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_all_booking_by_saloon}`, {
        params,
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export const getHomePageInfo = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_home_page_info}`)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getAllSaloons = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_all_saloon_Api}`, { params })
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getAllSaloonsForEmployee = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_all_saloons_for_employee}`, { params })
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getSaloonTemplateData = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${get_saloon_template_data}`, data, {})
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const subcribeEmail = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${subcribe_email}`, data, {})
      .then((response) => {
        console.log("response: resolve", response)
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getAllBookingByEmployee = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_all_booking_by_employee_Api}`)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getHomeServiceInfo = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_home_service_info}`)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getPrivacyPolicy = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_privacy_policy}`)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getAdminSocialLinksForHome = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_social_links_for_home}`)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getCookiePolicy = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_cookie_policy}`)
      .then((response) => {
        console.log("response: ", response)
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getFaqs = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_faqs}`)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getLegalInfo = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_legal_info}`)
      .then((response) => {
        console.log("response: ", response)
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getServices = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_services}`, {})
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// Employee Upload Media
export const employeePostUpload = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${employee_post_upload}`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getEmployeePosts = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(employee_get_posts)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const employeeDeletePost = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(employee_delete_post, {
        params,
      })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

//Employee Edit Profile
export const employeeEditProfile = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${edit_employee_profile}`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const updateBundlePlan = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${updateBundle}`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// Get Employee Details

export const getEmployeeDetails = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_employee_details}`)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getSalonPlains = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_salon_plains}`)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getAllBundle = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${getAllBundles}`)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getCompanyThatEmployeeAdd = (params = {}) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_company_by_employee}`, {
        params,
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getGoogleApiRefreshToken = (params = {}) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_google_api_refresh_token}`, {
        params,
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getSquareApiRefreshToken = (params = {}) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_square_api_refresh_token}`, {
        params,
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}
// Get Portfolio

export const getCompanyPorfolio = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${get_company_portfolio}`, data, {})
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// Get Employee By Services and Compnay
export const getEmployeeByServiceCompany = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${get_employee_by_service_company}`, data, {})
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const confirmBooking = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${confirm_booking}`, {
        params,
      })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// Create Booking

export const createBooking = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${create_booking}`, data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const createUpdateTemplate = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${create_Update_template}`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        }
      })

      .catch((error) => {
        reject(error)
      })
  })
}

export const getTemplateData = (state) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_customer_template}`, {
        params: {
          templateId: state,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getCardMethods = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_methods}`)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const locationSuggestions = (value) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${place_Autocomplete_URL(
          value,
          "AIzaSyBN4xzZ_BqLc7v4USWM_DdIU5BsrpYJNyw"
        )}`
      )
      .then((response) => {
        console.log("locationSuggestions -> response", response)
        resolve(response)
      })
      .catch((error) => {
        console.log("locationSuggestions -> error", error)
        reject(error)
      })
  })
}

export const searchByName = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${search_by_name}`, data, {}) // POST METHOD ON DEMAND
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getSaloonsByCategory = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${get_saloons_by_category_api}`, data, {}) // POST METHOD ON DEMAND
      .then((response) => {
        console.log(i18n.language)
        if (response.data.success) {
          resolve(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getUnavailabilites = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_employee_unavailibities}`, {
        params,
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response.data.data)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export const getAvailablesSpots = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_availables_spots}`, {
        params,
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response.data.data)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const deleteEmployeeUnavailability = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${employee_unavailibities}`, {
        params,
      })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const createEmployeeUnavailability = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${employee_unavailibities}`, data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export const updateEmployeeUnavailabilityNote = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${employee_unavailibities}/updateNote`, data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export const getCompanyUnavailabilites = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_company_unavailibities}`, {
        params,
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response.data.data)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getCompanyEmployeeUnavaiabilities = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${employee_unavailibities}/getCompanyEmployeeUnavaiabilities`, {
        params,
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response.data.data)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const deleteCompanyUnavailability = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${company_unavailibities}`, {
        params,
      })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const createCompanyUnavailability = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${company_unavailibities}`, data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getLiveDisplaySettings = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${live_display_settings}`, {
        params,
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response.data.data)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const deleteLiveDisplaySettings = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${live_display_settings}`, {
        params,
      })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const createLiveDisplaySettings = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${live_display_settings}`, data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const createLiveDisplaySlideResource = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${live_display_settings}/slideResource`, data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const deleteLiveDisplaySlideResource = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${live_display_settings}/slideResource`, {
        params,
      })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getCompanyCustomers = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_company_customers}`, {
        params,
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response.data.data)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getEmployeeCustomers = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_employee_customers}`)
      .then((response) => {
        if (response.data.success) {
          resolve(response.data.data)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getDemostrationSlots = (params = {}) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_demonstration_slots}`, {
        headers: {},
        params,
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response.data.body)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const createDemostrationReservation = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${demonstration}`, data, {
        headers: {},
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const partnerLogin = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${partner_login}`, data, {
        headers: {},
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const deleteCustomer = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${company_customer}`, {
        params,
      })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const upsertCustomer = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${company_customer}`, data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const importCustomers = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${company_customer}/import`, data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export const getBookingReminder = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${booking_reminder}`, {
        params,
      })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export const getBookingNote = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${booking_note}`, {
        params,
      })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getAllNotesByCustomer = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${booking_note}/${params._id}`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getAllRemindersByCustomer = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${booking_reminder}/${params._id}`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getCustomerBookingHistory = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${company_customer}/bookingHistory`, {
        params,
      })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getCustomerByUserId = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${company_customer}/getCustomerByUserId`, {
        params,
      })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getCustomerById = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${company_customer}/getCustomerById`, {
        params,
      })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export const createUserByCustomerId = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${company_customer}/createUserByCustomerId`, params)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const createCustomerTransaction = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${company_customer}/createTransaction`, payload)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const updateSite = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${update_site}`, data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const deleteBookingNote = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${booking_note}`, {
        params,
      })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const deleteBookingReminder = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${booking_reminder}`, {
        params,
      })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const createBookingReminder = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${booking_reminder}`, data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const updateBookingReminder = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${booking_reminder}`, data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const createBookingNote = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${booking_note}`, data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const updateBookingNote = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${booking_note}`, data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const deleteTax = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${company_taxes}`, {
        params,
      })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getCompanyTaxes = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${company_taxes}`, {
        params,
      })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const upsertTax = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${company_taxes}`, data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getTaxReports = (data) => {
  const additional = {}
  if (data.exportToXlsx) {
    additional.responseType = "blob"
  }
  return new Promise((resolve, reject) => {
    axios
      .post(`${company_taxes}/report`, data, {
        ...additional,
      })
      .then((response) => {
        if (response.data.success || data.exportToXlsx) {
          if (data.exportToXlsx) {
            FileDownload(response.data, "report.xlsx")
            return resolve({})
          }
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getSalesReport = (params) => {
  const additional = {}
  if (params.exportToXlsx) {
    additional.responseType = "blob"
  }
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_sales_report}`, {
        params,
        ...additional,
      })
      .then((response) => {
        if (response.data.success || params.exportToXlsx) {
          if (params.exportToXlsx) {
            FileDownload(response.data, "report.xlsx")
            return resolve({})
          }
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getProductReport = (data) => {
  const additional = {}
  if (data.exportToXlsx) {
    additional.responseType = "blob"
  }
  return new Promise((resolve, reject) => {
    axios
      .post(`${get_product_report}`, data, {
        ...additional,
      })
      .then((response) => {
        if (response.data.success || data.exportToXlsx) {
          if (data.exportToXlsx) {
            FileDownload(response.data, "report.xlsx")
            return resolve({})
          }
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export const getPackageReport = (data) => {
  const additional = {}
  if (data.exportToXlsx) {
    additional.responseType = "blob"
  }
  return new Promise((resolve, reject) => {
    axios
      .post(`${get_package_report}`, data, {
        ...additional,
      })
      .then((response) => {
        if (response.data.success || data.exportToXlsx) {
          if (data.exportToXlsx) {
            FileDownload(response.data, "report.xlsx")
            return resolve({})
          }
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export const getGiftCardReport = (data) => {
  const additional = {}
  if (data.exportToXlsx) {
    additional.responseType = "blob"
  }
  return new Promise((resolve, reject) => {
    axios
      .post(`${get_gift_card_report}`, data, {
        ...additional,
      })
      .then((response) => {
        if (response.data.success || data.exportToXlsx) {
          if (data.exportToXlsx) {
            FileDownload(response.data, "report.xlsx")
            return resolve({})
          }
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export const getCommissionReport = (data) => {
  const additional = {}
  if (data.exportToXlsx) {
    additional.responseType = "blob"
  }
  return new Promise((resolve, reject) => {
    axios
      .post(`${get_commission_report}`, data, {
        ...additional,
      })
      .then((response) => {
        if (response.data.success || data.exportToXlsx) {
          if (data.exportToXlsx) {
            FileDownload(response.data, "report.xlsx")
            return resolve({})
          }
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export const deleteWaitingList = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${waiting_list}`, {
        params,
      })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getWaitingList = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${waiting_list}`, {
        params,
      })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const createWaitingList = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${waiting_list}`, data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const createCustomPackage = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${packages}/customPackage`, data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export const deleteProduct = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${products}`, {
        params,
      })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getProduct = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${products}`, {
        params,
      })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export const getPackage = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${packages}`, {
        params,
      })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export const getGetGiftCard = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${giftCards}`, {
        params,
      })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export const createProduct = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${products}`, data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const updateProduct = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${products}`, data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getCart = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${cart}`, {
        params,
      })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const addExtra = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${add_extra_to_booking}`, data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const removeExtra = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${remove_extra_to_booking}`, {
        params,
      })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const addProduct = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${cart}`, data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const removeProduct = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${cart}`, {
        params,
      })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const updateCartProduct = (params, data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${cart}`, data, {
        params,
      })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const createCartSale = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${cart}/sale`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else {
          reject(response.data)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const upsertCheckinSettings = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${company_checkin_settings}/${data.companyId}`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getInventoryTransactions = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${inventory_transaction}`, {
        params,
      })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const createIncreaseTransaction = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${inventory_transaction_increase}`, data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const createReduceTransaction = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${inventory_transaction_reduce}`, data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getEmployeeById = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_employee_by_id}`, {
        params,
      })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const requestFinancialAccess = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${company_financial_access}/request`, data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const validateFinancialAccess = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${company_financial_access}/validate`, data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const oneTimePayment = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${one_time_pay}`, data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const addAccount = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${stripe_account}`, data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const startAccountOnBoarding = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${stripe_account}/startOnBoarding`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const toggleOnlinePayments = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${toggle_online_payments}`, data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const updateInteracTransferSettings = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${update_interac_transfer_settings}`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else {
          reject(response.data)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const updateRefundPolicy = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${update_refund_policy}`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else {
          reject(response.data)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const adjustWorkingHours = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(employee_adjust_hours, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else {
          reject(response.data)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getPayrollReport = (data) => {
  const additional = {}
  if (data.exportToXlsx) {
    additional.responseType = "blob"
  }
  return new Promise((resolve, reject) => {
    axios
      .post(`${employee_hours_report}`, data, {
        ...additional,
      })
      .then((response) => {
        if (data.exportToXlsx) {
          FileDownload(response.data, "report.xlsx")
          return resolve({})
        }
        if (response.data.success) {
          resolve(response)
        } else {
          reject(response.data)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const addWorkingHours = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${employee_hours}`, data, {})
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else {
          reject(response.data)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getCompanyEmployeeById = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_company_employee_by_id}`, {
        params,
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else {
          reject(response.data)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const removeFromBlackList = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${remove_from_black_list}`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else {
          reject(response.data)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const addToBlackList = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${add_to_black_list}`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else {
          reject(response.data)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getMessageTemplate = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${communication_settings}/getCustomMessage`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else {
          reject(response.data)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const sendForm = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${form_builder}/sendForm`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else {
          reject(response.data)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const resendForm = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${form_builder}/resendForm`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else {
          reject(response.data)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getFormsByBookingId = (params = {}) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${form_builder}/getFormsByBookingId`, {
        params,
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else {
          reject(response.data)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const resendConfirmation = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${resend_confirmation}`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const sendInvoice = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${send_invoice}`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getCarMakes = (params = {}) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${car}/getCarMakes`, {
        params,
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else {
          reject(response.data)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getCarModelsFromMake = (params = {}) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${car}/getCarModelsByMake`, {
        params,
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else {
          reject(response.data)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const saveAiSettings = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${save_ai_settings}`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else if (!response.data.success) {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const updateRatingLink = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${company_update_rating_link}`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response)
        } else {
          reject(response.data)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const verifyDiscountCode = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${discount_code}/verify`, data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getIntakeCategories = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(intake_category)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const upsertIntakeCategories = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(intake_category, data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const upsertIntakeCategoryItem = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${intake_category}/item`, data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const deleteIntakeCategories = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(intake_category, {
        params,
      })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const deleteIntakeCategoryItem = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${intake_category}/item`, {
        params,
      })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const changeProfilePicture = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${company_customer}/changeProfilePicture`, data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const customerStats = (params) => {
  const additional = {}
  if (params.exportToXlsx) {
    additional.responseType = "blob"
  }
  return new Promise((resolve, reject) => {
    axios
      .get(`${company_customer}/stats`, { params, ...additional })
      .then((response) => {
        if (params.exportToXlsx) {
          FileDownload(response.data, "report.xlsx")
          return resolve({})
        }
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const createStaffMeeting = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${staff_meeting}`, data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const updateStaffMeeting = (data) =>
  axios.post(`${staff_meeting}/update`, data)

export const getStaffMeetings = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(staff_meeting, { params })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getStaffMeeting = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${staff_meeting}/getOne`, { params })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const addEmployeeToStaffMeeting = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${staff_meeting}/addEmployee`, data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const removeEmployeeFromStaffMeeting = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${staff_meeting}/removeEmployee`, data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const removeStaffMeeting = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${staff_meeting}/remove`, data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
