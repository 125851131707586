import Swal from "sweetalert2"
import i18n from "../i18n"

export default function restoreConfirmation(callBack, title) {
  Swal.fire({
    title: title,
    text: i18n.t("are_you_sure_restore"),
    icon: "warning",
    showCancelButton: true,
    cancelButtonText: i18n.t("no"),
    confirmButtonColor: "#ff3600",
    cancelButtonColor: "#354e68",
    confirmButtonText: i18n.t("yes"),
  }).then((result) => {
    if (result.value) {
      callBack()
    }
  })
}
