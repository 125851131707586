import { connect } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import i18n from "../../../i18n";
import BaseModal from "../../BaseModal";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import PrimaryButton from "../../Core/PrimaryButton";
import DatePicker from "react-datepicker";
import moment from "moment";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import "./ExpenseUpsert.style.scss";
import CounterInput from "../../CounterInput";

const roundTo2Decimals = (x) => Math.round((x + Number.EPSILON) * 100) / 100;
function ExpenseUpsert({
  open,
  onClose,
  onSave,
  vendors = [],
  active = {},
  taxes,
  presetVendor,
  readOnly,
}) {
  const form = useRef();
  const [data, setData] = useState({});
  const [taxTotal, setTaxTotal] = useState(0);
  const [localTaxes, setLocalTaxes] = useState([]);
  const init = (data) => {
    setData({ ...data });
  };
  useEffect(() => {
    init(active);
  }, []);
  useEffect(() => {
    if (!readOnly) {
      data.taxes = taxes;
      setData({ ...data });
    }
  }, [taxes]);
  useEffect(() => {
    setLocalTaxes(data.taxes || []);
  }, [data.taxes]);
  useEffect(() => {
    if (active._id != data._id) {
      init(active);
    }
  }, [active]);
  const handleChange = (key, value) => {
    data[key] = value;
    setData({ ...data });
  };
  const handleSubmit = () => {
    data.taxes = data.taxes.map((value) => {
      const amount = roundTo2Decimals(
        (value.percentage / 100) * (parseFloat(data.amount) || 0)
      );
      return {
        type: value.name,
        amount,
      };
    });
    onSave(data);
  };

  const calculateTotals = (taxes = [], total) => {
    let taxTotal = 0;
    taxes.map((value) => {
      const amount = roundTo2Decimals(
        (value.percentage / 100) * (parseFloat(total) || 0)
      );
      taxTotal += amount;
    });
    taxTotal = roundTo2Decimals(taxTotal);
    setTaxTotal(taxTotal);
  };

  return (
    <div>
      {open ? (
        <BaseModal
          open={open}
          title={i18n.t(active._id ? "view_expense" : "add_expense")}
          containerClassName="half-container"
          content={
            <div className="d-flex expense-upsert">
              <ValidatorForm
                onSubmit={handleSubmit}
                ref={form}
                className="w-100"
              >
                {!presetVendor ? (
                  <div className="col-12 px-0 d-sm-flex align-items-center mb-3">
                    <label className="font-weight-bold" style={{ minWidth: '120px' }}>{i18n.t("vendor")}</label>
                    <div className="w-100">
                      <SelectValidator
                        className="w-100"
                        label={i18n.t("vendor")}
                        onChange={(e) => {
                          handleChange("vendorId", e.target?.value);
                        }}
                        value={data.vendorId || ""}
                        select
                        validators={["required"]}
                        errorMessages={[i18n.t("vendor_is_required")]}
                        inputProps={{ readOnly }}
                        variant="outlined"
                      >
                        <MenuItem value="" disabled={true}>
                          {i18n.t("vendor")}
                        </MenuItem>
                        {vendors.map((vendor) => (
                          <MenuItem value={vendor._id} key={vendor._id}>
                            {vendor.vendorName}
                          </MenuItem>
                        ))}
                      </SelectValidator>
                    </div>
                  </div>
                ) : null}
                <div className="col-12 px-0 d-sm-flex align-items-center mb-3">
                  <label className="font-weight-bold" style={{ minWidth: '120px' }}>{i18n.t("date")}</label>
                  <div className="w-100">
                    <DatePicker
                      wrapperClassName="w-100"
                      selected={data.date ? moment(data.date).toDate() : ""}
                      onChange={(e) => handleChange("date", e)}
                      value={data.date ? moment(data.date).toDate() : ""}
                      customInput={
                        <TextValidator
                          label={i18n.t("date")}
                          onChange={(e) => handleChange("date", e.target.value)}
                          value={data.date}
                          validators={["required"]}
                          errorMessages={[i18n.t("date_is_required")]}
                          inputProps={{ readOnly }}
                          variant="outlined"
                        />
                      }
                      placeholderText={i18n.t("date")}
                      readOnly={readOnly}
                    />
                  </div>
                </div>
                <div className="col-12 px-0 d-sm-flex align-items-center mb-3">
                  <label className="font-weight-bold" style={{ minWidth: '120px' }}>{i18n.t("amount")}</label>
                  <div className="w-100">
                    <CounterInput
                      value={data.amount || 0}
                      label={i18n.t("amount")}
                      onChange={(e) => handleChange("amount", e)}
                      onBlur={(e) => {
                        let num = parseFloat(e);
                        if (isNaN(num) || num < 0) {
                          num = 0;
                        }
                        num = Math.round((num + Number.EPSILON) * 100) / 100;

                        handleChange("amount", num);
                        calculateTotals(data.taxes, num);
                      }}
                    />
                  </div>
                </div>
                <div className="col-12 px-0 d-sm-flex align-items-center mb-3">
                  <label className="font-weight-bold" style={{ minWidth: '120px' }}>{i18n.t("taxes")}</label>
                  <div className="w-100">
                    <Autocomplete
                      className={readOnly ? "autocomplete-readonly" : ""}
                      multiple
                      id="taxes"
                      options={taxes}
                      getOptionLabel={(option) => option.name}
                      value={localTaxes}
                      onChange={(e, values) => {
                        handleChange("taxes", values);
                        calculateTotals(values, data.amount);
                      }}
                      disableClearable={readOnly}
                      disableCloseOnSelect={true}
                      disabled={readOnly}
                      renderInput={(params) => {
                        params.inputProps = { ...params.inputProps, readOnly };
                        params.InputProps = { ...params.InputProps, readOnly };
                        return (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={i18n.t("taxes")}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="col-12 px-0 d-sm-flex align-items-center mb-3">
                  <label className="font-weight-bold" style={{ minWidth: '120px' }}>{i18n.t("notes")}</label>
                  <div className="w-100">
                    <TextValidator
                      className="w-100"
                      label={i18n.t("notes")}
                      onChange={(e) => handleChange("notes", e.target.value)}
                      value={data.notes || ""}
                      inputProps={{ readOnly }}
                      variant="outlined"
                    />
                  </div>
                </div>
                <div className="col-12 px-0 d-sm-flex align-items-center mb-3">
                  <label className="font-weight-bold" style={{ minWidth: '120px' }}>{i18n.t("tax_total")}</label>
                  <div className="w-100">
                    <CounterInput
                      value={taxTotal || 0}
                      label={i18n.t("tax_total")}
                      readOnly={true}
                    />
                  </div>
                </div>
                <div className="col-12 px-0 d-sm-flex align-items-center mb-3">
                  <label className="font-weight-bold" style={{ minWidth: '120px' }}>{i18n.t("grand_total")}</label>
                  <div className="w-100">
                    <CounterInput
                      value={parseFloat(data.amount || 0) + (taxTotal || 0)}
                      label={i18n.t("grand_total")}
                      readOnly={true}
                    />
                  </div>
                </div>
                {!readOnly ? (
                  <div className="row mx-0 px-0 justify-content-end my-4 col-12">
                    <PrimaryButton
                      className="w-128"
                      props={{
                        type: "submit",
                      }}
                      label={data._id ? i18n.t("save") : i18n.t("create")}
                    />
                  </div>
                ) : null}
              </ValidatorForm>
            </div>
          }
          onClose={onClose}
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  active: state.expense.active,
  loading: state.expense.isFetching,
});

const action = {};

export default connect(mapStateToProps, action)(ExpenseUpsert);
