import React from "react";
import styled from "styled-components";

const FormSection = styled.p`
  width: 100%;
`;

export default function GenerateSectionField({ field }) {
  return <FormSection>{field.fieldName}</FormSection>;
}
