import React from "react";
import TextField from "@material-ui/core/TextField";
import styled from "styled-components";
import DetailsField from "./DetailsField.component";

const DatefieldWrapper = styled.div`
  width: 100%;
`;
const GenerateDateField = ({
  field,
  handleSubmit,
  readonly,
  error,
  answer,
  detailsRequired,
  details,
}) => {
  const handleOnChange = (e, choice = null, type) => {
    if (handleSubmit !== undefined) {
      handleSubmit(e.target.value, field._id, type);
    }
  };
  return (
    <DatefieldWrapper>
      <h6>{field.fieldName}</h6>
      <TextField
        type={field.type}
        onChange={(e) => handleOnChange(e, null, "answer")}
        variant="outlined"
        disabled={readonly}
        InputLabelProps={{
          shrink: true,
        }}
        error={!!error}
        helperText={error}
        value={answer}
      />
      {detailsRequired && (
        // <TextField
        //   onChange={(e) => handleOnChange(e, "details")}
        //   value={details}
        //   type="text"
        //   variant="outlined"
        //   inputProps={{ readOnly: readonly }}
        // />
        <DetailsField
          handleOnChange={handleOnChange}
          details={details}
          readonly={readonly}
        />
      )}
    </DatefieldWrapper>
  );
};

export default GenerateDateField;
