import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import "../Styles/selectOptions.sass";

class SelectOption extends Component {
  handleSelectFields = (e) => {
    // console.log(e.value);
    this.props.handleSelect(e.target.value);
  };

  render() {
    const { fieldOptions, fieldOptionsError } = this.props;
    return (
      <React.Fragment>
        <h4 className="radioTitle">Please specify options for Select:</h4>

        <TextField
          label='Enter Options separated by "-" (Hyphen symbol)'
          onChange={(e) => this.handleSelectFields(e)}
          variant="outlined"
          fullWidth={true}
          value={fieldOptions}
        />

        {fieldOptionsError ? <span>{fieldOptionsError}</span> : ""}
      </React.Fragment>
    );
  }
}

export default SelectOption;
