// @flow

import { GIFT_CARDS } from "./ActionTypes"
export function getRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: GIFT_CARDS.GET_SAGA,
  }
}

export function get(data) {
  return {
    data,
    type: GIFT_CARDS.GET,
  }
}

export function insertRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: GIFT_CARDS.INSERT_SAGA,
  }
}

export function insert(data) {
  return {
    data,
    type: GIFT_CARDS.INSERT,
  }
}

export function updateRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: GIFT_CARDS.UPDATE_SAGA,
  }
}

export function remove(data) {
  return {
    data,
    type: GIFT_CARDS.DELETE,
  }
}

export function removeRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: GIFT_CARDS.DELETE_SAGA,
  }
}

export function update(data) {
  return {
    data,
    type: GIFT_CARDS.UPDATE,
  }
}

export function setActive(data) {
  return {
    data,
    type: GIFT_CARDS.SET_ACTIVE,
  }
}

export function soldGiftCardRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: GIFT_CARDS.SOLD_SAGA,
  }
}

export function getUserGiftCardsRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: GIFT_CARDS.GET_USER_GIFT_CARDS_SAGA,
  }
}

export function getUserGiftCards(data) {
  return {
    data,
    type: GIFT_CARDS.GET_USER_GIFT_CARDS,
  }
}

export function getUserWithCompanyGiftCardsRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: GIFT_CARDS.GET_USER_WITH_COMPANY_GIFT_CARDS_SAGA,
  }
}

export function getUserWithCompanyGiftCards(data) {
  return {
    data,
    type: GIFT_CARDS.GET_USER_WITH_COMPANY_GIFT_CARDS,
  }
}


export function soldGiftCard(data) {
  return {
    data,
    type: GIFT_CARDS.SOLD,
  }
}

export function getSoldGiftCardRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: GIFT_CARDS.GET_SOLD_SAGA,
  }
}

export function getSoldGiftCard(data) {
  return {
    data,
    type: GIFT_CARDS.GET_SOLD,
  }
}

export function requestFailure() {
  return {
    type: GIFT_CARDS.REQUEST_FAILURE,
  }
}
