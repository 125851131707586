import React, { useEffect, useMemo, useRef, useState } from "react"
import {
  SelectValidator,
  TextValidator,
  ValidatorForm,
} from "react-material-ui-form-validator"
import MenuItem from "@material-ui/core/MenuItem"
import i18n from "../../../../../i18n"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import ReactDatePicker from "react-datepicker"
import { BaseModal } from "../../../../../components"
import PrimaryButton from "../../../../../components/Core/PrimaryButton"
import Tabs from "../../../../../components/Core/Tabs/Tabs"
import BasicTable from "../../../../../components/Core/BasicTable"
import { Checkbox, TextField } from "@material-ui/core"
import EnrollCustomers from "./Components/EnrollCustomers"
import { Autocomplete } from "@material-ui/lab"
import {
  getByActivityReferenceRequest,
  addTransactionRequest,
} from "../../../../../redux/actions/Saloon/ActivityScheduleEvent"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import InsertPayment from "../../../../../components/CreateInvoice/components/InsertPayment.component"
import DotsLoader from "../../../../../components/DotsLoader"
import { ErrorHelper, SuccessHelper } from "../../../../../helpers"
export default function AddScheduleEvent({
  open,
  setOpen,
  employees,
  employeeDetails,
  defaults = {},
  onAdd,
  onDelete,
  active = {},
  setActive = () => {},
  onSetCustomers,
  onSetAttendance,
  openTab,
  setOpenTab,
  activitySessionData,
  setActivitySessionData,
}) {
  const form = useRef()
  const [data, setData] = useState({})
  const [search, setSearch] = useState("")
  const [attendingCustomerMap, setAttendingCustomerMap] = useState({})
  const [enrollCustomersOpen, setEnrollCustomersOpen] = useState(false)
  const [companyEmployee, setCompanyEmployee] = useState()
  const [numberOfSessions, setNumberOfSessions] = useState()
  const [sessions, setSessions] = useState({})
  const dispatch = useDispatch()
  const activities = useSelector((s) => s.activity.data)
  const activitySessions = useSelector(
    (s) => s.activityScheduleEvent.activitySessions
  )
  const [activityToCollect, setActivityToCollect] = useState({ price: 0 })
  const [paymentOpen, setPaymentOpen] = useState(false)
  const [includeTaxes, setIncludeTaxes] = useState(true)
  const [loading, setLoading] = useState(false)
  const items = [
    {
      name: activityToCollect?.name,
      price: activityToCollect?.price,
    },
  ]
  const { price = 0 } = activityToCollect
  const taxDue = activityToCollect?.taxes?.reduce(
    (accum, tax) => accum + tax.amount,
    0
  )
  const collected = activityToCollect?.transactions?.reduce((accum, e) => {
    if (!e.wasReversed) {
      return accum + e.amount + (e.discount || 0) - e.tip
    }
    return accum
  }, 0)
  const grandTotal = (includeTaxes ? taxDue : 0) + price - collected
  const [showSessionEnrollmentModal, setShowSessionEnrollmentModal] =
    useState(false)
  const employeesMap = useMemo(() => {
    const map = employees.map((employee) => {
      return {
        value: employee._id,
        name: `${employee.employee.userId.firstName || ""} ${
          employee.employee.userId.lastName || ""
        }`.trim(),
        type: employee.isInstructor ? i18n.t("instructor") : i18n.t("employee"),
      }
    })
    map.sort((a, b) => (a.type > b.type ? 1 : -1))
    return map
  }, [employees])
  useEffect(() => {
    if (open) {
      dispatch(
        getByActivityReferenceRequest({
          activityReference: active.activityReference,
        })
      )

      setData({
        ...active,
        date: moment(active.startDate).toDate(),
        startTime: moment(active.startDate).format("HH:mm"),
        endTime: moment(active.endDate).format("HH:mm"),
      })

      setEnrollCustomersOpen(false)

      const map = {}
      ;(
        (showSessionEnrollmentModal
          ? activitySessionData?.customers
          : active?.customers) || []
      ).map((customer) => {
        map[customer._id._id] = customer.attended
      })
      setAttendingCustomerMap(map)
      setCompanyEmployee(
        employeesMap.find(
          (employee) => employee.value == active.companyEmployeeId
        )
      )
    }
  }, [open, active._id, active.customers, activitySessionData.customers])

  const handleAdd = () => {
    onAdd(data, sessions)
  }
  useEffect(() => {
    setSearch("")
  }, [showSessionEnrollmentModal])
  const checkTimes = () => {
    const { startTime, endTime } = data
    if (startTime && endTime) {
      const momentStart = moment(startTime, "hh:mm")
      const momentEnd = moment(endTime, "hh:mm")
      if (momentStart.isAfter(momentEnd)) {
        setData({ ...data, startTime: endTime, endTime: startTime })
      }
    }

    Object.values(sessions).map((session) => {
      const { startTime, endTime } = session
      if (startTime && endTime) {
        const momentStart = moment(startTime, "hh:mm")
        const momentEnd = moment(endTime, "hh:mm")
        if (momentStart.isAfter(momentEnd)) {
          session.startTime = endTime
          session.endTime = startTime
          setSessions({ ...sessions })
        }
      }
    })
  }
  useEffect(checkTimes, [data.startTime, data.endTime, sessions])
  useEffect(() => {
    setData(defaults)
    if (employeeDetails) {
      setData({
        ...data,
        companyEmployeeId: employeeDetails?.companyEmployee._id,
      })
    }
  }, [open])

  const onSave = ({
    note,
    cash,
    creditDebit,
    giftCard,
    tip,
    discount,
    chargedAmountByCard,
  }) => {
    setLoading(true)
    dispatch(
      addTransactionRequest(
        {
          _id: activityToCollect._id,
          newTransaction: {
            note,
            cash,
            creditDebit,
            giftCard,
            tip,
            discount,
            chargedAmountByCard,
          },
          includeTaxes,
        },
        {
          success: ({ data: newData }) => {
            setLoading(false)

            const updatedCustomerActivityScheduleEvents =
              active.customerActivityScheduleEvents.map((event) => {
                if (event._id === newData._id) {
                  return newData
                }
                return event
              })

            setActive({
              ...active,
              customerActivityScheduleEvents:
                updatedCustomerActivityScheduleEvents,
            })
            setPaymentOpen(false)
            SuccessHelper.handleSuccess(i18n.t("successfully_added"), true)
          },
          failure: (e) => {
            setLoading(false)
            ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true)
          },
        }
      )
    )
  }
  const customerTab = () => {
    return (
      <div>
        <div className="col-12 flex-column flex-md-row d-flex align-items-md-center gap-2 mb-3 px-0">
          <label className="font-weight-bold mr-3">{i18n.t("search")}</label>
          <TextField
            placeholder={i18n.t("search")}
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            variant="outlined"
          />
          <div className="row gap-1 justify-content-center flex-nowrap ml-2">
            <PrimaryButton
              className="w-98"
              label={i18n.t("enrollment")}
              onClick={() => setEnrollCustomersOpen(true)}
            />
            <PrimaryButton
              label={i18n.t("save")}
              onClick={() => {
                onSetAttendance(
                  attendingCustomerMap,
                  showSessionEnrollmentModal ? activitySessionData : ""
                )
              }}
            />
          </div>
        </div>
        <BasicTable
          rows={filteredCustomers}
          schema={[
            {
              key: "attended",
              header: i18n.t("attended"),
              style: { width: 45 },
              render: (val) => {
                return (
                  <Checkbox
                    edge="start"
                    checked={attendingCustomerMap[val._id._id] || false}
                    tabIndex={-1}
                    onClick={() => {
                      setAttendingCustomerMap({
                        ...attendingCustomerMap,
                        [val._id._id]: !attendingCustomerMap[val._id._id],
                      })
                    }}
                  />
                )
              },
            },
            {
              header: i18n.t("name"),
              field: "name",
              accessor: ({ _id: { name, firstName } }) =>
                `${firstName ? firstName : ""} ${name}`.trim(),
            },
            {
              header: i18n.t("phone_number"),
              field: "phoneNo",
              accessor: ({ _id: { phoneNo } }) => phoneNo,
            },
            {
              header: i18n.t("container.profile_email"),
              field: "email",
              accessor: ({ _id: { email } }) => email,
            },
            {
              key: "balance_due",
              header: i18n.t("balance_due"),
              accessor: (val) => {
                return val?.customerActivityScheduleEvent?.balanceDue?.toFixed(
                  2
                )
              },
            },
            {
              key: "action",
              header: "",
              style: { width: 60 },
              render: (val) => {
                if (
                  !val.customerActivityScheduleEvent ||
                  val.customerActivityScheduleEvent?.balanceDue <= 0
                ) {
                  return null
                }
                return (
                  <div className="row mx-0">
                    <FontAwesomeIcon
                      className="view-icon"
                      color="white"
                      icon={["fas", "hand-holding-usd"]}
                      onClick={() => {
                        setActivityToCollect({
                          ...val.customerActivityScheduleEvent,
                          name: activities.find(
                            ({ _id }) => _id === active?.activityId
                          )?.name,
                        })
                        setPaymentOpen(true)
                      }}
                    />
                  </div>
                )
              },
            },
          ]}
        />
      </div>
    )
  }
  const filteredCustomers = useMemo(() => {
    const customers = (
      showSessionEnrollmentModal
        ? activitySessionData.customers
        : data.customers
    )?.map((customer) => {
      return {
        ...customer,
        customerActivityScheduleEvent:
          active?.customerActivityScheduleEvents?.find(
            ({ companyCustomerId }) =>
              companyCustomerId._id === customer?._id._id
          ),
      }
    })

    if (search) {
      return customers?.filter((customer) => {
        return (
          customer._id.name.toLowerCase().includes(search.toLowerCase()) ||
          customer._id.phoneNo.includes(search.toLowerCase()) ||
          customer._id.email?.toLowerCase().includes(search.toLowerCase()) ||
          customer._id.fistName?.toLowerCase().includes(search.toLowerCase())
        )
      })
    }
    return customers
  }, [
    open,
    data.customers,
    search,
    active.customers,
    active.customerActivityScheduleEvents,
    activitySessionData.customers,
    showSessionEnrollmentModal,
  ])

  if (!open) {
    return null
  }
  return (
    <>
      <DotsLoader isloading={loading} />

      <BaseModal
        open={open}
        style={{ zIndex: 1304 }}
        contentContainerStyle={{
          overflowY: "visible",
        }}
        title={data._id ? i18n.t("edit_schedule") : i18n.t("add_schedule")}
        onClose={() => {
          setOpen(false)
          setSessions({})
          setOpenTab()
          dispatch(getByActivityReferenceRequest({}))
        }}
        containerClassName={"half-container"}
        content={
          <>
            <BaseModal
              open={showSessionEnrollmentModal}
              onClose={() => setShowSessionEnrollmentModal(false)}
              {...{
                content: <>{customerTab()}</>,
                title: i18n.t("customers"),
              }}
            />
            <EnrollCustomers
              setOpen={setEnrollCustomersOpen}
              open={enrollCustomersOpen}
              active={showSessionEnrollmentModal ? activitySessionData : data}
              onSetCustomers={onSetCustomers}
            />

            <InsertPayment
              open={paymentOpen}
              handleClose={() => setPaymentOpen(false)}
              defaultAmount={grandTotal}
              onSubmit={onSave}
              toggleTaxes={() => setIncludeTaxes(!includeTaxes)}
              items={items}
              subtotal={activityToCollect.price}
              taxDue={taxDue}
              includeTaxes={includeTaxes}
              grandTotal={grandTotal}
              collected={collected}
              giftCardBalance={
                activityToCollect?.companyCustomerId?.giftCardBalance
              }
              customerId={activityToCollect?.companyCustomerId?._id}
            />

            <Tabs
              openTab={openTab}
              tabs={[
                {
                  title: i18n.t("details"),
                  content: () => (
                    <div className="row mx-0 ScheduleBuilder">
                      <ValidatorForm
                        onSubmit={handleAdd}
                        ref={form}
                        className="row col-12 px-0 mx-0"
                        onError={(error) => {
                          console.log(error)
                        }}
                      >
                        {!employeeDetails ? (
                          <div className="col-12 d-sm-flex align-items-center mb-3 px-0">
                            <label
                              className="font-weight-bold"
                              style={{ minWidth: "120px" }}
                            >
                              {i18n.t("employees")}
                            </label>
                            <div className="w-100">
                              <Autocomplete
                                id="employees"
                                className="w-100"
                                options={employeesMap}
                                groupBy={(option) => option.type}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                  <TextValidator
                                    {...params}
                                    label={i18n.t("employees")}
                                    value={companyEmployee}
                                    validators={["required"]}
                                    errorMessages={[
                                      i18n.t("this_field_is_required"),
                                    ]}
                                    variant="outlined"
                                  />
                                )}
                                onChange={(e, value) => {
                                  setData({
                                    ...data,
                                    companyEmployeeId: value?.value,
                                  })
                                  setCompanyEmployee(value)
                                }}
                                value={companyEmployee || ""}
                              />
                            </div>
                          </div>
                        ) : null}

                        <div className="col-12 d-sm-flex align-items-center mb-3 px-0">
                          <label
                            className="font-weight-bold"
                            style={{ minWidth: "120px" }}
                          >
                            {i18n.t("activity")}
                          </label>
                          <div className="w-100">
                            <SelectValidator
                              containerProps={{ className: "w-100" }}
                              label={i18n.t("activity")}
                              onChange={(e) => {
                                const activity = activities.find(
                                  (activity) => activity._id == e.target.value
                                )

                                setData({
                                  ...data,
                                  price: activity?.price,
                                  activityId: e.target.value,
                                })
                                setNumberOfSessions(activity?.numberOfSessions)
                              }}
                              value={data.activityId || ""}
                              select
                              validators={["required"]}
                              errorMessages={[i18n.t("this_field_is_required")]}
                              variant="outlined"
                            >
                              {activities.map((activity) => {
                                return (
                                  <MenuItem value={activity._id}>
                                    {activity.name}
                                  </MenuItem>
                                )
                              })}
                            </SelectValidator>
                          </div>
                        </div>

                        {!data._id && data.activityId ? (
                          [...Array(numberOfSessions || 1)].map((e, i) => {
                            return (
                              <div className="row col-12 w-100 mb-3 px-0">
                                <span className="w-100 font-weight-bold">
                                  Session {i + 1} :
                                </span>
                                <ReactDatePicker
                                  popperProps={{
                                    positionFixed: true, // use this to make the popper position: fixed
                                  }}
                                  minDate={new Date()}
                                  wrapperClassName="col-12 col-md-4 col-lg-4 w-100 px-0"
                                  selected={sessions[i]?.date}
                                  dateFormat="MM/dd/yyyy"
                                  onChange={(val) => {
                                    sessions[i] = { ...sessions[i], date: val }
                                    setSessions({ ...sessions })
                                  }}
                                  customInput={
                                    <TextValidator
                                      label={i18n.t("date")}
                                      value={sessions[i]?.date || ""}
                                      validators={["required"]}
                                      errorMessages={[
                                        i18n.t("this_field_is_required"),
                                      ]}
                                      readOnly={true}
                                      variant="outlined"
                                    />
                                  }
                                />
                                <TextValidator
                                  containerProps={{
                                    className:
                                      "col-6 col-md-4 col-lg-4 pl-0 pl-md-2 pl-lg-2",
                                  }}
                                  label={i18n.t("start_time")}
                                  onChange={(e) => {
                                    sessions[i] = {
                                      ...sessions[i],
                                      startTime: e.target.value,
                                    }
                                    setSessions({ ...sessions })
                                  }}
                                  type="time"
                                  value={sessions[i]?.startTime || ""}
                                  validators={["required"]}
                                  errorMessages={[
                                    i18n.t("this_field_is_required"),
                                  ]}
                                  variant="outlined"
                                />
                                <TextValidator
                                  containerProps={{
                                    className: "col-6 col-md-4 col-lg-4 pr-0",
                                  }}
                                  label={i18n.t("end_time")}
                                  onChange={(e) => {
                                    sessions[i] = {
                                      ...sessions[i],
                                      endTime: e.target.value,
                                    }
                                    setSessions({ ...sessions })
                                  }}
                                  type="time"
                                  value={sessions[i]?.endTime || ""}
                                  validators={["required"]}
                                  errorMessages={[
                                    i18n.t("this_field_is_required"),
                                  ]}
                                  variant="outlined"
                                />
                              </div>
                            )
                          })
                        ) : data._id ? (
                          <>
                            <div className="col-12 flex-column flex-md-row d-flex align-items-md-center mb-3 px-0">
                              <label
                                className="font-weight-bold"
                                style={{ minWidth: "120px" }}
                              >
                                {i18n.t("date")}
                              </label>
                              <div className="w-100">
                                <ReactDatePicker
                                  popperProps={{
                                    positionFixed: true, // use this to make the popper position: fixed
                                  }}
                                  wrapperClassName="w-100"
                                  selected={data.date}
                                  dateFormat="MM/dd/yyyy"
                                  onChange={(val) => {
                                    setData({ ...data, date: val })
                                  }}
                                  customInput={
                                    <TextValidator
                                      label={i18n.t("date")}
                                      value={data.date || ""}
                                      validators={["required"]}
                                      errorMessages={[
                                        i18n.t("this_field_is_required"),
                                      ]}
                                      readOnly={true}
                                      variant="outlined"
                                    />
                                  }
                                />
                              </div>
                            </div>
                            <TextValidator
                              containerProps={{ className: "col-6 mb-3 pl-0" }}
                              label={i18n.t("start_time")}
                              onChange={(e) =>
                                setData({ ...data, startTime: e.target.value })
                              }
                              type="time"
                              value={data.startTime || ""}
                              validators={["required"]}
                              errorMessages={[i18n.t("this_field_is_required")]}
                              variant="outlined"
                            />
                            <TextValidator
                              containerProps={{ className: "col-6 mb-3 pr-0" }}
                              label={i18n.t("end_time")}
                              onChange={(e) => {
                                setData({ ...data, endTime: e.target.value })
                              }}
                              type="time"
                              value={data.endTime || ""}
                              validators={["required"]}
                              errorMessages={[i18n.t("this_field_is_required")]}
                              variant="outlined"
                            />
                          </>
                        ) : null}

                        <div className="row mx-0 px-0 justify-content-between mt-4 col-12">
                          {data._id ? (
                            <PrimaryButton
                              className="w-128"
                              onClick={() => onDelete(data._id)}
                              label={i18n.t("delete")}
                            />
                          ) : (
                            <div />
                          )}
                          <PrimaryButton
                            className="w-128"
                            props={{
                              type: "submit",
                            }}
                            label={data._id ? i18n.t("save") : i18n.t("create")}
                          />
                        </div>
                      </ValidatorForm>
                    </div>
                  ),
                },
                {
                  title: i18n.t("customer"),
                  hidden: !data._id,
                  content: () => <>{customerTab()}</>,
                },
                {
                  title: i18n.t("sessions"),
                  hidden: activitySessions.length <= 1,
                  content: () => (
                    <div>
                      <BasicTable
                        rows={activitySessions.filter(
                          ({ _id }) => _id != data._id
                        )}
                        schema={[
                          {
                            header: i18n.t("name"),
                            field: "name",
                            accessor: ({ activityId: { name } }) => name,
                          },
                          {
                            header: i18n.t("date"),
                            field: "date",
                            accessor: ({ startDate }) =>
                              moment(startDate).format("MM-DD-YYYY"),
                          },
                          {
                            header: i18n.t("start_time"),
                            field: "startTime",
                            accessor: ({ startDate }) =>
                              moment(startDate).format("HH:mm a"),
                          },
                          {
                            header: i18n.t("end_time"),
                            field: "endTime",
                            accessor: ({ endDate }) =>
                              moment(endDate).format("HH:mm a"),
                          },
                          {
                            header: i18n.t("action"),
                            field: "from",
                            accessor: (val) => (
                              <PrimaryButton
                                className="px-3"
                                onClick={() => {
                                  setShowSessionEnrollmentModal(true)
                                  setActivitySessionData(val)
                                }}
                                label={i18n.t("enrollment")}
                              />
                            ),
                          },
                        ]}
                      />
                    </div>
                  ),
                },
              ]}
            />
          </>
        }
      />
    </>
  )
}
