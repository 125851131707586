// @flow

import { COMPANY_SUBSCRIPTION } from "../ActionTypes"

export function getRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: COMPANY_SUBSCRIPTION.GET_SAGA,
  }
}

export function get(data) {
  return {
    data,
    type: COMPANY_SUBSCRIPTION.GET,
  }
}

export function removeRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: COMPANY_SUBSCRIPTION.DELETE_SAGA,
  }
}

export function remove(data) {
  return {
    data,
    type: COMPANY_SUBSCRIPTION.DELETE,
  }
}

export function upsertRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: COMPANY_SUBSCRIPTION.UPSERT_SAGA,
  }
}

export function upsert(data) {
  return {
    data,
    type: COMPANY_SUBSCRIPTION.UPSERT,
  }
}

export function requestFailure() {
  return {
    type: COMPANY_SUBSCRIPTION.REQUEST_FAILURE,
  }
}
