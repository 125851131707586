import React from "react";
import i18n from "../../../../i18n";
import Swal from "sweetalert2";
import { ErrorHelper, SuccessHelper } from "../../../../helpers";
import { removeRequest } from "../../../../redux/actions/GiftCards"
import BasicTable from "../../../../components/Core/BasicTable";
import DeleteIcon from "../../../../components/Core/BasicTable/Actions/DeleteIcon";
import EditIcon from "../../../../components/Core/BasicTable/Actions/EditIcon";
import { useDispatch } from "react-redux";
import moment from "moment/moment";
export default function GiftCardsTable({ rows, onEdit }) {
  const dispatch = useDispatch();
  const handleDelete = (_id) => {
    Swal.fire({
      title: i18n.t("are_you_sure_delete"),
      text: i18n.t("cannmot_reverse"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ff3600",
      cancelButtonColor: "#354e68",
      confirmButtonText: i18n.t("yes"),
    }).then((result) => {
      if (result.value) {
        dispatch(
          removeRequest(
            { _id },
            {
              success: () => {
                SuccessHelper.handleSuccess(
                  i18n.t("successfully_deleted"),
                  true
                );
              },
              failure: () => {
                ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
              },
            }
          )
        );
      }
    });
  };
  return (
    <>
      <BasicTable
        rows={rows}
        schema={[
          {
            header: i18n.t("name"),
            field: "name",
          },
          {
            header: i18n.t("price"),
            field: "price",
            accessor: ({ price = 0 }) => {
              return `$${price.toFixed(2)}`;
            },
          },
          {
            header: i18n.t("created_date"),
            field: "createdDate",
            accessor: (val) =>
              moment(val.createdDate).format("MM-DD-YYYY hh:mm A"),
          },
          {
            header: i18n.t("status"),
            field: "status",
          },
          {
            key: "action",
            header: i18n.t("actions"),
            style: { width: 120 },
            render: (val) => {
              return (
                <>
                  <EditIcon onClick={() => onEdit(val)} />
                  <DeleteIcon onClick={() => handleDelete(val._id)} />
                </>
              );
            },
          },
        ]}
      />
    </>
  );
}
