import React, { useEffect, useRef, useState } from "react";
// import Packages from "../../components/Packages/Packages.component";
import i18n from "../../../i18n";
import BaseModal from "../../BaseModal";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import MenuItem from "@material-ui/core/MenuItem";
import PrimaryButton from "../../Core/PrimaryButton";
import DatePicker from "react-datepicker";

export default function Packages({ open, onClose, onSave }) {
  const form = useRef();
  const [data, setData] = useState({});
  useEffect(() => {
    setData({ status: "Active" });
    ValidatorForm.addValidationRule("isValidDate", (value) => {
      return true;
    });
  }, []);
  const handleChange = (key, value) => {
    data[key] = value;
    setData({ ...data });
  };
  const handleSubmit = () => {
    onSave(data);
  };

  return (
    <div>
      {open ? (
        <BaseModal
          open={open}
          title={i18n.t("create_form")}
          content={
            <>
              <div className="d-flex">
                <ValidatorForm
                  onSubmit={handleSubmit}
                  ref={form}
                  className="w-100"
                  onError={(error) => {
                    if (!error.length) {
                      onSave(data);
                    }
                  }}
                >
                  <TextValidator
                    label={i18n.t("name")}
                    onChange={(e) => handleChange("formTitle", e.target.value)}
                    value={data.formTitle}
                    validators={["required"]}
                    errorMessages={[i18n.t("name_is_required")]}
                  />
                  <TextValidator
                    label={i18n.t("formDescription")}
                    onChange={(e) =>
                      handleChange("formDescription", e.target.value)
                    }
                    value={data.formDescription}
                    validators={["required"]}
                    errorMessages={[i18n.t("description_is_required")]}
                    rows={2}
                  />
                  <SelectValidator
                    label={i18n.t("status")}
                    onChange={(e) => {
                      handleChange("status", e.props?.value);
                    }}
                    value={data.status}
                    defaultValue={"Active"}
                    validators={["required"]}
                    errorMessages={[i18n.t("status_is_required")]}
                    select
                  >
                    <MenuItem value="Active">{i18n.t("Active")}</MenuItem>
                    <MenuItem value="Inactive">{i18n.t("Inactive")}</MenuItem>
                  </SelectValidator>
                  <div className="row mx-0 px-0 justify-content-end mt-2">
                    <PrimaryButton
                      props={{
                        type: "submit",
                      }}
                      label={i18n.t("create")}
                    />
                  </div>
                </ValidatorForm>
              </div>
            </>
          }
          onClose={onClose}
        />
      ) : null}
    </div>
  );
}
