import React, { useState } from "react"
import { BookingProvider } from "./Booking"
import { IntakeCustomerProvider } from "./IntakeCustomer/IntakeCustomer"

export function BookIntake({ children }) {
  const [bookingOpen, setBookingOpen] = useState(false)
  const [intakeOpen, setIntakeOpen] = useState(false)

  return (
    <>
      <BookingProvider open={bookingOpen} setOpen={setBookingOpen}>
        <IntakeCustomerProvider
          open={intakeOpen}
          setOpen={setIntakeOpen}
          setBookingOpen={setBookingOpen}
        >
          {children}
        </IntakeCustomerProvider>
      </BookingProvider>
    </>
  )
}

export default BookIntake
