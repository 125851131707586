import React from "react"
import PrimaryButton from "../../../../components/Core/PrimaryButton"
import i18n from "../../../../i18n"
import { BaseModal } from "../../../../components"
import Select from "../../../../components/Core/Select"
import { useSelector } from "react-redux"

export default function CustomerStatsFilterPopup({
  filters,
  setFilters,
  onFilter,
  open,
  setOpen,
}) {
  const ageGroups = useSelector((state) => state.customerAgeGroup.data)
  const districts = useSelector((state) => state.customerDistrict.data)
  const origins = useSelector((state) => state.customerOrigin.data)
  const statuses = useSelector((state) => state.customerStatus.data)
  const changeHandler = (key, value) => {
    if (!value) {
      delete filters[key]
    } else {
      filters[key] = value
    }
    setFilters({ ...filters })
  }
  return (
    <BaseModal
      open={open}
      onClose={() => setOpen(false)}
      title={i18n.t("filters")}
      content={
        <div className="row mb-1">
          <div className="col-xs-12 col-md-6">
            <Select
              label={i18n.t("status")}
              onChange={(e) => {
                changeHandler("statusId", e.target.value)
              }}
              value={filters.statusId || ""}
              options={statuses.map(({ _id, name }) => ({ name, value: _id }))}
            />
          </div>
          <div className="col-xs-12 col-md-6">
            <Select
              label={i18n.t("age_group")}
              onChange={(e) => {
                changeHandler("ageGroupId", e.target.value)
              }}
              value={filters.ageGroupId || ""}
              options={ageGroups.map(({ _id, name }) => ({ name, value: _id }))}
            />
          </div>
          <div className="col-xs-12 col-md-6 mt-1">
            <Select
              label={i18n.t("origin")}
              onChange={(e) => {
                changeHandler("originId", e.target.value)
              }}
              value={filters.originId || ""}
              options={origins.map(({ _id, name }) => ({ name, value: _id }))}
            />
          </div>
          <div className="col-xs-12 col-md-6 mt-1">
            <Select
              label={i18n.t("district")}
              onChange={(e) => {
                changeHandler("districtId", e.target.value)
              }}
              value={filters.districtId || ""}
              options={districts.map(({ _id, name }) => ({ name, value: _id }))}
            />
          </div>
          <div className="col-12 row justify-content-end mt-1">
            <PrimaryButton label={i18n.t("submit")} onClick={onFilter} />
          </div>
        </div>
      }
    />
  )
}
