import React, { useEffect, useRef, useState } from "react";
import i18n from "../../../../../i18n";
import PrimaryButton from "../../../../Core/PrimaryButton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { BaseModal } from "../../../..";
import {
  getCarMakes,
  getCarModelsFromMake,
} from "../../../../../config/simpleApiCalls";

export default function CustomerCarAdd({ handleAdd, open, setOpen }) {
  const form = useRef();
  const [data, setData] = useState({});
  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);
  useEffect(() => {
    getCarMakes().then(({ data }) => {
      setMakes(data.data.map(({ id, name }) => ({ value: id, name })));
    });
  }, []);

  useEffect(() => {
    if (data.make?.value) {
      getCarModelsFromMake({ make: data.make.value }).then(({ data }) => {
        setModels(data.data.map(({ id, name }) => ({ value: id, name })));
      });
    } else {
      setModels([]);
    }
  }, [data.make?.value]);

  return (
    <>
      <BaseModal
        title={i18n.t("add_car")}
        content={
          <div className="row mx-0">
            <ValidatorForm
              onSubmit={() =>
                handleAdd(
                  {
                    ...data,
                    make: data.make.name,
                    model: data.model.name,
                  },
                  () => {
                    setOpen(false);
                  }
                )
              }
              ref={form}
              className="row col-12 mx-0"
              onError={(error) => {
                console.log(error);
              }}
            >
              <div className="col-12 px-0 mb-1">
                <Autocomplete
                  options={makes}
                  getOptionLabel={(option) => option.name}
                  value={data.make || []}
                  onChange={(e, value = []) => {
                    setData({ ...data, make: value, model: "" });
                  }}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      value={data.make || ""}
                      containerProps={{ className: "col-12 px-0" }}
                      className="mb-1"
                      label={i18n.t("make")}
                      validators={["required"]}
                      errorMessages={[i18n.t("this_field_is_required")]}
                    />
                  )}
                />
              </div>
              <div className="col-12 px-0 mb-1">
                <Autocomplete
                  options={models}
                  getOptionLabel={(option) => option.name}
                  value={data.model || []}
                  onChange={(e, value = []) => {
                    setData({ ...data, model: value });
                  }}
                  disabled={!data.make}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      value={data.model || ""}
                      containerProps={{ className: "col-12 px-0" }}
                      className="mb-1"
                      label={i18n.t("model")}
                      validators={["required"]}
                      errorMessages={[i18n.t("this_field_is_required")]}
                    />
                  )}
                />
              </div>
              <div className="col-12 px-0 mb-1">
                <TextValidator
                  label={i18n.t("year")}
                  onChange={(e) => setData({ ...data, year: e.target.value })}
                  value={data.year}
                  validators={["required"]}
                  errorMessages={[i18n.t("name_required")]}
                />
              </div>

              <div className="row mx-0 px-0 justify-content-end mt-2 col-12">
                <PrimaryButton
                  props={{
                    type: "submit",
                  }}
                  label={data._id ? i18n.t("save") : i18n.t("create")}
                />
              </div>
            </ValidatorForm>
          </div>
        }
        onClose={() => setOpen(false)}
        open={open}
      />
    </>
  );
}
