// @flow
import Immutable from "seamless-immutable"
import * as types from "../actions/ActionTypes"

const initialState = Immutable({
  failure: false,
  isFetching: false,
  errorMessage: "",
})

export default (state = initialState, action) => {
  switch (action.type) {
  case types.UPDATE_NOTIFICATION.REQUEST:
    return Immutable.merge(state, {
      isFetching: true,
    })
  case types.UPDATE_NOTIFICATION.SUCCESS:
    return Immutable.merge(state, {
      failure: false,
      isFetching: false,
      errorMessage: "",
    })
  case types.UPDATE_NOTIFICATION.FAILURE:
    return Immutable.merge(state, {
      failure: true,
      isFetching: false,
      errorMessage: action.errorMessage,
    })
  default:
    return state
  }
}
