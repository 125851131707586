// @flow

import { COMMUNICATION_MARKETING } from "../ActionTypes"

export function getRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: COMMUNICATION_MARKETING.GET_SAGA,
  }
}

export function get(data) {
  return {
    data,
    type: COMMUNICATION_MARKETING.GET,
  }
}

export function fundRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: COMMUNICATION_MARKETING.FUND_SAGA,
  }
}

export function fund(data) {
  return {
    data,
    type: COMMUNICATION_MARKETING.FUND,
  }
}

export function getTransactionHistoryRequest(data) {
  return {
    data,
    type: COMMUNICATION_MARKETING.TRANSACTION_HISTORY_SAGA,
  }
}

export function getTransactionHistory(data) {
  return {
    data,
    type: COMMUNICATION_MARKETING.TRANSACTION_HISTORY,
  }
}

export function requestFailure() {
  return {
    type: COMMUNICATION_MARKETING.REQUEST_FAILURE,
  }
}
