// @flow
import Immutable from "seamless-immutable"
import * as types from "../../actions/ActionTypes"

const initialState = Immutable({
  isFetching: false,
  errorMessage: "",
  data: {},
})

export default (state = initialState, action) => {
  switch (action.type) {
  case types.COMMUNICATION_MARKETING.GET_SAGA:
  case types.COMMUNICATION_MARKETING.FUND_SAGA:
  case types.COMMUNICATION_MARKETING.TRANSACTION_HISTORY_SAGA:
    return Immutable.merge(state, {
      isFetching: true,
    })
  case types.COMMUNICATION_MARKETING.GET:
    return Immutable.merge(state, {
      isFetching: false,
      data: { ...state.data, ...action.data },
    })
  case types.COMMUNICATION_MARKETING.FUND: {
    const newState = { ...state, isFetching: false, data: { ...state.data } }
    const { account } = action.data
    newState.data = { ...newState.data, ...account }
    return newState
  }
  case types.COMMUNICATION_MARKETING.TRANSACTION_HISTORY: {
    const newState = { ...state, isFetching: false, data: { ...state.data } }
    newState.data = { ...newState.data, transactions: action.data }
    return newState
  }
  case types.COMMUNICATION_MARKETING.REQUEST_FAILURE:
    return Immutable.merge(state, {
      isFetching: false,
    })

  case types.LOGOUT:
    return initialState
  case types.CLEAR_LOGOUT:
    return initialState
  default:
    return state
  }
}
