import React, { useEffect, useState, useRef } from "react"
import i18n from "../../../i18n"

import { CheckboxForm } from "./employeeSection.styles.js"
import { TextField, Button } from "@material-ui/core"
import PrimaryButton from "../../../components/Core/PrimaryButton/index"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import ColorPicker from "../../../components/Core/ColorPicker/ColorPicker"
import Switch from "@material-ui/core/Switch"
import useOutsideClick from "../../../components/Core/ClickOutside/useOutsideClick"
export default function EmployeeSettings({ employee, handleSave }) {
  const [currentEmployee, setCurrentEmployee] = useState(null)
  const [colorod, setColorod] = useState("#0000000")
  const [formErrors, setFormErrors] = useState({})
  const [showPicker, setShowPicker] = useState(false)
  useEffect(() => {
    if (employee) {
      setCurrentEmployee(employee)
      setColorod(employee?.color)
    }
  }, [employee])
  const ref = useRef()

  useOutsideClick(ref, () => {
    setShowPicker(false)
  })
  const handleColorChange = (color) => {
    setColorod(color)
    setCurrentEmployee({ ...currentEmployee, color: color })
  }

  const handleFormChanges = (e) => {
    const { name, checked } = e.target
    if (checked !== undefined) {
      setCurrentEmployee({ ...currentEmployee, [name]: checked })
    }
  }
  const handleTextFormChanges = (e) => {
    const { name, value } = e.target
    if (value !== undefined) {
      if (name === "split") {
        if (value <= 100 && value >= 0) {
          setCurrentEmployee({ ...currentEmployee, [name]: value })
          setFormErrors({ ...formErrors, [name]: "" })
        } else {
          setCurrentEmployee({ ...currentEmployee, [name]: value })
          setFormErrors({ ...formErrors, [name]: i18n.t("splitError") })
        }
      } else if (name === "baseProductCommission") {
        if (value <= 100 && value >= 0) {
          setCurrentEmployee({ ...currentEmployee, [name]: value })
          setFormErrors({ ...formErrors, [name]: "" })
        } else {
          setCurrentEmployee({ ...currentEmployee, [name]: value })
          setFormErrors({
            ...formErrors,
            [name]: i18n.t("baseCommissionError"),
          })
        }
      } else if (name === "packageCommission") {
        if (value <= 100 && value >= 0) {
          setCurrentEmployee({ ...currentEmployee, [name]: value })
          setFormErrors({ ...formErrors, [name]: "" })
        } else {
          setCurrentEmployee({ ...currentEmployee, [name]: value })
          setFormErrors({
            ...formErrors,
            [name]: i18n.t("packageCommissionError"),
          })
        }
      } else {
        setCurrentEmployee({ ...currentEmployee, [name]: value })
      }
    }
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    const {
      color,
      allowCheckIn,
      isEmployeeIndependent,
      isReceptionist,
      isManager,
      isInstructor,
      hideEmployeeProfile,
      displayWorkingHours,
      seat,
      split,
      baseProductCommission,
      packageCommission,
      pinCode,
      priority,
    } = currentEmployee
    handleSave({
      color,
      allowCheckIn,
      isEmployeeIndependent,
      isReceptionist,
      isManager,
      isInstructor,
      hideEmployeeProfile,
      displayWorkingHours,
      seat,
      split,
      baseProductCommission,
      packageCommission,
      pinCode,
      priority,
    })
  }
  const newRender = () => {
    return (
      <>
        {currentEmployee && (
          <>
            <CheckboxForm onSubmit={handleSubmit} className="d-block">
              <div className="d-sm-flex w-100">
                <div
                  className="mobile-w-100"
                  style={{ width: "30%", paddingLeft: "2px" }}
                >
                  <FormControlLabel
                    control={
                      <Switch
                        value={currentEmployee.allowCheckIn}
                        checked={currentEmployee.allowCheckIn}
                        name="allowCheckIn"
                        className="ml-1 ml-md-0"
                        type="checkbox"
                        onChange={handleFormChanges}
                        color="primary"
                      />
                    }
                    label={i18n.t("allow_check_in")}
                  />
                  <div className="d-flex align-items-center my-4">
                    <label
                      className="font-weight-bold mr-3"
                      style={{ minWidth: 150 }}
                    >
                      {i18n.t("container.add_employee_enter_pincode")}
                    </label>
                    <TextField
                      disabled={!currentEmployee.allowCheckIn}
                      type="number"
                      name="pinCode"
                      value={currentEmployee.pinCode}
                      onChange={handleTextFormChanges}
                      variant="outlined"
                      className="pinCodeInput"
                    />
                  </div>
                  <FormControlLabel
                    control={
                      <Switch
                        value={currentEmployee.displayWorkingHours}
                        checked={currentEmployee.displayWorkingHours}
                        name="displayWorkingHours"
                        className="ml-1 ml-md-0"
                        type="checkbox"
                        onChange={handleFormChanges}
                        color="primary"
                      />
                    }
                    label={i18n.t("display_working_hours_on_calendar")}
                  />
                </div>
                <div className="mobile-w-100" style={{ width: "30%" }}>
                  <FormControlLabel
                    control={
                      <Switch
                        value={currentEmployee.isReceptionist}
                        checked={currentEmployee.isReceptionist}
                        name="isReceptionist"
                        className="ml-1 ml-md-0"
                        type="checkbox"
                        onChange={handleFormChanges}
                        color="primary"
                      />
                    }
                    label={i18n.t("is_receptionist")}
                  />
                  <div className="my-3">
                    <FormControlLabel
                      control={
                        <Switch
                          value={currentEmployee.isManager}
                          checked={currentEmployee.isManager}
                          name="isManager"
                          className="ml-1 ml-md-0"
                          type="checkbox"
                          onChange={handleFormChanges}
                          color="primary"
                        />
                      }
                      label={i18n.t("is_manager")}
                    />
                  </div>
                  <FormControlLabel
                    control={
                      <Switch
                        value={currentEmployee.isEmployeeIndependent}
                        checked={currentEmployee.isEmployeeIndependent}
                        name="isEmployeeIndependent"
                        className="ml-1 ml-md-0"
                        type="checkbox"
                        onChange={handleFormChanges}
                        color="primary"
                      />
                    }
                    label={i18n.t("is_independent")}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        value={currentEmployee.isInstructor}
                        checked={currentEmployee.isInstructor}
                        name="isInstructor"
                        className="ml-1 ml-md-0"
                        type="checkbox"
                        onChange={handleFormChanges}
                        color="primary"
                      />
                    }
                    label={i18n.t("is_instructor")}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        value={currentEmployee.hideEmployeeProfile}
                        checked={currentEmployee.hideEmployeeProfile}
                        name="hideEmployeeProfile"
                        className="ml-1 ml-md-0"
                        type="checkbox"
                        onChange={handleFormChanges}
                        color="primary"
                      />
                    }
                    label={i18n.t("hide_employee_profile")}
                  />
                </div>
                <div className="mobile-w-100" style={{ width: "40%" }}>
                  <div className="d-sm-flex align-items-center mb-3">
                    <label
                      className="font-weight-bold"
                      style={{ minWidth: "180px" }}
                    >
                      {i18n.t("seat/room")}
                    </label>
                    <TextField
                      name="seat"
                      onChange={handleTextFormChanges}
                      label={i18n.t("seat/room")}
                      type="number"
                      value={currentEmployee.seat}
                      variant="outlined"
                    />
                  </div>
                  <div className="d-sm-flex align-items-center mb-3">
                    <label
                      className="font-weight-bold"
                      style={{ minWidth: "180px" }}
                    >
                      {i18n.t("staff_split")}
                    </label>
                    <TextField
                      name="split"
                      onChange={handleTextFormChanges}
                      label={i18n.t("staff_split")}
                      type="number"
                      value={currentEmployee.split}
                      error={formErrors.split}
                      helperText={formErrors.split}
                      variant="outlined"
                    />
                  </div>
                  <div className="d-sm-flex align-items-center mb-3">
                    <label
                      className="font-weight-bold"
                      style={{ minWidth: "180px" }}
                    >
                      {i18n.t("base_product_commission")}
                    </label>
                    <TextField
                      name="baseProductCommission"
                      onChange={handleTextFormChanges}
                      label={i18n.t("base_product_commission")}
                      type="number"
                      value={currentEmployee.baseProductCommission}
                      error={formErrors.baseProductCommission}
                      helperText={formErrors.baseProductCommission}
                      variant="outlined"
                    />
                  </div>
                  <div className="d-sm-flex align-items-center mb-3">
                    <label
                      className="font-weight-bold"
                      style={{ minWidth: "180px" }}
                    >
                      {i18n.t("package_comission")}
                    </label>
                    <TextField
                      name="packageCommission"
                      onChange={handleTextFormChanges}
                      label={i18n.t("package_comission")}
                      type="number"
                      value={currentEmployee.packageCommission}
                      error={formErrors.packageCommission}
                      helperText={formErrors.packageCommission}
                      variant="outlined"
                    />
                  </div>
                  <div className="d-sm-flex align-items-center mb-3">
                    <label
                      className="font-weight-bold"
                      style={{ minWidth: "180px" }}
                    >
                      {i18n.t("color")}
                    </label>
                    <div className="w-100" ref={ref}>
                      <ColorPicker
                        id="color"
                        label={i18n.t("color")}
                        InputProps={{
                          value: colorod,
                        }}
                        fullWidth
                        showPicker={showPicker}
                        setShowPicker={setShowPicker}
                        onChange={(e) => {
                          if (e) {
                            handleColorChange(e)
                          }
                        }}
                        variant="outlined"
                      />
                    </div>
                  </div>
                  <div className="d-sm-flex align-items-center mb-3">
                    <label
                      className="font-weight-bold"
                      style={{ minWidth: "180px" }}
                    >
                      {i18n.t("priority")}
                    </label>
                    <TextField
                      name="priority"
                      onChange={(e) => {
                        const newValue = parseInt(e.target.value)
                        if (isNaN(newValue) || newValue > 10 || newValue < 1) {
                          return setCurrentEmployee({
                            ...currentEmployee,
                            [e.target.name]: 5,
                          })
                        }
                        setCurrentEmployee({
                          ...currentEmployee,
                          [e.target.name]: e.target.value,
                        })
                      }}
                      label={i18n.t("priority")}
                      type="number"
                      value={currentEmployee.priority}
                      variant="outlined"
                    />
                  </div>
                </div>
              </div>
              <div className="row mx-0 my-4 px-0 justify-content-center justify-content-md-end">
                <PrimaryButton
                  className="w-128"
                  label={i18n.t("save")}
                  type="submit"
                />
              </div>
            </CheckboxForm>
          </>
        )}
      </>
    )
  }
  return <>{newRender()}</>
}
