import React, { useEffect, useMemo, useRef, useState } from "react";

import { getRequest as getGeneratedSchedule } from "../../../redux/actions/Saloon/GeneratedBookingSchedule";
import { getRequest as getSchedule } from "../../../redux/actions/Saloon/AutoBookingSchedule";
import { useDispatch, useSelector } from "react-redux";
import { addCustomBooking } from "../../../config/simpleApiCalls";
import { DotsLoader } from "../../../components";
import BasicTable from "../../../components/Core/BasicTable";
import i18n from "../../../i18n";
import Select from "../../../components/Core/Select";
import DatePicker from "react-datepicker";
import moment from "moment";
import PrimaryButton from "../../../components/Core/PrimaryButton";
import Swal from "sweetalert2";
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import { TextField } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import SharedStorage from "../../../helpers/Storage"
import useCache from "../../../context/LocalCache";

const defaultConfiguration = {
  PERCENT_FILLED_SCHEDULE_WEIGHT: 2,
  PRIORITY_WEIGHT: 1.2,
  CUSTOMER_WEIGHT: 2,
  EMPLOYEE_WEIGHT: 1.5,
};
const configuration2 = {
  PERCENT_FILLED_SCHEDULE_WEIGHT: 1.2,
  PRIORITY_WEIGHT: 2.5,
  CUSTOMER_WEIGHT: 1.2,
  EMPLOYEE_WEIGHT: 2.5,
};
const configuration3 = {
  PERCENT_FILLED_SCHEDULE_WEIGHT: 1.2,
  PRIORITY_WEIGHT: 2.2,
  CUSTOMER_WEIGHT: 2.5,
  EMPLOYEE_WEIGHT: 1.2,
};

export default function GeneratedScheduleApproval() {
  const dispatch = useDispatch();

  const { getResource } = useCache();
  const customers = getResource((cache) => cache.company.customers);
  const employees = getResource((cache) => cache.company.employees);
  const allServices = getResource((cache) => cache.company.services);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [approvedIndexMap, setApprovedIndexMap] = useState({});
  const [filterEmployee, setFilterEmployee] = useState(null);
  const [filterCustomer, setFilterCustomer] = useState("");
  const [configuration, setConfiguration] = useState(defaultConfiguration);
  const filteredEmployees = useMemo(() => employees.filter(({ isActive }) => isActive), [employees])
  const loading = useSelector((s) => s.generatedBookingSchedule.isFetching);
  const schedule = useSelector((s) => s.generatedBookingSchedule.data);
  const schedules = useSelector((s) => s.autoBookingSchedule.data);

  const configurations = [
    { name: i18n.t("configuration_1"), value: defaultConfiguration },
    { name: i18n.t("configuration_2"), value: configuration2 },
    { name: i18n.t("configuration_3"), value: configuration3 },
  ];

  const onMount = async () => {
    const saloonData = JSON.parse(await SharedStorage.getItem("saloon"))
    setCompanyId(saloonData.companyId)
    dispatch(getSchedule({ type: "current" }))
  }
  useEffect(() => {
    onMount()
  }, [])

  useEffect(() => {
    if (selectedSchedule) {
      setApprovedIndexMap({});
      dispatch(
        getGeneratedSchedule({ scheduleId: selectedSchedule, configuration })
      );
    }
  }, [selectedSchedule, configuration]);

  const employeeMap = useMemo(() => {
    const map = {};
    filteredEmployees.map((employee) => {
      map[employee._id] = employee;
    });
    return map;
  }, [filteredEmployees]);

  const serviceMap = useMemo(() => {
    const map = {};
    allServices.map((service) => {
      map[service._id] = service;
    });
    return map;
  }, [allServices]);

  const customerMap = useMemo(() => {
    const map = {};
    customers.map((customer) => {
      map[customer._id] = customer;
    });
    return map;
  }, [customers]);

  const formattedAndFilteredSchedule = useMemo(() => {
    return schedule
      .map((element) => {
        const { serviceId, companyEmployeeId, companyCustomerId } = element;
        return {
          ...element,
          service: serviceMap[serviceId],
          employee: employeeMap[companyEmployeeId],
          customer: customerMap[companyCustomerId],
        };
      })
      .filter((element) => {
        if (filterEmployee && filterEmployee != element.companyEmployeeId) {
          return false;
        }
        if (filterCustomer) {
          const { customer } = element;
          if (customer.name.includes(filterCustomer)) {
            return true;
          }
          if (customer.phoneNo.includes(filterCustomer)) {
            return true;
          }
          if (customer.email.includes(filterCustomer)) {
            return true;
          }
          return false;
        }
        return true;
      });
  }, [
    employeeMap,
    serviceMap,
    customerMap,
    schedule,
    filterEmployee,
    filterCustomer,
  ]);

  const saveGeneratedAsBooking = (
    {
      serviceId,
      start,
      date,
      companyEmployeeId,
      companyCustomerId,
      customerSubscriptionId,
    },
    index
  ) => {
    Swal.fire({
      title: i18n.t("are_you_sure"),
      text: i18n.t("cannmot_reverse"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ff3600",
      cancelButtonColor: "#354e68",
      confirmButtonText: i18n.t("yes"),
    }).then((result) => {
      if (result.value) {
        const companyEmployee = employeeMap[companyEmployeeId];
        const service = serviceMap[serviceId];
        const customer = customerMap[companyCustomerId];
        const body = {
          companyId,
          userName: customer.name,
          email: customer.email,
          phoneNo: customer.phoneNo,
          status: 1,
          totalAmount: 0,
          notes: "",
          forceBooking: true,
          depositRequired: false,
          adjustedTime: 0,
          services: [
            {
              serviceId,
              categoryId: service.serviceId?._id,
              employeeId: companyEmployee.employeeId?._id,
              date,
              time: start,
            },
          ],
          customerSubscriptionId,
        };
        addCustomBooking(body)
          .then((res) => {
            if (res.data.success) {
              SuccessHelper.handleSuccess("Booking Created", true);
              setApprovedIndexMap({ ...approvedIndexMap, [index]: true });
            } else {
              ErrorHelper.handleErrors("Something went wrong", true);
            }
          })

          .catch((error) => {
            ErrorHelper.handleErrors("something_went_wrong", true);
          });
      }
    });
  };

  return (
    <div className="content-container p-4">
      <h1 className="saloon-dashboard-heading mb-4">
        {i18n.t("ai_booking_approval")}
      </h1>
      <div className="filter-approval-header mb-4">
        <div className="form-box mb-0">
          <label style={{ minWidth: 'fit-content' }}>{i18n.t("search_customers")}</label>
          <TextField
            label={i18n.t("search_customers")}
            fullWidth
            value={filterCustomer}
            onChange={(e) => setFilterCustomer(e.target.value)}
            variant="outlined"
          />
        </div>
        <div className="form-box">
          <PrimaryButton
            className="w-128 mx-3"
            label={i18n.t("search_reset")}
            onClick={() => {
              setFilterCustomer("");
            }}
          />
          <label>{i18n.t("date")}</label>
          <Select
            className="w-100"
            val={selectedSchedule}
            options={schedules.map((schedule) => ({
              value: schedule._id,
              name: moment(schedule.startDate).format("MM-DD-YYYY"),
            }))}
            onChange={(e) => setSelectedSchedule(e.target.value)}
            excludeEmptyValue
            fullWidth={false}
            variant="outlined"
          />
        </div>
        <div className="form-box">
          <label style={{ minWidth: 'fit-content' }}>{i18n.t("configuration")}</label>
          <Select
            className="w-100"
            value={configuration}
            options={configurations}
            onChange={(e) => setConfiguration(e.target.value)}
            variant="outlined"
          />
        </div>
        <div className="form-box">
          <label>{i18n.t("employee")}</label>
          <Select
            className="w-100"
            value={filterEmployee}
            options={filteredEmployees.map((employee) => ({
              name: employee.employeeId?.userId?.firstName,
              value: employee._id,
            }))}
            onChange={(e) => setFilterEmployee(e.target.value)}
            variant="outlined"
          />
          <PrimaryButton
            className="w-98 ml-3"
            label={i18n.t("filterReset")}
            onClick={() => {
              setConfiguration("");
              setFilterEmployee("");
            }}
          />
        </div>
      </div>
      <DotsLoader isloading={loading} />
      <BasicTable
        rows={formattedAndFilteredSchedule}
        schema={[
          {
            header: i18n.t("date"),
            field: "date",
          },
          {
            header: i18n.t("time"),
            field: "time",
            accessor: ({ end, start }) => `${start} - ${end}`,
          },
          {
            header: i18n.t("service"),
            field: "service",
            accessor: ({ service }) => service?.name,
          },
          {
            header: i18n.t("employee"),
            field: "employee",
            accessor: ({ employee }) => {
              if (!employee) {
                return "";
              }
              return `${employee.employeeId.userId.firstName} ${employee.employeeId.userId.lastName}`;
            },
          },
          {
            header: i18n.t("customer"),
            field: "customer",
            accessor: ({ customer }) => {
              if (!customer) {
                return "";
              }
              return `${customer.name} (${customer.phoneNo})`;
            },
          },
          {
            field: "approve",
            style: { width: 0 },
            render: (row, index) => {
              if (approvedIndexMap[index]) {
                return null;
              }
              return (
                <div className="row mx-0 align-items-center flex-nowrap">
                  <PrimaryButton
                    label={i18n.t("approve")}
                    className="mr-2"
                    onClick={() => {
                      saveGeneratedAsBooking(row, index);
                    }}
                  />
                  <div className="d-flex flex-column align-items-center">
                    <ErrorOutlineIcon color="error" />
                    <div
                      style={{
                        color: "var(--error-color)",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {i18n.t("top_priority")}
                    </div>
                  </div>
                </div>
              );
            },
          },
        ]}
      />
    </div>
  );
}
