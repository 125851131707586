import React from "react";
import TextField from "@material-ui/core/TextField";
import styled from "styled-components";

const FieldTitledWrapper = styled.div`
  width: 100%;
`;
const Field = ({ handleChange, fieldError, field, label, name }) => {
  return (
    <FieldTitledWrapper>
      <h6>{label ? label : "Field title:"}</h6>
      <TextField
        onChange={(e) => handleChange(e)}
        variant="outlined"
        fullWidth={true}
        value={field}
        name={name}
      />

      {fieldError ? <div className="fieldError">{fieldError}</div> : ""}
    </FieldTitledWrapper>
  );
};

export default Field;
