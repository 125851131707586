import { connect } from "react-redux"
import React, { Component } from "react"
import Swal from "sweetalert2"
import { Translation } from "react-i18next"
import i18n from "../../../i18n"
import { BaseModal, DotsLoader } from "../../../components"
import {
  saloonGetCategories,
  saloonEditService,
  updateMultipleService,
  saloonDeleteService,
} from "../../../config/simpleApiCalls"
import { getRequest as getForms } from "../../../redux/actions/Saloon/FormBuilder"
import { ErrorHelper, SuccessHelper } from "../../../helpers"
import "./styles.css"
import { Images } from "../../../theme"
import ServiceForm from "../../../components/Forms/Service/ServiceForm.component"
import DeleteIcon from "../../../components/Core/BasicTable/Actions/DeleteIcon"
import EditIcon from "../../../components/Core/BasicTable/Actions/EditIcon"
import PrimaryButton from "../../../components/Core/PrimaryButton"
import deleteConfirmation from "../../../helpers/DeleteHelper"
import SharedStorage from "../../../helpers/Storage"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import DragIndicatorIcon from "@material-ui/icons/DragIndicator"
import useCache from "../../../context/LocalCache"
import TextField from "@material-ui/core/TextField"
import Select from "../../../../src/components/Core/Select/index.jsx"
import { ScreenSizeContext } from "../../../context/ScreenSize.jsx"
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const grid = 8

class _AllServices extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // GENERAL
      searchText: "",
      showEditServiceCard: false,

      // FOR SERVICES
      services: [],

      // FOR EDIT SERVICE
      serviceId: "",
      filterUsersList: [],
      defaultValues: {},
      isloading: false,
    }
    this.onDragEnd = this.onDragEnd.bind(this)
  }

  componentDidMount = async () => {
    const company = JSON.parse(await SharedStorage.getItem("company"))
    if (company) {
      this.setState({
        companyId: company._id,
      })
      this.handleSaloonGetCategories()
      this.props.getForms({
        companyId: company._id,
      })
    }
    const isGym = company.function == "gym"
    this.setState({
      isGym,
      timeSlotLength: company.timeSlotLength,
    })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { products } = nextProps
    return {
      products: products.map((product) => ({
        text: product.name,
        value: product._id,
        ...product,
      })),
    }
  }

  onDragEnd(result) {
    if (!result.destination) {
      return
    }

    let serviceList = reorder(
      this.props.services,
      result.source.index,
      result.destination.index
    )

    let toUpdate = {}
    const newServiceList = serviceList.map((val, index) => {
      if (index + 1 !== serviceList[index].displayOrder) {
        toUpdate[val._id] = index + 1
      }
      return { ...val, displayOrder: index + 1 }
    })

    this.setState({ isloading: true })
    updateMultipleService(toUpdate)
      .then(({ data }) => {
        if (data.success) {
          SuccessHelper.handleSuccess(i18n.t("updated_display_order"), true)
        } else {
          ErrorHelper.handleErrors(i18n.t("someting_went_wrong"), true)
        }
        this.setState({ isloading: false })
      })
      .catch(() => {
        ErrorHelper.handleErrors(i18n.t("someting_went_wrong"), true)
        this.setState({ isloading: false })
      })
    this.setState({ serviceList: newServiceList })
  }

  handleSaloonGetCategories = () => {
    this.setState({ isloading: true })
    saloonGetCategories()
      .then((res) => {
        if (res.data.success) {
          this.setState({
            categoryList: res.data.data.map(({ name, _id }) => ({
              name,
              value: _id,
            })),
            isloading: false,
          })
        }
      })
      .catch((error) => {
        console.log(error, "get all category error")
      })
  }

  handleNavigation = (route) => {
    this.props.history.push(route)
  }

  onChangeSearchText = (text) => {
    this.setState({
      searchText: text,
      activePage: 1,
    })
  }

  onChangeCategoryText = (id) => {
    this.setState({
      searchCategory: id,
      activePage: 1,
    })
  }

  handleSaloonEditService = (data) => {
    const { isGym, serviceId } = this.state
    const {
      name,
      price,
      category,
      status,
      minimumTime,
      imageFile,
      shouldSendTextReminders,
      textReminderFrequency,
      subcategory,
      relatedProductIds = [],
      description,
      depositAmount,
      requireDeposit,
      forms = [],
      depositTarget,
      hiddenToCustomers,
      hidePrice,
    } = data
    const sendRequst = () => {
      this.setState({ isloading: true })
      let payloadFormData = new FormData()
      payloadFormData.append("name", name)
      // payloadFormData.append('categoryName', categoryName);
      if (!isGym) {
        payloadFormData.append(
          "shouldSendTextReminders",
          shouldSendTextReminders
        )
        payloadFormData.append("textReminderFrequency", textReminderFrequency)
      }
      payloadFormData.append("serviceId", category)
      payloadFormData.append("isActive", status ? 1 : 0)
      payloadFormData.append("duration", minimumTime)
      payloadFormData.append("price", price)
      payloadFormData.append("subcategory", subcategory)
      payloadFormData.append("description", description)
      payloadFormData.append(
        "relatedProductIds",
        relatedProductIds.map((product) => product.value).join(",")
      )
      payloadFormData.append(
        "selectedFormIds",
        forms.map((form) => form._id).join(",")
      )
      payloadFormData.append("requireDeposit", requireDeposit)
      if (depositAmount) {
        payloadFormData.append("depositAmount", depositAmount)
      }
      payloadFormData.append("depositTarget", depositTarget)
      payloadFormData.append("hiddenToCustomers", hiddenToCustomers)
      payloadFormData.append("hidePrice", hidePrice)

      if (imageFile) {
        payloadFormData.append("image", imageFile)
      }
      saloonEditService(payloadFormData, serviceId)
        .then((res) => {
          this.setState({ isloading: false })
          if (res.data.success) {
            SuccessHelper.handleSuccess(
              "Your service is successfully updated.",
              true
            )
            this.setState({
              showEditServiceCard: false,
              serviceId: "",
              defaultValues: {},
            })
          } else if (!res.data.success) {
            ErrorHelper.handleErrors(res.data.msg, true)
            this.setState({ isloading: false })
          }
        })
        .catch((error) => {
          this.setState({ isloading: false })
          console.log(error, "errorrrrrrrrrrrrrrrrrrr")
        })
    }
    const productsThatDontAllowBackorder = relatedProductIds.filter(
      (product) => !product.allowBackorder
    )
    if (productsThatDontAllowBackorder.length) {
      Swal.fire({
        icon: "warning",
        text: i18n.t("product_dont_allow_backorder_warning", {
          products: productsThatDontAllowBackorder
            .map((product) => product.text)
            .join(", "),
        }),
        showCancelButton: true,
        cancelButtonText: i18n.t("no"),
        confirmButtonText: i18n.t("yes"),
      }).then((e) => {
        const { dismiss, isConfirmed, isDismissed } = e
        if (!isDismissed || dismiss != "backdrop") {
          if (isConfirmed) {
            sendRequst()
          }
        }
      })
    } else {
      sendRequst()
    }
  }

  handleSaloonDeleteService = (serviceId) => {
    this.setState({ isloading: true })
    saloonDeleteService(serviceId)
      .then((res) => {
        this.setState({ isloading: true })
        if (res.data.success) {
          SuccessHelper.handleSuccess(
            "Your service is successfully deleted.",
            true
          )
        } else if (!res.data.success) {
          ErrorHelper.handleErrors(res.data.msg, true)
        }
      })
      .catch((error) => {
        this.setState({ isloading: true })
        console.log(error, "errorrrrrrrrrrrrrrrrrrr")
      })
  }

  openEditServiceCard = (data) => {
    const { products } = this.state
    const { forms } = this.props
    const hours = parseInt(data.duration / 60)
    const minutes = data.duration % 60
    const intersect = []
    if (data.relatedProductIds && data.relatedProductIds.length) {
      data.relatedProductIds.map((element) => {
        const relevant = products.find(({ value }) => value === element)
        if (relevant) {
          intersect.push(relevant)
        }
      })
    }

    const selectedFormObjects = data.selectedFormIds.map((id) =>
      forms.find(({ _id }) => _id === id)
    )

    this.setState({
      showEditServiceCard: true,
      serviceId: data._id,
      defaultValues: {
        name: data.name,
        hours,
        minutes,
        category: data._id,
        price: data.price,
        subcategory: data.subcategory ? data.subcategory : "",
        imageUrl: data.image,
        isActive: data.isActive,
        category: data.serviceId._id,
        shouldSendTextReminders: data.shouldSendTextReminders,
        textReminderFrequency: data.textReminderFrequency,
        requireDeposit: data.requireDeposit,
        depositAmount: data.depositAmount ? data.depositAmount : 0,
        description: data.description,
        relatedProductIds: intersect,
        forms: selectedFormObjects,
        depositTarget: data.depositTarget,
        hiddenToCustomers: data.hiddenToCustomers,
        hidePrice: data.hidePrice,
      },
    })
  }

  closeEditServiceCard = () => {
    this.setState({
      showEditServiceCard: false,
      defaultValues: {},
    })
  }

  renderLoader = () => {
    const { isloading } = this.state
    return <DotsLoader isloading={isloading} />
  }

  renderSaloonDashboardHeading = () => {
    const { screenSize } = this.context
    return (
      <div
        style={
          screenSize === "mobile"
            ? {
                position: "sticky",
                top: 0,
                zIndex: 1,
                backgroundColor: "white",
              }
            : {}
        }
        className="d-flex flex-column flex-md-row align-items-md-center justify-content-between my-3"
      >
        <h1 className="saloon-dashboard-heading pt-0 mb-3">
          <Translation>
            {(t) => t("container.dashboard_services_list")}
          </Translation>
        </h1>
        <div className="d-flex justify-content-center gap-2 pb-2 pb-md-0">
          {screenSize === "mobile" ? (
            <PrimaryButton
              onClick={() => this.setState({ openFiltersModal: true })}
              className="w-128"
            >
              <Translation>{(t) => t("filters")}</Translation>
            </PrimaryButton>
          ) : null}
          <PrimaryButton
            onClick={() => this.props.history.push("saloon-add-service")}
            className="w-128"
          >
            <Translation>{(t) => t("container.add_service")}</Translation>
          </PrimaryButton>
        </div>
      </div>
    )
  }

  renderSearchBar = () => {
    return (
      <div class="d-flex align-items-center col-sm-4 mb-3 pl-0 pr-0 pr-sm-3">
        <label className="font-weight-bolder mr-3">{i18n.t("search")}</label>
        <TextField
          type="text"
          placeholder={i18n.t("container.services_search_by_name")}
          value={this.state.searchText}
          onChange={(text) => this.onChangeSearchText(text.target.value)}
          variant={"outlined"}
        />
        <PrimaryButton
          onClick={() => this.onChangeSearchText("")}
          className="ml-3"
          style={{ minWidth: "fit-content" }}
        >
          <Translation>{(t) => t("search_reset")}</Translation>
        </PrimaryButton>
      </div>
    )
  }

  renderCategorySelect = () => {
    return (
      <div class="d-flex align-items-center col-sm-4 mb-3 pl-0 pr-0 pr-sm-3">
        <label className="font-weight-bolder mr-3">
          {i18n.t("container.categories")}
        </label>
        <Select
          onChange={(e) => {
            this.onChangeCategoryText(e.target.value)
          }}
          label={i18n.t("container.all_categories")}
          options={this.state.categoryList?.map((category) => {
            return {
              name: category.name,
              value: category.value,
            }
          })}
          variant={"outlined"}
        ></Select>
        <PrimaryButton
          onClick={() => this.onChangeCategoryText("")}
          className="ml-3"
          style={{ minWidth: "fit-content" }}
        >
          <Translation>{(t) => t("filterReset")}</Translation>
        </PrimaryButton>
      </div>
    )
  }

  renderCategoryForm = () => {
    return (
      <div class="d-sm-flex align-items-center col-12 px-0">
        {this.renderCategorySelect()}
        {this.renderSearchBar()}
      </div>
    )
  }

  renderTableHead = () => {
    const { searchText, searchCategory, isGym } = this.state

    return (
      <thead className="bg-white">
        <tr>
          {!searchText || !searchCategory ? (
            <th scope="col">
              <span className="category-table-th-text pl-0"></span>
            </th>
          ) : null}
          <th scope="col">
            <span className="category-table-th-text pl-0">
              <Translation>{(t) => t("container.profile_service")}</Translation>
            </span>
          </th>
          {!isGym && (
            <th scope="col" className="text-center">
              <span className="category-table-th-text pl-0">
                <Translation>{(t) => t("starting_price")}</Translation>
              </span>
            </th>
          )}
          <th scope="col" className="text-center">
            <span className="category-table-th-text pl-0">
              <Translation>{(t) => t("subcategory")}</Translation>
            </span>
          </th>
          {!isGym && (
            <th scope="col" className="text-center">
              <span className="category-table-th-text pl-0">
                {i18n.t("container.services_duration")}
              </span>
            </th>
          )}
          <th scope="col" className="text-center">
            <span className="category-table-th-text pl-0">
              {i18n.t("container.services_category")}
            </span>
          </th>
          <th scope="col" className="text-center">
            <span className="category-table-th-text pl-0">
              {i18n.t("container.profile_status")}
            </span>
          </th>
          <th scope="col">
            <span className="category-table-th-text pl-0"></span>
          </th>
        </tr>
      </thead>
    )
  }

  renderTableBody = (serviceList) => {
    const { searchText, searchCategory, isGym, widths = [] } = this.state
    let val = serviceList[0]

    return (
      <>
        <DragDropContext onDragEnd={(result) => this.onDragEnd(result)}>
          {val && (
            <>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => {
                  return (
                    <>
                      <tbody
                        style={{ textAlign: "center" }}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {serviceList.map((item, index) => {
                          const val = item
                          return (
                            <Draggable
                              key={item._id}
                              draggableId={item._id}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <>
                                  <tr
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    key={val._id}
                                  >
                                    {!searchCategory && !searchText ? (
                                      <td {...provided.dragHandleProps}>
                                        <DragIndicatorIcon />
                                      </td>
                                    ) : (
                                      <td></td>
                                    )}

                                    <td style={{ width: widths[0] }}>
                                      <div className="d-flex align-items-center">
                                        <img
                                          className="category-table-pic"
                                          src={
                                            val?.image
                                              ? val.image
                                              : Images.favicon
                                          }
                                        />
                                        <span className="category-table-td-text">
                                          {val.name}
                                        </span>
                                      </div>
                                    </td>
                                    {!isGym && (
                                      <td style={{ width: widths[0] }}>
                                        <span className="category-table-td-text">
                                          ${val.price.toFixed(2)}
                                        </span>
                                      </td>
                                    )}
                                    <td style={{ width: widths[0] }}>
                                      <span className="category-table-td-text">
                                        {val.subcategory}
                                      </span>
                                    </td>
                                    {!isGym && (
                                      <td style={{ width: widths[0] }}>
                                        <span className="category-table-td-text">
                                          {val?.duration} Minutes
                                        </span>
                                      </td>
                                    )}
                                    <td style={{ width: widths[0] }}>
                                      <span className="category-table-td-text">
                                        {val?.serviceId?.name}
                                      </span>
                                    </td>
                                    <td style={{ width: widths[0] }}>
                                      <span className="category-table-td-text">
                                        {val.isActive === 1 ? (
                                          <Translation>
                                            {(t) => t("container.active")}
                                          </Translation>
                                        ) : (
                                          <Translation>
                                            {(t) => t("container.inactive")}
                                          </Translation>
                                        )}
                                      </span>
                                    </td>
                                    <td style={{ width: widths[0] }}>
                                      <div style={{ width: 90 }}>
                                        <EditIcon
                                          onClick={() =>
                                            this.openEditServiceCard(val)
                                          }
                                        />
                                        <DeleteIcon
                                          onClick={() =>
                                            deleteConfirmation(() =>
                                              this.handleSaloonDeleteService(
                                                val._id
                                              )
                                            )
                                          }
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              )}
                            </Draggable>
                          )
                        })}
                        {provided.placeholder}
                      </tbody>
                    </>
                  )
                }}
              </Droppable>
            </>
          )}
        </DragDropContext>
      </>
    )
  }

  renderCategoryTable = () => {
    const { services } = this.props
    const { searchText, searchCategory } = this.state
    let filterUsersList = undefined
    if (searchText || searchCategory) {
      filterUsersList = services
      if (searchText) {
        filterUsersList = filterUsersList.filter((element) => {
          return element.name.toLowerCase().includes(searchText.toLowerCase())
        })
      }
      if (searchCategory) {
        filterUsersList = filterUsersList.filter((element) => {
          return element.serviceId._id == searchCategory
        })
      }
    }
    return (
      <div className="col-12 p-0 mt-4">
        <div className="table-responsive">
          <table class="table table-borderless saloon-category-table mb-0">
            {this.renderTableHead()}
            {this.renderTableBody(filterUsersList || services)}
          </table>
        </div>
      </div>
    )
  }

  renderCategoryCard = () => {
    const { screenSize } = this.context
    return (
      <div style={{ borderRadius: "1rem" }}>
        {screenSize !== "mobile" ? this.renderCategoryForm() : null}
        {this.renderCategoryTable()}
      </div>
    )
  }

  renderEditServiceCard = () => {
    const {
      defaultValues,
      showEditServiceCard,
      isGym,
      products,
      categoryList,
    } = this.state
    const { forms } = this.props
    return (
      <BaseModal
        open={true}
        onClose={this.closeEditServiceCard}
        {...{
          content: (
            <ServiceForm
              defaultValues={defaultValues}
              products={products}
              formOptions={forms}
              categories={categoryList}
              isGym={isGym}
              onSubmit={this.handleSaloonEditService}
            />
          ),
          title: i18n.t("service"),
        }}
      />
    )
  }

  render() {
    const { openFiltersModal } = this.state
    return (
      <div>
        {this.renderLoader()}
        <div className="content-container p-4">
          {this.state.showEditServiceCard && this.renderEditServiceCard()}
          {this.renderSaloonDashboardHeading()}
          {this.renderCategoryCard()}
        </div>
        <BaseModal
          open={openFiltersModal}
          onClose={() => this.setState({ openFiltersModal: false })}
          title={i18n.t("container.advancedSearchDialog_filter")}
          content={<>{this.renderCategoryForm()}</>}
        />
      </div>
    )
  }
}
_AllServices.contextType = ScreenSizeContext

const mapStateToProps = (state) => ({
  forms: state.formBuilder.data,
})

const action = { getForms }

const ConnectAllServices = connect(mapStateToProps, action)(_AllServices)

export default function AddService(props) {
  const { getResource } = useCache()
  const products = getResource((cache) => cache.company.products)
  const services = getResource((cache) => cache.company.services)
  return (
    <ConnectAllServices {...props} products={products} services={services} />
  )
}
