import { Autocomplete } from "@material-ui/lab"
import moment from "moment"
import React, { useState, useEffect, useMemo, useRef } from "react"
import ReactDatePicker from "react-datepicker"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import { BaseModal } from "../../../components"
import BasicTable from "../../../components/Core/BasicTable"
import PrimaryButton from "../../../components/Core/PrimaryButton"
import {
  getStaffMeeting,
  removeEmployeeFromStaffMeeting,
  addEmployeeToStaffMeeting,
  updateStaffMeeting,
} from "../../../config/simpleApiCalls"
import { ErrorHelper, SuccessHelper } from "../../../helpers"
import deleteConfirmation from "../../../helpers/DeleteHelper"
import i18n from "../../../i18n"
import useCache from "../../LocalCache"
import { Images } from "../../../theme"
import useScreenSize from "../../ScreenSize"
const maxDate = moment(moment.now()).toDate()
export default function UpdateStaffMeeting({ viewId, setViewId, setLoading }) {
  const form = useRef()
  const [data, setData] = useState({})
  const [addData, setAddData] = useState({})
  const [addEmployeeOpen, setAddEmployeeOpen] = useState(false)
  const { getResource } = useCache()
  const employees = getResource((cache) => cache.company.employees).filter(
    ({ isActive }) => isActive
  )
  const { screenSize } = useScreenSize()
  const init = async () => {
    setLoading(true)
    getStaffMeeting({ _id: viewId })
      .then((res) => {
        const { startDate } = res.data.data
        setData({ ...res.data.data, startDate: moment(startDate).toDate() })
      })
      .catch(() => {
        setViewId(null)
        ErrorHelper.handleErrors(i18n.t("something_went_wrong"))
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const handleRemoveEmployee = (booking) => {
    setLoading(true)
    removeEmployeeFromStaffMeeting({ bookingId: booking._id })
      .then((res) => {
        const { _id } = res.data.data
        setData({
          ...data,
          bookings: [...data.bookings.filter((booking) => booking._id != _id)],
        })
      })
      .catch(() => {
        ErrorHelper.handleErrors(i18n.t("something_went_wrong"))
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const handleAddEmployee = (payload) => {
    setLoading(true)
    addEmployeeToStaffMeeting(payload)
      .then((res) => {
        setData({
          ...data,
          bookings: [...data.bookings, ...res.data.data],
        })
        setAddEmployeeOpen(false)
      })
      .catch(() => {
        ErrorHelper.handleErrors(i18n.t("something_went_wrong"))
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const handleUpdate = () => {
    setLoading(true)
    const { startDate, startTime, duration, description, _id } = data
    updateStaffMeeting({ startDate, startTime, duration, description, _id })
      .then((res) => {
        setData({
          ...data,
          ...res.data.data,
          startDate: moment(res.data.data.startDate).toDate(),
        })
        SuccessHelper.handleSuccess(i18n.t("successfully_updated"))
      })
      .catch(() => {
        ErrorHelper.handleErrors(i18n.t("failed_to_update"))
      })
      .finally(() => {
        setLoading(false)
      })
  }
  useEffect(() => {
    if (viewId) {
      init()
      setAddData({})
      setData({})
    }
  }, [viewId])

  const filteredEmployee = useMemo(() => {
    if (!data.bookings) {
      return employees
    }
    const ids = data.bookings.map((booking) => {
      return booking.services[0].employeeId._id
    })
    return employees.filter((employee) => {
      if (employee.employeeId !== undefined) {
        return !ids.includes(employee.employeeId?._id)
      }
    })
  }, [data.bookings])

  return (
    <BaseModal
      open={Boolean(viewId)}
      onClose={() => {
        setViewId(null)
      }}
      title={i18n.t("staff_meeting")}
      containerClassName="half-container"
      content={
        <div className="row mx-0">
          <BaseModal
            open={addEmployeeOpen}
            onClose={() => {
              setAddEmployeeOpen(false)
            }}
            title={i18n.t("add_employees")}
            containerClassName="half-container"
            content={
              <div className="row mx-0">
                <ValidatorForm
                  onSubmit={async () => {
                    const payload = {
                      employees: addData.employees.map(
                        (employee) => employee.employee._id
                      ),
                      staffMeetingId: data._id,
                    }
                    handleAddEmployee(payload)
                  }}
                  ref={form}
                  className="row col-12 mx-0 p-0"
                  onError={(error) => {
                    console.log(error)
                  }}
                >
                  <div className="row col-12 px-0 pb-4 align-items-center inline-custom-form inline-custom-form1">
                    <label className="font-weight-bold">
                      {i18n.t("employees")}
                    </label>
                    <Autocomplete
                      multiple
                      options={filteredEmployee}
                      getOptionLabel={(option) =>
                        option.employeeId.userId.firstName
                      }
                      value={addData.employees || []}
                      onChange={(e, value = []) => {
                        setAddData({ ...data, employees: value })
                      }}
                      disableCloseOnSelect={true}
                      renderInput={(params) => {
                        return (
                          <TextValidator
                            {...params}
                            value={addData.employees}
                            label={i18n.t("employees")}
                            validators={["required", "hasArrayElements"]}
                            errorMessages={[
                              i18n.t("this_field_is_required"),
                              i18n.t("select_atleast_1_item"),
                            ]}
                            variant="outlined"
                          />
                        )
                      }}
                    />
                  </div>

                  <div className="row mx-0 px-0 justify-content-end mt-2 col-12">
                    <PrimaryButton
                      className="w-128 mb-3"
                      props={{
                        type: "submit",
                      }}
                      label={i18n.t("add")}
                    />
                  </div>
                </ValidatorForm>
              </div>
            }
          />
          <ValidatorForm
            onSubmit={async () => {
              handleUpdate()
            }}
            ref={form}
            className="row col-12 mx-0 px-0"
            onError={(error) => {
              console.log(error)
            }}
          >
            <div className="row col-12 flex-column flex-md-row px-0 pb-4 align-items-md-center inline-custom-form inline-custom-form1">
              <label className="font-weight-bold">{i18n.t("date")}</label>
              <div className={screenSize === "mobile" ? "w-100" : ""}>
                <ReactDatePicker
                  wrapperClassName="col-12 w-100 mb-1 px-0"
                  selected={data.startDate}
                  dateFormat="MM/dd/yyyy"
                  onChange={(val) => {
                    setData({ ...data, startDate: val })
                  }}
                  minDate={maxDate}
                  customInput={
                    <TextValidator
                      label={i18n.t("date")}
                      value={data.startDate || ""}
                      validators={["required"]}
                      errorMessages={[i18n.t("this_field_is_required")]}
                      readOnly={true}
                      variant="outlined"
                    />
                  }
                />
              </div>
            </div>
            <div className="row col-12 flex-column flex-md-row px-0 pb-4 align-items-md-center inline-custom-form inline-custom-form1">
              <label className="font-weight-bold">{i18n.t("time")}</label>
              <div className={screenSize === "mobile" ? "w-100" : ""}>
                <TextValidator
                  onChange={(e) =>
                    setData({ ...data, startTime: e.target.value })
                  }
                  value={data.startTime || ""}
                  validators={["required"]}
                  errorMessages={[i18n.t("this_field_is_required")]}
                  type="Time"
                  variant="outlined"
                />
              </div>
            </div>
            <div className="row col-12 flex-column flex-md-row px-0 pb-4 align-items-md-center inline-custom-form inline-custom-form1">
              <label className="font-weight-bold">
                {i18n.t("duration_in_minutes")}
              </label>
              <div
                className={screenSize === "mobile" ? "w-100" : ""}
                style={{ width: "calc(100% - 12rem)" }}
              >
                <TextValidator
                  onChange={(e) =>
                    setData({ ...data, duration: e.target.value })
                  }
                  onBlur={(e) => {
                    const x = parseInt(e.target.value)
                    if (isNaN(x) || x < 0) {
                      setData({ ...data, duration: 0 })
                    } else {
                      setData({ ...data, duration: Math.ceil(x / 5) * 5 })
                    }
                  }}
                  value={data.duration || ""}
                  validators={["required"]}
                  errorMessages={[i18n.t("this_field_is_required")]}
                  type="number"
                  variant="outlined"
                />
              </div>
            </div>
            <div className="row col-12 flex-column flex-md-row px-0 pb-4 align-items-md-center inline-custom-form inline-custom-form1">
              <label className="font-weight-bold">
                {i18n.t("description")}
              </label>
              <div className={screenSize === "mobile" ? "w-100" : ""}>
                <TextValidator
                  label={i18n.t("description")}
                  onChange={(e) =>
                    setData({ ...data, description: e.target.value })
                  }
                  value={data.description || ""}
                  validators={["required"]}
                  errorMessages={[i18n.t("this_field_is_required")]}
                  variant="outlined"
                />
              </div>
            </div>

            <div className="row mx-0 px-0 justify-content-end my-4 col-12">
              <PrimaryButton
                className="w-128"
                props={{
                  type: "submit",
                }}
                label={i18n.t("update")}
              />
            </div>
          </ValidatorForm>

          <BasicTable
            maxContentWidth={false}
            rows={data.bookings || []}
            schema={[
              {
                header: i18n.t("name"),
                field: "name",
                accessor: ({ services }) => {
                  return services[0].employeeId?.userId?.firstName
                },
              },
              {
                key: "action",
                header: i18n.t("actions"),
                style: { width: 160 },
                render: (val) => {
                  return (
                    <>
                      <PrimaryButton
                        className="w-34"
                        onClick={() =>
                          deleteConfirmation(() => {
                            handleRemoveEmployee(val)
                          })
                        }
                      >
                        <img
                          src={Images.table_delete_icon}
                          alt="delete"
                          width="18"
                        />
                      </PrimaryButton>
                    </>
                  )
                },
              },
            ]}
          />
          <div className="row justify-content-end mb-2 mt-3 w-100">
            <PrimaryButton
              label={i18n.t("add_employees")}
              className="w-128"
              onClick={() => {
                setAddEmployeeOpen(true)
              }}
            />
          </div>
        </div>
      }
    />
  )
}
