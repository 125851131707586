// @flow
import Immutable from "seamless-immutable"
import * as types from "../actions/ActionTypes"

const initialState = Immutable({
  isFetching: false,
  errorMessage: "",
  data: [],
  collectedMoney: [],
})

export default (state = initialState, action) => {
  switch (action.type) {
  case types.BOOKING_TRANSACTION.GET_SAGA:
    return Immutable.merge(state, {
      isFetching: true,
      collectedMoney: [],
    })
  case types.BOOKING_TRANSACTION.GET:
    return Immutable.merge(state, {
      isFetching: false,
      data: action.data,
    })
  case types.BOOKING_TRANSACTION.REVERSE_SAGA:
    return Immutable.merge(state, {
      isFetching: true,
    })
  case types.BOOKING_TRANSACTION.INSERT: {
    const newState = { ...state, isFetching: false, data: [...state.data] }
    if (Array.isArray(action.data)) {
      action.data.map((data) => {
        newState.data.unshift(data)
      })
    } else {
      newState.data.unshift(action.data)
    }
    const { _id } = action.data
    return newState
  }
  case types.BOOKING_TRANSACTION.INSERT_SAGA:
    return Immutable.merge(state, {
      isFetching: true,
    })
  case types.BOOKING_TRANSACTION.SET_COLLECT_MONEY:
    return Immutable.merge(state, {
      collectedMoney: action.data,
    })
  case types.BOOKING_TRANSACTION.REQUEST_FAILURE:
    return Immutable.merge(state, {
      isFetching: false,
    })

  case types.LOGOUT:
    return initialState
  case types.CLEAR_LOGOUT:
    return initialState
  default:
    return state
  }
}
