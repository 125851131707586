// @flow

import { CLASS } from "../ActionTypes"

export function getRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: CLASS.GET_SAGA,
  }
}

export function get(data) {
  return {
    data,
    type: CLASS.GET,
  }
}

export function removeRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: CLASS.DELETE_SAGA,
  }
}

export function remove(data) {
  return {
    data,
    type: CLASS.DELETE,
  }
}

export function upsertRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: CLASS.UPSERT_SAGA,
  }
}

export function upsert(data) {
  return {
    data,
    type: CLASS.UPSERT,
  }
}

export function removeGradeRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: CLASS.DELETE_GRADE_SAGA,
  }
}

export function removeGrade(data) {
  return {
    data,
    type: CLASS.DELETE_GRADE,
  }
}

export function addGradeRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: CLASS.ADD_GRADE_SAGA,
  }
}

export function addGrade(data) {
  return {
    data,
    type: CLASS.ADD_GRADE,
  }
}

export function requestFailure() {
  return {
    type: CLASS.REQUEST_FAILURE,
  }
}
