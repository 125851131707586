import React, { useEffect, useMemo, useState } from "react"
import i18n from "../../../../../../i18n"
import moment from "moment"
import { BaseModal } from "../../../../../../components"
import PrimaryButton from "../../../../../../components/Core/PrimaryButton"
import BasicTable from "../../../../../../components/Core/BasicTable"
import useCache from "../../../../../../context/LocalCache"
import { Checkbox, TextField } from "@material-ui/core"

export default function EnrollCustomers({
  open,
  setOpen,
  active = {},
  onSetCustomers,
}) {
  const [activeCustomerMap, setActiveCustomerMap] = useState({})
  const [toAddMap, setToAddMap] = useState({})
  const [search, setSearch] = useState("")
  const { getResource } = useCache()
  const customers = getResource((cache) => {
    return cache.company.customers || cache.employee.customers
  })

  useEffect(() => {
    if (open) {
      setToAddMap({})
      const activeCustomerMap = {}
      ;(active.customers || []).map((customer) => {
        activeCustomerMap[customer._id._id] = true
      })
      setActiveCustomerMap(activeCustomerMap)
    }
  }, [open, active._id])

  const filteredCustomers = useMemo(() => {
    if (search) {
      return customers.filter((customer) => {
        return (
          customer.name.toLowerCase().includes(search.toLowerCase()) ||
          customer.phoneNo.includes(search.toLowerCase()) ||
          customer.email?.toLowerCase().includes(search.toLowerCase()) ||
          customer.fistName?.toLowerCase().includes(search.toLowerCase())
        )
      })
    }
    return customers
  }, [open, customers, search])

  if (!open) {
    return null
  }
  return (
    <BaseModal
      open={open}
      contentContainerStyle={{
        overflowY: "visible",
      }}
      title={i18n.t("enroll_customers")}
      onClose={() => {
        setOpen(false)
        setSearch("")
      }}
      content={
        <div>
          <div className="col-12 d-flex align-items-center mb-3 px-0">
            <label className="font-weight-bold mr-3">{i18n.t("search")}</label>
            <TextField
              placeholder={i18n.t("search")}
              onChange={(e) => setSearch(e.target.value)}
              variant="outlined"
            />
            <PrimaryButton
              className="w-128 ml-3"
              label={i18n.t("save")}
              onClick={() =>
                onSetCustomers(
                  Object.keys(toAddMap),
                  Object.entries(activeCustomerMap)
                    .filter(([key, value]) => !value)
                    .map(([_id]) => ({ _id })),
                  setOpen,
                  active
                )
              }
            />
          </div>
          <BasicTable
            rows={filteredCustomers}
            schema={[
              {
                key: "enroll",
                header: i18n.t("enrolled"),
                style: { width: 45 },
                render: (val) => {
                  return (
                    <Checkbox
                      edge="start"
                      checked={Boolean(
                        activeCustomerMap[val._id] || toAddMap[val._id]
                      )}
                      tabIndex={-1}
                      onClick={() => {
                        console.log(
                          "sdsfssgs",
                          activeCustomerMap,
                          val._id in activeCustomerMap
                        )
                        if (val._id in activeCustomerMap) {
                          activeCustomerMap[val._id] =
                            !activeCustomerMap[val._id]
                          setActiveCustomerMap({ ...activeCustomerMap })
                        } else {
                          toAddMap[val._id] = true
                          setToAddMap({ ...toAddMap })
                        }
                      }}
                    />
                  )
                },
              },
              {
                header: i18n.t("name"),
                field: "name",
                render: (row, index) => {
                  return `${row.firstName ? row.firstName : ""} ${
                    row.name
                  }`.trim()
                },
              },
              {
                header: i18n.t("phone_number"),
                field: "phoneNo",
              },
              {
                header: i18n.t("container.profile_email"),
                field: "email",
              },
            ]}
          />
        </div>
      }
    />
  )
}
