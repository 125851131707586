import React, { Component } from "react";
import ProfileSection from "./Components/ProfileSection.component";
import SideDrawer from "./Components/SideDrawer.component";
import { SuccessHelper } from "../../helpers";
import i18n from "../../i18n";
import _ from "lodash";
import { request as get_available_timeslots } from "../../redux/actions/GetAvailableTimeslots.js";

import {
  editCustomerProfile,
  getWallet,
  getWaitingList,
  editBooking,
  createBooking,
} from "../../config/simpleApiCalls";
import { ErrorHelper } from "../../helpers";

import "./styles/profile.sass";
import BookingHistory from "./Components/BookingHistory.component";
import Wallet from "./Components/Wallet.component";
import WaitingList from "./Components/WaitingList.component";
import moment from "moment";
import { Images } from "../../theme";
import CardManagment from "../Card/CardManagment/index";
import { logout as user_logout } from "../../redux/actions/Login";
import { set } from "../../redux/actions/User";
import { getRequest as getCard } from "../../redux/actions/Card";
import { clear } from "../../redux/actions/ActiveBooking";
import { connect } from "react-redux";
import {
  getRequest,
  removeRequest,
  updateRequest,
  insertRequest,
} from "../../redux/actions/Card";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import UserPackages from "./Components/UserPackages";
import { getUserPackagesRequest } from "../../redux/actions/Packages";
import { TextField } from "@material-ui/core";
import DatePicker from "react-datepicker";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import SharedStorage from "../../helpers/Storage";
class Profile extends Component {
  state = {
    user: null,
    updateBookings: false,
    allWalletInfo: null,
    ammount: 0,
    display: {
      displayProfile: true,
      displayBooking: false,
      displayWallet: false,
      displayWaitingList: false,
      displayCards: false,
      displayPackages: false,
    },
    textMsg: "",
    selectSalon: "",
  };

  async componentDidMount() {
    try {
      const user = await JSON.parse(await SharedStorage.getItem("user"));
      this.setUserData(user);
      this.getUserWalletInfo();
      this.getWaitingList();
      this.props.getUserPackagesRequest({});
    } catch {
      alert("Something went wronge ;(");
    }
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    let toSet = {};
    if (nextProps.getAvailableTimeslots) {
      if (
        !nextProps.getAvailableTimeslots.failure &&
        !nextProps.getAvailableTimeslots.isFetching &&
        !nextProps.getAvailableTimeslots.errorMessage &&
        nextProps.getAvailableTimeslots.data &&
        nextProps.getAvailableTimeslots.data.data
      ) {
        toSet = {
          ...toSet,
          isloading: false,
        };
        if (!prevState.lockFields) {
          toSet.availableTimeslots = nextProps.getAvailableTimeslots.data.data;
        }
      } else if (nextProps.getAvailableTimeslots.isFetching) {
        toSet = { ...toSet, isloading: true };
      }
    }
    if (nextProps.booking) {
      nextProps.clear();
      const booking = { ...nextProps.booking };

      toSet = {
        ...toSet,
        displayProfile: false,
        displayBooking: true,
        displayWallet: false,
        displayWaitingList: false,
        showPopUp: true,
        selectBooking: booking,
      };
    }
    return toSet;
  }

  setUserData = (userData) => {
    if (userData && userData.access_token) {
      this.setState({ user: userData });
    }
  };

  editProfile = async () => {
    const { user, firstName, lastName, phoneNo, postalCode } = this.state;
    let payload = {};
    if (postalCode != null) {
      payload.postalCode = postalCode;
      this.setState({ postalCode: null });
    }
    if (phoneNo != null) {
      payload.phoneNo = phoneNo;
      this.setState({ phoneNo: null });
    }
    if (firstName != null) {
      payload.firstName = firstName;
      this.setState({ firstName: null });
    }
    if (lastName != null) {
      payload.lastName = lastName;
      this.setState({ lastName: null });
    }

    try {
      if (!_.isEmpty(payload)) {
        // saving role and accesstoken to use it later for session storage
        // if access token is not saved, we log out after updating the server
        let currentSession = _.pick(
          JSON.parse(await SharedStorage.getItem("user")),
          ["role", "access_token"]
        );

        // calling the backend to update the user information
        const respose = await editCustomerProfile(payload);
        // updating the session storage to prevent logging out
        const updatedUser = _.pick(respose.data.data, [
          "_id",
          "firstName",
          "lastName",
          "phoneNo",
          "postalCode",
          "userName",
          "email",
          "profile_img",
          "createdDate",
        ]);

        const joinedsession = { ...currentSession, ...updatedUser };
        const updatedUserString = JSON.stringify(joinedsession);

        SharedStorage.setItem("user", updatedUserString);

        SuccessHelper.handleSuccess(
          i18n.t("container.profile_edit_success"),

          true
        );
      }
    } catch (error) {
      alert(`Somthing went wronge ;( \n ${error.data.data}`);
    }
  };

  handleFields = (val, propName) => {
    this.setState({ [propName]: val });
  };

  handleUpdate = async (selectBooking) => {
    const payload = {
      ...selectBooking,
      bookingId: selectBooking._id,
      status: 3,
      services: selectBooking.services.map((service) => {
        return {
          ...service,
          categoryId:
            typeof service.categoryId == "object"
              ? service.categoryId._id
              : service.categoryId,
          employeeId:
            typeof service.employeeId == "object"
              ? service.employeeId._id
              : service.employeeId,
          serviceId:
            typeof service.serviceId == "object"
              ? service.serviceId._id
              : service.categoryId,
        };
      }),
    };
    delete payload._id;
    delete payload.__v;
    delete payload.userId;
    delete payload.companyId;
    delete payload.updatedDate;
    delete payload.createdDate;
    delete payload.email;

    const res = await editBooking(payload)
      .then(() => {
        SuccessHelper.handleSuccess("Updated Successfully", true);
        this.setState({ updateBookings: true });
      })
      .catch(() => {
        ErrorHelper.handleErrors("Failed to update", true);
      });
  };

  getUserWalletInfo = () => {
    getWallet()
      .then((res) => {
        this.setState({
          allWalletInfo: res.data.data.transactions,
          ammount: res.data.data.totalAmount,
        });
      })
      .catch((error) => {
        console.log("Profile -> getUserWalletInfo -> error", error);
      });
  };
  getWaitingList = () => {
    getWaitingList({})
      .then((res) => {
        this.setState({
          waitingList: res.data.data,
        });
      })
      .catch((error) => { });
  };

  handleDisplay = (display) => {
    this.setState({ display });
  };
  scrollUp = () => {
    this.messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  scrollDown = () => {
    this.messagesStartRef.current.scrollIntoView({ behavior: "smooth" });
  };

  renderSupportMessage = (val, id) => {
    return (
      <div key={id} className="row mb-3 mb-md-4 support-message">
        <div className="col-10 col-md-6">
          <div className="col-12 py-3">
            <div className="row mb-2">
              <div className="col-6">
                <span className="support-user-name">{val.senderName}</span>
              </div>
              <div className="col-6 text-right">
                <span className="support-message-time">
                  {moment(val.createdDate).fromNow()}
                </span>
              </div>
            </div>
            <p className="support-text-message">{val.msg}</p>
          </div>
        </div>
      </div>
    );
  };

  renderUserMessage = (val, id) => {
    return (
      <div key={id} className="row mb-3 mb-md-4 user-message">
        <div className="col-10 col-md-6 ml-auto">
          <div className="col-12 py-3">
            <div className="row mb-2">
              <div className="col-6">
                <span className="support-user-name">{val.senderName}</span>
              </div>
              <div className="col-6 text-right">
                <span className="support-message-time">
                  {moment(val.createdAt).fromNow()}
                </span>
              </div>
            </div>
            <p className="support-text-message">{val.msg}</p>
          </div>
        </div>
      </div>
    );
  };

  renderMessagesContainer = () => {
    const { messages_data, user } = this.state;
    return (
      <div className="col-12 aaaaaaa" id="messages-container">
        <div ref={this.messagesStartRef} />
        {messages_data &&
          !!messages_data.length &&
          messages_data.map((val, id) => {
            console.log(val);
            if (val.userId === user._id) {
              return this.renderUserMessage(val, id);
            } else {
              return this.renderSupportMessage(val, id);
            }
          })}
        <div ref={this.messagesEndRef} />
      </div>
    );
  };
  handleLogout = () => {
    this.props.user_logout();
    this.setState({ user: null });
    // this.handleNavigation("/");
  };

  renderPackages = () => {
    const { user = {} } = this.state;

    return (
      <div className="packagesContainer">
        <h2>Packages</h2>
        <div className="mainBookingBorder px-3">
          <UserPackages
            phoneNo={user?.phoneNumber}
            email={user?.email}
            name={user?.firstName}
            bookNow={this.bookNow}
          />
        </div>
      </div>
    );
  };

  bookNow = (payload) => {
    const { user = {} } = this.state;
    this.setState({ isloading: true });
    if (
      payload.services[0].time &&
      payload.services[0].date &&
      payload.services[0].employeeId
    ) {
      payload.name = payload.userName;
      payload.phoneNo = user?.phoneNo;
      delete payload.userName;
      return createBooking(payload)
        .then((res) => {
          if (res.data.success) {
            SuccessHelper.handleSuccess(i18n.t("booking_created"), true);
            this.setState({ isloading: false });
          } else {
            this.setState({ isloading: false });
            ErrorHelper.handleErrors(res.data.msg, true);
          }
        })
        .catch((error) => {
          this.setState({ isloading: false });
          ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
        });
    }
  };

  render() {
    const {
      user,
      updateBookings,
      allWalletInfo,
      ammount,
      waitingList,
      display,
    } = this.state;
    return (
      user && (
        <div className="profileContainer">
          <SideDrawer display={display} handleDisplay={this.handleDisplay} />
          <header className="profileHeader">
            <img
              style={{ height: "65%" }}
              alt="easy1 logo 800x300"
              src={Images.easy1_logo_800x300}
              className="cursor-pointer mainLogo newProfileLogo"
              onClick={() => this.handleNavigation("/")}
            />
            <div onClick={this.handleLogout} className="logOut">
              <span>Logout</span>
              <ExitToAppIcon />
            </div>
          </header>
          {display.displayProfile && (
            <ProfileSection
              user={user}
              editProfile={this.editProfile}
              handleFields={this.handleFields}
            />
          )}
          {display.displayBooking && (
            <BookingHistory
              handleUpdate={this.handleUpdate}
              updateBookings={updateBookings}
            />
          )}
          {display.displayWallet && (
            <Wallet allWalletInfo={allWalletInfo} ammount={ammount} />
          )}
          {display.displayWaitingList && (
            <WaitingList waitingList={waitingList} />
          )}
          {display.displayPackages && this.renderPackages()}

          {/* {display.displayCards && (
            <CardManagment
              removeDefault={true}
              getRequest={this.props.getRequest}
              removeRequest={this.props.removeRequest}
              updateRequest={this.props.updateRequest}
              insertRequest={this.props.insertRequest}
              cards={this.props.cards}
            />
          )} */}
        </div>
      )
    );
  }
}
const mapStateToProps = (state) => ({
  logout: state.logout,
  notifications: [...state.getNotifications.data],
  booking: state.activeBooking.data,
  cards: state.card.data,
  getAvailableTimeslots: state.getAvailableTimeslots,
});

const action = {
  user_logout,
  clear,
  set,
  getCard,
  removeRequest,
  updateRequest,
  insertRequest,
  getRequest,
  getUserPackagesRequest,
  get_available_timeslots,
};
export default connect(mapStateToProps, action)(Profile);
