import firebase from "firebase/app"
import "firebase/messaging"

export default function init() {
  var firebaseConfig = {
    apiKey: "AIzaSyA2THsdvgvkLWyLpCj39gPHlbCp65Djus4",
    authDomain: "scenic-lane-275515.firebaseapp.com",
    databaseURL: "https://scenic-lane-275515.firebaseio.com",
    projectId: "scenic-lane-275515",
    storageBucket: "scenic-lane-275515.appspot.com",
    messagingSenderId: "942023842711",
    appId: "1:942023842711:web:44bdbf7c28a4a5014ec2d2",
    measurementId: "G-H198Q4RWQ3",
  }

  firebase.initializeApp(firebaseConfig)
  const messaging = firebase.messaging()
  return {
    getToken: (setToken) => {
      return messaging
        .getToken({
          vapidKey:
            "BPyjyTdvnAn9PqWV3nUC7CnFdUb5faxeBHr_tOI8wGNtd7lbYIQH4PX-ltOHbOLx9k3QbOPbQOVXes8znkah_Q4",
        })
        .then((currentToken) => {
          if (currentToken) {
            setToken(currentToken)
          } else {
            setToken(null)
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err)
        })
    },
    onMessageListener: () =>
      new Promise((resolve) => {
        messaging.onMessage((payload) => {
          resolve(payload)
        })
      }),
  }
}
