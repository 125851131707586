import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core"
import moment from "moment"
import React from "react"
import { useMemo } from "react"
import { useSelector } from "react-redux"
import BasicTable from "../../../../components/Core/BasicTable"
import useCache from "../../../../context/LocalCache"
import i18n from "../../../../i18n"
import useScreenSize from "../../../../context/ScreenSize"
const arrayToMap = (array) => {
  const map = {}
  array.map(({ _id, name }) => {
    map[_id] = name
  })
  return map
}
export default function IntakeTables() {
  const { getResource } = useCache()
  const results = useSelector((s) => s.intake.data)
  const intakeCategories = getResource(
    (cache) => cache.company.intakeCategories
  )
  const categoryMap = useMemo(() => {
    return arrayToMap(intakeCategories)
  }, [intakeCategories])
  const products = getResource((cache) => cache.company.products)
  const productMap = useMemo(() => {
    return arrayToMap(products)
  }, [products])
  const services = getResource((cache) => cache.company.services)
  const serviceMap = useMemo(() => {
    return arrayToMap(services)
  }, [services])
  const intakeTypes = useSelector((state) => state.intakeType.data)
  const intakeTypeMap = useMemo(() => {
    return arrayToMap(intakeTypes)
  }, [intakeTypes])
  const activities = useSelector((s) => s.activity.data)
  const activityMap = useMemo(() => {
    return arrayToMap(activities)
  }, [activities])
  const { screenSize } = useScreenSize()
  const [groups, groupedRows, totalRecords] = useMemo(() => {
    const map = {}
    const groupRowsMap = {}
    let totalRecords = 0

    const addToGroup = ({ element, key, item }) => {
      if (!groupRowsMap[item?.intakeCategoryId || key]) {
        groupRowsMap[item?.intakeCategoryId || key] = {
          id: item?.intakeCategoryId || element._id,
          name: categoryMap[item?.intakeCategoryId] || key,
          rows: [],
        }
      }
      groupRowsMap[item?.intakeCategoryId || key].rows.push({
        ...element,
        intakeCategoryItemId: item || "",
      })
      if (!map[item?._id || key]) {
        map[item?._id || key] = {
          name: item?.name || key,
          count: 0,
        }
      }
      map[item?._id || key].count++
    }

    results.map((element) => {
      totalRecords++
      element.intakeCategoryItemId.map((item) => {
        addToGroup({ element, item })
      })

      if (!element.intakeCategoryItemId.length) {
        if (
          element.services.length &&
          element.activities.length &&
          element.products.length
        ) {
          addToGroup({ element, key: i18n.t("services_products_activities") })
        } else if (element.services.length && element.activities.length) {
          addToGroup({ element, key: i18n.t("services_activities") })
        } else if (element.activities.length && element.products.length) {
          addToGroup({ element, key: i18n.t("products_activities") })
        } else if (element.services.length && element.products.length) {
          addToGroup({ element, key: i18n.t("services_products") })
        } else if (element.services.length) {
          addToGroup({ element, key: i18n.t("services") })
        } else if (element.products.length) {
          addToGroup({ element, key: i18n.t("products") })
        } else if (element.activities.length) {
          addToGroup({ element, key: i18n.t("activities") })
        }
      }
    })

    return [Object.values(map), Object.values(groupRowsMap), totalRecords]
  }, [results])

  return (
    <div
      style={screenSize === "mobile" ? { height: "70vh" } : { height: "80vh" }}
      className="d-flex flex-column gap-1"
    >
      <div class="table-responsive overflow-visible">
        <BasicTable
          rows={[{}]}
          schema={groups.map((group) => ({
            header: group.name,
            field: group.name,
            render: () => {
              return <div>{group.count}</div>
            },
          }))}
        />
      </div>

      <TableContainer component={Paper}>
        <Table
          id="table"
          aria-label="activities Table"
          className="table mobile-max-content"
        >
          <TableHead>
            <TableRow
              style={{
                position: "sticky",
                top: -1,
                zIndex: 1,
                backgroundColor: "white",
              }}
            >
              <TableCell>{i18n.t("category")}</TableCell>
              <TableCell>{i18n.t("name")}</TableCell>
              <TableCell>{i18n.t("profile_email")}</TableCell>
              <TableCell>{i18n.t("employe_phone_number")}</TableCell>
              <TableCell>{i18n.t("services")}</TableCell>
              <TableCell>{i18n.t("products")}</TableCell>
              <TableCell>{i18n.t("activities")}</TableCell>
              <TableCell>{i18n.t("type")}</TableCell>
              <TableCell>{i18n.t("created_by")}</TableCell>
              <TableCell>{i18n.t("date")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell colSpan={10}>
                <div>{totalRecords} Results</div>
              </TableCell>
            </TableRow>
            {groupedRows.map((groupedRow) => {
              return (
                <>
                  <TableRow>
                    <TableCell colSpan={10}>
                      <div>
                        {groupedRow.name}: {groupedRow.rows.length}
                      </div>
                    </TableCell>
                  </TableRow>
                  {(groupedRow.rows || []).map((row, index) => (
                    <TableRow key={`${row._id}-${index}`}>
                      <TableCell>
                        <div>{row.intakeCategoryItemId?.name}</div>
                      </TableCell>
                      <TableCell>
                        <div>{row.companyCustomerId?.name}</div>
                      </TableCell>
                      <TableCell>
                        <div>{row.companyCustomerId?.email}</div>
                      </TableCell>
                      <TableCell>
                        <div>{row.companyCustomerId?.phoneNo}</div>
                      </TableCell>
                      <TableCell>
                        <div>
                          {row.services
                            .map((serviceId) => serviceMap[serviceId])
                            .join(", ")}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div>
                          {row.products
                            .map((productId) => productMap[productId])
                            .join(", ")}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div>
                          {row.activities
                            .map((activityId) => activityMap[activityId])
                            .join(", ")}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div>{intakeTypeMap[row.intakeTypeId]}</div>
                      </TableCell>
                      <TableCell>
                        {`${row.createdBy?.firstName || ""} ${
                          row.createdBy?.lastName || ""
                        }`}
                      </TableCell>
                      <TableCell>
                        <div>
                          {moment(row.createdDate).format("MM-DD-YYYY HH:mm")}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
