import { intake } from "../../../config/WebServices"
import SagaBuilder from "../../builders/SagaBuilder"
import actions from "../../actions/Saloon/Intake"

const _intake = SagaBuilder.build({
  type: "INTAKE",
  endpoint: intake,
  actions,
})

export default _intake
