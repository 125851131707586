import React, { useEffect, useMemo, useRef, useState, useCallback } from "react"
import { getRequest as getCustomerGroups } from "../../../redux/actions/Saloon/CompanyCustomerGroups"
import {
  getRequest as getTemplates,
  upsertRequest,
  removeRequest,
  upsertEventRequest,
  removeEventRequest,
} from "../../../redux/actions/Saloon/ActivityScheduleTemplate"
import ActivityActions from "../../../redux/actions/Saloon/Activity"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import FullCalendar from "@fullcalendar/react"
import interactionPlugin from "@fullcalendar/interaction"
import timeGridPlugin from "@fullcalendar/timegrid"
import dayGridPlugin from "@fullcalendar/daygrid"
import scrollGridPlugin from "@fullcalendar/scrollgrid"
import { getSaloonSchedule } from "../../../config/simpleApiCalls"
import "./ActivityScheduleTemplates.scss"
import UpsertScheduleEvent from "./Components/UpsertScheduleEvent"
import i18n from "../../../i18n"
import { ErrorHelper, SuccessHelper } from "../../../helpers"
import deleteConfirmation from "../../../helpers/DeleteHelper"
import UpsertTemplate from "./Components/UpsertTemplate"
import MenuItem from "@material-ui/core/MenuItem"
import { Menu } from "@material-ui/core"
import useCache from "../../../context/LocalCache"
import { BaseModal, DotsLoader } from "../../../components"
import Select from "../../../components/Core/Select"
import PrimaryButton from "../../../components/Core/PrimaryButton"
const { getRequest: getActivities } = ActivityActions
import useScreenSize from "../../../context/ScreenSize"
export const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
]

const getContrastYIQ = (hexcolor) => {
  if (hexcolor) {
    let color = hexcolor.replace("#", "")
    const r = parseInt(color.substr(0, 2), 16)
    const g = parseInt(color.substr(2, 2), 16)
    const b = parseInt(color.substr(4, 2), 16)
    const yiq = (r * 299 + g * 587 + b * 114) / 1000
    return yiq >= 128 ? "black" : "white"
  }
  return "#000"
}

export default function ActivityScheduleTemplates() {
  const dispatch = useDispatch()
  const { getResource } = useCache()
  const allServices = getResource((cache) => cache.company.services)
  const employees = getResource((cache) => cache.company.employees)
  const filteredEmployees = useMemo(() =>
    employees.filter(({ isActive }) => isActive)
  )
  const [defaults, setDefaults] = useState({})
  const [addEventOpen, setAddEventOpen] = useState(false)
  const [addTemplateOpen, setAddTemplateOpen] = useState(false)
  const [active, setActive] = useState()
  const [activeTemplateId, setActiveTemplateId] = useState(null)
  const [formAnchorEl, setFormAnchorEl] = React.useState(null)
  const [start, setStart] = useState("00")
  const [end, setEnd] = useState("24:00:00")
  // const loading = useSelector((s) => s.companyCustomerGroups.isFetching);
  const templates = useSelector((s) => s.activityScheduleTemplate.data)
  const activities = useSelector((s) => s.activity.data)
  const activityMap = useMemo(() => {
    const map = {}
    activities.map((activity) => {
      map[activity._id] = activity.name
    })
    return map
  }, [activities])
  const formatTemplate = (template) => ({ ...template, value: template._id })
  const formattedTemplates = useMemo(() => {
    return templates.map(formatTemplate)
  }, [templates])
  const { screenSize } = useScreenSize()
  const activeTemplate = useMemo(() => {
    return formattedTemplates.find(
      (template) => template.value == activeTemplateId
    )
  }, [activeTemplateId, formattedTemplates])

  const [openFiltersModal, setOpentFiltersModal] = useState(false)
  const events = activeTemplate?.events || []
  useEffect(() => {
    dispatch(getCustomerGroups({}))
    dispatch(getActivities())
    dispatch(
      getTemplates(
        {},
        {
          success: ({ data }) => {
            if (data.length) {
              setActiveTemplateId(data[0]._id)
            }
          },
        }
      )
    )
    getSaloonSchedule()
      .then((res) => {
        if (res.data.success && res.data.data) {
          let start = undefined
          let end = undefined
          res.data.data.weekPlans.map((dayPlan) => {
            const { checkIn, checkOut } = dayPlan

            if (checkIn) {
              let hour = parseInt(checkIn.split(":")[0])
              if (start) {
                if (start > hour) {
                  start = hour
                }
              } else {
                start = hour
              }
            }
            if (checkOut) {
              let hour = checkOut
              if (end) {
                if (end < hour) {
                  end = hour
                }
              } else {
                end = hour
              }
            }
          })
          setEnd(end)
          setStart(start)
        }
      })
      .catch((error) => {
        console.log(error, "error")
      })
  }, [])

  useEffect(() => {
    if (!addEventOpen) {
      setActive()
    }
  }, [addEventOpen])

  const employeeMap = useMemo(() => {
    const map = {}
    filteredEmployees.map((employee) => {
      map[employee._id] = employee
    })
    return map
  }, [filteredEmployees])
  const renderEventContent = useCallback((eventInfo) => {
    const { timeText, event, view } = eventInfo
    const employeeId = event._def.extendedProps.event.companyEmployeeId
    const activityId = event._def.extendedProps.event?.activityId
    const activityName = activityMap[activityId]
    return (
      <>
        <div className="text-wrapper">
          {timeText}
          <br />
          {employeeMap[employeeId]?.employeeId?.userId?.firstName}
          {<b>{` - ${activityName}`}</b>}
        </div>
      </>
    )
  })
  const formattedEvents = useMemo(() => {
    return events
      .map((event) => {
        const startDateTime = moment(
          `${moment().format("MM-DD-YYYY")} ${event.startTime}`,
          "MM-DD-YYYY HH:mm"
        )
        const endDateTime = moment(
          `${moment().format("MM-DD-YYYY")} ${event.endTime}`,
          "MM-DD-YYYY HH:mm"
        )
        startDateTime.day(event.dayOfWeek)
        endDateTime.day(event.dayOfWeek)
        const companyEmployee = employeeMap[event.companyEmployeeId]
        const color = companyEmployee?.color || "#000"
        return {
          title: companyEmployee?.employeeId?.userId?.firstName,
          start: startDateTime.toDate(),
          end: endDateTime.toDate(),
          backgroundColor: color,
          activity: activityMap[event.activityId] || "",
          textColor: getContrastYIQ(color),
          event: {
            ...event,
          },
        }
      })
      .filter((event) => !!event)
  }, [events, employeeMap])
  const onEventMouseEnter = (e) => {}
  const onEventMouseLeave = (e) => {}
  const onEvenClick = (e) => {
    const event = e.event._def.extendedProps.event
    setAddEventOpen(true)
    setActive(event)
  }
  const onAdd = (data) => {
    dispatch(
      upsertRequest(
        {
          ...data,
        },
        {
          success: ({ data }) => {
            SuccessHelper.handleSuccess(
              i18n.t(
                activeTemplateId ? "successfully_updated" : "successfully_added"
              ),
              true
            )
            setActiveTemplateId(data)
            setAddTemplateOpen(false)
          },
          failure: () => {
            ErrorHelper.handleErrors(
              i18n.t(activeTemplateId ? "failed_to_update" : "failed_to_add"),
              true
            )
          },
        }
      )
    )
  }

  const onDelete = (id) => {
    deleteConfirmation(() => {
      dispatch(
        removeRequest(
          { id },
          {
            success: () => {
              SuccessHelper.handleSuccess(i18n.t("successfully_deleted"), true)
              setAddTemplateOpen(false)
              setActiveTemplateId(null)
            },
            failure: () => {
              ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true)
            },
          }
        )
      )
    })
  }

  const onAddEvent = (data) => {
    const { _id } = data
    dispatch(
      upsertEventRequest(
        {
          ...data,
          activityScheduleTemplateId: activeTemplateId,
        },
        {
          success: ({ data }) => {
            SuccessHelper.handleSuccess(
              i18n.t(data._id ? "successfully_updated" : "successfully_added"),
              true
            )
            setActive(data)
            if (!_id) {
              setAddEventOpen(false)
            }
          },
          failure: () => {
            ErrorHelper.handleErrors(
              i18n.t(data._id ? "failed_to_update" : "failed_to_add"),
              true
            )
          },
        }
      )
    )
  }

  const onDeleteEvent = (id) => {
    deleteConfirmation(() => {
      dispatch(
        removeEventRequest(
          { id },
          {
            success: () => {
              SuccessHelper.handleSuccess(
                "Your service is successfully deleted.",
                true
              )
              setAddEventOpen(false)
            },
            failure: () => {
              ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true)
            },
          }
        )
      )
    })
  }
  const renderButtons = () => {
    return (
      <>
        {activeTemplateId ? (
          <div className="d-flex gap-2 flex-wrap">
            <PrimaryButton
              className="w-128"
              label={i18n.t("edit_template")}
              onClick={() => {
                setAddTemplateOpen(true)
                setFormAnchorEl(null)
              }}
            />
            <PrimaryButton
              className="w-128"
              label={i18n.t("add_event")}
              onClick={() => {
                setAddEventOpen(true)
                setDefaults({})
                setFormAnchorEl(null)
              }}
            />
            <PrimaryButton
              className="px-3"
              label={i18n.t("add_new_template")}
              onClick={() => {
                setAddTemplateOpen(true)
                setActiveTemplateId(null)
                setFormAnchorEl(null)
              }}
            />
          </div>
        ) : (
          <PrimaryButton
            className="w-128"
            label={i18n.t("add")}
            onClick={() => {
              setAddTemplateOpen(true)
              setActiveTemplateId(null)
            }}
          />
        )}
      </>
    )
  }
  const renderSelect = () => {
    return (
      <div className="d-flex align-items-center col-md-4 px-0 mb-4">
        <label className="font-weight-bolder mr-3">{i18n.t("template")}</label>
        <Select
          className="w-100"
          value={activeTemplateId}
          onChange={(e) => setActiveTemplateId(e.target.value)}
          options={formattedTemplates}
          label={i18n.t("template")}
          variant="outlined"
        />
      </div>
    )
  }
  return (
    <div className="content-container p-4">
      <DotsLoader isloading={false} />
      <div className="d-sm-flex justify-content-between align-items-center mb-4">
        <h1 className="saloon-dashboard-heading pt-0 mb-4">
          {i18n.t("activities_schedule_template")}
        </h1>
        {screenSize !== "mobile" ? (
          <>
            <>{renderButtons()}</>
          </>
        ) : (
          <div className="d-flex justify-content-center">
            <PrimaryButton
              label={i18n.t("filters")}
              onClick={() => setOpentFiltersModal(true)}
              className="w-128"
            />
          </div>
        )}
      </div>
      {screenSize !== "mobile" ? <>{renderSelect()}</> : null}

      <BaseModal
        open={openFiltersModal}
        onClose={() => setOpentFiltersModal(false)}
        title={i18n.t("filters")}
        content={
          <>
            <div className="mb-5">{renderButtons()}</div>
            <>{renderSelect()}</>
          </>
        }
      />
      <div className="row mx-0 ActivityScheduleTemplates">
        <UpsertScheduleEvent
          onAdd={onAddEvent}
          allServices={allServices}
          employees={filteredEmployees}
          open={addEventOpen}
          setOpen={setAddEventOpen}
          defaults={defaults}
          active={active}
          onDelete={onDeleteEvent}
        />
        <UpsertTemplate
          open={addTemplateOpen}
          setOpen={setAddTemplateOpen}
          active={activeTemplate}
          onAdd={onAdd}
          onDelete={onDelete}
        />
        <div className="CalendarContainer plan-calendar-wrapper w-100">
          <FullCalendar
            events={formattedEvents}
            eventMouseEnter={onEventMouseEnter}
            eventMouseLeave={onEventMouseLeave}
            eventClick={onEvenClick}
            slotDuration="00:15:00"
            contentHeight={3400}
            expandRows
            allDaySlot={false}
            stickyHeaderDates
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              scrollGridPlugin,
            ]}
            stickyFooterScrollbar={true}
            dayMinWidth={screenSize === "mobile" ? 150 : undefined}
            eventContent={renderEventContent}
            eventTimeFormat={{
              hour: "numeric",
              minute: "2-digit",
              meridiem: "short",
            }}
            initialView="timeGridWeek"
            dateClick={async (e) => {
              if (!activeTemplateId) {
                return
              }
              const date = moment(e.date)
              setDefaults({
                startTime: date.format("HH:mm"),
                dayOfWeek: daysOfWeek[date.day()],
              })
              setAddEventOpen(true)
            }}
            slotMinTime={`${start}:00:00`}
            slotMaxTime={end}
            dayHeaderContent={(e) => daysOfWeek[e.dow]}
            headerToolbar={false}
            eventOverlap={false}
            weekends
          />
        </div>
      </div>
    </div>
  )
}
