import React from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import styled from "styled-components";
import FormControl from "@material-ui/core/FormControl";

import i18n from "../../../i18n";

const DetailsBox = styled.section``;
export default function DetailsInput({ onChange, value }) {
  return (
    <DetailsBox>
      <FormControl>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox onChange={onChange} checked={value} />}
            label={i18n.t("section_required_details")}
          ></FormControlLabel>
        </FormGroup>
      </FormControl>
    </DetailsBox>
  );
}
