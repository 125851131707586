import { connect } from "react-redux"
import React, { Component } from "react"
import { Translation } from "react-i18next"
import i18n from "../../../i18n"
import { DotsLoader } from "../../../components"
import { request as SaloonAppointment } from "../../../redux/actions/Saloon/SaloonAppointment"
import { ErrorHelper, SuccessHelper } from "../../../helpers"
import { request as get_available_timeslots } from "../../../redux/actions/GetAvailableTimeslots.js"
import { request as get_gym_timeslots } from "../../../redux/actions/GetGymTimeslots.js"
import {
  createLiveDisplaySettings,
  getLiveDisplaySettings,
  createLiveDisplaySlideResource,
  deleteLiveDisplaySlideResource,
} from "../../../config/simpleApiCalls"
import "./styles.css"
import { SRLWrapper } from "simple-react-lightbox"
import PrimaryButton from "../../../components/Core/PrimaryButton"
import TextField from "@material-ui/core/TextField"
import deleteConfirmation from "../../../helpers/DeleteHelper"
import { Images } from "../../../theme"
import ToggleSelect from "../../../components/ToggleSelect"
import NumberInput from "../../../components/NumberInput"
import { ScreenSizeContext } from "../../../context/ScreenSize.jsx"
const imageSizeLimit = 10485760
const videoSizeLimit = 104857600

class LiveDisplaySettings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rowsMap: {},
      language: "English",
      slides: [],
      fields: [
        { value: "category", text: i18n.t("container.category") },
        { value: "time", text: i18n.t("container.time") },
        { value: "employee", text: i18n.t("container.employee") },
        {
          value: "name",
          text: i18n.t("container.advancedSearchDialog_name"),
        },
        { value: "status", text: i18n.t("container.profile_status") },
        { value: "seat", text: i18n.t("seat") },
      ],
      slideDuration: 15,
      showEntireDaysBookings: "no",
    }
  }

  async componentDidMount() {
    this.setState({
      isloading: true,
    })
    getLiveDisplaySettings({}).then((res) => {
      let { language, slides, slideDuration, showEntireDaysBookings } =
        this.state
      const rowsMap = {}
      if (res) {
        res.columns.map((column) => {
          rowsMap[column.key] = column.hidden
        })
        language = res.language
        slides = res.slides
        slideDuration = res.slideDuration || 15
        showEntireDaysBookings = res.showEntireDaysBookings ? "yes" : "no"
      }
      this.setState({
        rowsMap,
        language,
        slides,
        slideDuration,
        showEntireDaysBookings,
        isloading: false,
      })
    })
  }

  renderLoader = () => {
    const { isloading } = this.state
    return <DotsLoader isloading={isloading} />
  }

  onCheckChange = (e, field) => {
    const { rowsMap } = this.state
    rowsMap[field.value] = e.target.checked
    this.setState({
      rowsMap,
    })
  }

  save = () => {
    const { rowsMap, fields, language, slideDuration, showEntireDaysBookings } =
      this.state
    this.setState({ isloading: true })
    const columns = fields.map(({ value }) => {
      return {
        key: value,
        hidden: value in rowsMap ? rowsMap[value] : false,
      }
    })
    createLiveDisplaySettings({
      columns,
      language,
      slideDuration,
      showEntireDaysBookings: showEntireDaysBookings == "yes",
    })
      .then(() => {
        this.setState({ isloading: false })
        SuccessHelper.handleSuccess(i18n.t("successfully_updated"), true)
      })
      .catch(() => {
        this.setState({ isloading: false })
        ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true)
      })
  }

  renderInputCol12 = (label, val, onChange, limits) => {
    return (
      <div className="site-form row align-items-center gap-1 form-group col-md-12 px-0">
        <label className="font-weight-bold" style={{ width: "15em" }}>
          {label}
        </label>
        <TextField
          label={label}
          value={val}
          onChange={(text) => onChange(text)}
          limits={limits}
          type="numberic"
          className="editField"
          variant="outlined"
        />
      </div>
    )
  }

  LanguageSelector = () => {
    return (
      <div className="site-form d-flex align-items-center gap-1 form-group col-md-12 px-0">
        <label className="font-weight-bold" style={{ width: "15em" }}>
          {i18n.t("language")}
        </label>
        <ToggleSelect
          value={this.state.language === "Français" ? true : false}
          onChange={(e) =>
            this.setState({ language: e ? "Français" : "English" })
          }
          active="Français"
          inactive="English"
        />
      </div>
    )
  }

  fileSelectedHandler = (event) => {
    const { slides } = this.state
    const imageUrlRegex = /([a-zA-Z0-9\s_\\.\-:])+(.png|.jpeg|.jpg|.gif)$/gm
    const videoUrlRegex = /([a-zA-Z0-9\s_\\.\-:])+(.mp4)$/gm
    let payloadFormdata = new FormData()
    const file = event.target.files[0]
    if (file) {
      console.log(file, imageSizeLimit)
      let checkFile = event.target.files[0].type.split("/")
      let isValidateType = file.type
      if (checkFile[0] === "image") {
        if (isValidateType.match(imageUrlRegex)) {
          if (file.size > imageSizeLimit) {
            return ErrorHelper.handleErrors(i18n.t("image_too_large"), true)
          }
          payloadFormdata.append("image", file)
        } else {
          ErrorHelper.handleErrors("Image Format not supported", true)
        }
      } else if (checkFile[0] === "video") {
        if (isValidateType.match(videoUrlRegex)) {
          if (file.size > videoSizeLimit) {
            return ErrorHelper.handleErrors(i18n.t("video_too_large"), true)
          }
          payloadFormdata.append("video", file)
        } else {
          ErrorHelper.handleErrors(
            "Video Format not supported,Please upload an mp4 file",
            true
          )
        }
      } else {
        return
      }
      this.setState({ isloading: true })
      createLiveDisplaySlideResource(payloadFormdata)
        .then(({ data }) => {
          this.setState({ isloading: false })
          if (data.success) {
            this.setState({ slides: [...slides, data.data] })
            SuccessHelper.handleSuccess(i18n.t("successfully_added"), true)
          } else {
            if (data.code) {
              ErrorHelper.handleErrors(i18n.t(data.code, data.errorData), true)
            } else {
              throw ""
            }
          }
        })
        .catch((e) => {
          ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true)
        })
      //send requiest
    }
  }

  handleDelete = ({ _id }, index) => {
    const { slides } = this.state
    this.setState({ isloading: true })
    deleteLiveDisplaySlideResource({ id: _id })
      .then(({ data }) => {
        this.setState({ isloading: false })
        if (data.success) {
          slides.splice(index, 1)
          this.setState({ slides })
          SuccessHelper.handleSuccess(i18n.t("successfully_deleted"), true)
        } else {
          throw ""
        }
      })
      .catch((e) => {
        ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true)
      })
  }

  renderSlideImages = () => {
    const { slides } = this.state
    return (
      <div className="px-4">
        <div class="mt-3">
          <h5>{i18n.t("live_display_slides")}</h5>
          <div>{i18n.t("maximum_file_size_warning")}</div>
        </div>
        <SRLWrapper>
          <div class="mx-0 display-slides">
            {slides.map((val, index) => {
              return (
                <div
                  key={val._id}
                  class="imageParent"
                  style={{ marginTop: "8px" }}
                >
                  <>
                    {val.content.resourceType == "video" ? (
                      <video width="100%" height="145px" controls>
                        <source src={val.content.url} />
                      </video>
                    ) : (
                      <img className="img-fluid1" src={val.content.url} />
                    )}

                    <div
                      class="middle"
                      onClick={() => {
                        deleteConfirmation(() => this.handleDelete(val, index))
                      }}
                    >
                      <div class="text">{i18n.t("delete")}</div>
                    </div>
                  </>
                </div>
              )
            })}
            <div className="preview-image-wrapper">
              <div id="upload-box" onClick={() => this.fileInput.click()}>
                <img
                  src={Images.saloon_upload_media_icon}
                  style={{ width: 24 }}
                  className="interact"
                />
                <p id="drop-file-text">{i18n.t("add_slide")}</p>
              </div>
            </div>
          </div>
        </SRLWrapper>
      </div>
    )
  }

  render() {
    const { rowsMap, fields } = this.state

    const { screenSize } = this.context
    return (
      <div>
        <input
          type="file"
          style={{ display: "none" }}
          onChange={this.fileSelectedHandler}
          ref={(fileInput) => (this.fileInput = fileInput)}
          accept="image/*,video/*"
        />
        {this.renderLoader()}
        <div className="content-container">
          <h1
            className="saloon-dashboard-heading pb-3 pl-0 pl-md-4"
            style={
              screenSize === "mobile"
                ? {
                    position: "sticky",
                    top: "0",
                    backgroundColor: "white",
                    zIndex: 2,
                  }
                : {}
            }
          >
            {i18n.t("container.live_display_settings")}
          </h1>
          <div className="p-0 p-md-4">
            <table className="table table-borderless bg-white mb-0 live-display-table">
              <thead>
                <tr>
                  <th>
                    <span>
                      <Translation>{(t) => t("field")}</Translation>
                    </span>
                    <span className="mr-3 mr-md-0">
                      <Translation>{(t) => t("hidden")}</Translation>
                    </span>
                  </th>
                  {screenSize !== "mobile" && (
                    <th>
                      <span>
                        <Translation>{(t) => t("field")}</Translation>
                      </span>
                      <span>
                        <Translation>{(t) => t("hidden")}</Translation>
                      </span>
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {fields.map((field) => {
                  return (
                    <tr>
                      <td
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        {field.text}
                      </td>
                      <td>
                        <div class="custom-control custom-checkbox text-right mr-4">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id={field.value}
                            checked={rowsMap[field.value]}
                            onChange={(e) => this.onCheckChange(e, field)}
                          />
                          <label
                            class="custom-control-label custom-checkbox-label"
                            for={field.value}
                          ></label>
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <div className="full-width bg-white px-4 px-md-0">
              <div className="py-3 mt-4">
                <div className="py-2 my-2">{this.LanguageSelector()}</div>
                <div className="py-2 my-2">
                  <div className="site-form row align-items-center gap-1 form-group col-12 px-0">
                    <label
                      className="font-weight-bold"
                      style={{ width: "15em" }}
                    >
                      {i18n.t("slide_durations")}
                    </label>
                    <div
                      className="duration-input"
                      style={{ width: "calc(50% - 15em)" }}
                    >
                      <NumberInput
                        label={i18n.t("slide_durations")}
                        value={this.state.slideDuration}
                        onChange={(slideDuration) => {
                          this.setState({ slideDuration })
                        }}
                        limits={{ min: 5, max: 30 }}
                        variant="outlined"
                      />
                    </div>
                  </div>
                </div>
                <div className="py-2 my-2">
                  <div className="site-form d-flex align-items-center gap-1 form-group col-md-12 px-0">
                    <label
                      className="font-weight-bold"
                      style={{ width: "15em" }}
                    >
                      {i18n.t("show_entire_days_booking")}
                    </label>
                    <ToggleSelect
                      value={
                        this.state.showEntireDaysBookings === "yes"
                          ? true
                          : false
                      }
                      onChange={(e) =>
                        this.setState({
                          showEntireDaysBookings: e ? "yes" : "no",
                        })
                      }
                      active="yes"
                      inactive="no"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center justify-content-md-start">
              <PrimaryButton onClick={() => this.save()} className="w-128 my-3">
                {i18n.t("save")}
              </PrimaryButton>
            </div>
          </div>
          {this.renderSlideImages()}
        </div>
      </div>
    )
  }
}

LiveDisplaySettings.contextType = ScreenSizeContext

const mapStateToProps = (state) => ({
  booking: state.SalonAppointment,
  getAvailableTimeslots: state.getAvailableTimeslots,
  getGymTimeslots: state.getGymTimeslots,
})

const action = {
  SaloonAppointment,
  get_available_timeslots,
  get_gym_timeslots,
}

export default connect(mapStateToProps, action)(LiveDisplaySettings)
