import React from "react"
import { TextValidator } from "react-material-ui-form-validator"
import useScreenSize from "../../../context/ScreenSize"
export default ({
  type = "text",
  label,
  value,
  onChange,
  helperText,
  errorMessages,
  validators,
}) => {
  const { screenSize } = useScreenSize()
  return (
    <div className="row col-12 px-0 pb-4 align-items-center inline-custom-form">
      <label
        className={`font-weight-bold ${
          screenSize === "mobile" ? "col-12 " : ""
        }`}
      >
        {label}
      </label>
      <div className={screenSize === "mobile" ? "col-12" : ""}>
        <TextValidator
          type={type}
          label={label}
          value={value}
          onChange={onChange}
          helperText={helperText}
          validators={validators}
          errorMessages={errorMessages}
          variant="outlined"
          className="input"
        />
      </div>
    </div>
  )
}
