import React, { Component, useEffect, useMemo, useRef, useState } from "react"
import i18n from "../../../i18n"
import { ErrorHelper, SuccessHelper } from "../../../helpers"
import BasicTable from "../../Core/BasicTable"
import {
  getByCustomerRequest,
  addTransactionRequest,
} from "../../../redux/actions/Saloon/ActivityScheduleEvent"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import InsertPayment from "../../CreateInvoice/components/InsertPayment.component"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
export default function CustomerActivities({
  setLoading,
  companyCustomerId,
  giftCardBalance,
}) {
  const dispatch = useDispatch()
  const activityScheduleEvents = useSelector(
    (s) => s.activityScheduleEvent.customerHistory || []
  )
  const [open, setOpen] = useState(false)
  const [activityToCollect, setActivityToCollect] = useState({ price: 0 })
  const [includeTaxes, setIncludeTaxes] = useState(true)
  const onSave = ({
    note,
    cash,
    creditDebit,
    giftCard,
    tip,
    discount,
    chargedAmountByCard,
  }) => {
    setLoading(true)
    dispatch(
      addTransactionRequest(
        {
          _id: activityToCollect._id,
          newTransaction: {
            note,
            cash,
            creditDebit,
            giftCard,
            tip,
            discount,
            chargedAmountByCard,
          },
          includeTaxes,
        },
        {
          success: ({ data }) => {
            setLoading(false)
            activityToCollect.balanceDue = data.balanceDue
            activityToCollect.transactions = data.transactions
            setOpen()
            SuccessHelper.handleSuccess(i18n.t("successfully_added"), true)
          },
          failure: (e) => {
            setLoading(false)
            ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true)
          },
        }
      )
    )
  }
  const items = [
    {
      name: activityToCollect?.name,
      price: activityToCollect?.price,
    },
  ]
  const { price = 0 } = activityToCollect
  const taxDue = activityToCollect?.taxes?.reduce(
    (accum, tax) => accum + tax.amount,
    0
  )
  const collected = activityToCollect?.transactions?.reduce((accum, e) => {
    if (!e.wasReversed) {
      return accum + e.amount + (e.discount || 0) - e.tip
    }
    return accum
  }, 0)
  const grandTotal = (includeTaxes ? taxDue : 0) + price - collected
  useEffect(() => {
    setLoading(true)
    dispatch(
      getByCustomerRequest(
        { id: companyCustomerId },
        {
          success: () => {
            setLoading(false)
          },
          failure() {
            setLoading(false)
            ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true)
          },
        }
      )
    )
  }, [])

  return (
    <div>
      <BasicTable
        rows={activityScheduleEvents}
        schema={[
          {
            header: i18n.t("container.advancedSearchDialog_name"),
            field: "name",
            render: (val) => {
              return val?.activityId?.name
            },
          },
          {
            header: i18n.t("category"),
            field: "category",
            render: (val) => {
              return val?.activityId?.activityCategoryId?.name
            },
          },
          {
            header: i18n.t("start_date"),
            field: "startDate",
            accessor: (val) => {
              return moment(val?.startDate).format("MM-DD-YYYY hh:mm a")
            },
          },
          {
            header: i18n.t("attended"),
            field: "attended",
            accessor: (val) => {
              const customer = val?.customers.find(
                (customer) => customer._id == companyCustomerId
              )
              return i18n.t(customer?.attended ? i18n.t("yes") : i18n.t("no"))
            },
          },
          {
            header: i18n.t("container.profile_employe"),
            field: "employee",
            accessor: (val) => {
              return (
                val?.companyEmployeeId?.employeeId?.userId?.userName ||
                `${val?.companyEmployeeId?.employeeId?.userId?.firstName} 
                ${val?.companyEmployeeId?.employeeId?.userId?.lastName}`.trim()
              )
            },
          },
          {
            key: "balance_due",
            header: i18n.t("balance_due"),
            accessor: (val) =>
              val.customerActivityScheduleEvent?.balanceDue.toFixed(2),
          },
          {
            key: "action",
            header: "",
            style: { width: 60 },
            render: (val) => {
              if (
                !val.customerActivityScheduleEvent ||
                val.customerActivityScheduleEvent?.balanceDue <= 0
              ) {
                return null
              }
              return (
                <div className="row mx-0">
                  <FontAwesomeIcon
                    className="view-icon"
                    color="white"
                    icon={["fas", "hand-holding-usd"]}
                    onClick={() => {
                      setActivityToCollect({
                        ...val.customerActivityScheduleEvent,
                        name: val.activityId.name,
                      })
                      setOpen(true)
                    }}
                  />
                </div>
              )
            },
          },
        ]}
      />

      <div className="insert-payment">
        <InsertPayment
          open={open}
          handleClose={() => setOpen(false)}
          defaultAmount={grandTotal}
          onSubmit={onSave}
          toggleTaxes={() => setIncludeTaxes(!includeTaxes)}
          items={items}
          subtotal={activityToCollect.price}
          taxDue={taxDue}
          includeTaxes={includeTaxes}
          grandTotal={grandTotal}
          collected={collected}
          giftCardBalance={giftCardBalance}
          customerId={companyCustomerId}
        />
      </div>
    </div>
  )
}
