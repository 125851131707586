import React, { Component } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import i18n from "../../../i18n";
import moment from "moment";
import "../styles/waitingList.sass";

class WaitingList extends Component {
  renderTableHead = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell className="tableHead">
            {i18n.t("container.profile_salon")}
          </TableCell>
          <TableCell className="tableHead">{i18n.t("profile_date")}</TableCell>
          <TableCell className="tableHead">
            {i18n.t("container.time")}
          </TableCell>
          <TableCell className="tableHead">
            {i18n.t("container.profile_employe_name")}
          </TableCell>
          <TableCell className="tableHead">
            {i18n.t("container.profile_service")}
          </TableCell>
        </TableRow>
      </TableHead>
    );
  };

  renderTableBody = () => {
    const { waitingList = [] } = this.props;
    return (
      <TableBody>
        {!waitingList ? (
          <h1>Loading...</h1>
        ) : (
          waitingList.map((value) => {
            return (
              <TableRow>
                <TableCell>
                  {value.companyId ? value.companyId.name : ""}
                </TableCell>
                <TableCell>{value.date}</TableCell>
                <TableCell>
                  {value.startTime} - {value.endTime}
                </TableCell>
                <TableCell>
                  {value?.employeeId?.userId?.firstName}{" "}
                  {value?.employeeId?.userId?.lastName}
                </TableCell>
                <TableCell>{value.serviceId.name}</TableCell>
              </TableRow>
            );
          })
        )}
      </TableBody>
    );
  };

  renderWaitingList = () => {
    // const { waitingList = [], user } = this.state;
    return (
      <div className="waitingList">
        <h2>Waiting List</h2>
        <TableContainer>
          <Table stickyHeader component={Paper}>
            {this.renderTableHead()}
            {this.renderTableBody()}
          </Table>
        </TableContainer>
      </div>
    );
  };
  render() {
    return this.renderWaitingList();
  }
}

export default WaitingList;
