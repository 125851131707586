import React, { useEffect, useState } from "react";
import DotsLoader from "../../../components/DotsLoader/index";
import i18n from "../../../i18n";
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import { upsertTax } from "../../../config/simpleApiCalls";
import AddEditForm from "./AddEditForm";
export default function AddTax() {
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [percentage, setPercentage] = useState("");
  const [isActive, setIsActive] = useState(true);
  const handleSaloonCreateTax = () => {
    setIsLoading(true);
    const payload = {
      name,
      percentage,
      isActive,
    };
    upsertTax(payload)
      .then((res) => {
        if (res.data.success) {
          SuccessHelper.handleSuccess("Tax successfully added.", true);
          setIsLoading(false);
          setName("");
          setPercentage("");
          setIsActive(true);
        }
        if (!res.data.success) {
          ErrorHelper.handleErrors(res.data.msg, true);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        ErrorHelper.handleErrors("An error occured", true);
        setIsLoading(false);
        console.log(error, "errorrrrrrrrrrrrrrrrrrr");
      });
  };

  const handleName = (name) => {
    setName(name.toUpperCase());
  };
  const handlePercentage = (percentage) => {
    setPercentage(percentage);
  };
  const handleIsActive = (isActive) => {
    setIsActive(isActive);
  };

  return (
    <>
      <DotsLoader isloading={isLoading} />
      <div className="content-container p-4">
        <div className="row">
          <div className="col-12 px-0">
            <h1 className="saloon-dashboard-heading pb-3">
              {i18n.t("add_tax")}
            </h1>
          </div>
          <div className="col-md-4 px-0">
            <AddEditForm
              {...{
                name,
                percentage,
                isActive,
                handleName,
                handlePercentage,
                handleIsActive,
              }}
              handleSubmit={handleSaloonCreateTax}
            />
          </div>
        </div>
      </div>
    </>
  );
}
