import { intake_type } from "../../../config/WebServices"
import SagaBuilder from "../../builders/SagaBuilder"
import actions from "../../actions/Saloon/IntakeType"

const intakeType = SagaBuilder.build({
  type: "INTAKE_TYPE",
  endpoint: intake_type,
  actions,
})

export default intakeType
