import React, { Component } from "react"
import { Translation } from "react-i18next"
import i18n from "../../../i18n"
import {
  getCompletedBooking,
  removeProduct,
  removeExtra,
  updateCartProduct,
  editBooking,
} from "../../../config/simpleApiCalls"
import { Images } from "../../../theme"
import BookingNotesReminders from "../../../components/BookingNotesReminders"
import {
  DotsLoader,
  AppointmentModal,
  AddExtrasModal,
  BaseModal,
} from "../../../components"
import Pagination from "react-js-pagination"
import { ErrorHelper } from "../../../helpers"
import NumberFormat from "react-number-format"
import { getNestedValue } from "../../../util/objectMethods"
import moment from "moment"
import Sytles from "./sytles"
import Swal from "sweetalert2"
import { Select, TextField, MenuItem } from "@material-ui/core"
import DatePicker from "react-datepicker"
import PrimaryButton from "../../../components/Core/PrimaryButton/index.jsx"
import useCache from "../../../context/LocalCache"
import { ScreenSizeContext } from "../../../context/ScreenSize.jsx"
class _BookingHistory extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: null,
      isloading: false,
      showEditDelete: "",
      searchText: "",
      usersList: [],
      // employeeList: [],
      filterUsersList: [],
      showPopUp: false,
      showNotePopUp: false,
      bookingStauses: [
        { text: "Waiting", value: 1, styles: { color: "#aa43b5" } },
        { text: "Now Serving", value: 3, styles: { color: "#FFE478" } },
        { text: "Cancel", value: 2, styles: { color: "#FF7082" } },
        { text: "Done", value: 4, styles: { color: "#95DA47" } },
      ],
      activePage: 1,
    }
  }

  componentDidMount = async () => {
    Promise.all([this.getAllHistory()])
      .then(() => {
        this.setState({ isloading: false })
      })
      .catch((error) => {
        this.setState({ isloading: false })
      })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { employees } = nextProps

    let employeeMap = {}
    const formattedEmployees = employees.map((companyEmployee) => {
      const { employee, user, archivedEmployeeId } = companyEmployee
      employeeMap[(employee && employee._id) || archivedEmployeeId._id] =
        user.userName
          ? user.userName
          : `${getNestedValue(user, "firstName", "")} ${getNestedValue(
              user,
              "lastName",
              ""
            )}`
      return {
        text: user.userName
          ? user.userName
          : `${getNestedValue(user, "firstName", "")} ${getNestedValue(
              user,
              "lastName",
              ""
            )}`,
        value: (employee && employee._id) || archivedEmployeeId._id,
      }
    })

    return {
      employees: formattedEmployees,
      employeeMap,
    }
  }

  renderLoader = () => {
    const { isloading } = this.state
    return <DotsLoader isloading={isloading} />
  }

  getAllHistory = () => {
    this.setState({ isloading: true })

    return getCompletedBooking({
      type: "company",
      relations: ["notes", "reminders"],
    })
      .then((res) => {
        this.setState({ bookings: res.data.data, isloading: false })
      })
      .catch((e) => {
        this.setState({ isloading: true, isloading: false })
        alert("Something Went Wrong ;(")
        console.log(e)
      })
  }

  handleFilters = (data) => {
    const normalize = (value) => {
      value = value ? value : ""
      return value.toLowerCase()
    }

    data.name = normalize(data.name)
    data.phoneNumber = normalize(data.phoneNumber).replace(/ /g, "")
    const filteredBookings = this.state.bookings.filter((booking) => {
      if (data.name) {
        if (
          !normalize(getNestedValue(booking, ["userId", "userName"])).match(
            data.name
          ) &&
          !normalize(getNestedValue(booking, "name", "")).match(data.name) &&
          !normalize(getNestedValue(booking, ["userId", "firstName"])).match(
            data.name
          ) &&
          !normalize(getNestedValue(booking, ["userId", "lastNme"])).match(
            data.name
          )
        ) {
          return false
        }
      }
      if (data.phoneNumber) {
        if (
          !normalize(getNestedValue(booking, ["userId", "phoneNo"], "")).match(
            data.phoneNumber
          ) &&
          !normalize(getNestedValue(booking, "phoneNo", "")).match(
            data.phoneNumber
          )
        ) {
          return false
        }
      }
      if (data.selectedService) {
        if (
          getNestedValue(booking, ["services", 0, "serviceId", "name"], "") !==
          data.selectedService
        ) {
          return false
        }
      }
      if (data.selectedEmployee) {
        if (
          getNestedValue(booking, ["services", 0, "employeeId", "_id"], "") !==
          data.selectedEmployee
        ) {
          return false
        }
      }
      if (data.startDate || data.endDate) {
        // moment=
        if (data.startDate && data.endDate) {
          const date = moment(
            getNestedValue(booking, ["services", 0, "date"], ""),
            "MM-DD-YYYY"
          )
          if (
            !moment(data.startDate).isSameOrBefore(date) ||
            !moment(data.endDate).isSameOrAfter(date)
          ) {
            return false
          }
        } else {
          if (
            (data.startDate &&
              normalize(getNestedValue(booking, ["services", 0, "date"], "")) !=
                moment(data.startDate).format("MM-DD-YYYY")) ||
            (data.endDate &&
              normalize(getNestedValue(booking, ["services", 0, "date"], "")) !=
                moment(data.endDate).format("MM-DD-YYYY"))
          ) {
            return false
          }
        }
      }
      if (data.selectedStatus) {
        if (getNestedValue(booking, ["status"], "") != data.selectedStatus) {
          return false
        }
      }
      return true
    })
    this.setState({ filteredBookings })
  }

  onChangeSearchText = (searchText) => {
    this.setState({ searchText })
  }

  renderSearchBar = () => {
    const { searchText, filteredBookings } = this.state
    const { screenSize } = this.context
    return (
      <div
        className="d-flex flex-column flex-grow-1"
        style={
          screenSize === "mobile"
            ? {
                position: "sticky",
                top: "0",
                zIndex: "2",
                backgroundColor: "white",
              }
            : {}
        }
      >
        <span className="saloon-dashboard-heading mb-4">
          {i18n.t("container.profile_booking_history")}
        </span>

        {screenSize === "mobile" && (
          <div className="d-flex justify-content-center mb-2">
            <PrimaryButton
              className="w-128"
              onClick={() => this.setState({ openFilterModal: true })}
            >
              {i18n.t("filters")}
            </PrimaryButton>
          </div>
        )}
      </div>
    )
  }

  renderFilters = () => {
    const {
      employees = [],
      name,
      phoneNumber,
      selectedService,
      startDate,
      endDate,
      selectedEmployee,
      selectedStatus,
    } = this.state
    const { services } = this.props
    const set = (data) => {
      this.setState(data)
      const filters = {
        name,
        phoneNumber,
        selectedService,
        startDate,
        endDate,
        selectedEmployee,
        selectedStatus,
        ...data,
      }
      this.handleFilters(filters)
    }
    return (
      <div className="filter-header">
        <div className="form-box">
          <label>{i18n.t("name")}</label>
          <TextField
            label={i18n.t("name")}
            fullWidth
            value={name}
            onChange={(e) => {
              set({ name: e.target.value })
            }}
            variant="outlined"
          />
        </div>
        <div className="form-box">
          <label>{i18n.t("phone")}</label>
          <TextField
            label={i18n.t("phone")}
            fullWidth
            value={phoneNumber}
            onChange={(e) => set({ phoneNumber: e.target.value })}
            variant="outlined"
          />
        </div>
        <div className="form-box">
          <label>{i18n.t("services")}</label>
          <Select
            value={selectedService}
            onChange={(e) => set({ selectedService: e.target.value })}
            variant="outlined"
            className="w-100"
          >
            <MenuItem value={null}>{i18n.t("select_service")}</MenuItem>
            {services.map((service) => {
              return (
                <MenuItem key={service._id} value={service.name}>
                  {service.name}
                </MenuItem>
              )
            })}
          </Select>
        </div>
        <div className="form-box">
          <label>{i18n.t("date")}</label>
          <div className="d-flex">
            <DatePicker
              onChange={(startDate) => set({ startDate })}
              dateFormat="MM/dd/yyyy"
              selected={startDate}
              value={startDate}
              customInput={
                <TextField
                  style={{ width: "100%" }}
                  label="Start Date"
                  variant="outlined"
                />
              }
            />
            <DatePicker
              onChange={(endDate) => set({ endDate })}
              dateFormat="MM/dd/yyyy"
              selected={endDate}
              value={endDate}
              customInput={
                <TextField
                  style={{ width: "100%" }}
                  label="End Date"
                  variant="outlined"
                />
              }
            />
          </div>
        </div>
        {employees && (
          <div className="form-box">
            <label>{i18n.t("container.employee")}</label>
            <Select
              className="w-100"
              value={selectedEmployee}
              onChange={(e) => set({ selectedEmployee: e.target.value })}
              variant="outlined"
            >
              <MenuItem value={null}>
                {i18n.t("container.select_employe")}
              </MenuItem>
              {employees.map((employee) => {
                return (
                  <MenuItem key={employee.value} value={employee.value}>
                    {employee.text}
                  </MenuItem>
                )
              })}
            </Select>
          </div>
        )}
        <div className="form-box">
          <label>{i18n.t("container.profile_status")}</label>
          <Select
            className="w-100"
            onChange={(e) => set({ selectedStatus: e.target.value })}
            value={selectedStatus}
            variant="outlined"
          >
            <MenuItem value={null}>{i18n.t("Status")}</MenuItem>
            <MenuItem value={4}>{i18n.t("done")}</MenuItem>
            <MenuItem value={3}>{i18n.t("cancel")}</MenuItem>
          </Select>
        </div>
      </div>
    )
  }

  renderPopup = () => {
    const { showPopUp } = this.state
    return (
      <div
        className="active-service-card-wrapper "
        style={{ display: showPopUp ? "flex" : "none" }}
      >
        <div className="container ">
          <div
            className="col-8 p-3 my-5 bg-white pop_container"
            style={{ borderRadius: "1rem", margin: "auto" }}
          >
            <div className="py-0 px-2 text-right">
              <span
                onClick={() => this.setState({ showPopUp: false })}
                className="h4 cursor-pointer"
              >
                &times;
              </span>
            </div>
            {this.renderBookingMoreInfo()}
          </div>
        </div>
      </div>
    )
  }

  renderNotePopup = () => {
    const { showNotePopUp, selectBooking } = this.state
    if (showNotePopUp) {
      return (
        <>
          <BaseModal
            open={showNotePopUp}
            {...{
              title: (
                <div
                  dangerouslySetInnerHTML={{
                    __html: i18n.t("notes_and_reminders"),
                  }}
                />
              ),
              content: (
                <BookingNotesReminders
                  booking={selectBooking}
                  hasEditPermission={true}
                />
              ),
            }}
            onClose={() => this.setState({ showNotePopUp: false })}
          />
        </>
      )
    } else {
      return null
    }
  }

  renderExtras = () => {
    const { showExtrasModal } = this.state
    if (showExtrasModal) {
      const { selectBooking, bookings } = this.state
      const { products, services } = this.props
      const onUpdate = (data) => {
        this.setState({ ...data })
      }
      return (
        <AddExtrasModal
          selectBooking={selectBooking}
          products={products}
          bookings={bookings}
          allServices={services}
          onUpdate={onUpdate}
          handleClose={() => {
            console.log("here")
            this.setState({ showExtrasModal: false })
          }}
        />
      )
    } else {
      return null
    }
  }

  genericUpdate = async (body) => {
    const { selectBooking } = this.state
    this.setState({
      isloading: true,
    })
    let { bookings } = this.state
    const services = [
      {
        serviceId: selectBooking.services[0].serviceId?._id,
        categoryId: selectBooking.services[0].categoryId?._id,
        date: selectBooking.services[0].date,
        time: selectBooking.services[0].time,
        employeeId: selectBooking.services[0].employeeId?._id,
      },
    ]
    const { data } = await editBooking({
      bookingId: selectBooking._id,
      status: selectBooking.status,
      services,
      ...body,
    })
    let newSelectBooking = selectBooking
    if (data.success) {
      const newBooking = data.data
      newBooking.services = newBooking.services.map((service) => {
        return {
          ...service,
          employeeId: service.employeeId._id,
        }
      })
      newSelectBooking = { ...newBooking, index: selectBooking.index }
      if (typeof selectBooking.index === "number") {
        bookings[selectBooking.index] = newSelectBooking
      }
      this.handleClosePopup()
      this.setState({
        isloading: false,
        bookings: [...bookings],
        selectBooking: newSelectBooking,
        editDetails: false,
        ...body,
      })
    }
  }

  onChangeTip = (tip) => {
    const { tip: oldTip } = this.state
    if (oldTip != tip) {
      this.genericUpdate({ tip })
    }
  }

  toggleDepositRequired = (depositRequired) => {
    this.genericUpdate({ depositRequired })
  }

  toggleTaxes = (includeTaxes) => {
    this.genericUpdate({ includeTaxes })
  }

  renderBookingMoreInfo = () => {
    const {
      showPopUp,
      selectBooking,
      bookingStatus,
      bookingStauses,
      isGym,
      showInsertPayment,
      paymentCompleteCallback,
      employeeMap,
      bookings,
    } = this.state

    const { companyTaxes } = this.props

    if (showPopUp) {
      return (
        <>
          {this.renderExtras()}
          <AppointmentModal
            showInsertPayment={showInsertPayment}
            paymentCompleteCallback={paymentCompleteCallback}
            setCart={(cartId) => {
              if (cartId) {
                if (
                  typeof selectBooking.index === "number" &&
                  bookings[selectBooking.index]
                ) {
                  bookings[selectBooking.index].cartId = cartId
                }
                this.setState({
                  selectBooking: { ...selectBooking, cartId },
                  bookings,
                })
              }
            }}
            open={showPopUp}
            selectBooking={selectBooking}
            employeeMap={employeeMap}
            isGym={isGym}
            isSalon={true}
            handleDrawerClose={(ev) => {
              this.setState({ showPopUp: false })
            }}
            statusButtonClicked={(status) => {
              Swal.fire({
                title: i18n.t("are_you_sure_you_want_to_finalize_this_booking"),
                showCancelButton: true,
                cancelButtonText: "No",
                confirmButtonText: "Yes",
              }).then((e) => {
                const { dismiss, isConfirmed, isDismissed } = e
                if (!isDismissed || dismiss != "backdrop") {
                  if (isConfirmed) {
                    this.setState(
                      {
                        showInsertPayment: true,
                        paymentCompleteCallback: () =>
                          this.genericUpdate({ isFinalized: true, status }),
                      },
                      () => {
                        this.setState({
                          showInsertPayment: false,
                        })
                      }
                    )
                  } else {
                    this.genericUpdate({ isFinalized: isConfirmed, status })
                  }
                }
              })
            }}
            onProductChange={(e, index) => {
              this.setState({ isloading: true })
              updateCartProduct(
                {
                  productId: selectBooking.cartId.products[index]._id,
                  cartId: selectBooking.cartId._id,
                },
                { quantity: e }
              )
                .then(({ data }) => {
                  if (data.success) {
                    selectBooking.cartId.products[index].quantity =
                      data.data.quantity
                    if (typeof selectBooking.index === "number") {
                      bookings[selectBooking.index] = selectBooking
                    }
                    this.setState({
                      selectBooking: { ...selectBooking },
                      bookings,
                    })
                  } else {
                    if (data.code) {
                      ErrorHelper.handleErrors(
                        i18n.t(data.code, { ...data.data }),
                        true
                      )
                    } else {
                      ErrorHelper.handleErrors("Failed to Add Item", true)
                    }
                  }
                })
                .finally(() => {
                  this.setState({ isloading: false })
                })
            }}
            onProductDelete={(index) => {
              this.setState({ isloading: true })
              removeProduct({
                productId: selectBooking.cartId.products[index]._id,
                cartId: selectBooking.cartId._id,
              })
                .then(({ data }) => {
                  if (data.success) {
                    if (
                      typeof selectBooking.index === "number" &&
                      bookings[selectBooking.index]
                    ) {
                      bookings[selectBooking.index].cartId = data.data
                    }
                    this.setState({
                      selectBooking: { ...selectBooking, cartId: data.data },
                      bookings,
                    })
                  }
                })
                .finally(() => {
                  this.setState({ isloading: false })
                })
            }}
            onServiceDelete={(index) => {
              this.setState({ isloading: true })
              removeExtra({
                extraId: selectBooking.extras[index]._id,
                bookingId: selectBooking._id,
              })
                .then(({ data }) => {
                  if (data.success) {
                    if (
                      typeof selectBooking.index === "number" &&
                      bookings[selectBooking.index]
                    ) {
                      bookings[selectBooking.index].extras = data.data.extras
                    }
                    this.setState({
                      selectBooking: {
                        ...selectBooking,
                        extras: data.data.extras,
                      },
                      bookings,
                    })
                  }
                })
                .finally(() => {
                  this.setState({ isloading: false })
                })
            }}
            onNotesClick={() => this.setState({ showNotePopUp: true })}
            onAddClick={() => this.setState({ showExtrasModal: true })}
            companyTaxes={companyTaxes}
            toggleDepositRequired={this.toggleDepositRequired}
            toggleTaxes={this.toggleTaxes}
            onChangeTip={this.onChangeTip}
          ></AppointmentModal>
        </>
      )
    } else {
      return null
    }
  }

  handleRoutToChat = (firstName, salonId) => {
    this.props.history.push({
      pathname: "/saloon-chat",
      state: { name: firstName, _id: salonId },
    })
  }

  renderTableBody = (renderBooking) => {
    const { bookingStauses, isGym, activePage } = this.state
    const summary = {
      total: 0,
      tip: 0,
      grandTotal: 0,
    }
    const { screenSize } = this.context
    renderBooking = renderBooking.map((booking) => {
      booking.total = booking.totalAmount + (booking.tip || 0)
      summary.total += booking.totalAmount || 0
      summary.tip += booking.tip || 0
      summary.grandTotal += booking.total
      return booking
    })

    let previousPageNumber = activePage - 1
    return (
      <tbody>
        <tr
          style={{
            position: "sticky",
            top: "37px",
            zIndex: "1",
            backgroundColor: "#B3D7ED",
          }}
        >
          <td />
          <td />
          <td />
          <td />
          {!isGym && (
            <>
              <td className="font-weight-bold">
                <div className="mobile-max-content">
                  $ {summary.total.toFixed(2)}
                </div>
              </td>
              <td className="font-weight-bold">
                <div className="mobile-max-content">
                  $ {summary.tip.toFixed(2)}
                </div>
              </td>
              <td className="font-weight-bold">
                <div className="mobile-max-content">
                  $ {summary.grandTotal.toFixed(2)}
                </div>
              </td>
            </>
          )}
          <td />
          <td />
          <td />
        </tr>
        {renderBooking.map((val, ind) => {
          if (val.status == 1) return null
          if (ind >= previousPageNumber * 100 && ind < activePage * 100) {
            return (
              <tr
                key={val._id}
                style={{
                  backgroundColor: ind % 2 == 0 && "#F4F6F8",
                  borderTop: "1px solid #E5EBF1",
                  borderBottom:
                    renderBooking.length - 1 == ind && "1px solid #E5EBF1",
                }}
              >
                <td>
                  <div className="d-flex align-items-start flex-column">
                    {val.userId && val.userId.userName}
                    <span className="appointment-details-employee-other">
                      {getNestedValue(val, ["services", 0, "date"], "")}{" "}
                      {getNestedValue(val, ["services", 0, "time"], "")}
                    </span>
                  </div>
                </td>
                <td className=" Table_data">
                  <div className="d-flex align-items-center mt-1">
                    <span className="appointment-details-employee-name">
                      {" "}
                      <span>
                        <NumberFormat
                          type={"text"}
                          style={Sytles.removeInputStyle}
                          value={val.phoneNo}
                          disabled
                          format="### ### ####"
                        />
                        <div className="appointment-details-employee-name">
                          {" "}
                          {val.email || ""}{" "}
                        </div>
                      </span>
                    </span>
                  </div>
                </td>
                <td className=" Table_data">
                  <div className="d-flex align-items-center mt-1">
                    <span className="appointment-details-employee-name service-column">
                      {getNestedValue(
                        val,
                        ["services", 0, "serviceId", "name"],
                        ""
                      )}
                    </span>
                  </div>
                </td>
                <td className=" Table_data">
                  <div className="d-flex align-items-center mt-1">
                    <span className="appointment-details-employee-name service-column">
                      {getNestedValue(
                        val,
                        ["services", 0, "employeeId", "userId", "firstName"],
                        ""
                      )}
                    </span>
                  </div>
                </td>
                {!isGym && (
                  <>
                    <td className=" Table_data">
                      <div className="d-flex align-items-center mt-1">
                        <span className="appointment-details-employee-name">
                          {`$ ${val.totalAmount}`}
                        </span>
                      </div>
                    </td>
                    <td className=" Table_data">
                      <div className="d-flex align-items-center mt-1">
                        <span className="appointment-details-employee-name">
                          {`$ ${val.tip || 0}`}
                        </span>
                      </div>
                    </td>
                    <td className=" Table_data">
                      <div className="d-flex align-items-center mt-1">
                        <span className="appointment-details-employee-name">
                          {`$ ${val.total.toFixed(2) || 0}`}
                        </span>
                      </div>
                    </td>
                  </>
                )}
                <td className=" Table_data">
                  <div className="d-flex align-items-center mt-1">
                    <span className="appointment-details-employee-name font-weight-bold">
                      {bookingStauses[val.status - 1].text}
                      {val.isNoShow ? ` - ${i18n.t("no_show")}` : ""}
                    </span>
                  </div>
                </td>
                <td className=" Table_data">
                  <div className="d-flex align-items-center mt-1">
                    <PrimaryButton
                      style={{
                        width: "30px",
                        height: "30px",
                        minWidth: "auto",
                      }}
                      onClick={() =>
                        this.setState({ showPopUp: true, selectBooking: val })
                      }
                    >
                      <img src={Images.info_svg} width="16px" alt="info" />
                    </PrimaryButton>
                  </div>
                </td>
                <td className=" Table_data">
                  <div className="d-flex align-items-center mt-1">
                    <PrimaryButton
                      style={{
                        width: "30px",
                        height: "30px",
                        minWidth: "auto",
                      }}
                      onClick={() =>
                        this.setState({
                          showNotePopUp: true,
                          selectBooking: val,
                        })
                      }
                    >
                      <img src={Images.note_svg} width="16px" alt="info" />
                    </PrimaryButton>
                  </div>
                </td>
              </tr>
            )
          }
          // }
        })}
      </tbody>
    )
  }

  renderUserTable = () => {
    const {
      activePage,
      bookings,
      bookingStauses,
      searchText,
      filteredBookings,
      isGym,
    } = this.state
    const { screenSize } = this.context
    if (!bookings) return null
    let renderBooking = bookings
    if (filteredBookings) {
      renderBooking = filteredBookings
    } else if (searchText) {
      renderBooking = bookings.filter((val) => {
        if (
          getNestedValue(val.userId, "userName") &&
          (val.userId.userName
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
            val.phoneNo.includes(searchText))
        )
          return true
        return false
      })
    }
    return (
      <div className="col-12 px-0 pt-4">
        <div
          className="table-responsive"
          style={
            screenSize === "mobile" && renderBooking?.length > 0
              ? { height: "400px" }
              : { height: "80vh" }
          }
        >
          <table className="table table-borderless appointment-details-table">
            <thead
              style={{
                position: "sticky",
                top: "0",
                zIndex: "1",
                backgroundColor: "white",
              }}
            >
              <tr>
                <th scope="col">
                  <Translation>
                    {(t) => t("container.advancedSearchDialog_name")}
                  </Translation>
                </th>
                <th scope="col">
                  <Translation>
                    {(t) => t("container.employe_phone_number")}
                  </Translation>
                  /
                  <Translation>
                    {(t) => t("container.profile_email")}
                  </Translation>
                </th>
                <th scope="col">
                  <Translation>
                    {(t) => t("container.profile_service")}
                  </Translation>
                </th>
                <th scope="col">
                  <Translation>{(t) => t("employee")}</Translation>
                </th>
                {!isGym && (
                  <>
                    <th scope="col">
                      <div className="row">
                        <Translation>{(t) => t("by_price")}</Translation>
                      </div>
                    </th>
                    <th scope="col">
                      <div className="row">
                        <Translation>{(t) => t("tip")}</Translation>
                      </div>
                    </th>
                    <th scope="col">
                      <div className="row">
                        <Translation>{(t) => t("grand_total")}</Translation>
                      </div>
                    </th>
                  </>
                )}
                <th scope="col">
                  <Translation>
                    {(t) => t("container.profile_status")}
                  </Translation>
                </th>
                <th scope="col">
                  <Translation>{(t) => t("info")}</Translation>
                </th>
                <th scope="col">
                  <Translation>{(t) => t("Notes")}</Translation>
                </th>
              </tr>
            </thead>
            {this.renderTableBody(renderBooking)}
          </table>
        </div>
      </div>
    )
  }

  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber })
  }

  renderPagination = () => {
    const { bookings = [], activePage, filteredBookings } = this.state
    return (
      <div className="col-12 py-2 mb-3 d-flex justify-content-center">
        <Pagination
          hideNavigation
          activePage={activePage}
          itemsCountPerPage={100}
          totalItemsCount={
            filteredBookings ? filteredBookings.length : bookings.length
          }
          // pageRangeDisplayed={3}
          onChange={(val) => this.handlePageChange(val)}
          firstPageText={
            <img src={Images.pagination_back} style={{ width: 15 }} />
          }
          lastPageText={
            <img src={Images.pagination_forward} style={{ width: 15 }} />
          }
          itemClass="page-item"
          linkClass="page-link"
        />
      </div>
    )
  }

  render() {
    const { screenSize } = this.context
    const { openFilterModal } = this.state
    return (
      <div>
        {this.renderLoader()}
        <div className="p-4">
          <div className="row mx-0 px-0">
            {this.renderSearchBar()}
            {screenSize !== "mobile" ? this.renderFilters() : null}
            {this.renderUserTable()}
            {this.renderPopup()}
            {this.renderNotePopup()}
            {this.renderPagination()}
          </div>
        </div>
        <BaseModal
          open={openFilterModal}
          onClose={() => this.setState({ openFilterModal: false })}
          title={i18n.t("container.advancedSearchDialog_filter")}
          content={<>{this.renderFilters()}</>}
        />
      </div>
    )
  }
}
_BookingHistory.contextType = ScreenSizeContext
export default function BookingHistory(props) {
  const { getResource } = useCache()
  const products = getResource((cache) => cache.company.products)
  const taxes = getResource((cache) => cache.company.activeTaxes)
  const employees = getResource((cache) => cache.company.employees)
  const services = getResource((cache) => cache.company.services)
  return (
    <_BookingHistory
      {...props}
      products={products}
      companyTaxes={taxes}
      employees={employees}
      services={services}
    />
  )
}
