import React, { useEffect, useRef, useState } from "react"
import i18n from "../../../../i18n"
import { upsertRequest } from "../../../../redux/actions/Saloon/Promotion"
import PrimaryButton from "../../../../components/Core/PrimaryButton"
import { BaseModal } from "../../../../components"
import { useDispatch } from "react-redux"
import {
  SelectValidator,
  TextValidator,
  ValidatorForm,
} from "react-material-ui-form-validator"
import { ErrorHelper, SuccessHelper } from "../../../../helpers"
import FormContainer from "../../../../components/Core/FormContainer/FormContainer"
import moment from "moment"
import useCache from "../../../../context/LocalCache"
import { MenuItem } from "@material-ui/core"
import { useMemo } from "react"
import ReactDatePicker from "react-datepicker"
import ToggleSelect from "../../../../components/ToggleSelect"
import CounterInput from "../../../../components/CounterInput"

const PromotionUpsertPopup = ({ open, setOpen, active }) => {
  const dispatch = useDispatch()
  const { getResource } = useCache()
  const allServices = getResource((cache) => cache.company.services)
  const serviceMap = useMemo(() => {
    const map = {}
    allServices.map((service) => {
      map[service._id] = service
    })
    return map
  }, [allServices])
  const form = useRef()
  const [data, setData] = useState({})
  const title = data._id ? "update_promotion" : "create_promotion"

  const onSubmit = (e) => {
    const successMessage = data._id
      ? "successfully_updated"
      : "successfully_added"
    const failureMessage = data._id ? "failed_to_update" : "failed_to_add"
    dispatch(
      upsertRequest(
        { ...data },
        {
          success: ({ data: res }) => {
            const toSet = { ...data, ...res, serviceId: res.serviceId?._id }

            toSet.expiryDate = res.expiryDate && moment(res.expiryDate).toDate()
            setData(toSet)
            SuccessHelper.handleSuccess(i18n.t(successMessage), true)
          },
          failure: () => {
            ErrorHelper.handleErrors(i18n.t(failureMessage), true)
          },
        }
      )
    )
  }

  useEffect(() => {
    if (open) {
      const data = { ...(active || {}) }
      const expiryDate = data.expiryDate && moment(data.expiryDate).toDate()

      setData({
        type: "Percent",
        ...data,
        expiryDate,
        serviceId: data.serviceId?._id,
      })
    }
  }, [open])

  useEffect(() => {
    const { serviceId, type } = data
    let { amount, dollarAmount, percentAmount } = data
    if (serviceId && type && amount) {
      const { price } = serviceMap[serviceId]
      if (type == "Dollar") {
        if (price < amount) {
          amount = price
        }
        dollarAmount = amount
        percentAmount = ((1 - (price - amount) / price) * 100).toFixed(2)
      } else {
        if (amount > 100) {
          amount = 100
        }
        if (amount < 0) {
          amount = 0
        }
        percentAmount = amount
        dollarAmount = (price * (amount / 100)).toFixed(2)
      }
      setData({
        ...data,
        amount: type == "Dollar" ? dollarAmount : percentAmount,
        dollarAmount,
        percentAmount,
      })
    }
  }, [data.serviceId, data.type, data.amount])

  const amountRulesMap = {
    validators: ["required", "minNumber:1"],
    errorMessages: [
      i18n.t("this_field_is_required"),
      i18n.t("must_be_positive"),
    ],
  }
  if (data.type == "Percent") {
    amountRulesMap.validators.push("maxNumber:100")
    amountRulesMap.errorMessages.push("less_than_100")
  }

  return (
    <BaseModal
      open={open}
      onClose={() => setOpen(false)}
      containerClassName={"half-container"}
      title={i18n.t(title)}
      content={
        <ValidatorForm onSubmit={onSubmit} ref={form} className="w-100">
          <FormContainer>
            <div className="col-12 px-0 d-sm-flex align-items-center mb-3">
              <label className="font-weight-bold mobile-w-100" style={{ minWidth: '120px' }}>{i18n.t("name")}</label>
              <div className="w-100">
                <TextValidator
                  label={i18n.t("name")}
                  onChange={(e) => setData({ ...data, name: e.target.value })}
                  value={data.name || ""}
                  variant="outlined"
                />
              </div>
            </div>
            <div className="col-12 px-0 d-sm-flex align-items-center mb-3">
              <label className="font-weight-bold mobile-w-100" style={{ minWidth: '120px' }}>{i18n.t("type")}</label>
              <ToggleSelect
                value={data.type === "Dollar" ? true : false}
                onChange={(e) => {
                  const { value } = e
                  setData({
                    ...data,
                    type: e ? "Dollar" : "Percent",
                    amount: (value === "Dollar" ? data.dollarAmount : data.percentAmount) || data.amount,
                  })
                }}
                active="Dollar"
                inactive="Percent"
              />
            </div>
            <div className="col-12 px-0 d-sm-flex align-items-center mb-3">
              <label className="font-weight-bold mobile-w-100" style={{ minWidth: '120px' }}>{i18n.t("amount")}</label>
              <div className="w-100">
                <CounterInput
                  value={data.amount || 0}
                  label={i18n.t("amount")}
                  onChange={(e) => setData({ ...data, amount: e })}
                />
              </div>
            </div>
            <div className="col-12 px-0 d-sm-flex align-items-center mb-3">
              <label className="font-weight-bold mobile-w-100" style={{ minWidth: '120px' }}>{i18n.t("service")}</label>
              <div className="w-100">
                <SelectValidator
                  label={i18n.t("service")}
                  onChange={(e) => setData({ ...data, serviceId: e.target.value })}
                  value={data.serviceId || ""}
                  select
                  validators={["required"]}
                  errorMessages={[i18n.t("this_field_is_required")]}
                  variant="outlined"
                >
                  {allServices.map((service) => {
                    return (
                      <MenuItem key={service._id} value={service._id}>
                        {service.name} (${service.price})
                      </MenuItem>
                    )
                  })}
                </SelectValidator>
              </div>
            </div>
            <div className="col-12 px-0 d-sm-flex align-items-center mb-3">
              <label className="font-weight-bold mobile-w-100" style={{ minWidth: '120px' }}>{i18n.t("expiry_date")}</label>
              <ReactDatePicker
                wrapperClassName="w-100"
                selected={data.expiryDate}
                onChange={(e) => setData({ ...data, expiryDate: e })}
                value={data.expiryDate}
                customInput={
                  <TextValidator
                    label={i18n.t("expiry_date")}
                    value={data.expiryDate || ""}
                    inputProps={{ readOnly: true }}
                    variant="outlined"
                  />
                }
                placeholderText={i18n.t("expiry_date")}
              />
            </div>
            <div className="row justify-content-end my-3">
              <PrimaryButton
                className="w-128"
                props={{
                  type: "submit",
                }}
                label={i18n.t("save")}
              />
            </div>
          </FormContainer>
        </ValidatorForm>
      }
    />
  )
}

export default PromotionUpsertPopup
