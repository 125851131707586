// @flow
import Immutable from "seamless-immutable"
import * as types from "../actions/ActionTypes"
import SharedStorage from "../../helpers/Storage"
const initialState = Immutable({
  data: {},
})

const resetSessionStorage = async () => {
  await Promise.all([
    SharedStorage.removeItem("user"),
    SharedStorage.removeItem("employee"),
    SharedStorage.removeItem("saloon"),
    SharedStorage.removeItem("company"),
    SharedStorage.removeItem("access_token"),
    SharedStorage.removeItem("impersonationId"),
    SharedStorage.removeItem("menu_type"),
  ])
}
export default (state = initialState, action) => {
  switch (action.type) {
    case types.USER.SET_USER:
      try {
        const data = { ...state.data }
        Object.keys(action.data).map((key) => {
          data[key] = action.data[key]
          if (action.data[key]) {
            SharedStorage.setItem(key, action.data[key])
          } else {
            SharedStorage.removeItem(key)
          }
        })
        return Immutable.merge(state, {
          data,
        })
      } catch (error) {
        console.log("error", error)
        return state
      }

    case types.CLEAR_LOGOUT:
      resetSessionStorage()
      return initialState
    case types.LOGOUT.SUCCESS:
      resetSessionStorage()
      return initialState
    case types.SALOON_LOGOUT.SUCCESS:
      resetSessionStorage()
      return initialState
    case types.EMPLOYEE_LOGOUT.SUCCESS:
      resetSessionStorage()
      return initialState
    case types.LOGOUT:
      resetSessionStorage()
      return initialState
    default:
      return state
  }
}
