import { Button, styled } from "@material-ui/core"
import { grey } from "@material-ui/core/colors"

const SecondaryButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(grey[300]),
  backgroundColor: "inherit",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: grey[100],
  },
  border: "1px solid #E5EBF1",
  maxHeight: 34,
  fontSize: 12,
  height: "min-content",
  className: ""
}))

export default SecondaryButton
