import { connect } from "react-redux"
import React, { useEffect, useState } from "react"
import i18n from "../../../i18n"
import PrimaryButton from "../../../components/Core/PrimaryButton"
import SecondaryButton from "../../../components/Core/SecondaryButton/SecondaryButton"
import { BaseModal, DotsLoader } from "../../../components"
import { getReportRequest } from "../../../redux/actions/Saloon/expense"
import DatePicker from "react-datepicker"
import moment from "moment"
import ExpenseTable from "../../../components/Saloon/Expense/ExpenseTable.component"
import useScreenSize from "../../../context/ScreenSize"
const PayrollReport = ({
  expenseReport = {},
  propsLoading,
  getReportRequest,
}) => {
  const [startDate, setStartDate] = useState(
    moment(moment.now()).subtract(7, "d").toDate()
  )
  const [endDate, setEndDate] = useState(moment(moment.now()).toDate())
  const { screenSize } = useScreenSize()
  const [openFiltersModal, setOpenFiltersModal] = useState(false)
  const [openOptionsModal, setOpenOptionsModal] = useState(false)
  const getReport = async (exportToXlsx) => {
    getReportRequest({
      from: startDate,
      to: endDate,

      exportToXlsx,
    })
  }

  useEffect(async () => {
    getReport()
  }, [])

  const setSelectedValue = (val) => {
    let currentDate = moment(Date.now())
    if (val == 1) {
      let day = currentDate.day()
      const convertedStart = moment(currentDate).subtract(day, "days")
      const convertedEnd = moment(convertedStart).add(6, "days")
      setStartDate(convertedStart.toDate())
      setEndDate(convertedEnd.toDate())
    } else if (val == 2) {
      const startOfMonth = moment(currentDate).startOf("month")
      const endOfMonth = moment(currentDate).endOf("month")
      setStartDate(startOfMonth.toDate())
      setEndDate(endOfMonth.toDate())
    } else if (val == 3) {
      const convertedStart = moment(`01-01-${currentDate.year()}`, "MM-DD-YYYY")
      const convertedEnd = moment(`12-31-${currentDate.year()}`, "MM-DD-YYYY")
      setStartDate(convertedStart.toDate())
      setEndDate(convertedEnd.toDate())
    }
  }

  const onStartDateChange = (val) => {
    if (val > endDate) {
      setStartDate(endDate)
      setEndDate(val)
    } else {
      setStartDate(val)
    }
  }

  const onEndDateChange = (val) => {
    if (val < startDate) {
      setStartDate(val)
      setEndDate(startDate)
    } else {
      setEndDate(val)
    }
  }

  const renderFilter = () => {
    return (
      <div className="row col-12 px-0">
        <div className="row px-0 mb-4">
          <div className="d-sm-flex align-items-center gap-2">
            <label
              className="font-weight-bolder"
              style={{ minWidth: "fit-content" }}
            >
              {i18n.t("date_range")}
            </label>
            <div className="d-flex gap-2">
              <DatePicker
                className="form-control saloon-form-input"
                placeholderText="Select Start Date"
                selected={startDate}
                onChange={onStartDateChange}
                dateFormat="MM/dd/yyyy"
                variant="outlined"
              />
              <DatePicker
                className="form-control saloon-form-input"
                placeholderText="Select End Date"
                selected={endDate}
                onChange={onEndDateChange}
                dateFormat="MM/dd/yyyy"
              />
            </div>
          </div>
          <div className="row align-items-center px-0 mx-0 ml-sm-3 gap-2">
            <label className="font-weight-bolder">
              {i18n.t("container.quick_filter")}
            </label>
            <div className="d-flex px-0 gap-2 flex-wrap">
              <SecondaryButton onClick={() => setSelectedValue(1)}>
                {i18n.t("container.week")}
              </SecondaryButton>
              <SecondaryButton onClick={() => setSelectedValue(2)}>
                {i18n.t("container.month")}
              </SecondaryButton>
              <SecondaryButton onClick={() => setSelectedValue(3)}>
                {i18n.t("container.year")}
              </SecondaryButton>
              <PrimaryButton className="w-98" onClick={() => getReport()}>
                {i18n.t("search")}
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderButtons = () => {
    const { screenSize } = useScreenSize()
    return (
      <div className="d-flex flex-column flex-md-row gap-2">
        <PrimaryButton
          className={screenSize === "mobile" ? "" : "w-128"}
          label={i18n.t("export_to_excel")}
          onClick={() => getReport(true)}
        />
      </div>
    )
  }

  return (
    <div className="content-container p-4">
      <DotsLoader isloading={propsLoading} />
      <div className="col-12 px-0 flex-column flex-md-row d-flex align-items-center gap-2 justify-content-between mb-4">
        <span className="saloon-dashboard-heading pt-0">
          {i18n.t("expense_report")}
        </span>
        {screenSize !== "mobile" ? (
          <>{renderButtons()}</>
        ) : (
          <div className="d-flex gap-2 justify-content-center">
            <PrimaryButton
              className="w-128"
              onClick={() => setOpenFiltersModal(true)}
            >
              {i18n.t("filters")}
            </PrimaryButton>
            <PrimaryButton
              className="w-128"
              onClick={() => setOpenOptionsModal(true)}
            >
              Options
            </PrimaryButton>
          </div>
        )}
      </div>
      {screenSize !== "mobile" ? <>{renderFilter()}</> : null}

      <div className="mt-3">
        <ExpenseTable
          rows={expenseReport?.data}
          summary={expenseReport?.summary}
        />
      </div>
      <BaseModal
        open={openFiltersModal}
        contentClassName="overflow-visible"
        onClose={() => setOpenFiltersModal(false)}
        title={i18n.t("filters")}
        content={<>{renderFilter()}</>}
      />
      <BaseModal
        open={openOptionsModal}
        onClose={() => setOpenOptionsModal(false)}
        title="Options"
        content={<>{renderButtons()}</>}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  expenseReport: state.expense.report,
  propsLoading: state.expense.isFetching,
})

const action = { getReportRequest }

export default connect(mapStateToProps, action)(PayrollReport)
