import { put, takeEvery } from "redux-saga/effects"

import * as types from "../actions/ActionTypes"

import axios from "axios"
import fileDownload from "js-file-download"

function SagaBuilder() {
  const build = ({ type, endpoint, actions }) => {
    const { upsert, get, remove, requestFailure } = actions
    function* upsertSaga(action) {
      const { payload, callbackMap = {} } = action
      let data
      try {
        data = yield axios.post(`${endpoint}`, payload)
        data = data.data
        if (callbackMap.success && typeof callbackMap.success == "function") {
          callbackMap.success(data)
        }
        yield put(upsert(data.data))
      } catch (err) {
        if (callbackMap.failure && typeof callbackMap.failure == "function") {
          callbackMap.failure(data)
        }
        yield put(requestFailure(data))
      }
    }

    function* getSaga(action) {
      const { callbackMap = {}, payload = {} } = action
      const additional = {}
      if (payload.exportToXlsx) {
        additional.responseType = "blob"
      }
      let data
      try {
        data = yield axios.get(`${endpoint}`, {
          params: payload,
          ...additional,
        })
        data = data.data
        if (callbackMap.success && typeof callbackMap.success == "function") {
          callbackMap.success(data)
        }
        if (payload.exportToXlsx) {
          fileDownload(data, "report.xlsx")
          yield put(requestFailure(data))
        } else {
          yield put(get(data.data))
        }
      } catch (err) {
        if (callbackMap.failure && typeof callbackMap.failure == "function") {
          callbackMap.failure(data)
        }
        yield put(requestFailure(data))
      }
    }

    function* removeSaga(action) {
      const { payload, callbackMap = {} } = action
      let data
      try {
        data = yield axios.delete(`${endpoint}`, {
          params: payload,
        })
        data = data.data
        if (callbackMap.success && typeof callbackMap.success == "function") {
          callbackMap.success(data)
        }
        yield put(remove(data.data))
      } catch (err) {
        if (callbackMap.failure && typeof callbackMap.failure == "function") {
          callbackMap.failure(data)
        }
        yield put(requestFailure(data))
      }
    }

    return function* root() {
      yield takeEvery(types[type].UPSERT_SAGA, upsertSaga)
      yield takeEvery(types[type].GET_SAGA, getSaga)
      yield takeEvery(types[type].REMOVE_SAGA, removeSaga)
    }
  }

  return {
    build,
  }
}

export default SagaBuilder()
