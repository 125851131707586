import { put, takeEvery } from "redux-saga/effects"

import * as types from "../../actions/ActionTypes"
import {
  create,
  reuse,
  get,
  update,
  setRecipient,
  remove,
  requestFailure,
  getPricingDetails,
  dispatch,
  getOne,
} from "../../actions/Saloon/CommunicationsMarketingCampaign"

import axios from "axios"
import { communication_marketing_campaign } from "../../../config/WebServices"

function* createSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.post(`${communication_marketing_campaign}`, payload)
    data = data.data
    if (callbackMap.success && typeof callbackMap.success == "function") {
      callbackMap.success(data)
    }
    yield put(create(data.data))
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* reuseSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.post(
      `${communication_marketing_campaign}/reuse`,
      payload
    )
    data = data.data
    if (callbackMap.success && typeof callbackMap.success == "function") {
      callbackMap.success(data)
    }
    yield put(reuse(data.data))
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* getSaga(action) {
  const { callbackMap = {} } = action
  let data
  try {
    data = yield axios.get(`${communication_marketing_campaign}`)
    data = data.data
    if (callbackMap.success && typeof callbackMap.success == "function") {
      callbackMap.success(data)
    }
    yield put(get(data.data))
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* getOneSaga(action) {
  const { payload: params, callbackMap = {} } = action
  let data
  try {
    data = yield axios.get(`${communication_marketing_campaign}/getOne`, {
      params,
    })
    data = data.data
    if (callbackMap.success && typeof callbackMap.success == "function") {
      callbackMap.success(data)
    }
    yield put(getOne(data.data))
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* updateSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.put(`${communication_marketing_campaign}`, payload)
    data = data.data
    if (callbackMap.success && typeof callbackMap.success == "function") {
      callbackMap.success(data)
    }
    yield put(update(data.data))
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* setRecipientSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.post(
      `${communication_marketing_campaign}/setRecipients`,
      payload
    )
    data = data.data
    if (callbackMap.success && typeof callbackMap.success == "function") {
      callbackMap.success(data)
    }
    yield put(setRecipient(data.data))
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* removeSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.delete(`${communication_marketing_campaign}`, {
      params: payload,
    })
    data = data.data
    if (callbackMap.success && typeof callbackMap.success == "function") {
      callbackMap.success(data)
    }
    yield put(remove(data.data))
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* getDetailsSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.get(
      `${communication_marketing_campaign}/pricingDetails`,
      {
        params: payload,
      }
    )
    data = data.data
    if (callbackMap.success && typeof callbackMap.success == "function") {
      callbackMap.success(data)
    }
    yield put(getPricingDetails(data.data))
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* dispatchSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.post(
      `${communication_marketing_campaign}/dispatchCampaign`,
      payload
    )
    data = data.data
    if (callbackMap.success && typeof callbackMap.success == "function") {
      callbackMap.success(data)
    }
    yield put(dispatch(data.data))
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

export default function* root() {
  yield takeEvery(
    types.COMMUNICATION_MARKETING_CAMPAIGN.CREATE_SAGA,
    createSaga
  )
  yield takeEvery(types.COMMUNICATION_MARKETING_CAMPAIGN.REUSE_SAGA, reuseSaga)
  yield takeEvery(types.COMMUNICATION_MARKETING_CAMPAIGN.GET_SAGA, getSaga)
  yield takeEvery(
    types.COMMUNICATION_MARKETING_CAMPAIGN.GET_ONE_SAGA,
    getOneSaga
  )
  yield takeEvery(
    types.COMMUNICATION_MARKETING_CAMPAIGN.UPDATE_SAGA,
    updateSaga
  )
  yield takeEvery(
    types.COMMUNICATION_MARKETING_CAMPAIGN.SET_RECIPIENTS_SAGA,
    setRecipientSaga
  )
  yield takeEvery(
    types.COMMUNICATION_MARKETING_CAMPAIGN.REMOVE_SAGA,
    removeSaga
  )
  yield takeEvery(
    types.COMMUNICATION_MARKETING_CAMPAIGN.PRICING_DETAILS_SAGA,
    getDetailsSaga
  )
  yield takeEvery(
    types.COMMUNICATION_MARKETING_CAMPAIGN.DISPATCH_SAGA,
    dispatchSaga
  )
}
