// @flow
import Immutable from "seamless-immutable"
import * as types from "../actions/ActionTypes"

const initialState = Immutable({
  isFetching: false,
  isUpdating: false,
  errorMessage: "",
  data: {},
})

export default (state = initialState, action) => {
  switch (action.type) {
  case types.MANAGER_ACCESS.GET_SAGA:
    return Immutable.merge(state, {
      isFetching: true,
    })
  case types.MANAGER_ACCESS.GET:
    return Immutable.merge(state, {
      isFetching: false,
      data: action.data,
    })
  case types.MANAGER_ACCESS.UPDATE_SAGA:
    return Immutable.merge(state, {
      isUpdating: true,
    })
  case types.MANAGER_ACCESS.UPDATE: {
    const newState = { ...state, isUpdating: false, data: action.data }
    return newState
  }
  case types.MANAGER_ACCESS.REQUEST_FAILURE:
    return Immutable.merge(state, {
      isFetching: false,
      isUpdating: false,
    })
  case types.LOGOUT:
    return initialState
  case types.CLEAR_LOGOUT:
    return initialState
  default:
    return state
  }
}
