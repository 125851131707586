import React, { useEffect, useRef, useState } from "react"
import i18n from "../../../../i18n"
import { upsertRequest } from "../../../../redux/actions/Saloon/CommunicationsMarketingTemplate"
import PrimaryButton from "../../../../components/Core/PrimaryButton"
import { BaseModal } from "../../../../components"
import { useDispatch } from "react-redux"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import { ErrorHelper, SuccessHelper } from "../../../../helpers"
import FormContainer from "../../../../components/Core/FormContainer/FormContainer"
import moment from "moment"
import SharedStorage from "../../../../helpers/Storage"
const { REACT_APP_TEMPLATE_BUILDER_LINK } = process.env

const TemplateUpsertPopup = ({ open, setOpen, active }) => {
  const dispatch = useDispatch()
  const form = useRef()
  const [data, setData] = useState({})
  const title = data._id ? "update_template" : "create_template"

  const onSubmit = (e) => {
    const successMessage = data._id
      ? "successfully_updated"
      : "successfully_added"
    const failureMessage = data._id ? "failed_to_update" : "failed_to_add"
    const sendOn = moment(
      `${moment(data.date).format("MM-DD-YYYY")} ${data.time}`,
      "MM-DD-YYYY HH:mm"
    )
    dispatch(
      upsertRequest(
        { ...data, sendOn: sendOn.toDate(), campaignId: data._id },
        {
          success: ({ data: res }) => {
            setData({ ...data, ...res })
            SuccessHelper.handleSuccess(i18n.t(successMessage), true)
          },
          failure: () => {
            ErrorHelper.handleErrors(i18n.t(failureMessage), true)
          },
        }
      )
    )
  }

  useEffect(() => {
    if (open) {
      const data = { ...(active || {}) }

      setData({
        ...data,
      })
    }
  }, [open])
  const handleChange = (key, value) => {
    data[key] = value
    setData({ ...data })
  }
  return (
    <BaseModal
      open={open}
      onClose={() => setOpen(false)}
      title={i18n.t(title)}
      containerClassName={"half-container"}
      content={
        <ValidatorForm onSubmit={onSubmit} ref={form} className="w-100">
          <FormContainer>
            <div className="col-12 flex-column flex-md-row px-0 d-flex align-items-md-center mb-3">
              <label className="font-weight-bold mr-3">{i18n.t("name")}</label>
              <div className="w-100">
                <TextValidator
                  label={i18n.t("name")}
                  onChange={(e) => handleChange("name", e.target.value)}
                  value={data.name || ""}
                  validators={["required"]}
                  errorMessages={[i18n.t("this_field_is_required")]}
                  variant="outlined"
                />
              </div>
            </div>
            <div className="row justify-content-end my-3">
              {data._id && (
                <PrimaryButton
                  onClick={() => {
                    const newWindow = window.open(
                      `${REACT_APP_TEMPLATE_BUILDER_LINK}/?_id=${
                        data._id
                      }&lang=${localStorage.getItem("language")}`,
                      "_blank"
                    )
                    setTimeout(async () => {
                      newWindow.postMessage(
                        JSON.stringify({
                          access_token: await SharedStorage.getItem(
                            "access_token"
                          ),
                        }),
                        "*"
                      )
                    }, 2000)
                  }}
                  label={i18n.t("view_edit_template")}
                />
              )}
              <PrimaryButton
                className="w-128 ml-3"
                props={{
                  type: "submit",
                }}
                label={i18n.t("save")}
              />
            </div>
          </FormContainer>
        </ValidatorForm>
      }
    />
  )
}

export default TemplateUpsertPopup
