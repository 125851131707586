import { connect } from "react-redux"
import React, { Component } from "react"
import i18n from "../../i18n"

import { request as saloon_user_id } from "../../redux/actions/Saloon/SaloonByUserId"
import { request as get_notifications } from "../../redux/actions/GetNotifications"
import { DotsLoader, Navbar, Footer } from "../../components"
import { SuccessHelper, ErrorHelper } from "../../helpers"
import { Translation } from "react-i18next"
import { Images } from "../../theme"
import { partnerLogin } from "../../config/simpleApiCalls"
import { request as login_user } from "../../redux/actions/Login"
import { set } from "../../redux/actions/User"
import TextField from "@material-ui/core/TextField"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import "./styles.css"
import PrimaryButton from "../../components/Core/PrimaryButton"
import { ActiveMenuTypeContext } from "../../context/ActiveMenuType"
import { ROLES } from "../../util/enum"
import SharedStorage from "../../helpers/Storage"

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      handle: "",
      username: "",
      password: "",
      isloading: false,
      tabValue: 0,
      messaging_token: "",
      platform_name: "",
      formErrors: {
        emailError: "",
        usernameError: "",
        passwordError: "",
      },
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.login && this.props.login !== prevProps.login) {
      if (
        !this.props.login.failure &&
        !this.props.login.isFetching &&
        !this.props.login.errorMessage &&
        this.props.login.data &&
        this.props.login.data.data &&
        this.props.login.data.data.access_token
      ) {
        if (this.props.login.data.data.role === "5") {
          this.props.set({
            user: JSON.stringify(this.props.login.data.data),
            access_token: this.props.login.data.data.access_token,
          })
          this.props.history.push("/user-profile")
          this.setState({ isloading: false, handle: "", password: "" })
        } else {
          ErrorHelper.handleErrors("Access Denied", true)
          this.setState({ isloading: false, handle: "", password: "" })
        }
      }
    }
  }
  async componentDidMount() {
    const messaging_token = await SharedStorage.getItem("messaging_token")
    const platform_name = window.navigator.platform || "N/A"

    const user = JSON.parse(await SharedStorage.getItem("user"))
    if (user && user.access_token) {
      this.handleNavigation("/")
    }
    const saloonData = JSON.parse(await SharedStorage.getItem("saloon"))
    const companyData = JSON.parse(await SharedStorage.getItem("company"))

    this.setState({ messaging_token, platform_name })
    if (companyData && saloonData.access_token) {
      this.handleNavigation("/saloon-dashboard")
    }
  }

  onChangeHandle = (text) => this.setState({ handle: text.target.value })
  onChangeUserName = (text) => this.setState({ username: text.target.value })
  onChangePassword = (text) => this.setState({ password: text.target.value })

  checkValidation() {
    const { username, handle, password } = this.state
    const nameRegex =
      /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{2,}$/u
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const telephoneRegex =
      /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/g
    if (!handle) {
      this.setState({
        formErrors: {
          emailError: i18n.t("handle_required"),
        },
      })
      setTimeout(() => {
        this.setState({
          formErrors: {
            emailError: "",
          },
        })
      }, 8000)
    } else if (!handle.match(emailRegex) && !handle.match(telephoneRegex)) {
      this.setState({
        formErrors: {
          emailError: i18n.t("invalid_login_handle"),
        },
      })
      setTimeout(() => {
        this.setState({
          formErrors: {
            emailError: "",
          },
        })
      }, 8000)
    } else if (!password) {
      this.setState({
        formErrors: {
          passwordError: "Password is required!",
        },
      })
      setTimeout(() => {
        this.setState({
          formErrors: {
            passwordError: "",
          },
        })
      }, 6000)
    } else if (password.length < 6) {
      this.setState({
        formErrors: {
          passwordError: "Password must be contain 6 characters!",
        },
      })
      setTimeout(() => {
        this.setState({
          formErrors: {
            passwordError: "",
          },
        })
      }, 6000)
    } else {
      this.handleLogin()
    }
  }

  handleLogin = () => {
    this.setState({ isloading: true })
    const { handle, password, messaging_token, platform_name, tabValue } =
      this.state
    const payload = {
      handle,
      password,
      gcm_id: messaging_token ? messaging_token : "123",
      platform: platform_name,
    }
    if (tabValue === 0) {
      this.props.login_user(payload)
    } else {
      partnerLogin(payload).then(
        ({ data, success, msg, hideEmployeeProfile, accountAccess }) => {
          if (success) {
            this.context.setHideEmployeeProfile(hideEmployeeProfile)
            this.context.setAccountAccess(accountAccess)
            const { isCompanyProfile } = data
            if (data.role) {
              if (data.role.includes(ROLES.OWNER)) {
                if (isCompanyProfile) {
                  this.props.set({
                    saloon: JSON.stringify(data),
                    employee: JSON.stringify(data),
                    company: JSON.stringify(data.company),
                  })
                  this.context.setFunctionRole(data.company.function)

                  !data.company.isFreeTrial
                    ? this.handleNavigation("/saloon-dashboard")
                    : this.handleNavigation("/saloon-dashboard")
                  this.setState({
                    isloading: false,
                    handle: "",
                    password: "",
                  })
                } else {
                  this.props.set({
                    saloon: JSON.stringify(data),
                    employee: JSON.stringify(data),
                  })
                  this.handleNavigationTo("/saloon-register", data)
                }
              } else if (
                data.role.includes(ROLES.EMPLOYEE) ||
                data.role.includes(ROLES.INSTRUCTOR)
              ) {
                const toSet = {
                  employee: JSON.stringify({ ...data }),
                }
                if (
                  data.role.includes(ROLES.RECEPTIONIST) ||
                  data.role.includes(ROLES.MANAGER)
                ) {
                  toSet.saloon = JSON.stringify({ ...data })
                  toSet.company = JSON.stringify(data.company)
                }
                this.props.set(toSet)
                if (accountAccess === false) {
                  this.handleNavigationTo("/employee-active-saloon")

                  ErrorHelper.handleSuccess(
                    i18n.t("company_access_denied"),
                    true
                  )
                } else {
                  if (data.isEmployeeProfile) {
                    if (
                      data.viewSaloonDashboard &&
                      (data.role.includes(ROLES.RECEPTIONIST) ||
                        data.role.includes(ROLES.MANAGER))
                    ) {
                      this.handleNavigationTo("/saloon-dashboard")
                    } else {
                      this.handleNavigationTo("/employee-dashboard", data)
                    }
                  } else {
                    SuccessHelper.handleSuccess(
                      i18n.t("container.employe_working_hours_message"),
                      true,
                      i18n.t("container.employe_login_successfully")
                    )
                    this.handleNavigation("/employee-add-working-hour")
                  }
                  this.setState({
                    isloading: false,
                    handle: "",
                    password: "",
                  })
                }
              } else {
                ErrorHelper.handleErrors("Access Denied", true)
                this.setState({ isloading: false })
              }
              this.props.set({
                access_token: data.access_token,
              })
              setTimeout(() => {
                this.props.get_notifications()
              }, 100)
            } else {
              ErrorHelper.handleErrors("Access Denied", true)
              this.setState({ isloading: false })
            }
          } else {
            ErrorHelper.handleErrors(i18n.t(msg), true)
            this.setState({ isloading: false })
          }
        }
      )
    }
  }
  handleNavigationTo = (route, user) => {
    this.props.history.push({
      pathname: route,
      state: { continueRegistration: true },
    })
  }

  handleNavigation = (route) => {
    this.props.history.push(route)
  }

  renderLoader = () => {
    const { isloading } = this.state
    return <DotsLoader isloading={isloading} />
  }

  renderEmail = () => {
    const { formErrors } = this.state
    return (
      <div className="col-xs-12 col-sm-12 row mt-4 gap-1">
        <label className="font-weight-bold mr-auto mt-2 mb-0">
          {i18n.t("container.handle")}
        </label>
        <TextField
          id="inputusername"
          label={i18n.t("container.handle")}
          variant="outlined"
          onChange={(text) => this.onChangeHandle(text)}
          value={this.state.handle}
          error={!!formErrors.emailError}
          helperText={formErrors.emailError}
        />
      </div>
    )
  }

  renderPassword = () => {
    const { formErrors } = this.state
    return (
      <div className="col-xs-12 col-sm-12 row mt-3 gap-1">
        <label className="font-weight-bold mr-auto mt-2 mb-0">
          {i18n.t("password")}
        </label>
        <TextField
          id="inputPassword"
          variant="outlined"
          label={i18n.t("password")}
          placeholder={i18n.t("enter_password_placeholder")}
          onChange={(text) => this.onChangePassword(text)}
          value={this.state.password}
          type="password"
          error={!!formErrors.passwordError}
          helperText={formErrors.passwordError}
        />
      </div>
    )
  }

  renderLoginBtn = () => {
    return (
      <PrimaryButton
        className="btn-lg mb-1 ml-auto mr-auto "
        type="submit"
        onClick={() => this.checkValidation()}
        style={{
          width: "max-content",
          paddingLeft: "4em",
          paddingRight: "4em",
          textTransform: "none",
          borderRadius: "7px",
        }}
      >
        {i18n.t("login_now")}
      </PrimaryButton>
    )
  }

  renderForgetBtn = () => {
    const { tabValue } = this.state
    return (
      <div className="form-signin mt-2 mb-4 px-8">
        <span
          style={{
            color: "#F6943B",
            fontWeight: "700",
            cursor: "pointer",
            marginRight: "1.5em",
            display: "inherit",
            width: "max-content",
            marginLeft: "auto",
          }}
          onClick={() => {
            tabValue == 0
              ? this.handleNavigation("/forgot-password")
              : this.handleNavigation("/saloon-forgot-password")
          }}
        >
          {i18n.t("forgot_password")}
        </span>
      </div>
    )
  }

  renderLoginForm = () => {
    const { tabValue } = this.state
    return (
      <form
        action="javascript:void(0)"
        className="form-signin  px-4"
        style={{ display: "flex", flexDirection: "column" }}
      >
        {this.renderEmail()}
        {this.renderPassword()}
        {this.renderForgetBtn()}
        {this.renderLoginBtn()}
        <p
          id="signup"
          className="font-weight-bold"
          style={{
            fontSize: "1rem",
            color: "#3b3b3b",
            marginTop: "1rem",
            textAlign: "center",
          }}
        >
          {i18n.t("login_no_account")} &nbsp;
          <span
            style={{
              color: "#F6943B",
              fontWeight: "700",
              cursor: "pointer",
              marginleft: "10px",
            }}
            onClick={() => {
              switch (tabValue) {
                case 1:
                  this.handleNavigation("/employee-register")
                  break
                case 2:
                  this.handleNavigation("/saloon-register")
                  break
                default:
                  this.handleNavigation("/register")
              }
            }}
          >
            {i18n.t("sign_up")}
          </span>
        </p>
      </form>
    )
  }

  renderLoginCard = () => {
    const { tabValue } = this.state
    const tabStyles = {
      minWidth: "unset",
      fontWeight: 700,
      textTransform: "none",
      minHeight: "unset",
      paddingBottom: 3,
      fontSize: "1.5rem",
      letterSpacing: "0.36px",
      marginTop: "1rem",
    }
    return (
      <div className="card card-signin my-5">
        <div className="card-body px-0 ">
          <div
            id="mobile-logo-wrapper"
            className="row justify-content-center mt-5 mb-5"
          >
            <img alt="easy1 logo " src={Images.easy1_logo} width="75em" />
          </div>

          <h3
            className="card-title text-center mt-4"
            style={{ fontSize: "2rem", fontWeight: 700 }}
          >
            {i18n.t("sign_in")}
          </h3>
          <Tabs
            value={tabValue}
            onChange={(event, newValue) => {
              this.setState({ tabValue: newValue })
            }}
            centered
            TabIndicatorProps={{ style: { backgroundColor: "#F6943B" } }}
          >
            <Tab label={i18n.t("user")} style={tabStyles} />
            <Tab label={i18n.t("staff")} style={tabStyles} />
            <Tab label={i18n.t("owner")} style={tabStyles} />
          </Tabs>
          {this.renderLoginForm()}
        </div>
      </div>
    )
  }

  render() {
    return (
      <div
        className="container-fluid px-0 d-flex flex-column vh-100 justify-content-center"
        style={{ backgroundColor: "#F6F8FA" }}
      >
        {this.renderLoader()}
        <Navbar {...this.props} isBackgroundColor={true} />
        <div className="container my-auto">
          <div className="row">
            <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
              {this.renderLoginCard()}
            </div>
          </div>
        </div>
        <div id="copyright" className="text-center font-weight-bold mb-3">
          <Translation>{(t) => t("copyright")}</Translation>
        </div>
      </div>
    )
  }
}

Login.contextType = ActiveMenuTypeContext

const mapStateToProps = (state) => ({
  login: state.login,
  saloonUserId: state.saloonUserId,
})

const action = { saloon_user_id, login_user, get_notifications, set }

export default connect(mapStateToProps, action)(Login)
