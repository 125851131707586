import crudBuilder from "../../../builders/CrudBuilder/CrudBuilder"
import actions from "../../../redux/actions/Saloon/ActivityCategory"
const { upsertRequest, getRequest, removeRequest } = actions

const ActivityCategory = crudBuilder.build({
  type: "activityCategory",
  title: "activity_categories",
  actionMap: {
    upsert: upsertRequest,
    get: getRequest,
    remove: removeRequest,
  },
})

export default ActivityCategory
