import styled from "styled-components/macro"

export const LinkItem = styled.div`
  display: flex;
  align-items: center;
`

export const SocialLinksWrapper = styled.div`
  display: grid;
  gap: 1rem;
  margin-bottom: 1rem;
`

export const LinkIcon = styled.img.attrs((props) => ({
  src: props.src,
}))`
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
`
