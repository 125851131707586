import { connect } from "react-redux"
import React, { useEffect, useMemo, useRef, useState } from "react"
import i18n from "../../../i18n"
import BaseModal from "../../BaseModal"
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator"
import PrimaryButton from "../../Core/PrimaryButton"
import AppBar from "@material-ui/core/AppBar"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import {
  getRequest as getClassesRequest,
  upsertRequest as upsertClassRequest,
} from "../../../redux/actions/Saloon/Class"
import { getRequest as getStudentRequest } from "../../../redux/actions/Saloon/Student"
import {
  addClassRequest,
  removeClassRequest,
} from "../../../redux/actions/Saloon/Program"
import { ErrorHelper, SuccessHelper } from "../../../helpers"
import DotsLoader from "../../DotsLoader"
import ClassUpsert from "../Class/ClassUpsert"
import { MenuItem } from "@material-ui/core"
import BasicTable from "../../Core/BasicTable"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import DatePicker from "react-datepicker"
import moment from "moment"
import CollectPaymentModal from "../../PurchaseClassModal/CollectPaymentModal/CollectPaymentModal"
import deleteConfirmation from "../../../helpers/DeleteHelper"
import TabPanel from "../../Core/Tabs/TabPanel"
import CounterInput from "../../CounterInput"

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

function ProgramUpsert({
  open,
  onClose,
  onSave,
  active = {},
  setActive,
  getClassesRequest,
  upsertClassRequest,
  addClassRequest,
  removeClassRequest,
  getStudentRequest,
  companyId,
  classes,
}) {
  const form = useRef()
  const newClassForm = useRef()
  const [data, setData] = useState({})
  const [addClassOpen, setAddClassOpen] = useState(false)

  const [loading, setLoading] = useState(false)
  const [classReadOnly, setClassReadOnly] = useState(false)
  const [activeClass, setActiveClass] = useState({})
  const [newCourseClass, setNewCourseClass] = useState({ numberOfTimes: 1 })
  const [collectPaymentModalOpen, setCollectPaymentModalOpen] = useState(false)
  const [enrollmentToCollect, setEnrollmentToCollect] = useState()

  const init = (data) => {
    setData({ ...data })
    setTab(0)
    getClassesRequest({})
    getStudentRequest({})
  }
  const onSaveClass = (params) => {
    setLoading(true)
    upsertClassRequest(
      { companyId, ...params },
      {
        success: (data) => {
          SuccessHelper.handleSuccess(
            i18n.t(data.id ? "successfully_updated" : "successfully_added"),
            true
          )
          setAddClassOpen(false)
          setLoading(false)
        },
        failure: () => {
          ErrorHelper.handleErrors(
            i18n.t(data.id ? "failed_to_update" : "failed_to_add"),
            true
          )
          setLoading(false)
        },
      }
    )
  }
  const handleEnrollmentChange = (newEnrollement) => {
    const index = data.enrollments.findIndex(
      (element) => element._id == newEnrollement._id
    )
    if (index != -1) {
      const newEnrollments = [...data.enrollments]
      newEnrollments[index] = newEnrollement
      setData({ ...data, enrollments: newEnrollments })
    }
  }
  useEffect(() => {
    init(active)
  }, [])
  useEffect(() => {
    if (active._id != data._id) {
      init(active)
    }
  }, [active])
  const handleChange = (key, value) => {
    data[key] = value
    setData({ ...data })
  }
  const handleSubmit = () => {
    onSave(data)
  }
  const handleClassAdd = () => {
    addClassRequest(
      {
        _id: active._id,
        classId: newCourseClass,
      },
      {
        success: (data) => {
          SuccessHelper.handleSuccess(i18n.t("successfully_added"), true)
          setData({ ...data.data })
          setActive(data.data)
          setNewCourseClass()
        },
        failure: () => {
          ErrorHelper.handleErrors(i18n.t("failed_to_add"), true)
        },
      }
    )
  }
  const handleClassRemoval = (classId) => {
    removeClassRequest(
      {
        _id: active._id,
        classId,
      },
      {
        success: (result) => {
          SuccessHelper.handleSuccess(i18n.t("successfully_deleted"), true)
          console.log(result.data)
          setData({ ...result.data })
          setActive(result.data)
        },
        failure: () => {
          ErrorHelper.handleErrors(i18n.t("failed_to_delete"), true)
        },
      }
    )
  }

  const [tab, setTab] = React.useState(0)

  const handleTabChange = (event, newValue) => {
    setTab(newValue)
  }

  const usedClassMap = useMemo(() => {
    const { classes = [] } = data
    const map = {}
    classes.map((_class) => {
      map[_class._id] = true
    })
    return map
  }, [data.classes])

  return (
    <div>
      <DotsLoader isloading={loading} />
      {open ? (
        <BaseModal
          open={open}
          title={
            <div className="row mx-0">
              <div className="pr-2">
                {data._id ? i18n.t("edit_program") : i18n.t("add_course")}
              </div>
            </div>
          }
          containerClassName="half-container"
          content={
            <>
              <AppBar position="static" className="custom-tab">
                <Tabs
                  value={tab}
                  onChange={handleTabChange}
                  aria-label="simple tabs example"
                >
                  <Tab label={i18n.t("details")} {...a11yProps(0)} />
                  <Tab
                    label={i18n.t("classes")}
                    {...a11yProps(1)}
                    disabled={!data._id}
                  />
                </Tabs>
              </AppBar>
              <TabPanel value={tab} index={0}>
                <div>
                  <ValidatorForm
                    onSubmit={handleSubmit}
                    ref={form}
                    className="w-100"
                    onError={(error) => {
                      if (!error.length) {
                        onSave(data)
                      }
                    }}
                  >
                    <div className="col-12 flex-column flex-md-row px-0 d-flex align-items-md-center mb-3">
                      <label
                        className="font-weight-bold"
                        style={{ minWidth: "120px" }}
                      >
                        {i18n.t("name")}
                      </label>
                      <div style={{ width: "100%" }}>
                        <TextValidator
                          className="w-100"
                          label={i18n.t("name")}
                          onChange={(e) => handleChange("name", e.target.value)}
                          value={data.name || ""}
                          validators={["required"]}
                          errorMessages={[i18n.t("name_is_required")]}
                          variant="outlined"
                        />
                      </div>
                    </div>
                    <div className="col-12 px-0 d-flex align-items-center mb-3">
                      <label
                        className="font-weight-bold"
                        style={{ minWidth: "120px" }}
                      >
                        {i18n.t("price")}
                      </label>
                      <CounterInput
                        value={isNaN(data.price) ? 0 : data.price}
                        label={i18n.t("price")}
                        onChange={(e) => handleChange("price", e)}
                      />
                    </div>
                    <div className="col-12 px-0 d-flex align-items-center mb-3">
                      <label
                        className="font-weight-bold"
                        style={{ minWidth: "120px" }}
                      >
                        {i18n.t("hours_per_week")}
                      </label>
                      <CounterInput
                        value={isNaN(data.hoursPerWeek) ? 0 : data.hoursPerWeek}
                        label={i18n.t("hours_per_week")}
                        onChange={(e) => handleChange("hoursPerWeek", e)}
                      />
                    </div>
                    <div className="col-12 flex-column flex-md-row px-0 d-flex align-items-md-center mb-3">
                      <label
                        className="font-weight-bold"
                        style={{ minWidth: "120px" }}
                      >
                        {i18n.t("start_date")}
                      </label>
                      <div style={{ width: "100%" }}>
                        <DatePicker
                          wrapperClassName="w-100"
                          selected={
                            data.startDate
                              ? moment(data.startDate).toDate()
                              : ""
                          }
                          onChange={(e) => handleChange("startDate", e)}
                          value={
                            data.startDate
                              ? moment(data.startDate).toDate()
                              : ""
                          }
                          customInput={
                            <TextValidator
                              label={i18n.t("start_date")}
                              onChange={(e) =>
                                handleChange("startDate", e.target.value)
                              }
                              value={data.startDate}
                              validators={["required"]}
                              errorMessages={[i18n.t("this_field_is_required")]}
                              variant="outlined"
                            />
                          }
                          placeholderText={i18n.t("start_date")}
                        />
                      </div>
                    </div>
                    <div className="col-12 px-0 flex-column flex-md-row d-flex align-items-md-center mb-3">
                      <label
                        className="font-weight-bold"
                        style={{ minWidth: "120px" }}
                      >
                        {i18n.t("end_date")}
                      </label>
                      <div style={{ width: "100%" }}>
                        <DatePicker
                          wrapperClassName="w-100"
                          selected={
                            data.endDate ? moment(data.endDate).toDate() : ""
                          }
                          onChange={(e) => handleChange("endDate", e)}
                          value={
                            data.endDate ? moment(data.endDate).toDate() : ""
                          }
                          customInput={
                            <TextValidator
                              label={i18n.t("end_date")}
                              onChange={(e) =>
                                handleChange("endDate", e.target.value)
                              }
                              value={data.endDate}
                              validators={["required"]}
                              errorMessages={[i18n.t("this_field_is_required")]}
                              variant="outlined"
                            />
                          }
                          placeholderText={i18n.t("end_date")}
                        />
                      </div>
                    </div>
                    <div className="col-12 flex-column flex-md-row px-0 d-flex align-items-md-center mb-3">
                      <label
                        className="font-weight-bold"
                        style={{ minWidth: "120px" }}
                      >
                        {i18n.t("description")}
                      </label>
                      <div style={{ width: "100%" }}>
                        <TextValidator
                          className="w-100"
                          label={i18n.t("description")}
                          onChange={(e) =>
                            handleChange("description", e.target.value)
                          }
                          value={data.description || ""}
                          validators={["required"]}
                          errorMessages={[i18n.t("description_is_required")]}
                          variant="outlined"
                        />
                      </div>
                    </div>
                    <div className="row mx-0 px-0 justify-content-end mt-4 mb-3 col-12">
                      <PrimaryButton
                        className="w-128"
                        props={{
                          type: "submit",
                        }}
                        label={data._id ? i18n.t("save") : i18n.t("create")}
                      />
                    </div>
                  </ValidatorForm>
                </div>
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <ValidatorForm
                  onSubmit={() => {
                    handleClassAdd()
                  }}
                  ref={newClassForm}
                  className="w-100"
                >
                  <div className="row mb-3 px-0 flex-column flex-md-row align-items-md-end gap-2 pt-2">
                    <div style={{ flex: 10 }}>
                      <SelectValidator
                        label={i18n.t("class")}
                        onChange={(e) => {
                          setNewCourseClass(e.target?.value)
                        }}
                        value={newCourseClass || ""}
                        select
                        validators={["required"]}
                        errorMessages={[i18n.t("required")]}
                        variant="outlined"
                      >
                        <MenuItem disabled={true}>{i18n.t("class")}</MenuItem>
                        {classes.map((_class) => {
                          return (
                            <MenuItem
                              key={_class._id}
                              value={_class._id}
                              disabled={usedClassMap[_class._id]}
                            >
                              {_class.name}
                            </MenuItem>
                          )
                        })}
                      </SelectValidator>
                    </div>
                    <div className="d-flex gap-2 justify-content-center">
                      <PrimaryButton
                        className="w-98"
                        label={i18n.t("new")}
                        onClick={() => {
                          setAddClassOpen(true)
                        }}
                      />
                      <PrimaryButton
                        className="w-98"
                        props={{
                          type: "submit",
                        }}
                        label={i18n.t("add")}
                      />
                    </div>
                  </div>
                </ValidatorForm>
                <BasicTable
                  rows={data.classes}
                  maxContentWidth={false}
                  schema={[
                    {
                      header: i18n.t("class"),
                      field: "class",
                      accessor: (val) => val.name,
                    },
                    {
                      key: "action",
                      header: "",
                      style: { width: 60 },
                      render: (val) => {
                        return (
                          <FontAwesomeIcon
                            className="view-icon"
                            style={{ backgroundColor: "#F6943B" }}
                            color="white"
                            icon={["fas", "trash"]}
                            onClick={() =>
                              deleteConfirmation(() =>
                                handleClassRemoval(val._id)
                              )
                            }
                          />
                        )
                      },
                    },
                  ]}
                />
              </TabPanel>
            </>
          }
          onClose={onClose}
        />
      ) : null}
      {addClassOpen && (
        <ClassUpsert
          open={addClassOpen}
          element={activeClass}
          onClose={() => {
            setAddClassOpen(false)
            setActiveClass({})
          }}
          onSave={onSaveClass}
          presetVendor
          readOnly={classReadOnly}
        />
      )}
      {collectPaymentModalOpen ? (
        <CollectPaymentModal
          open={collectPaymentModalOpen}
          setOpen={setCollectPaymentModalOpen}
          enrollment={enrollmentToCollect}
          onSuccess={handleEnrollmentChange}
        />
      ) : null}
    </div>
  )
}

const mapStateToProps = (state) => ({
  classes: state._class.data,
  loading: state.program.isFetching,
})

const action = {
  getClassesRequest,
  upsertClassRequest,
  addClassRequest,
  removeClassRequest,
  getStudentRequest,
}

export default connect(mapStateToProps, action)(ProgramUpsert)
