import React, { useEffect, useMemo, useRef, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import i18n from "../../../../i18n";
import PrimaryButton from "../../../Core/PrimaryButton";
import moment from "moment";
import { BaseModal } from "../../..";
import ReactDatePicker from "react-datepicker";

export default function UpsertSchedule({
  open,
  setOpen,
  defaults = {},
  onSave,
  active = {},
}) {
  const form = useRef();
  const [data, setData] = useState({});

  useEffect(() => {
    if (open) {
      const formattedActive = { ...active };
      if (active._id) {
        formattedActive.startDate =
          active.startDate && moment(active.startDate).toDate();
        formattedActive.endDate =
          active.endDate && moment(active.endDate).toDate();
        formattedActive.dispatchDate =
          active.dispatchDate && moment(active.dispatchDate).toDate();
        formattedActive.closeDate =
          active.closeDate && moment(active.closeDate).toDate();
      }
      setData({
        ...defaults,
        ...formattedActive,
      });
    } else {
      setData({});
    }
  }, [open, active._id]);

  const [endDate, maxDate] = useMemo(() => {
    if (!data.startDate) {
      return [];
    }
    return [
      moment(data.startDate).add(6, "d").toDate(),
      moment(data.startDate).add(6, "d").toDate(),
    ];
  }, [data.startDate]);

  useEffect(() => {
    if (!data._id) {
      setData({
        ...data,
        dispatchDate: moment(data.startDate).add(1, "d").toDate(),
        closeDate: moment(data.startDate).add(6, "d").toDate(),
      });
    }
  }, [data.startDate]);

  const handleAdd = () => {
    onSave(data);
  };

  if (!open) {
    return null;
  }
  return (
    <BaseModal
      open={open}
      contentContainerStyle={{
        overflowY: "visible",
      }}
      title={data._id ? i18n.t("edit_schedule") : i18n.t("add_schedule")}
      onClose={() => setOpen(false)}
      containerClassName={"half-container"}
      content={
        <div className="row mx-0 ScheduleBuilder">
          <ValidatorForm
            onSubmit={handleAdd}
            ref={form}
            className="row col-12 px-0 mx-0"
            onError={(error) => {
              console.log(error);
            }}
          >
            <ReactDatePicker
              wrapperClassName="col-12 w-100 mb-1 px-0"
              selected={data.startDate}
              dateFormat="MM/dd/yyyy"
              onChange={(val) => {
                setData({ ...data, startDate: val });
              }}
              filterDate={(date) => {
                return date.getDay() === 0;
              }}
              readOnly={!!data._id}
              customInput={
                <TextValidator
                  label={i18n.t("start_date")}
                  value={data.startDate || ""}
                  validators={["required"]}
                  errorMessages={[i18n.t("this_field_is_required")]}
                  readOnly={true}
                />
              }
            />
            <ReactDatePicker
              wrapperClassName="col-12 w-100 mb-1 px-0"
              selected={endDate}
              dateFormat="MM/dd/yyyy"
              readOnly
              customInput={
                <TextValidator
                  label={i18n.t("end_date")}
                  value={endDate || ""}
                  readOnly={true}
                />
              }
            />
            <ReactDatePicker
              wrapperClassName="col-12 w-100 mb-1 px-0"
              selected={data.dispatchDate}
              dateFormat="MM/dd/yyyy"
              onChange={(val) => {
                setData({ ...data, dispatchDate: val });
              }}
              maxDate={maxDate}
              minDate={data.startDate}
              customInput={
                <TextValidator
                  label={i18n.t("send_schedule_on")}
                  value={data.dispatchDate || ""}
                  validators={["required"]}
                  errorMessages={[i18n.t("this_field_is_required")]}
                  readOnly={true}
                />
              }
            />
            <ReactDatePicker
              wrapperClassName="col-12 w-100 mb-1 px-0"
              selected={data.closeDate}
              dateFormat="MM/dd/yyyy"
              onChange={(val) => {
                setData({ ...data, closeDate: val });
              }}
              maxDate={maxDate}
              minDate={data.startDate}
              customInput={
                <TextValidator
                  label={i18n.t("close_schedule_on")}
                  value={data.closeDate || ""}
                  validators={["required"]}
                  errorMessages={[i18n.t("this_field_is_required")]}
                  readOnly={true}
                />
              }
            />

            <div className="row mx-0 px-0 justify-content-end mt-2 col-12">
              <PrimaryButton
                props={{
                  type: "submit",
                }}
                label={i18n.t("create")}
              />
            </div>
          </ValidatorForm>
        </div>
      }
    />
  );
}
