// @flow

import { GENERATED_BOOKING_SCHEDULE } from "../ActionTypes"

export function getRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: GENERATED_BOOKING_SCHEDULE.GET_SAGA,
  }
}

export function get(data) {
  return {
    data,
    type: GENERATED_BOOKING_SCHEDULE.GET,
  }
}
export function requestFailure() {
  return {
    type: GENERATED_BOOKING_SCHEDULE.REQUEST_FAILURE,
  }
}
