import React, { useState } from "react"
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js"
import Button from "@material-ui/core/Button"
import i18n from "../../../i18n"
import BaseModal from "../../BaseModal"
import Images from "../../../theme/Images"
import Accordion from "@material-ui/core/Accordion"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { SuccessHelper } from "../../../helpers"
import { ErrorHelper } from "../../../helpers"
import PlacesAutocomplete from "../../PlacesAutocomplete"
import TextField from "@material-ui/core/TextField"
import FormHelperText from "@material-ui/core/FormHelperText"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import DotsLoader from "../../DotsLoader"
import "./style.scss"
import { getNestedValue } from "../../../util/objectMethods"
import deleteConfirmation from "./../../../helpers/DeleteHelper"
import PrimaryButton from "../../Core/PrimaryButton"

const {
  saloon_form_master_card: master_card,
  saloon_form_american_express: amex,
  saloon_form_visa: visa,
  table_delete_icon: table_delete_icon,
} = Images
const cardBrandImageMap = {
  master_card,
  amex,
  visa,
}
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
}
// end points:
// company cards
// cards

const CardManagement = ({
  id = null,
  cards = [],
  defaultCard,
  insertRequest,
  removeRequest,
  getRequest,
  setDefaultRequest,
  removeDefault,
  showAddModal,
  setShowAddModal = () => {},
}) => {
  const stripe = useStripe()
  const elements = useElements()

  const [completed, setCompleted] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [name, setName] = useState()
  const [addressMap, setAddressMap] = useState({})
  const [errorMap, setErrorMap] = useState({})
  const [loading, setLoading] = useState(false)
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const createIntentForAdd = () => {
    const newErrorMap = {}
    if (Object.keys(addressMap).length < 2) {
      newErrorMap.address = i18n.t("full_address_is_required")
    }
    if (!completed) {
      newErrorMap.card = true
    }
    if (!name) {
      newErrorMap.name = i18n.t("name_is_required")
    }
    if (Object.keys(newErrorMap) == 0) {
      setLoading(true)
      insertRequest(
        { _id: id },
        {
          success: async (data) => {
            const result = await stripe
              .confirmCardSetup(data.client_secret, {
                payment_method: {
                  card: elements.getElement(CardElement),
                  billing_details: {
                    name,
                    address: {
                      city: addressMap.city,
                      country: addressMap.country_abbr,
                      line1: addressMap.address,
                      line2: addressMap.address_line_2,
                      postal_code: addressMap.postalCode,
                      state: addressMap.province_abbr,
                    },
                  },
                },
              })
              .then(() => {
                getRequest({ _id: id })
                SuccessHelper.handleSuccess(i18n.t("successfully_added"), true)
                setLoading(false)
                setShowAddModal(false)
              })
          },
        }
      )
    }
    setErrorMap(newErrorMap)
  }

  const removeCard = (body) => {
    removeRequest(body, {
      success: () => {
        SuccessHelper.handleSuccess(i18n.t("successfully_deleted"), true)
      },
      failure: () => {
        ErrorHelper.handleErrors(i18n.t("failed_to_delete"), true)
      },
    })
  }

  const onSetDefaultCard = (paymentMethodId) => {
    setDefaultRequest(
      { _id: id, paymentMethodId },
      {
        success: () => {
          SuccessHelper.handleSuccess(
            i18n.t("successfully_set_default_card"),
            true
          )
          getRequest({ _id: id })
        },
        failure: () => {
          ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true)
        },
      }
    )
  }

  return (
    <div className="card-management">
      <DotsLoader isloading={loading} />
      <BaseModal
        open={showAddModal}
        containerClassName={"half-container"}
        {...{
          title: i18n.t("add_card"),
          actions: (
            <div className="row justify-content-end mr-3 mb-3">
              <PrimaryButton
                className="w-128"
                onClick={() => {
                  createIntentForAdd()
                }}
              >
                {i18n.t("save")}
              </PrimaryButton>
            </div>
          ),
          content: (
            <div className="row mx-0">
              <div className="col-12 d-flex flex-column flex-md-row align-items-md-center mb-3 px-0">
                <label
                  className="font-weight-bold"
                  style={{ minWidth: "120px" }}
                >
                  {i18n.t("name")}
                </label>
                <TextField
                  id="name"
                  className="w-100"
                  label={i18n.t("name")}
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value)
                  }}
                  helperText={errorMap.name}
                  error={!!errorMap.name}
                  variant="outlined"
                />
              </div>
              <div className="col-12 d-flex flex-column flex-md-row align-items-md-center mb-3 px-0">
                <label
                  className="font-weight-bold"
                  style={{ minWidth: "120px" }}
                >
                  {i18n.t("address")}
                </label>
                <PlacesAutocomplete
                  className="w-100"
                  addressSelected={(newMap) =>
                    setAddressMap({
                      ...newMap,
                      address_line_2: getNestedValue(
                        addressMap,
                        ["address_line_2"],
                        ""
                      ),
                    })
                  }
                  helperText={errorMap.address}
                  error={!!errorMap.address}
                />
              </div>
              <div className="col-12 d-flex flex-column flex-md-row align-items-md-center mb-3 px-0">
                <label
                  className="font-weight-bold"
                  style={{ minWidth: "120px" }}
                >
                  {i18n.t("address_line_2")}
                </label>
                <TextField
                  id="address_line_32"
                  className="w-100"
                  label={i18n.t("address_line_2")}
                  value={getNestedValue(addressMap, ["address_line_2"], "")}
                  onChange={(e) =>
                    setAddressMap({
                      ...addressMap,
                      address_line_2: e.target.value,
                    })
                  }
                  helperText={errorMap.address_line_2}
                  error={!!errorMap.address_line_2}
                  variant="outlined"
                />
              </div>
              <div className="col-12 d-flex flex-column flex-md-row align-items-md-center mb-3 px-0">
                <label
                  className="font-weight-bold"
                  style={{ minWidth: "120px" }}
                >
                  {i18n.t("card")}
                </label>
                <div
                  style={{
                    border: "1px solid #d9d9d9",
                    width: "100%",
                    padding: "8px",
                    borderRadius: 5,
                  }}
                >
                  <FormControl
                    className="w-100"
                    error={!!errorMap.card}
                    variant="outlined"
                  >
                    <CardElement
                      options={CARD_ELEMENT_OPTIONS}
                      onChange={(e) => {
                        setCompleted(e.complete)
                      }}
                    />
                    {!!errorMap.card && (
                      <FormHelperText>
                        {i18n.t("card_is_required")}
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>
              </div>
            </div>
          ),
        }}
        onClose={() => setShowAddModal(false)}
      />

      {cards.length ? (
        <div className="pt-3 row gap-5">
          {cards.map((card) => {
            const { brand, last4, exp_month, exp_year } = card.card
            const {
              billing_details: {
                address: { city, country, line1, line2, postal_code, state },
                name,
              },
            } = card
            return (
              <div className="col-md-4 px-0">
                <Accordion
                  key={card.id}
                  // expanded={expanded === card.id}
                  onChange={handleChange(card.id)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <div className="row mx-0 justify-content-between align-items-center w-100">
                      <div className="row mx-0">
                        <div
                          style={{
                            backgroundImage: `url(${cardBrandImageMap[brand]})`,
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            width: 40,
                            height: 40,
                          }}
                        ></div>
                        <div
                          style={{
                            textTransform: "capitalize",
                            alignSelf: "center",
                          }}
                          className="pl-1"
                        >
                          {i18n.t("card_ending_in", { brand, last4 })}
                        </div>
                      </div>
                      {removeDefault || defaultCard != card.id ? (
                        <PrimaryButton
                          className="py-2 px-3"
                          style={{ minWidth: "unset" }}
                          onClick={(e) => {
                            e.stopPropagation()
                            deleteConfirmation(() =>
                              removeCard({ cardId: card.id, _id: id })
                            )
                          }}
                        >
                          <img
                            role="button"
                            style={{ width: 16, height: 18, pointer: "cursor" }}
                            src={Images.table_delete_icon}
                            disabled={!removeDefault && defaultCard == card.id}
                          />
                        </PrimaryButton>
                      ) : (
                        <strong style={{ color: "#F6943B" }}>Default</strong>
                      )}
                    </div>
                  </AccordionSummary>
                  <AccordionDetails className="d-block">
                    {removeDefault ? (
                      ""
                    ) : (
                      <React.Fragment>
                        <div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={defaultCard == card.id}
                                disabled={defaultCard == card.id}
                                onChange={() => onSetDefaultCard(card.id)}
                              />
                            }
                            label={`${i18n.t("default_card")} ${
                              defaultCard == card.id
                                ? i18n.t("cannot_delete_default_card")
                                : ""
                            }`}
                          />
                        </div>
                      </React.Fragment>
                    )}
                    <div className="row align-items-center mb-3">
                      <strong style={{ width: 150 }}>
                        {i18n.t("name_on_card")}
                      </strong>
                      <div className="card-info">{name}</div>
                    </div>
                    <div className="row align-items-center mb-3">
                      <strong style={{ width: 150 }}>Ending In</strong>
                      <div className="card-info">
                        {exp_month}/{exp_year}
                      </div>
                    </div>
                    <div className="row align-items-center mb-3">
                      <strong style={{ width: 150 }}>
                        {i18n.t("billing_address")}
                      </strong>
                      <div className="card-info">
                        {line2} {line1}, {city}, {state}, {postal_code},{" "}
                        {country}
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            )
          })}
        </div>
      ) : (
        <div className="pt-3"></div>
      )}
    </div>
  )
}

export default CardManagement
