import React, { useEffect, useState, useMemo } from "react"
import { useHistory } from "react-router-dom"
import { connect } from "react-redux"
import "./styles.scss"
import { getNestedValue } from "../../util/objectMethods"
import TextField from "@material-ui/core/TextField"
import i18n from "../../i18n"
import moment from "moment"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useTheme } from "@material-ui/core/styles"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import Button from "@material-ui/core/Button"
import { InputAdornment } from "@material-ui/core"
import ManageExtras from "../Extras/ManageExtras"
import BaseModal from "../BaseModal"
import TransactionDetails from "../TransactionDetails"
import CustomerEdit from "../Saloon/Customer/CustomerEdit"
import Chip from "@material-ui/core/Chip"
import { getRequest as getTransactions } from "../../redux/actions/BookingTransaction"
import useBooking from "../../context/Booking"
import {
  confirmBooking,
  sendForm,
  resendForm,
  getFormsByBookingId,
  getCustomerByUserId,
  addProduct,
} from "../../config/simpleApiCalls"
import { ErrorHelper, SuccessHelper } from "../../helpers"
import { getRequest as getForms } from "../../redux/actions/Saloon/FormBuilder"
import SendFormModal from "./components/SendFormModal.component"
import SendCommunicationsModal from "./components/SendCommunicationsModal.component"
import CancelModal from "../NotifyModal/CancelModal"
import InfoIcon from "@material-ui/icons/Info"
import Swal from "sweetalert2"
import MailIcon from "@material-ui/icons/Mail"
import PrintIcon from "@material-ui/icons/Print"
import { AccountCircle } from "@material-ui/icons"
import useActiveMenuType from "../../context/ActiveMenuType"
import PrimaryButton from "../Core/PrimaryButton"
import CustomerCollectPayment from "../Saloon/Customer/CustomerCollectPayment"
import { useCallback } from "react"
import debounce from "lodash.debounce"
import { Images } from "../../theme"
import useScreenSize from "../../context/ScreenSize"
const relatedStatusMap = {
  1: [2, 3, 6, 7],
  2: [3, 7],
}

const roundTo2Decimals = (x) => Math.round((x + Number.EPSILON) * 100) / 100

const AppointmentModal = ({
  open,
  selectBooking,
  employeeMap = {},
  handleDrawerClose,
  statusButtonClicked,
  isSalon,
  isIndependent,
  onProductChange,
  onProductDelete,
  onServiceDelete,
  onNotesClick,
  onModifyClick,
  onAddClick,
  companyTaxes,
  toggleDepositRequired,
  onChangeTip,
  getTransactions,
  transactions = [],
  showInsertPayment,
  paymentCompleteCallback,
  setData,
  toggleTaxes,
  getForms,
  forms = [],
  onChangeAdjustedTime,
  onUpdate,
  products: allProducts,
  bookings,
  isGym,
}) => {
  const statusButtonMap = {
    1: {
      label: i18n.t("container.services_waiting_status"),
    },
    2: {
      label: i18n.t("container.services_now_serving_status"),
    },
    3: {
      label: i18n.t("container.services_cancelled_status"),
    },
    4: {
      label: i18n.t("container.services_done_status"),
    },
    5: {
      label: i18n.t("finalize"),
    },
    6: {
      label: i18n.t("confirm"),
    },
    7: {
      label: i18n.t("no_show"),
    },
  }

  const { type } = useActiveMenuType()

  const [optionsModalOpen, setOptionsModalOpen] = useState(false)
  const { screenSize } = useScreenSize()
  const isOwner = useMemo(() => {
    return type && type != "employee"
  }, [type])

  const isElectron = window.isElectron
  const readOnly = !!selectBooking.isFinalized
  const status = selectBooking.status
  const date = getNestedValue(selectBooking, ["services", 0, "date"], "")
  const email =
    getNestedValue(selectBooking, ["email"], "") ||
    getNestedValue(selectBooking, ["userId", "email"], "")
  let formattedDate
  if (date) {
    formattedDate = moment(date, "MM-DD-YYYY")
  }
  const depositRequired = selectBooking.depositRequired
  const depositAmount = selectBooking.depositAmount
  const selectBookingAmount = parseFloat(selectBooking.totalAmount)
  const tip = selectBooking.tip ? parseFloat(selectBooking.tip) : 0
  const [bookingForms, setBookingForms] = React.useState({})
  const [resendForms, setResendForms] = React.useState({})
  const [customer, setCustomer] = React.useState({})
  const [collectCustomerPaymentOpen, setCollectCustomerPaymentOpen] =
    React.useState(false)
  const [adjustedTime, setAdjustedTime] = useState(0)
  const { setAddOpen, setSelectBooking, setLoading } = useBooking()
  const getBookingForms = () => {
    getFormsByBookingId({ bookingId: selectBooking._id }).then(({ data }) => {
      setBookingForms(data.data)
    })
  }

  useEffect(() => {
    getTransactions({ bookingId: selectBooking._id })
    if (!forms.length) {
      getForms({
        companyId: selectBooking.companyId?._id,
      })
    }
    getBookingForms()
    getCustomerByUserId({ userId: selectBooking.userId?._id }).then(
      ({ data }) => {
        setCustomer(data.data)
      }
    )
    if (selectBooking.addServiceQuestionPopup) {
      Swal.fire({
        title: i18n.t("would_you_to_add_extras"),
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonText: "Yes",
      }).then(async (e) => {
        const { isConfirmed } = e
        if (isConfirmed) {
          onAddClick()
        }
      })
    }
  }, [])
  useEffect(() => {
    setAdjustedTime(selectBooking.adjustedTime || 0)
  }, [selectBooking])
  const [formModalOpen, setFormModalOpen] = React.useState(false)
  const [communicationModalOpen, setCommunicationModalOpen] =
    React.useState(false)
  const [cancelModalOpen, setCancelModalOpen] = React.useState(false)

  const [communicationType, setCommunicationType] = useState()
  const onSendForm = (formIds, method) => {
    const getPromise = () => {
      if (resendForms) {
        return resendForm({
          method,
          bookingId: selectBooking._id,
        })
      }
      if (!formIds.length) {
        setFormModalOpen(false)
        return
      }
      return sendForm({
        method,
        formIds,
        userId: selectBooking.userId._id,
        bookingId: selectBooking._id,
        companyId: selectBooking.companyId._id,
      })
    }
    const promise = getPromise()
    if (!promise) {
      return
    }
    promise
      .then(() => {
        SuccessHelper.handleSuccess(i18n.t("successfully_added"), true)
        getBookingForms()
        setFormModalOpen(false)
      })
      .catch(() => {
        ErrorHelper.handleErrors(i18n.t("failed_to_add"), true)
      })
  }
  const service = getNestedValue(selectBooking, ["services", 0], undefined)
  const [statusAnchorEl, setStatusAnchorEl] = React.useState(null)
  const [formAnchorEl, setFormAnchorEl] = React.useState(null)
  const [sendAnchorEl, setSendAnchorEl] = React.useState(null)
  const [showCustomerModal, setShowCustomerModal] = useState(null)
  const theme = useTheme()
  const isXS = useMediaQuery(theme.breakpoints.down("xs"))
  const buttonSize = isXS ? "small" : "medium"
  let history = useHistory()

  const handleRoutToChat = (firstName, salonId, img) => {
    if (isSalon) {
      history.push({
        pathname: "/saloon-chat",
        state: { name: firstName, _id: salonId, img },
      })
    } else {
      history.push({
        pathname: "/employee-chat",
        state: { name: firstName, _id: salonId, img },
      })
    }
  }

  const handleStatusClick = (event) => {
    setStatusAnchorEl(event.currentTarget)
  }
  const handleStatusClose = () => {
    setStatusAnchorEl(null)
  }

  const handleFormClick = (event) => {
    setFormAnchorEl(event.currentTarget)
  }
  const handleFormClose = () => {
    setFormAnchorEl(null)
  }

  const handleSendClick = (event) => {
    setSendAnchorEl(event.currentTarget)
  }
  const handleSendClose = () => {
    setSendAnchorEl(null)
  }

  let productsTotal = 0
  const products = getNestedValue(
    selectBooking,
    ["cartId", "products"],
    []
  ).map((product) => {
    productsTotal += product.price * product.quantity
    return {
      ...product,
      name: product.productId.name,
    }
  })
  let servicesTotal = parseFloat(selectBookingAmount)
  let extrasTotal = 0
  getNestedValue(selectBooking, "extras", []).map((service) => {
    extrasTotal += service.price
  })
  const total = servicesTotal + extrasTotal + productsTotal
  const accountBalance =
    customer?.accountBalance && customer?.accountBalance > 0
      ? customer?.accountBalance
      : 0
  const giftCardBalance = customer?.giftCardBalance || 0
  const debounceSetAdjustedTime = useCallback(
    debounce((newValue) => {
      onChangeAdjustedTime(newValue)
    }, 400),
    []
  )

  const [lastInput, setLastInput] = useState()
  const [scannerInput, setScannerInput] = useState("")
  const [scannerComplete, setScannerComplete] = useState(false)
  const [collectedMap, setCollectedMap] = useState({
    extras: {},
    products: {},
  })
  useEffect(() => {
    transactions.map((transaction) => {
      const { metadata = {}, wasReversed, type } = transaction
      if (!wasReversed && type !== "Reverse") {
        Object.keys(metadata).map((key) => {
          let mappedKey
          switch (key) {
            case "Extras":
            case "Products": {
              mappedKey = key.toLowerCase()
              if (!collectedMap[mappedKey]) {
                collectedMap[mappedKey] = {}
              }
              if (!collectedMap[mappedKey][metadata[key]]) {
                collectedMap[mappedKey][metadata[key]] = true
              }
              break
            }
          }
        })
      }
    })
    setCollectedMap({ ...collectedMap })
  }, [transactions])
  const [displayManageExtras, setDisplayManageExtras] = useState(true)
  const onProductAddAdd = async () => {
    const existingIndex = products.findIndex((product) => {
      return (
        product.productId.barCode == scannerInput &&
        !collectedMap?.products?.[product._id]
      )
    })
    if (existingIndex !== -1) {
      const existing = products[existingIndex]
      onProductChange(existing.quantity + 1, existingIndex).then(() => {
        setDisplayManageExtras(false)
        setTimeout(() => {
          setDisplayManageExtras(true)
        }, 0)
      })
      return
    }
    const found = allProducts.find((product) => {
      return product.barCode == scannerInput
    })
    if (!found) {
      return
    }
    const { data } = await addProduct({
      bookingId: selectBooking._id,
      productId: found._id,
      quantity: 1,
    })
    if (data.success) {
      let newSelectBooking = selectBooking
      if (
        typeof selectBooking.index === "number" &&
        bookings[selectBooking.index]
      ) {
        bookings[selectBooking.index].cartId = data.data
      }
      newSelectBooking = { ...selectBooking, cartId: data.data }
      SuccessHelper.handleToastSuccess("Added Item", true)
      onUpdate({
        selectBooking: { ...newSelectBooking },
        bookings,
        allAppoin: bookings,
      })
    } else {
      if (data.code) {
        ErrorHelper.handleErrors(i18n.t(data.code, { ...data.data }), true)
      } else {
        ErrorHelper.handleErrors("Failed to Add Item", true)
      }
    }
  }
  useEffect(() => {
    if (scannerComplete) {
      onProductAddAdd()
    }
  }, [scannerComplete])
  const keyDownHandle = useCallback(function (e) {
    if (e.ctrlKey || e.key == "Shift") {
      return
    }
    if (e.key == "Enter") {
      setScannerComplete(true)
      setTimeout(() => {
        setScannerComplete(false)
        setLastInput()
        setScannerInput("")
      }, 0)
      return
    }
    const date = new Date()
    if (isNaN(date - lastInput) || date - lastInput < 150) {
      setScannerInput(scannerInput + e.key)
    } else {
      setScannerInput(e.key)
    }
    setLastInput(date)
  })
  useEffect(() => {
    document.addEventListener("keydown", keyDownHandle)
    return () => {
      document.removeEventListener("keydown", keyDownHandle)
    }
  }, [keyDownHandle])
  const serviceDate = moment(`${service.date} ${service.time}`)
  const printReceipt = () => {
    const {
      cartId,
      extras = [],
      services,
      totalAmount,
      shortenedId,
      companyId: {
        address,
        businessTelephone,
        name: companyName,
        _id: companyId,
      },
      discount = 0,
      tip = 0,
    } = selectBooking
    let subtotal = totalAmount

    const items = [
      {
        name: services[0]?.serviceId?.name,
        value: totalAmount,
      },
      ...(cartId?.products || []).map((product) => {
        const { _id, price, productId, quantity } = product
        const value = roundTo2Decimals(quantity * price)
        subtotal += value
        return {
          name: productId.name,
          value,
        }
      }),
      ...extras.map((service) => {
        const { price, serviceId, name } = service
        const value = roundTo2Decimals(price)
        subtotal += value
        return {
          name: serviceId ? serviceId.name : name,
          value,
        }
      }),
    ]

    let grandTotal = subtotal + tip - discount

    const computedTaxes = companyTaxes.map((tax) => {
      const value = (tax.percentage * subtotal) / 100
      grandTotal += value
      return {
        name: tax.name,
        value,
      }
    })
    if (window.api) {
      window.api.send("printer-print-receipt", {
        companyName,
        items,
        subtotal: roundTo2Decimals(subtotal),
        grandTotal: roundTo2Decimals(grandTotal),
        taxes: computedTaxes,
        shortenedId,
        address,
        phoneNo: businessTelephone,
        serviceEmployee: employeeMap[services[0].employeeId],
        printEmployee: "",
        companyId,
        discount: -1 * discount,
        tip,
      })
    }
  }
  const renderButtons = () => {
    return (
      <>
        <PrimaryButton
          className="button-section book"
          onClick={() => {
            setAddOpen(true)
            setSelectBooking(selectBooking)
          }}
          size={buttonSize}
        >
          <div className="py-2">{i18n.t("book")}</div>
        </PrimaryButton>
        <PrimaryButton
          className="button-section notes-btn"
          onClick={() => onNotesClick()}
          size={buttonSize}
        >
          {selectBooking.notes?.length || selectBooking.reminders?.length ? (
            <div className="booking-notification"></div>
          ) : (
            <div></div>
          )}
          <div className="py-2">{i18n.t("notes_and_reminders")}</div>
        </PrimaryButton>
        {status == 1 && (
          <PrimaryButton
            className="button-section modify-btn"
            onClick={() => onModifyClick()}
            size={buttonSize}
            // style={{
            //   backgroundColor: "#F6943B",
            //   color: "white",
            // }}
          >
            <div className="py-2">{i18n.t("modify")}</div>
          </PrimaryButton>
        )}
        <>
          <PrimaryButton
            className="button-section form-btn"
            size={buttonSize}
            aria-controls="customized-menu"
            aria-haspopup="true"
            // style={{
            //   backgroundColor: "#F6943B",
            //   color: "white",
            // }}
            variant="contained"
            // color="#F6943B"
            onClick={handleFormClick}
          >
            <div className="py-2">{i18n.t("form")}</div>
          </PrimaryButton>
          <Menu
            id="simple-menu"
            anchorEl={formAnchorEl}
            keepMounted
            open={Boolean(formAnchorEl)}
            onClose={handleFormClose}
          >
            <MenuItem
              onClick={() => {
                setFormModalOpen(true)
                setResendForms(true)
                handleFormClose()
              }}
            >
              <div className="py-2">{i18n.t("resend_form")}</div>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setFormModalOpen(true)
                setResendForms(false)
                handleFormClose()
              }}
            >
              <div className="py-2">{i18n.t("new_form")}</div>
            </MenuItem>
            <div className="py-3" style={{ padding: "0 16px" }}>
              {i18n.t("sent_forms")}
            </div>
            {Object.keys(bookingForms).map((status) => {
              return (
                <>
                  <div className="MuiListItem-gutters MuiListItem-root font-bold">
                    {i18n.t(status)}
                  </div>
                  {bookingForms[status].map((form) => {
                    return (
                      <MenuItem
                        onClick={() => {
                          window.open(`/form-fill/${form._id}`, "_blank")
                        }}
                      >
                        <div className="py-2">{form.formId.formTitle}</div>
                      </MenuItem>
                    )
                  })}
                </>
              )
            })}
          </Menu>
        </>
        <PrimaryButton
          className="button-section"
          onClick={handleSendClick}
          size={buttonSize}
        >
          {i18n.t("send")}
        </PrimaryButton>
        <Menu
          id="simple-menu"
          anchorEl={sendAnchorEl}
          keepMounted
          open={Boolean(sendAnchorEl)}
          onClose={handleSendClose}
        >
          <MenuItem
            onClick={() => {
              setCommunicationType("confirmation")
              setCommunicationModalOpen(true)
              setSendAnchorEl()
            }}
          >
            <div className="py-2">{i18n.t("resend_form")}</div>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setCommunicationType("invoice")
              setCommunicationModalOpen(true)
              setSendAnchorEl()
            }}
          >
            <div className="py-2">{i18n.t("invoice")}</div>
          </MenuItem>
        </Menu>
        {isElectron ? (
          <Button
            onClick={printReceipt}
            size={buttonSize}
            style={{
              backgroundColor: "#3f51b5",
              color: "white",
            }}
          >
            <div className="py-2">
              <PrintIcon />
            </div>
          </Button>
        ) : null}
        <>
          {relatedStatusMap[status] && !selectBooking.isFinalized ? (
            <>
              <PrimaryButton
                className="button-section"
                onClick={handleStatusClick}
                size={buttonSize}
              >
                {i18n.t("status")}
              </PrimaryButton>
              <Menu
                id="simple-menu"
                anchorEl={statusAnchorEl}
                keepMounted
                open={Boolean(statusAnchorEl)}
                onClose={handleStatusClose}
              >
                {relatedStatusMap[status].map((element) => {
                  if (element == 6) {
                    if (!selectBooking.isConfirmed) {
                      return (
                        <MenuItem
                          onClick={() => {
                            handleStatusClose()
                            confirmBooking({ id: selectBooking._id })
                              .then(({ data }) => {
                                if (data.success) {
                                  SuccessHelper.handleSuccess(
                                    i18n.t("success"),
                                    true
                                  )
                                } else {
                                  ErrorHelper.handleErrors(
                                    i18n.t("something_went_wrong"),
                                    true
                                  )
                                }
                              })
                              .catch(() => {
                                ErrorHelper.handleErrors(
                                  i18n.t("something_went_wrong"),
                                  true
                                )
                              })
                          }}
                        >
                          <div className="py-2">
                            {statusButtonMap[element].label}
                          </div>
                        </MenuItem>
                      )
                    }
                  } else {
                    return (
                      <MenuItem
                        onClick={() => {
                          handleStatusClose()
                          if (element == 3) {
                            setCancelModalOpen(true)
                          } else {
                            statusButtonClicked(element == 5 ? 4 : element)
                          }
                        }}
                      >
                        <div className="py-2">
                          {statusButtonMap[element].label}
                        </div>
                      </MenuItem>
                    )
                  }
                })}
              </Menu>
            </>
          ) : null}
        </>
      </>
    )
  }
  return (
    <span className="appointment-drawer">
      <BaseModal
        containerClassName="modal-container"
        open={open}
        showCloseBtn={screenSize !== "mobile" ? true : false}
        style={{
          zIndex: 1301,
        }}
        actions={
          screenSize === "mobile" ? (
            <div className="d-flex justify-content-center">
              <PrimaryButton
                className="w-128"
                onClick={() => handleDrawerClose()}
              >
                {i18n.t("close")}
              </PrimaryButton>
            </div>
          ) : null
        }
        {...{
          title: (
            <div>
              <div className="d-flex flex-wrap align-items-center">
                {serviceDate.format("MMMM Do YYYY h:mm a")}
                <Chip
                  className="status-chip"
                  size="small"
                  label={statusButtonMap[status]?.label}
                />

                {selectBooking.userPackageId ? (
                  <Chip
                    className="status-chip ml-1"
                    size="small"
                    label={i18n.t("prepaid")}
                  />
                ) : null}
                {selectBooking.transferValidated ? (
                  <Chip
                    className="status-chip ml-1"
                    size="small"
                    label={i18n.t("transfer_confirmed")}
                  />
                ) : null}
                {selectBooking.isConfirmed ? (
                  <Chip
                    className="status-chip ml-1"
                    size="small"
                    label={i18n.t("confirmed")}
                    icon={<InfoIcon />}
                    onClick={() => {
                      Swal.fire({
                        title:
                          selectBooking.confirmedBy == selectBooking.userId
                            ? i18n.t("confirmed_by_user")
                            : i18n.t("confirmed_an_employee"),
                        text: i18n.t("confirmed_by", {
                          name: selectBooking.confirmedBy?.firstName,
                        }),
                        confirmButtonText: "Okay",
                      })
                    }}
                  />
                ) : null}
              </div>
              {screenSize === "mobile" && (
                <div className="d-flex justify-content-center mt-3">
                  <PrimaryButton
                    className="w-128"
                    onClick={() => setOptionsModalOpen(true)}
                  >
                    Options
                  </PrimaryButton>
                </div>
              )}
            </div>
          ),
          content: (
            <div className={"my-appointment-drawer"}>
              <div className="create-appointment">
                {showCustomerModal ? (
                  <CustomerEdit
                    open={showCustomerModal}
                    setOpen={() => setShowCustomerModal(null)}
                    active={customer}
                    isGym={isGym}
                    handleEdit={() => {}}
                    setAccountBalance={() => {}}
                    handleDrawerClose={handleDrawerClose}
                  />
                ) : null}
              </div>
              <BaseModal
                title="Options"
                open={optionsModalOpen}
                onClose={() => setOptionsModalOpen(false)}
                content={
                  <div className="d-flex flex-column gap-2">
                    {renderButtons()}
                  </div>
                }
              />
              {formModalOpen ? (
                <SendFormModal
                  sendForm={onSendForm}
                  handleClose={() => {
                    setFormModalOpen(false)
                  }}
                  company={selectBooking.companyId}
                  email={email}
                  resend={resendForms}
                />
              ) : null}
              {cancelModalOpen ? (
                <CancelModal
                  handleClose={() => setCancelModalOpen(false)}
                  onSubmit={(data) => {
                    statusButtonClicked(3, data)
                  }}
                />
              ) : null}

              {communicationModalOpen ? (
                <SendCommunicationsModal
                  company={selectBooking.companyId}
                  email={email}
                  handleClose={() => setCommunicationModalOpen(false)}
                  bookingId={selectBooking._id}
                  communicationType={communicationType}
                />
              ) : null}
              <div className="row mx-0">
                <div className="col-md-7 col-lg-7 py-4 pr-sm-0 pr-md-4 pl-0 pr-lg-4">
                  <form
                    className="form row mx-0 px-0"
                    noValidate
                    autoComplete="off"
                  >
                    <div className="row col-12 justify-content-between p-0 px-md-1">
                      <label className="col-12 col-md-4 p-0 mt-2 font-weight-bold">
                        {i18n.t("name")}
                      </label>
                      <TextField
                        className="col-12 col-md-8 name-input"
                        id="standard-basic"
                        variant="outlined"
                        value={getNestedValue(
                          selectBooking,
                          ["userId", "userName"],
                          ""
                        )}
                        {...(isOwner && customer
                          ? {
                              InputProps: {
                                endAdornment: (
                                  <PrimaryButton
                                    onClick={() => setShowCustomerModal(true)}
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      minWidth: "auto",
                                      marginLeft: "1rem",
                                    }}
                                  >
                                    <img
                                      alt="profile"
                                      width="18px"
                                      src={Images.profile_svg_light}
                                    />
                                  </PrimaryButton>
                                ),
                              },
                            }
                          : {})}
                        helperText={`ID: ${selectBooking._id}`}
                        readOnly
                      />
                    </div>
                    {isSalon || isIndependent ? (
                      <div className="row justify-content-between col-lg-12 p-0 px-md-1 ">
                        <label className="col-12 col-md-4 p-0 mt-2 font-weight-bold">
                          {i18n.t("phone")}
                        </label>
                        <TextField
                          className="col-12 col-md-8"
                          id="standard-basic"
                          value={getNestedValue(selectBooking, ["phoneNo"], "")}
                          variant="outlined"
                          readOnly
                        />
                      </div>
                    ) : null}
                    {isSalon || isIndependent ? (
                      <div className="row justify-content-between col-12 p-0 px-md-1">
                        <label className="col-12 col-md-4 p-0 mt-2 font-weight-bold">
                          {i18n.t("container.profile_email")}
                        </label>
                        <TextField
                          className="col-12 col-md-8"
                          id="standard-basic"
                          value={email}
                          variant="outlined"
                          readOnly
                        />
                      </div>
                    ) : null}
                    {service && (
                      <>
                        {isSalon && (
                          <div className="row justify-content-between col-12 p-0 px-md-1">
                            <label className="col-12 col-md-4 p-0 mt-2 font-weight-bold">
                              {i18n.t("container.profile_employe_name")}
                            </label>
                            <TextField
                              className="col-12 col-md-8"
                              id="standard-basic"
                              value={employeeMap[service.employeeId?._id]}
                              variant="outlined"
                              readOnly
                            />
                          </div>
                        )}
                      </>
                    )}
                  </form>
                  {status == 1 ? (
                    <div className="row col-12 p-0">
                      <label className="col-12 col-md-4 p-0 mt-2 font-weight-bold">
                        {i18n.t("adjusted_time")}
                      </label>
                      <div className="row mx-0 align-items-center flex-nowrap p-0">
                        <Button
                          className="adjusted-time-modifier mr-3"
                          onClick={() => {
                            setAdjustedTime(adjustedTime - 5)
                            debounceSetAdjustedTime(adjustedTime - 5)
                          }}
                        >
                          <div>-</div>
                        </Button>
                        <TextField
                          readOnly
                          value={adjustedTime}
                          variant="outlined"
                          className="adjusted-time-field px-0"
                        />
                        <Button
                          className="adjusted-time-modifier ml-3"
                          onClick={() => {
                            setAdjustedTime(adjustedTime + 5)
                            debounceSetAdjustedTime(adjustedTime + 5)
                          }}
                        >
                          <div>+</div>
                        </Button>
                      </div>
                    </div>
                  ) : null}
                  {selectBooking.metadata?.carId ? (
                    <div className="row mx-0 mb-2">
                      <div className="col-4">
                        <TextField
                          readOnly
                          value={selectBooking.metadata?.carId?.make}
                          className="adjusted-time-field "
                          label={i18n.t("make")}
                        />
                      </div>
                      <div className="col-4">
                        <TextField
                          readOnly
                          value={selectBooking.metadata?.carId?.model}
                          className="adjusted-time-field "
                          label={i18n.t("model")}
                        />
                      </div>
                      <div className="col-4">
                        <TextField
                          readOnly
                          value={selectBooking.metadata?.carId?.year}
                          className="adjusted-time-field "
                          label={i18n.t("year")}
                        />
                      </div>
                    </div>
                  ) : null}
                  {displayManageExtras ? (
                    <ManageExtras
                      products={products}
                      services={selectBooking.extras}
                      onProductChange={onProductChange}
                      onProductDelete={onProductDelete}
                      onServiceDelete={onServiceDelete}
                      onAddClick={onAddClick}
                      readonly={status == 3 || readOnly}
                      collectedMap={collectedMap}
                    />
                  ) : null}
                </div>

                {/* <div className="col-lg col-md-1 p-0"></div> */}

                <div className="col-md col-lg px-md-0 py-2 px-0 pl-md-2">
                  <div className="row m-0 justify-content-between account-balance mb-4">
                    <div className="justify-content-start py-2 ml-3">
                      <div className="account-balance-amount">
                        $ {accountBalance.toFixed(2)}
                      </div>
                      <div>{i18n.t("container.account_balance")}</div>
                    </div>
                    <PrimaryButton
                      className="justify-content-end collect-btn px-4 py-2 mr-3"
                      label={i18n.t("collect")}
                      onClick={() => {
                        setCollectCustomerPaymentOpen(true)
                      }}
                    />
                  </div>
                  {collectCustomerPaymentOpen ? (
                    <CustomerCollectPayment
                      open={collectCustomerPaymentOpen}
                      setLoading={setLoading}
                      handleClose={() => {
                        setCollectCustomerPaymentOpen(false)
                      }}
                      onSuccess={(result) => {
                        setCollectCustomerPaymentOpen(false)
                        setCustomer({
                          ...customer,
                          accountBalance:
                            result.companyCustomer?.accountBalance,
                          giftCardBalance:
                            result.companyCustomer?.giftCardBalance,
                        })
                      }}
                      userId={customer?.userId}
                      accountBalance={accountBalance}
                      giftCardBalance={giftCardBalance}
                      customerId={customer?._id}
                    />
                  ) : null}
                  <TransactionDetails
                    bookingId={selectBooking._id}
                    products={products}
                    services={selectBooking.extras}
                    service={getNestedValue(
                      selectBooking,
                      ["services", 0, "serviceId"],
                      null
                    )}
                    serviceAmount={selectBookingAmount}
                    depositRequired={depositRequired}
                    depositAmount={depositAmount}
                    companyTaxes={companyTaxes}
                    toggleDepositRequired={toggleDepositRequired}
                    toggleTaxes={toggleTaxes}
                    tip={parseFloat(tip)}
                    customerId={customer?._id}
                    showInsertPayment={showInsertPayment}
                    paymentCompleteCallback={paymentCompleteCallback}
                    readOnly={readOnly}
                    setData={setData}
                    includeTaxes={selectBooking.includeTaxes}
                    onChangeTip={onChangeTip}
                    total={total}
                    onCompleteStatusUpdate={() => {
                      statusButtonClicked(4)
                    }}
                    setCustomer={(accountBalance, giftCardBalance) => {
                      setCustomer({
                        ...customer,
                        accountBalance,
                        giftCardBalance,
                      })
                    }}
                    discount={selectBooking.discount || 0}
                    accountBalance={accountBalance}
                    giftCardBalance={giftCardBalance}
                  />
                </div>
              </div>
              {screenSize !== "mobile" && (
                <div className="my-3 px-0 buttonsSection">
                  {renderButtons()}
                </div>
              )}
            </div>
          ),
        }}
        onClose={() => handleDrawerClose()}
      />
    </span>
  )
}

const mapStateToProps = (state) => ({
  transactions: state.bookingTransaction.data,
  forms: state.formBuilder.data,
})

const action = { getTransactions, getForms }

export default connect(mapStateToProps, action)(AppointmentModal)
