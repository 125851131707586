const gregorian_fr = {
    name: "gregorian_fr",
    months: [
        ["janvier", "janv"],
        ["février", "févr"],
        ["mars", "mars"],
        ["avril", "avr"],
        ["mai", "mai"],
        ["juin", "juin"],
        ["juillet", "juil"],
        ["août", "août"],
        ["septembre", "sept"],
        ["october", "oct"],
        ["novembre", "nov"],
        ["décembre", "déc"],
    ],
    weekDays: [
        ["samedi", "sam."],
        ["dimanche", "dim."],
        ["lundi", "lun."],
        ["mardi", "mar."],
        ["mercredi", "mer."],
        ["jeudi", "jeu."],
        ["vendredi", "ven."],
    ],
    digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
    meridiems: [
        ["AM", "am"],
        ["PM", "pm"],
    ],
};

export default gregorian_fr;