import React, { useEffect, useState } from "react"
import i18n from "../../../i18n"
import {
  getRequest,
  removeRequest,
} from "../../../redux/actions/Saloon/DiscountCode"
import PrimaryButton from "../../../components/Core/PrimaryButton"
import { DotsLoader } from "../../../components"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import BasicTable from "../../../components/Core/BasicTable"
import EditIcon from "../../../components/Core/BasicTable/Actions/EditIcon"
import DeleteIcon from "../../../components/Core/BasicTable/Actions/DeleteIcon"
import { ErrorHelper, SuccessHelper } from "../../../helpers"
import deleteConfirmation from "../../../helpers/DeleteHelper"

const Table = ({ UpsertPopup, reduxType, title, actionMap }) => {
  const dispatch = useDispatch()
  const [upsertPopupOpen, setUpsertPopupOpen] = useState(false)
  const [active, setActive] = useState(null)
  const loading = useSelector((s) => {
    return s[reduxType].isFetching
  })
  const data = useSelector((s) => s[reduxType].data)

  useEffect(() => {
    dispatch(actionMap.get())
  }, [])

  const onEdit = (val) => {
    setUpsertPopupOpen(true)
    setActive(val)
  }

  useEffect(() => {
    if (!upsertPopupOpen) {
      setActive(null)
    }
  }, [upsertPopupOpen])

  const handleDelete = (id) => {
    dispatch(
      actionMap.remove(
        { id },
        {
          success: () => {
            SuccessHelper.handleSuccess(i18n.t("successfully_deleted"), true)
          },
          failure: () => {
            ErrorHelper.handleErrors(i18n.t("failed_to_delete"), true)
          },
        }
      )
    )
  }

  return (
    <div className="content-container p-4">
      <DotsLoader isloading={loading} />
      <div className="d-flex pb-4 justify-content-between align-items-center flex-wrap gap-2">
        <h1 className="saloon-dashboard-heading">{i18n.t(title)}</h1>
        <PrimaryButton
          className="w-128"
          label={i18n.t("create")}
          onClick={() => setUpsertPopupOpen(true)}
        />
      </div>
      <BasicTable
        rows={data || []}
        maxContentWidth={false}
        schema={[
          {
            header: i18n.t("name"),
            field: "name",
          },
          {
            key: "action",
            header: i18n.t("actions"),
            style: { width: 160 },
            render: (val) => {
              return (
                <>
                  <EditIcon onClick={() => onEdit(val)} />
                  <DeleteIcon
                    onClick={() =>
                      deleteConfirmation(() => handleDelete(val._id))
                    }
                  />
                </>
              )
            },
          },
        ]}
      />
      <UpsertPopup
        open={upsertPopupOpen}
        setOpen={setUpsertPopupOpen}
        active={active}
        title={title}
        actionMap={actionMap}
      />
    </div>
  )
}

export default Table
