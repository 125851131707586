import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import RequestFunds from "../../../components/Saloon/RequestFunds";
import SharedStorage from "../../../helpers/Storage";
export default () => {
  const [userId, setUserId] = useState("");

  const onMount = async () => {
    const saloonData = JSON.parse(await SharedStorage.getItem("saloon"));
    setUserId(saloonData._id);
  };
  useEffect(() => {
    onMount();
  }, []);
  return (
    <div className="content-container p-4">
      <RequestFunds userId={userId} />
    </div>
  );
};
