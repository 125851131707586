import React, { useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import Swal from "sweetalert2"
import BasicTable from "../../../../../../components/Core/BasicTable"
import PrimaryButton from "../../../../../../components/Core/PrimaryButton"
import useCache from "../../../../../../context/LocalCache"
import { ErrorHelper, SuccessHelper } from "../../../../../../helpers"
import i18n from "../../../../../../i18n"
import { dispatchRequest } from "../../../../../../redux/actions/Saloon/CommunicationsMarketingCampaign"

export default function CampaignSummary({ data, setData, getOne }) {
  const dispatch = useDispatch()
  const { getResource } = useCache()
  const customers = getResource((cache) => cache.company.customers)
  const pricingDetails = useSelector(
    (s) => s.communicationsMarketingCampaign.pricingDetails
  )
  const allCustomerMap = useMemo(() => {
    const map = {}
    customers.map((customer) => {
      map[customer._id] = customer
    })
    return map
  }, [customers])
  const activeCampaign = useSelector(
    (s) => s.communicationsMarketingCampaign.activeCampaign
  )
  const {
    aCustomers,
    validByEmail,
    validBySms,
    totalCost,
    totalForEmail,
    totalForSms,
  } = useMemo(() => {
    if (!activeCampaign) {
      return {}
    }
    const { method } = activeCampaign
    const validByEmail = []
    const validBySms = []
    const aCustomers = activeCampaign.customers.map((customerId) => {
      const customer = allCustomerMap[customerId]
      if (customer !== undefined) {
        if (method == "SMS") {
          if (customer.phoneNo) {
            validBySms.push(customer)
          }
        } else if (method == "EMAIL") {
          if (customer.email) {
            validByEmail.push(customer)
          }
        } else if (method == "EMAIL THEN SMS") {
          if (customer.email) {
            validByEmail.push(customer)
          } else if (customer.phoneNo) {
            validBySms.push(customer)
          }
        }
      }
    })
    const totalForEmail = validByEmail.length * pricingDetails.pricePerEmail
    const totalForSms = validBySms.length * pricingDetails.pricePerSms
    const totalCost = Math.round(totalForEmail + totalForSms)
    return {
      aCustomers,
      validByEmail,
      validBySms,
      totalCost,
      totalForEmail,
      totalForSms,
    }
  }, [activeCampaign, data._id, allCustomerMap])

  const onSaveAndStart = () => {
    Swal.fire({
      title: i18n.t("start_campaign?"),
      text: i18n.t("campaign_will_send_now"),
      confirmButtonText: i18n.t("send"),
      cancelButtonText: i18n.t("cancel"),
      showCancelButton: true,
    }).then((e) => {
      const { dismiss, isConfirmed, isDismissed } = e
      if (isConfirmed) {
        dispatch(
          dispatchRequest(
            {
              campaignId: data._id,
            },
            {
              success: () => {
                SuccessHelper.handleSuccess(i18n.t("successfully_sent"), true)
                getOne(data._id)
              },
              failure: () => {
                ErrorHelper.handleErrors(i18n.t("failed_to_send"), true)
              },
            }
          )
        )
      }
    })
  }
  if (!activeCampaign) {
    return null
  }
  return (
    <div>
      <BasicTable
        rows={[
          {
            totalCustomers: customers.length,
            selectedCustomers: aCustomers.length,
            validCustomers: validByEmail.length + validBySms.length,
            toSendBySms: validBySms.length,
            toSendByEmail: validByEmail.length,
          },
        ]}
        schema={[
          {
            header: i18n.t("total_customers"),
            field: "totalCustomers",
          },
          {
            header: i18n.t("valid_customers"),
            field: "validCustomers",
          },
          {
            header: i18n.t("to_send_by_sms"),
            field: "toSendBySms",
          },
          {
            header: i18n.t("to_send_by_email"),
            field: "toSendByEmail",
          },
        ]}
      />
      <BasicTable
        maxContentWidth={false}
        rows={[
          {
            toSendBySms: validBySms.length,
            toSendByEmail: validByEmail.length,
            validCustomers: validByEmail.length + validBySms.length,
            totalCost: `$ ${(totalCost / 100).toFixed(2)}`,
            totalForEmail: `$ ${(totalForEmail / 100).toFixed(2)}`,
            totalForSms: `$ ${(totalForSms / 100).toFixed(2)}`,
          },
        ]}
        schema={[
          {
            header: i18n.t("total_for_sms"),
            field: "totalForSms",
          },
          {
            header: i18n.t("total_for_email"),
            field: "totalForEmail",
          },
          {
            header: i18n.t("total_cost"),
            field: "totalCost",
          },
        ]}
      />
      <div className="row justify-content-end my-3">
        <PrimaryButton
          className="w-128"
          label={i18n.t("start_campaign")}
          onClick={onSaveAndStart}
        />
      </div>
    </div>
  )
}
