import { Button } from "@material-ui/core";
import React from "react";

export function TouchChip({ label, onClick, selected, style = {} }) {
  return (
    <Button
      onClick={onClick}
      style={selected ? {
        backgroundColor: "var(--warning-color)",
        color: "white",
        width: "100%",
        textTransform: "capitalize",
        fontSize: "1.2rem",
        borderRadius: 7,
        ...style,
      } : {
        backgroundColor: "var(--white-color)",
        color: "#AAACAF",
        border: "1px solid #E5EBF1",
        width: "100%",
        textTransform: "capitalize",
        fontSize: "1.2rem",
        borderRadius: 7,
        ...style,
      }}
    >
      <div>{label}</div>
    </Button>
  );
}

export default TouchChip;
