import React, { useState, useEffect, useMemo } from "react"
import { BaseModal } from "../../../components"
import i18n from "../../../i18n"
import CustomerInfo from "./CustomerInfo/CustomerInfo"
import useBooking from "../../../context/Booking"
import CompanyBookingDetails, {
  book as companyBook,
  validate as companyValidate,
} from "./BookingDetails/CompanyBookingDetails"
import EmployeeBookingDetails, {
  book as employeeBook,
  validate as employeeValidate,
} from "./BookingDetails/EmployeeBookingDetails"
import PrimaryButton from "../../Core/PrimaryButton"
import {
  Button,
  InputAdornment,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core"
import "./CreateAppointment.css"
import Select from "../../Core/Select"
import { Add } from "@material-ui/icons"
import CustomerCarAdd from "../../Saloon/Customer/CustomerCars/Support/CustomerCarAdd"
import { useSelector } from "react-redux"
import useCache from "../../../context/LocalCache"
import {
  getBookingById,
  verifyDiscountCode,
  deleteCustomer,
} from "../../../config/simpleApiCalls"
import Swal from "sweetalert2"
import { set } from "../../../redux/actions/ActiveBooking"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { ErrorHelper } from "../../../helpers"
import SharedStorage from "../../../helpers/Storage"
import CustomerCollectPayment from "../../Saloon/Customer/CustomerCollectPayment"
import restoreConfirmation from "../../../helpers/RestoreHelper"
import useScreenSize from "../../../context/ScreenSize"
export function CreateAppointment({ isSalon, companyId }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const { screenSize } = useScreenSize()
  const { getResource } = useCache()
  const {
    addOpen,
    setAddOpen,
    onChange,
    data,
    clearAppointmentDetails,
    functionRole,
    setFunctionRole,
    setLoading,
    setSelectBooking,
    setLockFields,
  } = useBooking()
  const {
    adjustedTime = 0,
    car,
    discountCode = "",
    selectServices,
    enableRecurringBooking,
  } = data
  const viewBooking = async (id) => {
    setAddOpen(false)
    setLoading(true)
    const { data } = await getBookingById({
      id,
      relations: ["extras", "cart"],
    }).finally(() => {
      setLoading(false)
    })
    if (data.success) {
      dispatch(set({ ...data.data, addServiceQuestionPopup: true }))
    }
    history.push(isSalon ? "saloon-my-appointment" : "employee-my-appointment")
  }
  const cars = useSelector((s) => s.companyCustomerCars.data || [])
  const formattedCars = useMemo(() => {
    const items = [
      ...cars.map(({ make, model, year, _id }) => ({
        name: `${make} ${model} ${year}`,
        value: _id,
      })),
    ]
    if (car && car.make) {
      const { make, model, year } = car
      items.push({
        name: `${make} ${model} ${year}`,
        value: 1,
      })
    }
    return items
  }, [cars, car])
  const [formErrors, setFormErrors] = useState({})
  const [addCarOpen, setAddCarOpen] = useState(false)
  const [sendBookingConfirmation, setSendBookingConfirmation] = useState(true)

  const initialize = async () => {
    const selectSalon = JSON.parse(await SharedStorage.getItem("company"))
    onChange({ selectSalon, adjustedTime: 0 })
    setFunctionRole(selectSalon?.function)
  }
  useEffect(() => {
    if (addOpen && isSalon) {
      initialize()
    }
  }, [addOpen])

  const [collectCustomer, setCollectCustomer] = useState(false)
  const [collectCustomerPaymentOpen, setCollectCustomerPaymentOpen] =
    useState(false)
  const [showCustomerCurrentBookings, setShowCustomerCurrentBookings] =
    useState(false)

  let userList = getResource((cache) => {
    if (isSalon) {
      return cache.company.customers
    } else {
      return cache.employee.customers
    }
  })

  const validateAndBook = (dontRefresh) => {
    const validate = isSalon ? companyValidate : employeeValidate
    const book = isSalon ? companyBook : employeeBook
    const formErrors = validate({ ...data, functionRole })
    const createBooking = () => {
      book(
        { ...data, functionRole },
        setLoading,
        dontRefresh ? clearAppointmentDetails : null,
        viewBooking,
        sendBookingConfirmation,
        setShowCustomerCurrentBookings,
        clearAppointmentDetails
      ).finally(() => {
        setSendBookingConfirmation(true)
        setLoading(false)
        setCollectCustomer(null)
      })
    }

    setFormErrors(formErrors)
    if (Object.keys(formErrors).length) {
      setTimeout(() => setFormErrors({}), 8000)
    } else {
      const customer = userList.find(
        (customer) => customer.phoneNo === data.phoneNumber
      )
      if (customer?.isActive === false) {
        restoreConfirmation(
          () =>
            deleteCustomer({ ids: customer._id })
              .then((res) => {
                if (res.data.success) {
                  createBooking()
                } else if (!res.data.success) {
                  ErrorHelper.handleErrors(res.data.msg, true)
                }
              })
              .catch((error) => {
                console.log(error, "errorrrrrrrrrrrrrrrrrrr")
              }),
          i18n.t("this_is_inactive", { name: i18n.t("customer").toLowerCase() })
        )
      } else {
        createBooking()
      }
    }
  }
  const applyDiscountCode = async (discountCode) => {
    let { totalAmount, originalTotal, depositAmount, originalDepositAmount } =
      data
    originalTotal = originalTotal || totalAmount
    originalDepositAmount = originalDepositAmount || depositAmount
    if (discountCode) {
      setLoading(true)
      verifyDiscountCode({
        serviceId: selectServices?._id || selectServices,
        companyId,
        code: discountCode,
      })
        .then(({ data }) => {
          const { data: discount } = data
          if (discount.type == "Percent") {
            totalAmount = Math.roundTo2Decimals(
              totalAmount * (1 - discount.amount / 100)
            )
          } else {
            totalAmount = totalAmount - discount.amount
            totalAmount = totalAmount < 0 ? 0 : totalAmount
          }
          onChange({
            discount,
            totalAmount,
            depositAmount:
              depositAmount > totalAmount ? totalAmount : depositAmount,
            originalDepositAmount,
            originalTotal,
          })
        })
        .catch(() => {
          Swal.fire({
            title: i18n.t("error"),
            text: i18n.t("discount_code_is_not_valid"),
            icon: "error",
            confirmButtonText: "Ok",
          })
          onChange({
            totalAmount: originalTotal,
            depositAmount: originalDepositAmount,
            discount: undefined,
          })
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      onChange({
        totalAmount: originalTotal,
        depositAmount: originalDepositAmount,
        discount: undefined,
      })
    }
  }

  return (
    <>
      <BaseModal
        open={addOpen}
        title={i18n.t("add_booking")}
        onClose={() => {
          setSelectBooking(null)
          setSendBookingConfirmation(true)
          setAddOpen(false)
          setLockFields(false)
          setCollectCustomer(null)
        }}
        showCloseBtn={screenSize !== "mobile" ? true : false}
        style={{
          zIndex: 1302,
        }}
        content={
          addOpen ? (
            <div className="row mx-0 p-0 CreateAppointment">
              <CustomerCarAdd
                open={addCarOpen}
                setOpen={setAddCarOpen}
                handleAdd={(body) => {
                  onChange({ car: { ...body, value: 1 } })
                  setAddCarOpen(false)
                }}
              />
              <div className="col-xs-12 col-md pl-0 CreateAppointmentContainer">
                <CustomerInfo
                  customers={userList}
                  {...{
                    formErrors,
                  }}
                  setShowCustomerCurrentBookings={
                    setShowCustomerCurrentBookings
                  }
                  showCustomerCurrentBookings={showCustomerCurrentBookings}
                  isGym={false}
                  isSalon={isSalon}
                  setCollectCustomer={setCollectCustomer}
                  collectCustomer={collectCustomer}
                  handleDrawerClose={() => setAddOpen(false)}
                />
                {functionRole == "garage" ? (
                  <div className="form-label-group col-12 p-0 mb-3">
                    <Select
                      label={i18n.t("car")}
                      value={car?.value}
                      excludeEmptyValue
                      options={formattedCars}
                      onChange={(e) => {
                        onChange({ car: { value: e.target.value } })
                      }}
                      props={{
                        InputProps: {
                          endAdornment: (
                            <InputAdornment position="end">
                              <Add
                                fontSize="medium"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setAddCarOpen(true)
                                }}
                              />
                            </InputAdornment>
                          ),
                        },
                      }}
                    />
                  </div>
                ) : null}
                <div className="row mx-0 align-items-center flex-nowrap mb-2 col-lg-12 p-0">
                  <div className="col-4 p-0">
                    <label>{i18n.t("adjusted_time")}</label>
                  </div>
                  <div className="col-8 p-0">
                    <Button
                      className="adjusted-time-modifier"
                      onClick={() => {
                        onChange({ adjustedTime: adjustedTime - 5 })
                      }}
                    >
                      <div>-</div>
                    </Button>
                    <TextField
                      readOnly
                      value={adjustedTime}
                      variant="outlined"
                      className="adjusted-time-field px-0 text-center"
                    />
                    <Button
                      className="adjusted-time-modifier"
                      onClick={() => {
                        onChange({ adjustedTime: adjustedTime + 5 })
                      }}
                    >
                      <div>+</div>
                    </Button>
                  </div>
                </div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sendBookingConfirmation}
                      onChange={() => {
                        setSendBookingConfirmation(!sendBookingConfirmation)
                      }}
                    />
                  }
                  label={i18n.t("send_booking_confirmation")}
                />
                {selectServices && (
                  <div className="d-flex align-items-center">
                    <label className="w-50">{i18n.t("discount_code")}</label>
                    <TextField
                      value={discountCode}
                      onChange={(e) => {
                        onChange({ discountCode: e.target.value })
                      }}
                      onBlur={(e) => {
                        onChange({ discountCode: e.target.value })
                        applyDiscountCode(e.target.value)
                      }}
                      variant={"outlined"}
                    />
                  </div>
                )}
              </div>
              <div className="col-xs-12 col-md pr-0 CreateAppointmentContainer">
                {collectCustomer?.accountBalance ||
                collectCustomer?.giftCardBalance ? (
                  <div className="row m-0 justify-content-between account-balance mb-4">
                    <div className="d-flex flex-column">
                      <span>
                        {collectCustomer?.accountBalance
                          ? i18n.t("owing_balance_with_amount", {
                              balance:
                                collectCustomer.accountBalance.toFixed(2),
                            })
                          : null}
                      </span>
                      <span>
                        {collectCustomer?.giftCardBalance
                          ? i18n.t("gift_card_balance_with_amount", {
                              balance:
                                collectCustomer.giftCardBalance.toFixed(2),
                            })
                          : null}
                      </span>
                    </div>
                    {collectCustomer?.accountBalance ? (
                      <PrimaryButton
                        label={i18n.t("collect")}
                        onClick={() => {
                          setCollectCustomerPaymentOpen(true)
                        }}
                      />
                    ) : null}
                  </div>
                ) : null}
                {collectCustomerPaymentOpen ? (
                  <CustomerCollectPayment
                    open={collectCustomerPaymentOpen}
                    handleClose={() => {
                      setCollectCustomerPaymentOpen(false)
                    }}
                    onSuccess={(result) => {
                      setCollectCustomerPaymentOpen(false)
                      setCollectCustomer(result.companyCustomer)
                    }}
                    userId={collectCustomer.userId}
                    accountBalance={collectCustomer.accountBalance}
                    giftCardBalance={collectCustomer.giftCardBalance}
                    customerId={collectCustomer?._id}
                    setLoading={setLoading}
                  />
                ) : null}
                {isSalon ? (
                  <CompanyBookingDetails formErrors={formErrors} />
                ) : (
                  <EmployeeBookingDetails formErrors={formErrors} />
                )}
              </div>
            </div>
          ) : null
        }
        actions={
          <div className="row mr-md-4 mx-0 my-4 p-0 gap-2  justify-content-center justify-content-md-end">
            {screenSize === "mobile" && (
              <PrimaryButton
                label={i18n.t("close")}
                className="mr-1"
                onClick={() => {
                  setSelectBooking(null)
                  setSendBookingConfirmation(true)
                  setAddOpen(false)
                  setLockFields(false)
                  setCollectCustomer(null)
                }}
              />
            )}

            <PrimaryButton
              label={
                enableRecurringBooking
                  ? i18n.t("book")
                  : i18n.t("book_and_collect")
              }
              className="mr-1"
              onClick={() => validateAndBook()}
            />
            <PrimaryButton
              className="book-now rounded-lg px-4"
              label={i18n.t("book_now_and_reset")}
              onClick={() => validateAndBook(true)}
            />
          </div>
        }
      />
    </>
  )
}

export default CreateAppointment
