import React, { useState, useCallback, useMemo, useEffect } from "react";
import ReactDOM from "react-dom";

import PlanFilter from "./PlanFilter";
import PlanCalendar from "./PlanCalendar";
import useActiveMenuType from "../../../context/ActiveMenuType";
import SharedStorage from "../../../helpers/Storage";

const PlanView = ({
  open,
  width,
  slideSideNav,
  onEventClick,
  plans,
  displayAllAppointments,
  start,
  date,
  onDateClick,
  onDateChange = () => { },
  onDismiss = () => { },
  renderSidebar = () => { },
  employees = [],
  isSaloon,
  calendarView = () => { }
}) => {
  const { functionRole } = useActiveMenuType();
  const selectedCalView = useMemo(async () => {
    return JSON.parse(await SharedStorage.getItem('employee'))?.selectedCalView || "day"
  }, [])
  const [calView, setCalView] = useState(() => {
    if (selectedCalView == "day")
      return (isSaloon && functionRole != "garage" ? "resourceTimeGrid" : "timeGridDay")
    else if (selectedCalView == "month")
      return "dayGridMonth"
    else if (selectedCalView == "work week")
      return "timeGridWorkWeek"
    else
      return "timeGridWeek"
  }
  );
  const [calDate, setCalDate] = useState(new Date()); //init current date
  let allPlans = [];
  plans.forEach((plan) => {
    allPlans.push(...plan);
  });

  const handleCalendarView = useCallback((value) => {
    calendarView(value)
    switch (value) {
      case "day":
        setCalView(isSaloon ? "resourceTimeGrid" : "timeGridDay");
        break;
      case "work week":
        setCalView("timeGridWorkWeek");
        break;
      case "week":
        setCalView("timeGridWeek");
        break;
      case "month":
        setCalView("dayGridMonth")
        break;
      default:
        setCalView("timeGridWorkWeek");
        break;
    }

  }, []);
  useEffect(() => {
    setCalDate(date);
  });

  const handleCalendarDate = useCallback((value) => {
    setCalDate(value);
    onDateChange(value);
  }, []);

  return (
    <div
      className="plan-view"
      style={{
        marginLeft: !open ? 0 : width,
      }}
    >
      <PlanFilter
        handleCalendarView={handleCalendarView}
        handleCalendarDate={handleCalendarDate}
        calDate={calDate}
        slideSideNav={slideSideNav}
        displayAllAppointments={displayAllAppointments}
      />
      <div
        onClick={() => slideSideNav(false)}
        className="plan-calendar-wrapper"
      >
        <PlanCalendar
          calView={calView}
          calDate={calDate}
          planData={allPlans}
          onEventClick={onEventClick}
          start={start}
          onDateClick={onDateClick}
          onDismiss={onDismiss}
          employees={employees}
        />
        {renderSidebar && renderSidebar()}
      </div>
    </div>
  );
};

export default PlanView;
