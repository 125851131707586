import React, { useState, useEffect } from "react";
import { DotsLoader } from "../../../components";
import i18n from "../../../i18n";
import SocialMediaForm from "../../../containers/Saloon/LinkSocialMedia/SocialMediaForm";
import PrimaryButton from "../../../components/Core/PrimaryButton/index";
import ErrorHelper from "../../../helpers/ErrorHelper";
import {
  getEmployeeSocialLinks,
  UpdateEmployeeSocialMediaLink,
  createEmployeeSocialMediaLink,
} from "../../../config/simpleApiCalls";
import SuccessHelper from "../../../helpers/SuccessHelper";

export default function EmployeeSocialMedia() {
  const [isLoading, setIsLoading] = useState(false);
  const [socialLinks, setSocialLinks] = useState({
    facebook: undefined,
    instagram: undefined,
    twitter: undefined,
    youtube: undefined,
    snapchat: undefined,
    linkedin: undefined,
  });

  useEffect(async () => {
    getAllSocialMedia();
  }, []);

  const getAllSocialMedia = () => {
    setIsLoading(true);
    getEmployeeSocialLinks()
      .then((res) => {
        if (res.data.success && res.data.data.socialURL.length > 0) {
          const socialMap = {};
          res.data.data.socialURL.map((url) => {
            socialMap[url.socialType] = url.url;
          });
          setSocialLinks({ ...socialLinks, ...socialMap, hasLink: true });
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        ErrorHelper.handleErrors(i18n.t("someting_went_wrong"), true);
        setIsLoading(false);
        console.log(error, "errorrrrrrrrrrrrrrrrrrr");
      });
  };

  const handleChange = (e) => {
    setSocialLinks({
      ...socialLinks,
      [e.target.name]: e.target.value,
    });
  };

  const onUpdateSocialMedia = async () => {
    setIsLoading(true);
    const payload = { socialURL: [] };
    Object.keys(socialLinks).map((key) => {
      if (socialLinks[key] && key !== "hasLink") {
        payload.socialURL.push({
          socialType: key,
          url: socialLinks[key],
        });
      }
    });
    UpdateEmployeeSocialMediaLink(payload)
      .then((res) => {
        if (res.data.success) {
          SuccessHelper.handleSuccess(i18n.t("successfully_updated"), true);
          getAllSocialMedia();
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error, "errorrrrrrrrrrrrrrrrrrr");
        setIsLoading(false);
        ErrorHelper.handleErrors(i18n.t("failed_to_update"), true);
      });
  };

  const onCreateSocialMedia = async () => {
    setIsLoading(true);
    const payload = { socialURL: [] };
    Object.keys(socialLinks).map((key) => {
      if (socialLinks[key] && key !== "hasLink") {
        payload.socialURL.push({
          socialType: key,
          url: socialLinks[key],
        });
      }
    });
    createEmployeeSocialMediaLink(payload)
      .then((res) => {
        if (res.data.success) {
          getAllSocialMedia();
          SuccessHelper.handleSuccess(i18n.t("successfully_added"), true);
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error, "errorrrrrrrrrrrrrrrrrrr");
        ErrorHelper.handleErrors(i18n.t("failed_to_add"), true);

        setIsLoading(false);
      });
  };
  return (
    <>
      <DotsLoader isloading={isLoading} />
      <div className="content-container">
        <div className="row mx-0">
          <div className="">
            <h1 className="saloon-dashboard-heading">
              {i18n.t("container.dashboard_social_media_icons")}
            </h1>
          </div>
          <div className="col-12 py-3 px-0">
            <div
              className="col-12 p-3 bg-white"
              style={{ borderRadius: "1rem" }}
            >
              <SocialMediaForm {...{ handleChange, socialLinks }} />

              <div className="col-12 px-0 px-md-3">
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  {!socialLinks.hasLink && (
                    <PrimaryButton
                      className="w-128"
                      onClick={() => onCreateSocialMedia()}
                      label={i18n.t("container.save_changes")}
                    />
                  )}
                  {socialLinks.hasLink && (
                    <PrimaryButton
                      className="w-128"
                      onClick={() => onUpdateSocialMedia()}
                      label={i18n.t("container.update_changes")}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
