import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import FormGenerator from "./FormGenerator.component";
import {
  getByIdRequest,
  markAsViewedRequest,
  submitFilledFormRequest,
} from "../../../redux/actions/FormAnswer";
import { useSelector } from "react-redux"
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import i18n from "../../../i18n";
import PrimaryButton from "../../Core/PrimaryButton";
import DotsLoader from "../../DotsLoader";
import styled from "styled-components";
import _ from "lodash";
const ButtonWrapper = styled.section`
  @media screen and (min-width: 768px) {
    width: 60% !important;
  }
  @media screen and (min-width: 0px) {
    width: 80%;
  }
  margin: 0 auto;
`;

function FillForm({
  match,
  getByIdRequest,
  formToFill,
  markAsViewedRequest,
  submitFilledFormRequest,
  loading,
  history,
  formId,
  userId,
  getFilledForms = () => { },
  handleClose = () => { },
}) {

  const formToFillByOwner = useSelector(
    (state) => state.formBuilder.active
  )
  const [id, setId] = useState("");
  useEffect(() => {

    if (match) {
      let {
        params: { id },
      } = match;
      setId(id)
      getByIdRequest({ _id: id });
    }
  }, []);

  const [answers, setAnswers] = useState({});
  const [errorMap, setErrorMap] = useState({});
  const [status, setStatus] = useState("");
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    if (formToFillByOwner || formToFill?._id) {
      if (formToFill.status === "Submitted") {
        setStatus("submitted");
        setAnswers(formToFill.answerMap);
      } else {
        if (!userId) markAsViewedRequest({ _id: id });
      }
      setInitialized(true);
    }
  }, [formToFill, formToFillByOwner]);
  const onSubmit = () => {
    const errorMap = {};
    (formToFillByOwner || formToFill).questions.map((question) => {
      if (question.required && !(question._id in answers)) {
        errorMap[question._id] = i18n.t("this_field_is_required");
      }
    });
    setErrorMap(errorMap);
    if (!Object.values(errorMap).length) {
      const query = { _id: id, answerMap: answers }

      if (userId) {
        query.userId = userId
        query.formId = formId
        query.questions = formToFillByOwner.questions || formToFill.questions
      }

      submitFilledFormRequest(
        query,
        {
          success: () => {
            userId ? "" : history.replace("/");
            handleClose()
            getFilledForms()
            SuccessHelper.handleSuccess(i18n.t("form_submitted"), true);
          },
          failure: (e) => {
            ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
          },
        }
      );
    }
  };
  const onChange = (updatedQuestions) => {
    setAnswers(updatedQuestions);
  };
  const createAddress = (val) => {
    const items = [];
    if (val.address) {
      items.push(val.address);
    }
    if (val.city) {
      items.push(val.city);
    }
    if (val.province) {
      items.push(val.province);
    }
    return items.join(", ");
  };

  const questions = formToFillByOwner?.questions || formToFill?.questions;

  const orderedQuestions = _.orderBy(questions, "displayOrder");
  const orderedForm = { ...formToFillByOwner, ...formToFill, questions: orderedQuestions };

  return (
    <div>
      <DotsLoader isloading={loading} />
      {initialized ? (
        <div className="form_filler">
          <div className=" form-fill-top-bar" style={userId ? { position: "static", height: "unset" } : {}}>
            <div className="w-100 text-center">
              <h2 className="form-fill-header">
                {formToFill.formId?.companyId.name || formToFillByOwner.companyId.name}
              </h2>
              <h6>{createAddress(formToFill.formId?.companyId || formToFillByOwner.companyId)}</h6>
              <h6>{formToFill.formId?.companyId.businessEmail || formToFillByOwner.companyId.businessEmail}</h6>
              <h6>{formToFill.formId?.companyId.businessTelephone || formToFillByOwner.companyId.businessTelephone} </h6>
            </div>
          </div>
          <FormGenerator
            formTitle={formToFill.formId?.formTitle || formToFillByOwner.formTitle}
            fieldsToRender={orderedForm.questions.map((question) => ({
              ...question,
              fieldOptions: question.fieldOptions.join("-"),
            }))}
            errorMap={errorMap}
            onChange={onChange}
            readonly={status === "submitted"}
            answers={answers}
          />

          {status != "submitted" && (
            <ButtonWrapper>
              <div className="submitButton">
                <PrimaryButton
                  className="w-128"
                  label={i18n.t("submit")}
                  onClick={onSubmit}
                />
              </div>
            </ButtonWrapper>
          )}
        </div>
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  formToFill: state.formAnswer.active,
  loading: state.formAnswer.isFetching,

});

const action = { getByIdRequest, markAsViewedRequest, submitFilledFormRequest };

export default connect(mapStateToProps, action)(FillForm);
