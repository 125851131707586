import React from "react";

import RadioOption from "./RadioOption.component";
import SelectOption from "./SelectOption.component";
import MultipleChoiceOption from "./MultipleChoiceOption";
const InputOptions = ({
  inputType,
  handleFieldOptions,
  fieldOptionsError,
  fieldOptions,
}) => {
  return (
    // here we have the input types that need options
    // depending on the selected input type by user
    // we ask them to add options or any needed information
    <React.Fragment>
      {inputType === "radio" && (
        <RadioOption
          handleradio={handleFieldOptions}
          fieldOptionsError={fieldOptionsError}
          fieldOptions={fieldOptions}
        />
      )}
      {inputType === "select" && (
        <SelectOption
          handleSelect={handleFieldOptions}
          fieldOptionsError={fieldOptionsError}
          fieldOptions={fieldOptions}
        />
      )}
      {inputType === "multipleChoice" && (
        <MultipleChoiceOption
          handleSelect={handleFieldOptions}
          fieldOptionsError={fieldOptionsError}
          fieldOptions={fieldOptions}
        />
      )}
    </React.Fragment>
  );
};

export default InputOptions;
