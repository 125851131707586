import React, { useEffect, useState } from "react"
import Drawer from "@material-ui/core/Drawer"
import Toolbar from "@material-ui/core/Toolbar"
import { makeStyles } from "@material-ui/core/styles"
import { connect, useSelector } from "react-redux"
import useScreenSize from "../../context/ScreenSize"

export const drawerWidth = 276

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
  mobileSubmenuOpenStatus: {
    width: '0px !important'
  }
}))

export default function AppSidebar({
  children,
  open,
  onSetOpen,
  docked,
  content,
}) {
  const classes = useStyles()
  const [submenuOpen, setSubmenuOpen] = useState(false);

  useEffect(() => {
    document.addEventListener("mobileMenuClose", handleMobileMenu);
    return () => {
      document.removeEventListener("mobileMenuClose", handleMobileMenu);
    }
  }, [open, submenuOpen]);

  useEffect(() => {
    if (open && submenuOpen) {
      setSubmenuOpen(false);
    }
  }, [open]);

  useEffect(() => {
    if (!open && submenuOpen) {
      setSubmenuOpen(true);
    }
  }, [open, submenuOpen]);

  const handleMobileMenu = () => {
    if (open) {
      setSubmenuOpen(!submenuOpen);
    }
  }

  return (
    <>
      <Drawer
        variant={docked ? "permanent" : "temporary"}
        open={open}
        onClose={onSetOpen}
        className="AppSidebar"
        style={{
          width: drawerWidth,
          flexShrink: 0,
        }}
        classes={{
          paper: submenuOpen ? classes.mobileSubmenuOpenStatus : classes.drawerPaper,
        }}
      >
        {children}
      </Drawer>
      <div
        className={
          open && docked ? `${classes.contentShift} is-docked` : classes.content
        }
      >
        {content}
      </div>
    </>
  )
}
