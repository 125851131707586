import { connect } from "react-redux"
import React, { Component } from "react"
import { Translation } from "react-i18next"
import i18n from "../../../i18n"
import { getWaitingList } from "../../../config/simpleApiCalls"
import { DotsLoader } from "../../../components"
import NumberFormat from "react-number-format"
import { getNestedValue } from "../../../util/objectMethods"
import TextField from "@material-ui/core/TextField"
import Sytles from "./sytles"
import { WaitingListHeader } from "./styles"
import PrimaryButton from "../../../components/Core/PrimaryButton"
import SharedStorage from "../../../helpers/Storage"
class WaitingList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: null,
      isloading: false,
      showEditDelete: "",
      searchText: "",
      activePage: 1,
      usersList: [],
      // employeeList: [],
      filterUsersList: [],
      showPopUp: false,
      showNotePopUp: false,
      bookingStauses: [
        { text: "Waiting", value: 1, styles: { color: "#aa43b5" } },
        { text: "Now Serving", value: 3, styles: { color: "#FFE478" } },
        { text: "Cancel", value: 2, styles: { color: "#FF7082" } },
        { text: "Done", value: 4, styles: { color: "#95DA47" } },
      ],
    }
  }

  componentDidMount = async () => {
    this.setState({
      isloading: true,
    })
    getWaitingList({
      companyId: JSON.parse(await SharedStorage.getItem("company"))._id,
    })
      .then(({ data }) => {
        let waitingListEntries = []
        if (data.success) {
          waitingListEntries = data.data
        }
        this.setState({ waitingListEntries })
      })
      .finally(() => {
        this.setState({ isloading: false })
      })
  }

  renderLoader = () => {
    const { isloading } = this.state
    return <DotsLoader isloading={isloading} />
  }

  TemplateOption = () => {
    return (
      <ul
        style={{ marginLeft: "220px" }}
        class="list-group list-group-horizontal"
      >
        <li class="list-group-item li-padding li-radius-left">Day</li>
        <li class="list-group-item li-padding">Month</li>
        <li class="list-group-item li-padding li-radius-right">Year</li>
      </ul>
    )
  }

  onChangeSearchText = (searchText) => {
    this.setState({ searchText })
  }

  renderSearchBar = () => {
    const { searchText } = this.state
    return (
      <WaitingListHeader>
        <span className="saloon-dashboard-heading mb-4">
          {i18n.t("waiting_list")}
        </span>
        <div className="searchInput my-4">
          <label>{i18n.t("search")}</label>
          <div className="mx-3" style={{ width: "400px" }}>
            <TextField
              type="text"
              placeholder={i18n.t(
                "container.services_search_by_name_phone_number"
              )}
              onChange={(text) => this.onChangeSearchText(text.target.value)}
              value={searchText}
              variant="outlined"
            />
          </div>
          <PrimaryButton onClick={() => this.onChangeSearchText("")}>
            {i18n.t("search_reset")}
          </PrimaryButton>
        </div>
      </WaitingListHeader>
    )
  }

  renderTableBody = (waitingList) => {
    return (
      <tbody>
        {waitingList.map((val, index) => {
          return (
            <tr
              key={val._id}
              style={{
                backgroundColor: index % 2 == 0 && "#F4F6F8",
                borderTop: "1px solid #E5EBF1",
                borderBottom:
                  waitingList.length - 1 == index && "1px solid #E5EBF1",
              }}
            >
              <td>
                <div className="d-flex align-items-center">
                  {getNestedValue(val, ["userId", "userName"], "")}
                </div>
              </td>
              <td className="Table_data">
                <div className="d-flex align-items-center mt-1">
                  <span className="appointment-details-employee-name">
                    {" "}
                    <span>
                      <NumberFormat
                        type={"text"}
                        style={Sytles.removeInputStyle}
                        value={getNestedValue(val, ["userId", "phoneNo"], "")}
                        disabled
                        format="### ### ####"
                      />
                      <div className="appointment-details-employee-name">
                        {" "}
                        {val.email || ""}{" "}
                      </div>
                    </span>
                  </span>
                </div>
              </td>
              <td className="Table_data">
                <div className="d-flex align-items-center mt-1">
                  <span className="appointment-details-employee-name service-column">
                    {val.date}
                  </span>
                </div>
              </td>
              <td className="Table_data">
                <div className="d-flex align-items-center mt-1">
                  <span className="appointment-details-employee-name service-column">
                    {val.startTime} - {val.endTime}
                  </span>
                </div>
              </td>
              <td className="Table_data">
                <div className="d-flex align-items-center mt-1">
                  <span className="appointment-details-employee-name service-column">
                    {getNestedValue(val, ["serviceId", "name"], "")}
                  </span>
                </div>
              </td>
              <td className="Table_data">
                <div className="d-flex align-items-center mt-1">
                  <span className="appointment-details-employee-name service-column">
                    {getNestedValue(
                      val,
                      ["employeeId", "userId", "firstName"],
                      ""
                    )}{" "}
                    {getNestedValue(
                      val,
                      ["employeeId", "userId", "lastName"],
                      ""
                    )}
                  </span>
                </div>
              </td>
            </tr>
          )
          // }
        })}
      </tbody>
    )
  }

  renderUserTable = () => {
    const { waitingListEntries = [], searchText } = this.state
    let toRender = waitingListEntries
    if (searchText) {
      toRender = waitingListEntries.filter((val) => {
        if (
          getNestedValue(val.userId, "userName") &&
          (val.userId.userName
            ?.toLowerCase()
            .includes(searchText.toLowerCase()) ||
            val.userId.phoneNo?.includes(searchText))
        )
          return true
        return false
      })
    }
    return (
      <div className="table-responsive">
        <table className="table table-borderless appointment-details-table">
          <thead>
            <tr>
              <th scope="col">
                <Translation>
                  {(t) => t("container.advancedSearchDialog_name")}
                </Translation>
              </th>
              <th scope="col">
                <Translation>
                  {(t) => t("container.employe_phone_number")}
                </Translation>
                /
                <Translation>{(t) => t("container.profile_email")}</Translation>
              </th>
              <th scope="col">
                <Translation>{(t) => t("date")}</Translation>
              </th>
              <th scope="col">
                <Translation>{(t) => t("time")}</Translation>
              </th>
              <th scope="col">
                <Translation>
                  {(t) => t("container.profile_service")}
                </Translation>
              </th>
              <th scope="col">
                <Translation>{(t) => t("container.employee")}</Translation>
              </th>
            </tr>
          </thead>
          {this.renderTableBody(toRender)}
        </table>
      </div>
    )
  }

  render() {
    return (
      <div>
        {this.renderLoader()}
        <div className="content-container p-4">
          {this.renderSearchBar()}
          {this.renderUserTable()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

const action = {}

export default connect(mapStateToProps, action)(WaitingList)
