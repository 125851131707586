import { connect } from "react-redux"
import React, { useMemo, useState } from "react"
import i18n from "../../../i18n"
import BaseModal from "../../BaseModal"
import {
  getRequest as getClassesRequest,
  upsertRequest as upsertClassRequest,
} from "../../../redux/actions/Saloon/Class"
import {
  getRequest as getStudentRequest,
  setGradeRequest as setGrade,
} from "../../../redux/actions/Saloon/Student"
import {
  addClassRequest,
  removeClassRequest,
  addStudentToClassRequest,
  removeStudentFromClassRequest,
} from "../../../redux/actions/Saloon/Semester"

import BasicTable from "../../Core/BasicTable"
import { TextField } from "@material-ui/core"
import PrimaryButton from "../../Core/PrimaryButton"
import { ErrorHelper, SuccessHelper } from "../../../helpers"
import useScreenSize from "../../../context/ScreenSize"
function ProgramUpsert({
  open,
  onClose,
  student,
  class: _class,
  semester,
  setGrade,
}) {
  const [grades, setGrades] = useState([])
  const [studentClass, { _id: attemptId }] = useMemo(() => {
    const studentClass = student.studentClasses.find((element) => {
      return (
        element.classId == _class._id && element.activeSemester == semester._id
      )
    })
    const res = studentClass?.attempts.find(
      (attempt) => attempt.semesterId == semester._id
    )
    setGrades(res?.grades || [])
    return [studentClass, res || {}]
  }, [_class, student, semester])
  const { screenSize } = useScreenSize()
  const handleSave = () => {
    setGrade(
      {
        attemptId: attemptId,
        grades,
        studentId: student._id,
      },
      {
        success: (data) => {
          SuccessHelper.handleSuccess(i18n.t("successfully_updated"), true)
        },
        failure: () => {
          ErrorHelper.handleErrors(i18n.t("failed_to_update"), true)
        },
      }
    )
  }

  return (
    <div>
      {open ? (
        <BaseModal
          open={open}
          title={`${student.name} - ${i18n.t("grading")}`}
          showCloseBtn={screenSize === "mobile" ? false : true}
          containerClassName="half-container"
          content={
            <>
              <BasicTable
                rows={grades}
                maxContentWidth={false}
                schema={[
                  {
                    header: i18n.t("name"),
                    style: { verticalAlign: "middle" },
                    field: "name",
                  },
                  {
                    header: i18n.t("value"),
                    style: { verticalAlign: "middle" },
                    field: "value",
                  },
                  {
                    header: i18n.t("result"),
                    field: "duration",
                    render: (val, index) => {
                      return (
                        <TextField
                          defaultValue={val.result}
                          fullWidth
                          type="number"
                          onBlur={(e) => {
                            const result = parseFloat(e.target.value)
                            if (result < 0 || isNaN(result)) {
                              return
                            }
                            const newGrades = [...grades]
                            newGrades[index] = { ...newGrades[index], result }
                            setGrades(newGrades)
                          }}
                        />
                      )
                    },
                  },
                ]}
              />
              <div className="row mx-0 justify-content-center justify-content-md-end gap-2 my-4">
                {screenSize === "mobile" ? (
                  <PrimaryButton
                    className="w-128"
                    label={i18n.t("close")}
                    onClick={onClose}
                  />
                ) : null}

                <PrimaryButton
                  className="w-128"
                  label={i18n.t("save")}
                  onClick={handleSave}
                />
              </div>
            </>
          }
          onClose={onClose}
        />
      ) : null}
    </div>
  )
}

const mapStateToProps = (state) => ({
  classes: state._class.data,
})

const action = {
  getClassesRequest,
  upsertClassRequest,
  addClassRequest,
  removeClassRequest,
  getStudentRequest,
  addStudentToClassRequest,
  removeStudentFromClassRequest,
  setGrade,
}

export default connect(mapStateToProps, action)(ProgramUpsert)
