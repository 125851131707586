import { put, takeEvery } from "redux-saga/effects"

import * as types from "../../actions/ActionTypes"
import {
  update,
  insert,
  remove,
  set,
  getById,
  removeQuestions,
  updateQuestions,
  insertQuestions,
  requestFailure,
} from "../../actions/Saloon/FormBuilder"

import axios from "axios"
import { form_builder } from "../../../config/WebServices"

function* getSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.get(form_builder, {
      params: payload,
    })
    data = data.data

    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data)
      }
      yield put(set(data.data))
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data)
      }
      yield put(requestFailure(data))
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* getByIdSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.get(`${form_builder}/getById`, {
      params: payload,
    })
    data = data.data

    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data)
      }
      yield put(getById(data.data))
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data)
      }
      yield put(requestFailure(data))
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* insertSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.post(form_builder, payload)
    data = data.data
    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data)
      }
      yield put(insert(data.data))
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data)
      }
      yield put(requestFailure(data))
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* updateSaga(action) {
  const { payload, callbackMap = {}, params } = action
  let data
  try {
    data = yield axios.put(form_builder, payload, {
      params,
    })
    data = data.data
    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data)
      }
      yield put(update(data.data))
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data)
      }
      yield put(requestFailure(data))
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* deleteSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.delete(form_builder, {
      params: payload,
    })
    data = data.data
    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data)
      }
      yield put(remove(data.data))
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data)
      }
      yield put(requestFailure(data))
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* insertQuestionsSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.post(`${form_builder}/addQuestion`, payload)
    data = data.data
    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data)
      }
      yield put(insertQuestions(data.data))
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data)
      }
      yield put(requestFailure(data))
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* updateQuestionsSaga(action) {
  const { payload, params, callbackMap = {} } = action
  let data
  try {
    data = yield axios.post(`${form_builder}/updateQuestion`, payload, {
      params,
    })
    data = data.data
    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        console.log(payload.fieldName, data.status)
        callbackMap.success(data)
      }
      yield put(updateQuestions(data.data))
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data)
      }
      yield put(requestFailure(data))
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* deleteQuestionsSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.post(`${form_builder}/removeQuestion`, payload)
    data = data.data
    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data)
      }
      yield put(removeQuestions(data.data))
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data)
      }
      yield put(requestFailure(data))
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

export default function* root() {
  yield takeEvery(types.FORM_BUILDER.GET_SAGA, getSaga)
  yield takeEvery(types.FORM_BUILDER.GET_BY_ID_SAGA, getByIdSaga)
  yield takeEvery(types.FORM_BUILDER.INSERT_SAGA, insertSaga)
  yield takeEvery(types.FORM_BUILDER.UPDATE_SAGA, updateSaga)
  yield takeEvery(types.FORM_BUILDER.DELETE_SAGA, deleteSaga)
  yield takeEvery(types.FORM_BUILDER.INSERT_QUESTION_SAGA, insertQuestionsSaga)
  yield takeEvery(types.FORM_BUILDER.UPDATE_QUESTION_SAGA, updateQuestionsSaga)
  yield takeEvery(types.FORM_BUILDER.DELETE_QUESTION_SAGA, deleteQuestionsSaga)
}
