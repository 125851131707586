import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import i18n from "../../../i18n"

import {
  getRequest,
  removeRequest,
  updateRequest,
  insertRequest,
  setDefaultRequest,
} from "../../../redux/actions/CompanyCustomerCard"

import CardManagement from "../../Card/CardManagment"
import PrimaryButton from "../../Core/PrimaryButton"
function CustomerCreditCards({
  active = {},
  getRequest,
  removeRequest,
  updateRequest,
  insertRequest,
  defaultCard,
  cards,

  setDefaultRequest,
  setLoading,
}) {
  const [data, setData] = useState([])
  const [showAddModal, setShowAddModal] = useState(false)
  useEffect(() => {
    setLoading(true)
    getRequest(
      { _id: active._id },
      {
        success: () => {
          setLoading(false)
        },
        failure: () => {
          setLoading(false)
        },
      }
    )
  }, [])

  return (
    <>
      <PrimaryButton onClick={() => setShowAddModal(true)} className="w-128">
        {i18n.t("add")}
      </PrimaryButton>
      <CardManagement
        removeDefault={true}
        defaultCard={defaultCard}
        cards={cards}
        getRequest={getRequest}
        removeRequest={removeRequest}
        updateRequest={updateRequest}
        insertRequest={insertRequest}
        setDefaultRequest={setDefaultRequest}
        showAddModal={showAddModal}
        setShowAddModal={(val) => setShowAddModal(val)}
        id={active?._id}
      />
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    cards: state.companyCustomerCard.data,
    defaultCard: state.companyCustomerCard.defaultCard,
  }
}

const action = {
  getRequest,
  removeRequest,
  updateRequest,
  insertRequest,

  setDefaultRequest,
}

export default connect(mapStateToProps, action)(CustomerCreditCards)
