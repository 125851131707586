import React, { createContext, useState, useEffect, useContext } from "react"
import { BaseModal } from "../components"
import i18n from "../i18n"
import moment from "moment"
import TextField from "@material-ui/core/TextField/TextField"
import { getEmployeeDetails, editUser } from "../config/simpleApiCalls"
import * as msal from "@azure/msal-browser"
import SharedStorage from "../helpers/Storage"
import { Images } from "../theme"
import { Sync, SyncDisabled } from "@material-ui/icons"
import useScreenSize from "./ScreenSize"
import PrimaryButton from "../components/Core/PrimaryButton"
const OutlookCalendarContext = createContext({
  outlookevents: null,
  getEvents: () => {},
  disconnectOutlook: () => {},
})

export function OutlookCalendarProvider({ children }) {
  const [outlookEvents, setOutlookEvents] = useState([])
  const [viewOutlookEvent, setViewOutlookEvent] = useState()
  const [isOutlookSignedIn, setIsOutlookSignedIn] = useState(false)
  const [isOutlookChecked, setIsOutlookChecked] = useState(true)
  const [employeeIdForOutlook, setEmployeeIdForOutlook] = useState()
  const [employeeForOutlook, setEmployeeForOutlook] = useState()
  const [minStartTime, setMinStartTime] = useState()
  const [maxStartTime, setMaxStartTime] = useState()
  const SCOPES = ["User.Read", "Calendars.Read"]

  const authClient = new msal.PublicClientApplication({
    auth: {
      clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
      authority: process.env.REACT_APP_AZURE_AUTORITY,
      redirectUri: process.env.REACT_APP_AZURE_REDIRECT_URI,
    },
    cache: {
      cacheLocation: "localStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
  })
  useEffect(() => {
    if (employeeForOutlook) {
      getEmployeeDetails({ user: employeeForOutlook }).then(({ data }) => {
        setEmployeeIdForOutlook(data.data.details._id)
      })
    }
  }, [employeeForOutlook])
  useEffect(() => {
    if (employeeIdForOutlook) {
      getAccessToken()
    }
  }, [employeeIdForOutlook, minStartTime, maxStartTime])

  const getAccessToken = () => {
    const request = {
      scopes: SCOPES,
      account: authClient.getAccountByHomeId(employeeForOutlook?.homeAccountId),
    }
    if (employeeForOutlook?.homeAccountId) {
      authClient
        .acquireTokenSilent(request)
        .then((tokenResponse) => {
          getEvents(tokenResponse.accessToken)
        })
        .catch((error) => {})
    }
  }

  const disconnectOutlook = () => {
    const logoutRequest = {
      account: authClient.getAccountByHomeId(employeeForOutlook?.homeAccountId),
      onRedirectNavigate: (url) => {
        // Return false if you would like to stop navigation after local logout
        return false
      },
    }

    authClient.logoutRedirect(logoutRequest).then(() => {
      setOutlookEvents([])
      setIsOutlookSignedIn(false)
      removeHomeAccountId()
    })
  }
  const removeHomeAccountId = () => {
    editUser({ homeAccountId: null })
    employeeForOutlook.homeAccountId = null
    SharedStorage.setItem("employee", JSON.stringify(employeeForOutlook))
  }

  const signIn = () => {
    authClient
      .loginPopup({
        scopes: SCOPES,
        prompt: "select_account",
      })
      .then((res) => {
        editUser({ homeAccountId: res.account.homeAccountId })
        employeeForOutlook.homeAccountId = res.account.homeAccountId
        SharedStorage.setItem("employee", JSON.stringify(employeeForOutlook))
        getAccessToken()
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const getEvents = (accessToken) => {
    const headers = new Headers()
    const bearer = `Bearer ${accessToken}`

    headers.append("Authorization", bearer)

    const options = {
      method: "GET",
      headers: headers,
    }
    let events = []
    fetch("https://graph.microsoft.com/v1.0/me/calendars", options)
      .then((response) => response.json())
      .then((data) => {
        setIsOutlookSignedIn(true)
        data.value.map((calendar) => {
          if (
            calendar.name != "Birthdays" &&
            calendar.name != "United States holidays"
          ) {
            fetch(
              `https://graph.microsoft.com/v1.0/me/calendars/${calendar.id}/events?$filter=start/dateTime ge '${minStartTime}' and start/dateTime le '${maxStartTime}'`,
              options
            )
              .then((response) => response.json())
              .then((data) => {
                events = [...data.value, ...events]
                if (events.length > 0) {
                  setOutlookEvents(formatEvents(events))
                }
              })
              .catch((error) => console.log(error))
          }
        })
      })
      .catch((error) => console.log(error))
  }

  const formatEvents = (list) => {
    return list.map((item) => ({
      title: item.subject,
      start: moment
        .utc(item.start?.dateTime)
        .local()
        .format("YYYY-MM-DD HH:mm:ss"),
      end: moment.utc(item.end?.dateTime).local().format("YYYY-MM-DD HH:mm:ss"),
      extendedProps: { ...item, type: "outlookCalendar" },
      backgroundColor: "#00A4EF",
      resourceId: employeeIdForOutlook,
    }))
  }

  return (
    <OutlookCalendarContext.Provider
      value={{
        outlookEvents,
        setIsOutlookSignedIn,
        setOutlookEvents,
        isOutlookSignedIn,
        getEvents,
        disconnectOutlook,
        setViewOutlookEvent,
        isOutlookChecked,
        setEmployeeIdForOutlook,
        setIsOutlookChecked,
        signIn,
        setEmployeeForOutlook,
        setMaxStartTime,
        setMinStartTime,
      }}
    >
      <BaseModal
        open={Boolean(viewOutlookEvent)}
        onClose={() => {
          setViewOutlookEvent(null)
        }}
        title={i18n.t("outlook_calendar")}
        content={
          <div className="row p-2">
            <div className={"form-label-group mb-3 col-12"}>
              <TextField
                id="dueDate"
                label={i18n.t("textfield.title")}
                value={viewOutlookEvent?.subject}
                inputProps={{
                  style: { color: "black", borderBottom: "1px solid black" },
                }}
                disabled
              />
            </div>
            <div className={"form-label-group mb-3 col-12"}>
              <TextField
                id="dueDate"
                label={i18n.t("start_time")}
                value={moment(viewOutlookEvent?.start.dateTime).format(
                  "MMMM Do YYYY h:mm a"
                )}
                inputProps={{
                  style: { color: "black", borderBottom: "1px solid black" },
                }}
                disabled
              />
            </div>
            <div className={"form-label-group mb-3 col-12"}>
              <TextField
                id="dueDate"
                label={i18n.t("end_time")}
                value={moment(viewOutlookEvent?.end.dateTime).format(
                  "MMMM Do YYYY h:mm a"
                )}
                inputProps={{
                  style: { color: "black", borderBottom: "1px solid black" },
                }}
                disabled
              />
            </div>
            <div className="row col-12">
              {i18n.t("description")}
              <textarea
                className="pt-1 mt-2 w-100 "
                value={
                  viewOutlookEvent?.bodyPreview
                    ? viewOutlookEvent.bodyPreview
                    : ""
                }
                style={{
                  border: "1px solid black",
                  backgroundColor: "white",
                  color: "black",
                }}
                disabled
              ></textarea>
            </div>
          </div>
        }
      />
      {children}
    </OutlookCalendarContext.Provider>
  )
}

//Hook
function useOutlookCalendar() {
  return useContext(OutlookCalendarContext)
}
function outLookButton() {
  const { signIn, isOutlookSignedIn, disconnectOutlook } = useOutlookCalendar()
  const { screenSize } = useScreenSize()
  return (
    <>
      {screenSize !== "mobile" ? (
        <button
          className=" btn bg-white btn-outline-dark rounded-0 mt-1"
          onClick={() => (isOutlookSignedIn ? disconnectOutlook() : signIn())}
        >
          <div className="d-flex align-items-center">
            <img src={Images.microsoft_logo} />
            <span className="ml-1 mr-1">
              {isOutlookSignedIn ? <SyncDisabled /> : <Sync />}
            </span>
          </div>
        </button>
      ) : (
        <PrimaryButton
          className="w-100"
          onClick={() => (isOutlookSignedIn ? disconnectOutlook() : signIn())}
        >
          <div className="d-flex gap-2 align-items-center">
            <span>{isOutlookSignedIn ? <SyncDisabled /> : <Sync />}</span>
            <div>
              {isOutlookSignedIn
                ? i18n.t("unsync_from_outlook")
                : i18n.t("sync_with_outlook")}
            </div>
          </div>
        </PrimaryButton>
      )}
    </>
  )
}

export default useOutlookCalendar
export { outLookButton }
