// @flow

import { COMPANY_CUSTOMER_SUBSCRIPTION } from "../ActionTypes"

export function getRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: COMPANY_CUSTOMER_SUBSCRIPTION.GET_SAGA,
  }
}

export function get(data) {
  return {
    data,
    type: COMPANY_CUSTOMER_SUBSCRIPTION.GET,
  }
}

export function removeRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: COMPANY_CUSTOMER_SUBSCRIPTION.DELETE_SAGA,
  }
}

export function remove(data) {
  return {
    data,
    type: COMPANY_CUSTOMER_SUBSCRIPTION.DELETE,
  }
}

export function insertRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: COMPANY_CUSTOMER_SUBSCRIPTION.INSERT_SAGA,
  }
}

export function insert(data) {
  return {
    data,
    type: COMPANY_CUSTOMER_SUBSCRIPTION.INSERT,
  }
}

export function updateRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: COMPANY_CUSTOMER_SUBSCRIPTION.UPDATE_SAGA,
  }
}

export function update(data) {
  return {
    data,
    type: COMPANY_CUSTOMER_SUBSCRIPTION.UPDATE,
  }
}

export function collectOverdueRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: COMPANY_CUSTOMER_SUBSCRIPTION.COLLECT_OVERDUE_SAGA,
  }
}

export function collectOverdue(data) {
  return {
    data,
    type: COMPANY_CUSTOMER_SUBSCRIPTION.COLLECT_OVERDUE,
  }
}

export function subscriptionReportRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: COMPANY_CUSTOMER_SUBSCRIPTION.SUBSCRIPTION_REPORT_SAGA,
  }
}

export function subscriptionReport(data) {
  return {
    data,
    type: COMPANY_CUSTOMER_SUBSCRIPTION.SUBSCRIPTION_REPORT,
  }
}

export function requestFailure() {
  return {
    type: COMPANY_CUSTOMER_SUBSCRIPTION.REQUEST_FAILURE,
  }
}
