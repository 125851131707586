import React from "react";
import { connect } from "react-redux";
import { Navbar } from "../../../components";
import { Images } from "../../../theme";
import { Translation } from "react-i18next";
import { ErrorHelper } from "../../../helpers";
import { request as employee_register } from "../../../redux/actions/Employee/Register";
import SharedStorage from "../../../helpers/Storage"
import { default as EmployeeRegisterComponent } from "../../Saloon/EmployeeSection/EmployeeRegister";
class EmployeeRegister extends React.Component {
  constructor(props) {
    super(props);
  }

  handleNavigation = (route) => {
    this.props.history.push(route);
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.employeeRegister) {
      if (
        !nextProps.employeeRegister.failure &&
        !nextProps.employeeRegister.isFetching &&
        !nextProps.employeeRegister.errorMessage &&
        nextProps.employeeRegister.data &&
        nextProps.employeeRegister.data.data &&
        nextProps.employeeRegister.data.data.success &&
        nextProps.employeeRegister.data?.data?.data?.access_token
      ) {
        SharedStorage.setItem(
          "access_token",
          nextProps.employeeRegister.data.data.data.access_token
        )

      } else if (
        (nextProps.employeeRegister.failure &&
          !nextProps.employeeRegister.isFetching &&
          nextProps.employeeRegister.errorMessage) ||
        !nextProps.employeeRegister.data?.data?.success
      ) {
        if (nextProps.employeeRegister.data?.data?.msg) {
          ErrorHelper.handleErrors(
            nextProps.employeeRegister.data?.data?.msg,
            true
          );
        }
      }
    }
  }
  //Footer Social Icons
  renderFooterLogoSection = () => {
    const socialIcon = [
      Images.saloon_footer_facebook,
      Images.saloon_footer_twitter,
      Images.saloon_footer_instagram,
      Images.saloon_footer_youtube,
    ];
    return (
      <div
        className="container-fluid py-4"
        style={{ backgroundColor: "#fff3eb" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-5 col-md-6 px-0 px-md-3 d-flex align-item-center">
              <img src={Images.easy1_logo_800x300} id="footer-logo" />
            </div>
            <div className="col-7 col-md-6 px-0 px-md-3 d-flex justify-content-end align-items-center">
              {socialIcon.map((val, ind) => {
                return (
                  <span key={ind}>
                    <img src={val} className="saloon_footer_icon" />
                  </span>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  //Footer Text
  renderFooterTextSection = () => {
    return (
      <div className="container-fluid" style={{ backgroundColor: "#ff3600" }}>
        <div className="container">
          <div className="row">
            <div className="col-12 py-4">
              <h6 className="h6 mb-0 text-white text-center">
                &copy; <Translation>{(t) => t("copyright")}</Translation>
              </h6>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div style={{ backgroundColor: "#F7F8F7" }}>
        <div className="container-fluid">
          <div className="container">
            <Navbar {...this.props} isBackgroundColor={true} />
          </div>
        </div>
        <div className="container-fluid min-vh-100 " id="main-container">
          <div className="container" >
            <EmployeeRegisterComponent handleNavigation={this.handleNavigation} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  employeeRegister: state.employeeRegister,
});

const action = {
  employee_register,
};

export default connect(mapStateToProps, action)(EmployeeRegister);
