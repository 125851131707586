import React, { Component } from "react"
import { Translation } from "react-i18next"
import i18n from "../../../../i18n"
import { Images } from "../../../../theme"
import { BaseModal, DotsLoader } from "../../../../components"
import ProductForm from "../../../../components/Forms/Product"
import {
  updateMultipleProducts,
  updateProduct,
  deleteProduct,
  getInventoryTransactions,
  createIncreaseTransaction,
  createReduceTransaction,
} from "../../../../config/simpleApiCalls"
import { ErrorHelper, SuccessHelper } from "../../../../helpers"
import DatePicker from "react-datepicker"
import moment from "moment"
import "./styles.css"
import { getNestedValue } from "../../../../util/objectMethods"
import DeleteIcon from "../../../../components/Core/BasicTable/Actions/DeleteIcon"
import EditIcon from "../../../../components/Core/BasicTable/Actions/EditIcon"
import PrimaryButton from "../../../../components/Core/PrimaryButton/index.jsx"
import deleteConfirmation from "../../../../helpers/DeleteHelper"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import DragIndicatorIcon from "@material-ui/icons/DragIndicator"
import TextField from "@material-ui/core/TextField"
import useCache from "../../../../context/LocalCache"
import SecondaryButton from "../../../../components/Core/SecondaryButton/SecondaryButton"
import CounterInput from "../../../../components/CounterInput"
import ToggleSelect from "../../../../components/ToggleSelect"
import SharedStorage from "../../../../helpers/Storage"
import { ScreenSizeContext } from "../../../../context/ScreenSize.jsx"
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const grid = 8

const getItemStyle = (isDragging, draggableStyle) => ({
  // change background colour if dragging
  background: isDragging ? "#ccc" : "white",

  // styles we need to apply on draggables
  ...draggableStyle,
})

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: grid,
  width: 550,
})

class _AllProducts extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // GENERAL
      searchText: "",
      showEditProductCard: false,

      // FOR SERVICES
      products: [],

      // FOR EDIT SERVICE
      categoryList: [],
      serviceName: "",
      serviceId: "",
      price: 0,
      categoryName: "",
      categoryId: "",
      isActive: 1,
      imageUrl: "",
      minimumTime: 0,
      imageFile: "",
      filteredList: [],
      shouldSendTextReminders: false,
      // ERRORS
      serviceNameError: "",
      priceError: "",
      minimumTimeError: "",
      categoryNameError: "",
      textReminderFrequency: 2,
      startDate: Date.now(),
      endDate: Date.now(),
    }
    this.onDragEnd = this.onDragEnd.bind(this)
  }

  componentDidMount = async () => {
    const saloonData = await JSON.parse(await SharedStorage.getItem("saloon"))
    const company = JSON.parse(await SharedStorage.getItem("company"))
    const isGym = company?.function == "gym"
    this.setState({
      isGym,
      timeSlotLength: company?.timeSlotLength,
      companyId: company?._id,
    })
  }

  onDragEnd(result) {
    if (!result.destination) {
      return
    }
    let products = reorder(
      this.props.products,
      result.source.index,
      result.destination.index
    )

    let toUpdate = {}
    const newServiceList = products.map((val, index) => {
      if (index + 1 !== products[index].displayOrder) {
        toUpdate[val._id] = index + 1
      }
      return { ...val, displayOrder: index + 1 }
    })

    this.setState({ isloading: true })
    updateMultipleProducts(toUpdate)
      .then(({ data }) => {
        if (data.success) {
          SuccessHelper.handleSuccess(i18n.t("updated_display_order"), true)
        } else {
          ErrorHelper.handleErrors(i18n.t("someting_went_wrong"), true)
        }
        this.setState({ isloading: false })
      })
      .catch(() => {
        ErrorHelper.handleErrors(i18n.t("someting_went_wrong"), true)
        this.setState({ isloading: false })
      })
  }

  retrieveTransactions = () => {
    const { startDate, endDate, selectedProductId } = this.state
    this.setState({ isloading: true })
    getInventoryTransactions({
      startDate,
      endDate,
      productId: selectedProductId,
    }).then(({ data }) => {
      if (data.success) {
        this.setState({
          transactions: data.data.transactions,
          averageCost: data.data.average_cost ? data.data.average_cost : 0,
        })
      }
      this.setState({ isloading: false })
    })
  }

  handleTransaction = (type) => {
    const { costPerUnit, quantity, selectedProductId, notes } = this.state
    if (!notes) {
      this.setState({ notesError: i18n.t("notes_required") })
      setTimeout(() => {
        this.setState({ notesError: "" })
      }, 6000)
    } else {
      this.setState({ isloading: true })
      if (type == "increase") {
        createIncreaseTransaction({
          cost: costPerUnit,
          quantity,
          productId: selectedProductId,
          notes,
        }).then(({ data }) => {
          if (data.success) {
            SuccessHelper.handleSuccess("Transaction Recorded", true)
            this.setState({
              quantity: 0,
              costPerUnit: 0,
              notes: "",
            })
          } else {
            ErrorHelper.handleErrors(data.data.msg, true)
          }
          this.setState({ isloading: false })
        })
      } else {
        createReduceTransaction({
          quantity,
          productId: selectedProductId,
          notes,
        }).then(({ data }) => {
          if (data.success) {
            SuccessHelper.handleSuccess("Transaction Recorded", true)
            this.setState({
              quantity: 0,
              costPerUnit: 0,
              notes: "",
            })
          } else {
            ErrorHelper.handleErrors(data.msg, true)
          }
          this.setState({ isloading: false })
        })
      }
    }
  }

  handleNavigation = (route) => {
    this.props.history.push(route)
  }

  onChangeSearchText = (text) => {
    const { products } = this.props
    let newList = []
    let filteredList =
      products &&
      products.filter((val, ind) => {
        let name = val.name.toUpperCase()

        if (
          text.toUpperCase().charAt(0) === name.charAt(0) ||
          text.toUpperCase() === name
        ) {
          newList.push(val)
        } else {
          return newList
        }
      })
    this.setState({
      filteredList: newList,
      searchText: text,
      activePage: 1,
    })
  }

  checkValidation = (successCallback) => {
    const { name, description, cost, charge, quantity, reorderTrigger } =
      this.state.values
    if (!name) {
      this.setState({
        formErrors: {
          name: i18n.t("name_required"),
        },
      })
      setTimeout(() => {
        this.setState({ formErrors: {} })
      }, 6000)
    } else if (!description) {
      this.setState({
        formErrors: {
          description: i18n.t("container.description_required"),
        },
      })
      setTimeout(() => {
        this.setState({ formErrors: {} })
      }, 6000)
    } else {
      this.handleSaloonEditProduct(successCallback)
    }
  }

  handleSaloonEditProduct = (successCallback) => {
    this.setState({ isloading: true })
    const { editing } = this.state
    const {
      name,
      description,
      cost,
      charge,
      quantity,
      reorderTrigger,
      taxable,
      allow_backorder,
      image,
      commission,
      barCode,
      category,
    } = this.state.values
    let payload = new FormData()
    payload.append("name", name)
    payload.append("description", description)
    payload.append("charge", charge)
    payload.append("reorderTrigger", reorderTrigger)
    payload.append("_id", editing._id)
    payload.append("taxable", taxable)
    payload.append("allowBackorder", allow_backorder)
    payload.append("barCode", barCode)
    if (image) {
      payload.append("image", image)
    }
    if (commission) {
      payload.append("commission", parseFloat(commission))
    }
    if (category) {
      payload.append("category", category)
    }

    updateProduct(payload)
      .then((res) => {
        if (res.data.success) {
          SuccessHelper.handleSuccess(
            "Your product was successfully updated.",
            true
          )
          successCallback()
          this.setState({
            showEditProductCard: false,
          })
          this.setState({ isloading: false })
        } else if (!res.data.success) {
          ErrorHelper.handleErrors(res.data.msg, true)
          this.setState({ isloading: false })
        }
      })
      .catch((error) => {
        console.log(error, "errorrrrrrrrrrrrrrrrrrr")
      })
  }

  handleDeleteProduct = (id) => {
    this.setState({ isloading: true })
    deleteProduct({ id })
      .then((res) => {
        if (res.data.success) {
          SuccessHelper.handleSuccess(
            "Your product was successfully deleted.",
            true
          )
          this.setState({ isloading: false })
        } else if (!res.data.success) {
          ErrorHelper.handleErrors(res.data.msg, true)
          this.setState({ isloading: false })
        }
      })
      .catch((error) => {
        console.log(error, "errorrrrrrrrrrrrrrrrrrr")
      })
  }

  openTransactionHistory = async (data) => {
    await this.setSelectedValue(1)
    this.setState(
      {
        selectedProductId: data._id,
      },
      () => {
        this.setState({ showTransactionHistory: true })
        this.retrieveTransactions()
      }
    )
  }

  openTransactionCreation = (data) => {
    this.setState(
      {
        originalQuantity: data.originalQuantity,
        selectedProductId: data._id,
        transactionType: this.state.transactionType
          ? this.state.transactionType
          : "increase",
      },
      () => {
        this.setState({ showTransactionCreation: true })
      }
    )
  }

  openEditProductCard = (data) => {
    this.setState(
      {
        editing: data,
        values: {
          name: data.name,
          description: data.description,
          charge: getNestedValue(data, "charge", 0),
          reorderTrigger: getNestedValue(data, "reorderTrigger", 0),
          status: data.status,
          taxable: `${!!getNestedValue(data, "taxable", false)}`,
          imageUrl: getNestedValue(data, "image", 0),
          allow_backorder: `${!!getNestedValue(data, "allowBackorder", false)}`,
          commission: data.commission || 0,
          barCode: data.barCode,
          category: data.category || null,
        },
      },
      () => {
        this.setState({ showEditProductCard: true })
      }
    )
  }

  closeEditServiceCard = () => {
    this.setState({
      showEditProductCard: false,
    })
  }

  renderLoader = () => {
    const { isloading } = this.state
    return <DotsLoader isloading={isloading} />
  }

  renderSaloonDashboardHeading = () => {
    const { screenSize } = this.context
    return (
      <div
        style={
          screenSize === "mobile"
            ? {
                position: "sticky",
                top: "0",
                zIndex: "1",
                backgroundColor: "white",
                paddingTop: "10px",
              }
            : {}
        }
        className="d-flex flex-column gap-2 flex-md-row a align-items-center justify-content-between my-3"
      >
        <h1 className="saloon-dashboard-heading pt-0">
          <Translation>{(t) => t("container.inventory")}</Translation>
        </h1>
        <PrimaryButton
          className="w-128"
          onClick={() => this.props.history.push("saloon-add-inventory")}
        >
          <Translation>{(t) => t("container.add_product")}</Translation>
        </PrimaryButton>
      </div>
    )
  }

  renderCategoryForm = () => {
    const { screenSize } = this.context
    return (
      <div
        style={
          screenSize === "mobile"
            ? {
                position: "sticky",
                top: "78px",
                zIndex: "1",
                backgroundColor: "white",
                paddingBottom: "10px",
              }
            : {}
        }
        className="searchInput my-4"
      >
        <label className="font-weight-bold mr-3">{i18n.t("search")}</label>
        <div className="mx-3" style={{ width: "400px" }}>
          <TextField
            type="text"
            placeholder={i18n.t(
              "container.services_search_by_name_phone_number"
            )}
            onChange={(text) => this.onChangeSearchText(text.target.value)}
            value={this.state.searchText}
            variant="outlined"
          />
        </div>
        <PrimaryButton
          className="w-128"
          onClick={() => this.onChangeSearchText("")}
        >
          {i18n.t("search_reset")}
        </PrimaryButton>
      </div>
    )
  }

  renderTableHead = () => {
    const { isGym } = this.state
    return (
      <thead className="bg-white">
        <tr>
          <th scope="col" className="pl-0">
            <span className="category-table-th-text pl-0"></span>
          </th>
          <th scope="col" className="pl-0">
            <span className="category-table-th-text pl-0">
              <Translation>{(t) => t("name")}</Translation>
            </span>
          </th>
          <th scope="col" className="pl-0">
            <span className="category-table-th-text pl-0">
              <Translation>{(t) => t("description")}</Translation>
            </span>
          </th>
          <th scope="col" className="pl-0">
            <span className="category-table-th-text pl-0">
              <Translation>{(t) => t("category")}</Translation>
            </span>
          </th>
          <th scope="col" className="text-center">
            <span className="category-table-th-text pl-0">
              {i18n.t("sale_price")}
            </span>
          </th>
          <th scope="col" className="text-center">
            <span className="category-table-th-text pl-0">
              {i18n.t("quantity")}
            </span>
          </th>
          <th scope="col" className="text-center">
            <span className="category-table-th-text pl-0">
              {i18n.t("reserved")}
            </span>
          </th>
          <th scope="col" className="text-center">
            <span className="category-table-th-text pl-0">
              {i18n.t("available")}
            </span>
          </th>
          <th scope="col" className="text-center">
            <span className="category-table-th-text pl-0">
              {i18n.t("reorder_trigger")}
            </span>
          </th>
          <th scope="col" style={{ minWidth: "130px" }}>
            <span className="category-table-th-text pl-0">
              {i18n.t("actions")}
            </span>
          </th>
        </tr>
      </thead>
    )
  }

  renderTableBody = () => {
    const { searchText, filteredList, isGym, widths = [] } = this.state
    const { products } = this.props
    let val = products[0]
    return (
      <>
        <DragDropContext onDragEnd={(result) => this.onDragEnd(result)}>
          {val && (
            <>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => {
                  const listToDisplay = searchText ? filteredList : products

                  return (
                    <>
                      <tbody
                        style={{ textAlign: "center" }}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {listToDisplay.map((item, index) => {
                          const val = item
                          return (
                            <Draggable
                              key={item._id}
                              draggableId={item._id}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <>
                                  <tr
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                    key={val._id}
                                  >
                                    {!searchText ? (
                                      <td {...provided.dragHandleProps}>
                                        <DragIndicatorIcon />
                                      </td>
                                    ) : (
                                      <td></td>
                                    )}

                                    <td style={{ width: widths[0] }}>
                                      <div className="d-flex align-items-center">
                                        {val.image && (
                                          <img
                                            className="category-table-pic"
                                            src={val?.image}
                                          />
                                        )}
                                        <span className="category-table-td-text">
                                          {val.name}
                                        </span>
                                      </div>
                                    </td>
                                    <td style={{ width: widths[0] }}>
                                      <div className="d-flex align-items-center">
                                        <span className="category-table-td-text">
                                          {val.description}
                                        </span>
                                      </div>
                                    </td>
                                    <td style={{ width: widths[0] }}>
                                      <div className="d-flex align-items-center">
                                        <span className="category-table-td-text">
                                          {val.category}
                                        </span>
                                      </div>
                                    </td>
                                    <td style={{ width: widths[0] }}>
                                      <div className="d-flex align-items-center justify-content-center">
                                        <span className="category-table-td-text">
                                          ${parseFloat(val.charge).toFixed(2)}
                                        </span>
                                      </div>
                                    </td>
                                    <td style={{ width: widths[0] }}>
                                      <div className="d-flex align-items-center justify-content-center">
                                        <span className="category-table-td-text">
                                          {val?.quantity}
                                        </span>
                                      </div>
                                    </td>
                                    <td style={{ width: widths[0] }}>
                                      <div className="d-flex align-items-center justify-content-center">
                                        <span className="category-table-td-text">
                                          {val?.reserved}
                                        </span>
                                      </div>
                                    </td>
                                    <td style={{ width: widths[0] }}>
                                      <div className="d-flex align-items-center justify-content-center">
                                        <span className="category-table-td-text">
                                          {val?.quantity - val?.reserved}
                                        </span>
                                      </div>
                                    </td>
                                    <td style={{ width: widths[0] }}>
                                      <div className="d-flex align-items-center justify-content-center">
                                        <span className="category-table-td-text">
                                          {val?.reorderTrigger}
                                        </span>
                                      </div>
                                    </td>
                                    <td>
                                      <div style={{ display: "flex" }}>
                                        <PrimaryButton className="w-34 mx-1">
                                          <img
                                            src={
                                              Images.saloon_transaction_table_icon
                                            }
                                            width={16}
                                            onClick={() =>
                                              this.openTransactionHistory(val)
                                            }
                                          />
                                        </PrimaryButton>
                                        <PrimaryButton className="w-34 mx-1">
                                          <img
                                            src={
                                              Images.saloon_transaction_list_icon
                                            }
                                            width={16}
                                            onClick={() =>
                                              this.openTransactionCreation(val)
                                            }
                                          />
                                        </PrimaryButton>
                                        <EditIcon
                                          onClick={() =>
                                            this.openEditProductCard(val)
                                          }
                                        />
                                        <DeleteIcon
                                          onClick={() =>
                                            deleteConfirmation(() =>
                                              this.handleDeleteProduct(val._id)
                                            )
                                          }
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              )}
                            </Draggable>
                          )
                        })}
                        {provided.placeholder}
                      </tbody>
                    </>
                  )
                }}
              </Droppable>
            </>
          )}
        </DragDropContext>
      </>
      // <List
      //   transitionDuration={10}
      //   lockVertically={true}
      //   beforeDrag={({ elements, index }) => {
      //     const cells = Array.from(elements[index].children);
      //     const widths = cells.map(
      //       (cell) => window.getComputedStyle(cell).width
      //     );
      //     this.setState({ widths });
      //   }}
      //   values={searchText ? filteredList : products}
      //   onChange={({ oldIndex, newIndex }) => {
      //     const toUpdate = {};
      //     let newServiceList = arrayMove(products, oldIndex, newIndex);
      //     newServiceList = newServiceList.map((val, index) => {
      //       if (index + 1 !== newServiceList[index].displayOrder) {
      //         toUpdate[val._id] = index + 1;
      //       }
      //       return { ...val, displayOrder: index + 1 };
      //     });
      //     this.setState({ isloading: true });
      //     updateMultipleService(toUpdate)
      //       .then(({ data }) => {
      //         if (data.success) {
      //           SuccessHelper.handleSuccess(
      //             i18n.t("updated_display_order"),
      //             true
      //           );
      //         } else {
      //           ErrorHelper.handleErrors(i18n.t("someting_went_wrong"), true);
      //         }
      //         this.setState({ isloading: false });
      //       })
      //       .catch(() => {
      //         ErrorHelper.handleErrors(i18n.t("someting_went_wrong"), true);
      //         this.setState({ isloading: false });
      //       });
      //     this.setState({ products: newServiceList });
      //   }}
      //   renderList={({ children, props }) => (
      //     <tbody {...props} style={{ textAlign: "center" }}>
      //       {children}
      //     </tbody>
      //   )}
      //   renderItem={({ value, props, isDragged, isSelected }) => {
      //     const val = value;
      //     return (
      //       <tr
      //         key={val._id}
      //         {...props}
      //         style={{
      //           ...props.style,
      //           cursor: isDragged ? "grabbing" : "grab",
      //           backgroundColor: isDragged || isSelected ? "#EEE" : "#FFF",
      //         }}
      //       >
      //         <td style={{ width: widths[0] }}>
      //           <div className="d-flex align-items-center">
      //             {val.image && (
      //               <img className="category-table-pic" src={val?.image} />
      //             )}
      //             <span className="category-table-td-text">{val.name}</span>
      //           </div>
      //         </td>
      //         <td style={{ width: widths[0] }}>
      //           <div className="d-flex align-items-center">
      //             <span className="category-table-td-text">
      //               {val.description}
      //             </span>
      //           </div>
      //         </td>
      //         <td style={{ width: widths[0] }}>
      //           <div className="d-flex align-items-center">
      //             <span className="category-table-td-text">{val.category}</span>
      //           </div>
      //         </td>
      //         <td style={{ width: widths[0] }}>
      //           <div className="d-flex align-items-center justify-content-center">
      //             <span className="category-table-td-text">
      //               ${parseFloat(val.charge).toFixed(2)}
      //             </span>
      //           </div>
      //         </td>
      //         <td style={{ width: widths[0] }}>
      //           <div className="d-flex align-items-center justify-content-center">
      //             <span className="category-table-td-text">
      //               {val?.quantity}
      //             </span>
      //           </div>
      //         </td>
      //         <td style={{ width: widths[0] }}>
      //           <div className="d-flex align-items-center justify-content-center">
      //             <span className="category-table-td-text">
      //               {val?.reserved}
      //             </span>
      //           </div>
      //         </td>
      //         <td style={{ width: widths[0] }}>
      //           <div className="d-flex align-items-center justify-content-center">
      //             <span className="category-table-td-text">
      //               {val?.quantity - val?.reserved}
      //             </span>
      //           </div>
      //         </td>
      //         <td style={{ width: widths[0] }}>
      //           <div className="d-flex align-items-center justify-content-center">
      //             <span className="category-table-td-text">
      //               {val?.reorderTrigger}
      //             </span>
      //           </div>
      //         </td>
      //         <td style={{ minWidth: "150px" }}>
      //           <div className="row">
      //             <img
      //               role="button"
      //               className="category-table-action-icon"
      //               src={Images.saloon_view_transaction_icon}
      //               style={{ backgroundColor: "transparent", width: "2em" }}
      //               onClick={() => this.openTransactionHistory(val)}
      //             />
      //             <img
      //               role="button"
      //               className="category-table-action-icon"
      //               src={Images.saloon_transaction_icon}
      //               style={{ backgroundColor: "transparent", width: "2em" }}
      //               onClick={() => this.openTransactionCreation(val)}
      //             />
      //             <EditIcon onClick={() => this.openEditProductCard(val)} />
      //             <DeleteIcon
      //               onClick={() =>
      //                 deleteConfirmation(() =>
      //                   this.handleDeleteProduct(val._id)
      //                 )
      //               }
      //             />
      //           </div>
      //         </td>
      //       </tr>
      //     );
      //   }}
      // />
    )
  }

  renderTable = () => {
    return (
      <div className="table-responsive">
        <table className="table table-borderless saloon-category-table mb-0">
          {this.renderTableHead()}
          {this.renderTableBody()}
        </table>
      </div>
    )
  }

  renderCategoryCard = () => {
    return (
      <div className="bg-white" style={{ borderRadius: "1rem" }}>
        {this.renderCategoryForm()}
        {this.renderTable()}
      </div>
    )
  }

  renderCloseButton = (key = "showEditProductCard") => {
    return (
      <span
        onClick={() => {
          this.setState({ [key]: false })
        }}
        className="h4 cursor-pointer close-cross"
      >
        &times;
      </span>
    )
  }

  renderTransactionCreation = () => {
    const {
      showTransactionCreation,
      transactions = [],
      transactionType,
      costPerUnit,
      quantity,
      notesError,
      notes,
    } = this.state

    const { screenSize } = this.context
    return (
      <>
        <BaseModal
          open={showTransactionCreation}
          title={i18n.t("new_transaction")}
          containerClassName="half-container"
          showCloseBtn={screenSize !== "mobile" ? true : false}
          {...{
            content: (
              <>
                <div className="d-flex justify-content-center">
                  <div
                    className="col-12 px-0 bg-white"
                    style={{ borderRadius: "1rem" }}
                  >
                    <div className="d-flex align-items-center col-md-12 col-xs-12 px-0 mb-3">
                      <label
                        className="font-weight-bold"
                        style={{ minWidth: "100px" }}
                      >
                        {i18n.t("type")}
                      </label>
                      <ToggleSelect
                        value={transactionType === "increase" ? true : false}
                        onChange={(e) => {
                          this.setState({
                            transactionType: e ? "increase" : "decrease",
                          })
                        }}
                        active="increase"
                        inactive="decrease"
                      />
                    </div>
                    <div className="col-md-12 col-xs-12 px-0 mb-3">
                      <label
                        className="font-weight-bold"
                        style={{ minWidth: "100px" }}
                      >
                        {i18n.t("quantity")}
                      </label>
                      <CounterInput
                        value={isNaN(quantity) ? 0 : quantity}
                        label={i18n.t("quantity")}
                        onChange={(e) => {
                          this.setState({
                            quantity: parseInt(e),
                          })
                        }}
                      />
                    </div>
                    {transactionType === "increase" && (
                      <div className="col-md-12 col-xs-12 px-0 mb-3">
                        <label
                          className="font-weight-bold"
                          style={{ minWidth: "100px" }}
                        >
                          {i18n.t("cost_per_unit")}
                        </label>
                        <CounterInput
                          value={isNaN(costPerUnit) ? 0 : costPerUnit}
                          label={i18n.t("cost_per_unit")}
                          onChange={(e) => {
                            this.setState({
                              costPerUnit: parseInt(e),
                            })
                          }}
                        />
                      </div>
                    )}
                    <div className="col-md-12  col-xs-12 px-0 mb-3">
                      <div className="d-flex flex-column flex-md-row align-items-md-center">
                        <label
                          className="font-weight-bold"
                          style={{ minWidth: "100px" }}
                        >
                          {i18n.t("note")}
                        </label>
                        <TextField
                          label={i18n.t("notes")}
                          placeholder={i18n.t("enter_notes_placeholder")}
                          value={notes}
                          onChange={(e) => {
                            this.setState({ notes: e.target.value })
                          }}
                          error={notesError}
                          helperText={notesError}
                          variant={"outlined"}
                        />
                      </div>
                    </div>
                    <div className="row mx-0 justify-content-center gap-2 justify-content-md-end">
                      {screenSize === "mobile" ? (
                        <PrimaryButton
                          className="w-128 my-3"
                          onClick={() =>
                            this.setState({ showTransactionCreation: false })
                          }
                        >
                          <Translation>{(t) => t("close")}</Translation>
                        </PrimaryButton>
                      ) : null}
                      <PrimaryButton
                        className="w-128 my-3"
                        onClick={() => this.handleTransaction(transactionType)}
                      >
                        <Translation>{(t) => t("save")}</Translation>
                      </PrimaryButton>
                    </div>
                  </div>
                </div>
              </>
            ),
          }}
          onClose={() => this.setState({ showTransactionCreation: false })}
        />
      </>
    )
  }

  renderTransactionHistory = () => {
    const {
      showTransactionHistory,
      transactions = [],
      averageCost = 0,
      openFiltersModal,
    } = this.state
    const { screenSize } = this.context
    return (
      <BaseModal
        open={showTransactionHistory}
        showCloseBtn={screenSize !== "mobile" ? true : false}
        actions={
          screenSize !== "mobile" ? null : (
            <div className="d-flex justify-content-center">
              <PrimaryButton
                className="w-128"
                onClick={() => this.setState({ showTransactionHistory: false })}
              >
                {i18n.t("close")}
              </PrimaryButton>
            </div>
          )
        }
        title={
          <>
            <div>{i18n.t("transactions")}</div>
            {screenSize === "mobile" ? (
              <div className="d-flex justify-content-center mt-2">
                <PrimaryButton
                  className="w-128"
                  onClick={() => this.setState({ openFiltersModal: true })}
                >
                  {i18n.t("filters")}
                </PrimaryButton>
              </div>
            ) : null}
          </>
        }
        onClose={() => {
          this.setState({ showTransactionHistory: false })
        }}
        containerClassName="container overflow-auto"
        {...{
          content: (
            <>
              <BaseModal
                open={openFiltersModal}
                onClose={() => this.setState({ openFiltersModal: false })}
                title={i18n.t("container.advancedSearchDialog_filter")}
                content={<>{this.TemplateOption()}</>}
              />
              {screenSize !== "mobile" ? (
                <div className="py-2">{this.TemplateOption()}</div>
              ) : null}

              <table className="mobile-max-content table table-borderless saloon-category-table mb-0">
                <thead>
                  <th>{i18n.t("created_by")}</th>
                  <th>{i18n.t("date")}</th>
                  <th>{i18n.t("cost")}</th>
                  <th>{i18n.t("quantity")}</th>
                  <th>{i18n.t("notes")}</th>
                </thead>
                <tbody>
                  {transactions.map((transaction) => {
                    return this.renderTransactionRow(transaction)
                  })}
                </tbody>
              </table>
            </>
          ),
        }}
      />
      // <div
      //   className="edit-service-card-wrapper"
      //   style={{ display: showTransactionHistory ? "flex" : "none" }}
      // >
      //   <div className="container">
      //     <div className="col-12 p-3 bg-white" style={{ borderRadius: "1rem" }}>
      //       {this.renderCloseButton("showTransactionHistory")}
      //       <h3 className="pt-2">{i18n.t("transactions")}</h3>

      //     </div>
      //   </div>
      // </div>
    )
  }

  TemplateOption = () => {
    const { startDate, endDate } = this.state
    const dateViews = ["container.month", "container.quarter", "container.year"]

    return (
      <div className="form-box">
        <label style={{ maxWidth: "fit-content" }}>
          {i18n.t("date_range")}
        </label>
        <div className="d-flex mb-3">
          <DatePicker
            onChange={(data) => this.setStartDate(data)}
            dateFormat="MM/dd/yyyy"
            selected={startDate}
            value={startDate}
            customInput={
              <TextField
                style={{ width: "100%" }}
                label="Start Date"
                variant="outlined"
              />
            }
          />
          <DatePicker
            onChange={(data) => this.setEndDate(data)}
            dateFormat="MM/dd/yyyy"
            selected={endDate}
            value={endDate}
            customInput={
              <TextField
                style={{ width: "100%" }}
                label="End Date"
                variant="outlined"
              />
            }
          />
        </div>
        {dateViews.map((view, index) => {
          return (
            <SecondaryButton
              className={`mr-2 mb-3 ${
                this.state.isActive === index + 1 && "secondary-active active"
              }`}
              style={{ fontSize: 12 }}
              onClick={() => {
                this.setSelectedValue(index + 1),
                  this.setState({ isActive: index + 1 })
              }}
            >
              {i18n.t(view)}
            </SecondaryButton>
          )
        })}
        <PrimaryButton
          className="w-98"
          onClick={() => this.retrieveTransactions()}
        >
          {i18n.t("search")}
        </PrimaryButton>
      </div>
    )
  }

  setStartDate = (val) => {
    const { endDate } = this.state
    if (val > endDate) {
      this.setState({
        startDate: endDate,
        endDate: val,
      })
    } else {
      this.setState({
        startDate: val,
      })
    }
  }

  setEndDate = (val) => {
    const { startDate } = this.state
    if (val < startDate) {
      this.setState({
        startDate: val,
        endDate: startDate,
      })
    } else {
      this.setState({
        endDate: val,
      })
    }
  }

  setSelectedValue = (val) => {
    let currentDate = moment(Date.now())
    if (val == 1) {
      const startOfMonth = moment(currentDate).startOf("month")
      const endOfMonth = moment(currentDate).endOf("month")
      this.setState({
        startDate: startOfMonth.toDate(),
        endDate: endOfMonth.toDate(),
      })
    } else if (val == 2) {
      const startOfQuarter = moment(currentDate).startOf("quarter")
      const endOfQuarter = moment(currentDate).endOf("quarter")
      this.setState({
        startDate: startOfQuarter.toDate(),
        endDate: endOfQuarter.toDate(),
      })
    } else if (val == 3) {
      const convertedStart = moment(`01-01-${currentDate.year()}`, "MM-DD-YYYY")
      const convertedEnd = moment(`12-31-${currentDate.year()}`, "MM-DD-YYYY")
      this.setState({
        startDate: convertedStart.toDate(),
        endDate: convertedEnd.toDate(),
      })
    }
  }

  renderTransactionRow = (transaction) => {
    if (transaction.type == "sale") {
      return (
        <tr>
          <td>{getNestedValue(transaction, ["createdBy", "userName"], "")}</td>
          <td>
            {moment(getNestedValue(transaction, ["createdDate"], "")).format(
              "MM-DD-YYYY"
            )}
          </td>
          <td></td>
          <td>-{getNestedValue(transaction, ["quantity"], "")}</td>
          <td>{getNestedValue(transaction, ["notes"], "")}</td>
        </tr>
      )
    } else if (transaction.type == "increase") {
      return (
        <tr>
          <td>{getNestedValue(transaction, ["createdBy", "userName"], "")}</td>
          <td>
            {moment(getNestedValue(transaction, ["createdDate"], "")).format(
              "MM-DD-YYYY"
            )}
          </td>
          <td>${getNestedValue(transaction, ["cost"], "")}</td>
          <td>{getNestedValue(transaction, ["quantity"], "")}</td>
          <td>{getNestedValue(transaction, ["notes"], "")}</td>
        </tr>
      )
    } else if (transaction.type == "decrease") {
      return (
        <tr>
          <td>{getNestedValue(transaction, ["createdBy", "userName"], "")}</td>
          <td>
            {moment(getNestedValue(transaction, ["createdDate"], "")).format(
              "MM-DD-YYYY"
            )}
          </td>
          <td></td>
          <td>-{getNestedValue(transaction, ["quantity"], "")}</td>
          <td>{getNestedValue(transaction, ["notes"], "")}</td>
        </tr>
      )
    }
  }

  renderEditServiceCard = () => {
    const { showEditProductCard, formErrors = {}, values = {} } = this.state
    return (
      <BaseModal
        open={showEditProductCard}
        title={i18n.t("edit_product")}
        onClose={() => this.setState({ showEditProductCard: false })}
        {...{
          content: (
            <>
              <ProductForm
                formErrors={formErrors}
                checkValidation={this.checkValidation}
                setValue={(newValues) =>
                  this.setState({
                    values: { ...this.state.values, ...newValues },
                  })
                }
                values={values}
                isEdit={true}
                renderCloseButton={this.renderCloseButton}
                onClose={() => {
                  this.setState({ showEditProductCard: false })
                }}
              />
            </>
          ),
        }}
      />
    )
  }

  render() {
    return (
      <div>
        {this.renderLoader()}
        {this.renderEditServiceCard()}
        {this.renderTransactionHistory()}
        {this.renderTransactionCreation()}

        <div className="content-container p-4">
          {this.renderSaloonDashboardHeading()}
          {this.renderCategoryCard()}
        </div>
      </div>
    )
  }
}

_AllProducts.contextType = ScreenSizeContext
export default function AllProducts(props) {
  const { getResource } = useCache()
  const products = getResource((cache) => cache.company.products)
  return <_AllProducts {...props} products={products} />
}
