import { TextField } from "@material-ui/core"
import React, { useMemo, useState } from "react"
import { useSelector } from "react-redux"
import BasicTable from "../../../../../../components/Core/BasicTable"
import useCache from "../../../../../../context/LocalCache"
import i18n from "../../../../../../i18n"

export default function CampaignResultCustomers({ data, setData }) {
  const { getResource } = useCache()
  const customers = getResource((cache) => cache.company.customers)
  const activeCampaign = useSelector(
    (s) => s.communicationsMarketingCampaign.activeCampaign
  )

  const customerMap = useMemo(() => {
    const customerMap = {}
    customers.map((customer) => {
      customerMap[customer._id] = customer
    })
    return customerMap
  }, [customers])
  const sentCustomers = useMemo(() => {
    if (!activeCampaign) {
      return []
    }
    const recipients = activeCampaign?.recipients
    return recipients.map((recipient) => {
      return { customer: customerMap[recipient.to], ...recipient }
    })
  }, [activeCampaign, customerMap])
  const [search, setSearch] = useState("")
  const filteredCustomers = useMemo(() => {
    if (!search) return sentCustomers
    return sentCustomers.filter((recipient) => {
      return (
        recipient.customer.name.toLowerCase().includes(search.toLowerCase()) ||
        recipient.customer.phoneNo.includes(search.toLowerCase()) ||
        recipient.phoneNo.includes(search.toLowerCase())
      )
    })
  }, [search, sentCustomers])
  return (
    <>
      <div className="col-12 flex-column flex-md-row px-0 d-flex align-items-md-center mb-3">
        <label className="font-weight-bold mr-3">{i18n.t("search")}</label>
        <TextField
          placeholder={i18n.t("search")}
          onChange={(e) => setSearch(e.target.value)}
          variant="outlined"
        />
      </div>
      <div className="col-12 px-0 CustomerTableContainer">
        <BasicTable
          maxContentWidth={false}
          rows={filteredCustomers}
          schema={[
            {
              header: i18n.t("name"),
              field: "name",
              accessor: (val) => val.customer.name,
            },
            {
              header: i18n.t("sent_to"),
              field: "sent_to",
              accessor: (val) => {
                if (val.communicationType == "SMS") {
                  return val.phoneNo
                }
                return val.email
              },
            },
            {
              header: i18n.t("status"),
              field: "status",
              accessor: (val) => {
                return i18n.t(val.status)
              },
            },
          ]}
        />
      </div>
    </>
  )
}
