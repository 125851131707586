import React, { useCallback, useEffect, useState } from "react"
import i18n from "../../i18n"
import BaseModal from "../BaseModal"
import ThreeDButton from "../Core/3DButton/ThreeDButton"
import CreatePackageInvoice from "./CreatePackageInvoice.component"
import CreateCustomPackageInvoice from "./CreateCustomPackageInvoice.component"
import CreateGiftCardInvoice from "./CreateGiftCardInvoice.component"
import CreateProductsInvoice from "./CreateProductsInvoice"
import "./style.scss"
const CreateInvoice = ({ open, close, companies, companyTaxMap, isSalon }) => {
  const buttonStyle = { height: 50, fontSize: 14 }
  const [type, setType] = useState()
  const [lastInput, setLastInput] = useState()
  const [scannerInput, setScannerInput] = useState("")
  const [scannerComplete, setScannerComplete] = useState(false)
  useEffect(() => {
    setType()
  }, [open])
  const renderType = () => {
    if (type == "package") {
      return (
        <CreatePackageInvoice
          close={close}
          companies={companies}
          companyTaxMap={companyTaxMap}
          scannerInput={scannerComplete ? scannerInput : ""}
          isSalon={isSalon}
        />
      )
    } else if (type == "custom-package") {
      return (
        <CreateCustomPackageInvoice
          close={close}
          companies={companies}
          companyTaxMap={companyTaxMap}
          scannerInput={scannerComplete ? scannerInput : ""}
          isSalon={isSalon}
        />
      )
    } else if (type == "giftCards") {
      return (
        <CreateGiftCardInvoice
          close={close}
          companies={companies}
          companyTaxMap={companyTaxMap}
          scannerInput={scannerComplete ? scannerInput : ""}
          isSalon={isSalon}
        />
      )
    } else {
      return (
        <CreateProductsInvoice
          close={close}
          companies={companies}
          companyTaxMap={companyTaxMap}
          scannerInput={scannerComplete ? scannerInput : ""}
          isSalon={isSalon}
        />
      )
    }
  }
  const keyDownHandle = useCallback(function (e) {
    if (e.ctrlKey || e.key == "Shift") {
      return
    }
    if (e.key == "Enter") {
      setScannerComplete(true)
      setTimeout(() => {
        setScannerComplete(false)
        setLastInput()
        setScannerInput("")
      }, 0)
      return
    }
    const date = new Date()
    if (isNaN(date - lastInput) || date - lastInput < 150) {
      setScannerInput(scannerInput + e.key)
    } else {
      setScannerInput(e.key)
    }
    setLastInput(date)
  })
  useEffect(() => {
    document.addEventListener("keydown", keyDownHandle)
    return () => {
      document.removeEventListener("keydown", keyDownHandle)
    }
  }, [keyDownHandle])

  return (
    <div className="create-invoice">
      <BaseModal
        open={open}
        containerClassName={"half-container"}
        {...{
          title: i18n.t("sale"),
          content: (
            <div>
              {type ? (
                renderType()
              ) : (
                <div
                  className="grid-container"
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(4, 1fr)",
                    gap: "1rem",
                  }}
                >
                  <ThreeDButton
                    label={i18n.t("custom_packages")}
                    onClick={() => {
                      setType("custom-package")
                    }}
                    style={buttonStyle}
                    className="w-100"
                  />
                  <ThreeDButton
                    label={i18n.t("package")}
                    onClick={() => {
                      setType("package")
                    }}
                    style={buttonStyle}
                    className="w-100"
                  />
                  <ThreeDButton
                    label={i18n.t("products")}
                    onClick={() => {
                      setType("products")
                    }}
                    style={buttonStyle}
                    className="w-100"
                  />
                  <ThreeDButton
                    label={i18n.t("gift_card")}
                    onClick={() => {
                      setType("giftCards")
                    }}
                    style={buttonStyle}
                    className="w-100"
                  />
                </div>
              )}
            </div>
          ),
        }}
        onClose={close}
      />
    </div>
  )
}

export default CreateInvoice
