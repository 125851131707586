import { connect } from "react-redux"
import React, { useEffect, useRef, useState } from "react"
import { Button } from "@material-ui/core"
import { TextField } from "@material-ui/core"
import Select from "../../../components/Core/Select"
import DraggableDialog from "../../../components/DraggableDialog/DraggableDialog"

import i18n from "../../../i18n"
import Swal from "sweetalert2"
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator"
import PrimaryButton from "../../../components/Core/PrimaryButton"
import { addTransactionRequest as addTransaction } from "../../../redux/actions/Saloon/ProgramEnrollment"
import moment from "moment"
import DatePicker from "react-datepicker"
import { MenuItem } from "@material-ui/core"
import { BaseModal } from "../../../components"
import _ from "lodash"
import { ErrorHelper, SuccessHelper } from "../../../helpers"
import PaymentForm from "../PaymentForm"
import TransactionDetails from "../TransactionDetails/TransactionDetails"

function CollectPaymentModal({
  open,
  setOpen,
  enrollment,
  addTransaction,
  onSuccess,
  customer,
}) {
  const form = useRef()
  const [formErrors, setFormErrors] = useState("")
  const [data, setData] = useState({})
  const handleClose = () => {
    setData(null)
    setOpen(false)
  }

  const handleChange = (key, value) => {
    data[key] = value
    setData({ ...data })
  }

  useEffect(() => {
    if (enrollment) {
      const {
        amount = 0,
        balanceDue = 0,
        courseId,
        includeTaxes,
        taxes = [],
        transactions,
      } = enrollment
      const program = { ...courseId, price: amount }
      const collected = amount - balanceDue
      let collectedTaxes = transactions.reduce((accum, { taxes }) => {
        return (
          accum +
          (taxes || []).reduce((accum1, tax) => {
            return accum1 + tax.amount
          }, 0)
        )
      }, 0)
      setData({
        ...data,
        program,
        collected: collected + collectedTaxes,
        balanceDue: program.price,
        subtotal: program.price,
        includeTaxes,
        taxDue: taxes.reduce((accum, tax) => accum + tax.amount, 0),
        taxes,
      })
    }
  }, [enrollment])

  const handleSubmit = () => {
    const {
      cash,
      creditDebit,
      giftCard,
      discountAmount: discount,
      taxes,
      includeTaxes,
      note,
      tip,
    } = data
    let hasErrors = false
    let newFormErrors = {}
    if (giftCard > customer?.giftCardBalance) {
      newFormErrors.giftCard = i18n.t("must_be_less_or_equal", {
        amount: customer?.giftCardBalance,
      })
      hasErrors = true
    }
    if (hasErrors) {
      setFormErrors(newFormErrors)
      setTimeout(() => {
        setFormErrors({})
      }, 6000)
    } else {
      const newTransaction =
        cash || creditDebit || discount || giftCard
          ? {
              cash,
              creditDebit,
              giftCard,
              discount,
              taxes: includeTaxes ? taxes : [],
              note,
              tip,
            }
          : null
      if (newTransaction) {
        addTransaction(
          {
            _id: enrollment._id,
            newTransaction,
            includeTaxes,
            userId: customer.userId,
          },
          {
            success: (data) => {
              SuccessHelper.handleSuccess(i18n.t("successfully_added"), true)
              onSuccess(data.data)
              setData(null)
              setOpen(false)
            },
            failure: () => {
              ErrorHelper.handleErrors(i18n.t("failed_to_add"), true)
            },
          }
        )
      }
    }
  }
  if (!open || !data) {
    return null
  }
  return (
    <div>
      <BaseModal
        title={i18n.t("collect_paymnet")}
        containerClassName="modal-container"
        content={
          <ValidatorForm
            onSubmit={handleSubmit}
            ref={form}
            onError={(error) => {
              console.log(error)
            }}
          >
            <div className="row">
              <div className="col-sm-7 pl-0 pr-0 pr-sm-2">
                <PaymentForm
                  handleChange={handleChange}
                  data={data}
                  formErrors={formErrors}
                  giftCardBalance={customer?.giftCardBalance}
                />
              </div>
              {data.program ? (
                <TransactionDetails
                  handleChange={handleChange}
                  data={data}
                  colClass="col-sm-5 pl-0 pl-sm-2 pr-0"
                />
              ) : null}
            </div>
            <div className="col-12 row px-0 my-4 justify-content-end">
              <PrimaryButton
                className="w-128"
                props={{
                  type: "submit",
                }}
                label={data._id ? i18n.t("save") : i18n.t("create")}
              />
            </div>
          </ValidatorForm>
        }
        onClose={handleClose}
        open={open}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({})

const action = { addTransaction }

export default connect(mapStateToProps, action)(CollectPaymentModal)
