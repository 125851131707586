import React, { useState } from "react"
import { connect } from "react-redux"
import BalanceCards from "./BalanceCards.component"
import i18n from "../../../../i18n"
import { Translation } from "react-i18next"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import { getNestedValue } from "../../../../util/objectMethods"
import PrimaryButton from "../../../../components/Core/PrimaryButton/index"
import SecondaryButton from "../../../Core/SecondaryButton/SecondaryButton"
import useScreenSize from "../../../../context/ScreenSize"
import BaseModal from "../../../BaseModal"
import { Button } from "@material-ui/core"
const statuses = [
  { value: undefined, label: i18n.t("all") },
  { value: "Approved", label: i18n.t("Approved") },
  { value: "Rejected", label: i18n.t("Rejected") },
  { value: "Requested", label: i18n.t("Requested") },
]

function Header({
  setStatus,
  setEmployee,
  selectedEmployee,
  employees = [],
  totalPending = 0,
  totalAvailable = 0,
  totalPendingFromEmployee = 0,
  totalAvailableFromEmployee = 0,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [active, setActive] = useState(0)
  const { screenSize } = useScreenSize()
  const [openFilterModal, setOpenFilterModal] = useState(false)
  return (
    <>
      <div
        style={screenSize === "mobile" ? { marginTop: "140px" } : {}}
        className="col-12 removePadding"
      >
        <div
          style={
            screenSize === "mobile"
              ? {
                  flexDirection: "column",
                  position: "fixed",
                  width: "100%",
                  top: "0",
                  left: "0",
                  paddingBottom: "20px",
                  backgroundColor: "white",
                  zIndex: 5,
                  borderBottom: "1px solid #f2f2f2",
                }
              : {}
          }
          className="row mx-0 justify-content-between"
        >
          <h1 className="saloon-dashboard-heading pb-5">
            <Translation>{(t) => t("container.request_funds")}</Translation>
          </h1>
          {screenSize === "mobile" && (
            <div className="d-flex justify-content-center">
              <PrimaryButton
                onClick={(e) => setOpenFilterModal(true)}
                className="w-128"
              >
                {i18n.t("container.advancedSearchDialog_filter")}
              </PrimaryButton>
            </div>
          )}
        </div>
        <BalanceCards
          pending={totalPending}
          available={totalAvailable}
          pendingColor="#f9902f"
          availableColor="#829f61"
          type="toEmployee"
        />
        <BalanceCards
          pending={totalPendingFromEmployee}
          available={totalAvailableFromEmployee}
          pendingColor="#f9902f"
          availableColor="#829f61"
          type="ByEmployee"
        />
        {screenSize !== "mobile" && (
          <div className="filter-employee my-4">
            <h5 className="mb-0">
              {i18n.t("container.advancedSearchDialog_filter")}
            </h5>
            <Select
              onChange={(e) => setEmployee(e.target.value)}
              value={selectedEmployee}
              variant="outlined"
              style={{ width: 120 }}
            >
              {employees.map((employee) => (
                <MenuItem key={employee._id} value={employee._id}>
                  {getNestedValue(employee, [
                    "employeeId",
                    "userId",
                    "firstName",
                  ])}
                </MenuItem>
              ))}
            </Select>
            <PrimaryButton onClick={() => setEmployee("")} className="w-128">
              {i18n.t("filterReset")}
            </PrimaryButton>
            {statuses.map((status, index) => (
              <SecondaryButton
                className={`w-auto ${active === index && "active"}`}
                onClick={() => {
                  setAnchorEl(undefined)
                  setStatus(status.value)
                  setActive(index)
                }}
              >
                {status.label}
              </SecondaryButton>
            ))}
            <PrimaryButton
              onClick={() => {
                setStatus("")
                setActive(0)
              }}
              className="w-128"
            >
              {i18n.t("statusReset")}
            </PrimaryButton>
          </div>
        )}
        <BaseModal
          open={openFilterModal}
          title={i18n.t("container.advancedSearchDialog_filter")}
          showCloseBtn={false}
          content={
            <>
              <div className="row justify-content-between">
                <h5 className="mb-0">{i18n.t("employees")}</h5>
                <Button
                  onClick={() => setEmployee("")}
                  className="font-weight-bold text-capitalize"
                  style={{ color: "#F6943B" }}
                >
                  {i18n.t("filterReset")}
                </Button>
              </div>
              <Select
                onChange={(e) => setEmployee(e.target.value)}
                fullWidth
                value={selectedEmployee}
                variant="outlined"
              >
                {employees.map((employee) => (
                  <MenuItem key={employee._id} value={employee._id}>
                    {getNestedValue(employee, [
                      "employeeId",
                      "userId",
                      "firstName",
                    ])}
                  </MenuItem>
                ))}
              </Select>
              <div className="mt-4 row justify-content-between">
                <h5 className="mb-0">{i18n.t("status")}</h5>
                <Button
                  onClick={() => {
                    setActive(0)
                    setStatus("")
                  }}
                  className="font-weight-bold text-capitalize"
                  style={{ color: "#F6943B" }}
                >
                  {i18n.t("filterReset")}
                </Button>
              </div>
              <Select
                onChange={(e) => {
                  setStatus(statuses[e.target.value]?.value)
                  setActive(e.target.value)
                }}
                fullWidth
                value={active}
                variant="outlined"
              >
                {statuses.map((status, index) => (
                  <MenuItem key={index} value={index}>
                    {status.label}
                  </MenuItem>
                ))}
              </Select>
              <div className="mt-4 row justify-content-center">
                <PrimaryButton
                  onClick={() => {
                    setOpenFilterModal(false)
                  }}
                  className="w-128"
                >
                  {i18n.t("close")}
                </PrimaryButton>
              </div>
            </>
          }
        />
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({})

const action = {}

export default connect(mapStateToProps, action)(Header)
