// @flow

import { VENDOR } from "../ActionTypes"

export function getRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: VENDOR.GET_SAGA,
  }
}

export function get(data) {
  return {
    data,
    type: VENDOR.GET,
  }
}

export function removeRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: VENDOR.DELETE_SAGA,
  }
}

export function remove(data) {
  return {
    data,
    type: VENDOR.DELETE,
  }
}

export function upsertRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: VENDOR.UPSERT_SAGA,
  }
}

export function upsert(data) {
  return {
    data,
    type: VENDOR.UPSERT,
  }
}

export function setActive(data) {
  return {
    data,
    type: VENDOR.SET_ACTIVE,
  }
}

export function requestFailure() {
  return {
    type: VENDOR.REQUEST_FAILURE,
  }
}
