import React, { useEffect, useState } from "react"
import i18n from "../../../i18n"
import {
  getRequest,
  removeRequest,
} from "../../../redux/actions/Saloon/Promotion"
import PrimaryButton from "../../../components/Core/PrimaryButton"
import { DotsLoader } from "../../../components"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import PromotionUpsertPopup from "./Components/PromotionUpsertPopup"
import BasicTable from "../../../components/Core/BasicTable"
import moment from "moment"
import EditIcon from "../../../components/Core/BasicTable/Actions/EditIcon"
import DeleteIcon from "../../../components/Core/BasicTable/Actions/DeleteIcon"
import { ErrorHelper, SuccessHelper } from "../../../helpers"
import deleteConfirmation from "../../../helpers/DeleteHelper"
import { useMemo } from "react"
import useScreenSize from "../../../context/ScreenSize"
const Promotions = () => {
  const dispatch = useDispatch()
  const [upsertPopupOpen, setUpsertPopupOpen] = useState(false)
  const [active, setActive] = useState(null)
  const loading = useSelector((s) => s.promotion.isFetching)
  const campaigns = useSelector((s) => s.promotion.data)
  const company = useSelector((s) => s.user.data.company)
  const parsedCompany = useMemo(() => {
    return JSON.parse(company)
  }, [company])
  const { screenSize } = useScreenSize()
  useEffect(() => {
    dispatch(getRequest())
  }, [])

  const onEdit = (val) => {
    setUpsertPopupOpen(true)
    setActive(val)
  }

  useEffect(() => {
    if (!upsertPopupOpen) {
      setActive(null)
    }
  }, [upsertPopupOpen])

  const handleDelete = (id) => {
    dispatch(
      removeRequest(
        { id },
        {
          success: () => {
            SuccessHelper.handleSuccess(i18n.t("successfully_deleted"), true)
          },
          failure: () => {
            ErrorHelper.handleErrors(i18n.t("failed_to_delete"), true)
          },
        }
      )
    )
  }

  return (
    <div className="content-container p-4">
      <DotsLoader isloading={loading} />
      <div
        style={
          screenSize == "mobile"
            ? {
                position: "sticky",
                top: 0,
                zIndex: 1,
                backgroundColor: "white",
                paddingBottom: "1rem",
              }
            : {}
        }
        className="d-flex flex-column gap-2 flex-md-row justify-content-between align-items-center mb-3"
      >
        <span className="saloon-dashboard-heading">{i18n.t("promotions")}</span>
        <PrimaryButton
          className="w-128"
          label={i18n.t("create")}
          onClick={() => setUpsertPopupOpen(true)}
        />
      </div>
      <div className="table-responsive">
        <BasicTable
          rows={campaigns || []}
          schema={[
            {
              header: i18n.t("name"),
              field: "name",
            },
            {
              header: i18n.t("service"),
              field: "serviceId",
              accessor: ({ serviceId }) => {
                return serviceId?.name
              },
            },
            {
              header: i18n.t("type"),
              field: "type",
            },
            {
              header: i18n.t("amount"),
              field: "amount",
              accessor: ({ type, amount }) => {
                if (type == "Percent") {
                  return `${amount}%`
                }
                return `$${amount}`
              },
            },
            {
              header: "URL",
              field: "url",
              accessor: ({ shortenedId }) => {
                return `https://book.easy1booking.com/${parsedCompany.shortenedId}/promo/${shortenedId}`
              },
            },
            {
              header: i18n.t("created_on"),
              field: "createdDate",
              accessor: ({ createdDate }) => {
                return moment(createdDate).format("MM-DD-YYYY HH:mm")
              },
            },
            {
              key: "action",
              header: i18n.t("actions"),
              style: { width: 160 },
              render: (val) => {
                return (
                  <>
                    <EditIcon onClick={() => onEdit(val)} />
                    <DeleteIcon
                      onClick={() =>
                        deleteConfirmation(() => handleDelete(val._id))
                      }
                    />
                  </>
                )
              },
            },
          ]}
        />
      </div>
      <PromotionUpsertPopup
        open={upsertPopupOpen}
        setOpen={setUpsertPopupOpen}
        active={active}
      />
    </div>
  )
}

export default Promotions
