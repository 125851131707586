import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import XHR from "i18next-xhr-backend"
import { initReactI18next } from "react-i18next"
import translationEng from "../src/locales/en/translation.json"
import translationFr from "../src/locales/fr/translation.json"


// the translations
const resources = {
  en : {
    translation:translationEng
  },
  fr : {
    translation :translationFr
  },
  ns: ["translation"],
  defaultNS : "translation",

  keySeparator: true, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ","
  },
  
  react: {
    wait: true
  }
}

const options = {
  order: ["querystring", "navigator"],
  lookupQuerystring: "lng"
}

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug : true,
    detection: options,
    lng : "en",
    fallbackLng :"en",
    keySeparator : false,
    // interpolation: {
    //     escapeValue: false // react already safes from xss
    //   },
    // we init with resources
    resources,
    react: {
      useSuspense: false
    }
  })

export default i18n