import { put, takeEvery } from "redux-saga/effects"

import * as types from "../actions/ActionTypes"
import {
  insert,
  get,
  remove,
  requestFailure,
} from "../actions/ReoccuringCompanyUnavailabilties"

import axios from "axios"
import { reoccuring_company_unavailabilities } from "../../config/WebServices"

function* getSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.get(reoccuring_company_unavailabilities, {
      params: payload,
    })
    data = data.data

    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data)
      }
      yield put(get(data.data))
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data)
      }
      yield put(requestFailure(data))
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* insertSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.post(reoccuring_company_unavailabilities, payload)
    data = data.data
    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data)
      }
      yield put(insert(data.data))
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data)
      }
      yield put(requestFailure(data))
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* removeSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.delete(reoccuring_company_unavailabilities, {
      params: payload,
    })
    data = data.data
    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data)
      }
      yield put(remove(data.data))
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data)
      }
      yield put(requestFailure(data))
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

export default function* root() {
  yield takeEvery(types.REOCCURING_COMPANY_UNAVAILABILITIES.GET_SAGA, getSaga)
  yield takeEvery(
    types.REOCCURING_COMPANY_UNAVAILABILITIES.INSERT_SAGA,
    insertSaga
  )
  yield takeEvery(
    types.REOCCURING_COMPANY_UNAVAILABILITIES.DELETE_SAGA,
    removeSaga
  )
}
