import React, { Component } from "react"
import Pagination from "react-js-pagination"
import i18n from "../../../i18n"
import {
  deleteCustomer,
  getCompanyCustomers,
  getCustomerById,
  removeFromBlackList,
  addToBlackList,
} from "../../../config/simpleApiCalls"
import { Images } from "../../../theme"
import { DotsLoader } from "../../../components"
import NumberFormat from "react-number-format"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Swal from "sweetalert2"
import { ErrorHelper, SuccessHelper } from "../../../helpers"
import restoreConfirmation from "../../../helpers/RestoreHelper"
import "./styles.css"
import PrimaryButton from "../../../components/Core/PrimaryButton"
import CustomerEdit from "../../../components/Saloon/Customer/CustomerEdit"
import DeleteIcon from "../../../components/Core/BasicTable/Actions/DeleteIcon"
import ReuseIcon from "../../../components/Core/BasicTable/Actions/ReuseIcon"
import EditIcon from "../../../components/Core/BasicTable/Actions/EditIcon"
import deleteConfirmation from "../../../helpers/DeleteHelper"
import SharedStorage from "../../../helpers/Storage"
import TextField from "@material-ui/core/TextField"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"
import AdvancedCustomerFilters from "./components/AdvancedCustomerFilters"
import Tabs from "../../../components/Core/Tabs/Tabs"
export default class AllCustomers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: null,
      isloading: false,
      sidebarOpen: false,
      showEditDelete: "",
      searchText: "",
      activePage: 1,
      usersList: null,
      selectedEmployeeId: null,
      filterUsersList: undefined,
      tabIndex: 0,
      formErrors: {},
      bookingStauses: [
        { text: "Waiting", value: 1, styles: { color: "#aa43b5" } },
        { text: "Now Serving", value: 3, styles: { color: "#FFE478" } },
        { text: "Cancel", value: 2, styles: { color: "#FF7082" } },
        { text: "Done", value: 4, styles: { color: "#95DA47" } },
      ],
    }
  }

  componentDidMount = async () => {
    const saloonData = await JSON.parse(await SharedStorage.getItem("saloon"))
    if (saloonData) {
      this.setState(
        {
          companyId: saloonData.companyId,
          isloading: true,
        },
        () => {
          this.getCompanyCustomers()
        }
      )
    }
  }

  getCompanyCustomers = () => {
    const { companyId } = this.state
    getCompanyCustomers({ companyId, relations: ["transactions"] }).then(
      (res) => {
        this.setState({
          usersList: res,
          activeUsers: res.filter((user) => user.isActive),
          archivedUsers: res.filter((user) => !user.isActive),
          isloading: false,
        })
      }
    )
  }

  handleSaloonDeleteCustomer = (id, ind, type) => {
    const { activeUsers, archivedUsers } = this.state
    this.setState({ isloading: true })
    deleteCustomer({ ids: id })
      .then((res) => {
        if (res.data.success) {
          SuccessHelper.handleSuccess(
            type === "delete"
              ? "Your service is successfully deleted."
              : "Customer restored.",
            true
          )

          type === "delete"
            ? archivedUsers.push(res.data.data)
            : activeUsers.push(res.data.data)
          type === "delete"
            ? activeUsers.splice(ind, 1)
            : archivedUsers.splice(ind, 1)
          this.setState({ isloading: false, archivedUsers, activeUsers })
        } else if (!res.data.success) {
          ErrorHelper.handleErrors(res.data.msg, true)
          this.setState({ isloading: false })
        }
      })
      .catch((error) => {
        this.setState({ isloading: false })
        console.log(error, "errorrrrrrrrrrrrrrrrrrr")
      })
  }

  onChangeSearchText = (text) => {
    const { tabIndex, archivedUsers, activeUsers } = this.state
    let searchText = text.toUpperCase()
    const users = tabIndex === 0 ? activeUsers : archivedUsers

    let filterUsersList =
      users &&
      (searchText
        ? users.filter((val) => {
            let username = (val.name || "").toUpperCase()
            let email = (val.email || "").toUpperCase()
            let phoneNo = `${val.phoneNo}`
            let customerNumber = (val.customerNumber || "").toUpperCase()
            return (
              username.indexOf(searchText) > -1 ||
              phoneNo.indexOf(searchText) > -1 ||
              email.indexOf(searchText) > -1 ||
              customerNumber.indexOf(searchText) > -1
            )
          })
        : undefined)
    this.setState({
      filterUsersList,
      searchText: text,
      activePage: 1,
    })
  }

  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber })
  }

  renderLoader = () => {
    const { isloading } = this.state
    return <DotsLoader isloading={isloading} />
  }

  renderHeader = () => {
    return (
      <div className="row align-items-center col-12 px-0">
        <span className="saloon-dashboard-heading col-sm-8 px-0 mb-4">
          {i18n.t("container.customers_list")}
        </span>
        <div className="row justify-content-sm-end col-sm-4 px-0 mb-4">
          <PrimaryButton
            onClick={() => this.props.history.push("saloon-import-customers")}
            label={
              <div className="row mx-0 align-items-center px-2">
                <FontAwesomeIcon
                  className="mr-1"
                  icon={["far", "file-excel"]}
                />
                {i18n.t("ImportFromExcel")}
              </div>
            }
          />
          <PrimaryButton
            className="w-128 ml-3"
            onClick={() => this.props.history.push("saloon-add-customer")}
            label={i18n.t("create")}
          />
        </div>
      </div>
    )
  }

  renderSearch = () => {
    const { searchText, onlyBlackList } = this.state
    return (
      <div className="col-12 px-0">
        <div className="row align-items-center gap-2">
          <div className="row align-items-center col-sm-4 mb-3 px-0">
            <label className="font-weight-bold mr-3">{i18n.t("search")}</label>
            <TextField
              type="text"
              placeholder="Search by name, phone and email"
              className="col-10 px-0"
              onChange={(text) => this.onChangeSearchText(text.target.value)}
              value={searchText}
              variant="outlined"
            />
          </div>
          <PrimaryButton
            className="w-128 mb-3"
            onClick={() => this.setState({ advancedFiltersOpen: true })}
            label={i18n.t("container.advancedSearchDialog_advanced_search")}
          />
        </div>
      </div>
    )
  }

  removeFromBlackList = (val) => {
    Swal.fire({
      text: i18n.t("are_you_sure"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ff3600",
      cancelButtonColor: "#354e68",
      confirmButtonText: i18n.t("yes"),
    }).then((result) => {
      if (result.value) {
        this.setState({ isloading: true })
        removeFromBlackList({ _id: val._id })
          .then((res) => {
            if (res.data.success) {
              SuccessHelper.handleSuccess(
                "Customer successfully updated.",
                true
              )
              this.getCompanyCustomers()
            }
            if (!res.data.success) {
              ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true)
              this.setState({ isloading: false })
            }
          })
          .catch((error) => {
            ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true)
            this.setState({ isloading: false })
          })
      }
    })
  }

  addToBlackList = (val) => {
    Swal.fire({
      text: i18n.t("are_you_sure"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ff3600",
      cancelButtonColor: "#354e68",
      confirmButtonText: i18n.t("yes"),
    }).then((result) => {
      if (result.value) {
        this.setState({ isloading: true })
        addToBlackList({ _id: val._id })
          .then((res) => {
            if (res.data.success) {
              SuccessHelper.handleSuccess(
                "Customer successfully updated.",
                true
              )
              this.getCompanyCustomers()
            }
            if (!res.data.success) {
              ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true)
              this.setState({ isloading: false })
            }
          })
          .catch((error) => {
            ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true)
            this.setState({ isloading: false })
          })
      }
    })
  }

  renderTableBody = (usersList) => {
    const { activePage, filterUsersList, advancedFilters = {} } = this.state
    const { watchListed, hasEmail, status } = advancedFilters
    let previousPageNumber = activePage - 1
    let list = filterUsersList ? filterUsersList : usersList
    const filterFunction = (e) => {
      let value = true
      const { isBlackListed, email, statusId } = e
      if (typeof watchListed == "boolean" && isBlackListed != watchListed) {
        value = false
      }
      if (typeof hasEmail == "boolean") {
        if (hasEmail && !email) {
          value = false
        } else if (!hasEmail && email) {
          value = false
        }
      }
      if (status && status != statusId) {
        value = false
      }
      return value
    }
    if (Object.keys(advancedFilters).length) {
      list = list.filter(filterFunction)
    }
    let startDay = null
    let endDay = null
    let currentWorkingHr = { checkIn: "", checkOut: "" }
    return (
      <tbody>
        {list &&
          list.map((val, ind) => {
            if (ind >= previousPageNumber * 10 && ind < activePage * 10) {
              return (
                <tr key={val._id} style={{ borderTop: "3px solid #F7F7F7" }}>
                  <td>
                    <div className="d-flex align-items-center">
                      {val && (
                        <span className="appointment-details-employee-name">
                          {`${val.firstName || ""} ${val.name}`.trim()}
                        </span>
                      )}
                    </div>
                  </td>
                  <td className="Table_data">
                    <div className="d-flex align-items-center mt-1">
                      {val && (
                        <span className="appointment-details-employee-other justify-content-center">
                          <NumberFormat
                            value={val.phoneNo}
                            displayType={"text"}
                            format="### ### #### ####"
                          />
                          <div>{val.email}</div>
                        </span>
                      )}
                    </div>
                  </td>
                  <td className="Table_data">#{val.customerNumber}</td>
                  <td className="Table_data">{val.statusId?.name}</td>
                  <td className="Table_data">
                    {val.isBlackListed ? (
                      <FormControlLabel
                        control={
                          <Switch
                            value={val.isBlackListed}
                            checked={val.isBlackListed}
                            type="checkbox"
                            onChange={() => this.removeFromBlackList(val, ind)}
                            color="primary"
                          />
                        }
                        label={i18n.t("yes")}
                      />
                    ) : (
                      <FormControlLabel
                        control={
                          <Switch
                            value={val.isBlackListed}
                            checked={val.isBlackListed}
                            type="checkbox"
                            onChange={() => this.addToBlackList(val, ind)}
                            color="primary"
                          />
                        }
                        label={i18n.t("yes")}
                      />
                    )}
                    {/* <div className="d-flex align-items-center mt-1">
                      {val && (
                        <span className="appointment-details-employee-other">
                          {val.isBlackListed ? (
                            <div className="row">
                              {i18n.t("yes")}
                              <FontAwesomeIcon
                                className="black-list-undo"
                                color="white"
                                icon={["fas", "minus"]}
                                onClick={() =>
                                  this.removeFromBlackList(val, ind)
                                }
                              />
                            </div>
                          ) : (
                            <div className="row">
                              {i18n.t("no")}
                              <FontAwesomeIcon
                                className="black-list-undo"
                                color="white"
                                icon={["fas", "plus"]}
                                onClick={() => this.addToBlackList(val, ind)}
                              />
                            </div>
                          )}
                        </span>
                      )}
                    </div> */}
                  </td>
                  <td style={{ width: "120px" }}>
                    <div className="row">
                      {val.isActive ? (
                        <>
                          <EditIcon
                            onClick={() => {
                              getCustomerById({ _id: val._id })
                                .then(({ data }) => {
                                  this.openEditCustomerCard(data.data, ind)
                                })
                                .catch(() => {
                                  ErrorHelper.handleErrors(
                                    i18n.t("something_went_wrong"),
                                    true
                                  )
                                })
                            }}
                          />
                          <DeleteIcon
                            onClick={() =>
                              deleteConfirmation(() =>
                                this.handleSaloonDeleteCustomer(
                                  val._id,
                                  ind,
                                  "delete"
                                )
                              )
                            }
                          />
                        </>
                      ) : (
                        <ReuseIcon
                          onClick={() =>
                            restoreConfirmation(
                              () =>
                                this.handleSaloonDeleteCustomer(
                                  val._id,
                                  ind,
                                  "restore"
                                ),
                              i18n.t("this_is_inactive", {
                                name: i18n.t("customer").toLowerCase(),
                              })
                            )
                          }
                        />
                      )}
                    </div>
                  </td>
                </tr>
              )
            }
          })}
      </tbody>
    )
  }

  openEditCustomerCard = (val, index, readOnly = false) => {
    this.setState({
      showEditCustomerCard: true,
      active: val,
      index,
      readOnly,
    })
  }
  closeEditCustomerCard = () => {
    this.setState({
      showEditCustomerCard: false,
      index: undefined,
    })
  }

  renderUserTable = (users) => {
    return (
      <div className="table-responsive">
        <table className="table table-borderless appointment-details-table mobile-max-content">
          <thead>
            <tr>
              <th scope="col">{i18n.t("name")}</th>
              <th scope="col">{i18n.t("details")}</th>
              <th scope="col">{i18n.t("customer_number")}</th>
              <th scope="col">{i18n.t("status")}</th>
              <th scope="col">{i18n.t("is_black_listed")}</th>
              <th scope="col" style={{ width: "120px" }}>
                Actions
              </th>
            </tr>
          </thead>
          {this.renderTableBody(users)}
        </table>
      </div>
    )
  }

  renderPagination = (tabIndex) => {
    const { searchText, filterUsersList, activeUsers, archivedUsers } =
      this.state
    const users = tabIndex === 0 ? activeUsers : archivedUsers

    return (
      <div className="col-12 mt-5 d-flex justify-content-center">
        <Pagination
          hideNavigation
          activePage={this.state.activePage}
          itemsCountPerPage={10}
          totalItemsCount={
            searchText ? filterUsersList.length : users?.length || 0
          }
          // pageRangeDisplayed={3}
          onChange={(val) => this.handlePageChange(val)}
          firstPageText={
            <img src={Images.pagination_back} style={{ width: 15 }} />
          }
          lastPageText={
            <img src={Images.pagination_forward} style={{ width: 15 }} />
          }
          itemClass="page-item"
          linkClass="page-link"
        />
      </div>
    )
  }

  renderEditCustomerCard = () => {
    const { showEditCustomerCard, active, usersList, index } = this.state
    return (
      <CustomerEdit
        active={active}
        open={showEditCustomerCard}
        setOpen={() => this.setState({ showEditCustomerCard: false })}
        onUpdate={() => {
          this.getCompanyCustomers()
        }}
        setAccountBalance={(accountBalance) => {
          usersList[index] = { ...usersList[index], accountBalance }
          this.setState({ usersList })
        }}
        allCustomers={usersList}
      />
    )
  }

  render() {
    const {
      advancedFiltersOpen,
      advancedFilters = {},
      searchText,
      tabIndex,
      activeUsers,
      archivedUsers,
    } = this.state
    const tabs = [
      {
        title: i18n.t("Active"),
        content: () => <> {this.renderUserTable(activeUsers)}</>,
      },
      {
        title: i18n.t("Inactive"),
        content: () => <> {this.renderUserTable(archivedUsers)}</>,
      },
    ]
    return (
      <div>
        {this.renderLoader()}
        <div className="content-container p-4">
          <div className="row mx-0">
            {this.renderHeader()}
            {this.renderSearch()}
            <Tabs
              tabs={tabs}
              activeTab={(value) => {
                this.setState({ tabIndex: value }, () => {
                  this.onChangeSearchText(searchText)
                })
              }}
            />
            {this.renderPagination(tabIndex)}
            {this.renderEditCustomerCard()}
          </div>
        </div>
        <AdvancedCustomerFilters
          open={advancedFiltersOpen}
          setOpen={(advancedFiltersOpen) => {
            this.setState({ advancedFiltersOpen })
          }}
          filters={advancedFilters}
          setFilters={(advancedFilters) => this.setState({ advancedFilters })}
        />
      </div>
    )
  }
}
