import React, { useEffect } from "react"
import { connect } from "react-redux"
import i18n from "../../../../i18n"
import moment from "moment"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import CancelIcon from "@material-ui/icons/Cancel"
import { IconButton } from "@material-ui/core"
import { updateRequest } from "../../../../redux/actions/Saloon/AllFundsRequest"
import Swal from "sweetalert2"
import { ErrorHelper, SuccessHelper } from "../../../../helpers"
import useScreenSize from "../../../../context/ScreenSize"
import Table from "../../../Core/BasicTable/index"
function ListFundsRequests({ requests, updateRequest, accountDetails }) {
  const { screenSize } = useScreenSize()
  const rejectRequest = (request) => {
    Swal.fire({
      title: i18n.t("confirm_reject"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ff3600",
      cancelButtonColor: "#354e68",
      confirmButtonText: i18n.t("sure"),
      cancelButtonText: i18n.t("no"),
    }).then((result) => {
      if (result.value) {
        updateRequest(
          { _id: request._id, status: "Rejected" },
          {
            success() {
              SuccessHelper.handleSuccess("", true)
            },
            failure() {
              ErrorHelper.handleErrors(i18n.t("someting_went_wrong"), true)
            },
          }
        )
      }
    })
  }
  const approveRequest = (request) => {
    const {
      totalAvailable = 0,
      combinedPending = 0,
      employeePending = 0,
    } = accountDetails
    Swal.fire({
      title: i18n.t("confirm_approve"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ff3600",
      cancelButtonColor: "#354e68",
      confirmButtonText: i18n.t("yes"),
      cancelButtonText: i18n.t("no"),
    }).then((result) => {
      if (result.value) {
        updateRequest(
          { _id: request._id, status: "Approved" },
          {
            success() {
              SuccessHelper.handleSuccess("", true)
            },
            failure() {
              ErrorHelper.handleErrors(i18n.t("someting_went_wrong"), true)
            },
          }
        )
      }
    })
  }
  return (
    <Table
      rows={requests}
      schema={[
        {
          header: i18n.t("amount"),
          field: "amount",
          style: screenSize === "mobile" ? { width: 100 } : {},
          accessor: (data) => {
            return data?.amount?.toFixed(2)
          },
        },
        {
          header: i18n.t("approved_by"),
          field: "approved_by",
          style: screenSize === "mobile" ? { width: 100 } : {},
          accessor: (data) => {
            return data?.approver?.firstName
          },
        },
        {
          header: i18n.t("created_on"),
          field: "created_on",
          style: screenSize === "mobile" ? { width: 150 } : {},
          accessor: (data) => {
            return moment(data?.createdDate).format("MM-DD-YYYY hh:mm A")
          },
        },
        {
          header: i18n.t("status"),
          field: "status",
          style: screenSize === "mobile" ? { width: 100 } : {},
          accessor: (data) => {
            return i18n.t(data?.status)
          },
        },
        {
          header: i18n.t("actions"),
          field: "actions",
          accessor: (data) => {
            return (
              <>
                {data.status == "Requested" ? (
                  <div className="row mx-0 flex-nowrap">
                    <div>
                      <IconButton
                        size="small"
                        onClick={() => approveRequest(request)}
                      >
                        <CheckCircleIcon className="check-btn" />
                      </IconButton>
                    </div>
                    <div>
                      <IconButton
                        size="small"
                        onClick={() => rejectRequest(request)}
                      >
                        <CancelIcon className="x-btn" />
                      </IconButton>
                    </div>
                  </div>
                ) : null}
              </>
            )
          },
        },
      ]}
    ></Table>
  )
}

const mapStateToProps = (state) => ({})

const action = { updateRequest }

export default connect(mapStateToProps, action)(ListFundsRequests)
