// CATEGORY
var barb_a = require("./CATEGORY/barb_a.svg")
const barb = require("./CATEGORY/barb.svg")
const cons_a = require("./CATEGORY/cons_a.svg")
const cons = require(".//CATEGORY/cons.svg")
const eyeb_a = require("./CATEGORY/eyeb_a.svg")
const eyeb = require("./CATEGORY/eyeb.svg")
const eyel_a = require("./CATEGORY/eyel_a.svg")
const eyel = require("./CATEGORY/eyel.svg")
const fitn_a = require("./CATEGORY/fitn_a.svg")
const fitn = require("./CATEGORY/fitn.svg")
const gard_a = require("./CATEGORY/gard_a.svg")
const gard = require("./CATEGORY/gard.svg")
const hair_a = require("./CATEGORY/hair_a.svg")
const hair = require("./CATEGORY/hair.svg")
const hairrem_a = require("./CATEGORY/hairrem_a.svg")
const hairrem = require("./CATEGORY/hairrem.svg")
const loca_a = require("./CATEGORY/loca_a.svg")
const loca = require("./CATEGORY/loca.svg")
const mass_a = require("./CATEGORY/mass_a.svg")
const mass = require("./CATEGORY/mass.svg")
const nails_a = require("./CATEGORY/nails_a.svg")
const nails = require("./CATEGORY/nails.svg")
const pers_a = require("./CATEGORY/pers_a.svg")
const pers = require("./CATEGORY/pers.svg")
const profs_a = require("./CATEGORY/profs_a.svg")
const profs = require("./CATEGORY/profs.svg")
const skin_a = require("./CATEGORY/skin_a.svg")
const skin = require("./CATEGORY/skin.svg")
const tatt_a = require("./CATEGORY/tatt_a.svg")
const tatt = require("./CATEGORY/tatt.svg")

// INFO
var arrow_left = require("./INFO/arrow_left.svg")
const arrow_right = require("./INFO/arrow_right.svg")
const calendar_a = require("./INFO/calendar_a.svg")
const calendar = require("./INFO/calendar.svg")
const child = require("./INFO/child.svg")
const close = require("./INFO/close.svg")
const droplist_arrow = require("./INFO/droplist_arrow.svg")
const smile = require("./INFO/smile.svg")

// FILTER_STAR
const star_a = require("./INFO/FILTER_STAR/star_a.svg")
const star = require("./INFO/FILTER_STAR/star.svg")

// RATING
const r_star_a = require("./INFO/RATING/r_star_a.svg")
const r_star = require("./INFO/RATING/r_star.svg")

const categoryIcons = [
  barb,
  skin,
  nails,
  hair,
  eyel,
  hairrem,
  fitn,
  pers,
  profs,
  mass,
  eyeb,
  tatt,
  cons,
  gard,
  loca,
]

const categoryIconsActive = [
  barb_a,
  skin_a,
  nails_a,
  hair_a,
  eyel_a,
  hairrem_a,
  fitn_a,
  pers_a,
  profs_a,
  mass_a,
  eyeb_a,
  tatt_a,
  cons_a,
  gard_a,
  loca_a,
]

const infoIcons = [
  arrow_left,
  arrow_right,
  calendar,
  child,
  close,
  droplist_arrow,
  smile,
  star,
  r_star,
]

const infoIconsActive = [calendar_a, r_star_a, star_a]

module.exports = {
  categoryIcons,
  categoryIconsActive,
  infoIcons,
  infoIconsActive,
}
