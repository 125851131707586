const getNestedValue = function (reference, path, fallback=undefined){
  path = Array.isArray(path) ? path : [path]

  var pointer = reference
  for (var i=0, len=path.length; i<len; i++){
    if (typeof(pointer) != "string" && pointer != null && typeof(pointer[path[i]]) != "undefined"){
      pointer = pointer[path[i]]
    } else {
      return fallback
    }
  }

  if (typeof(pointer) == "string"){
    pointer = (""+pointer).trim()
    if (pointer.length == 0)
      return fallback
  }
  return pointer
}

export {
  // Getter
  getNestedValue,
}