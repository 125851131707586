import { useSelector } from "react-redux";
import React, { useState } from "react";
import i18n from "../../../../i18n";
import BasicTable from "../../../Core/BasicTable";
import PrimaryButton from "../../../Core/PrimaryButton";
import { DotsLoader } from "../../..";
import { removeRequest } from "../../../../redux/actions/Saloon/CompanyCustomerCars";
import { useDispatch } from "react-redux";
import { ErrorHelper, SuccessHelper } from "../../../../helpers";
import DeleteIcon from "../../../Core/BasicTable/Actions/DeleteIcon";
import Swal from "sweetalert2";
import deleteConfirmation from "../../../../helpers/DeleteHelper";
import CustomerCarAdd from "./Support/CustomerCarAdd";

export default function CustomerCars({ handleAdd }) {
  const dispatch = useDispatch();
  const cars = useSelector((s) => s.companyCustomerCars.data || []);
  const [addCarOpen, setAddCarOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleDelete = (ids) => {
    setLoading(true);
    dispatch(
      removeRequest(
        { ids },
        {
          success: () => {
            setLoading(false);
            SuccessHelper.handleSuccess(i18n.t("successfully_deleted"), true);
          },
          failure: () => {
            setLoading(false);
            ErrorHelper.handleErrors(i18n.t("failed_to_delete"), true);
          },
        }
      )
    );
  };

  return (
    <>
      <DotsLoader isloading={loading} />
      <div className="row mx-0 justify-content-end mb-1">
        <PrimaryButton
          label={i18n.t("add_car")}
          onClick={() => setAddCarOpen(true)}
        />
      </div>
      <BasicTable
        rows={cars || []}
        schema={[
          {
            header: i18n.t("make"),
            field: "make",
          },
          {
            header: i18n.t("model"),
            field: "model",
          },
          {
            header: i18n.t("year"),
            field: "year",
          },
          {
            field: "actions",
            style: { width: 80 },
            render: (row, index) => {
              return (
                <DeleteIcon
                  onClick={() =>
                    deleteConfirmation(() => handleDelete(row._id))
                  }
                />
              );
            },
          },
        ]}
      />
      <CustomerCarAdd
        handleAdd={handleAdd}
        open={addCarOpen}
        setOpen={setAddCarOpen}
      />
    </>
  );
}
