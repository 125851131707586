import { connect, useDispatch, useSelector } from "react-redux";
import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";

import i18n from "../../../i18n";
import { getNestedValue } from "../../../util/objectMethods";
import { getUserPackagesRequest } from "../../../redux/actions/Packages";
import PrimaryButton from "../../Core/PrimaryButton";
import CollapsibleTable from "../../CollapsibleTable";
import BaseModal from "../../BaseModal";
import { getEmployeesBySaloonAndService } from "../../../config/simpleApiCalls";
import { ErrorHelper } from "../../../helpers";
import Select from "../../Core/Select";
import DatePicker from "react-datepicker";
import { TextField } from "@material-ui/core";
import { request as getAvailableTimeslots } from "../../../redux/actions/GetAvailableTimeslots.js";

moment.locale("en");

const UserPackages = ({ userPackages = [], name, email, phoneNo, bookNow }) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [employeeId, setEmployeeId] = useState(null);
  const [employees, setEmployees] = useState([]);
  const availableTimeslots = useSelector(
    (s) => s.getAvailableTimeslots?.data?.data || {}
  );
  const formattedTimeSlots = useMemo(() => {
    const timeSlotsKeys = Object.keys(availableTimeslots);
    if (!timeSlotsKeys.length) {
      return [];
    }
    return timeSlotsKeys.map((key) => {
      return {
        name: key,
        value: key,
      };
    });
  }, [availableTimeslots]);

  const { functionRole, requiresEmployee, companyId } = useMemo(() => {
    if (!selectedPackage) {
      return {};
    }
    const functionRole = selectedPackage?.companyId?.function;
    const requiresEmployee = functionRole != "garage" && functionRole != "gym";
    const companyId = selectedPackage?.companyId?._id;
    return {
      functionRole,
      requiresEmployee,
      companyId,
    };
  }, [selectedPackage]);
  const serviceId = selected?.serviceId?._id;

  useEffect(() => {
    if (companyId && serviceId) {
      if (requiresEmployee) {
        getEmployeesBySaloonAndService({
          companyId,
          serviceId,
        })
          .then(({ data }) => {
            setEmployees(
              data.data.map((employee) => ({
                ...employee,
                value: employee.employeeId._id,
                name: `${employee.employeeId.userId.firstName} ${employee.employeeId.userId.lastName}`,
              }))
            );
          })
          .catch(() => {
            ErrorHelper.handleErrors("something_went_wrong", true);
          });
      }
    }
  }, [selectedPackage?.companyId, selected?.serviceId?._id]);

  useEffect(() => {
    if (date) {
      setTime();
      const formattedDate = moment(date).format("MM-DD-YYYY");
      dispatch(
        getAvailableTimeslots({
          data: {
            companyId,
            companyServiceId: serviceId,
            date: formattedDate,
            companyEmployeeId: employeeId,
          },
        })
      );
    }
  }, [date]);

  const onSelect = async (val, row) => {
    setSelected(val);
    setSelectedPackage(row);
    setEmployeeId();
    setDate();
    setTime();
  };
  const onBookNow = () => {
    if (!time) {
      return;
    }
    const formattedDate = moment(date).format("MM-DD-YYYY");
    const formattedTime = time.split(" - ")[0];
    const payload = {
      companyId,
      services: [
        {
          serviceId,
          categoryId: selected.serviceId.serviceId,
          employeeId,
          date: formattedDate,
          time: formattedTime,
        },
      ],
      userName: name,
      email,
      phoneNo,
      status: 1,
      totalAmount: 0,
      depositRequired: false,
      userPackageId: selectedPackage._id,
    };
    bookNow(payload).then(() => {
      setSelected(null);
      setSelectedPackage(null);
    });
  };
  const colClass = requiresEmployee ? "col-4" : "col-6";
  return (
    <div className="my-1">
      {selected ? (
        <BaseModal
          open={Boolean(selected)}
          onClose={() => setSelected(null)}
          {...{
            content: (
              <div className="row">
                {requiresEmployee ? (
                  <div className={`col-xs-12 ${colClass}`}>
                    <Select
                      options={employees}
                      label={i18n.t("employee")}
                      onChange={(e) => {
                        setDate();
                        setEmployeeId(e.target.value);
                      }}
                    />
                  </div>
                ) : null}
                <div className={`col-xs-12 ${colClass}`}>
                  <DatePicker
                    wrapperClassName="w-100 mb-1"
                    selected={date}
                    onChange={(e) => {
                      setDate(e);
                    }}
                    value={date}
                    customInput={
                      <TextField
                        label={i18n.t("date")}
                        value={date}
                        validators={["required"]}
                        errorMessages={[i18n.t("date_is_required")]}
                        inputProps={{ readOnly: true }}
                      />
                    }
                    placeholderText={i18n.t("date")}
                    disabled={!employeeId && requiresEmployee}
                  />
                </div>
                <div className={`col-xs-12 ${colClass}`}>
                  <Select
                    options={formattedTimeSlots}
                    label={i18n.t("time")}
                    props={{
                      disabled: !date,
                    }}
                    onChange={(e) => {
                      setTime(e.target.value);
                    }}
                  />
                </div>
              </div>
            ),
            contentClassName: "modify-modal-content",
            title: `${name} - ${selected.serviceId.name}`,
            actions: (
              <div className="row mx-0 px-0 justify-content-end">
                <PrimaryButton label={i18n.t("book_now")} onClick={onBookNow} />
              </div>
            ),
          }}
        />
      ) : null}
      <CollapsibleTable
        rows={userPackages}
        childKey="services"
        schema={[
          {
            key: "package",
            accessor: (val) => getNestedValue(val, ["packageId", "name"], ""),
          },
          {
            key: "numberOfSessions",
            accessor: (val) =>
              val.numberOfSessions - val.sessionBooked - val.sessionCompleted,
          },
          {
            key: "expiryDate",
            accessor: (val) =>
              val.expiryDate
                ? moment(val.expiryDate).format("MM-DD-YYYY hh:mm A")
                : "",
          },
        ]}
        childSchema={{
          title: i18n.t("services"),
          columns: [
            {
              key: "name",
              accessor: (val) => getNestedValue(val, ["serviceId", "name"], ""),
            },
            {
              key: "action",
              header: "",
              render: (val, row) => {
                const available =
                  row.numberOfSessions -
                  row.sessionBooked -
                  row.sessionCompleted;
                if (available > 0) {
                  return (
                    <PrimaryButton
                      label={i18n.t("redeem")}
                      onClick={() => {
                        onSelect(val, row);
                      }}
                    />
                  );
                }
              },
            },
          ],
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  userPackages: state.packages.userPackages,
});

const action = { getUserPackagesRequest };

export default connect(mapStateToProps, action)(UserPackages);
