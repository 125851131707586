import { connect } from "react-redux";
import React, { Component } from "react";
import FieldGenerator from "./Components/FieldGenerator.component";
import FormGenerator from "./Components/FormGenerator.component";
import FieldReview from "./Components/FieldReview.component";
import Field from "./Components/Field.component";
import Button from "@material-ui/core/Button";
import {
  updateRequest,
  insertQuestionsRequest,
  removeQuestionsRequest,
  updateQuestionsRequest,
} from "../../redux/actions/Saloon/FormBuilder";

import "./styles.sass";
import DotsLoader from "../DotsLoader";

import styled from "styled-components";
import SelectInput from "./Components/SelectInput.component";
import toastr from "../../helpers/Toastr";
import i18n from "../../i18n";
import PrimaryButton from "../../components/Core/PrimaryButton";

const inputStatusOptions = [
  { value: "Active", label: i18n.t("container.active") },
  { value: "Inactive", label: i18n.t("container.inactive") },
];
const inputTypeOptions = [
  { value: "One Time", label: i18n.t("one_time") },
  { value: "Reccuring", label: i18n.t("reccuring") },
];
const SaveSection = styled.section`
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2vh;
`;

const FormStatusWrapper = styled.section`
  margin-top: 1em;
`;

const scrollToRef = (ref) => window.scrollTo(2, ref.current);

class FormBuilder extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }
  state = {
    fieldsToRender: {},
    columns: {
      "column-1": {
        id: "column-1",
        fieldIds: [],
      },
    },
    columnOrder: ["column-1"],
    initialized: false,
    formTitle: "",
    toDelete: [],
    preview: false,
    formStatus: "active",
    type: "One Time",
  };

  componentDidMount() {
    this.setState({ initialized: true });
  }
  executeScroll = () => scrollToRef(this.myRef);

  static getDerivedStateFromProps(nextProps, prevState) {
    const { form } = nextProps;
    const fieldsToRender = {};
    const fields = form.questions.map((question) => {
      question = {
        ...question,
        id: question._id,
        fieldOptions: question.fieldOptions.join("-"),
      };
      fieldsToRender[question._id] = question;
      return question;
    });
    if (form != prevState.form && !prevState.isLoading) {
      return {
        form,
        toDelete: [],
        formTitle: form.formTitle,
        formDescription: form.formDescription,
        formStatus: form.status,
        type: form.type,
        formId: form._id,
        fieldsToRender,
        columns: {
          "column-1": {
            id: "column-1",
            fieldIds: fields.map((field) => field._id),
          },
        },
      };
    } else {
      return null;
    }
  }

  saveForm = () => {
    const {
      updateRequest,
      insertQuestionsRequest,
      removeQuestionsRequest,
      updateQuestionsRequest,
    } = this.props;
    const {
      formTitle,
      formDescription,
      formId,
      toDelete = [],
      formStatus,
      type,
    } = this.state;
    this.setState({ isLoading: true });
    let formToSubmit = {};

    const fields = this.state.columns["column-1"].fieldIds.map(
      (fieldId, index) => {
        return {
          ...this.state.fieldsToRender[fieldId],
          displayOrder: index + 1,
        };
      }
    );

    formToSubmit["formFields"] = fields;

    formToSubmit["formName"] = "client selected name";
    let promises = [];
    promises.push(
      new Promise((resolve) => {
        updateRequest(
          {
            formTitle,
            formDescription,
            status: formStatus,
            type,
          },
          { _id: formId },
          {
            success: resolve,
          }
        );
      })
    );
    fields.map((field) => {
      const { type, fieldName, id, _id, displayOrder, required, details } =
        field;
      let fieldOptions = field.fieldOptions
        ? field.fieldOptions.split("-")
        : "";
      if (_id) {
        promises.push(
          new Promise((resolve) => {
            updateQuestionsRequest(
              {
                type,
                fieldName,
                fieldOptions,
                displayOrder,
                required,
                details,
              },
              { _id },
              {
                success: () => {
                  toastr.success(i18n.t("successfully_updated"));
                  resolve();
                },
                failure: () => {
                  toastr.error(i18n.t("failed_to_update"));
                  resolve();
                },
              }
            );
          })
        );
      } else {
        promises.push(
          new Promise((resolve) => {
            insertQuestionsRequest(
              {
                type,
                fieldName,
                fieldOptions,
                formId,
                displayOrder,
                required,
                details,
              },
              {
                success: () => {
                  toastr.success(i18n.t("successfully_added"));
                  resolve();
                },
                failure: () => {
                  toastr.error(i18n.t("failed_to_add"));
                  resolve();
                },
              }
            );
          })
        );
      }
    });
    toDelete.map((_id) => {
      promises.push(
        new Promise((resolve) => {
          removeQuestionsRequest(
            { questionId: _id, formId },
            {
              success: () => {
                toastr.success(i18n.t("successfully_deleted"));
                resolve();
              },
              failure: () => {
                toastr.error(i18n.t("failed_to_delete"));
                resolve();
              },
            }
          );
        })
      );
    });
    Promise.all(promises).then(() => {
      this.setState({ isLoading: false });
      this.props.updateFormAfterSave();
    });

    /// we need to make an API call to save the form then redirect the user
  };

  handleGeneratedField = (generatedField) => {
    const { fieldsToRender, columns } = this.state;

    const newId = `field-${Object.keys(fieldsToRender).length + 1}`;

    generatedField["id"] = newId;

    const newFieldIds = [...columns["column-1"].fieldIds, newId];

    const newColumn = {
      ...columns["column-1"],
      fieldIds: newFieldIds,
    };

    this.setState(() => ({
      fieldsToRender: {
        // object that we want to update
        ...fieldsToRender, // keep all other key-value pairs
        [newId]: generatedField, // update the value of specific key
      },
      columns: {
        ...columns,
        ["column-1"]: newColumn,
      },
    }));
  };

  handleDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }
    if (
      destination.DroppableId === source.DroppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const column = this.state.columns[source.droppableId];

    const newFieldIds = Array.from(column.fieldIds);
    newFieldIds.splice(source.index, 1);
    newFieldIds.splice(destination.index, 0, draggableId);

    const newColumn = {
      ...column,
      fieldIds: newFieldIds,
    };
    const newState = {
      ...this.state,
      columns: {
        ...this.state.columns,
        [newColumn.id]: newColumn,
      },
    };

    this.setState(newState);
  };

  handleDeletField = (fieldId) => {
    const { fieldsToRender, columns, toDelete } = this.state;

    let ress = {};
    Object.keys(fieldsToRender).map((key) => {
      if (key != fieldId) {
        ress[key] = fieldsToRender[key];
      } else {
        if (fieldsToRender[key]._id) {
          toDelete.push(fieldsToRender[key]._id);
        }
      }
    });

    const column = columns["column-1"];

    const newFieldIds = columns["column-1"].fieldIds.filter(
      (f) => f != fieldId
    );

    const newColumn = {
      ...column,
      fieldIds: newFieldIds,
    };
    this.setState({
      fieldsToRender: ress,
      columns: {
        ...columns,
        ["column-1"]: newColumn,
      },
      toDelete,
    });
  };

  handleEditField = (toEdit) => {
    this.executeScroll();
    this.setState({ toEdit }, () => {
      this.setState({ toEdit: null });
    });
  };

  handleCommitEdit = (result) => {
    const { fieldsToRender } = this.state;
    if (result) {
      fieldsToRender[result.id] = result;
    }
    this.setState({ fieldsToRender });
  };

  handlePreview = () => {
    this.setState((prevState) => ({ preview: !prevState.preview }));
  };

  handelStatus = (e) => {
    this.setState({ formStatus: e.target.value });
  };

  handleType = (e) => {
    this.setState({ type: e.target.value });
  };
  render() {
    const {
      fieldsToRender,
      initialized,
      formTitle,
      formTitleError,
      formDescription,
      toEdit,
      isLoading,
      formStatus,
      type,
    } = this.state;
    let sortedField = {};

    return (
      <React.Fragment>
        <DotsLoader isloading={this.props.loading || isLoading} />
        {initialized && (
          <>
            <SaveSection>
              <PrimaryButton
                variant="contained"
                color="primary"
                onClick={() => this.saveForm()}
              >
                {i18n.t("save_form")}
              </PrimaryButton>
            </SaveSection>
            <div className="formDetails">
              <Field
                handleChange={(e) =>
                  this.setState({ formTitle: e.target.value })
                }
                fieldError={formTitleError}
                field={formTitle}
                label={i18n.t("Enter_Form_Title")}
                name="formTitle"
              />
              <div className="mt-3">
                <Field
                  handleChange={(e) =>
                    this.setState({ formDescription: e.target.value })
                  }
                  fieldError={formTitleError}
                  field={formDescription}
                  label={i18n.t("Enter_Form_Description")}
                  name="formDescription"
                />
              </div>
              <FormStatusWrapper>
                <SelectInput
                  handleChange={this.handelStatus}
                  // inputTypeError,
                  // inputType,
                  inputType={formStatus}
                  selectLabel="Status"
                  selectOptions={inputStatusOptions}
                />
              </FormStatusWrapper>
              <FormStatusWrapper>
                <SelectInput
                  handleChange={this.handleType}
                  // inputTypeError,
                  // inputType,
                  inputType={type}
                  selectLabel={i18n.t("type")}
                  selectOptions={inputTypeOptions}
                />
              </FormStatusWrapper>
            </div>
            <div className="formBuilder">
              <FieldGenerator
                handleGeneratedField={(e) => this.handleGeneratedField(e)}
                toEdit={toEdit}
                handleCommitEdit={this.handleCommitEdit}
                handlePreview={this.handlePreview}
                preview={this.state.preview}
                ref={this.myRef}
              />
              {/* this map is in case we have multiple columns but in this scenario we only have one column */}
              {this.state.columnOrder.map((columnId) => {
                const column = this.state.columns[columnId];

                const fields = column.fieldIds.map(
                  (fieldId) => this.state.fieldsToRender[fieldId]
                );

                sortedField = fields;

                return (
                  <FieldReview
                    key={column.id}
                    column={column}
                    fieldsToRender={fields}
                    handleDragEnd={(e) => this.handleDragEnd(e)}
                    handleDeletField={(e) => this.handleDeletField(e)}
                    handleEditField={this.handleEditField}
                  />
                );
              })}
            </div>
          </>
        )}

        {this.state.preview && (
          <FormGenerator
            fieldsToRender={sortedField}
            preview={this.state.preview}
            readonly={true}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.formBuilder.isFetching,
});

const action = {
  updateRequest,
  updateQuestionsRequest,
  insertQuestionsRequest,
  removeQuestionsRequest,
};

export default connect(mapStateToProps, action)(FormBuilder);
