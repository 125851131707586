import React, { useEffect, useMemo, useState, useCallback } from "react"
import { connect } from "react-redux"
import i18n from "../../../../i18n"
import BaseModal from "../../../BaseModal"
import TextField from "@material-ui/core/TextField"
import DollarInput from "../../../DollarInput"
import Tabs from "../../../Core/Tabs/Tabs"
import { Button } from "@material-ui/core"
import TransactionDetails from "../.."
import { ErrorHelper, SuccessHelper } from "../../../../helpers"
import "./styles.scss"
import DiscountKeyPad from "../../../CreateInvoice/components/DiscountKeyPad"
import TipKeyPad from "../../../CreateInvoice/components/TipKeyPad"
import CollectKeyPad from "../../../CreateInvoice/components/CollectKeyPad"
import BasicTable from "../../../Core/BasicTable"
import PrimaryButton from "../../../Core/PrimaryButton"
import debounce from "lodash.debounce"
import useScreenSize from "../../../../context/ScreenSize"

const roundTo2Decimals = (x) => Math.round((x + Number.EPSILON) * 100) / 100
const InsertPayment = ({
  open,
  onSubmit,
  handleClose,
  defaultAmount,
  transactionDetailsProps,
  type,
  transactions,
  onChangeTip,
  tip,
  total,
  accountBalance,
  giftCardBalance,
  setCustomer = () => {},
  customerId,
}) => {
  const [localTip, setLocalTip] = useState(0)
  useEffect(() => {
    setLocalTip(tip)
  }, [tip])
  const [amount, setAmount] = useState(0)
  const [balanceDue, setBalanceDue] = useState(0)
  const [discountPercent, setDiscountPercent] = useState(0)
  const [discountAmount, setDiscountAmount] = useState(0)
  const [accountBalanceToCollect, setAccountBalanceToCollect] = useState(0)
  const [giftCardRemaining, setGiftCardRemaining] = useState(giftCardBalance)
  const [tab, setTab] = useState(0)
  const [collectionEntries, setCollectionEntries] = useState([])
  const toCollect = useMemo(() => {
    return collectionEntries.reduce(
      (accum, element) => accum + element.amount,
      0
    )
  }, [collectionEntries])

  const { screenSize } = useScreenSize()

  useEffect(() => {
    setAmount(defaultAmount)
  }, [])
  const [note, setNote] = useState("")
  const [manualPaymentMap, setManualPaymentMap] = useState({})
  const [formErrors, setFormErrors] = useState("")
  const difference = roundTo2Decimals(balanceDue - toCollect - discountAmount)
  const validate = () => {
    let hasErrors = false
    let newFormErrors = {}
    if (!amount && type == "manual") {
      newFormErrors.amount = i18n.t("amount_is_required")
      hasErrors = true
    }
    if (type === "reverse" && !note) {
      newFormErrors.note = i18n.t("note_is_required")
      hasErrors = true
    }
    if (difference > 0) {
      ErrorHelper.handleErrors(
        i18n.t("must_be_greater_or_equal", {
          amount: balanceDue,
        }),
        true
      )
      hasErrors = true
    }
    if (hasErrors) {
      setFormErrors(newFormErrors)
      setTimeout(() => {
        setFormErrors({})
      }, 6000)
    } else {
      let total = discountAmount || 0
      let cash = 0
      let creditDebit = 0
      let giftCard = 0
      let chargedAmountByCard = []
      collectionEntries.map((entry) => {
        let amount = entry.amount
        if (total >= balanceDue) {
          total += amount
          return
        }
        total += amount
        if (total > balanceDue) {
          amount = amount - (total - balanceDue)
        }
        if (entry.method == "cash") {
          cash += amount
        } else if (entry.method == "gift_card") {
          giftCard += amount
        } else {
          if (entry.cardId) {
            chargedAmountByCard.push({
              amount: amount,
              cardId: entry.cardId,
            })
          }

          creditDebit += amount
        }
      })
      const changeDue = roundTo2Decimals(total - balanceDue)
      onSubmit(
        {
          amount,
          note,
          cash: parseFloat(cash),
          creditDebit: parseFloat(creditDebit),
          giftCard: parseFloat(giftCard),
          discount: discountAmount,
          accountBalanceToCollect,
          chargedAmountByCard,
        },
        manualPaymentMap,
        () => {
          if (changeDue > 0) {
            SuccessHelper.handleSuccess(
              i18n.t("change_due_prompt", { amount: changeDue }),
              true
            )
          } else {
            SuccessHelper.handleSuccess("", true)
          }
        }
      )
      setCustomer(accountBalance - accountBalanceToCollect, giftCardRemaining)
    }
  }
  const hasPaidForTip = Boolean(
    transactions.find(
      (transaction) =>
        transaction.metadata?.tip &&
        !transaction.wasReversed &&
        transaction.type != "Reverse"
    )
  )
  const colClass = type === "manual" ? "col-md-6 col-xs-12 py-4" : "col-12 py-4"

  const saveTip = useCallback(
    debounce((newValue) => {
      const newTip = parseFloat(newValue)
      console.log({ newValue })
      onChangeTip(
        newTip && newTip > 0
          ? Math.round((newTip + Number.EPSILON) * 100) / 100
          : 0
      )
    }, 400),
    []
  )

  return (
    <BaseModal
      open={open}
      containerClassName={type === "manual" ? "container" : ""}
      {...{
        title: (
          <div>
            {type === "reverse"
              ? `${i18n.t("reversal_amount")} ($ ${amount.toFixed(2)})`
              : `${i18n.t("amount_to_be_paid")} ($ ${balanceDue.toFixed(2)})`}
          </div>
        ),
        content: (
          <div className="transactions-details-insert-payment mt-4">
            <div className="row mx-0">
              <div className={colClass}>
                <div className="row mx-0">
                  {accountBalance ? (
                    <>
                      <div className="mb-2 col-sm-6 col-xs-12 px-1">
                        <DollarInput
                          value={accountBalance}
                          label={i18n.t("owing_balance")}
                          readOnly={true}
                        />
                      </div>
                      <div className="mb-2 col-sm-6 col-xs-12 px-1">
                        <DollarInput
                          value={accountBalanceToCollect}
                          onChange={(e) =>
                            setAccountBalanceToCollect(parseFloat(e))
                          }
                          label={i18n.t("to_collect")}
                          max={accountBalance}
                        />
                      </div>
                    </>
                  ) : null}
                  {type === "manual" && (
                    <div className="w-100 custom-tab">
                      <Tabs
                        activeTab={(value) => {
                          setTab(value)
                        }}
                        tabs={[
                          {
                            title: i18n.t("collect"),
                            value: "collect",
                            content: () => {
                              return (
                                <div>
                                  <CollectKeyPad
                                    giftCardRemaining={giftCardRemaining}
                                    setGiftCardRemaining={setGiftCardRemaining}
                                    customerId={customerId}
                                    onSubmit={setCollectionEntries}
                                    /*  onSubmit={(newEntry) => {
                                      console.log(newEntry)
                                      setCollectionEntries([
                                        ...collectionEntries,
                                        newEntry,
                                      ])
                                    }} */
                                  />
                                  {collectionEntries.length ? (
                                    <div className="mt-2">
                                      <BasicTable
                                        maxContentWidth={false}
                                        rows={collectionEntries}
                                        schema={[
                                          {
                                            header: i18n.t("method"),
                                            field: "method",
                                            render: (row, index) => {
                                              return (
                                                <div>{i18n.t(row.method)}</div>
                                              )
                                            },
                                          },
                                          {
                                            header: i18n.t("amount"),
                                            field: "amount",
                                            render: (row, index) => {
                                              return <div>$ {row.amount}</div>
                                            },
                                          },
                                          {
                                            field: "actions",
                                            style: { width: 80 },
                                            render: (row, index) => {
                                              return (
                                                <PrimaryButton
                                                  label={i18n.t("delete")}
                                                  onClick={() => {
                                                    const newEntries = [
                                                      ...collectionEntries,
                                                    ]
                                                    if (
                                                      newEntries[index]
                                                        .method == "gift_card"
                                                    ) {
                                                      setGiftCardRemaining(
                                                        giftCardRemaining +
                                                          newEntries[index]
                                                            .amount
                                                      )
                                                    }
                                                    newEntries.splice(index, 1)
                                                    setCollectionEntries(
                                                      newEntries
                                                    )
                                                  }}
                                                />
                                              )
                                            },
                                          },
                                        ]}
                                      />
                                    </div>
                                  ) : null}
                                </div>
                              )
                            },
                          },
                          {
                            title: i18n.t("tip"),
                            value: "tip",
                            hidden: hasPaidForTip,
                            content: () => {
                              const handleTipPercent = (percent) => {
                                const newValue = roundTo2Decimals(
                                  (percent / 100) * total
                                )
                                setLocalTip(newValue)
                                saveTip(newValue)
                              }
                              return (
                                <TipKeyPad
                                  handleTipPercent={handleTipPercent}
                                  value={localTip}
                                  setValue={(value) => {
                                    setLocalTip(value)
                                    saveTip(value)
                                  }}
                                />
                              )
                            },
                          },
                          {
                            title: i18n.t("discount"),
                            value: "discount",
                            content: () => {
                              return (
                                <DiscountKeyPad
                                  dollarAmount={discountAmount}
                                  onChangeDollarAmount={(value) => {
                                    setDiscountAmount(value)
                                    setDiscountPercent(
                                      roundTo2Decimals(
                                        (100 * value) / balanceDue
                                      )
                                    )
                                  }}
                                  percentAmount={discountPercent}
                                  onChangePercentAmount={(percent) => {
                                    setDiscountPercent(percent)
                                    setDiscountAmount(
                                      roundTo2Decimals(
                                        (balanceDue * percent) / 100
                                      )
                                    )
                                  }}
                                />
                              )
                            },
                          },
                        ]}
                      />
                    </div>
                  )}
                  <div className="d-flex align-items-center mb-2 col-12 pt-3 px-0">
                    <label className="mr-5 mb-3 font-weight-bolder">
                      {i18n.t("note")}
                    </label>
                    <TextField
                      onChange={(e) => setNote(e.target.value)}
                      helperText={formErrors.note}
                      error={Boolean(formErrors.note)}
                      variant={"outlined"}
                    />
                  </div>
                </div>
              </div>
              {type === "manual" && (
                <div className={colClass}>
                  <TransactionDetails
                    {...transactionDetailsProps}
                    paymentAmount={roundTo2Decimals(toCollect)}
                    manualPayment={type === "manual"}
                    accountBalanceToCollect={accountBalanceToCollect}
                    onBalanceDueChange={(balanceDue) => {
                      setBalanceDue(
                        parseFloat(balanceDue) ? parseFloat(balanceDue) : 0
                      )
                    }}
                    manualPaymentMapChange={setManualPaymentMap}
                    discountAmount={discountAmount}
                    toCollect={toCollect}
                  />
                </div>
              )}
            </div>
          </div>
        ),
        actions: (
          <div className="row mx-0 justify-content-center gap-2 justify-content-md-end mb-4">
            {screenSize === "mobile" && (
              <PrimaryButton className="w-128 " onClick={handleClose}>
                {i18n.t("close")}
              </PrimaryButton>
            )}
            <PrimaryButton
              className="w-128 mr-4"
              onClick={() => {
                validate()
              }}
            >
              {i18n.t("save")}
            </PrimaryButton>
          </div>
        ),
      }}
      showCloseBtn={screenSize !== "mobile" ? true : false}
      onClose={handleClose}
    />
  )
}

const mapStateToProps = (state) => ({
  transactions: state.bookingTransaction.data,
})

const action = {}

export default connect(mapStateToProps, action)(InsertPayment)
