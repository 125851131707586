import React from "react"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useTheme } from "@material-ui/core/styles"
import PrimaryButton from "../Core/PrimaryButton"
import i18n from "../../i18n"
import "./styles.scss"

const BaseModal = ({
  open,
  title,
  content,
  actions,
  showCreateBtn,
  showCloseBtn = true,
  onClose,
  style,
  containerClassName = "base-model-container",
  className = "",
  contentClassName = "",
}) => {
  const theme = useTheme()
  const isXS = useMediaQuery(theme.breakpoints.down("xs"))

  const TitleTag = isXS ? "h5" : "h3"
  return (
    <div className={`base-modal ${className}`}>
      <div
        className="employee-service-card-wrapper"
        style={{
          display: open ? "flex" : "none",
          zIndex: 1302,
          overflowY: "hidden",
          ...style,
        }}
      >
        <div
          className={containerClassName}
          style={{ alignSelf: "center", maxHeight: "100%" }}
        >
          <div className="col-12 px-0 bg-white pop_container base-modal-body">
            <div className="row mx-0 d-flex justify-content-between base-modal-title pb-4">
              <TitleTag className="title justify-content-start">
                {title}
              </TitleTag>
              <div className="">
                {showCreateBtn && (
                  <PrimaryButton
                    className="mr-3 create-btn w-128"
                    label={i18n.t("create")}
                    onClick={showCreateBtn}
                  />
                )}
                {showCloseBtn && (
                  <PrimaryButton
                    className="close-btn w-128"
                    label={i18n.t("close")}
                    onClick={onClose}
                  />
                )}
              </div>
            </div>
            <div className={`base-modal-content ${contentClassName}`}>
              {content}
            </div>
            {actions && <div className="base-modal-actions">{actions}</div>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default BaseModal
