// @flow
import Immutable from "seamless-immutable"
import * as types from "../actions/ActionTypes"

const initialState = Immutable({
  data: null,
})

export default (state = initialState, action) => {
  switch (action.type) {
  case types.BOOKING_REF_CODE.SET:
    return Immutable.merge(state, {
      data: action.data,
    })
  case types.BOOKING_REF_CODE.CLEAR:
    return Immutable.merge(state, {
      data: null,
    })
  case types.BOOKING_REF_CODE.VALIDATE_SAGA:
    return state
  default:
    return state
  }
}
