import { connect } from "react-redux";
import React, { Component } from "react";
import { Translation } from "react-i18next";
import i18n from "../../../i18n";
import { DotsLoader, Navbar } from "../../../components";
import { Images } from "../../../theme";
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import { request as register_saloon } from "../../../redux/actions/Saloon/Register";
import { request as create_saloon } from "../../../redux/actions/Saloon/CreateSaloon";
import { request as create_saloon_plan } from "../../../redux/actions/Saloon/CreateSaloonPlan";
import { request as saloon_payment_method } from "../../../redux/actions/Saloon/SaloonPaymentMethod";
import { logout as saloon_logout } from "../../../redux/actions/Saloon/Login";
import "./styles.css";

import PlacesAutocomplete from "../../../components/PlacesAutocomplete";
import TextField from "@material-ui/core/TextField";
import PhoneNumberFormat from "../../../containers/phoneNumberFormat/PhoneNumberFormat.component";
import Select from "../../../components/Core/Select";
class SaloonRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Personal Information
      firstName: "",
      lastName: "",
      password: "",
      address: "",
      city: "",
      province: "",
      postalCode: "",
      telephone: "",
      functionRole: "salon",
      email: "",

      // Personal Information Error
      firstNameError: "",
      lastNameError: "",
      passwordError: "",
      addressError: "",
      cityError: "",
      provinceError: "",
      postalCodeError: "",
      telephoneError: "",
      functionRoleError: "",
      emailError: "",

      // Business Information
      companyName: "",
      companyTagLine: "",
      companyShortDescription: "",
      companyEmail: "",
      companyTelephone: "",

      // Business Information Error
      companyNameError: "",
      companyTagLineError: "",
      companyShortDescriptionError: "",
      companyEmailError: "",
      companyTelephoneError: "",
      concurrentBookingsError: "",

      // General State
      latitude: null,
      longitude: null,

      companyId: "",
      formTypeId: 1,
      formTypeText: "Personal Information",
      isloading: false,
      numberOfConcurrentBookings: 0,
      companyAddress: {},
      personalAddress: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.saloonRegister) {
      if (
        !nextProps.saloonRegister.failure &&
        !nextProps.saloonRegister.isFetching &&
        nextProps.saloonRegister.data?.data
      ) {
        if (nextProps.saloonRegister.data?.data?.success) {
          this.setState({
            isloading: false,
            formTypeId: 2,
            formTypeText: "Business Information",
            access_token: nextProps.saloonRegister.data.data.data.access_token,
          });
        } else {
          ErrorHelper.handleErrors(
            nextProps.saloonRegister.data.data.msg,
            true
          );
          this.setState({ isloading: false });
        }
      } else if (
        nextProps.saloonRegister.failure &&
        !nextProps.saloonRegister.isFetching &&
        nextProps.saloonRegister.errorMessage
      ) {
        this.setState({ isloading: false });
      }
    }
    // For Business Information
    if (nextProps.createSaloon) {
      if (
        !nextProps.createSaloon.failure &&
        !nextProps.createSaloon.isFetching &&
        nextProps.createSaloon.data
      ) {
        if (nextProps.createSaloon.data.success) {
          SuccessHelper.handleSuccess(
            "Thank you! Your account has been successfully created.",
            true
          );
          this.props.saloon_logout();
          this.handleNavigation("/login");
        }
        this.setState({ isloading: false });
      } else if (
        nextProps.createSaloon.failure &&
        !nextProps.createSaloon.isFetching &&
        nextProps.createSaloon.errorMessage
      ) {
        this.setState({ isloading: false });
      }
    }
  }

  componentDidMount() {
    this.getGeolocation();
    this.setState({
      isloading: false,
    });

    if (this.props.location?.state?.continueRegistration) {
      this.setState({
        isloading: false,
        formTypeId: 2,
        formTypeText: "Business Information",
        access_token: this.props.location.state.access_token,
      });
    }
  }

  getGeolocation = () => {
    let $this = this;
    navigator.geolocation.getCurrentPosition(function (position) {
      $this.setState({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
    });
  };

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  renderAddress = (key, addressError) => {
    return (
      <>
        <PlacesAutocomplete
          addressError={addressError}
          addressSelected={(newMap) => {
            const addressMap = Object.assign(
              {},
              ...["address", "city", "country", "postalCode", "province"].map(
                (key) => ({
                  [key]: newMap[key],
                })
              )
            );
            this.setState({
              [key]: {
                addressMap,
              },
            });
          }}
        />
      </>
    );
  };

  // Personal Information
  onChangeFirstName = (text) => this.setState({ firstName: text.target.value });
  onChangeLastName = (text) => this.setState({ lastName: text.target.value });
  onChangePassword = (text) => this.setState({ password: text.target.value });
  OnChangeConfirmedPassword = (text) =>
    this.setState({ confirmedPassword: text.target.value });
  onChangeAddress = (text) => this.setState({ address: text.target.value });
  onChangeCity = (text) => this.setState({ city: text.target.value });
  onChangeProvince = (text) => this.setState({ province: text.target.value });
  onChangePostalCode = (text) =>
    this.setState({ postalCode: text.target.value });

  onChangeTelephone = (text) => {
    this.setState({ telephone: text });
  };

  onChangeFunctionRole = (text) => {
    this.setState({ functionRole: text.target.value });
  };

  onChangeEmail = (text) => this.setState({ email: text.target.value });

  // Business Information
  onChangeCompanyName = (text) =>
    this.setState({ companyName: text.target.value });
  onChangeCompanyTagLine = (text) =>
    this.setState({ companyTagLine: text.target.value });
  onChangeCompanyShortDescription = (text) =>
    this.setState({ companyShortDescription: text.target.value });
  onChangeCompanyEmail = (text) =>
    this.setState({ companyEmail: text.target.value });

  formatPhoneNumber(input, format) {
    // Strip non-numeric characters
    var digits = input.replace(/\D/g, "");

    // Replace each "X" with the next digit
    var count = 0;
    let newF = format.replace(/X/g, function () {
      return digits.charAt(count++);
    });
    return newF.replace("X", "");
  }
  onChangeCompanyTelephone = (text) => {
    // console.log(text);
    const phoneNumber = this.formatPhoneNumber(
      text.target.value,
      "XXX XXX XXXX"
    );
    this.setState({ companyTelephone: phoneNumber });
  };
  setStateForPhonenumber = (number) => {
    this.setState({ companyTelephone: number });
  };
  onChangeNumberOfConcurrentBookings = (text) =>
    this.setState({ numberOfConcurrentBookings: text.target.value });

  checkValidation = () => {
    const { formTypeId } = this.state;
    if (formTypeId === 1) {
      this.validatePersonalInformation();
    } else if (formTypeId === 2) {
      this.validateBusinessInformation();
    }
  };

  validatePersonalInformation = () => {
    const {
      firstName,
      lastName,
      password,
      confirmedPassword,
      telephone,
      email,
      personalAddress,
    } = this.state;

    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const nameRegex =
      /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{2,}$/u;
    const telephoneRegex =
      /[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]/g;
    if (!firstName) {
      this.setState({
        firstNameError: i18n.t("first_name_required"),
      });
      setTimeout(() => {
        this.setState({
          firstNameError: "",
        });
      }, 6000);
    } else if (!firstName.match(nameRegex)) {
      this.setState({
        firstNameError: i18n.t("first_name_lenght_minimum_required"),
      });
      setTimeout(() => {
        this.setState({
          firstNameError: "",
        });
      }, 6000);
    } else if (!lastName) {
      this.setState({
        lastNameError: i18n.t("last_name_required"),
      });
      setTimeout(() => {
        this.setState({
          lastNameError: "",
        });
      }, 6000);
    } else if (!lastName.match(nameRegex)) {
      this.setState({
        lastNameError: i18n.t("last_name_lenght_minimum_required"),
      });
      setTimeout(() => {
        this.setState({
          lastNameError: "",
        });
      }, 6000);
    } else if (!email) {
      this.setState({
        emailError: i18n.t("email_required"),
      });
      setTimeout(() => {
        this.setState({
          emailError: "",
        });
      }, 6000);
    } else if (!email.match(emailRegex)) {
      this.setState({
        emailError: <Translation>{(t) => t("invalid_email")}</Translation>,
      });
      setTimeout(() => {
        this.setState({
          emailError: "",
        });
      }, 6000);
    } else if (!telephone) {
      this.setState({
        telephoneError: i18n.t("telephone_required"),
      });
      setTimeout(() => {
        this.setState({
          telephoneError: "",
        });
      }, 6000);
    } else if (!telephone.match(telephoneRegex)) {
      this.setState({
        telephoneError: (
          <Translation>{(t) => t("invalid_phone_number")}</Translation>
        ),
      });
      setTimeout(() => {
        this.setState({
          telephoneError: "",
        });
      }, 6000);
    } else if (!password) {
      this.setState({
        passwordError: i18n.t("password_required"),
      });
      setTimeout(() => {
        this.setState({
          passwordError: "",
        });
      }, 6000);
    } else if (password.length < 6) {
      this.setState({
        passwordError: i18n.t("password_min_length"),
      });
      setTimeout(() => {
        this.setState({
          passwordError: "",
        });
      }, 6000);
    } else if (password != confirmedPassword) {
      this.setState({
        passwordError: (
          <Translation>{(t) => t("confirm_password_not_match")}</Translation>
        ),
      });
      setTimeout(() => {
        this.setState({
          passwordError: "",
        });
      }, 6000);
    } else if (Object.keys(personalAddress) == 0) {
      this.setState({
        addressError: i18n.t("address_required"),
      });
      setTimeout(() => {
        this.setState({
          addressError: "",
        });
      }, 6000);
    } else {
      this.handleRegisterSaloon();
    }
  };

  validateBusinessInformation = () => {
    const {
      companyName,
      companyTagLine,
      companyShortDescription,
      companyEmail,
      companyTelephone,
      imageFile,
      functionRole,
      companyAddress,
      numberOfConcurrentBookings,
    } = this.state;
    console.log({
      companyName,
      companyTagLine,
      companyShortDescription,
      companyEmail,
      companyTelephone,
      imageFile,
      functionRole,
      companyAddress,
      numberOfConcurrentBookings,
    });
    const { addressMap } = companyAddress;
    const nameRegex =
      /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{2,}$/u;
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const telephoneRegex =
      /[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]/g;
    if (!imageFile) {
      this.setState({
        imageError: (
          <Translation>
            {(t) => t("container.company_logo_required")}
          </Translation>
        ),
      });
      setTimeout(() => {
        this.setState({
          imageError: "",
        });
      }, 6000);
    } else if (!companyName) {
      this.setState({
        companyNameError: (
          <Translation>
            {(t) => t("container.company_name_required")}
          </Translation>
        ),
      });
      setTimeout(() => {
        this.setState({
          companyNameError: "",
        });
      }, 6000);
    } else if (!companyName.match(nameRegex)) {
      this.setState({
        companyNameError: (
          <Translation>
            {(t) => t("container.company_minimum_lenght_required")}
          </Translation>
        ),
      });
      setTimeout(() => {
        this.setState({
          companyNameError: "",
        });
      }, 6000);
    } else if (!companyTagLine) {
      this.setState({
        companyTagLineError: (
          <Translation>
            {(t) => t("container.company_tag_line_required")}
          </Translation>
        ),
      });
      setTimeout(() => {
        this.setState({
          companyTagLineError: "",
        });
      }, 6000);
    } else if (companyTagLine.length < 0) {
      this.setState({
        companyTagLineError: (
          <Translation>
            {(t) => t("container.company_minimum_character_required")}
          </Translation>
        ),
      });
      setTimeout(() => {
        this.setState({
          companyTagLineError: "",
        });
      }, 6000);
    } else if (!addressMap || !Object.keys(addressMap).length) {
      this.setState({
        companyAddressError: (
          <Translation>
            {(t) => t("container.company_address_required")}
          </Translation>
        ),
      });
      setTimeout(() => {
        this.setState({
          companyAddressError: "",
        });
      }, 6000);
    } else if (!companyShortDescription) {
      this.setState({
        companyShortDescriptionError: (
          <Translation>
            {(t) => t("container.company_short_description_required")}
          </Translation>
        ),
      });
      setTimeout(() => {
        this.setState({
          companyShortDescriptionError: "",
        });
      }, 6000);
    } else if (companyShortDescription.length < 0) {
      this.setState({
        companyShortDescriptionError: (
          <Translation>
            {(t) =>
              t("container.company_short_description__minimum_length_required")
            }
          </Translation>
        ),
      });
      setTimeout(() => {
        this.setState({
          companyShortDescriptionError: "",
        });
      }, 6000);
    } else if (!companyEmail) {
      this.setState({
        companyEmailError: "Company email is required.",
      });
      setTimeout(() => {
        this.setState({
          companyEmailError: "",
        });
      }, 6000);
    } else if (!companyEmail.match(emailRegex)) {
      this.setState({
        companyEmailError: "Company email is invalid.",
      });
      setTimeout(() => {
        this.setState({
          companyEmailError: "",
        });
      }, 6000);
    } else if (!companyTelephone) {
      this.setState({
        companyTelephoneError: "Company telephone is required.",
      });
      setTimeout(() => {
        this.setState({
          companyTelephoneError: "",
        });
      }, 6000);
    } else if (!companyTelephone.match(telephoneRegex)) {
      this.setState({
        companyTelephoneError: (
          <Translation>{(t) => t("invalid_phone_number")}</Translation>
        ),
      });
      setTimeout(() => {
        this.setState({
          companyTelephoneError: "",
        });
      }, 6000);
    } else if (functionRole == "garage" && numberOfConcurrentBookings <= 0) {
      this.setState({
        numberOfConcurrentBookingsError: (
          <Translation>{(t) => t("greater_than_0")}</Translation>
        ),
      });
      setTimeout(() => {
        this.setState({
          companyTelephoneError: "",
        });
      }, 6000);
    } else {
      this.handleCreateSaloon();
    }
  };

  handleRegisterSaloon = () => {
    this.setState({ isloading: true });
    const {
      // Personal Information
      firstName,

      lastName,
      password,
      city,
      province,
      postalCode,
      telephone,
      email,
      personalAddress,
    } = this.state;

    let payload = {
      firstName,
      lastName,
      email,
      password,
      platform: "ios",
      phoneNo: telephone,
      role: "3",
    };

    const { addressMap } = personalAddress;
    if (Object.keys(addressMap).length) {
      payload = { ...payload, ...addressMap };
    }

    console.log("hello");

    this.props.register_saloon(payload);
  };

  handleCreateSaloon = () => {
    this.setState({ isloading: true });
    const {
      // Business Information
      companyName,
      companyTagLine,
      companyShortDescription,
      companyEmail,
      companyTelephone,
      latitude,
      longitude,
      access_token,
      imageFile,
      functionRole,
      numberOfConcurrentBookings,
      companyAddress,
    } = this.state;

    var formData = new FormData();

    formData.append("name", companyName);
    formData.append("companyTagLine", companyTagLine);
    formData.append("companyShortDescription", companyShortDescription);
    formData.append("businessEmail", companyEmail);
    formData.append("businessTelephone", companyTelephone);
    formData.append("latitude", latitude ? latitude : "741.22");
    formData.append("longitude", longitude ? longitude : "78456875");
    formData.append("function", functionRole);
    formData.append("numberOfConcurrentBookings", numberOfConcurrentBookings);
    const { addressMap } = companyAddress;
    Object.keys(addressMap).map((key) => {
      formData.append(key, addressMap[key]);
    });

    if (imageFile) {
      formData.append("image", imageFile);
    }
    this.setState({ isloading: true });
    this.props.create_saloon({ formData, access_token });
  };

  fileSelectedHandler = (event, type) => {
    const file = URL.createObjectURL(event.target.files[0]);
    this.setState({
      imageUrl: file,
      imageFile: event.target.files[0],
      imageError: "",
    });
  };

  renderUploadPicture = () => {
    const { imageUrl, imageFile, imageError } = this.state;

    return (
      <div className="col-12 d-md-flex align-items-md-center text-center mb-3">
        <input
          type="file"
          style={{ display: "none" }}
          onChange={(event) => this.fileSelectedHandler(event)}
          ref={(fileInput) => (this.fileInput = fileInput)}
          accept="image/*"
        />
        <span
          onClick={() => this.fileInput.click()}
          style={{
            backgroundImage: `url(${imageUrl ? imageUrl : Images.user_profile_image
              })`,
            cursor: "pointer",
          }}
          id="saloon-edit-profile"
        />
        <span style={{ marginLeft: "10px" }}>
          Please select your profile image
        </span>
        {imageError ? (
          <small className="form-text text-danger ml-3">{imageError}</small>
        ) : null}
        {/* <button type="button" className="btn btn-light btn-sm" id="saloon-delete-picture">Delete</button> */}
      </div>
    );
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  renderContentSection = () => {
    const { formTypeId, formTypeText } = this.state;
    return (
      <div className="container-fluid min-vh-100" id="main-container">
        <div className="container">
          <div className="row">
            <div className="col-12 p-md-5 p-3" id="register-saloon-card">
              {this.renderCardHeading()}
              {formTypeId === 2 && this.renderUploadPicture()}
              {this.renderStepSectionLg()}
              {this.renderStepSectionSm()}
              {formTypeId === 1 && <h2 id="started-heading">Get Started</h2>}
              {formTypeId === 1 && this.renderPersonalInformationForm()}
              {formTypeId === 2 && this.renderBusinessInformationForm()}
              <div className="col-12">
                <img
                  onClick={() => this.checkValidation()}
                  src={Images.saloon_form_submit}
                  id="saloon-form-submit"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderCardHeading = () => {
    return (
      <>
        <h1 id="register-heading">Register yourself as a salon store</h1>
        <hr id="register-heading-line" />
      </>
    );
  };

  renderStepSectionLg = () => {
    const { formTypeId } = this.state;
    return (
      <div
        className="col-12 col-md-6 p-4"
        id="step-section-lg"
        style={{ backgroundColor: "#F4F6F8", borderRadius: 7 }}
      >
        <div className="row">
          <hr id="steps-dots-line" />
          <div className="col-3 d-flex align-items-center">
            {(formTypeId === 1 ||
              formTypeId === 2 ||
              formTypeId === 3 ||
              formTypeId === 4) && (
                <img src={Images.step_done_tick} className="step_done_tick" />
              )}
            <span className="steps-text">
              <Translation>{(t) => t("container.personal_info")}</Translation>
            </span>
          </div>
          <div className="col-3 d-flex align-items-center">
            {(formTypeId === 2 || formTypeId === 3 || formTypeId === 4) && (
              <img src={Images.step_done_tick} className="step_done_tick" />
            )}
            <span className="steps-text">
              <Translation>{(t) => t("container.business_info")}</Translation>
            </span>
          </div>
        </div>
      </div>
    );
  };

  renderStepSectionSm = () => {
    const { formTypeId, formTypeText } = this.state;
    return (
      <div
        className="col-12 p-3 py-md-4"
        id="step-section-sm"
        style={{ backgroundColor: "#FFF6EF" }}
      >
        <div className="row">
          <div className="col-12 d-flex align-items-center">
            <span className="step_no">{formTypeId}</span>
            <span className="steps-text-sm">{formTypeText}</span>
          </div>
        </div>
      </div>
    );
  };

  renderInputCol6 = (
    value,
    id,
    lable,
    inputType,
    onChange,
    errorMessage,
    props = {}
  ) => {
    return (
      <div class="form-group col-md-6 px-0 px-md-3 pt-md-1 pb-md-1">
        <TextField
          id={id}
          label={lable}
          className="w-100 mt-1"
          onChange={onChange}
          value={value}
          type={inputType}
          error={!!errorMessage}
          helperText={errorMessage}
          {...props}
        />
      </div>
    );
  };

  renderInputCol12 = (value, id, lable, inputType, onChange, errorMessage) => {
    return (
      <div class="form-group col-12 px-0 px-md-3">
        <label htmlFor={id} className="saloon-form-lable">
          {lable}:
        </label>
        <input
          value={value}
          type={inputType}
          class="form-control saloon-form-input"
          id={id}
          onChange={(text) => onChange(text)}
        />
        {errorMessage ? (
          <small className="form-text text-danger ml-3">{errorMessage}</small>
        ) : null}
      </div>
    );
  };

  renderPersonalInformationForm = () => {
    const {
      firstName,
      lastName,
      password,
      email,
      firstNameError,
      lastNameError,
      passwordError,
      addressError,
      telephoneError,
      emailError,
      confirmedPassword,
    } = this.state;
    return (
      <div className="col-12 px-0 px-md-3">
        <h3 className="form-heading">
          <Translation>{(t) => t("container.personal_info")}</Translation>:
        </h3>
        <div class="form-row">
          {this.renderInputCol6(
            firstName,
            "first-name",
            <Translation>{(t) => t("first_name")}</Translation>,
            "text",
            this.onChangeFirstName,
            firstNameError
          )}
          {this.renderInputCol6(
            lastName,
            "last-name",
            <Translation>{(t) => t("last_name")}</Translation>,
            "text",
            this.onChangeLastName,
            lastNameError
          )}
        </div>
        <div class="form-row">
          {this.renderInputCol6(
            email,
            "email",
            "Email",
            "email",
            this.onChangeEmail,
            emailError
          )}
          <div class="form-group col-md-6 px-0 px-md-3 pt-md-1 pb-md-1">
            <PhoneNumberFormat
              // {console.log(telephoneError)}
              errorMessage={telephoneError}
              phoneUpdate={(e) => this.onChangeTelephone(e)}
            />
          </div>
        </div>
        <div class="form-row">
          {this.renderInputCol6(
            password,
            "password",
            <Translation>{(t) => t("password")}</Translation>,
            "password",
            this.onChangePassword,
            passwordError
          )}
          {this.renderInputCol6(
            confirmedPassword,
            "confirm-password",
            <Translation>{(t) => t("container.confirm_password")}</Translation>,
            "password",
            this.OnChangeConfirmedPassword,
            passwordError
          )}
        </div>
        <div class="form-row col-md-12">
          <div className="form-group col-md-12 px-0 px-md-6 pt-md-6 pb-md-6">
            {this.renderAddress("personalAddress", addressError)}
          </div>
        </div>
      </div>
    );
  };
  inputTypeNumber2 = (id, lable, inputType, onChange, errorMessage) => {
    return (
      <TextField
        id={id}
        // label="company short description"
        className="form-control"
        onChange={(text) => onChange(text)}
        value={this.state.companyTelephone}
      // type={inputType}
      />
    );
  };

  renderBusinessInformationForm = () => {
    const {
      companyName,
      companyTagLine,
      companyShortDescription,
      companyEmail,
      companyTelephone,

      imageError,
      companyNameError,
      companyAddressError,
      companyTagLineError,
      companyShortDescriptionError,
      companyEmailError,
      companyTelephoneError,
      functionRoleError,
      concurrentBookingsError,
      functionRole,
      numberOfConcurrentBookings,
      numberOfConcurrentBookingsError,
    } = this.state;
    return (
      <div className="col-12 px-0 px-md-3">
        <h3 className="form-heading">
          {" "}
          <Translation>{(t) => t("container.business_info")}</Translation>:
        </h3>

        <div class="form-row">
          {this.renderInputCol6(
            companyName,
            "company-name",
            i18n.t("container.company_name"),
            "text",
            this.onChangeCompanyName,
            companyNameError
          )}
          {this.renderInputCol6(
            companyTagLine,
            "company-tag-line",
            i18n.t("container.company_tag_line_required"),
            "text",
            this.onChangeCompanyTagLine,
            companyTagLineError,
            "65"
          )}
          <div class="form-group col-md-12 px-0 px-md-3 pt-md-1 pb-md-1">
            {this.renderAddress("companyAddress")}
            {companyAddressError ? (
              <small className="form-text text-danger ml-3">
                {companyAddressError}
              </small>
            ) : null}
          </div>
          <div className="form-group col-md-12 px-0 px-md-3 pt-md-1 pb-md-1">
            <TextField
              id=""
              label="Company short description"
              className=" col-md-12 px-0"
              onChange={(text) => this.onChangeCompanyShortDescription(text)}
              value={companyShortDescription}
              multiline
              rows={3}
              rowsMax={4}
              error={!!companyShortDescriptionError}
              helperText={companyShortDescriptionError}
            />
          </div>

          {this.renderInputCol6(
            companyEmail,
            "email",
            <Translation>{(t) => t("container.profile_email")}</Translation>,
            "email",
            this.onChangeCompanyEmail,
            companyEmailError
          )}
          <div className="form-group col-md-6 px-0 px-md-3 pt-md-1 pb-md-1">
            <PhoneNumberFormat
              phoneUpdate={(e) => this.setStateForPhonenumber(e)}
              error={!!companyTelephoneError}
              helperText={companyTelephoneError}
            />
          </div>
          <div class="form-group col-md-6 px-0 px-md-3 pt-md-1 pb-md-1">
            <Select
              label={i18n.t("role")}
              value={functionRole}
              onChange={this.onChangeFunctionRole}
              options={[
                { name: i18n.t("salon"), value: "salon" },
                { name: i18n.t("garage"), value: "garage" },
              ]}
              error={functionRoleError}
              excludeEmptyValue
            />
          </div>
          {functionRole == "garage"
            ? this.renderInputCol6(
              numberOfConcurrentBookings,
              "numberOfConcurrentBookings",
              <Translation>
                {(t) => t("number_of_concurrent_bookings")}
              </Translation>,
              "number",
              this.onChangeNumberOfConcurrentBookings,
              numberOfConcurrentBookingsError
            )
            : null}
        </div>
      </div>
    );
  };

  openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  renderFooterLogoSection = () => {
    const socialIcon = [
      Images.saloon_footer_facebook,
      Images.saloon_footer_twitter,
      Images.saloon_footer_instagram,
      Images.saloon_footer_youtube,
    ];
    return (
      <div
        className="container-fluid py-4"
        style={{ backgroundColor: "#fff3eb" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-5 col-md-6 px-0 px-md-3 d-flex align-item-center">
              <img src={Images.easy1_logo_800x300} id="footer-logo" />
            </div>
            <div className="col-7 col-md-6 px-0 px-md-3 d-flex justify-content-end align-items-center">
              {socialIcon.map((val, ind) => {
                return (
                  <span key={ind}>
                    <img src={val} className="saloon_footer_icon" />
                  </span>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderFooterTextSection = () => {
    return (
      <div className="container-fluid" style={{ backgroundColor: "#ff3600" }}>
        <div className="container">
          <div className="row">
            <div className="col-12 py-4">
              <h6 className="h6 mb-0 text-white text-center">
                &copy; Copyright 2020 - 2021 Easy 1. All Right Reserved
              </h6>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { myPlan, plans } = this.state;
    // alert("hello");
    return (
      <div style={{ backgroundColor: "#F7F8F7" }}>
        {this.renderLoader()}
        <div className="container-fluid">
          <div className="container">
            <Navbar {...this.props} isBackgroundColor={true} />
          </div>
        </div>

        {this.renderContentSection()}
        {this.renderFooterLogoSection()}
        {this.renderFooterTextSection()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  saloonRegister: state.saloonRegister,
  createSaloon: state.createSaloon,
  saloonPaymentMethod: state.saloonPaymentMethod,
});

const action = {
  register_saloon,
  create_saloon,
  create_saloon_plan,
  saloon_payment_method,
  saloon_logout,
};

export default connect(mapStateToProps, action)(SaloonRegister);
