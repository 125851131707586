import React from "react"
import TextField from "@material-ui/core/TextField"
import PrimaryButton from "../Core/PrimaryButton"
import { FormHelperText, FormControl } from "@material-ui/core"
export const validateAndRound = (value, max) => {
  let num = parseFloat(value)
  if (isNaN(num) || num < 0) {
    num = 0
  }
  if (max && num > max) {
    num = max
  }
  num = Math.round((num + Number.EPSILON) * 100) / 100
  return num
}

const CounterInput = ({
  value,
  onChange = () => {},
  onBlur = () => {},
  error,
  readOnly,
  max,
  type = "number",
}) => {
  const handleChange = (text) => {
    onChange(text.target.value)
  }
  const handleBlur = (text) => {
    onChange(validateAndRound(text.target.value, max))
  }
  const increase = (text) => {
    onChange(parseFloat(text) + 1)
  }
  const decrease = (text) => {
    onChange(parseFloat(text) - 1)
  }
  return (
    <div className="d-flex flex-column">
      <div>
        <PrimaryButton
          className="w-34 mr-2"
          onClick={() => {
            decrease(value)
          }}
        >
          <div style={{ fontSize: 30 }}>-</div>
        </PrimaryButton>
        <TextField
          type={type}
          variant="outlined"
          onChange={handleChange}
          onBlur={onBlur}
          onFocus={(e) => {
            if (!readOnly) {
              e.target.select()
            }
          }}
          value={value}
          error={Boolean(error)}
          style={{ width: 100 }}
          className="textAlign"
          readOnly
        />
        <PrimaryButton className="w-34 ml-2" onClick={() => increase(value)}>
          <div style={{ fontSize: 30 }}>+</div>
        </PrimaryButton>
      </div>

      <FormHelperText error={Boolean(error)}>{error}</FormHelperText>
    </div>
  )
}

export default CounterInput
