import { DotsLoader } from "../../../components"
import PrimaryButton from "../../../components/Core/PrimaryButton/index"
import React, { useEffect, useRef, useState } from "react"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import FormContainer from "../../../components/Core/FormContainer/FormContainer"
import Header from "../../../components/Core/Header/Header"
import { FormControlLabel, Switch } from "@material-ui/core"
import { getSaloonById, updateRatingLink } from "../../../config/simpleApiCalls"
import { ErrorHelper, SuccessHelper } from "../../../helpers"
import i18n from "../../../i18n"
import { withStyles } from "@material-ui/core/styles"
import Swal from "sweetalert2"
import EmployeeRatings from "./EmployeeRatings"
const YellowSwitch = withStyles({
  switchBase: {
    color: "#E6E6E6",
    "&$checked": {
      color: "var(--warning-color)!important",
    },
    "&$checked + $track": {
      backgroundColor: "transparent",
      border: "1px solid var(--warning-color)",
      opacity: 1,
    },
  },
  checked: {},
  track: {},
})(Switch)

export default function Rating() {
  const [isLoading, setIsLoading] = useState(false)
  const form = useRef()

  const [ratingLink, setRatingLink] = useState("")
  const [sendEvaluation, setSendEvaluation] = useState(false)
  const [useExternalRating, setUseExternalRating] = useState(false)
  useEffect(() => {
    setIsLoading(true)
    getSaloonById()
      .then(({ data }) => {
        setIsLoading(false)
        if (!data.success) {
          return
        }
        const {
          data: { ratingLink, sendEvaluation, useExternalRating },
        } = data
        setRatingLink(ratingLink)
        setSendEvaluation(sendEvaluation)
        setUseExternalRating(useExternalRating)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }, [])

  const onRatingLinkSave = () => {
    setIsLoading(true)
    updateRatingLink({ ratingLink, sendEvaluation, useExternalRating })
      .then(() => {
        SuccessHelper.handleSuccess(i18n.t("successfully_updated"), true)
        setIsLoading(false)
      })
      .catch(() => {
        ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true)
        setIsLoading(false)
      })
  }

  const handleToggle = (e) => {
    Swal.fire({
      title: e.target.checked
        ? i18n.t("payment_agreement")
        : i18n.t("cancel_send_evaluation"),
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: i18n.t("no"),
      confirmButtonColor: "#ff3600",
      cancelButtonColor: "#354e68",
      confirmButtonText: i18n.t("yes"),
    }).then((result) => {
      if (result.value) {
        setSendEvaluation(!sendEvaluation)
      }
    })
  }
  return (
    <>
      <DotsLoader isloading={isLoading} />
      <div className="content-container p-4">
        <Header text="rating" />

        <ValidatorForm
          onSubmit={onRatingLinkSave}
          ref={form}
          className="w-100"
          onError={(error) => {
            if (!error.length) {
              onRatingLinkSave()
            }
          }}
        >
          <FormContainer>
            <div>
              <FormControlLabel
                control={
                  <YellowSwitch
                    checked={sendEvaluation}
                    onChange={handleToggle}
                  />
                }
                label={i18n.t("send_evaluation")}
              />
            </div>
            {sendEvaluation ? (
              <div className="col-12 d-flex flex-column px-0">
                <div>
                  <FormControlLabel
                    control={
                      <YellowSwitch
                        checked={useExternalRating}
                        onChange={(e) => setUseExternalRating(e.target.checked)}
                      />
                    }
                    label={i18n.t("use_external_rating")}
                  />
                </div>
                {useExternalRating ? (
                  <>
                    <label className="font-weight-bold mr-3">
                      {i18n.t("rating_link")}
                    </label>
                    <div style={{ width: 300 }}>
                      <TextValidator
                        label={i18n.t("rating_link")}
                        onChange={(e) => setRatingLink(e.target.value)}
                        value={ratingLink}
                        validators={
                          useExternalRating
                            ? [
                                "matchRegexp:(https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,})",
                                "required",
                              ]
                            : []
                        }
                        errorMessages={
                          useExternalRating
                            ? [i18n.t("invalid_url"), i18n.t("url_required")]
                            : []
                        }
                        placeholder="https://www.example.com"
                        variant="outlined"
                      />
                    </div>
                  </>
                ) : null}
              </div>
            ) : null}

            <PrimaryButton
              className="w-128 mt-4"
              props={{
                type: "submit",
              }}
              label={i18n.t("save")}
            />
          </FormContainer>
        </ValidatorForm>
        <div className="mt-5">
          <EmployeeRatings />
        </div>
      </div>
    </>
  )
}
