// @flow
import Immutable from "seamless-immutable"
import * as types from "../../actions/ActionTypes"

const initialState = Immutable({
  isFetching: false,
  errorMessage: "",
  data: [],
  categoryReport: [],
  attendanceReport: [],
  customerHistory: [],
  activityReport: [],
  activitySessions: [],
  availableEvents: [],
})

export default (state = initialState, action) => {
  switch (action.type) {
    case types.ACTIVITY_SCHEDULE_EVENT.GET:
      return Immutable.merge(state, {
        isFetching: false,
        data: action.data,
      })
    case types.ACTIVITY_SCHEDULE_EVENT.GET_SAGA:
    case types.ACTIVITY_SCHEDULE_EVENT.APPLY_TEMPLATE_SAGA:
    case types.ACTIVITY_SCHEDULE_EVENT.UPSERT_EVENT_SAGA:
    case types.ACTIVITY_SCHEDULE_EVENT.DELETE_EVENT_SAGA:
    case types.ACTIVITY_SCHEDULE_EVENT.SET_CUSTOMERS_SAGA:
    case types.ACTIVITY_SCHEDULE_EVENT.SET_ATTENDANCE_SAGA:
    case types.ACTIVITY_SCHEDULE_EVENT.CATEGORY_REPORT_SAGA:
    case types.ACTIVITY_SCHEDULE_EVENT.ATTENDANCE_REPORT_SAGA:
    case types.ACTIVITY_SCHEDULE_EVENT.ACTIVITY_REPORT_SAGA:
    case types.ACTIVITY_SCHEDULE_EVENT.GET_BY_CUSTOMER_SAGA:
    case types.ACTIVITY_SCHEDULE_EVENT.GET_BY_ACTIVITY_REFERENCE_SAGA:
    case types.ACTIVITY_SCHEDULE_EVENT.GET_AVAILABLE_EVENTS_SAGA:
    case types.ACTIVITY_SCHEDULE_EVENT.ADD_TRANSACTION_SAGA:
    case types.ACTIVITY_SCHEDULE_EVENT.REVERSE_TRANSACTION_SAGA:
      return Immutable.merge(state, {
        isFetching: true,
      })
    case types.ACTIVITY_SCHEDULE_EVENT.GET_BY_CUSTOMER:
      return Immutable.merge(state, {
        isFetching: false,
        customerHistory: action.data,
      })
    case types.ACTIVITY_SCHEDULE_EVENT.GET_BY_ACTIVITY_REFERENCE:
      return Immutable.merge(state, {
        isFetching: false,
        activitySessions: action.data,
      })
    case types.ACTIVITY_SCHEDULE_EVENT.GET_AVAILABLE_EVENTS:
      return Immutable.merge(state, {
        isFetching: false,
        availableEvents: action.data,
      })
    case types.ACTIVITY_SCHEDULE_EVENT.APPLY_TEMPLATE: {
      const newState = {
        ...state,
        isFetching: false,
        data: [...state.data, ...action.data],
      }
      return newState
    }
    case types.ACTIVITY_SCHEDULE_EVENT.UPSERT_EVENT: {
      const newState = { ...state, isFetching: false, data: [...state.data] }
      const { _id } = action.data
      const index = state.data.findIndex((element) => element._id == _id)
      if (index != -1) {
        newState.data[index] = action.data
      } else {
        newState.data.push(action.data)
      }
      return newState
    }
    case types.ACTIVITY_SCHEDULE_EVENT.ADD_TRANSACTION:
    case types.ACTIVITY_SCHEDULE_EVENT.REVERSE_TRANSACTION: {
      const newCustomerHistory = state.customerHistory.map((element) => {
        if (
          element.customerActivityScheduleEvent &&
          element.customerActivityScheduleEvent._id === action.data._id
        ) {
          return {
            ...element,
            customerActivityScheduleEvent: action.data,
          }
        }
        return element
      })
      const newData = state.data.map((element) => {
        if (
          element.customerActivityScheduleEvents &&
          element.customerActivityScheduleEvents._id === action.data._id
        ) {
          return {
            ...element,
            customerActivityScheduleEvents: action.data,
          }
        }
        return element
      })

      const newState = {
        ...state,
        isFetching: false,
        data: newData,
        customerHistory: newCustomerHistory,
      }

      return newState
    }
    case types.ACTIVITY_SCHEDULE_EVENT.SET_CUSTOMERS:
    case types.ACTIVITY_SCHEDULE_EVENT.SET_ATTENDANCE: {
      const newState = { ...state, isFetching: false, data: [...state.data] }
      const { _id } = action.data
      const index = state.data.findIndex((element) => element._id == _id)
      if (index != -1) {
        newState.data[index] = {
          ...newState.data[index],
          customers: action.data.customers,
        }
      }
      return newState
    }
    case types.ACTIVITY_SCHEDULE_EVENT.DELETE_EVENT: {
      const newState = { ...state, isFetching: false, data: [...state.data] }
      const { _id } = action.data
      const index = state.data.findIndex((element) => element._id == _id)
      if (index != -1) {
        newState.data.splice(index, 1)
      }
      return newState
    }
    case types.ACTIVITY_SCHEDULE_EVENT.CATEGORY_REPORT: {
      const newState = {
        ...state,
        isFetching: false,
        categoryReport: [...action.data],
      }
      return newState
    }
    case types.ACTIVITY_SCHEDULE_EVENT.ATTENDANCE_REPORT: {
      const newState = {
        ...state,
        isFetching: false,
        attendanceReport: [...action.data],
      }
      return newState
    }
    case types.ACTIVITY_SCHEDULE_EVENT.ACTIVITY_REPORT: {
      const newState = {
        ...state,
        isFetching: false,
        activityReport: [...action.data],
      }
      return newState
    }
    case types.ACTIVITY_SCHEDULE_EVENT.REQUEST_FAILURE:
      return Immutable.merge(state, {
        isFetching: false,
      })

    case types.LOGOUT:
      return initialState
    case types.CLEAR_LOGOUT:
      return initialState
    default:
      return state
  }
}
