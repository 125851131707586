import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import "../Styles/selectOptions.sass";

class MultipleChoiceOption extends Component {
  handleMultipleChoiceFields = (e) => {
    this.props.handleSelect(e.target.value);
  };

  render() {
    const { fieldOptions, fieldOptionsError } = this.props;
    return (
      <React.Fragment>
        <h4 className="radioTitle">
          Please provide the choices for the multiple choice question:
        </h4>

        <TextField
          label="Enter Options separated by - (Hyphen symbol)"
          onChange={(e) => this.handleMultipleChoiceFields(e)}
          variant="outlined"
          fullWidth={true}
          value={fieldOptions}
        />

        {fieldOptionsError ? <span>{fieldOptionsError}</span> : ""}
      </React.Fragment>
    );
  }
}

export default MultipleChoiceOption;
