import React, { useEffect, useRef, useState } from "react";
import i18n from "../../../../i18n";
import {
  ValidatorForm,
  TextValidator,
} from "react-material-ui-form-validator";
import PrimaryButton from "../../../../components/Core/PrimaryButton";
import { BaseModal } from "../../../../components";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ToggleSelect from "../../../../components/ToggleSelect";
import CounterInput from "../../../../components/CounterInput";

export default function SubscriptionUpsert({
  open,
  onClose,
  onSave,
  active = {},
  allServices = [],
}) {
  const form = useRef();
  const [data, setData] = useState({});

  useEffect(() => {
    if (open) {
      const serviceMap = {};
      allServices.map((service) => {
        serviceMap[service._id] = service;
      });
      setData({
        numberOfSessionsPerWeek: 1,
        ...active,
        services: [
          ...(active.services || []).map((serviceId) => serviceMap[serviceId]),
        ],
      });
    }
  }, [open, active._id]);

  const handleChange = (key, value) => {
    data[key] = value;
    setData({ ...data });
  };

  const handleSubmit = () => {
    const { services = [] } = data;
    onSave({ ...data, services: services.map((service) => service._id) });
  };

  return (
    <div>
      {open ? (
        <BaseModal
          open={open}
          title={
            data._id ? i18n.t("edit_subscription") : i18n.t("add_subscription")
          }
          containerClassName="half-container"
          content={
            <div className="d-flex">
              <ValidatorForm
                onSubmit={() => handleSubmit({ name: data.name })}
                ref={form}
                className="w-100 row mx-0"
                onError={(error) => {
                  if (!error.length) {
                    onSave(data);
                  }
                }}
              >
                <div className="col-12 px-0 d-sm-flex align-items-center mb-3">
                  <label className="font-weight-bold mobile-w-100" style={{ minWidth: '200px' }}>{i18n.t("name")}</label>
                  <div style={{ width: "100%" }}>
                    <TextValidator
                      label={i18n.t("name")}
                      onChange={(e) => handleChange("name", e.target.value)}
                      value={data.name || ""}
                      validators={["required"]}
                      errorMessages={[i18n.t("name_is_required")]}
                      variant="outlined"
                      className="w-100"
                    />
                  </div>
                </div>
                <div className="col-12 px-0 d-sm-flex align-items-center mb-3">
                  <label className="font-weight-bold mobile-w-100" style={{ minWidth: '200px' }}>{i18n.t("price")}</label>
                  <CounterInput
                    value={data.price}
                    label={i18n.t("price")}
                    onChange={(e) => handleChange("price", e)}
                  />
                </div>
                <div className="col-12 px-0 d-sm-flex align-items-center mb-3">
                  <label className="font-weight-bold mobile-w-100" style={{ minWidth: '200px' }}>{i18n.t("numberOfSessionsPerWeek")}</label>
                  <CounterInput
                    value={data.numberOfSessionsPerWeek}
                    label={i18n.t("numberOfSessionsPerWeek")}
                    onChange={(e) => handleChange("numberOfSessionsPerWeek", e)}
                  />
                </div>
                <div className="col-12 px-0 d-sm-flex align-items-center mb-3">
                  <label className="font-weight-bold mobile-w-100" style={{ minWidth: '200px' }}>{i18n.t("all_services")}</label>
                  <ToggleSelect
                    value={data.allServices}
                    onChange={(e) => handleChange("allServices", e)}
                    active="Yes"
                    inactive="No"
                  />
                </div>
                <div className="col-12 px-0 d-sm-flex align-items-center mb-3">
                  <label className="font-weight-bold mobile-w-100" style={{ minWidth: '200px' }}>{i18n.t("services")}</label>
                  <Autocomplete
                    multiple
                    options={allServices}
                    getOptionLabel={(option) => option.name}
                    value={data.services || []}
                    onChange={(e, value = []) => {
                      handleChange("services", value);
                    }}
                    disableCloseOnSelect={true}
                    renderInput={(params) => (
                      <TextValidator {...params} label={i18n.t("services")} variant="outlined" />
                    )}
                    className="w-100"
                  />
                </div>
                <div className="row mx-0 px-0 justify-content-end my-3 col-12">
                  <PrimaryButton
                    className="w-128"
                    props={{
                      type: "submit",
                    }}
                    label={data._id ? i18n.t("save") : i18n.t("create")}
                  />
                </div>
              </ValidatorForm>
            </div>
          }
          onClose={onClose}
        />
      ) : null}
    </div>
  );
}
