// @flow

import { SALOON_LOGOUT } from "../ActionTypes"

export function request(payload) {
  return {
    payload,
    type: SALOON_LOGOUT.REQUEST,
  }
}

export function success(data) {
  return {
    data,
    type: SALOON_LOGOUT.SUCCESS,
  }
}

export function failure(errorMessage) {
  return {
    errorMessage,
    type: SALOON_LOGOUT.FAILURE,
  }
}
