// @flow

import { CARD } from "./ActionTypes"

export function getRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: CARD.GET_SAGA,
  }
}

export function set({ cards }) {
  return {
    data: cards,
    type: CARD.SET,
  }
}

export function removeRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: CARD.DELETE_SAGA,
  }
}

export function remove(data) {
  return {
    data,
    type: CARD.DELETE,
  }
}

export function insertRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: CARD.INSERT_SAGA,
  }
}

export function insert(data) {
  return {
    data,
    type: CARD.INSERT,
  }
}

export function updateRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: CARD.UPDATE_SAGA,
  }
}

export function update(data) {
  return {
    data,
    type: CARD.UPDATE,
  }
}

export function requestFailure() {
  return {
    type: CARD.REQUEST_FAILURE,
  }
}
