import { connect } from "react-redux"
import React, { Component, useContext } from "react"
import { Translation } from "react-i18next"
import i18n from "../../../i18n"
import Avatar from "react-avatar"
import moment from "moment"
import { SuccessHelper } from "../../../helpers"
import DatePicker from "react-datepicker"
import { request as get_available_timeslots } from "../../../redux/actions/GetAvailableTimeslots.js"
import { clear } from "../../../redux/actions/ActiveBooking"
import SecondaryButton from "../../../components/Core/SecondaryButton/SecondaryButton.jsx"
import { ScreenSizeContext } from "../../../context/ScreenSize.jsx"
import { Images } from "../../../theme"
import BookingNotesReminders from "../../../components/BookingNotesReminders"
import {
  DotsLoader,
  AdvancedSearchDialog,
  BaseModal,
  AppointmentModal,
} from "../../../components"
import {
  getEmployeeDetails,
  getServices,
  gitWaitingAppoinments,
  editBooking,
  getEmployeesBySaloonAndService,
  addProduct,
  addExtra,
  removeProduct,
  updateCartProduct,
  removeExtra,
  dismissCancelledBooking,
} from "../../../config/simpleApiCalls"
import { ErrorHelper } from "../../../helpers"
import { getNestedValue } from "../../../util/objectMethods"
import Swal from "sweetalert2"
import "./styles.css"
import CalendarSidebar from "../../../components/CalendarView/components/Sidebar"
import CalendarSidebarContent from "../../../components/CalendarView/components/SidebarContent"
import PlanView from "../../../components/CalendarView/components/PlanView"
import AddExtra from "../../../components/Extras/AddExtra"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import FormHelperText from "@material-ui/core/FormHelperText"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import TextField from "@material-ui/core/TextField"
import useGoogleCalendar from "../../../context/GoogleCalendar"
import useOutlookCalendar from "../../../context/OutlookCalendar"
import Button from "@material-ui/core/Button"
import RefreshIcon from "@material-ui/icons/Refresh"
import { BookingContext } from "../../../context/Booking"
import ModifyModal from "../../../components/NotifyModal/ModifyModal"
import PrimaryButton from "../../../components/Core/PrimaryButton/index.jsx"
import EditIcon from "../../../components/Core/BasicTable/Actions/EditIcon"
import SearchIcon from "@material-ui/icons/Search"
import useCache from "../../../context/LocalCache"
import SharedStorage from "../../../helpers/Storage"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { withRouter } from "react-router-dom"
import Settings from "../../Saloon/MyAppointments/settings.js"
const weekNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednessday",
  "Thursday",
  "Friday",
  "Saturday",
]
const statusMap = {
  2: "container.services_now_serving_status",
  3: "cancelled",
  4: "container.services_done_status",
  7: "cancelled_no_show",
}
class _AddWorkingHours extends Component {
  constructor(props) {
    super(props)
    const today = moment(moment.now()).format("DD-MM-YYYY")
    const queryParams = new URLSearchParams(this.props.location.search)
    const shouldHideCalendar = queryParams.get("displayTable")
    this.state = {
      firstName: "",
      lastName: "",
      postalCode: "",
      email: "",
      daysMock: [
        { name: "---" },
        { name: "Sun" },
        { name: "Mon" },
        { name: "Tue" },
        { name: "Wed" },
        { name: "Thu" },
        { name: "Fri" },
        { name: "Sat" },
      ],
      weekPlans: [],
      phoneNumber: "",
      totalAmount: "",
      allSaloons: [],
      selectPaymentMethod: "",
      selectedCategory: null,
      selectSalon: "",
      selectServices: "",
      selectedService: "",
      companyId: null,
      selectEmployee: "ddddd",
      categoryList: [],
      getSalonServices: [],
      selectDateAndTime: "",
      selectedTime: "",
      openUpdateModify: false,

      formErrors: {
        firstNameError: "",
        lastNameError: "",
        postalCodeError: "",
        phoneNumberError: "",
        emailError: "",
        totalAmountError: "",
        selectPaymentMethodError: "",
        selectServicesError: "",
        selectDateAndTimeError: "",
        selectedTimeError: "",
      },

      bookingStauses: [
        { text: "Waiting", value: 1, styles: { color: "#aa43b5" } },
        { text: "Now Serving", value: 3, styles: { color: "#FFE478" } },
        { text: "Cancel", value: 2, styles: { color: "#FF7082" } },
        { text: "Done", value: 4, styles: { color: "#95DA47" } },
      ],
      employeesWorkingHoursData: [
        { dayName: "Sunday", dayOfWeek: 1, availableStatus: 0 },
        { dayName: "Monday", dayOfWeek: 2, availableStatus: 0 },
        { dayName: "Tuesday", dayOfWeek: 3, availableStatus: 0 },
        { dayName: "Wednesday", dayOfWeek: 4, availableStatus: 0 },
        { dayName: "Thursday", dayOfWeek: 5, availableStatus: 0 },
        { dayName: "Friday", dayOfWeek: 6, availableStatus: 0 },
        { dayName: "Saturday", dayOfWeek: 7, availableStatus: 0 },
      ],
      showPopUp: false,

      serviceList: [
        { name: "Hair Cutting", isSelected: false },
        { name: "Hair Cutting", isSelected: true },
        { name: "Hair Cutting", isSelected: false },
        { name: "Hair Cutting", isSelected: false },
      ],
      isVerified: false,
      employeeServicesList: [],
      employeesWorkingHoursError: false,
      hasSchedule: false,
      allAppoin: [],
      searchText: "",
      availableEmployees: [],
      notes: "",
      filtersMap: {
        status: {
          1: true,
          2: true,
          3: true,
          4: true,
        },
      },
      displayCalendar: !shouldHideCalendar,
      activeDate: null,
      calendarView: "day",
    }
  }

  onChangeTime = (text) => {
    const { availableTimeslots, getEmployeeBySalonData } = this.state
    const availableEmployees = availableTimeslots[text.target.value]
      .map((employeeId) => {
        return getEmployeeBySalonData.find(
          (employee) =>
            getNestedValue(employee, ["employeeId", "_id"], "") == employeeId
        )
      })
      .filter((employee) => !!employee)
    this.setState({
      selectedTime: text.target.value,
      newTime: text.target.value.split(" ")[0],
    })
  }

  onChangeTimeText = (text, key = "newTime") => {
    this.setState({
      selectedTime: text.target.value,
      [key]: text.target.value,
    })
  }

  async componentDidMount() {
    const { displayCalendar } = this.state
    if (!displayCalendar) {
      this.gitWaitingAppoinments(true)
    }

    const employeeData = await JSON.parse(
      await SharedStorage.getItem("employee")
    )
    this.setState({
      selectedCalView: employeeData?.selectedCalView || "day",
      employee: employeeData,
    })

    if (employeeData) {
      this.setState({ activeDate: Date.now() })
      this.getEmployeeData()
      this.getAllServices()
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let toSet = {}
    if (nextProps.getAvailableTimeslots) {
      if (
        !nextProps.getAvailableTimeslots.failure &&
        !nextProps.getAvailableTimeslots.isFetching &&
        !nextProps.getAvailableTimeslots.errorMessage &&
        nextProps.getAvailableTimeslots.data &&
        nextProps.getAvailableTimeslots.data.data
      ) {
        toSet = {
          ...toSet,
          isloading: false,
        }
        if (!prevState.lockFields) {
          toSet.availableTimeslots = nextProps.getAvailableTimeslots.data.data
        }
      } else if (nextProps.getAvailableTimeslots.isFetching) {
        toSet = { ...toSet, isloading: true }
      }
    }

    if (nextProps.booking) {
      nextProps.clear()
      const service = getNestedValue(nextProps.booking, ["services", 0], {})
      const booking = { ...nextProps.booking }
      toSet = {
        ...toSet,
        showBookingModal: true,
        selectBooking: booking,
        activeDate: moment(service.date, "MM-DD-YYYY").toDate(),
        selectBookingAmount: nextProps.booking.totalAmount,
        paymentMethod: nextProps.booking.paymentMethod,
        bookingStaus: nextProps.booking.status,
        estimateDuration: service.duration,
        editDetails: false,
      }
    }

    const { taxMap, productMap, companies } = nextProps
    const { filtersMap = {} } = prevState

    const allSaloonTaxMap = taxMap
    const allProductMap = productMap
    const allSaloons = companies.filter((salon) => !!salon.companyId)
    const allSaloonMap = {}
    let start = undefined
    allSaloons.map((saloon) => {
      saloon.weekPlans.map((dayPlan) => {
        const { checkIn } = dayPlan
        if (checkIn) {
          let hour = parseInt(checkIn.split(":")[0])
          if (start) {
            if (start > hour) {
              start = hour
            }
          } else {
            start = hour
          }
        }
      })
      allSaloonMap[saloon.companyId._id] = saloon
    })
    const allServices = []
    filtersMap.service = {}
    Object.values(allSaloonMap).map((saloon) => {
      saloon.companyServices.map((companyService) => {
        if (getNestedValue(companyService.serviceId, ["name"])) {
          allServices.push(getNestedValue(companyService.serviceId, ["name"]))
          filtersMap.service[
            getNestedValue(companyService.serviceId, ["name"])
          ] = true
        }
      })
    })
    toSet = {
      ...toSet,
      allSaloons,
      isloading: false,
      allSaloonMap,
      allSaloonTaxMap,
      allProductMap,
      start,
      filtersMap,
      allServices,
    }

    return toSet
  }

  componentDidUpdate(prevProps, prevState) {
    const { activeDate, calendarView } = this.state
    if (
      (activeDate && activeDate !== prevState.activeDate) ||
      calendarView !== prevState.calendarView
    ) {
      this.gitWaitingAppoinments()
    }
  }

  getEmployee = async (selectServices) => {
    const serviceData = selectServices
      ? JSON.parse(selectServices)
      : selectServices
    this.setState({ serviceData })
    const { selectSalon, lockFields, employeeId } = this.state

    this.setState({ isloading: true })
    const payload = {
      companyId: selectSalon,
      serviceId: serviceData._id,
    }
    try {
      const res = await getEmployeesBySaloonAndService(payload)
      this.setState({
        getEmployeeBySalonData: res.data.data,
        isloading: false,
      })
      if (lockFields) {
        const { newDate } = this.state
        let date = newDate
        this.setState({ isloading: true })
        this.props.get_available_timeslots({
          data: {
            companyId: selectSalon,
            companyServiceId: serviceData._id,
            companyEmployeeId: employeeId,
            date,
          },
          resolve: (data) => {
            if (data.success) {
              const availableTimeslots = data.data
              const {
                selectDateAndTime,
                selectServices,
                timeSlotLength,
                getEmployeeData,
              } = this.state
              const duration = parseInt(
                getNestedValue(
                  JSON.parse(selectServices || "{}"),
                  "duration",
                  timeSlotLength
                )
              )
              const slot = getNestedValue(
                availableTimeslots,
                [
                  `${moment(selectDateAndTime).format("HH:mm")} - ${moment(
                    selectDateAndTime
                  )
                    .add(duration, "m")
                    .format("HH:mm")}`,
                ],
                []
              )
              if (!slot.length) {
                Swal.fire({
                  title: i18n.t("you_are_not_available"),
                  confirmButtonText: i18n.t("remove_lock"),
                }).then((e) => {
                  this.removeLock()
                })
              }
            }
          },
        })
      }
      return res.data.data
    } catch (error) {
      console.log(error)
      ErrorHelper.handleErrors(
        "Aww somthing went wrong for getting Employees",
        true
      )
      this.setState({ isloading: false })
    }
  }

  onDateChange = async (selectDate) => {
    this.setState({
      selectedTime: undefined,
      availableTimeslots: {},
    })
    let date = undefined
    if (selectDate) {
      date = moment(selectDate).format("MM-DD-YYYY")
      const { serviceData, selectSalon, employeeId } = this.state
      await this.props.get_available_timeslots({
        data: {
          companyId: selectSalon,
          companyServiceId: serviceData._id,
          companyEmployeeId: employeeId,
          date,
        },
      })
    }
    this.setState({
      selectDateAndTime: selectDate,
      newDate: date,
    })
  }

  gitWaitingAppoinments = async (getAll) => {
    const { activeDate, calendarView } = this.state

    try {
      const query = { type: "employee", relations: ["notes", "reminders"] }
      if (!getAll) {
        let dates
        if (calendarView == "month") {
          dates = []

          var daysInMonth = moment(activeDate).daysInMonth()
          for (let i = 1; i <= daysInMonth; i++) {
            var current = moment(activeDate).date(i)
            dates.push(current.format("MM-DD-YYYY"))
          }
        } else {
          const date = moment(activeDate).startOf("week")

          dates = [date.format("MM-DD-YYYY")]

          for (let i = 0; i < 7; i++) {
            date.add(1, "d")

            dates.push(date.format("MM-DD-YYYY"))
          }
        }
        this.props.setTimeMin(moment(dates[0]).startOf("month").toISOString())
        this.props.setTimeMax(moment(dates[0]).endOf("month").toISOString())

        this.props.setMinStartTime(
          moment(dates[0]).startOf("month").toISOString()
        )
        this.props.setMaxStartTime(
          moment(dates[0]).endOf("month").toISOString()
        )

        query.dates = dates.join(",")
      }

      const res = await gitWaitingAppoinments(query)
      this.setState({
        allAppoin: res.data.data.map((element, index) => ({
          ...element,
          index,
        })),
        isloading: false,
      })
    } catch (error) {
      alert("Something went wrong ;(")
    }
  }

  getAllServices = () => {
    this.setState({ isloading: true })
    getServices()
      .then((res) => {
        if (res.data.success) {
          this.setState({ isloading: false, serviceList: res.data.data })
        }
      })
      .catch((error) => {
        this.setState({ isloading: false })
      })
  }

  handleNavigation = (route) => {
    this.props.history.push(route)
  }

  handleFilters = (data) => {
    let filteredBookings = this.state.allAppoin
    if (data) {
      const { allSaloonMap } = this.state
      const normalize = (value) => {
        value = value ? value : ""
        return value.toLowerCase()
      }
      data.name = normalize(data.name)
      data.phoneNumber = normalize(data.phoneNumber).replace(/ /g, "")
      filteredBookings = filteredBookings.filter((booking) => {
        const isEmployeeIndependent = getNestedValue(
          allSaloonMap,
          [
            getNestedValue(booking, ["companyId", "_id"]),
            "isEmployeeIndependent",
          ],
          true
        )
        if (data.name) {
          if (
            !normalize(getNestedValue(booking, ["userId", "userName"])).match(
              data.name
            ) &&
            !normalize(getNestedValue(booking, "name", "")).match(data.name) &&
            !normalize(getNestedValue(booking, ["userId", "firstName"])).match(
              data.name
            ) &&
            !normalize(getNestedValue(booking, ["userId", "lastNme"])).match(
              data.name
            )
          ) {
            return false
          }
        }
        if (data.shortenedId) {
          if (
            !getNestedValue(booking, ["shortenedId"]).match(data.shortenedId)
          ) {
            return false
          }
        }
        if (data.phoneNumber) {
          if (!isEmployeeIndependent) {
            return false
          } else if (
            !normalize(
              getNestedValue(booking, ["userId", "phoneNo"], "")
            ).match(data.phoneNumber) &&
            !normalize(getNestedValue(booking, "phoneNo", "")).match(
              data.phoneNumber
            )
          ) {
            return false
          }
        }
        if (data.service && data.service.length) {
          if (
            !getNestedValue(booking, ["services"], []).filter((value) =>
              data.service.includes(
                getNestedValue(value, ["serviceId", "name"], "").trim()
              )
            ).length
          ) {
            return false
          }
        }
        if (data.startDate || data.endDate) {
          // moment
          if (data.startDate && data.endDate) {
            const date = moment(
              getNestedValue(booking, ["services", 0, "date"], ""),
              "MM-DD-YYYY"
            )
            if (
              !moment(data.startDate).isSameOrBefore(date) ||
              !moment(data.endDate).isSameOrAfter(date)
            ) {
              return false
            }
          } else {
            if (
              (data.startDate &&
                normalize(
                  getNestedValue(booking, ["services", 0, "date"], "")
                ) != moment(data.startDate).format("MM-DD-YYYY")) ||
              (data.endDate &&
                normalize(
                  getNestedValue(booking, ["services", 0, "date"], "")
                ) != moment(data.endDate).format("MM-DD-YYYY"))
            ) {
              return false
            }
          }
        }
        if (data.status) {
          if (
            (Array.isArray(data.status) &&
              !data.status.includes(getNestedValue(booking, ["status"], 0))) ||
            (!Array.isArray(data.status) &&
              data.status != `${getNestedValue(booking, ["status"], 0)}`)
          ) {
            return false
          }
        }
        return true
      })
    }
    this.setState({ filteredBookings, showAdvancedSearchPopup: false })
  }

  renderEditDetail = () => {
    return {
      title: i18n.t("edit"),
      content: this.renderEmployeeDetail(),
      contentClassName: "modify-modal-content",
      actions: (
        <div className="row mx-0 justify-content-end">
          {this.state.openUpdateModify ? (
            <ModifyModal
              handleClose={() => this.setState({ openUpdateModify: false })}
              onSubmit={({ notifyCustomer }) => {
                this.handleUpdate({}, notifyCustomer)
                this.setState({ openUpdateModify: false })
              }}
            />
          ) : null}
          <Button
            onClick={() => this.setState({ openUpdateModify: true })}
            style={{
              backgroundColor: "#ff3600",
              color: "white",
            }}
          >
            {i18n.t("update")}
          </Button>
        </div>
      ),
    }
  }

  renderExtras = () => {
    const { selectBooking, allProductMap, allSaloonMap, allAppoin } = this.state
    let products = getNestedValue(
      allProductMap,
      [selectBooking.companyId._id],
      []
    )
    let services = getNestedValue(
      allSaloonMap,
      [selectBooking.companyId._id, "companyServices"],
      []
    ).map((service) => ({
      _id: service.serviceId._id,
      price: service.price,
      serviceId: service.serviceId,
      name: service.serviceId.name,
    }))
    const closaModal = () => {
      this.setState({ showExtrasModal: false, showPopUp: false })
    }
    const onAdd = async (type, values) => {
      this.setState({ isloading: true })
      if (type) {
        const { data } = await (type == "product" ? addProduct : addExtra)({
          bookingId: selectBooking._id,
          ...values,
        })
        if (data.success) {
          if (type == "product") {
            if (allAppoin[selectBooking.index]) {
              allAppoin[selectBooking.index].cartId = data.data
              this.setState({
                selectBooking: { ...selectBooking, cartId: data.data },
              })
            }
          } else {
            allAppoin[selectBooking.index] = data.data
            this.setState({ selectBooking: data.data })
          }
          this.setState({ allAppoin, showPopUp: false })
          SuccessHelper.handleSuccess("Added Item", true)
          closaModal()
        } else {
          if (data.code) {
            ErrorHelper.handleErrors(i18n.t(data.code, { ...data.data }), true)
          } else {
            ErrorHelper.handleErrors("Failed to Add Item", true)
          }
        }
      }
      this.setState({ isloading: false })
    }
    return {
      title: i18n.t("container.add_extras"),
      content: (
        <AddExtra
          services={services}
          products={products}
          closaModal={closaModal}
          onAdd={onAdd}
        />
      ),
    }
  }

  renderBookingNotes = () => {
    const { selectBooking = {}, allSaloonMap = {} } = this.state
    return {
      title: i18n.t("notes_and_reminders"),
      content: (
        <BookingNotesReminders
          booking={selectBooking}
          hasEditPermission={
            allSaloonMap[selectBooking.companyId._id].isEmployeeIndependent
          }
        />
      ),
    }
  }

  handleEditDetails = async () => {
    const { selectBooking } = this.state
    const companyId = selectBooking.companyId._id
    const selectService = getNestedValue(
      selectBooking,
      ["services", 0, "serviceId"],
      {}
    )
    console.log(selectService)
    this.setState({ selectSalon: companyId, enableWaitingList: false }, () => {
      let selectEmployee = getNestedValue(
        selectBooking,
        ["services", 0, "employeeId", "_id"],
        {}
      )
      const momentDate = moment(
        `${getNestedValue(
          selectBooking,
          ["services", 0, "date"],
          ""
        )} ${getNestedValue(selectBooking, ["services", 0, "time"], "")}`,
        "MM-DD-YYYY HH:mm"
      )
      Promise.all([
        new Promise((resolve) => {
          this.props.get_available_timeslots({
            data: {
              companyId,
              companyServiceId: selectService._id,
              date: momentDate.format("MM-DD-YYYY"),
              bookingIdToExclude: selectBooking._id,
              companyEmployeeId: selectEmployee,
            },
            resolve,
          })
        }),
        this.getEmployee(JSON.stringify(selectService)),
      ]).then(() => {
        setTimeout(() => {
          const { getEmployeeData, availableTimeslots } = this.state
          // let selectedEmployee = getEmployeeData.find(employee => employee.employeeId._id == selectEmployee)
          this.setState({
            showPopUp: true,
            editDetails: true,
            lockFields: false,
            // selectedEmployee: JSON.stringify(selectedEmployee),
            selectEmployee,
            selectedService: selectService,
            newDate: momentDate.format("MM-DD-YYYY"),
            newTime: momentDate.format("HH:mm"),
            selectDateAndTime: momentDate.toDate(),
            selectedTime: `${momentDate.format("HH:mm")} - ${moment(momentDate)
              .add(parseInt(selectService.duration), "minutes")
              .format("HH:mm")}`,
          })
        }, 50)
      })
    })
  }

  handleAddProductsServices = async () => {
    this.setState({
      showExtrasModal: true,
      showPopUp: true,
    })
  }

  handleNotes = async () => {
    this.setState({
      showNotesModal: true,
      showPopUp: true,
    })
  }

  handleUpdate = async (extras = {}, notifyCustomer) => {
    const {
      paymentMethod,
      selectBookingAmount,
      selectBooking,
      bookingStaus,
      notes,
      tip,
      companyTaxes = [],
      editDetails,
    } = this.state
    let { allAppoin } = this.state
    if (editDetails) {
      const {
        newDate,
        selectDateAndTime,
        selectedTime,
        newTime,
        selectEmployee,
        selectedService,
      } = this.state
      if (!selectEmployee) {
        this.setState({
          formErrors: {
            selectEmployeeError: "Please select Employee",
          },
        })
        setTimeout(() => {
          this.setState({
            formErrors: {
              selectEmployeeError: "",
            },
          })
        }, 8000)
        return true
      } else if (!selectDateAndTime) {
        this.setState({
          formErrors: {
            selectDateAndTimeError: "Please select Date",
          },
        })
        setTimeout(() => {
          this.setState({
            formErrors: {
              selectDateAndTimeError: "",
            },
          })
        }, 8000)
      } else if (!selectedTime) {
        this.setState({
          formErrors: {
            selectedTimeError: "Please select Time",
          },
        })
        setTimeout(() => {
          this.setState({
            formErrors: {
              selectedTimeError: "",
            },
          })
        }, 8000)
      } else if (
        !newTime ||
        !newTime.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)
      ) {
        this.setState({
          formErrors: {
            selectedTimeError: "Invalid, expected hh:mm",
          },
        })
        setTimeout(() => {
          this.setState({
            formErrors: {
              selectedTimeError: "",
            },
          })
        }, 8000)
      } else if (!selectedService) {
        this.setState({
          formErrors: {
            selectServiceError: "Please select Service",
          },
        })
        setTimeout(() => {
          this.setState({
            formErrors: {
              selectServiceError: "",
            },
          })
        }, 8000)
      } else {
        const services = [
          {
            serviceId: selectedService._id,
            categoryId: selectedService.serviceId?._id,
            date: newDate,
            time: newTime,
            employeeId: selectEmployee,
          },
        ]
        try {
          const { data } = await editBooking({
            bookingId: selectBooking._id,
            status: selectBooking.status,
            services,
            notifyCustomer: notifyCustomer ? true : false,
            isFinalized: selectBooking.isFinalized,
            totalAmount: selectedService.price,
          })
          let newSelectBooking = selectBooking
          if (data.success) {
            const newBooking = data.data
            this.setState({
              isloading: false,
              allAppoin: [...allAppoin],
              selectBooking: newSelectBooking,
              editDetails: false,
            })
            SuccessHelper.handleSuccess("Updated Successfully", true)
            if (
              (data.data.status == 4 && data.data.isFinalized) ||
              data.data.status == 3
            ) {
              if (typeof selectBooking.index === "number") {
                allAppoin.splice(selectBooking.index, 1)
              } else {
                const booking = allAppoin.find(
                  (booking) => booking._id == selectBooking._id
                )
                allAppoin.splice(booking.index, 1)
              }
              allAppoin.map((booking, index) => (booking.index = index))
            } else {
              newSelectBooking = {
                ...selectBooking,
                ...data.data,
                index: selectBooking.index,
              }
              allAppoin[selectBooking.index] = newSelectBooking
            }
            this.setState({
              isloading: false,
              allAppoin: [...allAppoin],
              selectBooking: newSelectBooking,
              editDetails: false,
            })
            SuccessHelper.handleSuccess("Updated Successfully", true)
            this.handleClosePopup()
          } else {
            this.setState({ isloading: false })
            ErrorHelper.handleErrors(data.msg, true)
          }
        } catch (error) {
          this.setState({ isloading: false })
          if (error.data.msg) {
            ErrorHelper.handleErrors(error.data.msg, true)
          } else {
            alert(
              error.response ? error.response.data.msg : "Something Went Wrong!"
            )
          }
        }
      }
    } else {
      const sendRequst = async (isFinalized) => {
        const taxes = []
        const payload = {
          bookingId: selectBooking._id,
          totalAmount: parseInt(selectBookingAmount),
          paymentMethod,
          status: bookingStaus,
          notes,
          tip: parseFloat(tip),
          taxes,
          isFinalized,
          ...extras,
        }
        console.log({ payload })

        this.setState({ isloading: true })

        try {
          const { data } = await editBooking(payload)
          let newSelectBooking = selectBooking
          if (data.success) {
            const newBooking = data.data
            newBooking.services = newBooking.services.map((service) => {
              return {
                ...service,
              }
            })
            if (
              (data.data.status == 4 && data.data.isFinalized) ||
              data.data.status == 3
            ) {
              allAppoin.splice(selectBooking.index, 1)
              allAppoin = allAppoin.map((booking, index) => ({
                ...booking,
                index,
              }))
            } else {
              newSelectBooking = { ...newBooking, index: selectBooking.index }
              allAppoin[selectBooking.index] = newSelectBooking
            }
          }
          this.setState({
            isloading: false,
            allAppoin: [...allAppoin],
            selectBooking: newSelectBooking,
            editDetails: false,
          })
          SuccessHelper.handleSuccess("Updated Successfully", true)
          this.handleClosePopup()
          this.setState({
            showPopUp: false,
            isloading: false,
            showBookingModal: false,
          })
        } catch (error) {
          this.setState({ isloading: false })
          if (error.data.msg) {
            ErrorHelper.handleErrors(error.data.msg, true)
          } else {
            alert(
              error.response ? error.response.data.msg : "Something Went Wrong!"
            )
          }
        }
      }
      if (bookingStaus == 4) {
        Swal.fire({
          title: i18n.t("are_you_sure_you_want_to_finalize_this_booking"),
          showCancelButton: true,
          cancelButtonText: "No",
          confirmButtonText: "Yes",
        }).then((e) => {
          const { dismiss, isConfirmed, isDismissed } = e
          if (!isDismissed || dismiss != "backdrop") {
            if (isConfirmed) {
              sendRequst(isConfirmed)
              this.setState(
                {
                  showInsertPayment: true,
                },
                () => {
                  this.setState({
                    showInsertPayment: false,
                  })
                }
              )
            } else {
              sendRequst(isConfirmed)
            }
          }
        })
      } else {
        sendRequst(false)
      }
    }
  }

  handleClosePopup = () => {
    this.setState({
      showProductsServicesModal: false,
      showNotesModal: false,
      showExtrasModal: false,
      editDetails: false,
      showPopUp: false,
    })
  }

  renderPopup = () => {
    const {
      showPopUp,
      allSaloonMap,
      showNotesModal,
      showExtrasModal,
      editDetails,
    } = this.state
    let modalFunction
    if (showNotesModal) {
      modalFunction = this.renderBookingNotes
    } else if (showExtrasModal) {
      modalFunction = this.renderExtras
    } else if (editDetails) {
      modalFunction = this.renderEditDetail
    }
    const modalProps = modalFunction ? modalFunction() : {}
    return (
      <BaseModal
        style={{ zIndex: 1303 }}
        open={showPopUp}
        {...modalProps}
        onClose={() => this.handleClosePopup()}
      />
    )
  }

  getEmployeeData = () => {
    this.setState({ isloading: true })
    getEmployeeDetails()
      .then((res) => {
        if (res.data.success) {
          for (let i = 0; i < res.data.data.details.weekPlans.length; i++) {
            res.data.data.details.weekPlans[i].dayName = weekNames[i]
          }
          this.setState({
            isloading: false,
            employeesWorkingHoursData: res.data.data.details.weekPlans,
            serviceList: res.data.data.details.services,
            employeeServicesList: res.data.data.companyEmployee.companyServices,
          })
        }
      })
      .catch((error) => {
        this.setState({ isloading: false })
      })
  }

  renderLoader = () => {
    const { isloading } = this.state
    return <DotsLoader isloading={isloading} />
  }

  onChangeSearchText = (searchText) => {
    this.setState({ searchText })
  }

  renderEmployDashboardHeading = () => {
    const { searchText, filteredBookings } = this.state
    const { forms_svg_light, my_appointment_svg_dark } = Images
    const {
      screenSizeContext: { screenSize },
    } = this.props
    return (
      <>
        <div className="col-12 py-3 px-0">
          <div className="row flex-column gap-2 flex-md-row align-items-center">
            <span className="saloon-dashboard-heading col-12 col-md-4 px-0">
              {i18n.t("container.my_appointments")}
            </span>
            <div className="col-12 col-md-8 flex-column-reverse flex-md-row px-0 row gap-2 justify-content-md-end">
              <TextField
                type="text"
                placeholder={i18n.t(
                  "container.services_search_by_name_phone_number_ref"
                )}
                className="col-12 col-md-4 px-0"
                onChange={(text) => this.onChangeSearchText(text.target.value)}
                value={searchText}
                InputProps={{
                  startAdornment: (
                    <SearchIcon style={{ color: "#b1b1b1" }} className="mr-1" />
                  ),
                }}
              />
              <div className="d-flex align-items-center justify-content-center justify-content-md-end">
                <SecondaryButton
                  className={screenSize !== "mobile" ? "w-98" : ""}
                  type="button"
                  onClick={() => {
                    setTimeout(() => {
                      this.handleFilters()
                    }, 500)
                    this.setState({
                      displayCalendar: true,
                    })
                  }}
                >
                  {screenSize !== "mobile" ? (
                    <Translation>{(t) => t("calendar")}</Translation>
                  ) : (
                    <img src={my_appointment_svg_dark} />
                  )}
                </SecondaryButton>
                <PrimaryButton
                  className={screenSize !== "mobile" ? "w-98" : ""}
                >
                  {screenSize !== "mobile" ? (
                    <Translation>{(t) => t("table")}</Translation>
                  ) : (
                    <img src={forms_svg_light} />
                  )}
                </PrimaryButton>
              </div>
            </div>
          </div>
        </div>
        <div className="filter-header">
          <PrimaryButton
            type="button"
            onClick={() => {
              if (!filteredBookings) {
                this.setState({ showAdvancedSearchPopup: true })
              } else {
                this.setState({ filteredBookings: undefined })
              }
            }}
            className="mb-2 "
            id="saloon-save-social-link"
          >
            {filteredBookings ? (
              <Translation>
                {(t) =>
                  t("container.advancedSearchDialog_clear_advanced_search")
                }
              </Translation>
            ) : (
              <Translation>
                {(t) => t("container.advancedSearchDialog_advanced_search")}
              </Translation>
            )}
          </PrimaryButton>
        </div>
      </>
    )
  }

  renderTableHead = () => {
    return (
      <thead
        style={{
          position: "sticky",
          top: "0",
          zIndex: "1",
          backgroundColor: "white",
        }}
      >
        <tr>
          <th scope="col">
            <span className="working-day-th-text">Name</span>
          </th>
          <th className="text-center" scope="col">
            <span className="working-day-th-text">Date</span>
          </th>
          <th className="text-center" scope="col">
            <span className="working-day-th-text">
              <Translation>
                {(t) => t("container.employe_custom_details")}
              </Translation>
            </span>
          </th>
          <th className="text-center" scope="col">
            <span className="working-day-th-text">
              <Translation>{(t) => t("container.profile_service")}</Translation>
            </span>
          </th>
          <th className="text-center" scope="col">
            <span className="working-day-th-text">Payment</span>
          </th>
          <th className="text-center" scope="col">
            <span className="working-day-th-text">
              <Translation>{(t) => t("by_price")}</Translation>
            </span>
          </th>
          <th className="text-center" scope="col">
            <span className="working-day-th-text">
              <Translation>{(t) => t("tip")}</Translation>
            </span>
          </th>
          <th className="text-center" scope="col">
            <span className="working-day-th-text">
              <Translation>{(t) => t("grand_total")}</Translation>
            </span>
          </th>
          {/* <th className="text-center" scope="col">
            <span className="working-day-th-text">Time/Date</span>
          </th> */}
          <th className="text-center" scope="col">
            <span className="working-day-th-text">Status</span>
          </th>
          <th className="text-center" scope="col">
            <span className="working-day-th-text"></span>
          </th>
        </tr>
      </thead>
    )
  }

  handleFilterChange = (key, value) => {
    const { filtersMap = {} } = this.state
    if (key == "service" || key == "status") {
      const keyMap = filtersMap[key] ? filtersMap[key] : {}
      if (keyMap[value]) {
        delete keyMap[value]
      } else {
        keyMap[value] = true
      }
      filtersMap[key] = keyMap
    } else {
      filtersMap[key] = value.target.value
    }

    this.handleFilters({
      ...filtersMap,
      status: Object.keys(filtersMap.status ? filtersMap.status : {}),
      employee: Object.keys(filtersMap.employee ? filtersMap.employee : {}),
      service: Object.keys(filtersMap.service ? filtersMap.service : {}),
    })
    this.setState({ filtersMap })
  }

  onCalendarClick = (date) => {
    const { timeSlotLength } = this.state
    const momentDate = moment(date)
    let remainder = parseInt(momentDate.format("mm")) % timeSlotLength
    momentDate.subtract(remainder, "m")
    this.context.setAddOpen(true)
    this.context.setLockFields(true)

    this.context.onChange({
      selectedTime: momentDate.format("HH:mm"),
      newTime: momentDate.format("HH:mm"),
      selectDateAndTime: momentDate.startOf("d").toDate(),
      formattedDate: momentDate.format("MM-DD-YYYY"),
      newDate: momentDate.format("MM-DD-YYYY"),
    })
  }

  Plan = (bookings) => {
    const {
      employees,
      allServices = [],
      start,
      filtersMap,
      allSaloonTaxMap,
      allSaloonMap,
      activeDate,
      allSaloons,
      allProductMap,
    } = this.state
    // const [open, setOpen] = useState(false);
    // const slideSideNav = useCallback(() => {
    //   setOpen(!open);
    // }, [open]);
    const statusClassMap = {
      1: "calendar-event-waiting",
      2: "calendar-event-now-serving",
      3: "calendar-event-cancel",
      4: "calendar-event-done",
    }
    const plans = bookings.map((booking) => {
      const { services, _id, name } = booking
      return services.map((service) => {
        const date = moment(
          `${service.date} ${service.time}`,
          "MM-DD-YYYY HH:mm"
        )
        const start = date.toDate()
        const end = date
          .add(parseInt(service.serviceId.duration), "minutes")
          .add(parseInt(booking.adjustedTime || 0), "minutes")
          .toDate()
        return {
          ...booking,
          id: _id,
          title: `${name} - ${service.serviceId.name}`,
          allDay: false,
          start,
          end,
          color: getNestedValue(
            allSaloonMap,
            [booking.companyId._id, "color"],
            "#000"
          ),
          duration: service.serviceId.duration + (booking.adjustedTime || 0),
          className: statusClassMap[booking.status],
          type: "booking",
          promotionId: service.promotionId,
        }
      })
    })
    const onEventClick = (val) => {
      if (val.status != 3) {
        this.setState({
          showBookingModal: true,
          selectBooking: val,
          selectBookingAmount: val.totalAmount,
          paymentMethod: val.paymentMethod,
          bookingStaus: val.status,
          estimateDuration: val.duration,
          notes: val.notes,
          editDetails: false,
          companyTaxes: allSaloonTaxMap[val.companyId._id],
          products: allProductMap[val.companyId._id],
          tip: val.tip,
        })
      }
    }

    return (
      <div className="p-4">
        <Settings
          title={i18n.t("view_settings")}
          style={{ zIndex: 1303 }}
          open={this.state.isOpen}
          onClick={this.handleFilters}
          employees={employees}
          services={allServices}
          onClose={(open) => {
            this.setState({
              isOpen: typeof open == "boolean" ? open : !this.state.isOpen,
            })
          }}
        />
        <PlanView
          open={this.state.isOpen}
          width="17vw"
          slideSideNav={(open) => {
            this.setState({
              isOpen: typeof open == "boolean" ? open : !this.state.isOpen,
            })
          }}
          companies={allSaloons.map(({ companyId }) => companyId)}
          companyTaxMap={allSaloonTaxMap}
          onEventClick={onEventClick}
          plans={plans}
          start={start}
          calendarView={(calendarView) => {
            this.setState({ calendarView })
          }}
          displayAllAppointments={() => {
            const { displayCalendar } = this.state
            if (displayCalendar) {
              this.gitWaitingAppoinments(true)
            }
            this.setState({ displayCalendar: !displayCalendar })
            if (!displayCalendar) {
              setTimeout(() => {
                this.handleFilters()
              }, 500)
            }
          }}
          onDateClick={this.onCalendarClick}
          date={activeDate}
          onDateChange={(e) => {
            this.setState({ activeDate: e }, () => {
              this.gitWaitingAppoinments()
            })
          }}
          onDismiss={(_id, index) => {
            const { allAppoin } = this.state
            this.setState({ isloading: true })
            dismissCancelledBooking({ id: _id })
              .then(({ data }) => {
                if (data.success) {
                  let newBookings = allAppoin
                  newBookings.splice(index, 1)
                  this.setState({
                    allAppoin: newBookings.map((booking, index) => ({
                      ...booking,
                      index,
                    })),
                    isloading: false,
                  })
                }
              })
              .finally(() => {
                this.setState({ isloading: false })
              })
          }}
        />
      </div>
    )
  }

  renderAppointmentCalendar = (bookings) => {
    const { searchText = "", filteredBookings } = this.state
    if (!bookings) return null
    let renderBooking = bookings
    if (filteredBookings) {
      renderBooking = filteredBookings
    } else if (searchText)
      renderBooking = bookings?.filter((val) => {
        var ll = val.userId?.userName || ""
        if (
          ll.toLowerCase().includes(searchText.toLowerCase()) ||
          val?.phoneNo?.includes(searchText)
        )
          return true
        return false
      })
    return (
      <div className="col-12 px-0">
        {this.Plan(renderBooking)}
        {/* <div className="table-responsive">
          <table className="table table-borderless saloon-working-hour-table">
            {this.renderTableHead()}
            {bookings && this.renderTableBody(bookings)} 
          </table>
        </div> */}
      </div>
    )
  }

  renderAppointmentTable = (bookings) => {
    const {
      screenSizeContext: { screenSize },
    } = this.props
    return (
      <div className="col-12 px-0">
        {this.renderEmployDashboardHeading()}
        <div
          style={
            screenSize == "mobile" ? { height: "60vh" } : { height: "70vh" }
          }
          className="table-responsive"
        >
          <table className="table table-borderless saloon-working-hour-table  mobile-max-content">
            {this.renderTableHead()}
            {bookings && this.renderTableBody(bookings)}
          </table>
        </div>
      </div>
    )
  }

  renderTableBody = () => {
    const {
      allAppoin,
      bookingStauses,
      searchText = "",
      allSaloonMap,
      filteredBookings,
      allSaloonTaxMap,
      isGym,
    } = this.state
    if (!allAppoin) return null
    let renderBooking = allAppoin
    if (filteredBookings) {
      renderBooking = filteredBookings
    } else if (searchText)
      renderBooking = allAppoin.filter((val) => {
        const { email, shortenedId, userId } = val
        console.log(userId?.userName || "", email, shortenedId)
        return (
          (userId?.userName || "")
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          (email || "").includes(searchText) ||
          (shortenedId || "")?.includes(searchText) ||
          val?.phoneNo?.includes(searchText)
        )
      })

    const summary = {
      total: 0,
      tip: 0,
      grandTotal: 0,
    }
    renderBooking = renderBooking.map((booking) => {
      booking.total = booking.totalAmount + (booking.tip || 0)
      summary.total += booking.totalAmount || 0
      summary.tip += booking.tip || 0
      summary.grandTotal += booking.total
      return booking
    })

    return (
      <tbody>
        <tr
          style={{
            position: "sticky",
            top: "36px",
            zIndex: "1",
            backgroundColor: "white",
          }}
        >
          <td />
          <td />
          <td />
          <td />
          <td />
          {!isGym && (
            <>
              <td className="px-1">
                <Button
                  disabled={true}
                  size="small"
                  style={{
                    backgroundColor: "#ff3600",
                    color: "white",
                    width: "7rem",
                  }}
                >
                  $ {summary.total.toFixed(2)}
                </Button>
              </td>
              <td className="px-1">
                <Button
                  disabled={true}
                  size="small"
                  style={{
                    backgroundColor: "#ff3600",
                    color: "white",
                    width: "7rem",
                  }}
                >
                  $ {summary.tip.toFixed(2)}
                </Button>
              </td>
              <td className="px-1">
                <Button
                  disabled={true}
                  size="small"
                  style={{
                    backgroundColor: "#ff3600",
                    color: "white",
                    width: "7rem",
                  }}
                >
                  $ {summary.grandTotal.toFixed(2)}
                </Button>
              </td>
              <td />
              <td />
            </>
          )}
        </tr>
        {renderBooking.map((val, ind) => {
          return (
            <tr key={ind} style={{ borderTop: "3px solid #F7F7F7" }}>
              <td>
                <div className="d-flex align-items-center">
                  <Avatar
                    name={val?.userId?.userName}
                    size="30"
                    color="#4862FF"
                    round={true}
                    textSizeRatio={2.0}
                  />
                  {/* {<img src={val.user.profile_img} className="appointment-details-employee-pic" style={{ width: 30, height: 30 }} />} */}
                  <div>
                    {
                      <span
                        style={{ marginLeft: "10px" }}
                        className="appointment-details-employee-name"
                      >
                        {val?.userId?.userName || ""}
                      </span>
                    }
                    <br />
                  </div>
                </div>
              </td>
              <td style={{ textAlign: "center" }}>
                <span className="working-days-name">
                  {" "}
                  {getNestedValue(val, ["services", 0, "date"], "")}{" "}
                  {getNestedValue(val, ["services", 0, "time"], "")}{" "}
                </span>
              </td>
              <td style={{ textAlign: "center" }}>
                {(getNestedValue(allSaloonMap, [
                  val.companyId._id,
                  "isEmployeeIndependent",
                ])
                  ? true
                  : false) && (
                  <span>
                    <div className="working-days-name">
                      {" "}
                      {val.phoneNo || ""}{" "}
                    </div>
                    <div className="working-days-name"> {val.email || ""} </div>
                  </span>
                )}
              </td>
              <td style={{ textAlign: "center" }}>
                <span className="working-days-name service-column">
                  {" "}
                  {getNestedValue(
                    val,
                    ["services", 0, "serviceId", "name"],
                    ""
                  )}{" "}
                </span>
              </td>
              <td className="text-center">
                <span className="working-days-name">
                  {val.paymentMethod || ""}
                </span>
              </td>
              <td className="text-center">
                <span className="working-days-name">{`$ ${
                  val.totalAmount || 0
                }`}</span>
              </td>
              <td className="text-center">
                <span className="working-days-name">{`$ ${
                  val.total || 0
                }`}</span>
              </td>
              <td className="text-center">
                <span className="working-days-name">{`$ ${val.tip || 0}`}</span>
              </td>
              {/* <td className="text-center">
                <span className="working-days-name">{val.dayName}</span>
              </td> */}
              <td className="text-center">
                {/* <span className="working-days-name">{val.dayName}</span> */}
                <span
                  className="working-days-name aBold"
                  style={bookingStauses[val.status - 1].styles}
                >
                  {bookingStauses[val.status - 1].text}
                  {val.isNoShow ? ` - ${i18n.t("no_show")}` : ""}
                </span>
              </td>
              <td
                className="text-center"
                onClick={() => {
                  this.setState({
                    showBookingModal: true,
                    selectBooking: val,
                    selectBookingAmount: val?.totalAmount,
                    paymentMethod: val?.paymentMethod,
                    bookingStaus: val.status,
                    notes: val.notes,
                    companyTaxes: allSaloonTaxMap[val.companyId],
                    tip: val.tip,
                  })
                }}
              >
                <EditIcon className="working-days-name" />
              </td>
            </tr>
          )
        })}
      </tbody>
    )
  }

  removeLock = () => {
    const { selectedEmployee, serviceData } = this.state
    if (serviceData) {
      const {
        selectSalon,
        newDate,
        selectServices,
        timeSlotLength,
        selectDateAndTime,
        employeeId,
      } = this.state
      const duration = parseInt(
        getNestedValue(
          JSON.parse(selectServices || "{}"),
          "duration",
          timeSlotLength
        )
      )
      this.setState({ isloading: true })
      this.props.get_available_timeslots({
        data: {
          companyId: selectSalon,
          companyServiceId: serviceData._id,
          date: newDate,
          companyEmployeeId: employeeId,
        },
        resolve: () => {
          this.setState({
            selectedTime: `${moment(selectDateAndTime).format(
              "HH:mm"
            )} - ${moment(selectDateAndTime)
              .add(duration, "m")
              .format("HH:mm")}`,
          })
        },
      })
    } else {
      this.setState({
        selectedTime: "",
        newTime: "",
        availableTimeslots: {},
      })
    }
    this.setState({ lockFields: false })
  }

  renderAdvancedSearchPopup = () => {
    const { showAdvancedSearchPopup, allSaloonMap = {} } = this.state
    const allServices = []
    Object.values(allSaloonMap).map((saloon) => {
      saloon.companyServices.map((companyService) => {
        if (getNestedValue(companyService.serviceId, ["name"]))
          allServices.push(getNestedValue(companyService.serviceId, ["name"]))
      })
    })
    const hideFitlerDetails = Object.values(allSaloonMap).find(
      (salon) => salon && !salon.isEmployeeIndependent
    )
    return (
      showAdvancedSearchPopup && (
        <AdvancedSearchDialog
          services={allServices}
          hideFitlerDetails={!!hideFitlerDetails}
          extraStatues={[
            { text: "Waiting", value: 1 },
            { text: "Now Serving", value: 2 },
          ]}
          onFilter={(data) => this.handleFilters(data)}
          onClose={() => this.setState({ showAdvancedSearchPopup: false })}
        ></AdvancedSearchDialog>
      )
    )
  }

  renderFOBs = () => {
    const { displayCalendar } = this.state
    return (
      <>
        <button
          className="refresh-fob p-1"
          onClick={() => {
            this.gitWaitingAppoinments()
          }}
        >
          <RefreshIcon />
        </button>
      </>
    )
  }

  renderTimeSelection = () => {
    const {
      formErrors,
      availableTimeslots,
      selectedTime,
      enableWaitingList,
      newTime,
      timeSlotLength,
      startTime,
      endTime,
      lockFields,
    } = this.state
    if (enableWaitingList) {
      return (
        <>
          <div
            className={
              "form-label-group mb-3 " +
              (enableWaitingList ? "col-12" : "col-sm-6 col-xs-12")
            }
          >
            <TextField
              value={startTime}
              id="time"
              label={i18n.t("container.start_time")}
              disabled={lockFields}
              type="time"
              className="full-width"
              onChange={(text) => this.onChangeTimeText(text, "startTime")}
              onBlur={() => {
                if (startTime) {
                  let hour = startTime.split(":")[0]
                  let minute = parseInt(startTime.split(":")[1])
                  const remainder = minute % timeSlotLength
                  if (remainder) {
                    minute =
                      minute -
                      remainder +
                      ((minute % timeSlotLength) / timeSlotLength > 0.5
                        ? timeSlotLength
                        : 0)
                    if (minute >= 60) {
                      hour++
                      minute %= 60
                    }
                    const time = `${hour}:${
                      minute < 10 ? "0" + minute : minute
                    }`
                    this.setState({ startTime: time })
                  }
                }
              }}
              helperText={formErrors.startTimeError}
              error={!!formErrors.startTimeError}
            />
          </div>
          <div className="form-label-group mb-3 col-6 pl-0">
            <TextField
              value={endTime}
              id="time"
              label={i18n.t("container.end_time")}
              disabled={lockFields}
              type="time"
              className="full-width"
              onChange={(text) => this.onChangeTimeText(text, "endTime")}
              onBlur={() => {
                if (endTime) {
                  let hour = endTime.split(":")[0]
                  let minute = parseInt(endTime.split(":")[1])
                  const remainder = minute % timeSlotLength
                  if (remainder) {
                    minute =
                      minute -
                      remainder +
                      ((minute % timeSlotLength) / timeSlotLength > 0.5
                        ? timeSlotLength
                        : 0)
                    if (minute >= 60) {
                      hour++
                      minute %= 60
                    }
                    const time = `${hour}:${
                      minute < 10 ? "0" + minute : minute
                    }`
                    this.setState({ endTime: time })
                  }
                }
              }}
              helperText={formErrors.endTimeError}
              error={!!formErrors.endTimeError}
            />
          </div>
        </>
      )
    } else {
      return (
        <div
          className={
            "form-label-group mb-3 " +
            (enableWaitingList ? "col-12" : "col-sm-6 col-xs-12")
          }
        >
          <FormControl
            error={!!formErrors.selectedTimeError}
            className="full-width"
          >
            <InputLabel id="services">{i18n.t("container.time")}</InputLabel>
            <Select
              value={selectedTime}
              disabled={lockFields}
              onChange={(text) => this.onChangeTime(text)}
              placeholder={i18n.t("container.select_time")}
            >
              {Object.keys(availableTimeslots ? availableTimeslots : {})?.map(
                (val, ind) => {
                  return (
                    <MenuItem key={ind} value={val}>
                      {val.split(" ")[0]}
                    </MenuItem>
                  )
                }
              )}
            </Select>
            {!!formErrors.selectedTimeError && (
              <FormHelperText>{formErrors.selectedTimeError}</FormHelperText>
            )}
          </FormControl>
        </div>
      )
    }
  }
  renderEmployeeDetail = () => {
    const {
      formErrors,
      selectDateAndTime,
      lockFields,
      enableWaitingList,
      employeesWorkingHoursData,
      selectedService,
      employeeServicesList,
    } = this.state
    const employeeServicesListMap = employeeServicesList.map((service) => {
      return service.serviceId
    })
    console.log(selectedService)
    return (
      <div className="row px-0 mx-0">
        <div
          className={
            "form-label-group mb-3 " +
            (enableWaitingList ? "col-12" : "col-sm-6 col-xs-12")
          }
        >
          <DatePicker
            className="full-width"
            minDate={new Date()}
            selected={selectDateAndTime}
            disabled={lockFields}
            onChange={(date) => this.onDateChange(date)}
            value={this.state.selectDateAndTime}
            filterDate={(date) => {
              return employeesWorkingHoursData.availableStatus != 0
            }}
            customInput={
              <TextField
                id="time"
                label={"Date" + (lockFields ? ` (${i18n.t("locked")})` : "")}
                value={this.state.newDate}
                className="full-width"
                readOnly={true}
                disabled={lockFields}
                helperText={formErrors.selectDateAndTimeError}
                error={!!formErrors.selectDateAndTimeError}
              />
            }
            placeholderText={i18n.t(
              "container.services_click_to_select_a_date"
            )}
          />
        </div>

        {this.renderTimeSelection()}
        {
          <div
            className={
              "form-label-group mb-3 " +
              (enableWaitingList ? "col-12" : "col-sm-6 col-xs-12")
            }
          >
            <FormControl className="full-width">
              <Autocomplete
                options={Object.values(employeeServicesListMap)}
                blurOnSelect
                value={selectedService}
                onChange={(e, value) => {
                  this.setState({ selectedService: value })
                }}
                getOptionLabel={(option) => option.name}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={i18n.t("services")}
                    helperText={formErrors?.selectServiceError}
                    error={!!formErrors?.selectServiceError}
                    fullWidth
                  />
                )}
              />
            </FormControl>
          </div>
        }
      </div>
    )
  }

  renderEditAppointment = () => {
    const {
      employees,
      allServices = [],
      start,
      employeeColorMap = {},
      timeSlotLength,
      showBookingModal,
      bookingStatuses,
      selectBooking = {},
      employeeMap,
      isGym,
      allAppoin,
      allSaloonMap,
      companyTaxes,
      showInsertPayment,
      products,
    } = this.state

    if (showBookingModal) {
      return (
        <AppointmentModal
          showInsertPayment={showInsertPayment}
          setData={({ cartId, discount }) => {
            if (
              typeof selectBooking.index === "number" &&
              allAppoin[selectBooking.index]
            ) {
              if (discount || typeof discount == "number") {
                allAppoin[selectBooking.index].discount = discount
                this.setState({
                  selectBooking: { ...selectBooking, discount },
                  allAppoin,
                })
              }
              if (cartId) {
                allAppoin[selectBooking.index].cartId = cartId
                this.setState({
                  selectBooking: { ...selectBooking, cartId },
                  allAppoin,
                })
              }
            }
          }}
          open={showBookingModal}
          selectBooking={selectBooking}
          isIndependent={getNestedValue(
            allSaloonMap,
            [
              getNestedValue(selectBooking, ["companyId", "_id"]),
              "isEmployeeIndependent",
            ],
            true
          )}
          employeeMap={employeeMap}
          isGym={isGym}
          handleDrawerClose={(ev) => {
            this.setState({ showBookingModal: false })
          }}
          statusButtonClicked={(status, additional) => {
            const sendUpdate = (result, append = {}) => {
              if (result.value) {
                this.setState({ bookingStaus: status }, () => {
                  this.handleUpdate({ ...append })
                })
              } else {
                this.setState(
                  {
                    bookingStaus: status,
                  },
                  this.handleUpdate
                )
              }
            }
            if (status == 3) {
              return sendUpdate({ value: true }, additional)
            }
            if (status != 4) {
              Swal.fire({
                title: i18n.t("change_status"),
                text: `${i18n.t("confirm_status_change")} ${i18n.t(
                  statusMap[status]
                )}`,
                showCancelButton: true,
                cancelButtonText: i18n.t("no"),
                confirmButtonText: i18n.t("yes"),
              }).then((result) => {
                if (result.value) {
                  if (status == 7) {
                    status = 3
                    const price = getNestedValue(
                      selectBooking,
                      ["totalAmount"],
                      ""
                    )
                    const noShowFeePercentage = getNestedValue(
                      selectBooking,
                      ["companyId", "noShowFeePercentage"],
                      0
                    )
                    const noShowFee = (price * noShowFeePercentage) / 100

                    if (noShowFee) {
                      Swal.fire({
                        title: i18n.t("charge_no_show_fee"),
                        text: `$ ${noShowFee.toFixed(2)}`,
                        showCancelButton: true,
                        cancelButtonText: i18n.t("no"),
                        confirmButtonText: i18n.t("yes"),
                      }).then((result) =>
                        sendUpdate(result, { isNoShow: result.value })
                      )
                    } else {
                      sendUpdate({ value: false })
                    }
                  } else {
                    sendUpdate({ value: false })
                  }
                }
              })
            } else {
              this.setState(
                {
                  bookingStaus: status,
                },
                this.handleUpdate
              )
            }
          }}
          onProductChange={async (e, index) => {
            this.setState({ isloading: true })
            await updateCartProduct(
              {
                productId: selectBooking.cartId.products[index]._id,
                cartId: selectBooking.cartId._id,
              },
              { quantity: e }
            )
              .then(({ data }) => {
                if (data.success) {
                  selectBooking.cartId.products[index].quantity =
                    data.data.quantity
                  allAppoin[selectBooking.index] = selectBooking
                  this.setState({
                    selectBooking: { ...selectBooking },
                    allAppoin,
                  })
                } else {
                  if (data.code) {
                    ErrorHelper.handleErrors(
                      i18n.t(data.code, { ...data.data }),
                      true
                    )
                  } else {
                    ErrorHelper.handleErrors("Failed to Add Item", true)
                  }
                }
              })
              .finally(() => {
                this.setState({ isloading: false })
              })
          }}
          onProductDelete={(index) => {
            this.setState({ isloading: true })
            removeProduct({
              productId: selectBooking.cartId.products[index]._id,
              cartId: selectBooking.cartId._id,
            })
              .then(({ data }) => {
                if (data.success) {
                  if (allAppoin[selectBooking.index]) {
                    allAppoin[selectBooking.index].cartId = data.data
                    this.setState({
                      selectBooking: { ...selectBooking, cartId: data.data },
                      allAppoin,
                    })
                  }
                }
              })
              .finally(() => {
                this.setState({ isloading: false })
              })
          }}
          onServiceDelete={(index) => {
            this.setState({ isloading: true })
            removeExtra({
              extraId: selectBooking.extras[index]._id,
              bookingId: selectBooking._id,
            })
              .then(({ data }) => {
                if (data.success) {
                  allAppoin[selectBooking.index] = data.data
                  this.setState({ selectBooking: data.data, allAppoin })
                }
              })
              .finally(() => {
                this.setState({ isloading: false })
              })
          }}
          onNotesClick={() => this.handleNotes()}
          onModifyClick={() => this.handleEditDetails()}
          onAddClick={() => this.handleAddProductsServices()}
          companyTaxes={companyTaxes}
          toggleDepositRequired={this.toggleDepositRequired}
          toggleTaxes={this.toggleTaxes}
          onChangeTip={this.onChangeTip}
          onChangeAdjustedTime={this.onChangeAdjustedTime}
          onUpdate={(data) => {
            this.setState({ ...data })
          }}
          products={products}
          bookings={allAppoin}
        ></AppointmentModal>
      )
    } else {
      return null
    }
  }

  genericUpdate = async (body) => {
    const { selectBooking } = this.state
    this.setState({
      isloading: true,
    })
    let { allAppoin } = this.state
    const services = [
      {
        serviceId: selectBooking.services[0].serviceId._id,
        categoryId: selectBooking.services[0].categoryId?._id,
        date: selectBooking.services[0].date,
        time: selectBooking.services[0].time,
        employeeId: selectBooking.services[0].employeeId?._id,
      },
    ]
    const { data } = await editBooking({
      bookingId: selectBooking._id,
      status: selectBooking.status,
      services,
      ...body,
    })
    let newSelectBooking = selectBooking
    if (data.success) {
      const newBooking = data.data
      newBooking.services = newBooking.services.map((service) => {
        return {
          ...service,
        }
      })
      newSelectBooking = { ...newBooking, index: selectBooking.index }
      if (typeof selectBooking.index === "number") {
        allAppoin[selectBooking.index] = newSelectBooking
      } else {
        const booking = allAppoin.find(
          (booking) => booking._id == newSelectBooking._id
        )
        newSelectBooking.index = booking.index
        allAppoin[booking.index] = newSelectBooking
      }
      this.handleClosePopup()
      this.setState({
        isloading: false,
        allAppoin: [...allAppoin],
        selectBooking: newSelectBooking,
        editDetails: false,
        ...body,
      })
    }
  }

  onChangeTip = (tip) => {
    const { tip: oldTip } = this.state
    if (oldTip != tip) {
      this.genericUpdate({ tip })
    }
  }

  onChangeAdjustedTime = (adjustedTime) => {
    this.genericUpdate({ adjustedTime })
  }

  toggleDepositRequired = (depositRequired) => {
    this.genericUpdate({ depositRequired })
  }

  toggleTaxes = (includeTaxes) => {
    this.genericUpdate({ includeTaxes })
  }

  render() {
    const { allAppoin, displayCalendar } = this.state

    return (
      <div>
        {this.renderLoader()}
        <div>
          <div className="row mx-0 mb-5 mb-md-0">
            {/* {this.renderEmployDashboardHeading()} */}
            {this.renderPopup()}
            {displayCalendar
              ? this.renderAppointmentCalendar(allAppoin ? allAppoin : [])
              : this.renderAppointmentTable(allAppoin ? allAppoin : [])}
            {this.renderEditAppointment()}
            {this.renderAdvancedSearchPopup()}
            {this.renderFOBs()}
          </div>
        </div>
      </div>
    )
  }
}

_AddWorkingHours.contextType = BookingContext

const mapStateToProps = (state) => ({
  getAvailableTimeslots: state.getAvailableTimeslots,
  booking: state.activeBooking.data,
  transactions: state.bookingTransaction.data,
})

const action = {
  get_available_timeslots,
  clear,
}

const ConnectAddWorkingHours = connect(
  mapStateToProps,
  action
)(_AddWorkingHours)

function AddWorkingHours(props) {
  const { getResource } = useCache()
  const { setTimeMin, setTimeMax } = useGoogleCalendar()
  const { setMinStartTime, setMaxStartTime } = useOutlookCalendar()
  const companies = getResource((cache) => cache.employee.companies)
  const taxMap = getResource((cache) => cache.employee.taxMap)
  const productMap = getResource((cache) => cache.employee.productMap)
  const screenSizeContext = useContext(ScreenSizeContext)
  return (
    <ConnectAddWorkingHours
      {...props}
      setTimeMax={setTimeMax}
      setTimeMin={setTimeMin}
      setMaxStartTime={setMaxStartTime}
      setMinStartTime={setMinStartTime}
      companies={companies}
      taxMap={taxMap}
      productMap={productMap}
      screenSizeContext={screenSizeContext}
    />
  )
}
export default withRouter(AddWorkingHours)
