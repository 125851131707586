// @flow
import Immutable from "seamless-immutable"
import * as types from "../../actions/ActionTypes"

const initialState = Immutable({
  isFetching: false,
  errorMessage: "",
  data: [],
})

export default (state = initialState, action) => {
  switch (action.type) {
    case types.ACTIVITY_SCHEDULE_TEMPLATE.GET:
      return Immutable.merge(state, {
        isFetching: false,
        data: action.data,
      })
    case types.ACTIVITY_SCHEDULE_TEMPLATE.GET_SAGA:
    case types.ACTIVITY_SCHEDULE_TEMPLATE.UPSERT_SAGA:
    case types.ACTIVITY_SCHEDULE_TEMPLATE.DELETE_SAGA:
    case types.ACTIVITY_SCHEDULE_TEMPLATE.DELETE_EVENT_SAGA:
    case types.ACTIVITY_SCHEDULE_TEMPLATE.UPSERT_EVENT_SAGA:
      return Immutable.merge(state, {
        isFetching: true,
      })
    case types.ACTIVITY_SCHEDULE_TEMPLATE.UPSERT: {
      const newState = { ...state, isFetching: false, data: [...state.data] }
      const { _id } = action.data
      const index = state.data.findIndex((element) => element._id == _id)
      if (index != -1) {
        newState.data[index] = action.data
      } else {
        newState.data.push(action.data)
      }
      return newState
    }
    case types.ACTIVITY_SCHEDULE_TEMPLATE.DELETE: {
      const newState = { ...state, isFetching: false, data: [...state.data] }
      const { _id } = action.data
      const index = state.data.findIndex((element) => element._id == _id)
      if (index != -1) {
        newState.data.splice(index, 1)
      }
      return newState
    }
    case types.ACTIVITY_SCHEDULE_TEMPLATE.UPSERT_EVENT: {
      const newState = { ...state, isFetching: false, data: [...state.data] }
      const normalized = Array.isArray(action.data)
        ? action.data
        : [action.data]
      normalized.map((element) => {
        const { activityScheduleTemplateId, _id } = element
        const index = state.data.findIndex(
          (element) => element._id == activityScheduleTemplateId
        )
        if (index != -1) {
          const eventIndex = newState.data[index].events.findIndex(
            (element) => element._id == _id
          )
          if (eventIndex == -1) {
            newState.data[index] = {
              ...newState.data[index],
              events: [...newState.data[index].events, ...normalized],
            }
          } else {
            const newEvents = [...newState.data[index].events]
            newEvents[eventIndex] = element
            newState.data[index] = {
              ...newState.data[index],
              events: newEvents,
            }
          }
        }
      })
      return newState
    }
    case types.ACTIVITY_SCHEDULE_TEMPLATE.DELETE_EVENT: {
      const newState = { ...state, isFetching: false, data: [...state.data] }
      const { activityScheduleTemplateId, _id } = action.data
      const index = state.data.findIndex(
        (element) => element._id == activityScheduleTemplateId
      )
      if (index != -1) {
        const eventIndex = newState.data[index].events.findIndex(
          (element) => element._id == _id
        )
        if (eventIndex != -1) {
          const copy = {
            ...newState.data[index],
            events: [...newState.data[index].events],
          }
          copy.events.splice(eventIndex, 1)
          newState.data[index] = copy
        }
      }
      return newState
    }
    case types.ACTIVITY_SCHEDULE_TEMPLATE.REQUEST_FAILURE:
      return Immutable.merge(state, {
        isFetching: false,
      })

    case types.LOGOUT:
      return initialState
    case types.CLEAR_LOGOUT:
      return initialState
    default:
      return state
  }
}
