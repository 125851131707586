import crudBuilder from "../../../builders/CrudBuilder/CrudBuilder"
import actions from "../../../redux/actions/Saloon/CustomerStatus"
const { upsertRequest, getRequest, removeRequest } = actions

const CustomerStatus = crudBuilder.build({
  type: "customerStatus",
  title: "customer_status",
  actionMap: {
    upsert: upsertRequest,
    get: getRequest,
    remove: removeRequest,
  },
})

export default CustomerStatus
