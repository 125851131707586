import { connect } from "react-redux";
import React, { Component } from "react";
import { Translation } from "react-i18next";
import i18n from "../../../i18n";
import { logout as user_logout } from "../../../redux/actions/Login";
import { set } from "../../../redux/actions/User";
import { getRequest as getCard } from "../../../redux/actions/Card";
import { clear } from "../../../redux/actions/ActiveBooking";
import { Images } from "../../../theme";
import { DotsLoader, Sidebar } from "../../../components";
import { SuccessHelper } from "../../../helpers";
import { ErrorHelper } from "../../../helpers";
import {
  editCustomerProfile,
  getWallet,
  getAllBookingsByUser,
  getWaitingList,
  deleteWaitingList,
  editBooking,
  createBooking,
} from "../../../config/simpleApiCalls";
import { getNestedValue } from "../../../util/objectMethods";
import NotificationCenter from "../../../components/NotificationCenter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getUserPackagesRequest } from "../../../redux/actions/Packages";

import moment from "moment";

import "./styles.css";
import Swal from "sweetalert2";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import {
  getRequest,
  removeRequest,
  updateRequest,
  insertRequest,
} from "../../../redux/actions/Card";
import { TextField } from "@material-ui/core";
import UserPackages from "../../../components/NewProfile/Components/UserPackages";
import SharedStorage from "../../../helpers/Storage";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <div className={{ width: "100wv" }}>{children}</div>}
    </div>
  );
};
class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      routeName: "",
      showSidebar: false,
      showDropdownMenu: false,
      userEmail: "",
      displayProfile: true,
      displayBooking: false,
      displayWallet: false,
      allBookings: [],
      showPopUp: false,
      selectBooking: "",
      messages_data: [],
      user: null,
      textMsg: "",
      value: 0,
    };
    this.messagesEndRef = React.createRef();
    this.messagesStartRef = React.createRef();
    this.notificationIcon = React.createRef();
  }

  async componentDidMount() {
    const user = await JSON.parse(await SharedStorage.getItem("user"));
    this.setUserData(user);

    this.setState({ user });
    // this.checkRoomFounded(user);
    this.getUserWalletInfo(user);
    this.getWaitingList(user);
    this.props.getUserPackagesRequest({
      relations: ["company"],
    });
    this.getBookingHistory();
    // getCard()
    this.props.getCard({});
    // console.log("hello cards", this.props.cards);
    // TODO:
    // get call to Cards
    // save to redux
    // action reducer sagas and store
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let toSet = {};
    if (nextProps.booking) {
      nextProps.clear();
      const booking = { ...nextProps.booking };

      toSet = {
        ...toSet,
        displayProfile: false,
        displayBooking: true,
        displayWallet: false,
        displayWaitingList: false,
        showPopUp: true,
        selectBooking: booking,
      };
    }
    return toSet;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.displayBooking &&
      this.state.displayBooking !== prevState.displayBooking
    ) {
      this.getBookingHistory();
    }
  }

  getUserWalletInfo = (user) => {
    getWallet()
      .then((res) => {
        this.setState({
          allWalletInfo: res.data.data.transactions,
          ammount: res.data.data.totalAmount,
        });
      })
      .catch((error) => { });
  };

  getWaitingList = (user) => {
    getWaitingList({})
      .then((res) => {
        this.setState({
          waitingList: res.data.data,
        });
      })
      .catch((error) => { });
  };

  onChangeDisplayBooking = () => {
    this.setState({
      displayProfile: false,
      displayBooking: true,
      displayWallet: false,
      displayWaitingList: false,
    });
  };
  onChangeDisplayWallet = () => {
    this.setState({
      displayProfile: false,
      displayBooking: false,
      displayWallet: true,
      displayWaitingList: false,
    });
  };
  onContentDisplayWaitingList = () => {
    this.setState({
      displayProfile: false,
      displayBooking: false,
      displayWallet: false,
      displayWaitingList: true,
    });
  };
  onContentDisplayProfile = () => {
    this.setState({
      displayProfile: true,
      displayBooking: false,
      displayWallet: false,
      displayWaitingList: false,
    });
  };

  getBookingHistory = async () => {
    this.setState({ isLoading: true });
    const {
      state: { user },
      state,
    } = this;
    try {
      const respose = await getAllBookingsByUser();
      this.setState({ isLoading: true, allBookings: respose.data.data });
    } catch (error) {
      alert("Something went wronge ;(");
      this.setState({ isLoading: false });
    }
  };

  handleUpdate = async (selectBooking) => {
    const payload = {
      ...selectBooking,
      bookingId: selectBooking._id,
      status: 3,
      services: selectBooking.services.map((service) => {
        return {
          ...service,
          categoryId:
            typeof service.categoryId == "object"
              ? service.categoryId._id
              : service.categoryId,
          employeeId:
            typeof service.employeeId == "object"
              ? service.employeeId._id
              : service.employeeId,
          serviceId:
            typeof service.serviceId == "object"
              ? service.serviceId._id
              : service.categoryId,
        };
      }),
    };
    delete payload._id;
    delete payload.__v;
    delete payload.userId;
    delete payload.companyId;
    delete payload.updatedDate;
    delete payload.createdDate;
    delete payload.email;

    const res = await editBooking(payload)
      .then(() => {
        SuccessHelper.handleSuccess("Updated Successfully", true);
        this.getBookingHistory();
      })
      .catch(() => {
        ErrorHelper.handleErrors("Failed to update", true);
      });
  };

  // componentDidMount() {
  //     this.setUserData()
  // }

  setUserData = (userData) => {
    if (userData) {
      this.setState({ user: userData, userEmail: userData.email });
    }
  };

  editProfile = async () => {
    const {
      state: { user },
      state,
    } = this;
    const payload = {};
    if (state.postalCode) payload.postalCode = state.postalCode;
    if (state.phoneNo) payload.phoneNo = state.phoneNo;
    if (state.firstName) payload.firstName = state.firstName;
    if (state.lastName) payload.lastName = state.lastName;
    try {
      const respose = await editCustomerProfile(payload);
      const { firstName, lastName, phoneNo } = respose.data.data;
      this.setState({ firstName, lastName, phoneNo });
      const updatedUser = JSON.stringify({ ...user, ...respose.data.data });
      this.props.set({
        user: updatedUser,
      });
      SuccessHelper.handleSuccess(
        <Translation>{(t) => t("container.profile_edit_success")}</Translation>,
        true
      );
    } catch (error) {
      alert(`Somthing went wronge ;( \n ${error.data.data}`);
    }
  };

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  handleLogout = () => {
    this.props.user_logout();
    this.setState({ user: null });
    this.handleNavigation("/");
  };

  renderLargeNavbar = () => {
    const { user, userEmail } = this.state;
    return (
      <div
        id="large-navbar"
        className="d-flex align-items-center justify-content-between"
      >
        <img
          alt="easy1 logo 800x300"
          src={Images.easy1_logo_800x300}
          className="logo cursor-pointer"
          onClick={() => this.handleNavigation("/")}
        />
        <div className="row mx-0 pr-1">
          <span className="d-flex align-items-center">
            {user && (
              <button
                type="button"
                className="emailButton"
              // onClick={() => this.handleLogout()}
              >
                {userEmail}
              </button>
            )}
          </span>
          <div
            className="sidebarContent align-self-center"
            onClick={this.handleLogout}
          >
            <p
              style={{ color: "#8E8E8E", fontSize: "18px", cursor: "pointer" }}
            >
              Logout
            </p>
          </div>
        </div>
      </div>
    );
  };

  setAnchor = (e) => {
    if (e) {
      this.setState({ anchor: this.notificationIcon.current });
    } else {
      this.setState({ anchor: null });
    }
  };

  renderSmallNavbar = () => {
    const { notifications } = this.props;
    const { showSidebar, userEmail, user } = this.state;
    return (
      <div id="small-navbar" className="header px-2">
        <div className="py-2 px-2 d-flex align-items-center justify-content-between">
          <img
            alt="easy1 logo 800x300"
            src={Images.easy1_logo_800x300}
            className="logo cursor-pointer"
            onClick={() => this.handleNavigation("/")}
          />
          <div className="row mx-0 align-items-center">
            <span
              style={{ cursor: "pointer" }}
              className="mr-1"
              onClick={this.setAnchor}
              ref={this.notificationIcon}
            >
              <FontAwesomeIcon
                color="#A2A2A2"
                icon={["fas", "bell"]}
                style={{ width: 20, height: 20 }}
              />
              {notifications.filter((notification) => notification.status == 1)
                .length ? (
                <span className="badge" />
              ) : null}
            </span>
            <span
              className="d-flex align-items-center"
              onClick={() =>
                this.setState({ showSidebar: showSidebar ? false : true })
              }
            >
              <div className="pr-2" onClick={this.handleLogout}>
                <p
                  style={{
                    color: "#8E8E8E",
                    fontSize: "18px",
                    cursor: "pointer",
                  }}
                >
                  Logout
                </p>
              </div>
            </span>
          </div>
        </div>
      </div>
    );
  };

  renderProfileInfo = (img, placeholder, val) => {
    return (
      <div className="col-4 col-4">
        <div className="row">
          <div className="col-lg-2">
            <img
              style={{ width: "30px", height: "30px", marginTop: 10 }}
              src={img}
            />
          </div>
          <div className="col-lg-6">
            <p style={{ marginBottom: 0 }}>{placeholder}</p>
            <p style={{ fontWeight: "bold" }}>{val}</p>
          </div>
        </div>
      </div>
    );
  };

  renderMainInformation = () => {
    const { user } = this.state;
    if (!user) return;
    return (
      <div style={{ backgroundColor: "#424242", paddingTop: "20px" }}>
        <div>
          <div className="container">
            <div
              className="row"
              id="mainProfile"
              style={{ backgroundColor: "#EBEBEB", padding: ".5rem" }}
            >
              {this.renderProfileInfo(
                Images.saloon_customer_name,
                "Name",
                user.firstName
                  ? `${user.firstName} ${user.lastName}`
                  : user.userName || ""
              )}
              {user.phoneNo &&
                this.renderProfileInfo(
                  Images.saloon_customer_phone,
                  <Translation>
                    {(t) => t("container.employe_phone_number")}
                  </Translation>,
                  user.phoneNo
                )}
              {this.renderProfileInfo(
                Images.saloon_customer_email,
                <Translation>
                  {(t) => t("container.profile_email")}
                </Translation>,
                user.email || ""
              )}
              {user.postalCole &&
                this.renderProfileInfo(
                  Images.saloon_customer_postal,
                  <Translation>
                    {(t) => t("container.postal_code")}
                  </Translation>,
                  user.postalCole
                )}
              {/* {this.renderProfileInfo(Images.saloon_customer_date,"Date Of Birth","09-05-2020")} */}
            </div>
          </div>
        </div>
      </div>
    );
  };

  handleTabChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  renderSideBar = () => {
    const { user } = this.state;
    // const {
    //   displayProfile,
    //   displayBooking,
    //   displayWallet,
    //   displayWaitingList,
    // } = this.state;
    return (
      <div>
        <AppBar
          className="mt-3 "
          elevation={0}
          position="static"
          color="default"
          style={{ border: "2px solid #d3d3d3", borderBottom: "none" }}
        >
          <Tabs
            value={this.state.value}
            onChange={this.handleTabChange}
            variant="scrollable"
            scrollButtons="on"
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable force tabs example"
            style={{ width: "100%" }}
          >
            <Tab label="Profile" />
            <Tab label="Booking History" />
            <Tab label="wallet" />
            <Tab label="waiting list" />
            <Tab label={i18n.t("packages")} />
            {/* <Tab label="Cards" /> */}
            {/* <Tab label="wallet" />
            <Tab label="waiting list" /> */}
          </Tabs>
        </AppBar>
        <TabPanel value={this.state.value} index={0}>
          {this.renderProfileSection()}
        </TabPanel>
        <TabPanel value={this.state.value} index={1}>
          {this.renderBooking()}
        </TabPanel>
        <TabPanel value={this.state.value} index={2}>
          {this.renderWalletSectionRow()}
        </TabPanel>
        <TabPanel value={this.state.value} index={3}>
          {this.renderWaitingList()}
        </TabPanel>
        <TabPanel value={this.state.value} index={4}>
          {this.renderPackages()}
        </TabPanel>
        {/* <TabPanel value={this.state.value} index={5}>
          <CardManagment
            removeDefault={true}
            getRequest={this.props.getRequest}
            removeRequest={this.props.removeRequest}
            updateRequest={this.props.updateRequest}
            insertRequest={this.props.insertRequest}
            cards={this.props.cards}
          />
        </TabPanel> */}
      </div>
    );
  };

  renderInputCol6 = (lable, inputType, val, isDisable, keyName) => {
    return (
      <div
        className="form-group col-lg-12"
        title={
          !!isDisable
            ? `⊗ Con't able to edit ${lable}`
            : `✎ Able to edit ${lable}`
        }
      >
        <TextField
          label={lable}
          className="col-md-12"
          onChange={(e) => this.setState({ [keyName]: e.target.value })}
          value={this.state[keyName] !== undefined ? this.state[keyName] : val}
          type={inputType}
          disabled={!!isDisable}
        />

        {/* <label style={{ fontWeight: "bold" }} className="saloon-form-lable">
          {lable}:
        </label>
        <input
          type={inputType}
          className="form-control saloon-form-input"
          value={this.state[keyName] !== undefined ? this.state[keyName] : val}
          disabled={!!isDisable}
          onChange={(e) => this.setState({ [keyName]: e.target.value })}
        /> */}
      </div>
    );
  };

  renderProfileSection = () => {
    const { user, firstName, lastName, phoneNo } = this.state;
    if (!user) return;

    return (
      <div style={{ paddingBottom: "40px" }}>
        <div className="mainBorderStyle">
          <div style={{ padding: "1rem", paddingLeft: "1.5rem" }}>
            <p style={{ fontSize: "20px", fontWeight: "bold" }}>
              {" "}
              <Translation>{(t) => t("container.profile_edit")}</Translation>
            </p>
          </div>
          <div className="row">
            <div className="col-lg-6">
              {this.renderInputCol6(
                i18n.t("first_name"),
                "text",
                firstName || user.firstName || "",
                false,
                "firstName"
              )}
            </div>
            <div className="col-lg-6">
              {this.renderInputCol6(
                i18n.t("last_name"),
                "text",
                lastName || user.lastName || "",
                false,
                "lastName"
              )}
            </div>

            <div className="col-lg-6">
              {this.renderInputCol6(
                <Translation>
                  {(t) => t("container.employe_phone_number")}
                </Translation>,
                "text",
                phoneNo || user.phoneNo || "",
                false,
                "phoneNo"
              )}
            </div>
            <div className="col-lg-6">
              {this.renderInputCol6(
                <Translation>
                  {(t) => t("container.profile_email")}
                </Translation>,
                "text",
                user.email || "",
                true,
                "email"
              )}
            </div>
            <div className="col-lg-6">
              {this.renderInputCol6(
                <Translation>{(t) => t("container.postal_code")}</Translation>,
                "text",
                user.postalCode || "",
                false,
                "postalCode"
              )}
            </div>
            {/* <div class="col-lg-6">
                {this.renderInputCol6("Date Of Birth",'text',"1/2/2020")}
            </div> */}
          </div>
          <div style={{ paddingTop: "40px", paddingBottom: "20px" }}>
            <button
              onClick={this.editProfile}
              style={{ marginLeft: "40px" }}
              className="btn btn-light btn-sm"
            >
              <Translation>{(t) => t("container.profile_update")}</Translation>
            </button>
          </div>
        </div>
      </div>
    );
  };

  scrollUp = () => {
    this.messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  scrollDown = () => {
    this.messagesStartRef.current.scrollIntoView({ behavior: "smooth" });
  };

  renderSupportMessage = (val, id) => {
    return (
      <div key={id} className="row mb-3 mb-md-4 support-message">
        <div className="col-10 col-md-6">
          <div className="col-12 py-3">
            <div className="row mb-2">
              <div className="col-6">
                <span className="support-user-name">{val.senderName}</span>
              </div>
              <div className="col-6 text-right">
                <span className="support-message-time">
                  {moment(val.createdDate).fromNow()}
                </span>
              </div>
            </div>
            <p className="support-text-message">{val.msg}</p>
          </div>
        </div>
      </div>
    );
  };

  renderUserMessage = (val, id) => {
    return (
      <div key={id} className="row mb-3 mb-md-4 user-message">
        <div className="col-10 col-md-6 ml-auto">
          <div className="col-12 py-3">
            <div className="row mb-2">
              <div className="col-6">
                <span className="support-user-name">{val.senderName}</span>
              </div>
              <div className="col-6 text-right">
                <span className="support-message-time">
                  {moment(val.createdAt).fromNow()}
                </span>
              </div>
            </div>
            <p className="support-text-message">{val.msg}</p>
          </div>
        </div>
      </div>
    );
  };

  renderMessagesContainer = () => {
    const { messages_data, user } = this.state;
    return (
      <div className="col-12 aaaaaaa" id="messages-container">
        <div ref={this.messagesStartRef} />
        {messages_data &&
          !!messages_data.length &&
          messages_data.map((val, id) => {
            if (val.userId === user._id) {
              return this.renderUserMessage(val, id);
            } else {
              return this.renderSupportMessage(val, id);
            }
          })}
        <div ref={this.messagesEndRef} />
      </div>
    );
  };

  renderWalletSectionRow = () => {
    const { allWalletInfo, ammount } = this.state;
    return (
      <div className="mainBookingBorder ">
        <div
          className="d-flex justify-content-between"
          style={{ padding: "1rem", paddingLeft: "2.5rem" }}
        >
          <div>
            <p style={{ fontSize: "20px", fontWeight: "bold" }}>
              {i18n.t("container.profile_my_wallet")}
            </p>
            {/* <p style={{ fontSize: "20px" }}>Below are your wallet point</p> */}
          </div>
          <div>
            <p
              className=""
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                color: "#FF3600",
                textAlign: "center",
              }}
            >
              {ammount} Pts
            </p>
            <button
              type="button"
              className="btn btn-light btn-sm mb-2"
              style={{ width: "120px" }}
            >
              $ {(ammount / 10000).toFixed(2)}
            </button>
          </div>
        </div>
        <div style={{ overflowX: "auto" }} className="full-width px-3">
          <table className="bookingTable">
            <button className="tableButton mb-3">
              <Translation>
                {(t) => t("container.profile_my_wallet")}
              </Translation>{" "}
            </button>
            <tr className="">
              <th style={{ padding: "0.5rem" }}>
                <Translation>
                  {(t) => t("container.profile_salon_name")}
                </Translation>
              </th>
              <th style={{ padding: "0.5rem" }}>Date</th>
              <th style={{ padding: "0.5rem", textAlign: "center" }}>
                <Translation>
                  {(t) => t("container.profile_points_left")}
                </Translation>
              </th>
              <th style={{ padding: "0.5rem" }}>
                <Translation>
                  {(t) => t("container.profile_points_remaining")}
                </Translation>
              </th>
            </tr>
          </table>
          {this.renderWalletSection()}
        </div>
      </div>
    );
  };

  renderWalletSection = () => {
    const { allWalletInfo, ammount } = this.state;

    return (
      <div>
        <table className="bookingTable px-3">
          {allWalletInfo &&
            allWalletInfo.map((v) => {
              return (
                <tr className="border-top">
                  <td style={{ padding: "0.5rem" }}>
                    {v.bookingId?.companyId?.name}
                  </td>
                  <td style={{ padding: "0.5rem" }}>
                    {moment().format("DD-MM-YYYY", v.createdDate)}
                  </td>
                  <td style={{ padding: "0.5rem" }}>
                    {v.type == "Credit"
                      ? `+${v.paidAmount}`
                      : `-${v.paidAmount}`}
                  </td>
                  <td style={{ padding: "0.5rem" }}>{v.totalAmount}</td>
                </tr>
              );
            })}
        </table>
      </div>
    );
  };

  renderBooking = () => {
    const { allBookings } = this.state;
    return (
      <div className="mainBookingBorder px-3">
        <div style={{ padding: "1rem", paddingLeft: "2.5rem" }}>
          <p style={{ fontSize: "20px", fontWeight: "bold" }}>
            <Translation>
              {(t) => t("container.profile_booking_history")}
            </Translation>
          </p>
          {/* <p style={{ fontSize: "20px" }}>Below your are booking history</p> */}
        </div>
        <div style={{ overflowX: "auto" }}>
          <table className="bookingTable">
            <button className="tableButton">
              <Translation>
                {(t) => t("container.profile_booking_history")}
              </Translation>
            </button>
            <tr>
              <th className="p-2">
                <Translation>{(t) => t("container.profile_salon")}</Translation>
              </th>
              <th className="p-2">
                <Translation>{(t) => t("container.employee")}</Translation>
              </th>
              <th className="p-2">
                <Translation>{(t) => t("container.profile_price")}</Translation>
              </th>
              <th className="p-2">
                <Translation>
                  {(t) => t("container.profile_status")}
                </Translation>
              </th>
              <th className="p-2">
                <Translation>
                  {(t) => t("container.profile_more_info")}
                </Translation>
              </th>
              <th></th>
            </tr>
            {!allBookings.length ? (
              <h1>Loading...</h1>
            ) : (
              allBookings.map((value) => {
                return (
                  <>
                    <tr>
                      <td className="p-2">
                        {value.companyId ? value.companyId.name : ""}
                      </td>
                      <td className="p-2">
                        {getNestedValue(value, [
                          "services",
                          0,
                          "employeeId",
                          "userId",
                          "userName",
                        ]) || ""}
                      </td>
                      <td className="p-2">$ {value.totalAmount || 0}</td>
                      <td className="p-2" style={{ fontWeight: "bold" }}>
                        {value?.status ? (
                          <div>
                            {value.status === 1 ? "Pending" : null}
                            {value.status === 2 ? "Now serving" : null}
                            {value.status === 3 ? "Cancelled" : null}
                            {value.status === 4 ? "Done" : null}
                          </div>
                        ) : null}
                      </td>
                      <td className=" action-cell">
                        <div className="d-flex align-items-center mt-1">
                          <button
                            style={{
                              backgroundColor: "#FF3600",
                              fontSize: "15px",
                            }}
                            className="btn btn-lg"
                            onClick={() =>
                              this.setState({
                                showPopUp: true,
                                selectBooking: value,
                              })
                            }
                          >
                            Details
                          </button>
                        </div>
                      </td>
                      <td className=" action-cell">
                        {value.status != 3 && value.status != 4 ? (
                          <div className="d-flex align-items-center mt-1 py-1">
                            <button
                              style={{
                                backgroundColor: "#FF3600",
                                fontSize: "15px",
                              }}
                              className="btn btn-lg"
                              onClick={() => {
                                Swal.fire({
                                  title: "Are you sure you want to cancel?",
                                  text: "You cannot undo this",
                                  icon: "warning",
                                  showCancelButton: true,
                                  confirmButtonColor: "#ff3600",
                                  cancelButtonColor: "#354e68",
                                  confirmButtonText: "Yes",
                                  cancelButtonText: "No",
                                }).then((result) => {
                                  if (result.value) {
                                    this.handleUpdate(value);
                                  }
                                });
                              }}
                            >
                              {i18n.t("container.services_cancelled_status")}
                            </button>
                          </div>
                        ) : null}
                      </td>
                    </tr>
                    {this.renderPopup()}
                  </>
                );
              })
            )}
          </table>
        </div>
      </div>
    );
  };

  handleWaitingListDelete = (ids) => {
    const { user } = this.state;
    this.setState({ isloading: true });
    deleteWaitingList({ ids })
      .then(() => {
        SuccessHelper.handleSuccess(i18n.t("successfully_deleted"), true);
        this.getWaitingList(user);
        this.setState({ isloading: false });
      })
      .catch(() => {
        ErrorHelper.handleErrors(i18n.t("failed_to_delete"), true);
        this.setState({ isloading: false });
      });
  };

  renderWaitingList = () => {
    const { waitingList = [], user } = this.state;
    return (
      <div className="mainBookingBorder px-3">
        <div style={{ padding: "1rem", paddingLeft: "2.5rem" }}>
          <p style={{ fontSize: "20px", fontWeight: "bold" }}>
            <Translation>{(t) => t("waiting_list")}</Translation>
          </p>
          {/* <p style={{ fontSize: "20px" }}>Below your are booking history</p> */}
        </div>
        <div style={{ overflowX: "auto" }}>
          <table className="bookingTable px-3">
            <button className="tableButton">
              <Translation>{(t) => t("waiting_list")}</Translation>
            </button>
            <tr>
              <th style={{ padding: "0.5rem" }}>
                <Translation>{(t) => t("container.profile_salon")}</Translation>
              </th>
              <th style={{ padding: "0.5rem" }}>
                <Translation>{(t) => t("date")}</Translation>
              </th>
              <th style={{ padding: "0.5rem" }}>
                <Translation>{(t) => t("container.time")}</Translation>
              </th>
              <th style={{ padding: "0.5rem" }}>
                <Translation>
                  {(t) => t("container.profile_employe_name")}
                </Translation>
              </th>
              <th style={{ padding: "0.5rem" }}>
                <Translation>
                  {(t) => t("container.profile_service")}
                </Translation>
              </th>
              <th style={{ padding: "0.5rem" }}></th>
            </tr>
            {!waitingList ? (
              <h1>Loading...</h1>
            ) : (
              waitingList.map((value) => {
                return (
                  <>
                    <tr>
                      <td style={{ padding: "0.5rem" }}>
                        {value.companyId ? value.companyId.name : ""}
                      </td>
                      <td style={{ padding: "0.5rem" }}>{value.date}</td>
                      <td style={{ padding: "0.5rem" }}>
                        {value.startTime} - {value.endTime}
                      </td>
                      <td style={{ padding: "0.5rem" }}>
                        {getNestedValue(
                          value,
                          ["employeeId", "userId", "firstName"],
                          ""
                        )}{" "}
                        {getNestedValue(
                          value,
                          ["employeeId", "userId", "lastName"],
                          ""
                        )}
                      </td>
                      <td style={{ padding: "0.5rem" }}>
                        {value.serviceId.name}
                      </td>
                      <td style={{ padding: "0.5rem" }}>
                        <img
                          onClick={() =>
                            this.handleWaitingListDelete(value._id)
                          }
                          className="category-table-action-icon"
                          src={Images.saloon_working_hour_delete}
                        />
                      </td>
                    </tr>
                    {this.renderPopup()}
                  </>
                );
              })
            )}
          </table>
        </div>
      </div>
    );
  };

  renderPackages = () => {
    const { user = {} } = this.state;
    return (
      <div className="mainBookingBorder px-3">
        <UserPackages
          phoneNo={user?.phoneNumber}
          email={user?.email}
          name={user?.firstName || user?.userName}
          bookNow={this.bookNow}
        />
      </div>
    );
  };

  bookNow = (payload) => {
    const { user = {} } = this.state;
    this.setState({ isloading: true });
    if (payload.services[0].time && payload.services[0].date) {
      payload.name = payload.userName;
      payload.phoneNo = user?.phoneNo;
      delete payload.userName;
      return createBooking(payload)
        .then((res) => {
          if (res.data.success) {
            SuccessHelper.handleSuccess(i18n.t("booking_created"), true);
            this.setState({ isloading: false });
          } else {
            this.setState({ isloading: false });
            ErrorHelper.handleErrors(res.data.msg, true);
          }
        })
        .catch((error) => {
          this.setState({ isloading: false });
          ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
        });
    }
  };

  renderPopup = () => {
    const { showPopUp } = this.state;
    return (
      <div
        className="employee-service-card-wrapper "
        style={{ display: showPopUp ? "flex" : "none" }}
      >
        <div className="container ">
          <div
            className="col-8 p-3 my-5 bg-white pop_container"
            style={{ borderRadius: "1rem", margin: "auto" }}
          >
            <div className="py-0 px-2 text-right">
              <span
                onClick={() => this.setState({ showPopUp: false })}
                className="h4 cursor-pointer"
              >
                &times;
              </span>
            </div>
            {this.renderBookingMoreInfo()}
          </div>
        </div>
      </div>
    );
  };

  renderBookingMoreInfo = () => {
    const { allSaloons, selectBooking, bookingStaus, bookingStauses } =
      this.state;

    return (
      <div>
        <div class="form-group justify-content-center align-items-center">
          <div>
            {selectBooking &&
              selectBooking.services.map((service, index) => (
                <>
                  <div
                    className="myappoint-input-container row "
                    style={{
                      width: "auto",
                      flexDirection: "row",
                    }}
                  >
                    <div className="form-group my-apointment-input">
                      <div className="pt-3 justify-content-center align-items-center">
                        <div className="spaceAround">
                          <span>
                            {index + 1}.{" "}
                            <Translation>
                              {(t) => t("container.profile_service")}
                            </Translation>
                          </span>
                          <span className="textSize">
                            {moment(`${service.date} ${service.time}`).format(
                              "MMMM Do YYYY h:mm a"
                            )}
                          </span>
                        </div>

                        <input
                          disabled
                          type="text"
                          id="addEmployee_search_bar leftSpace"
                          className="form-control form-control-sm appointment-input lowLeftSpace"
                          placeholder="Service"
                          value={service.serviceId.name}
                        />
                      </div>
                    </div>
                    <div className="form-group  my-apointment-input">
                      {typeof service.employeeId == "object" && (
                        <div className="pt-3 justify-content-center align-items-center">
                          <div className="spaceAround">
                            <span>
                              {" "}
                              <Translation>
                                {(t) => t("container.profile_employe_name")}
                              </Translation>
                            </span>
                          </div>

                          <input
                            disabled
                            type="text"
                            id="addEmployee_search_bar leftSpace"
                            className="form-control form-control-sm appointment-input lowLeftSpace"
                            placeholder="Employe Name"
                            value={
                              getNestedValue(service.employeeId, [
                                "userId",
                                "userName",
                              ]) ||
                              `${getNestedValue(service.employeeId, [
                                "userId",
                                "firstName",
                              ])} ${getNestedValue(service.employeeId, [
                                "userId",
                                "lastName",
                              ])}`
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </>
              ))}
          </div>
        </div>
      </div>
    );
  };

  renderContent = () => {
    const {
      displayProfile,
      displayBooking,
      displayWallet,
      displayWaitingList,
    } = this.state;
    return <div className="mx-3">{this.renderSideBar()}</div>;
  };

  render() {
    const { notifications, history } = this.props;
    const { anchor, isloading } = this.state;
    return (
      <div
        style={{
          backgroundColor: this.props.isBackgroundColor
            ? "#fff"
            : "transparent",
          transition: "background-color 300ms linear",
        }}
      >
        <DotsLoader isloading={isloading} />
        <NotificationCenter
          {...{
            notifications,
            anchor,
            setAnchor: this.setAnchor,
            history,
          }}
        />
        {this.renderLargeNavbar()}
        {this.renderSmallNavbar()}
        {this.renderMainInformation()}
        {this.renderContent()}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  logout: state.logout,
  notifications: [...state.getNotifications.data],
  booking: state.activeBooking.data,
  cards: state.card.data,
});

const action = {
  user_logout,
  clear,
  set,
  getCard,
  removeRequest,
  updateRequest,
  insertRequest,
  getRequest,
  getUserPackagesRequest,
};
export default connect(mapStateToProps, action)(Profile);
