import React, { useState } from "react";
import i18n from "../../i18n";
import BaseModal from "../BaseModal/index";
import PrimaryButton from "../Core/PrimaryButton/index";
import Select from "../Core/Select/index";

const ModifyModal = ({ cancelledAppointment, handleClose, onSubmit }) => {
  const yesNoOptions = [
    { value: "yes", name: i18n.t("yes") },
    { value: "no", name: i18n.t("no") },
  ];
  const [notifyCustomer, setNotifyCustomer] = useState(false);

  return (
    <BaseModal
      open={true}
      containerClassName={"half-container"}
      {...{
        title: <div>{cancelledAppointment ? i18n.t("reinstate_appointment") : i18n.t("modify")}</div>,
        content: (
          <div>
            <Select
              options={yesNoOptions}
              defaultValue={"no"}
              excludeEmptyValue
              label={i18n.t("notify_customer")}
              onChange={(e) => setNotifyCustomer(e.target.value == "yes")}
            />
          </div>
        ),
        actions: (
          <div className="row mx-0 px-0 justify-content-end">
            <PrimaryButton
              onClick={() => onSubmit({ notifyCustomer })}
              label={i18n.t("confirm")}
            />
          </div>
        ),
      }}
      onClose={() => handleClose()}
    />
  );
};

export default ModifyModal;
