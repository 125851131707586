import React, { useEffect, useState } from "react";
import i18n from "../../../i18n";

import PrimaryButton from "../../../components/Core/PrimaryButton";
import { DotsLoader } from "../../../components";
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import Select from "../../../components/Core/Select";
import { getSaloonById, saveAiSettings } from "../../../config/simpleApiCalls";
import { TextField } from "@material-ui/core";
import ToggleSelect from "../../../components/ToggleSelect";

export default function AiSettings() {
  const [loading, setLoading] = useState(false);
  const [numberOfSlotsPerSession, setNumberOfSlotsPerSession] = useState(2);
  const [scheduleSendTime, setScheduleSendTime] = useState("08:00");
  const [scheduleBlocksTimeslots, setScheduleBlocksTimeslots] = useState("yes");

  useEffect(() => {
    setLoading(true);
    getSaloonById()
      .then(({ data }) => {
        setNumberOfSlotsPerSession(data.data.numberOfSlotsPerSession || 2);
        setScheduleSendTime(data.data.scheduleSendTime || "08:00");
        setScheduleBlocksTimeslots(
          data.data.scheduleBlocksTimeslots ? "yes" : "no"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onSaveCustomMessages = () => {
    setLoading(true);
    saveAiSettings({
      numberOfSlotsPerSession,
      scheduleSendTime,
      scheduleBlocksTimeslots: scheduleBlocksTimeslots == "yes",
    })
      .then(() => {
        SuccessHelper.handleSuccess(i18n.t("successfully_updated"), true);
      })
      .catch(() => {
        ErrorHelper.handleErrors(i18n.t("failed_to_update"), true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="content-container p-4">
      <DotsLoader isloading={loading} />
      <h1 className="saloon-dashboard-heading pb-4">
        {i18n.t("ai_settings")}
      </h1>
      <div className="row col-lg-4 col-md-6 align-items-center form-group px-0 mb-3">
        <label className="font-weight-bold" style={{ width: '200px' }}>{i18n.t("number_of_slots_per_session")}</label>
        <Select
          value={numberOfSlotsPerSession}
          options={[
            { value: "2", name: "2" },
            { value: "3", name: "3" },
            { value: "4", name: "4" },
            { value: "5", name: "5" },
            { value: "6", name: "6" },
            { value: "7", name: "7" },
            { value: "8", name: "8" },
            { value: "9", name: "9" },
            { value: "10", name: "10" },
          ]}
          excludeEmptyValue
          onChange={(e) => {
            setNumberOfSlotsPerSession(e.target.value);
          }}
          className="editField"
          variant="outlined"
        />
      </div>
      <div className="row col-lg-4 col-md-6 align-items-center form-group px-0 mb-3">
        <label className="font-weight-bold" style={{ width: '200px' }}>{i18n.t("send_schedule_at")}</label>
        <TextField
          value={scheduleSendTime}
          defaultValue="08:00"
          type="time"
          onChange={(e) => {
            setScheduleSendTime(e.target.value);
          }}
          className="editField"
          variant="outlined"
        />
      </div>
      <div className="row col-lg-4 col-md-6 align-items-center form-group px-0 mb-3">
        <label className="font-weight-bold" style={{ width: '200px' }}>{i18n.t("schedule_block_timeslots")}</label>
        <ToggleSelect
          value={scheduleBlocksTimeslots === "yes" ? true : false}
          onChange={(e) => {
            setScheduleBlocksTimeslots(e ? "yes" : "no");
          }}
          active="yes"
          inactive="no"
        />
      </div>
      <div className="mt-4">
        <PrimaryButton className="w-128" label={i18n.t("save")} onClick={onSaveCustomMessages} />
      </div>
    </div>
  );
}
