// @flow

import { SET_ACTIVE_BOOKING, UPDATE_ACTIVE_BOOKING, CLEAR_ACTIVE_BOOKING, LOGOUT } from "./ActionTypes"

export function set(data) {
  console.log("data", data)
  return {
    data,
    type: SET_ACTIVE_BOOKING
  }
}

export function clear() {
  return {
    type: CLEAR_ACTIVE_BOOKING
  }
}

export function logout() {
  return {
    type: LOGOUT
  }
}
