import { TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import React, { useMemo } from "react"
import { useEffect } from "react"
import ReactDatePicker from "react-datepicker"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import statusActions from "../../../../../redux/actions/Saloon/CustomerStatus"
import Select from "../../../../../components/Core/Select"
import useCache from "../../../../../context/LocalCache"
import i18n from "../../../../../i18n"
import "./IntakeFilters.css"
import ActivityActions from "../../../../../redux/actions/Saloon/Activity"
const { getRequest: getActivities } = ActivityActions
export default function IntakeFilters({ filters, setFilters, onFilter }) {
  const dispatch = useDispatch()
  const { getResource } = useCache()
  const intakeCategories = getResource(
    (cache) => cache.company.intakeCategories
  )
  const products = getResource((cache) => cache.company.products)
  const services = getResource((cache) => cache.company.services)
  const statuses = useSelector((state) => state.customerStatus.data)
  const intakeTypes = useSelector((state) => state.intakeType.data)
  const employees = getResource((cache) => cache.company.employees).filter(
    ({ isActive }) => isActive
  )
  const activities = useSelector((s) => s.activity.data)
  const formattedEmployees = useMemo(() => {
    return employees.map(({ user }) => ({
      value: user._id,
      name: `${user.firstName} ${user.lastName}`,
    }))
  }, [employees])
  const _intakeCategory = useMemo(() => {
    const items = []
    intakeCategories.map((_category) => {
      items.push(..._category.items)
    })
    return items
  }, [intakeCategories])

  const formattedIntakeTypes = useMemo(() => {
    return intakeTypes.map((intakeType) => ({
      value: intakeType._id,
      name: intakeType.name,
    }))
  }, [intakeTypes])
  useEffect(() => {
    dispatch(statusActions.getRequest())
    dispatch(getActivities())
  }, [])

  return (
    <div>
      <div className="filter-header">
        <div className="d-flex flex-column flex-md-row align-items-md-center align-items-start form-box">
          <label>{i18n.t("products")}</label>
          <Autocomplete
            multiple
            options={products.map((element) => ({
              value: element._id,
              name: element.name,
            }))}
            getOptionLabel={(option) => option?.name}
            disableCloseOnSelect={true}
            onChange={(e, value) => {
              if (!value.length) {
                delete filters.products
                return setFilters({ ...filters })
              }
              const products = value.map(({ value }) => value)
              setFilters({ ...filters, products: products })
            }}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={i18n.t("products")}
              />
            )}
          />
        </div>
        <div className="d-flex flex-column flex-md-row align-items-md-center align-items-start form-box">
          <label>{i18n.t("services")}</label>
          <Autocomplete
            multiple
            options={services.map((element) => ({
              value: element._id,
              name: element.name,
            }))}
            getOptionLabel={(option) => option?.name}
            disableCloseOnSelect={true}
            onChange={(e, value) => {
              if (!value.length) {
                delete filters.services
                return setFilters({ ...filters })
              }
              const services = value.map(({ value }) => value)
              setFilters({ ...filters, services: services })
            }}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={i18n.t("services")}
              />
            )}
          />
        </div>
        <div className="d-flex flex-column flex-md-row align-items-md-center align-items-start form-box">
          <label>{i18n.t("category")}</label>
          <Select
            label={i18n.t("category")}
            options={_intakeCategory.map((element) => ({
              value: element._id,
              name: element.name,
            }))}
            onChange={(e) => {
              if (!e.target.value) {
                delete filters.intakeCategoryItemId
                return setFilters({ ...filters })
              }
              setFilters({ ...filters, intakeCategoryItemId: e.target.value })
            }}
            variant="outlined"
          />
        </div>
        <div className="d-flex flex-column flex-md-row align-items-md-center align-items-start form-box">
          <label>{i18n.t("status")}</label>
          <Select
            label={i18n.t("status")}
            onChange={(e) => {
              setFilters({ ...filters, statusId: e.target.value })
            }}
            value={filters.statusId || ""}
            options={statuses.map(({ _id, name }) => ({ name, value: _id }))}
            variant="outlined"
          />
        </div>
        <div className="d-flex flex-column flex-md-row align-items-md-center align-items-start form-box">
          <label>{i18n.t("created_by")}</label>
          <Select
            label={i18n.t("created_by")}
            onChange={(e) => {
              setFilters({ ...filters, createdBy: e.target.value })
            }}
            value={filters.createdBy || ""}
            options={formattedEmployees}
            variant="outlined"
          />
        </div>
        <div className="d-flex flex-column flex-md-row align-items-md-center align-items-start form-box">
          <label>{i18n.t("type")}</label>
          <Select
            label={i18n.t("type")}
            onChange={(e) => {
              setFilters({ ...filters, intakeTypeId: e.target.value })
            }}
            value={filters.intakeTypeId || ""}
            options={formattedIntakeTypes}
            variant="outlined"
          />
        </div>
      </div>
      <div className="d-sm-flex gap-2 px-3">
        <div className="d-flex flex-column flex-md-row align-items-md-center align-items-start form-box">
          <label>{i18n.t("activities")}</label>
          <div className="mobile-w-100" style={{ width: 200 }}>
            <Autocomplete
              multiple
              options={activities.map((element) => ({
                value: element._id,
                name: element.name,
              }))}
              getOptionLabel={(option) => option?.name}
              disableCloseOnSelect={true}
              onChange={(e, value) => {
                if (!value.length) {
                  delete filters.activities
                  return setFilters({ ...filters })
                }
                const activities = value.map(({ value }) => value)
                setFilters({ ...filters, activities: activities })
              }}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={i18n.t("activities")}
                />
              )}
            />
          </div>
        </div>
        <div className="form-box">
          <label style={{ minWidth: "fit-content" }}>
            {i18n.t("date_range")}
          </label>
          <div className="d-flex gap-2">
            <ReactDatePicker
              selected={filters.startDate}
              dateFormat="MM/dd/yyyy"
              onChange={(val) => {
                setFilters({ ...filters, startDate: val })
              }}
              customInput={
                <TextField
                  label={i18n.t("start_date")}
                  value={filters.startDate}
                  className="full-width"
                  readOnly={true}
                  variant="outlined"
                />
              }
            />
            <ReactDatePicker
              selected={filters.endDate}
              dateFormat="MM/dd/yyyy"
              onChange={(val) => {
                setFilters({ ...filters, endDate: val })
              }}
              customInput={
                <TextField
                  label={i18n.t("end_date")}
                  value={filters.endDate}
                  className="full-width"
                  readOnly={true}
                  variant="outlined"
                />
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}
