import {
  Box,
  Collapse,
  Paper,
  TextField,
  FormHelperText,
} from "@material-ui/core"
import { ExpandLess, ExpandMore, Lock } from "@material-ui/icons"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { useMemo } from "react"
import { Calendar, DateObject } from "react-multi-date-picker"

import { useDispatch, useSelector } from "react-redux"
import i18n from "../../../../i18n"
import "./DateAndTime.css"
import TouchList from "./TouchList"
import { request as getAvailableTimeslots } from "../../../../redux/actions/GetAvailableTimeslots.js"
import useBooking from "../../../../context/Booking"
import TouchChip from "./TouchChip"
import styled from "styled-components"
import gregorian_fr from "../../../../locales/fr/gregorian_fr"
import DatePicker from "react-datepicker"
import Select from "../../../Core/Select"
import { getAvailablesSpots } from "../../../../config/simpleApiCalls.js"
const CalendarWrapper = styled.div`
  display: flex;
  @media (max-width: 480px) {
    flex-direction: column;
  }
`

export function EmployeeDateAndTime({
  data,
  open,
  setOpen,
  onChange,
  formErrors,
  enableRecurringBooking,
}) {
  const dispatch = useDispatch()
  const {
    timeObj,
    newTime = "",
    selectServices,
    employeeId,
    selectSalon = {},
    formattedDate = "",
    recurringBookingDates = [],
    startTime = "",
    endTime = "",
    selectDateAndTime,
    enableWaitingList,
  } = data

  const mapOfSchedule = useMemo(() => {
    if (selectSalon?.weekPlans) {
      return selectSalon.weekPlans.map((day) => day.availableStatus)
    }
    return []
  }, [selectSalon])

  const [endDate, setEndate] = useState()
  const [values, setValues] = useState()
  const [recurringBookingFrequency, setRecurringBookingFrequency] = useState(1)
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false)
  const [enableMultipleDates, setEnableMultipleDates] = useState(false)
  const [availableDates, setAvailableDates] = useState([])
  const frequencies = [
    {
      name: i18n.t("every_week"),
      value: 1,
    },
  ]
  for (let i = 2; i <= 26; i++) {
    frequencies.push({
      name: i18n.t("container.every_x_weeks", { value: i }),
      value: i,
    })
  }

  const fetchAvailableTimeslots = (startDate) => {
    const promises = []
    const endOfMonth = moment(startDate).endOf("month")
    startDate = startDate.isBefore(moment()) ? moment() : startDate
    setLoading(true)
    while (startDate.isSameOrBefore(endOfMonth, "day")) {
      const date = startDate.format("MM-DD-YYYY")

      promises.push(
        getAvailablesSpots({
          companyId,
          companyServiceId: selectServices._id,
          companyEmployeeId: employeeId,
          date,
        }).then((data) => {
          return {
            date,
            data,
          }
        })
      )
      startDate.add(1, "day")
    }

    Promise.all(promises).then((results) => {
      const availableTimeslots = results.reduce((acc, { date, data }) => {
        acc[date] = data
        return acc
      }, {})

      setAvailableDates(availableTimeslots)

      setLoading(false)
    })
  }

  const timeSlotLength = selectSalon?.companyId?.timeSlotLength
  const { lockFields, setLoading } = useBooking()
  const selected = `${newTime ? newTime + " " : ""}${
    formattedDate ? formattedDate : recurringBookingDates.join(", ")
  }`
  const availableTimeslots = useSelector(
    (s) => s.getAvailableTimeslots?.data?.data || {}
  )
  const formattedTimeSlots = useMemo(() => {
    const timeSlotsKeys = Object.keys(availableTimeslots)
    if (!timeSlotsKeys.length) {
      return []
    }
    return timeSlotsKeys.map((key) => {
      return {
        name: key,
        ...timeSlotsKeys,
      }
    })
  }, [availableTimeslots])
  useEffect(() => {
    if (!enableRecurringBooking) {
      values?.length && setValues(values[0])
      setEnableMultipleDates(false)
      setShowAdvancedOptions(false)
      onChange({
        recurringBookingDates: [],
        formattedDate: recurringBookingDates[0],
      })
    } else if (enableRecurringBooking && values) {
      setShowAdvancedOptions(true)
    }
  }, [enableRecurringBooking])

  useEffect(() => {
    if (!lockFields) {
      setValues()
      onChange({
        newTime: null,
        selectedTime: null,
        timeObj: null,
        recurringBookingDates: [],
        selectDateAndTime: "",
        formattedDate: null,
      })
      setShowAdvancedOptions(false)
      setEndate()
      setEnableMultipleDates(false)
    }
    if (selectServices) {
      const currentDate = moment()
      fetchAvailableTimeslots(currentDate)
    }
  }, [selectServices])
  useEffect(() => {
    if (recurringBookingDates.length && endDate) {
      setEnableMultipleDates(true)
      let start = moment(recurringBookingDates[0])

      let calendarDates = []
      let bookingDates = []
      while (start.isSameOrBefore(endDate, "day")) {
        calendarDates.push(new DateObject(start.toDate()))
        bookingDates.push(moment(start).format("MM-DD-YYYY"))
        start.add(7 * recurringBookingFrequency, "days")
      }

      setValues(calendarDates)
      onChange({ recurringBookingDates: bookingDates })
    }
  }, [endDate, recurringBookingFrequency])

  const {
    companyId: { _id: companyId },
  } = selectSalon

  const onCalendarChange = (dateObjects) => {
    setValues(dateObjects)

    if (lockFields) {
      return
    }
    let recurringBookingDates = []
    let date
    let formattedDate

    if (!enableRecurringBooking) {
      setEnableMultipleDates(false)
      setShowAdvancedOptions(false)
      date = dateObjects[0]?.toDate() || dateObjects.toDate()
      formattedDate = dateObjects.format("MM-DD-YYYY")
    } else {
      setShowAdvancedOptions(true)
      if (!Array.isArray(dateObjects)) {
        date = dateObjects.toDate()
        recurringBookingDates.push(dateObjects.format("MM-DD-YYYY"))
      } else {
        date = dateObjects[0]?.toDate()
        dateObjects.map((dateObject) => {
          const date = dateObject.format("MM-DD-YYYY")
          recurringBookingDates.push(date)
        })
      }
    }

    onChange({
      selectDateAndTime: date,
      formattedDate,
      recurringBookingDates,
    })
    if (!formattedDate && !recurringBookingDates.length) {
      setValues()
      setShowAdvancedOptions(false)
      setEnableMultipleDates(false)
      setEndate()
      onChange({
        newTime: null,
        selectedTime: null,
        timeObj: null,
        recurringBookingDates: [],
        selectDateAndTime: "",
      })
    }

    setLoading(true)
    dispatch(
      getAvailableTimeslots({
        data: {
          companyId,
          companyServiceId: selectServices._id,
          companyEmployeeId: employeeId,
          date: formattedDate || recurringBookingDates[0],
        },
        resolve: ({ data }) => {
          if (
            (!Object.keys(data).length && recurringBookingDates.length > 1) ||
            (Object.keys(data).length &&
              !Object.keys(data).find((key) =>
                key.split(" - ")[0].includes(newTime)
              ) &&
              ((formattedDate && newTime) || recurringBookingDates?.length > 1))
          ) {
            onChange({
              newTime: null,
              selectedTime: null,
              timeObj: null,
              recurringBookingDates,
              formattedDate,
            })
          }

          setLoading(false)
        },
      })
    )
  }

  return (
    <Paper
      onClick={setOpen}
      elevation={2}
      style={{
        color: "#252525",
      }}
      className="mb-2 DateAndTime"
    >
      <Box padding={2}>
        <div className="row justify-content-between mx-0">
          {selected ? selected : i18n.t("select_date_and_time")}
          <div>
            {lockFields ? <Lock className="mr-2" /> : null}
            {open ? <ExpandLess /> : <ExpandMore />}
          </div>
        </div>
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Paper variant="outlined">
          <Box padding={1}>
            <CalendarWrapper>
              <div style={{ flexGrow: 1 }}>
                <Calendar
                  value={values}
                  sort={true}
                  className="custom-calendar"
                  weekStartDayIndex={1}
                  format="MM-DD-YYYY"
                  onChange={onCalendarChange}
                  minDate={new Date()}
                  onMonthChange={(date) => {
                    fetchAvailableTimeslots(moment(date.format("MM-DD-YYYY")))
                  }}
                  locale={i18n.language == "fr" && gregorian_fr}
                  mapDays={({ date, today }) => {
                    if (!lockFields) {
                      if (
                        availableDates[date] &&
                        !Object.keys(availableDates[date.format("MM-DD-YYYY")])
                          .length
                      ) {
                        return {
                          disabled: true,
                          style: { color: "#ccc" },
                          children: (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                padding: "0 10px",
                                fontSize: "11px",
                              }}
                            >
                              <div>{date.format("D")}</div>
                              <div style={{ fontSize: "8px" }}>
                                {" "}
                                {i18n.t("n/a")}
                              </div>
                            </div>
                          ),
                        }
                      } else {
                        return {
                          children: (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                padding: "0 10px",
                                fontSize: "11px",
                              }}
                            >
                              <div>{date.format("D")}</div>
                              {!moment(date.format("MM-DD-YYYY")).isBefore(
                                today.format("MM-DD-YYYY")
                              ) ? (
                                <div style={{ fontSize: "8px" }}>
                                  {" "}
                                  {i18n.t("available")}
                                </div>
                              ) : null}
                            </div>
                          ),
                        }
                      }
                    }

                    if (mapOfSchedule[date.weekDay.index]) {
                      return lockFields
                    } else {
                      if (formattedDate != date.format("MM-DD-YYYY")) {
                        return {
                          disabled: true,
                          style: { color: "#ccc" },
                        }
                      }
                    }
                  }}
                  multiple={enableMultipleDates}
                >
                  {showAdvancedOptions ? (
                    <div className="row">
                      <div
                        className={"form-label-group col-12 col-lg-6 col-md-6"}
                      >
                        <Select
                          options={frequencies}
                          label={i18n.t("frequency")}
                          value={recurringBookingFrequency}
                          onChange={(e) => {
                            setRecurringBookingFrequency(e.target.value)
                          }}
                        />
                      </div>
                      <div
                        className={"form-label-group col-12 col-lg-6 col-md-6"}
                      >
                        <DatePicker
                          popperProps={{
                            positionFixed: true, // use this to make the popper position: fixed
                          }}
                          minDate={new Date()}
                          selected={endDate}
                          onChange={(endDate) => {
                            setEndate(endDate)
                          }}
                          value={endDate}
                          customInput={
                            <TextField
                              id="time"
                              label={i18n.t("end_date")}
                              value={endDate}
                            />
                          }
                          placeholderText={i18n.t(
                            "container.services_click_to_select_a_date"
                          )}
                        />
                      </div>
                    </div>
                  ) : null}
                </Calendar>
                {formErrors?.selectDateAndTimeError && (
                  <FormHelperText error>
                    {formErrors?.selectDateAndTimeError}
                  </FormHelperText>
                )}
              </div>
              {selectDateAndTime ? (
                <>
                  {enableWaitingList ? (
                    <div className="px-1">
                      <div className="row mx-0">
                        <div className="form-label-group mb-3 col-6 pl-0">
                          <TextField
                            value={startTime}
                            id="time"
                            label={i18n.t("container.start_time")}
                            disabled={lockFields}
                            type="time"
                            className="full-width"
                            onChange={(text) =>
                              onChange({ startTime: text.target.value })
                            }
                            onBlur={() => {
                              if (startTime) {
                                let hour = startTime.split(":")[0]
                                let minute = parseInt(startTime.split(":")[1])
                                const remainder = minute % timeSlotLength
                                if (remainder) {
                                  minute =
                                    minute -
                                    remainder +
                                    ((minute % timeSlotLength) /
                                      timeSlotLength >
                                    0.5
                                      ? timeSlotLength
                                      : 0)
                                  if (minute >= 60) {
                                    hour++
                                    minute %= 60
                                  }
                                  const time = `${hour}:${
                                    minute < 10 ? "0" + minute : minute
                                  }`
                                  onChange({ startTime: time })
                                }
                              }
                            }}
                            helperText={formErrors.startTimeError}
                            error={!!formErrors.startTimeError}
                          />
                        </div>
                        <div className="form-label-group mb-3 col-6 pl-0">
                          <TextField
                            value={endTime}
                            id="time"
                            label={i18n.t("container.end_time")}
                            disabled={lockFields}
                            type="time"
                            className="full-width"
                            onChange={(text) =>
                              onChange({ endTime: text.target.value })
                            }
                            onBlur={() => {
                              if (endTime) {
                                let hour = endTime.split(":")[0]
                                let minute = parseInt(endTime.split(":")[1])
                                const remainder = minute % timeSlotLength
                                if (remainder) {
                                  minute =
                                    minute -
                                    remainder +
                                    ((minute % timeSlotLength) /
                                      timeSlotLength >
                                    0.5
                                      ? timeSlotLength
                                      : 0)
                                  if (minute >= 60) {
                                    hour++
                                    minute %= 60
                                  }
                                  const time = `${hour}:${
                                    minute < 10 ? "0" + minute : minute
                                  }`
                                  onChange({ endTime: time })
                                }
                              }
                            }}
                            helperText={formErrors.endTimeError}
                            error={!!formErrors.endTimeError}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="TimeList px-1">
                      {lockFields ? (
                        <TouchChip
                          label={newTime}
                          onClick={() => {}}
                          selected
                        />
                      ) : (
                        <TouchList
                          options={formattedTimeSlots}
                          chipClassName="TimeChip"
                          onChange={(val, e) => {
                            e.stopPropagation()
                            onChange({
                              selectedTime: val.name,
                              timeObj: val,
                              newTime: val.name.split(" ")[0],
                            })
                          }}
                          selected={timeObj}
                          noOptionsMessage="no_time_available"
                          error={formErrors.selectedTimeError}
                          style={{ gridTemplateColumns: "1fr" }}
                        />
                      )}
                    </div>
                  )}
                </>
              ) : null}
            </CalendarWrapper>
          </Box>
        </Paper>
      </Collapse>
    </Paper>
  )
}

export default EmployeeDateAndTime
