import React from "react"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import PrimaryButton from "../../../../../components/Core/PrimaryButton"
import statusActions from "../../../../../redux/actions/Saloon/CustomerStatus"
import i18n from "../../../../../i18n"
import "./CustomerSubscriptionReportFilters.css"

export default function CustomerSubscriptionReportFilters({
  onFilter,
}) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(statusActions.getRequest())
  }, [])

  return (
    <div className="row justify-content-sm-end gap-2 mt-3">
      <PrimaryButton
        className="w-128"
        label={i18n.t("submit")}
        onClick={() => onFilter()}
      />
      <PrimaryButton
        className="w-128"
        label={i18n.t("export_to_excel")}
        onClick={() => onFilter(true)}
      />
    </div>
  )
}
