import { connect } from "react-redux"
import React, { useEffect, useState } from "react"
import { Route, Switch, Redirect } from "react-router-dom"
// import CryptoJS from "crypto-js";

import Profile from "../containers/Customer/Profile"
import { set } from "../redux/actions/User"
import NewProfile from "../components/NewProfile/Profile.component"
import SharedStorage from "../helpers/Storage"

const isUser = { isAuthenticated: false }

// const decrypt = (value) => {
//     let bytes = CryptoJS.AES.decrypt(value, secret_key);
//     let decryptedUserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
//     return decryptedUserData;
// };

const isUserAuthenticate = async () => {
  const userData = JSON.parse(await SharedStorage.getItem("user"))
  if (userData && userData.access_token && userData.role == 5) {
    isUser.isAuthenticated = true
  } else {
    isUser.isAuthenticated = false
  }
}

const PrivateRoute = ({ component: Component, ...rest }) => {
  let user = rest.props
  let isAuthenticated = user && user.isAdmin

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuthentication = async () => {
      await isUserAuthenticate();
      setLoading(false);
    };

    checkAuthentication();
  }, []);

  if (loading) {
    // Render loading state or a spinner while checking authentication
    return <div>Loading...</div>;
  }

  return (
    <Route
      exact
      {...rest}
      render={(props) =>
        isAuthenticated || (isUser && isUser.isAuthenticated) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}

const CustomerRoutes = ({ userLogin, impersonateUser, set, history }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (
      userLogin &&
      !userLogin.failure &&
      !userLogin.isFetching &&
      !userLogin.errorMessage &&
      userLogin.data &&
      userLogin.data.data &&
      userLogin.data.data.access_token &&
      userLogin.data.data.role == 5
    ) {
      setUser(userLogin.data.data);
    }
  }, [userLogin]);

  useEffect(() => {
    if (
      impersonateUser &&
      !impersonateUser.failure &&
      !impersonateUser.isFetching &&
      !impersonateUser.errorMessage &&
      impersonateUser.data &&
      impersonateUser.data.data &&
      impersonateUser.data.data.access_token &&
      impersonateUser.isFetching
    ) {
      set({
        user: JSON.stringify(impersonateUser.data.data),
        access_token: impersonateUser.data.data.access_token,
      })
      history.push("/user-profile")
      setUser(impersonateUser.data.data);
    }
  }, [impersonateUser, set, history]);

  return (
    <Switch>
      <PrivateRoute
        exact
        path="/user-profile"
        component={Profile}
        props={user}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/new-user-profile"
        component={NewProfile}
        props={user}
      ></PrivateRoute>
    </Switch>
  )
}

const mapStateToProps = (state) => ({
  userLogin: state.login,
  impersonateUser: state.impersonateUser,
  user: state.user.data.user,
})

const action = { set }

export default connect(mapStateToProps, action)(CustomerRoutes)
