import React, { useMemo } from "react"
import DotsLoader from "../DotsLoader"
import { connect } from "react-redux"
import { soldPackageRequest, getRequest } from "../../redux/actions/Packages"
import { useEffect } from "react"
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator"
import MUAutocomplete from "@material-ui/lab/Autocomplete"
import MenuItem from "@material-ui/core/MenuItem"
import PrimaryButton from "../Core/PrimaryButton"
import i18n from "../../i18n"
import { useState } from "react"
import { ErrorHelper, SuccessHelper } from "../../helpers"
import InsertPayment from "./components/InsertPayment.component"
import Autocomplete from "react-autocomplete"
import TextField from "@material-ui/core/TextField"
import FormHelperText from "@material-ui/core/FormHelperText"
import { IconButton } from "@material-ui/core"
import { TouchApp } from "@material-ui/icons"
import CustomerSearchUp from "../TouchCustomerSeachUp/CustomerSearchUp"
import useCache from "../../context/LocalCache"
import restoreConfirmation from "../../helpers/RestoreHelper"
import { deleteCustomer } from "../../config/simpleApiCalls"
import MultiSelect from "../MultiSelect"
import CounterInput from "../CounterInput"
import DatePicker from "react-datepicker"
import { createCustomPackage } from "../../config/simpleApiCalls"
import moment from "moment"
const telephoneRegex = /[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]/g

const CreateCustomPackageInvoice = ({
  close,
  loading,
  companies,
  companyTaxMap = {},
  getRequest,
  soldPackageRequest,
  scannerInput,
  isSalon,
}) => {
  const [email, setEmail] = useState("")
  const [phoneNo, setPhoneNo] = useState("")
  const [selectedPackage, setSelectedPackage] = useState("")
  const [selectedPackageIndex, setSelectedPackageIndex] = useState("")
  const [companyPackages, setCompanyPackages] = useState([])
  const [company, setCompany] = useState("")
  const [name, setName] = useState("")
  const [giftCardBalance, setGiftCardBalance] = useState(0)
  const [taxes, setTaxes] = useState([])
  const [isAddTransactionOpen, setIsAddTransactionOpen] = useState(false)
  const [includeTaxes, setIncludeTaxes] = useState(true)
  const [phoneNoError, setPhoneNoError] = useState("")
  const { getResource } = useCache()
  const [data, setData] = useState({ price: 0, numberOfSessions: 1 })
  const [errors, setErrors] = useState({})
  const allServices = getResource((cache) => cache.company.services)
  const [customerId, setCustomerId] = useState("")
  const employees = useMemo(() => {
    return getResource((cache) => cache.company.employees)
      .filter(({ isActive }) => isActive)
      .map((employee) => ({
        value: employee.employee._id,
        name: employee.employee.userId.firstName,
      }))
  }, [getResource((cache) => cache.employees)])
  const [employee, setEmployee] = useState()
  const [touchSearchOpen, setTouchSearchOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    if (companies.length == 1) {
      onCompanySelect(companies[0]._id)
    }
  }, [])

  useEffect(() => {
    if (scannerInput) {
      const foundIndex = companyPackages.findIndex((companyPackage) => {
        return companyPackage.barCode == scannerInput
      })
      if (foundIndex !== -1) {
        setSelectedPackage(companyPackages[foundIndex])
        setSelectedPackageIndex(foundIndex)
      }
    }
  }, [scannerInput])

  const [customers, inactiveCustomers] = getResource((cache) => {
    let customers
    if (isSalon) {
      customers = cache.company.customers
    } else {
      customers = cache.employee.customers
    }
    return [
      customers.filter(({ isActive }) => isActive),
      customers.filter(({ isActive }) => !isActive),
    ]
  })

  const onCompanySelect = async (companyId) => {
    setCompany(companyId)
    setTaxes(companyTaxMap[companyId] ? companyTaxMap[companyId] : [])
    setCompanyPackages([])
    setSelectedPackage("")
    setSelectedPackageIndex("")
    getRequest(
      { companyId },
      {
        success: ({ data }) => {
          setCompanyPackages(data)
        },
      }
    )
  }
  const onSave = ({
    note,
    cash,
    creditDebit,
    giftCard,
    tip,
    discount,
    chargedAmountByCard = [],
  }) => {
    setIsLoading(true)
    data.services = data.services.map(({ value }) => value)

    createCustomPackage({
      email,
      phoneNo,
      packageData: data,
      name,
      note,
      cash,
      creditDebit,
      giftCard,
      includeTaxes,
      tip,
      discount,
      employeeId: employee,
      chargedAmountByCard,
    }).then(({ data }) => {
      if (data.success) {
        close()
        SuccessHelper.handleSuccess(i18n.t("successfully_added"), true)
      } else {
        ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true)
      }

      setIsLoading(false)
    })
  }
  const validatePhoneNo = () => {
    if (!phoneNo || !phoneNo.replace(/\s/g, "").match(telephoneRegex)) {
      setPhoneNoError(i18n.t("invalid_phone_number"))
      return
    }
    setPhoneNoError("")
    return true
  }

  const validate = (key, minValue, value, name) => {
    if (value === "") {
      errors[key] = i18n.t(`${name}_is_required`)
    } else if (value < minValue) {
      errors[key] = i18n.t(`${name}_min_${minValue}`)
    } else {
      delete errors[key]
    }

    setErrors({ ...errors })
  }

  const handleSubmit = () => {
    validate("numberOfSessions", 1, data.numberOfSessions, "number_of_session")
    validate("price", 0, data.price, "price")
    const inactiveCustomer = inactiveCustomers.find(
      (customer) => customer.phoneNo === phoneNo
    )

    if (
      validatePhoneNo() &&
      !inactiveCustomer &&
      Object.keys(errors).length === 0
    ) {
      setIsAddTransactionOpen(true)
    } else if (inactiveCustomer) {
      restoreConfirmation(
        () =>
          deleteCustomer({ ids: inactiveCustomer._id })
            .then((res) => {
              if (res.data.success) {
                inactiveCustomer.email && setEmail(inactiveCustomer.email)
                setName(
                  `${inactiveCustomer.firstName || ""} ${
                    inactiveCustomer.name
                  }`.trim()
                )
                setGiftCardBalance(inactiveCustomer.giftCardBalance)
                setIsAddTransactionOpen(true)
              } else if (!res.data.success) {
                ErrorHelper.handleErrors(res.data.msg, true)
              }
            })
            .catch((error) => {
              console.log(error, "errorrrrrrrrrrrrrrrrrrr")
            }),
        i18n.t("this_is_inactive", { name: i18n.t("customer").toLowerCase() })
      )
    }
  }
  const cummulativeTaxRate =
    taxes.reduce((accum, tax) => accum + tax.percentage, 0) / 100
  const price = data.price
  const items = [
    {
      name: data.name,
      price,
    },
  ]

  const taxDue = cummulativeTaxRate * price
  const grandTotal = (1 + (includeTaxes ? cummulativeTaxRate : 0)) * price
  return (
    <>
      <DotsLoader isloading={loading || isLoading} />
      <ValidatorForm
        onSubmit={handleSubmit}
        className="w-100"
        onError={() => {
          validatePhoneNo()
          validate(
            "numberOfSessions",
            1,
            data.numberOfSessions,
            "number_of_session"
          )
          validate("price", 0, data.price, "price")
        }}
      >
        <div className="row mx-0 px-0">
          <div className="col-12 px-0">
            {employees.length ? (
              <div className="col-12 d-flex flex-column flex-md-row align-items-mdcenter mb-3 px-0">
                <label
                  className="font-weight-bold"
                  style={{ minWidth: "140px" }}
                >
                  {i18n.t("sold_by")}
                </label>
                <div className="w-100">
                  <SelectValidator
                    label={i18n.t("sold_by")}
                    onChange={(e) => {
                      setEmployee(e.target?.value)
                    }}
                    value={employee}
                    select
                    variant="outlined"
                  >
                    {employees.map((employee) => {
                      return (
                        <MenuItem value={employee.value}>
                          {employee.name}
                        </MenuItem>
                      )
                    })}
                  </SelectValidator>
                </div>
              </div>
            ) : null}
            {companies.length && companies.length != 1 ? (
              <div className="col-12 d-flex flex-column flex-md-row align-items-md-center mb-3 px-0">
                <label
                  className="font-weight-bold"
                  style={{ minWidth: "140px" }}
                >
                  {i18n.t("company")}
                </label>
                <div className="w-100">
                  <SelectValidator
                    label={i18n.t("company")}
                    onChange={(e) => {
                      onCompanySelect(e.target?.value)
                    }}
                    value={company}
                    select
                    variant="outlined"
                  >
                    {companies.map((company) => {
                      return (
                        <MenuItem value={company._id}>{company.name}</MenuItem>
                      )
                    })}
                  </SelectValidator>
                </div>
              </div>
            ) : null}
            <div className="col-12 d-flex flex-column flex-md-row align-items-md-center mb-3 px-0">
              <label
                className="font-weight-bold "
                style={{ minWidth: "140px" }}
              >
                {i18n.t("container.employe_phone_number")}
              </label>
              <div className="w-100 d-flex">
                <Autocomplete
                  f
                  placeholder={i18n.t("enter_phone_number_placeholder_type")}
                  renderInput={(props) => {
                    return (
                      <TextField
                        id="phoneNumber"
                        label={i18n.t("container.employe_phone_number")}
                        error={!!phoneNoError}
                        {...props}
                        variant="outlined"
                      />
                    )
                  }}
                  items={
                    phoneNo
                      ? customers.map(
                          ({
                            name = "",
                            firstName = "",
                            phoneNo = "",
                            email = "",
                            giftCardBalance = 0,
                            userId,
                            isBlackListed,
                            _id,
                          }) => ({
                            name,
                            phoneNo,
                            firstName,
                            email,
                            giftCardBalance,
                            userId,
                            isBlackListed,
                            _id,
                          })
                        )
                      : []
                  }
                  shouldItemRender={(item, value) =>
                    item.phoneNo.toLowerCase().indexOf(value.toLowerCase()) >
                      -1 ||
                    item.name.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
                    item.firstName?.toLowerCase().indexOf(value.toLowerCase()) >
                      -1
                  }
                  getItemValue={(item) => item.phoneNo}
                  renderItem={(item, highlighted) => (
                    <MenuItem key={item._id}>
                      {`${item.firstName ? item.firstName : ""} ${
                        item.name
                      }`.trim()}
                    </MenuItem>
                  )}
                  menuStyle={{
                    zIndex: "9998",
                    position: "absolute",
                    background: "white",
                    top: 64,
                    left: 0,
                  }}
                  wrapperStyle={{
                    width: "100%",
                  }}
                  renderMenu={(children) => {
                    return <div className="menu">{children.slice(0, 15)}</div>
                  }}
                  value={phoneNo}
                  onChange={(e) => {
                    setPhoneNo(e.target.value)
                  }}
                  onSelect={(
                    value,
                    { phoneNo, firstName, email, name, giftCardBalance, _id }
                  ) => {
                    setCustomerId(_id)
                    setPhoneNo(phoneNo)
                    setEmail(email)
                    setName(`${firstName || ""} ${name}`.trim())
                    setGiftCardBalance(giftCardBalance)
                  }}
                />
                <IconButton
                  aria-label="search"
                  className="ml-2 p-2"
                  style={{ backgroundColor: "#3f51b5" }}
                  onClick={() => setTouchSearchOpen(true)}
                >
                  <TouchApp
                    fontSize="medium"
                    color="white"
                    style={{ color: "white" }}
                  />
                </IconButton>
              </div>
            </div>
            <CustomerSearchUp
              open={touchSearchOpen}
              setOpen={setTouchSearchOpen}
              customers={customers || []}
              onDone={(
                phoneNo,
                { email, firstName, name, giftCardBalance }
              ) => {
                setPhoneNo(phoneNo)
                setEmail(email)
                setName(`${firstName || ""} ${name}`.trim())
                setGiftCardBalance(giftCardBalance)
                setTouchSearchOpen(false)
              }}
            />
            {!!phoneNoError && (
              <FormHelperText error>{phoneNoError}</FormHelperText>
            )}
            <div className="col-12 d-flex flex-column flex-md-row align-items-md-center mb-3 px-0">
              <label
                className="font-weight-bold "
                style={{ minWidth: "140px" }}
              >
                {i18n.t("name")}
              </label>
              <div className="w-100">
                <TextValidator
                  label={i18n.t("name")}
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  validators={["required"]}
                  errorMessages={[i18n.t("name_required")]}
                  variant="outlined"
                />
              </div>
            </div>
            <div className="col-12 d-flex flex-column flex-md-row align-items-md-center mb-3 px-0">
              <label
                className="font-weight-bold "
                style={{ minWidth: "140px" }}
              >
                {i18n.t("email_to_invoice")}
              </label>
              <div className="w-100">
                <TextValidator
                  label={i18n.t("email_to_invoice")}
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  validators={["required", "isEmail"]}
                  errorMessages={[
                    i18n.t("email_required"),
                    i18n.t("invalid_email"),
                  ]}
                  variant="outlined"
                />
              </div>
            </div>
          </div>

          <div className="col-12 d-flex align-items-center mb-3 px-0 pt-2 border-top border-bottom">
            <h3>{i18n.t("package")}</h3>
          </div>

          <div className="col-12 d-flex flex-column flex-md-row align-items-md-center mb-3 px-0">
            <label className="font-weight-bold " style={{ minWidth: "140px" }}>
              {i18n.t("name")}
            </label>
            <div className="w-100">
              <TextValidator
                label={i18n.t("name")}
                onChange={(e) => setData({ ...data, name: e.target.value })}
                value={data.name}
                validators={["required"]}
                errorMessages={[i18n.t("name_is_required")]}
                variant="outlined"
              />
            </div>
          </div>
          <div className="col-12 d-flex flex-column flex-md-row align-items-md-center mb-3 px-0">
            <label className="font-weight-bold " style={{ minWidth: "140px" }}>
              {i18n.t("description")}
            </label>
            <div className="w-100">
              <TextValidator
                label={i18n.t("description")}
                onChange={(e) =>
                  setData({ ...data, description: e.target.value })
                }
                value={data.description}
                validators={["required"]}
                errorMessages={[i18n.t("description_is_required")]}
                variant="outlined"
              />
            </div>
          </div>
          <div className="col-12 d-flex flex-column flex-md-row align-items-md-center mb-3 px-0">
            <label className="font-weight-bold " style={{ minWidth: "140px" }}>
              {i18n.t("bar_code")}
            </label>
            <div className="w-100">
              <TextValidator
                className="mb-1"
                label={i18n.t("bar_code")}
                onChange={(e) => setData({ ...data, barCode: e.target.value })}
                value={data.barCode}
                variant="outlined"
              />
            </div>
          </div>
          <div className="col-12 d-flex flex-column flex-md-row align-items-md-center mb-3 px-0">
            <label className="font-weight-bold " style={{ minWidth: "140px" }}>
              {i18n.t("time_to_use")}
            </label>
            <div className="w-100">
              <SelectValidator
                label={i18n.t("time_to_use")}
                onChange={(e) => {
                  setData({ ...data, timeToUse: e.target.value })
                }}
                value={data.timeToUse}
                variant="outlined"
                select
              >
                <MenuItem value="" disabled={true}>
                  {i18n.t("time_to_use")}
                </MenuItem>
                <MenuItem value={0}>{i18n.t("no_limit")}</MenuItem>
                <MenuItem value={7}>{i18n.t("1_week")}</MenuItem>
                <MenuItem value={14}>{i18n.t("2_week")}</MenuItem>
                <MenuItem value={30}>{i18n.t("1_month")}</MenuItem>
                <MenuItem value={90}>{i18n.t("3_months")}</MenuItem>
                <MenuItem value={180}>{i18n.t("6_months")}</MenuItem>
                <MenuItem value={270}>{i18n.t("9_months")}</MenuItem>
                <MenuItem value={365}>{i18n.t("1_year")}</MenuItem>
              </SelectValidator>
            </div>
          </div>
          <div className="col-12 d-flex flex-column flex-md-row align-items-md-center mb-3 px-0">
            <label className="font-weight-bold " style={{ minWidth: "140px" }}>
              {i18n.t("expiry_date")}
            </label>
            <DatePicker
              wrapperClassName="w-100"
              selected={data.expiryDate ? moment(data.expiryDate).toDate() : ""}
              onChange={(e) => setData({ ...data, expiryDate: e })}
              value={data.expiryDate ? moment(data.expiryDate).toDate() : ""}
              customInput={
                <TextValidator
                  label={i18n.t("expiry_date")}
                  onChange={(e) => setData({ ...data, expiryDate: e })}
                  value={data.expiryDate}
                  variant="outlined"
                />
              }
              placeholderText={i18n.t("expiry_date")}
            />
          </div>
          <div className="col-12 d-flex flex-column flex-md-row align-items-md-center mb-3 px-0">
            <label className="font-weight-bold " style={{ minWidth: "140px" }}>
              {i18n.t("services")}
            </label>
            <div className="w-100">
              <MultiSelect
                items={allServices.map((service) => ({
                  value: service._id,
                  name: service.name,
                }))}
                data={data}
                open={open}
                selectAllLabel={i18n.t("select_all")}
                onChange={setData}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    value={data.services}
                    validators={["required", "hasArrayElements"]}
                    errorMessages={[
                      i18n.t("this_field_is_required"),
                      i18n.t("select_atleast_1_item"),
                    ]}
                    variant="outlined"
                    label={i18n.t("services")}
                  />
                )}
              />
            </div>
          </div>
          <div className="col-12 d-flex align-items-center mb-3 px-0">
            <label className="font-weight-bold " style={{ minWidth: "140px" }}>
              {i18n.t("price")}
            </label>
            <CounterInput
              value={data.price}
              label={i18n.t("price")}
              error={errors?.price}
              errorMessages={errors.price}
              onChange={(e) => {
                setData({ ...data, price: parseFloat(e) })
                validate("price", 0, e, "price")
              }}
            />
          </div>
          <div className="col-12 d-flex align-items-center mb-3 px-0">
            <label className="font-weight-bold " style={{ minWidth: "140px" }}>
              {i18n.t("number_of_sessions")}
            </label>
            <CounterInput
              value={data.numberOfSessions}
              label={i18n.t("number_of_sessions")}
              onChange={(e) => {
                setData({ ...data, numberOfSessions: parseInt(e) })
                validate("numberOfSessions", 1, e, "number_of_session")
              }}
              error={errors?.numberOfSessions}
              errorMessages={errors.numberOfSessions}
            />
          </div>
        </div>
        <div className="row mx-0 px-0 justify-content-end my-4">
          <InsertPayment
            defaultAmount={grandTotal}
            open={isAddTransactionOpen}
            onSubmit={onSave}
            handleClose={() => setIsAddTransactionOpen(false)}
            toggleTaxes={() => setIncludeTaxes(!includeTaxes)}
            items={items}
            subtotal={data.price}
            taxDue={taxDue}
            includeTaxes={includeTaxes}
            grandTotal={grandTotal}
            giftCardBalance={giftCardBalance}
            customerId={customerId}
          />

          <PrimaryButton
            props={{
              type: "submit",
            }}
            label={i18n.t("collect_payment")}
          />
        </div>
      </ValidatorForm>
    </>
  )
}

const mapStateToProps = (state) => ({
  loading: state.packages.isFetching,
})

const action = { soldPackageRequest, getRequest }

export default connect(mapStateToProps, action)(CreateCustomPackageInvoice)
