import { connect } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import i18n from "../../../i18n"
import moment from "moment"
import DotsLoader from "../../DotsLoader"
import Swal from "sweetalert2"
import { ErrorHelper, SuccessHelper } from "../../../helpers"
import { removeRequest } from "../../../redux/actions/Saloon/expense"
import BasicTable from "../../Core/BasicTable"
import { TableCell, TableHead, TableRow } from "@material-ui/core"
import PrimaryButton from "../../Core/PrimaryButton"
import { Images } from "../../../theme"
import DeleteIcon from "../../../components/Core/BasicTable/Actions/DeleteIcon"
import EditIcon from "../../../components/Core/BasicTable/Actions/EditIcon"
import deleteConfirmation from "../../../helpers/DeleteHelper"

function ExpensesTable({ rows, removeRequest, summary, onView }) {
  const [loading, setLoading] = useState(false)
  const handleDelete = (_id) => {
    setLoading(true)
    removeRequest(
      { ids: _id },
      {
        success: () => {
          setLoading(false)
          SuccessHelper.handleSuccess(
            "Your service is successfully deleted.",
            true
          )
        },
        failure: () => {
          setLoading(false)
          ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true)
        },
      }
    )
  }

  return (
    <>
      <DotsLoader isloading={loading} />
      <div className="table-responsive">
        <BasicTable
          prependRows={
            summary ? (
              <TableRow>
                <TableCell />
                <TableCell />
                <TableCell>
                  <PrimaryButton label={`$${summary?.amount.toFixed(2)}`} />
                </TableCell>
                <TableCell>
                  <PrimaryButton label={`$${summary?.taxTotal.toFixed(2)}`} />
                </TableCell>
                <TableCell>
                  <PrimaryButton label={`$${summary?.grandTotal.toFixed(2)}`} />
                </TableCell>
              </TableRow>
            ) : null
          }
          rows={rows}
          schema={[
            {
              header: i18n.t("vendor"),
              field: "vendorId",
              accessor: (val) => val.vendorId?.vendorName,
            },
            {
              header: i18n.t("date"),
              field: "date",
              accessor: (val) => moment(val.date).format("MM-DD-YYYY"),
            },
            {
              header: i18n.t("amount"),
              field: "amount",
              accessor: (val) => `$${val.amount.toFixed(2)}`,
            },
            {
              header: i18n.t("tax_total"),
              field: "tax_total",
              accessor: (val) => {
                const tax_total =
                  val.taxes?.reduce((accum, tax) => accum + tax.amount, 0) || 0
                return `$${tax_total.toFixed(2)}`
              },
            },
            {
              header: i18n.t("grand_total"),
              field: "grand_total",
              accessor: (val) => {
                const tax_total =
                  val.taxes?.reduce((accum, tax) => accum + tax.amount, 0) || 0
                return `$${(tax_total + val.amount).toFixed(2)}`
              },
            },
            {
              header: i18n.t("created_by"),
              field: "created_by",
              accessor: (val) => {
                return val.createdBy?.firstName
              },
            },
            ...(summary
              ? []
              : [
                  {
                    key: "action",
                    header: i18n.t("actions"),
                    render: (val) => {
                      return (
                        <>
                          <EditIcon onClick={() => onView({ ...val })} />
                          <DeleteIcon
                            onClick={() =>
                              deleteConfirmation(() => handleDelete(val._id))
                            }
                          />{" "}
                        </>
                      )
                    },
                  },
                ]),
          ]}
        />
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({})

const action = {
  removeRequest,
}

export default connect(mapStateToProps, action)(ExpensesTable)
