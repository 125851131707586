import React, { useEffect, useMemo, useRef, useState } from "react"
import {
  SelectValidator,
  TextValidator,
  ValidatorForm,
} from "react-material-ui-form-validator"
import MenuItem from "@material-ui/core/MenuItem"
import i18n from "../../../../i18n"
import { Autocomplete } from "@material-ui/lab"
import PrimaryButton from "../../../Core/PrimaryButton"
import { useSelector } from "react-redux"
import moment from "moment"
import { BaseModal } from "../../.."
import { daysOfWeek } from "../ScheduleTemplateBuilder"

export default function AddScheduleEvent({
  open,
  setOpen,
  allServices,
  employees,
  defaults = {},
  onAdd,
  onDelete,
  active = {},
}) {
  const form = useRef()
  const [data, setData] = useState({})
  employees = employees.filter(({ isActive }) => isActive)
  const customerGroups = useSelector((s) => s.companyCustomerGroups.data || [])

  const serviceMap = useMemo(() => {
    const map = {}
    allServices.map((service) => {
      map[service._id] = service
    })
    return map
  }, [allServices])
  const employeeMap = useMemo(() => {
    const map = {}
    employees.map((employee) => {
      map[employee._id] = employee
    })
    return map
  }, [employees])
  const groupMap = useMemo(() => {
    const map = {}
    customerGroups.map((group) => {
      map[group._id] = group
    })
    return map
  }, [customerGroups])
  useEffect(() => {
    if (open) {
      setData({
        ...active,
        employee: employeeMap[active.companyEmployeeId],
        groups: [...(active.groups || []).map((group) => groupMap[group])],
        services: [
          ...(active.services || []).map((service) => serviceMap[service]),
        ],
      })
    }
  }, [open, active._id])

  const handleAdd = () => {
    onAdd(data)
  }

  const checkTimes = () => {
    const { startTime, endTime } = data
    if (startTime && endTime) {
      const momentStart = moment(startTime, "hh:mm")
      const momentEnd = moment(endTime, "hh:mm")
      if (momentStart.isAfter(momentEnd)) {
        setData({ ...data, startTime: endTime, endTime: startTime })
      }
    }
  }
  useEffect(checkTimes, [data.startTime, data.endTime])
  useEffect(() => {
    setData({
      ...defaults,
      daysOfWeek: defaults.dayOfWeek && [defaults.dayOfWeek],
    })
  }, [open])
  return (
    <BaseModal
      open={open}
      title={
        data._id ? i18n.t("edit_template_event") : i18n.t("add_template_event")
      }
      onClose={() => setOpen(false)}
      containerClassName={"half-container"}
      content={
        <div className="row mx-0 ScheduleBuilder">
          <ValidatorForm
            onSubmit={handleAdd}
            ref={form}
            className="row col-12 px-0 mx-0"
            onError={(error) => {
              console.log(error)
            }}
          >
            {data._id ? (
              <div className="col-12 flex-column flex-md-row d-flex align-items-md-center mb-3 px-0">
                <label
                  className="font-weight-bold"
                  style={{ minWidth: "120px" }}
                >
                  {i18n.t("employee")}
                </label>
                <div className="w-100">
                  <SelectValidator
                    label={i18n.t("employee")}
                    onChange={(e) => {
                      setData({
                        ...data,
                        employee: e.target.value,
                      })
                    }}
                    value={data.employee || ""}
                    select
                    validators={["required"]}
                    errorMessages={[i18n.t("this_field_is_required")]}
                    variant="outlined"
                  >
                    {employees.map((employee) => {
                      return (
                        <MenuItem value={employee}>
                          {employee.employeeId.userId.firstName}
                        </MenuItem>
                      )
                    })}
                  </SelectValidator>
                </div>
              </div>
            ) : (
              <div className="col-12 flex-column flex-md-row d-flex align-items-md-center mb-3 px-0">
                <label
                  className="font-weight-bold"
                  style={{ minWidth: "120px" }}
                >
                  {i18n.t("employees")}
                </label>
                <div className="w-100">
                  <Autocomplete
                    multiple
                    options={employees}
                    getOptionLabel={(option) =>
                      option.employeeId.userId.firstName
                    }
                    value={data.employees || []}
                    onChange={(e, value = []) => {
                      setData({ ...data, employees: value })
                    }}
                    disableCloseOnSelect={true}
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        label={i18n.t("employees")}
                        value={data.employees}
                        validators={["required", "hasArrayElements"]}
                        errorMessages={[
                          i18n.t("this_field_is_required"),
                          i18n.t("select_atleast_1_item"),
                        ]}
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              </div>
            )}
            <div className="col-12 flex-column flex-md-row d-flex align-items-md-center mb-3 px-0">
              <label className="font-weight-bold" style={{ minWidth: "120px" }}>
                {i18n.t("services")}
              </label>
              <div className="w-100">
                <Autocomplete
                  multiple
                  options={allServices}
                  getOptionLabel={(option) => option?.name}
                  value={data.services || []}
                  onChange={(e, value = []) => {
                    setData({ ...data, services: value })
                  }}
                  disableCloseOnSelect={true}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label={i18n.t("services")}
                      value={data.services}
                      validators={["required", "hasArrayElements"]}
                      errorMessages={[
                        i18n.t("this_field_is_required"),
                        i18n.t("select_atleast_1_item"),
                      ]}
                      variant="outlined"
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-12 flex-column flex-md-row d-flex align-items-md-center mb-3 px-0">
              <label className="font-weight-bold" style={{ minWidth: "120px" }}>
                {i18n.t("groups")}
              </label>
              <div className="w-100">
                <Autocomplete
                  multiple
                  options={customerGroups}
                  getOptionLabel={(option) => option.name}
                  value={data.groups || []}
                  onChange={(e, value = []) => {
                    setData({ ...data, groups: value })
                  }}
                  disableCloseOnSelect={true}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label={i18n.t("groups")}
                      value={data.groups}
                      validators={["required", "hasArrayElements"]}
                      errorMessages={[
                        i18n.t("this_field_is_required"),
                        i18n.t("select_atleast_1_item"),
                      ]}
                      variant="outlined"
                    />
                  )}
                />
              </div>
            </div>
            {data._id ? (
              <div className="col-12 d-flex flex-column flex-md-row align-items-md-center mb-3 px-0">
                <label
                  className="font-weight-bold"
                  style={{ minWidth: "120px" }}
                >
                  {i18n.t("day_of_week")}
                </label>
                <div className="w-100">
                  <SelectValidator
                    containerProps={{ className: "w-100" }}
                    label={i18n.t("day_of_week")}
                    onChange={(e) => {
                      setData({
                        ...data,
                        dayOfWeek: e.target.value,
                      })
                    }}
                    value={data.dayOfWeek || ""}
                    select
                    validators={["required"]}
                    errorMessages={[i18n.t("this_field_is_required")]}
                    variant="outlined"
                  >
                    {daysOfWeek.map((dayOfWeek) => {
                      return (
                        <MenuItem value={dayOfWeek}>
                          {i18n.t(dayOfWeek)}
                        </MenuItem>
                      )
                    })}
                  </SelectValidator>
                </div>
              </div>
            ) : (
              <div className="col-12 d-flex flex-column flex-md-row  align-items-md-center mb-3 px-0">
                <label
                  className="font-weight-bold"
                  style={{ minWidth: "120px" }}
                >
                  {i18n.t("day_of_week")}
                </label>
                <div className="w-100">
                  <Autocomplete
                    multiple
                    options={daysOfWeek}
                    value={data.daysOfWeek || []}
                    onChange={(e, value = []) => {
                      setData({ ...data, daysOfWeek: value })
                    }}
                    disableCloseOnSelect={true}
                    renderInput={(params) => {
                      return (
                        <TextValidator
                          {...params}
                          value={data.daysOfWeek}
                          label={i18n.t("days_of_week")}
                          validators={["required", "hasArrayElements"]}
                          errorMessages={[
                            i18n.t("this_field_is_required"),
                            i18n.t("select_atleast_1_item"),
                          ]}
                          variant="outlined"
                        />
                      )
                    }}
                  />
                </div>
              </div>
            )}
            <div className="col-12 d-flex flex-column flex-md-row align-items-md-center mb-3 px-0">
              <label className="font-weight-bold" style={{ minWidth: "120px" }}>
                {i18n.t("start_time")}
              </label>
              <div className="w-100">
                <TextValidator
                  onChange={(e) =>
                    setData({ ...data, startTime: e.target.value })
                  }
                  type="time"
                  value={data.startTime || ""}
                  validators={["required"]}
                  errorMessages={[i18n.t("this_field_is_required")]}
                  variant="outlined"
                />
              </div>
            </div>
            <div className="col-12 d-flex flex-column flex-md-row align-items-md-center mb-3 px-0">
              <label className="font-weight-bold" style={{ minWidth: "120px" }}>
                {i18n.t("end_time")}
              </label>
              <div className="w-100">
                <TextValidator
                  onChange={(e) =>
                    setData({ ...data, endTime: e.target.value })
                  }
                  type="time"
                  value={data.endTime || ""}
                  validators={["required"]}
                  errorMessages={[i18n.t("this_field_is_required")]}
                  variant="outlined"
                />
              </div>
            </div>
            <div className="row mx-0 px-0 justify-content-between my-3 col-12">
              {data._id ? (
                <PrimaryButton
                  className="w-128"
                  onClick={() => onDelete(data._id)}
                  label={i18n.t("delete")}
                />
              ) : (
                <div />
              )}
              <PrimaryButton
                className="w-128"
                props={{
                  type: "submit",
                }}
                label={data._id ? i18n.t("save") : i18n.t("create")}
              />
            </div>
          </ValidatorForm>
        </div>
      }
    />
  )
}
