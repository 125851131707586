import { put, takeEvery } from "redux-saga/effects"

import * as types from "../../actions/ActionTypes"
import {
  fund,
  get,
  getTransactionHistory,
  requestFailure,
} from "../../actions/Saloon/CommunicationsMarketing"

import axios from "axios"
import { communication_markting } from "../../../config/WebServices"

function* getSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.get(communication_markting, {
      params: payload,
    })
    data = data.data
    if (callbackMap.success && typeof callbackMap.success == "function") {
      callbackMap.success(data)
    }
    yield put(get(data.data))
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* fundSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.post(
      `${communication_markting}/chargeAndFundAccount`,
      payload
    )
    data = data.data
    if (callbackMap.success && typeof callbackMap.success == "function") {
      callbackMap.success(data)
    }
    yield put(fund(data.data))
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* getTransactionHistorySaga(action) {
  const { callbackMap = {} } = action
  let data
  try {
    data = yield axios.get(`${communication_markting}/getTransactionHistory`)
    data = data.data
    if (callbackMap.success && typeof callbackMap.success == "function") {
      callbackMap.success(data)
    }
    yield put(getTransactionHistory(data.data))
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

export default function* root() {
  yield takeEvery(types.COMMUNICATION_MARKETING.GET_SAGA, getSaga)
  yield takeEvery(types.COMMUNICATION_MARKETING.FUND_SAGA, fundSaga)
  yield takeEvery(
    types.COMMUNICATION_MARKETING.TRANSACTION_HISTORY_SAGA,
    getTransactionHistorySaga
  )
}
