import React, { useEffect, useState } from "react"

import TextField from "@material-ui/core/TextField"
import PrimaryButton from "../../../components/Core/PrimaryButton"
import i18n from "../../../i18n"
import { Translation } from "react-i18next"
import "./styles.scss"
import { BaseModal } from "../../../components"
import useScreenSize from "../../../context/ScreenSize"
import useActiveMenuType from "../../../context/ActiveMenuType"
const Settings = ({
  open,
  title,
  actions,
  style,
  containerClassName = "base-model-container large-container",
  className = "",
  contentClassName = "",
  onClick = () => {},
  employees,
  services,

  viewWorkingHours,
  viewUnavailabilities,
  setViewWorkingHoursUnavailabilities = () => {},
  setViewUnavailabilities = () => {},
  onClose,
}) => {
  const statuses = [
    { text: "Waiting", value: 1 },
    { text: "Now Serving", value: 2 },
    { text: "Done", value: 4 },
    { text: "Cancel", value: 3 },
  ]

  const { screenSize } = useScreenSize()
  const { type } = useActiveMenuType()
  const handleSelectAll = () => {
    var checkboxes = document.getElementsByName("status")
    for (var i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = true
    }
    setNewViewWorkingHours(true)
    setNewViewUnavailabilities(true)
    initFilter()
  }

  const handleUnSelectAll = () => {
    var checkboxes = document.getElementsByName("status")
    for (var i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = false
    }
    setNewViewWorkingHours(false)
    setNewViewUnavailabilities(false)
    setFilter({ status: [], employee: [], service: [] })
  }

  const [newViewWorkingHours, setNewViewWorkingHours] = useState(
    localStorage.getItem("viewWorkingHoursUnavailabilities") == "true"
  )

  const [newViewUnavailabilities, setNewViewUnavailabilities] = useState(
    localStorage.getItem("viewUnavailabilities") == "true"
  )
  const [filter, setFilter] = useState({})
  useEffect(() => {
    initFilter()
  }, [employees, services])

  const initFilter = () => {
    setFilter({
      status: statuses?.map(({ value }) => value),
      employee: employees?.map(({ value }) => value),
      service: [...services],
    })
  }
  const onChange = (key, value, e) => {
    const { checked, type } = e.target
    if (type === "checkbox") {
      if (!checked) {
        filter[key].splice(filter[key].indexOf(value), 1)
      } else {
        filter[key].push(value)
      }
    } else if (type === "text") {
      filter[key] = value
    }

    setFilter({ ...filter })
  }
  const buttonRender = () => {
    return (
      <>
        <PrimaryButton
          className="w-128 mb-3"
          label={i18n.t("select_all")}
          onClick={() => handleSelectAll()}
        />
        <PrimaryButton
          className="w-128 mb-3 ml-3"
          label={i18n.t("unselect_all")}
          onClick={handleUnSelectAll}
        />
      </>
    )
  }
  return (
    <>
      <BaseModal
        open={open}
        onClose={() => onClose()}
        showCloseBtn={false}
        title={
          screenSize === "mobile" ? (
            <>
              <div>{title}</div>
              <div className="mt-3 d-flex col-12 justify-content-center">
                {buttonRender()}
              </div>
            </>
          ) : (
            title
          )
        }
        containerClassName={containerClassName}
        contentClassName={contentClassName}
        actions={
          screenSize === "mobile" ? (
            <div className="d-flex justify-content-center gap-2">
              <PrimaryButton
                className="w-128"
                label={i18n.t("cancel")}
                onClick={() => {
                  onClose()
                }}
              />
              <PrimaryButton
                className="w-128"
                label={i18n.t("save")}
                onClick={() => {
                  onClick(filter)
                  onClose()
                  setViewWorkingHoursUnavailabilities(newViewWorkingHours)
                  setViewUnavailabilities(newViewUnavailabilities)
                }}
              />
            </div>
          ) : null
        }
        content={
          <div>
            <div className="setting-header">
              <div className="col-4 d-flex flex-wrap align-items-center px-0">
                <label className="font-weight-bold mr-3">
                  <Translation>
                    {(t) => t("container.advancedSearchDialog_name")}
                  </Translation>
                </label>
                <div
                  style={
                    screenSize === "mobile"
                      ? { width: "100%" }
                      : { width: "calc(100% - 70px)" }
                  }
                >
                  <TextField
                    onChange={(e) => onChange("name", e.target.value, e)}
                    defaultValue=""
                    variant="outlined"
                  />
                </div>
              </div>
              <div className="col-4 d-flex flex-wrap align-items-center px-0">
                <label className="font-weight-bold mr-3">
                  <Translation>
                    {(t) => t("container.advancedSearchDialog_phone_number")}
                  </Translation>
                </label>
                <div
                  style={
                    screenSize === "mobile"
                      ? { width: "100%" }
                      : { width: "calc(100% - 130px)" }
                  }
                >
                  <TextField
                    onChange={(e) => onChange("phoneNumber", e.target.value, e)}
                    defaultValue=""
                    variant="outlined"
                  />
                </div>
              </div>
              <div className="col-4 d-flex flex-wrap  align-items-center px-0">
                <label className="font-weight-bold mr-3">
                  <Translation>{(t) => t("reference_code")}</Translation>
                </label>
                <div
                  style={
                    screenSize === "mobile"
                      ? { width: "100%" }
                      : { width: "calc(100% - 130px)" }
                  }
                >
                  <TextField
                    onChange={(e) => onChange("shortenedId", e.target.value, e)}
                    defaultValue=""
                    variant="outlined"
                  />
                </div>
              </div>
            </div>
            {screenSize !== "mobile" && (
              <div className="mt-5 mr-5 text-right">{buttonRender()}</div>
            )}
            <div className="setting-body">
              <div className="container col-3">
                {type !== "employee" ? (
                  <>
                    <div>
                      <input
                        type="checkbox"
                        className="styled-checkbox"
                        name="status"
                        defaultChecked={newViewWorkingHours}
                        onChange={(e) => {
                          setNewViewWorkingHours(e.target.checked)
                        }}
                        id="show_working_hours"
                      />
                      <label
                        className="box-label mb-0"
                        htmlFor="show_working_hours"
                      >
                        <Translation>
                          {(t) => t("show_working_hours")}
                        </Translation>
                      </label>
                    </div>
                    <div className="mt-4">
                      <input
                        type="checkbox"
                        className="styled-checkbox"
                        name="status"
                        defaultChecked={newViewUnavailabilities}
                        onChange={(e) => {
                          setNewViewUnavailabilities(e.target.checked)
                        }}
                        id="show_unavailiblities"
                      />
                      <label
                        className="box-label mb-0"
                        htmlFor="show_unavailiblities"
                      >
                        <Translation>
                          {(t) => t("show_unavailiblities")}
                        </Translation>
                      </label>
                    </div>
                  </>
                ) : null}

                <label className={`title ${type !== "employee" ? "mt-4" : ""}`}>
                  <Translation>
                    {(t) => t("container.profile_status")}
                  </Translation>
                </label>
                {statuses.map((status, i) => (
                  <div className="mt-4">
                    <input
                      type="checkbox"
                      className="styled-checkbox"
                      name="status"
                      defaultChecked={true}
                      onChange={(e) => onChange("status", status.value, e)}
                      id={"status" + i}
                    />
                    <label className="box-label mb-0" htmlFor={"status" + i}>
                      {status.text}
                    </label>
                  </div>
                ))}
              </div>

              {employees && employees.length ? (
                <div className="container col-3">
                  <label className="title">
                    <Translation>
                      {(t) => t("container.add_employe_employees")}
                    </Translation>
                  </label>

                  {employees.map((employee, i) => (
                    <div className="mt-4">
                      <input
                        type="checkbox"
                        className="styled-checkbox"
                        name="status"
                        defaultChecked={true}
                        onChange={(e) => {
                          onChange("employee", employee.value, e)
                        }}
                        id={"employee" + i}
                      />
                      <label
                        className="box-label mb-0"
                        htmlFor={"employee" + i}
                      >
                        {employee.text}
                      </label>
                    </div>
                  ))}
                </div>
              ) : null}

              <div
                className={`container ${
                  employees && employees.length ? "col-6" : "col-9"
                }`}
              >
                {services && services.length ? (
                  <>
                    <label className="title">
                      <Translation>
                        {(t) => t("container.profile_service")}
                      </Translation>
                    </label>
                    <div className="setting-service-container">
                      {services.map((service, i) => (
                        <div className="mt-4">
                          <input
                            type="checkbox"
                            className="styled-checkbox"
                            name="status"
                            defaultChecked={true}
                            onChange={(e) => onChange("service", service, e)}
                            id={"service" + i}
                          />
                          <label
                            className="box-label mb-0"
                            htmlFor={"service" + i}
                          >
                            {service}
                          </label>
                        </div>
                      ))}
                    </div>
                  </>
                ) : null}
              </div>
            </div>
            {screenSize !== "mobile" && (
              <div className="row mr-5 px-0 justify-content-end my-5">
                <PrimaryButton
                  className="w-128 mr-2"
                  label={i18n.t("cancel")}
                  onClick={() => {
                    onClose()
                  }}
                />
                <PrimaryButton
                  className="w-128"
                  label={i18n.t("save")}
                  onClick={() => {
                    onClick(filter)
                    onClose()
                    setViewWorkingHoursUnavailabilities(newViewWorkingHours)
                    setViewUnavailabilities(newViewUnavailabilities)
                  }}
                />
              </div>
            )}
          </div>
        }
      />
    </>
  )
}

export default Settings
