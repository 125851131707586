import { put, takeEvery } from "redux-saga/effects"

import * as types from "../../actions/ActionTypes"
import {
  upsert,
  get,
  remove,
  requestFailure,
  removeEvent,
  upsertEvent,
} from "../../actions/Saloon/ActivityScheduleTemplate"

import axios from "axios"
import { activity_schedule_template } from "../../../config/WebServices"

function* getSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.get(activity_schedule_template, {
      params: payload,
    })
    data = data.data
    if (callbackMap.success && typeof callbackMap.success == "function") {
      callbackMap.success(data)
    }
    yield put(get(data.data))
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* upsertSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.post(activity_schedule_template, payload)
    data = data.data
    if (callbackMap.success && typeof callbackMap.success == "function") {
      callbackMap.success(data)
    }
    yield put(upsert(data.data))
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* removeSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.delete(activity_schedule_template, {
      params: payload,
    })
    data = data.data
    if (callbackMap.success && typeof callbackMap.success == "function") {
      callbackMap.success(data)
    }
    yield put(remove(data.data))
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* upsertEventSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.post(`${activity_schedule_template}/event`, payload)
    data = data.data
    if (callbackMap.success && typeof callbackMap.success == "function") {
      callbackMap.success(data)
    }
    yield put(upsertEvent(data.data))
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* removeEventSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.delete(`${activity_schedule_template}/event`, {
      params: payload,
    })
    data = data.data
    if (callbackMap.success && typeof callbackMap.success == "function") {
      callbackMap.success(data)
    }
    yield put(removeEvent(data.data))
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

export default function* root() {
  yield takeEvery(types.ACTIVITY_SCHEDULE_TEMPLATE.GET_SAGA, getSaga)
  yield takeEvery(types.ACTIVITY_SCHEDULE_TEMPLATE.UPSERT_SAGA, upsertSaga)
  yield takeEvery(types.ACTIVITY_SCHEDULE_TEMPLATE.DELETE_SAGA, removeSaga)
  yield takeEvery(
    types.ACTIVITY_SCHEDULE_TEMPLATE.UPSERT_EVENT_SAGA,
    upsertEventSaga
  )
  yield takeEvery(
    types.ACTIVITY_SCHEDULE_TEMPLATE.DELETE_EVENT_SAGA,
    removeEventSaga
  )
}
