import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import i18n from "../../../i18n";
import {
  getByIdRequest,
  insertRequest,
} from "../../../redux/actions/Saloon/FormBuilder";
import PrimaryButton from "../../../components/Core/PrimaryButton";
import { DotsLoader } from "../../../components";
import FormBuilder from "../../../components/FormBuilder/FormBuilder.component";
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import styled from "styled-components";
import SharedStorage from "../../../helpers/Storage";

const FormContainer = styled.section`
  background-color: white;
  padding-bottom: 8vh;
`;

const SectionHeadline = styled.h1`
  padding: 4vh;
  letter-spacing: 2px;
  width: 80%;
  margin: 0 auto 0 auto;
  padding-left: 0;
`;
const FormBuilderContainer = ({
  getByIdRequest,
  insertRequest,
  loading,
  history,
  match,
  active,
}) => {
  const {
    params: { formId },
  } = match;
  const [companyId, setCompanyId] = useState("");
  const [addOpen, setAddOpen] = useState(false);
  const onMount = async () => {
    const company = JSON.parse(await SharedStorage.getItem("company"));
    setCompanyId(company._id);
    getByIdRequest({
      _id: formId,
    });
  };
  useEffect(() => {
    onMount();
  }, []);
  const onAdd = (data) => {
    insertRequest(
      { ...data, companyId },
      {
        success: ({ data }) => {
          history.push(`/saloon-form-builder/${data._id}`);
          SuccessHelper.handleSuccess(i18n.t("add_succesfully"), true);
          setAddOpen(false);
        },
        failure: () => {
          ErrorHelper.handleErrors(i18n.t("failed_to_add"), true);
        },
      }
    );
  };

  const updateFormAfterSave = () => {
    getByIdRequest({
      _id: formId,
    });
  };
  return (
    <FormContainer>
      <DotsLoader isloading={loading} />
      <div className="row mx-0 justify-content-between">
        <SectionHeadline className="">
          {i18n.t("container.design_a_form")}
        </SectionHeadline>
      </div>
      {active ? (
        <FormBuilder updateFormAfterSave={updateFormAfterSave} form={active} />
      ) : null}
    </FormContainer>
  );
};

const mapStateToProps = (state) => ({
  active: state.formBuilder.active,
  loading: state.formBuilder.isFetching,
});

const action = { getByIdRequest, insertRequest };

export default connect(mapStateToProps, action)(FormBuilderContainer);
