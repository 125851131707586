import { connect } from "react-redux"
import React, { Component } from "react"
import moment from "moment"
import {
  getUser,
  editUser,
  userCreateProfilePicture,
  getCompanyThatEmployeeAdd,
} from "../../../config/simpleApiCalls"
import { DotsLoader } from "../../../components"
import { Images } from "../../../theme"
import { SuccessHelper } from "../../../helpers"
import i18n from "../../../i18n"
import { Translation } from "react-i18next"
import "./styles.css"
import { TextField } from "@material-ui/core"
import PhoneNumberFormat from "../../phoneNumberFormat/PhoneNumberFormat.component.jsx"
import CalendarSelect from "../../../components/Core/Select/index"
import SharedStorage from "../../../helpers/Storage"
import { Capacitor } from "@capacitor/core"
import PrimaryButton from "../../../components/Core/PrimaryButton"

class EditProfile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // Personal Information
      imageFile: null,
      imageUrl: "",
      firstName: "",
      lastName: "",
      password: "",
      dateOfBirth: "",
      password: "",
      address: "",
      city: "",
      province: "",
      postalCode: "",
      telephone: "",
      functionRole: "",
      email: "",
      employee: "Employee",
      employeeUrl: "",

      // Personal Information Error
      firstNameError: "",
      lastNameError: "",
      dateOfBirthError: "",
      passwordError: "",
      addressError: "",
      cityError: "",
      provinceError: "",
      postalCodeError: "",
      telephoneError: "",
      emailError: "",
      functionRoleError: "",
      isloading: false,
      calViews: ["day", "work week", "week", "month"],
      selectedCalView: "day",
      employee: {},
    }
  }

  variant = "outlined"

  async componentDidMount() {
    this.handleGetUser()
    getCompanyThatEmployeeAdd().then(async ({ data }) => {
      if (data.data[0])
        this.setState({
          employeeUrl: `${process.env.REACT_APP_IFRAME_URL}${data.data[0].companyId.shortenedId}/profile/${data.data[0].employeeId}`,
          selectedCalView:
            JSON.parse(await SharedStorage.getItem("employee"))
              ?.selectedCalView || "day",
          employee: JSON.parse(await SharedStorage.getItem("employee")),
        })
    })
  }

  handleGetUser = async () => {
    this.setState({ isloading: true })
    getUser()
      .then((res) => {
        if (res.data.success) {
          this.setState({
            isloading: false,
            firstName: res.data.data.user.firstName,
            lastName: res.data.data.user.lastName,
            dateOfBirth: res.data.data.user.dob,
            address: res.data.data.user.address,
            city: res.data.data.user.city,
            province: res.data.data.user.province,
            postalCode: res.data.data.user.postalCode,
            telephone: res.data.data.user.phoneNo,
            email: res.data.data.user.email,
            imageUrl: res.data.data.user.profile_img,
            selectedCalView: res.data.data.user.selectedCalView,
          })
        }
      })
      .catch((error) => {
        console.log(error, "errorrrrrrrrrrrrrrrrrrr")
      })
  }

  handleNavigation = (route) => {
    this.props.history.push(route)
  }

  // Personal Information
  onChangeFirstName = (text) => this.setState({ firstName: text.target.value })
  onChangeLastName = (text) => this.setState({ lastName: text.target.value })
  onChangeDateOfBirth = (text) =>
    this.setState({ dateOfBirth: text.target.value })
  onChangePassword = (text) => this.setState({ password: text.target.value })
  onChangeAddress = (text) => this.setState({ address: text.target.value })
  onChangeCity = (text) => this.setState({ city: text.target.value })
  onChangeProvince = (text) => this.setState({ province: text.target.value })
  onChangePostalCode = (text) =>
    this.setState({ postalCode: text.target.value })
  onChangeTelephone = (text) => this.setState({ telephone: text })
  onChangeFunctionRole = (text) =>
    this.setState({ functionRole: text.target.value })
  onChangeEmail = (text) => this.setState({ email: text.target.value })

  checkValidation = () => {
    const {
      firstName,
      lastName,
      dateOfBirth,
      password,
      address,
      city,
      province,
      postalCode,
      telephone,
      functionRole,
      email,
    } = this.state

    // console.log(
    //   telephone.match(telephoneRegex),
    //   "dateOfBirth-_____________________________________________________________________________"
    // );

    const currentYear = new Date().getFullYear()
    const currentMonth = new Date().getMonth()
    const currentDate = new Date().getDate()
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const nameRegex =
      /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{2,}$/u
    const telephoneRegex = /[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]/g
    if (!firstName) {
      this.setState({
        firstNameError: (
          <Translation>{(t) => t("first_name_required")}</Translation>
        ),
      })
      setTimeout(() => {
        this.setState({
          firstNameError: "",
        })
      }, 6000)
    } else if (!telephone.replace(/\s/g, "").match(telephoneRegex)) {
      this.setState({
        telephoneError: (
          <Translation>{(t) => t("invalid_phone_number")}</Translation>
        ),
      })

      setTimeout(() => {
        this.setState({
          telephoneError: "",
        })
      }, 6000)
    } else if (!lastName) {
      this.setState({
        lastNameError: (
          <Translation>{(t) => t("last_name_required")}</Translation>
        ),
      })
      setTimeout(() => {
        this.setState({
          lastNameError: "",
        })
      }, 6000)
    } else if (!address) {
      this.setState({
        addressError: <Translation>{(t) => t("address_required")}</Translation>,
      })
      setTimeout(() => {
        this.setState({
          addressError: "",
        })
      }, 6000)
    } else if (!city) {
      this.setState({
        cityError: <Translation>{(t) => t("city_required")}</Translation>,
      })
      setTimeout(() => {
        this.setState({
          cityError: "",
        })
      }, 6000)
    } else if (!province) {
      this.setState({
        provinceError: (
          <Translation>{(t) => t("province_required")}</Translation>
        ),
      })
      setTimeout(() => {
        this.setState({
          provinceError: "",
        })
      }, 6000)
    } else if (!postalCode) {
      this.setState({
        postalCodeError: (
          <Translation>{(t) => t("postal_code_required")}</Translation>
        ),
      })
      setTimeout(() => {
        this.setState({
          postalCodeError: "",
        })
      }, 6000)
    } else if (!telephone) {
      this.setState({
        telephoneError: (
          <Translation>{(t) => t("telephone_required")}</Translation>
        ),
      })
      setTimeout(() => {
        this.setState({
          telephoneError: "",
        })
      }, 6000)
    } else if (!email) {
      this.setState({
        emailError: (
          <Translation>{(t) => t("container.email_required")}</Translation>
        ),
      })
      setTimeout(() => {
        this.setState({
          emailError: "",
        })
      }, 6000)
    } else if (!email.match(emailRegex)) {
      this.setState({
        emailError: (
          <Translation>{(t) => t("container.invalid_email")}</Translation>
        ),
      })
      setTimeout(() => {
        this.setState({
          emailError: "",
        })
      }, 6000)
    } else {
      this.handleEditUser()
    }
  }

  handleEditUser = () => {
    this.setState({ isloading: true })

    const {
      firstName,
      lastName,
      dateOfBirth,
      city,
      address,
      province,
      telephone,
      postalCode,
      email,
      selectedCalView,
      employee,
    } = this.state
    const payload = {
      firstName,
      lastName,
      dob: dateOfBirth,
      city,
      address,
      province,
      phoneNo: telephone,
      postalCode,
      userName: firstName + " " + lastName,
      selectedCalView,
    }

    editUser(payload)
      .then((res) => {
        if (res.data.success) {
          SuccessHelper.handleSuccess(
            i18n.t("container.employe_update_profile"),
            true
          )
          // this.setState({ isloading: true });
          employee.selectedCalView = selectedCalView
          SharedStorage.setItem("employee", JSON.stringify(employee))
          this.handleGetUser()
        }
      })
      .catch((error) => {
        console.log(error, "errorrrrrrrrrrrrrrrrrrr")
      })
  }

  fileSelectedHandler = (event) => {
    const file = URL.createObjectURL(event.target.files[0])

    this.setState({ imageUrl: file, imageFile: event.target.files[0] })
  }

  handleUploadNewPicture = () => {
    this.setState({ isloading: true })
    const { imageUrl, imageFile } = this.state
    let payloadFormdata = new FormData()
    payloadFormdata.append("profile_img", imageFile)
    userCreateProfilePicture(payloadFormdata)
      .then((res) => {
        if (res.data.success) {
          SuccessHelper.handleSuccess(
            <Translation>
              {(t) => t("container.employe_update_img_profile")}
            </Translation>,
            true
          )
          this.setState({ isloading: false, imageFile: null })
          //   , () =>
          // );}
          this.handleGetUser()
        }
      })
      .catch((error) => {
        console.log(error, "errorrrrrrrrrrrrrrrrrrr")
      })
  }

  renderSaloonDashboardHeading = () => {
    return (
      <div className="col-12 p-4">
        <h1 className="saloon-dashboard-heading p-4">
          <Translation>
            {(t) => t("container.employe_profile_edit")}
          </Translation>
        </h1>
      </div>
    )
  }

  renderUploadPicture = () => {
    const { imageUrl, imageFile } = this.state
    return (
      <div className="col-12 d-md-flex align-items-md-center text-center px-5 pb-5">
        <input
          type="file"
          style={{ display: "none" }}
          onChange={this.fileSelectedHandler}
          ref={(fileInput) => (this.fileInput = fileInput)}
          accept="image/*"
        />
        <span
          onClick={() => this.fileInput.click()}
          style={{
            backgroundImage: `url(${
              imageUrl ? imageUrl : Images.user_profile_image
            })`,
          }}
          id="saloon-edit-profile"
        />
        {imageFile ? (
          <button
            type="button"
            className="btn btn-light btn-sm"
            id="saloon-upload-picture"
            onClick={() => this.handleUploadNewPicture()}
          >
            <Translation>
              {(t) => t("container.employe_profile_upload_img")}
            </Translation>
          </button>
        ) : null}
        {/* <button type="button" className="btn btn-light btn-sm" id="saloon-delete-picture">Delete</button> */}
      </div>
    )
  }

  renderEditProfileCard = () => {
    const { formTypeId } = this.state
    return (
      <div className="col-12">
        <div className="col-12 p-3 mb-3" id="saloon-edit-profile-card">
          {this.renderPersonalInformationForm()}
          <PrimaryButton
            onClick={() => this.checkValidation()}
            className="mt-3 ml-md-3 mt-md-4 w-128 "
          >
            {i18n.t("save")}
          </PrimaryButton>
        </div>
      </div>
    )
  }

  renderInputCol6 = (
    id,
    lable,
    inputType,
    onChange,
    val,
    disabled,
    errorMessage
  ) => {
    return (
      <div class="row align-items-center gap-1 form-group col-md-12 px-2">
        <label className="font-weight-bold">{lable}</label>
        <TextField
          type={inputType}
          // label={lable}
          id={id}
          onChange={(text) => onChange(text)}
          value={val}
          variant={this.variant}
          disabled={disabled}
          error={errorMessage}
          helperText={errorMessage ? errorMessage : ""}
        />
      </div>
    )
  }

  // renderInputCol12 = (
  //   id,
  //   lable,
  //   inputType,
  //   onChange,
  //   val,
  //   disabled,
  //   errorMessage
  // ) => {
  //   return (
  //     <div className="row align-items-center gap-1 form-group col-md-12 p-2">
  //       <label className="font-weight-bold">{lable}</label>
  //       <TextField
  //         type={inputType}
  //         // label={lable}
  //         id={id}
  //         onChange={(text) => onChange(text)}
  //         value={val}
  //         disabled={disabled}
  //         error={errorMessage}
  //         helperText={errorMessage ? errorMessage : ""}
  //       />
  //     </div>
  //   )
  // }

  renderLoader = () => {
    const { isloading } = this.state
    return <DotsLoader isloading={isloading} />
  }

  inputTypeNumber = (
    id,
    lable,
    inputType,
    onChange,
    val,
    errorMessageStatus,
    errorMessage
  ) => {
    return (
      <div class="row align-items-center gap-1 form-group col-md-12 px-2">
        <label className="font-weight-bold">{lable}</label>
        <PhoneNumberFormat
          phoneUpdate={(e) => onChange(e)}
          val={val}
          variant={this.variant}
          error={errorMessage}
          helperText={errorMessage ? errorMessage : ""}
        />
      </div>
    )
  }

  renderPersonalInformationForm = () => {
    return (
      <div className="col-12 px-0 px-md-3">
        {/* <h3 className="form-heading">{i18n.t("container.personal_info")}</h3> */}
        <div class="form-row">
          {this.renderInputCol6(
            "first-name",
            i18n.t("first_name"),
            "text",
            this.onChangeFirstName,
            this.state.firstName,
            false,
            this.state.firstNameError
          )}
          {this.renderInputCol6(
            "last-name",
            i18n.t("last_name"),
            "text",
            this.onChangeLastName,
            this.state.lastName,
            false,
            this.state.lastNameError
          )}
          {this.inputTypeNumber(
            "telephone",
            <Translation>{(t) => t("phone_number")}</Translation>,
            "telephone",
            this.onChangeTelephone,
            this.state.telephone,
            false,
            this.state.telephoneError
          )}
          {this.renderInputCol6(
            "date",
            <Translation>{(t) => t("container.dob")}</Translation>,
            "date",
            this.onChangeDateOfBirth,
            this.state.dateOfBirth,
            true,
            this.state.dateOfBirthError
          )}
          {this.renderInputCol6(
            "email",
            <Translation>{(t) => t("container.profile_email")}</Translation>,
            "email",
            this.onChangeEmail,
            this.state.email,
            true,
            this.state.emailError
          )}
          {this.renderInputCol6(
            "address",
            <Translation>{(t) => t("container.address")}</Translation>,
            "text",
            this.onChangeAddress,
            this.state.address,
            false,
            this.state.addressError
          )}
          {this.renderInputCol6(
            "city",
            <Translation>{(t) => t("container.city")}</Translation>,
            "text",
            this.onChangeCity,
            this.state.city,
            false,
            this.state.cityError
          )}
          {this.renderInputCol6(
            "province",
            <Translation>{(t) => t("container.province")}</Translation>,
            "text",
            this.onChangeProvince,
            this.state.province,
            false,
            this.state.provinceError
          )}
          {this.renderInputCol6(
            "postal-code",
            <Translation>{(t) => t("container.postal_code")}</Translation>,
            "text",
            this.onChangePostalCode,
            this.state.postalCode,
            false,
            this.state.postalCodeError
          )}
          {this.renderInputCol6(
            "employee_url",
            <Translation>{(t) => t("employee_url")}</Translation>,
            "employee_url",
            "",
            this.state.employeeUrl,
            true,
            ""
          )}
          <div class="row align-items-center gap-1 form-group col-md-12 px-2">
            <label className="font-weight-bold">
              {i18n.t("calendar_view")}
            </label>
            <CalendarSelect
              defaultValue={this.state.selectedCalView}
              variant={this.variant}
              onChange={(value) => {
                this.state.selectedCalView = value.target.value
              }}
              excludeEmptyValue={true}
              options={this.state.calViews.map((view) => {
                return {
                  name: i18n.t(view),
                  value: view,
                }
              })}
              className="select-week"
            />
          </div>

          {/*  */}
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        {this.renderLoader()}
        <div className="content-container">
          <div className="row mx-0">
            {this.renderSaloonDashboardHeading()}
            {Capacitor.platform == "web" && this.renderUploadPicture()}
            {this.renderEditProfileCard()}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

const action = {}

export default connect(mapStateToProps, action)(EditProfile)
