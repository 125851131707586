import React, { useEffect, useMemo, useState } from "react"
import i18n from "../../../i18n"
import NumberFormat from "react-number-format"
import PrimaryButton from "../../Core/PrimaryButton"
import BasicTable from "../../Core/BasicTable"
import { getNestedValue } from "../../../util/objectMethods"
import { TableCell, TableRow } from "@material-ui/core"
import BaseModal from "../../BaseModal"
import moment from "moment"
import BookingNotesReminders from "../../BookingNotesReminders"
import { Images } from "../../../theme"
import { getCustomerBookingHistory } from "../../../config/simpleApiCalls"
import useScreenSize from "../../../context/ScreenSize"
const bookingStatuses = [
  { text: "Waiting", value: 1, styles: { color: "#aa43b5" } },
  { text: "Now Serving", value: 3, styles: { color: "#FFE478" } },
  { text: "Cancel", value: 2, styles: { color: "#FF7082" } },
  { text: "Done", value: 4, styles: { color: "#95DA47" } },
]

export default function CustomerDetails({
  isGym,
  setLoading,
  companyCustomerId,
  companyId,
}) {
  const [selectBooking, setSelectBooking] = useState(null)
  const [openNotes, setOpenNotes] = useState(null)
  const [openInfo, setOpenInfo] = useState(null)
  const [bookings, setBookings] = useState([])
  const [summary, setSummary] = useState()
  const { screenSize } = useScreenSize()
  useEffect(async () => {
    setLoading(true)
    const { data } = await getCustomerBookingHistory({
      _id: companyCustomerId,
      companyId,
    })
    const summary = {
      total: 0,
      tip: 0,
      taxes: 0,
      grandTotal: 0,
    }
    let renderBooking = data.data
      ?.reverse()
      .map((booking) => {
        if (booking.status == 1) {
          return null
        }
        booking.taxTotal = getNestedValue(booking, "taxes", []).reduce(
          (accum, element) => accum + element.amount,
          0
        )
        booking.total =
          booking.totalAmount + (booking.tip || 0) + booking.taxTotal
        if (booking.status == 4) {
          summary.total += booking.totalAmount || 0
          summary.tip += booking.tip || 0
          summary.taxes += booking.taxTotal
          summary.grandTotal += booking.total
        }
        return booking
      })
      .filter((booking) => !!booking)
    setBookings(renderBooking)
    setSummary(summary)
    setLoading(false)
  }, [])
  const additionalColumn = useMemo(() => {
    if (isGym) {
      return []
    }
    return [
      {
        header: i18n.t("by_price"),
        field: "price",
        accessor: (val) => {
          return `$ ${(val.totalAmount || 0).toFixed(2)}`
        },
      },
      {
        header: i18n.t("taxes"),
        field: "taxes",
        accessor: (val) => {
          return `$ ${(val.taxTotal || 0).toFixed(2)}`
        },
      },
      {
        header: i18n.t("tip"),
        field: "tip",
        accessor: (val) => {
          return `$ ${(val.tip || 0).toFixed(2)}`
        },
      },

      {
        header: i18n.t("grand_total"),
        field: "grand_total",
        accessor: (val) => {
          return `$ ${val.total.toFixed(2)}`
        },
      },
    ]
  }, [isGym])
  return (
    <div>
      <BasicTable
        tableContainerStyle={
          screenSize === "mobile" ? { height: "50vh" } : { height: "60vh" }
        }
        tableHeadStyle={{
          position: "sticky",
          top: "-1px",
          zIndex: 1,
          backgroundColor: "white",
        }}
        rows={bookings}
        prependRows={
          summary ? (
            <TableRow
              style={{
                position: "sticky",
                top: 29,
                zIndex: 1,
                backgroundColor: "#B3D7ED",
              }}
            >
              <TableCell>
                <strong>{i18n.t("summary")}: </strong>
              </TableCell>
              <TableCell />
              <TableCell />
              <TableCell>{`$${summary?.total.toFixed(2)}`}</TableCell>
              <TableCell>{`$${summary?.taxes.toFixed(2)}`}</TableCell>
              <TableCell>{`$${summary?.tip.toFixed(2)}`}</TableCell>
              <TableCell>{`$${summary?.grandTotal.toFixed(2)}`}</TableCell>
              <TableCell />
              <TableCell />
              <TableCell />
            </TableRow>
          ) : null
        }
        schema={[
          {
            header: i18n.t("container.advancedSearchDialog_name"),
            field: "name",
            render: (val) => {
              return (
                <div className="d-flex align-items-start flex-column">
                  {val.userId && val.userId.userName}
                  <span className="appointment-details-employee-other">
                    {getNestedValue(val, ["services", 0, "date"], "")}{" "}
                    {getNestedValue(val, ["services", 0, "time"], "")}
                  </span>
                </div>
              )
            },
          },
          {
            header: i18n.t("container.employe_phone_number"),
            field: "phone",
            render: (val) => {
              return (
                <div className="d-flex align-items-center mt-1">
                  <span className="appointment-details-employee-name">
                    {" "}
                    <span className="text-center">
                      <NumberFormat
                        type={"text"}
                        style={{
                          border: 0,
                          backgroundColor: "transparent",
                          padding: 0,
                          textAlign: "center",
                        }}
                        value={val.phoneNo}
                        disabled
                        format="### ### ####"
                      />
                      <div className="appointment-details-employee-name">
                        {" "}
                        {val.email || ""}{" "}
                      </div>
                    </span>
                  </span>
                </div>
              )
            },
          },
          {
            header: i18n.t("container.profile_service"),
            field: "service",
            accessor: (val) => {
              return getNestedValue(
                val,
                ["services", 0, "serviceId", "name"],
                ""
              )
            },
          },
          ...additionalColumn,
          {
            header: i18n.t("container.profile_status"),
            field: "status",
            render: (val) => {
              return (
                <span className="appointment-details-employee-name font-weight-bold">
                  {getNestedValue(
                    bookingStatuses,
                    [val.status - 1, "text"],
                    ""
                  )}
                  {val.isNoShow ? ` - ${i18n.t("no_show")}` : ""}
                </span>
              )
            },
          },
          {
            header: i18n.t("Info"),
            field: "info",
            render: (val) => {
              return (
                <PrimaryButton
                  className="w-34"
                  onClick={() => {
                    setSelectBooking(val)
                    setOpenInfo(true)
                  }}
                >
                  <img src={Images.info_svg} width="16px" alt="info" />
                </PrimaryButton>
              )
            },
          },
          {
            header: i18n.t("Notes"),
            field: "notes",
            render: (val) => {
              return (
                <PrimaryButton
                  className="w-34"
                  onClick={() => {
                    setSelectBooking(val)
                    setOpenNotes(true)
                  }}
                >
                  <img src={Images.note_svg} width="16px" alt="info" />
                </PrimaryButton>
              )
            },
          },
        ]}
      />
      <BaseModal
        open={!!selectBooking && openInfo}
        onClose={() => {
          setSelectBooking()
          setOpenInfo(false)
        }}
        title={i18n.t("info")}
        containerClassName="base-model-container info-container"
        content={
          <div className="d-flex mb-5">
            {selectBooking &&
              selectBooking.services.map((service, index) => (
                <>
                  <div className="col-6 px-0">
                    <div className="spaceAround">
                      <div style={{ minWidth: "fit-content", padding: 4 }}>
                        {index + 1}. {i18n.t("container.profile_service")}
                      </div>
                      <div className="textSize">
                        {moment(`${service.date} ${service.time}`).format(
                          "MMMM Do YYYY h:mm a"
                        )}
                        <span className="ml-3">{service.serviceId.name}</span>
                      </div>
                    </div>
                  </div>
                  {!isGym && (
                    <div className="col-6 px-0">
                      <div className="spaceAround">
                        <div style={{ minWidth: "fit-content", padding: 4 }}>
                          {" "}
                          Employee Name
                        </div>
                        <div className="textSize">
                          {getNestedValue(service.employeeId, "userId")
                            ? getNestedValue(
                                service.employeeId.userId,
                                "userName"
                              ) ||
                              `${getNestedValue(
                                service.employeeId.userId,
                                "firstName",
                                ""
                              )} ${getNestedValue(
                                service.employeeId.userId,
                                "lastName",
                                ""
                              )}`
                            : ""}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ))}
          </div>
        }
      />
      <BaseModal
        open={!!selectBooking && openNotes}
        onClose={() => {
          setSelectBooking()
          setOpenNotes(false)
        }}
        title={i18n.t("info")}
        containerClassName="base-model-container info-container custom-tab"
        content={
          <BookingNotesReminders
            booking={selectBooking}
            hasEditPermission={true}
          />
        }
      />
    </div>
  )
}
