import { take, put, call, fork } from "redux-saga/effects"

import * as types from "../../actions/ActionTypes"
import { success, failure } from "../../actions/Saloon/Register"

import { saloon_register_Api } from "../../../config/WebServices"
import { ErrorHelper } from "../../../helpers"
import axios from "axios"

function callRequest(data) {
  return axios.post(saloon_register_Api, data)
}
function* watchRequest() {
  while (true) {
    const { payload } = yield take(types.SALOON_REGISTER.REQUEST)
    try {
      const response = yield call(callRequest, payload)
      yield put(success(response))
    } catch (err) {
      yield put(failure(err))
      ErrorHelper.handleErrors(err, true)
    }
  }
}

export default function* root() {
  yield fork(watchRequest)
}
