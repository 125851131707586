import React, { useEffect, useState } from "react"
import i18n from "../../../i18n"
import {
  getRequest,
  removeRequest,
  getPricingDetailsRequest,
} from "../../../redux/actions/Saloon/CommunicationsMarketingCampaign"
import { getRequest as getAccountRequest } from "../../../redux/actions/Saloon/CommunicationsMarketing"
import { getRequest as getTemplatesRequest } from "../../../redux/actions/Saloon/CommunicationsMarketingTemplate"
import PrimaryButton from "../../../components/Core/PrimaryButton"
import { DotsLoader } from "../../../components"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import CampaignUpsertPopup from "./Components/CampaignUpsertPopup/CampaignUpsertPopup"
import BasicTable from "../../../components/Core/BasicTable"
import moment from "moment"
import EditIcon from "../../../components/Core/BasicTable/Actions/EditIcon"
import DeleteIcon from "../../../components/Core/BasicTable/Actions/DeleteIcon"
import { ErrorHelper, SuccessHelper } from "../../../helpers"
import deleteConfirmation from "../../../helpers/DeleteHelper"
import { Images } from "../../../theme"
import CampaignReusePopup from "./Components/CampaignReusePopup/CampaignReusePopup"
import useScreenSize from "../../../context/ScreenSize"
const CommunicationsMarketingCampaign = () => {
  const dispatch = useDispatch()
  const [upsertPopupOpen, setUpsertPopupOpen] = useState(false)
  const [reusePopupOpen, setReusePopupOpen] = useState(false)
  const [active, setActive] = useState(null)
  const loading = useSelector(
    (s) => s.communicationsMarketingCampaign.isFetching
  )
  const campaigns = useSelector((s) => s.communicationsMarketingCampaign.data)
  const { screenSize } = useScreenSize()
  useEffect(() => {
    dispatch(getRequest())
    dispatch(getPricingDetailsRequest())
    dispatch(getAccountRequest())
    dispatch(getTemplatesRequest())
  }, [])

  const onEdit = (val) => {
    setUpsertPopupOpen(true)
    setActive(val)
  }

  useEffect(() => {
    setActive(null)
  }, [upsertPopupOpen])

  const handleDelete = (id) => {
    dispatch(
      removeRequest(
        { id },
        {
          success: () => {
            SuccessHelper.handleSuccess(i18n.t("successfully_deleted"), true)
          },
          failure: () => {
            ErrorHelper.handleErrors(i18n.t("failed_to_delete"), true)
          },
        }
      )
    )
  }

  const onReuse = (campaign) => {
    setActive(campaign)
    setReusePopupOpen(true)
  }

  return (
    <div className="content-container p-4">
      <DotsLoader isloading={loading} />
      <div
        style={
          screenSize === "mobile"
            ? {
                position: "sticky",
                top: 0,
                zIndex: 1,
                backgroundColor: "white",
                paddingBottom: "1rem",
              }
            : {}
        }
        className="d-flex flex-column flex-md-row gap-2 justify-content-between align-items-center mb-4"
      >
        <h1 className="saloon-dashboard-heading pt-0">
          {i18n.t("communication_marketing_campaigns")}
        </h1>
        <PrimaryButton
          className="w-128"
          label={i18n.t("create")}
          onClick={() => setUpsertPopupOpen(true)}
        />
      </div>
      <div className="table-responsive">
        <BasicTable
          rows={campaigns || []}
          schema={[
            {
              header: i18n.t("name"),
              field: "name",
            },
            {
              header: i18n.t("send_on"),
              field: "sendOn",
              accessor: ({ sendOn }) => {
                return moment(sendOn).format("MM-DD-YYYY HH:mm")
              },
            },
            {
              header: i18n.t("created_on"),
              field: "createdDate",
              accessor: ({ createdDate }) => {
                return moment(createdDate).format("MM-DD-YYYY HH:mm")
              },
            },
            {
              header: i18n.t("status"),
              field: "status",
              accessor: ({ status }) => {
                return i18n.t(status)
              },
            },
            {
              header: i18n.t("method"),
              field: "method",
              accessor: ({ method }) => {
                return i18n.t(method)
              },
            },
            {
              header: i18n.t("reuse"),
              field: "reuse",
              render: (val) => {
                return (
                  <button
                    role="button"
                    onClick={() => onReuse(val)}
                    className="campaigns-table-action-icon"
                  >
                    <img
                      style={{ width: 16, filter: "brightness(0) invert(1)" }}
                      src={Images.reuse}
                    />
                  </button>
                )
              },
            },
            {
              key: "action",
              header: i18n.t("actions"),
              style: { width: 120 },
              render: (val) => {
                return (
                  <>
                    <EditIcon onClick={() => onEdit(val)} />
                    {val.status != "Complete" ? (
                      <DeleteIcon
                        onClick={() =>
                          deleteConfirmation(() => handleDelete(val._id))
                        }
                      />
                    ) : null}
                  </>
                )
              },
            },
          ]}
        />
      </div>
      <CampaignUpsertPopup
        open={upsertPopupOpen}
        setOpen={setUpsertPopupOpen}
        active={active}
      />
      <CampaignReusePopup
        open={reusePopupOpen}
        setOpen={setReusePopupOpen}
        active={active}
      />
    </div>
  )
}

export default CommunicationsMarketingCampaign
