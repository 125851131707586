import { connect } from "react-redux";
import React, { Component } from "react";

import { DotsLoader } from "../../components";
import { ErrorHelper } from "../../helpers";
import "./styles.css";
import { getFaqs } from "../../config/simpleApiCalls";

class GetFaqs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getTermData: [],
      textArea: "",
      isloading: true,
    };
  }

  onChangeTextArea = (text) => this.setState({ textArea: text.target.value });

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  onSetSidebarOpen = (open) => {
    this.setState({ sidebarOpen: open });
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  componentDidMount() {
    this.getTermAndContition();
  }
  getTermAndContition = (el) => {
    getFaqs()
      .then((res) => {
        if (res.data.success) {
          this.setState({
            isloading: false,
            getTermData: res.data.data,
          });
        } else {
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
        ErrorHelper.handleErrors("Something Went Wrong", true);
        console.log(error);
      });
  };

  renderDetail = () => {
    const { getTermData } = this.state;

    return (
      <div className="m-3">
        {!!getTermData.length &&
          getTermData.map((val, ind) => {
            return (
              <div
                className="pb-3"
                style={{ color: "#A1A1A1", overflow: "hidden" }}
              >
                {val?.question}
                <p>{val?.answer}</p>

                <br />
              </div>
            );
          })}
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.renderLoader()}
        <div
          className="container-fluid min-vh-100 px-0"
          style={{ backgroundColor: "#F7F7F7" }}
        >
          <div className="container mb-4 mb-md-5">
            <div className="row">
              <h2 className="pt-3">Get FAQs</h2>
              <div className="col-12 mt-3 bg-white shadow rounded px-3">
                {this.renderDetail()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(GetFaqs);
