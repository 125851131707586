import React, { useState } from "react"
import { DotsLoader } from "../../../components"
import i18n from "../../../i18n"
import CustomerSubscriptionReportFilters from "./components/CustomerSubscriptionReportFilters/CustomerSubscriptionReportFilters"
import CustomerSubscriptionReportTables from "./components/CustomerSubscriptionReportTables"
import { useDispatch, useSelector } from "react-redux"
import { ErrorHelper } from "../../../helpers"
import { useEffect } from "react"
import { subscriptionReportRequest } from "../../../redux/actions/Saloon/CompanyCustomerSubscription"
import statusActions from "../../../redux/actions/Saloon/CustomerStatus"

const timeOptions = [
  { name: "this_week", value: "Week" },
  { name: "this_month", value: "Month" },
  { name: "this_year", value: "Year" },
]

export default function CustomerSubscriptionReport() {
  const dispatch = useDispatch()
  const loading = useSelector((s) => s.companyCustomerSubscription.isFetching)
  const [filters, setFilters] = useState({ expires: "Year" })
  const [active, setActive] = useState(null)

  useEffect(() => {
    onFilter()
  }, [])

  const onFilter = (exportToXlsx) => {
    const params = { filters }
    if (exportToXlsx) {
      params.exportToXlsx = true
    }
    dispatch(
      subscriptionReportRequest(params, {
        failure() {
          ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true)
        },
      })
    )
    dispatch(statusActions.getRequest())
  }

  return (
    <div className="content-container p-4">
      <DotsLoader isloading={loading} />
      <div className="d-sm-flex justify-content-between align-items-center mb-4">
        <h1 className="saloon-dashboard-heading pt-0">
          {i18n.t("subscription_report")}
        </h1>
        <CustomerSubscriptionReportFilters
          filters={filters}
          setFilters={setFilters}
          onFilter={onFilter}
        />
      </div>
      <div className="col-xs-12 col-md-6 px-0 row gap-2 mb-3">
        {
          timeOptions.map((element, index) => (
            <button
              className={`reportBtn ${active === index && 'active'}`}
              name={i18n.t(element.name)}
              value={element.value}
              onClick={(e) => {
                if (!e.target.value) {
                  delete filters.expires
                  return setFilters({ ...filters })
                }
                setFilters({ ...filters, expires: e.target.value })
                setActive(index)
              }}
            >{element.value}</button>
          ))
        }
      </div>
      <CustomerSubscriptionReportTables />
    </div>
  )
}
