import React, { useEffect, useRef, useState, useMemo } from "react"
import i18n from "../../../i18n"
import {
  SelectValidator,
  ValidatorForm,
} from "react-material-ui-form-validator"
import PrimaryButton from "../../Core/PrimaryButton"
import Datepicker from "../../Core/Datepicker/Datepicker"
import CustomPlacesAutocomplete from "../../PlacesAutocomplete"
import moment from "moment"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { MenuItem, TextField } from "@material-ui/core"
import { useDispatch } from "react-redux"
import ageGroupActions from "../../../redux/actions/Saloon/CustomerAgeGroup"
import originActions from "../../../redux/actions/Saloon/CustomerOrigin"
import districtActions from "../../../redux/actions/Saloon/CustomerDistrict"
import statusActions from "../../../redux/actions/Saloon/CustomerStatus"
import { useSelector } from "react-redux"
import { changeProfilePicture } from "../../../config/simpleApiCalls"
import { Images } from "../../../theme"
import customerReferenceActions from "../../../redux/actions/Saloon/CustomerReference"
import useCache from "../../../context/LocalCache"
import useActiveMenuType from "../../../context/ActiveMenuType"
import ValidateForm from "../../Core/TextField/validateForm"

import restoreConfirmation from "../../../helpers/RestoreHelper"
import { deleteCustomer } from "../../../config/simpleApiCalls"
import useScreenSize from "../../../context/ScreenSize"
export default function CustomerDetails({
  onSave,
  active = {},
  allCustomers = [],
  onUpdate,
}) {
  const dispatch = useDispatch()
  const { getResource } = useCache()
  const form = useRef()
  const [data, setData] = useState({})
  const [addressText, setAddressText] = useState("")
  const [parentAccount, setParentAccount] = useState("")
  const [initialized, setInitialized] = useState(false)
  const imageInput = useRef()
  const ageGroups = useSelector((state) => state.customerAgeGroup.data)
  const districts = useSelector((state) => state.customerDistrict.data)
  const origins = useSelector((state) => state.customerOrigin.data)
  const statuses = useSelector((state) => state.customerStatus.data)
  const customerReferences = useSelector(
    (state) => state.customerReference.data
  )
  const { type } = useActiveMenuType()
  const isSalon = useMemo(() => {
    return type && type != "employee"
  }, [type])

  const { screenSize } = useScreenSize()
  useEffect(() => {
    dispatch(ageGroupActions.getRequest())
    dispatch(originActions.getRequest())
    dispatch(districtActions.getRequest())
    dispatch(statusActions.getRequest())
    dispatch(customerReferenceActions.getRequest())
  }, [])
  let userList = getResource((cache) => {
    if (isSalon) {
      return cache.company.customers
    } else {
      return cache.employee.customers
    }
  })
  const init = (data) => {
    setInitialized(false)
    const dob = data?.dob ? moment(data.dob).toDate() : ""
    setData({ ...data, dob })
    const keys = ["address", "postalCode", "city", "province", "country"]
    const addressText = keys
      .map((key) => data?.address?.[key])
      .filter((element) => !!element)
      .join(", ")
    setAddressText(addressText)
    setInitialized(true)
  }
  const handleSubmit = () => {
    const customer = userList.find(
      (customer) => customer.phoneNo === data.phoneNo
    )

    if (!data._id && customer?.isActive === false) {
      restoreConfirmation(
        () =>
          deleteCustomer({ ids: customer._id })
            .then((res) => {
              if (res.data.success) {
                onUpdate(res.data.data)
              } else if (!res.data.success) {
                ErrorHelper.handleErrors(res.data.msg, true)
              }
            })
            .catch((error) => {
              console.log(error, "errorrrrrrrrrrrrrrrrrrr")
            }),
        i18n.t("this_is_inactive", { name: i18n.t("customer").toLowerCase() })
      )
    } else {
      const copy = { ...data, parentAccountId: parentAccount?._id }
      onSave(copy).then(() => {
        if (!copy._id) {
          setData({})
          setParentAccount()
        }
      })
    }
  }
  const handleChange = (key, value) => {
    data[key] = value
    setData({ ...data })
  }

  useEffect(() => {
    if (active && active._id != data._id) {
      init(userList.find((customer) => customer._id == active._id))
    }
  }, [active])

  const formattedCustomers = useMemo(() => {
    return allCustomers.map((customer) => ({
      ...customer,
      value: customer._id,
      name: `${customer.name}${
        customer.phoneNo ? ` (${customer.phoneNo})` : ""
      }`,
    }))
  }, [allCustomers])

  useEffect(() => {
    setParentAccount(
      formattedCustomers.find(
        (customer) => customer._id == data.parentAccountId
      )
    )
  }, [data.parentAccountId, formattedCustomers])

  const fileSelectedHandler = (event) => {
    const profilePicUrl = URL.createObjectURL(event.target.files[0])
    setData({ ...data, profilePicUrl })
    let payload = new FormData()
    payload.append("image", event.target.files[0])
    payload.append("_id", data._id)

    changeProfilePicture(payload)
  }

  return (
    <div className="col-sm-12 px-0">
      {Boolean(data._id) && (
        <div className="col-12 d-md-flex align-items-md-center text-center justify-content-center mb-3">
          <input
            type="file"
            style={{ display: "none" }}
            onChange={(event) => fileSelectedHandler(event)}
            ref={(ref) => (imageInput.current = ref)}
            accept="image/*"
          />
          <span
            onClick={() => imageInput?.current?.click()}
            style={{
              backgroundImage: `url(${
                data.profilePicUrl
                  ? data.profilePicUrl
                  : Images.user_profile_image
              })`,
              cursor: "pointer",
            }}
            id="saloon-edit-profile"
          />
        </div>
      )}
      <ValidatorForm
        onSubmit={(e) => {
          handleSubmit()
        }}
        ref={form}
      >
        {/* <div className="col-6 p-0"> */}
        <div className="col-12 px-0">
          <ValidateForm
            type="text"
            label={i18n.t("first_name")}
            value={data.firstName || ""}
            onChange={(e) => handleChange("firstName", e.target.value)}
            validators={["required"]}
            errorMessages={[i18n.t("first_name_required")]}
            className="mb-4"
          />
          <ValidateForm
            type="text"
            label={i18n.t("name")}
            value={data.name || ""}
            onChange={(e) => handleChange("name", e.target.value)}
            validators={["required"]}
            errorMessages={[i18n.t("name_is_required")]}
            className="mb-4"
          />
          <ValidateForm
            type="number"
            label={i18n.t("phoneNo")}
            value={data.phoneNo || ""}
            onChange={(e) => handleChange("phoneNo", e.target.value)}
            validators={[
              "required",
              "matchRegexp:^[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]$",
            ]}
            errorMessages={[
              i18n.t("phone_is_required"),
              i18n.t("invalid_phone_number"),
            ]}
            className="mb-4"
          />
          <ValidateForm
            type="email"
            label={i18n.t("container.profile_email")}
            value={data.email || ""}
            onChange={(e) => handleChange("email", e.target.value)}
            validators={["isEmail"]}
            errorMessages={[i18n.t("invalid_email")]}
            className="mb-4"
          />
          {initialized ? (
            <div className="row col-12 px-0 pb-4 align-items-center inline-custom-form">
              <label
                className={`${
                  screenSize === "mobile" ? "col-12 " : ""
                } font-weight-bold`}
              >
                {i18n.t("address")}
              </label>
              <div
                className={screenSize === "mobile" ? "col-12" : ""}
                style={{ width: "calc(100% - 120px)" }}
              >
                <CustomPlacesAutocomplete
                  defaultAddress={addressText || ""}
                  addressSelected={(newMap) => handleChange("address", newMap)}
                />
              </div>
            </div>
          ) : null}
          <div className="row col-12 px-0 pb-4 align-items-center inline-custom-form">
            <label
              className={`${
                screenSize === "mobile" ? "col-12 " : ""
              } font-weight-bold`}
            >
              {i18n.t("container.dob")}
            </label>
            <div className={screenSize === "mobile" ? "col-12" : ""}>
              <Datepicker
                label={i18n.t("container.dob")}
                selected={data.dob || ""}
                onChange={(date) => {
                  handleChange("dob", date)
                }}
                wrapperClassName="col-12 p-0"
              />
            </div>
          </div>
          <ValidateForm
            type="text"
            label={i18n.t("maiden_name")}
            value={data.maidenName || ""}
            onChange={(e) => handleChange("maidenName", e.target.value)}
            className="mb-4"
          />
        </div>
        <div className="col-12 px-0">
          <div className="row col-12 px-0 pb-4 align-items-center inline-custom-form">
            <label
              className={`${
                screenSize === "mobile" ? "col-12 " : ""
              } font-weight-bold`}
            >
              {i18n.t("parent_account")}
            </label>
            <Autocomplete
              className={screenSize === "mobile" ? "col-12" : ""}
              options={formattedCustomers}
              value={parentAccount}
              getOptionLabel={(option) => option.name}
              onChange={(e, value) => {
                setParentAccount(value)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={i18n.t("parent_account")}
                />
              )}
            />
          </div>
          <div className="row col-12 px-0 pb-4 align-items-center inline-custom-form">
            <label
              className={`${
                screenSize === "mobile" ? "col-12 " : ""
              } font-weight-bold`}
            >
              {i18n.t("referenced_by")}
            </label>
            <div className={screenSize === "mobile" ? "col-12" : ""}>
              <SelectValidator
                label={i18n.t("referenced_by")}
                onChange={(e) => handleChange("referencedBy", e.target.value)}
                value={data.referencedBy || ""}
                select
                variant="outlined"
              >
                {customerReferences.map((customerReference) => {
                  return (
                    <MenuItem value={customerReference._id}>
                      {customerReference.name}
                    </MenuItem>
                  )
                })}
              </SelectValidator>
            </div>
          </div>
          <div className="row col-12 px-0 pb-4 align-items-center inline-custom-form">
            <label
              className={`${
                screenSize === "mobile" ? "col-12 " : ""
              } font-weight-bold`}
            >
              {i18n.t("preferred_communication_method")}
            </label>
            <div className={screenSize === "mobile" ? "col-12" : ""}>
              <SelectValidator
                className="mb-4"
                onChange={(e) =>
                  handleChange("preferredCommMethod", e.target.value)
                }
                value={data.preferredCommMethod || "Email"}
                select
                variant="outlined"
              >
                <MenuItem value="Email">{i18n.t("Email")}</MenuItem>
                <MenuItem value="Phone">{i18n.t("phone")}</MenuItem>
              </SelectValidator>
            </div>
          </div>
          <div className="row col-12 px-0 pb-4 align-items-center inline-custom-form">
            <label
              className={`${
                screenSize === "mobile" ? "col-12 " : ""
              } font-weight-bold`}
            >
              {i18n.t("age_group")}
            </label>
            <div className={screenSize === "mobile" ? "col-12" : ""}>
              <SelectValidator
                label={i18n.t("age_group")}
                onChange={(e) => handleChange("ageGroupId", e.target.value)}
                value={data.ageGroupId || ""}
                select
                variant="outlined"
              >
                {ageGroups.map((element) => {
                  return (
                    <MenuItem value={element._id} key={element._id}>
                      {element.name}
                    </MenuItem>
                  )
                })}
              </SelectValidator>
            </div>
          </div>
          <div className="row col-12 px-0 pb-4 align-items-center inline-custom-form">
            <label
              className={`${
                screenSize === "mobile" ? "col-12 " : ""
              } font-weight-bold`}
            >
              {i18n.t("origin")}
            </label>
            <div className={screenSize === "mobile" ? "col-12" : ""}>
              <SelectValidator
                label={i18n.t("origin")}
                onChange={(e) => handleChange("originId", e.target.value)}
                value={data.originId || ""}
                select
                variant="outlined"
              >
                {origins.map((element) => {
                  return (
                    <MenuItem value={element._id} key={element._id}>
                      {element.name}
                    </MenuItem>
                  )
                })}
              </SelectValidator>
            </div>
          </div>
          <div className="row col-12 px-0 pb-4 align-items-center inline-custom-form">
            <label
              className={`${
                screenSize === "mobile" ? "col-12 " : ""
              } font-weight-bold`}
            >
              {i18n.t("district")}
            </label>
            <div className={screenSize === "mobile" ? "col-12" : ""}>
              <SelectValidator
                label={i18n.t("district")}
                onChange={(e) => handleChange("districtId", e.target.value)}
                value={data.districtId || ""}
                select
                variant="outlined"
              >
                {districts.map((element) => {
                  return (
                    <MenuItem value={element._id} key={element._id}>
                      {element.name}
                    </MenuItem>
                  )
                })}
              </SelectValidator>
            </div>
          </div>
          <div className="row col-12 px-0 pb-4 align-items-center inline-custom-form">
            <label
              className={`${
                screenSize === "mobile" ? "col-12 " : ""
              } font-weight-bold`}
            >
              {i18n.t("status")}
            </label>
            <div className={screenSize === "mobile" ? "col-12" : ""}>
              <SelectValidator
                label={i18n.t("status")}
                onChange={(e) => handleChange("statusId", e.target.value)}
                value={data.statusId || ""}
                select
                variant="outlined"
              >
                {statuses.map((element) => {
                  return (
                    <MenuItem value={element._id} key={element._id}>
                      {element.name}
                    </MenuItem>
                  )
                })}
              </SelectValidator>
            </div>
          </div>
        </div>

        <div className="my-4 col-12 p-0">
          <PrimaryButton
            className="w-128"
            props={{
              type: "submit",
            }}
            label={data._id ? i18n.t("save") : i18n.t("create")}
          />
        </div>
      </ValidatorForm>
    </div>
  )
}
