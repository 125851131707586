import React, { useState, useEffect, useMemo } from "react"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import useActiveMenuType from "../../../context/ActiveMenuType"
import i18n from "../../../i18n"
import { getNestedValue } from "../../../util/objectMethods"
import { AmnouncementModal } from "../../Announcements"
import { request } from "../../../redux/actions/Announcements"

export function AppUpdateAnnouncement({ children, open, setOpen }) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(request())
  }, [])
  const { type } = useActiveMenuType()
  const announcements = useSelector((s) => s.announcements.data)
  const latestAnnouncement = useMemo(() => {
    if (!announcements.length) {
      return null
    }
    const language = getNestedValue(i18n, "language", "-en").split("-")[0]
    const title = getNestedValue(announcements, [0, "header", language], "")
    let content = ""
    if (type == "salon") {
      content = getNestedValue(
        announcements,
        [0, "clientMessage", language],
        ""
      )
      if (!content) {
        content = getNestedValue(
          announcements,
          [0, "employeeMessage", language],
          ""
        )
      }
    } else if (type == "employee") {
      content = getNestedValue(
        announcements,
        [0, "employeeMessage", language],
        ""
      )
    }
    if (!content) {
      return null
    }
    return { title, content, version: announcements[0].version }
  }, [type, announcements])
  const lastViewAppVersion =
    localStorage.getItem("last_view_app_version") || "0.0.1"
  useEffect(() => {
    if (
      type &&
      latestAnnouncement &&
      lastViewAppVersion &&
      latestAnnouncement.version != lastViewAppVersion
    ) {
      console.log("here")
      // setVersionNotesOpen(true);
    }
  }, [type, latestAnnouncement, lastViewAppVersion])

  return (
    <>
      {children}

      <AmnouncementModal
        isModalOpen={open}
        setModalOpen={(isOpen) => {
          console.log({ isOpen })
          if (!isOpen) {
            localStorage.setItem(
              "last_view_app_version",
              latestAnnouncement.version
            )
          }
          setOpen(isOpen)
        }}
        title={latestAnnouncement?.title}
        content={latestAnnouncement?.content}
      />
    </>
  )
}

export default AppUpdateAnnouncement
