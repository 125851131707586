import moment from "moment"
import React, { useEffect } from "react"
import BasicTable from "../../../components/Core/BasicTable"
import PrimaryButton from "../../../components/Core/PrimaryButton"
import useStaffMeeting from "../../../context/StaffMeeting/StaffMeeting"
import deleteConfirmation from "../../../helpers/DeleteHelper"
import i18n from "../../../i18n"
import { Images } from "../../../theme"
import useScreenSize from "../../../context/ScreenSize"
export default function StaffMeeting() {
  const { setAddOpen, staffMeetings, get, setViewId, remove } =
    useStaffMeeting()
  useEffect(() => {
    get()
  }, [])

  const { screenSize } = useScreenSize()
  return (
    <div className="content-container p-4">
      <div
        style={
          screenSize === "mobile"
            ? {
                position: "sticky",
                top: 0,
                zIndex: 1,
                backgroundColor: "white",
              }
            : {}
        }
        className={`${
          screenSize === "mobile" ? "flex-column gap-2 pb-2" : ""
        } d-flex justify-content-between align-items-center mb-4`}
      >
        <h1 className="saloon-dashboard-heading pb-3">
          {i18n.t("staff_meetings")}
        </h1>
        <PrimaryButton
          className="w-128"
          label={i18n.t("create")}
          onClick={() => {
            setAddOpen(true)
          }}
        />
      </div>
      <BasicTable
        rows={staffMeetings}
        schema={[
          {
            header: i18n.t("date"),
            field: "date",
            accessor: ({ startDate }) => {
              return moment(startDate).format("MM-DD-YYYY")
            },
          },
          {
            header: i18n.t("time"),
            field: "time",
            accessor: ({ startTime }) => {
              return startTime
            },
          },
          {
            header: i18n.t("employees"),
            field: "employees",
            accessor: ({ bookings }) => {
              return bookings.map((booking) => booking.name).join(", ")
            },
          },
          {
            header: i18n.t("number_of_employees"),
            field: "numberOfEmployees",
            accessor: ({ bookings }) => {
              return bookings.length
            },
          },
          {
            key: "action",
            header: i18n.t("actions"),
            style: { width: 160 },
            render: (val) => {
              return (
                <div className="row gap-2 flex-nowrap">
                  <PrimaryButton
                    className="w-34"
                    onClick={() => setViewId(val._id)}
                  >
                    <img src={Images.table_view_icon} alt="view" width="18" />
                  </PrimaryButton>
                  <PrimaryButton
                    className="w-34"
                    onClick={() =>
                      deleteConfirmation(() => {
                        remove(val._id)
                      })
                    }
                  >
                    <img
                      src={Images.table_delete_icon}
                      alt="delete"
                      width="18"
                    />
                  </PrimaryButton>
                </div>
              )
            },
          },
        ]}
      />
    </div>
  )
}
