import { connect } from "react-redux"
import React, { useEffect, useState } from "react"
import PayrollReportTable from "../../../components/Saloon/PayrollReport/PayrollReportTable.component"
import i18n from "../../../i18n"
import PrimaryButton from "../../../components/Core/PrimaryButton"
import { BaseModal, DotsLoader } from "../../../components"
import { SuccessHelper } from "../../../helpers"
import {
  getPayrollReport,
  adjustWorkingHours,
  addWorkingHours,
} from "../../../config/simpleApiCalls"
import DatePicker from "react-datepicker"
import moment from "moment"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import SecondaryButton from "../../../components/Core/SecondaryButton/SecondaryButton"
import "./styles.css"
import useCache from "../../../context/LocalCache"
import { exportTableToPDF } from "../../../util/exportToPDF"
import useScreenSize from "../../../context/ScreenSize"
const PayrollReport = () => {
  const { getResource } = useCache()
  const allEmployees = getResource((cache) => cache.company.employees)
    .filter(({ isActive }) => isActive)
    .map((employee) => ({
      value: employee._id,
      label: employee.employee.userId.firstName,
    }))

  const [selectedEmployee, setSelectedEmployee] = useState("")
  const [loading, setLoading] = useState(false)
  const [startDate, setStartDate] = useState(
    moment(moment.now()).subtract(7, "d").toDate()
  )
  const [endDate, setEndDate] = useState(moment(moment.now()).toDate())
  const [adjustedMap, setAdjustedMap] = useState({})
  const [toAddMap, setToAddMap] = useState({})
  const [employeeDataMap, setEmployeeDataMap] = useState({})
  const [employeeIds, setEmployeeIds] = useState([])
  const [employeeInfos, setEmployeeInfos] = useState([])
  const { screenSize } = useScreenSize()
  const [openFiltersModal, setOpenFiltersModal] = useState(false)
  const [openOptionsModal, setOpenOptionsModal] = useState(false)

  const getReport = async (exportToXlsx) => {
    setLoading(true)
    setToAddMap({})
    setAdjustedMap({})
    const copyStartDate = moment(startDate)
    const range = []
    while (!copyStartDate.isAfter(endDate)) {
      range.push(copyStartDate.format("MM-DD-YYYY"))
      copyStartDate.add(1, "d")
    }
    const employeeDataMap = {}
    allEmployees.map((employee) => {
      employeeDataMap[employee.value] = {}
      range.map((date) => {
        employeeDataMap[employee.value][date] = {
          details: [],
          date: `${date}`,
          totalWorkMinutes: 0,
        }
      })
    })

    return await getPayrollReport({
      from: startDate,
      to: endDate,
      exportToXlsx,
    }).then(({ data = {} }) => {
      if (data.success) {
        data.data.map((element) => {
          const {
            _id: { employeeId },
            entries,
          } = element
          entries.map((element) => {
            const { checkIns, checkOuts, createdDate } = element
            const date = moment(createdDate).format("MM-DD-YYYY")
            if (!employeeDataMap[employeeId]) {
              employeeDataMap[employeeId] = {}
            }
            employeeDataMap[employeeId][date] = {
              details: checkIns.map((checkIn, index) => [
                checkIn,
                checkOuts[index],
              ]),
              date,
              ...element,
            }
          })
        })

        setEmployeeDataMap(employeeDataMap)
      }
      setLoading(false)
    })
  }

  useEffect(async () => {
    setLoading(true)
    await getReport()
    setLoading(false)
  }, [])

  const setSelectedValue = (val) => {
    let currentDate = moment(Date.now())
    if (val == 1) {
      let day = currentDate.day()
      const convertedStart = moment(currentDate).subtract(day, "days")
      const convertedEnd = moment(convertedStart).add(6, "days")
      setStartDate(convertedStart.toDate())
      setEndDate(convertedEnd.toDate())
    } else if (val == 2) {
      const startOfMonth = moment(currentDate).startOf("month")
      const endOfMonth = moment(currentDate).endOf("month")
      setStartDate(startOfMonth.toDate())
      setEndDate(endOfMonth.toDate())
    } else if (val == 3) {
      const convertedStart = moment(`01-01-${currentDate.year()}`, "MM-DD-YYYY")
      const convertedEnd = moment(`12-31-${currentDate.year()}`, "MM-DD-YYYY")
      setStartDate(convertedStart.toDate())
      setEndDate(convertedEnd.toDate())
    }
  }

  const onAdjust = (employeeId, overriddenTotalWorkMinutes, row, index) => {
    if (row._id) {
      adjustedMap[row._id] = overriddenTotalWorkMinutes
      setAdjustedMap(adjustedMap)
    } else {
      if (!toAddMap[employeeId]) {
        toAddMap[employeeId] = {}
      }
      toAddMap[employeeId][row.date] = {
        ...row,
        overriddenTotalWorkMinutes,
        totalWorkMinutes: overriddenTotalWorkMinutes,
      }
      setToAddMap(toAddMap)
    }
    const copy = { ...employeeDataMap }
    copy[employeeId][row.date] = {
      ...row,
      overriddenTotalWorkMinutes,
      totalWorkMinutes: overriddenTotalWorkMinutes,
    }
    setEmployeeDataMap(copy)
  }

  const onSave = () => {
    const toAdjust = { ...adjustedMap }
    setLoading(true)
    const addPromises = []
    Object.keys(toAddMap).map((employeeId) => {
      Object.keys(toAddMap[employeeId]).map((currentDate) => {
        const { date, totalWorkMinutes } = toAddMap[employeeId][currentDate]
        addPromises.push(
          addWorkingHours({
            employeeId,
            totalWorkMinutes,
            createdDate: moment(date, "MM-DD-YYYY").startOf("d").toDate(),
          })
        )
      })
    })
    Promise.all([
      ...Object.keys(adjustedMap).map((workingDayId) => {
        adjustWorkingHours({
          _id: workingDayId,
          adjustment: adjustedMap[workingDayId],
        })
      }),
      ...addPromises,
    ])
      .then(() => {
        setAdjustedMap(toAdjust)
        setToAddMap(toAddMap)
        SuccessHelper.handleSuccess(i18n.t("successfully_updated"), true)
        getReport()
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    const formattedIds = Object.keys(employeeDataMap).map((employeeId) => {
      return `#employee-${employeeId}`
    })
    const names = allEmployees.map((employee) => {
      return {
        name: employee.label,
        totalHoursWorked: i18n.t("total_hours_worked", {
          totalHoursWorked: employeeTotalHoursMap[employee.value],
        }),
      }
    })
    if (!selectedEmployee) {
      setEmployeeIds(formattedIds)
      setEmployeeInfos(names)
    }
  }, [employeeDataMap, selectedEmployee])

  useEffect(() => {
    if (selectedEmployee) {
      setEmployeeIds([`#employee-${selectedEmployee}`])
    }
    allEmployees.map((employee) => {
      if (selectedEmployee == employee.value) {
        setEmployeeInfos([
          {
            name: employee.label,
            totalHoursWorked: i18n.t("total_hours_worked", {
              totalHoursWorked: employeeTotalHoursMap[employee.value],
            }),
          },
        ])
      }
    })
  }, [selectedEmployee])

  const onStartDateChange = (val) => {
    if (val > endDate) {
      setStartDate(endDate)
      setEndDate(val)
    } else {
      setStartDate(val)
    }
  }

  const onEndDateChange = (val) => {
    if (val < startDate) {
      setStartDate(val)
      setEndDate(startDate)
    } else {
      setEndDate(val)
    }
  }

  const employeeTotalHoursMap = {}
  Object.keys(employeeDataMap).map((employeeId) => {
    let totalMinutes = 0
    Object.keys(employeeDataMap[employeeId]).map((date) => {
      totalMinutes += employeeDataMap[employeeId][date].totalWorkMinutes
    })
    employeeTotalHoursMap[employeeId] =
      Math.round((totalMinutes / 60 + Number.EPSILON) * 100) / 100
  })
  const renderFilter = () => {
    const { screenSize } = useScreenSize()
    return (
      <div className="row col-12 px-0">
        <div
          className={`row px-0 mb-4 ${screenSize === "mobile" ? "col-12" : ""}`}
        >
          <div
            className={`w-100 d-flex flex-column flex-md-row align-items-md-center gap-2`}
          >
            <label className="font-weight-bolder">
              {i18n.t("container.employee")}
            </label>
            <div style={{ minWidth: 200 }}>
              <Select
                value={selectedEmployee}
                onChange={(e) => setSelectedEmployee(e.target.value)}
                variant="outlined"
                fullWidth
              >
                {allEmployees.map((employee) => (
                  <MenuItem value={employee.value}>{employee.label}</MenuItem>
                ))}
              </Select>
            </div>
            <PrimaryButton
              onClick={() => setSelectedEmployee()}
              className="w-98"
            >
              {i18n.t("filterReset")}
            </PrimaryButton>
          </div>
        </div>
        <div className="row px-0 mb-4">
          <div className="d-sm-flex align-items-center ml-sm-3 mb-3 gap-2">
            <label className="font-weight-bolder">{i18n.t("date_range")}</label>
            <div className="d-flex gap-2">
              <DatePicker
                className="form-control saloon-form-input"
                placeholderText="Select Start Date"
                selected={startDate}
                onChange={onStartDateChange}
                dateFormat="MM/dd/yyyy"
                variant="outlined"
              />
              <DatePicker
                className="form-control saloon-form-input"
                placeholderText="Select End Date"
                selected={endDate}
                onChange={onEndDateChange}
                dateFormat="MM/dd/yyyy"
              />
            </div>
          </div>
          <div className="row align-items-center px-0 mx-0 ml-sm-3 gap-2">
            <label className="font-weight-bolder">
              {i18n.t("container.quick_filter")}
            </label>
            <div className="d-flex px-0 gap-2 flex-wrap">
              <SecondaryButton onClick={() => setSelectedValue(1)}>
                {i18n.t("container.week")}
              </SecondaryButton>
              <SecondaryButton onClick={() => setSelectedValue(2)}>
                {i18n.t("container.month")}
              </SecondaryButton>
              <SecondaryButton onClick={() => setSelectedValue(3)}>
                {i18n.t("container.year")}
              </SecondaryButton>
              <PrimaryButton className="w-98" onClick={() => getReport()}>
                {i18n.t("search")}
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    )
  }
  const renderButtons = () => {
    const { screenSize } = useScreenSize()
    return (
      <div className="d-flex flex-column flex-md-row gap-2">
        {Object.keys(adjustedMap).length || Object.keys(toAddMap).length ? (
          <PrimaryButton label={i18n.t("save_adjustments")} onClick={onSave} />
        ) : null}
        <PrimaryButton
          className={screenSize === "mobile" ? "" : "w-128"}
          onClick={() =>
            exportTableToPDF(
              employeeIds,
              i18n.t("payroll_report"),
              employeeInfos,
              false,
              "portrait"
            )
          }
        >
          {i18n.t("export_to_pdf")}
        </PrimaryButton>
        <PrimaryButton
          className={screenSize === "mobile" ? "" : "w-128"}
          onClick={() => getReport(true)}
        >
          {i18n.t("export_to_excel")}
        </PrimaryButton>
      </div>
    )
  }
  return (
    <div className="content-container p-4">
      <DotsLoader isloading={loading} />
      <div
        style={
          screenSize === "mobile"
            ? {
                position: "sticky",
                top: 0,
                zIndex: 1,
                backgroundColor: "white",
                padding: "1rem 0",
              }
            : null
        }
        className="col-12 px-0 d-flex flex-column flex-md-row gap-2 align-items-center justify-content-between mb-4"
      >
        <span className="saloon-dashboard-heading pt-0">
          {i18n.t("payroll_report")}
        </span>
        {screenSize !== "mobile" ? (
          <>{renderButtons()}</>
        ) : (
          <div className="d-flex w-100 justify-content-center gap-2">
            <PrimaryButton
              className="w-128"
              onClick={() => setOpenFiltersModal(true)}
            >
              {i18n.t("filters")}
            </PrimaryButton>
            <PrimaryButton
              className="w-128"
              onClick={() => setOpenOptionsModal(true)}
            >
              Options
            </PrimaryButton>
          </div>
        )}
      </div>

      {screenSize !== "mobile" ? <>{renderFilter()}</> : null}

      <div>
        {allEmployees.map((employee) => {
          if (employeeDataMap[employee.value]) {
            if (selectedEmployee) {
              if (employee.value == selectedEmployee) {
                return (
                  <div className="mb-1 mt-3">
                    <div className="row mx-0 px-0 justify-content-between align-items-center">
                      <h4>{employee.label}</h4>
                      <div>
                        {i18n.t("total_hours_worked", {
                          totalHoursWorked:
                            employeeTotalHoursMap[employee.value],
                        })}
                      </div>
                    </div>

                    <PayrollReportTable
                      data={Object.values(employeeDataMap[employee.value])}
                      id={selectedEmployee}
                      onAdjust={(...params) =>
                        onAdjust(employee.value, ...params)
                      }
                    />
                  </div>
                )
              }
            } else {
              return (
                <div className="mb-1 mt-3">
                  <div className="row mx-0 px-0 justify-content-between align-items-center">
                    <h4>{employee.label}</h4>
                    <div>
                      {i18n.t("total_hours_worked", {
                        totalHoursWorked: employeeTotalHoursMap[employee.value],
                      })}
                    </div>
                  </div>
                  <PayrollReportTable
                    data={Object.values(employeeDataMap[employee.value])}
                    id={employee.value}
                    onAdjust={(...params) =>
                      onAdjust(employee.value, ...params)
                    }
                  />
                </div>
              )
            }
          }
        })}
      </div>
      <BaseModal
        open={openFiltersModal}
        contentClassName="overflow-visible"
        onClose={() => setOpenFiltersModal(false)}
        title={i18n.t("filters")}
        content={<>{renderFilter()}</>}
      />
      <BaseModal
        open={openOptionsModal}
        onClose={() => setOpenOptionsModal(false)}
        title="Options"
        content={<>{renderButtons()}</>}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({})

const action = {}

export default connect(mapStateToProps, action)(PayrollReport)
