import { connect } from "react-redux"
import React, { Component } from "react"
import { Translation } from "react-i18next"
import i18n from "../../../i18n"
import {
  getUser,
  editUser,
  getSaloonById,
  editCompany,
  userCreateProfilePicture,
  editCompanyLogo,
  editCompanyUser,
} from "../../../config/simpleApiCalls"
import { DotsLoader } from "../../../components"
import { Images } from "../../../theme"
import { SuccessHelper, ErrorHelper } from "../../../helpers"
import NumberFormat from "react-number-format"
import { set } from "../../../redux/actions/User"

import "./styles.css"
import TextField from "@material-ui/core/TextField"
import PhoneNumberFormat from "../../../containers/phoneNumberFormat/PhoneNumberFormat.component"
import PlacesAutocomplete from "../../../components/PlacesAutocomplete"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import { ActiveMenuTypeContext } from "../../../context/ActiveMenuType"
import SharedStorage from "../../../helpers/Storage"
import { Capacitor } from "@capacitor/core"
import CalendarSelect from "../../../components/Core/Select/index"
import PrimaryButton from "../../../components/Core/PrimaryButton"
class EditProfile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // Personal Information
      firstName: "",
      lastName: "",
      password: "",
      address: "",
      city: "",
      province: "",
      postalCode: "",
      telephone: "",
      functionRole: "",
      email: "",

      //Personal Information Error
      firstNameError: "",
      lastNameError: "",
      addressError: "",
      cityError: "",
      provinceError: "",
      postalCodeError: "",
      telephoneError: "",
      emailError: "",

      //User Data
      userData: [],

      companyId: "",

      // Business Information
      companyLogoUrl: null,
      companyLogoFile: null,
      companyName: "",
      noOfEmployer: "",
      companyAddress: "",
      companyTagLine: "",
      companyShortDescription: "",
      companyCity: "",
      companyProvince: "",
      companyPostalCode: "",
      // companyTaxId: "",
      companyEmail: "",
      companyTelephone: "",
      // companyCertificate: "",
      timeSlotLength: 15,

      // General State
      formTypeId: 0,
      formTypeText: "Personal Information",
      isloading: false,
      imageUrl: "",
      addressMap: {},
      calViews: ["day", "work week", "week", "month"],
      selectedCalView: "day",
      employee: {},
    }
  }

  async componentDidMount() {
    const companyData = await JSON.parse(await SharedStorage.getItem("company"))

    this.setState({
      selectedCalView:
        JSON.parse(await SharedStorage.getItem("employee"))?.selectedCalView ||
        "day",
      employee: JSON.parse(await SharedStorage.getItem("employee")),
    })
    if (companyData) {
      this.setState({ companyId: companyData._id }, () => {
        this.getAllData()
        // this.handleGetSaloonPaymentMethod();
      })
    }
  }

  getAllData = async () => {
    this.setState({ isloading: true })
    //Get User Data
    Promise.all([
      getUser()
        .then((res) => {
          if (res.data.success) {
            this.setState({
              isloading: false,
              firstName: res.data.data.user.firstName,
              lastName: res.data.data.user.lastName,
              address: res.data.data.user.address,
              city: res.data.data.user.city,
              province: res.data.data.user.province,
              postalCode: res.data.data.user.postalCode,
              telephone: res.data.data.user.phoneNo,
              email: res.data.data.user.email,
              imageUrl: res.data.data.user.profile_img,
            })
          }
        })
        .catch((error) => {
          this.setState({ isloading: false })

          if (error.response) {
            alert(error.response.data.msg)

            // Request made and server responded
            console.log(error.response.data)
            console.log(error.response.status)
            console.log(error.response.headers)
          } else if (error.request) {
            alert("Something Went Wrong")

            // The request was made but no response was received
            console.log(error.request)
          } else {
            alert(error.msg)
            // Something happened in setting up the request that triggered an Error
            console.log(error.msg)
          }
        }),

      //Get Company Data
      getSaloonById().then((res) => {
        if (res.data.success) {
          this.props.set({
            company: JSON.stringify(res.data.data),
          })
          const isGym = res.data.data.function == "gym"
          const hours = parseInt((res.data.data.blockLength || 0) / 60)
          const minutes = (res.data.data.blockLength || 0) % 60
          this.setState({
            isGym,
            isloading: false,
            companyName: res.data.data.name,
            numberOfConcurrentBookings:
              res.data.data.numberOfConcurrentBookings,
            blockLength: res.data.data.blockLength,
            hours,
            minutes,
            companyAddress: res.data.data.address,
            companyLogoUrl: res.data.data.companyLogo,
            companyTagLine: res.data.data.companyTagLine,
            companyShortDescription: res.data.data.companyShortDescription,
            companyCity: res.data.data.city,
            companyProvince: res.data.data.province,
            companyPostalCode: res.data.data.postalCode,
            companyCountry: res.data.data.country,
            // companyTaxId: res.data.data.businessTaxId,
            companyEmail: res.data.data.businessEmail,
            companyTelephone: res.data.data.businessTelephone,
            // companyCertificate: res.data.data.businessCertificate,
            timeSlotLength: res.data.data.timeSlotLength || 15,
            function: res.data.data.function,
          })
        }
      }),
    ]).then(() => {
      this.setState({ formTypeId: 1 })
    })
  }

  editUserProfile = () => {
    this.setState({ isloading: true })
    const {
      firstName,
      lastName,
      city,
      address,
      province,
      telephone,
      postalCode,
      email,
      imageUrl,
      imageFile,
      selectedCalView,
      employee,
    } = this.state

    var bodyFormData = {
      firstName,
      lastName,
      userName: firstName + " " + lastName,
      city,
      address,
      province,
      phoneNo: telephone,
      postalCode,
      selectedCalView,
    }
    if (employee.role.includes("3")) {
      editCompanyUser(bodyFormData).then(
        (res) => {
          if (res.data.success) {
            this.setState({
              isloading: false,
              formTypeId: 2,
              formTypeText: "Business Info",
            })
            employee.selectedCalView = selectedCalView
            SharedStorage.setItem("employee", JSON.stringify(employee))
            SuccessHelper.handleSuccess(i18n.t("successfully_saved"), true)
          }
        },
        (error) => {
          this.setState({
            isloading: false,
          })
          ErrorHelper.handleErrors(i18n.t("someting_went_wrong"), true)
        }
      )
    } else {
      editUser(bodyFormData).then(
        (res) => {
          if (res.data.success) {
            this.setState({
              isloading: false,
            })
            employee.selectedCalView = selectedCalView
            SharedStorage.setItem("employee", JSON.stringify(employee))
            SuccessHelper.handleSuccess(i18n.t("successfully_saved"), true)
          }
        },
        (error) => {
          this.setState({
            isloading: false,
          })
          ErrorHelper.handleErrors(i18n.t("someting_went_wrong"), true)
        }
      )
    }
  }

  editCompanyProfile = () => {
    this.setState({ isloading: true })
    const {
      companyName,
      noOfEmployer,
      companyAddress,
      companyTagLine,
      companyShortDescription,
      companyCity,
      companyProvince,
      companyPostalCode,
      // companyTaxId,
      companyEmail,
      companyTelephone,
      // companyCertificate,
      numberOfConcurrentBookings,
      blockLength,
      timeSlotLength,
    } = this.state

    const payload = {
      name: companyName,
      address: companyAddress,
      companyTagLine,
      companyShortDescription,
      city: companyCity,
      province: companyProvince,
      postalCode: companyPostalCode,
      // businessTaxId: companyTaxId,
      businessTelephone: companyTelephone,
      // businessCertificate: companyCertificate,
      latitude: "24.22323",
      longitude: "67.23123123",
      numberOfConcurrentBookings,
      blockLength,
      isActive: 0,
      timeSlotLength: parseInt(timeSlotLength),
    }

    editCompany(payload)
      .then((res) => {
        if (res.data.success) {
          this.setState({
            isloading: false,
            formTypeId: 1,
            formTypeText: "Personal Information",
          })
          SuccessHelper.handleSuccess(i18n.t("successfully_saved"), true)
        }
      })
      .catch((error) => {
        ErrorHelper.handleErrors(error, true)
        this.setState({ isloading: false })
        console.log(error, "editCompany error")
      })
  }

  handleNavigation = (route) => {
    this.props.history.push(route)
  }

  // Personal Information
  onChangeFirstName = (text) => this.setState({ firstName: text.target.value })
  onChangeLastName = (text) => this.setState({ lastName: text.target.value })
  onChangePassword = (text) => this.setState({ password: text.target.value })
  onChangeAddress = (text) => this.setState({ address: text.target.value })
  onChangeCity = (text) => this.setState({ city: text.target.value })
  onChangeProvince = (text) => this.setState({ province: text.target.value })
  onChangePostalCode = (text) =>
    this.setState({ postalCode: text.target.value })
  onChangeTelephone = (text) => this.setState({ telephone: text })
  onChangeCompanyTelephone = (text) => this.setState({ companyTelephone: text })

  onChangeFunctionRole = (text) =>
    this.setState({ functionRole: text.target.value })
  onChangeEmail = (text) => this.setState({ email: text.target.value })

  // Business Information
  onChangeCompanyName = (text) =>
    this.setState({ companyName: text.target.value })
  onChangeNumberOfConcurrentBookings = (text) =>
    this.setState({ numberOfConcurrentBookings: text.target.value })
  onChangeCompanyTagLine = (text) =>
    this.setState({ companyTagLine: text.target.value })
  onChangeCompanyShortDescription = (text) =>
    this.setState({ companyShortDescription: text.target.value })
  onChangeCompanyCity = (text) =>
    this.setState({ companyCity: text.target.value })
  onChangeCompanyProvince = (text) =>
    this.setState({ companyProvince: text.target.value })
  onChangeCompanyPostalCode = (text) =>
    this.setState({ companyPostalCode: text.target.value })
  onChangeCompanyEmail = (text) =>
    this.setState({ companyEmail: text.target.value })
  onChangeCompanyTelephone = (text) => {
    this.setState({ companyTelephone: text })
  }
  onChangeTimeSlotLength = (text) =>
    this.setState({ timeSlotLength: text.target.value })

  onChangeHour = (text) => {
    const hours = parseInt(text.target.value)
    const blockLength =
      parseInt(text.target.value * 60) + parseInt(this.state.minutes)
    this.setState({
      hours,
      blockLength,
    })
  }
  onChangeMinute = (text) => {
    const minutes = parseInt(text.target.value)
    const blockLength = parseInt(this.state.hours * 60) + parseInt(minutes)
    this.setState({
      minutes,
      blockLength,
    })
  }

  checkValidation = () => {
    const { formTypeId } = this.state
    if (formTypeId === 1) {
      this.validatePersonalInformation()
    } else if (formTypeId === 2) {
      this.validateBusinessInformation()
    }
  }

  validatePersonalInformation = () => {
    const {
      firstName,
      lastName,
      address,
      city,
      province,
      postalCode,
      telephone,
      email,
      addressMap,
    } = this.state
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const nameRegex =
      /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{2,}$/u
    const telephoneRegex =
      /^\s*(?:\+?(\d{1,3}))?[- (]*(\d{3})[- )]*(\d{3})[- ]*(\d{4})(?: *[x/#]{1}(\d+))?\s*$/

    if (!firstName) {
      this.setState({
        firstNameError: i18n.t("first_name_required"),
      })
      setTimeout(() => {
        this.setState({
          firstNameError: "",
        })
      }, 6000)
    } else if (!lastName) {
      this.setState({
        lastNameError: i18n.t("last_name_required"),
      })
      setTimeout(() => {
        this.setState({
          lastNameError: "",
        })
      }, 6000)
    } else if (!telephone) {
      this.setState({
        telephoneError: i18n.t("telephone_required"),
      })
      setTimeout(() => {
        this.setState({
          telephoneError: "",
        })
      }, 6000)
    } else if (!telephone.match(telephoneRegex)) {
      this.setState({
        telephoneError: i18n.t("invalid_phone_number"),
      })

      setTimeout(() => {
        this.setState({
          telephoneError: "",
        })
      }, 6000)
    } else if (!email) {
      this.setState({
        emailError: i18n.t("email_required"),
      })
      setTimeout(() => {
        this.setState({
          emailError: "",
        })
      }, 6000)
    } else if (!email.match(emailRegex)) {
      this.setState({
        emailError: i18n.t("invalid_email"),
      })
      setTimeout(() => {
        this.setState({
          emailError: "",
        })
      }, 6000)
    } else if (!address) {
      this.setState({
        addressError: i18n.t("address_required"),
      })
      setTimeout(() => {
        this.setState({
          addressError: "",
        })
      }, 6000)
    } else if (!city) {
      this.setState({
        cityError: i18n.t("city_required"),
      })
      setTimeout(() => {
        this.setState({
          cityError: "",
        })
      }, 6000)
    } else if (!province) {
      this.setState({
        provinceError: i18n.t("province_required"),
      })
      setTimeout(() => {
        this.setState({
          provinceError: "",
        })
      }, 6000)
    } else if (!postalCode) {
      this.setState({
        postalCodeError: i18n.t("postal_code_required"),
      })
      setTimeout(() => {
        this.setState({
          postalCodeError: "",
        })
      }, 6000)
    } else {
      this.editUserProfile()
    }
  }

  validateBusinessInformation = () => {
    const {
      companyName,
      noOfEmployer,
      companyAddress,
      companyTagLine,
      companyShortDescription,
      companyCity,
      companyProvince,
      companyPostalCode,
      // companyTaxId,
      companyEmail,
      companyTelephone,
      // companyCertificate,
      numberOfConcurrentBookings,
      blockLength,
      isGym,
    } = this.state
    const nameRegex =
      /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{2,}$/u
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const telephoneRegex = /[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]/g

    if (!companyName) {
      this.setState({
        companyNameError: (
          <Translation>
            {(t) => t("container.company_name_required")}
          </Translation>
        ),
      })
      setTimeout(() => {
        this.setState({
          companyNameError: "",
        })
      }, 6000)
    } else if (isGym && !numberOfConcurrentBookings) {
      this.setState({
        numberOfConcurrentBookingsError: i18n.t("greater_than_0"),
      })
      setTimeout(() => {
        this.setState({
          numberOfConcurrentBookingsError: "",
        })
      }, 6000)
    } else if (isGym && blockLength < 1) {
      this.setState({
        blockLengthError: "Must be atleast 15 minutes.",
      })
      setTimeout(() => {
        this.setState({
          blockLengthError: "",
        })
      }, 6000)
    } else if (!companyAddress) {
      this.setState({
        companyAddressError: (
          <Translation>
            {(t) => t("container.company_address_required")}
          </Translation>
        ),
      })
      setTimeout(() => {
        this.setState({
          companyAddressError: "",
        })
      }, 6000)
    } else if (!companyTagLine) {
      this.setState({
        companyTagLineError: (
          <Translation>
            {(t) => t("container.company_tag_line_required")}
          </Translation>
        ),
      })
      setTimeout(() => {
        this.setState({
          companyTagLineError: "",
        })
      }, 6000)
    } else if (companyTagLine.length < 0) {
      this.setState({
        companyTagLineError: "You must add a tag line description",
      })
      setTimeout(() => {
        this.setState({
          companyTagLineError: "",
        })
      }, 6000)
    } else if (!companyShortDescription) {
      this.setState({
        companyShortDescriptionError: (
          <Translation>
            {(t) => t("container.company_short_description_required")}
          </Translation>
        ),
      })
      setTimeout(() => {
        this.setState({
          companyShortDescriptionError: "",
        })
      }, 6000)
    } else if (companyShortDescription.length < 0) {
      this.setState({
        companyShortDescriptionError:
          "You must provide the company description",
      })
      setTimeout(() => {
        this.setState({
          companyShortDescriptionError: "",
        })
      }, 6000)
    } else if (!companyCity) {
      this.setState({
        companyCityError: (
          <Translation>{(t) => t("city_required")}</Translation>
        ),
      })
      setTimeout(() => {
        this.setState({
          companyCityError: "",
        })
      }, 6000)
    } else if (!companyProvince) {
      this.setState({
        companyProvinceError: (
          <Translation>{(t) => t("province_required")}</Translation>
        ),
      })
      setTimeout(() => {
        this.setState({
          companyProvinceError: "",
        })
      }, 6000)
    } else if (!companyPostalCode) {
      this.setState({
        companyPostalCodeError: (
          <Translation>{(t) => t("postal_code_required")}</Translation>
        ),
      })
      setTimeout(() => {
        this.setState({
          companyPostalCodeError: "",
        })
      }, 6000)
    }
    // else if (!companyTaxId) {
    // this.setState({
    // companyTaxIdError: "Company tax id is required.",
    // });
    // setTimeout(() => {
    // this.setState({
    // companyTaxIdError: "",
    // });
    // }, 6000);
    // }
    else if (!companyEmail) {
      this.setState({
        companyEmailError: "Company email is required.",
      })
      setTimeout(() => {
        this.setState({
          companyEmailError: "",
        })
      }, 6000)
    } else if (!companyEmail.match(emailRegex)) {
      this.setState({
        companyEmailError: "Company email is invalid.",
      })
      setTimeout(() => {
        this.setState({
          companyEmailError: "",
        })
      }, 6000)
    } else if (!companyTelephone) {
      this.setState({
        companyTelephoneError: "Company telephone is required.",
      })
      setTimeout(() => {
        this.setState({
          companyTelephoneError: "",
        })
      }, 6000)
    } else if (!companyTelephone.match(telephoneRegex)) {
      this.setState({
        companyTelephoneError: (
          <Translation>{(t) => t("invalid_phone_number")}</Translation>
        ),
      })
      setTimeout(() => {
        this.setState({
          companyTelephoneError: "",
        })
      }, 6000)
    } else {
      this.editCompanyProfile()
    }
  }

  openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer")
    if (newWindow) newWindow.opener = null
  }

  handleEditSaloonPlan = () => {
    this.setState({
      isloading: false,
      formTypeId: 4,
      formTypeText: "Payment Method",
    })
  }

  fileSelectedHandler = (event, type) => {
    if (type === "profile") {
      const file = URL.createObjectURL(event.target.files[0])
      this.setState({ imageUrl: file, imageFile: event.target.files[0] })
    } else if (type === "company") {
      const file = URL.createObjectURL(event.target.files[0])
      this.setState({
        companyLogoUrl: file,
        companyLogoFile: event.target.files[0],
      })
    }
  }

  handleUploadNewPicture = () => {
    this.setState({ isloading: true })
    const { imageUrl, imageFile } = this.state
    let payloadFormdata = new FormData()
    payloadFormdata.append("profile_img", imageFile)
    userCreateProfilePicture(payloadFormdata)
      .then((res) => {
        if (res.data.success) {
          SuccessHelper.handleSuccess(
            "Your profile image has been successfully updated.",
            true
          )
          this.setState({ isloading: false, imageFile: null }, () =>
            this.getAllData()
          )
        }
      })
      .catch((error) => {
        console.log(error, "handleUploadNewPicture error")
      })
  }

  handleCompanyLogo = () => {
    this.setState({
      isloading: true,
    })
    const { companyLogoUrl, companyLogoFile, companyId } = this.state
    let payloadFormData = new FormData()
    payloadFormData.append("logo", companyLogoFile)
    payloadFormData.append("id", companyId)
    editCompanyLogo(payloadFormData)
      .then((res) => {
        if (res.data.success) {
          SuccessHelper.handleSuccess(
            "Your Company Logo has been successfully updated.",
            true
          )
          this.setState({ isloading: false, companyLogoFile: null }, () =>
            this.getAllData()
          )
        }
      })
      .catch((error) => {
        console.log(error, "editCompanyLogo error")
      })
  }

  renderSaloonDashboardHeading = () => {
    return (
      <div className="col-12 p-3">
        <h1 className="saloon-dashboard-heading">
          {i18n.t("container.employe_edit_profile")}
        </h1>
      </div>
    )
  }

  renderUploadPicture = () => {
    const { imageUrl, imageFile, formTypeId, companyLogoUrl, companyLogoFile } =
      this.state

    return (
      <div className="col-12 d-md-flex align-items-md-center text-center mt-4 mb-3">
        <input
          type="file"
          style={{ display: "none" }}
          onChange={(event) =>
            formTypeId == 1
              ? this.fileSelectedHandler(event, "profile")
              : this.fileSelectedHandler(event, "company")
          }
          ref={(fileInput) =>
            formTypeId == 1
              ? (this.fileInput = fileInput)
              : (this.companyLogoInput = fileInput)
          }
          accept="image/*"
        />
        <span
          onClick={() =>
            formTypeId == 1
              ? this.fileInput.click()
              : this.companyLogoInput.click()
          }
          style={{
            backgroundImage: `url(${
              formTypeId == 1
                ? imageUrl
                  ? imageUrl
                  : Images.add_profile
                : companyLogoUrl
                ? companyLogoUrl
                : Images.saloon_dashboard_wallet
            })`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: imageUrl && formTypeId == 1 ? "" : "30%",
          }}
          className="ml-md-4 ml-lg-4"
          id="saloon-edit-profile"
        />
        {(imageFile && formTypeId == 1) ||
        (companyLogoFile && formTypeId == 2) ? (
          <button
            type="button"
            className="btn btn-light btn-sm"
            id="saloon-upload-picture"
            onClick={() =>
              formTypeId == 1
                ? this.handleUploadNewPicture()
                : this.handleCompanyLogo()
            }
          >
            Upload New Picture
          </button>
        ) : null}
      </div>
    )
  }

  renderEditProfileCard = () => {
    const { formTypeId, employee } = this.state
    return (
      <div className="col-12 p-0">
        <div className="col-12 p-md-5 p-3 mb-3" id="saloon-edit-profile-card">
          {employee.role?.includes("3") && this.renderStepSectionLg()}
          {employee.role?.includes("3") && this.renderStepSectionSm()}
          {formTypeId === 1 && this.renderPersonalInformationForm()}
          {formTypeId === 2 && this.renderBusinessInformationForm()}
          <div className="row justify-content-center justify-content-md-start">
            <PrimaryButton
              onClick={() => this.checkValidation()}
              className="ml-2 w-128"
            >
              {i18n.t("save")}
            </PrimaryButton>
          </div>
        </div>
      </div>
    )
  }

  renderStepSectionLg = () => {
    const { formTypeId } = this.state
    return (
      <div className="col-12 p-4" id="edit-step-section-lg">
        <div className="row">
          <hr id="edit-steps-dots-line" />
          <div className="col-3 p-0 d-flex align-items-center">
            {(formTypeId === 1 ||
              formTypeId === 2 ||
              formTypeId === 3 ||
              formTypeId === 4) && (
              <img
                src={Images.check}
                className="step_done_tick"
                onClick={() => this.showFormPage()}
              />
            )}
            <span className="steps-text">
              <Translation>{(t) => t("container.personal_info")}</Translation>
            </span>
          </div>
          <div className="col-3 d-flex align-items-center">
            {(formTypeId === 2 || formTypeId === 3 || formTypeId === 4) && (
              <img
                src={Images.check}
                className="step_done_tick"
                onClick={() => this.showFormPage()}
              />
            )}
            <span className="steps-text">
              {i18n.t("container.business_info")}
            </span>
          </div>
          <div className="col-3 d-flex align-items-center">
            {(formTypeId === 3 || formTypeId === 4) && (
              <img
                src={Images.check}
                className="step_done_tick"
                onclick={() => this.showFormPage()}
              />
            )}
          </div>
        </div>
      </div>
    )
  }

  renderStepSectionSm = () => {
    const { formTypeId, formTypeText } = this.state
    return (
      <div className="col-12 p-3 py-md-4 mb-3 " id="edit-step-section-sm">
        <div className="row">
          <div className="col-12 p-0 d-flex align-items-center">
            <span className="step_no">{formTypeId}</span>
            <span className="steps-text-sm">{formTypeText}</span>
          </div>
        </div>
      </div>
    )
  }

  showFormPage = () => {
    const { formTypeId } = this.state
    return (
      <div className="col-12">
        <div className="col-12 p-md-5 p-3 mb-3" id="saloon-edit-profile-card">
          {this.renderStepSectionLg()}
          {this.renderStepSectionSm()}
          {formTypeId === 1 && this.renderPersonalInformationForm()}
          {formTypeId === 2 && this.renderBusinessInformationForm()}
          <div className="col-12">
            <img
              onClick={() => this.checkValidation()}
              src={Images.saloon_form_submit}
              id="edit-saloon-form-submit"
            />
          </div>
        </div>
      </div>
    )
  }

  renderInputCol6 = (
    id,
    lable,
    inputType,
    onChange,
    val,
    disabled,
    errorMessage,
    maxNumber,
    isNumeric
  ) => {
    return (
      <div className="row align-items-center gap-1 form-group col-md-12 px-2">
        <label className="font-weight-bold">{lable}</label>
        <TextField
          // type={inputType}
          // label={lable}
          className="editField"
          id={id}
          // maxLength={maxNumber}
          onChange={(text) => onChange(text)}
          value={val}
          // disabled={disabled}
          type={isNumeric ? "number" : ""}
          variant="outlined"
        />
        {errorMessage ? (
          <small className="form-text text-danger ml-3">{errorMessage}</small>
        ) : null}
      </div>
    )
  }

  renderAddress = (key) => {
    let addressElements = []
    if (key == "address") {
      const { address, city, country, postalCode, province } = this.state
      addressElements = [address, postalCode, city, province, country]
    } else {
      const {
        companyAddress,
        companyCity,
        companyCountry,
        companyPostalCode,
        companyProvince,
      } = this.state
      addressElements = [
        companyAddress,
        companyPostalCode,
        companyCity,
        companyProvince,
        companyCountry,
      ]
    }

    let address = addressElements.filter((element) => !!element).join(", ")
    return (
      <div class="w-35" style={{ width: "35%" }}>
        <PlacesAutocomplete
          defaultAddress={address}
          variant="outlined"
          addressSelected={(newMap) => {
            const addressMap = Object.assign(
              {},
              ...["address", "city", "country", "postalCode", "province"].map(
                (key) => ({
                  [key]: newMap[key],
                })
              )
            )
            this.setState({
              key: {
                addressMap,
              },
            })
          }}
        />
      </div>
    )
  }

  renderInputCol12 = (id, lable, inputType, onChange, val, errorMessage) => {
    return (
      <div className="row align-items-center gap-1 form-group col-md-12 p-2">
        <label className="font-weight-bold">{lable}</label>
        <TextField
          // label={lable}
          id={id}
          onChange={(text) => onChange(text)}
          value={val}
          type={inputType}
          // rows={3}
          // rowsMax={4}
          className="editField"
          variant="outlined"
        />

        {errorMessage ? (
          <small className="form-text text-danger ml-3">{errorMessage}</small>
        ) : null}
      </div>
    )
  }

  renderLoader = () => {
    const { isloading } = this.state
    return <DotsLoader isloading={isloading} />
  }
  inputTypeNumber = (
    id,
    lable,
    inputType,
    onChange,
    val,
    errorMessageStatus,
    errorMessage
  ) => {
    return (
      <div className="form-group col-md-6 px-0">
        <label htmlFor={id} className="saloon-form-lable">
          {lable}:
        </label>
        <NumberFormat
          type={inputType}
          className="form-control saloon-form-input"
          id={id}
          onChange={(text) => onChange(text)}
          value={val}
          format="### ### ####"
        />

        {errorMessage ? (
          <small className="form-text text-danger ml-3">{errorMessage}</small>
        ) : null}
      </div>
    )
  }
  renderPersonalInformationForm = () => {
    return (
      <div className="my-3 py-3">
        {this.renderInputCol6(
          "first-name",
          i18n.t("first_name"),
          "text",
          this.onChangeFirstName,
          this.state.firstName,
          false,
          this.state.firstNameError
        )}
        {this.renderInputCol6(
          "last-name",
          i18n.t("last_name"),
          "text",
          this.onChangeLastName,
          this.state.lastName,
          false,
          this.state.lastNameError
        )}
        <div className="row align-items-center gap-1 form-group px-2">
          <label className="font-weight-bold">{i18n.t("phone_number")}</label>
          <PhoneNumberFormat
            phoneUpdate={(e) => this.onChangeTelephone(e)}
            val={this.state.telephone}
            errorMessage={this.state.telephoneError}
            variant="outlined"
          />
        </div>

        {this.renderInputCol6(
          "email",
          <Translation>{(t) => t("container.profile_email")}</Translation>,
          "email",
          this.onChangeEmail,
          this.state.email,
          true,
          this.state.emailError
        )}
        {this.renderInputCol6(
          "address",
          <Translation>{(t) => t("container.address")}</Translation>,
          "text",
          this.renderAddress("address"),
          this.state.address,
          false,
          this.state.addressError
        )}

        {this.renderInputCol6(
          "city",
          <Translation>{(t) => t("container.city")}</Translation>,
          "text",
          this.onChangeCity,
          this.state.city,
          false,
          this.state.cityError
        )}
        {this.renderInputCol6(
          "province",
          <Translation>{(t) => t("container.province")}</Translation>,
          "text",
          this.onChangeProvince,
          this.state.province,
          false,
          this.state.provinceError
        )}

        {this.renderInputCol6(
          "postal-code",
          <Translation>{(t) => t("container.postal_code")}</Translation>,
          "text",
          this.onChangePostalCode,
          this.state.postalCode,
          false,
          this.state.postalCodeError
        )}
        <div className="row align-items-center gap-1 form-group px-2">
          <label className="font-weight-bold">{i18n.t("calendar_view")}</label>
          <CalendarSelect
            defaultValue={this.state.selectedCalView}
            variant="outlined"
            onChange={(value) => {
              this.state.selectedCalView = value.target.value
            }}
            excludeEmptyValue={true}
            options={this.state.calViews.map((view) => {
              return {
                name: i18n.t(view),
                value: view,
              }
            })}
            className="select-week"
          />
        </div>
      </div>
    )
  }

  renderBusinessInformationForm = () => {
    const { isGym } = this.state
    const { functionRole } = this.context
    return (
      <div className="my-3 py-3">
        {this.renderInputCol6(
          "company-name",
          i18n.t("container.company_name"),
          "text",
          this.onChangeCompanyName,
          this.state.companyName,
          false,
          this.state.companyNameError
        )}
        {this.renderInputCol6(
          "company-tag-line",
          i18n.t("container.company_tag_line"),
          "text",
          this.onChangeCompanyTagLine,
          this.state.companyTagLine,
          false,
          this.state.companyTagLineError,
          "250"
        )}
        {this.renderInputCol6(
          "company-address",
          i18n.t("container.address"),
          "text",
          this.onChangeAddress,
          this.state.address,
          false,
          this.state.addressError,
          "250"
        )}
        {this.renderInputCol12(
          "company-short-description",
          i18n.t("container.company_short_description"),
          "text",
          (text) => this.onChangeCompanyShortDescription(text),
          this.state.companyShortDescription
        )}

        {this.state.companyShortDescriptionError ? (
          <small className="form-text text-danger ml-3">
            {this.state.companyShortDescriptionError}
          </small>
        ) : null}
        {this.renderInputCol6(
          "city",
          <Translation>{(t) => t("container.city")}</Translation>,
          "text",
          this.onChangeCompanyCity,
          this.state.companyCity,
          false,
          this.state.companyCityError
        )}
        {this.renderInputCol6(
          "province",
          <Translation>{(t) => t("container.province")}</Translation>,
          "text",
          this.onChangeCompanyProvince,
          this.state.companyProvince,
          false,
          this.state.companyProvinceError
        )}
        {this.renderInputCol6(
          "postal-code",
          <Translation>{(t) => t("container.postal_code")}</Translation>,
          "text",
          this.onChangeCompanyPostalCode,
          this.state.companyPostalCode,
          false,
          this.state.companyPostalCodeError
        )}
        {this.renderInputCol6(
          "email",
          <Translation>{(t) => t("container.profile_email")}</Translation>,
          "email",
          this.onChangeCompanyEmail,
          this.state.companyEmail,
          true,
          this.state.companyEmailError
        )}
        <div className="align-items-center gap-1 form-group px-2 col-12">
          <label className="font-weight-bold">{i18n.t("phone_number")}</label>
          <PhoneNumberFormat
            phoneUpdate={(e) => this.onChangeCompanyTelephone(e)}
            val={this.state.companyTelephone}
            errorMessage={this.state.companyTelephoneError}
            variant="outlined"
          />
        </div>
        <div className="align-items-center gap-1 form-group px-2 col-12">
          <label className="font-weight-bold">
            {i18n.t("time_slot_length")}
          </label>
          <FormControl className="editField">
            <Select
              id={"timeSlotLength"}
              onChange={(text) => this.onChangeTimeSlotLength(text)}
              value={this.state.timeSlotLength}
              variant="outlined"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={15}>15</MenuItem>
            </Select>
          </FormControl>
        </div>
        {(functionRole == "gym" || functionRole == "garage") &&
          this.renderInputCol6(
            "no-of-concurrent-bookings",
            "No of Concurrent Bookings",
            "text",
            this.onChangeNumberOfConcurrentBookings,
            this.state.numberOfConcurrentBookings,
            false,
            this.state.numberOfConcurrentBookingsError,
            4,
            true
          )}
        {isGym && (
          <div className={`form-group col-12 px-0`}>
            <label htmlFor={"hours"} className="saloon-form-lable">
              Block Length
            </label>
            <div className="form-row">
              <div className={`col-12 px-1`}>
                <select
                  id={"hours"}
                  className="form-control saloon-edit-category-input"
                  onChange={(text) => this.onChangeHour(text)}
                  value={this.state.hours}
                >
                  <option className="form-group" value={0}>
                    Hours
                  </option>
                  <option className="form-group" value={0}>
                    0
                  </option>
                  <option className="form-group" value={1}>
                    1
                  </option>
                  <option className="form-group" value={2}>
                    2
                  </option>
                  <option className="form-group" value={3}>
                    3
                  </option>
                  <option className="form-group" value={4}>
                    4
                  </option>
                  <option className="form-group" value={5}>
                    5
                  </option>
                  <option className="form-group" value={6}>
                    6
                  </option>
                  <option className="form-group" value={7}>
                    7
                  </option>
                  <option className="form-group" value={8}>
                    8
                  </option>
                  <option className="form-group" value={9}>
                    9
                  </option>
                  <option className="form-group" value={10}>
                    10
                  </option>
                  <option className="form-group" value={11}>
                    11
                  </option>
                  <option className="form-group" value={12}>
                    12
                  </option>
                </select>
              </div>
              <div className={`col-6 px-1`}>
                <select
                  id={"minutes"}
                  className="form-control saloon-edit-category-input"
                  onChange={(text) => this.onChangeMinute(text)}
                  value={this.state.minutes}
                >
                  <option className="form-group" value={0}>
                    Minutes
                  </option>
                  <option className="form-group" value={0}>
                    0
                  </option>
                  <option className="form-group" value={15}>
                    15
                  </option>
                  <option className="form-group" value={30}>
                    30
                  </option>
                  <option className="form-group" value={45}>
                    45
                  </option>
                </select>
              </div>
            </div>
            {this.state.blockLengthError ? (
              <small className="form-text text-danger ml-3">
                {this.state.blockLengthError}
              </small>
            ) : null}
          </div>
        )}
      </div>
    )
  }

  render() {
    const { formTypeId } = this.state

    return (
      <div>
        {this.renderLoader()}
        <div className="content-container">
          <div className="row mx-0">
            <h2 className="font-weight-bold ml-lg-5 ml-md-5 mt-5 mx-auto">
              {formTypeId == 1 && i18n.t("container.personal_info")}
              {formTypeId == 2 && i18n.t("container.business_info")}
            </h2>
            {/* {this.renderSaloonDashboardHeadinng()} */}
            {Capacitor.platform == "web" && this.renderUploadPicture()}
            {this.renderEditProfileCard()}
          </div>
        </div>
      </div>
    )
  }
}

EditProfile.contextType = ActiveMenuTypeContext

const mapStateToProps = (state) => ({})

const action = { set }

export default connect(mapStateToProps, action)(EditProfile)
