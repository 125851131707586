import { activity as _activity } from "../../../config/WebServices"
import SagaBuilder from "../../builders/SagaBuilder"
import actions from "../../actions/Saloon/Activity"

const activity = SagaBuilder.build({
  type: "ACITVITY",
  endpoint: _activity,
  actions,
})

export default activity
