import { MenuItem } from "@material-ui/core"
import moment from "moment"
import React, { useRef } from "react"
import ReactDatePicker from "react-datepicker"
import {
  SelectValidator,
  TextValidator,
  ValidatorForm,
} from "react-material-ui-form-validator"
import { useDispatch, useSelector } from "react-redux"
import FormContainer from "../../../../../../components/Core/FormContainer/FormContainer"
import PrimaryButton from "../../../../../../components/Core/PrimaryButton"
import { ErrorHelper, SuccessHelper } from "../../../../../../helpers"
import i18n from "../../../../../../i18n"
import {
  updateRequest,
  createRequest,
} from "../../../../../../redux/actions/Saloon/CommunicationsMarketingCampaign"

export default function CampaignDetails({ data, setData, getOne }) {
  const dispatch = useDispatch()
  const templates = useSelector((s) => s.communicationsMarketingTemplate.data)
  const form = useRef()
  const onSubmit = (e) => {
    const dispatchFunction = data._id ? updateRequest : createRequest
    const successMessage = data._id
      ? "successfully_updated"
      : "successfully_added"
    const failureMessage = data._id ? "failed_to_update" : "failed_to_add"
    const sendOn = moment(
      `${moment(data.date).format("MM-DD-YYYY")} ${data.time}`,
      "MM-DD-YYYY HH:mm"
    )
    dispatch(
      dispatchFunction(
        { ...data, sendOn: sendOn.toDate(), campaignId: data._id },
        {
          success: ({ data: res }) => {
            setData({ ...data, ...res })
            getOne(data._id)
            SuccessHelper.handleSuccess(i18n.t(successMessage), true)
          },
          failure: () => {
            ErrorHelper.handleErrors(i18n.t(failureMessage), true)
          },
        }
      )
    )
  }
  const handleChange = (key, value) => {
    data[key] = value
    setData({ ...data })
  }
  const readOnly = data.status == "Complete"
  return (
    <ValidatorForm onSubmit={onSubmit} ref={form} className="w-100">
      <FormContainer>
        <div className="col-12 flex-column flex-md-row px-0 d-flex align-items-md-center mb-3">
          <label className="font-weight-bold" style={{ minWidth: "100px" }}>
            {i18n.t("name")}
          </label>
          <div className="w-100">
            <TextValidator
              label={i18n.t("name")}
              onChange={(e) => handleChange("name", e.target.value)}
              value={data.name || ""}
              validators={["required"]}
              errorMessages={[i18n.t("this_field_is_required")]}
              inputProps={{ readOnly }}
              variant="outlined"
            />
          </div>
        </div>
        <div className="col-12 flex-column flex-md-row px-0 d-flex align-items-md-center mb-3">
          <label className="font-weight-bold" style={{ minWidth: "100px" }}>
            {i18n.t("subject")}
          </label>
          <div className="w-100">
            <TextValidator
              label={i18n.t("subject")}
              onChange={(e) => handleChange("subject", e.target.value)}
              value={data.subject || ""}
              validators={["required", "maxStringLength:60"]}
              errorMessages={[
                i18n.t("this_field_is_required"),
                i18n.t("max_length_message", { maxLength: 60 }),
              ]}
              inputProps={{ readOnly, maxLength: 60 }}
              variant="outlined"
            />
          </div>
        </div>
        <div className="col-12 flex-column flex-md-row px-0 d-flex align-items-md-center mb-3">
          <label className="font-weight-bold" style={{ minWidth: "100px" }}>
            {i18n.t("send_date")}
          </label>
          <ReactDatePicker
            wrapperClassName="w-100"
            selected={data.date}
            onChange={(e) => {
              handleChange("date", e)
            }}
            value={data.date}
            minDate={new Date()}
            customInput={
              <TextValidator
                label={i18n.t("send_date")}
                value={data.date || ""}
                validators={["required"]}
                errorMessages={[i18n.t("this_field_is_required")]}
                inputProps={{ readOnly: true }}
                variant="outlined"
              />
            }
            placeholderText={i18n.t("date")}
            readOnly={readOnly}
          />
        </div>
        <div className="col-12 flex-column flex-md-row px-0 d-flex align-items-md-center mb-3">
          <label className="font-weight-bold" style={{ minWidth: "100px" }}>
            {i18n.t("send_time")}
          </label>
          <div className="w-100">
            <TextValidator
              type="time"
              label={i18n.t("send_time")}
              onChange={(e) => handleChange("time", e.target.value)}
              value={data.time || ""}
              validators={["required"]}
              errorMessages={[i18n.t("this_field_is_required")]}
              inputProps={{ readOnly }}
              variant="outlined"
            />
          </div>
        </div>
        <div className="col-12 flex-column flex-md-row px-0 d-flex align-items-md-center mb-3">
          <label className="font-weight-bold" style={{ minWidth: "100px" }}>
            {i18n.t("method")}
          </label>
          <div className="w-100">
            <SelectValidator
              label={i18n.t("method")}
              select
              validators={["required"]}
              errorMessages={[i18n.t("required")]}
              value={data.method || ""}
              onChange={(e) => handleChange("method", e.target.value)}
              inputProps={{ readOnly }}
              variant="outlined"
            >
              <MenuItem value="SMS">{i18n.t("just_sms")}</MenuItem>
              <MenuItem value="EMAIL">{i18n.t("just_email")}</MenuItem>
              <MenuItem value="EMAIL THEN SMS">
                {i18n.t("email_then_sms")}
              </MenuItem>
            </SelectValidator>
          </div>
        </div>
        <div className="col-12 flex-column flex-md-row px-0 d-flex align-items-md-center mb-3">
          <label className="font-weight-bold" style={{ minWidth: "100px" }}>
            {i18n.t("template")}
          </label>
          <div className="w-100">
            <SelectValidator
              label={i18n.t("template")}
              select
              validators={["required"]}
              errorMessages={[i18n.t("required")]}
              value={data.templateId || ""}
              onChange={(e) => handleChange("templateId", e.target.value)}
              inputProps={{ readOnly }}
              variant="outlined"
            >
              {templates.map((template) => {
                return (
                  <MenuItem value={template._id} key={template._id}>
                    {template.name}
                  </MenuItem>
                )
              })}
            </SelectValidator>
          </div>
        </div>
        {!readOnly && (
          <div className="row justify-content-end my-3">
            <PrimaryButton
              className="w-128"
              props={{
                type: "submit",
              }}
              label={i18n.t("save")}
            />
          </div>
        )}
      </FormContainer>
    </ValidatorForm>
  )
}
