import { connect } from "react-redux"
import React, { Component } from "react"

import { DotsLoader, BaseModal } from "../../../components"
import {
  getUnavailabilites,
  deleteEmployeeUnavailability,
  createEmployeeUnavailability,
  updateEmployeeUnavailabilityNote,
} from "../../../config/simpleApiCalls"
import {
  getRequest,
  removeRequest,
  updateNoteRequest,
} from "../../../redux/actions/ReoccuringUnavailabilties"
import moment from "moment"
import DatePicker from "react-datepicker"
import { SuccessHelper, ErrorHelper } from "../../../helpers"
import "./styles.css"
import NoteAddIcon from "@material-ui/icons/NoteAdd"
import i18n from "../../../i18n"
import ReoccuringUnavailabilities from "../../../components/ReoccuringUnavailabilities/ReoccuringUnavailabilities.component"
import BasicTable from "../../../components/Core/BasicTable"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Swal from "sweetalert2"
import PrimaryButton from "../../../components/Core/PrimaryButton"
import deleteConfirmation from "../../../helpers/DeleteHelper"
import { TextField } from "@material-ui/core"
import { ScreenSizeContext } from "../../../context/ScreenSize"
import { Button } from "@material-ui/core"

class ManageUnavailibilites extends Component {
  constructor(props) {
    super(props)
    this.state = {
      date: Date.now(),
      dateAvailabiltiesMap: {},
      selectionMap: {},
      isLoading: false,
      markedDates: {},
      toAddMap: {},
      toDeleteMap: {},
      showAddUnavailabiltyNote: false,
      unavailabilityNote: "",
    }
  }

  componentDidMount() {
    this.setState({ isLoading: true })
    this.getUnavailabilitesFromDate(this.state.date)
    this.props.getRequest({
      employeeId: this.props.employeeId,
    })
  }

  getUnavailabilitesFromDate(date) {
    const params = {
      date: moment(date).format("MM-DD-YYYY"),
      employeeId: this.props.employeeId,
    }
    this.setState({ isLoading: true, date })
    getUnavailabilites(params)
      .then((res) => {
        this.setState({
          isLoading: false,
          availabilities: res,
          toAddMap: {},
          toDeleteMap: {},
        })
      })
      .catch(() => {
        this.setState({ isLoading: false })
      })
  }

  renderLoader = () => {
    const { isLoading } = this.state
    return <DotsLoader isloading={isLoading || this.props.isFetching} />
  }

  toggleSelection(key) {
    const { toAddMap, toDeleteMap, availabilities = {} } = this.state
    if (availabilities[key] != "booked") {
      if (typeof availabilities[key] == "object") {
        if (toDeleteMap[key]) {
          delete toDeleteMap[key]
        } else {
          toDeleteMap[key] = availabilities[key]
        }
      } else {
        if (toAddMap[key]) {
          delete toAddMap[key]
        } else {
          toAddMap[key] = true
        }
      }
      this.setState({ toAddMap, toDeleteMap })
    }
  }

  onSetSidebarOpen = (open) => {
    this.setState({ sidebarOpen: open })
  }

  async save() {
    const { toAddMap, toDeleteMap, date } = this.state
    let formattedDate = moment(date).format("MM-DD-YYYY")
    this.setState({ isLoading: true })
    const toAdd = []
    Object.keys(toAddMap).map((timeRange) => {
      const time = timeRange.split(" ")[0]
      const data = {
        time,
        date: formattedDate,
        employeeId: this.props.employeeId,
      }
      if (toAddMap[timeRange] != true) {
        data.note = toAddMap[timeRange]
      }
      toAdd.push(data)
    })
    const toDelete = []
    Object.keys(toDeleteMap).map((timeRange) => {
      toDelete.push(toDeleteMap[timeRange]._id)
    })
    const promises = []
    if (toAdd.length) {
      promises.push(createEmployeeUnavailability(toAdd))
    }
    if (toDelete.length) {
      promises.push(deleteEmployeeUnavailability({ ids: toDelete.join(",") }))
    }
    Promise.all(promises).then(() => {
      this.getUnavailabilitesFromDate(this.state.date)
    })
  }

  markAllAsAvailable = () => {
    const { availabilities = {} } = this.state
    let toDelete = []
    Object.keys(availabilities).map((key) => {
      let availability = availabilities[key]
      if (typeof availability == "object") {
        toDelete.push(availability._id)
      }
    })
    deleteEmployeeUnavailability({ ids: toDelete.join(",") }).then(() => {
      this.getUnavailabilitesFromDate(this.state.date)
    })
  }

  markAllAsUnavailable = () => {
    const { availabilities = {}, date } = this.state
    let formattedDate = moment(date).format("MM-DD-YYYY")
    let toAdd = []
    Object.keys(availabilities).map((key) => {
      let availability = availabilities[key]
      if (availabilities[key] != "booked" && typeof availability != "object") {
        const time = key.split(" ")[0]
        toAdd.push({
          time,
          date: formattedDate,
          employeeId: this.props.employeeId,
        })
      }
    })
    createEmployeeUnavailability(toAdd).then(() => {
      this.getUnavailabilitesFromDate(this.state.date)
    })
  }

  openAdvancedModal = () => {
    this.setState({ advancedModalOpen: true })
  }

  handleDelete = (id) => {
    Swal.fire({
      title: i18n.t("undo_blocked_times"),
      showCancelButton: true,
      cancelButtonText: i18n.t("no"),
      confirmButtonText: i18n.t("yes"),
    }).then((result) => {
      this.setState({ isloading: true })
      this.props.removeRequest(
        {
          id,
          clear: Boolean(result.value),
        },
        {
          success: () => {
            SuccessHelper.handleSuccess(i18n.t("successfully_deleted"), true)
            this.setState({ isloading: false })
          },
          failure: () => {
            ErrorHelper.handleErrors(i18n.t("failed_to_delete"), true)
            this.setState({ isloading: false })
          },
        }
      )
    })
  }
  buttonsRender = () => {
    return (
      <>
        <PrimaryButton
          onClick={() => this.markAllAsAvailable()}
          type="button"
          className="my-1btn-light"
          id="saloon-save-social-link"
        >
          Mark all as available
        </PrimaryButton>

        <PrimaryButton
          onClick={() => this.markAllAsUnavailable()}
          type="button"
          className="my-1btn-light"
          id="saloon-save-social-link"
        >
          Mark all as unavailable
        </PrimaryButton>
        <PrimaryButton
          onClick={() => this.openAdvancedModal()}
          type="button"
          className="my-1btn-light"
          id="saloon-save-social-link"
        >
          {i18n.t("advanced")}
        </PrimaryButton>
      </>
    )
  }

  datePickerRender = () => {
    const { date } = this.state
    return (
      <div className="justify-content-start d-flex gap-2 mb-3">
        <div className="d-flex align-items-center font-weight-bold ml-1">
          {i18n.t("date")}
        </div>
        <DatePicker
          className="form-control datepicker"
          minDate={new Date()}
          selected={date}
          onChange={(date) => this.getUnavailabilitesFromDate(date)}
          value={date}
          placeholderText={i18n.t("container.services_click_to_select_a_date")}
        />
      </div>
    )
  }
  openOptionsModal = () => {
    this.setState({ optionsModalOpen: true })
  }

  openFilterModal = () => {
    this.setState({ filterModalOpen: true })
  }

  render() {
    const {
      date,
      availabilities = {},
      toAddMap,
      toDeleteMap,
      advancedModalOpen,
      showAddAdvancedModal,
      showAddUnavailabiltyNote,
      selectedAvailability,
      unavailabilityNote,
      optionsModalOpen,
      filterModalOpen,
    } = this.state

    const { screenSize } = this.context
    return (
      <div className="p-3">
        {this.renderLoader()}
        <BaseModal
          open={showAddUnavailabiltyNote}
          style={{ zIndex: 1304 }}
          onClose={() => this.setState({ showAddUnavailabiltyNote: false })}
          title={i18n.t("add_note")}
          content={
            showAddUnavailabiltyNote ? (
              <div className="row flex-column">
                <TextField
                  multiline
                  minRows={5}
                  maxRows={5}
                  value={unavailabilityNote}
                  onChange={(e) =>
                    this.setState({ unavailabilityNote: e.target.value })
                  }
                  variant="outlined"
                />
                <div className="row mt-3 justify-content-end">
                  <PrimaryButton
                    onClick={async () => {
                      if (selectedAvailability?._id) {
                        const { data } = advancedModalOpen
                          ? this.props.updateNoteRequest(
                              {
                                _id: selectedAvailability?._id,
                                note: unavailabilityNote,
                              },
                              {
                                success: () => {
                                  SuccessHelper.handleSuccess(
                                    i18n.t("successfully_updated"),
                                    true
                                  )
                                  this.getUnavailabilitesFromDate(
                                    this.state.date
                                  )
                                },
                                failure: () => {
                                  ErrorHelper.handleErrors(
                                    i18n.t("failed_to_update"),
                                    true
                                  )
                                },
                              }
                            )
                          : updateEmployeeUnavailabilityNote({
                              _id: selectedAvailability?._id,
                              note: unavailabilityNote,
                            }).then(({ data }) => {
                              if (data.success) {
                                this.getUnavailabilitesFromDate(this.state.date)
                              } else {
                                ErrorHelper(i18n.t("someting_went_wrong"))
                              }
                            })
                      } else {
                        toAddMap[selectedAvailability] = unavailabilityNote
                        this.setState({ toAddMap }, () => {
                          this.save()
                        })
                      }
                      this.setState({ showAddUnavailabiltyNote: false })
                    }}
                    type="button"
                  >
                    {i18n.t("save")}
                  </PrimaryButton>
                </div>
              </div>
            ) : null
          }
        />
        <div
          className="content-container px-0 pb-0"
          style={{ flexDirection: "row" }}
        >
          {screenSize !== "mobile" ? (
            <div>
              {this.datePickerRender()}
              <div className="row gap-2 justify-content-center mb-3">
                {this.buttonsRender()}
              </div>
            </div>
          ) : (
            <div className="row gap-2 justify-content-center mb-4">
              <PrimaryButton onClick={() => this.openFilterModal()}>
                {i18n.t("container.advancedSearchDialog_filter")}
              </PrimaryButton>

              <PrimaryButton onClick={() => this.openOptionsModal()}>
                Options
              </PrimaryButton>
            </div>
          )}
          <div>
            {Object.keys(availabilities).length ? (
              <>
                <div className="availabilities">
                  {Object.keys(availabilities).map((availability) => {
                    let slotClass = "available "
                    if (typeof availabilities[availability] == "object") {
                      slotClass = "unavailable "
                    } else if (availabilities[availability] == "booked") {
                      slotClass = "booked "
                    }
                    if (toAddMap[availability]) {
                      slotClass += "selected"
                    } else if (toDeleteMap[availability]) {
                      slotClass += "unselected"
                    }
                    return (
                      <div onClick={() => this.toggleSelection(availability)}>
                        <div
                          className={
                            "row justify-content-center gap-2 font-weight-bold my-1 mx-1 py-3 " +
                            slotClass
                          }
                          style={{ position: "relative" }}
                        >
                          <div>{availability}</div>
                          <div>
                            {typeof availabilities[availability] == "object"
                              ? "unavailable"
                              : availabilities[availability]}
                          </div>
                          {(typeof availabilities[availability] == "object" &&
                            !availabilities[availability]
                              .reoccuringEmployeeUnavailibilitiesId) ||
                          (slotClass.includes("selected") &&
                            !slotClass.includes("unavailable")) ? (
                            <NoteAddIcon
                              style={{ position: "absolute", right: 0, top: 0 }}
                              onClick={(e) => {
                                e.stopPropagation()
                                this.setState({
                                  showAddUnavailabiltyNote: true,
                                  selectedAvailability: availabilities[
                                    availability
                                  ]?._id
                                    ? availabilities[availability]
                                    : availability,
                                  unavailabilityNote:
                                    availabilities[availability]?.note,
                                })
                              }}
                            />
                          ) : null}
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div className="row my-3 justify-content-center justify-content-md-end">
                  <PrimaryButton
                    onClick={() => this.save()}
                    className="w-128"
                    type="button"
                  >
                    Save
                  </PrimaryButton>
                </div>
              </>
            ) : (
              <div className="col-12">Nothing found for this day</div>
            )}
          </div>
          {advancedModalOpen ? (
            <BaseModal
              open={advancedModalOpen}
              style={{ zIndex: 1303 }}
              {...{
                title: i18n.t("advanced"),
                content: (
                  <>
                    {showAddAdvancedModal ? (
                      <BaseModal
                        containerClassName="half-container"
                        open
                        {...{
                          contentClassName: "modify-modal-content",
                          title: i18n.t("add"),
                          content: (
                            <>
                              <ReoccuringUnavailabilities
                                employeeId={this.props.employeeId}
                                onClose={() => {
                                  this.setState({
                                    showAddAdvancedModal: false,
                                  })
                                }}
                              />
                            </>
                          ),
                        }}
                        onClose={() => {
                          this.setState({ showAddAdvancedModal: false })
                        }}
                      />
                    ) : null}
                    <BasicTable
                      rows={this.props.reoccuringUnavailabilties}
                      schema={[
                        {
                          field: "startDate",
                          header: i18n.t("start_date"),
                          accessor: (val) =>
                            moment(val.startDate).format("MM-DD-YYYY"),
                        },
                        {
                          field: "endDate",
                          header: i18n.t("end_date"),
                          accessor: (val) =>
                            moment(val.endDate).format("MM-DD-YYYY"),
                        },
                        {
                          field: "startTime",
                          header: i18n.t("start_time"),
                        },
                        {
                          field: "endTime",
                          header: i18n.t("end_time"),
                        },
                        {
                          field: "createdDate",
                          header: i18n.t("created_date"),
                          accessor: (val) =>
                            moment(val.createdDate).format("MM-DD-YYYY"),
                        },
                        {
                          key: "action",
                          header: i18n.t("actions"),
                          render: (val) => {
                            return (
                              <div className="row gap-1">
                                <NoteAddIcon
                                  style={{
                                    color: "white",
                                    cursor: "pointer",
                                    height: "28px",
                                  }}
                                  className="view-icon"
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    this.setState({
                                      showAddUnavailabiltyNote: true,
                                      selectedAvailability: val,
                                      unavailabilityNote: val?.note,
                                    })
                                  }}
                                />
                                <FontAwesomeIcon
                                  className="view-icon"
                                  style={{ backgroundColor: "#F6943B" }}
                                  color="white"
                                  icon={["fas", "trash"]}
                                  onClick={() =>
                                    deleteConfirmation(() =>
                                      this.handleDelete(val._id)
                                    )
                                  }
                                />
                              </div>
                            )
                          },
                        },
                      ]}
                    />
                    {screenSize == "mobile" && (
                      <div className="row justify-content-center">
                        <PrimaryButton
                          className="mr-3 create-btn w-128"
                          label={i18n.t("create")}
                          onClick={() => {
                            this.setState({ showAddAdvancedModal: true })
                          }}
                        />

                        <PrimaryButton
                          className="close-btn w-128"
                          label={i18n.t("close")}
                          onClick={() => {
                            this.setState({
                              advancedModalOpen: false,
                              showAddAdvancedModal: false,
                            })
                          }}
                        />
                      </div>
                    )}
                  </>
                ),
              }}
              showCreateBtn={
                screenSize !== "mobile"
                  ? () => {
                      this.setState({ showAddAdvancedModal: true })
                    }
                  : null
              }
              onClose={() => {
                this.setState({
                  advancedModalOpen: false,
                  showAddAdvancedModal: false,
                })
              }}
              showCloseBtn={screenSize === "mobile" ? false : true}
            />
          ) : null}
          {optionsModalOpen ? (
            <BaseModal
              open={optionsModalOpen}
              {...{
                title: "Options",
                content: (
                  <div className="row justify-content-center flex-column gap-2">
                    {" "}
                    {this.buttonsRender()}
                  </div>
                ),
              }}
              onClose={() => {
                this.setState({
                  optionsModalOpen: false,
                })
              }}
            />
          ) : null}
          {filterModalOpen ? (
            <BaseModal
              containerClassName={"base-model-container position-relative"}
              open={filterModalOpen}
              {...{
                title: i18n.t("container.advancedSearchDialog_filter"),
                content: (
                  <>
                    <Button
                      className="position-absolute font-weight-bold text-capitalize"
                      style={{ color: "#F6943B", top: "20px", right: "10px" }}
                      onClick={() =>
                        this.getUnavailabilitesFromDate(Date.now())
                      }
                    >
                      {i18n.t("resetFilter")}
                    </Button>
                    {this.datePickerRender()}
                    <div className="row justify-content-center  gap-2">
                      <PrimaryButton
                        onClick={() =>
                          this.setState({
                            filterModalOpen: false,
                          })
                        }
                        className="w-128"
                      >
                        {i18n.t("close")}
                      </PrimaryButton>
                    </div>
                  </>
                ),
              }}
              showCloseBtn={false}
            />
          ) : null}
        </div>
      </div>
    )
  }
}
ManageUnavailibilites.contextType = ScreenSizeContext
const mapStateToProps = (state) => ({
  reoccuringUnavailabilties: state.reoccuringUnavailabilties.data,
  isFetching: state.reoccuringUnavailabilties.isFetching,
})

const action = {
  getRequest,
  removeRequest,
  updateNoteRequest,
}

export default connect(mapStateToProps, action)(ManageUnavailibilites)
