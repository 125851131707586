import { combineReducers } from "redux"

import user from "./User"

// CUSTOMER
import login from "./Login"
import register from "./Register"
import forgotPassword from "./ForgotPassword"
import verifyResetCode from "./VerifyResetCode"
import resetPassword from "./ResetPassword"
import socialLogin from "./SocialLogin"
import logout from "./LogOut"
import getHomePageInfo from "./GetHomePageInfo"
import getHome from "./GetHome"
import getNotifications from "./GetNotifications"
import updateNotification from "./UpdateNotification"
import activeBooking from "./ActiveBooking"

// SALOON
import saloonLogin from "./Saloon/Login"
import saloonRegister from "./Saloon/Register"
import createSaloon from "./Saloon/CreateSaloon"
import createSaloonPlan from "./Saloon/CreateSaloonPlan"
import saloonPaymentMethod from "./Saloon/SaloonPaymentMethod"
import saloonUserId from "./Saloon/SaloonByUserId"
import SalonAppointment from "./Saloon/SalonAppointment"
import saloonLogout from "./Saloon/LogOut"
import getAvailableTimeslots from "./GetAvailableTimeslots"
import getGymTimeslots from "./GetGymTimeslots"
import billing from "./Saloon/Billing"
import easy1Products from "./Saloon/Easy1Products"
import subscription from "./Saloon/Subscription"
import allFundsRequest from "./Saloon/AllFundsRequest"
import orders from "./Saloon/Orders"
import formBuilder from "./Saloon/FormBuilder"
import vendor from "./Saloon/Vendor"
import expense from "./Saloon/Expense"
import communicationSettings from "./Saloon/CommunicationSettings"
import program from "./Saloon/Program"
import _class from "./Saloon/Class"
import student from "./Saloon/Student"
import studentGroup from "./Saloon/StudentGroup"
import coursePlanner from "./Saloon/CoursePlanner"
import ProgramEnrollment from "./Saloon/ProgramEnrollment"
import semester from "./Saloon/Semester"
import noShowReport from "./Saloon/NoShowReport"
import reoccuringCompanyUnavailabilties from "./ReoccuringCompanyUnavailabilties"
import companyCustomerCars from "./Saloon/CompanyCustomerCars"
import companyCustomerSubscription from "./Saloon/CompanyCustomerSubscription"
import companyCustomerGroups from "./Saloon/CompanyCustomerGroups"
import companySubscription from "./Saloon/CompanySubscription"
import autoBookingSchedule from "./Saloon/AutoBookingSchedule"
import autoBookingScheduleTemplate from "./Saloon/AutoBookingScheduleTemplate"
import generatedBookingSchedule from "./Saloon/GeneratedBookingSchedule"
import communicationsMarketing from "./Saloon/CommunicationsMarketing"
import communicationsMarketingCampaign from "./Saloon/CommunicationsMarketingCampaign"
import communicationsMarketingTemplate from "./Saloon/CommunicationsMarketingTemplate"
import promotion from "./Saloon/Promotion"
import discountCode from "./Saloon/DiscountCode"
import customerAgeGroup from "./Saloon/CustomerAgeGroup"
import customerDistrict from "./Saloon/CustomerDistrict"
import customerOrigin from "./Saloon/CustomerOrigin"
import customerStatus from "./Saloon/CustomerStatus"
import timePeriod from "./Saloon/TimePeriod"
import intake from "./Saloon/Intake"
import intakeType from "./Saloon/IntakeType"
import customerReference from "./Saloon/CustomerReference"
import activity from "./Saloon/Activity"
import activityCategory from "./Saloon/ActivityCategory"
import activityScheduleTemplate from "./Saloon/ActivityScheduleTemplate"
import activityScheduleEvent from "./Saloon/ActivityScheduleEvent"

//EMPLOYEE
import employeeRegister from "./Employee/Register"
import employeeLogin from "./Employee/Login"
import employeeLogout from "./Employee/LogOut"
import employeeFundsRequest from "./Employee/EmployeeFundsRequest"

// IMPERSONATE
import impersonateEmployee from "./Employee/ImpersonateEmployee"
import impersonateSalon from "./Saloon/ImpersonateSalon"
import impersonateUser from "./ImpersonateUser"

import announcements from "./Announcements"
import sidebar from "./Sidebar"
import card from "./Card"
import bookingTransaction from "./BookingTransaction"
import companyCard from "./CompanyCard"
import companyCustomerCard from "./CompanyCustomerCard"
import validateBookingTransfer from "./ValidateBookingTransfer"
import packages from "./Packages"
import giftCards from "./GiftCards"
import formAnswer from "./FormAnswer"
import reoccuringUnavailabilties from "./ReoccuringUnavailabilties"
import receptionistAccess from "./ReceptionistAccess"
import managerAccess from "./ManagerAccess"
import ownerAccess from "./OwnerAccess"
import instructorAccess from "./InstructorAccess"

export const rootReducer = combineReducers({
  user,
  // CUSTOMER
  login,
  register,
  forgotPassword,
  verifyResetCode,
  resetPassword,
  socialLogin,
  logout,
  getAvailableTimeslots,
  getGymTimeslots,
  getHomePageInfo,
  getHome,
  getNotifications,
  updateNotification,
  activeBooking,
  // SALOON
  saloonLogin,
  saloonRegister,
  createSaloon,
  createSaloonPlan,
  saloonPaymentMethod,
  saloonUserId,
  SalonAppointment,
  saloonLogout,
  billing,
  easy1Products,
  subscription,
  allFundsRequest,
  orders,
  formBuilder,
  vendor,
  expense,
  communicationSettings,
  program,
  _class,
  student,
  studentGroup,
  coursePlanner,
  ProgramEnrollment,
  semester,
  noShowReport,
  reoccuringCompanyUnavailabilties,
  companyCustomerCars,
  companyCustomerSubscription,
  companyCustomerGroups,
  companySubscription,
  autoBookingSchedule,
  autoBookingScheduleTemplate,
  generatedBookingSchedule,
  communicationsMarketing,
  communicationsMarketingCampaign,
  communicationsMarketingTemplate,
  promotion,
  discountCode,
  customerAgeGroup,
  customerDistrict,
  customerOrigin,
  customerStatus,
  timePeriod,
  intake,
  intakeType,
  customerReference,
  activity,
  activityCategory,
  activityScheduleTemplate,
  activityScheduleEvent,

  //EMPLOYEE
  employeeRegister,
  employeeLogin,
  employeeLogout,
  employeeFundsRequest,

  // IMPERSONATE
  impersonateEmployee,
  impersonateSalon,
  impersonateUser,

  announcements,
  sidebar,
  card,
  bookingTransaction,
  companyCard,
  companyCustomerCard,
  validateBookingTransfer,
  packages,
  giftCards,
  formAnswer,
  reoccuringUnavailabilties,
  receptionistAccess,
  managerAccess,
  ownerAccess,
  instructorAccess,
})
