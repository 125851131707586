import React, { Component } from "react"
import { Translation } from "react-i18next"
import { connect } from "react-redux"
import i18n from "../../../i18n"
import { Images } from "../../../theme"
import DatePicker from "react-datepicker"
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  Button,
  MenuItem,
} from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import {
  DotsLoader,
  AppointmentModal,
  AddExtrasModal,
  BaseModal,
} from "../../../components"
import BookingNotesReminders from "../../../components/BookingNotesReminders"
import ModifyModal from "../../../components/NotifyModal/ModifyModal"
import {
  gitWaitingAppoinments,
  getEmployeesBySaloonAndService,
  getEmployeeDetails,
  removeExtra,
  removeProduct,
  updateCartProduct,
  editBooking,
} from "../../../config/simpleApiCalls"
import { request as get_available_timeslots } from "../../../redux/actions/GetAvailableTimeslots.js"
import "./styles.css"
import useCache from "../../../context/LocalCache"
import PrimaryButton from "../../../components/Core/PrimaryButton"
import { getNestedValue } from "../../../util/objectMethods"
import moment from "moment"
import { SuccessHelper } from "../../../helpers"
import Swal from "sweetalert2"
import BasicTable from "../../../components/Core/BasicTable/index.jsx"
import { ScreenSizeContext } from "../../../context/ScreenSize.jsx"
const weekNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednessday",
  "Thursday",
  "Friday",
  "Saturday",
]

const statusMap = {
  2: "container.services_now_serving_status",
  3: "cancelled",
  4: "container.services_done_status",
  7: "cancelled_no_show",
}
class _Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      usersList: [],
      allAppoin: [],
      isloading: false,
      blockUsersList: [],
      unblockUsersList: [],
      showPopUp: false,
      formErrors: {
        selectServicesError: "",
        selectDateAndTimeError: "",
        selectedTimeError: "",
      },
      employeeServicesList: [],
      employeesWorkingHoursData: [
        { dayName: "Sunday", dayOfWeek: 1, availableStatus: 0 },
        { dayName: "Monday", dayOfWeek: 2, availableStatus: 0 },
        { dayName: "Tuesday", dayOfWeek: 3, availableStatus: 0 },
        { dayName: "Wednesday", dayOfWeek: 4, availableStatus: 0 },
        { dayName: "Thursday", dayOfWeek: 5, availableStatus: 0 },
        { dayName: "Friday", dayOfWeek: 6, availableStatus: 0 },
        { dayName: "Saturday", dayOfWeek: 7, availableStatus: 0 },
      ],
      selectedService: {},
      bookingStauses: [
        { text: "Waiting", value: 1, styles: { color: "#aa43b5" } },
        { text: "Now Serving", value: 3, styles: { color: "#FFE478" } },
        { text: "Cancel", value: 2, styles: { color: "#FF7082" } },
        { text: "Done", value: 4, styles: { color: "#95DA47" } },
      ],
      search: "",
      filteredAppointments: [],
    }
  }

  async componentDidMount() {
    this.gitWaitingAppoinments()
    this.getEmployeeData()
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { companies, taxMap, productMap } = nextProps
    let availableTimeslots = {}
    if (nextProps.getAvailableTimeslots) {
      if (
        !nextProps.getAvailableTimeslots.failure &&
        !nextProps.getAvailableTimeslots.isFetching &&
        !nextProps.getAvailableTimeslots.errorMessage &&
        nextProps.getAvailableTimeslots.data &&
        nextProps.getAvailableTimeslots.data.data
      ) {
        availableTimeslots = nextProps.getAvailableTimeslots.data.data
      }
    }
    const allSaloonMap = {}
    companies.map((saloon) => {
      allSaloonMap[saloon.companyId._id] = saloon
    })
    return {
      availableTimeslots,
      allSaloonMap,
      employeeSalon: companies,
      allSaloonTaxMap: taxMap,
      allProductMap: productMap,
    }
  }

  genericUpdate = async (body) => {
    const { selectBooking } = this.state
    this.setState({
      isloading: true,
    })
    let { allAppoin } = this.state
    const services = [
      {
        serviceId: selectBooking.services[0].serviceId._id,
        categoryId: selectBooking.services[0].categoryId?._id,
        date: selectBooking.services[0].date,
        time: selectBooking.services[0].time,
        employeeId: selectBooking.services[0].employeeId?._id,
      },
    ]
    const { data } = await editBooking({
      bookingId: selectBooking._id,
      status: selectBooking.status,
      services,
      ...body,
    })
    let newSelectBooking = selectBooking
    if (data.success) {
      const newBooking = data.data
      newBooking.services = newBooking.services.map((service) => {
        return {
          ...service,
        }
      })
      newSelectBooking = { ...newBooking, index: selectBooking.index }
      if (typeof selectBooking.index === "number") {
        allAppoin[selectBooking.index] = newSelectBooking
      } else {
        const booking = allAppoin.find(
          (booking) => booking._id == newSelectBooking._id
        )
        newSelectBooking.index = booking.index
        allAppoin[booking.index] = newSelectBooking
      }

      this.setState({
        isloading: false,
        allAppoin: [...allAppoin],
        selectBooking: newSelectBooking,
        editDetails: false,
        ...body,
      })
    }
  }

  onChangeTip = (tip) => {
    const { tip: oldTip } = this.state
    if (oldTip != tip) {
      this.genericUpdate({ tip })
    }
  }

  onChangeAdjustedTime = (adjustedTime) => {
    this.genericUpdate({ adjustedTime })
  }

  toggleDepositRequired = (depositRequired) => {
    this.genericUpdate({ depositRequired })
  }

  toggleTaxes = (includeTaxes) => {
    this.genericUpdate({ includeTaxes })
  }
  gitWaitingAppoinments = async () => {
    this.setState({ isloading: true })
    try {
      const res = await gitWaitingAppoinments({
        type: "employee",
        relations: ["notes", "reminders"],
      })
      const appointments = res.data.data.filter(({ status }) => status === 1)
      this.setState({
        allAppoin: appointments,
        isloading: false,
        filteredAppointments: appointments,
      })
    } catch (error) {
      alert("Something went wrong ;(")
      this.setState({ isloading: false })
    }
  }

  handleNavigation = (route) => {
    this.props.history.push(route)
  }

  renderLoader = () => {
    const { isloading } = this.state
    return <DotsLoader isloading={isloading} />
  }

  getEmployee = async (selectServices) => {
    const serviceData = selectServices
      ? JSON.parse(selectServices)
      : selectServices
    this.setState({ serviceData })
    const { selectSalon, lockFields, employeeId } = this.state

    this.setState({ isloading: true })
    const payload = {
      companyId: selectSalon,
      serviceId: serviceData._id,
    }
    try {
      const res = await getEmployeesBySaloonAndService(payload)
      this.setState({
        getEmployeeBySalonData: res.data.data,
        isloading: false,
      })

      return res.data.data
    } catch (error) {
      console.log(error)
      ErrorHelper.handleErrors(
        "Aww somthing went wrong for getting Employees",
        true
      )
      this.setState({ isloading: false })
    }
  }

  _renderEmployeeCard = () => {
    const { allAppoin } = this.state
    return (
      <>
        <div
          className="py-3 px-4 mb-5 rounded"
          style={{ backgroundColor: "#F4F6F8", width: "fit-content" }}
        >
          <div className="d-flex align-items-center justify-content-center">
            <div
              className="text-center p-2 rounded"
              style={{ backgroundColor: "#E5EBF1" }}
            >
              <img src={Images.dashboard_employees} />
            </div>
            <div className="ml-3">
              <div>
                <span className="dashboard-card-number">
                  {allAppoin?.length ? allAppoin.length : "0"}
                </span>
              </div>
              <span className="dashboard-card-title mt-1 mb-0">
                {i18n.t("container.booking")}
              </span>
            </div>
          </div>
        </div>
      </>
    )
  }
  _renderCategoriesCard = () => {
    const { employeeSalon } = this.state
    return (
      <>
        <div
          className="py-3 px-4 mb-5 rounded"
          style={{ backgroundColor: "#F4F6F8", width: "fit-content" }}
        >
          <div className="row align-items-center dashboard-card-service">
            <div
              className="text-center px-2 py-1 rounded"
              style={{ backgroundColor: "#E5EBF1", width: "fit-content" }}
            >
              <img src={Images.active_salon_dark_svg} />
            </div>
            <div className="ml-3">
              <div>
                <span className="dashboard-card-number">
                  {employeeSalon?.length ? employeeSalon.length : "0"}
                </span>
              </div>
              <span className="dashboard-card-title mt-1 mb-0">
                <Translation>{(t) => t("container.active_salon")}</Translation>
              </span>
            </div>
          </div>
        </div>
      </>
    )
  }
  handleSearch = (e) => {
    const { allAppoin } = this.state
    console.log(allAppoin)
    const { value } = e.target
    this.setState({ search: value })
    if (!value) {
      this.setState({ filteredAppointments: allAppoin })
    } else {
      const filteredAppointments = allAppoin.filter(
        ({ phoneNo, name, status }) => {
          const normalizedSearchQuery = value
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
          const normalizedName = name
            ?.toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")

          return (
            normalizedName?.includes(normalizedSearchQuery) ||
            (phoneNo?.toLowerCase().includes(normalizedSearchQuery) &&
              status == 1)
          )
        }
      )
      console.log(filteredAppointments)
      this.setState({ filteredAppointments: filteredAppointments })
    }
  }

  renderAppointmentCard = () => {
    const { allAppoin, search, filteredAppointments } = this.state
    const { screenSize } = this.context
    return (
      <>
        <div className="col-12 col-sm-6 col-md-6 col-lg-6 offset-md-0 offset-lg-0 px-0">
          <div
            className={
              screenSize === "mobile" ? "d-flex justify-content-between" : ""
            }
          >
            {this._renderEmployeeCard()}
            {screenSize === "mobile" ? this._renderCategoriesCard() : null}
          </div>

          <div className="row flex-column flex-md-row  align-items-end mb-3">
            <div className="col-lg-4 col-12 dashboard-table-title">
              <Translation>
                {(t) => t("container.employe_dashboard_appointment")}
              </Translation>
            </div>
            <div className="col-lg-4 col-12">
              <TextField
                label={i18n.t("search")}
                value={search}
                onChange={this.handleSearch}
              />
            </div>
            <div className="col-lg-4 col-12 mt-lg-0 mt-3 d-flex pr-0">
              <Button
                className="add_employee_btn_active ml-auto"
                onClick={() =>
                  this.props.history.push(
                    "employee-my-appointment?displayTable=true"
                  )
                }
              >
                {i18n.t("container.employe_dashboard_see_all_salon")}
              </Button>
            </div>
          </div>
          <BasicTable
            tableContainerStyle={
              screenSize === "mobile" ? { height: "50vh" } : { height: "70vh" }
            }
            rows={filteredAppointments}
            schema={[
              {
                header: <Translation>{(t) => t("name")}</Translation>,
                field: "name",
                render: (val) => {
                  return val.name ? val.name : ""
                },
              },
              {
                header: (
                  <Translation>{(t) => t("employe_phone_number")}</Translation>
                ),
                field: "phoneNo",
                render: (val) => {
                  return val.phoneNo ? val.phoneNo : ""
                },
              },
              {
                header: <Translation>{(t) => t("date_and_time")}</Translation>,
                field: "date",
                accessor: (val) => {
                  return val.services[0].date + " " + val.services[0].time
                },
              },
              {
                header: <Translation>{(t) => t("status")}</Translation>,
                field: "status",
                accessor: (val) => {
                  return val?.status === 1 ? "Waiting" : null
                },
              },
              {
                header: <Translation>{(t) => t("info")}</Translation>,
                field: "info",
                render: (val) => {
                  return (
                    <PrimaryButton
                      className="btn-orange px-0"
                      style={{ minWidth: "2.33rem", lineHeight: "1.5" }}
                      onClick={() =>
                        this.setState({
                          showPopUp: true,
                          selectBooking: val,
                        })
                      }
                    >
                      <img src={Images.info_svg} alt="info-svg" />
                    </PrimaryButton>
                  )
                },
              },
            ]}
          />
        </div>
      </>
    )
  }

  renderPopup = () => {
    const { showPopUp } = this.state

    return (
      <div
        className="active-service-card-wrapper "
        style={{ display: showPopUp ? "flex" : "none" }}
      >
        <div className="container ">
          <div
            className="col-8 p-3 my-5 bg-white pop_container"
            style={{ borderRadius: "7px", margin: "auto" }}
          >
            <div className="py-0 px-2 text-right">
              <span
                onClick={() => this.setState({ showPopUp: false })}
                className="h4 cursor-pointer"
              >
                &times;
              </span>
            </div>
            {this.renderBookingMoreInfo()}
          </div>
        </div>
      </div>
    )
  }

  handleEditDetails = async () => {
    const { selectBooking } = this.state
    const companyId = selectBooking.companyId._id
    const selectService = getNestedValue(
      selectBooking,
      ["services", 0, "serviceId"],
      {}
    )

    this.setState({ selectSalon: companyId, enableWaitingList: false }, () => {
      let selectEmployee = getNestedValue(
        selectBooking,
        ["services", 0, "employeeId", "_id"],
        {}
      )
      const momentDate = moment(
        `${getNestedValue(
          selectBooking,
          ["services", 0, "date"],
          ""
        )} ${getNestedValue(selectBooking, ["services", 0, "time"], "")}`,
        "MM-DD-YYYY HH:mm"
      )
      Promise.all([
        new Promise((resolve) => {
          this.props.get_available_timeslots({
            data: {
              companyId,
              companyServiceId: selectService._id,
              date: momentDate.format("MM-DD-YYYY"),
              bookingIdToExclude: selectBooking._id,
              companyEmployeeId: selectEmployee,
            },
            resolve,
          })
        }),
        this.getEmployee(JSON.stringify(selectService)),
      ]).then(() => {
        this.setState({
          editDetails: true,
          selectEmployee,
          selectedService: selectService,
          newDate: momentDate.format("MM-DD-YYYY"),
          newTime: momentDate.format("HH:mm"),
          selectDateAndTime: momentDate.toDate(),
          selectedTime: `${momentDate.format("HH:mm")} - ${moment(momentDate)
            .add(parseInt(selectService.duration), "minutes")
            .format("HH:mm")}`,
        })
      })
    })
  }

  renderBookingMoreInfo = () => {
    const {
      showPopUp,
      selectBooking,
      isGym,
      showInsertPayment,
      paymentCompleteCallback,
      employeeMap,
      bookings,
      allSaloonTaxMap,
      allSaloonMap,
    } = this.state

    if (showPopUp) {
      return (
        <>
          {this.renderExtras()}
          <AppointmentModal
            showInsertPayment={showInsertPayment}
            paymentCompleteCallback={paymentCompleteCallback}
            setCart={(cartId) => {
              if (cartId) {
                if (
                  typeof selectBooking.index === "number" &&
                  bookings[selectBooking.index]
                ) {
                  bookings[selectBooking.index].cartId = cartId
                }
                this.setState({
                  selectBooking: { ...selectBooking, cartId },
                  bookings,
                })
              }
            }}
            isIndependent={getNestedValue(
              allSaloonMap,
              [
                getNestedValue(selectBooking, ["companyId", "_id"]),
                "isEmployeeIndependent",
              ],
              true
            )}
            open={showPopUp}
            selectBooking={selectBooking}
            employeeMap={employeeMap}
            isGym={isGym}
            handleDrawerClose={(ev) => {
              this.setState({ showPopUp: false })
            }}
            statusButtonClicked={(status, additional) => {
              const sendUpdate = (result, append = {}) => {
                if (result.value) {
                  this.setState({ bookingStaus: status }, () => {
                    this.handleUpdate({ ...append })
                  })
                } else {
                  this.setState(
                    {
                      bookingStaus: status,
                    },
                    this.handleUpdate
                  )
                }
              }
              if (status == 3) {
                return sendUpdate({ value: true }, additional)
              }
              if (status != 4) {
                Swal.fire({
                  title: i18n.t("change_status"),
                  text: `${i18n.t("confirm_status_change")} ${i18n.t(
                    statusMap[status]
                  )}`,
                  showCancelButton: true,
                  cancelButtonText: i18n.t("no"),
                  confirmButtonText: i18n.t("yes"),
                }).then((result) => {
                  if (result.value) {
                    if (status == 7) {
                      status = 3
                      const price = getNestedValue(
                        selectBooking,
                        ["totalAmount"],
                        ""
                      )
                      const noShowFeePercentage = getNestedValue(
                        selectBooking,
                        ["companyId", "noShowFeePercentage"],
                        0
                      )
                      const noShowFee = (price * noShowFeePercentage) / 100

                      if (noShowFee) {
                        Swal.fire({
                          title: i18n.t("charge_no_show_fee"),
                          text: `$ ${noShowFee.toFixed(2)}`,
                          showCancelButton: true,
                          cancelButtonText: i18n.t("no"),
                          confirmButtonText: i18n.t("yes"),
                        }).then((result) =>
                          sendUpdate(result, { isNoShow: result.value })
                        )
                      } else {
                        sendUpdate({ value: false })
                      }
                    } else {
                      sendUpdate({ value: false })
                    }
                  }
                })
              } else {
                this.setState(
                  {
                    bookingStaus: status,
                  },
                  this.handleUpdate
                )
              }
            }}
            onModifyClick={() => this.handleEditDetails()}
            onProductChange={(e, index) => {
              this.setState({ isloading: true })
              updateCartProduct(
                {
                  productId: selectBooking.cartId.products[index]._id,
                  cartId: selectBooking.cartId._id,
                },
                { quantity: e }
              )
                .then(({ data }) => {
                  if (data.success) {
                    selectBooking.cartId.products[index].quantity =
                      data.data.quantity
                    if (typeof selectBooking.index === "number") {
                      bookings[selectBooking.index] = selectBooking
                    }
                    this.setState({
                      selectBooking: { ...selectBooking },
                      bookings,
                    })
                  } else {
                    if (data.code) {
                      ErrorHelper.handleErrors(
                        i18n.t(data.code, { ...data.data }),
                        true
                      )
                    } else {
                      ErrorHelper.handleErrors("Failed to Add Item", true)
                    }
                  }
                })
                .finally(() => {
                  this.setState({ isloading: false })
                })
            }}
            onProductDelete={(index) => {
              this.setState({ isloading: true })
              removeProduct({
                productId: selectBooking.cartId.products[index]._id,
                cartId: selectBooking.cartId._id,
              })
                .then(({ data }) => {
                  if (data.success) {
                    if (
                      typeof selectBooking.index === "number" &&
                      bookings[selectBooking.index]
                    ) {
                      bookings[selectBooking.index].cartId = data.data
                    }
                    this.setState({
                      selectBooking: { ...selectBooking, cartId: data.data },
                      bookings,
                    })
                  }
                })
                .finally(() => {
                  this.setState({ isloading: false })
                })
            }}
            onServiceDelete={(index) => {
              this.setState({ isloading: true })
              removeExtra({
                extraId: selectBooking.extras[index]._id,
                bookingId: selectBooking._id,
              })
                .then(({ data }) => {
                  if (data.success) {
                    if (
                      typeof selectBooking.index === "number" &&
                      bookings[selectBooking.index]
                    ) {
                      bookings[selectBooking.index].extras = data.data.extras
                    }
                    this.setState({
                      selectBooking: {
                        ...selectBooking,
                        extras: data.data.extras,
                      },
                      bookings,
                    })
                  }
                })
                .finally(() => {
                  this.setState({ isloading: false })
                })
            }}
            onChangeAdjustedTime={this.onChangeAdjustedTime}
            onNotesClick={() => this.setState({ showNotePopUp: true })}
            onAddClick={() => this.setState({ showExtrasModal: true })}
            companyTaxes={allSaloonTaxMap[selectBooking.companyId._id]}
            toggleDepositRequired={this.toggleDepositRequired}
            toggleTaxes={this.toggleTaxes}
            onChangeTip={this.onChangeTip}
          ></AppointmentModal>
        </>
      )
    } else {
      return null
    }
  }

  renderExtras = () => {
    const { showExtrasModal } = this.state
    if (showExtrasModal) {
      const { selectBooking, allSaloonMap, bookings, allProductMap } =
        this.state
      let products = getNestedValue(
        allProductMap,
        [selectBooking.companyId._id],
        []
      )

      let services = getNestedValue(
        allSaloonMap,
        [selectBooking.companyId._id, "companyServices"],
        []
      ).map((service) => ({
        _id: service.serviceId._id,
        price: service.price,
        serviceId: service.serviceId,
        name: service.serviceId.name,
      }))
      const onUpdate = (data) => {
        this.setState({ ...data })
      }
      return (
        <AddExtrasModal
          selectBooking={selectBooking}
          products={products}
          bookings={bookings}
          allServices={services}
          onUpdate={onUpdate}
          handleClose={() => {
            this.setState({ showExtrasModal: false })
          }}
        />
      )
    } else {
      return null
    }
  }

  handleUpdate = async (extras = {}, notifyCustomer) => {
    const {
      paymentMethod,
      selectBooking,
      bookingStaus,
      notes,
      tip,
      editDetails,
    } = this.state

    let { allAppoin } = this.state
    if (editDetails) {
      const {
        newDate,
        selectDateAndTime,
        selectedTime,
        newTime,
        selectEmployee,
        selectedService,
      } = this.state
      if (!selectEmployee) {
        this.setState({
          formErrors: {
            selectEmployeeError: "Please select Employee",
          },
        })
        setTimeout(() => {
          this.setState({
            formErrors: {
              selectEmployeeError: "",
            },
          })
        }, 8000)
        return true
      } else if (!selectDateAndTime) {
        this.setState({
          formErrors: {
            selectDateAndTimeError: "Please select Date",
          },
        })
        setTimeout(() => {
          this.setState({
            formErrors: {
              selectDateAndTimeError: "",
            },
          })
        }, 8000)
      } else if (!selectedTime) {
        this.setState({
          formErrors: {
            selectedTimeError: "Please select Time",
          },
        })
        setTimeout(() => {
          this.setState({
            formErrors: {
              selectedTimeError: "",
            },
          })
        }, 8000)
      } else if (
        !newTime ||
        !newTime.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)
      ) {
        this.setState({
          formErrors: {
            selectedTimeError: "Invalid, expected hh:mm",
          },
        })
        setTimeout(() => {
          this.setState({
            formErrors: {
              selectedTimeError: "",
            },
          })
        }, 8000)
      } else if (!selectedService) {
        this.setState({
          formErrors: {
            selectServiceError: "Please select Service",
          },
        })
        setTimeout(() => {
          this.setState({
            formErrors: {
              selectServiceError: "",
            },
          })
        }, 8000)
      } else {
        const services = [
          {
            serviceId: selectedService._id,
            categoryId: selectedService.serviceId?._id,
            date: newDate,
            time: newTime,
            employeeId: selectEmployee,
          },
        ]
        try {
          const { data } = await editBooking({
            bookingId: selectBooking._id,
            status: selectBooking.status,
            services,
            notifyCustomer: notifyCustomer ? true : false,
            isFinalized: selectBooking.isFinalized,
            totalAmount: selectedService.price,
          })
          let newSelectBooking = selectBooking
          if (data.success) {
            const newBooking = data.data
            this.setState({
              isloading: false,
              allAppoin: [...allAppoin],
              selectBooking: newSelectBooking,
              editDetails: false,
            })
            SuccessHelper.handleSuccess("Updated Successfully", true)
            if (
              (data.data.status == 4 && data.data.isFinalized) ||
              data.data.status == 3
            ) {
              if (typeof selectBooking.index === "number") {
                allAppoin.splice(selectBooking.index, 1)
              } else {
                const booking = allAppoin.find(
                  (booking) => booking._id == selectBooking._id
                )
                allAppoin.splice(booking.index, 1)
              }
              allAppoin.map((booking, index) => (booking.index = index))
            } else {
              newSelectBooking = {
                ...selectBooking,
                ...data.data,
                index: selectBooking.index,
              }
              allAppoin[selectBooking.index] = newSelectBooking
            }
            this.setState({
              isloading: false,
              allAppoin: [...allAppoin],
              selectBooking: newSelectBooking,
              editDetails: false,
            })
            SuccessHelper.handleSuccess("Updated Successfully", true)
          } else {
            this.setState({ isloading: false })
            ErrorHelper.handleErrors(data.msg, true)
          }
        } catch (error) {
          console.log(error)
          this.setState({ isloading: false })
          if (error.data?.msg) {
            ErrorHelper.handleErrors(error.data.msg, true)
          } else {
            alert(
              error.response ? error.response.data.msg : "Something Went Wrong!"
            )
          }
        }
      }
    } else {
      const sendRequst = async (isFinalized) => {
        const taxes = []
        const payload = {
          bookingId: selectBooking._id,
          totalAmount: parseInt(selectBooking.totalAmount),
          paymentMethod,
          status: bookingStaus,
          notes,
          tip: parseFloat(tip),
          taxes,
          isFinalized,
          ...extras,
        }
        console.log({ payload })

        this.setState({ isloading: true })

        try {
          const { data } = await editBooking(payload)
          let newSelectBooking = selectBooking
          if (data.success) {
            console.log(data.data.status)
            const newBooking = data.data
            newBooking.services = newBooking.services.map((service) => {
              return {
                ...service,
              }
            })
            if (
              (data.data.status == 4 && data.data.isFinalized) ||
              data.data.status == 3
            ) {
              allAppoin.splice(selectBooking.index, 1)
              allAppoin = allAppoin.map((booking, index) => ({
                ...booking,
                index,
              }))
            } else {
              newSelectBooking = { ...newBooking, index: selectBooking.index }
              allAppoin[selectBooking.index] = newSelectBooking
            }
          }
          this.setState({
            isloading: false,
            allAppoin: [...allAppoin],
            selectBooking: newSelectBooking,
            editDetails: false,
          })
          SuccessHelper.handleSuccess("Updated Successfully", true)
          this.setState({
            showPopUp: false,
            isloading: false,
          })
        } catch (error) {
          console.log(error)
          this.setState({ isloading: false })
          if (error.data.msg) {
            ErrorHelper.handleErrors(error.data.msg, true)
          } else {
            alert(
              error.response ? error.response.data.msg : "Something Went Wrong!"
            )
          }
        }
      }
      if (bookingStaus == 4) {
        Swal.fire({
          title: i18n.t("are_you_sure_you_want_to_finalize_this_booking"),
          showCancelButton: true,
          cancelButtonText: "No",
          confirmButtonText: "Yes",
        }).then((e) => {
          const { dismiss, isConfirmed, isDismissed } = e
          if (!isDismissed || dismiss != "backdrop") {
            if (isConfirmed) {
              sendRequst(isConfirmed)
              this.setState(
                {
                  showInsertPayment: true,
                },
                () => {
                  this.setState({
                    showInsertPayment: false,
                  })
                }
              )
            } else {
              sendRequst(isConfirmed)
            }
          }
        })
      } else {
        sendRequst(false)
      }
    }
  }

  renderEditDetails = () => {
    const { editDetails } = this.state
    return (
      <BaseModal
        open={editDetails}
        onClose={() => this.setState({ editDetails: false })}
        title={i18n.t("edit")}
        containerClassName={"base-model-container"}
        content={this.renderEmployeeDetail()}
        actions={
          <div className="row mx-0 justify-content-end">
            {this.state.openUpdateModify ? (
              <ModifyModal
                handleClose={() => this.setState({ openUpdateModify: false })}
                onSubmit={({ notifyCustomer }) => {
                  this.handleUpdate({}, notifyCustomer)
                  this.setState({ openUpdateModify: false })
                }}
              />
            ) : null}
            <Button
              onClick={() => this.setState({ openUpdateModify: true })}
              style={{
                backgroundColor: "#ff3600",
                color: "white",
              }}
            >
              {i18n.t("update")}
            </Button>
          </div>
        }
      />
    )
  }
  getEmployeeData = () => {
    this.setState({ isloading: true })
    getEmployeeDetails()
      .then((res) => {
        if (res.data.success) {
          for (let i = 0; i < res.data.data.details.weekPlans.length; i++) {
            res.data.data.details.weekPlans[i].dayName = weekNames[i]
          }
          this.setState({
            isloading: false,
            employeesWorkingHoursData: res.data.data.details.weekPlans,
            employeeServicesList: res.data.data.companyEmployee.companyServices,
          })
        }
      })
      .catch((error) => {
        console.log(error)
        this.setState({ isloading: false })
      })
  }

  renderEmployeeDetail = () => {
    const {
      formErrors,
      selectDateAndTime,
      employeesWorkingHoursData,
      selectedService,
      employeeServicesList,
    } = this.state

    const employeeServicesListMap = employeeServicesList?.map((service) => {
      return service.serviceId
    })

    return (
      <div className="row px-0 mx-0">
        <div className={"form-label-group mb-3 col-sm-6 col-xs-12"}>
          <DatePicker
            popperProps={{
              positionFixed: true, // use this to make the popper position: fixed
            }}
            className="full-width"
            minDate={new Date()}
            selected={selectDateAndTime}
            onChange={(date) => this.onDateChange(date)}
            value={this.state.selectDateAndTime}
            filterDate={(date) => {
              return employeesWorkingHoursData.availableStatus != 0
            }}
            customInput={
              <TextField
                id="time"
                label={"Date"}
                value={this.state.newDate}
                className="full-width"
                readOnly={true}
                helperText={formErrors?.selectDateAndTimeError}
                error={!!formErrors?.selectDateAndTimeError}
              />
            }
            placeholderText={i18n.t(
              "container.services_click_to_select_a_date"
            )}
          />
        </div>

        {this.renderTimeSelection()}
        {
          <div className={"form-label-group mb-3 col-sm-6 col-xs-12"}>
            <FormControl className="full-width">
              <Autocomplete
                options={Object.values(employeeServicesListMap)}
                blurOnSelect
                value={selectedService}
                onChange={(e, value) => {
                  this.setState({ selectedService: value })
                }}
                getOptionLabel={(option) => option.name}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={i18n.t("services")}
                    helperText={formErrors?.selectServiceError}
                    error={!!formErrors?.selectServiceError}
                    fullWidth
                  />
                )}
              />
            </FormControl>
          </div>
        }
      </div>
    )
  }
  onChangeTime = (text) => {
    this.setState({
      selectedTime: text.target.value,
      newTime: text.target.value.split(" ")[0],
    })
  }
  renderTimeSelection = () => {
    const { formErrors, availableTimeslots, selectedTime } = this.state
    if (selectedTime === undefined) {
      return null
    }
    return (
      <div className={"form-label-group mb-3 col-sm-6 col-xs-12"}>
        <FormControl
          error={!!formErrors?.selectedTimeError}
          className="full-width"
        >
          <InputLabel id="services">{i18n.t("container.time")}</InputLabel>
          <Select
            value={selectedTime}
            onChange={(text) => this.onChangeTime(text)}
            placeholder={i18n.t("container.select_time")}
          >
            {Object.keys(availableTimeslots ? availableTimeslots : {})?.map(
              (val, ind) => {
                return (
                  <MenuItem key={ind} value={val}>
                    {val.split(" ")[0]}
                  </MenuItem>
                )
              }
            )}
          </Select>
          {!!formErrors.selectedTimeError && (
            <FormHelperText>{formErrors.selectedTimeError}</FormHelperText>
          )}
        </FormControl>
      </div>
    )
  }
  onDateChange = async (selectDate) => {
    this.setState({
      selectedTime: undefined,
      availableTimeslots: {},
    })
    let date = undefined
    if (selectDate) {
      date = moment(selectDate).format("MM-DD-YYYY")
      const { serviceData, selectSalon, employeeId } = this.state
      await this.props.get_available_timeslots({
        data: {
          companyId: selectSalon,
          companyServiceId: serviceData._id,
          companyEmployeeId: employeeId,
          date,
        },
      })
    }
    this.setState({
      selectDateAndTime: selectDate,
      newDate: date,
    })
  }
  renderNotePopup = () => {
    const { showNotePopUp, selectBooking = {}, allSaloonMap = {} } = this.state
    if (showNotePopUp) {
      return (
        <>
          <BaseModal
            open={showNotePopUp}
            {...{
              title: (
                <div
                  dangerouslySetInnerHTML={{
                    __html: i18n.t("notes_and_reminders"),
                  }}
                />
              ),
              content: (
                <BookingNotesReminders
                  booking={selectBooking}
                  hasEditPermission={
                    allSaloonMap[selectBooking.companyId._id]
                      ?.isEmployeeIndependent
                  }
                />
              ),
            }}
            onClose={() => this.setState({ showNotePopUp: false })}
          />
        </>
      )
    } else {
      return null
    }
  }

  renderAvaiableEmployee = () => {
    const { employeeSalon } = this.state
    const { screenSize } = this.context
    return (
      <>
        <div className="col-12 col-sm-6 col-md-6 col-lg-5 offset-md-0 offset-lg-0 px-0">
          {screenSize !== "mobile" ? this._renderCategoriesCard() : null}
          <div className="d-flex row justify-content-between active-salon-title mb-3">
            <span className="dashboard-table-title">
              <Translation>
                {(t) => t("container.employe_dashboard_active_salon")}
              </Translation>
            </span>
            <div>
              <Button
                className="add_employee_btn_active "
                onClick={() =>
                  this.props.history.push("/employee-active-saloon")
                }
              >
                <Translation>
                  {(t) => t("container.employe_dashboard_see_all_salon")}
                </Translation>
              </Button>
            </div>
          </div>
          <BasicTable
            rows={employeeSalon}
            schema={[
              {
                header: (
                  <Translation>
                    {(t) => t("container.employe_dashboard_salon_name")}
                  </Translation>
                ),
                field: "name",
                render: (val) => {
                  return val?.companyId?.name
                },
              },
              {
                header: "ID",
                field: "id",
                render: (val) => {
                  return val?.companyId?._id
                },
              },
              {
                header: (
                  <Translation>
                    {(t) => t("container.employe_dashboard_phone_number")}
                  </Translation>
                ),
                field: "phoneNo",
                accessor: (val) => {
                  return val?.companyId?.businessTelephone
                },
              },
            ]}
          />
        </div>
      </>
    )
  }
  _renderCard = () => {
    return (
      <>
        <div className="row gap-5">
          {this.renderAppointmentCard()}
          {this.renderAvaiableEmployee()}
        </div>
      </>
    )
  }
  render() {
    return (
      <div>
        {this.renderLoader()}
        <div className="content-container p-4">
          <h1 className="saloon-dashboard-heading mb-4">
            <Translation>
              {(t) => t("container.employe_dashboard_for_employee")}
            </Translation>
          </h1>
          {this._renderCard()}
          {this.renderPopup()}
          {this.renderNotePopup()}
          {this.renderEditDetails()}
        </div>
      </div>
    )
  }
}

_Dashboard.contextType = ScreenSizeContext

const mapStateToProps = (state) => ({
  getAvailableTimeslots: state.getAvailableTimeslots,
})

const action = {
  get_available_timeslots,
}

const ConnectDashboard = connect(mapStateToProps, action)(_Dashboard)

export default function Dashboard(props) {
  const { getResource } = useCache()
  const companies = getResource((cache) => cache.employee.companies)
  const taxMap = getResource((cache) => cache.employee.taxMap)
  const productMap = getResource((cache) => cache.employee.productMap)
  return (
    <ConnectDashboard
      {...props}
      companies={companies}
      taxMap={taxMap}
      productMap={productMap}
    />
  )
}
