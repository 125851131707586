// @flow

import { DISCOUNT_CODE } from "../ActionTypes"

export function upsertRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: DISCOUNT_CODE.UPSERT_SAGA,
  }
}

export function upsert(data) {
  return {
    data,
    type: DISCOUNT_CODE.UPSERT,
  }
}

export function getRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: DISCOUNT_CODE.GET_SAGA,
  }
}

export function get(data) {
  return {
    data,
    type: DISCOUNT_CODE.GET,
  }
}

export function removeRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: DISCOUNT_CODE.REMOVE_SAGA,
  }
}

export function remove(data) {
  return {
    data,
    type: DISCOUNT_CODE.REMOVE,
  }
}

export function requestFailure() {
  return {
    type: DISCOUNT_CODE.REQUEST_FAILURE,
  }
}
