import { put, takeEvery } from "redux-saga/effects"

import * as types from "../../actions/ActionTypes"
import {
  insert,
  get,
  getRequest,
  requestFailure,
  getAccountDetails,
} from "../../actions/Employee/EmployeeFundsRequest"

import axios from "axios"
import {
  request_account_payout,
  get_funds_requests,
  get_account_totals,
} from "../../../config/WebServices"

function* getSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.get(get_funds_requests, {
      params: payload,
    })
    data = data.data

    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data)
      }
      yield put(get(data.data))
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data)
      }
      yield put(requestFailure(data))
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* insertSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.post(request_account_payout, payload)
    data = data.data
    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data)
      }
      yield put(getRequest({}))
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data)
      }
      yield put(requestFailure(data))
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* accountDetailsSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.get(get_account_totals)
    data = data.data
    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data)
      }
      yield put(getAccountDetails(data.data))
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data)
      }
      yield put(requestFailure(data))
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

export default function* root() {
  yield takeEvery(types.EMPLOYEE_FUNDS_REQUEST.GET_SAGA, getSaga)
  yield takeEvery(types.EMPLOYEE_FUNDS_REQUEST.INSERT_SAGA, insertSaga)
  yield takeEvery(
    types.EMPLOYEE_FUNDS_REQUEST.ACCOUNT_DETAILS_SAGA,
    accountDetailsSaga
  )
}
