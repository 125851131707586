import * as types from "../actions/ActionTypes"

function ActionBuilder() {
  const build = ({ type }) => {
    return {
      upsertRequest: (payload, callbackMap) => {
        return {
          payload,
          callbackMap,
          type: types[type].UPSERT_SAGA,
        }
      },

      upsert: (data) => {
        return {
          data,
          type: types[type].UPSERT,
        }
      },

      getRequest: (payload, callbackMap) => {
        return {
          payload,
          callbackMap,
          type: types[type].GET_SAGA,
        }
      },

      get: (data) => {
        return {
          data,
          type: types[type].GET,
        }
      },

      removeRequest: (payload, callbackMap) => {
        return {
          payload,
          callbackMap,
          type: types[type].REMOVE_SAGA,
        }
      },

      remove: (data) => {
        return {
          data,
          type: types[type].REMOVE,
        }
      },

      requestFailure: () => {
        return {
          type: types[type].REQUEST_FAILURE,
        }
      },
    }
  }

  return {
    build,
  }
}

const actionBuilder = ActionBuilder()

export default actionBuilder
