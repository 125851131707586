// @flow

import { LOGOUT, CLEAR_LOGOUT } from "./ActionTypes"

export function request(payload) {
  return {
    payload,
    type: LOGOUT.REQUEST,
  }
}

export function success(data) {
  return {
    data,
    type: LOGOUT.SUCCESS,
  }
}

export function failure(errorMessage) {
  return {
    errorMessage,
    type: LOGOUT.FAILURE,
  }
}

export function clearLogout() {
  return {
    type: CLEAR_LOGOUT,
  }
}
