import { connect } from "react-redux"
import React from "react"
import { Route, Switch, Redirect } from "react-router-dom"
// import CryptoJS from "crypto-js";

import Dashboard from "../containers/Saloon/Dashboard"
import EditProfile from "../containers/Saloon/EditProfile"
import AllServices from "../containers/Saloon/AllServices"
import AddService from "../containers/Saloon/AddService"
import AllProducts from "../containers/Saloon/Products/AllProducts"
import AddProduct from "../containers/Saloon/Products/AddProduct"
import ImportedCustomer from "../containers/Saloon/ImportCustomer"

import AddWorkingHours from "../containers/Saloon/AddWorkingHours"
import UploadMedia from "../containers/Saloon/UploadMedia"
import BlackList from "../containers/Saloon/BlackList"
import SaloonBooking from "../containers/Saloon/Booking"
import Rating from "../containers/Saloon/Rating/Rating"
import BookingReport from "../containers/Saloon/BookingReport"
import TaxReport from "../containers/Saloon/TaxReport"
import SalesReport from "../containers/Saloon/SalesReport"
import EmployeeReport from "../containers/Saloon/EmployeeReport"
import MyAppointment from "../containers/Saloon/MyAppointments"
import Wallet from "../containers/Saloon/Wallet"
import LiveDisplaySettings from "../containers/Saloon/LiveDisplaySettings"
import Access from "../containers/Saloon/Security/Access"

import AllCustomers from "../containers/Saloon/AllCustomers"
import AddCustomer from "../containers/Saloon/AddCustomer"

// import { secret_key } from "../config/SecretKeys";
import ChooseTemplate from "./../containers/Saloon/ChooseTemplate/index"
import SiteSetUp from "./../containers/Saloon/SiteSetUp/index"
import MySite from "./../containers/Saloon/MySite/index"
import PricingPlan from "./../containers/Saloon/Pricing&Plan/index"
import WaitingList from "../containers/Saloon/WaitingList"
import ManageUnavailibilites from "../containers/Saloon/ManageUnavailibilites"
import AccountSettings from "../containers/Saloon/AccountSettings"
import BillingHistory from "../containers/Saloon/BillingHistory"
import DevicesAndEquipment from "../containers/Saloon/DevicesAndEquipment"
import RequestFunds from "../containers/Saloon/RequestFunds"
import Orders from "../containers/Saloon/Orders"
import PayrollReport from "../containers/Saloon/PayrollReport/PayrollReport.container"
import RefundPolicy from "../containers/Saloon/RefundPolicy"
import Vendor from "../containers/Saloon/Vendor/Vendor.container"
import Expense from "../containers/Saloon/Expense/Expense.container"
import ExpenseReport from "../containers/Saloon/ExpenseReport/ExpenseReport.container"

// Components
import OnlinePayments from "../containers/Saloon/OnlinePayments"
import Packages from "../containers/Saloon/Packages/Packages.container"
import GiftCards from "../containers/Saloon/GiftCards/GiftCards.container"
import Form from "../containers/Saloon/Form/Form.container"
import FormBuilder from "../containers/Saloon/FormBuilder/FormBuilder.container"
import CommunicationSettings from "../containers/Saloon/CommunicationSettings/CommunicationSettings.container"
import Roles from "../containers/Saloon/Roles/Roles.container"
import PrivateRoute from "./components/PrivateRoute.component"
import ProductReport from "../containers/Saloon/ProductReport/ProductReport.container"
import PackageReport from "../containers/Saloon/PackageReport/PackageReport.container"
import GiftCardReport from "../containers/Saloon/GiftCardReport/GiftCardReport"
import CommissionReport from "../containers/Saloon/CommissionReport/CommissionReport"
import _Class from "../containers/Saloon/Class/Class"
import Program from "../containers/Saloon/Program/Program"
import Student from "../containers/Saloon/Student/Student"
import StudentGroup from "../containers/Saloon/StudentGroup/StudentGroup"
import CoursePlanner from "../containers/Saloon/CoursePlanner/CoursePlanner"
import Semester from "../containers/Saloon/Semester/Semester"
import NoShowReport from "../containers/Saloon/NoShowReport/NoShowReport"
import EmployeeSection from "../containers/Saloon/EmployeeSection/EmployeeSection"
import SaloonSocialMedia from "../containers/Saloon/LinkSocialMedia/SaloonSocialMedia"
import { functionHiddenItemsMap } from "../components/Saloon/SidebarContent/SaloonSidebarMenu.component"
import { ActiveMenuTypeContext } from "../context/ActiveMenuType"
import AllTaxes from "../containers/Saloon/Taxes/AllTaxes.jsx"
import AddTax from "../containers/Saloon/Taxes/AddTax"
import CustomerGroups from "../containers/Saloon/CustomerGroups/CustomerGroups"
import Subscription from "../containers/Saloon/Subscription/Subscription"
import ScheduleBuilder from "../components/Partner/ScheduleBuilder/ScheduleBuilder"
import GeneratedScheduleApproval from "../containers/Saloon/GeneratedScheduleApproval/GeneratedScheduleApproval"
import ScheduleTemplateBuilder from "../components/Partner/ScheduleTemplateBuilder/ScheduleTemplateBuilder"
import AiSettings from "../containers/Saloon/AiSettings/AiSettings"
import CommunicationsMarketing from "../containers/Saloon/CommunicationsMarketing/CommunicationsMarketing"
import CommunicationsMarketingCampaign from "../containers/Saloon/CommunicationsMarketingCampaign/CommunicationsMarketingCampaign"
import CommunicationsMarketingTemplate from "../containers/Saloon/CommunicationsMarketingTemplate/CommunicationsMarketingTemplate"
import Promotions from "../containers/Saloon/Promotions/Promotions"
import DiscountCodes from "../containers/Saloon/DiscountCodes/DiscountCodes"
import IntakeCategories from "../containers/Saloon/IntakeCategories/IntakeCategories"
import CustomerAgeGroup from "../containers/Saloon/CustomerAgeGroup/CustomerAgeGroup"
import CustomerDistrict from "../containers/Saloon/CustomerDistrict/CustomerDistrict"
import CustomerOrigin from "../containers/Saloon/CustomerOrigin/CustomerOrigin"
import CustomerStatus from "../containers/Saloon/CustomerStatus/CustomerStatus"
import TimePeriod from "../containers/Saloon/TimePeriod/TimePeriod"
import IntakeReport from "../containers/Saloon/IntakeReport/IntakeReport"
import CustomerStats from "../containers/Saloon/CustomerStats/CustomerStats"
import IntakeType from "../containers/Saloon/IntakeType/IntakeType"
import Activity from "../containers/Saloon/Activity/Activity"
import ActivityScheduleTemplates from "../containers/Saloon/ActivityScheduleTemplates/ActivityScheduleTemplates"
import ActivityScheduleBuilder from "../containers/Saloon/ActivityScheduleBuilder/ActivityScheduleBuilder"
import ActivityCategory from "../containers/Saloon/ActivityCategory/ActivityCategory"
import ActivityCategoryReport from "../containers/Saloon/ActivityCategoryReport/ActivityCategoryReport"
import ActivityAttendanceReport from "../containers/Saloon/ActivityAttendanceReport/ActivityAttendanceReport"
import ActivityReport from "../containers/Saloon/ActivityReport/ActivityReport"
import CustomerSubscriptionReport from "../containers/Saloon/CustomerSubscriptionReport/CustomerSubscriptionReport"
import StaffMeeting from "../containers/Saloon/StaffMeeting/StaffMeeting"
import CustomerReference from "../containers/Saloon/CustomerReference/CustomerReference"
import { ROLES } from "../util/enum"
import SharedStorage from "../helpers/Storage"
import RatingForm from "../containers/Saloon/RatingForm/RatingForm"
class SaloonRoutes extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      user: null,
    }
  }

  async componentDidMount() {
    const saloonData = JSON.parse(await SharedStorage.getItem("saloon"))
    if (saloonData && saloonData.access_token) {
      this.setState({ user: saloonData })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.saloonLogin) {
      if (
        !nextProps.saloonLogin.failure &&
        !nextProps.saloonLogin.isFetching &&
        !nextProps.saloonLogin.errorMessage &&
        nextProps.saloonLogin.data &&
        nextProps.saloonLogin.data.data &&
        nextProps.saloonLogin.data.data.access_token &&
        nextProps.saloonLogin.data.data.role == 3
      ) {
        this.setState({ user: nextProps.saloonLogin.data.data })
      }
    }
    if (nextProps.impersonateSalon) {
      if (
        !nextProps.impersonateSalon.failure &&
        !nextProps.impersonateSalon.isFetching &&
        !nextProps.impersonateSalon.errorMessage &&
        nextProps.impersonateSalon.data &&
        nextProps.impersonateSalon.data.data &&
        nextProps.impersonateSalon.data.data.access_token &&
        this.props.impersonateSalon.isFetching
      ) {
        let data = nextProps.impersonateSalon.data.data
        const { isCompanyProfile } = data
        if (
          data.role &&
          (data.role.includes(ROLES.OWNER) ||
            data.role.includes(ROLES.EMPLOYEE))
        ) {
          if (isCompanyProfile) {
            !data.company.isFreeTrial
              ? this.handleNavigation("/saloon-pricing-plains")
              : this.handleNavigation("/saloon-pricing-plains")
          }
        }

        this.setState({ user: nextProps.impersonateSalon.data.data })
      }
    }
  }

  handleNavigation = (route) => {
    this.props.history.push(route)
  }

  PrivateRoute = ({ component: Component, path, resource }) => {
    const { functionRole } = this.context
    if (
      functionHiddenItemsMap[functionRole] &&
      functionHiddenItemsMap[functionRole][resource]
    ) {
      return null
    }
    return (
      <PrivateRoute
        {...this.props}
        path={path}
        Component={Component}
        type="saloon"
        resource={resource}
      />
    )
  }

  render() {
    return (
      <Switch>
        {this.PrivateRoute({
          path: "/saloon-dashboard",
          component: Dashboard,
          resource: "Dashboard",
        })}
        {this.PrivateRoute({
          path: "/saloon-edit-profile",
          component: EditProfile,
          resource: "Profile",
        })}
        {this.PrivateRoute({
          path: "/saloon-add-employee",
          component: EmployeeSection,
          resource: "AddEmployee",
        })}
        {this.PrivateRoute({
          path: "/saloon-company-employee",
          component: EmployeeSection,
          resource: "EmployeeList",
        })}
        {this.PrivateRoute({
          path: "/saloon-my-appointment",
          component: MyAppointment,
          resource: "Booking",
        })}
        {this.PrivateRoute({
          path: "/saloon-add-service",
          component: AddService,
          resource: "AddService",
        })}
        {this.PrivateRoute({
          path: "/saloon-all-services",
          component: AllServices,
          resource: "AllServices",
        })}
        {this.PrivateRoute({
          path: "/saloon-add-inventory",
          component: AddProduct,
          resource: "AddInvetory",
        })}
        {this.PrivateRoute({
          path: "/saloon-my-inventory",
          component: AllProducts,
          resource: "Inventory",
        })}
        {this.PrivateRoute({
          path: "/saloon-working-hours",
          component: AddWorkingHours,
          resource: "WorkingHours",
        })}
        {this.PrivateRoute({
          path: "/saloon-upload-media",
          component: UploadMedia,
          resource: "UploadMedia",
        })}

        {this.PrivateRoute({
          path: "/saloon-link-social-accounts",
          component: SaloonSocialMedia,
          resource: "SocialLinks",
        })}

        {this.PrivateRoute({
          path: "/saloon-security",
          component: Access,
          resource: "Security",
        })}
        {this.PrivateRoute({
          path: "/saloon-site-set-up",
          component: SiteSetUp,
          resource: "SiteSetUp",
        })}
        {this.PrivateRoute({
          path: "/saloon-my-site",
          component: MySite,
          resource: "SiteSetUp",
        })}
        {this.PrivateRoute({
          path: "/saloon-choose-a-template",
          component: ChooseTemplate,
          resource: "Templates",
        })}
        {this.PrivateRoute({
          path: "/saloon-booking",
          component: SaloonBooking,
          resource: "Booking",
        })}
        {this.PrivateRoute({
          path: "/saloon-booking-report",
          component: BookingReport,
          resource: "BookingReport",
        })}
        {this.PrivateRoute({
          path: "/saloon-tax-report",
          component: TaxReport,
          resource: "TaxReport",
        })}
        {this.PrivateRoute({
          path: "/saloon-sales-report",
          component: SalesReport,
          resource: "SalesReport",
        })}
        {this.PrivateRoute({
          path: "/saloon-pricing-plains",
          component: PricingPlan,
          resource: "PricingPlans",
        })}
        {this.PrivateRoute({
          path: "/saloon-customers",
          component: AllCustomers,
          resource: "Customers",
        })}
        {this.PrivateRoute({
          path: "/saloon-add-customer",
          component: AddCustomer,
          resource: "AddCustomer",
        })}
        {this.PrivateRoute({
          path: "/saloon-taxes",
          component: AllTaxes,
          resource: "TaxList",
        })}
        {this.PrivateRoute({
          path: "/saloon-add-tax",
          component: AddTax,
          resource: "TaxList",
        })}

        {this.PrivateRoute({
          path: "/saloon-import-customers",
          component: ImportedCustomer,
          resource: "ImportCustomer",
        })}
        {this.PrivateRoute({
          path: "/saloon-customer-groups",
          component: CustomerGroups,
          resource: "Customers",
        })}
        {this.PrivateRoute({
          path: "/saloon-account-settings",
          component: AccountSettings,
          resource: "AccountSettings",
        })}
        {this.PrivateRoute({
          path: "/saloon-billing-history",
          component: BillingHistory,
          resource: "BillingHistory",
        })}
        {this.PrivateRoute({
          path: "/saloon-devices-and-equipment",
          component: DevicesAndEquipment,
          resource: "DevicesAndEquipment",
        })}
        {this.PrivateRoute({
          path: "/saloon-form-builder/:formId",
          component: FormBuilder,
          resource: "Form",
        })}
        {this.PrivateRoute({
          path: "/saloon-forms",
          component: Form,
          resource: "Form",
        })}

        {this.PrivateRoute({
          path: "/saloon-all-funds-requests",
          component: RequestFunds,
          resource: "FundRequests",
        })}
        {this.PrivateRoute({
          path: "/saloon-orders",
          component: Orders,
          resource: "Orders",
        })}
        {this.PrivateRoute({
          path: "/saloon-refund-policy",
          component: RefundPolicy,
          resource: "RefundPolicy",
        })}
        {this.PrivateRoute({
          path: "/saloon-payroll-report",
          component: PayrollReport,
          resource: "PayrollReport",
        })}
        {this.PrivateRoute({
          path: "/saloon-vendor",
          component: Vendor,
          resource: "Vendor",
        })}
        {this.PrivateRoute({
          path: "/saloon-expense",
          component: Expense,
          resource: "Expense",
        })}
        {this.PrivateRoute({
          path: "/saloon-expense-report",
          component: ExpenseReport,
          resource: "ExpenseReport",
        })}
        {this.PrivateRoute({
          path: "/saloon-communication-settings",
          component: CommunicationSettings,
          resource: "CommunicationSettings",
        })}
        {this.PrivateRoute({
          path: "/saloon-roles",
          component: Roles,
          resource: "Roles",
        })}
        {this.PrivateRoute({
          path: "/saloon-product-report",
          component: ProductReport,
          resource: "ProductReport",
        })}
        {this.PrivateRoute({
          path: "/saloon-package-report",
          component: PackageReport,
          resource: "PackageReport",
        })}
        {this.PrivateRoute({
          path: "/saloon-gift-card-report",
          component: GiftCardReport,
          resource: "GiftCardReport",
        })}
        {this.PrivateRoute({
          path: "/saloon-commission-report",
          component: CommissionReport,
          resource: "CommissionReport",
        })}
        {this.PrivateRoute({
          path: "/saloon-no-show-report",
          component: NoShowReport,
          resource: "NoShowReport",
        })}
        {this.PrivateRoute({
          path: "/saloon-classes",
          component: _Class,
          resource: "Program",
        })}
        {this.PrivateRoute({
          path: "/saloon-programs",
          component: Program,
          resource: "Program",
        })}
        {this.PrivateRoute({
          path: "/saloon-students",
          component: Student,
          resource: "Program",
        })}
        {this.PrivateRoute({
          path: "/saloon-student-groups",
          component: StudentGroup,
          resource: "Program",
        })}
        {this.PrivateRoute({
          path: "/saloon-program-planner",
          component: CoursePlanner,
          resource: "Program",
        })}
        {this.PrivateRoute({
          path: "/saloon-semester",
          component: Semester,
          resource: "Program",
        })}

        {this.PrivateRoute({
          path: "/saloon-rating",
          component: Rating,
          resource: "Rating",
        })}
        {this.PrivateRoute({
          path: "/saloon-rating-form",
          component: RatingForm,
          resource: "RatingForm",
        })}
        {this.PrivateRoute({
          path: "/saloon-employee-report",
          component: EmployeeReport,
          resource: "EmployeeReport",
        })}
        {this.PrivateRoute({
          path: "/saloon-wallet",
          component: Wallet,
          resource: "Wallet",
        })}
        {this.PrivateRoute({
          path: "/saloon-live-display-settings",
          component: LiveDisplaySettings,
          resource: "LiveDisplaySettings",
        })}
        {this.PrivateRoute({
          path: "/saloon-waiting-list",
          component: WaitingList,
          resource: "WaitingList",
        })}
        {this.PrivateRoute({
          path: "/company-manage-unavailabilities",
          component: ManageUnavailibilites,
          resource: "WorkingHours",
        })}
        {this.PrivateRoute({
          path: "/saloon-online-payments",
          component: OnlinePayments,
          resource: "OnlinePayments",
        })}
        {this.PrivateRoute({
          path: "/saloon-black-listing-settings",
          component: BlackList,
          resource: "BlackListSettings",
        })}
        {this.PrivateRoute({
          path: "/saloon-packages",
          component: Packages,
          resource: "Packages",
        })}
        {this.PrivateRoute({
          path: "/saloon-gift-cards",
          component: GiftCards,
          resource: "GiftCards",
        })}
        {this.PrivateRoute({
          path: "/saloon-subscriptions",
          component: Subscription,
          resource: "Subscriptions",
        })}
        {this.PrivateRoute({
          path: "/saloon-ai-schedule",
          component: ScheduleBuilder,
          resource: "AiSchedule",
        })}
        {this.PrivateRoute({
          path: "/saloon-ai-approval",
          component: GeneratedScheduleApproval,
          resource: "AiScheduleApproval",
        })}
        {this.PrivateRoute({
          path: "/saloon-ai-schedule-templates",
          component: ScheduleTemplateBuilder,
          resource: "AiScheduleTemplate",
        })}
        {this.PrivateRoute({
          path: "/saloon-ai-settings",
          component: AiSettings,
          resource: "AiSettings",
        })}
        {this.PrivateRoute({
          path: "/saloon-comm-marketing-account",
          component: CommunicationsMarketing,
          resource: "Marketing",
        })}
        {this.PrivateRoute({
          path: "/saloon-comm-marketing-campaigns",
          component: CommunicationsMarketingCampaign,
          resource: "Marketing",
        })}
        {this.PrivateRoute({
          path: "/saloon-comm-marketing-templates",
          component: CommunicationsMarketingTemplate,
          resource: "Marketing",
        })}
        {this.PrivateRoute({
          path: "/saloon-promotions",
          component: Promotions,
          resource: "Promotion",
        })}
        {this.PrivateRoute({
          path: "/saloon-discount-codes",
          component: DiscountCodes,
          resource: "DiscountCode",
        })}
        {this.PrivateRoute({
          path: "/saloon-intake-categories",
          component: IntakeCategories,
          resource: "Customers",
        })}
        {this.PrivateRoute({
          path: "/saloon-age-groups",
          component: CustomerAgeGroup,
          resource: "Customers",
        })}
        {this.PrivateRoute({
          path: "/saloon-districts",
          component: CustomerDistrict,
          resource: "Customers",
        })}
        {this.PrivateRoute({
          path: "/saloon-origins",
          component: CustomerOrigin,
          resource: "Customers",
        })}
        {this.PrivateRoute({
          path: "/saloon-customer-statuses",
          component: CustomerStatus,
          resource: "Customers",
        })}
        {this.PrivateRoute({
          path: "/saloon-time-periods",
          component: TimePeriod,
          resource: "TimePeriod",
        })}
        {this.PrivateRoute({
          path: "/saloon-intake-report",
          component: IntakeReport,
          resource: "Customers",
        })}
        {this.PrivateRoute({
          path: "/saloon-customer-stats",
          component: CustomerStats,
          resource: "Customers",
        })}
        {this.PrivateRoute({
          path: "/saloon-intake-type",
          component: IntakeType,
          resource: "Customers",
        })}
        {this.PrivateRoute({
          path: "/saloon-customer-references",
          component: CustomerReference,
          resource: "Customers",
        })}
        {this.PrivateRoute({
          path: "/saloon-activity",
          component: Activity,
          resource: "Activities",
        })}
        {this.PrivateRoute({
          path: "/saloon-activity-category",
          component: ActivityCategory,
          resource: "ActivityCategories",
        })}
        {this.PrivateRoute({
          path: "/saloon-activity-schedule-templates",
          component: ActivityScheduleTemplates,
          resource: "ActivityScheduleTemplate",
        })}
        {this.PrivateRoute({
          path: "/saloon-activity-schedule-builder",
          component: ActivityScheduleBuilder,
          resource: "ActivitiesSchedule",
        })}
        {this.PrivateRoute({
          path: "/saloon-activity-category-report",
          component: ActivityCategoryReport,
          resource: "ActivityCategoryReport",
        })}
        {this.PrivateRoute({
          path: "/saloon-activity-attendance-report",
          component: ActivityAttendanceReport,
          resource: "ActivityAttendanceReport",
        })}
        {this.PrivateRoute({
          path: "/saloon-activity-report",
          component: ActivityReport,
          resource: "ActivityReport",
        })}
        {this.PrivateRoute({
          path: "/saloon-subscription-report",
          component: CustomerSubscriptionReport,
          resource: "Customers",
        })}
        {this.PrivateRoute({
          path: "/saloon-staff-meeting",
          component: StaffMeeting,
          resource: "Booking",
        })}
      </Switch>
    )
  }
}

SaloonRoutes.contextType = ActiveMenuTypeContext

const mapStateToProps = (state) => ({
  saloonLogin: state.saloonLogin,
  impersonateSalon: state.impersonateSalon,
  user: state.user.data.saloon
    ? JSON.parse(state.user.data.saloon)
    : state.user.data.saloon,
  docked: state.sidebar.isDocked,
  sidebarOpen: state.sidebar.isOpen,
})

const action = {}

export default connect(mapStateToProps, action)(SaloonRoutes)
