import { useDispatch, useSelector } from "react-redux"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { request as employee_logout } from "../../redux/actions/Employee/LogOut"
import { request as saloon_logout } from "../../redux/actions/Saloon/LogOut"
import { clearLogout as clear_logout } from "../../redux/actions/LogOut"
import { request as get_notifications } from "../../redux/actions/GetNotifications"
import { Images } from "../../theme"
import NotificationCenter from "../NotificationCenter"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Annoucements from "../Announcements"
import "./style.scss"
import {
  AddCircle,
  CalendarToday,
  ExitToApp,
  Menu as MenuIcon,
  Notifications,
  People,
} from "@material-ui/icons"
import { Badge } from "@material-ui/core"
import { useHistory } from "react-router-dom"
import useScreenSize from "../../context/ScreenSize"
import useActiveMenuType from "../../context/ActiveMenuType"
import i18n from "../../i18n"
import useInvoice from "../../context/Invoice"
import useBooking from "../../context/Booking"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import useIntakeCustomer from "../../context/IntakeCustomer/IntakeCustomer"
import useStaffMeeting from "../../context/StaffMeeting/StaffMeeting"
import useGoogleCalendar from "../../context/GoogleCalendar"
import useOutlookCalendar from "../../context/OutlookCalendar"

const iconSize = 24
export default function LoggedInNavbar({ onSetSidebarOpen }) {
  const history = useHistory()
  const dispatch = useDispatch()
  const { screenSize } = useScreenSize()
  const { type } = useActiveMenuType()
  const { setAddOpen } = useBooking()
  const { setAddOpen: setInvoiceAddOpen } = useInvoice()
  const { setAddOpen: setStaffMeetingOpen } = useStaffMeeting()
  const { setOpen: setIntakeCustomerOpen } = useIntakeCustomer()
  const { setIsGoogleSignedIn, setGoogleEvents } = useGoogleCalendar()
  const { setIsOutlookSignedIn, setOutlookEvents, setEmployeeIdForOutlook } =
    useOutlookCalendar()
  const notificationIcon = useRef()
  const [anchor, _setAnchor] = useState()
  const [addAnchor, _setAddAnchor] = useState()

  const sidebarOpen = useSelector((state) => state.sidebar.isOpen)
  const _user = useSelector((state) => state.user.data)
  const { user, isSalon, isEmployee } = useMemo(() => {
    const saloon = _user.saloon ? JSON.parse(_user.saloon) : null
    const isSalon = saloon ? Boolean(Object.keys(saloon).length) : false
    const employee = JSON.parse(_user.employee)
    const isEmployee = Boolean(employee)
    const user = { ...saloon, ...employee }
    return {
      user,
      isSalon,
      isEmployee,
    }
  }, [_user])

  const saloonLogout = useSelector((state) => state.saloonLogout)

  useEffect(() => {
    if (saloonLogout) {
      if (
        !saloonLogout.failure &&
        !saloonLogout.isFetching &&
        !saloonLogout.errorMessage &&
        saloonLogout.data &&
        saloonLogout.data.success
      ) {
        dispatch(clear_logout({}))
        this.handleNavigation("/login")
      } else if (
        saloonLogout.failure &&
        !saloonLogout.isFetching &&
        saloonLogout.errorMessage
      ) {
        this.setState({ isloading: false })
      }
    }
  }, [saloonLogout])

  const employeeLogout = useSelector((state) => state.employeeLogout)
  useEffect(() => {
    if (employeeLogout) {
      if (
        !employeeLogout.failure &&
        !employeeLogout.isFetching &&
        !employeeLogout.errorMessage &&
        employeeLogout.data &&
        employeeLogout.data.success
      ) {
        dispatch(clear_logout())
        this.handleNavigation("/login")
      } else if (
        employeeLogout.failure &&
        !employeeLogout.isFetching &&
        employeeLogout.errorMessage
      ) {
        this.setState({ isloading: false })
      }
    }
  }, [employeeLogout])

  const notifications = useSelector((state) => state.getNotifications.data)
  const setAnchor = (e) => {
    if (e) {
      _setAnchor(notificationIcon.current)
      dispatch(get_notifications())
    } else {
      _setAnchor(null)
    }
  }
  const setAddAnchor = (e) => {
    if (e) {
      _setAddAnchor(notificationIcon.current)
    } else {
      _setAddAnchor(null)
    }
  }

  const handleLogout = () => {
    dispatch(saloon_logout({}))
    dispatch(employee_logout({}))
    setGoogleEvents([])
    setIsGoogleSignedIn(false)
    setOutlookEvents([])
    setIsOutlookSignedIn(false)
    setEmployeeIdForOutlook(null)

    history.push("/")
  }
  const appbarStyle = useMemo(() => {
    const baseStyles = {
      backgroundColor: "var(--primary-color)",
      zIndex: 1201,
      maxWidth: "100vw",
    }

    if (screenSize == "mobile") {
      return {
        bottom: 0,
        top: "auto",
        ...baseStyles,
      }
    } else {
      return {
        ...baseStyles,
      }
    }
  }, [screenSize])

  const menuButton = useCallback(() => {
    return (
      <div className="NavbarIcon">
        <div>
          <MenuIcon
            onClick={() => onSetSidebarOpen(!sidebarOpen)}
            style={{
              width: iconSize,
              height: iconSize,
              color: "var(--black-color)",
              cursor: "pointer",
              margin: "0 20px",
            }}
          />
          <img src={Images.easy1_logo} style={{ height: 40 }} />
        </div>
        <div className="text-center mobile-only IconLabel">
          {i18n.t("menu")}
        </div>
      </div>
    )
  }, [sidebarOpen])

  const notificationButton = useCallback(() => {
    const unreadNotifications = notifications.filter((notification) =>
      notification.typeId.code != "RC"
        ? notification.status == 1
        : type == "employee"
        ? notification.employeeStatus == 1
        : notification.status == 1
    )

    return (
      <div className="NavbarIcon ">
        <span
          style={{ cursor: "pointer" }}
          className={
            screenSize != "mobile" && unreadNotifications.length
              ? "position-relative mr-2 "
              : "position-relative"
          }
          onClick={setAnchor}
          ref={notificationIcon}
        >
          <Badge
            badgeContent={unreadNotifications.length}
            max={99}
            color="secondary"
          >
            <Notifications
              style={{
                width: iconSize,
                height: iconSize,
                color: "var(--black-color)",
              }}
            />
          </Badge>
        </span>

        <div className="text-center mobile-only IconLabel">
          {i18n.t("notifications")}
        </div>
      </div>
    )
  }, [notifications])

  const logoutButton = useCallback(() => {
    return (
      <ExitToApp
        onClick={handleLogout}
        style={{
          width: iconSize,
          height: iconSize,
          color: "var(--black-color)",
          cursor: "pointer",
          marginLeft: "0.7rem",
        }}
      />
    )
  }, [])

  const myCalendarButton = useCallback(() => {
    return (
      <div className="NavbarIcon">
        <div>
          <CalendarToday
            onClick={() =>
              history.push(
                type == "salon"
                  ? "/saloon-my-appointment"
                  : "/employee-my-appointment"
              )
            }
            style={{
              width: iconSize,
              height: iconSize,
              color: "white",
              cursor: "pointer",
            }}
          />
        </div>
        <div className="text-center mobile-only IconLabel">
          {i18n.t("calendar")}
        </div>
      </div>
    )
  }, [type])

  const myCustomersButton = useCallback(() => {
    return (
      <div className="NavbarIcon">
        <div>
          <People
            onClick={() =>
              history.push(
                type == "salon" ? "/saloon-customers" : "/employee-customers"
              )
            }
            style={{
              width: iconSize,
              height: iconSize,
              color: "white",
              cursor: "pointer",
            }}
          />
        </div>
        <div className="text-center mobile-only IconLabel">
          {i18n.t("customers")}
        </div>
      </div>
    )
  }, [type])

  const createButton = useCallback(() => {
    return (
      <div className="NavbarIcon">
        <div>
          <AddCircle
            onClick={setAddAnchor}
            ref={notificationIcon}
            style={{
              width: iconSize,
              height: iconSize,
              color: "white",
              cursor: "pointer",
            }}
          />
        </div>
        <div className="text-center mobile-only IconLabel">
          {i18n.t("action")}
        </div>
      </div>
    )
  }, [type])

  if (!user) return null
  return (
    <div>
      <Menu
        anchorEl={addAnchor}
        keepMounted
        open={Boolean(addAnchor)}
        onClose={() => setAddAnchor()}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={() => {
            setAddOpen(true)
            setAddAnchor()
          }}
        >
          <div className="py-2">{i18n.t("create_appointment")}</div>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setInvoiceAddOpen(true)
            setAddAnchor()
          }}
        >
          <div className="py-2">{i18n.t("create_sale")}</div>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setIntakeCustomerOpen(true)
            setAddAnchor()
          }}
        >
          <div className="py-2">{i18n.t("intake_customer")}</div>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setStaffMeetingOpen(true)
            setAddAnchor()
          }}
        >
          <div className="py-2">{i18n.t("staff_meeting")}</div>
        </MenuItem>
      </Menu>
      <NotificationCenter
        {...{
          notifications,
          anchor,
          setAnchor,
          isSalon,
          isEmployee,
          history,
          type,
        }}
      />
      <AppBar position="fixed" elevation={0} style={appbarStyle}>
        <Annoucements />
        <Toolbar>
          {screenSize == "mobile" ? (
            <div className="row mx-0 justify-content-between align-items-center header w-100">
              {myCalendarButton()}
              {myCustomersButton()}
              {createButton()}
              {notificationButton()}
              {menuButton()}
            </div>
          ) : (
            <div className="row mx-0 justify-content-between align-items-center header w-100">
              {menuButton()}
              <div className="row mx-0 align-items-center">
                {notificationButton()}
                {logoutButton()}
              </div>
            </div>
          )}
        </Toolbar>
      </AppBar>
      {screenSize != "mobile" ? (
        <Toolbar
          style={{
            height: `var(--appbar-height)`,
          }}
        />
      ) : null}
    </div>
  )
}
