import { put, takeEvery } from "redux-saga/effects"

import * as types from "../actions/ActionTypes"
import {
  insert,
  get,
  setCollectedMoney,
  requestFailure,
  getRequest,
} from "../actions/BookingTransaction"

import axios from "axios"
import { booking_Transaction } from "../../config/WebServices"

function* getSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.get(booking_Transaction, {
      params: payload,
    })
    data = data.data

    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data)
      }
      yield put(get(data.data))
      yield put(setCollectedMoney(data.collectedMoney))
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data)
      }
      yield put(requestFailure(data))
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* insertSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.post(booking_Transaction, payload)
    data = data.data
    if (data.success) {
      const filteredData = data.data
        .filter((element) => element.success)
        .map((element) => element.bookingTransaction)
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data)
      }
      yield put(insert(filteredData))
      console.log(data, data.collectedMoney)
      yield put(setCollectedMoney(data.collectedMoney))
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data)
      }
      yield put(requestFailure(data))
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* reverseSaga(action) {
  const { payload, callbackMap = {} } = action
  const { bookingId } = payload
  let data
  try {
    data = yield axios.post(`${booking_Transaction}/reverse`, payload)
    data = data.data
    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data)
      }
      yield put(getRequest({ bookingId }))
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data)
      }
      yield put(requestFailure(data))
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

export default function* root() {
  yield takeEvery(types.BOOKING_TRANSACTION.GET_SAGA, getSaga)
  yield takeEvery(types.BOOKING_TRANSACTION.INSERT_SAGA, insertSaga)
  yield takeEvery(types.BOOKING_TRANSACTION.REVERSE_SAGA, reverseSaga)
}
