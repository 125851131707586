import React, { Component } from "react"
import i18n from "../../../i18n"
import { getReports } from "../../../config/simpleApiCalls"
import { DotsLoader, BaseModal } from "../../../components"
import Avatar from "react-avatar"
import moment from "moment"
import DatePicker from "react-datepicker"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import Button from "@material-ui/core/Button"
import { getNestedValue } from "../../../util/objectMethods"
import "./styles.css"
import PrimaryButton from "../../../components/Core/PrimaryButton"
import SecondaryButton from "../../../components/Core/SecondaryButton/SecondaryButton.jsx"
import useCache from "../../../context/LocalCache"
import { exportTableToPDF } from "../../../util/exportToPDF"
import { ScreenSizeContext } from "../../../context/ScreenSize.jsx"

class _BookingReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: null,
      showEditDelete: "",
      searchText: "",
      activePage: 1,
      usersList: [],
      startDate: new Date(),
      endDate: new Date(),
      // employeeList: [],
      filterUsersList: [],
      isLoading: false,
      selectedEmployee: "",
      selectedService: "",
    }
  }

  async componentDidMount() {
    this.retrieveReports()
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { services: allServices = [], employees } = nextProps
    const allServiceMap = {}
    const services = allServices.map((service) => {
      allServiceMap[service._id] = service
      return {
        value: service._id,
        label: service.name,
      }
    })
    return {
      employees: employees.map((employee) => ({
        value: employee.employee?._id || employee.archivedEmployeeId._id,
        label:
          employee.employee?.userId.firstName ||
          employee.archivedEmployeeId.userId.firstName,
      })),
      allServiceMap,
      services,
    }
  }

  setStartDate = (val) => {
    const { endDate } = this.state
    if (val > endDate) {
      this.setState({
        startDate: endDate,
        endDate: val,
      })
    } else {
      this.setState({
        startDate: val,
      })
    }
  }

  setEndDate = (val) => {
    const { startDate } = this.state
    if (val < startDate) {
      this.setState({
        startDate: val,
        endDate: startDate,
      })
    } else {
      this.setState({
        endDate: val,
      })
    }
  }

  TemplateOption = () => {
    const { startDate, endDate } = this.state
    return (
      <div className="d-sm-flex align-items-center ml-sm-3 mb-3 gap-2">
        <label
          className="font-weight-bolder"
          style={{ minWidth: "fit-content" }}
        >
          {i18n.t("date_range")}
        </label>
        <div className="d-flex gap-2">
          <DatePicker
            className="form-control saloon-form-input"
            placeholderText="Select Start Date"
            selected={startDate}
            onChange={(data) => this.setStartDate(data)}
            dateFormat="MM/dd/yyyy"
            variant="outlined"
          />
          <DatePicker
            className="form-control saloon-form-input"
            placeholderText="Select End Date"
            selected={endDate}
            onChange={(data) => this.setEndDate(data)}
            dateFormat="MM/dd/yyyy"
          />
        </div>
      </div>
    )
  }

  renderSearchBar = () => {
    const { searchText } = this.state
    const { screenSize } = this.context
    return (
      <div className="col-12 px-0 d-sm-flex align-items-center justify-content-between mb-4">
        <span className="saloon-dashboard-heading pt-0">
          {i18n.t("container.booking_report")}
        </span>
        {screenSize !== "mobile" ? <>{this.renderButtons()}</> : null}
      </div>
    )
  }
  renderButtons = () => {
    const { screenSize } = this.context
    return (
      <div className="d-flex flex-column flex-md-row gap-2">
        <PrimaryButton
          className={screenSize !== "mobile" ? "w-128" : ""}
          onClick={() =>
            exportTableToPDF(["#table"], i18n.t("container.booking_report"))
          }
        >
          {i18n.t("export_to_pdf")}
        </PrimaryButton>
        <PrimaryButton
          className={screenSize !== "mobile" ? "w-128" : ""}
          onClick={() => this.retrieveReports(true)}
        >
          {i18n.t("export_to_excel")}
        </PrimaryButton>
      </div>
    )
  }
  renderTableBody = () => {
    let { data, filteredData, isLoading, allServiceMap } = this.state
    const toRender = filteredData ? filteredData : data

    if (!isLoading) {
      if (toRender) {
        const summary = {
          total: 0,
          tip: 0,
          grandTotal: 0,
          discount: 0,
        }
        toRender.map((val) => {
          const { booking, employee } = val
          summary.total += booking.totalAmount
          summary.tip += booking.tip || 0
          summary.discount += booking.discount || 0
          summary.grandTotal += booking.total - booking.discount
          val.booking = booking
          return val
        })
        return (
          <tbody>
            {!!toRender.length && (
              <tr
                style={{
                  position: "sticky",
                  top: 37,
                  backgroundColor: "white",
                  zIndex: 1,
                }}
              >
                <td>
                  <strong>{i18n.t("summary")}: </strong>
                </td>
                <td>
                  <strong>
                    {i18n.t("number_of_bookings")}: {toRender.length}
                  </strong>
                </td>
                <td className="px-0">
                  <Button
                    disabled={true}
                    style={{
                      backgroundColor: "#F6943B",
                      color: "white",
                      width: "7rem",
                    }}
                  >
                    $ {summary.total.toFixed(2)}
                  </Button>
                </td>
                <td className="px-0">
                  <Button
                    disabled={true}
                    style={{
                      backgroundColor: "#F6943B",
                      color: "white",
                      width: "7rem",
                    }}
                  >
                    $ {summary.tip.toFixed(2)}
                  </Button>
                </td>
                <td className="px-0">
                  <Button
                    disabled={true}
                    style={{
                      backgroundColor: "#F6943B",
                      color: "white",
                      width: "7rem",
                    }}
                  >
                    $ {summary.discount.toFixed(2)}
                  </Button>
                </td>
                <td className="px-0">
                  <Button
                    disabled={true}
                    style={{
                      backgroundColor: "#F6943B",
                      color: "white",
                      width: "7rem",
                    }}
                  >
                    $ {summary.grandTotal.toFixed(2)}
                  </Button>
                </td>
              </tr>
            )}
            {toRender.map((val, ind) => {
              let totalAmount = val.booking.totalAmount
                ? val.booking.totalAmount
                : 0
              let totalTip = val.booking.tip ? val.booking.tip : 0
              let grandTotal = totalAmount + totalTip - val.booking.discount
              const { firstName } = val.employee.userId
              return (
                <tr
                  key={ind}
                  style={{
                    backgroundColor: ind % 2 == 0 && "#F4F6F8",
                    borderTop: "1px solid #E5EBF1",
                    borderBottom:
                      toRender.length - 1 == ind && "1px solid #E5EBF1",
                  }}
                >
                  <td>
                    <div className="d-flex align-items-center">
                      <Avatar
                        name={firstName}
                        size="30"
                        color="#4862FF"
                        round={true}
                        textSizeRatio={2.0}
                      />
                      {/* {<img src={val.user.profile_img} className="appointment-details-employee-pic" style={{ width: 30, height: 30 }} />} */}
                      <div>
                        {
                          <span
                            style={{ marginLeft: "10px" }}
                            className="appointment-details-employee-name"
                          >
                            {firstName}
                          </span>
                        }
                      </div>
                    </div>
                  </td>
                  <td className=" Table_data">
                    <div className="d-flex align-items-center mt-1">
                      <span className="appointment-details-employee-name">
                        {getNestedValue(
                          allServiceMap,
                          [val.booking.services[0].serviceId, "name"],
                          ""
                        )}
                      </span>
                    </div>
                  </td>
                  <td className=" Table_data">
                    <div className="d-flex align-items-center mt-1">
                      <span className="appointment-details-employee-name">
                        {" "}
                        ${totalAmount.toFixed(2)}
                      </span>
                    </div>
                  </td>
                  <td className=" Table_data">
                    <div className="d-flex align-items-center mt-1">
                      <span className="appointment-details-employee-name">
                        {" "}
                        ${totalTip.toFixed(2)}
                      </span>
                    </div>
                  </td>
                  <td className=" Table_data">
                    <div className="d-flex align-items-center mt-1">
                      <span className="appointment-details-employee-name">
                        {" "}
                        ${val.booking.discount.toFixed(2)}
                      </span>
                    </div>
                  </td>
                  <td className=" Table_data">
                    <div className="d-flex align-items-center mt-1">
                      <span className="appointment-details-employee-name">
                        {" "}
                        ${grandTotal.toFixed(2)}
                      </span>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        )
      }
    } else {
      return <DotsLoader isloading={this.state.isloading} />
    }
  }

  renderLoader = () => {
    const { isLoading } = this.state
    return <DotsLoader isloading={isLoading} />
  }

  onChangeSelected = ({ service = "", employee = "" }) => {
    const { data } = this.state
    this.setState({
      selectedEmployee: employee,
      selectedService: service,
      data,
    })
  }

  renderUserTable = () => {
    const { screenSize } = this.context
    return (
      <div className="col-12 px-0 bg-white">
        <div
          className="table-responsive max-vh"
          style={
            screenSize === "mobile" ? { height: "70vh" } : { height: "80vh" }
          }
        >
          <table
            id="table"
            className="table table-borderless appointment-details-table mb-0"
          >
            <thead>
              <tr
                style={{
                  position: "sticky",
                  top: 0,
                  backgroundColor: "white",
                  zIndex: 1,
                }}
              >
                <th scope="col">
                  {i18n.t("container.advancedSearchDialog_name")}
                </th>
                <th scope="col">{i18n.t("service")}</th>
                <th scope="col">{i18n.t("container.amount")}</th>
                <th scope="col">{i18n.t("container.tip")}</th>
                <th scope="col">{i18n.t("discount")}</th>
                <th scope="col">{i18n.t("grand_total")}</th>
              </tr>
            </thead>
            {this.renderTableBody()}
          </table>
        </div>
      </div>
    )
  }

  setSelectedValue = (val) => {
    let currentDate = moment(Date.now())
    if (val == 1) {
      let day = currentDate.day()
      const convertedStart = moment(currentDate).subtract(day, "days")
      const convertedEnd = moment(convertedStart).add(6, "days")
      this.setState({
        startDate: convertedStart.toDate(),
        endDate: convertedEnd.toDate(),
      })
    } else if (val == 2) {
      const startOfMonth = moment(currentDate).startOf("month")
      const endOfMonth = moment(currentDate).endOf("month")
      this.setState({
        startDate: startOfMonth.toDate(),
        endDate: endOfMonth.toDate(),
      })
    } else if (val == 3) {
      const convertedStart = moment(`01-01-${currentDate.year()}`, "MM-DD-YYYY")
      const convertedEnd = moment(`12-31-${currentDate.year()}`, "MM-DD-YYYY")
      this.setState({
        startDate: convertedStart.toDate(),
        endDate: convertedEnd.toDate(),
      })
    }
  }

  retrieveReports = (exportToXlsx) => {
    const { selectedEmployee, selectedService } = this.state
    this.setState({ isLoading: true }, () => {})
    const { startDate, endDate, allServiceMap = {} } = this.state
    let payload = {
      start: moment(startDate).format("MM-DD-YYYY"),
      end: moment(endDate).format("MM-DD-YYYY"),
      exportToXlsx,
    }
    if (selectedEmployee) {
      payload.employeeId = selectedEmployee
    }
    if (selectedService) {
      payload.serviceId = selectedService
    }
    getReports(payload)
      .then((res) => {
        if (res.data.success) {
          this.setState({ isLoading: false })
        }
        let data = res.data.data
        data = data.map((val) => {
          const { booking, employee } = val
          booking.total = booking.totalAmount + (booking.tip || 0)
          val.booking = booking
          return val
        })
        this.setState(
          {
            data,
            filteredData: undefined,
          },
          () => {
            this.onChangeSelected({
              employee: selectedEmployee,
              service: selectedService,
            })
          }
        )
      })
      .catch((err) => {
        this.setState({ isLoading: false })
      })
  }

  ShowMenuValues = () => {
    return (
      <div className="row align-items-center px-0 mx-0 ml-sm-3 gap-2">
        <label className="font-weight-bolder">
          {i18n.t("container.quick_filter")}
        </label>
        <div className="d-flex px-0 gap-2 flex-wrap">
          <SecondaryButton onClick={() => this.setSelectedValue(1)}>
            {i18n.t("container.week")}
          </SecondaryButton>
          <SecondaryButton onClick={() => this.setSelectedValue(2)}>
            {i18n.t("container.month")}
          </SecondaryButton>
          <SecondaryButton onClick={() => this.setSelectedValue(3)}>
            {i18n.t("container.year")}
          </SecondaryButton>
          <PrimaryButton
            className="w-98"
            onClick={() => this.retrieveReports()}
          >
            {i18n.t("search")}
          </PrimaryButton>
        </div>
      </div>
    )
  }

  renderFilter() {
    const {
      selectedEmployee,
      selectedService,
      employees = [],
      services = [],
    } = this.state
    return (
      <div className="row col-12 px-0">
        <div className="row col-sm-5 px-0 mb-4">
          <div className="w-100 d-flex flex-column flex-md-row align-items-md-center gap-2">
            <label className="font-weight-bolder">
              {i18n.t("container.employee")}
            </label>
            <Select
              value={selectedEmployee}
              onChange={(e) =>
                this.onChangeSelected({
                  employee: e.target.value,
                  service: selectedService,
                })
              }
              fullWidth
              variant="outlined"
            >
              {employees.map((employee) => (
                <MenuItem value={employee.value}>{employee.label}</MenuItem>
              ))}
            </Select>
            <label className="font-weight-bolder">
              {i18n.t("container.services")}
            </label>
            <Select
              value={selectedService}
              onChange={(e) =>
                this.onChangeSelected({
                  employee: selectedEmployee,
                  service: e.target.value,
                })
              }
              fullWidth
              variant="outlined"
            >
              {services.map((service) => (
                <MenuItem value={service.value}>{service.label}</MenuItem>
              ))}
            </Select>
            <PrimaryButton
              onClick={() => this.onChangeSelected({})}
              className="w-98"
            >
              {i18n.t("filterReset")}
            </PrimaryButton>
          </div>
        </div>

        {this.TemplateOption()}
        {this.ShowMenuValues()}
      </div>
    )
  }
  render() {
    const { isLoading } = this.state
    const { screenSize } = this.context
    return (
      <>
        {isLoading ? this.renderLoader() : null}

        <div>
          <div className="content-container p-4">
            <div className="row">
              {this.renderSearchBar()}
              {screenSize !== "mobile" ? (
                this.renderFilter()
              ) : (
                <div className="d-flex w-100 justify-content-center gap-2">
                  <PrimaryButton
                    className="w-128"
                    onClick={() => this.setState({ openFiltersModal: true })}
                  >
                    {i18n.t("filters")}
                  </PrimaryButton>
                  <PrimaryButton
                    className="w-128"
                    onClick={() => this.setState({ openOptionsModal: true })}
                  >
                    Options
                  </PrimaryButton>
                </div>
              )}

              {this.renderUserTable()}
            </div>
          </div>

          <BaseModal
            open={this.state.openFiltersModal}
            onClose={() => this.setState({ openFiltersModal: false })}
            title={i18n.t("container.advancedSearchDialog_filter")}
            content={<>{this.renderFilter()}</>}
            contentClassName="overflow-visible"
          />
          <BaseModal
            open={this.state.openOptionsModal}
            onClose={() => this.setState({ openOptionsModal: false })}
            title="Options"
            content={<>{this.renderButtons()}</>}
          />
        </div>
      </>
    )
  }
}
_BookingReport.contextType = ScreenSizeContext
export default function BookingReport(props) {
  const { getResource } = useCache()
  const services = getResource((cache) => cache.company.services)
  const employees = getResource((cache) => cache.company.employees)
  return <_BookingReport {...props} services={services} employees={employees} />
}
