import { connect } from "react-redux"
import React, { useMemo } from "react"
import i18n from "../../i18n"
import Tabs from "../Core/Tabs/Tabs"
import BookingReminders from "../BookingReminders"
import BookingNotes from "../BookingNotes"
import useActiveMenuType from "../../context/ActiveMenuType"
const BookingNotesReminders = ({ booking, hasEditPermission }) => {
  const { type } = useActiveMenuType()
  const isSalon = useMemo(() => {
    return type && type != "employee"
  }, [type])
  const tabs = [
    {
      title: i18n.t("notes"),
      content: () => (
        <BookingNotes
          bookingId={booking?._id}
          hasEditPermission={hasEditPermission}
        />
      ),
    },
    {
      title: i18n.t("tasks_and_reminders"),
      content: () => (
        <BookingReminders
          booking={booking}
          hasEditPermission={hasEditPermission}
          isSalon={isSalon}
        />
      ),
    },
  ]
  return (
    <div className="custom-tab">
      <Tabs tabs={tabs} />
    </div>
  )
}
const mapStateToProps = (state) => ({})

const action = {}

export default connect(mapStateToProps, action)(BookingNotesReminders)
