import React, { useEffect, useRef, useState } from "react"
import i18n from "../../../i18n"
import PrimaryButton from "../../../components/Core/PrimaryButton"
import { BaseModal } from "../../../components"
import { useDispatch } from "react-redux"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import { ErrorHelper, SuccessHelper } from "../../../helpers"
import useScreenSize from "../../../context/ScreenSize"
const UpsertPopup = ({ open, setOpen, active, title, actionMap }) => {
  const dispatch = useDispatch()
  const form = useRef()
  const [data, setData] = useState({})
  const _title = data._id ? `update_${title}` : `create_${title}`
  const { screenSize } = useScreenSize()
  const onSubmit = (e) => {
    const successMessage = data._id
      ? "successfully_updated"
      : "successfully_added"
    const failureMessage = data._id ? "failed_to_update" : "failed_to_add"
    dispatch(
      actionMap.upsert(
        { ...data },
        {
          success: ({ data: res }) => {
            const toSet = {
              ...data,
              ...res,
            }

            setData(toSet)
            SuccessHelper.handleSuccess(i18n.t(successMessage), true)
          },
          failure: () => {
            ErrorHelper.handleErrors(i18n.t(failureMessage), true)
          },
        }
      )
    )
  }

  useEffect(() => {
    if (open) {
      const data = { ...(active || {}) }
      setData({
        ...data,
      })
    }
  }, [open])

  return (
    <BaseModal
      open={open}
      onClose={() => setOpen(false)}
      showCloseBtn={screenSize !== "mobile" ? true : false}
      title={i18n.t(_title)}
      containerClassName={"half-container"}
      content={
        <ValidatorForm
          onSubmit={onSubmit}
          ref={form}
          className="d-flex align-items-end w-100"
        >
          <div className="validate-flex col-12 px-0 d-flex align-items-center justify-content-between mb-3">
            <label className="font-weight-bold mr-3">{i18n.t("name")}</label>
            <div
              className="validate-input"
              style={{ width: "calc(100% - 220px)" }}
            >
              <TextValidator
                label={i18n.t("name")}
                onChange={(e) => setData({ ...data, name: e.target.value })}
                value={data.name || ""}
                validators={["required"]}
                errorMessages={[i18n.t("this_field_is_required")]}
                variant="outlined"
              />
            </div>
            <div
              className={
                screenSize == "mobile"
                  ? "d-flex justify-content-center gap-2 w-100"
                  : ""
              }
            >
              {screenSize == "mobile" && (
                <PrimaryButton
                  className="w-128 ml-3"
                  onClick={() => setOpen(false)}
                  label={i18n.t("cancel")}
                />
              )}
              <PrimaryButton
                className="w-128 ml-3"
                props={{
                  type: "submit",
                }}
                label={i18n.t("save")}
              />
            </div>
          </div>
        </ValidatorForm>
      }
    />
  )
}

export default UpsertPopup
