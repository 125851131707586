// @flow

import { COMMUNICATION_MARKETING_TEMPLATE } from "../ActionTypes"

export function upsertRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: COMMUNICATION_MARKETING_TEMPLATE.UPSERT_SAGA,
  }
}

export function upsert(data) {
  return {
    data,
    type: COMMUNICATION_MARKETING_TEMPLATE.UPSERT,
  }
}

export function getRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: COMMUNICATION_MARKETING_TEMPLATE.GET_SAGA,
  }
}

export function get(data) {
  return {
    data,
    type: COMMUNICATION_MARKETING_TEMPLATE.GET,
  }
}

export function removeRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: COMMUNICATION_MARKETING_TEMPLATE.REMOVE_SAGA,
  }
}

export function remove(data) {
  return {
    data,
    type: COMMUNICATION_MARKETING_TEMPLATE.REMOVE,
  }
}

export function requestFailure() {
  return {
    type: COMMUNICATION_MARKETING_TEMPLATE.REQUEST_FAILURE,
  }
}
