import { connect } from "react-redux";
import React, { useRef, useState } from "react";
import i18n from "../../../i18n";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import PrimaryButton from "../../Core/PrimaryButton";
import {
  removeGradeRequest,
  addGradeRequest,
} from "../../../redux/actions/Saloon/Class";
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import BasicTable from "../../Core/BasicTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BaseModal from "../../BaseModal";
import deleteConfirmation from "../../../helpers/DeleteHelper";

function ClassUpsert({
  _class,
  onSave,
  addGradeRequest,
  removeGradeRequest,
  updateClass,
}) {
  const form = useRef();
  const [data, setData] = useState({});
  const [addModalOpen, setAddModalOpen] = useState(false);

  const onSaveGrade = () => {
    addGradeRequest(
      { _id: _class._id, ...data },
      {
        success: (data) => {
          SuccessHelper.handleSuccess(i18n.t("successfully_added"), true);
          updateClass(data.data);
          setAddModalOpen(false);
        },
        failure: () => {
          ErrorHelper.handleErrors(i18n.t("failed_to_add"), true);
        },
      }
    );
  };

  const handleChange = (key, value) => {
    data[key] = value;
    setData({ ...data });
  };

  const handleRemove = (gradeId) => {
    removeGradeRequest(
      {
        _id: _class._id,
        gradeId,
      },
      {
        success: (data) => {
          SuccessHelper.handleSuccess(i18n.t("successfully_deleted"), true);
          updateClass(data.data);
        },
        failure: () => {
          ErrorHelper.handleErrors(i18n.t("failed_to_delete"), true);
        },
      }
    );
  };

  return (
    <div>
      <div className="row mx-0 align-items-center justify-content-between mb-3 p-0">
        <h4 className="m-0">{i18n.t("grades")}</h4>
        <PrimaryButton
          className="w-128"
          label={i18n.t("add")}
          onClick={() => {
            setAddModalOpen(true);
            setData({});
          }}
        />
      </div>
      <BasicTable
        rows={_class.grades}
        schema={[
          {
            header: i18n.t("name"),
            field: "name",
            accessor: (val) => val.name,
          },
          {
            header: i18n.t("value"),
            field: "value",
            accessor: (val) => val.value,
          },
          {
            key: "action",
            header: "",
            style: { width: 60 },
            render: (val) => {
              if (data.finalized) {
                return null;
              }
              return (
                <FontAwesomeIcon
                  className="view-icon"
                  style={{ backgroundColor: "#f6943b" }}
                  color="white"
                  icon={["fas", "trash"]}
                  onClick={() =>
                    deleteConfirmation(() => handleRemove(val._id))
                  }
                />
              );
            },
          },
        ]}
      />
      <BaseModal
        open={addModalOpen}
        onClose={() => {
          setAddModalOpen(false);
        }}
        containerClassName="half-container"
        title={i18n.t("add_grade")}
        content={
          <div>
            <ValidatorForm
              onSubmit={onSaveGrade}
              ref={form}
              className="w-100"
              onError={(error) => {
                if (!error.length) {
                  onSave(data);
                }
              }}
            >
              <div className="col-12 px-0 d-flex align-items-center mb-3">
                <label className="font-weight-bold" style={{ minWidth: '100px' }}>{i18n.t("name")}</label>
                <div className="w-100">
                  <TextValidator
                    className="w-100"
                    label={i18n.t("name")}
                    onChange={(e) => handleChange("name", e.target.value)}
                    value={data.name || ""}
                    validators={["required"]}
                    errorMessages={[i18n.t("name_is_required")]}
                    variant="outlined"
                  />
                </div>
              </div>
              <div className="col-12 px-0 d-flex align-items-center mb-3">
                <label className="font-weight-bold" style={{ minWidth: '100px' }}>{i18n.t("value")}</label>
                <div className="w-100">
                  <TextValidator
                    className="w-100"
                    type="number"
                    label={i18n.t("value")}
                    onChange={(e) => handleChange("value", e.target.value)}
                    onBlur={(text) => {
                      let num = parseFloat(text.target.value);
                      if (isNaN(num) || num < 0) {
                        num = 0;
                      }
                      num = Math.round((num + Number.EPSILON) * 100) / 100;
                      handleChange("value", num);
                    }}
                    value={data.value || ""}
                    validators={["required", "minNumber:1"]}
                    errorMessages={[
                      i18n.t("value_is_required"),
                      i18n.t("must_be_positive"),
                    ]}
                    variant="outlined"
                  />
                </div>
              </div>
              <div className="row mx-0 px-0 justify-content-end my-4 col-12">
                <PrimaryButton
                  className="w-128"
                  props={{
                    type: "submit",
                  }}
                  label={data._id ? i18n.t("save") : i18n.t("create")}
                />
              </div>
            </ValidatorForm>
          </div>
        }
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  classes: state._class.data,
  loading: state._class.isFetching,
});

const action = {
  addGradeRequest,
  removeGradeRequest,
};

export default connect(mapStateToProps, action)(ClassUpsert);
