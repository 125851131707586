import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  SelectValidator,
  TextValidator,
  ValidatorForm,
} from "react-material-ui-form-validator";
import MenuItem from "@material-ui/core/MenuItem";
import i18n from "../../../../i18n";
import { Autocomplete } from "@material-ui/lab";
import PrimaryButton from "../../../Core/PrimaryButton";
import { useSelector } from "react-redux";
import moment from "moment";
import { BaseModal } from "../../..";
import ReactDatePicker from "react-datepicker";

export default function AddScheduleEvent({
  open,
  setOpen,
  allServices,
  employees,
  defaults = {},
  onAdd,
  onDelete,
  active = {},
  selectedSchedule = {},
}) {
  const form = useRef();
  const [data, setData] = useState({});

  const { minDate, maxDate } = useMemo(() => {
    if (selectedSchedule) {
      const { startDate, endDate } = selectedSchedule;
      return {
        minDate: moment(startDate).toDate(),
        maxDate: moment(endDate).toDate(),
      };
    }
  }, [selectedSchedule]);

  const customerGroups = useSelector((s) => s.companyCustomerGroups.data || []);

  const serviceMap = useMemo(() => {
    const map = {};
    allServices.map((service) => {
      map[service._id] = service;
    });
    return map;
  }, [allServices]);
  const employeeMap = useMemo(() => {
    const map = {};
    employees.map((employee) => {
      map[employee._id] = employee;
    });
    return map;
  }, [employees]);
  const groupMap = useMemo(() => {
    const map = {};
    customerGroups.map((group) => {
      map[group._id] = group;
    });
    return map;
  }, [customerGroups]);
  useEffect(() => {
    if (open) {
      setData({
        ...active,
        employee: employeeMap[active.companyEmployeeId],
        groups: [...(active.groups || []).map((group) => groupMap[group])],
        services: [
          ...(active.services || []).map((service) => serviceMap[service]),
        ],
        date: moment(active.date).toDate(),
      });
    }
  }, [open, active._id]);

  const handleAdd = () => {
    onAdd(data);
  };

  const checkTimes = () => {
    const { startTime, endTime } = data;
    if (startTime && endTime) {
      const momentStart = moment(startTime, "hh:mm");
      const momentEnd = moment(endTime, "hh:mm");
      if (momentStart.isAfter(momentEnd)) {
        setData({ ...data, startTime: endTime, endTime: startTime });
      }
    }
  };
  useEffect(checkTimes, [data.startTime, data.endTime]);
  useEffect(() => {
    setData(defaults);
  }, [open]);
  return (
    <BaseModal
      open={open}
      contentContainerStyle={{
        overflowY: "visible",
      }}
      title={data._id ? i18n.t("edit_schedule") : i18n.t("add_schedule")}
      onClose={() => setOpen(false)}
      content={
        <div className="row mx-0 ScheduleBuilder">
          <ValidatorForm
            onSubmit={handleAdd}
            ref={form}
            className="row col-12 px-0 mx-0"
            onError={(error) => {
              console.log(error);
            }}
          >
            {data._id ? (
              <SelectValidator
                containerProps={{ className: "col-12 px-0 mb-1" }}
                label={i18n.t("employee")}
                onChange={(e) => {
                  setData({
                    ...data,
                    employee: e.target.value,
                  });
                }}
                value={data.employee || ""}
                select
                validators={["required"]}
                errorMessages={[i18n.t("this_field_is_required")]}
              >
                {employees.map((employee) => {
                  return (
                    <MenuItem value={employee}>
                      {employee.employeeId.userId.firstName}
                    </MenuItem>
                  );
                })}
              </SelectValidator>
            ) : (
              <div className="col-12 px-0 mb-1">
                <Autocomplete
                  multiple
                  options={employees}
                  getOptionLabel={(option) =>
                    option.employeeId.userId.firstName
                  }
                  value={data.employees || []}
                  onChange={(e, value = []) => {
                    setData({ ...data, employees: value });
                  }}
                  disableCloseOnSelect={true}
                  renderInput={(params) => {
                    return (
                      <TextValidator
                        {...params}
                        value={data.employees}
                        label={i18n.t("employees")}
                        validators={["required", "hasArrayElements"]}
                        errorMessages={[
                          i18n.t("this_field_is_required"),
                          i18n.t("select_atleast_1_item"),
                        ]}
                      />
                    );
                  }}
                />
              </div>
            )}
            <div className="col-12 px-0 mb-1">
              <Autocomplete
                multiple
                options={allServices}
                getOptionLabel={(option) => option?.name}
                value={data.services || []}
                onChange={(e, value = []) => {
                  setData({ ...data, services: value });
                }}
                disableCloseOnSelect={true}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    value={data.services}
                    label={i18n.t("services")}
                    validators={["required", "hasArrayElements"]}
                    errorMessages={[
                      i18n.t("this_field_is_required"),
                      i18n.t("select_atleast_1_item"),
                    ]}
                  />
                )}
              />
            </div>
            <div className="col-12 px-0 mb-1">
              <Autocomplete
                multiple
                options={customerGroups}
                getOptionLabel={(option) => option?.name}
                value={data.groups || []}
                onChange={(e, value = []) => {
                  setData({ ...data, groups: value });
                }}
                disableCloseOnSelect={true}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    value={data.groups}
                    label={i18n.t("groups")}
                    validators={["required", "hasArrayElements"]}
                    errorMessages={[
                      i18n.t("this_field_is_required"),
                      i18n.t("select_atleast_1_item"),
                    ]}
                  />
                )}
              />
            </div>
            <ReactDatePicker
              wrapperClassName="col-12 w-100 mb-1 px-0"
              selected={data.date}
              dateFormat="MM/dd/yyyy"
              onChange={(val) => {
                setData({ ...data, date: val });
              }}
              maxDate={maxDate}
              minDate={minDate}
              customInput={
                <TextValidator
                  label={i18n.t("date")}
                  value={data.date || ""}
                  validators={["required"]}
                  errorMessages={[i18n.t("this_field_is_required")]}
                  readOnly={true}
                />
              }
            />
            <TextValidator
              containerProps={{ className: "col-6 mb-1 pl-0" }}
              label={i18n.t("start_time")}
              onChange={(e) => setData({ ...data, startTime: e.target.value })}
              type="time"
              value={data.startTime || ""}
              validators={["required"]}
              errorMessages={[i18n.t("this_field_is_required")]}
            />
            <TextValidator
              containerProps={{ className: "col-6 mb-1 pr-0" }}
              label={i18n.t("end_time")}
              onChange={(e) => setData({ ...data, endTime: e.target.value })}
              type="time"
              value={data.endTime || ""}
              validators={["required"]}
              errorMessages={[i18n.t("this_field_is_required")]}
            />

            <div className="row mx-0 px-0 justify-content-between mt-2 col-12">
              {data._id ? (
                <PrimaryButton
                  onClick={() => onDelete(data._id)}
                  label={i18n.t("delete")}
                />
              ) : (
                <div />
              )}
              <PrimaryButton
                props={{
                  type: "submit",
                }}
                label={data._id ? i18n.t("save") : i18n.t("create")}
              />
            </div>
          </ValidatorForm>
        </div>
      }
    />
  );
}
