import React, { createRef } from "react"
import i18n from "../../../i18n"
import "./styles.scss"
import ThreeDButton from "../../Core/3DButton/ThreeDButton"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import PrimaryButton from "../../Core/PrimaryButton"
import { ScreenSizeContext } from "../../../context/ScreenSize"
class AddExtra extends React.Component {
  constructor() {
    super()
    this.state = { activeItem: "" }
    this.productQuantityRef = createRef()
    this.otherNameRef = createRef()
    this.otherPriceRef = createRef()
  }

  renderAdditional = () => {
    const { products, services } = this.props
    const { additionType, activeItem } = this.state

    if (additionType == "product") {
      const productMap = {}
      products.map((element) => {
        productMap[element._id] = {
          ...element,
        }
      })
      return (
        <>
          <div className="col-12 px-0 d-flex align-items-center mb-3">
            <label className="font-weight-bold" style={{ minWidth: "100px" }}>
              {i18n.t("select_product")}
            </label>
            <div className="w-100">
              <Autocomplete
                options={Object.values(productMap)}
                blurOnSelect
                classes={{ inputRoot: "autocomplete-select" }}
                value={activeItem}
                onChange={(e, value) => {
                  this.setState({ activeItem: value })
                }}
                getOptionLabel={(option) => option.name}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={i18n.t("select_product")}
                    fullWidth
                    variant="outlined"
                  />
                )}
              />
            </div>
          </div>
          <div className="col-12 px-0 d-flex align-items-center mb-3">
            <label className="font-weight-bold" style={{ minWidth: "100px" }}>
              {i18n.t("quantity")}
            </label>
            <input
              type="number"
              className="form-control extra-form"
              placeholder={i18n.t("quantity")}
              ref={this.productQuantityRef}
              defaultValue={1}
              min={1}
              step={1}
              onBlur={(e) => {
                const value = parseInt(e.target.value)
                if (value < 0 || isNaN(value)) {
                  this.productQuantityRef.current.value = 0
                } else {
                  this.productQuantityRef.current.value = value
                }
              }}
            />
          </div>
        </>
      )
    } else if (additionType == "service") {
      const serviceMap = {}
      services.map((element) => {
        serviceMap[element._id] = {
          ...element,
        }
      })
      return (
        <>
          <div className="col-12 px-0 d-flex align-items-center mb-3">
            <label className="font-weight-bold" style={{ minWidth: "100px" }}>
              {i18n.t("service")}
            </label>
            <Autocomplete
              options={Object.values(serviceMap)}
              classes={{ inputRoot: "autocomplete-select" }}
              blurOnSelect
              value={activeItem}
              onChange={(e, value) => {
                this.setState({ activeItem: value })
              }}
              getOptionLabel={(option) => option.name}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={i18n.t("select_service")}
                  fullWidth
                  variant="outlined"
                />
              )}
            />
          </div>
        </>
      )
    } else if (additionType == "other") {
      return (
        <>
          <div className="col-12 px-0 d-flex align-items-center mb-3">
            <label className="font-weight-bold" style={{ minWidth: "100px" }}>
              {i18n.t("name")}
            </label>
            <input
              type="text"
              className="form-control extra-form"
              placeholder={i18n.t("name")}
              ref={this.otherNameRef}
            />
          </div>
          <div className="col-12 px-0 d-flex align-items-center mb-3">
            <label className="font-weight-bold" style={{ minWidth: "100px" }}>
              {i18n.t("by_price")}
            </label>
            <input
              type="number"
              className="form-control extra-form"
              placeholder={i18n.t("by_price")}
              ref={this.otherPriceRef}
              defaultValue={0}
              min={0}
              onBlur={(e) => {
                const value = parseFloat(e.target.value)
                if (value < 0 || isNaN(value)) {
                  this.otherPriceRef.current.value = 0
                } else {
                  this.otherPriceRef.current.value = value.toFixed(2)
                }
              }}
            />
          </div>
        </>
      )
    } else {
      return null
    }
  }

  onAdd = () => {
    const { onAdd } = this.props
    const { additionType, activeItem } = this.state
    let values = {}
    if (additionType == "product") {
      values = {
        productId: activeItem._id,
        quantity: this.productQuantityRef.current.value,
      }
    } else if (additionType == "service") {
      values = {
        serviceId: activeItem._id,
        price: activeItem.price,
      }
    } else if (additionType == "other") {
      values = {
        name: this.otherNameRef.current.value,
        price: this.otherPriceRef.current.value,
      }
    }
    if (!Object.values(values).includes(undefined)) onAdd(additionType, values)
  }

  render() {
    const { closaModal, onAdd } = this.props
    const { additionType } = this.state
    const { screenSize } = this.context
    return (
      <>
        {additionType ? (
          <>
            <div className="row mx-0 justify-content-between py-3">
              {this.renderAdditional()}
            </div>
          </>
        ) : (
          <div
            style={{
              display: "grid",
              gridTemplateColumns:
                screenSize === "mobile" ? "1fr" : "repeat(3, 1fr)",
              gap: "1rem",
            }}
          >
            <ThreeDButton
              label={i18n.t("product")}
              onClick={() => {
                this.setState({ additionType: "product" })
              }}
              style={{ maxHeight: 80, height: 50 }}
              className="w-100"
            />
            <ThreeDButton
              label={i18n.t("service")}
              onClick={() => {
                this.setState({ additionType: "service" })
              }}
              style={{ maxHeight: 80, height: 50 }}
              className="w-100"
            />
            <ThreeDButton
              label={i18n.t("other")}
              onClick={() => {
                this.setState({ additionType: "other" })
              }}
              style={{ maxHeight: 80, height: 50 }}
              className="w-100"
            />
          </div>
        )}
        <div className="mt-5 col-12 text-right px-0">
          <PrimaryButton
            className="w-128"
            onClick={() => {
              additionType
                ? this.setState({ additionType: null, activeItem: "" })
                : closaModal()
            }}
          >
            {i18n.t("cancel")}
          </PrimaryButton>
          {additionType && (
            <PrimaryButton className="w-128 ml-3" onClick={this.onAdd}>
              {i18n.t("add")}
            </PrimaryButton>
          )}
        </div>
      </>
    )
  }
}
AddExtra.contextType = ScreenSizeContext
export default AddExtra
