import React, { useEffect, useState, useRef } from "react"
import { connect } from "react-redux"
import i18n from "../../../i18n"
import BaseModal from "../../BaseModal"
import TextField from "@material-ui/core/TextField"
import DollarInput from "../../DollarInput"
import PercentInput from "../../PercentInput"
import PrimaryButton from "../../Core/PrimaryButton"
import { SuccessHelper } from "../../../helpers"
import useScreenSize from "../../../context/ScreenSize"
import { createCustomerTransaction } from "../../../config/simpleApiCalls"
import CardManagement from "../../Card/CardManagment"
import {
  getRequest,
  insertRequest as insertCardRequest,
} from "../../../redux/actions/CompanyCustomerCard"
import Swal from "sweetalert2"
import Select from "../../Core/Select/index.jsx"
import ToggleSelect from "../../ToggleSelect/index.jsx"
import { ErrorHelper } from "../../../helpers"

const roundTo2Decimals = (x) => Math.round((x + Number.EPSILON) * 100) / 100
const InsertPayment = ({
  open,
  handleClose,
  onSuccess,
  accountBalance,
  giftCardBalance,
  userId,
  cards = [],
  customerId,
  getRequest,
  insertCardRequest,
  setLoading,
}) => {
  const [creditDebit, setCreditDebit] = useState(0)
  const [discountPercent, setDiscountPercent] = useState(0)
  const [discountAmount, setDiscountAmount] = useState(0)
  const [accountBalanceToCollect, setAccountBalanceToCollect] = useState(0)
  const [giftCard, setGiftCard] = useState(0)
  const [cash, setCash] = useState(0)
  const [note, setNote] = useState("")
  const [formErrors, setFormErrors] = useState("")
  const difference = roundTo2Decimals(
    parseFloat(accountBalanceToCollect) -
      parseFloat(cash) -
      parseFloat(creditDebit) -
      parseFloat(giftCard) -
      discountAmount
  )
  const { screenSize } = useScreenSize()
  const prevCreditDebitRef = useRef(0)
  const [showCards, setShowCards] = useState(false)
  const [selectedCard, setSelectedCard] = useState(null)
  const [showInsertCard, setShowInsertCard] = useState(false)
  const [showToggleSelect, setShowToggleSelect] = useState(false)

  useEffect(() => {
    if (creditDebit > 0 && prevCreditDebitRef.current == 0) {
      setShowToggleSelect(true)
    } else if (creditDebit <= 0 && prevCreditDebitRef.current != 0) {
      setShowToggleSelect(false)
      setShowCards(false)
      setSelectedCard(null)
    }

    prevCreditDebitRef.current = creditDebit
  }, [creditDebit])

  useEffect(() => {
    if (cards.length > 0 && showToggleSelect && !showCards) {
      setShowCards(true)
    } else {
      setShowCards(false)
    }
  }, [cards])
  useEffect(() => {
    getRequest({ _id: customerId })
  }, [customerId])
  const validate = () => {
    let hasErrors = false
    let newFormErrors = {}
    if (difference > 0) {
      newFormErrors.money = i18n.t("must_be_greater_or_equal", {
        amount: accountBalanceToCollect,
      })
      newFormErrors.giftCard = i18n.t("must_be_greater_or_equal", {
        amount: accountBalanceToCollect,
      })
      hasErrors = true
    } else if (giftCard > giftCardBalance) {
      newFormErrors.giftCard = i18n.t("must_be_less_or_equal", {
        amount: giftCardBalance,
      })
      hasErrors = true
    } else if (showCards && !selectedCard) {
      newFormErrors.card = i18n.t("select_card")

      hasErrors = true
    } else if (showCards && selectedCard && creditDebit < 0.6) {
      hasErrors = true
      ErrorHelper.handleErrors(
        i18n.t("must_be_greater_or_equal", {
          amount: (0.6).toFixed(2),
        }),
        true
      )
    }
    if (hasErrors) {
      setFormErrors(newFormErrors)
      setTimeout(() => {
        setFormErrors({})
      }, 6000)
    } else {
      setLoading(true)
      createCustomerTransaction({
        amount: accountBalanceToCollect,
        note,
        cash: parseFloat(cash),
        creditDebit: parseFloat(creditDebit),
        discount: discountAmount,
        giftCard: parseFloat(giftCard),
        userId,
        cardId: selectedCard,
      }).then(({ data }) => {
        setLoading(false)
        if (!data.success) {
          ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true)
        } else {
          onSuccess(data.data)
          if (difference < 0) {
            SuccessHelper.handleSuccess(
              i18n.t("change_due_prompt", { amount: difference }),
              true
            )
          } else {
            SuccessHelper.handleSuccess("", true)
          }
        }
      })
    }
  }
  return (
    <>
      <BaseModal
        open={open}
        containerClassName={"half-container"}
        {...{
          title: (
            <div>
              {i18n.t("amount_to_be_paid")} ($ {accountBalance?.toFixed(2)})
            </div>
          ),
          content: (
            <div className="transactions-details-insert-payment mt-4">
              {showInsertCard ? (
                <CardManagement
                  removeDefault={false}
                  cards={cards}
                  getRequest={getRequest}
                  insertRequest={insertCardRequest}
                  showAddModal={showInsertCard}
                  setShowAddModal={(val) => {
                    setShowInsertCard(val)
                    if (!cards.length) {
                      setShowCards(false)
                    }
                  }}
                  id={customerId}
                />
              ) : null}
              <div className="row mx-0">
                <>
                  <div className="row mb-2 col-12 px-1 d-flex align-items-center">
                    <div className="col-4 p-0 mb-3 font-weight-bold">
                      <label>{i18n.t("cash")}</label>
                    </div>
                    <div className="col-12 col-md-8 p-0">
                      <DollarInput
                        value={cash}
                        onChange={setCash}
                        error={formErrors.money}
                      />
                    </div>
                  </div>
                  <div className="row mb-2 col-12 px-1 align-items-center">
                    <div className="col-4 p-0 mb-3 font-weight-bold">
                      <label>{i18n.t("credit_debit")}</label>
                    </div>
                    <div className="col-12 col-md-8 p-0">
                      <DollarInput
                        value={creditDebit}
                        onChange={setCreditDebit}
                        error={formErrors.money}
                      />
                    </div>
                  </div>
                  {showToggleSelect ? (
                    <div className="row mb-2 col-12 px-1 align-items-center">
                      <label className="col-4 p-0 mb-3 font-weight-bold">
                        {i18n.t("pay_with_saved_card")}
                      </label>
                      <ToggleSelect
                        value={showCards}
                        onChange={(e) => {
                          setSelectedCard("")
                          setShowCards(e)
                          if (cards?.length <= 0 && e) {
                            Swal.fire({
                              title: i18n.t("do_you_want_to_save_card"),
                              cancelButtonText: i18n.t("no"),
                              showCancelButton: true,
                              confirmButtonColor: "#ff3600",
                              cancelButtonColor: "#354e68",
                              confirmButtonText: i18n.t("yes"),
                            }).then((result) => {
                              if (result.value) {
                                setShowInsertCard(true)
                              } else {
                                setShowCards(false)
                              }
                            })
                          }
                        }}
                        active={i18n.t("enable")}
                        inactive={i18n.t("disable")}
                      />
                    </div>
                  ) : null}

                  {showCards && cards.length ? (
                    <div className="row mb-2 col-12 px-1 align-items-center">
                      <label className="col-4 p-0 mb-3 font-weight-bold">
                        {i18n.t("cards")}
                      </label>
                      <div className="col-12 col-md-8 p-0">
                        <Select
                          variant="outlined"
                          label={i18n.t("card")}
                          onChange={(e) => setSelectedCard(e.target.value)}
                          options={cards.map((card) => ({
                            name: card.card.last4,
                            value: card.id,
                          }))}
                          error={formErrors.card}
                        />
                      </div>
                    </div>
                  ) : null}
                  {giftCardBalance ? (
                    <div className="row mb-2 col-12 px-1 align-items-center">
                      <div className="col-4 p-0 mb-3 font-weight-bold">
                        <label>{`${i18n.t(
                          "gift_card"
                        )} ($ ${giftCardBalance.toFixed(2)}) `}</label>
                      </div>
                      <div className="col-12 col-md-8 p-0">
                        <DollarInput
                          value={giftCard}
                          onChange={setGiftCard}
                          error={formErrors.giftCard}
                        />
                      </div>
                    </div>
                  ) : null}
                  <div className="row mb-2 col-12 px-1 align-items-center">
                    <div className="col-4 p-0 mb-3 font-weight-bold">
                      <label>{i18n.t("discount_amount")}</label>
                    </div>
                    <div className="col-12 col-md-8 p-0">
                      <DollarInput
                        value={discountAmount}
                        onChange={(value) => {
                          setDiscountAmount(value)
                          setDiscountPercent(
                            roundTo2Decimals(
                              (100 * value) / accountBalanceToCollect
                            )
                          )
                        }}
                        error={formErrors.discount}
                      />
                    </div>
                  </div>
                  <div className="row mb-2 col-12 px-1 align-items-center">
                    <div className="col-4 p-0 mb-3 font-weight-bold">
                      <label>{i18n.t("discount_percent")}</label>
                    </div>
                    <div className="col-12 col-md-8 p-0">
                      <PercentInput
                        value={discountPercent}
                        onChange={(percent) => {
                          setDiscountPercent(percent)
                          setDiscountAmount(
                            roundTo2Decimals(
                              (accountBalanceToCollect * percent) / 100
                            )
                          )
                        }}
                        error={formErrors.discount}
                      />
                    </div>
                  </div>
                </>
                <>
                  <div className="row mb-2 col-12 px-1 align-items-center">
                    <div className="col-4 p-0 mb-3 font-weight-bold">
                      <label>{i18n.t("total")}</label>
                    </div>
                    <div className="col-12 col-md-8 p-0">
                      <DollarInput
                        value={roundTo2Decimals(
                          parseFloat(cash) +
                            parseFloat(creditDebit) +
                            parseFloat(giftCard)
                        )}
                        readOnly={true}
                      />
                    </div>
                  </div>
                  <div className="row mb-2 col-12 px-1 align-items-center balance-due">
                    <div className="col-4 p-0 mb-3 font-weight-bold">
                      <label>
                        {difference < 0
                          ? i18n.t("change_due")
                          : i18n.t("balance_due")}
                      </label>
                    </div>
                    <div className="col-12 col-md-8 p-0">
                      <DollarInput value={difference} readOnly={true} />
                    </div>
                  </div>
                </>

                <div className="row mb-2 col-12 px-1 align-items-center">
                  <div className="col-4 p-0 mb-3 font-weight-bold">
                    <label>{i18n.t("note")}</label>
                  </div>
                  <div className="col-12 col-md-8 p-0">
                    <TextField
                      variant="outlined"
                      placeholder="Note"
                      onChange={(e) => setNote(e.target.value)}
                      helperText={formErrors.note}
                      error={Boolean(formErrors.note)}
                    />
                  </div>
                </div>
              </div>
            </div>
          ),
          actions: (
            <div className="row mx-0 justify-content-center justify-content-md-end gap-2 mx-4 mb-4">
              {screenSize == "mobile" && (
                <PrimaryButton className="w-128" onClick={handleClose}>
                  {i18n.t("close")}
                </PrimaryButton>
              )}
              <PrimaryButton
                className="w-128"
                onClick={() => {
                  validate()
                }}
              >
                {i18n.t("save")}
              </PrimaryButton>
            </div>
          ),
        }}
        showCloseBtn={screenSize !== "mobile" ? true : false}
        onClose={handleClose}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  transactions: state.bookingTransaction.data,
  cards: state.companyCustomerCard.data,
})

const action = {
  getRequest,
  insertCardRequest,
}

export default connect(mapStateToProps, action)(InsertPayment)
