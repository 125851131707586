import React from "react"
import moment from "moment"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import PrimaryButton from "../../Core/PrimaryButton"

import "./styles.scss"
import i18n from "../../../i18n"
import { Images } from "../../../theme"

moment.locale("en")

const ManageExtras = ({
  products = [],
  services = [],
  readonly,
  onProductChange = () => { },
  onProductDelete = () => { },
  onServiceDelete = () => { },
  onAddClick = () => { },
  collectedMap = {},
}) => {
  return (
    <>
      <div className="row mx-0 justify-content-between mt-4">
        <label className="font-weight-bold">{i18n.t("extras")}</label>
        {!readonly && (
          <PrimaryButton
            onClick={() => onAddClick()}
            className="w-128"
          >
            {i18n.t("add")}
          </PrimaryButton>
        )}
      </div>
      {products.length || services.length ? (
        <>
          <div className="mt-1">
            <Table
              // className={classes.table}
              aria-labelledby="tableTitle"
              size={"small"}
              aria-label="enhanced table"
            >
              <TableHead style={{ backgroundColor: "#F6943B" }}>
                <TableRow>
                  <TableCell style={{ color: "white" }}>Name</TableCell>
                  <TableCell style={{ color: "white" }}>Quantity</TableCell>
                  <TableCell style={{ color: "white" }}>Price</TableCell>
                  {!readonly && (
                    <TableCell style={{ color: "white" }}></TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {products.map((product, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={product._id}
                    >
                      <TableCell>{product.name}</TableCell>
                      <TableCell>
                        {!readonly &&
                          !collectedMap?.products?.[product._id] ? (
                          <input
                            type="number"
                            style={{ width: "60px" }}
                            disabled={readonly}
                            defaultValue={product.quantity}
                            min={1}
                            onBlur={(e) => {
                              let change = parseInt(e.target.value)
                              if (change < 0) {
                                change = 0
                              }
                              onProductChange(change, index)
                            }}
                          />
                        ) : (
                          <>{product.quantity}</>
                        )}
                      </TableCell>
                      <TableCell>
                        {(product.price * product.quantity).toFixed(2)}
                      </TableCell>
                      {!readonly && !collectedMap.products[product._id] ? (
                        <TableCell style={{ width: 43 }}>
                          <img
                            onClick={() => onProductDelete(index)}
                            className="extra-delete-icon"
                            src={Images.saloon_working_hour_delete}
                          />
                        </TableCell>
                      ) : (
                        <TableCell style={{ width: 30 }} />
                      )}
                    </TableRow>
                  )
                })}
                {services.map((service, index) => (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={service._id}
                  >
                    <TableCell>
                      {service.serviceId
                        ? service.serviceId.name
                        : service.name}
                    </TableCell>
                    <TableCell>1</TableCell>
                    <TableCell>{service.price}</TableCell>
                    {!readonly && !service.paid ? (
                      <TableCell style={{ width: 30 }}>
                        <img
                          onClick={() => onServiceDelete(index)}
                          className="extra-delete-icon"
                          src={Images.saloon_working_hour_delete}
                        />
                      </TableCell>
                    ) : (
                      <TableCell style={{ width: 30 }} />
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </>
      ) : null}
    </>
  )
}

export default ManageExtras
