import React, { useEffect, useMemo, useRef, useState } from "react"
import DatePicker from "react-datepicker"
import moment from "moment"
import useGoogleCalendar, {
  googleButton,
} from "../../../context/GoogleCalendar"
import "./PlanFilter.scss"
import i18n from "../../../i18n"
import PrimaryButton from "../../Core/PrimaryButton"
import SecondaryButton from "../../Core/SecondaryButton/SecondaryButton"
import TextField from "@material-ui/core/TextField"
import useOutlookCalendar, {
  outLookButton,
} from "../../../context/OutlookCalendar"
import SharedStorage from "../../../helpers/Storage"
import useScreenSize from "../../../context/ScreenSize"
import BaseModal from "../../BaseModal"
import { Button } from "@material-ui/core"
import { Images } from "../../../theme"
moment.locale("en")

const PlanFilter = ({
  handleCalendarView,
  handleCalendarDate,
  calDate,
  slideSideNav,
  displayAllAppointments,
}) => {
  const [active, setActive] = useState(null)
  const calViews = ["day", "work week", "week", "month"]
  const [selectedCalView, setSelectedCalView] = useState("day")
  const { setEmployeeForGoogle } = useGoogleCalendar()
  const { setEmployeeForOutlook } = useOutlookCalendar()
  const [openFilterModal, setOpenFilterModal] = useState(false)
  const [openOptionsModal, setOpenOptionsModal] = useState(false)
  const [index, setIndex] = useState(0)
  const { screenSize } = useScreenSize()
  const { forms_svg_dark, my_appointment_svg_light } = Images
  const mount = async () => {
    setEmployeeForGoogle(JSON.parse(await SharedStorage.getItem("employee")))
    setEmployeeForOutlook(JSON.parse(await SharedStorage.getItem("employee")))
    setSelectedCalView(
      JSON.parse(await SharedStorage.getItem("employee"))?.selectedCalView ||
        "day"
    )
  }
  useEffect(() => {
    mount()
  }, [])

  useEffect(() => {
    handleCalendarView(selectedCalView)
    const index = calViews.indexOf(selectedCalView)
    if (index === -1) return
    setIndex(index)
    setActive(index)
  }, [selectedCalView])

  const filtersRender = () => {
    return (
      <>
        <DatePicker
          selected={calDate}
          onChange={(date) => {
            handleCalendarDate(date)
          }}
          dateFormat="MM/dd/yyyy"
          variant="outlined"
          customInput={
            <TextField variant="outlined" className="w-100 mb-3 mb-md-0" />
          }
        />
        {calViews.map((view, index) => {
          return (
            <div
              name={i18n.t(view)}
              onClick={() => {
                handleCalendarView(view)
                setActive(index)
              }}
            >
              <SecondaryButton
                defaultValue={selectedCalView}
                className={`ml-2 ${active === index && "active"}`}
              >
                {i18n.t(view)}
              </SecondaryButton>
            </div>
          )
        })}
      </>
    )
  }
  return (
    <>
      <BaseModal
        open={openFilterModal}
        containerClassName="position-relative"
        onClose={() => setOpenFilterModal(false)}
        title={i18n.t("container.advancedSearchDialog_filter")}
        showCloseBtn={false}
        content={
          <>
            <div className="w-100 font-weight-bold">{i18n.t("date")}</div>
            <div className="plan-filter">
              <div className="toggle-nav">{filtersRender()}</div>
            </div>
            <div className="d-flex justify-content-center">
              <PrimaryButton
                className="w-128"
                label={i18n.t("close")}
                onClick={() => setOpenFilterModal(false)}
              />
            </div>
            <Button
              className="position-absolute font-weight-bold text-capitalize"
              style={{ color: "#F6943B", top: "20px", right: "10px" }}
              onClick={() => {
                handleCalendarView(selectedCalView)
                setActive(index)
              }}
            >
              {i18n.t("resetFilter")}
            </Button>
          </>
        }
      />
      <BaseModal
        open={openOptionsModal}
        onClose={() => setOpenOptionsModal(false)}
        title="Options"
        content={
          <div className="d-flex flex-column gap-2">
            {outLookButton()}
            {googleButton()}
          </div>
        }
      />
      <div className="d-flex align-items-center flex-wrap justify-content-between gap-2 mb-4">
        <span className="saloon-dashboard-heading px-0 pt-0">
          {i18n.t("container.my_calendar")}
        </span>
        <div className="topBar">
          <PrimaryButton className={screenSize !== "mobile" ? "w-98" : ""}>
            {screenSize !== "mobile" ? (
              i18n.t("calendar")
            ) : (
              <img src={my_appointment_svg_light} />
            )}
          </PrimaryButton>
          <SecondaryButton
            onClick={displayAllAppointments}
            className={screenSize !== "mobile" ? "w-98" : ""}
          >
            {screenSize !== "mobile" ? (
              i18n.t("table")
            ) : (
              <img src={forms_svg_dark} />
            )}
          </SecondaryButton>
        </div>
      </div>
      <div className="plan-filter">
        <div className="toggle-nav">
          <PrimaryButton
            label={i18n.t("view_settings")}
            onClick={slideSideNav}
            className="w-128 mr-0 mr-md-3"
          />
          {screenSize !== "mobile" ? (
            <>
              {filtersRender()}
              <div id="sync_button_wrapper" className="row gap-2 ml-3">
                {outLookButton()}
                {googleButton()}
              </div>
              <PrimaryButton
                className="ml-3 w-98"
                label={i18n.t("data_reset")}
                onClick={() => {
                  handleCalendarView(selectedCalView)
                  setActive(index)
                }}
              />
            </>
          ) : (
            <>
              <PrimaryButton
                className="w-98"
                label={i18n.t("container.advancedSearchDialog_filter")}
                onClick={() => {
                  setOpenFilterModal(true)
                }}
              />
              <PrimaryButton
                className=" w-98"
                label="Options"
                onClick={() => {
                  setOpenOptionsModal(true)
                }}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default PlanFilter
