import { connect } from "react-redux"
import React, { Component, useContext } from "react"
import { Translation } from "react-i18next"
import i18n from "../../../i18n"
import BookingNotesReminders from "../../../components/BookingNotesReminders"
import {
  DotsLoader,
  AdvancedSearchBox,
  BaseModal,
  AppointmentModal,
} from "../../../components"
import { request as SaloonAppointment } from "../../../redux/actions/Saloon/SaloonAppointment"
import { ErrorHelper, SuccessHelper } from "../../../helpers"
import DatePicker from "react-datepicker"
import { request as get_available_timeslots } from "../../../redux/actions/GetAvailableTimeslots.js"
import { request as get_gym_timeslots } from "../../../redux/actions/GetGymTimeslots.js"
import { insertRequest as createTransactions } from "../../../redux/actions/BookingTransaction"
import { clear } from "../../../redux/actions/ActiveBooking"
import {
  editBooking,
  getEmployeesBySaloonAndService,
  gitWaitingAppoinments,
  getSaloonSchedule,
  removeProduct,
  updateCartProduct,
  removeExtra,
  dismissCancelledBooking,
  getCompanyEmployeeUnavaiabilities,
} from "../../../config/simpleApiCalls"
import { getNestedValue } from "../../../util/objectMethods"
import Swal from "sweetalert2"
import moment from "moment"
import "./styles.scss"
import PlanView from "../../../components/CalendarView/components/PlanView"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import FormHelperText from "@material-ui/core/FormHelperText"
import FormControl from "@material-ui/core/FormControl"
import TextField from "@material-ui/core/TextField"

import AddExtrasModal from "../../../components/Extras/AddExtrasModal"
import RefreshIcon from "@material-ui/icons/Refresh"
import { BookingContext } from "../../../context/Booking"
import { getRequest as getClassEvents } from "../../../redux/actions/Saloon/CoursePlanner"
import ModifyModal from "../../../components/NotifyModal/ModifyModal.jsx"
import PrimaryButton from "../../../components/Core/PrimaryButton/index.jsx"
import SecondaryButton from "../../../components/Core/SecondaryButton/SecondaryButton"
import EditIcon from "../../../components/Core/BasicTable/Actions/EditIcon"
import useCache from "../../../context/LocalCache"
import Autocomplete from "@material-ui/lab/Autocomplete"
import Settings from "./settings"
import useGoogleCalendar from "../../../context/GoogleCalendar"
import useOutlookCalendar from "../../../context/OutlookCalendar"
import SharedStorage from "../../../helpers/Storage"
import { withRouter } from "react-router-dom"
import { Images } from "../../../theme"
import { ScreenSizeContext } from "../../../context/ScreenSize.jsx"
import { Button } from "@material-ui/core"
moment.locale(i18n.language)

const statusMap = {
  2: "container.services_now_serving_status",
  3: "cancelled",
  4: "container.services_done_status",
  7: "cancelled_no_show",
}
class _SalonAppointment extends Component {
  constructor(props) {
    super(props)
    const queryParams = new URLSearchParams(this.props.location.search)
    const shouldHideCalendar = queryParams.get("displayTable")
    this.state = {
      lastName: "",
      postalCode: "",
      email: "",
      phoneNumber: "",
      totalAmount: 0,
      selectedTime: "",
      selectPaymentMethod: undefined,
      selectedCategory: null,
      selectSalon: "",
      selectServices: "",
      selectedService: "",
      companyId: null,
      selectEmployee: "",
      categoryList: [],
      getSalonServices: [],
      selectDateAndTime: undefined,
      weekPlans: [],
      formErrors: {
        lastNameError: "",
        postalCodeError: "",
        phoneNumberError: "",
        emailError: "",
        totalAmountError: "",
        selectPaymentMethodError: "",
        selectServiceError: "",
        selectEmployeeError: "",
        selectDateAndTimeError: "",
        selectedTimeError: "",
      },
      openUpdateModify: false,
      bookingStatuses: [
        {
          text: i18n.t("container.services_waiting_status"),
          value: 1,
          styles: { color: "#aa43b5" },
        },
        {
          text: i18n.t("container.services_now_serving_status"),
          value: 3,
          styles: { color: "#FFE478" },
        },
        {
          text: i18n.t("container.services_cancelled_status"),
          value: 2,
          styles: { color: "#FF7082" },
        },
        {
          text: i18n.t("container.services_done_status"),
          value: 4,
          styles: { color: "#95DA47" },
        },
      ],

      bookings: null,
      employeesWorkingHoursData: [
        { dayName: "Sunday", dayOfWeek: 1, availableStatus: 0 },
        { dayName: "Monday", dayOfWeek: 2, availableStatus: 0 },
        { dayName: "Tuesday", dayOfWeek: 3, availableStatus: 0 },
        { dayName: "Wednesday", dayOfWeek: 4, availableStatus: 0 },
        { dayName: "Thursday", dayOfWeek: 5, availableStatus: 0 },
        { dayName: "Friday", dayOfWeek: 6, availableStatus: 0 },
        { dayName: "Saturday", dayOfWeek: 7, availableStatus: 0 },
      ],
      showPopUp: false,

      serviceList: [
        { name: "Hair Cutting", isSelected: false },
        { name: "Hair Cutting", isSelected: true },
        { name: "Hair Cutting", isSelected: false },
        { name: "Hair Cutting", isSelected: false },
      ],
      isVerified: false,
      employeeServicesList: [],
      employeesWorkingHoursError: false,
      hasSchedule: false,
      selectBookingAmount: "",
      tip: 0,
      filtersMap: {
        status: {
          1: true,
          2: true,
          3: true,
          4: true,
        },
        service: {},
        employee: {},
      },
      displayCalendar: !shouldHideCalendar,
      activeDate: null,
      calendarView: "day",
    }
  }

  onChangeSelectEmployee = (text) => {
    const { lockFields } = this.state
    let employee = JSON.parse(text.target.value)
    if (employee) {
      let employeeId = employee.employeeId._id
      this.setState({
        selectEmployee: employeeId,
        weekPlans: employee.weekPlans,
        selectedEmployee: text.target.value,
        ...(lockFields
          ? {}
          : {
              newDate: "",
              selectedTime: undefined,
              availableTimeslots: {},
              selectDateAndTime: undefined,
              newDate: "",
              selectedTime: undefined,
            }),
      })
    }
  }

  onChangeTime = (text) => {
    this.setState({
      selectedTime: text.target.value,
      newTime: text.target.value.split(" ")[0],
    })
  }

  onChangeTimeText = (text, key = "newTime") => {
    this.setState({
      selectedTime: text.target.value,
      [key]: text.target.value,
    })
  }

  onDateChange = async (selectDate) => {
    const {
      isGym,
      selectEmployee,
      selectedTime,
      enableDoubleBooking,
      enableWaitingList,
    } = this.state

    this.setState({
      selectedTime: enableDoubleBooking ? selectedTime : undefined,
      availableTimeslots: {},
      datePickerOpen: true,
    })
    let date = undefined
    if (selectDate) {
      date = moment(selectDate).format("MM-DD-YYYY")
      if (isGym) {
        const { companyData, selectServices } = this.state
        await this.props.get_gym_timeslots({
          companyId: companyData._id,
          date,
        })
      } else {
        const { companyData, selectServices } = this.state
        await this.props.get_available_timeslots({
          data: {
            companyId: companyData._id,
            companyServiceId: JSON.parse(selectServices)._id,
            date,
            companyEmployeeId: selectEmployee,
            showUnavailable: enableWaitingList,
          },
        })
      }
    }
    this.setState({ selectDateAndTime: selectDate, newDate: date })
  }

  async componentDidMount() {
    const { displayCalendar } = this.state
    if (!displayCalendar) {
      this.getSaloonAppoint(true)
    }
    const companyData = await JSON.parse(await SharedStorage.getItem("company"))
    const employeeData = await JSON.parse(
      await SharedStorage.getItem("employee")
    )
    const viewWorkingHoursUnavailabilities =
      localStorage.getItem("viewWorkingHoursUnavailabilities") == "true"
    const viewUnavailabilities =
      localStorage.getItem("viewUnavailabilities") == "true"

    this.setState({ viewWorkingHoursUnavailabilities, viewUnavailabilities })
    this.setState({
      company: companyData,
      calendarView: employeeData?.selectedCalView || "day",
    })
    const { timeSlotLength, _id } = companyData
    const isGym =
      JSON.parse(await SharedStorage.getItem("company"))?.function == "gym"
    this.setState(
      { isGym, timeSlotLength, companyData, activeDate: Date.now() },
      () => {
        Promise.all([
          getSaloonSchedule()
            .then((res) => {
              if (res.data.success && res.data.data) {
                let start = undefined
                res.data.data.weekPlans.map((dayPlan) => {
                  const { checkIn } = dayPlan
                  if (checkIn) {
                    let hour = parseInt(checkIn.split(":")[0])
                    if (start) {
                      if (start > hour) {
                        start = hour
                      }
                    } else {
                      start = hour
                    }
                  }
                })
                this.setState({
                  start,
                  isloading: false,
                })
              } else {
                this.setState({ isloading: false })
              }
            })
            .catch((error) => {
              console.log(error, "error")
            }),
        ]).then(() => {
          this.setState({ initialized: true })
        })
      }
    )
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { services, employees: allEmployees } = nextProps
    const { filtersMap } = prevState
    let allServices = []
    filtersMap.service = {}
    services.map((service) => {
      if (getNestedValue(service.serviceId, ["name"])) {
        allServices.push(service)
        filtersMap.service[getNestedValue(service, ["name"])] = true
      }
    })
    let employeeColorMap = {}
    let employeeMap = {}
    filtersMap.employee = {}
    const employees = allEmployees.map((companyEmployee) => {
      const { employee, user, archivedEmployeeId, isActive } = companyEmployee

      employeeColorMap[employee?._id || archivedEmployeeId._id] =
        companyEmployee.color || "#000000"
      employeeMap[employee?._id || archivedEmployeeId._id] = user.userName
        ? user.userName
        : `${getNestedValue(user, "firstName", "")} ${getNestedValue(
            user,
            "lastName",
            ""
          )}`
      filtersMap.employee[employee?._id || archivedEmployeeId._id] = true
      return {
        text: user.userName
          ? user.userName
          : `${getNestedValue(user, "firstName", "")} ${getNestedValue(
              user,
              "lastName",
              ""
            )}`,
        value: employee?._id || archivedEmployeeId._id,
        isActive,
        profile_img: user?.profile_img || "",
      }
    })
    let toSet = {}
    if (nextProps.booking) {
      nextProps.clear()
      const service = getNestedValue(nextProps.booking, ["services", 0], {})
      const booking = { ...nextProps.booking }
      toSet = {
        ...toSet,
        showBookingModal: true,
        selectBooking: booking,
        activeDate: moment(service.date, "MM-DD-YYYY").toDate(),
        selectBookingAmount: nextProps.booking.totalAmount,
        paymentMethod: nextProps.booking.paymentMethod,
        bookingStaus: nextProps.booking.status,
        estimateDuration: service.duration,
        editDetails: false,
      }
    }
    return {
      employees,
      filtersMap,
      employeeColorMap,
      employeeMap,
      rawEmployees: allEmployees,
      allServices,
      ...toSet,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (this.state.activeDate &&
        this.state.activeDate !== prevState.activeDate) ||
      (!prevState.initialized && this.state.initialized) ||
      this.state.calendarView !== prevState.calendarView
    ) {
      this.getSaloonAppoint()
      this.getCompanyEmployeeUnavaiabilities()
      this.getClassEvents()
    }
  }

  getEmployee = async (selectServices) => {
    const serviceData = selectServices
      ? JSON.parse(selectServices)
      : selectServices
    this.setState({ serviceData })
    const { companyData, lockFields } = this.state

    this.setState({ isloading: true })
    try {
      const res = await getEmployeesBySaloonAndService({
        companyId: companyData._id,
        serviceId: serviceData._id,
      })
      this.setState({
        getEmployeeData: res.data.data,
        isloading: false,
        selectServices,
      })
      if (lockFields) {
        const { newDate } = this.state
        let date = newDate
        const { companyData } = this.state
        this.setState({ isloading: true })
        this.props.get_available_timeslots({
          data: {
            companyId: companyData._id,
            companyServiceId: serviceData._id,
            date,
          },
        })
      }
      return res.data.data
    } catch (error) {
      ErrorHelper.handleErrors(
        "Aww somthing went wrong for getting Employees",
        true
      )
      this.setState({ isloading: false })
    }
  }

  handleFilters = (data) => {
    let filteredBookings = this.state.bookings
    console.log(data)
    if (data) {
      const normalize = (value) => {
        value = value ? value : ""
        return value.toLowerCase()
      }
      data.name = normalize(data.name)
      data.phoneNumber = normalize(data.phoneNumber).replace(/ /g, "")
      filteredBookings = filteredBookings.filter((booking) => {
        if (data.name) {
          if (
            !normalize(getNestedValue(booking, ["userId", "userName"])).match(
              data.name
            ) &&
            !normalize(getNestedValue(booking, "name", "")).match(data.name) &&
            !normalize(getNestedValue(booking, ["userId", "firstName"])).match(
              data.name
            ) &&
            !normalize(getNestedValue(booking, ["userId", "lastNme"])).match(
              data.name
            )
          ) {
            return false
          }
        }
        if (data.shortenedId) {
          if (
            !getNestedValue(booking, ["shortenedId"]).match(data.shortenedId)
          ) {
            return false
          }
        }
        if (data.phoneNumber) {
          if (
            !normalize(
              getNestedValue(booking, ["userId", "phoneNo"], "")
            ).match(data.phoneNumber) &&
            !normalize(getNestedValue(booking, "phoneNo", "")).match(
              data.phoneNumber
            )
          ) {
            return false
          }
        }
        if (data.service && data.service.length) {
          if (
            !getNestedValue(booking, ["services"], []).filter((value) =>
              data.service.includes(
                getNestedValue(value, ["serviceId", "name"], "").trim()
              )
            ).length
          ) {
            return false
          }
        }
        if (data.employee && data.employee.length) {
          if (
            !getNestedValue(booking, ["services"], []).filter((value) =>
              data.employee.includes(value.employeeId)
            ).length
          ) {
            return false
          }
        }
        if (data.startDate || data.endDate) {
          // moment
          if (data.startDate && data.endDate) {
            const date = moment(
              getNestedValue(booking, ["services", 0, "date"], ""),
              "MM-DD-YYYY"
            )
            if (
              !moment(data.startDate).isSameOrBefore(date) ||
              !moment(data.endDate).isSameOrAfter(date)
            ) {
              return false
            }
          } else {
            if (
              (data.startDate &&
                normalize(
                  getNestedValue(booking, ["services", 0, "date"], "")
                ) != moment(data.startDate).format("MM-DD-YYYY")) ||
              (data.endDate &&
                normalize(
                  getNestedValue(booking, ["services", 0, "date"], "")
                ) != moment(data.endDate).format("MM-DD-YYYY"))
            ) {
              return false
            }
          }
        }
        if (data.status) {
          if (
            (Array.isArray(data.status) &&
              !data.status.includes(getNestedValue(booking, ["status"], 0))) ||
            (!Array.isArray(data.status) &&
              data.status != `${getNestedValue(booking, ["status"], 0)}`)
          ) {
            return false
          }
        }
        return true
      })
    }
    this.setState({ filteredBookings, showAdvancedSearchPopup: false })
  }

  generateDates = () => {
    const { activeDate, calendarView } = this.state
    let dates
    if (calendarView == "month") {
      dates = []

      var daysInMonth = moment(activeDate).daysInMonth()
      for (let i = 1; i <= daysInMonth; i++) {
        var current = moment(activeDate).date(i)
        dates.push(current.format("MM-DD-YYYY"))
      }
    } else {
      const date = moment(activeDate).startOf("week")

      dates = [date.format("MM-DD-YYYY")]

      for (let i = 0; i < 7; i++) {
        date.add(1, "d")

        dates.push(date.format("MM-DD-YYYY"))
      }
    }
    this.props.setTimeMin(moment(dates[0]).startOf("month").toISOString())
    this.props.setTimeMax(moment(dates[0]).endOf("month").toISOString())

    this.props.setMinStartTime(moment(dates[0]).startOf("month").toISOString())
    this.props.setMaxStartTime(moment(dates[0]).endOf("month").toISOString())

    return dates
  }

  getSaloonAppoint = async (getAll) => {
    const { activeDate } = this.state

    const query = { relations: ["notes", "reminders"] }
    if (!getAll) {
      const dates = this.generateDates()
      query.dates = dates.join(",")
    }
    try {
      this.setState({ isloading: true })
      const res = await gitWaitingAppoinments(query)
      this.setState({
        bookings: res.data.data.map((element, index) => {
          element.services = element.services.map((service) => {
            return {
              ...service,
              employeeId: service.employeeId?._id,
            }
          })
          return { ...element, index }
        }),
        isloading: false,
      })
    } catch (error) {
      console.log(error)
      alert("Something went wrong:(")
    }
  }

  getClassEvents = () => {
    this.props.getClassEvents({})
  }

  renderEditDetail = () => {
    const { showReinstateModal } = this.state
    return {
      title: showReinstateModal
        ? i18n.t("reinstate_appointment")
        : i18n.t("modify"),
      content: this.renderEmployeeDetail(),
      contentClassName: "modify-modal-content",
      actions: (
        <div className="row mx-0 justify-content-end">
          {this.state.openUpdateModify ? (
            <ModifyModal
              cancelledAppointment={showReinstateModal}
              handleClose={() => this.setState({ openUpdateModify: false })}
              onSubmit={({ notifyCustomer }) => {
                this.handleUpdate({}, notifyCustomer)
              }}
            />
          ) : null}
          <PrimaryButton
            onClick={() => this.setState({ openUpdateModify: true })}
            className="w-128 mr-4 mb-4"
          >
            {showReinstateModal ? i18n.t("reinstate") : i18n.t("update")}
          </PrimaryButton>
        </div>
      ),
    }
  }

  handleClosePopup = () => {
    this.setState({
      showProductsServicesModal: false,
      showNotesModal: false,
      showExtrasModal: false,
      editDetails: false,
      showPopUp: false,
    })
  }

  handleAddProductsServices = async () => {
    this.setState({
      showProductsServicesModal: true,
      showPopUp: true,
    })
  }

  handleNotes = async () => {
    this.setState({
      showNotesModal: true,
      showPopUp: true,
    })
  }

  deleteCancelledAppointment = (_id, index) => {
    Swal.fire({
      title: i18n.t("are_you_sure_delete"),
      showCancelButton: true,
      cancelButtonText: i18n.t("no"),
      confirmButtonText: i18n.t("yes"),
    }).then(async (e) => {
      const { isConfirmed } = e
      if (isConfirmed) {
        const { bookings } = this.state
        this.setState({ isloading: true })
        dismissCancelledBooking({ id: _id })
          .then(({ data }) => {
            if (data.success) {
              let newBookings = bookings
              newBookings.splice(index, 1)
              this.setState({
                bookings: newBookings.map((booking, index) => ({
                  ...booking,
                  index,
                })),
                isloading: false,
                showReinstateModal: false,
              })
            }
          })
          .finally(() => {
            this.setState({ isloading: false })
          })
      }
    })
  }

  handleUpdate = async (extras = {}, notifyCustomer) => {
    const {
      paymentMethod,
      selectBookingAmount,
      selectBooking,
      bookingStaus,
      notes,
      tip,
      editDetails,
      bookings,
      showReinstateModal,
      selectedService,
    } = this.state

    if (editDetails) {
      const {
        newDate,
        selectDateAndTime,
        selectedTime,
        newTime,
        selectEmployee,
      } = this.state

      if (!selectEmployee) {
        this.setState({
          formErrors: {
            selectEmployeeError: "Please select Employee",
          },
        })
        setTimeout(() => {
          this.setState({
            formErrors: {
              selectEmployeeError: "",
            },
          })
        }, 8000)
        return true
      } else if (!selectDateAndTime) {
        this.setState({
          formErrors: {
            selectDateAndTimeError: "Please select Date",
          },
        })
        setTimeout(() => {
          this.setState({
            formErrors: {
              selectDateAndTimeError: "",
            },
          })
        }, 8000)
      } else if (!selectedTime) {
        this.setState({
          formErrors: {
            selectedTimeError: "Please select Time",
          },
        })
        setTimeout(() => {
          this.setState({
            formErrors: {
              selectedTimeError: "",
            },
          })
        }, 8000)
      } else if (
        !newTime ||
        !newTime.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)
      ) {
        this.setState({
          formErrors: {
            selectedTimeError: "Invalid, expected hh:mm",
          },
        })
        setTimeout(() => {
          this.setState({
            formErrors: {
              selectedTimeError: "",
            },
          })
        }, 8000)
      } else if (!selectedService) {
        this.setState({
          formErrors: {
            selectServiceError: "Please select Service",
          },
        })
        setTimeout(() => {
          this.setState({
            formErrors: {
              selectServiceError: "",
            },
          })
        }, 8000)
      } else {
        const services = [
          {
            serviceId: selectedService._id,
            categoryId: selectedService.serviceId?._id,
            date: newDate,
            time: newTime,
            employeeId: selectEmployee,
          },
        ]

        const { data } = await editBooking({
          bookingId: selectBooking._id,
          services,
          isFinalized: selectBooking.isFinalized,
          notifyCustomer: notifyCustomer ? true : false,
          status: showReinstateModal ? 1 : selectBooking.status,
          totalAmount: selectedService.price,
        })
        let newSelectBooking = selectBooking

        if (data.success) {
          const newBooking = data.data
          newBooking.services = newBooking.services.map((service) => {
            return {
              ...service,
              employeeId: service.employeeId._id,
            }
          })
          newSelectBooking = { ...newBooking, index: selectBooking.index }
          if (typeof selectBooking.index === "number") {
            bookings[selectBooking.index] = newSelectBooking
          }

          this.handleClosePopup()
          this.setState({
            isloading: false,
            bookings: [...bookings],
            selectBooking: newSelectBooking,
            editDetails: false,
          })
          const msg = showReinstateModal ? "Reinstated" : "Updated"
          this.setState({ showReinstateModal: false, openUpdateModify: false })
          SuccessHelper.handleSuccess(msg + " Successfully", true)
        }
      }
    } else {
      const sendRequst = async (isFinalized) => {
        const taxes = []
        const payload = {
          bookingId: selectBooking._id,
          services: selectBooking.services.map((service) => ({
            employeeId:
              typeof service.employeeId == "object"
                ? service.employeeId._id
                : service.employeeId,
            serviceId: service.serviceId._id,
            categoryId: service.categoryId?._id,
            time: service.time,
            date: service.date,
          })),
          totalAmount: parseInt(selectBookingAmount),
          paymentMethod,
          status: bookingStaus,
          notes,
          tip: parseFloat(tip),
          taxes,
          isFinalized,
          ...extras,
        }

        try {
          this.setState({ isloading: true })
          const { data } = await editBooking(payload)
          let newSelectBooking = selectBooking
          if (data.success) {
            data.data.services = data.data.services.map((service) => {
              return {
                ...service,
                employeeId: service.employeeId._id,
              }
            })
            if (
              (data.data.status == 4 && data.data.isFinalized) ||
              data.data.status == 3
            ) {
              if (typeof selectBooking.index === "number") {
                bookings.splice(selectBooking.index, 1)
              } else {
                const booking = bookings.find(
                  (booking) => booking._id == selectBooking._id
                )
                bookings.splice(booking.index, 1)
              }
              bookings.map((booking, index) => (booking.index = index))
            } else {
              newSelectBooking = {
                ...selectBooking,
                ...data.data,
                index: selectBooking.index,
              }
              if (typeof selectBooking.index === "number") {
                bookings[selectBooking.index] = {
                  ...newSelectBooking,
                  index: selectBooking.index,
                }
              }
            }
            this.setState({
              isloading: false,
              bookings: [...bookings],
              selectBooking: newSelectBooking,
            })
            this.handleClosePopup()
            SuccessHelper.handleSuccess("Updated Successfully", true)
            this.setState({ showBookingModal: false })
          } else {
            this.setState({ isloading: false })
            ErrorHelper.handleErrors(data.msg, true)
          }
        } catch (error) {
          console.log(error)
          this.setState({ isloading: false })
          if (error.data.msg) {
            ErrorHelper.handleErrors(error.data.msg, true)
          } else if (error.response) {
            alert(error.response.data.msg)

            // Request made and server responded
          } else if (error.request) {
            alert("Something Went Wrong ")

            // The request was made but no response was received
          } else {
            alert(error.msg)
            // Something happened in setting up the request that triggered an Error
          }
        }
      }
      if (bookingStaus == 4) {
        Swal.fire({
          title: i18n.t("are_you_sure_you_want_to_finalize_this_booking"),
          showCancelButton: true,
          cancelButtonText: "No",
          confirmButtonText: "Yes",
        }).then((e) => {
          const { dismiss, isConfirmed, isDismissed } = e
          if (!isDismissed || dismiss != "backdrop") {
            if (isConfirmed) {
              sendRequst(isConfirmed)
              this.setState(
                {
                  showInsertPayment: true,
                },
                () => {
                  this.setState({
                    showInsertPayment: false,
                  })
                }
              )
            } else {
              sendRequst(isConfirmed)
            }
          }
        })
      } else {
        sendRequst(false)
      }
    }
  }

  renderExtras = () => {
    const { selectBooking, bookings, allServices } = this.state
    const { products } = this.props
    const onUpdate = (data) => {
      this.setState({ ...data })
    }
    return {
      title: i18n.t("container.add_extras"),
      content: (
        <AddExtrasModal
          selectBooking={selectBooking}
          products={products}
          bookings={bookings}
          allServices={allServices}
          onUpdate={onUpdate}
          handleClose={this.handleClosePopup}
        />
      ),
    }
  }

  renderBookingNotes = () => {
    const { selectBooking } = this.state
    return {
      title: i18n.t("notes_and_reminders"),
      content: (
        <BookingNotesReminders
          booking={selectBooking}
          hasEditPermission={true}
        />
      ),
    }
  }

  handleEditDetails = async () => {
    const { selectBooking, companyData } = this.state
    const selectService = getNestedValue(
      selectBooking,
      ["services", 0, "serviceId"],
      {}
    )
    let selectEmployee = getNestedValue(
      selectBooking,
      ["services", 0, "employeeId"],
      {}
    )
    const momentDate = moment(
      `${getNestedValue(
        selectBooking,
        ["services", 0, "date"],
        ""
      )} ${getNestedValue(selectBooking, ["services", 0, "time"], "")}`,
      "MM-DD-YYYY HH:mm"
    )
    Promise.all([
      new Promise((resolve) => {
        this.props.get_available_timeslots({
          data: {
            companyId: companyData._id,
            companyServiceId: selectService._id,
            date: momentDate.format("MM-DD-YYYY"),
            bookingIdToExclude: selectBooking._id,
            companyEmployeeId: selectEmployee,
          },
          resolve,
        })
      }),
      this.getEmployee(JSON.stringify(selectService)),
    ]).then(() => {
      setTimeout(() => {
        const { getEmployeeData, availableTimeslots } = this.state
        let selectedEmployee = getEmployeeData.find(
          (employee) => employee.employeeId._id == selectEmployee
        )
        this.setState({
          showPopUp: true,
          enableDoubleBooking: false,
          enableWaitingList: false,
          editDetails: true,
          lockFields: false,
          selectedEmployee: JSON.stringify(selectedEmployee),
          selectEmployee,
          selectedService: selectService,
          newDate: momentDate.format("MM-DD-YYYY"),
          newTime: momentDate.format("HH:mm"),
          selectDateAndTime: momentDate.toDate(),
          selectedTime: `${momentDate.format("HH:mm")} - ${moment(momentDate)
            .add(parseInt(selectService.duration), "minutes")
            .format("HH:mm")}`,
        })
      }, 50)
    })
  }

  handleAddProductsServices = () => {
    this.setState({
      showExtrasModal: true,
      showPopUp: true,
    })
  }

  renderPopup = () => {
    const {
      showPopUp,
      allSaloonMap,
      showNotesModal,
      showExtrasModal,
      editDetails,
    } = this.state
    let modalFunction
    if (showNotesModal) {
      modalFunction = this.renderBookingNotes
    } else if (showExtrasModal) {
      modalFunction = this.renderExtras
    } else if (editDetails) {
      modalFunction = this.renderEditDetail
    }
    const modalProps = modalFunction ? modalFunction() : {}
    return (
      <BaseModal
        style={{ zIndex: 1303 }}
        containerClassName={"half-container"}
        open={showPopUp}
        {...modalProps}
        onClose={() => this.handleClosePopup()}
      />
    )
  }

  renderLoader = () => {
    const { isloading, initialized } = this.state
    return <DotsLoader isloading={isloading || !initialized} />
  }

  onChangeSearchText = (searchText) => {
    this.setState({ searchText })
  }

  renderSaloonDashboardHeading = () => {
    const { searchText, filteredBookings } = this.state
    const { showAdvancedSearchPopup, allServices = [], employees } = this.state
    const { forms_svg_light, my_appointment_svg_dark } = Images
    const {
      screenSizeContext: { screenSize },
    } = this.props

    return (
      <>
        <div className="col-12 pb-4 px-0">
          <div
            className="row align-items-center"
            style={screenSize === "mobile" ? { gap: "0.5rem" } : {}}
          >
            <span className="saloon-dashboard-heading col-12 col-md-4 px-0 pt-0">
              {i18n.t("container.my_calendar")}
            </span>
            <div className="col-12 col-md-8  px-0 row gap-2 justify-content-center justify-content-md-end">
              {screenSize === "mobile" && (
                <PrimaryButton
                  type="button"
                  onClick={() => {
                    this.setState({ openFilterModal: true })
                  }}
                  className="w-128"
                >
                  {i18n.t("filters")}
                </PrimaryButton>
              )}
              <div className="d-flex align-items-center justify-content-end">
                <SecondaryButton
                  className={screenSize !== "mobile" ? "w-98" : ""}
                  type="button"
                  onClick={() => {
                    setTimeout(() => {
                      this.handleFilters()
                    }, 500)
                    this.setState({
                      displayCalendar: true,
                    })
                  }}
                >
                  {screenSize !== "mobile" ? (
                    <Translation>{(t) => t("calendar")}</Translation>
                  ) : (
                    <img src={my_appointment_svg_dark} />
                  )}
                </SecondaryButton>
                <PrimaryButton
                  className={screenSize !== "mobile" ? "w-98" : ""}
                >
                  {screenSize !== "mobile" ? (
                    <Translation>{(t) => t("table")}</Translation>
                  ) : (
                    <img src={forms_svg_light} />
                  )}
                </PrimaryButton>
              </div>
            </div>
          </div>
        </div>
        {screenSize !== "mobile" && (
          <>
            <div className="text-right mb-3">
              <PrimaryButton
                type="button"
                onClick={() => {
                  if (!filteredBookings) {
                    this.setState({ showAdvancedSearchPopup: true })
                  } else {
                    this.setState({ filteredBookings: undefined })
                  }
                }}
                className="w-128"
              >
                {filteredBookings ? (
                  <Translation>{(t) => t("filterReset")}</Translation>
                ) : (
                  <Translation>{(t) => t("filters")}</Translation>
                )}
              </PrimaryButton>
            </div>
            <div className="filter-header">{this.renderAdvanceSearchBox()}</div>
          </>
        )}
      </>
    )
  }
  renderAdvanceSearchBox = () => {
    const { allServices = [], employees, filteredBookings } = this.state
    return (
      <AdvancedSearchBox
        services={allServices.map((service) =>
          getNestedValue(service, ["name"])
        )}
        employees={employees}
        onFilter={(data) => this.handleFilters(data)}
        hideFitlerDetails={false}
        filteredBookings={filteredBookings}
        extraStatues={[
          { text: "Waiting", value: 1 },
          { text: "Now Serving", value: 2 },
        ]}
        onClose={() => this.setState({ showAdvancedSearchPopup: false })}
      ></AdvancedSearchBox>
    )
  }
  getContrastYIQ = (hexcolor) => {
    if (hexcolor) {
      hexcolor = hexcolor.replace("#", "")
      var r = parseInt(hexcolor.substr(0, 2), 16)
      var g = parseInt(hexcolor.substr(2, 2), 16)
      var b = parseInt(hexcolor.substr(4, 2), 16)
      var yiq = (r * 299 + g * 587 + b * 114) / 1000
      return yiq >= 128 ? "black" : "white"
    } else {
      return "black"
    }
  }

  onCalendarClick = (date, employeeId = "") => {
    const { timeSlotLength, rawEmployees } = this.state
    let employee = ""
    if (employeeId) {
      employee = rawEmployees.find((employee) => {
        return employee.employeeId._id == employeeId
      })
      employee = employee ? JSON.stringify(employee) : ""
    }
    const momentDate = moment(date)
    let remainder = parseInt(momentDate.format("mm")) % timeSlotLength
    momentDate.subtract(remainder, "m")
    this.context.setAddOpen(true)
    this.context.setLockFields(true)

    this.context.onChange({
      selectedTime: momentDate.format("HH:mm"),
      newTime: momentDate.format("HH:mm"),
      selectDateAndTime: momentDate.startOf("d").toDate(),
      formattedDate: momentDate.format("MM-DD-YYYY"),
      newDate: momentDate.format("MM-DD-YYYY"),
    })
  }

  setViewWorkingHoursUnavailabilities = (viewWorkingHoursUnavailabilities) => {
    localStorage.setItem(
      "viewWorkingHoursUnavailabilities",
      viewWorkingHoursUnavailabilities
    )
    this.setState({
      viewWorkingHoursUnavailabilities,
    })
  }
  setViewUnavailabilities = (viewUnavailabilities) => {
    localStorage.setItem("viewUnavailabilities", viewUnavailabilities)
    this.setState({
      viewUnavailabilities,
    })
  }

  Plan = (bookingsToRender) => {
    const {
      employees,
      allServices = [],
      start,
      employeeColorMap = {},
      employeeMap = {},
      selectBooking = {},
      activeDate,
      unavailabilities = [],
      workingDayUnavailabilities = [],
      viewWorkingHoursUnavailabilities,
      viewUnavailabilities,
    } = this.state
    const { events = [] } = this.props
    let formattedUnavailabilities = viewUnavailabilities
      ? unavailabilities.map((element) => ({
          ...element,
          textColor: this.getContrastYIQ(employeeColorMap[element.employeeId]),
          resourceId: element.employeeId,
          color: employeeColorMap[element.employeeId] || "#000",
          title: ` ${employeeMap[element.employeeId]} - ${i18n.t(
            "unavailable"
          )}`,
        }))
      : []
    let formattedViewWorkingHoursUnavailabilities =
      viewWorkingHoursUnavailabilities
        ? workingDayUnavailabilities.map((element) => ({
            ...element,
            textColor: this.getContrastYIQ(
              employeeColorMap[element.employeeId]
            ),
            resourceId: element.employeeId,
            color: employeeColorMap[element.employeeId] || "#000",
            title: ` ${employeeMap[element.employeeId]} - ${i18n.t(
              "unavailable"
            )}`,
          }))
        : []
    const statusClassMap = {
      1: "calendar-event-waiting",
      2: "calendar-event-now-serving",
      3: "calendar-event-cancel",
      4: "calendar-event-done",
    }
    const formattedEvents = events
      .map((event) => {
        if (!event.employeeId) {
          return null
        }
        const color = event.classId?.color || "#000"
        return {
          title: ` ${employeeMap[event.employeeId]} - ${event.classId?.name}`,
          start: moment(event.startDateTime).toDate(),
          end: moment(event.endDateTime).toDate(),
          backgroundColor: color,
          resourceId: event.employeeId,
          textColor: this.getContrastYIQ(color),
          className: "unavailability-event",
          event: {
            ...event,
          },
        }
      })
      .filter((event) => !!event)
    const date = getNestedValue(selectBooking, ["services", 0, "date"], "")
    const plans = bookingsToRender.map((booking) => {
      const { services, _id, name } = booking
      return services.map((service) => {
        const date = moment(
          `${service.date} ${service.time}`,
          "MM-DD-YYYY HH:mm"
        )
        const start = date.toDate()
        const end = date
          .add(
            parseInt(booking.duration || service.serviceId.duration),
            "minutes"
          )
          .add(parseInt(booking.adjustedTime || 0), "minutes")
          .toDate()
        if (booking.type == "Staff Meeting") {
          return {
            ...booking,
            id: _id,
            title: `${i18n.t("staff_meeting")}`,
            employeeName: "",
            allDay: false,
            start,
            end,
            textColor: this.getContrastYIQ(
              employeeColorMap[service.employeeId]
            ),
            color: employeeColorMap[service.employeeId],
            duration: booking.duration + (booking.adjustedTime || 0),
            className: statusClassMap[booking.status],
            type: "booking",
            resourceId: service.employeeId,
            bookingType: booking.type,
          }
        }
        let employeeName = ""
        if (employeeMap[service.employeeId]) {
          employeeName = employeeMap[service.employeeId].split(" ")[0]
        }
        return {
          ...booking,
          id: _id,
          title: `${name} - ${service.serviceId.name}`,
          employeeName,
          allDay: false,
          start,
          end,
          textColor: this.getContrastYIQ(employeeColorMap[service.employeeId]),
          color: employeeColorMap[service.employeeId],
          duration: service.serviceId.duration + (booking.adjustedTime || 0),
          className: statusClassMap[booking.status],
          type: "booking",
          resourceId: service.employeeId,
          promotionId: service.promotionId,
          bookingType: booking.type,
        }
      })
    })
    const onEventClick = (val) => {
      if (val.status != 3) {
        this.setState({
          showBookingModal: true,
          selectBooking: val,
          selectBookingAmount: val.totalAmount,
          paymentMethod: val.paymentMethod,
          bookingStaus: val.status, //41 45 24
          estimateDuration: val.duration,
          notes: val.notes,
          editDetails: false,
          tip: val.tip,
        })
      } else {
        this.setState({
          selectBooking: val,
          showReinstateModal: true,
          serviceDate: moment(
            `${val.services[0].date} ${val.services[0].time}`
          ),
        })
      }
    }
    return (
      <>
        <Settings
          title={i18n.t("view_settings")}
          style={{ zIndex: 1303 }}
          open={this.state.isOpen}
          onClick={this.handleFilters}
          employees={employees}
          services={allServices.map((service) =>
            getNestedValue(service, ["name"])
          )}
          setViewWorkingHoursUnavailabilities={
            this.setViewWorkingHoursUnavailabilities
          }
          setViewUnavailabilities={this.setViewUnavailabilities}
          viewWorkingHours={viewWorkingHoursUnavailabilities}
          viewUnavailabilities={viewUnavailabilities}
          onClose={(open) => {
            this.setState({
              isOpen: typeof open == "boolean" ? open : !this.state.isOpen,
            })
          }}
        />
        <PlanView
          open={this.state.isOpen}
          width="17vw"
          slideSideNav={(open) => {
            this.setState({
              isOpen: typeof open == "boolean" ? open : !this.state.isOpen,
            })
          }}
          onEventClick={onEventClick}
          plans={[
            ...plans,
            formattedUnavailabilities,
            formattedViewWorkingHoursUnavailabilities,
            formattedEvents,
          ]}
          displayAllAppointments={() => {
            this.getSaloonAppoint(true)
            this.setState({ displayCalendar: false })
            this.getClassEvents()
          }}
          onDateClick={this.onCalendarClick}
          start={start}
          date={activeDate}
          onDateChange={(e) => {
            this.setState({ activeDate: e })
          }}
          calendarView={(calendarView) => {
            this.setState({ calendarView })
          }}
          onDismiss={(_id, index) => {
            this.deleteCancelledAppointment(_id, index)
          }}
          employees={employees}
          isSaloon
        />
      </>
    )
  }
  renderAppointmentCalendar = (bookings) => {
    const { searchText, filteredBookings } = this.state
    if (!bookings) return null
    let renderBooking = bookings
    if (filteredBookings) {
      renderBooking = filteredBookings
    } else if (searchText)
      renderBooking = bookings?.filter((val) => {
        var ll = getNestedValue(val, ["userId", "userName"], "") || ""
        if (
          ll.toLowerCase().includes(searchText.toLowerCase()) ||
          val?.phoneNo?.includes(searchText)
        )
          return true
        return false
      })
    return <div className="col-12 px-0">{this.Plan(renderBooking)}</div>
  }
  renderAppointmentTable = (bookings) => {
    const {
      screenSizeContext: { screenSize },
    } = this.props
    return (
      <div className="col-12 px-0">
        {this.renderSaloonDashboardHeading()}
        <div
          className="table-responsive"
          style={
            screenSize === "mobile" && bookings?.length > 0
              ? { height: "500px" }
              : { height: "80vh" }
          }
        >
          <table className="table table-borderless saloon-working-hour-table">
            {this.renderTableHead()}
            {bookings && this.renderTableBody(bookings)}
          </table>
        </div>
      </div>
    )
  }

  renderAdvancedSearchPopup = () => {
    const { showAdvancedSearchPopup, allServices = [], employees } = this.state
    return (
      showAdvancedSearchPopup && (
        <AdvancedSearchBox
          services={allServices.map((service) =>
            getNestedValue(service, ["name"])
          )}
          employees={employees}
          onFilter={(data) => this.handleFilters(data)}
          hideFitlerDetails={false}
          extraStatues={[
            { text: "Waiting", value: 1 },
            { text: "Now Serving", value: 2 },
          ]}
          onClose={() => this.setState({ showAdvancedSearchPopup: false })}
        ></AdvancedSearchBox>
      )
    )
  }

  renderFOBs = () => {
    const { bookings = [], displayCalendar } = this.state
    return (
      <>
        <button
          className="refresh-fob p-1"
          onClick={() => {
            this.getSaloonAppoint()
            this.getCompanyEmployeeUnavaiabilities()
            this.getClassEvents()
          }}
        >
          <RefreshIcon />
        </button>
      </>
    )
  }

  onChange = (data, then = () => {}) => {
    this.setState(data, then)
  }

  renderTimeSelection = () => {
    const {
      formErrors,
      enableDoubleBooking,
      enableWaitingList,
      newTime,
      timeSlotLength,
      startTime,
      endTime,
      lockFields,
      selectedTime,
    } = this.state
    const { availableTimeslots } = this.props
    if (enableDoubleBooking) {
      const label =
        i18n.t("container.time") + (lockFields ? ` (${i18n.t("locked")})` : "")
      return (
        <div className="reminder-box">
          <label>{label}</label>
          <TextField
            id="time"
            value={newTime}
            type="time"
            className="full-width"
            onChange={(text) => this.onChangeTimeText(text)}
            variant="outlined"
            onBlur={() => {
              if (newTime) {
                let hour = newTime.split(":")[0]
                let minute = parseInt(newTime.split(":")[1])
                const remainder = minute % timeSlotLength
                if (remainder) {
                  minute =
                    minute -
                    remainder +
                    ((minute % timeSlotLength) / timeSlotLength >= 0.5
                      ? timeSlotLength
                      : 0)
                  if (minute >= 60) {
                    hour++
                    minute %= 60
                  }
                  const time = `${hour}:${minute < 10 ? "0" + minute : minute}`
                  this.setState({ newTime: time, selectedTime: time })
                }
              }
            }}
            helperText={formErrors.selectedTimeError}
            error={!!formErrors.selectedTimeError}
          />
        </div>
      )
    } else if (enableWaitingList) {
      return (
        <>
          <div className="reminder-box">
            <label>{i18n.t("container.start_time")}</label>
            <TextField
              id="start-time"
              placeholder={i18n.t("container.start_time")}
              value={startTime}
              className="full-width"
              variant="outlined"
              onChange={(text) => this.onChangeTimeText(text, "startTime")}
              onBlur={() => {
                if (startTime) {
                  let hour = startTime.split(":")[0]
                  let minute = parseInt(startTime.split(":")[1])
                  const remainder = minute % timeSlotLength
                  if (remainder) {
                    minute =
                      minute -
                      remainder +
                      ((minute % timeSlotLength) / timeSlotLength > 0.5
                        ? timeSlotLength
                        : 0)
                    if (minute >= 60) {
                      hour++
                      minute %= 60
                    }
                    const time = `${hour}:${
                      minute < 10 ? "0" + minute : minute
                    }`
                    this.setState({ startTime: time })
                  }
                }
              }}
              disabled={lockFields}
              type="time"
              helperText={formErrors.startTimeError}
              error={!!formErrors.startTimeError}
            />
          </div>
          <div className="reminder-box">
            <label>{i18n.t("container.end_time")}</label>
            <TextField
              id="time"
              placeholder={i18n.t("container.end_time")}
              value={endTime}
              className="full-width"
              variant="outlined"
              onChange={(text) => this.onChangeTimeText(text, "endTime")}
              onBlur={() => {
                if (endTime) {
                  let hour = endTime.split(":")[0]
                  let minute = parseInt(endTime.split(":")[1])
                  const remainder = minute % timeSlotLength
                  if (remainder) {
                    minute =
                      minute -
                      remainder +
                      ((minute % timeSlotLength) / timeSlotLength > 0.5
                        ? timeSlotLength
                        : 0)
                    if (minute >= 60) {
                      hour++
                      minute %= 60
                    }
                    const time = `${hour}:${
                      minute < 10 ? "0" + minute : minute
                    }`
                    this.setState({ endTime: time })
                  }
                }
              }}
              type="time"
              disabled={lockFields}
              helperText={formErrors.endTimeError}
              error={!!formErrors.endTimeError}
            />
          </div>
        </>
      )
    } else {
      return (
        <div className="reminder-box">
          <label>{i18n.t("container.time")}</label>
          <FormControl
            error={!!formErrors.selectedTimeError}
            className="full-width"
          >
            <Select
              variant="outlined"
              value={selectedTime}
              disabled={lockFields}
              onChange={(text) => this.onChangeTime(text)}
              placeholder={i18n.t("container.select_time")}
            >
              {Object.keys(availableTimeslots ? availableTimeslots : {})?.map(
                (val, ind) => {
                  return (
                    <MenuItem key={ind} value={val}>
                      {val.split(" ")[0]}{" "}
                      {availableTimeslots[val].length
                        ? ""
                        : `(${i18n.t("not_available")})`}
                    </MenuItem>
                  )
                }
              )}
            </Select>
            {!!formErrors.selectedTimeError && (
              <FormHelperText>{formErrors.selectedTimeError}</FormHelperText>
            )}
          </FormControl>
        </div>
      )
    }
  }

  renderEmployeeDetail = () => {
    const {
      formErrors,
      selectedEmployee,
      selectEmployee,
      selectDateAndTime,
      isGym,
      getEmployeeData,
      rawEmployees,
      enableDoubleBooking,
      enableWaitingList,
      lockFields,
      allServices,
      selectedService,
    } = this.state

    let employees = []
    if (enableDoubleBooking) {
      employees = rawEmployees
    } else {
      employees = getEmployeeData
    }

    return (
      <div className="mt-4">
        {/* <div className="col-12 pb-2 px-0 modify-double-booking">
          <TouchChip
            label={i18n.t("double_booking")}
            onClick={() => {
              let newSelected
              if (enableDoubleBooking) {
                newSelected = getEmployeeData.find(
                  (employee) => employee.employeeId._id == selectEmployee
                )
              } else {
                newSelected = rawEmployees.find(
                  (employee) => employee.employee._id == selectEmployee
                )
              }
              if (newSelected) {
                this.setState({
                  selectedEmployee: JSON.stringify(newSelected),
                })
              } else {
                this.setState({ selectedEmployee: null, selectEmployee: "" })
              }
              this.setState({ enableDoubleBooking: !enableDoubleBooking })
            }}
            selected={enableDoubleBooking}
            style={{ width: "max-content" }}
          />
        </div> */}
        {!isGym && (
          <div className="reminder-box">
            <label>{i18n.t("container.employee")}</label>
            <FormControl
              error={!!formErrors.selectEmployeeError}
              className="full-width"
            >
              <Select
                variant="outlined"
                value={selectedEmployee}
                onChange={(e) => this.onChangeSelectEmployee(e)}
                placeholder={i18n.t("select_employee")}
              >
                {employees?.map((val, ind) => {
                  let employeeName = val?.employeeId?.userId?.firstName
                    ? `${val?.employeeId?.userId?.firstName} ${val?.employeeId?.userId?.lastName}`
                    : val?.employeeId?.userId?.userName
                  return (
                    <MenuItem key={val._id} value={JSON.stringify(val)}>
                      {employeeName}
                    </MenuItem>
                  )
                })}
              </Select>
              {!!formErrors.selectEmployeeError && (
                <FormHelperText>
                  {formErrors.selectEmployeeError}
                </FormHelperText>
              )}
            </FormControl>
          </div>
        )}
        <div className="reminder-box">
          <label>{"Date" + (lockFields ? ` (${i18n.t("locked")})` : "")}</label>
          <FormControl className="full-width">
            <DatePicker
              className="full-width"
              minDate={new Date()}
              selected={selectDateAndTime}
              disabled={lockFields}
              onChange={(date) => this.onDateChange(date)}
              filterDate={(date) => {
                const weekPlans =
                  JSON.parse(selectedEmployee).weekPlans[date.getDay()]
                return weekPlans.availableStatus != 0
              }}
              value={this.state.selectDateAndTime}
              customInput={
                <TextField
                  id="time"
                  value={this.state.newDate}
                  variant="outlined"
                  className="full-width"
                  readOnly={true}
                  disabled={lockFields}
                  helperText={formErrors.selectDateAndTimeError}
                  error={!!formErrors.selectDateAndTimeError}
                />
              }
              placeholderText={i18n.t(
                "container.services_click_to_select_a_date"
              )}
            />
          </FormControl>
        </div>
        {this.renderTimeSelection()}
        <div className="reminder-box">
          <label>{i18n.t("services")}</label>
          <FormControl className="full-width">
            <Autocomplete
              options={Object.values(allServices)}
              blurOnSelect
              value={selectedService}
              onChange={(e, value) => {
                this.setState({ selectedService: value })
                if (value) {
                  this.getEmployee(JSON.stringify(value)).then(() => {
                    if (!enableDoubleBooking) {
                      const employee = this.state.getEmployeeData.find(
                        (employee) => employee.employeeId._id == selectEmployee
                      )
                      if (!employee) {
                        this.setState({
                          selectedEmployee: null,
                          selectEmployee: "",
                        })
                      }
                    }
                  })
                }
              }}
              getOptionLabel={(option) => option.name}
              fullWidth
              classes={{ inputRoot: "py-0" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  helperText={formErrors?.selectServiceError}
                  error={!!formErrors?.selectServiceError}
                  fullWidth
                  variant="outlined"
                />
              )}
            />
          </FormControl>
        </div>
      </div>
    )
  }

  renderTableHead = () => {
    const { isGym } = this.state
    const {
      screenSizeContext: { screenSize },
    } = this.props
    return (
      <thead
        style={{
          position: "sticky",
          top: "0",
          zIndex: "1",
          backgroundColor: "white",
        }}
      >
        <tr>
          <th scope="col">
            <span className="working-day-th-text">
              {i18n.t("container.advancedSearchDialog_name")}
            </span>
          </th>
          <th className="text-center" scope="col">
            <span className="working-day-th-text">Date</span>
          </th>
          <th className="text-center" scope="col">
            <span className="working-day-th-text">
              <Translation>{(t) => t("container.profile_service")}</Translation>
            </span>
          </th>
          <th className="text-center" scope="col">
            <span className="working-day-th-text">
              <Translation>{(t) => t("container.employee")}</Translation>
            </span>
          </th>
          <th className="text-center" scope="col">
            <span className="working-day-th-text">
              <Translation>
                {(t) => t("container.employe_custom_details")}
              </Translation>
            </span>
          </th>
          {!isGym && (
            <th className="text-center" scope="col">
              <span className="working-day-th-text">
                {i18n.t("container.profile_payment_method")}
              </span>
            </th>
          )}
          {!isGym && (
            <>
              <th className="text-center" scope="col">
                <span className="working-day-th-text">
                  <Translation>{(t) => t("by_price")}</Translation>
                </span>
              </th>
              <th className="text-center" scope="col">
                <span className="working-day-th-text">
                  <Translation>{(t) => t("tip")}</Translation>
                </span>
              </th>
              <th className="text-center" scope="col">
                <span className="working-day-th-text">
                  <Translation>{(t) => t("grand_total")}</Translation>
                </span>
              </th>
            </>
          )}
          <th className="text-center" scope="col">
            <span className="working-day-th-text">
              {i18n.t("container.profile_status")}
            </span>
          </th>
          <th className="text-center" scope="col">
            <span className="working-day-th-text"></span>
          </th>
        </tr>
      </thead>
    )
  }

  renderTableBody = (bookings) => {
    const {
      searchText,
      bookingStatuses,
      filteredBookings,
      isGym,
      employeeMap,
    } = this.state
    const {
      screenSizeContext: { screenSize },
    } = this.props
    if (!bookings) return null
    let renderBooking = bookings
    if (filteredBookings) {
      renderBooking = filteredBookings
    } else if (searchText)
      renderBooking = bookings?.filter((val) => {
        var ll = getNestedValue(val, ["userId", "userName"], "") || ""
        if (
          ll.toLowerCase().includes(searchText.toLowerCase()) ||
          val?.phoneNo?.includes(searchText) ||
          val?.shortenedId?.includes(searchText)
        )
          return true
        return false
      })
    const summary = {
      total: 0,
      tip: 0,
      grandTotal: 0,
    }
    renderBooking = renderBooking.map((booking) => {
      booking.total = booking.totalAmount + (booking.tip || 0)
      summary.total += booking.totalAmount || 0
      summary.tip += booking.tip || 0
      summary.grandTotal += booking.total
      return booking
    })
    return (
      <tbody>
        <tr
          style={{
            position: "sticky",
            top: "38px",
            zIndex: "1",
            backgroundColor: "#B3D7ED",
          }}
        >
          <td />
          <td />
          <td />
          <td />
          <td />
          <td />
          {!isGym && (
            <>
              <td className="px-1 text-center font-weight-bold">
                <div className="mobile-max-content">
                  $ {summary.total.toFixed(2)}
                </div>
              </td>
              <td className="px-1 text-center font-weight-bold">
                <div className="mobile-max-content">
                  $ {summary.tip.toFixed(2)}
                </div>
              </td>
              <td className="px-1 mobile-max-content text-center font-weight-bold">
                <div className="mobile-max-content">
                  $ {summary.grandTotal.toFixed(2)}
                </div>
              </td>
            </>
          )}
          <td />
          <td />
        </tr>
        {renderBooking.map((val, ind) => {
          return (
            <tr
              key={ind}
              style={{
                backgroundColor: ind % 2 == 0 && "#F4F6F8",
                borderTop: "1px solid #E5EBF1",
                borderBottom:
                  renderBooking.length - 1 == ind && "1px solid #E5EBF1",
              }}
            >
              <td>
                {val && val.userId && (
                  <span className="working-days-name">
                    {getNestedValue(val.userId, "userName")
                      ? val.userId.userName
                      : ""}
                  </span>
                )}
              </td>
              <td style={{ textAlign: "center" }}>
                <span className="working-days-name">
                  {" "}
                  {getNestedValue(val, ["services", 0, "date"], "")}{" "}
                  {getNestedValue(val, ["services", 0, "time"], "")}{" "}
                </span>
              </td>
              <td style={{ textAlign: "center" }}>
                <span className="working-days-name">
                  {" "}
                  {getNestedValue(
                    val,
                    ["services", 0, "serviceId", "name"],
                    ""
                  )}{" "}
                </span>
              </td>
              <td style={{ textAlign: "center" }}>
                <span className="working-days-name">
                  {" "}
                  {getNestedValue(
                    employeeMap,
                    getNestedValue(val, ["services", 0, "employeeId"]),
                    ""
                  )}{" "}
                </span>
              </td>
              <td style={{ textAlign: "center" }}>
                <span>
                  <div className="working-days-name"> {val.phoneNo || ""} </div>
                  <div className="working-days-name"> {val.email || ""} </div>
                </span>
              </td>
              {!isGym && (
                <td className="text-center">
                  <span className="working-days-name">
                    {val.paymentMethod || ""}
                  </span>
                </td>
              )}
              {!isGym && (
                <>
                  <td className="text-center">
                    {val && (
                      <span className="working-days-name">
                        {val.totalAmount ? `$ ${val.totalAmount}` : "$ 0"}
                      </span>
                    )}
                  </td>
                  <td className="text-center">
                    {val && (
                      <span className="working-days-name">
                        {val.tip ? `$ ${val.tip}` : "$ 0"}
                      </span>
                    )}
                  </td>
                  <td className="text-center">
                    {val && (
                      <span className="working-days-name">
                        {val.total ? `$ ${val.total}` : "$ 0"}
                      </span>
                    )}
                  </td>
                </>
              )}
              <td className="text-center">
                <span
                  className="working-days-name aBold"
                  style={bookingStatuses[val.status - 1].styles}
                >
                  {bookingStatuses[val.status - 1].text}
                </span>
              </td>
              <td
                className="text-center"
                onClick={() =>
                  this.setState({
                    showBookingModal: true,
                    selectBooking: val,
                    selectBookingAmount: val.totalAmount,
                    paymentMethod: val.paymentMethod,
                    bookingStaus: val.status,
                    estimateDuration: val.services[0].serviceId.duration,
                    notes: val.notes,
                    tip: val.tip,
                  })
                }
              >
                <EditIcon className="working-days-name" />
              </td>
            </tr>
          )
        })}
      </tbody>
    )
  }

  renderEditAppointment = () => {
    const { products } = this.props
    const {
      showBookingModal,
      selectBooking = {},
      employeeMap,
      isGym,
      bookings,
      showInsertPayment,
      showReinstateModal,
      serviceDate,
    } = this.state
    const { companyTaxes } = this.props
    if (showBookingModal) {
      return (
        <AppointmentModal
          style={{ zIndex: "999" }}
          showInsertPayment={showInsertPayment}
          setData={({ cartId, discount }) => {
            if (
              typeof selectBooking.index === "number" &&
              bookings[selectBooking.index]
            ) {
              if (discount || typeof discount == "number") {
                bookings[selectBooking.index].discount = discount
                this.setState({
                  selectBooking: { ...selectBooking, discount },
                  bookings,
                })
              }
              if (cartId) {
                bookings[selectBooking.index].cartId = cartId
                this.setState({
                  selectBooking: { ...selectBooking, cartId },
                  bookings,
                })
              }
            }
          }}
          open={showBookingModal}
          selectBooking={selectBooking}
          employeeMap={employeeMap}
          isGym={isGym}
          isSalon={true}
          handleDrawerClose={(ev) => {
            this.setState({ showBookingModal: false })
          }}
          statusButtonClicked={(status, additional) => {
            const sendUpdate = (result, append = {}) => {
              if (result.value) {
                this.setState({ bookingStaus: status }, () => {
                  this.handleUpdate({ ...append })
                })
              } else {
                this.setState(
                  {
                    bookingStaus: status,
                  },
                  this.handleUpdate
                )
              }
            }
            if (status == 3) {
              return sendUpdate({ value: true }, additional)
            }
            if (status != 4) {
              Swal.fire({
                title: i18n.t("change_status"),
                text: `${i18n.t("confirm_status_change")} ${i18n.t(
                  statusMap[status]
                )}`,
                showCancelButton: true,
                cancelButtonText: i18n.t("no"),
                confirmButtonText: i18n.t("yes"),
              }).then((result) => {
                if (result.value) {
                  if (status == 7) {
                    status = 3
                    const price = getNestedValue(
                      selectBooking,
                      ["totalAmount"],
                      ""
                    )
                    const noShowFeePercentage = getNestedValue(
                      selectBooking,
                      ["companyId", "noShowFeePercentage"],
                      0
                    )
                    const noShowFee = (price * noShowFeePercentage) / 100

                    if (noShowFee) {
                      Swal.fire({
                        title: i18n.t("charge_no_show_fee"),
                        text: `$ ${noShowFee.toFixed(2)}`,
                        showCancelButton: true,
                        cancelButtonText: i18n.t("no"),
                        confirmButtonText: i18n.t("yes"),
                      }).then((result) => {
                        sendUpdate(result, { isNoShow: result.value })
                      })
                    } else {
                      sendUpdate({ value: false })
                    }
                  } else {
                    sendUpdate({ value: false })
                  }
                }
              })
            } else {
              this.setState(
                {
                  bookingStaus: status,
                },
                this.handleUpdate
              )
            }
          }}
          onProductChange={async (e, index) => {
            this.setState({ isloading: true })
            await updateCartProduct(
              {
                productId: selectBooking.cartId.products[index]._id,
                cartId: selectBooking.cartId._id,
              },
              { quantity: e }
            )
              .then(({ data }) => {
                if (data.success) {
                  const newBooking = { ...selectBooking }
                  newBooking.cartId.products[index] = {
                    ...selectBooking.cartId.products[index],
                    quantity: data.data.quantity,
                  }
                  if (typeof selectBooking.index === "number") {
                    bookings[selectBooking.index] = newBooking
                  }
                  this.setState({
                    selectBooking: newBooking,
                    bookings,
                  })
                } else {
                  if (data.code) {
                    ErrorHelper.handleErrors(
                      i18n.t(data.code, { ...data.data }),
                      true
                    )
                  } else {
                    ErrorHelper.handleErrors("Failed to Add Item", true)
                  }
                }
              })
              .finally(() => {
                this.setState({ isloading: false })
              })
          }}
          onProductDelete={(index) => {
            this.setState({ isloading: true })
            removeProduct({
              productId: selectBooking.cartId.products[index]._id,
              cartId: selectBooking.cartId._id,
            })
              .then(({ data }) => {
                if (data.success) {
                  if (
                    typeof selectBooking.index === "number" &&
                    bookings[selectBooking.index]
                  ) {
                    bookings[selectBooking.index].cartId = data.data
                  }
                  this.setState({
                    selectBooking: { ...selectBooking, cartId: data.data },
                    bookings,
                  })
                }
              })
              .finally(() => {
                this.setState({ isloading: false })
              })
          }}
          onServiceDelete={(index) => {
            this.setState({ isloading: true })
            removeExtra({
              extraId: selectBooking.extras[index]._id,
              bookingId: selectBooking._id,
            })
              .then(({ data }) => {
                if (data.success) {
                  if (
                    typeof selectBooking.index === "number" &&
                    bookings[selectBooking.index]
                  ) {
                    bookings[selectBooking.index].extras = data.data.extras
                  }
                  this.setState({
                    selectBooking: {
                      ...selectBooking,
                      extras: data.data.extras,
                    },
                    bookings,
                  })
                }
              })
              .finally(() => {
                this.setState({ isloading: false })
              })
          }}
          onNotesClick={() => this.handleNotes()}
          onModifyClick={() => this.handleEditDetails()}
          onAddClick={() => this.handleAddProductsServices()}
          companyTaxes={companyTaxes}
          toggleDepositRequired={this.toggleDepositRequired}
          toggleTaxes={this.toggleTaxes}
          onChangeTip={this.onChangeTip}
          onChangeAdjustedTime={this.onChangeAdjustedTime}
          onUpdate={(data) => {
            this.setState({ ...data })
          }}
          products={products}
          bookings={bookings}
        ></AppointmentModal>
      )
    } else if (showReinstateModal) {
      return (
        <BaseModal
          open={showReinstateModal}
          containerClassName={"half-container"}
          {...{
            title: <div>{serviceDate.format("MMMM Do YYYY h:mm a")}</div>,
            content: (
              <div className=" row justify-content-around">
                <PrimaryButton
                  className="mb-2"
                  onClick={() => {
                    this.handleEditDetails()
                  }}
                  label={i18n.t("reinstate_appointment")}
                />
                <PrimaryButton
                  className="mb-2"
                  onClick={() => {
                    this.deleteCancelledAppointment(
                      selectBooking._id,
                      selectBooking.index
                    )
                  }}
                  label={i18n.t("delete_appointment")}
                />
              </div>
            ),
          }}
          onClose={() => this.setState({ showReinstateModal: false })}
        />
      )
    }
  }

  genericUpdate = async (body) => {
    const { selectBooking } = this.state
    this.setState({
      isloading: true,
    })
    let { bookings } = this.state
    const services = [
      {
        serviceId: selectBooking.services[0].serviceId._id,
        categoryId: selectBooking.services[0].categoryId?._id,
        date: selectBooking.services[0].date,
        time: selectBooking.services[0].time,
        employeeId:
          selectBooking.services[0].employeeId._id ||
          selectBooking.services[0].employeeId,
      },
    ]
    const { data } = await editBooking({
      bookingId: selectBooking._id,
      status: selectBooking.status,
      services,
      ...body,
    })
    let newSelectBooking = selectBooking
    if (data.success) {
      const newBooking = data.data
      newBooking.services = newBooking.services.map((service) => {
        return {
          ...service,
          employeeId: service.employeeId._id,
        }
      })
      newSelectBooking = { ...newBooking, index: selectBooking.index }
      if (typeof selectBooking.index === "number") {
        bookings[selectBooking.index] = newSelectBooking
      } else {
        const booking = bookings.find(
          (booking) => booking._id == newSelectBooking._id
        )
        newSelectBooking.index = booking.index
        bookings[booking.index] = newSelectBooking
      }
      this.handleClosePopup()
      this.setState({
        isloading: false,
        bookings: [...bookings],
        selectBooking: newSelectBooking,
        editDetails: false,
        ...body,
      })
    }
  }

  onChangeTip = (tip) => {
    const { tip: oldTip } = this.state
    if (oldTip != tip) {
      this.genericUpdate({ tip })
    }
  }

  onChangeAdjustedTime = (adjustedTime) => {
    this.genericUpdate({ adjustedTime })
  }

  toggleDepositRequired = (depositRequired) => {
    this.genericUpdate({ depositRequired })
  }

  toggleTaxes = (includeTaxes) => {
    this.genericUpdate({ includeTaxes })
  }

  getCompanyEmployeeUnavaiabilities = () => {
    const { rawEmployees = [] } = this.state
    const employeeIdCompanyEmployeeMap = {}
    rawEmployees.map((element) => {
      employeeIdCompanyEmployeeMap[
        element.employeeId?._id || element.archivedEmployeeId._id
      ] = element
    })
    const dates = this.generateDates()
    getCompanyEmployeeUnavaiabilities({
      startDate: dates[0],
      endDate: dates[dates.length - 1],
    }).then((data) => {
      let unavailabilities = []
      data = data.map((element) => {
        const { date, employeeId, note, reoccuringEmployeeUnavailibilities } =
          element._id
        if (employeeIdCompanyEmployeeMap[employeeId]?.displayWorkingHours) {
          const timeMap = {}
          element.entries.map((entry) => {
            timeMap[entry.time] = true
          })
          let keys = Object.keys(timeMap).sort()
          let key = keys[0]
          while (keys.length) {
            let dateTime = moment(key, "HH:mm")
            let previous = dateTime.format("HH:mm")

            while (timeMap[dateTime.format("HH:mm")]) {
              keys.splice(0, 1)
              dateTime.add(30, "m")
              previous = dateTime.format("HH:mm")
            }
            unavailabilities.push({
              start: moment(`${date} ${key}`, "MM-DD-YYYY HH:mmm").toDate(),
              end: moment(`${date} ${previous}`, "MM-DD-YYYY HH:mmm").toDate(),
              date,
              employeeId,
              note: reoccuringEmployeeUnavailibilities
                ? reoccuringEmployeeUnavailibilities.note
                : note,
              allDay: false,
              type: "unavailability",
              className: "unavailability-event",
            })
            key = keys[0]
          }
        }
      })
      const workingDayUnavailabilities = []
      dates.map((date) => {
        rawEmployees
          .filter(({ isActive }) => isActive)
          .map((employee) => {
            if (employee.displayWorkingHours) {
              const momentedDate = moment(date, "MM-DD-YYYY")
              const workingDay = employee.weekPlans[momentedDate.day()]
              if (workingDay?.availableStatus) {
                workingDayUnavailabilities.push({
                  start: moment(`${date} 00:00`, "MM-DD-YYYY HH:mmm").toDate(),
                  end: moment(
                    `${date} ${workingDay.checkIn}`,
                    "MM-DD-YYYY HH:mmm"
                  ).toDate(),
                  date,
                  employeeId: employee.employeeId?._id,
                  allDay: false,
                  type: "unavailability",
                  className: "unavailability-event un",
                })
                workingDayUnavailabilities.push({
                  start: moment(
                    `${date} ${workingDay.checkOut}`,
                    "MM-DD-YYYY HH:mmm"
                  ).toDate(),
                  end: moment(`${date} 23:59`, "MM-DD-YYYY HH:mmm").toDate(),
                  date,
                  employeeId: employee.employeeId?._id,
                  allDay: false,
                  type: "unavailability",
                  className: "unavailability-event",
                })
              } else {
                workingDayUnavailabilities.push({
                  start: moment(`${date} 00:00`, "MM-DD-YYYY HH:mmm").toDate(),
                  end: moment(`${date} 23:59`, "MM-DD-YYYY HH:mmm").toDate(),
                  date,
                  employeeId: employee.employeeId?._id,
                  allDay: false,
                  type: "unavailability",
                  className: "unavailability-event",
                })
              }
            }
          })
      })
      this.setState({ unavailabilities, workingDayUnavailabilities })
    })
  }

  render() {
    const {
      bookings,
      selectBooking,
      filteredBookings,
      displayCalendar,
      showPopUp,
      openFilterModal,
    } = this.state
    return (
      <div>
        {this.renderLoader()}
        <div className="p-4 mb-5 mb-md-0">
          <div className="row mx-0">
            {showPopUp && this.renderPopup()}
            {displayCalendar
              ? this.renderAppointmentCalendar(bookings ? bookings : [])
              : this.renderAppointmentTable(bookings ? bookings : [])}
            {this.renderEditAppointment()}
            {this.renderAdvancedSearchPopup()}
            {this.renderFOBs()}
          </div>
        </div>
        <BaseModal
          showCloseBtn={false}
          containerClassName={"base-model-container position-relative"}
          open={openFilterModal}
          title={i18n.t("container.advancedSearchDialog_filter")}
          content={
            <>
              <Button
                className="position-absolute font-weight-bold text-capitalize"
                style={{ color: "#F6943B", top: "20px", right: "10px" }}
                onClick={() => {
                  if (!filteredBookings) {
                    this.setState({ showAdvancedSearchPopup: true })
                  } else {
                    this.setState({ filteredBookings: undefined })
                  }
                }}
              >
                {i18n.t("resetFilter")}
              </Button>
              {this.renderAdvanceSearchBox()}
            </>
          }
          actions={
            <div className="row justify-content-center">
              <PrimaryButton
                className="w-128"
                onClick={() => this.setState({ openFilterModal: false })}
              >
                {i18n.t("close")}
              </PrimaryButton>
            </div>
          }
        />
      </div>
    )
  }
}

_SalonAppointment.contextType = BookingContext

const mapStateToProps = (state) => ({
  booking: state.activeBooking.data,
  getAvailableTimeslots: state.getAvailableTimeslots,
  availableTimeslots: state.getAvailableTimeslots?.data?.data || {},
  getGymTimeslots: state.getGymTimeslots,
  transactions: state.bookingTransaction.data,
  events: state.coursePlanner.data,
})

const action = {
  SaloonAppointment,
  get_available_timeslots,
  get_gym_timeslots,
  clear,
  createTransactions,
  getClassEvents,
}

const ConnectedSalonAppointment = connect(
  mapStateToProps,
  action
)(_SalonAppointment)

function SalonAppointment(props) {
  const { getResource } = useCache()
  const { setTimeMin, setTimeMax } = useGoogleCalendar()
  const { setMinStartTime, setMaxStartTime } = useOutlookCalendar()
  const products = getResource((cache) => cache.company.products)
  const companyTaxes = getResource((cache) => cache.company.activeTaxes)
  const services = getResource((cache) => cache.company.services)
  const employees = getResource((cache) => cache.company.employees)
  const screenSizeContext = useContext(ScreenSizeContext)
  return (
    <ConnectedSalonAppointment
      {...props}
      setTimeMax={setTimeMax}
      setTimeMin={setTimeMin}
      setMaxStartTime={setMaxStartTime}
      setMinStartTime={setMinStartTime}
      products={products}
      companyTaxes={companyTaxes}
      services={services}
      employees={employees}
      screenSizeContext={screenSizeContext}
    />
  )
}
export default withRouter(SalonAppointment)
