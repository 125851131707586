import { Button } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import i18n from "../../../../i18n";
import PrimaryButton from "../../../../components/Core/PrimaryButton/index";
import { Images } from "../../../../theme";

function ListFundsRequests({
  available = 0,
  pending = 0,
  total = 0,
  buttonText,
  buttonClickCallback = () => { },
  pendingColor = "#DDDDDD",
  availableColor = "#DDDDDD",
  ...props
}) {
  return (
    <>
      <div className="balance-card">
        {
          props.type === "toEmployee" ? (
            <img src={Images.funds_to_pending} />
          ) : (
            <img src={Images.funds_by_pending} />
          )}
        <div className="balance-card-body">
          <div className="balance-card-body-title">
            $ {pending.toFixed(2)}
          </div>
          <div className="balance-card-body-text">
            {
              props.type === "toEmployee" ? (
                i18n.t("total_owed_to_employees_pending")
              ) : (
                i18n.t("total_owed_by_employees_pending")
              )}
          </div>
        </div>
      </div>
      <div className="balance-card">
        {
          props.type === "toEmployee" ? (
            <img src={Images.funds_to_available} />
          ) : (
            <img src={Images.funds_by_available} />
          )}
        <div className="balance-card-body">
          <div className="balance-card-body-title">
            $ {available.toFixed(2)}
          </div>
          <div className="balance-card-body-text">
            {
              props.type === "toEmployee" ? (
                i18n.t("total_owed_to_employees_available")
              ) : (
                i18n.t("total_owed_by_employees_available")
              )}
          </div>
        </div>
      </div>
      {buttonText ? (
        <PrimaryButton className="w-128" onClick={buttonClickCallback}>
          {buttonText}
        </PrimaryButton>
      ) : null}
    </>
  );
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(ListFundsRequests);
