import React from "react";

import { Images } from "../../../../theme";

export default function DeleteIcon({ onClick = () => { } }) {
  return (
    <img
      role="button"
      onClick={onClick}
      className="category-table-action-icon"
      style={{ width: 34, height: 34, pointer: "cursor" }}
      src={Images.table_delete_icon}
    />
  );
}
