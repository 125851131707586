import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import {
  addCustomBooking,
  createWaitingList,
  getEmployeesBySaloonAndService,
  getSaloonServices,
} from "../../../../config/simpleApiCalls"
import useBooking from "../../../../context/Booking"
import { ErrorHelper, SuccessHelper } from "../../../../helpers"
import i18n from "../../../../i18n"
import CollapseTouchList from "../Support/CollapseTouchList"
import { request as getAvailableTimeslots } from "../../../../redux/actions/GetAvailableTimeslots.js"
import { getNestedValue } from "../../../../util/objectMethods"
import moment from "moment"
import Swal from "sweetalert2"
import CompanyDateAndTime from "../Support/CompanyDateAndTime"
import PrimaryButton from "../../../Core/PrimaryButton"
import useActiveMenuType from "../../../../context/ActiveMenuType"
import styled from "styled-components/macro"
import Switch from "@material-ui/core/Switch"
import useCache from "../../../../context/LocalCache"
import FormControlLabel from "@material-ui/core/FormControlLabel";

const AddBookingWrapper = styled.div``
const AddBookingTitle = styled.div`
  font-size: 18px;
  margin-top: 10px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  @media (max-width: 480px) {
    font-weight: bold;
    text-align: center;
  }
`

export function CompanyBookingDetails({ formErrors = {} }) {
  const dispatch = useDispatch()
  const [filteredEmployeeList, setFilteredEmployeeList] = useState()
  const [allEmployees, setAllEmployees] = useState([])
  const [enableDoubleBooking, setEnableDoubleBooking] = useState(false)
  const [enableRecurringBooking, setEnableRecurringBooking] = useState(false)
  const [enableWaitingList, setEnableWaitingList] = useState(false)

  const handleDoubleBooking = (e) => {
    setEnableDoubleBooking(!enableDoubleBooking)
  };
  const handleWaitingList = (e) => {
    setEnableWaitingList(!enableWaitingList)
  };
  const handleRecurringBooking = (e) => {
    setEnableRecurringBooking(!enableRecurringBooking);
    setEnableDoubleBooking(false);
    setEnableWaitingList(false);
    if (!enableRecurringBooking) {
      Swal.fire({
        title: i18n.t("enable_double_booking_for_recurring_booking"),
        showCancelButton: false,
        confirmButtonText: "Ok",
      })
    }
  };

  const {
    lockFields,
    setLockFields,
    onChange,
    data,
    currentOpen,
    setCurrentOpen,
    categoryList,
    serviceList,
    setServiceList,
    employeeList,
    setEmployeeList,
    setLoading,
  } = useBooking()
  const { functionRole } = useActiveMenuType()
  const { getResource } = useCache()
  const {
    selectedCategory,
    selectSalon = {},
    newDate,
    selectServices,
    selectEmployee,
    newTime,
    userPackageId,
    isGym,
  } = data
  const requireEmployee = functionRole !== "garage" && !isGym
  useEffect(() => {
    if (!selectServices) {
      setCurrentOpen("category")
    }
  }, [selectServices])
  const timeSlotLength = selectSalon?.timeSlotLength
  const employees = getResource((cache) => cache.company.employees)
  useEffect(() => {
    setAllEmployees(
      employees.map((companyEmployee) => {
        const { employeeId, user } = companyEmployee
        return {
          ...employeeId,
          employeeId,
          name: user.userName ? user.userName : `${user.firstName}`,
        }
      })
    )
  }, [employees])
  useEffect(() => {
    const { _id: companyId } = selectSalon
    setLoading(true)

    if (!companyId) {
      return
    }
    const payload = {
      companyId,
    }
    const promises = []
    if (requireEmployee) {
    }
    Promise.all(promises).then(() => setLoading(false))
  }, [selectSalon])
  const removeLock = (e) => {
    e.stopPropagation()
    if (selectEmployee) {
      setLoading(true)
      const { _id: companyId } = selectSalon
      let date = newDate
      const body = {
        companyId,
        companyServiceId: selectServices._id,
        date,
      }
      if (selectEmployee) {
        body.companyEmployeeId = selectEmployee?.employeeId?._id
      }
      dispatch(
        getAvailableTimeslots({
          data: body,
          resolve: () => setLoading(false),
        })
      )
    } else {
      onChange({
        newDate: "",
        selectedTime: "",
        newTime: "",
        formattedDate: "",
        selectDateAndTime: undefined,
      })
      setFilteredEmployeeList()
      if (requireEmployee) {
        setCurrentOpen("employees")
      } else {
        setCurrentOpen("dateTime")
      }
    }
    setLockFields(false)
  }

  const onChangeSelectCategory = (val, e) => {
    e.stopPropagation()
    const { _id: companyId } = selectSalon
    onChange({
      selectedCategory: val,
      selectServices: "",
      ...(lockFields
        ? {}
        : {
          selectDateAndTime: "",
          newDate: "",
          selectedTime: undefined,
          availableTimeslots: {},
        }),
    })
    const payload = {
      companyId,
      categoryId: val._id,
    }
    setLoading(true)
    getSaloonServices(payload)
      .then((res) => {
        if (res.data.success) {
          setLoading(false)
          setServiceList(res.data.data)
          setCurrentOpen("service")
        }
      })
      .catch((error) => { })
  }

  const onChangeSelectService = async (val, e) => {
    e.stopPropagation()
    const { isBlackListed } = data
    const { price, depositAmount, requireDeposit, depositTarget } = val
    let amount = 0
    if (
      requireDeposit &&
      depositAmount &&
      (depositTarget == "everyone" ||
        (depositTarget == "blacklisted" && isBlackListed))
    ) {
      amount = depositAmount
    }
    onChange({
      selectServices: val,
      selectEmployee: "",
      selectedEmployee: "",
      availableEmployees: [],
      totalAmount: price,
      depositAmount: amount,
      discountCode: "",
      discount: undefined,
      ...(lockFields
        ? {}
        : {
          selectDateAndTime: "",
          newDate: "",
          selectedTime: undefined,
          availableTimeslots: {},
        }),
    })
    const serviceData = { ...val }
    const { _id: companyId } = selectSalon

    if (requireEmployee) {
      setCurrentOpen("employees")
    } else {
      setCurrentOpen("dateTime")
    }

    try {
      setLoading(true)
      if (lockFields) {
        setFilteredEmployeeList([])
        let date = newDate
        dispatch(
          getAvailableTimeslots({
            data: {
              companyId,
              companyServiceId: serviceData._id,
              date,
            },
            resolve: ({ data: timeslots }) => {
              const duration = parseInt(
                getNestedValue(serviceData, "duration", timeSlotLength)
              )
              const date = moment(`${newDate} ${newTime}`, "MM-DD-YYYY HH:mm")
              const key = `${date.format("HH:mm")} - ${date
                .add(duration, "m")
                .format("HH:mm")}`
              const matchingEmployees = getNestedValue(timeslots, [key], [])
              setFilteredEmployeeList(
                allEmployees.filter((employee) =>
                  matchingEmployees.includes(
                    getNestedValue(employee, ["employeeId", "_id"])
                  )
                )
              )
            },
          })
        )
      } else {
        setFilteredEmployeeList()
      }
      const res = await getEmployeesBySaloonAndService({
        companyId,
        serviceId: serviceData._id,
      })
      setLoading(false)
      setEmployeeList(
        res.data.data.map((employee) => ({
          ...employee,
          name: `${employee.employeeId.userId.firstName} ${employee.employeeId.userId.lastName}`,
        }))
      )
    } catch (error) {
      ErrorHelper.handleErrors(
        "Aww somthing went wrong for getting Employees",
        true
      )
    }
  }

  const onChangeSelectEmployee = (val, e) => {
    e.stopPropagation()
    onChange({
      selectEmployee: val,
      weekPlans: val.weekPlans,
      selectedEmployee: val,
      ...(lockFields
        ? {}
        : {
          newDate: "",
          selectedTime: undefined,
          availableTimeslots: {},
          selectDateAndTime: undefined,
          selectedTime: undefined,
        }),
    })
    setCurrentOpen("dateTime")
  }

  let employeesToRender = []
  if (enableDoubleBooking) {
    employeesToRender = allEmployees
  } else if (filteredEmployeeList) {
    employeesToRender = filteredEmployeeList
  } else {
    employeesToRender = employeeList
  }
  return (
    <AddBookingWrapper>
      <AddBookingTitle>
        {i18n.t("container.enter_appointment_details")}
        {lockFields && (
          <PrimaryButton label={i18n.t("remove_lock")} onClick={removeLock} />
        )}
      </AddBookingTitle>
      <div className="row mx-0 mb-3 p-0">
        {
          !enableRecurringBooking ?
            <div className="col-md-6 p-2 p-md-1">
              <FormControlLabel
                control={
                  <Switch
                    value={enableDoubleBooking}
                    checked={enableDoubleBooking}
                    type="checkbox"
                    onChange={handleDoubleBooking}
                    color="primary"
                  />
                }
                label={i18n.t("double_booking")}
              />
            </div>
            : null
        }
        {
          !lockFields && !enableDoubleBooking && !enableRecurringBooking ? (
            <div className="col-md-6 p-2 p-md-1 enable-waiting-list align-items-center">
              <FormControlLabel
                control={
                  <Switch
                    value={enableWaitingList}
                    checked={enableWaitingList}
                    type="checkbox"
                    onChange={handleWaitingList}
                    color="primary"
                  />
                }
                label={i18n.t("enable_waiting_list")}
              />
            </div>
          ) : null
        }
        {
          !lockFields ? (
            <div className="col-md-6 p-2 p-md-1">
              <FormControlLabel
                control={
                  <Switch
                    value={enableRecurringBooking}
                    checked={enableRecurringBooking}
                    type="checkbox"
                    onChange={handleRecurringBooking}
                    color="primary"
                  />
                }
                label={i18n.t("enable_recurring_booking")}
              />
            </div>
          ) : null
        }
      </div >
      <CollapseTouchList
        options={categoryList}
        onChange={onChangeSelectCategory}
        selected={selectedCategory}
        noOptionsMessage={"no_categories"}
        open={currentOpen == "category"}
        setOpen={() => setCurrentOpen("category")}
        placeholder={i18n.t("select_category")}
        error={formErrors.categoryError}
        locked={userPackageId}
      // style={{ display: "flex" }}
      />
      {
        selectedCategory ? (
          <CollapseTouchList
            options={serviceList}
            onChange={onChangeSelectService}
            selected={selectServices}
            noOptionsMessage={"no_services"}
            open={currentOpen == "service"}
            setOpen={() => setCurrentOpen("service")}
            placeholder={i18n.t("select_service")}
            error={formErrors.selectServicesError}
            locked={userPackageId}
            enableSearch
          />
        ) : null
      }

      {
        selectServices && requireEmployee ? (
          <CollapseTouchList
            options={employeesToRender}
            onChange={onChangeSelectEmployee}
            selected={selectEmployee}
            noOptionsMessage={"no_employees"}
            open={currentOpen == "employees"}
            setOpen={() => setCurrentOpen("employees")}
            placeholder={i18n.t("select_employee")}
            error={formErrors.selectEmployeeError}
          />
        ) : null
      }
      {
        selectServices || lockFields ? (
          <CompanyDateAndTime
            data={data}
            onChange={onChange}
            open={currentOpen == "dateTime"}
            setOpen={() => setCurrentOpen("dateTime")}
            setCurrentOpen={setCurrentOpen}
            enableWaitingList={enableWaitingList}
            enableDoubleBooking={enableDoubleBooking}
            enableRecurringBooking={enableRecurringBooking}
            formErrors={formErrors}
          />
        ) : null
      }
    </AddBookingWrapper >
  )
}

export default CompanyBookingDetails

export function validate(data) {
  const {
    name,
    email,
    phoneNumber = "",
    selectedCategory,
    selectServices,
    selectEmployee,
    formattedDate,
    recurringBookingDates,
    selectedTime,
    isGym,
    newTime,
    enableWaitingList,
    startTime,
    endTime,
    functionRole,
  } = data
  const requireEmployee = functionRole !== "garage" && !isGym
  const formErrors = {}
  const nameRegex =
    /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{2,}$/u
  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const telephoneRegex = /[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]/g
  if (email && !email.match(emailRegex)) {
    formErrors.emailError = i18n.t("invalid_email")
  }
  if (phoneNumber && !phoneNumber.replace(/\s/g, "").match(telephoneRegex)) {
    formErrors.phoneNumberError = i18n.t("invalid_phone_number")
  }
  if (!phoneNumber) {
    formErrors.phoneNumberError = i18n.t("container.employe_valid_phone_number")
  }
  if (!name) {
    formErrors.nameError = i18n.t("name_required")
  }
  if (!selectedCategory) {
    formErrors.categoryError = i18n.t("select_category")
  }
  if (!selectServices) {
    formErrors.selectServicesError = i18n.t("select_service")
  }
  if (requireEmployee && !selectEmployee) {
    formErrors.selectEmployeeError = i18n.t("select_employee")
  }

  if (!formattedDate && (!recurringBookingDates || !recurringBookingDates?.length)) {
    formErrors.selectDateAndTimeError = i18n.t("select_date")
  }
  if (!enableWaitingList && !selectedTime) {
    formErrors.selectDateAndTimeError = i18n.t("select_time")
  }
  if (
    !enableWaitingList &&
    (!newTime || !newTime.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/))
  ) {
    formErrors.selectedTimeError = i18n.t("invalid_time")
  }
  if (
    enableWaitingList &&
    (!startTime || !startTime.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/))
  ) {
    formErrors.startTimeError = i18n.t("invalid_time")
  }
  if (
    enableWaitingList &&
    (!endTime || !endTime.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/))
  ) {
    formErrors.endTimeError = i18n.t("invalid_time")
  }
  return formErrors
}
export async function book(data, setLoading, resetFunction, viewBooking, sendBookingConfirmation, setShowCustomerCurrentBookings, clearAppointmentDetails) {
  const {
    name,
    email,
    phoneNumber,
    totalAmount,
    selectedCategory,
    selectServices,
    selectEmployee,
    selectSalon,
    newTime,
    formattedDate,
    recurringBookingDates,
    notes,
    enableWaitingList,
    enableDoubleBooking,
    depositAmount,
    startTime,
    endTime,
    adjustedTime,
    userPackageId,
    car = {},
    discount,
  } = data

  const { value, make, model, year } = car

  const service = {
    serviceId: selectServices._id,
    categoryId: selectedCategory._id,
    date: formattedDate,
    time: newTime,
    ...(selectEmployee
      ? {
        employeeId: selectEmployee?.employeeId?._id,
      }
      : {}),
  }

  if (!enableWaitingList) {
    const book = (depositRequired) => {
      if (formattedDate) {
        return createBooking(depositRequired, service)
      } else {
        recurringBookingDates.map((date, index, recurringBookingDates) => {
          delete service.date
          createBooking(depositRequired, { ...service, date }, true)
          if (recurringBookingDates.length - 1 === index) {
            if (resetFunction) {
              SuccessHelper.handleSuccess("Booking Created", true)
            } else {
              setShowCustomerCurrentBookings(true)
            }
            clearAppointmentDetails()
          }

        })
      }
    }
    const createBooking = (depositRequired, service, recurring = false) => {
      const payload = {
        companyId: selectSalon._id,
        userName: name,
        email,
        phoneNo: phoneNumber,
        status: 1,
        totalAmount,
        notes,
        forceBooking: !!enableDoubleBooking,
        depositRequired,
        adjustedTime,
        userPackageId,
        carId: value == 1 ? null : value,
        make,
        model,
        year,
        discountId: discount?._id,
        sendBookingConfirmation: !recurring ? sendBookingConfirmation : false,
      }
      return addCustomBooking({ ...payload, services: [service] })
        .then((res) => {
          if (res.data.success) {
            if (!recurring) {
              if (resetFunction) {
                SuccessHelper.handleSuccess("Booking Created", true)
                resetFunction()
              } else {
                viewBooking(res.data.data.booking._id)
              }
            }

          } else {
            ErrorHelper.handleErrors("Something went wrong", true)
          }
        })

        .catch((error) => {
          if (error.response) {
            // Alert.alert("Error", error.response.data.msg);
            ErrorHelper.handleErrors(error.response.data.msg, true)

            // Request made and server responded
          } else if (error.request) {
            ErrorHelper.handleErrors("Something Went Wrong", true)

            // The request was made but no response was received
          } else {
            ErrorHelper.handleErrors(error.msg, true)
          }
        })
    }
    if (depositAmount) {
      return await Swal.fire({
        title: i18n.t("require_deposit", { depositAmount }),
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonText: "Yes",
      }).then((e) => {
        setLoading(true)
        const { dismiss, isConfirmed, isDismissed } = e
        if (!isDismissed || dismiss != "backdrop") {
          book(isConfirmed)
        }
      })
    } else {
      book(false)
    }
  } else {
    const payload = {
      companyId: selectSalon._id,
      email,
      phoneNo: phoneNumber,
      status: 1,
      serviceId: selectServices._id,
      date: formattedDate,
      startTime: startTime,
      endTime: endTime,
      employeeId: selectEmployee?.employeeId?._id,
    }
    setLoading(true)
    return createWaitingList({ ...payload })
      .then((res) => {
        if (res.data.success) {
          SuccessHelper.handleSuccess("Added to waiting list", true)
        } else {
          ErrorHelper.handleErrors("Something went wrong", true)
        }
      })

      .catch((error) => {
        if (error.response) {
          ErrorHelper.handleErrors(error.response.data.msg, true)
        } else if (error.request) {
          ErrorHelper.handleErrors("Something Went Wrong", true)
        } else {
          ErrorHelper.handleErrors(error.msg, true)
        }
      })
  }
}
