import { connect } from "react-redux"
import React, { Component } from "react"
import { request as update_notification } from "../../redux/actions/UpdateNotification"

import { set } from "../../redux/actions/ActiveBooking"
import Popover from "@material-ui/core/Popover"
import { getBookingById, getEmployeeDetails } from "../../config/simpleApiCalls"
import Tabs from "../Core/Tabs/Tabs"
import "./style.scss"
import i18n from "../../i18n"
import { getNestedValue } from "../../util/objectMethods"
import moment from "moment"
import AppUpdateAnnouncement from "../Partner/AppUpdateAnnouncement/AppUpdateAnnouncement"
import BookingReminders from "../BookingReminders"
import BaseModal from "../BaseModal"
import PrimaryButton from "../Core/PrimaryButton"
import SharedStorage from "../../helpers/Storage"

const statusMap = {
  1: "sent",
  2: "opened",
}
class NotificationCenter extends Component {
  constructor(props) {
    super(props)
  }

  state = { openReminderModal: false, openEmployeeReminders: false }

  generateMessage = (notification) => {
    const { isSalon, isEmployee, type } = this.props
    let append = ""
    if (isSalon) {
      append = "salon"
    } else if (isEmployee) {
      append = "employee"
    } else {
      append = "user"
    }
    switch (notification.typeId.code) {
      case "BCAN":
      case "BSU":
      case "BC":
      case "CCI":
        return i18n.t(`${notification.typeId.code}_message_${append}`, {
          name: notification.metadata.bookingId?.name,
          time: getNestedValue(
            notification.metadata.bookingId,
            ["services", 0, "time"],
            ""
          ),
          date: getNestedValue(
            notification.metadata.bookingId,
            ["services", 0, "date"],
            ""
          ),
          employeeName: getNestedValue(
            notification.metadata.bookingId,
            ["services", 0, "employeeId", "userId", "firstName"],
            ""
          ),
          companyName: getNestedValue(
            notification.metadata.bookingId,
            ["companyId", "name"],
            ""
          ),
        })
      case "TSU":
        return i18n.t(`${notification.typeId.code}_message_${append}`, {
          name: notification.metadata.payoutId?.requester?.firstName,
          status: i18n.t(notification.metadata.status),
        })
      case "RC":
        return i18n.t(`${notification.typeId.code}_message_${type}`, {
          employeeName: notification.metadata.reminderId?.employeeId?.userId.firstName,
        })
      case "VT":
        if (append == "salon") {
          return i18n.t(`${notification.typeId.code}_message_${append}`, {
            name: notification.metadata.bookingId?.name,
          })
        } else {
          return null
        }
    }
  }

  handleCancelledNotificationClick = (notification) => {
    this.loadCalendarPage(notification)
  }

  handleCompletedNotificationClick = (notification) => {
    this.loadCalendarPage(notification)
  }

  handleCreatedNotificationClick = (notification) => {
    this.loadCalendarPage(notification)
  }
  handleReminderClick = (notification) => {
    const reminder = notification.metadata.reminderId
    this.setState({ openReminderModal: true, reminder })
    this.props.setAnchor()

  }
  loadCalendarPage = async (notification) => {
    const { isSalon, isEmployee } = this.props
    let route = ""
    const { data } = await getBookingById({
      id: notification.metadata.bookingId._id,
      relations: ["extras", "cart"],
    })
    if (isSalon) {
      route = "saloon-my-appointment"
    } else if (isEmployee) {
      route = "employee-my-appointment"
    } else {
      route = "user-profile"
    }
    if (data.success) {
      this.props.set(data.data)
    }
    this.props.setAnchor()
    if (this.props.history.location !== route) {
      this.props.history.push(route)
    }
  }

  openNotification = (notification) => {
    this.markAsRead(notification)
    switch (notification.typeId.code) {
      case "BCAN":
        this.handleCancelledNotificationClick(notification)
        return
      case "BSU":
        this.handleCompletedNotificationClick(notification)
        return
      case "BC":
        this.handleCreatedNotificationClick(notification)
        return
      case "VT":
      case "CCI":
        this.handleCreatedNotificationClick(notification)
        return
      case "RC":
        this.handleReminderClick(notification)
        return
      default:
        return
    }
  }

  markAsRead = (notification, e) => {

    if (notification.typeId.code == "RC") {
      const query = { _id: notification._id }
      if (this.props.type == "employee") {
        if (notification.userId == notification.metadata.reminderId.employeeId.userId._id) {
          if (notification.status == 1) {
            query.status = 2
          }
        }
        query.employeeStatus = 2
      } else {
        if (notification.status == 1) {
          query.status = 2
        }
      }
      this.props.update_notification(query)
    } else {
      if (notification.status == 1) {
        this.props.update_notification({ _id: notification._id, status: 2 })
      }
    }

    if (e) {
      e.stopPropagation()
    }
  }

  setAnnouncementOpen = (announcementsOpen) => {
    this.setState({ announcementsOpen })
    if (announcementsOpen) {
      this.props.setAnchor()
    }
  }

  render = () => {
    const { notifications = [], anchor, setAnchor, type, announcements } = this.props
    const { announcementsOpen, openReminderModal, reminder, employeeId, openEmployeeReminders } = this.state
    const notificationsArray = notifications.reduce((array, notification) => {
      if (notification.typeId.code != "RC") {
        array.notifications.push(notification);
        if (notification.status == 1) {
          array.unreadNotifications++
        }
      } else {
        array.reminders.push(notification);
        if (type == "employee" ? notification.employeeStatus == 1 : notification.status == 1) {
          array.unreadReminders++
        }
      }
      return array
    }, { reminders: [], notifications: [], unreadReminders: 0, unreadNotifications: 0 })

    const tabs = [
      {
        title: <div className="position-relative">{i18n.t("notifications")}
          <span className="position-absolute notificationNumber" style={{ top: "-14px", right: "-9px" }}>
            {notificationsArray.unreadNotifications > 0 ? notificationsArray.unreadNotifications : ""}</span>
        </div>,
        content: () => (
          <div className="items d-flex flex-column align-items-center">
            {notificationsArray.notifications.map((notification) => {
              return (
                <div
                  key={notification._id}
                  className={`header-notification p-2 ${statusMap[notification.status]
                    }`}
                  onClick={() => this.openNotification(notification)}
                >
                  <div className="row mx-0">
                    <div className="header-notification-content">
                      <strong>{i18n.t(notification.typeId.code)}:</strong>{" "}
                      {this.generateMessage(notification)}
                    </div>
                    {notification.status == 1 ? (
                      <div
                        className="header-dismiss"
                        onMouseEnter={this.handlePopoverOpen}
                        onMouseLeave={this.handlePopoverClose}
                      />
                    ) : null}
                  </div>
                  <div className="row mx-0 date pt-1">
                    {moment(notification.createdDate).format(
                      "MM-DD-YYYY hh:mm a"
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        ),
      },
      {
        title: <div className="position-relative">{i18n.t("tasks_and_reminders")}
          <span className="position-absolute notificationNumber" style={{ top: "-14px", right: "-9px" }}>
            {notificationsArray.unreadReminders > 0 ? notificationsArray.unreadReminders : ""}
          </span>
        </div>,
        content: () =>

          <div className="items d-flex flex-column align-items-center">
            {type == "employee" ? (
              <PrimaryButton
                onClick={async () => {
                  getEmployeeDetails({ user: JSON.parse(await SharedStorage.getItem('employee')) }).then(({ data }) => {
                    this.setState({ openEmployeeReminders: true, employeeId: data.data.details._id })
                    this.props.setAnchor()
                  })

                }}
                label={i18n.t("view_my_reminders")}
              />
            ) : null}
            {notificationsArray.reminders.map((notification) => {
              const status = type == "employee" ? notification.employeeStatus == 1 : notification.status == 1
              return (
                <div
                  key={notification._id}
                  className={`header-notification p-2 ${statusMap[type == "employee" ? notification.employeeStatus : notification.status]
                    }`}
                  onClick={() => this.openNotification(notification)}
                >
                  <div className="row mx-0">
                    <div className="header-notification-content">
                      <strong>{this.generateMessage(notification)}</strong> {notification.metadata.reminderId?.subject}
                    </div>
                    {status ? (
                      <div
                        className="header-dismiss"
                        onMouseEnter={this.handlePopoverOpen}
                        onMouseLeave={this.handlePopoverClose}
                      />
                    ) : null}
                  </div>
                  <div className="row mx-0 pt-1">
                    <strong>{i18n.t("priority")} : </strong> {i18n.t(notification.metadata.reminderId?.priorityId?.name)}
                  </div>
                  <div className="row mx-0 date pt-1">
                    {moment(notification.createdDate).format(
                      "MM-DD-YYYY hh:mm a"
                    )}
                  </div>
                </div>
              )
            })}
          </div >
      },]
    return (
      <div>

        {openEmployeeReminders ? (
          <BaseModal
            open={openEmployeeReminders}
            onClose={() => {
              this.setState({ openEmployeeReminders: false })
            }}
            title={i18n.t("my_reminders")}
            content={
              <BookingReminders
                hasEditPermission={true}
                employeeId={employeeId}
              />
            }
          />) : null}
        {openReminderModal ? (
          <BaseModal
            open={openReminderModal}
            onClose={() => {
              this.setState({ openReminderModal: false })
            }}

            title={i18n.t("tasks_and_reminders")}
            content={
              <BookingReminders
                reminder={reminder}
                closeOnDelete={() => {
                  this.setState({ openReminderModal: false })
                }}
                hasEditPermission={true}
              />
            }
          />) : null}
        <AppUpdateAnnouncement
          open={announcementsOpen}
          setOpen={(open) => this.setAnnouncementOpen(open)}
        >
          <Popover
            open={Boolean(anchor)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            anchorEl={anchor}
            onClose={() => setAnchor()}
            PaperProps={{
              className: "header-notification-center",
            }}


          >
            {!!announcements.length && (
              <div
                className="n-announcements"
                onClick={() => this.setAnnouncementOpen(true)}
              >
                {i18n.t("announcements")}
              </div>
            )}
            < Tabs styles={"pr-0"} tabs={tabs} />
          </Popover>
        </AppUpdateAnnouncement>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  announcements: state.announcements.data,
})

const action = { update_notification, set }

export default connect(mapStateToProps, action)(NotificationCenter)
