import React, { useState } from "react"
import TextField from "@material-ui/core/TextField"
import i18n from "../../i18n"

const PhoneNumberFormat = ({
  phoneUpdate,
  val = "",
  errorMessage,
  helperText,
  variant = "",
}) => {
  const [formatedPhoneNumber, setFormatedPhoneNumber] = useState()

  const formatPhoneNumber = (input, format) => {
    // Strip non-numeric characters
    var digits = input.replace(/\D/g, "")

    // Replace each "X" with the next digit
    let count = 0
    let newF = format.replace(/X/g, function () {
      return digits.charAt(count++)
    })
    return newF.replace("X", "")
  }

  const onChangeCompanyTelephone = (text) => {
    let phoneNumber = formatPhoneNumber(text.target.value, "XXX XXX XXXX")
    let res = phoneNumber.replaceAll(" ", "")
    phoneUpdate(res)
    setFormatedPhoneNumber(phoneNumber)
  }
  let originalNumber = formatPhoneNumber(val, "XXX XXX XXXX")
  // console.log(errorMessage)
  return (
    <TextField
      label={i18n.t("phone_number")}
      variant={variant || "standard"}
      className="testss"
      onChange={(text) => onChangeCompanyTelephone(text)}
      value={!formatedPhoneNumber ? originalNumber : formatedPhoneNumber}
      // InputLabelProps={{ shrink: true }}

      error={!!errorMessage}
      helperText={errorMessage}

      // InputLabelProps={{
      //   shrink: true,
      // }}
      // type={inputType}
    />
  )
}

export default PhoneNumberFormat
