import React, { useEffect, useState } from "react"
import i18n from "../../../i18n"
import { getRequest } from "../../../redux/actions/Saloon/CommunicationsMarketing"
import { getRequest as getCards } from "../../../redux/actions/CompanyCard"
import PrimaryButton from "../../../components/Core/PrimaryButton"
import { DotsLoader } from "../../../components"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import FundAccountPopup from "./Components/FundAccountPopup"

import { getTransactionHistoryRequest } from "../../../redux/actions/Saloon/CommunicationsMarketing"
import BasicTable from "../../../components/Core/BasicTable"
import moment from "moment"
import useScreenSize from "../../../context/ScreenSize"
const CommunicationsMarketing = () => {
  const dispatch = useDispatch()
  const [fundModalOpen, setFundModalOpen] = useState(false)
  const loading = useSelector((s) => s.communicationsMarketing.isFetching)
  const account = useSelector((s) => s.communicationsMarketing.data)
  const { screenSize } = useScreenSize()
  useEffect(() => {
    dispatch(getRequest())
    dispatch(getCards())
    dispatch(getTransactionHistoryRequest())
  }, [])

  return (
    <div className="content-container p-4">
      <DotsLoader isloading={loading} />
      <div
        style={
          screenSize === "mobile"
            ? {
                position: "sticky",
                top: "0",
                paddingBottom: "0.2rem",
                backgroundColor: "white",
                zIndex: 1,
              }
            : {}
        }
      >
        <h1 className="saloon-dashboard-heading mb-5">
          {i18n.t("communication_marketing_account")}
        </h1>
        <div className="d-flex justify-content-between justify-content-md-start align-items-center mb-3">
          <div className="mr-3">
            <h4 className="font-weight-bolder">
              $ {((account.balance || 0) / 100).toFixed(2)}
            </h4>
            <h6>{i18n.t("container.account_balance")}</h6>
          </div>
          <PrimaryButton
            className="w-98"
            label={i18n.t("fund")}
            onClick={() => setFundModalOpen(true)}
          />
        </div>
        <h4 className="font-weight-bolder my-3">{i18n.t("history")}</h4>
      </div>
      <BasicTable
        rows={account?.transactions || []}
        maxContentWidth={false}
        schema={[
          {
            header: i18n.t("amount"),
            field: "amount",
            accessor: ({ amount }) => `$${(amount / 100).toFixed(2)}`,
          },
          {
            header: i18n.t("type"),
            field: "type",
          },
          {
            header: i18n.t("created_on"),
            field: "createdDate",
            accessor: ({ createdDate }) => {
              return moment(createdDate).format("MM-DD-YYYY HH:mm")
            },
          },
        ]}
      />
      <FundAccountPopup open={fundModalOpen} setOpen={setFundModalOpen} />
    </div>
  )
}

export default CommunicationsMarketing
