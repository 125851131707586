import { connect } from "react-redux";
import React, { Component } from "react";
import i18n from "../../../i18n";
import {
  UpdateSaloonBlackList,
  getSaloonById,
} from "../../../config/simpleApiCalls";

import { DotsLoader } from "../../../components";
import { SuccessHelper, ErrorHelper } from "../../../helpers";
import TextField from "@material-ui/core/TextField";
import "./styles.css";
import PrimaryButton from "../../../components/Core/PrimaryButton";
import Select from "../../../components/Core/Select";
import SharedStorage from "../../../helpers/Storage";

class BlackList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasLink: false,
      companyId: "",
      isloading: true,
      intialized: false,
    };
  }

  componentDidMount = async () => {
    const companyData = await JSON.parse(
      await SharedStorage.getItem("company")
    );
    const companyUpdatedData = await getSaloonById();
    if (companyData) {
      this.setState({
        companyId: companyData._id,
        blackListThreshold: companyUpdatedData.data.data.blackListThreshold,
        blackListLimit: companyUpdatedData.data.data.blackListLimit,
        isloading: false,
        intialized: true,
      });
    }
  };

  onUpdateBlackList = async () => {
    this.setState({ isloading: true });
    const { blackListLimit, blackListThreshold, companyId } = this.state;
    const payload = { companyId, blackListLimit, blackListThreshold };

    UpdateSaloonBlackList(payload)
      .then((res) => {
        this.setState({ isloading: false });
        if (res.data.success) {
          SuccessHelper.handleSuccess(i18n.t("successfully_updated"), true);
        } else {
          ErrorHelper.handleErrors(i18n.t("failed_to_update"), true);
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
      });
  };

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  onChangeBookingLimit = (text) =>
    this.setState({ blackListLimit: text.target.value });
  onChangeBookingThreshold = (text) => {
    let threshold = parseInt(text.target.value);
    const { blackListLimit = 0 } = this.state;
    if (threshold > blackListLimit) {
      threshold = blackListLimit;
    }
    this.setState({ blackListThreshold: threshold });
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  renderSaloonDashboardHeading = () => {
    return (
      <div className="">
        <h1 className="saloon-dashboard-heading pb-3">
          {i18n.t("container.dashboard_black_list_config")}
        </h1>
      </div>
    );
  };

  renderSelect = () => {
    const { blackListLimit } = this.state;
    return (
      <div className="site-form row align-items-center gap-1 form-group col-md-12 px-0">
        <label className="font-weight-bold">{i18n.t("number_of_bookings")}</label>
        <Select
          label={i18n.t("number_of_bookings")}
          value={blackListLimit}
          options={[
            { name: 5, value: "5" },
            { name: 10, value: "10" },
            { name: 15, value: "15" },
          ]}
          onChange={this.onChangeBookingLimit}
          className="editField"
          variant="outlined"
        />
      </div>
    );
  };

  renderInput = () => {
    const { blackListThreshold } = this.state;
    return (
      <div className="site-form row align-items-center gap-1 form-group col-md-12 px-0">
        <label className="font-weight-bold">{i18n.t("cancellation_limit")}</label>
        <TextField
          value={blackListThreshold}
          onChange={this.onChangeBookingThreshold}
          className="editField"
          variant="outlined"
        />
      </div>
    );
  };

  renderSocialLinkCard = () => {
    return (
      <div className="col-12 py-3 px-0">
        <div
          className="col-12 bg-white"
          style={{ borderRadius: "1rem", padding: "0" }}
        >
          <div className="col-12 px-0 px-md-3"></div>
          <div className="mb-3">
            <div className="col-12 col-md-6 d-flex align-items-center px-0">
              {this.state.intialized ? (
                this.renderSelect()
              ) : null}
            </div>
            <div className="col-12 col-md-6 d-flex align-items-center px-0">
              {this.renderInput()}
            </div>
          </div>
          <div className="mt-3">
            <PrimaryButton
              className="w-128"
              label={i18n.t("container.update_changes")}
              onClick={this.onUpdateBlackList}
            />
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.renderLoader()}
        <div className="content-container">
          <div className="p-4">
            {this.renderSaloonDashboardHeading()}
            {this.renderSocialLinkCard()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(BlackList);
