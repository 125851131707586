import React, { Component } from "react";
import Fields from "./Fields.component";
import { DragDropContext } from "react-beautiful-dnd";
import Button from "@material-ui/core/Button";

import "../Styles/fieldReview.sass";

class FieldReview extends Component {
  onDragEnd = (result) => {
    this.props.handleDragEnd(result);
  };

  render() {
    if (this.props.fieldsToRender.length > 0) {
      return (
        <div className="fieldReview">
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Fields
              column={this.props.column}
              fieldsToRender={this.props.fieldsToRender}
              handleDeletField={this.props.handleDeletField}
              handleEditField={this.props.handleEditField}
            />
          </DragDropContext>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default FieldReview;
