import jsPDF from "jspdf"
import autoTable from "jspdf-autotable"
import html2canvas from "html2canvas"

export const exportTableToPDF = (
  ids,
  name,
  employeeInfos = [],
  bold = true,
  orientation = "landscape"
) => {
  try {
    const properties = {
      theme: "grid",
      didParseCell: function (data) {
        if (data.row.index === 0 && bold) {
          data.cell.styles.fontStyle = "bold"
        }
      },
      styles: { overflow: "linebreak", fontSize: 9 },
      columnStyles: { text: { cellWidth: "auto" } },
    }

    const pdf = new jsPDF(orientation, "pt", "a4")
    for (var i = 0; ids.length > i; i++) {
      if (employeeInfos.length) {
        i == 0
          ? pdf.text(40, 35, employeeInfos[i].name)
          : pdf.text(
            40,
            pdf.autoTable.previous.finalY + 35,
            employeeInfos[i].name
          )
        pdf.setFontSize(11)

        i == 0
          ? pdf.text(430, 35, employeeInfos[i].totalHoursWorked)
          : pdf.text(
            430,
            pdf.autoTable.previous.finalY + 35,
            employeeInfos[i].totalHoursWorked
          )
        pdf.setFontSize(14)

        properties.startY = i == 0 ? 45 : pdf.autoTable.previous.finalY + 45

        var res = pdf.autoTableHtmlToJson(document.querySelector(ids[i]))
        var columns = [res.columns[1], res.columns[2]]
        var rows = res.data.map(function (val) {
          return val.slice(1, -1)
        })

        pdf.autoTable(columns, rows, properties)
      } else {
        properties.html = ids[i]
        pdf.autoTable(properties)
      }
    }

    pdf.save(name + ".pdf")
  } catch (e) {
    console.log(e)
  }
}

export const exportImgToPDF = async (element, name) => {
  const pdf = new jsPDF("landscape", "pt", "a4")
  const data = await html2canvas(element)
  const img = data.toDataURL("image/png")
  const imgProperties = pdf.getImageProperties(img)
  const pdfWidth = pdf.internal.pageSize.getWidth()
  const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width
  pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight)
  pdf.save(name + ".pdf")
}
