import { connect } from "react-redux";
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// import CryptoJS from "crypto-js";

import Booking from "../containers/Employee/Booking";
import MyRating from "../containers/Employee/Rating";
import EditProfile from "../containers/Saloon/EditProfile"
import EmployeeEditProfile from "../containers/Employee/EditProfile";
import Dashboard from "../containers/Employee/Dashboard";
import UploadMedia from "../containers/Employee/UploadMedia";
import AddWorkingHours from "../containers/Employee/AddWorkingHours";
import ActiveSaloon from "./../containers/Employee/ActiveSaloon";
import MyAppointment from "./../containers/Employee/MyAppointment";
import EmployeeWallet from "./../containers/Employee/Wallet";
import AllCustomers from "../containers/Employee/AllCustomers";
import RequestFunds from "../containers/Employee/RequestFunds";
import ManageUnavailibilites from "./../containers/Employee/ManageUnavailibilites";
import PrivateRoute from "./components/PrivateRoute.component";
import ActivityScheduleBuilder from "../containers/Saloon/ActivityScheduleBuilder/ActivityScheduleBuilder";
import { ROLES } from "../util/enum";
// Components
import { set } from "../redux/actions/User";
import EmployeeSocialMedia from "../containers/Employee/LinkSocialMedia/EmployeeSocialMedia";

const isUser = { isAuthenticated: false };

class EmployeeRoutes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.employeeLogin) {
      if (
        !nextProps.employeeLogin.failure &&
        !nextProps.employeeLogin.isFetching &&
        !nextProps.employeeLogin.errorMessage &&
        nextProps.employeeLogin.data &&
        nextProps.employeeLogin.data.data &&
        nextProps.employeeLogin.data.data.access_token &&
        nextProps.employeeLogin.data.data.role == 4
      ) {
        this.setState({ user: nextProps.employeeLogin.data.data });
      }
    }
    if (nextProps.impersonateEmployee) {
      if (
        !nextProps.impersonateEmployee.failure &&
        !nextProps.impersonateEmployee.isFetching &&
        !nextProps.impersonateEmployee.errorMessage &&
        nextProps.impersonateEmployee.data &&
        nextProps.impersonateEmployee.data.data &&
        nextProps.impersonateEmployee.data.data.access_token &&
        this.props.impersonateEmployee.isFetching
      ) {
        let data = nextProps.impersonateEmployee.data.data;
        const { isCompanyProfile } = data;
        if (data.role && (data.role.includes(ROLES.OWNER) || data.role.includes(ROLES.EMPLOYEE))) {
          const toSet = {};
          if (isCompanyProfile) {
            toSet.saloon = JSON.stringify(data);
            toSet.company = JSON.stringify(data.company);
          }
          this.props.set({
            ...toSet,
            employee: JSON.stringify(data),
            access_token: data.access_token,
          });
          if (!data.isEmployeeProfile) {
            this.props.history.push("/employee-add-working-hour");
          } else {
            this.props.history.push("/employee-dashboard");
          }
        }

        this.setState({ user: nextProps.impersonateEmployee.data.data });
      }
    }
  }

  PrivateRoute = ({ component: Component, path, resource }) => {
    return (
      <PrivateRoute
        {...this.props}
        path={path}
        Component={Component}
        type="employee"
        resource={resource}
      />
    );
  };

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  render() {
    const { user, sidebarOpen } = this.state;
    const { docked } = this.props;
    return (
      <Switch>
        {this.PrivateRoute({
          path: "/employee-booking",
          component: Booking,
          props: user,
          resource: "EmployeeBookingHistory",
        })}
        {this.PrivateRoute({
          path: "/employee-rating",
          component: MyRating,
          props: user,
          resource: "EmployeeRating",
        })}
        {this.PrivateRoute({
          path: "/employee-activity-schedule-builder",
          component: ActivityScheduleBuilder,
          resource: "EmployeeActivitiesSchedule",
        })}
        {this.PrivateRoute({
          path: "/employee-edit-profile",
          component: EmployeeEditProfile,
          props: user,
          resource: "EmployeeProfile",
        })}
        {this.PrivateRoute({
          path: "/employee-dashboard",
          component: Dashboard,
          props: user,
          resource: "EmployeeDashboard",
        })}
        {this.PrivateRoute({
          path: "/employee-social-media",
          component: EmployeeSocialMedia,
          props: user,
          resource: "EmployeeSocialMedia",
        })}
        {this.PrivateRoute({
          path: "/employee-upload-media",
          component: UploadMedia,
          props: user,
          resource: "EmployeeUploadMedia",
        })}
        {this.PrivateRoute({
          path: "/employee-active-saloon",
          component: ActiveSaloon,
          props: user,
          resource: "EmployeeActiveSaloon",
        })}
        {this.PrivateRoute({
          path: "/employee-add-working-hour",
          component: AddWorkingHours,
          props: user,
          resource: "EmployeeWorkingHours",
        })}
        {this.PrivateRoute({
          path: "/employee-my-appointment",
          component: MyAppointment,
          props: user,
          resource: "EmployeeMyAppointment",
        })}
        {this.PrivateRoute({
          path: "/employee-wallet",
          component: EmployeeWallet,
          props: user,
          resource: "EmployeeWallet",
        })}
        {this.PrivateRoute({
          path: "/employee-customers",
          component: AllCustomers,
          props: user,
          resource: "EmployeeAllCustomers",
        })}
        {this.PrivateRoute({
          path: "/employee-request-funds",
          component: RequestFunds,
          props: user,
          resource: "EmployeeRequestFunds",
        })}
        {this.PrivateRoute({
          path: "/employee-manage-unavailabilities",
          component: ManageUnavailibilites,
          props: user,
          resource: "EmployeeWorkingHours"
        })}
      </Switch>
    );
  }
}

const mapStateToProps = (state) => ({
  employeeLogin: state.employeeLogin,
  impersonateEmployee: state.impersonateEmployee,
  user: state.user.data.employee
    ? JSON.parse(state.user.data.employee)
    : state.user.data.employee,
  docked: state.sidebar.isDocked,
  sidebarOpen: state.sidebar.isOpen,
  salonAllowedResource: state.receptionistAccess.data?.resourceMap,
});

const action = { set };

export default connect(mapStateToProps, action)(EmployeeRoutes);
