import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import "../Styles/radioOption.sass";

class RadioOption extends Component {
  handleRadioFields = (e) => {
    this.props.handleradio(e.target.value);
  };

  render() {
    const { fieldOptions } = this.props;
    return (
      <React.Fragment>
        <h4 className="radioTitle">Please select options for radio button</h4>

        <TextField
          label="Enter Options seperated by - (Hyphen symbol)"
          onChange={(e) => this.handleRadioFields(e)}
          variant="outlined"
          fullWidth={true}
          value={fieldOptions}
        />

        {/* {inputTypeError ? <span>{inputTypeError}</span> : ""} */}
      </React.Fragment>
    );
  }
}

export default RadioOption;
