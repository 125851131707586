import React, { useState } from "react";
import i18n from "../../../i18n";
import { TextField } from "@material-ui/core";
import PrimaryButton from "../../../components/Core/PrimaryButton";
import Select from "../../../components/Core/Select";
import ToggleSelect from "../../../components/ToggleSelect";

export default function AddEdit({
  name = "",
  percentage = 0,
  isActive = true,
  handleName = () => { },
  handlePercentage = () => { },
  handleIsActive = () => { },
  handleSubmit = () => { },
}) {
  const [formErrors, setFormErrors] = useState({});
  const options = [
    { name: i18n.t("yes"), value: true },
    { name: i18n.t("no"), value: false },
  ];
  const checkValidation = () => {
    if (!name) {
      setFormErrors({ ...formErrors, nameError: i18n.t("name_error") });
      setTimeout(() => {
        setFormErrors({ ...formErrors, nameError: "" });
      }, 8000);
    } else if (!percentage) {
      setFormErrors({
        ...formErrors,
        percentageError: i18n.t("percentage_is_required"),
      });
      setTimeout(() => {
        setFormErrors({ ...formErrors, percentageError: "" });
      }, 6000);
    } else {
      handleSubmit();
    }
  };
  return (
    <div className="col-12 px-0">
      <div class="row mb-3 px-0">
        <div className="col-12 d-flex align-items-center mb-3 px-0">
          <label className="font-weight-bold" style={{ minWidth: '100px' }}>{i18n.t("name")}</label>
          <TextField
            label={i18n.t("name")}
            value={name}
            onChange={(text) => {
              handleName(text.target.value);
            }}
            error={formErrors.nameError}
            helperText={formErrors.nameError}
            variant="outlined"
            style={{ maxWidth: 300 }}
          />
        </div>
        <div className="col-12 d-flex align-items-center mb-3 px-0">
          <label className="font-weight-bold" style={{ minWidth: '100px' }}>{i18n.t("percentage")}</label>
          <TextField
            type="number"
            label={i18n.t("percentage")}
            onChange={(text) => {
              handlePercentage(text.target.value);
            }}
            variant="outlined"
            onBlur={() => {
              const newPercentage = percentage ? parseFloat(percentage) : 0;
              if (newPercentage < 0) {
                handlePercentage(0);
              } else {
                if (newPercentage === 0) {
                  handlePercentage(newPercentage);
                } else {
                  handlePercentage(newPercentage.toFixed(3));
                }
              }
            }}
            value={percentage}
            error={formErrors.percentageError}
            helperText={formErrors.percentageError}
            style={{ maxWidth: 300 }}
          />
        </div>
        <div className="col-12 d-flex align-items-center mb-3 px-0">
          <label className="font-weight-bold" style={{ minWidth: '100px' }}>{i18n.t("is_active")}</label>
          <ToggleSelect
            value={isActive}
            onChange={(e) => {
              handleIsActive(e);
            }}
            active="yes"
            inactive="no"
          />
        </div>
      </div>
      <div className="mt-3">
        <PrimaryButton
          className="w-128"
          onClick={() => checkValidation()}
          type="button"
          label={i18n.t("save")}
        />
      </div>
    </div>
  );
}
