import { put, takeEvery } from "redux-saga/effects"

import * as types from "../../actions/ActionTypes"
import {
  insert,
  get,
  getReport,
  remove,
  requestFailure,
} from "../../actions/Saloon/expense"

import axios from "axios"
import { expense } from "../../../config/WebServices"
import FileDownload from "js-file-download"

function* getSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.get(expense, {
      params: payload,
    })
    data = data.data

    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data)
      }
      yield put(get(data.data))
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data)
      }
      yield put(requestFailure(data))
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* getReportSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    const additional = {}
    if (payload.exportToXlsx) {
      additional.responseType = "blob"
    }
    data = yield axios.get(`${expense}/getReport`, {
      params: payload,
      ...additional,
    })
    data = data.data

    if (payload.exportToXlsx) {
      yield put(getReport(data))
      return FileDownload(data, "report.xlsx")
    }
    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data)
      }
      yield put(getReport(data))
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data)
      }
      yield put(requestFailure(data))
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* insertSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.post(expense, payload)
    data = data.data
    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data)
      }
      yield put(insert(data.data))
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data)
      }
      yield put(requestFailure(data))
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* removeSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.delete(expense, {
      params: payload,
    })
    data = data.data
    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data)
      }
      yield put(remove(data.data))
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data)
      }
      yield put(requestFailure(data))
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

export default function* root() {
  yield takeEvery(types.EXPENSE.GET_SAGA, getSaga)
  yield takeEvery(types.EXPENSE.INSERT_SAGA, insertSaga)
  yield takeEvery(types.EXPENSE.DELETE_SAGA, removeSaga)
  yield takeEvery(types.EXPENSE.GET_REPORT_SAGA, getReportSaga)
}
