import { connect } from "react-redux";
import React, { Component } from "react";
import { Translation } from "react-i18next";
import i18n from "../../i18n";
import { getServices, getHomeServiceInfo } from "../../config/simpleApiCalls";
import { request as login_user } from "../../redux/actions/Login";
import { DotsLoader, Navbar, Footer } from "../../components";
import { Images } from "../../theme/index";
import "./styles.css";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceList: [],
      benefitsList: [],
      isloading: false,
      ourServices: {
        _id: "1234342",
        header: {
          img_url: "https://example.com/test.png",
          title: "string",
          subTitle: "string",
          des: "string",
          _id: "23234",
        },
        body: {
          mainTitle: "String",
          img_url: "https://example.com/test.png",
          listItem: [
            {
              img_url: "https://example.com/test.png",
              title: "string",
              des: "string",
              _id: "213123123",
            },
            {
              img_url: "https://example.com/test.png",
              title: "string",
              des: "string",
              _id: "",
            },
          ],
        },
        benefits: {
          mainTitle: "string",
          des: "string",
          listItem: [
            {
              img_url: "https://example.com/test.png",
              title: "string",
              des: "string",
              _id: "213123123",
            },
            {
              img_url: "https://example.com/test.png",
              title: "string",
              des: "string",
              _id: "",
            },
          ],
        },
        muchMore: {
          mainTitle: "String",
          listItem: [
            {
              img_url: "https://example.com/test.png",
              title: "string",
              des: "string",
              _id: "213123123",
            },
            {
              img_url: "https://example.com/test.png",
              title: "string",
              des: "string",
              _id: "",
            },
          ],
        },
        footer: {
          mainTitle: "String",
          img_url: "https://example.com/test.png",
          des: "String",
          _id: "string",
          listItem: [
            {
              title: "string",
              appUrl: "com.apple.com",
              icon: "https://IOS",
              _id: "string",
            },
            {
              title: "string",
              appUrl: "com.android.com",
              icon: "https://android",
              _id: "string",
            },
          ],
        },
      },

      ourServices: null,
      Pagelocation: null,
      cardDetail: [
        {
          img: Images.setting_icon,
          imgBgColor: "#FFD954",
          title: "Collections",
          detail:
            "There is no need to limit your imagination with predefine color scheme or block structure.",
        },
        {
          img: Images.setting_icon,
          imgBgColor: "#D249FF",
          title: "Collections",
          detail:
            "It is very important to take attention to the most value element of each store.",
        },
        {
          img: Images.setting_icon,
          imgBgColor: "#2879FE",
          title: "Reports",
          detail:
            "Does someone read this? FREE HTML version for anyone who report about reading this block.",
        },
      ],
    };
  }

  componentDidMount = () => {
    this.getAllServices();
    this.getAllHomeServiceInfo();
  };

  getAllHomeServiceInfo() {
    getHomeServiceInfo()
      .then((res) => {
        if (res.data.success) {
          this.setState({
            ourServices: res.data.data.ourServices,
          });
        }
      })
      .catch((err) => {
        alert("Something went wrong");
        console.log("err: ", err);
      });
  }

  getAllServices = () => {
    this.setState({ isloading: true });
    getServices()
      .then((res) => {
        if (res.data.success) {
          this.setState({ isloading: false, serviceList: res.data.data });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
        console.log(error, "errorrrrrrrrrrrrrrrrrrr");
      });
  };

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  renderJumbotron = () => {
    const { ourServices } = this.state;

    // let ourServices = this.props.history.location?.state.state.ourServices;

    return (
      <div
        className="container-fluid py-5 min-vh-100"
        style={{
          backgroundImage: `url(${ourServices?.header.img_url})`,
          backgroundSize: "cover",
        }}
      />
    );
  };

  renderServiceSection = () => {
    const { serviceList } = this.state;
    return (
      <div className="container-fluid py-5" style={{ backgroundColor: "#fff" }}>
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1 id="service-heading">
                <Translation>
                  {(t) => t("container.profile_service")}
                </Translation>{" "}
              </h1>
              <span style={{ whiteSpace: "nowrap" }}>
                <hr
                  className="service-heading-underline"
                  style={{ width: "40px" }}
                />
                <hr
                  className="service-heading-underline"
                  style={{ width: "120px" }}
                />
              </span>
            </div>
          </div>
          <div className="row">
            {serviceList.map((val, ind) => {
              return (
                <div key={ind} className="col-12 col-md-6 col-lg-4 p-3">
                  <div
                    class="card"
                    style={{
                      borderRadius: "1rem",
                      backgroundColor: "#ff3600",
                      minHeight: "180px",
                    }}
                  >
                    <div class="text-center">
                      <img
                        className="card-img-top"
                        src={val.image}
                        style={{ paddingTop: 20, width: 80 }}
                        alt="Card image cap"
                      />
                    </div>
                    <div className="card-body">
                      <h5
                        className="card-title"
                        style={{ textAlign: "center" }}
                      >
                        <strong style={{ color: "#ffffff" }}>{val.name}</strong>
                      </h5>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {/* <div class="card-footer bg-transparent border-0 pt-0">
                                            <span className="services-more-btn" onClick={() => { }}>more &gt;</span>
                                        </div> */}
        </div>
      </div>
    );
  };

  renderBenefitsSection = () => {
    const { ourServices } = this.state;

    return (
      <div className="container-fluid py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-9 col-lg-6">
              <h2 id="benefits-heading">{ourServices?.benefits.mainTitle}</h2>
              <p id="benefits-desc">{ourServices?.benefits.des}</p>
            </div>
          </div>
          <div className="row">
            {ourServices?.benefits.listItem.map((val, ind) => {
              return (
                <div key={ind} className="col-12 col-md-6 col-lg-4 p-3">
                  <img
                    // class="p-3"
                    src={val.img_url}
                    style={{ marginBottom: 20, width: 50 }}
                    alt="Card image cap"
                  />
                  <h5 className="h5">
                    <strong>{val.title}</strong>
                  </h5>
                  <p className="">{val.des}</p>
                </div>
              );
            })}
          </div>
          {/* <div className="col-12 text-center">
            <button
              type="button"
              className="btn btn-light btn-sm"
              id="benefit-view-more-btn"
            >
              View More &gt;&gt;
            </button>
          </div> */}
        </div>
      </div>
    );
  };

  render() {
    let { cardDetail, ourServices, Pagelocation } = this.state;
    console.log(this.props, "propsss");

    return (
      <div style={{ backgroundColor: "#F8FDFF" }}>
        <Navbar {...this.props} isBackgroundColor={true} />
        {this.renderLoader()}
        {this.renderJumbotron()}

        <Footer
          cardDetail={ourServices}
          renderHeaderInfo={true}
          renderServices={this.renderServiceSection()}
          renderBenefitsSection={this.renderBenefitsSection()}
        />
        {/* {this.renderServiceSection()} */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ login: state.login });

const action = { login_user };

export default connect(mapStateToProps, action)(Login);
