import crudBuilder from "../../../builders/CrudBuilder/CrudBuilder"
import actions from "../../../redux/actions/Saloon/CustomerOrigin"
const { upsertRequest, getRequest, removeRequest } = actions

const CustomerOrigin = crudBuilder.build({
  type: "customerOrigin",
  title: "origins",
  actionMap: {
    upsert: upsertRequest,
    get: getRequest,
    remove: removeRequest,
  },
})

export default CustomerOrigin
