import { TextField } from "@material-ui/core";
import React, { useState, useEffect, useMemo } from "react";
import i18n from "../../i18n";
import BaseModal from "../BaseModal";
import ThreeDButton from "../Core/3DButton/ThreeDButton";
import PrimaryButton from "../Core/PrimaryButton";
import SecondaryButton from "../Core/SecondaryButton/SecondaryButton";
import TouchList from "../Partner/CreateAppointment/Support/TouchList";
import BackspaceIcon from "@material-ui/icons/Backspace";

export function CustomerSearchUp({ customers = [], onDone, open, setOpen }) {
  const [phoneNo, setPhoneNo] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState("");
  useEffect(() => {
    if (open) {
      setPhoneNo("");
    }
  }, [open]);
  const buttonStyle = { height: 34, fontSize: 16 };
  const buttonWrapperStyke = { borderRadius: 7 };
  const buttonClick = (char) => {
    setPhoneNo(phoneNo + char);
  };
  const handleBackSpace = () => {
    setPhoneNo(phoneNo.slice(0, -1));
  };
  const filteredCustomer = useMemo(() => {
    if (customers.length) {
      if (!phoneNo) {
        return customers?.slice(0, 100);
      }
      return customers
        .filter((customer) => {
          return (
            customer.phoneNo.toLowerCase().indexOf(phoneNo.toLowerCase()) > -1
          );
        })
        .slice(0, 100);
    }
    return []

  }, [customers, phoneNo]);
  return (
    <BaseModal
      open={open}
      title={i18n.t("customer_look_up")}
      onClose={() => {
        setOpen(false);
      }}
      content={
        <div className="row mx-0">
          <div className="col-sm-8 px-0">
            <div className="d-sm-flex mx-sm-2 align-items-center flex-nowrap mb-3">
              <label className="font-weight-bold mr-3 mobile-w-100" style={{ minWidth: 100 }}>{i18n.t("employe_phone_number")}</label>
              <TextField
                label={i18n.t("employe_phone_number")}
                value={phoneNo}
                onChange={(e) => setPhoneNo(e.target.value)}
                variant="outlined"
              />
              <SecondaryButton onClick={() => setPhoneNo("")} className="ml-sm-3 mt-3 mt-sm-0">
                {i18n.t("clear")}
              </SecondaryButton>
            </div>
            <div className="row mx-0">
              <div className="col-4 p-2">
                <ThreeDButton
                  label="1"
                  style={buttonStyle}
                  wrapperStyle={buttonWrapperStyke}
                  onClick={() => buttonClick("1")}
                />
              </div>
              <div className="col-4 p-2">
                <ThreeDButton
                  label="2"
                  style={buttonStyle}
                  wrapperStyle={buttonWrapperStyke}
                  onClick={() => buttonClick("2")}
                />
              </div>
              <div className="col-4 p-2">
                <ThreeDButton
                  label="3"
                  style={buttonStyle}
                  wrapperStyle={buttonWrapperStyke}
                  onClick={() => buttonClick("3")}
                />
              </div>
              <div className="col-4 p-2">
                <ThreeDButton
                  label="4"
                  style={buttonStyle}
                  wrapperStyle={buttonWrapperStyke}
                  onClick={() => buttonClick("4")}
                />
              </div>
              <div className="col-4 p-2">
                <ThreeDButton
                  label="5"
                  style={buttonStyle}
                  wrapperStyle={buttonWrapperStyke}
                  onClick={() => buttonClick("5")}
                />
              </div>
              <div className="col-4 p-2">
                <ThreeDButton
                  label="6"
                  style={buttonStyle}
                  wrapperStyle={buttonWrapperStyke}
                  onClick={() => buttonClick("6")}
                />
              </div>
              <div className="col-4 p-2">
                <ThreeDButton
                  label="7"
                  style={buttonStyle}
                  wrapperStyle={buttonWrapperStyke}
                  onClick={() => buttonClick("7")}
                />
              </div>
              <div className="col-4 p-2">
                <ThreeDButton
                  label="8"
                  style={buttonStyle}
                  wrapperStyle={buttonWrapperStyke}
                  onClick={() => buttonClick("8")}
                />
              </div>
              <div className="col-4 p-2">
                <ThreeDButton
                  label="9"
                  style={buttonStyle}
                  wrapperStyle={buttonWrapperStyke}
                  onClick={() => buttonClick("9")}
                />
              </div>
              <div className="col-4 p-2" />
              <div className="col-4 p-2">
                <ThreeDButton
                  label="0"
                  style={buttonStyle}
                  wrapperStyle={buttonWrapperStyke}
                  onClick={() => buttonClick("0")}
                />
              </div>
              <div className="col-4 p-2">
                <ThreeDButton
                  label={<BackspaceIcon />}
                  style={buttonStyle}
                  wrapperStyle={buttonWrapperStyke}
                  onClick={handleBackSpace}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-4 px-0" style={{ maxHeight: 420, overflowY: "auto" }}>
            <TouchList
              options={filteredCustomer}
              onChange={(customer) => {
                setSelectedCustomer(customer);
                setPhoneNo(customer.phoneNo);
              }}
              selected={selectedCustomer}
              noOptionsMessage={i18n.t("no_customers")}
            />
          </div>
        </div>
      }
      actions={
        <div className="row mx-0 justify-content-end mb-4 mr-4">
          <PrimaryButton
            className="w-128"
            label={i18n.t("done")}
            onClick={() => onDone(phoneNo, selectedCustomer)}
          />
        </div>
      }
    />
  );
}

export default CustomerSearchUp;
