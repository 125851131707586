import React, { useEffect, useRef, useState } from "react"
import i18n from "../../../../i18n"
import actions from "../../../../redux/actions/Saloon/TimePeriod"
import PrimaryButton from "../../../../components/Core/PrimaryButton"
import { BaseModal } from "../../../../components"
import { useDispatch } from "react-redux"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import { ErrorHelper, SuccessHelper } from "../../../../helpers"
import FormContainer from "../../../../components/Core/FormContainer/FormContainer"
import moment from "moment"
import ReactDatePicker from "react-datepicker"
import useScreenSize from "../../../../context/ScreenSize"
const { upsertRequest } = actions

const TimePeriodUpsertPopup = ({ open, setOpen, active }) => {
  const dispatch = useDispatch()
  const form = useRef()
  const [data, setData] = useState({})
  const title = data._id ? "update_time_period" : "create_time_period"
  const { screenSize } = useScreenSize()
  const onSubmit = (e) => {
    const successMessage = data._id
      ? "successfully_updated"
      : "successfully_added"
    const failureMessage = data._id ? "failed_to_update" : "failed_to_add"
    dispatch(
      upsertRequest(
        { ...data },
        {
          success: ({ data: res }) => {
            const toSet = { ...data, ...res }

            toSet.startDate = res.startDate && moment(res.startDate).toDate()
            toSet.endDate = res.endDate && moment(res.endDate).toDate()
            setData(toSet)
            SuccessHelper.handleSuccess(i18n.t(successMessage), true)
            setOpen(false)
          },
          failure: () => {
            ErrorHelper.handleErrors(i18n.t(failureMessage), true)
          },
        }
      )
    )
  }

  useEffect(() => {
    if (open) {
      const data = { ...(active || {}) }
      const startDate = data.startDate && moment(data.startDate).toDate()
      const endDate = data.endDate && moment(data.endDate).toDate()

      setData({
        ...data,
        startDate,
        endDate,
      })
    }
  }, [open])

  return (
    <BaseModal
      open={open}
      onClose={() => setOpen(false)}
      title={i18n.t(title)}
      showCloseBtn={screenSize !== "mobile" ? true : false}
      containerClassName={"half-container"}
      content={
        <ValidatorForm onSubmit={onSubmit} ref={form} className="w-100 my-4">
          <FormContainer>
            <div className="form-container mb-4">
              <h6 className="mb-0">{i18n.t("name")}</h6>
              <TextValidator
                label={i18n.t("name")}
                variant="outlined"
                onChange={(e) => setData({ ...data, name: e.target.value })}
                value={data.name || ""}
                validators={["required"]}
                errorMessages={[i18n.t("this_field_is_required")]}
              />
            </div>
            <div className="form-container mb-4">
              <h6 className="mb-0">{i18n.t("start_date")}</h6>
              <ReactDatePicker
                wrapperClassName="w-100"
                selected={data.startDate}
                onChange={(e) => setData({ ...data, startDate: e })}
                value={data.startDate}
                maxDate={data.endDate}
                customInput={
                  <TextValidator
                    variant="outlined"
                    label={i18n.t("start_date")}
                    value={data.startDate || ""}
                    inputProps={{ readOnly: true }}
                  />
                }
                placeholderText={i18n.t("start_date")}
              />
            </div>
            <div className="form-container mb-4">
              <h6 className="mb-0">{i18n.t("end_date")}</h6>
              <ReactDatePicker
                wrapperClassName="w-100"
                selected={data.endDate}
                onChange={(e) => setData({ ...data, endDate: e })}
                value={data.endDate}
                minDate={data.startDate}
                customInput={
                  <TextValidator
                    variant="outlined"
                    label={i18n.t("end_date")}
                    value={data.endDate || ""}
                    inputProps={{ readOnly: true }}
                  />
                }
                placeholderText={i18n.t("end_date")}
              />
            </div>
            <div
              className={
                screenSize === "mobile"
                  ? "d-flex justify-content-center w-100 gap-2"
                  : "row justify-content-end"
              }
            >
              {screenSize === "mobile" && (
                <PrimaryButton
                  className="w-128"
                  onClick={() => setOpen(false)}
                  label={i18n.t("cancel")}
                />
              )}

              <PrimaryButton
                className="w-128"
                props={{
                  type: "submit",
                }}
                label={i18n.t("save")}
              />
            </div>
          </FormContainer>
        </ValidatorForm>
      }
    />
  )
}

export default TimePeriodUpsertPopup
