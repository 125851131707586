// @flow

import { CREATE_SALOON_PLAN, SALOON_LOGOUT } from "../ActionTypes"

export function request(payload) {
  return {
    payload,
    type: CREATE_SALOON_PLAN.REQUEST,
  }
}

export function success(data) {
  return {
    data,
    type: CREATE_SALOON_PLAN.SUCCESS,
  }
}

export function failure(errorMessage) {
  return {
    errorMessage,
    type: CREATE_SALOON_PLAN.FAILURE,
  }
}

export function logout() {
  return {
    type: SALOON_LOGOUT,
  }
}
