import React from "react";
import SocialMediaItem from "./SocialMediaItem";
import { Images } from "../../../theme";
import { SocialLinksWrapper } from "./styles";

export default function SocialMediaForm({
        socialLinks = {},
        handleChange = () => { },
}) {
        return (
                <SocialLinksWrapper>
                        <SocialMediaItem
                                icon={Images.saloon_social_facebook}
                                urlPlaceHolder="https://www.facebook.com/"
                                name="facebook"
                                handleChange={handleChange}
                                link={socialLinks.facebook}
                        />
                        <SocialMediaItem
                                icon={Images.saloon_social_instagram}
                                urlPlaceHolder="https://www.instagram.com/"
                                name="instagram"
                                handleChange={handleChange}
                                link={socialLinks.instagram}
                        />
                        <SocialMediaItem
                                icon={Images.saloon_social_twitter}
                                urlPlaceHolder="https://twitter.com/"
                                name="twitter"
                                handleChange={handleChange}
                                link={socialLinks.twitter}
                        />
                        <SocialMediaItem
                                icon={Images.saloon_social_youtube}
                                urlPlaceHolder="https://www.youtube.com/"
                                name="youtube"
                                handleChange={handleChange}
                                link={socialLinks.youtube}
                        />
                        <SocialMediaItem
                                icon={Images.saloon_social_snapchat}
                                urlPlaceHolder="https://www.snapchat.com/"
                                name="snapchat"
                                handleChange={handleChange}
                                link={socialLinks.snapchat}
                        />
                        <SocialMediaItem
                                icon={Images.saloon_social_linkedin}
                                urlPlaceHolder="https://www.linkedin.com/"
                                name="linkedin"
                                handleChange={handleChange}
                                link={socialLinks.linkedin}
                        />
                </SocialLinksWrapper>
        );
}
