import { put, takeEvery } from "redux-saga/effects"

import * as types from "../../actions/ActionTypes"
import {
  get,
  upsertEvent,
  removeEvent,
  requestFailure,
  applyTemplate,
  setCustomers,
  setAttendance,
  categoryReport,
  attendanceReport,
  getByCustomer,
  activityReport,
  getByActivityReference,
  getAvailableEvents,
  addTransaction,
  reverseTransaction,
} from "../../actions/Saloon/ActivityScheduleEvent"

import axios from "axios"
import { activity_schedule_event } from "../../../config/WebServices"
import fileDownload from "js-file-download"

function* getSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.get(activity_schedule_event, {
      params: payload,
    })
    data = data.data
    if (callbackMap.success && typeof callbackMap.success == "function") {
      callbackMap.success(data)
    }
    yield put(get(data.data))
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* upsertEventSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.post(`${activity_schedule_event}`, payload)
    data = data.data
    if (callbackMap.success && typeof callbackMap.success == "function") {
      callbackMap.success(data)
    }
    yield put(upsertEvent(data.data))
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* removeEventSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.delete(`${activity_schedule_event}`, {
      params: payload,
    })
    data = data.data
    if (callbackMap.success && typeof callbackMap.success == "function") {
      callbackMap.success(data)
    }
    yield put(removeEvent(data.data))
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* applyTemplateSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.post(`${activity_schedule_event}/applyTemplate`, payload)
    data = data.data
    if (callbackMap.success && typeof callbackMap.success == "function") {
      callbackMap.success(data)
    }
    yield put(applyTemplate(data.data))
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* setCustomerSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.post(`${activity_schedule_event}/setCustomers`, payload)
    data = data.data
    if (callbackMap.success && typeof callbackMap.success == "function") {
      callbackMap.success(data)
    }
    yield put(setCustomers(data.data))
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* setAttendanceSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.post(`${activity_schedule_event}/setAttendance`, payload)
    data = data.data
    if (callbackMap.success && typeof callbackMap.success == "function") {
      callbackMap.success(data)
    }
    yield put(setAttendance(data.data))
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* categoryReportSaga(action) {
  const { payload, callbackMap = {} } = action
  const additional = {}
  if (payload.exportToXlsx) {
    additional.responseType = "blob"
  }
  let data
  try {
    data = yield axios.get(`${activity_schedule_event}/categoryReport`, {
      params: payload,
      ...additional,
    })
    data = data.data
    if (callbackMap.success && typeof callbackMap.success == "function") {
      callbackMap.success(data)
    }
    if (payload.exportToXlsx) {
      fileDownload(data, "report.xlsx")
      yield put(requestFailure(data))
    } else {
      yield put(categoryReport(data.data))
    }
  } catch (err) {
    console.log(err)
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}
function* getByCustomerSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.get(`${activity_schedule_event}/getByCustomer`, {
      params: payload,
    })
    data = data.data
    if (callbackMap.success && typeof callbackMap.success == "function") {
      callbackMap.success(data)
    }
    yield put(getByCustomer(data.data))
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* getByActivityReferenceSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.get(
      `${activity_schedule_event}/getByActivityReference`,
      {
        params: payload,
      }
    )
    data = data.data
    if (callbackMap.success && typeof callbackMap.success == "function") {
      callbackMap.success(data)
    }
    yield put(getByActivityReference(data.data))
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}
function* getAvailableEventsSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.get(`${activity_schedule_event}/getAvailableEvents`, {
      params: payload,
    })
    data = data.data
    if (callbackMap.success && typeof callbackMap.success == "function") {
      callbackMap.success(data)
    }
    yield put(getAvailableEvents(data.data))
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}
function* attendanceReportSaga(action) {
  const { payload, callbackMap = {} } = action
  const additional = {}
  if (payload.exportToXlsx) {
    additional.responseType = "blob"
  }
  let data
  try {
    data = yield axios.get(`${activity_schedule_event}/attendanceReport`, {
      params: payload,
      ...additional,
    })
    data = data.data
    if (callbackMap.success && typeof callbackMap.success == "function") {
      callbackMap.success(data)
    }
    if (payload.exportToXlsx) {
      fileDownload(data, "report.xlsx")
      yield put(requestFailure(data))
    } else {
      yield put(attendanceReport(data.data))
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}
function* activityReportSaga(action) {
  const { payload, callbackMap = {} } = action
  const additional = {}
  if (payload.exportToXlsx) {
    additional.responseType = "blob"
  }
  let data
  try {
    data = yield axios.get(`${activity_schedule_event}/activityReport`, {
      params: payload,
      ...additional,
    })
    data = data.data
    if (callbackMap.success && typeof callbackMap.success == "function") {
      callbackMap.success(data)
    }
    if (payload.exportToXlsx) {
      fileDownload(data, "report.xlsx")
      yield put(requestFailure(data))
    } else {
      yield put(activityReport(data.data))
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* addTransactionSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.post(
      `${activity_schedule_event}/addTransaction`,
      payload
    )
    data = data.data

    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data)
      }
      yield put(addTransaction(data.data))
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data)
      }
      yield put(requestFailure(data))
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* reverseTransactionSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.post(
      `${activity_schedule_event}/reverseTransaction`,
      payload
    )
    data = data.data
    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data)
      }
      yield put(reverseTransaction(data.data))
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data)
      }
      yield put(requestFailure(data))
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

export default function* root() {
  yield takeEvery(types.ACTIVITY_SCHEDULE_EVENT.GET_SAGA, getSaga)
  yield takeEvery(
    types.ACTIVITY_SCHEDULE_EVENT.APPLY_TEMPLATE_SAGA,
    applyTemplateSaga
  )
  yield takeEvery(
    types.ACTIVITY_SCHEDULE_EVENT.UPSERT_EVENT_SAGA,
    upsertEventSaga
  )
  yield takeEvery(
    types.ACTIVITY_SCHEDULE_EVENT.DELETE_EVENT_SAGA,
    removeEventSaga
  )
  yield takeEvery(
    types.ACTIVITY_SCHEDULE_EVENT.SET_CUSTOMERS_SAGA,
    setCustomerSaga
  )
  yield takeEvery(
    types.ACTIVITY_SCHEDULE_EVENT.SET_ATTENDANCE_SAGA,
    setAttendanceSaga
  )
  yield takeEvery(
    types.ACTIVITY_SCHEDULE_EVENT.CATEGORY_REPORT_SAGA,
    categoryReportSaga
  )
  yield takeEvery(
    types.ACTIVITY_SCHEDULE_EVENT.ATTENDANCE_REPORT_SAGA,
    attendanceReportSaga
  )
  yield takeEvery(
    types.ACTIVITY_SCHEDULE_EVENT.ACTIVITY_REPORT_SAGA,
    activityReportSaga
  )
  yield takeEvery(
    types.ACTIVITY_SCHEDULE_EVENT.GET_BY_CUSTOMER_SAGA,
    getByCustomerSaga
  )
  yield takeEvery(
    types.ACTIVITY_SCHEDULE_EVENT.GET_BY_ACTIVITY_REFERENCE_SAGA,
    getByActivityReferenceSaga
  )
  yield takeEvery(
    types.ACTIVITY_SCHEDULE_EVENT.GET_AVAILABLE_EVENTS_SAGA,
    getAvailableEventsSaga
  )
  yield takeEvery(
    types.ACTIVITY_SCHEDULE_EVENT.ADD_TRANSACTION_SAGA,
    addTransactionSaga
  )
  yield takeEvery(
    types.ACTIVITY_SCHEDULE_EVENT.REVERSE_TRANSACTION_SAGA,
    reverseTransactionSaga
  )
}
