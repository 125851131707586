import { take, put, call, fork } from "redux-saga/effects"

import { get_availables_spots } from "../../config/WebServices"
import axios from "axios"
import * as types from "../actions/ActionTypes"

import { success, failure } from "../actions/GetAvailableTimeslots"

import { ErrorHelper } from "../../helpers"

async function callRequest(params) {
  return new Promise((resolve, reject) => {
    axios
      .get(get_availables_spots, {
        params,
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response.data)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

function* watchRequest() {
  while (true) {
    const e = yield take(types.GETAVAILABLETIMESLOTS.REQUEST)
    const {
      payload: { data, resolve },
      index,
    } = e

    // const { targetView } = payload;
    // delete payload.targetView;
    try {
      const response = yield call(callRequest, data)
      if (resolve) {
        resolve(response)
      }
      yield put(success(response, index))
    } catch (err) {
      yield put(failure(err))
      ErrorHelper.handleErrors(err, true)
    }
  }
}

export default function* root() {
  yield fork(watchRequest)
}
