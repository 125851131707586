import React, { useEffect, useMemo } from "react"
import AppBar from "@material-ui/core/AppBar"
import MuiTabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Box from "@material-ui/core/Box"
import { makeStyles, Paper } from "@material-ui/core"
import useIntakeCustomer from "../../../context/IntakeCustomer/IntakeCustomer"
import useScreenSize from "../../../context/ScreenSize"
const useStyles = makeStyles(() => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  root: {
    flexGrow: 1,
    width: "100%",
  },
  tabsBar: {
    "& .MuiTabs-indicator": {
      // display: "none",
      backgroundColor: "orange",
    },
  },
}))

function TabPanel(props) {
  const { key, children, value, index, styles, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Paper variant="outlined">
          <Box className={styles} key={key} p={1}>
            {children}
          </Box>
        </Paper>
      )}
    </div>
  )
}

export default function Tabs({
  tabs,
  visible,
  openTab,
  setfilteredNotes,
  styles,
  activeTab = () => {},
}) {
  const classes = useStyles()
  const [currentTab, setTab] = React.useState(0)
  const { activityTooltips, setActivityTooltips } = useIntakeCustomer()
  const { screenSize } = useScreenSize()
  const handleChange = (event, newValue) => {
    if (activityTooltips) {
      Object.keys(activityTooltips).map(
        (tooltipId) => (activityTooltips[tooltipId] = false)
      )
      setActivityTooltips({ ...activityTooltips })
    }

    setTab(newValue)
    activeTab(newValue)
    if (currentTab != newValue && setfilteredNotes) setfilteredNotes(undefined)
  }

  const _tabs = useMemo(() => {
    return tabs.filter((tab) => !tab.hidden)
  }, [tabs])
  useEffect(() => {
    const index = _tabs.findIndex((tab) => {
      return tab.title === openTab
    })
    setTab(index != -1 ? index : 0)
  }, [visible, openTab])
  return (
    <div className={classes.root}>
      <AppBar
        position={screenSize === "mobile" ? "sticky" : "static"}
        className="custom-tab bg-white"
        style={screenSize === "mobile" ? { top: "-6px" } : null}
      >
        <MuiTabs
          className={classes.tabsBar}
          value={currentTab}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          {_tabs.map((tab, index) => {
            if (tab.hidden) {
              return null
            }
            return (
              <Tab
                key={tab.title + index}
                label={tab.title}
                disabled={tab.disabled}
              />
            )
          })}
        </MuiTabs>
      </AppBar>
      {_tabs.map((tab, index) => {
        return (
          <TabPanel
            key={tab.title + index}
            value={currentTab}
            index={index}
            styles={styles}
          >
            {tab.content ? tab.content() : null}
          </TabPanel>
        )
      })}
    </div>
  )
}
