import React, { useState, useCallback, useMemo, useEffect, useRef } from "react"
import { useSelector } from "react-redux"
import FullCalendar, { formatDate } from "@fullcalendar/react"
import timeGridPlugin from "@fullcalendar/timegrid"
import scrollGridPlugin from "@fullcalendar/scrollgrid"
import interactionPlugin from "@fullcalendar/interaction"
import EmojiFlagsSharpIcon from "@material-ui/icons/EmojiFlagsSharp"
import "./PlanView.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn"
import PlanCalendarVerticalView from "./PlanCalendarVerticalView"
import "./PlanCalendar.scss"
import { Description } from "@material-ui/icons"
import useStaffMeeting from "../../../context/StaffMeeting/StaffMeeting"
import dayGridPlugin, { DayGridView } from "@fullcalendar/daygrid"
import useGoogleCalendar from "../../../context/GoogleCalendar"
import useOutlookCalendar from "../../../context/OutlookCalendar"
import BaseModal from "../../BaseModal"
import i18n from "../../../i18n"
import useScreenSize from "../../../context/ScreenSize"
const PlanCalendar = ({
  calView,
  calDate,
  planData,
  onEventClick = () => {},
  start,
  onDateClick,
  onDismiss,
  employees,
}) => {
  const calendarRef = useRef(null)
  const { setViewId } = useStaffMeeting()
  const { googleEvents, setViewGoogleEvent, isGoogleChecked } =
    useGoogleCalendar()
  const { outlookEvents, setViewOutlookEvent, isOutlookChecked } =
    useOutlookCalendar()
  const [visibleWeekEnds, setVisibleWeekends] = useState(true)
  const [unavailabilityNote, setUnavailabilityNote] = useState("")
  const selectEvent = useCallback(
    (event) => {
      const props = event.event._def.extendedProps
      if (props.bookingType == "Staff Meeting") {
        return setViewId(props.staffMeetingId)
      }
      if (props.type == "booking") {
        onEventClick(event.event._def.extendedProps)
      }
      if (props.type == "googleCalendar") {
        setViewGoogleEvent(props)
      }
      if (props.type == "outlookCalendar") {
        setViewOutlookEvent(props)
      }
      if (props.type == "unavailability" && props.note) {
        setUnavailabilityNote(props.note)
      }
    },
    [planData]
  )

  const { screenSize } = useScreenSize()
  planData = planData.reduce((acc, curVal, index, src) => {
    if (!curVal.staffMeetingId) {
      acc.push(curVal)
    } else if (!acc.find((y) => y.staffMeetingId == curVal.staffMeetingId)) {
      const names = src
        .filter((data) => data.staffMeetingId == curVal.staffMeetingId)
        .map((x) => x.name)
      curVal.names = names.join(", ")
      acc.push(curVal)
    }
    return acc
  }, [])

  const renderEventContent = useCallback((eventInfo) => {
    const { timeText, event, view } = eventInfo
    const isInSalon = event._def.extendedProps.isInSalon
    const isConfirmed = event._def.extendedProps.isConfirmed
    const transferValidated = event._def.extendedProps.transferValidated
    const status = event._def.extendedProps.status
    const index = event._def.extendedProps.index
    const _id = event._def.extendedProps._id
    const employeeName =
      event._def.extendedProps.employeeName || event._def.extendedProps.names
    const { promotionId, commMarketingCampaignId, type, note } =
      event._def.extendedProps
    const indicatorStyles = {
      backgroundColor: eventInfo.textColor,
      color: eventInfo.backgroundColor,
    }

    const monthViewStyles = {
      backgroundColor:
        event.backgroundColor != "null" ? event.backgroundColor : "#3788d8",
      color: event.textColor ? event.textColor : "white",
      fontSize: "0.8rem",
      whiteSpace: "normal",
    }
    const monthViewPosition = view.type == "dayGridMonth" ? "0" : ""
    return (
      <>
        {isInSalon && status == 1 ? (
          <div className="flag-wrapper">
            <EmojiFlagsSharpIcon
              className="flag-icon"
              color="white"
              icon={["fas", "flag"]}
            />
          </div>
        ) : null}
        {isConfirmed && status != 3 ? (
          <FontAwesomeIcon
            className="icon"
            color="green"
            icon={["fas", "check-circle"]}
            style={{
              backgroundColor: "white",
              borderRadius: "200px",
              position: "absolute",
              right: monthViewPosition,
              top: monthViewPosition,
            }}
            size="lg"
          />
        ) : null}
        {transferValidated && !isInSalon && status == 1 ? (
          <MonetizationOnIcon
            className="money-icon"
            // style={{ fill: event.textColor }}
          />
        ) : null}

        {event._def.extendedProps.notes?.length ||
        event._def.extendedProps.reminders?.length ? (
          <Description
            className="notes-icon"
            style={{
              color: "white",
              right: monthViewPosition,
              bottom: monthViewPosition,
            }}
          />
        ) : null}
        {type == "outlookCalendar" ? (
          <FontAwesomeIcon
            icon={["fab", "microsoft"]}
            className="notes-icon"
            style={{
              color: "white",
              backgroundColor: "#7FBA00",
              padding: "3px",
              right: monthViewPosition,
              bottom: monthViewPosition,
              width: view.type == "dayGridMonth" ? "2em" : "",
            }}
          />
        ) : null}
        {type == "googleCalendar" ? (
          <FontAwesomeIcon
            icon={["fab", "google"]}
            className="notes-icon"
            style={{
              color: "white",
              backgroundColor: "#4285F4",
              padding: "3px",
              right: monthViewPosition,
              bottom: monthViewPosition,
              width: view.type == "dayGridMonth" ? "2em" : "",
            }}
          />
        ) : null}
        <div
          className={`text-wrapper w-100 ${
            view.type == "dayGridMonth" ? "p-1" : ""
          } ${
            type === "unavailability" && view.type == "dayGridMonth"
              ? "unavailability-event"
              : ""
          }`}
          style={monthViewStyles}
        >
          <b>{timeText}</b>&nbsp;
          <i>{event.title}</i>
          {employeeName ? (
            <>
              <br />
              <b>{employeeName}</b>
            </>
          ) : null}
          {note ? (
            <Description
              className="notes-icon"
              style={{
                color: "white",
                right: monthViewPosition,
                bottom: monthViewPosition,
              }}
            />
          ) : null}
          <div className="row gap-1 mt-1">
            {!!commMarketingCampaignId && (
              <div className="indicator" style={indicatorStyles}>
                <b>C</b>
              </div>
            )}
            {!!promotionId && (
              <div className="indicator" style={indicatorStyles}>
                <b>P</b>
              </div>
            )}
          </div>
        </div>
      </>
    )
  }, [])

  useEffect(() => {
    setVisibleWeekends(true)
    if (calView === "timeGridWorkWeek") {
      calView = "timeGridWeek"
      setVisibleWeekends(false)
    }
    calendarRef.current.getApi().changeView(calView, calDate)
  })

  // Hacky way to force full calendar to re render
  const { isDocked, isOpen } = useSelector((state) => ({
    isDocked: state.sidebar.isDocked,
    isOpen: state.sidebar.isOpen,
  }))
  useEffect(() => {
    setTimeout(() => {
      if (calendarRef.current) {
        calendarRef.current.getApi().changeView(calView, calDate)
      }
    }, 300)
  }, [isDocked, isOpen])
  let slotMinTime = `${start ? start : "00"}:00:00`

  // Add events from Google Calendar if it is checked in the filters
  if (isGoogleChecked) planData = [...planData, ...googleEvents]

  // Add events from Outlook Calendar if it is checked in the filters
  if (isOutlookChecked) planData = [...planData, ...outlookEvents]
  return (
    <div className="plan-calendar">
      <BaseModal
        open={!!unavailabilityNote}
        onClose={() => setUnavailabilityNote("")}
        title={i18n.t("note")}
        content={
          <div style={{ fontSize: "1rem" }} className="m-1">
            {unavailabilityNote}
          </div>
        }
      />
      {calView == "resourceTimeGrid" ? (
        <PlanCalendarVerticalView
          planData={planData}
          start={start}
          onDateClick={onDateClick}
          renderEventContent={renderEventContent}
          selectEvent={selectEvent}
          visibleWeekEnds={visibleWeekEnds}
          calendarRef={calendarRef}
          employees={employees}
        />
      ) : (
        <FullCalendar
          locale="en"
          dayMaxEvent={true}
          dayMaxEvents={3}
          plugins={[
            timeGridPlugin,
            interactionPlugin,
            dayGridPlugin,
            scrollGridPlugin,
          ]}
          initialView="timeGridWeek"
          events={planData}
          headerToolbar={false}
          stickyFooterScrollbar={true}
          dayMinWidth={screenSize === "mobile" ? 150 : undefined}
          weekends={visibleWeekEnds}
          slotEventOverlap={false}
          eventClick={selectEvent}
          eventContent={renderEventContent}
          expandRows={true}
          contentHeight={
            calendarRef.current?._calendarApi.currentDataManager.data
              .currentViewType == "dayGridMonth"
              ? null
              : 2000
          }
          slotMinTime={slotMinTime}
          ref={calendarRef}
          allDaySlot={false}
          eventTimeFormat={{
            hour: "numeric",
            minute: "2-digit",
            meridiem: "short",
          }}
          displayEventEnd={{ month: true }}
          stickyHeaderDates={true}
          slotDuration={"00:15:00"}
          dateClick={({ date }) => onDateClick(date)}
        />
      )}
    </div>
  )
}

export default PlanCalendar
