import { take, put, call, fork } from "redux-saga/effects"

import * as types from "../../actions/ActionTypes"
import { success, failure } from "../../actions/Saloon/ImpersonateSalon"
import { set } from "../../actions/User"

import axios from "axios"
import { impersonation } from "../../../config/WebServices"
import { ErrorHelper } from "../../../helpers"
import { ROLES } from "../../../util/enum"
function callRequest(data) {
  return axios.get(impersonation, {
    params: {
      id: data,
      type: "salon",
    },
  })
}

function* watchRequest() {
  while (true) {
    const { payload } = yield take(types.IMPERSONATE_SALON.REQUEST)
    try {
      const { data } = yield call(callRequest, payload)
      if (data.success) {
        const copy = { ...data.data }
        const { company } = copy
        delete copy.company
        const toSet = {}
        if (copy.role.includes(ROLES.OWNER)) {
          toSet.employee = JSON.stringify(copy)
        }
        yield put(
          set({
            ...toSet,
            access_token: copy.access_token,
            user: JSON.stringify(copy),
            saloon: JSON.stringify(copy),
            company: JSON.stringify(company),
          })
        )
      }
      yield put(success(data))
    } catch (err) {
      yield put(failure(err))
      ErrorHelper.handleErrors(err, true)
    }
  }
}

export default function* root() {
  yield fork(watchRequest)
}
