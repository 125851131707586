import React, { useState } from "react"
import TextField from "@material-ui/core/TextField"
import i18n from "../../i18n"
import InputAdornment from "@material-ui/core/InputAdornment"

export const validateAndRound = (value, max) => {
  let num = parseFloat(value)

  if (isNaN(num) || num < 0) {
    num = 0
  }

  if (max && num > max) {
    num = parseFloat(max)
  }

  num = Math.round((num + Number.EPSILON) * 100) / 100

  return num
}

const DollarInput = ({
  value,
  onChange = () => {},
  error,
  readOnly,
  label,
  max,
  type = "number",
}) => {
  const handleChange = (text) => {
    onChange(validateAndRound(text.target.value, max).toString())
  }
  const handleBlur = (text) => {
    onChange(validateAndRound(text.target.value, max).toString())
  }
  return (
    <TextField
      type={type}
      variant="outlined"
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={(e) => {
        if (!readOnly) {
          e.target.select()
        }
      }}
      label={label}
      value={value}
      InputProps={{
        readOnly,
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
      helperText={error}
      error={Boolean(error)}
    />
  )
}

export default DollarInput
