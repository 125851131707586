import { customer_status } from "../../../config/WebServices"
import SagaBuilder from "../../builders/SagaBuilder"
import actions from "../../actions/Saloon/CustomerStatus"

const customerStatus = SagaBuilder.build({
  type: "CUSTOMER_STATUS",
  endpoint: customer_status,
  actions,
})

export default customerStatus
