import React, { useEffect, useMemo, useState } from "react"
import i18n from "../../../i18n"
import BaseModal from "../../BaseModal"
import TextField from "@material-ui/core/TextField"
import { SuccessHelper, ErrorHelper } from "../../../helpers"
import PrimaryButton from "../../Core/PrimaryButton"
import { Button, Checkbox } from "@material-ui/core"
import Tabs from "../../Core/Tabs/Tabs"
import TipKeyPad from "./TipKeyPad"
import DiscountKeyPad from "./DiscountKeyPad"
import CollectKeyPad from "./CollectKeyPad"
import BasicTable from "../../Core/BasicTable"
import useScreenSize from "../../../context/ScreenSize"
const roundTo2Decimals = (x) => Math.round((x + Number.EPSILON) * 100) / 100

const InsertPayment = ({
  open,
  onSubmit,
  handleClose,
  toggleTaxes = () => {},
  items = [],
  subtotal,
  taxDue,
  includeTaxes,
  grandTotal,
  collected,
  giftCardBalance,
  customer,
  type,
  customerId,
}) => {
  const [amount, setAmount] = useState(0)
  const [balanceDue, setBalanceDue] = useState(0)
  const [tip, setTip] = useState(0)
  const [discountPercent, setDiscountPercent] = useState(0)
  const [discountAmount, setDiscountAmount] = useState(0)
  const [collectionEntries, setCollectionEntries] = useState([])
  const [giftCardRemaining, setGiftCardRemaining] = useState(0)
  const { screenSize } = useScreenSize()
  const toCollect = useMemo(() => {
    return collectionEntries.reduce(
      (accum, element) => accum + element.amount,
      0
    )
  }, [collectionEntries])

  useEffect(() => {
    setGiftCardRemaining(giftCardBalance)
  }, [giftCardBalance])
  useEffect(() => {
    const newTotal = roundTo2Decimals(
      grandTotal + parseFloat(tip || 0) - parseFloat(discountAmount || 0)
    )
    setAmount(newTotal)
    setBalanceDue(newTotal)
  }, [grandTotal, tip, discountAmount])
  const [note, setNote] = useState("")
  const [formErrors, setFormErrors] = useState("")
  const [tab, setTab] = useState(0)
  const difference = roundTo2Decimals(balanceDue - toCollect - discountAmount)

  const resetFields = () => {
    setTip(0)
    setDiscountAmount(0)
    setCollectionEntries([])
    setDiscountPercent(0)
    setAmount(0)
    setNote("")
    setFormErrors("")
    setTab(0)
    setBalanceDue(0)
  }
  const validate = () => {
    let hasErrors = false
    let newFormErrors = {}

    if (!amount && amount !== 0) {
      newFormErrors.amount = i18n.t("amount_is_required")
      hasErrors = true
    }
    if ((type == "gift_card" || type == "product") && difference > 0) {
      ErrorHelper.handleErrors(
        i18n.t("must_be_greater_or_equal", {
          amount: balanceDue,
        }),
        true
      )
      hasErrors = true
    }
    if (hasErrors) {
      setFormErrors(newFormErrors)
      setTimeout(() => {
        setFormErrors({})
      }, 6000)
    } else {
      let total = discountAmount || 0
      let cash = 0
      let creditDebit = 0
      let giftCard = 0
      let chargedAmountByCard = []
      collectionEntries.map((entry) => {
        let amount = entry.amount
        if (total >= balanceDue) {
          total += amount
          return
        }
        total += amount
        if (total > balanceDue) {
          amount = amount - (total - balanceDue)
        }
        if (entry.method == "cash") {
          cash += amount
        } else if (entry.method == "gift_card") {
          giftCard += amount
        } else {
          if (entry.cardId) {
            chargedAmountByCard.push({
              amount: amount,
              cardId: entry.cardId,
            })
          }

          creditDebit += amount
        }
      })
      const changeDue = roundTo2Decimals(total - balanceDue)
      if (changeDue > 0) {
        SuccessHelper.handleSuccess(
          i18n.t("change_due_prompt", { amount: changeDue }),
          true
        )
      }

      const data = {
        amount,
        note,
        cash: roundTo2Decimals(cash),
        creditDebit: roundTo2Decimals(creditDebit),
        giftCard: roundTo2Decimals(giftCard),
        tip,
        discount: discountAmount,
      }
      if (chargedAmountByCard.length > 0) {
        data.chargedAmountByCard = chargedAmountByCard
      }
      onSubmit(data)
      if (customer) customer.giftCardBalance = giftCardRemaining
    }
  }
  const colClass = "col-md-6 col-xs-12"
  return (
    <BaseModal
      open={open}
      containerClassName={"container"}
      showCloseBtn={screenSize !== "mobile" ? true : false}
      {...{
        title: (
          <div>
            {`${i18n.t("amount_to_be_paid")} ($ ${balanceDue.toFixed(2)})`}
          </div>
        ),
        content: (
          <div>
            <div className="row mx-0">
              <div className={colClass}>
                <Tabs
                  openTab={!open && 0}
                  activeTab={(value) => setTab(value)}
                  tabs={[
                    {
                      title: i18n.t("collect"),
                      value: "collect",
                      content: () => {
                        return (
                          <div>
                            <CollectKeyPad
                              open={open}
                              giftCardRemaining={giftCardRemaining}
                              setGiftCardRemaining={setGiftCardRemaining}
                              customerId={customerId || customer?._id}
                              onSubmit={setCollectionEntries}
                            />
                            {collectionEntries.length ? (
                              <div className="mt-2">
                                <BasicTable
                                  maxContentWidth={false}
                                  rows={collectionEntries}
                                  schema={[
                                    {
                                      header: i18n.t("method"),
                                      field: "method",
                                      render: (row, index) => {
                                        return <div>{i18n.t(row.method)}</div>
                                      },
                                    },
                                    {
                                      header: i18n.t("amount"),
                                      field: "amount",
                                      render: (row, index) => {
                                        return <div>$ {row.amount}</div>
                                      },
                                    },
                                    {
                                      field: "actions",
                                      style: { width: 80 },
                                      render: (row, index) => {
                                        return (
                                          <PrimaryButton
                                            label={i18n.t("delete")}
                                            onClick={() => {
                                              const newEntries = [
                                                ...collectionEntries,
                                              ]
                                              if (
                                                newEntries[index].method ==
                                                "gift_card"
                                              ) {
                                                setGiftCardRemaining(
                                                  giftCardRemaining +
                                                    newEntries[index].amount
                                                )
                                              }
                                              newEntries.splice(index, 1)
                                              setCollectionEntries(newEntries)
                                            }}
                                          />
                                        )
                                      },
                                    },
                                  ]}
                                />
                              </div>
                            ) : null}
                          </div>
                        )
                      },
                    },
                    {
                      title: i18n.t("tip"),
                      value: "tip",
                      content: () => {
                        const handleTipPercent = (percent) => {
                          setTip(roundTo2Decimals((percent / 100) * subtotal))
                        }
                        return (
                          <TipKeyPad
                            handleTipPercent={handleTipPercent}
                            value={tip}
                            setValue={setTip}
                          />
                        )
                      },
                    },
                    {
                      title: i18n.t("discount"),
                      value: "discount",
                      content: () => {
                        return (
                          <DiscountKeyPad
                            dollarAmount={discountAmount}
                            onChangeDollarAmount={(value) => {
                              setDiscountAmount(value)
                              setDiscountPercent(
                                roundTo2Decimals((100 * value) / balanceDue)
                              )
                            }}
                            percentAmount={discountPercent}
                            onChangePercentAmount={(percent) => {
                              setDiscountPercent(percent)
                              setDiscountAmount(
                                roundTo2Decimals((balanceDue * percent) / 100)
                              )
                            }}
                          />
                        )
                      },
                    },
                  ]}
                />
                <div className="row mb-3">
                  <TextField
                    value={note}
                    label={i18n.t("note")}
                    onChange={(e) => setNote(e.target.value)}
                    helperText={formErrors.note}
                    error={Boolean(formErrors.note)}
                    variant="outlined"
                  />
                </div>
              </div>
              <div
                className={`${colClass} row flex-column justify-content-between transaction-details-body insert-payment py-3`}
              >
                <div>
                  <div className="row transaction-details-header">
                    <h4 className="font-weight-bold">
                      {i18n.t("transaction_details")}
                    </h4>
                  </div>
                  <table className="w-100 px-2 transaction-details-table">
                    <tbody>
                      {items.map((item, index) => {
                        return (
                          <tr className="summary-row" key={index}>
                            <td className="name" colSpan={2}>
                              {item.name}
                            </td>
                            <td />
                            <td className="money-value">
                              $ {item.price.toFixed(2)}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
                <div>
                  <table className="w-100 px-2 transaction-details-table">
                    <tr className="summary-row">
                      <td colSpan={2}>{i18n.t("subtotal")}</td>
                      <td />
                      <td className="money-value">${subtotal.toFixed(2)}</td>
                    </tr>
                    {taxDue ? (
                      <tr>
                        <td colSpan={2}>
                          <Checkbox
                            className="p-0 pr-1"
                            disabled={Boolean(collected)}
                            onChange={() => toggleTaxes(!includeTaxes)}
                            checked={Boolean(includeTaxes)}
                            size="medium"
                          />
                          {i18n.t("tax_total")}
                        </td>
                        <td />
                        <td className="money-value">${taxDue.toFixed(2)}</td>
                      </tr>
                    ) : null}
                    {tip ? (
                      <tr>
                        <td colSpan={2}>{i18n.t("tip")}</td>
                        <td />
                        <td className="money-value">
                          ${(tip || 0).toFixed(2)}
                        </td>
                      </tr>
                    ) : null}
                    {discountAmount ? (
                      <tr>
                        <td colSpan={2}>{i18n.t("discount")}</td>
                        <td />
                        <td className="money-value">
                          $-{(discountAmount || 0).toFixed(2)}
                        </td>
                      </tr>
                    ) : null}
                    {collected ? (
                      <tr>
                        <td colSpan={2}>{i18n.t("collected")}</td>
                        <td />
                        <td className="money-value">
                          $ -{collected.toFixed(2)}
                        </td>
                      </tr>
                    ) : null}

                    {grandTotal ? (
                      <tr className="summary-row">
                        <td colSpan={2}>{i18n.t("grand_total")}</td>
                        <td />
                        <td className="money-value">
                          $
                          {(
                            grandTotal +
                            (tip || 0) -
                            (discountAmount || 0)
                          ).toFixed(2)}
                        </td>
                      </tr>
                    ) : null}

                    {toCollect ? (
                      <>
                        <tr className="summary-row">
                          <td colSpan={2}>{i18n.t("to_collect")}</td>
                          <td />
                          <td className="money-value">
                            $ -{toCollect.toFixed(2)}
                          </td>
                        </tr>
                        <tr className="summary-row">
                          <td colSpan={2}>{i18n.t("remaining")}</td>
                          <td />
                          <td className="money-value">
                            $ {(balanceDue - toCollect).toFixed(2)}
                          </td>
                        </tr>
                      </>
                    ) : null}
                  </table>
                </div>
              </div>
            </div>
          </div>
        ),
        actions: (
          <div className="d-flex justify-content-center mb-3 gap-2 justify-content-md-end">
            {screenSize === "mobile" ? (
              <PrimaryButton
                className="w-128"
                onClick={() => {
                  handleClose()
                  resetFields()
                }}
              >
                {i18n.t("close")}
              </PrimaryButton>
            ) : null}
            <PrimaryButton
              className="w-128 mr-3 "
              onClick={() => {
                validate()
              }}
            >
              {i18n.t("apply")}
            </PrimaryButton>
          </div>
        ),
      }}
      onClose={() => {
        handleClose()
        resetFields()
      }}
    />
  )
}

export default InsertPayment
