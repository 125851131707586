import React, { useEffect, useMemo, useRef, useState } from "react"
import {
  SelectValidator,
  TextValidator,
  ValidatorForm,
} from "react-material-ui-form-validator"
import MenuItem from "@material-ui/core/MenuItem"
import i18n from "../../../../i18n"
import { Autocomplete } from "@material-ui/lab"
import { useSelector } from "react-redux"
import moment from "moment"
import { daysOfWeek } from "../ActivityScheduleTemplates"
import { BaseModal } from "../../../../components"
import PrimaryButton from "../../../../components/Core/PrimaryButton"

export default function AddScheduleEvent({
  open,
  setOpen,
  employees,
  defaults = {},
  onAdd,
  onDelete,
  active = {},
}) {
  const form = useRef()
  const [data, setData] = useState({})
  const activities = useSelector((s) => s.activity.data)
  const [companyEmployee, setCompanyEmployee] = useState()
  const employeesMap = useMemo(() => {
    const map = employees.map((employee) => {
      return {
        value: employee._id,
        name: `${employee.employee.userId.firstName || ""} ${
          employee.employee.userId.lastName || ""
        }`.trim(),
        type: employee.isInstructor ? i18n.t("instructor") : i18n.t("employee"),
      }
    })
    map.sort((a, b) => (a.type > b.type ? 1 : -1))
    return map
  }, [employees])
  useEffect(() => {
    if (open) {
      setData({
        ...active,
      })
      setCompanyEmployee(
        employeesMap.find(
          (employee) => employee.value == active.companyEmployeeId
        )
      )
    }
  }, [open, active._id])

  const handleAdd = () => {
    onAdd(data)
  }

  const checkTimes = () => {
    const { startTime, endTime } = data
    if (startTime && endTime) {
      const momentStart = moment(startTime, "hh:mm")
      const momentEnd = moment(endTime, "hh:mm")
      if (momentStart.isAfter(momentEnd)) {
        setData({ ...data, startTime: endTime, endTime: startTime })
      }
    }
  }
  useEffect(checkTimes, [data.startTime, data.endTime])
  useEffect(() => {
    setData({
      ...defaults,
      daysOfWeek: defaults.dayOfWeek && [defaults.dayOfWeek],
    })
  }, [open])
  return (
    <BaseModal
      open={open}
      containerClassName={"half-container"}
      contentContainerStyle={{
        overflowY: "visible",
      }}
      title={
        data._id ? i18n.t("edit_template_event") : i18n.t("add_template_event")
      }
      onClose={() => setOpen(false)}
      content={
        <div className="row mx-0 ScheduleBuilder">
          <ValidatorForm
            onSubmit={handleAdd}
            ref={form}
            className="row col-12 px-0 mx-0"
            onError={(error) => {
              console.log(error)
            }}
          >
            <div className="col-12 flex-column flex-md-row d-flex align-items-md-center mb-3 px-0">
              <label className="font-weight-bold" style={{ minWidth: "120px" }}>
                {i18n.t("employee")}
              </label>
              <div className="w-100">
                <Autocomplete
                  id="employees"
                  className="w-100"
                  options={employeesMap}
                  groupBy={(option) => option.type}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label={i18n.t("employees")}
                      value={companyEmployee}
                      validators={["required"]}
                      errorMessages={[i18n.t("this_field_is_required")]}
                      variant="outlined"
                    />
                  )}
                  onChange={(e, value) => {
                    setData({
                      ...data,
                      companyEmployeeId: value?.value,
                    })
                    setCompanyEmployee(value)
                  }}
                  value={companyEmployee || ""}
                />
              </div>
            </div>
            <div className="col-12 flex-column flex-md-row d-flex align-items-md-center mb-3 px-0">
              <label className="font-weight-bold" style={{ minWidth: "120px" }}>
                {i18n.t("activity")}
              </label>
              <SelectValidator
                containerProps={{ className: "w-100" }}
                label={i18n.t("activity")}
                onChange={(e) => {
                  setData({
                    ...data,
                    activityId: e.target.value,
                  })
                }}
                value={data.activityId || ""}
                select
                validators={["required"]}
                errorMessages={[i18n.t("this_field_is_required")]}
                variant="outlined"
              >
                {activities.map((activity) => {
                  return (
                    <MenuItem value={activity._id}>{activity.name}</MenuItem>
                  )
                })}
              </SelectValidator>
            </div>
            {data._id ? (
              <div className="col-12 flex-column flex-md-row d-flex align-items-md-center mb-3 px-0">
                <label
                  className="font-weight-bold"
                  style={{ minWidth: "120px" }}
                >
                  {i18n.t("day_of_week")}
                </label>
                <SelectValidator
                  containerProps={{ className: "w-100" }}
                  label={i18n.t("day_of_week")}
                  onChange={(e) => {
                    setData({
                      ...data,
                      dayOfWeek: e.target.value,
                    })
                  }}
                  value={data.dayOfWeek || ""}
                  select
                  validators={["required"]}
                  errorMessages={[i18n.t("this_field_is_required")]}
                  variant="outlined"
                >
                  {daysOfWeek.map((dayOfWeek) => {
                    return (
                      <MenuItem value={dayOfWeek}>{i18n.t(dayOfWeek)}</MenuItem>
                    )
                  })}
                </SelectValidator>
              </div>
            ) : (
              <div className="col-12 flex-column flex-md-row d-flex align-items-md-center mb-3 px-0">
                <label
                  className="font-weight-bold"
                  style={{ minWidth: "120px" }}
                >
                  {i18n.t("days_of_week")}
                </label>
                <div className="w-100">
                  <Autocomplete
                    multiple
                    options={daysOfWeek}
                    value={data.daysOfWeek || []}
                    onChange={(e, value = []) => {
                      setData({ ...data, daysOfWeek: value })
                    }}
                    disableCloseOnSelect={true}
                    renderInput={(params) => {
                      return (
                        <TextValidator
                          {...params}
                          value={data.daysOfWeek}
                          label={i18n.t("days_of_week")}
                          validators={["required", "hasArrayElements"]}
                          errorMessages={[
                            i18n.t("this_field_is_required"),
                            i18n.t("select_atleast_1_item"),
                          ]}
                          variant="outlined"
                        />
                      )
                    }}
                  />
                </div>
              </div>
            )}
            <div className="col-12 flex-column flex-md-row d-flex align-items-md-center mb-3 px-0">
              <label className="font-weight-bold" style={{ minWidth: "120px" }}>
                {i18n.t("start_time")}
              </label>
              <TextValidator
                containerProps={{ className: "w-100" }}
                onChange={(e) =>
                  setData({ ...data, startTime: e.target.value })
                }
                type="time"
                value={data.startTime || ""}
                validators={["required"]}
                errorMessages={[i18n.t("this_field_is_required")]}
                variant="outlined"
              />
            </div>
            <div className="col-12 flex-column flex-md-row d-flex align-items-md-center mb-3 px-0">
              <label className="font-weight-bold" style={{ minWidth: "120px" }}>
                {i18n.t("end_time")}
              </label>
              <TextValidator
                containerProps={{ className: "w-100" }}
                onChange={(e) => setData({ ...data, endTime: e.target.value })}
                type="time"
                value={data.endTime || ""}
                validators={["required"]}
                errorMessages={[i18n.t("this_field_is_required")]}
                variant="outlined"
              />
            </div>
            <div className="row mx-0 px-0 justify-content-between mt-4 col-12">
              {data._id ? (
                <PrimaryButton
                  className="w-128"
                  onClick={() => onDelete(data._id)}
                  label={i18n.t("delete")}
                />
              ) : (
                <div />
              )}
              <PrimaryButton
                className="w-128"
                props={{
                  type: "submit",
                }}
                label={data._id ? i18n.t("save") : i18n.t("create")}
              />
            </div>
          </ValidatorForm>
        </div>
      }
    />
  )
}
