import { connect } from "react-redux"
import React, { Component } from "react"
import { DotsLoader } from "../../../components"
import {
  getUser,
  addAccount,
  startAccountOnBoarding,
  toggleOnlinePayments,
  getSquareApiRefreshToken,
  editUser,
} from "../../../config/simpleApiCalls"
import { SuccessHelper, ErrorHelper } from "../../../helpers"
import Switch from "@material-ui/core/Switch"
import FormControlLabel from "@material-ui/core/FormControlLabel"

import i18n from "../../../i18n"
import Swal from "sweetalert2"
import { withStyles } from "@material-ui/core/styles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./styles.scss"
import { TextField } from "@material-ui/core"
import { Images } from "../../../theme/index"
import {
  updateInteracTransferSettings,
  getSaloonById,
} from "../../../config/simpleApiCalls"
import PrimaryButton from "../../../components/Core/PrimaryButton"
import SharedStorage from "../../../helpers/Storage"
import Broswer from "../../../helpers/Browser"
const YellowSwitch = withStyles({
  switchBase: {
    color: "#E6E6E6",
    "&$checked": {
      color: "var(--warning-color)!important",
    },
    "&$checked + $track": {
      backgroundColor: "transparent",
      border: "1px solid var(--warning-color)",
      opacity: 1,
    },
  },
  checked: {},
  track: {},
})(Switch)

const ccStyle = {
  width: 36,
  height: 36,
  cursor: "pointer",
  margin: "0px 6px",
}

class MyAccount extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      accountCreated: false,
      isOnboarded: false,
      recipientEmail: "",
      recipientPhoneNo: "",
      securityAnswer: "",
      securityQuestion: "",
      enableOnlinePayments: false,
      enableInteracTransfer: false,
      refundPolicy: undefined,
    }
  }

  async componentDidMount() {
    const saloonData = await JSON.parse(await SharedStorage.getItem("saloon"))
    const urlParams = new URLSearchParams(location.search)

    if (urlParams.get("code")) {
      getSquareApiRefreshToken({ code: urlParams.get("code") }).then(
        ({ data }) => {
          this.props.history.replace({
            search: "",
          })
          if (data.success) {
            editUser({ squarePaymentRefreshToken: data.refresh_token })
            this.setState({ squarePaymentRefreshToken: data.refresh_token })
          }
        }
      )
    }

    this.setState({ isLoading: true })
    Promise.all([
      getSaloonById()
        .then(({ data }) => {
          const {
            recipientEmail,
            recipientPhoneNo,
            securityAnswer,
            securityQuestion,
            refundPolicy,
          } = data.data
          this.setState({
            recipientEmail,
            recipientPhoneNo,
            securityAnswer,
            securityQuestion,
            refundPolicy,
          })
        })
        .catch(() => {
          this.setState({ isLoading: false })
        }),
      getUser().then(({ data }) => {
        if (data.success) {
          const { user } = data.data
          this.setState({
            accountCreated: !!user.stripeAccountId,
            isOnboarded: user.userOnboarded,
            enableOnlinePayments: user.enableOnlinePayments,
            enableInteracTransfer: user.enableInteracTransfer,
            enableStripe: user.enableStripe,
            enableSquarePayment: user.enableSquarePayment,
            squarePaymentRefreshToken: user.squarePaymentRefreshToken,
            isLoading: false,
          })
        }
      }),
    ]).then(() => {
      this.setState({ isLoading: false })
    })
  }

  renderLoader = () => {
    const { isLoading } = this.state
    return <DotsLoader isloading={isLoading} />
  }

  startAccountOnBoarding = () => {
    startAccountOnBoarding().then(({ data }) => {
      if (data.success) {
        this.setState({ isLoading: false })
        Broswer.open(data.accountLinks, "_blank")
      } else {
        if (data.code !== "already_onboarded") {
          ErrorHelper.handleErrors(i18n.t("someting_went_wrong"), true)
        }
        this.setState({ isLoading: false })
      }
    })
  }

  createAccount = () => {
    addAccount({}).then(({ data }) => {
      if (data.success) {
        this.startAccountOnBoarding()
      } else {
        ErrorHelper.handleErrors(i18n.t("someting_went_wrong"), true)
        this.setState({ isLoading: false })
      }
    })
  }

  connect = () => {
    const { accountCreated } = this.state
    this.setState({ isLoading: true })
    if (!accountCreated) {
      this.createAccount()
    } else {
      this.startAccountOnBoarding()
    }
  }

  handleToggle = (body = {}) => {
    let {
      enableOnlinePayments,
      enableInteracTransfer,
      enableStripe,
      enableSquarePayment,
    } = this.state
    const sendRequest = () => {
      this.setState({ isLoading: true })
      toggleOnlinePayments({
        enableOnlinePayments,
        enableInteracTransfer,
        enableStripe,
        enableSquarePayment,
        ...body,
      })
        .then(({ data }) => {
          this.setState({ isLoading: false })
          if (data.success) {
            const {
              enableOnlinePayments,
              enableInteracTransfer,
              enableStripe,
              enableSquarePayment,
            } = data.data
            this.setState(
              {
                enableOnlinePayments,
                enableInteracTransfer,
                enableStripe,
                enableSquarePayment,
                forceRerender: true,
              },
              () =>
                this.setState({
                  forceRerender: false,
                })
            )
            SuccessHelper.handleSuccess(i18n.t("successfully_updated"), true)
          } else {
            ErrorHelper.handleErrors(i18n.t("failed_to_update"), true)
          }
        })
        .catch(() => {
          ErrorHelper.handleErrors(i18n.t("failed_to_update"), true)
          this.setState({ isLoading: false })
        })
    }
    if (
      body.enableOnlinePayments ||
      (body.enableStripe && enableSquarePayment) ||
      (body.enableSquarePayment && enableStripe)
    ) {
      Swal.fire({
        title: body.enableOnlinePayments
          ? i18n.t("payment_charge_message")
          : body.enableStripe && enableSquarePayment
          ? i18n.t("desactive_online_payment_method", {
              method: "Square Payment",
            })
          : body.enableSquarePayment &&
            enableStripe &&
            i18n.t("desactive_online_payment_method", { method: "Stripe" }),
        showCancelButton: true,
        cancelButtonText: i18n.t("cancel"),
        confirmButtonText: "Okay",
      }).then((result) => {
        if (result.value) {
          if (
            body.enableOnlinePayments &&
            !enableStripe &&
            !enableSquarePayment
          )
            enableStripe = true
          sendRequest()
        } else {
          this.setState(
            {
              isLoading: false,
              forceRerender: true,
            },
            () =>
              this.setState({
                forceRerender: false,
              })
          )
        }
      })
    } else {
      sendRequest()
    }
  }

  handleSettingsSave = async () => {
    const {
      recipientEmail,
      recipientPhoneNo,
      securityAnswer,
      securityQuestion,
    } = this.state
    this.setState({ isloading: true })
    try {
      const errorMap = {}
      if (!recipientEmail && !recipientPhoneNo) {
        errorMap.recipientEmail = i18n.t("email_or_phone_is_required")
        errorMap.recipientPhoneNo = i18n.t("email_or_phone_is_required")
      }
      if (!securityQuestion) {
        errorMap.securityQuestion = i18n.t("security_answer_is_required")
      }
      if (!securityAnswer) {
        errorMap.securityAnswer = i18n.t("security_question_is_required")
      }

      if (Object.keys(errorMap).length) {
        this.setState({ errorMap, isloading: false })
      } else {
        updateInteracTransferSettings({
          recipientEmail,
          recipientPhoneNo,
          securityAnswer,
          securityQuestion,
        })
          .then(({ data }) => {
            if (data.success) {
              SuccessHelper.handleSuccess(i18n.t("successfully_updated"), true)
            } else {
              ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true)
            }
          })
          .finally(() => {
            this.setState({ isloading: false })
          })
      }
    } catch (e) {
      ErrorHelper.handleErrors(e, true)
      this.setState({ isloading: false })
    }
  }

  render() {
    const {
      isOnboarded,
      enableOnlinePayments,
      enableInteracTransfer,
      enableStripe,
      enableSquarePayment,
      squarePaymentRefreshToken,
      isLoading,
      recipientEmail,
      recipientPhoneNo,
      securityAnswer,
      securityQuestion,
      forceRerender,
      errorMap = {},
      refundPolicy,
    } = this.state
    const hasSetPolicy = Boolean(refundPolicy)
    return (
      <div className="content-container">
        {this.renderLoader()}
        <div className="p-4">
          <div>
            <h1 className="saloon-dashboard-heading pb-3">
              {i18n.t("container.online_payments")}
            </h1>
          </div>
          {!hasSetPolicy && (
            <div className="row mx-0 px-1 align-items-center pb-3">
              <h6 className="pr-2 text-danger">
                {i18n.t("must_set_refund_policy")}
              </h6>
              <PrimaryButton
                label={i18n.t("here")}
                onClick={() => {
                  this.props.history.push("/saloon-refund-policy")
                }}
              />
            </div>
          )}
          {!forceRerender && (
            <FormControlLabel
              control={
                <YellowSwitch
                  disabled={!hasSetPolicy}
                  checked={enableOnlinePayments}
                  onChange={() =>
                    this.handleToggle({
                      enableOnlinePayments: !enableOnlinePayments,
                    })
                  }
                />
              }
              label={i18n.t("enable_online_payments")}
            />
          )}
          <fieldset>
            <legend style={{ fontSize: "1rem", paddingTop: "2rem" }}>
              {i18n.t("powered_by")}&nbsp;
              <strong className="header-stripe">Stripe</strong>
            </legend>
            <div
              className="d-sm-flex align-items-center"
              style={{ marginLeft: "-11px" }}
            >
              {!forceRerender && (
                <YellowSwitch
                  checked={Boolean(
                    enableStripe && enableOnlinePayments && !enableSquarePayment
                  )}
                  disabled={!hasSetPolicy || !enableOnlinePayments}
                  onChange={() => {
                    this.handleToggle({
                      enableStripe: !enableStripe,
                      enableSquarePayment: false,
                    })
                  }}
                />
              )}
              <h6 className="mb-0 ml-sm-3">{i18n.t("enable")}</h6>
            </div>
            <div className="d-sm-flex align-items-center">
              <div className="row mx-0 mb-3">
                <FontAwesomeIcon
                  style={{ ...ccStyle, marginLeft: 0 }}
                  icon={["fab", "cc-visa"]}
                />
                <FontAwesomeIcon
                  style={ccStyle}
                  icon={["fab", "cc-mastercard"]}
                />
                <FontAwesomeIcon style={ccStyle} icon={["fab", "cc-amex"]} />
                <FontAwesomeIcon
                  style={ccStyle}
                  icon={["fab", "cc-discover"]}
                />
              </div>
              {isOnboarded ? (
                <div className="ml-sm-3 mb-3">
                  {i18n.t("already_connected")}
                </div>
              ) : (
                <div className="ml-sm-3 mb-3">
                  <PrimaryButton onClick={this.connect}>
                    {i18n.t("connect_now")}
                  </PrimaryButton>
                </div>
              )}
            </div>
          </fieldset>
          {process.env.REACT_APP_IS_DEV == "true" ? (
            <fieldset>
              <legend style={{ fontSize: "1rem", paddingTop: "2rem" }}>
                {i18n.t("powered_by")}&nbsp;
                <strong className="header-stripe">Square Payment</strong>
                <div
                  className="d-sm-flex align-items-center"
                  style={{ marginLeft: "-11px" }}
                >
                  {!forceRerender && (
                    <YellowSwitch
                      checked={Boolean(
                        enableSquarePayment &&
                          enableOnlinePayments &&
                          !enableStripe
                      )}
                      disabled={!hasSetPolicy || !enableOnlinePayments}
                      onChange={() => {
                        this.handleToggle({
                          enableSquarePayment: !enableSquarePayment,
                          enableStripe: false,
                        })
                      }}
                    />
                  )}
                  <h6 className="mb-0 ml-sm-3">{i18n.t("enable")}</h6>
                </div>
              </legend>
              <div className="d-sm-flex align-items-center">
                <div className="row mx-0 mb-3">
                  <FontAwesomeIcon
                    style={{ ...ccStyle, marginLeft: 0 }}
                    icon={["fab", "cc-visa"]}
                  />
                  <FontAwesomeIcon
                    style={ccStyle}
                    icon={["fab", "cc-mastercard"]}
                  />
                  <FontAwesomeIcon style={ccStyle} icon={["fab", "cc-amex"]} />
                  <FontAwesomeIcon
                    style={ccStyle}
                    icon={["fab", "cc-discover"]}
                  />
                </div>
                {squarePaymentRefreshToken ? (
                  <div className="ml-sm-3 mb-3">
                    {i18n.t("already_connected")}
                  </div>
                ) : (
                  <div className="ml-sm-3 mb-3">
                    <PrimaryButton
                      onClick={() => {
                        location.href = `https://connect.squareup${
                          process.env.REACT_APP_IS_DEV == "true"
                            ? "sandbox"
                            : ""
                        }.com/oauth2/authorize?client_id=${
                          process.env.REACT_APP_SQUARE_PAYMENT_CLIENT_ID
                        }&scope=PAYMENTS_WRITE+PAYMENTS_WRITE_ADDITIONAL_RECIPIENTS&session=false&state=82201dd8d83d23cc8a48caf52b`
                      }}
                    >
                      {i18n.t("connect_now")}
                    </PrimaryButton>
                  </div>
                )}
              </div>
            </fieldset>
          ) : null}

          <fieldset>
            <legend
              style={{
                fontSize: "1rem",
                paddingTop: "1rem",
                paddingBottom: "1rem",
                marginLeft: "-7px",
              }}
            >
              <div className="d-sm-flex align-items-center">
                <img
                  src={Images.interac_etransfer_logo}
                  style={{ width: 100 }}
                />
                {i18n.t("interac_transfer")}
              </div>
              <div className="d-sm-flex align-items-center">
                {!forceRerender && (
                  <YellowSwitch
                    checked={enableInteracTransfer && enableOnlinePayments}
                    disabled={!hasSetPolicy || !enableOnlinePayments}
                    onChange={() =>
                      this.handleToggle({
                        enableInteracTransfer: !enableInteracTransfer,
                      })
                    }
                  />
                )}
                <h6 className="mb-0 ml-sm-3">{i18n.t("enable")}</h6>
              </div>
            </legend>
            <div className="site-form row align-items-center form-group col-12 px-0">
              <label
                className="font-weight-bold"
                style={{
                  textTransform: "capitalize",
                }}
              >
                {i18n.t("email_recipent")}
              </label>
              <TextField
                value={recipientEmail}
                onChange={(e) =>
                  this.setState({ recipientEmail: e.target.value })
                }
                error={!!errorMap.recipientEmail}
                helperText={errorMap.recipientEmail}
                className="editField"
                variant={"outlined"}
              />
            </div>
            <div className="site-form row align-items-center form-group col-12 px-0">
              <label
                className="font-weight-bold"
                style={{
                  textTransform: "capitalize",
                }}
              >
                {i18n.t("phone_recipent")}
              </label>
              <TextField
                value={recipientPhoneNo}
                onChange={(e) =>
                  this.setState({ recipientPhoneNo: e.target.value })
                }
                error={!!errorMap.recipientPhoneNo}
                helperText={errorMap.recipientPhoneNo}
                className="editField"
                variant={"outlined"}
              />
            </div>
            <div className="site-form row align-items-center form-group col-12 px-0">
              <label
                className="font-weight-bold"
                style={{
                  textTransform: "capitalize",
                }}
              >
                {i18n.t("security_question")}
              </label>
              <TextField
                value={securityQuestion}
                onChange={(e) =>
                  this.setState({ securityQuestion: e.target.value })
                }
                error={!!errorMap.securityQuestion}
                helperText={errorMap.securityQuestion}
                className="editField"
                variant={"outlined"}
              />
            </div>
            <div className="site-form row align-items-center form-group col-12 px-0">
              <label
                className="font-weight-bold"
                style={{
                  textTransform: "capitalize",
                }}
              >
                {i18n.t("security_answer")}
              </label>
              <TextField
                value={securityAnswer}
                onChange={(e) =>
                  this.setState({ securityAnswer: e.target.value })
                }
                error={!!errorMap.securityAnswer}
                helperText={errorMap.securityAnswer}
                className="editField"
                variant={"outlined"}
              />
            </div>
            <div className="mt-5">
              <PrimaryButton
                className="w-128"
                onClick={this.handleSettingsSave}
              >
                {i18n.t("save")}
              </PrimaryButton>
            </div>
          </fieldset>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

const action = {}

export default connect(mapStateToProps, action)(MyAccount)
