import { connect } from "react-redux"
import React, { useEffect, useMemo, useRef, useState } from "react"
import i18n from "../../../i18n"
import BaseModal from "../../BaseModal"
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator"
import PrimaryButton from "../../Core/PrimaryButton"
import AppBar from "@material-ui/core/AppBar"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import {
  getRequest as getClassesRequest,
  upsertRequest as upsertClassRequest,
} from "../../../redux/actions/Saloon/Class"
import {
  getRequest as getStudentRequest,
  setFinalizedRequest,
} from "../../../redux/actions/Saloon/Student"
import {
  addClassRequest,
  removeClassRequest,
  addStudentToClassRequest,
  addStudentGroupToClassRequest,
  removeStudentFromClassRequest,
} from "../../../redux/actions/Saloon/Semester"
import { ErrorHelper, SuccessHelper } from "../../../helpers"
import DotsLoader from "../../DotsLoader"
import ClassUpsert from "../Class/ClassUpsert"
import { MenuItem } from "@material-ui/core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Swal from "sweetalert2"
import DatePicker from "react-datepicker"
import moment from "moment"
import { Chip } from "@material-ui/core"
import CollapsibleTable from "../../CollapsibleTable"
import { Checkbox } from "@material-ui/core"
import StudentGrading from "./StudentGrading"
import deleteConfirmation from "../../../helpers/DeleteHelper"
import TabPanel from "../../Core/Tabs/TabPanel"
import useScreenSize from "../../../context/ScreenSize"
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

function ProgramUpsert({
  open,
  onClose,
  onSave,
  active = {},
  setActive,
  getClassesRequest,
  upsertClassRequest,
  addClassRequest,
  removeClassRequest,
  getStudentRequest,
  addStudentToClassRequest,
  addStudentGroupToClassRequest,
  removeStudentFromClassRequest,
  setFinalizedRequest,
  companyId,
  classes,
  students,
  studentGroups,
}) {
  const form = useRef()
  const newClassForm = useRef()
  const [data, setData] = useState({})
  const [addClassOpen, setAddClassOpen] = useState(false)
  const [gradingProps, setGradingProps] = useState(null)

  const [loading, setLoading] = useState(false)
  const [activeClass, setActiveClass] = useState({})
  const [newCourseClass, setNewCourseClass] = useState({ numberOfTimes: 1 })
  const { screenSize } = useScreenSize()
  const init = (data) => {
    setData({ ...data })
    setTab(0)
    getClassesRequest({})
  }
  const onSaveClass = (params) => {
    setLoading(true)
    upsertClassRequest(
      { companyId, ...params },
      {
        success: (data) => {
          SuccessHelper.handleSuccess(
            i18n.t(data.id ? "successfully_updated" : "successfully_added"),
            true
          )
          setAddClassOpen(false)
          setLoading(false)
        },
        failure: () => {
          ErrorHelper.handleErrors(
            i18n.t(data.id ? "failed_to_update" : "failed_to_add"),
            true
          )
          setLoading(false)
        },
      }
    )
  }
  useEffect(() => {
    init(active)
  }, [])
  useEffect(() => {
    if (active._id != data._id) {
      init(active)
    }
  }, [active])
  const handleChange = (key, value) => {
    data[key] = value
    setData({ ...data })
  }
  const handleSubmit = () => {
    onSave(data)
  }
  const handleClassAdd = () => {
    addClassRequest(
      {
        _id: active._id,
        classId: newCourseClass,
      },
      {
        success: (data) => {
          SuccessHelper.handleSuccess(i18n.t("successfully_added"), true)
          setData({ ...data.data })
          setActive(data.data)
          setNewCourseClass()
        },
        failure: () => {
          ErrorHelper.handleErrors(i18n.t("failed_to_add"), true)
        },
      }
    )
  }
  const handleClassRemoval = (classId) => {
    removeClassRequest(
      {
        _id: active._id,
        classId,
      },
      {
        success: (result) => {
          SuccessHelper.handleSuccess(i18n.t("successfully_deleted"), true)
          setData({ ...result.data })
          setActive(result.data)
        },
        failure: () => {
          ErrorHelper.handleErrors(i18n.t("failed_to_delete"), true)
        },
      }
    )
  }

  const handleFinalization = (params) => {
    Swal.fire({
      title: i18n.t("are_you_sure"),
      text: i18n.t("finalize_course_warning"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ff3600",
      cancelButtonColor: "#354e68",
      confirmButtonText: i18n.t("yes"),
    }).then((result) => {
      if (result.value) {
        onSave({ ...params, finalized: true })
      }
    })
  }

  const [tab, setTab] = React.useState(0)

  const handleTabChange = (event, newValue) => {
    setTab(newValue)
  }

  const usedClassMap = useMemo(() => {
    const { classes = [] } = data
    const map = {}
    classes.map((_class) => {
      map[_class.classId?._id] = true
    })
    return map
  }, [data.classes])
  const [studentMap, semesterStudentClassMap] = useMemo(() => {
    const semesterStudentClassMap = {}
    const map = {}
    students.map((student) => {
      map[student._id] = student
      const { studentClasses = [] } = student
      studentClasses.map((studentClass) => {
        if (!semesterStudentClassMap[studentClass.classId]) {
          semesterStudentClassMap[studentClass.classId] = {}
        }
        if (studentClass.activeSemester == data._id) {
          semesterStudentClassMap[studentClass.classId][student._id] = true
        } else {
          semesterStudentClassMap[studentClass.classId][student._id] = false
        }
      })
    })
    return [map, semesterStudentClassMap]
  }, [students, data])

  const findAttempt = (val, parent) => {
    const studentClass = val.student.studentClasses.find((element) => {
      return (
        element.classId == parent.classId._id &&
        element.activeSemester == data._id
      )
    })
    return (
      studentClass?.attempts?.find(
        (attempt) => attempt.semesterId == data._id
      ) || {}
    )
  }

  return (
    <div>
      <DotsLoader isloading={loading} />
      {open ? (
        <BaseModal
          open={open}
          showCloseBtn={screenSize !== "mobile" ? true : false}
          actions={
            <>
              {screenSize === "mobile" ? (
                <div className="row gap-2 justify-content-center">
                  {data._id &&
                    (!data.finalized ? (
                      <PrimaryButton
                        label={i18n.t("graded_and_finalized")}
                        onClick={() => handleFinalization(data)}
                      />
                    ) : null)}
                  <PrimaryButton
                    className="w-128"
                    label={i18n.t("close")}
                    onClick={onClose}
                  />
                </div>
              ) : null}
            </>
          }
          containerClassName={"half-container"}
          title={
            <div className="row mx-0 mb-3">
              <div className="pr-2">
                {data._id ? i18n.t("edit_semester") : i18n.t("add_semester")}
              </div>
              {data._id &&
                (!data.finalized && screenSize !== "mobile" ? (
                  <PrimaryButton
                    label={i18n.t("graded_and_finalized")}
                    onClick={() => handleFinalization(data)}
                  />
                ) : data.finalized ? (
                  <Chip
                    className="status-chip"
                    size="small"
                    label={i18n.t("finalized")}
                  />
                ) : null)}
            </div>
          }
          content={
            <>
              <AppBar position="static" className="custom-tab">
                <Tabs
                  value={tab}
                  onChange={handleTabChange}
                  aria-label="simple tabs example"
                >
                  <Tab label={i18n.t("details")} {...a11yProps(0)} />
                  <Tab
                    label={i18n.t("classes")}
                    {...a11yProps(1)}
                    disabled={!data._id}
                  />
                </Tabs>
              </AppBar>
              <TabPanel value={tab} index={0}>
                <div>
                  <ValidatorForm
                    onSubmit={handleSubmit}
                    ref={form}
                    className="w-100"
                    onError={(error) => {
                      if (!error.length) {
                        onSave(data)
                      }
                    }}
                  >
                    <div className="col-12 flex-column flex-md-row px-0 d-flex align-items-md-center mb-3">
                      <label
                        className="font-weight-bold"
                        style={{ minWidth: "120px" }}
                      >
                        {i18n.t("name")}
                      </label>
                      <div className="w-100">
                        <TextValidator
                          className="w-100"
                          label={i18n.t("name")}
                          onChange={(e) => handleChange("name", e.target.value)}
                          value={data.name || ""}
                          validators={["required"]}
                          errorMessages={[i18n.t("name_is_required")]}
                          variant="outlined"
                        />
                      </div>
                    </div>
                    <div className="col-12 flex-column flex-md-row  px-0 d-flex align-items-md-center mb-3">
                      <label
                        className="font-weight-bold"
                        style={{ minWidth: "120px" }}
                      >
                        {i18n.t("start_date")}
                      </label>
                      <DatePicker
                        wrapperClassName="w-100"
                        selected={
                          data.startDate ? moment(data.startDate).toDate() : ""
                        }
                        onChange={(e) => handleChange("startDate", e)}
                        value={
                          data.startDate ? moment(data.startDate).toDate() : ""
                        }
                        customInput={
                          <TextValidator
                            label={i18n.t("start_date")}
                            onChange={(e) =>
                              handleChange("startDate", e.target.value)
                            }
                            value={data.startDate}
                            validators={["required"]}
                            errorMessages={[i18n.t("this_field_is_required")]}
                            InputProps={{
                              readOnly: data.finalized,
                            }}
                            variant="outlined"
                          />
                        }
                        placeholderText={i18n.t("start_date")}
                        readOnly={data.finalized}
                      />
                    </div>
                    <div className="col-12 flex-column flex-md-row  px-0 d-flex align-items-md-center mb-3">
                      <label
                        className="font-weight-bold"
                        style={{ minWidth: "120px" }}
                      >
                        {i18n.t("end_date")}
                      </label>
                      <DatePicker
                        wrapperClassName="w-100"
                        selected={
                          data.endDate ? moment(data.endDate).toDate() : ""
                        }
                        onChange={(e) => handleChange("endDate", e)}
                        value={
                          data.endDate ? moment(data.endDate).toDate() : ""
                        }
                        customInput={
                          <TextValidator
                            label={i18n.t("end_date")}
                            onChange={(e) =>
                              handleChange("endDate", e.target.value)
                            }
                            value={data.endDate}
                            validators={["required"]}
                            errorMessages={[i18n.t("this_field_is_required")]}
                            InputProps={{
                              readOnly: data.finalized,
                            }}
                            variant="outlined"
                          />
                        }
                        placeholderText={i18n.t("end_date")}
                        readOnly={data.finalized}
                      />
                    </div>
                    <div className="row mx-0  mb-3 px-0 justify-content-end my-4 col-12">
                      {!data.finalized ? (
                        <PrimaryButton
                          className="w-128"
                          props={{
                            type: "submit",
                          }}
                          label={data._id ? i18n.t("save") : i18n.t("create")}
                        />
                      ) : null}
                    </div>
                  </ValidatorForm>
                </div>
              </TabPanel>
              <TabPanel value={tab} index={1}>
                {!data.finalized ? (
                  <ValidatorForm
                    onSubmit={() => {
                      handleClassAdd()
                    }}
                    ref={newClassForm}
                    className="w-100"
                  >
                    <div className="row mb-3 flex-column flex-md-row px-0 align-items-md-end gap-2 pt-2">
                      <div style={{ flex: 10 }}>
                        <SelectValidator
                          label={i18n.t("class")}
                          onChange={(e) => {
                            setNewCourseClass(e.target?.value)
                          }}
                          value={newCourseClass || ""}
                          select
                          validators={["required"]}
                          errorMessages={[i18n.t("required")]}
                          variant="outlined"
                        >
                          <MenuItem disabled={true}>{i18n.t("class")}</MenuItem>
                          {classes.map((_class) => {
                            return (
                              <MenuItem
                                key={_class._id}
                                value={_class._id}
                                disabled={usedClassMap[_class._id]}
                              >
                                {_class.name}
                              </MenuItem>
                            )
                          })}
                        </SelectValidator>
                      </div>
                      <div className="d-flex gap-2 justify-content-center">
                        <PrimaryButton
                          className="w-98"
                          label={i18n.t("new")}
                          onClick={() => {
                            setAddClassOpen(true)
                          }}
                        />
                        <PrimaryButton
                          className="w-98"
                          props={{
                            type: "submit",
                          }}
                          label={i18n.t("add")}
                        />
                      </div>
                    </div>
                  </ValidatorForm>
                ) : null}
                <CollapsibleTable
                  maxContentWidth={false}
                  rows={data.classes}
                  childKey="students"
                  childrenGetter={(row) => {
                    const map = semesterStudentClassMap[row.classId._id] || {}
                    const children = Object.keys(map).map((studentId) => {
                      const student = studentMap[studentId]

                      let attempt
                      student.studentClasses.map((_class) => {
                        _class.attempts.map((element) => {
                          if (element.semesterId == data._id) {
                            attempt = element
                          }
                        })
                      })
                      const finalized = attempt?.status == "Complete"
                      console.log(attempt)
                      return {
                        student,
                        selected: map[studentId],
                        finalized,
                      }
                    })

                    return children
                  }}
                  prependChildren={(parent) =>
                    data.finalized ? null : (
                      <div className="row mx-0 mb-3">
                        {studentGroups.map((studentGroup) => {
                          return (
                            <Chip
                              key={studentGroup._id}
                              className="status-chip"
                              onClick={() => {
                                const body = {
                                  _id: data._id,
                                  studentGroupId: studentGroup._id,
                                  classId: parent.classId._id,
                                }
                                const callbackMap = {
                                  success: ({ data }) => {
                                    SuccessHelper.handleSuccess(
                                      i18n.t("added_students", {
                                        amount: data.length,
                                      }),
                                      true
                                    )
                                    console.log(data)
                                    getStudentRequest({
                                      relations: ["classes"],
                                    })
                                  },
                                }
                                addStudentGroupToClassRequest(body, callbackMap)
                              }}
                              style={{
                                backgroundColor: "#B3D7ED",
                                color: "#252525",
                                cursor: "pointer",
                              }}
                              size="small"
                              label={studentGroup.name}
                            />
                          )
                        })}
                      </div>
                    )
                  }
                  schema={[
                    {
                      header: "class",
                      key: "class",
                      accessor: (val) => val.classId?.name,
                    },
                    {
                      key: "",
                      style: { width: 60 },
                      render: (val) => {
                        if (data.finalized) {
                          return null
                        }
                        return (
                          <FontAwesomeIcon
                            className="view-icon"
                            style={{ backgroundColor: "#F6943B" }}
                            color="white"
                            icon={["fas", "trash"]}
                            onClick={() =>
                              deleteConfirmation(() =>
                                handleClassRemoval(val._id)
                              )
                            }
                          />
                        )
                      },
                    },
                  ]}
                  childSchema={{
                    className: "mobile-max-content",
                    title: i18n.t("students"),
                    columns: [
                      ...(data.finalized
                        ? []
                        : [
                            {
                              key: "enrolled",
                              style: { width: 80 },
                              render: (val, parent) => {
                                if (val.finalized) {
                                  return null
                                }
                                return (
                                  <Checkbox
                                    checked={val.selected}
                                    onChange={() => {
                                      const body = {
                                        _id: data._id,
                                        studentId: val.student._id,
                                        classId: parent.classId._id,
                                      }
                                      const callbackMap = {
                                        success: () => {
                                          getStudentRequest({
                                            relations: ["classes"],
                                          })
                                        },
                                      }
                                      if (val.selected) {
                                        removeStudentFromClassRequest(
                                          body,
                                          callbackMap
                                        )
                                      } else {
                                        addStudentToClassRequest(
                                          body,
                                          callbackMap
                                        )
                                      }
                                    }}
                                    inputProps={{ "aria-label": "enrolled" }}
                                  />
                                )
                              },
                            },
                          ]),
                      {
                        key: "name",
                        style: { verticalAlign: "middle" },
                        accessor: (val, parent) => {
                          return val.student?.name
                        },
                      },
                      {
                        key: "average",
                        style: { verticalAlign: "middle" },
                        accessor: (val, parent) => {
                          const studentClass = val.student.studentClasses.find(
                            (element) => {
                              return (
                                element.classId == parent.classId._id &&
                                element.activeSemester == data._id
                              )
                            }
                          )
                          const attempt =
                            studentClass?.attempts?.find(
                              (attempt) => attempt.semesterId == data._id
                            ) || {}

                          let values = 0
                          let results = 0
                          ;(attempt.grades || []).map((grades) => {
                            values += grades.value || 0
                            results += grades.result || 0
                          })
                          if (!values) {
                            return `N/A`
                          }
                          return `${((results / values) * 100).toFixed(2)} %`
                        },
                      },
                      ...(data.finalized
                        ? [
                            {
                              key: "passed",
                              style: { verticalAlign: "middle" },
                              accessor: (val, parent) => {
                                const attempt = findAttempt(val, parent)

                                let values = 0
                                let results = 0
                                ;(attempt.grades || []).map((grades) => {
                                  values += grades.value || 0
                                  results += grades.result || 0
                                })
                                return (results / values) * 100 >=
                                  attempt.passingGrade
                                  ? "Yes"
                                  : "No"
                              },
                            },
                          ]
                        : []),
                      {
                        style: { width: 180 },
                        render: (val, parent) => {
                          return (
                            <div className="row mx-0 mb-3">
                              <PrimaryButton
                                label={i18n.t("grade")}
                                onClick={() => {
                                  setGradingProps({
                                    student: val.student,
                                    class: parent.classId,
                                  })
                                }}
                              />
                              {val.finalized ? null : (
                                <PrimaryButton
                                  label={i18n.t("finalize")}
                                  className="ml-1"
                                  onClick={() => {
                                    Swal.fire({
                                      title: i18n.t("are_you_sure"),
                                      icon: "warning",
                                      showCancelButton: true,
                                      confirmButtonColor: "#ff3600",
                                      cancelButtonColor: "#354e68",
                                      confirmButtonText: i18n.t("yes"),
                                    }).then((result) => {
                                      if (result.value) {
                                        const attempt = findAttempt(val, parent)
                                        setFinalizedRequest({
                                          attemptId: attempt._id,
                                          studentId: val.student._id,
                                        })
                                      }
                                    })
                                  }}
                                />
                              )}
                            </div>
                          )
                        },
                      },
                    ],
                  }}
                />
              </TabPanel>
            </>
          }
          onClose={onClose}
        />
      ) : null}
      {addClassOpen && (
        <ClassUpsert
          open={addClassOpen}
          element={activeClass}
          onClose={() => {
            setAddClassOpen(false)
            setActiveClass({})
          }}
          onSave={onSaveClass}
          presetVendor
        />
      )}
      {gradingProps && (
        <StudentGrading
          {...gradingProps}
          open={Boolean(gradingProps)}
          onClose={() => setGradingProps(null)}
          semester={data}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  classes: state._class.data,
  students: state.student.data,
  studentGroups: state.studentGroup.data,
  loading: state.semester.isFetching,
})

const action = {
  getClassesRequest,
  upsertClassRequest,
  addClassRequest,
  removeClassRequest,
  getStudentRequest,
  addStudentToClassRequest,
  addStudentGroupToClassRequest,
  removeStudentFromClassRequest,
  setFinalizedRequest,
}

export default connect(mapStateToProps, action)(ProgramUpsert)
