import { take, put, call, fork } from "redux-saga/effects"

import axios from "axios"
import { get_gym_timeslots } from "../../config/WebServices"
import * as types from "../actions/ActionTypes"

import { success, failure } from "../actions/GetGymTimeslots"

import { ErrorHelper } from "../../helpers"

async function callRequest(params) {
  return new Promise((resolve, reject) => {
    axios
      .get(get_gym_timeslots, {
        params,
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response.data)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

function* watchRequest() {
  while (true) {
    const { payload, index } = yield take(types.GETGYMTIMESLOTS.REQUEST)

    // const { targetView } = payload;
    // delete payload.targetView;
    try {
      const response = yield call(callRequest, payload)
      console.log("response", response)
      yield put(success(response, index))
    } catch (err) {
      yield put(failure(err))
      ErrorHelper.handleErrors(err, true)
    }
  }
}

export default function* root() {
  yield fork(watchRequest)
}
