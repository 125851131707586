// @flow

import { NO_SHOW_REPORT } from "../ActionTypes"

export function getRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: NO_SHOW_REPORT.GET_SAGA,
  }
}

export function get(data) {
  return {
    data,
    type: NO_SHOW_REPORT.GET,
  }
}

export function requestFailure() {
  return {
    type: NO_SHOW_REPORT.REQUEST_FAILURE,
  }
}
