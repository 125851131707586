import React, { useEffect, useState } from "react"
import i18n from "../../../i18n"
import {
  getRequest,
  removeRequest,
} from "../../../redux/actions/Saloon/CommunicationsMarketingTemplate"
import PrimaryButton from "../../../components/Core/PrimaryButton"
import { DotsLoader } from "../../../components"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import TemplateUpsertPopup from "./Components/TemplateUpsertPopup"
import BasicTable from "../../../components/Core/BasicTable"
import moment from "moment"
import EditIcon from "../../../components/Core/BasicTable/Actions/EditIcon"
import DeleteIcon from "../../../components/Core/BasicTable/Actions/DeleteIcon"
import { ErrorHelper, SuccessHelper } from "../../../helpers"
import deleteConfirmation from "../../../helpers/DeleteHelper"
import useScreenSize from "../../../context/ScreenSize"
const CommunicationsMarketingTemplate = () => {
  const dispatch = useDispatch()
  const [upsertPopupOpen, setUpsertPopupOpen] = useState(false)
  const [active, setActive] = useState(null)
  const loading = useSelector(
    (s) => s.communicationsMarketingTemplate.isFetching
  )
  const campaigns = useSelector((s) => s.communicationsMarketingTemplate.data)
  const { screenSize } = useScreenSize()
  useEffect(() => {
    dispatch(getRequest())
  }, [])

  const onEdit = (val) => {
    setUpsertPopupOpen(true)
    setActive(val)
  }

  const handleDelete = (id) => {
    dispatch(
      removeRequest(
        { id },
        {
          success: () => {
            SuccessHelper.handleSuccess(i18n.t("successfully_deleted"), true)
          },
          failure: () => {
            ErrorHelper.handleErrors(i18n.t("failed_to_delete"), true)
          },
        }
      )
    )
  }

  return (
    <div className="content-container p-4">
      <DotsLoader isloading={loading} />
      <div
        style={
          screenSize === "mobile"
            ? {
                position: "sticky",
                top: "0",
                paddingBottom: "1rem",
                backgroundColor: "white",
                zIndex: 1,
              }
            : {}
        }
        className="d-flex flex-column flex-md-row gap-2 justify-content-between align-items-center mb-3"
      >
        <h1 className="saloon-dashboard-heading">
          {i18n.t("communication_marketing_templates")}
        </h1>
        <PrimaryButton
          className="w-128"
          label={i18n.t("create")}
          onClick={() => setUpsertPopupOpen(true)}
        />
      </div>
      <BasicTable
        rows={campaigns || []}
        schema={[
          {
            header: i18n.t("name"),
            field: "name",
          },
          {
            header: i18n.t("created_on"),
            field: "createdDate",
            accessor: ({ createdDate }) => {
              return moment(createdDate).format("MM-DD-YYYY HH:mm")
            },
          },
          {
            key: "action",
            header: i18n.t("actions"),

            render: (val) => {
              return (
                <>
                  <EditIcon onClick={() => onEdit(val)} />
                  <DeleteIcon
                    onClick={() =>
                      deleteConfirmation(() => handleDelete(val._id))
                    }
                  />
                </>
              )
            },
          },
        ]}
      />
      <TemplateUpsertPopup
        open={upsertPopupOpen}
        setOpen={setUpsertPopupOpen}
        active={active}
      />
    </div>
  )
}

export default CommunicationsMarketingTemplate
