// @flow
import Immutable from "seamless-immutable"
import * as types from "../../actions/ActionTypes"

const initialState = Immutable({
  isFetching: false,
  errorMessage: "",
  data: [],
  active: {},
})

export default (state = initialState, action) => {
  switch (action.type) {
  case types.COMMUNICATION_SETTINGS.GET_SAGA:
    return Immutable.merge(state, {
      isFetching: true,
    })
  case types.COMMUNICATION_SETTINGS.GET:
    return Immutable.merge(state, {
      isFetching: false,
      data: action.data,
    })
  case types.COMMUNICATION_SETTINGS.UPSERT_SAGA:
    return Immutable.merge(state, {
      isFetching: true,
    })
  case types.COMMUNICATION_SETTINGS.UPSERT: {
    const newState = { ...state, isFetching: false, data: action.data }
    return newState
  }
  case types.LOGOUT:
    return initialState
  case types.CLEAR_LOGOUT:
    return initialState
  default:
    return state
  }
}
