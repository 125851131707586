// @flow

import { BOOKING_TRANSACTION } from "./ActionTypes"
export function getRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: BOOKING_TRANSACTION.GET_SAGA,
  }
}

export function get(data) {
  return {
    data,
    type: BOOKING_TRANSACTION.GET,
  }
}

export function insertRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: BOOKING_TRANSACTION.INSERT_SAGA,
  }
}

export function insert(data) {
  return {
    data,
    type: BOOKING_TRANSACTION.INSERT,
  }
}

export function reverseRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: BOOKING_TRANSACTION.REVERSE_SAGA,
  }
}

export function setCollectedMoney(data) {
  return {
    data,
    type: BOOKING_TRANSACTION.SET_COLLECT_MONEY,
  }
}

export function requestFailure() {
  return {
    type: BOOKING_TRANSACTION.REQUEST_FAILURE,
  }
}
