import { connect } from "react-redux"
import React, { Component } from "react"

import { BaseModal, DotsLoader } from "../../../components"
import {
  getCompanyUnavailabilites,
  deleteCompanyUnavailability,
  createCompanyUnavailability,
} from "../../../config/simpleApiCalls"
import {
  getRequest,
  removeRequest,
} from "../../../redux/actions/ReoccuringCompanyUnavailabilties"
import moment from "moment"
import DatePicker from "react-datepicker"
import "./styles.css"

import i18n from "../../../i18n"
import ReoccuringUnavailabilities from "../../../components/ReoccuringUnavailabilities/ReoccuringUnavailabilities.component"
import PrimaryButton from "../../../components/Core/PrimaryButton"
import BasicTable from "../../../components/Core/BasicTable"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { SuccessHelper, ErrorHelper } from "../../../helpers"
import Swal from "sweetalert2"
import deleteConfirmation from "../../../helpers/DeleteHelper"
import { ScreenSizeContext } from "../../../context/ScreenSize"
import { Button } from "@material-ui/core"

class ManageUnavailibilites extends Component {
  constructor(props) {
    super(props)
    this.state = {
      date: Date.now(),
      dateAvailabiltiesMap: {},
      selectionMap: {},
      isLoading: false,
      markedDates: {},
      toAddMap: {},
      toDeleteMap: {},
    }
  }

  async componentDidMount() {
    this.setState({ isLoading: true }, () => {
      this.getCompanyUnavailabilitesFromDate(this.state.date)
    })
    this.props.getRequest({})
  }

  getCompanyUnavailabilitesFromDate(date) {
    const params = {
      date: moment(date).format("MM-DD-YYYY"),
    }
    this.setState({ isLoading: true, date })
    getCompanyUnavailabilites(params)
      .then((res) => {
        this.setState({
          isLoading: false,
          availabilities: res,
          toAddMap: {},
          toDeleteMap: {},
        })
      })
      .catch(() => {
        this.setState({ isLoading: false })
      })
  }

  renderLoader = () => {
    const { isLoading } = this.state
    return <DotsLoader isloading={isLoading || this.props.isFetching} />
  }

  toggleSelection(key) {
    const { toAddMap, toDeleteMap, availabilities = {} } = this.state
    if (availabilities[key] != "booked") {
      if (typeof availabilities[key] == "object") {
        if (toDeleteMap[key]) {
          delete toDeleteMap[key]
        } else {
          toDeleteMap[key] = availabilities[key]
        }
      } else {
        if (toAddMap[key]) {
          delete toAddMap[key]
        } else {
          toAddMap[key] = true
        }
      }
      this.setState({ toAddMap, toDeleteMap })
    }
  }

  onSetSidebarOpen = (open) => {
    this.setState({ sidebarOpen: open })
  }

  async save() {
    const { toAddMap, toDeleteMap, date } = this.state
    let formattedDate = moment(date).format("MM-DD-YYYY")
    this.setState({ isLoading: true })
    const toAdd = []
    Object.keys(toAddMap).map((timeRange) => {
      const time = timeRange.split(" ")[0]
      toAdd.push({ time, date: formattedDate })
    })
    const toDelete = []
    Object.keys(toDeleteMap).map((timeRange) => {
      toDelete.push(toDeleteMap[timeRange]._id)
    })
    const promises = []
    if (toAdd.length) {
      promises.push(createCompanyUnavailability(toAdd))
    }
    if (toDelete.length) {
      promises.push(deleteCompanyUnavailability({ ids: toDelete.join(",") }))
    }
    Promise.all(promises).then(() => {
      this.getCompanyUnavailabilitesFromDate(this.state.date)
    })
  }

  markAllAsAvailable = () => {
    const { availabilities = {} } = this.state
    let toDelete = []
    Object.keys(availabilities).map((key) => {
      let availability = availabilities[key]
      console.log(availability)
      if (typeof availability == "object") {
        toDelete.push(availability._id)
      }
    })
    deleteCompanyUnavailability({ ids: toDelete.join(",") }).then(() => {
      this.getCompanyUnavailabilitesFromDate(this.state.date)
    })
  }

  markAllAsUnavailable = () => {
    const { availabilities = {}, date } = this.state
    let formattedDate = moment(date).format("MM-DD-YYYY")
    let toAdd = []
    Object.keys(availabilities).map((key) => {
      let availability = availabilities[key]
      console.log(availability)
      if (availabilities[key] != "booked" && typeof availability != "object") {
        const time = key.split(" ")[0]
        toAdd.push({ time, date: formattedDate })
      }
    })
    createCompanyUnavailability(toAdd).then(() => {
      this.getCompanyUnavailabilitesFromDate(this.state.date)
    })
  }

  openAdvancedModal = () => {
    this.setState({ advancedModalOpen: true })
  }

  openOptionsModal = () => {
    this.setState({ optionsModalOpen: true })
  }

  openFilterModal = () => {
    this.setState({ filterModalOpen: true })
  }

  handleDelete = (id) => {
    Swal.fire({
      title: i18n.t("undo_blocked_times"),
      showCancelButton: true,
      cancelButtonText: i18n.t("no"),
      confirmButtonText: i18n.t("yes"),
    }).then((result) => {
      this.setState({ isloading: true })
      this.props.removeRequest(
        {
          id,
          clear: Boolean(result.value),
        },
        {
          success: () => {
            SuccessHelper.handleSuccess(i18n.t("successfully_deleted"), true)
            this.setState({ isloading: false })
          },
          failure: () => {
            ErrorHelper.handleErrors(i18n.t("failed_to_delete"), true)
            this.setState({ isloading: false })
          },
        }
      )
    })
  }

  buttonsRender = () => {
    return (
      <>
        <PrimaryButton
          onClick={() => this.markAllAsAvailable()}
          type="button"
          className="my-1btn-light"
          id="saloon-save-social-link"
        >
          Mark all as available
        </PrimaryButton>

        <PrimaryButton
          onClick={() => this.markAllAsUnavailable()}
          type="button"
          className="my-1btn-light"
          id="saloon-save-social-link"
        >
          Mark all as unavailable
        </PrimaryButton>

        <PrimaryButton
          onClick={() => this.openAdvancedModal()}
          type="button"
          className="my-1btn-light"
          id="saloon-save-social-link"
        >
          {i18n.t("advanced")}
        </PrimaryButton>
      </>
    )
  }

  datePickerRender = () => {
    return (
      <div className="justify-content-lg-start justify-content-md-start justify-content-center d-flex gap-2 mb-4">
        <div className="d-flex align-items-center font-weight-bold ml-1">
          {i18n.t("date")}
        </div>
        <DatePicker
          className="form-control datepicker"
          minDate={new Date()}
          selected={this.state.date}
          onChange={(date) => this.getCompanyUnavailabilitesFromDate(date)}
          value={this.state.date}
          placeholderText={i18n.t("container.services_click_to_select_a_date")}
        />
      </div>
    )
  }
  render() {
    const {
      date,
      availabilities = {},
      toAddMap,
      toDeleteMap,
      advancedModalOpen,
      showAddAdvancedModal,
      optionsModalOpen,
      filterModalOpen,
    } = this.state
    const { screenSize } = this.context
    return (
      <div>
        {this.renderLoader()}
        <div className="p-0 p-md-4" style={{ flexDirection: "row" }}>
          {" "}
          <div
            className="d-sm-flex justify-content-between px-0 col-12"
            style={
              screenSize === "mobile"
                ? {
                    position: "sticky",
                    top: "0",
                    marginBottom: "10px",
                    backgroundColor: "white",
                    borderBottom: "1px solid #E5E5E5",
                    zIndex: 5,
                  }
                : {}
            }
          >
            <h1 className="saloon-dashboard-heading  pt-md-0 pb-4 ">
              {i18n.t("container.employe_dashboard_manage_unavailabilities")}
            </h1>
            {screenSize !== "mobile" ? (
              <div className="row gap-2 justify-content-start mb-4">
                {this.buttonsRender()}
              </div>
            ) : (
              <div className="row gap-2 justify-content-center mb-4">
                <PrimaryButton onClick={() => this.openFilterModal()}>
                  {i18n.t("container.advancedSearchDialog_filter")}
                </PrimaryButton>

                <PrimaryButton onClick={() => this.openOptionsModal()}>
                  Options
                </PrimaryButton>
              </div>
            )}
          </div>
          <div>
            {screenSize !== "mobile" && <>{this.datePickerRender()}</>}

            {Object.keys(availabilities).length ? (
              <>
                <div className="availabilities">
                  {Object.keys(availabilities).map((availability) => {
                    let slotClass = "available "
                    if (typeof availabilities[availability] == "object") {
                      slotClass = "unavailable "
                    } else if (availabilities[availability] == "booked") {
                      slotClass = "booked "
                    }
                    if (toAddMap[availability]) {
                      slotClass += "selected"
                    } else if (toDeleteMap[availability]) {
                      slotClass += "unselected"
                    }
                    return (
                      <div onClick={() => this.toggleSelection(availability)}>
                        <div
                          className={
                            "row justify-content-center gap-2 font-weight-bold my-1 mx-1 py-3 " +
                            slotClass
                          }
                        >
                          <div>{availability}</div>
                          <div>
                            {typeof availabilities[availability] == "object"
                              ? "unavailable"
                              : availabilities[availability]}
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div className="row mt-4 py-3 px-0 col-12 justify-content-center justify-content-md-start">
                  <PrimaryButton onClick={() => this.save()} className="w-128">
                    Save
                  </PrimaryButton>
                </div>
              </>
            ) : (
              <div className="col-12">Nothing found for this day</div>
            )}
            {advancedModalOpen ? (
              <BaseModal
                style={{ zIndex: 1303 }}
                containerClassName={
                  screenSize == "mobile"
                    ? "align-self-end"
                    : "base-model-container align-self-center"
                }
                open={advancedModalOpen}
                {...{
                  title: i18n.t("advanced"),
                  content: (
                    <>
                      {showAddAdvancedModal ? (
                        <BaseModal
                          containerClassName="half-container"
                          open
                          {...{
                            contentClassName: "modify-modal-content",
                            title: i18n.t("add"),
                            content: (
                              <>
                                <ReoccuringUnavailabilities
                                  onClose={() => {
                                    this.setState({
                                      showAddAdvancedModal: false,
                                    })
                                  }}
                                />
                              </>
                            ),
                          }}
                          onClose={() => {
                            this.setState({ showAddAdvancedModal: false })
                          }}
                        />
                      ) : null}
                      <BasicTable
                        rows={this.props.reoccuringUnavailabilties}
                        schema={[
                          {
                            field: "startDate",
                            header: i18n.t("start_date"),
                            accessor: (val) =>
                              moment(val.startDate).format("MM-DD-YYYY"),
                          },
                          {
                            field: "endDate",
                            header: i18n.t("end_date"),
                            accessor: (val) =>
                              moment(val.endDate).format("MM-DD-YYYY"),
                          },
                          {
                            field: "startTime",
                            header: i18n.t("start_time"),
                          },
                          {
                            field: "endTime",
                            header: i18n.t("end_time"),
                          },
                          {
                            field: "createdDate",
                            header: i18n.t("created_date"),
                            accessor: (val) =>
                              moment(val.createdDate).format("MM-DD-YYYY"),
                          },
                          {
                            key: "action",
                            header: i18n.t("actions"),
                            render: (val) => {
                              return (
                                <>
                                  <FontAwesomeIcon
                                    className="view-icon"
                                    style={{ backgroundColor: "#F6943B" }}
                                    color="white"
                                    icon={["fas", "trash"]}
                                    onClick={() =>
                                      deleteConfirmation(() =>
                                        this.handleDelete(val._id)
                                      )
                                    }
                                  />
                                </>
                              )
                            },
                          },
                        ]}
                      />
                      {screenSize == "mobile" && (
                        <div className="row justify-content-center">
                          <PrimaryButton
                            className="mr-3 create-btn w-128"
                            label={i18n.t("create")}
                            onClick={() => {
                              this.setState({ showAddAdvancedModal: true })
                            }}
                          />

                          <PrimaryButton
                            className="close-btn w-128"
                            label={i18n.t("close")}
                            onClick={() => {
                              this.setState({
                                advancedModalOpen: false,
                                showAddAdvancedModal: false,
                              })
                            }}
                          />
                        </div>
                      )}
                    </>
                  ),
                }}
                onClose={() => {
                  this.setState({
                    advancedModalOpen: false,
                    showAddAdvancedModal: false,
                  })
                }}
                showCreateBtn={
                  screenSize !== "mobile"
                    ? () => {
                        this.setState({ showAddAdvancedModal: true })
                      }
                    : null
                }
                showCloseBtn={screenSize !== "mobile" ? true : false}
              />
            ) : null}
            {optionsModalOpen ? (
              <BaseModal
                open={optionsModalOpen}
                {...{
                  title: "Options",
                  content: (
                    <div className="row justify-content-center flex-column gap-2">
                      {" "}
                      {this.buttonsRender()}
                    </div>
                  ),
                }}
                onClose={() => {
                  this.setState({
                    optionsModalOpen: false,
                  })
                }}
              />
            ) : null}
            {filterModalOpen ? (
              <BaseModal
                containerClassName={"base-model-container position-relative"}
                open={filterModalOpen}
                {...{
                  title: i18n.t("container.advancedSearchDialog_filter"),
                  content: (
                    <>
                      <Button
                        className="position-absolute font-weight-bold text-capitalize"
                        style={{ color: "#F6943B", top: "20px", right: "10px" }}
                        onClick={() =>
                          this.getCompanyUnavailabilitesFromDate(Date.now())
                        }
                      >
                        {i18n.t("resetFilter")}
                      </Button>
                      {this.datePickerRender()}
                      <div className="row justify-content-center  gap-2">
                        <PrimaryButton
                          onClick={() =>
                            this.setState({
                              filterModalOpen: false,
                            })
                          }
                          className="w-128"
                        >
                          {i18n.t("close")}
                        </PrimaryButton>
                      </div>
                    </>
                  ),
                }}
                showCloseBtn={false}
              />
            ) : null}
          </div>
        </div>
      </div>
    )
  }
}

ManageUnavailibilites.contextType = ScreenSizeContext

const mapStateToProps = (state) => ({
  reoccuringUnavailabilties: state.reoccuringCompanyUnavailabilties.data,
  isFetching: state.reoccuringCompanyUnavailabilties.isFetching,
})

const action = {
  getRequest,
  removeRequest,
}

export default connect(mapStateToProps, action)(ManageUnavailibilites)
