import React, { useState, useEffect } from "react"
import i18n from "../../../i18n"
import _ from "lodash"
import WorkingHour from "./WorkingHour"
import { WorkingDaysWrapper, WorkingDay } from "./employeeSection.styles"
import PrimaryButton from "../../../components/Core/PrimaryButton/index"
import { ErrorHelper } from "../../../helpers"
import useScreenSize from "../../../context/ScreenSize"
import useActiveMenuType from "../../../context/ActiveMenuType"
import { Translation } from "react-i18next"
export default function EditEmployeeWorkingHours({
  employee,
  handleSave = () => {},
  history,
  hideTitle = false,
}) {
  const [currentSchedule, setCurrentSchedule] = useState([])
  const { screenSize } = useScreenSize()
  const { type } = useActiveMenuType()
  const daysOfWeek = {
    1: "Sunday",
    2: "Monday",
    3: "Tuesday",
    4: "Wednesday",
    5: "Thursday",
    6: "Friday",
    7: "Saturday",
  }
  useEffect(() => {
    const newSchedule = {
      1: {
        availableStatus: 0,
        dayOfWeek: 1,
      },
      2: {
        availableStatus: 0,
        dayOfWeek: 2,
      },
      3: {
        availableStatus: 0,
        dayOfWeek: 3,
      },
      4: {
        availableStatus: 0,
        dayOfWeek: 4,
      },
      5: {
        availableStatus: 0,
        dayOfWeek: 5,
      },
      6: {
        availableStatus: 0,
        dayOfWeek: 6,
      },
      7: {
        availableStatus: 0,
        dayOfWeek: 7,
      },
    }
    if (employee?.weekPlans) {
      employee.weekPlans.map((element) => {
        newSchedule[element.dayOfWeek] = element
      })
    }
    setCurrentSchedule(Object.values(newSchedule))
  }, [employee])

  const handleWorkingHours = (newDay, index) => {
    let newSchedule = [...currentSchedule]
    newSchedule[index] = newDay
    setCurrentSchedule(newSchedule)
  }

  const handleEmployeeSchedule = () => {
    const errored = currentSchedule.find((element) => element.error)
    if (errored) {
      return ErrorHelper.handleErrors(i18n.t("invalid_working_hours"), true)
    }
    handleSave({
      weekPlans: currentSchedule.map(
        ({ availableStatus, checkIn, checkOut, dayOfWeek }) => ({
          availableStatus,
          checkIn,
          checkOut,
          dayOfWeek,
        })
      ),
    })
  }

  return (
    <>
      {!hideTitle && (
        <div
          style={
            screenSize == "mobile"
              ? {
                  position: "fixed",
                  backgroundColor: "white",
                  padding: "10px",
                  top: "0",
                  zIndex: 1,
                  left: "0",
                }
              : {}
          }
        >
          <div className="row mx-0 justify-content-between mb-2">
            <h1 className="saloon-dashboard-heading font-weight-bold align-self-center">
              <Translation>
                {(t) => t("container.add_working_hours")}
              </Translation>{" "}
              &amp; {i18n.t("container.hours")}
            </h1>
          </div>
          <div className="mt-2 d-flex gap-2 mb-3 justify-content-center justify-content-md-end">
            <PrimaryButton
              style={{ backgroundColor: "#F6943B" }}
              type="submit"
              onClick={() =>
                history.push(
                  type === "employee"
                    ? "/employee-manage-unavailabilities"
                    : "/company-manage-unavailabilities"
                )
              }
            >
              {i18n.t("container.employe_dashboard_manage_unavailabilities")}
            </PrimaryButton>

            {employee && screenSize === "mobile" && (
              <PrimaryButton
                onClick={handleEmployeeSchedule}
                label={i18n.t("update_employee_schedule")}
              />
            )}
          </div>
        </div>
      )}
      {employee && (
        <>
          <div className="overflow-auto">
            <WorkingDay
              style={
                screenSize == "mobile"
                  ? {
                      borderTop: "1px solid #E5EBF1",
                      marginTop: !hideTitle && type !== "employee" && "120px",
                      width: "max-content",
                      paddingRight: "15px",
                    }
                  : { borderTop: "1px solid #E5EBF1" }
              }
              className="pt-4"
            >
              <h5 className="px-1 font-weight-bold">{i18n.t("day_of_week")}</h5>
              <h5 className="px-1 font-weight-bold text-center">
                {i18n.t("available")}
              </h5>
              <h5 className="px-1 font-weight-bold">{i18n.t("check_in")}</h5>
              <h5 className="px-1 font-weight-bold">{i18n.t("check_out")}</h5>
            </WorkingDay>
            <WorkingDaysWrapper>
              {currentSchedule.map((day, index) => {
                return (
                  <div
                    key={_.uniqueId()}
                    style={{
                      backgroundColor: index % 2 == 0 && "#F4F6F8",
                      padding: "0.7em",
                      borderTop: "1px solid #E5EBF1",
                      borderBottom:
                        currentSchedule.length - 1 == index &&
                        "1px solid #E5EBF1",
                    }}
                  >
                    <WorkingHour
                      {...{
                        daysOfWeek,
                        day,
                      }}
                      handleWorkingHours={(e) => handleWorkingHours(e, index)}
                    />
                  </div>
                )
              })}
            </WorkingDaysWrapper>
          </div>
          {(screenSize !== "mobile" || hideTitle) && (
            <div className="row justify-content-end mt-4 mx-0">
              <PrimaryButton
                onClick={handleEmployeeSchedule}
                label={i18n.t("update_employee_schedule")}
              />
            </div>
          )}
        </>
      )}
    </>
  )
}
