import { TableCell, TableRow } from "@material-ui/core"
import moment from "moment"
import React from "react"
import { useSelector } from "react-redux"
import BasicTable from "../../../../components/Core/BasicTable"
import i18n from "../../../../i18n"
export default function ActivityReportTables() {
  const activityReport = useSelector(
    (s) => s.activityScheduleEvent.activityReport
  )

  return (
    <div className="d-flex flex-column gap-1">
      <BasicTable
        prependRows={
          <TableRow>
            <TableCell colSpan={9}>
              <div>{activityReport.length} Results</div>
            </TableCell>
          </TableRow>
        }
        rows={activityReport}
        schema={[
          {
            header: i18n.t("activity"),
            field: "activity",
            accessor: ({ activity }) => {
              return activity?.name
            },
          },
          {
            header: i18n.t("number_of_sessions"),
            field: "number_of_sessions",
            accessor: ({ activity }) => {
              return activity?.numberOfSessions || 1
            },
          },

          {
            header: i18n.t("number_of_presences"),
            field: "number_of_presences",
            accessor: ({ customers }) => {
              const attended = customers.filter(customer => {
                if (customer.attended)
                  return customer
              })

              return `${attended.length}/${customers.length}`
            },
          },
        ]}
      />
    </div>
  )
}
