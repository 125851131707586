import { connect } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import i18n from "../../../i18n"
import DotsLoader from "../../DotsLoader"
import Swal from "sweetalert2"
import { ErrorHelper, SuccessHelper } from "../../../helpers"
import { removeRequest } from "../../../redux/actions/Saloon/StudentGroup"
import BasicTable from "../../Core/BasicTable"
import { Images } from "../../../theme"
import DeleteIcon from "../../../components/Core/BasicTable/Actions/DeleteIcon"
import EditIcon from "../../../components/Core/BasicTable/Actions/EditIcon"
import deleteConfirmation from "../../../helpers/DeleteHelper"

function CourseTable({ rows, onEdit, removeRequest }) {
  const [loading, setLoading] = useState(false)
  const handleDelete = (_id) => {
    setLoading(true)
    removeRequest(
      { ids: _id },
      {
        success: () => {
          setLoading(false)
          SuccessHelper.handleSuccess(
            "Your service is successfully deleted.",
            true
          )
        },
        failure: () => {
          setLoading(false)
          ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true)
        },
      }
    )
  }
  return (
    <>
      <DotsLoader isloading={loading} />
      <BasicTable
        maxContentWidth={false}
        rows={rows}
        schema={[
          {
            header: i18n.t("name"),
            field: "name",
          },
          {
            key: "action",
            header: i18n.t("actions"),
            style: { width: 120 },
            render: (val) => {
              return (
                <>
                  <EditIcon
                    onClick={() => {
                      onEdit(val)
                    }}
                  />
                  <DeleteIcon
                    onClick={() => {
                      deleteConfirmation(() => handleDelete(val._id))
                    }}
                  />
                </>
              )
            },
          },
        ]}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  rows: state.studentGroup.data,
})

const action = {
  removeRequest,
}

export default connect(mapStateToProps, action)(CourseTable)
