import React, { useEffect, useMemo, useState } from "react"
import { getRequest as getCustomerGroups } from "../../../redux/actions/Saloon/CompanyCustomerGroups"
import {
  getRequest as getTemplates,
  upsertRequest,
  removeRequest,
  upsertEventRequest,
  removeEventRequest,
} from "../../../redux/actions/Saloon/AutoBookingScheduleTemplate"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import FullCalendar from "@fullcalendar/react"
import interactionPlugin from "@fullcalendar/interaction"
import timeGridPlugin from "@fullcalendar/timegrid"
import dayGridPlugin from "@fullcalendar/daygrid"
import scrollGridPlugin from "@fullcalendar/scrollgrid"
import "./ScheduleTemplateBuilder.scss"
import UpsertScheduleEvent from "./Components/UpsertScheduleEvent"
import DotsLoader from "../../DotsLoader"
import BaseModal from "../../BaseModal"
import i18n from "../../../i18n"
import PrimaryButton from "../../Core/PrimaryButton"
import { ErrorHelper, SuccessHelper } from "../../../helpers"
import deleteConfirmation from "../../../helpers/DeleteHelper"
import Select from "../../Core/Select"
import UpsertTemplate from "./Components/UpsertTemplate"
import useCache from "../../../context/LocalCache"
import useScreenSize from "../../../context/ScreenSize"
export const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
]

const getContrastYIQ = (hexcolor) => {
  if (hexcolor) {
    let color = hexcolor.replace("#", "")
    const r = parseInt(color.substr(0, 2), 16)
    const g = parseInt(color.substr(2, 2), 16)
    const b = parseInt(color.substr(4, 2), 16)
    const yiq = (r * 299 + g * 587 + b * 114) / 1000
    return yiq >= 128 ? "black" : "white"
  }
  return "#000"
}

export default function ScheduleTemplateBuilder() {
  const dispatch = useDispatch()
  const { getResource } = useCache()
  const allServices = getResource((cache) => cache.company.services)
  const employees = getResource((cache) => cache.company.employees)
  const [defaults, setDefaults] = useState({})
  const [addEventOpen, setAddEventOpen] = useState(false)
  const [addTemplateOpen, setAddTemplateOpen] = useState(false)
  const [active, setActive] = useState()
  const [activeTemplateId, setActiveTemplateId] = useState(null)
  const [formAnchorEl, setFormAnchorEl] = React.useState(null)
  const { screenSize } = useScreenSize()
  // const loading = useSelector((s) => s.companyCustomerGroups.isFetching);
  const templates = useSelector((s) => s.autoBookingScheduleTemplate.data)
  const formatTemplate = (template) => ({ ...template, value: template._id })
  const formattedTemplates = useMemo(() => {
    return templates.map(formatTemplate)
  }, [templates])
  const [openFilterModal, setOpenFilterModal] = useState(false)
  const activeTemplate = useMemo(() => {
    return formattedTemplates.find(
      (template) => template.value == activeTemplateId
    )
  }, [activeTemplateId, formattedTemplates])

  const events = activeTemplate?.events || []
  useEffect(() => {
    dispatch(getCustomerGroups({}))
    dispatch(
      getTemplates(
        {},
        {
          success: ({ data }) => {
            if (data.length) {
              setActiveTemplateId(data[0]._id)
            }
          },
        }
      )
    )
  }, [])

  useEffect(() => {
    if (!addEventOpen) {
      setActive()
    }
  }, [addEventOpen])

  const employeeMap = useMemo(() => {
    const map = {}
    employees.map((employee) => {
      map[employee._id] = employee
    })
    return map
  }, [employees])

  const formattedEvents = useMemo(() => {
    return events
      .map((event) => {
        const startDateTime = moment(
          `${moment().format("MM-DD-YYYY")} ${event.startTime}`,
          "MM-DD-YYYY HH:mm"
        )
        const endDateTime = moment(
          `${moment().format("MM-DD-YYYY")} ${event.endTime}`,
          "MM-DD-YYYY HH:mm"
        )
        startDateTime.day(event.dayOfWeek)
        endDateTime.day(event.dayOfWeek)
        const companyEmployee = employeeMap[event.companyEmployeeId]
        const color = companyEmployee?.color || "#000"
        return {
          title: companyEmployee?.employeeId?.userId?.firstName,
          start: startDateTime.toDate(),
          end: endDateTime.toDate(),
          backgroundColor: color,
          textColor: getContrastYIQ(color),
          event: {
            ...event,
          },
        }
      })
      .filter((event) => !!event)
  }, [events, employeeMap])
  const onEventMouseEnter = (e) => {}
  const onEventMouseLeave = (e) => {}
  const onEvenClick = (e) => {
    const event = e.event._def.extendedProps.event
    setAddEventOpen(true)
    setActive(event)
  }
  const onAdd = (data) => {
    dispatch(
      upsertRequest(
        {
          ...data,
        },
        {
          success: ({ data }) => {
            SuccessHelper.handleSuccess(
              i18n.t(
                activeTemplateId ? "successfully_updated" : "successfully_added"
              ),
              true
            )
            setActiveTemplateId(data)
            setAddTemplateOpen(false)
          },
          failure: () => {
            ErrorHelper.handleErrors(
              i18n.t(activeTemplateId ? "failed_to_update" : "failed_to_add"),
              true
            )
          },
        }
      )
    )
  }

  const onDelete = (ids) => {
    deleteConfirmation(() => {
      dispatch(
        removeRequest(
          { ids },
          {
            success: () => {
              SuccessHelper.handleSuccess(i18n.t("successfully_deleted"), true)
              setAddTemplateOpen(false)
              setActiveTemplateId(null)
            },
            failure: () => {
              ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true)
            },
          }
        )
      )
    })
  }

  const onAddEvent = (data) => {
    const { employees = [], _id } = data
    dispatch(
      upsertEventRequest(
        {
          ...data,
          companyEmployeeId: data.employee?._id,
          groups: data.groups.map((group) => group._id),
          services: data.services.map((service) => service._id),
          autoBookingTemplateId: activeTemplateId,
          employees: employees.map((employee) => employee._id),
        },
        {
          success: ({ data }) => {
            SuccessHelper.handleSuccess(
              i18n.t(data._id ? "successfully_updated" : "successfully_added"),
              true
            )
            setActive(data)
            if (!_id) {
              setAddEventOpen(false)
            }
          },
          failure: () => {
            ErrorHelper.handleErrors(
              i18n.t(data._id ? "failed_to_update" : "failed_to_add"),
              true
            )
          },
        }
      )
    )
  }

  const onDeleteEvent = (ids) => {
    deleteConfirmation(() => {
      dispatch(
        removeEventRequest(
          { ids },
          {
            success: () => {
              SuccessHelper.handleSuccess(
                "Your service is successfully deleted.",
                true
              )
              setAddEventOpen(false)
            },
            failure: () => {
              ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true)
            },
          }
        )
      )
    })
  }

  const renderButtons = () => {
    return (
      <>
        <PrimaryButton
          className="w-128"
          label={i18n.t("edit_template")}
          onClick={() => {
            setAddTemplateOpen(true)
            setFormAnchorEl(null)
          }}
        />
        <PrimaryButton
          className="w-128"
          label={i18n.t("add_event")}
          onClick={() => {
            setAddEventOpen(true)
            setDefaults({})
            setFormAnchorEl(null)
          }}
        />
        <PrimaryButton
          className="px-3"
          label={i18n.t("add_new_template")}
          onClick={() => {
            setAddTemplateOpen(true)
            setActiveTemplateId(null)
            setFormAnchorEl(null)
          }}
        />
      </>
    )
  }
  const renderSelect = () => {
    return (
      <>
        <label className="font-weight-bolder mr-3">{i18n.t("template")}</label>
        <div
          style={screenSize === "mobile" ? { width: "100%" } : { width: 300 }}
        >
          <Select
            value={activeTemplateId}
            onChange={(e) => setActiveTemplateId(e.target.value)}
            options={formattedTemplates}
            label={i18n.t("template")}
            variant="outlined"
          />
        </div>
      </>
    )
  }
  return (
    <div className="content-container p-4">
      <DotsLoader isloading={false} />
      <div className="row align-items-center justify-content-between mx-0 ScheduleTemplateBuilder">
        <h1 className="saloon-dashboard-heading mb-4">
          {i18n.t("ai_booking_schedule_template")}
        </h1>
        <div className="d-flex px-0 justify-content-end gap-1">
          {screenSize !== "mobile" ? (
            <>{renderButtons()}</>
          ) : (
            <div className="mb-3">
              <PrimaryButton
                className="w-128"
                label={i18n.t("filters")}
                onClick={() => setOpenFilterModal(true)}
              />
            </div>
          )}
        </div>
      </div>
      {screenSize !== "mobile" ? (
        <div className="row align-items-center mt-3 mb-4">{renderSelect()}</div>
      ) : null}

      <BaseModal
        open={openFilterModal}
        onClose={() => setOpenFilterModal(false)}
        title={i18n.t("filters")}
        content={
          <>
            <div className="d-flex gap-2 flex-wrap mb-3">{renderButtons()}</div>
            <div>{renderSelect()}</div>
          </>
        }
      />
      <UpsertScheduleEvent
        onAdd={onAddEvent}
        allServices={allServices}
        employees={employees}
        open={addEventOpen}
        setOpen={setAddEventOpen}
        defaults={defaults}
        active={active}
        onDelete={onDeleteEvent}
      />
      <UpsertTemplate
        open={addTemplateOpen}
        setOpen={setAddTemplateOpen}
        active={activeTemplate}
        onAdd={onAdd}
        onDelete={onDelete}
      />
      <div className="CalendarContainer plan-calendar-wrapper w-100">
        <FullCalendar
          events={formattedEvents}
          eventMouseEnter={onEventMouseEnter}
          eventMouseLeave={onEventMouseLeave}
          eventClick={onEvenClick}
          slotDuration="00:15:00"
          contentHeight={3400}
          expandRows
          allDaySlot={false}
          stickyHeaderDates
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            interactionPlugin,
            scrollGridPlugin,
          ]}
          stickyFooterScrollbar={true}
          dayMinWidth={screenSize === "mobile" ? 150 : undefined}
          initialView="timeGridWeek"
          dateClick={async (e) => {
            if (!activeTemplateId) {
              return
            }
            const date = moment(e.date)
            setDefaults({
              startTime: date.format("HH:mm"),
              dayOfWeek: daysOfWeek[date.day()],
            })
            setAddEventOpen(true)
          }}
          dayHeaderContent={(e) => daysOfWeek[e.dow]}
          headerToolbar={false}
          eventOverlap={false}
          weekends
        />
      </div>
    </div>
  )
}
