import { customer_age_group } from "../../../config/WebServices"
import SagaBuilder from "../../builders/SagaBuilder"
import actions from "../../actions/Saloon/CustomerAgeGroup"

const customerAgeGroup = SagaBuilder.build({
  type: "CUSTOMER_AGE_GROUP",
  endpoint: customer_age_group,
  actions,
})

export default customerAgeGroup
