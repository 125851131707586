import { connect } from "react-redux"
import React, { useEffect, useState } from "react"
import StudentTable from "../../../components/Saloon/Student/StudentTable"
import StudentUpsert from "../../../components/Saloon/Student/StudentUpsert"
import i18n from "../../../i18n"
import {
  getRequest,
  upsertRequest,
  removeRequest,
} from "../../../redux/actions/Saloon/Student"
import { getRequest as getClassesRequest } from "../../../redux/actions/Saloon/Class"
import PrimaryButton from "../../../components/Core/PrimaryButton"
import { DotsLoader } from "../../../components"
import { ErrorHelper, SuccessHelper } from "../../../helpers"
import useCache from "../../../context/LocalCache"
import SharedStorage from "../../../helpers/Storage"

const Student = ({
  getRequest,
  upsertRequest,
  students = [],
  getClassesRequest,
  loading: propsLoading,
}) => {
  const [companyId, setCompanyId] = useState("")
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [active, setActive] = useState()
  const { getResource } = useCache()
  const customers = getResource((cache) => cache.company.customers)
  const taxes = getResource((cache) => cache.company.activeTaxes)

  const onMount = async () => {
    const company = JSON.parse(await SharedStorage.getItem("company"))
    setCompanyId(company._id)
    setLoading(true)
    getRequest(
      { relations: ["enrollments"] },
      {
        success: () => setLoading(false),
      }
    )
    getClassesRequest({})
  }

  useEffect(() => {
    onMount()
  }, [])
  const onClose = () => {
    setOpen(false)
  }
  const onSave = (data) => {
    setLoading(true)
    const request = upsertRequest
    request(
      { ...data },
      {
        success: (data) => {
          SuccessHelper.handleSuccess(
            i18n.t(data.id ? "successfully_updated" : "successfully_added"),
            true
          )
          setActive(data.data)
          setLoading(false)
        },
        failure: () => {
          ErrorHelper.handleErrors(
            i18n.t(data.id ? "failed_to_update" : "failed_to_add"),
            true
          )
          setLoading(false)
        },
      }
    )
  }
  const onEdit = (data) => {
    const keys = ["address", "postalCode", "city", "province", "country"]
    const addressText = keys
      .map((key) => data.address?.[key])
      .filter((element) => !!element)
      .join(", ")
    setActive({ ...data, addressText })
    setOpen(true)
  }
  return (
    <div className="content-container p-4">
      <DotsLoader isloading={loading || propsLoading} />
      <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mb-4">
        <h1 className="saloon-dashboard-heading pb-3">{i18n.t("student")}</h1>
        <PrimaryButton
          className="w-128"
          label={i18n.t("create")}
          onClick={() => {
            setActive()
            setOpen(true)
          }}
        />
      </div>
      <StudentTable rows={students} onEdit={onEdit} />
      {open && (
        <StudentUpsert
          open={open}
          active={active}
          onClose={onClose}
          onSave={onSave}
          customers={customers}
          taxes={taxes}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  loading: state.student.isFetching,
})

const action = {
  getRequest,
  upsertRequest,
  removeRequest,
  getClassesRequest,
}

export default connect(mapStateToProps, action)(Student)
