import React, { useEffect, useMemo, useRef, useState } from "react"
import i18n from "../../../../i18n"
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator"
import PrimaryButton from "../../../../components/Core/PrimaryButton"
import { BaseModal } from "../../../../components"
import { useSelector } from "react-redux"
import { MenuItem } from "@material-ui/core"
import { InputAdornment } from "@material-ui/core"
export default function ActivityUpsert({ open, onClose, onSave, active = {} }) {
  const form = useRef()
  const [data, setData] = useState({})
  const activityCategories = useSelector((s) => s.activityCategory.data || [])

  useEffect(() => {
    if (open) {
      if (active._id) {
        const copy = { ...active }
        if (!active.price) {
          copy.price = 0
        }
        if (!active.numberOfSessions) {
          copy.numberOfSessions = 1
        }

        setData({ ...copy })
      } else {
        setData({ price: 0, numberOfSessions: 1 })
      }
    }
  }, [open, active._id])

  const handleChange = (key, value) => {
    data[key] = value
    setData({ ...data })
  }
  const handleSubmit = (toSave) => {
    onSave({ ...data, ...toSave })
  }

  return (
    <div>
      {open ? (
        <BaseModal
          open={open}
          title={data._id ? i18n.t("edit_activity") : i18n.t("add_activity")}
          containerClassName="half-container"
          content={
            <div className="d-flex">
              <ValidatorForm
                onSubmit={() => handleSubmit({ name: data.name })}
                ref={form}
                className="w-100 row mx-0"
                onError={(error) => {
                  if (!error.length) {
                    onSave(data)
                  }
                }}
              >
                <div className="col-12 flex-column flex-md-row d-flex align-items-md-center mb-3 px-0">
                  <label className="font-weight-bold" style={{ width: 180 }}>
                    {i18n.t("name")}
                  </label>
                  <TextValidator
                    containerProps={{ className: "w-100" }}
                    label={i18n.t("name")}
                    onChange={(e) => handleChange("name", e.target.value)}
                    value={data.name || ""}
                    validators={["required"]}
                    errorMessages={[i18n.t("name_is_required")]}
                    variant="outlined"
                  />
                </div>
                <div className="col-12 flex-column flex-md-row  d-flex align-items-md-center mb-3 px-0">
                  <label className="font-weight-bold" style={{ width: 180 }}>
                    {i18n.t("price")}
                  </label>
                  <TextValidator
                    containerProps={{ className: "w-100" }}
                    label={i18n.t("price")}
                    onChange={(e) => handleChange("price", e.target.value)}
                    onBlur={(text) => {
                      let num = parseFloat(text.target.value)
                      if (isNaN(num) || num < 0) {
                        num = 0
                      }
                      num = Math.round((num + Number.EPSILON) * 100) / 100
                      handleChange("price", num)
                    }}
                    value={data.price}
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    validators={["required", "minNumber:0"]}
                    errorMessages={[
                      i18n.t("price_is_required"),
                      i18n.t("must_be_positive"),
                    ]}
                    variant="outlined"
                  />
                </div>
                <div className="col-12 flex-column flex-md-row  d-flex align-items-md-center mb-3 px-0">
                  <label className="font-weight-bold" style={{ width: 180 }}>
                    {i18n.t("number_of_sessions")}
                  </label>
                  <TextValidator
                    containerProps={{ className: "w-100" }}
                    label={i18n.t("number_of_sessions")}
                    onChange={(e) =>
                      handleChange("numberOfSessions", e.target.value)
                    }
                    value={data.numberOfSessions}
                    type="number"
                    validators={["required", "minNumber:1"]}
                    errorMessages={[
                      i18n.t("number_of_session_is_required"),
                      i18n.t("must_be_positive"),
                    ]}
                    variant="outlined"
                  />
                </div>
                <div className="col-12 flex-column flex-md-row  d-flex align-items-md-center mb-3 px-0">
                  <label className="font-weight-bold" style={{ width: 180 }}>
                    {i18n.t("category")}
                  </label>
                  <SelectValidator
                    containerProps={{ className: "w-100" }}
                    label={i18n.t("category")}
                    value={data.activityCategoryId || ""}
                    onChange={(e) =>
                      handleChange("activityCategoryId", e.target.value)
                    }
                    select
                    validators={["required"]}
                    errorMessages={[i18n.t("name_is_required")]}
                    variant="outlined"
                  >
                    {activityCategories.map((activityCategory) => {
                      return (
                        <MenuItem value={activityCategory._id}>
                          {activityCategory.name}
                        </MenuItem>
                      )
                    })}
                  </SelectValidator>
                </div>
                <div className="row mx-0 px-0 justify-content-end mt-4 col-12">
                  <PrimaryButton
                    className="w-128"
                    props={{
                      type: "submit",
                    }}
                    label={data._id ? i18n.t("save") : i18n.t("create")}
                  />
                </div>
              </ValidatorForm>
            </div>
          }
          onClose={onClose}
        />
      ) : null}
    </div>
  )
}
