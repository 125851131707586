import * as React from "react"
import i18n from "../../../i18n"
import SpeedDial from "@material-ui/lab/SpeedDial"
import useBooking from "../../../context/Booking"
import "./CreateSpeedDial.css"
import useInvoice from "../../../context/Invoice"
import { useHistory } from "react-router-dom"
import useActiveMenuType from "../../../context/ActiveMenuType"
import useScreenSize from "../../../context/ScreenSize"
import styled from "styled-components/macro"
import useIntakeCustomer from "../../../context/IntakeCustomer/IntakeCustomer"
import { useSelector } from "react-redux"
import useStaffMeeting from "../../../context/StaffMeeting/StaffMeeting"
import SharedStorage from "../../../helpers/Storage"
import { useMemo, useEffect, useState } from "react"

import { ROLES } from "../../../util/enum"
const ActionButton = styled.div`
  font-size: 1rem;
  @media (max-width: 480px) {
    font-size: 0.8rem;
  }
`

export default function CreateSpeedDial() {
  const { type, accountAccess } = useActiveMenuType()
  const { screenSize } = useScreenSize()

  const receptionistAllowedResource = useSelector(
    (s) => s.receptionistAccess.data?.resourceMap
  )
  const managerAllowedResource = useSelector(
    (s) => s.managerAccess.data?.resourceMap
  )
  const ownerAllowedResource = useSelector(
    (s) => s.ownerAccess.data?.resourceMap
  )
  const isSalon = React.useMemo(() => {
    return type !== "employee"
  }, [type])
  const history = useHistory()
  const [open, setOpen] = React.useState(false)
  const { setAddOpen: setStaffMeetingOpen } = useStaffMeeting()
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const { setAddOpen } = useBooking()
  const { setAddOpen: setInvoiceAddOpen } = useInvoice()
  const { setOpen: setIntakeCustomerOpen } = useIntakeCustomer()

  const [saloonData, setSaloonData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const data = await SharedStorage.getItem('saloon');
      setSaloonData(JSON.parse(data));
    };

    fetchData();
  }, []);

  const defaultActions = [
    {
      icon: <ActionButton>{i18n.t("container.my_calendar")}</ActionButton>,
      onClick: () => {
        history.push(
          isSalon ? "/saloon-my-appointment" : "/employee-my-appointment"
        );
      },
    },
    {
      icon: <ActionButton>{i18n.t("create_appointment")}</ActionButton>,
      onClick: () => {
        setAddOpen(true);
      },
    },
    {
      icon: <ActionButton>{i18n.t("create_sale")}</ActionButton>,
      onClick: () => {
        setInvoiceAddOpen(true);
      },
    },
    {
      icon: <ActionButton>{i18n.t("intake_customer")}</ActionButton>,
      onClick: () => {
        setIntakeCustomerOpen(true);
      },
    },
  ];

  const actions = useMemo(() => {
    if (
      ((saloonData?.role || []).includes(ROLES.RECEPTIONIST) &&
        receptionistAllowedResource?.["Booking"]) ||
      ((saloonData?.role || []).includes(ROLES.MANAGER) &&
        managerAllowedResource?.["Booking"]) ||
      ((saloonData?.role || []).includes(ROLES.OWNER) &&
        ownerAllowedResource?.["Booking"])
    ) {
      return [
        ...defaultActions,
        {
          icon: <ActionButton>{i18n.t("staff_meeting")}</ActionButton>,
          onClick: () => {
            setStaffMeetingOpen(true);
            console.log("yoooo");
          },
        },
      ];
    }

    return defaultActions;
  }, [saloonData, defaultActions]);

  if (screenSize == "mobile") {
    return null
  }

  return (
    <>
      {accountAccess ? (
        <div
          style={{
            height: open ? 195 : 64,
            transform: "translateZ(0px)",
            flexGrow: 1,
            position: "fixed",
            bottom: 40,
            right: 15,
            zIndex: 6,
            display: "flex",
          }}
          className="CreateSpeedDial"
        >
          <SpeedDial
            ariaLabel="create"
            sx={{ position: "absolute", right: 32 }}
            icon={<ActionButton>Action</ActionButton>}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
            style={{ alignItems: "flex-end" }}
            className="dialStyles"
            transitionDuration={0}
          >
            {open
              ? actions.map((action) => (
                <div
                  key={action.name}
                  onClick={action.onClick}
                  className="CreateSpeedDialChild"
                >
                  {action.icon}
                </div>
              ))
              : null}
          </SpeedDial>
        </div>
      ) : null}
    </>
  )
}