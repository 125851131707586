// @flow

import { EXPENSE } from "../ActionTypes"

export function getRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: EXPENSE.GET_SAGA,
  }
}

export function get(data) {
  return {
    data,
    type: EXPENSE.GET,
  }
}

export function getReportRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: EXPENSE.GET_REPORT_SAGA,
  }
}

export function getReport(data) {
  return {
    data,
    type: EXPENSE.GET_REPORT,
  }
}

export function removeRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: EXPENSE.DELETE_SAGA,
  }
}

export function remove(data) {
  return {
    data,
    type: EXPENSE.DELETE,
  }
}

export function insertRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: EXPENSE.INSERT_SAGA,
  }
}

export function insert(data) {
  return {
    data,
    type: EXPENSE.INSERT,
  }
}

export function setActive(data) {
  return {
    data,
    type: EXPENSE.SET_ACTIVE,
  }
}

export function requestFailure() {
  return {
    type: EXPENSE.REQUEST_FAILURE,
  }
}
