import React from "react";
import TextField from "@material-ui/core/TextField";

export default ({
    type = "text",
    label,
    value,
    onChange,
    helperText
}) => {
    return (
        <div className="form-group col-md-6 px-0 align-items-center row mx-0 site-form">
            <label className="font-weight-bold">{label}</label>
            <TextField
                type={type}
                label={label}
                value={value}
                onChange={onChange}
                helperText={helperText}
                variant="outlined"
                className="editField"
            />
        </div>
    );
};
