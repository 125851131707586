import Swal from "sweetalert2";

class ErrorHelper {
  Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  handleErrors(err, doAlert, props) {
    if (doAlert) {
      return Swal.fire({
        icon: "error",
        title: "Error",
        text: err,
        confirmButtonColor: "#ff3600",
        ...props,
      });
    }
  }
  handleToastError(msg, doAlert) {
    if (doAlert) {
      if (msg) {
        this.Toast.fire({
          icon: "error",
          title: msg,
        });
      }
    }
  }
}

export default new ErrorHelper();
