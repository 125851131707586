import { connect } from "react-redux"
import React, { useEffect, useRef, useState } from "react"
import { Button } from "@material-ui/core"
import { TextField } from "@material-ui/core"
import Select from "../../../components/Core/Select"
import DraggableDialog from "../../../components/DraggableDialog/DraggableDialog"

import i18n from "../../../i18n"
import Swal from "sweetalert2"
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator"
import PrimaryButton from "../../../components/Core/PrimaryButton"
import moment from "moment"
import DatePicker from "react-datepicker"
import { MenuItem } from "@material-ui/core"
import { BaseModal } from "../../../components"
import _ from "lodash"
import Autocomplete from "@material-ui/lab/Autocomplete"

const opetionFormatter = (element) => ({ ...element, value: element._id })

function EventModal({
  open,
  setOpen,
  active = {},
  onSubmit,
  employees,
  classes,
}) {
  const form = useRef()
  const [data, setData] = useState({})
  const init = (data) => {
    setData({ ...data })
  }
  useEffect(() => {
    init(active)
  }, [])
  useEffect(() => {
    if ((!active._id && !data) || active._id != data?._id) {
      init(active)
    }
  }, [active])

  const handleClose = () => {
    setData(null)
    setOpen(false)
  }

  const handleChange = (key, value) => {
    data[key] = value
    setData({ ...data })
  }

  const handleSubmit = () => {
    const body = _.omit(data, ["date", "startTime", "endTime"])
    const { date, startTime, endTime, classId } = data
    body.classId = classId._id
    const extractedDate = moment(date).format("MM-DD-YYYY")
    body.startDateTime = moment(`${extractedDate} ${startTime}`)
    body.endDateTime = moment(`${extractedDate} ${endTime}`)
    onSubmit(body)
  }
  const checkTimes = () => {
    const { startTime, endTime } = data
    if (startTime && endTime) {
      const momentStart = moment(startTime, "hh:mm")
      const momentEnd = moment(endTime, "hh:mm")
      if (momentStart.isAfter(momentEnd)) {
        setData({ ...data, startTime: endTime, endTime: startTime })
      }
    }
  }
  if (!open || !data) {
    return null
  }
  return (
    <div>
      <BaseModal
        title="Edit Event"
        contentContainerStyle={{
          overflowY: "visible",
        }}
        containerClassName={"half-container"}
        content={
          <ValidatorForm
            onSubmit={handleSubmit}
            ref={form}
            className="w-100 row mx-0 p-3"
            onError={(error) => {}}
          >
            <div className="col-12 flex-column flex-md-row px-0 d-flex align-items-md-center justify-content-between mb-3">
              <label className="font-weight-bold" style={{ minWidth: 120 }}>
                {i18n.t("class")}
              </label>
              <div className="w-100">
                <Autocomplete
                  id="forms-standard"
                  options={classes}
                  getOptionLabel={(option) => option.name}
                  value={data.classId || ""}
                  onChange={(e, value = []) => {
                    handleChange("classId", value)
                  }}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      className="mb-1"
                      value={data.classId || ""}
                      label={i18n.t("class")}
                      validators={["required"]}
                      errorMessages={[i18n.t("this_field_is_required")]}
                      variant="outlined"
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-12 flex-column flex-md-row px-0 d-flex align-items-md-center justify-content-between mb-3">
              <label className="font-weight-bold" style={{ minWidth: 120 }}>
                {i18n.t("employee")}
              </label>
              <div className="w-100">
                <SelectValidator
                  containerProps={{ className: "w-100" }}
                  label={i18n.t("employee")}
                  onChange={(e) => handleChange("employeeId", e.target.value)}
                  value={data.employeeId || ""}
                  select
                  variant="outlined"
                >
                  {employees.map((employee) => {
                    return (
                      <MenuItem value={employee.value}>
                        {employee.name}
                      </MenuItem>
                    )
                  })}
                </SelectValidator>
              </div>
            </div>
            <div className="col-12 px-0 flex-column flex-md-row d-flex align-items-md-center justify-content-between mb-3">
              <label className="font-weight-bold" style={{ minWidth: 120 }}>
                {i18n.t("date")}
              </label>
              <div className="w-100">
                <DatePicker
                  wrapperClassName="w-100"
                  selected={data.date ? moment(data.date).toDate() : ""}
                  onChange={(e) => handleChange("date", e)}
                  value={data.date ? moment(data.date).toDate() : ""}
                  customInput={
                    <TextValidator
                      label={i18n.t("date")}
                      onChange={(e) => handleChange("date", e.target.value)}
                      value={data.date}
                      validators={["required"]}
                      errorMessages={[i18n.t("this_field_is_required")]}
                      variant="outlined"
                    />
                  }
                  placeholderText={i18n.t("end_date")}
                />
              </div>
            </div>
            <div className="col-12 flex-column flex-md-row px-0 d-flex align-items-md-center justify-content-between mb-3">
              <label className="font-weight-bold" style={{ minWidth: 120 }}>
                {i18n.t("start_time")}
              </label>
              <div className="w-100">
                <TextValidator
                  className="w-100"
                  onChange={(e) => handleChange("startTime", e.target.value)}
                  onBlur={checkTimes}
                  type="time"
                  value={data.startTime || ""}
                  validators={["required"]}
                  errorMessages={[i18n.t("this_field_is_required")]}
                  variant="outlined"
                />
              </div>
            </div>
            <div className="col-12 flex-column flex-md-row px-0 d-flex align-items-md-center justify-content-between mb-3">
              <label className="font-weight-bold" style={{ minWidth: 120 }}>
                {i18n.t("end_time")}
              </label>
              <div className="w-100">
                <TextValidator
                  className="w-100"
                  onChange={(e) => handleChange("endTime", e.target.value)}
                  onBlur={checkTimes}
                  type="time"
                  value={data.endTime || ""}
                  validators={["required"]}
                  errorMessages={[i18n.t("this_field_is_required")]}
                  variant="outlined"
                />
              </div>
            </div>
            <div className="row mx-0 px-0 justify-content-end my-4 col-12">
              <PrimaryButton
                className="w-128"
                props={{
                  type: "submit",
                }}
                label={data._id ? i18n.t("save") : i18n.t("create")}
              />
            </div>
          </ValidatorForm>
        }
        onClose={handleClose}
        open={open}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  studentGroups: state.studentGroup.data,
  programs: state.program.data,
  classes: state._class.data,
})

const action = {}

export default connect(mapStateToProps, action)(EventModal)
