import { take, put, call, fork, takeEvery } from "redux-saga/effects"

import * as types from "../actions/ActionTypes"
import { updateById } from "../actions/GetNotifications"
import { success, failure, request } from "../actions/UpdateNotification"

import axios from "axios"
import { notification } from "../../config/WebServices"
import { ErrorHelper } from "../../helpers"

function callRequest(data) {
  return axios.put(notification, data)
}

function* sendRequest(action) {
  try {
    const response = yield call(callRequest, action.payload)
    if (response.data.success) {
      yield put(success(response.data.data))
      yield put(updateById(action.payload))
    } else {
      yield put(failure())
    }
  } catch (err) {
    yield put(failure(err))
  }
}

export default function* root() {
  yield takeEvery(types.UPDATE_NOTIFICATION.REQUEST, sendRequest)
}
