import React from "react"
import i18n from "../../i18n"
import PrimaryButton from "../Core/PrimaryButton"
import SecondaryButton from "../Core/SecondaryButton/SecondaryButton"

const ToggleSelect = ({
  value,
  onChange = () => { },
  active,
  inactive
}) => {

  const handleChange = (val) => {
    onChange(val)
  }

  return (
    <div className="row align-items-center px-0">
      {value === true ? (
        <div className="d-flex">
          <SecondaryButton className="w-98" onClick={() => handleChange(false)}>
            {i18n.t(inactive)}
          </SecondaryButton>
          <PrimaryButton className="w-98" onClick={() => handleChange(true)}>
            {i18n.t(active)}
          </PrimaryButton>
        </div>
      ) : (
        <div className="d-flex">
          <PrimaryButton className="w-98" onClick={() => handleChange(false)}>
            {i18n.t(inactive)}
          </PrimaryButton>
          <SecondaryButton className="w-98" onClick={() => handleChange(true)}>
            {i18n.t(active)}
          </SecondaryButton>
        </div>
      )}
    </div>
  )
}

export default ToggleSelect
