import { connect } from "react-redux"
import React, { Component } from "react"
import ReactDOM from "react-dom"
import "./index.css"
import { I18nextProvider } from "react-i18next"
import i18n from "./i18n"
import App from "./App"
import translationEng from "../src/locales/en/translation.json"
import translationFr from "../src/locales/fr/translation.json"
import * as serviceWorker from "./serviceWorker"
import { Provider } from "react-redux"
import store from "./redux/store"
import { request as impersonateEmployee } from "./redux/actions/Employee/ImpersonateEmployee"
import { request as impersonateSalon } from "./redux/actions/Saloon/ImpersonateSalon"
import { request as impersonateUser } from "./redux/actions/ImpersonateUser"
import axios from "axios"
import { library } from "@fortawesome/fontawesome-svg-core"

import {
  faCheckSquare,
  faCoffee,
  faAngleDown,
  faImages,
  faMinusCircle,
  faEye,
  faCog,
  faCheckCircle,
  faSlidersH,
  faCaretUp,
  faComment,
  faTrash,
  faFlag,
  faMinus,
  faPlus,
  faFileDownload,
  faHandHoldingUsd,
} from "@fortawesome/free-solid-svg-icons"
import { faFileExcel } from "@fortawesome/free-regular-svg-icons"
import {
  faCcVisa,
  faCcMastercard,
  faCcAmex,
  faCcDiscover,
  faMicrosoft,
  faGoogle,
} from "@fortawesome/free-brands-svg-icons"
// import axios from "axios";
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import SharedStorage from "./helpers/Storage"
Math.roundTo2Decimals = (x) => Math.round((x + Number.EPSILON) * 100) / 100
let stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK)

library.add(
  faCheckSquare,
  faCoffee,
  faAngleDown,
  faFileExcel,
  faImages,
  faMinusCircle,
  faEye,
  faCog,
  faCheckCircle,
  faSlidersH,
  faCaretUp,
  faComment,
  faTrash,
  faFlag,
  faCcVisa,
  faCcMastercard,
  faCcAmex,
  faCcDiscover,
  faMinus,
  faPlus,
  faFileDownload,
  faHandHoldingUsd,
  faMicrosoft,
  faGoogle
)

const locale = navigator.language
let lang
if (locale === "en") {
  lang = translationEng
} else {
  lang = translationFr
}

const messageHandler = (e, self) => {
  if (
    e.origin == "https://admin.easy1booking.com" ||
    // dev only
    e.origin == "http://localhost:3001"
  ) {
    const data = JSON.parse(e.data)
    if (data.type == "employee") {
      self.props.impersonateEmployee(data.id)
    } else if (data.type == "salon") {
      self.props.impersonateSalon(data.id)
    } else if (data.type == "user") {
      self.props.impersonateUser(data.id)
    }
    SharedStorage.setItem("impersonationId", data.id)
  }
}
class ImpersonationProvider extends Component {
  componentDidMount() {
    axios.interceptors.request.use(async (req) => {
      const impersonationId = await SharedStorage.getItem("impersonationId")
      if (impersonationId) {
        req.headers["impersonationId"] = impersonationId
      }

      req.headers.Authorization =
        req.headers.Authorization ||
        (await SharedStorage.getItem("access_token"))

      return req
    })
    window.addEventListener("message", (e) => messageHandler(e, this))
  }

  componentWillUnmount() {
    window.removeEventListener("message", messageHandler)
  }

  render() {
    return this.props.children
  }
}

const mapStateToProps = (state) => ({})

const action = {
  impersonateEmployee,
  impersonateSalon,
  impersonateUser,
}

const ImpersonationProviderComponent = connect(
  mapStateToProps,
  action
)(ImpersonationProvider)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ImpersonationProviderComponent>
        <Elements stripe={stripePromise}>
          <App />
          <ToastContainer />
        </Elements>
      </ImpersonationProviderComponent>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
