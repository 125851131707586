import React, { useEffect, useMemo, useState } from "react"
import i18n from "../../../i18n"
import NumberFormat from "react-number-format"
import PrimaryButton from "../../Core/PrimaryButton"
import BasicTable from "../../Core/BasicTable"
import { getNestedValue } from "../../../util/objectMethods"
import useActiveMenuType from "../../../context/ActiveMenuType"
import { set } from "../../../redux/actions/ActiveBooking"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import {
  getBookingById,
  getCustomerBookingHistory,
} from "../../../config/simpleApiCalls"
import SharedStorage from "../../../helpers/Storage"
const bookingStatuses = [
  { text: "Waiting", value: 1, styles: { color: "#aa43b5" } },
]

export default function CurrentBookingTable({
  setLoading,
  isGym,
  handleDrawerClose = () => {},
  companyCustomerId,
  companyId,
  setShowCustomerCurrentBookings = () => {},
}) {
  const dispatch = useDispatch()
  const history = useHistory()
  const { type } = useActiveMenuType()
  const isSalon = useMemo(() => {
    return type && type != "employee"
  }, [type])
  const [bookings, setBookings] = useState([])
  useEffect(async () => {
    setLoading(true)
    const { data } = await getCustomerBookingHistory({
      _id: companyCustomerId,
      companyId,
    })
    let renderBooking = data.data
      ?.reverse()
      .map((booking) => {
        if (booking.status != 1) {
          return null
        }
        booking.taxTotal = getNestedValue(booking, "taxes", []).reduce(
          (accum, element) => accum + element.amount,
          0
        )
        booking.total =
          booking.totalAmount + (booking.tip || 0) + booking.taxTotal

        return booking
      })
      .filter((booking) => !!booking)
    setBookings(renderBooking)
    setLoading(false)
  }, [])

  const additionalColumn = useMemo(() => {
    if (isGym) {
      return []
    }
    return [
      {
        header: i18n.t("by_price"),
        field: "price",
        accessor: (val) => {
          return `$ ${(val.totalAmount || 0).toFixed(2)}`
        },
      },
      {
        header: i18n.t("taxes"),
        field: "taxes",
        accessor: (val) => {
          return `$ ${(val.taxTotal || 0).toFixed(2)}`
        },
      },
      {
        header: i18n.t("tip"),
        field: "tip",
        accessor: (val) => {
          return `$ ${(val.tip || 0).toFixed(2)}`
        },
      },

      {
        header: i18n.t("grand_total"),
        field: "grand_total",
        accessor: (val) => {
          return `$ ${val.total?.toFixed(2)}`
        },
      },
    ]
  }, [isGym])

  return (
    <div>
      <BasicTable
        rows={bookings}
        schema={[
          {
            header: i18n.t("container.advancedSearchDialog_name"),
            field: "name",
            render: (val) => {
              return (
                <div className="d-flex align-items-start flex-column">
                  {val.userId && val.userId.userName}
                  <span className="appointment-details-employee-other">
                    {getNestedValue(val, ["services", 0, "date"], "")}{" "}
                    {getNestedValue(val, ["services", 0, "time"], "")}
                  </span>
                </div>
              )
            },
          },
          {
            header: i18n.t("container.profile_employe"),
            field: "employee",
            accessor: (val) => {
              return (
                getNestedValue(
                  val,
                  ["services", 0, "employeeId", "userId", "userName"],
                  ""
                ) ||
                `${getNestedValue(
                  val,
                  ["services", 0, "employeeId", "userId", "firstName"],
                  ""
                )} ${getNestedValue(
                  val,
                  ["services", 0, "employeeId", "userId", "lastName"],
                  ""
                )}`
              )
            },
          },
          {
            header: i18n.t("container.employe_phone_number"),
            field: "phone",
            render: (val) => {
              return (
                <div className="d-flex align-items-center mt-1">
                  <span className="appointment-details-employee-name">
                    {" "}
                    <span>
                      <NumberFormat
                        type={"text"}
                        style={{ border: 0 }}
                        value={val.phoneNo}
                        disabled
                        format="### ### ####"
                      />
                      <div className="appointment-details-employee-name">
                        {" "}
                        {val.email || ""}{" "}
                      </div>
                    </span>
                  </span>
                </div>
              )
            },
          },
          {
            header: i18n.t("container.profile_service"),
            field: "service",
            accessor: (val) => {
              return getNestedValue(
                val,
                ["services", 0, "serviceId", "name"],
                ""
              )
            },
          },
          ...additionalColumn,
          {
            header: i18n.t("container.profile_status"),
            field: "status",
            render: (val) => {
              return (
                <span
                  style={{
                    fontWeight: "bold",
                    ...getNestedValue(
                      bookingStatuses,
                      [val.status - 1, "styles"],
                      {}
                    ),
                  }}
                  className="appointment-details-employee-name"
                >
                  {getNestedValue(
                    bookingStatuses,
                    [val.status - 1, "text"],
                    ""
                  )}
                </span>
              )
            },
          },
          {
            header: i18n.t("view"),
            field: "view",
            render: (val) => {
              const [shouldRenderButton, setShouldRenderButton] =
                useState(false)

              useEffect(() => {
                const checkShouldRenderButton = async () => {
                  const employee = await SharedStorage.getItem("employee")
                  const shouldRender =
                    isSalon ||
                    JSON.parse(employee)?._id ===
                      getNestedValue(
                        val,
                        ["services", 0, "employeeId", "userId", "_id"],
                        ""
                      )
                  setShouldRenderButton(shouldRender)
                }

                checkShouldRenderButton()
              }, [isSalon, val])

              const handleClick = async () => {
                const { data } = await getBookingById({
                  id: val._id,
                  relations: ["extras", "cart"],
                })

                handleDrawerClose()
                setShowCustomerCurrentBookings(false)

                if (data.success) {
                  dispatch(
                    set({ ...data.data, addServiceQuestionPopup: false })
                  )
                }

                history.push(
                  isSalon ? "saloon-my-appointment" : "employee-my-appointment"
                )
              }

              return shouldRenderButton ? (
                <PrimaryButton label={i18n.t("view")} onClick={handleClick} />
              ) : null
            },
          },
        ]}
      />
    </div>
  )
}
