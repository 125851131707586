// @flow
import Immutable from "seamless-immutable"
import * as types from "../actions/ActionTypes"

const initialState = Immutable({
  isFetching: false,
  errorMessage: "",
  data: [],
  userGiftCards: [],
  soldGiftCards: [],
  active: null,
})

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GIFT_CARDS.GET_SAGA:
      return Immutable.merge(state, {
        isFetching: true,
      })
    case types.GIFT_CARDS.GET:
      return Immutable.merge(state, {
        isFetching: false,
        data: action.data,
      })
    case types.GIFT_CARDS.INSERT: {
      const newState = { ...state, isFetching: false, data: [...state.data] }
      const { _id } = action.data
      const index = state.data.findIndex((element) => element._id == _id)
      newState.data.unshift(action.data)
      return newState
    }
    case types.GIFT_CARDS.GET_USER_GIFT_CARDS:
    case types.GIFT_CARDS.GET_USER_WITH_COMPANY_GIFT_CARDS: {
      const userGiftCards = action.data
      return Immutable.merge(state, {
        isFetching: false,
        userGiftCards,
      })
    }
    case types.GIFT_CARDS.GET_USER_GIFT_CARDS_SAGA:
    case types.GIFT_CARDS.GET_USER_WITH_COMPANY_GIFT_CARDS_SAGA:
      return Immutable.merge(state, {
        isFetching: true,
      })
    case types.GIFT_CARDS.INSERT_SAGA:
      return Immutable.merge(state, {
        isFetching: true,
      })
    case types.GIFT_CARDS.DELETE: {
      const newState = { ...state, isFetching: false, data: [...state.data] }
      const { _id } = action.data
      const index = state.data.findIndex((element) => element._id == _id)
      if (index != -1) {
        newState.data.splice(index, 1)
      }
      return newState
    }
    case types.GIFT_CARDS.DELETE_SAGA:
      return Immutable.merge(state, {
        isFetching: true,
      })
    case types.GIFT_CARDS.UPDATE: {
      const newState = { ...state, isFetching: false, data: [...state.data] }
      const { _id } = action.data
      const index = state.data.findIndex((element) => element._id == _id)
      if (index != -1) {
        newState.data[index] = action.data
      } else {
        newState.data.unshift(action.data)
      }
      return newState
    }
    case types.GIFT_CARDS.UPDATE_SAGA:
      return Immutable.merge(state, {
        isFetching: true,
      })
    case types.GIFT_CARDS.SOLD: {
      return Immutable.merge(state, {
        isFetching: false,
      })
    }
    case types.GIFT_CARDS.SOLD_SAGA:
      return Immutable.merge(state, {
        isFetching: true,
      })
    case types.GIFT_CARDS.GET_SOLD: {
      return Immutable.merge(state, {
        soldGiftCards: action.data,
        isFetching: false,
      })
    }
    case types.GIFT_CARDS.GET_SOLD_SAGA:
      return Immutable.merge(state, {
        isFetching: true,
      })
    case types.GIFT_CARDS.REQUEST_FAILURE:
      return Immutable.merge(state, {
        isFetching: false,
      })
    case types.GIFT_CARDS.SET_ACTIVE:
      return Immutable.merge(state, {
        active: action.data,
      })
    case types.LOGOUT:
      return initialState
    case types.CLEAR_LOGOUT:
      return initialState
    default:
      return state
  }
}
