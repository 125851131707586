// @flow

import { PROGRAM } from "../ActionTypes"

export function getRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: PROGRAM.GET_SAGA,
  }
}

export function get(data) {
  return {
    data,
    type: PROGRAM.GET,
  }
}

export function removeRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: PROGRAM.DELETE_SAGA,
  }
}

export function remove(data) {
  return {
    data,
    type: PROGRAM.DELETE,
  }
}

export function upsertRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: PROGRAM.UPSERT_SAGA,
  }
}

export function upsert(data) {
  return {
    data,
    type: PROGRAM.UPSERT,
  }
}

export function removeClassRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: PROGRAM.DELETE_CLASS_SAGA,
  }
}

export function removeClass(data) {
  return {
    data,
    type: PROGRAM.DELETE_CLASS,
  }
}

export function addClassRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: PROGRAM.ADD_CLASS_SAGA,
  }
}

export function addClass(data) {
  return {
    data,
    type: PROGRAM.ADD_CLASS,
  }
}

export function requestFailure() {
  return {
    type: PROGRAM.REQUEST_FAILURE,
  }
}
