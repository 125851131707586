import React, { useEffect, useMemo, useRef, useState } from "react"
import { getRequest as getCustomerGroups } from "../../../redux/actions/Saloon/CompanyCustomerGroups"
import {
  getRequest as getSchedule,
  insertRequest,
  removeRequest,
  applyTemplateRequest,
  upsertEventRequest,
  removeEventRequest,
  removeAllEventsRequest,
  updateRequest,
} from "../../../redux/actions/Saloon/AutoBookingSchedule"
import { getRequest as getTemplates } from "../../../redux/actions/Saloon/AutoBookingScheduleTemplate"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import FullCalendar from "@fullcalendar/react"
import interactionPlugin from "@fullcalendar/interaction"
import timeGridPlugin from "@fullcalendar/timegrid"
import dayGridPlugin from "@fullcalendar/daygrid"
import scrollGridPlugin from "@fullcalendar/scrollgrid"
import "./ScheduleBuilder.scss"
import UpsertScheduleEvent from "./Components/UpsertScheduleEvent"
import { DotsLoader } from "../.."
import i18n from "../../../i18n"
import PrimaryButton from "../../Core/PrimaryButton"
import { ErrorHelper, SuccessHelper } from "../../../helpers"
import deleteConfirmation from "../../../helpers/DeleteHelper"
import ReactDatePicker from "react-datepicker"
import { IconButton, TextField } from "@material-ui/core"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import ApplyTemplate from "./Components/ApplyTemplate"
import UpsertSchedule from "./Components/UpsertSchedule"
import useCache from "../../../context/LocalCache"
import useScreenSize from "../../../context/ScreenSize"
const getContrastYIQ = (hexcolor) => {
  if (hexcolor) {
    let color = hexcolor.replace("#", "")
    const r = parseInt(color.substr(0, 2), 16)
    const g = parseInt(color.substr(2, 2), 16)
    const b = parseInt(color.substr(4, 2), 16)
    const yiq = (r * 299 + g * 587 + b * 114) / 1000
    return yiq >= 128 ? "black" : "white"
  }
  return "#000"
}

export default function ScheduleBuilder() {
  const dispatch = useDispatch()
  const { getResource } = useCache()
  const allServices = getResource((cache) => cache.company.services)
  const calendarRef = useRef(null)
  const employees = getResource((cache) => cache.company.employees)
  const [defaults, setDefaults] = useState({})
  const [addEventOpen, setAddEventOpen] = useState(false)
  const [upsertScheduleOpen, setUpsertScheduleOpen] = useState(false)
  const [applyTemplateOpen, setApplyTemplateOpen] = useState(false)

  const [active, setActive] = useState()
  const [date, setDate] = useState(Date.now())

  const { screenSize } = useScreenSize()

  // const loading = useSelector((s) => s.companyCustomerGroups.isFetching);
  const schedules = useSelector((s) => s.autoBookingSchedule.data)

  const selectedSchedule = useMemo(() => {
    const schedule = schedules.find(
      (schedule) =>
        moment(schedule.startDate).format("MM-DD-YYYY") ==
        moment(date).format("MM-DD-YYYY")
    )
    return schedule || {}
  }, [schedules, date])
  const { events = [], _id: autoBookingScheduleId } = selectedSchedule

  useEffect(() => {
    dispatch(getCustomerGroups({}))
    dispatch(getSchedule())
    dispatch(getTemplates())
    const momented = moment(date)
    momented.day(0)
    setDate(momented.toDate())
  }, [])

  useEffect(() => {
    if (!addEventOpen) {
      setActive()
    }
  }, [addEventOpen])

  const [employeeMap = {}, workingDayUnavailabilities] = useMemo(() => {
    const map = {}
    const workingDayUnavailabilities = []
    employees.map((employee) => {
      const tempDate = moment(date)
      map[employee._id] = employee
      if (employee.displayWorkingHours) {
        employee.weekPlans.map((workingDay, index) => {
          const formatted = tempDate.day(index).format("MM-DD-YYYY")
          const common = {
            date,
            companyEmployeeId: employee._id,
            allDay: false,
            type: "unavailability",
            className: "unavailability-event",
          }
          if (workingDay?.availableStatus) {
            workingDayUnavailabilities.push({
              start: moment(`${formatted} 00:00`, "MM-DD-YYYY HH:mmm").toDate(),
              end: moment(
                `${formatted} ${workingDay.checkIn}`,
                "MM-DD-YYYY HH:mmm"
              ).toDate(),
              ...common,
            })
            workingDayUnavailabilities.push({
              start: moment(
                `${formatted} ${workingDay.checkOut}`,
                "MM-DD-YYYY HH:mmm"
              ).toDate(),
              end: moment(`${formatted} 23:59`, "MM-DD-YYYY HH:mmm").toDate(),
              ...common,
            })
          } else {
            workingDayUnavailabilities.push({
              start: moment(`${date} 00:00`, "MM-DD-YYYY HH:mmm").toDate(),
              end: moment(`${date} 23:59`, "MM-DD-YYYY HH:mmm").toDate(),
              ...common,
            })
          }
        })
      }
    })
    return [map, workingDayUnavailabilities]
  }, [employees])

  const formattedEvents = useMemo(() => {
    return [
      ...events
        .map((event) => {
          const startDateTime = moment(
            `${moment(event.date).format("MM-DD-YYYY")} ${event.startTime}`,
            "MM-DD-YYYY HH:mm"
          )
          const endDateTime = moment(
            `${moment(event.date).format("MM-DD-YYYY")} ${event.endTime}`,
            "MM-DD-YYYY HH:mm"
          )
          const companyEmployee = employeeMap[event.companyEmployeeId]
          const color = companyEmployee?.color || "#000"
          return {
            title: companyEmployee?.employeeId?.userId?.firstName,
            start: startDateTime.toDate(),
            end: endDateTime.toDate(),
            backgroundColor: color,
            textColor: getContrastYIQ(color),
            event: {
              ...event,
            },
          }
        })
        .filter((event) => !!event),
      ...workingDayUnavailabilities.map((element) => {
        const companyEmployee = employeeMap[element.companyEmployeeId]
        const color = companyEmployee?.color || "#000"
        return {
          ...element,
          textColor: getContrastYIQ(color),
          color: color,
          title: ` ${companyEmployee?.employeeId?.userId?.firstName} - ${i18n.t(
            "unavailable"
          )}`,
        }
      }),
    ]
  }, [events, employeeMap, workingDayUnavailabilities])
  const onEventMouseEnter = (e) => {}
  const onEventMouseLeave = (e) => {}
  const onEvenClick = (e) => {
    if (e.event._def.extendedProps.type !== "unavailability") {
      const event = e.event._def.extendedProps.event
      setAddEventOpen(true)
      setActive(event)
    }
  }

  const onSave = (data) => {
    const saveFunction = data._id ? updateRequest : insertRequest
    dispatch(
      saveFunction(
        {
          ...data,
        },
        {
          success: () => {
            SuccessHelper.handleSuccess(
              i18n.t(data._id ? "successfully_updated" : "successfully_added"),
              true
            )
            setUpsertScheduleOpen(false)
            setDate(data.startDate)
          },
          failure: () => {
            ErrorHelper.handleErrors(
              i18n.t(data._id ? "failed_to_update" : "failed_to_add"),
              true
            )
          },
        }
      )
    )
  }

  const onDelete = (ids) => {
    deleteConfirmation(() => {
      dispatch(
        removeRequest(
          { ids },
          {
            success: () => {
              SuccessHelper.handleSuccess(
                "Your service is successfully deleted.",
                true
              )
              setAddEventOpen(false)
            },
            failure: () => {
              ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true)
            },
          }
        )
      )
    })
  }

  const onDeleteAllEvents = (_id) => {
    deleteConfirmation(() => {
      dispatch(
        removeAllEventsRequest(
          { _id },
          {
            success: () => {
              SuccessHelper.handleSuccess(i18n.t("success"), true)
              setAddEventOpen(false)
            },
            failure: (err) => {
              ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true)
            },
          }
        )
      )
    })
  }

  const onAddEvent = (data) => {
    const { employees = [], _id } = data
    dispatch(
      upsertEventRequest(
        {
          ...data,
          companyEmployeeId: data.employee?._id,
          groups: data.groups.map((group) => group._id),
          services: data.services.map((service) => service._id),
          employees: employees.map((employee) => employee._id),
          autoBookingScheduleId,
        },
        {
          success: ({ data }) => {
            SuccessHelper.handleSuccess(i18n.t("successfully_added"), true)
            setActive(data)
            if (!_id) {
              setAddEventOpen(false)
            }
          },
          failure: () => {
            ErrorHelper.handleErrors(i18n.t("failed_to_add"), true)
          },
        }
      )
    )
  }

  const onDeleteEvent = (ids) => {
    deleteConfirmation(() => {
      dispatch(
        removeEventRequest(
          { ids },
          {
            success: () => {
              SuccessHelper.handleSuccess(i18n.t("successfully_deleted"), true)
              setAddEventOpen(false)
            },
            failure: () => {
              ErrorHelper.handleErrors(i18n.t("failed_to_delete"), true)
            },
          }
        )
      )
    })
  }

  const onApply = (autoBookingTemplateId) => {
    dispatch(
      applyTemplateRequest(
        { autoBookingTemplateId, autoBookingScheduleId },
        {
          success: () => {
            SuccessHelper.handleSuccess(i18n.t("applied_template"), true)
            setApplyTemplateOpen(false)
          },
          failure: () => {
            ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true)
          },
        }
      )
    )
  }

  const onArrowClick = (forward) => {
    const displacement = 7
    setDate(
      moment(date)
        .add((forward ? 1 : -1) * displacement, "d")
        .toDate()
    )
  }
  useEffect(() => {
    if (calendarRef.current) {
      calendarRef.current.getApi().changeView("timeGridWeek", date)
    }
  }, [date])

  return (
    <div className="content-container p-4">
      <DotsLoader isloading={false} />
      <div className="row align-items-center justify-content-between mx-0 ScheduleTemplateBuilder mb-4">
        <h1 className="saloon-dashboard-heading">{i18n.t("schedule")}</h1>
        {autoBookingScheduleId ? (
          <div className="d-flex px-0 justify-content-end gap-1">
            <PrimaryButton
              className="w-128"
              label={i18n.t("apply_template")}
              onClick={() => {
                setApplyTemplateOpen(true)
              }}
            />
            <PrimaryButton
              className="w-128"
              label={i18n.t("add_event")}
              onClick={() => {
                setAddEventOpen(true)
              }}
            />
            <PrimaryButton
              className="px-3"
              label={i18n.t("delete_all_events")}
              onClick={() => {
                onDeleteAllEvents(autoBookingScheduleId)
              }}
            />
            <PrimaryButton
              className="w-128"
              label={i18n.t("edit_schedule")}
              onClick={() => {
                setUpsertScheduleOpen(true)
              }}
            />
          </div>
        ) : (
          <>
            <PrimaryButton
              className="w-128"
              label={i18n.t("create_schedule")}
              onClick={() => {
                setUpsertScheduleOpen(true)
              }}
            />
          </>
        )}
      </div>
      <div className="row mx-0 ScheduleBuilder mb-4">
        <UpsertScheduleEvent
          onAdd={onAddEvent}
          allServices={allServices}
          employees={employees}
          open={addEventOpen}
          setOpen={setAddEventOpen}
          defaults={defaults}
          active={active}
          onDelete={onDeleteEvent}
          selectedSchedule={selectedSchedule}
        />
        <ApplyTemplate
          open={applyTemplateOpen}
          setOpen={setApplyTemplateOpen}
          onApply={onApply}
        />
        <UpsertSchedule
          open={upsertScheduleOpen}
          setOpen={setUpsertScheduleOpen}
          onSave={onSave}
          defaults={{ startDate: date }}
          active={selectedSchedule}
        />
        <div className="d-flex align-items-center TopBar gap-2 mb-4">
          <IconButton
            aria-label="previous"
            className="arrow-btn"
            onClick={() => onArrowClick()}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <ReactDatePicker
            selected={date}
            dateFormat="MM/dd/yyyy"
            onChange={(val) => {
              setDate(val)
            }}
            filterDate={(date) => {
              return date.getDay() === 0
            }}
            customInput={
              <TextField
                value={date}
                className="full-width"
                readOnly={true}
                variant="outlined"
              />
            }
          />
          <IconButton
            aria-label="next"
            className="arrow-btn"
            onClick={() => onArrowClick(true)}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </div>
        <div className="CalendarContainer plan-calendar-wrapper w-100">
          <FullCalendar
            events={formattedEvents}
            eventMouseEnter={onEventMouseEnter}
            eventMouseLeave={onEventMouseLeave}
            eventClick={onEvenClick}
            slotDuration="00:15:00"
            contentHeight={3400}
            expandRows
            allDaySlot={false}
            stickyHeaderDates
            stickyFooterScrollbar={true}
            dayMinWidth={screenSize === "mobile" ? 150 : undefined}
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              scrollGridPlugin,
            ]}
            initialView="timeGridWeek"
            dateClick={async (e) => {
              if (autoBookingScheduleId) {
                const date = moment(e.date)
                setDefaults({
                  startTime: date.format("HH:mm"),
                  date: date.toDate(),
                })
                setAddEventOpen(true)
              }
            }}
            headerToolbar={false}
            eventOverlap={false}
            ref={calendarRef}
            weekends
          />
        </div>
      </div>
    </div>
  )
}
