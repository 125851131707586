import { connect } from "react-redux"
import React, { useEffect, useRef, useState } from "react"
import i18n from "../../../../i18n"
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator"
import PrimaryButton from "../../../../components/Core/PrimaryButton"
import { BaseModal } from "../../../../components"
import { InputAdornment } from "@material-ui/core"
import MenuItem from "@material-ui/core/MenuItem"
import DatePicker from "react-datepicker"
import moment from "moment/moment"

function GiftCardsUpsert({
  open,
  onClose,
  onSave,
  active = { status: "Active" },
}) {
  const form = useRef()
  const [data, setData] = useState({})

  useEffect(() => {
    if (open) {
      setData({
        ...active,
      })
    }
  }, [open, active._id])

  const handleChange = (key, value) => {
    data[key] = value
    setData({ ...data })
  }

  const handleSubmit = () => {
    onSave({ ...data })
  }

  return (
    <div>
      {open ? (
        <BaseModal
          open={open}
          title={data._id ? i18n.t("edit_gift_card") : i18n.t("add_gift_card")}
          containerClassName="half-container"
          content={
            <div className="d-flex">
              <ValidatorForm
                onSubmit={handleSubmit}
                ref={form}
                className="w-100"
                onError={(error) => {
                  if (!error.length) {
                    onSave(data)
                  }
                }}
              >
                <div className="col-12 flex-column flex-md-row px-0 d-flex align-items-md-center mb-3">
                  <label
                    className="font-weight-bold"
                    style={{ minWidth: "150px" }}
                  >
                    {i18n.t("name")}
                  </label>
                  <div className="w-100">
                    <TextValidator
                      label={i18n.t("name")}
                      onChange={(e) => handleChange("name", e.target.value)}
                      value={data.name}
                      validators={["required"]}
                      errorMessages={[i18n.t("name_is_required")]}
                      variant="outlined"
                    />
                  </div>
                </div>
                <div className="col-12 flex-column flex-md-row px-0 d-flex align-items-md-center mb-3">
                  <label
                    className="font-weight-bold"
                    style={{ minWidth: "150px" }}
                  >
                    {i18n.t("description")}
                  </label>
                  <div className="w-100">
                    <TextValidator
                      label={i18n.t("description")}
                      onChange={(e) =>
                        handleChange("description", e.target.value)
                      }
                      value={data.description}
                      validators={["required"]}
                      errorMessages={[i18n.t("description_is_required")]}
                      rows={2}
                      variant="outlined"
                    />
                  </div>
                </div>
                <div className="col-12 flex-column flex-md-row px-0 d-flex align-items-md-center mb-3">
                  <label
                    className="font-weight-bold"
                    style={{ minWidth: "150px" }}
                  >
                    {i18n.t("status")}
                  </label>
                  <div className="w-100">
                    <SelectValidator
                      label={i18n.t("status")}
                      onChange={(e) => {
                        console.log("j")
                        handleChange("status", e.target?.value)
                      }}
                      value={data.status || ""}
                      validators={["required"]}
                      errorMessages={[i18n.t("status_is_required")]}
                      select
                      variant="outlined"
                    >
                      <MenuItem value="Active">{i18n.t("Active")}</MenuItem>
                      <MenuItem value="Inactive">{i18n.t("Inactive")}</MenuItem>
                    </SelectValidator>
                  </div>
                </div>
                <div className="col-12 flex-column flex-md-row px-0 d-flex align-items-md-center mb-3">
                  <label
                    className="font-weight-bold"
                    style={{ minWidth: "150px" }}
                  >
                    {i18n.t("expiry_date")}
                  </label>
                  <DatePicker
                    wrapperClassName="w-100"
                    selected={
                      data.expiryDate ? moment(data.expiryDate).toDate() : ""
                    }
                    onChange={(e) => handleChange("expiryDate", e)}
                    value={
                      data.expiryDate ? moment(data.expiryDate).toDate() : ""
                    }
                    customInput={
                      <TextValidator
                        label={i18n.t("expiry_date")}
                        onChange={(e) =>
                          handleChange("expiryDate", e.target.value)
                        }
                        value={data.expiryDate}
                        variant="outlined"
                      />
                    }
                    placeholderText={i18n.t("expiry_date")}
                  />
                </div>
                <div className="col-12 flex-column flex-md-row px-0 d-flex align-items-md-center mb-3">
                  <label
                    className="font-weight-bold"
                    style={{ minWidth: "150px" }}
                  >
                    {i18n.t("time_to_use")}
                  </label>
                  <div className="w-100">
                    <SelectValidator
                      label={i18n.t("time_to_use")}
                      onChange={(e) => {
                        handleChange("timeToUse", e.target?.value)
                      }}
                      value={data.timeToUse || ""}
                      select
                      variant="outlined"
                    >
                      <MenuItem value="" disabled={true}>
                        {i18n.t("time_to_use")}
                      </MenuItem>
                      <MenuItem value={0}>{i18n.t("no_limit")}</MenuItem>
                      <MenuItem value={7}>{i18n.t("1_week")}</MenuItem>
                      <MenuItem value={14}>{i18n.t("2_week")}</MenuItem>
                      <MenuItem value={30}>{i18n.t("1_month")}</MenuItem>
                      <MenuItem value={90}>{i18n.t("3_months")}</MenuItem>
                      <MenuItem value={180}>{i18n.t("6_months")}</MenuItem>
                      <MenuItem value={270}>{i18n.t("9_months")}</MenuItem>
                      <MenuItem value={365}>{i18n.t("1_year")}</MenuItem>
                    </SelectValidator>
                  </div>
                </div>
                <div className="col-12 flex-column flex-md-row px-0 d-flex align-items-md-center mb-3">
                  <label
                    className="font-weight-bold"
                    style={{ minWidth: "150px" }}
                  >
                    {i18n.t("price")}
                  </label>
                  <div className="w-100">
                    <TextValidator
                      label={i18n.t("price")}
                      onChange={(e) => handleChange("price", e.target.value)}
                      onBlur={(text) => {
                        let num = parseFloat(text.target.value)
                        if (isNaN(num) || num < 0) {
                          num = 0
                        }
                        num = Math.round((num + Number.EPSILON) * 100) / 100
                        handleChange("price", num)
                      }}
                      value={data.price}
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      validators={["required", "minNumber:1"]}
                      errorMessages={[
                        i18n.t("price_is_required"),
                        i18n.t("must_be_positive"),
                      ]}
                      variant="outlined"
                    />
                  </div>
                </div>
                <div className="col-12 flex-column flex-md-row px-0 d-flex align-items-md-center mb-3">
                  <label
                    className="font-weight-bold"
                    style={{ minWidth: "150px" }}
                  >
                    {i18n.t("barCode")}
                  </label>
                  <div className="w-100">
                    <TextValidator
                      label={i18n.t("bar_code")}
                      onChange={(e) => handleChange("barCode", e.target.value)}
                      value={data.barCode}
                      variant="outlined"
                    />
                  </div>
                </div>
                <div className="row mx-0 px-0 justify-content-end my-3">
                  <PrimaryButton
                    className="w-128"
                    props={{
                      type: "submit",
                    }}
                    label={data._id ? i18n.t("save") : i18n.t("create")}
                  />
                </div>
              </ValidatorForm>
            </div>
          }
          onClose={onClose}
        />
      ) : null}
    </div>
  )
}
const mapStateToProps = (state) => ({
  active: state.giftCards.active,
  loading: state.giftCards.isFetching,
})

const action = {}

export default connect(mapStateToProps, action)(GiftCardsUpsert)
