import React from "react";
import { useDispatch } from "react-redux"
import { SuccessHelper, ErrorHelper } from "../../../helpers";
import deleteConfirmation from "../../../helpers/DeleteHelper";
import BasicTable from "../../Core/BasicTable";
import i18n from "../../../i18n";
import moment from "moment";
import PrimaryButton from "../../Core/PrimaryButton";
import { removeRequest } from "../../../redux/actions/FormAnswer";
import { Images } from "../../../theme";
import SharedStorage from "../../../helpers/Storage";

export default function FormTable({ data = [], handleDelete = () => { }, handleView, setLoading }) {
  const dispatch = useDispatch()
  const deleteFormAnswer = (id) => {
    dispatch(removeRequest({ _id: id },
      {
        success: () => {
          SuccessHelper.handleSuccess(i18n.t("successfully_deleted"), true)
          handleDelete(id)
        },
        failure: () => {
          ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
        },
      }))
  }
  const generatePdf = async (element) => {
    const access_token = await SharedStorage.getItem('access_token')
    setLoading(true)
    fetch(`${process.env.REACT_APP_PDF_SERVICE_URL}form/${element._id}?` + new URLSearchParams({
      token: access_token,
      dev: process.env.REACT_APP_IS_DEV
    })).then(res => res.blob()).then(blob => {
      const newBlob = new Blob([blob]);

      const blobUrl = window.URL.createObjectURL(newBlob);

      const link = document.createElement('a');
      link.href = blobUrl;
      const title = element.formId.formTitle ? element.formId.formTitle : i18n.t("form")
      const userName = element.userId.firstName + " " + element.userId.lastName
      link.setAttribute('download', `${title} - ${userName}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      // clean up Url
      window.URL.revokeObjectURL(blobUrl);
      setLoading(false)
    }).catch(e => {
      ErrorHelper.handleErrors(
        i18n.t("something_went_wrong"),
        true
      )
      setLoading(false)
    })
  }
  const schema = [
    {
      header: i18n.t("name"),
      field: "formTitle",
      accessor: (row, field) => row.formId.formTitle,
    },
    {
      header: i18n.t("customer_name"),
      field: "customerName",
      accessor: (row, field) => row.userId?.firstName,
    },
    {
      header: i18n.t("status"),
      field: "status",
      accessor: (row, field) => i18n.t(row.status),
    },
    {
      header: i18n.t("sent_date"),
      field: "createdDate",
      accessor: (row, field) =>
        row[field] ? moment(row[field]).format("MM-DD-YYYY hh:mm a") : "",
    },
    {
      header: i18n.t("submitted_date"),
      field: "submittedDate",
      accessor: (row, field) =>
        row[field] ? moment(row[field]).format("MM-DD-YYYY hh:mm a") : "",
    },
    {
      header: i18n.t("viewed_date"),
      field: "viewedDate",
      accessor: (row, field) =>
        row[field] ? moment(row[field]).format("MM-DD-YYYY hh:mm a") : "",
    },
    {
      header: i18n.t("actions"),
      field: "actions",
      headerClassName: "text-right pr-5",
      render: (element, index) => {
        return (
          <div className="row gap-1 flex-nowrap justify-content-end">
            {
              element.status === "Submitted" ? (
                <div className="row gap-1">
                  <PrimaryButton
                    className="w-34"
                    key={`table-body-button-${element._id}`}
                    onClick={() => handleView(element)}
                  >
                    <img src={Images.table_view_icon} alt="view" width="18" />
                  </PrimaryButton>
                  <PrimaryButton
                    className="w-34"
                    onClick={() => generatePdf(element)}
                  >
                    <img src={Images.attach_file} alt="pdf" width="18" />
                  </PrimaryButton>
                </div>
              ) : null
            }
            <PrimaryButton
              className="w-34"
              onClick={() => deleteConfirmation(() => { deleteFormAnswer(element._id) })}
            >
              <img src={Images.table_delete_icon} width="18" alt="delete" />
            </PrimaryButton>
          </div >
        );

      },
    },
  ];

  return <div className="table-responsive"><BasicTable rows={data} schema={schema} /></div>;
}
