// @flow

import { COMMUNICATION_MARKETING_CAMPAIGN } from "../ActionTypes"

export function createRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: COMMUNICATION_MARKETING_CAMPAIGN.CREATE_SAGA,
  }
}

export function create(data) {
  return {
    data,
    type: COMMUNICATION_MARKETING_CAMPAIGN.CREATE,
  }
}

export function reuseRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: COMMUNICATION_MARKETING_CAMPAIGN.REUSE_SAGA,
  }
}

export function reuse(data) {
  return {
    data,
    type: COMMUNICATION_MARKETING_CAMPAIGN.REUSE,
  }
}

export function getRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: COMMUNICATION_MARKETING_CAMPAIGN.GET_SAGA,
  }
}

export function get(data) {
  return {
    data,
    type: COMMUNICATION_MARKETING_CAMPAIGN.GET,
  }
}

export function getOneRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: COMMUNICATION_MARKETING_CAMPAIGN.GET_ONE_SAGA,
  }
}

export function getOne(data) {
  return {
    data,
    type: COMMUNICATION_MARKETING_CAMPAIGN.GET_ONE,
  }
}

export function updateRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: COMMUNICATION_MARKETING_CAMPAIGN.UPDATE_SAGA,
  }
}

export function update(data) {
  return {
    data,
    type: COMMUNICATION_MARKETING_CAMPAIGN.UPDATE,
  }
}

export function setRecipientRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: COMMUNICATION_MARKETING_CAMPAIGN.SET_RECIPIENTS_SAGA,
  }
}

export function setRecipient(data) {
  return {
    data,
    type: COMMUNICATION_MARKETING_CAMPAIGN.SET_RECIPIENTS,
  }
}

export function removeRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: COMMUNICATION_MARKETING_CAMPAIGN.REMOVE_SAGA,
  }
}

export function remove(data) {
  return {
    data,
    type: COMMUNICATION_MARKETING_CAMPAIGN.REMOVE,
  }
}

export function getPricingDetailsRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: COMMUNICATION_MARKETING_CAMPAIGN.PRICING_DETAILS_SAGA,
  }
}

export function getPricingDetails(data) {
  return {
    data,
    type: COMMUNICATION_MARKETING_CAMPAIGN.PRICING_DETAILS,
  }
}

export function dispatchRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: COMMUNICATION_MARKETING_CAMPAIGN.DISPATCH_SAGA,
  }
}

export function dispatch(data) {
  return {
    data,
    type: COMMUNICATION_MARKETING_CAMPAIGN.DISPATCH,
  }
}

export function requestFailure() {
  return {
    type: COMMUNICATION_MARKETING_CAMPAIGN.REQUEST_FAILURE,
  }
}
