import { connect } from "react-redux"
import React, { Component } from "react"
import { DotsLoader } from "../../../components"
import { Images } from "../../../theme"
import { SuccessHelper, ErrorHelper } from "../../../helpers"
import { updateSite, getSaloonById } from "../../../config/simpleApiCalls"
import TextField from "@material-ui/core/TextField"
import "./styles.css"
import PrimaryButton from "../../../components/Core/PrimaryButton/index.jsx"
import i18n from "../../../i18n"
import { QRCodeSVG } from "qrcode.react"
import ToggleSelect from "../../../components/ToggleSelect"
import Tabs from "../../../components/Core/Tabs/Tabs"
import SharedStorage from "../../../helpers/Storage"
class SiteSetUp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      urlError: "",
      websiteUrl: "",
    }
  }

  async componentDidMount() {
    const company = JSON.parse(await SharedStorage.getItem("company"))
    this.setState({ isloading: true, company })
    getSaloonById()
      .then((res) => {
        if (res.data.success) {
          const {
            previewImageUrl,
            websiteUrl,
            _id,
            disableOnlineBooking,
            shortenedId,
            disableOnlineBookingNewCustomer,
            disableOnlineBookingNewCustomerMessages,
          } = res.data.data
          this.setState({
            previewImageUrl: `${previewImageUrl?.url}`,
            websiteUrl,
            companyId: _id,
            companyShortId: shortenedId,
            disableOnlineBooking: disableOnlineBooking ? "yes" : "no",
            disableOnlineBookingNewCustomer: disableOnlineBookingNewCustomer
              ? "yes"
              : "no",
            disableOnlineBookingNewCustomerMessages:
              disableOnlineBookingNewCustomerMessages
                ? JSON.parse(disableOnlineBookingNewCustomerMessages)
                : { en: "", fr: "" },
          })
        }
      })
      .finally(() => {
        this.setState({ isloading: false })
      })
  }

  handleNavigation = (route) => {
    this.props.history.push(route)
  }

  handleSubmit = () => {
    const {
      imageFile,
      websiteUrl,
      disableOnlineBooking,
      disableOnlineBookingNewCustomer,
      disableOnlineBookingNewCustomerMessages,
    } = this.state
    this.setState({ isloading: false })
    if (
      !websiteUrl ||
      websiteUrl.match(
        "^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$"
      )
    ) {
      let payloadFormdata = new FormData()
      payloadFormdata.append("websiteUrl", websiteUrl)
      payloadFormdata.append(
        "disableOnlineBooking",
        disableOnlineBooking == "yes"
      )

      payloadFormdata.append(
        "disableOnlineBookingNewCustomerMessages",
        JSON.stringify(disableOnlineBookingNewCustomerMessages)
      )
      payloadFormdata.append(
        "disableOnlineBookingNewCustomer",
        disableOnlineBookingNewCustomer == "yes"
      )

      if (imageFile) {
        payloadFormdata.append("image", imageFile)
      }
      updateSite(payloadFormdata)
        .then(({ data }) => {
          if (data.success) {
            SuccessHelper.handleSuccess("Successfully saved site changes", true)
          } else {
            ErrorHelper.handleErrors("Failed to save site changes", true)
          }
          this.setState({ isloading: false })
        })
        .catch(() => {
          ErrorHelper.handleErrors("An Error Occured", true)
          this.setState({ isloading: false })
        })
    }
  }

  onUrlChange = (e) => {
    const url = e.target.value
    if (url) {
      if (
        !url.match(
          "^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$"
        )
      ) {
        this.setState({ urlError: i18n.t("invalid_url") })
      } else {
        this.setState({ urlError: "" })
      }
    }

    this.setState({ websiteUrl: e.target.value })
  }

  renderInputCol6 = (id, label, val, helperText) => {
    return (
      <div class="site-form row align-items-start gap-1 form-group col-md-12 px-0">
        <label className="font-weight-bold">{label}</label>
        <TextField
          id={id}
          label={label}
          value={val}
          helperText={helperText}
          className="editField"
          variant="outlined"
        />
      </div>
    )
  }

  renderInputCol12 = (id, label, val, onChange, errorMessage) => {
    return (
      <div className="site-form row align-items-center gap-1 form-group col-md-12 px-0">
        <label className="font-weight-bold">{label}</label>
        <TextField
          id={id}
          label={label}
          value={val}
          onChange={(text) => onChange(text)}
          className="editField"
          variant="outlined"
        />

        {errorMessage ? (
          <small className="form-text text-danger ml-3">{errorMessage}</small>
        ) : null}
      </div>
    )
  }

  renderTemplates = () => {
    const {
      urlError,
      companyId,
      companyShortId,
      company,
      disableOnlineBookingNewCustomerMessages,
      disableOnlineBookingNewCustomer,
    } = this.state

    const tabs = [
      {
        title: i18n.t("english"),
        content: () => (
          <TextField
            multiline
            label={i18n.t("message")}
            minRows={5}
            maxRows={5}
            value={disableOnlineBookingNewCustomerMessages?.en || ""}
            onChange={(e) =>
              this.setState({
                disableOnlineBookingNewCustomerMessages: {
                  ...disableOnlineBookingNewCustomerMessages,
                  en: e.target.value,
                },
              })
            }
            variant="outlined"
          />
        ),
      },
      {
        title: i18n.t("french"),
        content: () => (
          <TextField
            multiline
            label={i18n.t("message")}
            minRows={5}
            maxRows={5}
            value={disableOnlineBookingNewCustomerMessages?.fr || ""}
            onChange={(e) =>
              this.setState({
                disableOnlineBookingNewCustomerMessages: {
                  ...disableOnlineBookingNewCustomerMessages,
                  fr: e.target.value,
                },
              })
            }
            variant="outlined"
          />
        ),
      },
    ]
    return (
      <div className="col-12 mx-0">
        <div id="printDiv" className="mb-5">
          <h1 id="companyName">{company?.name}</h1>
          <QRCodeSVG
            id="qrCode"
            value={`book.easy1booking.com/${companyShortId || companyId}`}
          />
          <PrimaryButton
            className="print-btn d-block mt-3"
            onClick={() => window.print()}
          >
            {i18n.t("print")}
          </PrimaryButton>
        </div>
        {this.renderInputCol6(
          "booking_link",
          i18n.t("booking_link"),
          `book.easy1booking.com/${companyShortId || companyId}`,
          i18n.t("booking_link_description")
        )}
        {this.renderInputCol12(
          "your_website_url",
          i18n.t("container.your_website_url"),
          this.state.websiteUrl,
          this.onUrlChange
        )}
        <div className="site-form row align-items-center gap-1 form-group col-md-12 px-0 my-5">
          <label className="font-weight-bold">{i18n.t("online_booking")}</label>
          <ToggleSelect
            value={this.state.disableOnlineBooking === "yes" ? true : false}
            onChange={(e) =>
              this.setState({ disableOnlineBooking: e ? "yes" : "no" })
            }
            active={i18n.t("enable")}
            inactive={i18n.t("disable")}
          />
        </div>
        <div className="site-form row align-items-center gap-1 form-group col-md-12 px-0 my-5">
          <label className="font-weight-bold">
            {i18n.t("online_booking_new_customer")}
          </label>
          <ToggleSelect
            value={
              this.state.disableOnlineBookingNewCustomer === "yes"
                ? true
                : false
            }
            onChange={(e) =>
              this.setState({
                disableOnlineBookingNewCustomer: e ? "yes" : "no",
              })
            }
            active={i18n.t("enable")}
            inactive={i18n.t("disable")}
          />
        </div>
        {disableOnlineBookingNewCustomer == "yes" ? (
          <div className="language-tab">{<Tabs tabs={tabs} />}</div>
        ) : null}

        <div className="mt-3">{this.renderUploadFileCard()}</div>
        <div className="col-12 px-0 mt-5">
          <PrimaryButton onClick={() => this.handleSubmit()} className="w-128">
            {i18n.t("save")}
          </PrimaryButton>
        </div>
      </div>
    )
  }

  fileSelectedHandler = (event) => {
    const imageUrlRegex = /([a-zA-Z0-9\s_\\.\-:])+(.png|.jpeg|.jpg|.gif)$/gm
    if (event.target.files[0]) {
      let checkFile = event.target.files[0].type.split("/")
      let isValidateType = event.target.files[0].type
      if (checkFile[0] === "image") {
        if (isValidateType.match(imageUrlRegex)) {
          const file = URL.createObjectURL(event.target.files[0])
          this.setState({
            mediaType: event.target.files[0].type.split("/")[0],
            previewImageUrl: file,
            imageFile: event.target.files[0],
            imageName: event.target.files[0].name,
            imageTitle: event.target.files[0].name,
            imageDescription: event.target.files[0].name,
          })
        } else {
          ErrorHelper.handleErrors("Image Format not supported", true)
        }
      }
    }
  }

  renderUploadFileCard = () => {
    const { imageUrl, previewImageUrl } = this.state
    return (
      <div className="col-12 px-0">
        <div className="bg-white">
          <div className="col-12 px-0">
            <p id="upload-file-text">{i18n.t("container.preview_picture")}</p>
            <input
              type="file"
              style={{ display: "none" }}
              onChange={this.fileSelectedHandler}
              ref={(fileInput) => (this.fileInput = fileInput)}
              accept="image/*"
            />
            <div className="col-12 row mx-0 px-0 mt-3">
              {previewImageUrl && (
                <div className="preview-image-wrapper pr-5">
                  <div className="col-12 p-0">
                    <img src={previewImageUrl} className="interact-icon" />
                    <h5 className="mt-4">Current Image</h5>
                  </div>
                </div>
              )}
              <div className="preview-image-wrapper">
                <div id="upload-box" onClick={() => this.fileInput.click()}>
                  <img
                    src={Images.saloon_upload_media_icon}
                    style={{ width: 24 }}
                    className="interact"
                  />
                  <p id="drop-file-text">
                    {i18n.t("container.dashboard_drop_files")}{" "}
                    <span style={{ color: "#F6943B" }}>
                      {i18n.t("container.dashboard_browse")}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderLoader = () => {
    const { isloading } = this.state
    return <DotsLoader isloading={isloading} />
  }

  renderSaloonDashboardHeading = () => {
    return (
      <div className="col-12 mx-0 pt-3">
        <h1 className="saloon-dashboard-heading">
          {i18n.t("container.site_set_up")}
        </h1>
      </div>
    )
  }

  onSetSidebarOpen = (open) => {
    this.setState({ sidebarOpen: open })
  }

  render() {
    return (
      <div>
        {this.renderLoader()}
        <div className="content-container">
          <div className="row mx-0">
            {this.renderSaloonDashboardHeading()}
            <div
              className="col-12 p-0 mt-4 bg-white mb-4"
              style={{ borderRadius: "1rem" }}
            >
              {/* {this.TemplateOptionheader()} */}
              {this.renderTemplates()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

const action = {}

export default connect(mapStateToProps, action)(SiteSetUp)
