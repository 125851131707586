import React, { useEffect, useState } from "react"
import i18n from "../../../i18n"
import { BaseModal, DotsLoader } from "../../../components"
import DatePicker from "react-datepicker"
import moment from "moment"
import { getCommissionReport } from "../../../config/simpleApiCalls"
import BasicTable from "../../../components/Core/BasicTable"
import Select from "../../../components/Core/Select"
import PrimaryButton from "../../../components/Core/PrimaryButton"
import SecondaryButton from "../../../components/Core/SecondaryButton/SecondaryButton"
import { Autocomplete } from "@material-ui/lab"
import TextField from "@material-ui/core/TextField"
import "./styles.css"
import useCache from "../../../context/LocalCache"
import useScreenSize from "../../../context/ScreenSize"
export default function CommissionReport() {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [startDate, setStartDate] = useState(
    moment(moment.now()).subtract(7, "d").toDate()
  )
  const [endDate, setEndDate] = useState(moment(moment.now()).toDate())
  const [category, setCategory] = useState("")
  const { getResource } = useCache()
  const employees = getResource(
    (cache) => cache.company.employees,
    (employee) => ({
      value: employee.employee?._id || employee.archivedEmployeeId._id,
      name:
        employee.employee?.userId.firstName ||
        employee.archivedEmployeeId.userId.firstName,
    })
  )
  const services = getResource(
    (cache) => cache.company.services,
    (element) => ({
      value: element._id,
      name: element.name,
      categoryName: i18n.t("services"),
      category: "service",
    })
  )
  const packages = getResource(
    (cache) => cache.company.packages,
    (element) => ({
      value: element._id,
      name: element.name,
      categoryName: i18n.t("packages"),
      category: "package",
    })
  )
  const products = getResource(
    (cache) => cache.company.products,
    (element) => ({
      value: element._id,
      name: element.name,
      categoryName: i18n.t("products"),
      category: "product",
    })
  )
  const [employeeId, setEmployeeId] = useState("")
  const { screenSize } = useScreenSize()
  const [openFiltersModal, setOpenFiltersModal] = useState(false)
  const [openOptionsModal, setOpenOptionsModal] = useState(false)
  const getReport = async (exportToXlsx) => {
    setLoading(true)
    const data = {
      from: startDate,
      to: endDate,
      exportToXlsx,
    }
    if (employeeId) {
      data.employeeId = employeeId
    }
    if (category) {
      data.category = category
    }
    getCommissionReport(data)
      .then(({ data }) => {
        if (data) setData(data.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }
  useEffect(() => {
    getReport()
  }, [])

  const setSelectedValue = (val) => {
    let currentDate = moment(Date.now())
    if (val == 1) {
      let day = currentDate.day()
      const convertedStart = moment(currentDate).subtract(day, "days")
      const convertedEnd = moment(convertedStart).add(6, "days")
      setStartDate(convertedStart.toDate())
      setEndDate(convertedEnd.toDate())
    } else if (val == 2) {
      const startOfMonth = moment(currentDate).startOf("month")
      const endOfMonth = moment(currentDate).endOf("month")
      setStartDate(startOfMonth.toDate())
      setEndDate(endOfMonth.toDate())
    } else if (val == 3) {
      const convertedStart = moment(`01-01-${currentDate.year()}`, "MM-DD-YYYY")
      const convertedEnd = moment(`12-31-${currentDate.year()}`, "MM-DD-YYYY")
      setStartDate(convertedStart.toDate())
      setEndDate(convertedEnd.toDate())
    }
  }

  const onStartDateChange = (val) => {
    if (val > endDate) {
      setStartDate(endDate)
      setEndDate(val)
    } else {
      setStartDate(val)
    }
  }

  const onEndDateChange = (val) => {
    if (val < startDate) {
      setStartDate(val)
      setEndDate(startDate)
    } else {
      setEndDate(val)
    }
  }
  const renderFilter = () => {
    return (
      <div className="row col-12 px-0">
        <div className="row col-12 col-md-5 px-0 mb-4">
          <div className="w-100 d-flex flex-column flex-md-row align-items-md-center gap-2">
            <label className="font-weight-bolder text-capitalize">
              {i18n.t("filter")}
            </label>
            <Select
              value={employeeId || ""}
              onChange={(e) => setEmployeeId(e.target.value)}
              label={i18n.t("container.employee")}
              options={employees}
              fullWidth
              variant="outlined"
            />
            <Autocomplete
              id="grouped-demo"
              options={[...packages, ...products, ...services]}
              groupBy={(option) => option.categoryName}
              getOptionLabel={(option) => option.name}
              value={category || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={i18n.t("container.categories")}
                />
              )}
              onChange={(e, value) => {
                setCategory(value)
              }}
              fullWidth
            />
            <PrimaryButton
              onClick={() => {
                setEmployeeId()
                setCategory()
              }}
              className="w-98"
            >
              {i18n.t("filterReset")}
            </PrimaryButton>
          </div>
        </div>
        <div className="row col-sm-7 px-0 mb-4">
          <div className="d-sm-flex align-items-center ml-sm-3 mb-3 gap-2">
            <label className="font-weight-bolder">{i18n.t("date_range")}</label>
            <div className="d-flex gap-2">
              <DatePicker
                className="form-control saloon-form-input"
                placeholderText="Select Start Date"
                selected={startDate}
                onChange={onStartDateChange}
                dateFormat="MM/dd/yyyy"
                variant="outlined"
              />
              <DatePicker
                className="form-control saloon-form-input"
                placeholderText="Select End Date"
                selected={endDate}
                onChange={onEndDateChange}
                dateFormat="MM/dd/yyyy"
              />
            </div>
          </div>
          <div className="row align-items-center px-0 mx-0 ml-sm-3 gap-2">
            <div className="d-flex px-0 gap-2 flex-wrap">
              <SecondaryButton onClick={() => setSelectedValue(1)}>
                {i18n.t("container.week")}
              </SecondaryButton>
              <SecondaryButton onClick={() => setSelectedValue(2)}>
                {i18n.t("container.month")}
              </SecondaryButton>
              <SecondaryButton onClick={() => setSelectedValue(3)}>
                {i18n.t("container.year")}
              </SecondaryButton>
              <PrimaryButton className="w-98" onClick={() => getReport()}>
                {i18n.t("search")}
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    )
  }
  const renderButtons = () => {
    return (
      <PrimaryButton
        className={screenSize === "mobile" ? "w-100" : "w-128"}
        onClick={() => getReport(true)}
      >
        {i18n.t("export")}
      </PrimaryButton>
    )
  }
  return (
    <div className="content-container p-4">
      <DotsLoader isloading={loading} />
      <div className="col-12 px-0 flex-column flex-md-row gap-2 d-flex align-items-center justify-content-between mb-4">
        <span className="saloon-dashboard-heading pt-0">
          {i18n.t("commission_report")}
        </span>
        {screenSize !== "mobile" ? (
          <>{renderButtons()}</>
        ) : (
          <div className="d-flex gap-2 justify-content-center">
            <PrimaryButton
              className="w-128"
              onClick={() => setOpenFiltersModal(true)}
            >
              {i18n.t("filters")}
            </PrimaryButton>
            <PrimaryButton
              className="w-128"
              onClick={() => setOpenOptionsModal(true)}
            >
              Options
            </PrimaryButton>
          </div>
        )}
      </div>
      {screenSize !== "mobile" ? <>{renderFilter()}</> : null}

      <div
        className="mt-3 table-responsive"
        style={screenSize === "mobile" ? { height: "70vh" } : null}
      >
        <BasicTable
          rows={data}
          schema={[
            {
              header: i18n.t("name"),
              field: "name",
            },
            {
              header: i18n.t("category"),
              field: "category",
              accessor: ({ category }) => {
                return i18n.t(`${category}`)
              },
            },
            {
              header: i18n.t("commission"),
              field: "commission",
              accessor: ({ commission }) => {
                return `${commission} %`
              },
            },
            {
              header: i18n.t("commission_total"),
              field: "commissionTotal",
            },
            {
              header: i18n.t("total"),
              field: "total",
            },
            {
              header: i18n.t("sold_by"),
              field: "employee",
            },
            {
              header: i18n.t("created_at"),
              field: "createdDate",
              accessor: ({ createdDate }) => {
                if (!createdDate) {
                  return ""
                }
                return moment(createdDate).format("MM-DD-YYYY hh:mma")
              },
            },
          ]}
        />
      </div>
      <BaseModal
        open={openFiltersModal}
        onClose={() => setOpenFiltersModal(false)}
        title={i18n.t("container.advancedSearchDialog_filter")}
        content={<>{renderFilter()}</>}
        contentClassName="overflow-visible"
      />
      <BaseModal
        open={openOptionsModal}
        onClose={() => setOpenOptionsModal(false)}
        title="Options"
        content={<>{renderButtons()}</>}
      />
    </div>
  )
}
