import React, { Component, useEffect, useRef, useState } from "react"
import { DotsLoader } from "../../../components"
import PrimaryButton from "../../../components/Core/PrimaryButton"
import { ErrorHelper, SuccessHelper } from "../../../helpers"
import i18n from "../../../i18n"
import { ReactFormGenerator } from "react-form-builder2"
import "react-form-builder2/dist/app.css"
import "./styles.css"
import {
  getRatingFormByCompanyId,
  answerRatingForm,
} from "../../../config/simpleApiCalls"
import { useParams } from "react-router-dom"

export default function RatingSurvey() {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])
  const [service, setService] = useState()
  const [booking, setBooking] = useState("")
  const [message, setMessage] = useState("")
  const [company, setCompany] = useState()
  const { companyShortenedId, bookingShortenedId } = useParams()
  useEffect(() => {
    getRatingFormByCompanyId({ companyShortenedId, bookingShortenedId })
      .then(({ data }) => {
        if (data.alreadyAnswered) {
          const message =
            data.language === "en"
              ? "Thank you for answering the questionnaire"
              : "Merci d'avoir répondu au questionnaire."
          setMessage(message)
        } else {
          setCompany(data.booking.companyId)
          setData(data.fields || [])
          setBooking(data.booking)
          setService(data.booking.services[0])
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }, [])

  const onSubmit = (answers) => {
    setIsLoading(true)

    answerRatingForm({
      questions: data,
      answers,
      bookingId: booking?._id,
      employeeId: service?.employeeId,
      userId: booking?.userId,
      companyId: company?._id,
    })
      .then(() => {
        const message =
          company?.communicationLanguage === "en"
            ? "Thank you for answering the questionnaire"
            : "Merci d'avoir répondu au questionnaire."
        setMessage(message)
      })
      .catch((e) => {
        console.log(e)
        ErrorHelper.handleErrors(i18n.t("someting_went_wrong"), true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <>
      <DotsLoader isloading={isLoading} />
      <div className="content-container py-5 ">
        {message ? (
          <h1 className="text-center">{message}</h1>
        ) : (
          <div className="mx-md-4">
            <h1 className="mb-5">
              {service?.serviceId?.name}
              {service && " - "}
              {`${service?.employeeId.userId.firstName || ""} ${
                service?.employeeId.userId.lastName || ""
              }`}
            </h1>
            <ReactFormGenerator
              submitButton={
                <PrimaryButton type="submit">
                  {company?.communicationLanguage == "en"
                    ? "Submit"
                    : "Soumettre"}
                </PrimaryButton>
              }
              onSubmit={onSubmit}
              data={data}
              hide_actions={!company ? true : false}
            />
          </div>
        )}
      </div>
    </>
  )
}
