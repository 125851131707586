// @flow

import { BOOKING_REF_CODE } from "./ActionTypes"

export function set(data) {
  return {
    data,
    type: BOOKING_REF_CODE.SET,
  }
}

export function clear() {
  return {
    type: BOOKING_REF_CODE.CLEAR,
  }
}

export function validateRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: BOOKING_REF_CODE.VALIDATE_SAGA,
  }
}

export function requestFailure() {
  return {
    type: BOOKING_REF_CODE.REQUEST_FAILURE,
  }
}
