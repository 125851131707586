import { connect, useSelector } from "react-redux"
import React from "react"
import { Route, Redirect } from "react-router-dom"
import { useMemo, useState } from "react"
import { DotsLoader } from "../../components"
import { setOpened } from "../../redux/actions/Sidebar"
import { useEffect } from "react"
import useActiveMenuType from "../../context/ActiveMenuType"
import ForbiddenRoute from "./Forbidden.component"
import { ROLES } from "../../util/enum"
import SharedStorage from "../../helpers/Storage"
function PrivateRoute(props) {
  const {
    user,
    sidebarOpen,
    docked,
    Component,
    setOpened,
    type,
    resource,
    receptionistAllowedResource = {},
    managerAllowedResource = {},
    ownerAllowedResource = {},
    instructorAllowedResource = {},
    resourcesLoading,
    ...rest
  } = props
  const [hasReceptionAccess, setHasReceptionAccess] = useState(false)
  const [hasManagerAccess, setHasManagerAccess] = useState(false)
  const [hasInstructorAccess, setHasInstructorAccess] = useState(false)
  const [hasEmployeeAccess, setHasEmployeeAccess] = useState(false)
  const { isInstructor, setIsInstructor, hideEmployeeProfile, accountAccess } = useActiveMenuType()
  useEffect(async () => {
    setOpened(docked)
    const employeeData = JSON.parse(await SharedStorage.getItem("employee"))
    setHasReceptionAccess(employeeData?.role?.includes(ROLES.RECEPTIONIST))
    setHasManagerAccess(employeeData?.role?.includes(ROLES.MANAGER))
    setHasInstructorAccess(employeeData?.role?.includes(ROLES.INSTRUCTOR))
    setHasEmployeeAccess(employeeData?.role?.includes(ROLES.EMPLOYEE))
    await SharedStorage.getItem("menu_type") == "instructor" ? setIsInstructor(true) : setIsInstructor(false)
  }, [])
  const hasAccess = useMemo(() => {
    if (accountAccess === false) {
      if (resource == "EmployeeActiveSaloon") {
        return true
      }
      return false
    }
    if (!resource || (hasEmployeeAccess && !isInstructor && !hideEmployeeProfile)) {
      return true
    }
    return (
      (hasReceptionAccess && receptionistAllowedResource[resource]) ||
      (hasManagerAccess && managerAllowedResource[resource]) ||
      (hasInstructorAccess && instructorAllowedResource[resource]) ||
      (user?.role?.includes(ROLES.OWNER) && ownerAllowedResource[resource])
    )
  })
  const isAuthenticated = useMemo(() => {
    if (type == "saloon") {
      return (
        user &&
        user.access_token &&
        user.role &&
        (user.role.includes(ROLES.OWNER) ||
          user.role.includes(ROLES.RECEPTIONIST) ||
          user.role.includes(ROLES.MANAGER))
      )
    } else if (type == "employee") {
      return user && user.access_token && user.role && (user.role.includes(ROLES.EMPLOYEE) || user.role.includes(ROLES.INSTRUCTOR))
    }
  }, [type, user])

  return (
    <Route
      exact
      {...{
        ...rest,
        props: { user },
      }}
      render={(routeProps) =>
        isAuthenticated && user ? (
          <>
            <div
              className="container-fluid px-0"
              style={{ backgroundColor: "white" }}
            >
              <div className={`routes-main-content`}>
                {resourcesLoading ? (
                  <DotsLoader isloading={true} />
                ) : (
                  // The null here sould be replaced with a 403 page
                  <>{hasAccess ? <Component {...routeProps} /> : <ForbiddenRoute />}</>
                )}
              </div>
            </div>
          </>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}

const mapStateToProps = (state) => ({
  saloonLogin: state.saloonLogin,
  impersonateSalon: state.impersonateSalon,
  user:
    (state.user.data.saloon
      ? JSON.parse(state.user.data.saloon)
      : state.user.data.saloon) ||
    (state.user.data.employee
      ? JSON.parse(state.user.data.employee)
      : state.user.data.employee),
  docked: state.sidebar.isDocked,
  sidebarOpen: state.sidebar.isOpen,
  receptionistAllowedResource: state.receptionistAccess.data?.resourceMap,
  managerAllowedResource: state.managerAccess.data?.resourceMap,
  ownerAllowedResource: state.ownerAccess.data?.resourceMap,
  instructorAllowedResource: state.instructorAccess.data?.resourceMap,
  resourcesLoading:
    state.receptionistAccess.isFetching || state.managerAccess.isFetching ||
    state.ownerAccess.isFetching || state.hasInstructorAccess?.isFetching,
})

const action = { setOpened }
export default connect(mapStateToProps, action)(PrivateRoute)
