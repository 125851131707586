import React, { Component } from "react"
import i18n from "../../../i18n"
import { DotsLoader } from "../../../components"
import Avatar from "react-avatar"
import { getWallet } from "../../../config/simpleApiCalls"

class EmployeeWallet extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isloading: true,
      walletInfo: [],
    }
  }

  async componentDidMount() {
    this.setState({ isloading: true })

    getWallet()
      .then((res) => {
        this.setState({ walletInfo: res.data.data, isloading: false })
      })
      .catch((error) => console.log("errrr", error))
  }

  renderLoader = () => {
    const { isloading } = this.state
    return <DotsLoader isloading={isloading} />
  }

  TemplateOption = () => {
    return (
      <ul
        style={{ marginLeft: "220px" }}
        class="list-group list-group-horizontal"
      >
        <li class="list-group-item li-padding li-radius-left">Day</li>
        <li class="list-group-item li-padding">Month</li>
        <li class="list-group-item li-padding li-radius-right">Year</li>
      </ul>
    )
  }

  renderSearchBar = () => {
    const { walletInfo } = this.state
    // const { walletInfo } = reciver;

    return (
      <>
        <div className="col-12 col-lg-4 col-md-4 mt-4">
          <div>
            <span className="saloon-dashboard-heading">
              {i18n.t("container.profile_my_wallet")}
            </span>
          </div>
          {/* <span className="">lorem ipsum lorem ipsum lorem ipsum</span> */}
        </div>
        <div className="col-12 col-lg-8 col-md-8 mt-4">
          <div className=" d-flex justify-content-end row">
            <div
              className="col-12 col-lg-7 col-md-6"
              style={{ textAlign: "end" }}
            >
              <div
                style={{ fontSize: 30, color: "#FF3600", fontWeight: "bold" }}
              >
                {walletInfo?.totalAmount} Pts
              </div>
              <div>
                <button
                  type="button"
                  className="btn btn-light btn-sm mb-2"
                  style={{ width: "150px" }}
                >
                  1$ = 1000 Points
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  renderTableBody = (v) => {
    let { walletInfo } = this.state

    let name = v.reciver.userName
      ? v.reciver.userName
      : v.reciver.firstName + " " + v.reciver?.lastName

    return (
      <tbody>
        <tr className="border-top">
          <td>
            <div className="d-flex align-items-center">
              <Avatar
                name={name}
                size="30"
                color="#4862FF"
                round={true}
                textSizeRatio={2.0}
              />
              <span
                style={{ marginLeft: "10px" }}
                className="appointment-details-employee-name"
              >
                {name}
              </span>
            </div>
          </td>
          <td className=" Table_data">
            <div className="d-flex align-items-center mt-1">
              <span className="appointment-details-employee-name">
                {v.type}
              </span>
            </div>
          </td>
          <td className=" Table_data">
            <div className="d-flex align-items-center mt-1">
              <span className="appointment-details-employee-name">
                {v.paidAmount}
              </span>
            </div>
          </td>
          <td className=" Table_data">
            <div className="d-flex align-items-center mt-1">
              <span className="appointment-details-employee-name">
                {walletInfo.totalAmount}
              </span>
            </div>
          </td>
        </tr>
      </tbody>
    )
  }

  renderUserTable = () => {
    let { walletInfo } = this.state

    return (
      <div className="col-12 p-3">
        <div className="table-responsive">
          <table className="table table-borderless bg-white mobile-max-content">
            <thead>
              <tr>
                <th scope="col">
                  <span className="appointment-details-th-text">
                    {i18n.t("container.dashboard_customer_name")}
                  </span>
                </th>
                <th scope="col">
                  <span className="appointment-details-th-text">Type</span>
                </th>
                <th scope="col">
                  <span className="appointment-details-th-text">
                    {i18n.t("container.profile_add_points")}
                  </span>
                </th>
                <th scope="col">
                  <span className="appointment-details-th-text">
                    {i18n.t("container.profile_points_remaining")}
                  </span>
                </th>
              </tr>
            </thead>
            {walletInfo?.transactions &&
              walletInfo.transactions.map((v, i) => this.renderTableBody(v))}
          </table>
        </div>
      </div>
    )
  }

  render() {
    const { isloading } = this.state

    return (
      <div>
        {isloading ? (
          this.renderLoader()
        ) : (
          <div className="content-container">
            <div className="row">
              {this.renderSearchBar()}
              {this.renderUserTable()}
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default EmployeeWallet
