import React, { useEffect, useRef, useState } from "react";
// import Packages from "../../components/Packages/Packages.component";
import i18n from "../../../i18n";
import BaseModal from "../../BaseModal";
import {
  ValidatorForm,
  SelectValidator,
} from "react-material-ui-form-validator";
import MenuItem from "@material-ui/core/MenuItem";
import PrimaryButton from "../../Core/PrimaryButton";

export default function Packages({
  open,
  onClose,
  element = { status: "Active" },
  onSave,
  id,
  allServices,
}) {
  const form = useRef();
  const [data, setData] = useState({});
  useEffect(() => {
    setData({ ...element });
  }, []);
  const handleChange = (key, value) => {
    data[key] = value;
    setData({ ...data });
  };
  const handleSubmit = () => {
    onSave(data);
  };

  return (
    <div>
      {open ? (
        <BaseModal
          open={open}
          title={id ? i18n.t("edit_package_item") : i18n.t("add_package_item")}
          content={
            <>
              <div className="d-flex">
                <ValidatorForm
                  onSubmit={handleSubmit}
                  ref={form}
                  className="w-100"
                  onError={(error) => {
                    console.log(error);
                    if (!error.length) {
                      onSave(data);
                    }
                  }}
                >
                  <SelectValidator
                    label={i18n.t("service")}
                    onChange={(e) => {
                      handleChange("serviceId", e.props?.value);
                    }}
                    value={data.serviceId}
                    validators={["required"]}
                    errorMessages={[i18n.t("service_is_required")]}
                    select
                  >
                    {allServices.map((service) => {
                      return (
                        <MenuItem value={service._id}>{service.name}</MenuItem>
                      );
                    })}
                  </SelectValidator>
                  <SelectValidator
                    label={i18n.t("status")}
                    onChange={(e) => {
                      console.log(e);
                      handleChange("status", e.props?.value);
                    }}
                    value={data.status}
                    defaultValue={"Active"}
                    validators={["required"]}
                    errorMessages={[i18n.t("status_is_required")]}
                    select
                  >
                    <MenuItem value="Active">{i18n.t("Active")}</MenuItem>
                    <MenuItem value="Inactive">{i18n.t("Inactive")}</MenuItem>
                  </SelectValidator>
                  <div className="row mx-0 px-0 justify-content-end mt-2">
                    <PrimaryButton
                      props={{
                        type: "submit",
                      }}
                      label={id ? i18n.t("save") : i18n.t("create")}
                    />
                  </div>
                </ValidatorForm>
              </div>
            </>
          }
          onClose={onClose}
        />
      ) : null}
    </div>
  );
}
