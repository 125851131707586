import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import i18n from "../../../i18n";
import DotsLoader from "../../DotsLoader";
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import { removeRequest } from "../../../redux/actions/Saloon/Program";
import BasicTable from "../../Core/BasicTable";
import DeleteIcon from "../../Core/BasicTable/Actions/DeleteIcon";
import EditIcon from "../../Core/BasicTable/Actions/EditIcon";
import deleteConfirmation from "../../../helpers/DeleteHelper";

function CourseTable({ rows, onEdit, removeRequest }) {
  const [loading, setLoading] = useState(false);
  const handleDelete = (_id) => {
    setLoading(true);
    removeRequest(
      { ids: _id },
      {
        success: () => {
          setLoading(false);
          SuccessHelper.handleSuccess(
            "Your service is successfully deleted.",
            true
          );
        },
        failure: () => {
          setLoading(false);
          ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
        },
      }
    );
  };
  return (
    <>
      <DotsLoader isloading={loading} />
      <BasicTable
        rows={rows}
        schema={[
          {
            header: i18n.t("name"),
            field: "name",
          },
          {
            header: i18n.t("total_hours"),
            field: "totalHours",
            accessor: ({ totalHours }) => {
              return totalHours;
            },
          },
          {
            header: i18n.t("price"),
            field: "phoneNo",
            accessor: ({ price }) => {
              if (!price) {
                return "";
              }
              return `$${price}`;
            },
          },
          {
            key: "action",
            header: i18n.t("actions"),
            style: { width: 120 },
            render: (val) => {
              return (
                <>
                  <EditIcon
                    onClick={() => {
                      onEdit(val);
                    }}
                  />
                  <DeleteIcon
                    onClick={() =>
                      deleteConfirmation(() => handleDelete(val._id))
                    }
                  />
                </>
              );
            },
          },
        ]}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  rows: state.program.data,
});

const action = {
  removeRequest,
};

export default connect(mapStateToProps, action)(CourseTable);
