import React, { useEffect, useMemo, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { LoggedInNavbar } from ".."
import { setDocked, setOpened } from "../../redux/actions/Sidebar"
import AppSidebar from "../AppSidebar"
import CreateSpeedDial from "../Partner/CreateSpeedDial/CreateSpeedDial"
import { Footer, SidebarContent } from "../Saloon"
import { getRequest as getReceptionistAccess } from "../../redux/actions/ReceptionistAccess"
import { getRequest as getManagerAccess } from "../../redux/actions/ManagerAccess"
import { getRequest as getOwnerAccess } from "../../redux/actions/OwnerAccess"
import { getRequest as getInstructorAccess } from "../../redux/actions/InstructorAccess"
const mql = window.matchMedia(`(min-width: 1200px)`)

export default function AppWrapper({ children, ...props }) {
  const dispatch = useDispatch()
  const user = useSelector(
    (state) =>
      (state.user.data.saloon
        ? JSON.parse(state.user.data.saloon)
        : state.user.data.saloon) ||
      (state.user.data.employee
        ? JSON.parse(state.user.data.employee)
        : state.user.data.employee)
  )

  const isAuthenticated = useMemo(() => {
    return user?.access_token
  }, [user])

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getReceptionistAccess({}))
      dispatch(getManagerAccess({}))
      dispatch(getOwnerAccess({}))
      dispatch(getInstructorAccess({}))
    }
  }, [isAuthenticated])

  const docked = useSelector((state) => state.sidebar.isDocked)
  const sidebarOpen = useSelector((state) => state.sidebar.isOpen)
  const onDockedChange = (e) => dispatch(setDocked(e))

  useEffect(() => {
    const listener = () => {
      onDockedChange(mql.matches)
    }

    mql.addListener(listener)

    return mql.removeListener(listener)
  }, [])

  const onSetOpen = () => {
    dispatch(setOpened(!sidebarOpen))
  }

  if (!isAuthenticated) {
    return children
  }

  return (
    <>
      <LoggedInNavbar
        {...props}
        onSetSidebarOpen={onSetOpen}
        docked={docked}
      />

      <AppSidebar
        children={<SidebarContent {...props} />}
        open={sidebarOpen}
        docked={docked && sidebarOpen}
        onSetOpen={onSetOpen}
        sidebarClassName="custom-sidebar"
        content={children}
      />
      <CreateSpeedDial />
      <Footer sidebarOpen={sidebarOpen} />
    </>
  )
}
