import { connect } from "react-redux"
import React, { useEffect, useRef, useState } from "react"
import i18n from "../../../i18n"
import BaseModal from "../../BaseModal"
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator"
import PrimaryButton from "../../Core/PrimaryButton"
import AppBar from "@material-ui/core/AppBar"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import {
  getRequest as getStudentsRequest,
  upsertRequest as upsertStudentRequest,
} from "../../../redux/actions/Saloon/Student"
import {
  addStudentRequest,
  removeStudentRequest,
} from "../../../redux/actions/Saloon/StudentGroup"
import { ErrorHelper, SuccessHelper } from "../../../helpers"
import DotsLoader from "../../DotsLoader"
import StudentUpsert from "../Student/StudentUpsert"
import { MenuItem } from "@material-ui/core"
import BasicTable from "../../Core/BasicTable"
import deleteConfirmation from "../../../helpers/DeleteHelper"
import DeleteIcon from "../../Core/BasicTable/Actions/DeleteIcon"
import TabPanel from "../../Core/Tabs/TabPanel"

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

function StudentGroupUpsert({
  open,
  onClose,
  onSave,
  active = {},
  setActive,
  getStudentsRequest,
  upsertStudentRequest,
  addStudentRequest,
  removeStudentRequest,
  companyId,
  students,
}) {
  const form = useRef()
  const newClassForm = useRef()
  const [data, setData] = useState({})
  const [addStudentOpen, setAddStudentOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [activeStudent, setActiveStudent] = useState({})
  const [newGroupStudent, setNewGroupStudent] = useState({ numberOfTimes: 1 })

  const init = (data) => {
    setData({ ...data })
    setTab(0)
    getStudentsRequest({})
  }
  const onSaveClass = (params) => {
    setLoading(true)
    upsertStudentRequest(
      { companyId, vendorId: data._id, ...params },
      {
        success: (data) => {
          SuccessHelper.handleSuccess(
            i18n.t(data.id ? "successfully_updated" : "successfully_added"),
            true
          )
          setAddStudentOpen(false)
          setLoading(false)
        },
        failure: () => {
          ErrorHelper.handleErrors(
            i18n.t(data.id ? "failed_to_update" : "failed_to_add"),
            true
          )
          setLoading(false)
        },
      }
    )
  }
  useEffect(() => {
    init(active)
  }, [])
  useEffect(() => {
    if (active._id != data._id) {
      init(active)
    }
  }, [active])
  const handleChange = (key, value) => {
    data[key] = value
    setData({ ...data })
  }
  const handleSubmit = () => {
    onSave(data)
  }
  const handleStudentAdd = () => {
    addStudentRequest(
      {
        _id: active._id,
        studentId: newGroupStudent,
      },
      {
        success: (data) => {
          SuccessHelper.handleSuccess(i18n.t("successfully_added"), true)
          setActive(data.data)
          setNewGroupStudent()
        },
        failure: () => {
          ErrorHelper.handleErrors(i18n.t("failed_to_add"), true)
        },
      }
    )
  }
  const handleStudentRemoval = (studentId) => {
    removeStudentRequest(
      {
        _id: active._id,
        studentId,
      },
      {
        success: (data) => {
          SuccessHelper.handleSuccess(i18n.t("successfully_deleted"), true)
          setActive(data.data)
        },
        failure: () => {
          ErrorHelper.handleErrors(i18n.t("failed_to_delete"), true)
        },
      }
    )
  }
  const [tab, setTab] = React.useState(-1)

  const handleTabChange = (event, newValue) => {
    setTab(newValue)
  }

  return (
    <div>
      <DotsLoader isloading={loading} />
      {open ? (
        <BaseModal
          open={open}
          title={data._id ? i18n.t("edit_group") : i18n.t("add_group")}
          containerClassName="half-container"
          content={
            <>
              <AppBar position="static" className="custom-tab">
                <Tabs
                  value={tab}
                  onChange={handleTabChange}
                  aria-label="simple tabs example"
                >
                  <Tab label={i18n.t("details")} {...a11yProps(0)} />
                  <Tab
                    label={i18n.t("students")}
                    {...a11yProps(1)}
                    disabled={!data._id}
                  />
                </Tabs>
              </AppBar>
              <TabPanel value={tab} index={0}>
                <div>
                  <ValidatorForm
                    onSubmit={handleSubmit}
                    ref={form}
                    className="w-100"
                    onError={(error) => {
                      if (!error.length) {
                        onSave(data)
                      }
                    }}
                  >
                    <div className="col-12 flex-column flex-md-row px-0 d-flex align-items-md-center mb-3">
                      <label
                        className="font-weight-bold"
                        style={{ minWidth: "120px" }}
                      >
                        {i18n.t("name")}
                      </label>
                      <div style={{ width: "100%" }}>
                        <TextValidator
                          className="w-100"
                          label={i18n.t("name")}
                          onChange={(e) => handleChange("name", e.target.value)}
                          value={data.name || ""}
                          validators={["required"]}
                          errorMessages={[i18n.t("name_is_required")]}
                          variant="outlined"
                        />
                      </div>
                    </div>
                    <div className="col-12 flex-column flex-md-row px-0 d-flex align-items-md-center mb-3">
                      <label
                        className="font-weight-bold"
                        style={{ minWidth: "120px" }}
                      >
                        {i18n.t("description")}
                      </label>
                      <div style={{ width: "100%" }}>
                        <TextValidator
                          className="w-100"
                          label={i18n.t("description")}
                          onChange={(e) =>
                            handleChange("description", e.target.value)
                          }
                          value={data.description || ""}
                          validators={["required"]}
                          errorMessages={[i18n.t("description_is_required")]}
                          variant="outlined"
                        />
                      </div>
                    </div>
                    <div className="row mx-0 px-0 justify-content-end my-4 col-12">
                      <PrimaryButton
                        className="w-128"
                        props={{
                          type: "submit",
                        }}
                        label={data._id ? i18n.t("save") : i18n.t("create")}
                      />
                    </div>
                  </ValidatorForm>
                </div>
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <ValidatorForm
                  onSubmit={() => {
                    console.log("class add submit", newGroupStudent)
                    handleStudentAdd()
                  }}
                  ref={newClassForm}
                  className="w-100"
                >
                  <div className="row mb-3 flex-column flex-md-row px-0 align-items-md-end gap-2 pt-2">
                    <div style={{ flex: 10 }}>
                      <SelectValidator
                        label={i18n.t("student")}
                        onChange={(e) => {
                          setNewGroupStudent(e.target.value)
                        }}
                        value={newGroupStudent || ""}
                        select
                        validators={["required"]}
                        errorMessages={[i18n.t("required")]}
                        variant="outlined"
                      >
                        <MenuItem disabled={true}>{i18n.t("student")}</MenuItem>
                        {students.map((_class) => {
                          return (
                            <MenuItem value={_class._id}>
                              {_class.name}
                            </MenuItem>
                          )
                        })}
                      </SelectValidator>
                    </div>
                    <div className="d-flex gap-2 justify-content-center">
                      <PrimaryButton
                        className="w-98"
                        label={i18n.t("new")}
                        onClick={() => {
                          setAddStudentOpen(true)
                        }}
                      />
                      <PrimaryButton
                        className="w-98"
                        props={{
                          type: "submit",
                        }}
                        label={i18n.t("add")}
                      />
                    </div>
                  </div>
                </ValidatorForm>
                <BasicTable
                  rows={active.students}
                  schema={[
                    {
                      header: i18n.t("phoneNo"),
                      field: "phoneNo",
                    },
                    {
                      header: i18n.t("name"),
                      field: "name",
                    },
                    {
                      header: i18n.t("profile_email"),
                      field: "email",
                    },
                    {
                      key: "action",
                      header: "",
                      render: (val) => {
                        return (
                          <DeleteIcon
                            onClick={() =>
                              deleteConfirmation(() =>
                                handleStudentRemoval(val._id)
                              )
                            }
                          />
                        )
                      },
                    },
                  ]}
                />
              </TabPanel>
            </>
          }
          onClose={onClose}
        />
      ) : null}
      {addStudentOpen && (
        <StudentUpsert
          open={addStudentOpen}
          element={activeStudent}
          onClose={() => {
            setAddStudentOpen(false)
            setActiveStudent({})
          }}
          onSave={onSaveClass}
          presetVendor
        />
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  students: state.student.data,
  loading: state.program.isFetching,
})

const action = {
  getStudentsRequest,
  upsertStudentRequest,
  addStudentRequest,
  removeStudentRequest,
}

export default connect(mapStateToProps, action)(StudentGroupUpsert)
