import { connect } from "react-redux";
import React, { useState } from "react";
import i18n from "../../../i18n";
import DotsLoader from "../../DotsLoader";
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import { removeRequest } from "../../../redux/actions/Saloon/Class";
import BasicTable from "../../Core/BasicTable";
import ClassUpsert from "./ClassUpsert";
import DeleteIcon from "../../Core/BasicTable/Actions/DeleteIcon";
import EditIcon from "../../Core/BasicTable/Actions/EditIcon";
import deleteConfirmation from "../../../helpers/DeleteHelper";

function ClassTable({ rows, onEdit, allServices, removeRequest }) {
  const [open, setOpen] = useState(false);
  const [element, setElement] = useState();
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState();
  const onClose = () => {
    setElement();
    setOpen(false);
  };
  const handleDelete = (_id) => {
    setLoading(true);
    removeRequest(
      { ids: _id },
      {
        success: () => {
          setLoading(false);
          SuccessHelper.handleSuccess(
            "Your service is successfully deleted.",
            true
          );
        },
        failure: () => {
          setLoading(false);
          ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
        },
      }
    );
  };
  return (
    <>
      <DotsLoader isloading={loading} />
      <BasicTable
        rows={rows}
        schema={[
          {
            header: i18n.t("name"),
            field: "name",
          },
          {
            header: i18n.t("duration"),
            field: "duration",
            accessor: (data) => {
              if (!data.duration) {
                return "";
              }
              return i18n.t("duration_with_hours", {
                duration: data.duration,
              });
            },
          },
          {
            header: i18n.t("color"),
            field: "color",
            render: ({ color }) => {
              color = color || "#000000";
              return (
                <div className="d-flex">
                  <div
                    style={{
                      backgroundColor: color,
                      width: 20,
                      height: 20,
                    }}
                    className="mr-1"
                  />
                  {color}
                </div>
              );
            },
          },
          {
            key: "action",
            header: i18n.t("actions"),
            style: { width: 120 },
            render: (val) => {
              return (
                <>
                  <EditIcon onClick={() => onEdit(val)} />
                  <DeleteIcon
                    onClick={() =>
                      deleteConfirmation(() => handleDelete(val._id))
                    }
                  />
                </>
              );
            },
          },
        ]}
      />
      {open && (
        <ClassUpsert
          open={open}
          onClose={onClose}
          element={element}
          allServices={allServices}
          onSave={() => {}}
          id={id}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  rows: state._class.data,
});

const action = {
  removeRequest,
};

export default connect(mapStateToProps, action)(ClassTable);
