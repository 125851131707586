import { connect } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import i18n from "../../../i18n";
import BaseModal from "../../BaseModal";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import PrimaryButton from "../../Core/PrimaryButton";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {
  getRequest as getExpensesRequest,
  insertRequest as insertExpenseRequest,
} from "../../../redux/actions/Saloon/expense";
import ExpenseTable from "../Expense/ExpenseTable.component";
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import DotsLoader from "../../DotsLoader";
import ExpenseUpsert from "../Expense/ExpenseUpsert.component";
import TabPanel from "../../Core/Tabs/TabPanel";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function VendorUpsert({
  open,
  onClose,
  onSave,
  active = {},
  getExpensesRequest,
  insertExpenseRequest,
  companyId,
  taxes,
  expenses,
}) {
  const form = useRef();
  const [data, setData] = useState({});
  const [addExpenseOpen, setAddExpenseOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [expenseReadOnly, setExpenseReadOnly] = useState(false);
  const [activeExpense, setActiveExpense] = useState({});

  const init = (data) => {
    setData({ ...data });
    setTab(0);
    getExpensesRequest({ vendorId: data._id });
  };
  const onSaveExpense = (params) => {
    setLoading(true);
    insertExpenseRequest(
      { companyId, vendorId: data._id, ...params },
      {
        success: (data) => {
          SuccessHelper.handleSuccess(
            i18n.t(data.id ? "successfully_updated" : "successfully_added"),
            true
          );
          setAddExpenseOpen(false);
          setLoading(false);
        },
        failure: () => {
          ErrorHelper.handleErrors(
            i18n.t(data.id ? "failed_to_update" : "failed_to_add"),
            true
          );
          setLoading(false);
        },
      }
    );
  };
  useEffect(() => {
    init(active);
  }, []);
  useEffect(() => {
    if (active._id != data._id) {
      init(active);
    }
  }, [active]);
  const handleChange = (key, value) => {
    data[key] = value;
    setData({ ...data });
  };
  const handleSubmit = () => {
    onSave(data);
  };
  const [tab, setTab] = React.useState(-1);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };
  const onView = (data) => {
    data.taxes = data.taxes
      .map((setTax) => {
        return taxes.find((tax) => tax.name == setTax.type);
      })
      .filter((tax) => !!tax);
    setActiveExpense({ ...data });
    setExpenseReadOnly(true);
    setAddExpenseOpen(true);
  };

  return (
    <div>
      <DotsLoader isloading={loading} />
      {open ? (
        <BaseModal
          open={open}
          title={data._id ? i18n.t("edit_vendor") : i18n.t("add_vendor")}
          containerClassName="large-container"
          content={
            <>
              <AppBar position="static" className="custom-tab">
                <Tabs
                  value={tab}
                  onChange={handleTabChange}
                  aria-label="simple tabs example"
                >
                  <Tab label={i18n.t("details")} {...a11yProps(0)} />
                  <Tab
                    label={i18n.t("expenses")}
                    {...a11yProps(1)}
                    disabled={!data._id}
                  />
                </Tabs>
              </AppBar>
              <TabPanel value={tab} index={0}>
                <div className="d-flex">
                  <ValidatorForm
                    onSubmit={handleSubmit}
                    ref={form}
                    className="w-100 row mx-0 p-0"
                    onError={(error) => {
                      if (!error.length) {
                        onSave(data);
                      }
                    }}
                  >
                    <div className="col-md-6 pl-0 pr-0 pr-sm-5 d-sm-flex align-items-center mb-3">
                      <label className="font-weight-bold" style={{ minWidth: '150px' }}>{i18n.t("name")}</label>
                      <div className="mobile-w-100" style={{ width: "calc(100% - 150px)" }}>
                        <TextValidator
                          className="w-100"
                          label={i18n.t("name")}
                          onChange={(e) =>
                            handleChange("vendorName", e.target.value)
                          }
                          value={data.vendorName || ""}
                          validators={["required"]}
                          errorMessages={[i18n.t("name_is_required")]}
                          variant="outlined"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 pr-0 pl-0 d-sm-flex align-items-center mb-3">
                      <label className="font-weight-bold" style={{ minWidth: '150px' }}>{i18n.t("primary_contact")}</label>
                      <div className="mobile-w-100" style={{ width: "calc(100% - 150px)" }}>
                        <TextValidator
                          className="w-100"
                          label={i18n.t("primary_contact")}
                          onChange={(e) =>
                            handleChange("primaryContact", e.target.value)
                          }
                          value={data.primaryContact || ""}
                          validators={["required"]}
                          errorMessages={[i18n.t("primary_contact_is_required")]}
                          variant="outlined"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 pl-0 pr-0 pr-sm-5 d-sm-flex align-items-center mb-3">
                      <label className="font-weight-bold" style={{ minWidth: '150px' }}>{i18n.t("container.profile_email")}</label>
                      <div className="mobile-w-100" style={{ width: "calc(100% - 150px)" }}>
                        <TextValidator
                          className="w-100"
                          label={i18n.t("container.profile_email")}
                          onChange={(e) => handleChange("email", e.target.value)}
                          value={data.email || ""}
                          validators={["isEmail"]}
                          errorMessages={[i18n.t("invalid_email")]}
                          variant="outlined"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 pr-0 pl-0 d-sm-flex align-items-center mb-3">
                      <label className="font-weight-bold" style={{ minWidth: '150px' }}>{i18n.t("phoneNo")}</label>
                      <div className="mobile-w-100" style={{ width: "calc(100% - 150px)" }}>
                        <TextValidator
                          className="w-100"
                          label={i18n.t("phoneNo")}
                          onChange={(e) => handleChange("phoneNo", e.target.value)}
                          value={data.phoneNo || ""}
                          variant="outlined"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 pl-0 pr-0 pr-sm-5 d-sm-flex align-items-center mb-3">
                      <label className="font-weight-bold" style={{ minWidth: '150px' }}>{i18n.t("mobile_phone_no")}</label>
                      <div className="mobile-w-100" style={{ width: "calc(100% - 150px)" }}>
                        <TextValidator
                          className="w-100"
                          label={i18n.t("mobile_phone_no")}
                          onChange={(e) =>
                            handleChange("mobilePhoneNo", e.target.value)
                          }
                          value={data.mobilePhoneNo || ""}
                          variant="outlined"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 pr-0 pl-0 d-sm-flex align-items-center mb-3">
                      <label className="font-weight-bold" style={{ minWidth: '150px' }}>{i18n.t("attention")}</label>
                      <div className="mobile-w-100" style={{ width: "calc(100% - 150px)" }}>
                        <TextValidator
                          className="w-100"
                          label={i18n.t("attention")}
                          onChange={(e) =>
                            handleChange("attention", e.target.value)
                          }
                          value={data.attention}
                          variant="outlined"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 pl-0 pr-0 pr-sm-5 d-sm-flex align-items-center mb-3">
                      <label className="font-weight-bold" style={{ minWidth: '150px' }}>{i18n.t("address")}</label>
                      <div className="mobile-w-100" style={{ width: "calc(100% - 150px)" }}>
                        <TextValidator
                          className="w-100"
                          label={i18n.t("address")}
                          onChange={(e) => handleChange("address", e.target.value)}
                          value={data.address || ""}
                          variant="outlined"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 pr-0 pl-0 d-sm-flex align-items-center mb-3">
                      <label className="font-weight-bold" style={{ minWidth: '150px' }}>{i18n.t("postal_code")}</label>
                      <div className="mobile-w-100" style={{ width: "calc(100% - 150px)" }}>
                        <TextValidator
                          className="w-100"
                          label={i18n.t("postal_code")}
                          onChange={(e) =>
                            handleChange("postalCode", e.target.value)
                          }
                          value={data.postalCode || ""}
                          variant="outlined"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 pl-0 pr-0 pr-sm-5 d-sm-flex align-items-center mb-3">
                      <label className="font-weight-bold" style={{ minWidth: '150px' }}>{i18n.t("city")}</label>
                      <div className="mobile-w-100" style={{ width: "calc(100% - 150px)" }}>
                        <TextValidator
                          className="w-100"
                          label={i18n.t("city")}
                          onChange={(e) => handleChange("city", e.target.value)}
                          value={data.city || ""}
                          variant="outlined"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 pr-0 pl-0 d-sm-flex align-items-center mb-3">
                      <label className="font-weight-bold" style={{ minWidth: '150px' }}>{i18n.t("province")}</label>
                      <div className="mobile-w-100" style={{ width: "calc(100% - 150px)" }}>
                        <TextValidator
                          className="w-100"
                          label={i18n.t("province")}
                          onChange={(e) => handleChange("province", e.target.value)}
                          value={data.province || ""}
                          variant="outlined"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 pl-0 pr-0 pr-sm-5 d-sm-flex align-items-center mb-3">
                      <label className="font-weight-bold" style={{ minWidth: '150px' }}>{i18n.t("country")}</label>
                      <div className="mobile-w-100" style={{ width: "calc(100% - 150px)" }}>
                        <TextValidator
                          className="w-100"
                          label={i18n.t("country")}
                          onChange={(e) => handleChange("country", e.target.value)}
                          value={data.country || ""}
                          variant="outlined"
                        />
                      </div>
                    </div>
                    <div className="row mx-0 px-0 justify-content-end my-4 col-12">
                      <PrimaryButton
                        className="w-128"
                        props={{
                          type: "submit",
                        }}
                        label={data._id ? i18n.t("save") : i18n.t("create")}
                      />
                    </div>
                  </ValidatorForm>
                </div>
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <div className="row my-4 mx-0 px-0 justify-content-end">
                  <PrimaryButton
                    className="w-128"
                    label={i18n.t("add")}
                    onClick={() => {
                      setAddExpenseOpen(true);
                    }}
                  />
                </div>
                <ExpenseTable rows={expenses} onView={onView} />
              </TabPanel>
            </>
          }
          onClose={onClose}
        />
      ) : null}
      {addExpenseOpen && (
        <ExpenseUpsert
          open={addExpenseOpen}
          element={activeExpense}
          onClose={() => {
            setAddExpenseOpen(false);
            setActiveExpense({});
          }}
          onSave={onSaveExpense}
          taxes={taxes}
          presetVendor
          readOnly={expenseReadOnly}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  active: state.vendor.active,
  expenses: state.expense.data,
  loading: state.vendor.isFetching,
});

const action = { getExpensesRequest, insertExpenseRequest };

export default connect(mapStateToProps, action)(VendorUpsert);
