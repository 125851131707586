import styled from "styled-components/macro"

export const WaitingListHeader = styled.div`
  div.searchInput {
    display: flex;
    align-items: center;
    label {
      font-weight: 700;
      margin-right: 2rem;
    }
  }

  @media (max-width: 480px) {
    flex-direction: column;
    padding-top: 2rem;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
    div {
      width: 100%;
      padding: 1em;
      margin-bottom: 0;
    }
  }
`
