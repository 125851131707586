// @flow

import { IMPERSONATE_SALON } from "../ActionTypes"

export function request(payload) {
  return {
    payload,
    type: IMPERSONATE_SALON.REQUEST,
  }
}

export function success(data) {
  return {
    data,
    type: IMPERSONATE_SALON.SUCCESS,
  }
}

export function failure(errorMessage) {
  return {
    errorMessage,
    type: IMPERSONATE_SALON.FAILURE,
  }
}
