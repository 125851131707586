// @flow

import { STUDENT_GROUP } from "../ActionTypes"

export function getRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: STUDENT_GROUP.GET_SAGA,
  }
}

export function get(data) {
  return {
    data,
    type: STUDENT_GROUP.GET,
  }
}

export function removeRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: STUDENT_GROUP.DELETE_SAGA,
  }
}

export function remove(data) {
  return {
    data,
    type: STUDENT_GROUP.DELETE,
  }
}

export function upsertRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: STUDENT_GROUP.UPSERT_SAGA,
  }
}

export function upsert(data) {
  return {
    data,
    type: STUDENT_GROUP.UPSERT,
  }
}

export function removeStudentRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: STUDENT_GROUP.DELETE_STUDENT_SAGA,
  }
}

export function removeStudent(data) {
  return {
    data,
    type: STUDENT_GROUP.DELETE_STUDENT,
  }
}

export function addStudentRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: STUDENT_GROUP.ADD_STUDENT_SAGA,
  }
}

export function addStudent(data) {
  return {
    data,
    type: STUDENT_GROUP.ADD_STUDENT,
  }
}

export function requestFailure() {
  return {
    type: STUDENT_GROUP.REQUEST_FAILURE,
  }
}
