import React from "react";
import i18n from '../../i18n';
import { getNestedValue } from "../../util/objectMethods";
import "./styles.scss"

export default function PricingFooterCard({
  items = [],
  className,
  header
}) {
  return (
    <div className={`pricing-footer-card ${className}`}>
      {header && <div className='header'>{header}</div>}
      <table className="table table-borderless table-layout-fixed">
        <tbody>
          {items.map((val, ind) => {
            const label = getNestedValue(val, ['label'], '')
            const amount = getNestedValue(val, ['amount'], 0)
            return (
              <tr key={label + ind}>
                <td>
                  <span className="tab-text">{label}</span>
                </td>
                <td className="text-right font-weight-bold">
                  {amount}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  )
}