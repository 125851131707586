import React from 'react'
import PropTypes from 'prop-types'
import { ChromePicker } from 'react-color'

const PickerDialog = ({
  value,
  onChange
}) => (
  <div style={{ position: 'relative' }}>
    <div style={{ position: 'absolute', zIndex: '2' }}>
      <ChromePicker
        color={value}
        onChange={onChange}
      />
    </div>
  </div>
)

PickerDialog.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func
}

export default PickerDialog
