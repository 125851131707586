import React, { useMemo, useState } from "react"
import { DotsLoader } from "../../../components"
import i18n from "../../../i18n"
import ActivityAttendanceReportFilters from "./components/ActivityAttendanceReportFilters/ActivityAttendanceReportFilters"
import ActivityAttendanceReportTables from "./components/ActivityAttendanceReportTables"
import { attendanceReportRequest } from "../../../redux/actions/Saloon/ActivityScheduleEvent"
import { useDispatch, useSelector } from "react-redux"
import { ErrorHelper } from "../../../helpers"
import { useEffect } from "react"
import actions from "../../../redux/actions/Saloon/Activity"
import moment from "moment"
import { Pie } from "react-chartjs-2"
import { dynamicColors } from "../../../util/colorMethods"
import PrimaryButton from "../../../components/Core/PrimaryButton"
const { getRequest } = actions

const startDate = moment().subtract(7, "d").toDate()
const endDate = moment().toDate()

export default function ActivityAttendanceReport() {
  const dispatch = useDispatch()
  const loading = useSelector((s) => s.activityScheduleEvent.isFetching)
  const [filters, setFilters] = useState({ startDate, endDate })
  const attendanceReport = useSelector(
    (s) => s.activityScheduleEvent.attendanceReport
  )

  useEffect(() => {
    onFilter()
    dispatch(getRequest())
  }, [])

  const onFilter = (exportToXlsx) => {
    const params = { filters }
    if (exportToXlsx) {
      params.exportToXlsx = true
    }
    dispatch(
      attendanceReportRequest(params, {
        failure() {
          ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true)
        },
      })
    )
  }

  const attendanceGrouping = useMemo(() => {
    const data = {
      labels: [i18n.t("yes"), i18n.t("no")],
    }
    const map = { yes: 0, no: 0 }
    attendanceReport.map((element, index) => {
      const key = element.customer.attended ? "yes" : "no"
      console.log(key, element.customer.attended)
      map[key]++
    })
    const dataset = {
      label: i18n.t("statuses"),
      data: [map.yes, map.no],
      backgroundColor: [dynamicColors(), dynamicColors()],
    }

    data.datasets = [dataset]
    return data
  }, [attendanceReport])

  return (
    <div className="content-container p-4">
      <DotsLoader isloading={loading} />
      <div className="d-sm-flex justify-content-sm-between align-items-center mb-4">
        <h1 className="saloon-dashboard-heading pt-0">
          {i18n.t("activity_attendance_report")}
        </h1>
        <div className="d-flex px-0 justify-content-sm-end gap-1 mt-3">
          <PrimaryButton
            className="px-3"
            label={i18n.t("export_to_excel")}
            onClick={() => onFilter(true)}
          />
          <PrimaryButton
            className="w-98"
            label={i18n.t("submit")}
            onClick={() => onFilter()}
          />
        </div>
      </div>
      <ActivityAttendanceReportFilters
        filters={filters}
        setFilters={setFilters}
        onFilter={onFilter}
      />
      <ActivityAttendanceReportTables />
      {Boolean(attendanceReport.length) && (
        <div className="mt-3">
          <div className="p-3 p-md-4 dashboard-box m-2">
            <Pie data={attendanceGrouping} />
          </div>
        </div>
      )}
    </div>
  )
}
