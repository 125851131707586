// @flow
import Immutable from "seamless-immutable"
import * as types from "../actions/ActionTypes"

const initialState = Immutable({
  isFetching: false,
  errorMessage: "",
  active: {},
  data: [],
})

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FORM_ANSWER.GET_BY_ID_SAGA:
      return Immutable.merge(state, {
        active: {},
        isFetching: true,
      })
    case types.FORM_ANSWER.GET_BY_ID:
      return Immutable.merge(state, {
        isFetching: false,
        active: action.data,
      })
    case types.FORM_ANSWER.GET_SAGA:
      return Immutable.merge(state, {
        data: [],
        isFetching: true,
      })
    case types.FORM_ANSWER.GET:
      return Immutable.merge(state, {
        isFetching: false,
        data: action.data,
      })
    case types.FORM_ANSWER.GET_BY_CUSTOMER_SAGA:
      return Immutable.merge(state, {
        data: [],
        isFetching: true,
      })
    case types.FORM_ANSWER.DELETE: {
      const newState = { ...state, isFetching: false, data: [...state.data] }
      const { _id } = action.data

      const index = state.data.findIndex((element) => element._id == _id)
      if (index != -1) {
        newState.data.splice(index, 1)
      }
      return newState
    }
    case types.FORM_ANSWER.DELETE_SAGA:
      return Immutable.merge(state, {
        isFetching: true,
      })
    case types.FORM_ANSWER.GET_BY_CUSTOMER:
      return Immutable.merge(state, {
        isFetching: false,
        data: action.data,
      })
    case types.FORM_ANSWER.SUBMIT_FILLED_FORM_SAGA:
      return Immutable.merge(state, {
        isFetching: true,
      })
    case types.FORM_ANSWER.SUBMIT_FILLED_FORM:
      return Immutable.merge(state, {
        isFetching: false,
      })
    case types.FORM_ANSWER.MARK_AS_VIEWED_SAGA:
      return Immutable.merge(state, {
        isFetching: true,
      })
    case types.FORM_ANSWER.MARK_AS_VIEWED:
      return Immutable.merge(state, {
        isFetching: false,
      })
    case types.FORM_ANSWER.REQUEST_FAILURE:
      return Immutable.merge(state, {
        isFetching: false,
      })

    case types.LOGOUT:
      return initialState
    case types.CLEAR_LOGOUT:
      return initialState
    default:
      return state
  }
}
