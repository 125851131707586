import React, { useEffect, useState } from "react"
import i18n from "../../../i18n"
import PrimaryButton from "../../../components/Core/PrimaryButton"
import { DotsLoader } from "../../../components"
import IntakeCategoriesUpsertPopup from "./Components/IntakeCategoriesUpsertPopup"
import BasicTable from "../../../components/Core/BasicTable"
import moment from "moment"
import EditIcon from "../../../components/Core/BasicTable/Actions/EditIcon"
import DeleteIcon from "../../../components/Core/BasicTable/Actions/DeleteIcon"
import { ErrorHelper, SuccessHelper } from "../../../helpers"
import deleteConfirmation from "../../../helpers/DeleteHelper"
import { deleteIntakeCategories } from "../../../config/simpleApiCalls"
import useCache from "../../../context/LocalCache"
import "./styles.scss"
import useScreenSize from "../../../context/ScreenSize"
const IntakeCategories = () => {
  const { getResource } = useCache()
  const [upsertPopupOpen, setUpsertPopupOpen] = useState(false)
  const [active, setActive] = useState(null)
  const [loading, setLoading] = useState(false)
  const intakeCategories = getResource(
    (cache) => cache.company.intakeCategories
  )
  const { screenSize } = useScreenSize()
  const onEdit = (val) => {
    setUpsertPopupOpen(true)
    setActive(val)
  }

  useEffect(() => {
    if (!upsertPopupOpen) {
      setActive(null)
    }
  }, [upsertPopupOpen])

  useEffect(() => {
    if (active?._id) {
      const newActive = intakeCategories.find(({ _id }) => active._id)
      setActive(newActive)
    }
  }, [intakeCategories])

  const handleDelete = (id) => {
    setLoading(true)
    deleteIntakeCategories({ id })
      .then(() => {
        SuccessHelper.handleSuccess(i18n.t("successfully_deleted"), true)
      })
      .catch(() => {
        ErrorHelper.handleErrors(i18n.t("failed_to_delete"), true)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div className="content-container p-4">
      <DotsLoader isloading={loading} />
      <div className="d-flex pb-4 justify-content-between align-items-center flex-wrap gap-2">
        <h1 className="saloon-dashboard-heading">
          {i18n.t("intake_categories")}
        </h1>
        <PrimaryButton
          className="w-128"
          label={i18n.t("create")}
          onClick={() => setUpsertPopupOpen(true)}
        />
      </div>
      <BasicTable
        rows={intakeCategories || []}
        schema={[
          {
            header: i18n.t("name"),
            field: "name",
            style: screenSize === "mobile" ? { width: 200 } : {},
          },
          {
            header: i18n.t("created_on"),
            field: "createdDate",
            accessor: ({ createdDate }) => {
              return moment(createdDate).format("MM-DD-YYYY HH:mm")
            },
            style: screenSize === "mobile" ? { width: 200 } : {},
          },
          {
            key: "action",
            header: i18n.t("actions"),
            style: { width: 160 },
            render: (val) => {
              return (
                <>
                  <EditIcon onClick={() => onEdit(val)} />
                  <DeleteIcon
                    onClick={() =>
                      deleteConfirmation(() => handleDelete(val._id))
                    }
                  />
                </>
              )
            },
          },
        ]}
      />
      <IntakeCategoriesUpsertPopup
        open={upsertPopupOpen}
        setOpen={setUpsertPopupOpen}
        active={active}
        setLoading={setLoading}
      />
    </div>
  )
}

export default IntakeCategories
