import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import BaseModal from "../../../BaseModal";
import i18n from "../../../../i18n";
import TextField from "@material-ui/core/TextField";
import DollarInput from "../../../DollarInput";
import { insertRequest } from "../../../../redux/actions/Saloon/AllFundsRequest";
import { ErrorHelper, SuccessHelper } from "../../../../helpers";
import PrimaryButton from "../../../Core/PrimaryButton";
import SecondaryButton from "../../../Core/SecondaryButton/SecondaryButton";

function AddModal({
  open,
  handleClose,
  available = 0,
  insertRequest,
  companyEmployeeId,
  type,
}) {
  const roundTo2Decimals = (number) =>
    Math.round((number + Number.EPSILON) * 100) / 100;
  const [amount, setAmount] = useState(roundTo2Decimals(available));
  useEffect(() => {
    setAmount(roundTo2Decimals(available));
  }, [available]);
  const [note, setNote] = useState("");
  const [formErrors, setFormErrors] = useState({});
  // const [amount, setAmount] = useState(available)

  const onSaveClick = () => {
    let newErrors = {};
    if (!amount) {
      newErrors.amount = i18n.t("amount_is_required");
    }
    if (amount > available) {
      newErrors.amount = i18n.t("not_greater_than_available", { available });
    }
    setFormErrors(newErrors);
    if (!Object.keys(newErrors).length) {
      insertRequest(
        { amount, note, companyEmployeeId, type },
        {
          success() {
            SuccessHelper.handleSuccess(i18n.t("successfully_added"), true);
            handleClose();
          },
          failure() {
            ErrorHelper.handleErrors(i18n.t("failed_to_add"), true);
          },
        }
      );
    }
  };

  const calculatePercent = (percent) => {
    setAmount(
      Math.round(((percent / 100) * available + Number.EPSILON) * 100) / 100
    );
  };
  return (
    <>
      <BaseModal
        open={open}
        containerClassName={"small-modal"}
        {...{
          title: i18n.t("request_funds"),
          actions: (
            <div className="row justify-content-end px-4">
              <PrimaryButton className="w-128 mb-5" onClick={onSaveClick}>
                {i18n.t("save")}
              </PrimaryButton>
            </div>
          ),
          content: (
            <div className="mt-3">
              <div className="request-amount">
                <h6 className="mb-0">{i18n.t("container.amount")}</h6>
                <TextField
                  value={amount}
                  onChange={setAmount}
                  error={formErrors.amount}
                  variant="outlined"
                />
                <SecondaryButton
                  size="small"
                  onClick={() => calculatePercent(25)}
                >
                  25%
                </SecondaryButton>
                <SecondaryButton
                  size="small"
                  onClick={() => calculatePercent(50)}
                >
                  50%
                </SecondaryButton>
                <SecondaryButton
                  size="small"
                  onClick={() => calculatePercent(75)}
                >
                  75%
                </SecondaryButton>
                <SecondaryButton
                  size="small"
                  onClick={() => calculatePercent(100)}
                >
                  100%
                </SecondaryButton>
              </div>
              <div className="request-note mt-4">
                <h6 className="m-0">{i18n.t("note")}</h6>
                <TextField
                  type="numeric"
                  label={i18n.t("note")}
                  onChange={(e) => setNote(e.target.value)}
                  value={note}
                  variant="outlined"
                />
              </div>
            </div>
          ),
        }}
        onClose={handleClose}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  accountDetails: state.employeeFundsRequest.accountDetails,
});

const action = { insertRequest };

export default connect(mapStateToProps, action)(AddModal);
