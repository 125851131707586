// @flow

import { PROMOTION } from "../ActionTypes"

export function upsertRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: PROMOTION.UPSERT_SAGA,
  }
}

export function upsert(data) {
  return {
    data,
    type: PROMOTION.UPSERT,
  }
}

export function getRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: PROMOTION.GET_SAGA,
  }
}

export function get(data) {
  return {
    data,
    type: PROMOTION.GET,
  }
}

export function removeRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: PROMOTION.REMOVE_SAGA,
  }
}

export function remove(data) {
  return {
    data,
    type: PROMOTION.REMOVE,
  }
}

export function requestFailure() {
  return {
    type: PROMOTION.REQUEST_FAILURE,
  }
}
