import React from "react";
// import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import styled from "styled-components";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import DetailsField from "./DetailsField.component";

const RadioQuestion = styled.div`
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 11px;
  width: 100%;
`;

const GenerateRadioField = ({
  field,
  handleSubmit,
  readonly,
  error,
  answer,
  detailsRequired,
  details,
}) => {
  const handleOnChange = (e, choice = null, type) => {
    if (handleSubmit !== undefined) {
      handleSubmit(e.target.value, field._id, type);
    }
  };
  return (
    <RadioQuestion>
      <FormLabel component="legend">{field.fieldName}</FormLabel>
      <RadioGroup>
        <FormControl
          variant="outlined"
          style={{ width: "100%" }}
          error={Boolean(error)}
        >
          {field.fieldOptions.split("-").map((radioValue) => {
            return (
              <FormControlLabel
                key={`${radioValue}-key`}
                value={radioValue}
                control={
                  <Radio onChange={(e) => handleOnChange(e, null, "answer")} />
                }
                label={radioValue}
                disabled={readonly}
                checked={radioValue == answer}
              />
            );
          })}
          <FormHelperText>{error}</FormHelperText>
        </FormControl>
      </RadioGroup>
      {detailsRequired && (
        // <TextField
        //   onChange={(e) => handleOnChange(e, "details")}
        //   value={details}
        //   type="text"
        //   variant="outlined"
        //   inputProps={{ readOnly: readonly }}
        // />
        <DetailsField
          handleOnChange={handleOnChange}
          details={details}
          readonly={readonly}
        />
      )}
    </RadioQuestion>
  );
};

export default GenerateRadioField;
