import { connect } from "react-redux"
import React, { useEffect, useState } from "react"
import i18n from "../../../i18n"
import {
  getRequest,
  upsertRequest,
  removeRequest,
} from "../../../redux/actions/Saloon/StudentGroup"
import PrimaryButton from "../../../components/Core/PrimaryButton"
import { DotsLoader } from "../../../components"
import { ErrorHelper, SuccessHelper } from "../../../helpers"
import StudentGroupTable from "../../../components/Saloon/StudentGroup/StudentGroupTable"
import StudentGroupUpsert from "../../../components/Saloon/StudentGroup/StudentGroupUpsert"
import SharedStorage from "../../../helpers/Storage"
import useScreenSize from "../../../context/ScreenSize"
const Program = ({
  getRequest,
  upsertRequest,
  vendors = [],
  loading: propsLoading,
}) => {
  const [companyId, setCompanyId] = useState("")
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [active, setActive] = useState()
  const onMount = async () => {
    const company = JSON.parse(await SharedStorage.getItem("company"))
    setCompanyId(company._id)
    setLoading(true)
    getRequest(
      {},
      {
        success: () => setLoading(false),
      }
    )
  }
  const { screenSize } = useScreenSize()
  useEffect(() => {
    onMount()
  }, [])
  const onClose = () => {
    setOpen(false)
  }
  const onSave = (data) => {
    setLoading(true)
    const request = upsertRequest
    request(
      { companyId, ...data },
      {
        success: (data) => {
          SuccessHelper.handleSuccess(
            i18n.t(data.id ? "successfully_updated" : "successfully_added"),
            true
          )
          setActive(data.data)
          setLoading(false)
        },
        failure: () => {
          ErrorHelper.handleErrors(
            i18n.t(data.id ? "failed_to_update" : "failed_to_add"),
            true
          )
          setLoading(false)
        },
      }
    )
  }
  const onEdit = (data) => {
    setActive(data)
    setOpen(true)
  }
  return (
    <div className="content-container p-4">
      <DotsLoader isloading={loading || propsLoading} />
      <div
        style={
          screenSize === "mobile"
            ? {
                position: "sticky",
                top: 0,
                zIndex: 1,
                backgroundColor: "white",
                paddingBottom: "1em",
              }
            : {}
        }
        className="d-flex flex-column flex-md-row gap-2 justify-content-between align-items-center mb-4"
      >
        <h1 className="saloon-dashboard-heading pb-3">
          {i18n.t("student_group")}
        </h1>
        <PrimaryButton
          className="w-128"
          label={i18n.t("create")}
          onClick={() => {
            setActive()
            setOpen(true)
          }}
        />
      </div>
      <StudentGroupTable onEdit={onEdit} />
      {open && (
        <StudentGroupUpsert
          open={open}
          active={active}
          setActive={setActive}
          onClose={onClose}
          onSave={onSave}
          companyId={companyId}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  loading: state.program.isFetching,
})

const action = {
  getRequest,
  upsertRequest,
  removeRequest,
}

export default connect(mapStateToProps, action)(Program)
