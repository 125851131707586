import React from "react";
import BasicTable from "../../Core/BasicTable";
import i18n from "../../../i18n";
import moment from "moment";
import PrimaryButton from "../../Core/PrimaryButton";
import { Images } from "../../../theme";

export default function FormTable({
  data = [],
  handleDelete,
  handleEdit,
  handlePreview,
}) {
  const prepareData = (data) => {
    const fieldsToRender = {};
    const fields = data.questions.map((question) => {
      question = {
        ...question,
        id: question._id,
        fieldOptions: question.fieldOptions.join("-"),
      };
      fieldsToRender[question._id] = question;
      return question;
    });

    const test = {
      data,
      toDelete: [],
      formTitle: data.formTitle,
      formDescription: data.formDescription,
      defaultStatus: data.status,
      formId: data._id,
      fieldsToRender,
      columns: {
        "column-1": {
          id: "column-1",
          fieldIds: fields.map((field) => field._id),
        },
      },
    };
    handlePreview(test);

    {
      /* <Dialog
        // onClose={handleClose}
        // aria-labelledby="simple-dialog-title"
        open={false}
      >
        <DialogTitle id="simple-dialog-title">Form Title</DialogTitle>
      </Dialog> */
    }
    {
      /* {this.state.preview && (
        <FormGenerator
        // fieldsToRender={sortedField}
        // preview={this.state.preview}
        // readonly={true}
        /> */
    }
  };
  const schema = [
    {
      header: i18n.t("name"),
      field: "formTitle",
    },
    {
      header: i18n.t("created_date"),
      field: "createdDate",
      accessor: (row, field) => moment(row[field]).format("MM-DD-YYYY hh:mm a"),
    },
    {
      header: i18n.t("status"),
      field: "status",
      accessor: (row, field) => i18n.t(row[field]),
    },
    {
      header: i18n.t("actions"),
      field: "actions",
      headerClassName: "text-right pr-5",
      render: (row, index) => {
        const { field } = row;
        return (
          <div className="row gap-1 flex-nowrap justify-content-end">
            <PrimaryButton
              className="w-34"
              key={`table-body-button-${field}`}
              onClick={() => prepareData(row)}
            >
              <img src={Images.table_view_icon} alt="view" width="18" />
            </PrimaryButton>
            <PrimaryButton
              className="w-34"
              key={`table-body-button-${field}`}
              onClick={() => handleEdit(row)}
            >
              <img src={Images.table_edit_icon} alt="edit" width="18" />
            </PrimaryButton>
            <PrimaryButton
              className="w-34"
              key={`table-body-button-${field}`}
              onClick={() => handleDelete(row)}
            >
              <img src={Images.table_delete_icon} alt="delete" width="18" />
            </PrimaryButton>
          </div>
        );
      },
    },
  ];

  return <BasicTable rows={data} schema={schema} />;
}
