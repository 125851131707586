import React, { useMemo, useRef } from "react"
import FullCalendar, { formatDate } from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import interactionPlugin from "@fullcalendar/interaction"
import resourceTimeline from "@fullcalendar/resource-timeline"
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid"
import resourceDayGridPlugin from "@fullcalendar/resource-daygrid"
import scrollGridPlugin from "@fullcalendar/scrollgrid"
import useScreenSize from "../../../context/ScreenSize"
import "./PlanView.scss"
import "./PlanCalendar.scss"
const PlanCalendar = ({
  planData,
  start,
  onDateClick,
  renderEventContent,
  selectEvent,
  calendarRef,
  employees,
}) => {
  const { screenSize } = useScreenSize()
  let slotMinTime = `${start ? start : "00"}:00:00`
  const resources = useMemo(() => {
    const filteredEmployees = employees.filter(({ isActive }) => isActive)

    return filteredEmployees.map((employee) => ({
      id: employee.value,
      title: employee.text,
      profile_img: employee.profile_img,
    }))
  }, [employees])

  const resourceLabelContent = ({ resource }) => {
    return (
      <div className="d-flex flex-column align-items-center justify-content-center gap-2">
        <img
          style={{ height: "40px", width: "40px", borderRadius: "50%" }}
          src={resource.extendedProps.profile_img}
        />
        <div>{resource.title}</div>
      </div>
    )
  }
  return (
    <div className="plan-calendar">
      <FullCalendar
        locale="en"
        plugins={[
          dayGridPlugin,
          timeGridPlugin,
          resourceTimeGridPlugin,
          resourceTimeline,
          resourceDayGridPlugin,
          interactionPlugin,
          scrollGridPlugin,
        ]}
        initialView="resourceTimeGrid"
        datesAboveResources
        resources={resources}
        resourceLabelContent={resourceLabelContent}
        events={planData}
        headerToolbar={false}
        stickyFooterScrollbar={true}
        dayMinWidth={screenSize === "mobile" ? 150 : undefined}
        weekends={true}
        slotEventOverlap={false}
        eventClick={selectEvent}
        eventContent={renderEventContent}
        expandRows={true}
        contentHeight={2000}
        slotMinTime={slotMinTime}
        ref={calendarRef}
        allDaySlot={false}
        stickyHeaderDates={true}
        slotDuration={"00:15:00"}
        dateClick={(e) => {
          const {
            date,
            resource: { id },
          } = e
          onDateClick(date, id)
        }}
      />
    </div>
  )
}

export default PlanCalendar
