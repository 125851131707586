import React, { Component, useState } from "react"
import { connect } from "react-redux"
import {
  getRequest,
  removeRequest,
} from "../../redux/actions/Saloon/Subscription"
import i18n from "../../i18n"
import Accordion from "@material-ui/core/Accordion"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { SuccessHelper } from "../../helpers"
import { ErrorHelper } from "../../helpers"
import Swal from "sweetalert2"
import "./style.scss"
import { getNestedValue } from "../../util/objectMethods"
import moment from "moment"
import PrimaryButton from "../Core/PrimaryButton"

const CardManagement = ({
  subscriptions = [],
  insertRequest,
  removeRequest,
  getRequest,
  history,
  productMap,
}) => {
  const [showAddModal, setShowAddModal] = useState(false)
  const [expanded, setExpanded] = useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const removeSubscription = (body, endDate) => {
    Swal.fire({
      title: i18n.t("cancel_subscription"),
      text: i18n.t("cancel_confirmation"),
      showCancelButton: true,
      cancelButtonText: i18n.t("no"),
      confirmButtonText: i18n.t("yes"),
    }).then((result) => {
      if (result.value) {
        removeRequest(body, {
          success: () => {
            SuccessHelper.handleSuccess(i18n.t("successfully_cancelled"), true)
            getRequest()
          },
          failure: () => {
            ErrorHelper.handleErrors(i18n.t("failed_to_cancel"), true)
          },
        })
      }
    })
  }

  return (
    <div>
      {subscriptions.length ? (
        <div className="pt-3 row gap-5">
          {subscriptions.map((subscription) => {
            const nextPaymentOn = moment
              .unix(subscription.current_period_end)
              .format("MM-DD-YYYY")
            const total = subscription.items.data.reduce(
              (accum, item) => accum + item.price.unit_amount * item.quantity,
              0
            )
            const totalFormatted = (total / 100).toFixed(2)
            let frequency = i18n.t("yearly")
            console.log(subscription.items.data)
            const interval = getNestedValue(subscription.items.data, [
              0,
              "price",
              "recurring",
              "interval",
            ])
            const intervalCount = getNestedValue(subscription.items.data, [
              0,
              "price",
              "recurring",
              "interval_count",
            ])
            if (interval == "month") {
              if (intervalCount == 3) {
                frequency = i18n.t("quarterly")
              } else {
                frequency = i18n.t("monthly")
              }
            }
            return (
              <div className="col-md-4 px-0">
                <Accordion
                  key={subscription.id}
                  // expanded={expanded === subscription.id}
                  onChange={handleChange(subscription.id)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <div className="row mx-0 justify-content-between align-items-center w-100">
                      <div className="row mx-0">
                        <div style={{ alignSelf: "center" }} className="pl-1">
                          {subscription.items.length} {i18n.t("items")}
                        </div>
                        <div style={{ alignSelf: "center" }} className="pl-2">
                          (${totalFormatted} {frequency})
                        </div>
                      </div>
                      <div style={{ alignSelf: "center" }}>
                        {i18n.t("next_bundle_payment_on", { nextPaymentOn })}
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails className="d-block">
                    <table className="table bg-white">
                      <thead>
                        <tr>
                          <th>{i18n.t("name")}</th>
                          <th>{i18n.t("quantity")}</th>
                          <th>{i18n.t("cost")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {subscription.items.data.map((item) => {
                          const costFormatted = (
                            (getNestedValue(item, ["price", "unit_amount"], 0) *
                              item.quantity) /
                            100
                          ).toFixed(2)
                          return (
                            <tr>
                              <td>
                                {getNestedValue(
                                  productMap,
                                  [item?.price?.product, "name"],
                                  ""
                                )}
                              </td>
                              <td>{item.quantity}</td>
                              <td>${costFormatted}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                    <div className="row mx-0 justify-content-end">
                      <PrimaryButton
                        className="w-128"
                        onClick={() => {
                          removeSubscription(
                            { id: subscription.id },
                            totalFormatted
                          )
                        }}
                      >
                        {i18n.t("cancel")}
                      </PrimaryButton>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            )
          })}
        </div>
      ) : (
        <div className="pt-3"></div>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({})

const action = { removeRequest, getRequest }

export default connect(mapStateToProps, action)(CardManagement)
