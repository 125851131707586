import React from "react"
import { connect } from "react-redux"
import { DotsLoader } from "../../../components"
import { Images } from "../../../theme"
import { Translation } from "react-i18next"
import i18n from "../../../i18n"
import { SuccessHelper, ErrorHelper } from "../../../helpers"
import { request as employee_register } from "../../../redux/actions/Employee/Register"
import {
  addCompanyByEmployee,
  getEmployeeServices,
  createEmployeeProfile,
} from "../../../config/simpleApiCalls"
import NumberFormat from "react-number-format"
import PlacesAutocomplete from "../../../components/PlacesAutocomplete"
import TextField from "@material-ui/core/TextField"
import PrimaryButton from "../../../components/Core/PrimaryButton"
import SharedStorage from "../../../helpers/Storage"

class EmployeeRegister extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      // Personal Information
      firstName: "",
      lastName: "",
      password: "",
      password: "",
      confirmedPassword: "",
      address: "",
      city: "",
      province: "",
      postalCode: "",
      telephone: "",
      email: "",

      // Personal Information Error
      firstNameError: "",
      lastNameError: "",
      passwordError: "",
      addressError: "",
      cityError: "",
      provinceError: "",
      postalCodeError: "",
      telephoneError: "",
      emailError: "",
      // Working Hours
      employeesWorkingHoursData: [
        { dayOfWeek: 1, availableStatus: 0, dayName: i18n.t("Sunday") },
        { dayOfWeek: 2, availableStatus: 0, dayName: i18n.t("Monday") },
        { dayOfWeek: 3, availableStatus: 0, dayName: i18n.t("Tuesday") },
        { dayOfWeek: 4, availableStatus: 0, dayName: i18n.t("Wednesday") },
        { dayOfWeek: 5, availableStatus: 0, dayName: i18n.t("Thursday") },
        { dayOfWeek: 6, availableStatus: 0, dayName: i18n.t("Friday") },
        { dayOfWeek: 7, availableStatus: 0, dayName: i18n.t("Saturday") },
      ],
      employeesWorkingHoursError: false,

      // Services
      serviceList: [],
      employeesServicesError: false,

      isWorkingHourVerified: false,
      isServicesVerified: false,
      isloading: false,
      formTypeId: 1,
      formTypeText: (
        <Translation>{(t) => t("container.personal_info")}</Translation>
      ),
      checkinval: null,
      checkoutval: null,
      newUser: null,
      companyId: null,
      registerError: "",
    }
  }

  async componentDidMount() {
    this.setState({
      companyId: JSON.parse(await SharedStorage.getItem("company"))?._id,
    })
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.employeeRegister !== this.props.employeeRegister) {
      if (
        !nextProps.employeeRegister.failure &&
        !nextProps.employeeRegister.isFetching &&
        !nextProps.employeeRegister.errorMessage &&
        nextProps.employeeRegister.data &&
        nextProps.employeeRegister.data.data &&
        nextProps.employeeRegister.data.data.success &&
        nextProps.employeeRegister.data?.data?.data?.access_token
      ) {
        this.setState(
          {
            newUser: nextProps.employeeRegister.data?.data?.data,
          },
          () => this.getAllEmployeeServices()
        )
      } else if (
        (nextProps.employeeRegister.failure &&
          !nextProps.employeeRegister.isFetching &&
          nextProps.employeeRegister.errorMessage) ||
        !nextProps.employeeRegister.data?.data?.success
      ) {
        if (nextProps.employeeRegister.data?.data?.alreadyExist) {
          Swal.fire({
            title: i18n.t("user_exists_already"),
            text: i18n.t("use_this_profile"),
            showCancelButton: true,
            cancelButtonText: i18n.t("no"),
            confirmButtonText: i18n.t("yes"),
          }).then(async (e) => {
            const { isConfirmed } = e
            if (isConfirmed) {
              this.setState(
                {
                  alreadyExist:
                    nextProps.employeeRegister.data?.data?.alreadyExist,
                  employeeProfile:
                    nextProps.employeeRegister.data?.data?.employee,
                },
                () => this.getAllEmployeeServices()
              )
            }
          })
        } else if (nextProps.employeeRegister.data?.data?.msg) {
          ErrorHelper.handleErrors(
            nextProps.employeeRegister.data?.data?.msg,
            true
          )
        }
        this.setState({ isloading: false })
      }
    }
  }

  // Personal Information
  onChangeFirstName = (text) => this.setState({ firstName: text.target.value })
  onChangeLastName = (text) => this.setState({ lastName: text.target.value })
  OnChangePassword = (text) => this.setState({ password: text.target.value })
  OnChangeConfirmedPassword = (text) =>
    this.setState({ confirmedPassword: text.target.value })
  onChangeTelephone = (text) => this.setState({ telephone: text.target.value })
  onChangeEmail = (text) => this.setState({ email: text.target.value })

  onChangeWorkingHrCheckbox = (e, ind) => {
    const { employeesWorkingHoursData } = this.state
    if (e.target.checked) {
      employeesWorkingHoursData[ind].availableStatus = 1
      this.setState({ employeesWorkingHoursData })
    } else {
      employeesWorkingHoursData[ind].availableStatus = 0
      this.setState({ employeesWorkingHoursData })
    }
  }

  onChangeServiceCheckbox = (e, ind) => {
    const { serviceList } = this.state
    if (e.target.checked) {
      serviceList[ind].isSelected = true
      this.setState({ serviceList })
    } else {
      serviceList[ind].isSelected = false
      this.setState({ serviceList })
    }
  }

  onChangeCheckIn = (text, ind) => {
    const { employeesWorkingHoursData } = this.state

    if (text.target.value) {
      employeesWorkingHoursData[ind].checkIn = text.target.value
    }
    this.setState({ employeesWorkingHoursData, checkinval: text.target.value })
  }

  onChangeCheckOut = (text, ind) => {
    const { employeesWorkingHoursData, checkinval } = this.state
    let isValidCheckin = checkinval != null ? checkinval.split(":") : ""
    let isValidCheckout = text.target.value.split(":")

    if (text.target.value) {
      if (
        isValidCheckout[0] === isValidCheckin[0] &&
        isValidCheckin[1] >= isValidCheckout[1]
      ) {
        employeesWorkingHoursData[ind].checkOut = ""
        setTimeout(() => {
          ErrorHelper.handleErrors(
            "Checkout time should be greater than checkin",
            true
          )
        }, 1000)
      } else if (isValidCheckin[0] > isValidCheckout[0]) {
        employeesWorkingHoursData[ind].checkOut = ""
        setTimeout(() => {
          ErrorHelper.handleErrors(
            "Checkout time should be greater than checkin",
            true
          )
        }, 1000)
      } else {
        employeesWorkingHoursData[ind].checkOut = text.target.value
      }
    }
    this.setState({ employeesWorkingHoursData })
  }

  checkValidation = () => {
    const { formTypeId } = this.state
    if (formTypeId === 1) {
      this.validatePersonalInformation()
    } else if (formTypeId === 2) {
      this.validateServicesWorkingHours()
    }
  }

  validatePersonalInformation = () => {
    const {
      firstName,
      lastName,
      password,
      telephone,
      email,
      confirmedPassword,
      addressMap,
    } = this.state

    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const nameRegex =
      /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{2,}$/u
    const telephoneRegex =
      /[0-9][0-9][0-9] [0-9][0-9][0-9] [0-9][0-9][0-9][0-9]/g
    if (!firstName) {
      this.setState({
        firstNameError: (
          <Translation>{(t) => t("first_name_required")}</Translation>
        ),
      })
      setTimeout(() => {
        this.setState({
          firstNameError: "",
        })
      }, 6000)
    } else if (!firstName.match(nameRegex)) {
      this.setState({
        firstNameError: (
          <Translation>
            {(t) => t("first_name_lenght_minimum_required")}
          </Translation>
        ),
      })
      setTimeout(() => {
        this.setState({
          firstNameError: "",
        })
      }, 6000)
    } else if (!lastName) {
      this.setState({
        lastNameError: (
          <Translation>{(t) => t("last_name_required")}</Translation>
        ),
      })
      setTimeout(() => {
        this.setState({
          lastNameError: "",
        })
      }, 6000)
    } else if (!lastName.match(nameRegex)) {
      this.setState({
        lastNameError: (
          <Translation>
            {(t) => t("last_name_lenght_minimum_required")}
          </Translation>
        ),
      })
      setTimeout(() => {
        this.setState({
          lastNameError: "",
        })
      }, 6000)
    } else if (!email) {
      this.setState({
        emailError: <Translation>{(t) => t("email_required")}</Translation>,
      })
      setTimeout(() => {
        this.setState({
          emailError: "",
        })
      }, 6000)
    } else if (!email.match(emailRegex)) {
      this.setState({
        emailError: <Translation>{(t) => t("invalid_email")}</Translation>,
      })
      setTimeout(() => {
        this.setState({
          emailError: "",
        })
      }, 6000)
    } else if (!telephone) {
      this.setState({
        telephoneError: (
          <Translation>{(t) => t("telephone_required")}</Translation>
        ),
      })
      setTimeout(() => {
        this.setState({
          telephoneError: "",
        })
      }, 6000)
    } else if (!telephone.match(telephoneRegex)) {
      this.setState({
        telephoneError: (
          <Translation>{(t) => t("invalid_phone_number")}</Translation>
        ),
      })

      setTimeout(() => {
        this.setState({
          telephoneError: "",
        })
      }, 6000)
    } else if (!password) {
      this.setState({
        passwordError: (
          <Translation>{(t) => t("confirm_password_not_match")}</Translation>
        ),
      })
      setTimeout(() => {
        this.setState({
          passwordError: "",
        })
      }, 6000)
    } else if (password.length < 6) {
      this.setState({
        passwordError: (
          <Translation>{(t) => t("password_min_length")}</Translation>
        ),
      })
      setTimeout(() => {
        this.setState({
          passwordError: "",
        })
      }, 6000)
    } else if (password != confirmedPassword) {
      this.setState({
        passwordError: (
          <Translation>{(t) => t("confirm_password_not_match")}</Translation>
        ),
      })
      setTimeout(() => {
        this.setState({
          passwordError: "",
        })
      }, 6000)
    } else if (addressMap == undefined || Object.keys(addressMap) == 0) {
      this.setState({
        addressError: i18n.t("address_required"),
      })
      setTimeout(() => {
        this.setState({
          addressError: "",
        })
      }, 6000)
    } else {
      this.handleRegister()
    }
  }

  handleRegister = () => {
    this.setState({ isloading: true })
    const {
      firstName,
      lastName,
      password,
      telephone,
      email,
      addressMap,
      companyId,
    } = this.state
    let payload = {
      firstName,
      lastName,
      email,
      phoneNo: telephone,
      password,
      platform: "android",
      role: "4",
      companyId: companyId,
    }
    if (Object.keys(addressMap).length) {
      payload = { ...payload, ...addressMap }
    }
    this.props.employee_register(payload)
  }

  getAllEmployeeServices = () => {
    this.setState({ isloading: true })
    getEmployeeServices()
      .then((res) => {
        if (res.data.success) {
          for (let i = 0; i < res.data.data.length; i++) {
            res.data.data[i].isSelected = false
          }
          this.setState({
            isloading: false,
            serviceList: res.data.data,
            formTypeId: 2,
            formTypeText: `
              ${i18n.t("container.services")} / ${i18n.t(
              "container.working_hours"
            )}`,
          })
        }
      })
      .catch((error) => {
        console.log(error, "errorrrrrrrrrrrrrrrrrrr")
      })
  }

  validateServicesWorkingHours = () => {
    const { employeesWorkingHoursData, serviceList } = this.state
    for (let i = 0; i < employeesWorkingHoursData.length; i++) {
      if (employeesWorkingHoursData[i].availableStatus === 1) {
        if (!employeesWorkingHoursData[i].checkIn) {
          this.setState({
            isWorkingHourVerified: false,
            employeesWorkingHoursError: true,
          })
          setTimeout(() => {
            this.setState({
              isWorkingHourVerified: false,
              employeesWorkingHoursError: false,
            })
          }, 6000)
          return
        } else if (!employeesWorkingHoursData[i].checkOut) {
          this.setState({
            isWorkingHourVerified: false,
            employeesWorkingHoursError: true,
          })
          setTimeout(() => {
            this.setState({
              isWorkingHourVerified: false,
              employeesWorkingHoursError: false,
            })
          }, 6000)
          return
        } else {
          this.setState({
            isWorkingHourVerified: true,
            employeesWorkingHoursError: false,
          })
        }
      }
    }
    for (let i = 0; i < serviceList.length; i++) {
      if (serviceList[i].isSelected) {
        this.setState({
          isServicesVerified: true,
          employeesServicesError: false,
        })
      }
    }
    if (isWorkingHourVerified && isServicesVerified) {
      this.handleCreateEmployeeProfile()
    }
    if (serviceList.find((service) => service.isSelected == true)) {
      this.setState({
        isServicesVerified: true,
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { isServicesVerified, isWorkingHourVerified } = this.state
    if (
      prevState.isServicesVerified !== isServicesVerified &&
      prevState.isWorkingHourVerified !== isWorkingHourVerified &&
      isWorkingHourVerified &&
      isServicesVerified
    ) {
      this.handleCreateEmployeeProfile()
    }
  }
  handleCreateEmployeeProfile = async () => {
    this.setState({ isloading: true })
    const {
      serviceList,
      employeesWorkingHoursData,
      newUser,
      companyId,
      alreadyExist,
      employeeProfile,
    } = this.state
    const employeesWorkingHoursSorted = employeesWorkingHoursData.map(function (
      obj
    ) {
      if (obj.availableStatus === 0) {
        delete obj["checkIn"] // Delete old key
        delete obj["checkOut"] // Delete old key
      }
      delete obj["dayName"] // Delete old key
      delete obj["_id"] // Delete old key
      return obj
    })
    let serviceListSorted = []
    for (let i = 0; i < serviceList.length; i++) {
      if (serviceList[i].isSelected) {
        let myService = { charges: 1, serviceId: serviceList[i]._id }
        serviceListSorted.push(myService)
      }
    }
    const payload = {
      services: serviceListSorted,
      weekPlans: employeesWorkingHoursSorted,
      userId: alreadyExist ? alreadyExist._id : newUser._id,
    }
    if (employeeProfile) {
      payload._id = employeeProfile._id
    }
    try {
      const response = employeeProfile
        ? await updateEmployeeProfile(payload)
        : await createEmployeeProfile(payload)
      if (response.data.success) {
        if (!this.props.handleNavigation) {
          addCompanyByEmployee({
            companyId: companyId,
            userId: alreadyExist ? alreadyExist._id : newUser._id,
          })
            .then((res) => {
              if (res.data.success) {
                this.props.handleAdd(response.data.data)
                this.setState({ formTypeId: 1 })
              }
            })
            .catch((error) => {
              this.setState({ isloading: false })
            })
        } else {
          SuccessHelper.handleSuccess("You have successfully Signup.", true)
          this.props.handleNavigation("/partner-login")
        }
      }
    } catch (error) {
      this.setState({ isloading: false })
    }
  }

  renderLoader = () => {
    const { isloading } = this.state
    return <DotsLoader isloading={isloading} />
  }

  // Card Heading
  renderCardHeading = () => {
    return (
      <>
        <h1 id="register-heading" className="mb-5">
          {this.props.handleNavigation
            ? i18n.t("container.register_yourself_employee")
            : i18n.t("add_new_employee")}
        </h1>
      </>
    )
  }

  renderStepSectionLg = () => {
    const { formTypeId } = this.state
    return (
      <div
        className="col-12 col-md-6 p-4"
        id="step-section-lg"
        style={{ backgroundColor: "#F4F6F8", borderRadius: 7 }}
      >
        <div className="row">
          <hr id="steps-dots-line" />
          <div className="col-3 d-flex align-items-center">
            {(formTypeId === 1 || formTypeId === 2) && (
              <img src={Images.step_done_tick} className="step_done_tick" />
            )}
            <span className="steps-text">
              <Translation>{(t) => t("container.personal_info")}</Translation>
            </span>
          </div>
          <div className="col-4 d-flex align-items-center">
            {formTypeId === 2 && (
              <img src={Images.step_done_tick} className="step_done_tick" />
            )}
            <span className="steps-text">
              <Translation>{(t) => t("container.services")}</Translation> /{" "}
              <Translation>{(t) => t("container.working_hours")}</Translation>
            </span>
          </div>
        </div>
      </div>
    )
  }

  renderStepSectionSm = () => {
    const { formTypeId, formTypeText } = this.state
    return (
      <div
        className="col-12 p-3 py-md-4"
        id="step-section-sm"
        style={{ backgroundColor: "#FFF6EF" }}
      >
        <div className="row">
          <div className="col-12 d-flex align-items-center">
            <span className="step_no">{formTypeId}</span>
            <span className="steps-text-sm">{formTypeText}</span>
          </div>
        </div>
      </div>
    )
  }

  //Main Content
  renderContentSection = () => {
    const { formTypeId, employeesServicesError, employeesWorkingHoursError } =
      this.state
    return (
      <div id={this.props.handleNavigation ? "register-saloon-card" : ""}>
        {this.renderCardHeading()}
        {this.renderStepSectionLg()}
        {this.renderStepSectionSm()}
        {formTypeId === 1 && this.renderPersonalInformationForm()}
        {formTypeId === 2 && this.renderServicesWorkingHours()}
        <div className="col-12 px-0">
          {formTypeId === 2 && (
            <p className="text-danger">
              <strong>Note : </strong>
              <Translation>
                {(t) => t("service_working_hour_required")}
              </Translation>
              .
            </p>
          )}
          {employeesWorkingHoursError && (
            <p className="mb-0 text-danger">
              <Translation>
                {(t) => t("container.working_hours_required")}
              </Translation>
            </p>
          )}
          {employeesServicesError && (
            <p className="mb-0 text-danger">
              <strong>Error : </strong>
              <Translation>
                {(t) => t("container.add_service_price_required")}
              </Translation>
            </p>
          )}
          {formTypeId === 2 && (
            <PrimaryButton
              className="w-128 row justify-content-center justify-content-md-start"
              id="saloon-form-submit"
              onClick={() => this.checkValidation()}
            >
              Save
            </PrimaryButton>
          )}
        </div>
      </div>
    )
  }

  renderInputCol6 = (id, label, inputType, onChange, errorType) => {
    return (
      <div className="site-form row align-items-center gap-1 form-group col-md-12 px-2">
        <label className="font-weight-bold">{label}</label>
        <TextField
          id={id}
          label={label}
          className="editField"
          variant="outlined"
          type={inputType}
          onChange={(text) => onChange(text)}
        />
        {errorType ? (
          <small className="form-text text-danger ml-3">{errorType}</small>
        ) : null}
      </div>
    )
  }

  inputTypeNumber = (id, lable, inputType, onChange, errorMessage) => {
    return (
      <div className="site-form row align-items-center gap-1 form-group col-md-12 px-2">
        <label htmlFor={id} className="font-weight-bold">
          {lable}:
        </label>
        <NumberFormat
          type={inputType}
          className="form-control"
          id={id}
          onChange={(text) => onChange(text)}
          format="### ### ####"
          style={{ width: "35%" }}
        />

        {errorMessage ? (
          <small className="form-text text-danger ml-3">{errorMessage}</small>
        ) : null}
      </div>
    )
  }

  renderAddress = () => {
    return (
      <div className="site-form row align-items-center gap-1 form-group col-md-12 p-2">
        <label className="font-weight-bold">{i18n.t("address")}</label>
        <div className="w-35" style={{ width: "35%" }}>
          <PlacesAutocomplete
            variant="outlined"
            addressError={this.state.addressError}
            addressSelected={(newMap) => {
              const addressMap = Object.assign(
                {},
                ...["address", "city", "country", "postalCode", "province"].map(
                  (key) => ({
                    [key]: newMap[key],
                  })
                )
              )
              this.setState({
                addressMap,
              })
            }}
          />
        </div>
      </div>
    )
  }

  renderPersonalInformationForm = () => {
    return (
      <div className="col-12 px-0 mt-5">
        {this.renderInputCol6(
          "first-name",
          i18n.t("first_name"),
          "text",
          this.onChangeFirstName,
          this.state.firstNameError
        )}
        {this.renderInputCol6(
          "last-name",
          i18n.t("last_name"),
          "text",
          this.onChangeLastName,
          this.state.lastNameError
        )}
        {this.inputTypeNumber(
          "telephone",
          <Translation>{(t) => t("container.telephone")}</Translation>,
          "text",
          this.onChangeTelephone,
          this.state.telephoneError
        )}
        {this.renderInputCol6(
          "email",
          <Translation>{(t) => t("container.profile_email")}</Translation>,
          "email",
          this.onChangeEmail,
          this.state.emailError
        )}
        {this.renderAddress()}
        {this.renderInputCol6(
          "password",
          i18n.t("password"),
          "password",
          this.OnChangePassword,
          this.state.passwordError
        )}
        {this.renderInputCol6(
          "confirm-password",
          i18n.t("confirm_password"),
          "password",
          this.OnChangeConfirmedPassword,
          this.state.passwordError
        )}
        <div className="mt-5 row justify-content-center justify-content-md-start">
          <PrimaryButton
            className="w-128"
            onClick={() => this.checkValidation()}
          >
            Save
          </PrimaryButton>
        </div>
      </div>
    )
  }

  renderWorkingHoursInput = (ind, data) => {
    return (
      <div className="form-row working-days-table">
        <div className=" px-0 pr-md-3 d-flex align-items-center">
          <input
            value={data.checkIn}
            type="time"
            className="form-control edit-employee-input"
            onChange={(text) => this.onChangeCheckIn(text, ind)}
          />
        </div>
        <div className="px-3 px-md-0 d-flex align-items-center">
          <span>to</span>
        </div>
        <div
          className="  px-0 pl-md-3 d-flex align-items-center"
          style={{ marginBottom: "0px !important" }}
        >
          <input
            value={data.checkOut}
            type="time"
            className="form-control edit-employee-input"
            onChange={(text) => this.onChangeCheckOut(text, ind)}
          />
        </div>
      </div>
    )
  }

  renderWorkingHoursTable = () => {
    const { employeesWorkingHoursData } = this.state
    return (
      <div className="col-12 p-0">
        <div className="table-responsive">
          <table className="table table-borderless saloon-working-hour-table">
            <thead>
              <tr>
                <th scope="col" className="pl-0">
                  <span className="working-day-th-text pl-0">
                    <Translation>
                      {(t) => t("container.add_employee_working_days")}
                    </Translation>
                  </span>
                </th>
                <th className="text-center" scope="col">
                  <span className="working-day-th-text pl-0">
                    <Translation>{(t) => t("container.hours")}</Translation>
                  </span>
                </th>
                <th className="text-center" scope="col">
                  <span className="working-day-th-text pl-0">
                    <Translation>
                      {(t) => t("container.availability")}
                    </Translation>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {employeesWorkingHoursData.map((val, ind) => {
                return (
                  <tr key={ind} style={{ borderTop: "3px solid #F7F7F7" }}>
                    <td>
                      <span className="working-days-name">{val.dayName}</span>
                    </td>
                    <td style={{ display: "flex", justifyContent: "center" }}>
                      {val.availableStatus === 1 && (
                        <span style={{ alignItems: "center" }}>
                          {this.renderWorkingHoursInput(ind, val)}
                        </span>
                      )}
                      {val.availableStatus === 0 && (
                        <PrimaryButton className="w-98">OFF</PrimaryButton>
                      )}
                    </td>
                    <td className="text-center">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id={ind}
                          checked={val.availableStatus ? true : false}
                          onChange={(e) =>
                            this.onChangeWorkingHrCheckbox(e, ind)
                          }
                        />
                        <label
                          className="custom-control-label"
                          for={ind}
                        ></label>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  renderServicesTable = () => {
    const { serviceList } = this.state
    return (
      <div className="col-12 p-0">
        <div className="table-responsive">
          <table className="table table-borderless saloon-working-hour-table">
            <thead>
              <tr>
                <th scope="col" className="pl-0">
                  <span className="working-day-th-text pl-0">
                    <Translation>
                      {(t) => t("container.profile_service")}
                    </Translation>
                  </span>
                </th>
                <th className="text-center" scope="col">
                  <span className="working-day-th-text pl-0">
                    <Translation>{(t) => t("by_price")}</Translation>
                  </span>
                </th>
                <th className="text-center" scope="col">
                  <span className="working-day-th-text pl-0">
                    <Translation>{(t) => t("container_choose")}</Translation>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {serviceList.map((val, ind) => {
                return (
                  <tr key={ind} style={{ borderTop: "3px solid #F7F7F7" }}>
                    <td>
                      <span className="working-days-name text-capitalize">
                        {val.name}
                      </span>
                    </td>
                    <td style={{ display: "flex", justifyContent: "center" }}>
                      {!val.isSelected && (
                        <PrimaryButton className="px-3">
                          <Translation>
                            {(t) => t("container.not_selected")}
                          </Translation>
                        </PrimaryButton>
                      )}
                      {val.isSelected && (
                        <PrimaryButton className="px-3">
                          <Translation>
                            {(t) => t("container.selected")}
                          </Translation>
                        </PrimaryButton>
                      )}
                    </td>
                    <td className="text-center">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id={val._id}
                          checked={val.isSelected ? true : false}
                          onChange={(e) => this.onChangeServiceCheckbox(e, ind)}
                        />
                        <label
                          className="custom-control-label"
                          for={val._id}
                        ></label>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  renderServicesWorkingHours = () => {
    return (
      <div className="col-12 px-0 mt-5">
        <h3 className="form-heading">
          <Translation>{(t) => t("container.working_hours")}</Translation>:
        </h3>
        {this.renderWorkingHoursTable()}
        <h3 className="form-heading">
          <Translation>{(t) => t("container.services")}</Translation>:
        </h3>
        {this.renderServicesTable()}
      </div>
    )
  }

  render() {
    return (
      <div>
        {this.renderLoader()}
        {this.renderContentSection()}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  employeeRegister: state.employeeRegister,
})

const action = {
  employee_register,
}

export default connect(mapStateToProps, action)(EmployeeRegister)
