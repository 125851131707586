import * as types from "../../actions/ActionTypes"
import { success, failure } from "../../actions/Saloon/CreateSaloon"
import { take, put, call, fork } from "redux-saga/effects"
import { create_saloon_Api } from "../../../config/WebServices"
import { ErrorHelper } from "../../../helpers"
import axios from "axios"

function callRequest(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(create_saloon_Api, data.formData, {
        headers: { Authorization: data.access_token },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response.data)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}
function* watchRequest() {
  while (true) {
    const { payload } = yield take(types.CREATE_SALOON.REQUEST)
    try {
      const data = yield call(callRequest, payload)
      yield put(success(data))
    } catch (err) {
      yield put(failure(err))
      ErrorHelper.handleErrors(err, true)
    }
  }
}

export default function* root() {
  yield fork(watchRequest)
}
