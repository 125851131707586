// @flow

import { SALOON_PAYMENT_METHOD, SALOON_LOGOUT } from "../ActionTypes"

export function request(payload) {
  return {
    payload,
    type: SALOON_PAYMENT_METHOD.REQUEST,
  }
}

export function success(data) {
  return {
    data,
    type: SALOON_PAYMENT_METHOD.SUCCESS,
  }
}

export function failure(errorMessage) {
  return {
    errorMessage,
    type: SALOON_PAYMENT_METHOD.FAILURE,
  }
}
