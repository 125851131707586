import React, { useEffect } from "react"
import i18n from "../../i18n"
import TextField from "@material-ui/core/TextField"
import DollarInput from "../DollarInput"
import CounterInput from "../CounterInput"
import SecondaryButton from "../Core/SecondaryButton/SecondaryButton"
const roundTo2Decimals = (x) => Math.round((x + Number.EPSILON) * 100) / 100

export default function PaymentForm({
  handleChange,
  data,
  giftCardBalance,
  formErrors,
}) {
  const {
    subtotal = 0,
    balanceDue = 0,
    tip = 0,
    includeTaxes,
    taxDue = 0,
  } = data
  useEffect(() => {
    const newTotal = roundTo2Decimals(
      parseFloat(subtotal) + parseFloat(tip) + (includeTaxes ? taxDue : 0)
    )
    handleChange("balanceDue", newTotal)
  }, [subtotal, tip, includeTaxes, taxDue])
  const difference = roundTo2Decimals(
    balanceDue -
      parseFloat(data.cash || 0) -
      parseFloat(data.creditDebit || 0) -
      parseFloat(data.giftCard || 0) -
      (data.discountAmount || 0) -
      (data.collected || 0)
  )
  return (
    <>
      <div className="col-12 px-0 d-flex align-items-center mb-3">
        <label className="font-weight-bold" style={{ minWidth: "150px" }}>
          {i18n.t("cash")}
        </label>
        <CounterInput
          value={isNaN(data.cash) ? 0 : data.cash}
          label={i18n.t("cash")}
          onChange={(e) => handleChange("cash", e)}
        />
      </div>
      <div className="col-12 px-0 d-flex align-items-center mb-3">
        <label className="font-weight-bold" style={{ minWidth: "150px" }}>
          {i18n.t("credit_debit")}
        </label>
        <CounterInput
          value={isNaN(data.creditDebit) ? 0 : data.creditDebit}
          label={i18n.t("credit_debit")}
          onChange={(e) => handleChange("creditDebit", e)}
        />
      </div>
      {giftCardBalance ? (
        <div className="col-12 px-0 d-flex align-items-center mb-3">
          <label className="font-weight-bold" style={{ minWidth: "150px" }}>
            {i18n.t("gift_card")}
          </label>
          <CounterInput
            value={isNaN(data.giftCard) ? 0 : data.giftCard}
            label={i18n.t("gift_card")}
            onChange={(e) => handleChange("giftCard", e)}
          />
        </div>
      ) : null}
      <div className="col-12 px-0 d-flex align-items-center mb-3">
        <label className="font-weight-bold" style={{ minWidth: "150px" }}>
          {i18n.t("discount_amount")} ($)
        </label>
        <CounterInput
          value={isNaN(data.discountAmount) ? 0 : data.discountAmount}
          label={i18n.t("discount_amount")}
          onChange={(e) => handleChange("discountAmount", e)}
        />
      </div>
      <div className="col-12 px-0 d-flex align-items-center mb-3">
        <label className="font-weight-bold" style={{ minWidth: "150px" }}>
          {i18n.t("discount_percent")} (%)
        </label>
        <CounterInput
          value={isNaN(data.discountPercent) ? 0 : data.discountPercent}
          label={i18n.t("discount_percent")}
          onChange={(e) => handleChange("discountPercent", e)}
        />
      </div>
      <div className="col-12 px-0 d-flex align-items-center mb-3">
        <label className="font-weight-bold mr-3">{i18n.t("tip")}</label>
        <div className="" style={{ width: 190 }}>
          <DollarInput
            value={data.tip}
            onChange={(e) => handleChange("tip", parseFloat(e))}
            label={i18n.t("tip")}
          />
        </div>
        <div className="row gap-2 ml-3">
          <SecondaryButton
            onClick={() =>
              handleChange("tip", roundTo2Decimals((15.0 / 100) * subtotal))
            }
          >
            15 %
          </SecondaryButton>
          <SecondaryButton
            onClick={() =>
              handleChange("tip", roundTo2Decimals((20.0 / 100) * subtotal))
            }
          >
            20 %
          </SecondaryButton>
          <SecondaryButton
            onClick={() =>
              handleChange("tip", roundTo2Decimals((35.0 / 100) * subtotal))
            }
          >
            35 %
          </SecondaryButton>
        </div>
      </div>
      <div className="col-12 px-0 d-flex align-items-center mb-3">
        <label className="font-weight-bold" style={{ minWidth: "100px" }}>
          {difference < 0 ? i18n.t("change_due") : i18n.t("balance_due")}
        </label>
        <DollarInput
          value={difference}
          label={difference < 0 ? i18n.t("change_due") : i18n.t("balance_due")}
          readOnly={true}
          variant="outlined"
        />
      </div>
      <div className="col-12 px-0 d-flex align-items-center mb-3">
        <label className="font-weight-bold" style={{ minWidth: "100px" }}>
          {i18n.t("note")}
        </label>
        <TextField
          label={i18n.t("note")}
          onChange={(e) => handleChange("note", e.target.value)}
          helperText={formErrors && formErrors.note}
          error={Boolean(formErrors && formErrors.note)}
          variant="outlined"
        />
      </div>
    </>
  )
}
