import { connect } from "react-redux"
import React, { Component } from "react"
import i18n from "../../i18n"
import { DotsLoader } from "../../components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Translation } from "react-i18next"
import {
  getBookingNote,
  deleteBookingNote,
  createBookingNote,
  updateBookingNote,
} from "../../config/simpleApiCalls"
import { ErrorHelper, SuccessHelper } from "../../helpers"
import moment from "moment"
import "./styles.scss"
import Lightbox from "../LightBox/index"
import PrimaryButton from "../Core/PrimaryButton"
import { Images } from "../../theme"
import { TextField } from "@material-ui/core"
import SecondaryButton from "../Core/SecondaryButton/SecondaryButton"

class BookingNotes extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mediaType: null,
      imageUrl: null,
      imageFile: null,
      imageName: null,
      imageTitle: null,
      imageDescription: null,
      isloading: false,
    }
  }

  async componentDidMount() {
    const { bookingId, notes = [] } = this.props
    if (bookingId) {
      this.setState({ isloading: true })
      getBookingNote({ bookingId }).then(({ data }) => {
        this.setState({ isloading: false })
        if (data.success) {
          this.setState({ notes: data.data ? data.data.reverse() : [] })
        } else {
          this.setState({ notes: [] })
        }
      })
    } else {
      this.setState({ notes })
    }
  }

  onChangeTitle = (text) => this.setState({ imageTitle: text.target.value })
  onChangeDescription = (text) =>
    this.setState({ imageDescription: text.target.value })

  handleNavigation = (route) => {
    this.props.history.push(route)
  }
  fileSelectedHandler = (event) => {
    const { files = [] } = this.state
    const newFiles = Object.values(event.target.files).map((file) => {
      return {
        imageUrl: URL.createObjectURL(file),
        imageFile: file,
      }
    })
    files.push(...newFiles)
    this.setState({ files })
  }

  handleSave = () => {
    const { text = "", files = [], notes = [] } = this.state
    const {
      bookingId,
      companyCustomerId,
      successCallback = () => {},
    } = this.props
    if (!text && (!files || !files.length)) {
      ErrorHelper.handleErrors(
        "Please Enter a Comment or Attach a Picture",
        true
      )
    } else {
      this.setState({ isloading: true })
      let payloadFormdata = new FormData()
      files.map((file) => payloadFormdata.append("image", file.imageFile))
      payloadFormdata.append("text", text)
      if (bookingId) {
        payloadFormdata.append("bookingId", bookingId)
      }
      if (companyCustomerId) {
        payloadFormdata.append("companyCustomerId", companyCustomerId)
      }
      createBookingNote(payloadFormdata)
        .then(({ data }) => {
          this.setState({ isloading: false })
          if (data.success) {
            this.setState({
              notes: [data.data, ...notes],
              files: [],
              text: "",
            })
            SuccessHelper.handleSuccess(i18n.t("successfully_added"), true)
            successCallback && successCallback(data.data)
          } else {
            ErrorHelper.handleErrors(i18n.t("failed_to_add"), true)
          }
        })
        .catch(() => {
          this.setState({ isloading: false })
          ErrorHelper.handleErrors("An Error Occured", true)
        })
    }
  }

  handleUpdate = (note, index) => {
    const { text = "", _id } = note
    const { notes } = this.state
    this.setState({ isloading: true })
    updateBookingNote({ text, _id })
      .then(({ data }) => {
        this.setState({ isloading: false })
        if (data.success) {
          notes[index] = data.data
          this.setState({ notes })
          SuccessHelper.handleSuccess(i18n.t("successfully_updated"), true)
        } else {
          ErrorHelper.handleErrors(i18n.t("failed_to_update"), true)
        }
      })
      .catch((e) => {
        this.setState({ isloading: false })
        ErrorHelper.handleErrors("An Error Occured", true)
      })
  }

  handleDelete = (id, index) => {
    const { notes } = this.state
    this.setState({ isloading: true })
    deleteBookingNote({ id })
      .then(({ data }) => {
        this.setState({ isloading: false })
        if (data.success) {
          notes.splice(index, 1)
          this.setState({ notes })
          SuccessHelper.handleSuccess(i18n.t("successfully_deleted"), true)
        } else {
          ErrorHelper.handleErrors(i18n.t("failed_to_delete"), true)
        }
      })
      .catch(() => {
        this.setState({ isloading: false })
        ErrorHelper.handleErrors("An Error Occured", true)
      })
  }

  renderLoader = () => {
    const { isloading } = this.state
    return <DotsLoader isloading={isloading} />
  }

  renderUploadFileCard = () => {
    const { files = [] } = this.state
    const { readonly } = this.props
    return (
      <>
        <div className="w-100 d-flex">
          <div className="w-100">
            <TextField
              type="text"
              placeholder="Note"
              className="w-100"
              onChange={(e) => this.setState({ text: e.target.value })}
              variant="outlined"
              readOnly={readonly}
            />
            <div className="row">
              {files.map((file, index) => {
                return (
                  <div className="mt-1 mr-2" key={file.imageUrl}>
                    <FontAwesomeIcon
                      onClick={() => {
                        files.splice(index, 1)
                        this.setState({ files })
                      }}
                      style={{
                        width: 20,
                        height: 20,
                        cursor: "pointer",
                        position: "relative",
                        top: "16px",
                        right: "-68px",
                        display: "inline-block",
                      }}
                      color="red"
                      icon={["fas", "minus-circle"]}
                    />
                    <div style={{ backgroundColor: "black" }}>
                      <img
                        src={file.imageUrl}
                        style={{
                          width: "80px",
                          height: "80px",
                          objectFit: "contain",
                        }}
                      ></img>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <PrimaryButton
            onClick={() => this.fileInput.click()}
            style={{
              width: "34px",
              height: "34px",
              minWidth: "auto",
              marginLeft: "1rem",
              borderRadius: 7,
            }}
          >
            <img alt="file" width="18px" src={Images.attach_file} />
          </PrimaryButton>
        </div>
      </>
    )
  }

  renderExistingNotes = () => {
    const { notes = [], activeLightBoxIndex } = this.state
    const { hasEditPermission, readonly, notes: propsNotes } = this.props
    return (
      <div className="w-100 notes">
        {(propsNotes || notes).map((note, index) => {
          return (
            <div className="row mx-2 p-3 mt-3 note-container" key={note._id}>
              <h4 className="font-weight-bold text-dark">
                {i18n.t(`note_${note.type}`)}
              </h4>
              <p className="col-12 stamp text-dark">
                Created by{" "}
                {`${note.createdBy.firstName} ${note.createdBy.lastName}`} at{" "}
                {moment(note.createdDate).format("MM-DD-YYYY hh:mm")}
              </p>
              <p className="col-12 stamp text-dark">
                Update by{" "}
                {`${note.updatedBy.firstName} ${note.updatedBy.lastName}`} at{" "}
                {moment(note.updatedDate).format("MM-DD-YYYY hh:mm")}
              </p>
              {note.images.map((image) => {
                return (
                  <div
                    className="m-1"
                    key={image.url}
                    onClick={() =>
                      this.setState({ activeLightBoxIndex: index })
                    }
                  >
                    <div style={{ backgroundColor: "black" }}>
                      <img
                        src={image.url}
                        style={{
                          width: "80px",
                          height: "80px",
                          objectFit: "contain",
                        }}
                      ></img>
                    </div>
                  </div>
                )
              })}
              {activeLightBoxIndex === index ? (
                <Lightbox
                  startIndex={0}
                  onClose={() => {
                    this.setState({ activeLightBoxIndex: undefined })
                  }}
                  images={note.images.map((image, index) => {
                    return {
                      url: image.url,
                      title: `Note ${index + 1}`,
                    }
                  })}
                />
              ) : null}
              <textarea
                className="p-3 w-100 mt-3"
                value={note.text}
                readOnly={true}
                onChange={(e) => {
                  notes[index].text = e.target.value
                  this.setState({ notes })
                }}
              ></textarea>
              {hasEditPermission && (
                <div className="row mx-0 mt-3 text-right d-block w-100">
                  <SecondaryButton
                    className="w-98 mr-2"
                    style={{ fontSize: 12 }}
                    onClick={() => this.handleDelete(note._id, index)}
                  >
                    <Translation>{(t) => t("delete")}</Translation>
                  </SecondaryButton>
                  <PrimaryButton
                    className="w-98"
                    onClick={() => this.handleUpdate(note, index)}
                  >
                    <Translation>{(t) => t("update")}</Translation>
                  </PrimaryButton>
                </div>
              )}
            </div>
          )
        })}
      </div>
    )
  }

  render() {
    const { readonly } = this.props

    return (
      <>
        {!readonly && (
          <>
            <div className="d-sm-flex note-row">
              <p className="mb-0 text-dark label">
                {i18n.t("Add_note_message")}
              </p>
              <input
                type="file"
                style={{ display: "none" }}
                onChange={this.fileSelectedHandler}
                ref={(fileInput) => (this.fileInput = fileInput)}
                accept="image/*,video/*"
                multiple
              />
              {this.renderUploadFileCard()}
            </div>
            <div className="text-right mt-4">
              <PrimaryButton
                onClick={() => this.handleSave()}
                className="w-128"
              >
                <Translation>{(t) => t("save")}</Translation>
              </PrimaryButton>
            </div>
          </>
        )}
        {this.renderExistingNotes()}
      </>
    )
  }
}

const mapStateToProps = (state) => ({})

const action = {}

export default connect(mapStateToProps, action)(BookingNotes)
