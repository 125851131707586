import { connect } from "react-redux"
import React, { useEffect, useState } from "react"
import PackagesTable from "../../../components/Packages/PackagesTable.component"
import PackagesUpsert from "../../../components/Packages/PackagesUpsert.component"
import i18n from "../../../i18n"
import {
  getRequest,
  insertRequest,
  updateRequest,
  setActive,
} from "../../../redux/actions/Packages"
import PrimaryButton from "../../../components/Core/PrimaryButton"
import { DotsLoader } from "../../../components"
import { ErrorHelper, SuccessHelper } from "../../../helpers"
import useCache from "../../../context/LocalCache"
import SharedStorage from "../../../helpers/Storage"
import useScreenSize from "../../../context/ScreenSize"
const Packcages = ({
  getRequest,
  insertRequest,
  updateRequest,
  packages = [],
  setActive,
  loading: propsLoading,
  active,
}) => {
  const [companyId, setCompanyId] = useState("")
  const { getResource } = useCache()
  const allServices = getResource((cache) => cache.company.services)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const { screenSize } = useScreenSize()
  const onMount = async () => {
    const company = JSON.parse(await SharedStorage.getItem("company"))
    setCompanyId(company._id)
    setLoading(true)
    Promise.all([
      new Promise((resolve) => {
        getRequest(
          { companyId: company._id },
          {
            success: resolve,
          }
        )
      }),
    ]).then(() => {
      setLoading(false)
    })
  }
  useEffect(() => {
    onMount()
  }, [])

  const onClose = () => {
    setOpen(false)
  }
  const onSave = (data) => {
    setLoading(true)
    const request = data._id ? updateRequest : insertRequest
    request(
      { companyId, ...data },
      {
        success: (data) => {
          SuccessHelper.handleSuccess(
            i18n.t(data._id ? "successfully_updated" : "successfully_added"),
            true
          )
          setActive(data.data)
          setLoading(false)
          setOpen(false)
        },
        failure: () => {
          ErrorHelper.handleErrors(
            i18n.t(data._id ? "failed_to_update" : "failed_to_add"),
            true
          )
          setLoading(false)
        },
      }
    )
  }
  const onEdit = (data) => {
    setActive(data)
    setOpen(true)
  }
  return (
    <div className="content-container p-4">
      <DotsLoader isloading={loading || propsLoading} />
      <div
        style={
          screenSize === "mobile"
            ? {
                position: "sticky",
                top: 0,
                zIndex: 1,
                backgroundColor: "white",
              }
            : {}
        }
        className="d-flex flex-column flex-md-row justify-content-between align-items-center"
      >
        <span className="saloon-dashboard-heading mb-4">
          {i18n.t("packages_classes")}
        </span>
        <PrimaryButton
          className="w-128 mb-4"
          label={i18n.t("create")}
          onClick={() => {
            setActive()
            setOpen(true)
          }}
        />
      </div>
      <PackagesTable
        rows={packages}
        onEdit={onEdit}
        allServices={allServices}
      />
      {open && (
        <PackagesUpsert
          open={open}
          element={active}
          onClose={onClose}
          onSave={onSave}
          allServices={allServices}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  active: state.packages.active,
  packages: state.packages.data,
  loading: state.packages.isFetching,
})

const action = {
  getRequest,
  insertRequest,
  updateRequest,
  setActive,
}

export default connect(mapStateToProps, action)(Packcages)
