import { connect } from "react-redux"
import React, { useEffect, useMemo, useState } from "react"
import i18n from "../../../i18n"
import { ErrorHelper, SuccessHelper } from "../../../helpers"
import Tabs from "../../Core/Tabs/Tabs"
import BaseModal from "../../BaseModal"
import CustomerDetails from "./CustomerDetails"
import FilledFormTable from "../Form/FilledFormTable"
import BookingHistoryTable from "../BookingHistoryTable/BookingHistoryTable"
import CurrentBookingTable from "../CurrentBookingTable/CurrentBookingTable"
import CustomerActivities from "./CustomerActivities"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useTheme } from "@material-ui/core/styles"
import { getByCustomerRequest as getFilledFromsRequest } from "../../../redux/actions/FormAnswer"
import {
  getRequest as getCustomerCars,
  upsertRequest as upsertCar,
} from "../../../redux/actions/Saloon/CompanyCustomerCars"
import { getRequest as getCustomerSubscriptions } from "../../../redux/actions/Saloon/CompanyCustomerSubscription"
import MenuItem from "@material-ui/core/MenuItem"
import Menu from "@material-ui/core/Menu"
import { getUserWithCompanyPackagesRequest } from "../../../redux/actions/Packages"
import SendFormModal from "../../../components/AppointmentModal/components/SendFormModal.component"
import {
  getAllNotesByCustomer,
  getCustomerBookingHistory,
  upsertCustomer,
  getAllRemindersByCustomer,
  sendForm,
  createUserByCustomerId,
} from "../../../config/simpleApiCalls"
import BookingNotes from "../../BookingNotes"
import BookingReminders from "../../BookingReminders"
import CustomerAccountTransactions from "./CustomerAccountTransactions"
import CustomerGiftCards from "./CustomerGiftCards"
import "./CustomerEdit.scss"
import DotsLoader from "../../DotsLoader"
import CustomerPackages from "./CustomerPackages"
import useActiveMenuType from "../../../context/ActiveMenuType"
import CustomerCars from "./CustomerCars/CustomerCars"
import CustomerSubscription from "./CustomerSubscription/CustomerSubscription"
import PrimaryButton from "../../../components/Core/PrimaryButton/index"
import { getRequest as getForms } from "../../../redux/actions/Saloon/FormBuilder"
import TextField from "@material-ui/core/TextField"
import FillForm from "../../FormBuilder/Components/FillForm"
import { useDispatch } from "react-redux"
import { getByIdRequest as getFormById } from "../../../redux/actions/Saloon/FormBuilder"
import useScreenSize from "../../../context/ScreenSize"
import CustomerCreditCards from "./CustomerCreditCards"
function CustomerEdit({
  open,
  setOpen,
  active = {},
  getFilledFromsRequest,
  isGym,
  onUpdate = () => {},
  setAccountBalance = () => {},
  getUserWithCompanyPackagesRequest,
  getCustomerCars,
  getForms,
  upsertCar,
  allCustomers,
  getCustomerSubscriptions,
  formsOptions = [],
  companyForms = [],
  openTab,
  handleDrawerClose,
  setShowCustomerCurrentBookings = () => {},
  isloading = () => {},
}) {
  const { functionRole } = useActiveMenuType()
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)
  const [forms, setForms] = useState([])
  const [notes, setNotes] = useState([])
  const [reminders, setReminders] = useState([])
  const [filteredNotes, setfilteredNotes] = useState(undefined)
  const [packages, setPackages] = useState([])
  const [addNote, setAddNote] = useState(false)
  const theme = useTheme()
  const [fillFormAnchorEl, setFillFormAnchorEl] = useState(null)
  const [searchText, setSearchText] = useState("")
  const [formModalOpen, setFormModalOpen] = useState(false)
  const [fillFormModalOpen, setFillFormModalOpen] = useState(false)
  const companyData = useMemo(() => {
    return active?.companyId
  }, [active])
  const [companyCustomerId, setCompanyCustomerId] = useState()
  const [companyForm, setCompanyForm] = useState()
  const [bookings, setBookings] = useState([])
  const { screenSize } = useScreenSize()
  const dispatch = useDispatch()

  const onSendForm = (formIds, method) => {
    const getPromise = () => {
      if (!formIds.length) {
        setFormModalOpen(false)
        return
      }
      return sendForm({
        method,
        formIds,
        userId: data.userId,
        companyId: data.companyId,
        customerId: data.id,
      })
    }
    const promise = getPromise()
    if (!promise) {
      return
    }
    promise
      .then(() => {
        SuccessHelper.handleSuccess(i18n.t("successfully_added"), true)
        getFilledFroms(data)
        setFormModalOpen(false)
      })
      .catch(() => {
        ErrorHelper.handleErrors(i18n.t("failed_to_add"), true)
      })
  }
  const init = (data) => {
    if (!data.userId) {
      createUserByCustomerId({ _id: data._id })
        .then(({ data }) => {
          const customer = data.data
          setData({ ...customer })
        })
        .catch(() => {
          ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true)
        })
    }
    if (!formsOptions.length) {
      getForms({
        companyId: companyData?._id,
      })
    }

    if (data?.userId) {
      getFilledFroms(data)

      getAllRemindersByCustomer({ _id: data._id }).then(({ data }) => {
        if (data.success) {
          setReminders(data.data)
        } else {
          setReminders([])
        }
        isloading()
      })

      getAllNotesByCustomer({ _id: data._id }).then(({ data }) => {
        if (data.success) {
          setNotes(data.data)
        } else {
          setNotes([])
        }
      })

      setCompanyCustomerId(data._id)

      getUserWithCompanyPackagesRequest(
        { userId: data.userId },
        {
          success: ({ data }) => {
            setPackages(data)
          },
        }
      )
      if (functionRole == "garage") {
        getCustomerCars({ companyCustomerId: data._id })
      }
    } else {
      setForms([])
    }
    getCustomerSubscriptions({ companyCustomerId: data._id })
    setData({ ...data })
    getAllNotesByCustomer({ _id: data._id }).then(({ data }) => {
      if (data.success) {
        setNotes(data.data)
      } else {
        setNotes([])
      }
    })
  }
  const getFilledFroms = (data) => {
    getFilledFromsRequest(
      { _id: data._id },
      {
        success: (data) => {
          setForms(data.data)
        },
        failure: () => {
          ErrorHelper.handleErrors("An error occured", true)
        },
      }
    )
  }
  useEffect(() => {
    if (open && active && active._id != data._id) {
      init(active)
    }
  }, [active?._id, open])
  const handleView = (form) => {
    window.open(`/form-fill/${form._id}`, "_blank")
  }
  const handleDelete = (id) => {
    const index = forms.findIndex((item) => item._id == id)
    forms.splice(index, 1)
    setForms(forms)
  }
  const handleEdit = (payload) => {
    setLoading(true)
    return upsertCustomer(payload)
      .then((res) => {
        if (res.data.success) {
          SuccessHelper.handleSuccess("Customer successfully updated.", true)
          onUpdate(res.data.data)
        }
        if (!res.data.success) {
          ErrorHelper.handleErrors(res.data.msg, true)
        }
      })
      .catch((error) => {
        ErrorHelper.handleErrors("An error occured", true)
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const onChangeSearchText = (text) => {
    setSearchText(text.value)

    const textValue = text.target.value.toUpperCase()
    const filteredNotes =
      notes &&
      (textValue
        ? notes.filter((val) => {
            const type = (
              val.type == "Booking" ? "Staff" : val.type || ""
            ).toUpperCase()
            const text = (val.text || "").toUpperCase()
            const createdDate = `${val.createdDate}`
            const updatedDate = `${val.updatedDate}`
            const createdByFirstName = (
              val.createdBy.firstName || ""
            ).toUpperCase()
            const createdByLastName = (
              val.createdBy.lastName || ""
            ).toUpperCase()
            const updatedByFirstName = (
              val.updatedBy.firstName || ""
            ).toUpperCase()
            const updatedByLastName = (
              val.updatedBy.lastName || ""
            ).toUpperCase()
            return (
              type.indexOf(textValue) > -1 ||
              text.indexOf(textValue) > -1 ||
              createdDate.indexOf(textValue) > -1 ||
              updatedDate.indexOf(textValue) > -1 ||
              createdByFirstName.indexOf(textValue) > -1 ||
              createdByLastName.indexOf(textValue) > -1 ||
              updatedByFirstName.indexOf(textValue) > -1 ||
              updatedByLastName.indexOf(textValue) > -1
            )
          })
        : undefined)
    setfilteredNotes(filteredNotes)
  }

  const handleFormClick = (event) => {
    setFillFormAnchorEl(event.currentTarget)
  }
  const handleFormClose = () => {
    setFillFormAnchorEl(null)
  }
  const handleCarAdd = (body, callBack) => {
    setLoading(true)
    upsertCar(
      {
        companyCustomerId: active._id,
        ...body,
      },
      {
        success: () => {
          setLoading(false)
          callBack()
          SuccessHelper.handleSuccess(i18n.t("successfully_added"), true)
        },
        failure: () => {
          setLoading(false)
          ErrorHelper.handleErrors(i18n.t("failed_to_add"), true)
        },
      }
    )
  }
  const addNewNote = () => {
    setAddNote(true)
  }
  const closeAddNewNote = () => {
    setAddNote(false)
  }

  const tabs = [
    {
      title: i18n.t("details"),
      content: () => (
        <CustomerDetails
          active={data}
          onSave={handleEdit}
          allCustomers={allCustomers}
          onUpdate={onUpdate}
        />
      ),
    },
    {
      title: i18n.t("forms"),
      content: () => (
        <div>
          {
            <PrimaryButton
              className="w-98 mb-3 mr-3"
              onClick={() => setFormModalOpen(true)}
            >
              <div>{i18n.t("send_form")}</div>
            </PrimaryButton>
          }

          {
            <PrimaryButton className="w-98 mb-3" onClick={handleFormClick}>
              <div>{i18n.t("fill_form")}</div>
            </PrimaryButton>
          }
          <Menu
            id="simple-menu"
            anchorEl={fillFormAnchorEl}
            keepMounted
            open={Boolean(fillFormAnchorEl)}
            onClose={handleFormClose}
          >
            {companyForms.map((form) => {
              return (
                <MenuItem
                  onClick={() => {
                    setFillFormModalOpen(true)
                    setCompanyForm(form)
                    dispatch(getFormById({ _id: form._id }))
                    handleFormClose()
                  }}
                >
                  <div className="py-2">{form.formTitle}</div>
                </MenuItem>
              )
            })}
          </Menu>

          <FilledFormTable
            data={forms}
            handleDelete={handleDelete}
            handleView={handleView}
            setLoading={setLoading}
          />
        </div>
      ),
    },
    {
      title: i18n.t("current_and_recurring_bookings"),
      content: () => (
        <CurrentBookingTable
          setLoading={setLoading}
          companyCustomerId={companyCustomerId}
          companyId={companyData?._id}
          setShowCustomerCurrentBookings={setShowCustomerCurrentBookings}
          isGym={isGym}
          handleDrawerClose={handleDrawerClose}
        />
      ),
    },
    {
      title: i18n.t("booking_history"),
      content: () => (
        <BookingHistoryTable
          setLoading={setLoading}
          companyCustomerId={companyCustomerId}
          companyId={companyData?._id}
          isGym={isGym}
        />
      ),
    },
    {
      title: i18n.t("activity_and_registration"),
      content: () => (
        <CustomerActivities
          setLoading={setLoading}
          companyCustomerId={companyCustomerId}
          companyId={companyData?._id}
          giftCardBalance={active?.giftCardBalance}
        />
      ),
    },
    {
      title: i18n.t("notes"),
      content: () => (
        <>
          <BaseModal
            open={addNote}
            onClose={closeAddNewNote}
            title={i18n.t("add_note")}
            containerClassName="modal-container w-75"
            content={
              addNote ? (
                <>
                  <BookingNotes
                    hasEditPermission
                    companyCustomerId={active && active["_id"]}
                    successCallback={(note) => {
                      setNotes([note, ...notes])
                      closeAddNewNote()
                    }}
                  />
                </>
              ) : null
            }
          />
          <div className="d-flex align-items-center mb-4">
            <div className="d-flex align-items-center col-12 col-md-6 px-0">
              <label className="font-weight-bolder mr-3">
                {i18n.t("search")}
              </label>
              <div className={screenSize === "mobile" ? "col-12" : "w-75"}>
                <TextField
                  type="text"
                  placeholder={i18n.t("search")}
                  onChange={(text) => onChangeSearchText(text)}
                  value={searchText}
                  variant="outlined"
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-0 text-right">
              <PrimaryButton
                className="w-128"
                label={i18n.t("add_new_note")}
                onClick={addNewNote}
              />
            </div>
          </div>
          <BookingNotes
            notes={filteredNotes ? filteredNotes : notes}
            readonly={true}
            hasEditPermission={true}
          />
        </>
      ),
    },
    {
      title: i18n.t("tasks_and_reminders"),
      content: () => (
        <BookingReminders
          reminders={reminders}
          companyCustomerId={companyCustomerId}
          hasEditPermission={true}
        />
      ),
    },
    {
      title: (
        <div className="row mx-0 align-items-center">
          {i18n.t("gift_card")}
          {data?.giftCardBalance ? (
            <div className="account-tab-header pl-3">{` ($ ${active.giftCardBalance?.toFixed(
              2
            )})`}</div>
          ) : (
            ""
          )}
        </div>
      ),
      content: () => (
        <CustomerGiftCards active={data} setLoading={setLoading} />
      ),
    },
    {
      title: (
        <div className="row mx-0 align-items-center">
          {i18n.t("owing")}
          {data?.accountBalance ? (
            <div className="account-tab-header pl-3">{` ($ ${data.accountBalance.toFixed(
              2
            )})`}</div>
          ) : (
            ""
          )}
        </div>
      ),
      content: () => (
        <CustomerAccountTransactions
          active={data}
          setLoading={setLoading}
          accountBalance={data?.accountBalance}
          setAccountBalance={(accountBalance) => {
            setData({ ...data, accountBalance })
            setAccountBalance(accountBalance)
          }}
          giftCardBalance={active?.giftCardBalance}
        />
      ),
    },
    {
      title: i18n.t("packages"),
      content: () => <CustomerPackages data={packages} customer={data} />,
    },
    {
      title: i18n.t("subscriptions"),
      content: () => <CustomerSubscription customer={data} />,
    },
    {
      title: i18n.t("cards"),
      content: () => (
        <CustomerCreditCards active={data} setLoading={setLoading} />
      ),
    },
  ]
  if (functionRole == "garage") {
    tabs.push({
      title: i18n.t("cars"),
      content: () => (
        <CustomerCars companyCustomerId={active._id} handleAdd={handleCarAdd} />
      ),
    })
  }
  return (
    <div>
      {formModalOpen ? (
        <SendFormModal
          sendForm={onSendForm}
          handleClose={() => {
            setFormModalOpen(false)
          }}
          company={companyData}
          email={data.email}
        />
      ) : null}
      {fillFormModalOpen ? (
        <BaseModal
          open={fillFormModalOpen}
          onClose={() => setFillFormModalOpen(false)}
          title={companyForm.formTitle ? companyForm.formTitle : i18n.t("form")}
          content={
            open ? (
              <>
                <FillForm
                  userId={data.userId}
                  formId={companyForm._id}
                  getFilledForms={() => {
                    getFilledFroms(data)
                  }}
                  handleClose={() => setFillFormModalOpen(false)}
                />
              </>
            ) : null
          }
        />
      ) : null}
      <BaseModal
        open={open}
        style={{ zIndex: 1200 }}
        onClose={setOpen}
        title={i18n.t("container.customer")}
        containerClassName="container custom-tab"
        content={
          open ? (
            <>
              <DotsLoader isloading={loading} />
              <div className="customer-tab">
                <Tabs
                  tabs={tabs}
                  openTab={openTab}
                  setfilteredNotes={setfilteredNotes}
                />
              </div>
            </>
          ) : null
        }
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  formsOptions: state.formBuilder.data,
  companyForms: state.formBuilder.data,
})

const action = {
  getFilledFromsRequest,
  getUserWithCompanyPackagesRequest,
  getCustomerCars,
  upsertCar,
  getCustomerSubscriptions,
  getForms,
}

export default connect(mapStateToProps, action)(CustomerEdit)
