import { connect } from "react-redux"
import React, { Component } from "react"
import { Translation } from "react-i18next"
import i18n from "../../../i18n"
import { DotsLoader } from "../../../components"
import {
  employeeEditProfile,
  getEmployeeDetails,
  getCompanyEmployeeInfo,
  getServices,
} from "../../../config/simpleApiCalls"
import { SuccessHelper } from "../../../helpers"
import Swal from "sweetalert2"
import EditEmployeeWorkingHours from "../../Saloon/EmployeeSection/EditEmployeeWorkingHours.jsx"
import "./styles.css"
import PrimaryButton from "../../../components/Core/PrimaryButton"
import SharedStorage from "../../../helpers/Storage"
import { ScreenSizeContext } from "../../../context/ScreenSize.jsx"
const weekNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednessday",
  "Thursday",
  "Friday",
  "Saturday",
]
class AddWorkingHours extends Component {
  constructor(props) {
    super(props)
    this.state = {
      employeesWorkingHoursData: [
        { dayName: i18n.t("Sunday"), dayOfWeek: 1, availableStatus: 0 },
        { dayName: "Monday", dayOfWeek: 2, availableStatus: 0 },
        { dayName: "Tuesday", dayOfWeek: 3, availableStatus: 0 },
        { dayName: "Wednesday", dayOfWeek: 4, availableStatus: 0 },
        { dayName: "Thursday", dayOfWeek: 5, availableStatus: 0 },
        { dayName: "Friday", dayOfWeek: 6, availableStatus: 0 },
        { dayName: "Saturday", dayOfWeek: 7, availableStatus: 0 },
      ],
      serviceList: [],
      isVerified: false,
      employeeServicesList: [],
      selectedEmployees: [],
      hasSchedule: false,
    }
  }

  componentDidMount = async () => {
    const employeeData = await JSON.parse(
      await SharedStorage.getItem("employee")
    )
    if (employeeData) {
      this.setState({}, () => {
        this.getEmployeeData()
        this.getAllServices()
      })
    }
  }

  getAllServices = () => {
    this.setState({ isloading: true })
    getServices()
      .then((res) => {
        if (res.data.success) {
          this.setState({ isloading: false, serviceList: res.data.data })
        }
      })
      .catch((error) => {
        this.setState({ isloading: false })
      })
  }

  handleNavigation = (route) => {
    this.props.history.push(route)
  }

  onChangeCheckbox = (e, ind) => {
    const { employeesWorkingHoursData } = this.state
    if (e.target.checked) {
      employeesWorkingHoursData[ind].availableStatus = 1
      this.setState({ employeesWorkingHoursData })
    } else {
      employeesWorkingHoursData[ind].availableStatus = 0
      this.setState({ employeesWorkingHoursData })
    }
  }

  onChangeCheckIn = (text, ind) => {
    const { employeesWorkingHoursData } = this.state
    if (text.target.value) {
      employeesWorkingHoursData[ind].checkIn = text.target.value
    }
    this.setState({ employeesWorkingHoursData })
  }

  onChangeCheckOut = (text, ind) => {
    const { employeesWorkingHoursData } = this.state
    if (text.target.value) {
      employeesWorkingHoursData[ind].checkOut = text.target.value
    }

    this.setState({ employeesWorkingHoursData })
  }

  checkValidation = () => {
    let { employeesWorkingHoursData, isVerified, hasSchedule } = this.state
    for (let i = 0; i < employeesWorkingHoursData.length; i++) {
      if (employeesWorkingHoursData[i].availableStatus === 1) {
        if (!employeesWorkingHoursData[i].checkIn) {
          isVerified = false
        } else if (!employeesWorkingHoursData[i].checkOut) {
          isVerified = false
        } else {
          isVerified = true
        }
      }
    }
    this.setState({
      isVerified,
    })
    if (isVerified && !hasSchedule) {
      this.handleCreateSaloonSchedule()
    } else if (isVerified && hasSchedule) {
      this.handleEditSaloonSchedule()
    }
  }

  handleCreateSaloonSchedule = () => {
    this.setState({ isloading: true })
    const { employeesWorkingHoursData, serviceList } = this.state
    const employeesWorkingHoursSorted = employeesWorkingHoursData.map(function (
      obj
    ) {
      delete obj["dayName"] // Delete old key
      delete obj["_id"] // Delete old key
      return obj
    })
    let serviceListSorted = []
    for (let i = 0; i < serviceList.length; i++) {
      if (serviceList[i].isSelected) {
        let myService = {
          charges: 1,
          serviceId: serviceList[i]._id,
        }
        serviceListSorted.push(myService)
      }
    }
    const payload = {
      services: serviceListSorted,
      weekPlans: employeesWorkingHoursSorted,
    }
    employeeEditProfile(payload)
      .then((res) => {
        if (res.data.success) {
          SuccessHelper.handleSuccess("Saved Successfully", true)
          this.getEmployeeData()
        } else {
          this.setState({ isloading: false })
        }
      })
      .catch((error) => {
        Swal.fire({
          title: error,
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#ff3600",
          cancelButtonColor: "#354e68",
          confirmButtonText: "Yes, delete it!",
        })
      })
  }

  gettingCompanyEmployeeInfo = (e) => {
    let allServices = []

    this.setState({ showAddEmployeePopup: true })

    const payload = {}

    getCompanyEmployeeInfo(payload)
      .then((res) => {
        if (res.data.success) {
          res.data.details.services.map((v) => {
            res.data.services.map((services) => {
              if (v.serviceId._id === services._id) allServices.push(services)
            })
          })

          for (let i = 0; i < allServices.length; i++) {
            allServices[i].isSelected = allServices[i]["isActive"]
          }

          // for (let i = 0; i < res.data.result.companyServices.length; i++) {
          //   res.data.result.companyServices[i].isSelected =
          //     res.data.result.companyServices[i]["isActive"];
          // }

          this.setState({
            isloading: false,
            employeesWorkingHoursData: res.data.result.weekPlans,
            serviceList: this.state.allServices,
            companyServices: allServices,
            selectedEmployeeId: e.employee._id,
            companyServices: res.data.result.companyServices,
          })
        }
      })
      .catch((error) => {
        this.setState({ isloading: false })
      })
  }

  getEmployeeData = () => {
    this.setState({ isloading: true })

    let allServices = []

    getEmployeeDetails()
      .then((res) => {
        // if (res.data.success) {
        //   console.log("EMPLOYEEE DAATAAAA", res.data);
        //   for (let i = 0; i < res.data.data.details.weekPlans.length; i++) {
        //     res.data.data.details.weekPlans[i].dayName = weekNames[i];
        //   }
        //   this.setState({
        //     isloading: false,
        //     employeesWorkingHoursData: res.data.data.details.weekPlans,
        //     serviceList: res.data.data.services,
        //     employeeServicesList: res.data.data.details.services,
        //   });
        //   this.getSpecificData();
        // }

        // })

        //
        const serviceList = res.data.data.services
        if (res.data.success) {
          res.data.data.details.services.forEach((value) => {
            res.data.data.services.forEach((companyServices, index) => {
              if (companyServices._id === value.serviceId._id) {
                serviceList[index].charges = value.charges
              }
            })
          })

          this.setState({
            isloading: false,
            serviceList,
            employeesWorkingHoursData: res.data.data.details.weekPlans,
            employee: res.data.data.details,
          })

          res.data.data.details.services.map((v) => {
            res.data.data.services.map((services) => {
              if (v.serviceId._id === services._id) allServices.push(services)
            })
          })

          for (let i = 0; i < res.data.data.details.weekPlans.length; i++) {
            res.data.data.details.weekPlans[i].dayName = weekNames[i]
          }

          for (let i = 0; i < allServices.length; i++) {
            allServices[i].isSelected = true
          }
        }

        this.setState({
          isloading: false,
          serviceList: this.state.serviceList,
          employeesWorkingHoursData: res.data.data.details.weekPlans,
        })
      })

      .catch((error) => {
        this.setState({ isloading: false })
        console.log(error, "errorrrrrrrrrrrrrrrrrrr")
      })
  }

  getSpecificData = () => {
    const { serviceList, employeeServicesList, selectedEmployees } = this.state
    let followingIds = employeeServicesList.map((group) => group.serviceId._id)
    this.setState({
      selectedEmployees: selectedEmployees.push(
        serviceList.map((group) => ({
          ...group,
          following: followingIds.includes(group._id),
        }))
      ),
    })
  }

  renderLoader = () => {
    const { isloading } = this.state
    return <DotsLoader isloading={isloading} />
  }

  renderSaloonDashboardHeading = () => {
    const { screenSize } = this.context
    return (
      <>
        {screenSize === "mobile" ? (
          <>
            <div className="py-5 my-5"></div>
          </>
        ) : (
          <div className="col-12 pl-3 pt-3 ">
            <div className="row mx- justify-content-between align-items-center">
              <h1 className="saloon-dashboard-heading font-weight-bold">
                <Translation>
                  {(t) => t("container.add_working_hours")}
                </Translation>{" "}
                &amp; <Translation>{(t) => t("container.hours")}</Translation>
              </h1>
              <div className="mt-4 d-flex justify-content-end">
                <PrimaryButton
                  className="btn"
                  style={{ backgroundColor: "#F6943B" }}
                  type="submit"
                  onClick={() =>
                    this.props.history.push("/employee-manage-unavailabilities")
                  }
                >
                  {i18n.t(
                    "container.employe_dashboard_manage_unavailabilities"
                  )}
                </PrimaryButton>
              </div>
            </div>
          </div>
        )}
      </>
    )
  }

  onChangeServiceCheckbox = (e, ind) => {
    const { serviceList } = this.state

    if (e.target.checked) {
      serviceList[ind].isSelected = true
      this.setState({ serviceList })
    } else {
      serviceList[ind].isSelected = false
      this.setState({ serviceList })
    }
  }

  renderServicesTable = () => {
    let { selectedEmployees, serviceList, allServices } = this.state
    return (
      <div className="col-12 p-3 pb-5">
        <div className="table-responsive">
          <table class="table table-borderless saloon-working-hour-table mobile-max-content">
            <thead>
              <tr
                className="bg-white"
                style={{ borderTop: "1px solid #E5EBF1" }}
              >
                <th scope="col">
                  <span>
                    <Translation>
                      {(t) => t("container.profile_service")}
                    </Translation>
                  </span>
                </th>
                <th className="text-center" scope="col">
                  <Translation>{(t) => t("status")}</Translation>
                </th>
                <th className="text-center" scope="col">
                  <span className="">
                    <Translation>{(t) => t("container_choose")}</Translation>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {serviceList &&
                serviceList.map((val, ind) => {
                  return (
                    <tr
                      key={ind}
                      style={{
                        backgroundColor: ind % 2 == 0 && "#F4F6F8",
                        borderTop: "1px solid #E5EBF1",
                        borderBottom:
                          serviceList.length - 1 == ind && "1px solid #E5EBF1",
                      }}
                    >
                      <td>
                        <span className="working-days-name text-capitalize font-weight-bold">
                          {val.name}
                        </span>
                      </td>
                      <td
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          fontWeight: "600",
                        }}
                      >
                        {!val.isSelected && (
                          <span>
                            <Translation>
                              {(t) => t("container.not_selected")}
                            </Translation>
                          </span>
                        )}
                      </td>
                      <td className="text-center">
                        <div>
                          <input
                            style={{
                              transform: "scale(1.8)",
                            }}
                            type="checkbox"
                            id={val._id}
                            checked={val.isSelected}
                            onChange={(e) =>
                              this.onChangeServiceCheckbox(e, ind)
                            }
                          />
                        </div>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
  saveSchedule = async (newSchedule) => {
    await this.setState({
      employeesWorkingHoursData: newSchedule.weekPlans,
    })
    this.checkValidation()
  }

  render() {
    const { screenSize } = this.context
    return (
      <div>
        {this.renderLoader()}
        <div className="content-container">
          <div className="p-3">
            {this.renderSaloonDashboardHeading()}
            {this.renderServicesTable()}
            <div
              style={{
                margin: "0 1em",
                backgroundColor: "white",
              }}
            >
              {this.state.employee && (
                <EditEmployeeWorkingHours
                  hideTitle={screenSize === "mobile" ? false : true}
                  history={this.props.history}
                  employee={this.state.employee}
                  handleSave={this.saveSchedule}
                  className="p-5"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
AddWorkingHours.contextType = ScreenSizeContext

const mapStateToProps = (state) => ({})

const action = {}

export default connect(mapStateToProps, action)(AddWorkingHours)
