import React, { useEffect, useMemo, useState } from "react"
import { BaseModal } from "../../../../components"
import PrimaryButton from "../../../../components/Core/PrimaryButton"
import SecondaryButton from "../../../../components/Core/SecondaryButton/SecondaryButton"
import Select from "../../../../components/Core/Select"
import i18n from "../../../../i18n"
import { useDispatch, useSelector } from "react-redux"
import statusActions from "../../../../redux/actions/Saloon/CustomerStatus"

export default function AdvancedCustomerFilters({
  open,
  setOpen,
  filters,
  setFilters,
}) {
  const dispatch = useDispatch()
  const statuses = useSelector((state) => state.customerStatus.data)
  const [localFilters, setLocalFilters] = useState({})

  useEffect(() => {
    dispatch(statusActions.getRequest())
  }, [])
  useEffect(() => {
    setLocalFilters(filters)
  }, [filters])

  const yesNoOptions = [
    { name: i18n.t("yes"), value: true },
    { name: i18n.t("no"), value: false },
  ]
  const statusOptions = useMemo(() => {
    return statuses.map(({ _id, name }) => ({ name, value: _id }))
  }, [statuses])

  const onSubmit = () => {
    setFilters(localFilters)
    setOpen(false)
  }
  const onClear = () => {
    setFilters({})
    setOpen(false)
  }

  return (
    <BaseModal
      open={open}
      onClose={() => setOpen(false)}
      title={i18n.t("container.advancedSearchDialog_advanced_search")}
      containerClassName={"half-container"}
      actions={
        <div className="row justify-content-end mr-3 mb-3 gap-1">
          <SecondaryButton className="w-128" onClick={onClear}>{i18n.t("clear")}</SecondaryButton>
          <PrimaryButton className="w-128" onClick={onSubmit}>{i18n.t("search")}</PrimaryButton>
        </div>
      }
      content={
        <div>
          <div className="col-12 px-0 d-flex align-items-center mb-3">
            <label className="font-weight-bold" style={{ minWidth: '120px' }}>{i18n.t("watch_listed")}</label>
            <Select
              label={i18n.t("watch_listed")}
              options={yesNoOptions}
              onChange={(e) =>
                setLocalFilters({ ...filters, watchListed: e.target.value })
              }
              variant="outlined"
            />
          </div>
          <div className="col-12 px-0 d-flex align-items-center mb-3">
            <label className="font-weight-bold" style={{ minWidth: '120px' }}>{i18n.t("has_email")}</label>
            <Select
              label={i18n.t("has_email")}
              options={yesNoOptions}
              onChange={(e) =>
                setLocalFilters({ ...filters, hasEmail: e.target.value })
              }
              variant="outlined"
            />
          </div>
          <div className="col-12 px-0 d-flex align-items-center">
            <label className="font-weight-bold" style={{ minWidth: '120px' }}>{i18n.t("status")}</label>
            <Select
              label={i18n.t("status")}
              options={statusOptions}
              onChange={(e) =>
                setLocalFilters({ ...filters, status: e.target.value })
              }
              variant="outlined"
            />
          </div>
        </div>
      }
    />
  )
}
