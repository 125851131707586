// @flow

import { ACTIVITY_SCHEDULE_TEMPLATE } from "../ActionTypes"

export function getRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: ACTIVITY_SCHEDULE_TEMPLATE.GET_SAGA,
  }
}

export function get(data) {
  return {
    data,
    type: ACTIVITY_SCHEDULE_TEMPLATE.GET,
  }
}

export function removeRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: ACTIVITY_SCHEDULE_TEMPLATE.DELETE_SAGA,
  }
}

export function remove(data) {
  return {
    data,
    type: ACTIVITY_SCHEDULE_TEMPLATE.DELETE,
  }
}

export function upsertRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: ACTIVITY_SCHEDULE_TEMPLATE.UPSERT_SAGA,
  }
}

export function upsert(data) {
  return {
    data,
    type: ACTIVITY_SCHEDULE_TEMPLATE.UPSERT,
  }
}

export function removeEventRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: ACTIVITY_SCHEDULE_TEMPLATE.DELETE_EVENT_SAGA,
  }
}

export function removeEvent(data) {
  return {
    data,
    type: ACTIVITY_SCHEDULE_TEMPLATE.DELETE_EVENT,
  }
}

export function upsertEventRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: ACTIVITY_SCHEDULE_TEMPLATE.UPSERT_EVENT_SAGA,
  }
}

export function upsertEvent(data) {
  return {
    data,
    type: ACTIVITY_SCHEDULE_TEMPLATE.UPSERT_EVENT,
  }
}

export function requestFailure() {
  return {
    type: ACTIVITY_SCHEDULE_TEMPLATE.REQUEST_FAILURE,
  }
}
