// @flow

import { AUTO_BOOKING_SCHEDULE } from "../ActionTypes"

export function getRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: AUTO_BOOKING_SCHEDULE.GET_SAGA,
  }
}

export function get(data) {
  return {
    data,
    type: AUTO_BOOKING_SCHEDULE.GET,
  }
}

export function removeRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: AUTO_BOOKING_SCHEDULE.DELETE_SAGA,
  }
}

export function remove(data) {
  return {
    data,
    type: AUTO_BOOKING_SCHEDULE.DELETE,
  }
}

export function insertRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: AUTO_BOOKING_SCHEDULE.INSERT_SAGA,
  }
}

export function insert(data) {
  return {
    data,
    type: AUTO_BOOKING_SCHEDULE.INSERT,
  }
}

export function updateRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: AUTO_BOOKING_SCHEDULE.UPDATE_SAGA,
  }
}

export function update(data) {
  return {
    data,
    type: AUTO_BOOKING_SCHEDULE.UPDATE,
  }
}

export function removeEventRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: AUTO_BOOKING_SCHEDULE.DELETE_EVENT_SAGA,
  }
}

export function removeEvent(data) {
  return {
    data,
    type: AUTO_BOOKING_SCHEDULE.DELETE_EVENT,
  }
}

export function removeAllEventsRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: AUTO_BOOKING_SCHEDULE.DELETE_ALL_EVENTS_SAGA,
  }
}

export function removeAllEvents(data) {
  return {
    data,
    type: AUTO_BOOKING_SCHEDULE.DELETE_ALL_EVENTS,
  }
}

export function upsertEventRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: AUTO_BOOKING_SCHEDULE.UPSERT_EVENT_SAGA,
  }
}

export function upsertEvent(data) {
  return {
    data,
    type: AUTO_BOOKING_SCHEDULE.UPSERT_EVENT,
  }
}

export function applyTemplateRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: AUTO_BOOKING_SCHEDULE.APPLY_TEMPLATE_SAGA,
  }
}

export function applyTemplate(data) {
  return {
    data,
    type: AUTO_BOOKING_SCHEDULE.APPLY_TEMPLATE,
  }
}

export function requestFailure() {
  return {
    type: AUTO_BOOKING_SCHEDULE.REQUEST_FAILURE,
  }
}
