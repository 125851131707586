import {
  Box,
  Collapse,
  FormHelperText,
  Paper,
  TextField,
} from "@material-ui/core"
import { ExpandLess, ExpandMore, Lock } from "@material-ui/icons"
import React, { useMemo, useState } from "react"
import i18n from "../../../../i18n"
import TouchList from "./TouchList"

export function CollapseTouchList({
  options = [],
  onChange,
  selected,
  noOptionsMessage,
  open,
  setOpen,
  placeholder,
  error,
  locked,
  enableSearch,
}) {
  const [search, setSearch] = useState("")
  const filteredOptions = useMemo(() => {
    if (!search) {
      return options
    }
    return options.filter((option) =>
      option.name.toUpperCase().includes(search.toUpperCase())
    )
  }, [options, search])
  return (
    <div className="mb-2">
      <Paper
        onClick={() => {
          if (!locked) {
            setOpen(true)
          }
        }}
        elevation={2}
        style={{
          color: "#252525"
        }}
      >
        <Box padding={2}>
          <div className="row justify-content-between mx-0 py-1">
            {selected?.name ? selected.name : placeholder}
            <div>
              {locked ? <Lock className="mr-2" /> : null}
              {open ? <ExpandLess /> : <ExpandMore />}
            </div>
          </div>
        </Box>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Paper variant="outlined">
            <Box padding={2}>
              {Boolean(enableSearch) && (
                <div className="col-12 p-0 d-flex align-items-center mb-3">
                  <label className="font-weight-bolder mr-3">{i18n.t("search")}</label>
                  <TextField
                    label={i18n.t("search")}
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                    variant="outlined"
                  />
                </div>
              )}
              <TouchList
                options={filteredOptions}
                onChange={onChange}
                selected={selected}
                noOptionsMessage={noOptionsMessage}
                error={error}
              />
            </Box>
          </Paper>
        </Collapse>
      </Paper>
      {!!error && (
        <FormHelperText error style={{ fontSize: 16 }} className="text-center">
          {error}
        </FormHelperText>
      )}
    </div>
  )
}

export default CollapseTouchList
