// @flow

import { EMPLOYEE_FUNDS_REQUEST } from "../ActionTypes"
export function getRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: EMPLOYEE_FUNDS_REQUEST.GET_SAGA,
  }
}

export function get(data) {
  return {
    data,
    type: EMPLOYEE_FUNDS_REQUEST.GET,
  }
}

export function insertRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: EMPLOYEE_FUNDS_REQUEST.INSERT_SAGA,
  }
}

export function insert(data) {
  return {
    data,
    type: EMPLOYEE_FUNDS_REQUEST.INSERT,
  }
}

export function getAccountDetailsRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: EMPLOYEE_FUNDS_REQUEST.ACCOUNT_DETAILS_SAGA,
  }
}

export function getAccountDetails(data) {
  return {
    data,
    type: EMPLOYEE_FUNDS_REQUEST.ACCOUNT_DETAILS,
  }
}

export function requestFailure() {
  return {
    type: EMPLOYEE_FUNDS_REQUEST.REQUEST_FAILURE,
  }
}
