import React, { useState } from "react";
import { connect } from "react-redux";
function EmployeeHeader({ name }) {
  return (
    <>
      <div className="row mx-0 justify-content-between pb-1">
        <h4 className="font-weight-bold">{name}</h4>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(EmployeeHeader);
