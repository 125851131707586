import React, { Component } from "react"
import i18n from "../../../i18n"
import { getSalesReport } from "../../../config/simpleApiCalls"
import { BaseModal, DotsLoader } from "../../../components"
import Button from "@material-ui/core/Button"
import moment from "moment"
import DatePicker from "react-datepicker"
import "./styles.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Select from "../../../components/Core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import PrimaryButton from "../../../components/Core/PrimaryButton"
import SecondaryButton from "../../../components/Core/SecondaryButton/SecondaryButton"
import useCache from "../../../context/LocalCache"
import { exportTableToPDF } from "../../../util/exportToPDF"
import { ScreenSizeContext } from "../../../context/ScreenSize"
class _SalesReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      usersList: [],
      startDate: new Date(),
      endDate: new Date(),
      // employeeList: [],
      filterUsersList: [],
      isLoading: false,
      employeeId: "",
    }
  }

  componentDidMount() {
    this.getSalesReport()
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { employees } = nextProps
    return {
      employees: employees.map((employee) => ({
        value: employee.employee?._id || employee.archivedEmployeeId._id,
        name:
          employee.employee?.userId.firstName ||
          employee.archivedEmployeeId.userId.firstName,
      })),
    }
  }
  getSalesReport = (exportToXlsx) => {
    this.setState({ isLoading: true }, () => {})
    const convertedStart = moment(this.state.startDate).format("MM-D-YYYY")
    const convertedEnd = moment(this.state.endDate).format("MM-D-YYYY")
    let data = {
      start: convertedStart,
      end: convertedEnd,
      exportToXlsx,
      employeeId: this.state.employeeId,
    }

    getSalesReport(data)
      .then((res) => {
        if (res.data.success) {
          this.setState({ isLoading: false })
        }
        this.setState({
          data: res.data.data.transactions,
          summary: res.data.data.summary,
        })
      })
      .catch((err) => {
        this.setState({ isLoading: false })
      })
  }

  setStartDate = (val) => {
    const { endDate } = this.state
    if (val > endDate) {
      this.setState({
        startDate: endDate,
        endDate: val,
      })
    } else {
      this.setState({
        startDate: val,
      })
    }
  }

  setEndDate = (val) => {
    const { startDate } = this.state
    if (val < startDate) {
      this.setState({
        startDate: val,
        endDate: startDate,
      })
    } else {
      this.setState({
        endDate: val,
      })
    }
  }

  TemplateOption = () => {
    const { startDate, endDate } = this.state
    return (
      <div className="d-sm-flex align-items-center ml-sm-3 mb-3 gap-2">
        <label
          className="font-weight-bolder"
          style={{ minWidth: "fit-content" }}
        >
          {i18n.t("date_range")}
        </label>
        <div className="d-flex gap-2">
          <DatePicker
            className="form-control saloon-form-input"
            placeholderText="Select Start Date"
            selected={startDate}
            onChange={(data) => this.setStartDate(data)}
            dateFormat="MM/dd/yyyy"
            variant="outlined"
          />
          <DatePicker
            className="form-control saloon-form-input"
            placeholderText="Select End Date"
            selected={endDate}
            onChange={(data) => this.setEndDate(data)}
            dateFormat="MM/dd/yyyy"
          />
        </div>
      </div>
    )
  }

  renderSearchBar = () => {
    const { searchText } = this.state
    const { screenSize } = this.context
    return (
      <div className="col-12 px-0 d-sm-flex align-items-center justify-content-between mb-4">
        <div className="saloon-dashboard-heading pt-0 mb-3">
          {i18n.t("sales_report")}
        </div>
        {screenSize !== "mobile" ? <>{this.renderButtons()}</> : null}
      </div>
    )
  }

  renderButtons = () => {
    const { screenSize } = this.context
    return (
      <div className="d-flex flex-column flex-md-row gap-2 mb-3">
        <PrimaryButton
          className={screenSize === "mobile" ? "" : "w-128"}
          onClick={() => exportTableToPDF(["#table"], i18n.t("sales_report"))}
        >
          {i18n.t("export_to_pdf")}
        </PrimaryButton>
        <PrimaryButton
          className={screenSize === "mobile" ? "" : "w-128"}
          onClick={() => this.getSalesReport(true)}
        >
          {i18n.t("export_to_excel")}
        </PrimaryButton>
      </div>
    )
  }

  renderTableBody = () => {
    const { data, isLoading, summary = {} } = this.state
    if (!isLoading) {
      if (data) {
        return (
          <tbody>
            <tr
              style={{
                position: "sticky",
                top: "35px",
                backgroundColor: "white",
                zIndex: 1,
              }}
            >
              <td className="px-0">
                <Button
                  disabled={true}
                  style={{
                    backgroundColor: "#F6943B",
                    color: "white",
                    width: "7rem",
                  }}
                >
                  $ {summary.servicesTotal}
                </Button>
              </td>
              <td className="px-0">
                <Button
                  disabled={true}
                  style={{
                    backgroundColor: "#F6943B",
                    color: "white",
                    width: "7rem",
                  }}
                >
                  $ {summary.productsTotal}
                </Button>
              </td>
              <td className="px-0">
                <Button
                  disabled={true}
                  style={{
                    backgroundColor: "#F6943B",
                    color: "white",
                    width: "7rem",
                  }}
                >
                  $ {summary.extrasTotal}
                </Button>
              </td>
              <td className="px-0">
                <Button
                  disabled={true}
                  style={{
                    backgroundColor: "#F6943B",
                    color: "white",
                    width: "7rem",
                  }}
                >
                  $ {summary.packagesTotal}
                </Button>
              </td>
              <td className="px-0">
                <Button
                  disabled={true}
                  style={{
                    backgroundColor: "#F6943B",
                    color: "white",
                    width: "7rem",
                  }}
                >
                  $ {summary.giftCardsTotal}
                </Button>
              </td>
              <td className="px-0">
                <Button
                  disabled={true}
                  style={{
                    backgroundColor: "#F6943B",
                    color: "white",
                    width: "7rem",
                  }}
                >
                  $ {summary.subtotal}
                </Button>
              </td>
              <td className="px-0">
                <Button
                  disabled={true}
                  style={{
                    backgroundColor: "#F6943B",
                    color: "white",
                    width: "7rem",
                  }}
                >
                  $ {summary.taxTotal}
                </Button>
              </td>
              <td className="px-0">
                <Button
                  disabled={true}
                  style={{
                    backgroundColor: "#F6943B",
                    color: "white",
                    width: "7rem",
                  }}
                >
                  $ {summary.discount}
                </Button>
              </td>
              <td className="px-0">
                <Button
                  disabled={true}
                  style={{
                    backgroundColor: "#F6943B",
                    color: "white",
                    width: "7rem",
                  }}
                >
                  $ {summary.tip}
                </Button>
              </td>
              <td className="px-0">
                <Button
                  disabled={true}
                  style={{
                    backgroundColor: "#F6943B",
                    color: "white",
                    width: "7rem",
                  }}
                >
                  $ {summary.grandTotal}
                </Button>
              </td>
              <td />
              <td />
            </tr>
            {data.map((val, ind) => {
              return (
                <tr key={val._id} style={{ borderTop: "3px solid #F7F7F7" }}>
                  <td className=" Table_data">
                    <div className="d-flex align-items-center mt-1">
                      <span className="appointment-details-employee-name">
                        $ {val.servicesTotal}
                      </span>
                    </div>
                  </td>
                  <td className=" Table_data">
                    <div className="d-flex align-items-center">
                      <span className="appointment-details-employee-name">
                        $ {val.productsTotal}
                      </span>
                    </div>
                  </td>
                  <td className=" Table_data">
                    <div className="d-flex align-items-center mt-1">
                      <span className="appointment-details-employee-name">
                        $ {val.extrasTotal}
                      </span>
                    </div>
                  </td>
                  <td className=" Table_data">
                    <div className="d-flex align-items-center mt-1">
                      <span className="appointment-details-employee-name">
                        $ {val.packagesTotal}
                      </span>
                    </div>
                  </td>
                  <td className=" Table_data">
                    <div className="d-flex align-items-center mt-1">
                      <span className="appointment-details-employee-name">
                        $ {val.giftCardsTotal}
                      </span>
                    </div>
                  </td>
                  <td className=" Table_data">
                    <div className="d-flex align-items-center mt-1">
                      <span className="appointment-details-employee-name">
                        $ {val.subtotal}
                      </span>
                    </div>
                  </td>
                  <td className=" Table_data">
                    <div className="d-flex align-items-center mt-1">
                      <span className="appointment-details-employee-name">
                        $ {val.taxTotal}
                      </span>
                    </div>
                  </td>
                  <td className=" Table_data">
                    <div className="d-flex align-items-center mt-1">
                      <span className="appointment-details-employee-name">
                        $ {val.discount}
                      </span>
                    </div>
                  </td>
                  <td className=" Table_data">
                    <div className="d-flex align-items-center mt-1">
                      <span className="appointment-details-employee-name">
                        $ {val.tip}
                      </span>
                    </div>
                  </td>
                  <td className=" Table_data">
                    <div className="d-flex align-items-center mt-1">
                      <span className="appointment-details-employee-name">
                        $ {val.grandTotal}
                      </span>
                    </div>
                  </td>
                  <td className=" Table_data">
                    <div className="d-flex align-items-center mt-1">
                      <span className="appointment-details-employee-name">
                        {val.soldBy}
                      </span>
                    </div>
                  </td>
                  <td className=" Table_data">
                    <div className="d-flex align-items-center mt-1">
                      <span className="appointment-details-employee-name">
                        {moment(val.createdDate).format("MM-DD-YYYY HH:mm")}
                      </span>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        )
      }
    } else {
      return <DotsLoader isloading={this.state.isloading} />
    }
  }

  renderLoader = () => {
    const { isLoading } = this.state
    return <DotsLoader isloading={isLoading} />
  }

  renderUserTable = () => {
    const { data } = this.state
    const { screenSize } = this.context
    if (data && data.length) {
      return (
        <div className="col-12 px-0">
          <div
            style={
              screenSize === "mobile" ? { height: "70vh" } : { height: "80vh" }
            }
            className="table-responsive"
          >
            <table
              id="table"
              className="table table-borderless appointment-details-table"
            >
              <thead>
                <tr
                  style={{
                    position: "sticky",
                    top: "0",
                    backgroundColor: "white",
                    zIndex: 1,
                  }}
                >
                  <th scope="col">{i18n.t("service_total")}</th>
                  <th scope="col">{i18n.t("product_total")}</th>
                  <th scope="col">{i18n.t("extras_total")}</th>
                  <th scope="col">{i18n.t("packages_total")}</th>
                  <th scope="col">{i18n.t("gift_card_total")}</th>
                  <th scope="col">{i18n.t("subtotal")}</th>
                  <th scope="col">{i18n.t("tax_total")}</th>
                  <th scope="col">{i18n.t("discount")}</th>
                  <th scope="col">{i18n.t("tip")}</th>
                  <th scope="col">{i18n.t("grand_total")}</th>
                  <th scope="col">{i18n.t("sold_by")}</th>
                  <th scope="col">{i18n.t("created_at")}</th>
                </tr>
              </thead>
              {this.renderTableBody()}
            </table>
          </div>
        </div>
      )
    } else {
      return (
        <div className="row full-width mx-2 justify-content-center mt-1">
          {i18n.t("nothing_found")}
        </div>
      )
    }
  }

  setSelectedValue = (val) => {
    let currentDate = moment(Date.now())
    if (val == 1) {
      const startOfMonth = moment(currentDate).startOf("month")
      const endOfMonth = moment(currentDate).endOf("month")
      this.setState({
        startDate: startOfMonth.toDate(),
        endDate: endOfMonth.toDate(),
      })
    } else if (val == 2) {
      const startOfQuarter = moment(currentDate).startOf("quarter")
      const endOfQuarter = moment(currentDate).endOf("quarter")
      this.setState({
        startDate: startOfQuarter.toDate(),
        endDate: endOfQuarter.toDate(),
      })
    } else if (val == 3) {
      const convertedStart = moment(`01-01-${currentDate.year()}`, "MM-DD-YYYY")
      const convertedEnd = moment(`12-31-${currentDate.year()}`, "MM-DD-YYYY")
      this.setState({
        startDate: convertedStart.toDate(),
        endDate: convertedEnd.toDate(),
      })
    }
  }

  ShowMenuValues = () => {
    return (
      <div className="row align-items-center px-0 mx-0 ml-sm-3 mb-3 gap-2">
        <label className="font-weight-bolder">
          {i18n.t("container.quick_filter")}
        </label>
        <div className="d-flex flex-wrap px-0 gap-2">
          <SecondaryButton onClick={() => this.setSelectedValue(1)}>
            {i18n.t("container.week")}
          </SecondaryButton>
          <SecondaryButton onClick={() => this.setSelectedValue(2)}>
            {i18n.t("container.month")}
          </SecondaryButton>
          <SecondaryButton onClick={() => this.setSelectedValue(3)}>
            {i18n.t("container.year")}
          </SecondaryButton>
          <PrimaryButton className="w-98" onClick={() => this.getSalesReport()}>
            {i18n.t("search")}
          </PrimaryButton>
        </div>
      </div>
    )
  }

  retrieveButton = () => {
    return (
      <div className="px-0 row mx-0 justify-content-end mb-3">
        <PrimaryButton
          type="button"
          className="mr-2"
          onClick={() => exportTableToPDF(["#table"], i18n.t("sales_report"))}
        >
          PDF
        </PrimaryButton>
        <PrimaryButton
          type="button"
          className="mr-2"
          onClick={() => this.getSalesReport(true)}
        >
          {i18n.t("export")}
          <FontAwesomeIcon className="ml-1" icon={["far", "file-excel"]} />
        </PrimaryButton>
        <PrimaryButton
          type="button"
          className=""
          onClick={() => this.getSalesReport()}
        >
          {i18n.t("search")}
        </PrimaryButton>
      </div>
    )
  }

  renderFilter = () => {
    const { screenSize } = this.context
    return (
      <div className="row col-12 px-0">
        <div className={`${screenSize === "mobile" ? "col-12" : ""} row px-0`}>
          <div className="w-100 d-flex flex-column flex-md-row flex-wrap align-md-items-center gap-2 mb-3">
            <label className="font-weight-bolder">
              {i18n.t("container.employee")}
            </label>
            <div style={{ minWidth: 200 }}>
              <Select
                value={this.state.employeeId}
                onChange={(e) => this.setState({ employeeId: e.target.value })}
                label={i18n.t("container.employee")}
                options={this.state.employees}
                variant="outlined"
              />
            </div>
            <PrimaryButton
              onClick={() => this.setState({ employeeId: "" })}
              className="w-98"
            >
              {i18n.t("filterReset")}
            </PrimaryButton>
          </div>
        </div>
        <div className="row px-0">
          {this.TemplateOption()}
          {this.ShowMenuValues()}
        </div>
      </div>
    )
  }

  render() {
    const { isLoading } = this.state
    const { screenSize } = this.context
    return (
      <>
        {isLoading ? this.renderLoader() : null}

        <div className="content-container p-4">
          <div className="row">
            {this.renderSearchBar()}
            {screenSize !== "mobile" ? (
              <>{this.renderFilter()}</>
            ) : (
              <div className="row w-100 justify-content-center gap-2">
                <PrimaryButton
                  className="w-128"
                  onClick={() => this.setState({ openFiltersModal: true })}
                >
                  {i18n.t("filters")}
                </PrimaryButton>
                <PrimaryButton
                  className="w-128"
                  onClick={() => this.setState({ openOptionsModal: true })}
                >
                  Options
                </PrimaryButton>
              </div>
            )}

            {this.renderUserTable()}
          </div>
          <BaseModal
            open={this.state.openFiltersModal}
            onClose={() => this.setState({ openFiltersModal: false })}
            title={i18n.t("container.advancedSearchDialog_filter")}
            content={<>{this.renderFilter()}</>}
            contentClassName="overflow-visible"
          />
          <BaseModal
            open={this.state.openOptionsModal}
            onClose={() => this.setState({ openOptionsModal: false })}
            title="Options"
            content={<>{this.renderButtons()}</>}
          />
        </div>
      </>
    )
  }
}
_SalesReport.contextType = ScreenSizeContext
export default function SalesReport(props) {
  const { getResource } = useCache()
  const employees = getResource((cache) => cache.company.employees)
  return <_SalesReport {...props} employees={employees} />
}
