import React, { useEffect } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import DetailsField from "./DetailsField.component";

import styled from "styled-components";

const ChecboxChoiceWrapper = styled.div`
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 11px;
  width: 100%;
`;

const GenerateCheckboxField = ({
  field,
  handleSubmit,
  readonly,
  answer,
  detailsRequired,
  details,
}) => {
  useEffect(() => {
    handleSubmit(false, field._id);
  }, []);
  const handleOnChange = (e, choice = null, type) => {
    if (type === "answer") {
      if (handleSubmit !== undefined) {
        handleSubmit(e.target.checked, field._id, type);
      }
    } else {
      if (handleSubmit !== undefined) {
        handleSubmit(e.target.value, field._id, type);
      }
    }
  };
  return (
    <>
      <ChecboxChoiceWrapper>
        <span>{field.fieldName}</span>
        <Checkbox
          inputProps={{ "aria-label": "primary checkbox" }}
          onChange={(e) => handleOnChange(e, null, "answer")}
          disabled={readonly}
          checked={answer}
        />
        {detailsRequired && (
          // <TextField
          //   onChange={(e) => handleOnChange(e, "details")}
          //   value={details}
          //   type="text"
          //   variant="outlined"
          //   inputProps={{ readOnly: readonly }}
          // />
          <DetailsField
            handleOnChange={handleOnChange}
            details={details}
            readonly={readonly}
          />
        )}
      </ChecboxChoiceWrapper>
    </>
  );
};

export default GenerateCheckboxField;
