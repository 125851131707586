// @flow

import { COMPANY_CUSTOMER_GROUPS } from "../ActionTypes"

export function getRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: COMPANY_CUSTOMER_GROUPS.GET_SAGA,
  }
}

export function get(data) {
  return {
    data,
    type: COMPANY_CUSTOMER_GROUPS.GET,
  }
}

export function removeRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: COMPANY_CUSTOMER_GROUPS.DELETE_SAGA,
  }
}

export function remove(data) {
  return {
    data,
    type: COMPANY_CUSTOMER_GROUPS.DELETE,
  }
}

export function upsertRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: COMPANY_CUSTOMER_GROUPS.UPSERT_SAGA,
  }
}

export function upsert(data) {
  return {
    data,
    type: COMPANY_CUSTOMER_GROUPS.UPSERT,
  }
}

export function requestFailure() {
  return {
    type: COMPANY_CUSTOMER_GROUPS.REQUEST_FAILURE,
  }
}
