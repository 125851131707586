import React, { useEffect } from "react"
import useScreenSize from "../../../context/ScreenSize"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
} from "@material-ui/core"

export default function BasicTable({
  schema = [],
  rows = [],
  prependRows = [],
  appendRows = [],
  onRowClick = () => {},
  id = "",
  maxContentWidth = true,
  tableContainerStyle = {},
  tableHeadStyle = {},
}) {
  const { screenSize } = useScreenSize()
  const tableStyle =
    screenSize === "mobile" && maxContentWidth ? { width: "max-content" } : {}
  const renderTableHead = () => {
    return (
      <TableHead style={tableHeadStyle}>
        <TableRow>
          {schema.map(({ header, style, headerClassName = "" }) => {
            let content = header
            if (typeof header == "function") {
              content = header()
            }
            return (
              <TableCell
                key={`table-head-${header}`}
                style={style}
                className={`${headerClassName}`}
              >
                {content}
              </TableCell>
            )
          })}
        </TableRow>
      </TableHead>
    )
  }

  const renderTableBody = (row, index) => {
    return schema.map(({ field, accessor, render, style = {} }) => {
      let content
      if (render) {
        content = render(row, index)
      } else {
        if (!accessor) {
          accessor = () => row[field]
        }
        content = accessor(row, field)
      }
      return (
        <TableCell key={`table-body-${field}`} style={style}>
          {content}
        </TableCell>
      )
    })
  }

  return (
    <TableContainer style={tableContainerStyle}>
      <Table
        id={id}
        aria-label="activities Table"
        style={tableStyle}
        className="table"
      >
        {renderTableHead(schema)}
        <TableBody className="h-100">
          {prependRows}
          {rows.map((element, index) => (
            <TableRow
              style={{
                backgroundColor: index % 2 == 0 && "#F4F6F8",
                borderTop: "1px solid #E5EBF1",
                borderBottom: rows.length - 1 == index && "1px solid #E5EBF1",
              }}
              key={`${element._id}-${index}`}
              onClick={() => onRowClick(element)}
            >
              {renderTableBody(element, index)}
            </TableRow>
          ))}
          {appendRows}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
