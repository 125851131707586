// @flow

import { ORDERS } from "../ActionTypes"
export function getRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: ORDERS.GET_SAGA,
  }
}

export function get(data) {
  return {
    data,
    type: ORDERS.GET,
  }
}

export function requestFailure() {
  return {
    type: ORDERS.REQUEST_FAILURE,
  }
}
