import { ErrorHelper, SuccessHelper } from "../../helpers"
import React from "react"
import Table from "./Components/Table"
import UpsertPopup from "./Components/UpsertPopup"

function CrudBuilder() {
  const build = ({ actionMap, type, title }) => {
    const UpsertPopupComponent = UpsertPopup
    return function crud() {
      return (
        <Table
          UpsertPopup={UpsertPopupComponent}
          reduxType={type}
          title={title}
          actionMap={actionMap}
        />
      )
    }
  }

  return {
    build,
  }
}

const crudBuilder = CrudBuilder()

export default crudBuilder
