import { Capacitor } from "@capacitor/core";
import { Preferences } from '@capacitor/preferences';


const SharedStorage = {
  getItem: async (key) => {
    if (Capacitor.platform == "web") {
      return sessionStorage.getItem(key);
    } else {
      return Preferences.get({ key }).then(({ value }) => value);
    }
  },
  setItem: (key, value) => {
    if (Capacitor.platform == "web") {
      return sessionStorage.setItem(key, value);
    } else {
      return Preferences.set({
        key,
        value,
      });
    }
  },
  removeItem: (key) => {
    if (Capacitor.platform == "web") {
      return sessionStorage.removeItem(key);
    } else {
      return Preferences.remove({
        key,
      });
    }
  },
};

export default SharedStorage;
