import { connect } from "react-redux"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { Chip } from "@material-ui/core"
import { TextField } from "@material-ui/core"

import i18n from "../../../i18n"
import PrimaryButton from "../../../components/Core/PrimaryButton"
import { BaseModal } from "../../../components"
import _ from "lodash"
import BasicTable from "../../../components/Core/BasicTable"
import { Checkbox } from "@material-ui/core"

function EventModal({
  open,
  setOpen,
  studentGroups,
  scheduledStudents,
  onSubmit,
  enrolledStudents,
  notEnrolledStudents,
}) {
  const [scheduledMap, setScheduledMap] = useState({})
  const [search, setSearch] = useState("")

  useEffect(() => {
    const map = {}
    if (scheduledStudents) {
      scheduledStudents.map((student) => {
        map[student.studentId._id] = true
      })
    }
    setScheduledMap(map)
  }, [scheduledStudents])
  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = () => {
    onSubmit({
      students: Object.keys(scheduledMap).map((studentId) => ({ studentId })),
    })
  }

  const availableStudentMap = useMemo(() => {
    const map = {}
    ;[...enrolledStudents, ...notEnrolledStudents].map((student) => {
      map[student._id] = true
    })
    return map
  }, [enrolledStudents, notEnrolledStudents])

  const [textEnrolledStudents, textNotEnrolledStudents] = useMemo(() => {
    if (!search) {
      return [enrolledStudents, notEnrolledStudents]
    }
    return [
      enrolledStudents.filter((student) => {
        return student.name.toLowerCase().includes(search.toLowerCase())
      }),
      notEnrolledStudents.filter((student) => {
        return student.name.toLowerCase().includes(search.toLowerCase())
      }),
    ]
  }, [search])

  if (!open) {
    return null
  }
  const tableSchema = [
    {
      header: i18n.t("scheduled"),
      style: { width: 80, textTransform: "capitalize" },
      field: "scheduled",
      render: (val) => {
        return (
          <Checkbox
            checked={scheduledMap[val._id] || false}
            onChange={() => {
              if (scheduledMap[val._id]) {
                delete scheduledMap[val._id]
              } else {
                scheduledMap[val._id] = true
              }
              setScheduledMap({ ...scheduledMap })
            }}
            inputProps={{ "aria-label": "enrolled" }}
          />
        )
      },
    },
    {
      header: i18n.t("student"),
      field: "student",
      style: { verticalAlign: "middle" },
      accessor: (val) => val.name,
    },
  ]
  return (
    <div>
      <BaseModal
        title={i18n.t("manage_scheduled_students")}
        contentContainerStyle={{
          overflowY: "visible",
        }}
        containerClassName={"half-container"}
        contentClassName="modal-table"
        content={
          <>
            <div className="row mx-0">
              <div className="col-12 px-0 d-flex align-items-center justify-content-between mb-3">
                <label className="font-weight-bold mr-3">
                  {i18n.t("search")}
                </label>
                <div className="w-100">
                  <TextField
                    label={i18n.t("search")}
                    onChange={(e) => {
                      setSearch(e.target.value)
                    }}
                    variant="outlined"
                  />
                </div>
              </div>
            </div>
            <div className="row mx-0">
              {studentGroups.map((studentGroup) => {
                return (
                  <Chip
                    key={studentGroup._id}
                    className="status-chip"
                    onClick={() => {
                      studentGroup.students.map((student) => {
                        if (availableStudentMap) {
                          scheduledMap[student._id] = true
                        }
                      })
                      setScheduledMap({ ...scheduledMap })
                    }}
                    style={{
                      backgroundColor: "#e1e1e1",
                      marginRight: 4,
                      cursor: "pointer",
                    }}
                    size="small"
                    label={studentGroup.name}
                  />
                )
              })}
            </div>
            <div className="my-3">{i18n.t("enrolled")}</div>
            <BasicTable
              maxContentWidth={false}
              rows={textEnrolledStudents}
              schema={tableSchema}
            />
            <div className="my-3">{i18n.t("not_enrolled")}</div>
            <BasicTable
              maxContentWidth={false}
              rows={textNotEnrolledStudents}
              schema={tableSchema}
            />
            <div className="row mx-0 justify-content-end mt-4 mb-3">
              <PrimaryButton
                className="w-128"
                label={i18n.t("save")}
                onClick={handleSubmit}
              />
            </div>
          </>
        }
        onClose={handleClose}
        open={open}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  students: state.student.data,
  studentGroups: state.studentGroup.data,
  programs: state.program.data,
  classes: state._class.data,
})

const action = {}

export default connect(mapStateToProps, action)(EventModal)
