import React, { useState, useEffect, useMemo } from "react"
import i18n from "../../i18n"
import BaseModal from "../BaseModal/index"
import PrimaryButton from "../Core/PrimaryButton/index"
import Select from "../Core/Select/index"
import { TextField } from "@material-ui/core"
import DatePicker from "react-datepicker"
import FormHelperText from "@material-ui/core/FormHelperText"
import FormControl from "@material-ui/core/FormControl"
import { DotsLoader } from ".."
import { ErrorHelper, SuccessHelper } from "../../helpers"
import {
  getPriority,
  createBookingReminder,
  updateBookingReminder,
} from "../../config/simpleApiCalls"
import moment from "moment"
import useCache from "../../context/LocalCache"
import useActiveMenuType from "../../context/ActiveMenuType"
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete"

const ReminderModal = ({
  booking,
  handleClose,
  companyCustomerId,
  successCallback,
  reminder,
  _employeeId,
}) => {
  const getRoundedDate = (minutes, d = new Date()) => {
    const ms = 1000 * 60 * minutes // convert minutes to ms
    const roundedDate = new Date(Math.ceil(d.getTime() / ms) * ms)

    return roundedDate
  }
  const [subject, setSubject] = useState("")
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [dueDate, setDueDate] = useState(new Date())
  const [reminderOn, setReminderOn] = useState(getRoundedDate(5))
  const [description, setDescription] = useState("")
  const [formErrors, setFormErrors] = useState("")
  const [priorities, setPriorities] = useState([])
  const [selectedPriority, setSelectedPriority] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const { type } = useActiveMenuType()
  const isSalon = useMemo(() => {
    return type && type != "employee"
  }, [type])
  const { getResource } = useCache();
  const employees = useMemo(() => {
    return getResource((cache) => cache.company.employees).filter(({ isActive }) => isActive).map((employee) => ({
      value: employee.employee._id,
      name: employee.employee.userId.firstName,
    }));
  }, [getResource((cache) => cache.company.employees)]);
  const customers = getResource((cache) => {
    let data
    if (isSalon) {
      data = cache.company.customers
    } else {
      data = cache.employee.customers
    }
    return data?.filter(customer => customer.isActive)
  })
  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => option.phoneNo + option.name + option.firstName,
  });
  const [employeeId, setEmployeeId] = useState();
  const filterPassedTime = (time) => {
    const currentDate = new Date()
    const selectedDate = new Date(time)

    return currentDate.getTime() < selectedDate.getTime()
  }

  const validate = () => {
    const errors = {}

    if (!description) {
      errors.descriptionError = i18n.t("description_is_required")
    }
    if (!subject) {
      errors.subjectError = i18n.t("subject_is_required")
    }
    if (moment(dueDate).format("MM-DD-YYYY") < moment().format("MM-DD-YYYY")) {
      errors.dueDateError = i18n.t("invalid_date")
    }
    if (reminderOn < new Date()) {
      errors.reminderOnError = i18n.t("invalid_date")
    }
    if (_employeeId && !selectedCustomer) {
      errors.customerError = i18n.t("customer_is_required")
    }
    if (companyCustomerId && !employeeId && !_employeeId) {
      errors.employeeError = i18n.t("employee_is_required")
    }
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors)
      setTimeout(() => {
        setFormErrors({})
      }, 6000)
    } else {
      if (reminder) {
        handleUpdate()
      } else {
        handleSave()
      }
    }
  }
  const handleUpdate = () => {
    setIsLoading(true)
    const data = {
      subject,
      description,
      priorityId: selectedPriority,
      reminderOn,
      dueDate,
      _id: reminder._id,
      employeeId,
    }
    updateBookingReminder(data)
      .then(({ data }) => {
        setIsLoading(false)
        if (data.success) {
          SuccessHelper.handleSuccess(i18n.t("successfully_updated"), true)
          successCallback && successCallback(data.data)
          handleClose()
        } else {
          ErrorHelper.handleErrors(i18n.t("failed_to_update"), true)
        }
      })
      .catch((e) => {
        setIsLoading(false)
        ErrorHelper.handleErrors("An Error Occured", true)
      })
  }
  const handleSave = () => {
    setIsLoading(true)
    const data = {
      subject,
      description,
      priorityId: selectedPriority,
      reminderOn,
      dueDate,
    }
    if (booking) {
      data.bookingId = booking._id
      data.employeeId = booking.services[0].employeeId
    }
    if (companyCustomerId) {
      data.companyCustomerId = companyCustomerId
      data.employeeId = employeeId
    }
    if (_employeeId) {
      data.companyCustomerId = selectedCustomer._id
      data.employeeId = _employeeId
    }

    createBookingReminder(data)
      .then(({ data }) => {
        setIsLoading(false)
        if (data.success) {
          SuccessHelper.handleSuccess(i18n.t("successfully_added"), true)
          successCallback && successCallback(data.data)
          handleClose()
        } else {
          ErrorHelper.handleErrors(i18n.t("failed_to_add"), true)
        }
      })
      .catch(() => {
        setIsLoading(false)
        ErrorHelper.handleErrors("An Error Occured", true)
      })
  }
  useEffect(() => {
    getPriority().then(({ data }) => {
      setPriorities(
        data.data.map((priority) => ({
          name: i18n.t(priority.name),
          value: priority._id,
        }))
      )
    })
  }, [])
  useEffect(() => {
    setSelectedPriority(priorities[0]?.value)
    if (reminder) {
      setSubject(reminder.subject)
      setDescription(reminder.description)
      setSelectedPriority(reminder.priorityId._id)
      setDueDate(new Date(reminder.dueDate))
      setReminderOn(new Date(reminder.reminderOn))
      setEmployeeId(reminder.employeeId)
    }
  }, [priorities])
  return (
    <div>
      <DotsLoader isloading={isLoading} />
      <BaseModal
        containerClassName="large-container"
        open={true}
        {...{
          title: (
            <div>
              {reminder ? i18n.t("update_reminder") : i18n.t("create_reminder")}
            </div>
          ),
          content: (
            <>
              <div className="reminder-box col-sm-4 p-0">
                <label>{i18n.t("subject")}</label>
                <TextField
                  id="subject"
                  variant="outlined"
                  placeholder={i18n.t("subject")}
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  helperText={formErrors?.subjectError}
                  error={!!formErrors?.subjectError}
                />
              </div>
              <div className="reminder-col mt-4">
                <div className="reminder-box">
                  <label>{i18n.t("container.employee")}</label>
                  <FormControl className="full-width">
                    <Autocomplete
                      fullWidth
                      options={employees}
                      getOptionLabel={(option) => option.name}
                      onChange={(e, value) => {
                        setEmployeeId(value?.value)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          helperText={formErrors?.employeeError}
                          error={!!formErrors?.employeeError}
                          label={i18n.t("container.employee")}
                        />
                      )}
                    />
                  </FormControl>
                </div>
                <div className="reminder-box">
                  <label>{i18n.t("due_date")}</label>
                  <DatePicker
                    className="full-width"
                    portalId="root-portal"
                    minDate={new Date()}
                    onChange={(dueDate) => {
                      setDueDate(dueDate)
                    }}
                    customInput={
                      <TextField
                        id="reminderOn"
                        variant="outlined"
                        value={dueDate}
                        className="full-width"
                        readOnly={true}
                        helperText={formErrors?.dueDateError}
                        error={!!formErrors?.dueDateError}
                      />
                    }
                    selected={dueDate}
                    placeholderText={i18n.t(
                      "container.services_click_to_select_a_date"
                    )}
                  />
                </div>
                <div className="reminder-box">
                  <label>{i18n.t("reminder_on")}</label>
                  <DatePicker
                    className="full-width"
                    portalId="root-portal"
                    minDate={new Date()}
                    showTimeSelect
                    timeIntervals={5}
                    onChange={(reminderOn) => {
                      setReminderOn(reminderOn)
                    }}
                    filterTime={filterPassedTime}
                    customInput={
                      <TextField
                        id="reminderOn"
                        value={reminderOn}
                        variant="outlined"
                        className="full-width"
                        readOnly={true}
                        helperText={formErrors?.reminderOnError}
                        error={!!formErrors?.reminderOnError}
                      />
                    }
                    dateFormat="MMMM d, yyyy h:mm aa"
                    selected={reminderOn}
                    placeholderText={i18n.t(
                      "container.services_click_to_select_a_date"
                    )}
                  />
                </div>
                <div className="reminder-box">
                  <label>{i18n.t("priority")}</label>
                  <FormControl className="full-width">
                    <Select
                      value={selectedPriority}
                      onChange={(e) => setSelectedPriority(e.target.value)}
                      excludeEmptyValue
                      variant="outlined"
                      options={priorities}
                    />
                  </FormControl>
                </div>
              </div>
              <div className="reminder-box">
                <label>{i18n.t("description")}</label>
                <textarea
                  placeholder={i18n.t("description")}
                  className="mt-2 w-100 form-control"
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value)
                  }}
                  rows="3"
                  error={!!formErrors?.descriptionError}
                ></textarea>
              </div>
            </>
          ),
          actions: (
            <div className="text-right mr-4 mb-4">
              <PrimaryButton
                className="w-128"
                onClick={() => validate()}
                label={reminder ? i18n.t("update") : i18n.t("create")}
              />
            </div>
          ),
        }}
        onClose={() => handleClose()}
      />
    </div>
  )
}

export default ReminderModal
