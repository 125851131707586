import React, { useEffect, useRef, useState } from "react";
import {
  SelectValidator,
  ValidatorForm,
} from "react-material-ui-form-validator";
import MenuItem from "@material-ui/core/MenuItem";
import i18n from "../../../../i18n";
import PrimaryButton from "../../../Core/PrimaryButton";
import { useSelector } from "react-redux";
import { BaseModal } from "../../..";

export default function ApplyTemplate({ open, setOpen, onApply }) {
  const form = useRef();
  const [selectedTemplate, setSelectedTemplate] = useState();

  const templates = useSelector((s) => s.autoBookingScheduleTemplate.data);

  useEffect(() => {
    if (open) {
      setSelectedTemplate();
    }
  }, [open]);

  const handleConfirm = () => {
    onApply(selectedTemplate);
  };

  return (
    <BaseModal
      open={open}
      title={i18n.t("apply_template")}
      onClose={() => setOpen(false)}
      content={
        <ValidatorForm
          onSubmit={handleConfirm}
          ref={form}
          className="row col-12 px-0 mx-0"
          onError={(error) => {
            console.log(error);
          }}
        >
          <SelectValidator
            containerProps={{ className: "col-12 px-0 mb-1" }}
            label={i18n.t("templates")}
            onChange={(e) => {
              setSelectedTemplate(e.target.value);
            }}
            value={selectedTemplate || ""}
            select
            validators={["required"]}
            errorMessages={[i18n.t("this_field_is_required")]}
          >
            {templates.map((template) => {
              return (
                <MenuItem key={template._id} value={template._id}>
                  {template.name}
                </MenuItem>
              );
            })}
          </SelectValidator>

          <div className="row mx-0 px-0 justify-content-end mt-2 col-12">
            <PrimaryButton
              props={{
                type: "submit",
              }}
              label={i18n.t("apply")}
            />
          </div>
        </ValidatorForm>
      }
    />
  );
}
