import { Checkbox, TextField } from "@material-ui/core"
import React, { useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import BasicTable from "../../../../../../components/Core/BasicTable"
import PrimaryButton from "../../../../../../components/Core/PrimaryButton"
import useCache from "../../../../../../context/LocalCache"
import { ErrorHelper, SuccessHelper } from "../../../../../../helpers"
import i18n from "../../../../../../i18n"
import { setRecipientRequest } from "../../../../../../redux/actions/Saloon/CommunicationsMarketingCampaign"
import useScreenSize from "../../../../../../context/ScreenSize"
export default function CampaignCustomers({ data, setData, getOne }) {
  const dispatch = useDispatch()
  const { getResource } = useCache()
  const customers = getResource((cache) => cache.company.customers)
  const [customerMap, setCustomerMap] = useState({})
  const [search, setSearch] = useState("")
  const filteredCustomers = useMemo(() => {
    if (!search) return customers
    return customers.filter((customer) => {
      return (
        customer.name.toLowerCase().includes(search.toLowerCase()) ||
        customer.phoneNo.includes(search.toLowerCase())
      )
    })
  }, [search, customers])

  const { screenSize } = useScreenSize()
  const allSelected = useMemo(() => {
    const notSelected = filteredCustomers.find(
      (customer) => !customerMap[customer._id]
    )
    return !notSelected
  }, [filteredCustomers, customerMap])
  useEffect(() => {
    const { customers = [], prospects = [] } = data

    const customerMap = {}
    customers.map((customerId) => {
      customerMap[customerId] = true
    })
    setCustomerMap(customerMap)
  }, [data])
  const onSubmitRecipients = () => {
    dispatch(
      setRecipientRequest(
        {
          campaignId: data._id,
          customers: Object.keys(customerMap),
        },
        {
          success: ({ data: res }) => {
            setData({ ...data, ...res })
            getOne(data._id)
            SuccessHelper.handleSuccess(i18n.t("successfully_updated"), true)
          },
          failure: () => {
            ErrorHelper.handleErrors(i18n.t("failed_to_update"), true)
          },
        }
      )
    )
  }
  return (
    <>
      <div className="col-12 flex-column flex-md-row px-0 d-flex align-items-md-center mb-3">
        <label className="font-weight-bold mr-3">{i18n.t("search")}</label>
        <TextField
          placeholder={i18n.t("search")}
          onChange={(e) => setSearch(e.target.value)}
          variant="outlined"
        />
      </div>
      <div className="col-12 px-0 CustomerTableContainer">
        <BasicTable
          rows={filteredCustomers}
          onRowClick={(val) => {
            if (customerMap[val._id]) {
              delete customerMap[val._id]
              setCustomerMap({ ...customerMap })
            } else {
              setCustomerMap({
                ...customerMap,
                [val._id]: true,
              })
            }
          }}
          schema={[
            {
              key: "action",
              style: { width: 40 },
              header: () => {
                return (
                  <Checkbox
                    edge="start"
                    checked={allSelected}
                    tabIndex={-1}
                    onClick={() => {
                      const map = { ...customerMap }
                      const mapFunc = allSelected
                        ? (customer) => {
                            delete map[customer._id]
                          }
                        : (customer) => {
                            map[customer._id] = true
                          }
                      filteredCustomers.map(mapFunc)
                      setCustomerMap(map)
                    }}
                  />
                )
              },
              render: (val) => {
                return (
                  <Checkbox
                    edge="start"
                    checked={customerMap[val._id] || false}
                    tabIndex={-1}
                  />
                )
              },
            },
            {
              header: i18n.t("name"),
              field: "name",
            },
            {
              header: i18n.t("send_to"),
              field: "phoneNo",
            },
            {
              header: i18n.t("EMAIL"),
              field: "email",
            },
          ]}
        />
      </div>
      <div
        style={
          screenSize === "mobile"
            ? {
                position: "sticky",
                bottom: "-13px",
                paddingTop: "10px",
                paddingBottom: "10px",
                zIndex: 1,
                backgroundColor: "white",
              }
            : {}
        }
        className="row justify-content-center justify-content-md-end my-4"
      >
        <PrimaryButton
          className="w-128"
          label={i18n.t("save")}
          onClick={onSubmitRecipients}
        />
      </div>
    </>
  )
}
