import { connect } from "react-redux"
import React, { Component } from "react"
import { Translation } from "react-i18next"
import i18n from "../../../../i18n"
import { Images } from "../../../../theme"
import { DotsLoader } from "../../../../components"
import ProductForm from "../../../../components/Forms/Product"
import { createProduct } from "../../../../config/simpleApiCalls"
import { ErrorHelper, SuccessHelper } from "../../../../helpers"

import "./styles.css"
import Sytles from "./sytles"
import { times } from "lodash"
import SharedStorage from "../../../../helpers/Storage"
class AddService extends Component {
  constructor(props) {
    super(props)
    this.state = {
      serviceName: "",
      price: 0,
      categoryList: [],
      categoryName: "",
      categoryId: "",
      isActive: 1,
      imageUrl: "",
      imageFile: "",
      minimumTime: 0,
      shouldSendTextReminders: false,
      textReminderFrequency: 2,
      values: {},

      // ERRORS
      serviceNameError: "",
      minimumTimeError: "",
      priceError: "",
      categoryNameError: "",
    }
  }

  componentDidMount = async () => {
    const company = JSON.parse(await SharedStorage.getItem("company"))
    const isGym = company?.function == "gym"
    this.setState({ isGym, timeSlotLength: company.timeSlotLength })
  }

  handleNavigation = (route) => {
    this.props.history.push(route)
  }

  checkValidation = (successCallback) => {
    const { name, description, cost, charge, quantity, reorderTrigger } =
      this.state.values

    if (!name) {
      this.setState({
        formErrors: {
          name: i18n.t("name_required"),
        },
      })
      setTimeout(() => {
        this.setState({ formErrors: {} })
      }, 6000)
    } else if (!description) {
      this.setState({
        formErrors: {
          description: i18n.t("container.description_required"),
        },
      })
      setTimeout(() => {
        this.setState({ formErrors: {} })
      }, 6000)
    } else if (!charge) {
      this.setState({
        formErrors: {
          charge: i18n.t("charge_required"),
        },
      })
      setTimeout(() => {
        this.setState({ formErrors: {} })
      }, 6000)
    } else {
      this.handleCreateProduct(successCallback)
    }
  }

  handleCreateProduct = (successCallback) => {
    this.setState({ isloading: true })
    const {
      name,
      description,
      cost,
      charge,
      quantity,
      reorderTrigger,
      taxable,
      image,
      commission,
      barCode,
      category,
    } = this.state.values
    let payload = new FormData()
    payload.append("name", name)
    payload.append("description", description)
    payload.append("charge", parseFloat(charge))
    payload.append("taxable", taxable == "true")
    payload.append("barCode", barCode)

    console.log("category", category)
    if (image) {
      payload.append("image", image)
    }
    if (cost) {
      payload.append("cost", parseFloat(cost))
    }
    if (quantity) {
      payload.append("quantity", parseFloat(quantity))
    }
    if (reorderTrigger) {
      payload.append("reorderTrigger", parseFloat(reorderTrigger))
    }
    if (commission) {
      payload.append("commission", parseFloat(commission))
    }

    if (category) {
      payload.append("category", category)
    }

    createProduct(payload)
      .then((res) => {
        if (res.data.success) {
          console.log(res.data)
          successCallback()
          SuccessHelper.handleSuccess(
            "Your product was successfully added.",
            true
          )
          this.setState({
            isloading: false,
          })
        }
        if (!res.data.success) {
          ErrorHelper.handleErrors(res.data.msg, true)
          this.setState({ isloading: false })
        }
      })
      .catch((error) => {
        console.log(error, "errorrrrrrrrrrrrrrrrrrr")
      })
  }

  renderLoader = () => {
    const { isloading } = this.state
    return <DotsLoader isloading={isloading} />
  }

  renderSaloonDashboardHeading = () => {
    return (
      <h1 className="saloon-dashboard-heading mb-3">
        {i18n.t("container.add_product")}
      </h1>
    )
  }

  renderAddServiceForm = () => {
    const { formErrors = {}, values = {} } = this.state
    return (
      <div className="col-12">
        <div
          className="col-12 p-0 bg-white add-product"
          style={{ borderRadius: "1rem" }}
        >
          <ProductForm
            formErrors={formErrors}
            checkValidation={this.checkValidation}
            values={values}
            setValue={(newValues) =>
              this.setState({ values: { ...this.state.values, ...newValues } })
            }
          />
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        {this.renderLoader()}
        <div className="p-4 mb-5 mb-md-0">
          {this.renderSaloonDashboardHeading()}
          {this.renderAddServiceForm()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

const action = {}

export default connect(mapStateToProps, action)(AddService)
