import React, { useEffect, useRef, useState } from "react";
import i18n from "../../../../i18n";
import {
  getRequest,
  fundRequest,
} from "../../../../redux/actions/Saloon/CommunicationsMarketing";
import { getRequest as getCards } from "../../../../redux/actions/CompanyCard";
import PrimaryButton from "../../../../components/Core/PrimaryButton";
import { BaseModal, DotsLoader } from "../../../../components";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  SelectValidator,
  ValidatorForm,
} from "react-material-ui-form-validator";
import { MenuItem } from "@material-ui/core";
import { ErrorHelper, SuccessHelper } from "../../../../helpers";
import FormContainer from "../../../../components/Core/FormContainer/FormContainer";

const FundAccountPopup = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const form = useRef();
  const [data, setData] = useState({});
  const loading = useSelector((s) => s.communicationsMarketing.isFetching);
  const cards = useSelector((s) => s.companyCard.data);
  useEffect(() => {
    dispatch(getRequest());
    dispatch(getCards());
  }, []);

  const onSubmit = (e) => {
    dispatch(
      fundRequest(data, {
        success: () => {
          SuccessHelper.handleSuccess(
            i18n.t("added_to_account", { amount: data.amount }),
            true
          );
          setOpen(false);
        },
        failure: () => {
          ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
        },
      })
    );
  };
  useEffect(() => {
    if (open) {
      setData({});
    }
  }, [open]);
  const handleChange = (key, value) => {
    data[key] = value;
    setData({ ...data });
  };
  return (
    <BaseModal
      open={open}
      onClose={() => setOpen(false)}
      title={i18n.t("fund_account")}
      containerClassName={"half-container"}
      content={
        <ValidatorForm onSubmit={onSubmit} ref={form} className="w-100">
          <DotsLoader isloading={loading} />
          <FormContainer>
            <div className="col-12 px-0 d-flex align-items-center mb-3">
              <label className="font-weight-bold" style={{ minWidth: '120px' }}>{i18n.t("card")}</label>
              <div className="w-100">
                <SelectValidator
                  label={i18n.t("card")}
                  select
                  validators={["required"]}
                  errorMessages={[i18n.t("required")]}
                  value={data.cardId || ""}
                  onChange={(e) => handleChange("cardId", e.target.value)}
                  variant="outlined"
                >
                  {cards.map((card) => {
                    return <MenuItem value={card.id}>{card.card.last4}</MenuItem>;
                  })}
                </SelectValidator>
              </div>
            </div>
            <div className="col-12 px-0 d-flex align-items-center mb-3">
              <label className="font-weight-bold" style={{ minWidth: '120px' }}>{i18n.t("amount")}</label>
              <div className="w-100">
                <SelectValidator
                  label={i18n.t("amount")}
                  select
                  validators={["required"]}
                  errorMessages={[i18n.t("required")]}
                  value={data.amount || ""}
                  onChange={(e) => handleChange("amount", e.target.value)}
                  variant="outlined"
                >
                  <MenuItem value="1000">$10.00</MenuItem>
                  <MenuItem value="2500">$25.00</MenuItem>
                  <MenuItem value="5000">$50.00</MenuItem>
                </SelectValidator>
              </div>
            </div>
            <div className="row justify-content-end my-3">
              <PrimaryButton
                className="w-128"
                props={{
                  type: "submit",
                }}
                label={i18n.t("save")}
              />
            </div>
          </FormContainer>
        </ValidatorForm>
      }
    />
  );
};

export default FundAccountPopup;
