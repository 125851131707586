import React from "react"
import { Translation } from "react-i18next"
import { drawerWidth } from "../../AppSidebar"
export function Footer({ sidebarOpen }) {
  return (
    <div
      className="saloon-footer-text col-12 py-3"
      style={{ paddingLeft: sidebarOpen ? drawerWidth : 0 }}
    >
      <Translation>{(t) => t("copyright")}</Translation>
    </div>
  )
}

export default Footer
