// @flow
import Immutable from "seamless-immutable"
import * as types from "../../actions/ActionTypes"

const initialState = Immutable({
  isFetching: false,
  errorMessage: "",
  data: [],
  pricingDetails: {},
  activeCampaign: null,
})

export default (state = initialState, action) => {
  switch (action.type) {
    case types.COMMUNICATION_MARKETING_CAMPAIGN.CREATE_SAGA:
    case types.COMMUNICATION_MARKETING_CAMPAIGN.REUSE_SAGA:
    case types.COMMUNICATION_MARKETING_CAMPAIGN.GET_SAGA:
    case types.COMMUNICATION_MARKETING_CAMPAIGN.UPDATE_SAGA:
    case types.COMMUNICATION_MARKETING_CAMPAIGN.SET_RECIPIENTS_SAGA:
    case types.COMMUNICATION_MARKETING_CAMPAIGN.REMOVE_SAGA:
    case types.COMMUNICATION_MARKETING_CAMPAIGN.PRICING_DETAILS_SAGA:
    case types.COMMUNICATION_MARKETING_CAMPAIGN.DISPATCH_SAGA:
    case types.COMMUNICATION_MARKETING_CAMPAIGN.GET_ONE_SAGA:
      return Immutable.merge(state, {
        isFetching: true,
      })
    case types.COMMUNICATION_MARKETING_CAMPAIGN.CREATE:
    case types.COMMUNICATION_MARKETING_CAMPAIGN.REUSE:
      return Immutable.merge(state, {
        isFetching: false,
        data: [action.data, ...state.data],
      })
    case types.COMMUNICATION_MARKETING_CAMPAIGN.GET: {
      return Immutable.merge(state, {
        isFetching: false,
        data: action.data,
      })
    }
    case types.COMMUNICATION_MARKETING_CAMPAIGN.GET_ONE: {
      return Immutable.merge(state, {
        isFetching: false,
        activeCampaign: action.data,
      })
    }
    case types.COMMUNICATION_MARKETING_CAMPAIGN.UPDATE:
    case types.COMMUNICATION_MARKETING_CAMPAIGN.SET_RECIPIENTS:
    case types.COMMUNICATION_MARKETING_CAMPAIGN.DISPATCH: {
      const newState = { ...state, isFetching: false, data: [...state.data] }
      console.log({ action: action.data })
      const { _id } = action.data
      const index = state.data.findIndex((element) => element._id == _id)
      if (index != -1) {
        newState.data[index] = { ...newState.data[index], ...action.data }
      }
      return newState
    }
    case types.COMMUNICATION_MARKETING_CAMPAIGN.REMOVE: {
      const newState = { ...state, isFetching: false, data: [...state.data] }
      const { _id } = action.data
      const index = state.data.findIndex((element) => element._id == _id)
      if (index != -1) {
        newState.data.splice(index, 1)
      }
      return newState
    }
    case types.COMMUNICATION_MARKETING_CAMPAIGN.PRICING_DETAILS: {
      return Immutable.merge(state, {
        pricingDetails: action.data,
      })
    }
    case types.COMMUNICATION_MARKETING_CAMPAIGN.REQUEST_FAILURE:
      return Immutable.merge(state, {
        isFetching: false,
      })

    case types.LOGOUT:
      return initialState
    case types.CLEAR_LOGOUT:
      return initialState
    default:
      return state
  }
}
