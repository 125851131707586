import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import i18n from "../../i18n";
import { request as user_logout } from "../../redux/actions/LogOut";
import { clearLogout as clear_logout } from "../../redux/actions/LogOut";
import { SuccessHelper } from "../../helpers";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import "./styles.css";
import { Popover } from "@material-ui/core";
import SharedStorage from "../../helpers/Storage";
import Broswer from "../../helpers/Browser";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      routeName: "",
    };
    this.anchorEl = React.createRef();
  }

  static propTypes = {
    showSidebar: PropTypes.bool,
  };

  static defaultProps = {
    showSidebar: false,
  };

  async componentDidMount() {
    let routeName = "";
    if (this.props.location.pathname.split("/")[1]) {
      routeName = this.props.location.pathname.split("/")[1];
    } else {
      routeName = this.props.location.pathname;
    }
    const user = await JSON.parse(await SharedStorage.getItem("user"));
    this.setState({ user, routeName });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.logout) {
      if (
        !nextProps.logout.failure &&
        !nextProps.logout.isFetching &&
        !nextProps.logout.errorMessage &&
        nextProps.logout.data &&
        nextProps.logout.data.success
      ) {
        nextProps.clear_logout();
        this.setState({ user: null });
      } else if (
        nextProps.logout.failure &&
        !nextProps.logout.isFetching &&
        nextProps.logout.errorMessage
      ) {
        this.setState({ isloading: false });
      }
    }
  }

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  handleLogout = () => {
    const { user } = this.state;
    this.props.user_logout({});
    this.props.history.push("/");
  };

  renderNavbarItem = (route, title) => {
    const { routeName } = this.state;
    return (
      <li
        className={routeName == route ? "navbar-item-active" : "navbar-item"}
        onClick={() => this.handleNavigation(route)}
      >
        {title}
      </li>
    );
  };

  renderLogin = () => {
    const { routeName } = this.state;
    return (
      <>
        <li
          aria-controls="login-menu"
          aria-haspopup="true"
          className={
            routeName == "login" ? "navbar-item-active" : "navbar-item"
          }
          onClick={
            () => this.setState({ isLoginMenuOpen: true }) //() => this.handleNavigation("login")
          }
        >
          <div ref={this.anchorEl}>{"Login"}</div>
        </li>
      </>
    );
  };

  renderLogout = () => {
    return (
      <li className="navbar-item" onClick={() => this.handleLogout()}>
        {i18n.t("logout")}
      </li>
    );
  };

  openInNewTab = (url) => {
    const newWindow = Broswer.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  render() {
    const { user } = this.state;
    const { showSidebar } = this.props;
    return (
      <div
        className="sidebar-wrapper"
        style={{ display: showSidebar ? "block" : "none" }}
      >
        <ul className="navbar-list">
          <li
            onClick={() =>
              this.openInNewTab("https://www.easy1booking-business.com/")
            }
            className={"navbar-item"}
          >
            For Business
          </li>

          {this.renderNavbarItem("our-services", i18n.t("our_services"))}
          {this.renderNavbarItem(
            "demonstration-reservation",
            i18n.t("book_a_demo")
          )}
          {this.renderNavbarItem("/careers.html", i18n.t("careers"))}
          {user && user.access_token ? (
            <>
              {this.renderNavbarItem("/user-profile", i18n.t("Profile"))}
              {this.renderLogout()}
            </>
          ) : null}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ logout: state.logout });

const action = { user_logout, clear_logout };

export default connect(mapStateToProps, action)(Sidebar);
