import { connect } from "react-redux"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { MenuItem, TextField } from "@material-ui/core"

import i18n from "../../../i18n"
import Swal from "sweetalert2"
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator"
import PrimaryButton from "../../../components/Core/PrimaryButton"
import moment from "moment"
import DatePicker from "react-datepicker"
import { BaseModal } from "../../../components"
import AppBar from "@material-ui/core/AppBar"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import TabPanel from "../../../components/TabPanel/TabPanel.component"
import _ from "lodash"
import BasicTable from "../../../components/Core/BasicTable"
import { Checkbox } from "@material-ui/core"
import ManageScheduledStudentsModal from "./ManageScheduledStudentsModal"
import deleteConfirmation from "../../../helpers/DeleteHelper"

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

function EventModal({
  open,
  setOpen,
  studentGroups,
  students,
  active = {},
  onSubmit,
  onDelete,
  setAttendance,
  employees,
}) {
  const form = useRef()
  const [data, setData] = useState({})
  const [manageScheduledModalOpen, setManageScheduledModalOpen] =
    useState(false)
  const [tab, setTab] = React.useState(0)

  const handleTabChange = (event, newValue) => {
    setTab(newValue)
  }
  const init = (data) => {
    setTab(0)
    setData({ ...data })
  }
  useEffect(() => {
    init(active)
  }, [])
  useEffect(() => {
    init(active)
  }, [active])

  const [enrolledStudents, notEnrolledStudents] = useMemo(() => {
    if (!data || !Object.values(data).length) {
      return []
    }
    const enrolledStudents = []
    const notEnrolledStudents = []
    students.filter((student) => {
      const enrolled = student.studentClasses?.find((_class) => {
        return _class.classId == data.classId._id
      })
      if (enrolled) {
        enrolledStudents.push(student)
      } else {
        notEnrolledStudents.push(student)
      }
    })
    return [enrolledStudents, notEnrolledStudents]
  }, [data, students])

  const handleClose = () => {
    setData(null)
    setOpen(false)
  }

  const handleChange = (key, value) => {
    data[key] = value
    setData({ ...data })
  }

  const handleDelete = () => {
    onDelete(data._id)
  }

  const handleSubmit = (param = {}) => {
    const body = _.omit({ ...data, ...param }, [
      "date",
      "startTime",
      "endTime",
      "classId",
    ])
    const { date, startTime, endTime, classId } = data
    const extractedDate = moment(date).format("MM-DD-YYYY")
    body.startDateTime = moment(`${extractedDate} ${startTime}`)
    body.endDateTime = moment(`${extractedDate} ${endTime}`)
    body.classId = classId._id
    onSubmit(body)
  }
  const checkTimes = () => {
    const { startTime, endTime } = data
    if (startTime && endTime) {
      const momentStart = moment(startTime, "hh:mm")
      const momentEnd = moment(endTime, "hh:mm")
      if (momentStart.isAfter(momentEnd)) {
        setData({ ...data, startTime: endTime, endTime: startTime })
      }
    }
  }
  if (!open || !data) {
    return null
  }
  return (
    <div>
      <BaseModal
        title="Edit Event"
        contentContainerStyle={{
          overflowY: "visible",
        }}
        containerClassName={"half-container"}
        content={
          <>
            <AppBar position="static" className="custom-tab">
              <Tabs
                value={tab}
                onChange={handleTabChange}
                aria-label="simple tabs example"
              >
                <Tab label={i18n.t("details")} {...a11yProps(0)} />
                <Tab label={i18n.t("students")} {...a11yProps(1)} />
              </Tabs>
            </AppBar>
            <TabPanel value={tab} index={0}>
              <ValidatorForm
                onSubmit={() => handleSubmit()}
                ref={form}
                className="w-100 row mx-0"
                onError={(error) => {}}
              >
                <div className="col-12 flex-column flex-md-row px-0 d-flex align-items-md-center justify-content-between mb-3">
                  <label className="font-weight-bold" style={{ minWidth: 120 }}>
                    {i18n.t("class")}
                  </label>
                  <div className="w-100">
                    <TextValidator
                      className="w-100"
                      label={i18n.t("class")}
                      value={data.classId?.name}
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                    />
                  </div>
                </div>
                <div className="col-12 flex-column flex-md-row px-0 d-flex align-items-md-center justify-content-between mb-3">
                  <label className="font-weight-bold" style={{ minWidth: 120 }}>
                    {i18n.t("class")}
                  </label>
                  <div className="w-100">
                    <SelectValidator
                      className="w-100"
                      label={i18n.t("employee")}
                      onChange={(e) =>
                        handleChange("employeeId", e.target.value)
                      }
                      value={data.employeeId || ""}
                      select
                      variant="outlined"
                    >
                      {employees.map((employee) => {
                        return (
                          <MenuItem value={employee.value}>
                            {employee.name}
                          </MenuItem>
                        )
                      })}
                    </SelectValidator>
                  </div>
                </div>
                <div className="col-12 flex-column flex-md-row px-0 d-flex align-items-md-center justify-content-between mb-3">
                  <label className="font-weight-bold" style={{ minWidth: 120 }}>
                    {i18n.t("date")}
                  </label>
                  <div className="w-100">
                    <DatePicker
                      wrapperClassName="w-100"
                      selected={data.date ? moment(data.date).toDate() : ""}
                      onChange={(e) => handleChange("date", e)}
                      value={data.date ? moment(data.date).toDate() : ""}
                      customInput={
                        <TextValidator
                          label={i18n.t("date")}
                          onChange={(e) => handleChange("date", e.target.value)}
                          value={data.date}
                          validators={["required"]}
                          errorMessages={[i18n.t("this_field_is_required")]}
                          variant="outlined"
                        />
                      }
                      placeholderText={i18n.t("end_date")}
                    />
                  </div>
                </div>
                <div className="col-12 flex-column flex-md-row px-0 d-flex align-items-md-center justify-content-between mb-3">
                  <label className="font-weight-bold" style={{ minWidth: 120 }}>
                    {i18n.t("start_time")}
                  </label>
                  <div className="w-100">
                    <TextValidator
                      className="w-100"
                      onChange={(e) =>
                        handleChange("startTime", e.target.value)
                      }
                      onBlur={checkTimes}
                      type="time"
                      value={data.startTime || ""}
                      validators={["required"]}
                      errorMessages={[i18n.t("this_field_is_required")]}
                      variant="outlined"
                    />
                  </div>
                </div>
                <div className="col-12 flex-column flex-md-row px-0 d-flex align-items-md-center justify-content-between mb-3">
                  <label className="font-weight-bold" style={{ minWidth: 120 }}>
                    {i18n.t("end_time")}
                  </label>
                  <div className="w-100">
                    <TextValidator
                      className="w-100"
                      onChange={(e) => handleChange("endTime", e.target.value)}
                      onBlur={checkTimes}
                      type="time"
                      value={data.endTime || ""}
                      validators={["required"]}
                      errorMessages={[i18n.t("this_field_is_required")]}
                      variant="outlined"
                    />
                  </div>
                </div>
                <div className="row mx-0 px-0 justify-content-end my-4 col-12 gap-2">
                  <PrimaryButton
                    label={i18n.t("delete")}
                    onClick={() => deleteConfirmation(() => handleDelete())}
                    className="w-98"
                  />
                  <PrimaryButton
                    className="w-98"
                    props={{
                      type: "submit",
                    }}
                    label={data._id ? i18n.t("save") : i18n.t("create")}
                  />
                </div>
              </ValidatorForm>
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <div className="row mx-0 justify-content-end mb-3">
                <PrimaryButton
                  label={i18n.t("scheduled_students")}
                  onClick={() => setManageScheduledModalOpen(true)}
                />
              </div>
              <BasicTable
                rows={data.students}
                maxContentWidth={false}
                schema={[
                  {
                    header: i18n.t("attended"),
                    style: { width: 80 },
                    field: "attended",
                    render: (val) => {
                      return (
                        <Checkbox
                          checked={val.attended}
                          onChange={() => {
                            setAttendance({
                              _id: data._id,
                              studentId: val._id,
                              attended: !val.attended,
                            })
                          }}
                          inputProps={{ "aria-label": "enrolled" }}
                        />
                      )
                    },
                  },
                  {
                    header: i18n.t("student"),
                    field: "student",
                    style: { verticalAlign: "middle" },
                    accessor: (val) => val.studentId?.name,
                  },
                  {
                    header: i18n.t("note"),
                    field: "note",
                    render: (val) => {
                      return (
                        <TextField
                          defaultValue={val.note}
                          label={i18n.t("note")}
                          fullWidth
                          onBlur={(e) => {
                            if (e.target.value != val.note) {
                              setAttendance({
                                _id: data._id,
                                studentId: val._id,
                                note: e.target.value,
                              })
                            }
                          }}
                        />
                      )
                    },
                  },
                ]}
              />
            </TabPanel>
          </>
        }
        onClose={handleClose}
        open={open}
      />
      {manageScheduledModalOpen ? (
        <ManageScheduledStudentsModal
          open={manageScheduledModalOpen}
          setOpen={setManageScheduledModalOpen}
          enrolledStudents={enrolledStudents}
          notEnrolledStudents={notEnrolledStudents}
          scheduledStudents={data.students}
          onSubmit={handleSubmit}
        />
      ) : null}
    </div>
  )
}

const mapStateToProps = (state) => ({
  students: state.student.data,
  studentGroups: state.studentGroup.data,
  classes: state._class.data,
})

const action = {}

export default connect(mapStateToProps, action)(EventModal)
