import React, { useState } from "react"
import Button from "@material-ui/core/Button"
import BasicTable from "../../Core/BasicTable"
import i18n from "../../../i18n"
import moment from "moment"
import CollectPaymentModal from "../../CreateInvoice/CollectPaymentModal"
import { getNestedValue } from "../../../util/objectMethods"
import CollapsibleTable from "../../CollapsibleTable"
import { Translation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import PrimaryButton from "../../Core/PrimaryButton"
import useBooking from "../../../context/Booking"
import {
  getEmployeesBySaloonAndService,
  getCustomerByUserId,
} from "../../../config/simpleApiCalls"
import { useEffect } from "react"
export default function CustomerPackages({ data: packages = [], customer }) {
  const [packageToCollect, setPackageToCollect] = useState({})
  const [collectPaymentModalOpen, setCollectPaymentModalOpen] = useState(null)
  const [giftCardBalance, setGiftcardBalance] = useState(0)
  const {
    onChange,
    data,
    setCurrentOpen,
    setEmployeeList,
    categoryList,
    setAddOpen,
  } = useBooking()
  const { selectSalon } = data
  useEffect(() => {
    getCustomerByUserId({ userId: customer && customer.userId }).then(
      ({ data }) => {
        setGiftcardBalance(data.data.giftCardBalance)
      }
    )
  }, [collectPaymentModalOpen])
  return (
    <>
      <div
        className="table-responsive package-table"
        style={{ borderTop: "1px solid #E0E2E4" }}
      >
        <CollapsibleTable
          rows={packages}
          childKey="services"
          schema={[
            {
              key: "package",
              accessor: (val) => getNestedValue(val, ["packageId", "name"], ""),
            },
            {
              key: "numberOfSessions",
              accessor: (val) =>
                val.numberOfSessions - val.sessionBooked - val.sessionCompleted,
            },
            {
              key: "purchased_date",
              accessor: (val) =>
                moment(val.createdDate).format("MM-DD-YYYY hh:mm A"),
            },
            {
              key: "balance_due",
              accessor: (val) => val.balanceDue.toFixed(2),
            },
            {
              key: "expiryDate",
              accessor: (val) =>
                moment(val.expiryDate).format("MM-DD-YYYY hh:mm A"),
            },
            {
              key: "action",
              header: "",
              style: { width: 60 },
              render: (val) => {
                if (val.balanceDue <= 0) {
                  return null
                }
                return (
                  <div className="row mx-0">
                    <FontAwesomeIcon
                      className="view-icon"
                      color="white"
                      icon={["fas", "hand-holding-usd"]}
                      onClick={() => {
                        setPackageToCollect(val)
                        setCollectPaymentModalOpen(true)
                      }}
                    />
                  </div>
                )
              },
            },
          ]}
          childSchema={{
            columns: [
              {
                key: "name",
                accessor: (val) =>
                  getNestedValue(val, ["serviceId", "name"], ""),
              },
              {
                key: "action",
                header: "",
                render: (val, row) => {
                  const available =
                    row.numberOfSessions -
                    row.sessionBooked -
                    row.sessionCompleted
                  if (available > 0) {
                    return (
                      <PrimaryButton
                        className="w-98"
                        label={i18n.t("redeem")}
                        onClick={async () => {
                          const service = val.serviceId
                          const selectedCategory = categoryList.find(
                            (element) => element._id == service.serviceId
                          )
                          onChange({
                            phoneNumber: customer.phoneNo,
                            email: customer.email,
                            name: customer.name,
                            selectSalon,
                            selectedCategory,
                            selectServices: service,
                            userPackageId: row._id,
                            selectEmployee: null,
                            newDate: "",
                            selectedTime: undefined,
                            availableTimeslots: {},
                            selectDateAndTime: undefined,
                            selectedTime: undefined,
                          })
                          const res = await getEmployeesBySaloonAndService({
                            companyId: row.companyId,
                            serviceId: service._id,
                          })
                          setEmployeeList(
                            res.data.data.map((employee) => ({
                              ...employee,
                              name: `${employee.employeeId.userId.firstName} ${employee.employeeId.userId.lastName}`,
                            }))
                          )
                          setCurrentOpen("employees")
                          setAddOpen(true)
                        }}
                      />
                    )
                  }
                },
              },
            ],
          }}
        />
      </div>
      {collectPaymentModalOpen ? (
        <CollectPaymentModal
          open={collectPaymentModalOpen}
          setOpen={() => {
            setCollectPaymentModalOpen(false)
            setPackageToCollect({})
          }}
          customer={customer}
          userPackage={packageToCollect}
          giftCardBalance={giftCardBalance}
          onSuccess={() => {}}
        />
      ) : null}
    </>
  )
}
