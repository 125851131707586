import React, { createContext, useContext, useState } from "react"
import { DotsLoader } from "../../components"
import {
  getStaffMeetings,
  removeStaffMeeting,
} from "../../config/simpleApiCalls"
import { ErrorHelper, SuccessHelper } from "../../helpers"
import i18n from "../../i18n"
import CreateStaffMeeting from "./components/CreateStaffMeeting"
import UpdateStaffMeeting from "./components/UpdateStaffMeeting"

const StaffMettingContext = createContext({
  addOpen: false,
  setAddOpen: () => {},
  viewId: null,
  setViewId: () => {},
  staffMeetings: [],
  get: () => {},
  remove: () => {},
})
export function StaffMettingProvider({ children }) {
  const [staffMeetings, setStaffMeetings] = useState([])
  const [addOpen, setAddOpen] = useState(false)
  const [viewId, setViewId] = useState(null)
  const [loading, setLoading] = useState(false)
  const get = () => {
    setLoading(true)
    getStaffMeetings()
      .then((res) => {
        setStaffMeetings(res.data.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const remove = (_id) => {
    setLoading(true)
    removeStaffMeeting({ _id })
      .then(() => {
        SuccessHelper.handleSuccess(i18n.t("successfully_deleted"), true)
        get()
      })
      .catch(() => {
        console.log("sdfsfsdf")
        ErrorHelper.handleErrors(i18n.t("failed_to_delete"), true)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <StaffMettingContext.Provider
      value={{
        addOpen,
        setAddOpen,
        viewId,
        setViewId,
        staffMeetings,
        get,
        remove,
      }}
    >
      <DotsLoader isloading={loading} />
      <CreateStaffMeeting
        addOpen={addOpen}
        setAddOpen={setAddOpen}
        setLoading={setLoading}
        get={get}
      />
      <UpdateStaffMeeting
        viewId={viewId}
        setViewId={setViewId}
        setLoading={setLoading}
      />
      {children}
    </StaffMettingContext.Provider>
  )
}

//Hook
function useStaffMeeting() {
  return useContext(StaffMettingContext)
}

export default useStaffMeeting
