import React from "react"
import i18n from "../../i18n"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import moment from "moment"
import { getNestedValue } from "../../util/objectMethods"

export default function InvoiceTable({ invoices = [] }) {
  const downloadPdf = (downloadUrl) => {
    window.open(downloadUrl, "_blank")
  }
  return (
    <div className="table-responsive">
      <table className="table table-borderless appointment-details-table mobile-max-content">
        <thead>
          <tr>
            <th scope="col">{i18n.t("date")}</th>
            <th scope="col">{i18n.t("status")}</th>
            <th scope="col">{i18n.t("amount")}</th>
            <th scope="col" style={{ width: "35px" }}>
              {i18n.t("download")}
            </th>
          </tr>
        </thead>
        <tbody>
          {invoices.map((val, index) => {
            const downloadUrl = getNestedValue(val, "pdfUrl", "")
            return (
              <tr
                key={val.pdfUrl}
                style={{
                  backgroundColor: index % 2 == 0 && "#F4F6F8",
                  borderTop: "1px solid #E5EBF1",
                  borderBottom:
                    invoices.length - 1 == index && "1px solid #E5EBF1",
                }}
              >
                <td>
                  <div className="d-flex align-items-center">
                    {moment.unix(val.created).format("MM-DD-YYYY HH:mmA")}
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    {i18n.t(val.status)}
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    {val.totalDue ? `$${(val.totalDue / 100).toFixed(2)}` : ""}
                  </div>
                </td>
                <td style={{ width: "35px" }}>
                  <div className="row justify-content-center">
                    {downloadUrl ? (
                      <FontAwesomeIcon
                        className="view-icon"
                        color="white"
                        icon={["fas", "file-download"]}
                        onClick={() => downloadPdf(downloadUrl)}
                      />
                    ) : null}
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
