// @flow
import Immutable from "seamless-immutable"
import * as types from "../actions/ActionTypes"

function ReducerBuilder() {
  const build = ({ type, actions = [] }) => {
    const initialState = Immutable({
      isFetching: false,
      errorMessage: "",
      data: [],
    })

    const handlerMap = {
      [types[type].REQUEST_FAILURE]: (state, action) => {
        return Immutable.merge(state, {
          isFetching: false,
        })
      },
      [types.LOGOUT]: () => {
        return initialState
      },
      [types.CLEAR_LOGOUT]: () => {
        return initialState
      },
    }

    actions.map((action) => {
      let _action = action
      if (typeof action == "string") {
        _action = {
          key: action,
        }
      }
      const sagaKey = `${type}_${_action.key}_SAGA`
      handlerMap[sagaKey] = (state, action) => {
        return Immutable.merge(state, {
          isFetching: true,
        })
      }

      let handler = () => {}
      if (_action.handler) {
        handler = _action.handler
      } else {
        if (_action.key == "GET") {
          handler = (state, action) => {
            return Immutable.merge(state, {
              isFetching: false,
              data: action?.data || [],
            })
          }
        } else if (_action.key == "UPSERT") {
          handler = (state, action) => {
            const newState = {
              ...state,
              isFetching: false,
              data: [...state.data],
            }
            const { _id } = action.data
            const index = state.data.findIndex((element) => element._id == _id)
            if (index != -1) {
              newState.data[index] = action.data
            } else {
              newState.data.push(action.data)
            }
            return newState
          }
        } else if (_action.key == "REMOVE") {
          handler = (state, action) => {
            const newState = {
              ...state,
              isFetching: false,
              data: [...state.data],
            }
            const { _id } = action.data
            const index = state.data.findIndex((element) => element._id == _id)
            if (index != -1) {
              newState.data.splice(index, 1)
            }
            return newState
          }
        }
      }
      handlerMap[`${type}_${_action.key}`] = handler
    })

    return (state = initialState, action) => {
      try {
        if (handlerMap[action.type]) {
          return handlerMap[action.type](state, action)
        }
        return state
      } catch (err) {
        console.error("Error in reducer", err)
        throw err
      }
    }
  }

  return {
    build,
  }
}

export default ReducerBuilder()
