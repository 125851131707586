import React, { useMemo } from "react"
import DotsLoader from "../DotsLoader"
import { connect } from "react-redux"
import { soldGiftCardRequest, getRequest } from "../../redux/actions/GiftCards"
import { useEffect } from "react"
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator"
import MenuItem from "@material-ui/core/MenuItem"
import PrimaryButton from "../Core/PrimaryButton"
import i18n from "../../i18n"
import { useState } from "react"
import { ErrorHelper, SuccessHelper } from "../../helpers"
import InsertPayment from "./components/InsertPayment.component"
import Autocomplete from "react-autocomplete"
import TextField from "@material-ui/core/TextField"
import FormHelperText from "@material-ui/core/FormHelperText"
import { IconButton } from "@material-ui/core"
import { TouchApp } from "@material-ui/icons"
import CustomerSearchUp from "../TouchCustomerSeachUp/CustomerSearchUp"
import useCache from "../../context/LocalCache"
import restoreConfirmation from "../../helpers/RestoreHelper"
import { deleteCustomer } from "../../config/simpleApiCalls"

const telephoneRegex = /[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]/g

const CreateGiftCardInvoice = ({
  close,
  loading,
  companies,
  companyTaxMap = {},
  getRequest,
  soldGiftCardRequest,
  scannerInput,
  isSalon,
}) => {
  const [email, setEmail] = useState("")
  const [phoneNo, setPhoneNo] = useState("")
  const [recipientEmail, setRecipientEmail] = useState("")
  const [recipientPhoneNo, setRecipientPhoneNo] = useState("")
  const [recipientName, setRecipientName] = useState("")
  const [selectedGiftCard, setSelectedGiftCard] = useState("")
  const [selectedGiftCardIndex, setSelectedGiftCardIndex] = useState("")
  const [companyGiftCards, setCompanyGiftCards] = useState([])
  const [company, setCompany] = useState("")
  const [name, setName] = useState("")
  const [giftCardBalance, setGiftCardBalance] = useState()
  const [taxes, setTaxes] = useState([])
  const [isAddTransactionOpen, setIsAddTransactionOpen] = useState(false)
  const [includeTaxes, setIncludeTaxes] = useState(true)
  const [phoneNoError, setPhoneNoError] = useState("")
  const [recipientPhoneNoError, setRecipientPhoneNoError] = useState("")
  const { getResource } = useCache()
  const [customerId, setCustomerId] = useState("")
  const employees = useMemo(() => {
    return getResource((cache) => cache.company.employees)
      .filter(({ isActive }) => isActive)
      .map((employee) => ({
        value: employee.employee._id,
        name: employee.employee.userId.firstName,
      }))
  }, [getResource((cache) => cache.employees)])
  const [employee, setEmployee] = useState()
  const [touchSearchOpen, setTouchSearchOpen] = useState(false)
  const [recipientTouchSearchOpen, setRecipientTouchSearchOpen] =
    useState(false)
  useEffect(() => {
    if (companies.length == 1) {
      onCompanySelect(companies[0]._id)
    }
  }, [])

  useEffect(() => {
    if (scannerInput) {
      const foundIndex = companyGiftCards.findIndex((companyPackage) => {
        return companyPackage.barCode == scannerInput
      })
      if (foundIndex !== -1) {
        setSelectedGiftCard(companyGiftCards[foundIndex])
        setSelectedGiftCardIndex(foundIndex)
      }
    }
  }, [scannerInput])

  const [customers, inactiveCustomers] = getResource((cache) => {
    let customers
    if (isSalon) {
      customers = cache.company.customers
    } else {
      customers = cache.employee.customers
    }
    return [
      customers.filter(({ isActive }) => isActive),
      customers.filter(({ isActive }) => !isActive),
    ]
  })

  const onCompanySelect = async (companyId) => {
    setCompany(companyId)
    setTaxes(companyTaxMap[companyId] ? companyTaxMap[companyId] : [])
    setCompanyGiftCards([])
    setSelectedGiftCard("")
    setSelectedGiftCardIndex("")
    getRequest(
      { companyId },
      {
        success: ({ data }) => {
          setCompanyGiftCards(data)
        },
      }
    )
  }
  const onSave = ({
    note,
    cash,
    creditDebit,
    giftCard,
    tip,
    discount,
    chargedAmountByCard = [],
  }) => {
    soldGiftCardRequest(
      {
        email,
        phoneNo,
        giftCardSelectedId: selectedGiftCard._id,
        name,
        note,
        cash,
        creditDebit,
        giftCard,
        includeTaxes,
        tip,
        discount,
        employeeId: employee,
        recipientEmail,
        recipientName,
        recipientPhoneNo,
        chargedAmountByCard,
      },
      {
        success: () => {
          close()
          SuccessHelper.handleSuccess(i18n.t("successfully_added"), true)
        },
        failure: () => {
          ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true)
        },
      }
    )
  }
  const validatePhoneNo = (phoneNo, setPhoneNoError) => {
    if (!phoneNo || !phoneNo.replace(/\s/g, "").match(telephoneRegex)) {
      setPhoneNoError(i18n.t("invalid_phone_number"))
      return
    }
    setPhoneNoError("")
    return true
  }
  const restoreRecipient = (inactiveRecipient) => {
    restoreConfirmation(
      () =>
        deleteCustomer({ ids: inactiveRecipient._id })
          .then((res) => {
            if (res.data.success) {
              inactiveRecipient.email &&
                setRecipientEmail(inactiveRecipient.email)
              setRecipientName(
                `${inactiveRecipient.firstName || ""} ${
                  inactiveRecipient.name
                }`.trim()
              )
              setIsAddTransactionOpen(true)
            } else if (!res.data.success) {
              ErrorHelper.handleErrors(res.data.msg, true)
            }
          })
          .catch((error) => {
            console.log(error, "errorrrrrrrrrrrrrrrrrrr")
          }),
      i18n.t("this_is_inactive", { name: i18n.t("recipient").toLowerCase() })
    )
  }
  const handleSubmit = () => {
    const inactiveBuyer = inactiveCustomers.find(
      (customer) => customer.phoneNo === phoneNo
    )
    const inactiveRecipient = inactiveCustomers.find(
      (customer) => customer.phoneNo === recipientPhoneNo
    )
    if (
      validatePhoneNo(phoneNo, setPhoneNoError) &&
      validatePhoneNo(recipientPhoneNo, setRecipientPhoneNoError) &&
      !inactiveBuyer &&
      !inactiveRecipient
    ) {
      setIsAddTransactionOpen(true)
    } else if (inactiveBuyer || inactiveCustomers) {
      if (inactiveBuyer) {
        restoreConfirmation(
          () =>
            deleteCustomer({ ids: inactiveBuyer._id })
              .then((res) => {
                if (res.data.success) {
                  inactiveBuyer.email && setEmail(inactiveBuyer.email)
                  setName(
                    `${inactiveBuyer.firstName || ""} ${
                      inactiveBuyer.name
                    }`.trim()
                  )
                  setGiftCardBalance(inactiveBuyer.giftCardBalance)
                  if (
                    !inactiveRecipient ||
                    inactiveBuyer._id === inactiveRecipient._id
                  ) {
                    inactiveRecipient.email &&
                      setRecipientEmail(inactiveRecipient.email)
                    setRecipientName(
                      `${inactiveRecipient.firstName || ""} ${
                        inactiveRecipient.name
                      }`.trim()
                    )
                    setIsAddTransactionOpen(true)
                  } else {
                    restoreRecipient(inactiveRecipient)
                  }
                } else if (!res.data.success) {
                  ErrorHelper.handleErrors(res.data.msg, true)
                }
              })
              .catch((error) => {
                console.log(error, "errorrrrrrrrrrrrrrrrrrr")
              }),
          i18n.t("this_is_inactive", { name: i18n.t("buyer").toLowerCase() })
        )
      } else if (inactiveRecipient) {
        restoreRecipient(inactiveRecipient)
      }
    }
  }
  const cummulativeTaxRate =
    taxes.reduce((accum, tax) => accum + tax.percentage, 0) / 100
  const items = [
    {
      name: selectedGiftCard.name,
      price: selectedGiftCard.price,
    },
  ]
  const subtotal = selectedGiftCard.price
  const { price = 0 } = selectedGiftCard
  const taxDue = cummulativeTaxRate * price
  const grandTotal = (1 + (includeTaxes ? cummulativeTaxRate : 0)) * price
  return (
    <>
      <DotsLoader isloading={loading} />
      <ValidatorForm
        onSubmit={handleSubmit}
        className="w-100"
        onError={() => {
          validatePhoneNo(phoneNo, setPhoneNoError)
          validatePhoneNo(recipientPhoneNo, setRecipientPhoneNoError)
        }}
      >
        <div className="row mx-0 px-0">
          <div className="col-lg-6 px-2">
            <h5 className="text-center mb-3">{i18n.t("buyer")}</h5>
            {employees.length ? (
              <SelectValidator
                className="mb-3"
                label={i18n.t("sold_by")}
                onChange={(e) => {
                  setEmployee(e.target?.value)
                }}
                value={employee}
                select
                variant="outlined"
              >
                {employees.map((employee) => {
                  return (
                    <MenuItem value={employee.value}>{employee.name}</MenuItem>
                  )
                })}
              </SelectValidator>
            ) : null}
            {companies.length && companies.length != 1 ? (
              <SelectValidator
                className="mb-3"
                label={i18n.t("company")}
                onChange={(e) => {
                  onCompanySelect(e.target?.value)
                }}
                value={company}
                select
                variant="outlined"
              >
                {companies.map((company) => {
                  return <MenuItem value={company._id}>{company.name}</MenuItem>
                })}
              </SelectValidator>
            ) : null}
            <SelectValidator
              className="mb-3"
              label={i18n.t("gift_card")}
              onChange={(e) => {
                setSelectedGiftCard(companyGiftCards[e.target?.value])
                setSelectedGiftCardIndex(e.target?.value)
              }}
              value={`${selectedGiftCardIndex}`}
              validators={["required"]}
              errorMessages={[i18n.t("gift_card_required")]}
              select
              variant="outlined"
            >
              {companyGiftCards.map((companyGiftCard, index) => {
                return (
                  <MenuItem value={`${index}`}>{companyGiftCard.name}</MenuItem>
                )
              })}
            </SelectValidator>
            <div className="row mx-0 align-items-center flex-nowrap mb-3">
              <Autocomplete
                placeholder={i18n.t("enter_phone_number_placeholder_type")}
                renderInput={(props) => {
                  return (
                    <TextField
                      id="phoneNumber"
                      label={i18n.t("container.employe_phone_number")}
                      error={!!phoneNoError}
                      {...props}
                      variant="outlined"
                    />
                  )
                }}
                items={
                  phoneNo
                    ? customers.map(
                        ({
                          name = "",
                          firstName = "",
                          phoneNo = "",
                          email = "",
                          giftCardBalance = 0,
                          userId,
                          isBlackListed,
                          _id,
                        }) => ({
                          name,
                          firstName,
                          phoneNo,
                          email,
                          giftCardBalance,
                          userId,
                          isBlackListed,
                          _id,
                        })
                      )
                    : []
                }
                shouldItemRender={(item, value) =>
                  item.phoneNo.toLowerCase().indexOf(value.toLowerCase()) >
                    -1 ||
                  item.name.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
                  item.firstName?.toLowerCase().indexOf(value.toLowerCase()) >
                    -1
                }
                getItemValue={(item) => item.phoneNo}
                renderItem={(item, highlighted) => (
                  <MenuItem key={item._id}>
                    {`${item.firstName ? item.firstName : ""} ${
                      item.name
                    }`.trim()}
                  </MenuItem>
                )}
                menuStyle={{
                  zIndex: "9998",
                  position: "absolute",
                  background: "white",
                  top: 64,
                  left: 0,
                }}
                wrapperStyle={{
                  width: "100%",
                }}
                renderMenu={(children) => {
                  return <div className="menu">{children.slice(0, 15)}</div>
                }}
                value={phoneNo}
                onChange={(e) => setPhoneNo(e.target.value)}
                onSelect={(
                  value,
                  { phoneNo, email, name, giftCardBalance, _id }
                ) => {
                  setCustomerId(_id)
                  setPhoneNo(phoneNo)
                  setEmail(email)
                  setName(name)
                  setGiftCardBalance(giftCardBalance)
                }}
              />
              <IconButton
                aria-label="search"
                className="ml-2 p-2"
                style={{ backgroundColor: "#3f51b5" }}
                onClick={() => setTouchSearchOpen(true)}
              >
                <TouchApp
                  fontSize="medium"
                  color="white"
                  style={{ color: "white" }}
                />
              </IconButton>
            </div>
            <CustomerSearchUp
              open={touchSearchOpen}
              setOpen={setTouchSearchOpen}
              customers={customers || []}
              onDone={(
                phoneNo,
                { email, firstName, name, giftCardBalance }
              ) => {
                setPhoneNo(phoneNo)
                setEmail(email)
                setName(`${firstName || ""} ${name}`.trim())
                setGiftCardBalance(giftCardBalance)
                setTouchSearchOpen(false)
              }}
            />
            {!!phoneNoError && (
              <FormHelperText error>{phoneNoError}</FormHelperText>
            )}
            <TextValidator
              className="mb-3"
              label={i18n.t("name")}
              onChange={(e) => setName(e.target.value)}
              value={name}
              validators={["required"]}
              errorMessages={[i18n.t("name_required")]}
              variant="outlined"
            />
            <TextValidator
              className="mb-3"
              label={i18n.t("email_to_invoice")}
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              validators={["required", "isEmail"]}
              errorMessages={[
                i18n.t("email_required"),
                i18n.t("invalid_email"),
              ]}
              variant="outlined"
            />
          </div>
          <div className="col-lg-6 px-2">
            <h5 className="text-center mb-3">{i18n.t("recipient")}</h5>
            <div className="row mx-0 align-items-center flex-nowrap mb-3">
              <Autocomplete
                placeholder={i18n.t("enter_phone_number_placeholder_type")}
                renderInput={(props) => {
                  return (
                    <TextField
                      id="phoneNumber"
                      label={i18n.t("container.employe_phone_number")}
                      error={!!recipientPhoneNoError}
                      {...props}
                      variant="outlined"
                    />
                  )
                }}
                items={
                  recipientPhoneNo
                    ? customers.map(
                        ({
                          name = "",
                          phoneNo = "",
                          firstName = "",
                          email = "",
                          userId,
                          isBlackListed,
                        }) => ({
                          name,
                          firstName,
                          phoneNo,
                          email,
                          userId,
                          isBlackListed,
                        })
                      )
                    : []
                }
                shouldItemRender={(item, value) =>
                  item.phoneNo.toLowerCase().indexOf(value.toLowerCase()) >
                    -1 ||
                  item.name.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
                  item.firstName?.toLowerCase().indexOf(value.toLowerCase()) >
                    -1
                }
                getItemValue={(item) => item.phoneNo}
                renderItem={(item, highlighted) => (
                  <MenuItem key={item._id}>
                    {`${item.firstName ? item.firstName : ""} ${
                      item.name
                    }`.trim()}
                  </MenuItem>
                )}
                menuStyle={{
                  zIndex: "9998",
                  position: "absolute",
                  background: "white",
                  top: 64,
                  left: 0,
                }}
                wrapperStyle={{
                  width: "100%",
                }}
                renderMenu={(children) => {
                  return <div className="menu">{children.slice(0, 15)}</div>
                }}
                value={recipientPhoneNo}
                onChange={(e) => setRecipientPhoneNo(e.target.value)}
                onSelect={(value, { phoneNo, firstName, email, name }) => {
                  setRecipientPhoneNo(phoneNo)
                  setRecipientEmail(email)
                  setRecipientName(`${firstName || ""} ${name}`.trim())
                }}
              />
              <IconButton
                aria-label="search"
                className="ml-2 p-2"
                style={{ backgroundColor: "#3f51b5" }}
                onClick={() => setRecipientTouchSearchOpen(true)}
              >
                <TouchApp
                  fontSize="medium"
                  color="white"
                  style={{ color: "white" }}
                />
              </IconButton>
            </div>
            <CustomerSearchUp
              open={recipientTouchSearchOpen}
              setOpen={setRecipientTouchSearchOpen}
              customers={customers || []}
              onDone={(phoneNo, { firstName, email, name }) => {
                setRecipientPhoneNo(phoneNo)
                setRecipientEmail(email)
                setRecipientName(`${firstName || ""} ${name}`.trim())
                setRecipientTouchSearchOpen(false)
              }}
            />
            {!!recipientPhoneNoError && (
              <FormHelperText error>{recipientPhoneNoError}</FormHelperText>
            )}
            <TextValidator
              className="mb-3"
              label={i18n.t("name")}
              onChange={(e) => setRecipientName(e.target.value)}
              value={recipientName}
              validators={["required"]}
              errorMessages={[i18n.t("name_required")]}
              variant="outlined"
            />
            <TextValidator
              className="mb-3"
              label={i18n.t("profile_email")}
              onChange={(e) => setRecipientEmail(e.target.value)}
              value={recipientEmail}
              validators={["required", "isEmail"]}
              errorMessages={[
                i18n.t("email_required"),
                i18n.t("invalid_email"),
              ]}
              variant="outlined"
            />
          </div>
          {selectedGiftCard ? (
            <div className="px-2 mt-2 row mx-0 flex-column justify-content-end col-lg-6">
              <table>
                <tr>
                  <td>{selectedGiftCard.name}</td>
                  <td className="text-right" style={{ whiteSpace: "nowrap" }}>
                    $ {subtotal.toFixed(2)}
                  </td>
                </tr>
                {cummulativeTaxRate && (
                  <tr>
                    <td>{i18n.t("tax_total")}</td>
                    <td className="text-right" style={{ whiteSpace: "nowrap" }}>
                      $ {taxDue.toFixed(2)}
                    </td>
                  </tr>
                )}
                <tr className="font-weight-bold">
                  <td>{i18n.t("grand_total")}</td>
                  <td className="text-right" style={{ whiteSpace: "nowrap" }}>
                    $ {grandTotal.toFixed(2)}
                  </td>
                </tr>
              </table>
            </div>
          ) : null}
        </div>
        <div className="row mx-0 px-0 justify-content-end my-4">
          {selectedGiftCard ? (
            <InsertPayment
              defaultAmount={grandTotal}
              open={isAddTransactionOpen}
              onSubmit={onSave}
              handleClose={() => setIsAddTransactionOpen(false)}
              toggleTaxes={() => setIncludeTaxes(!includeTaxes)}
              items={items}
              subtotal={selectedGiftCard.price}
              taxDue={taxDue}
              includeTaxes={includeTaxes}
              grandTotal={grandTotal}
              giftCardBalance={giftCardBalance}
              type="gift_card"
              customerId={customerId}
            />
          ) : null}
          <PrimaryButton
            props={{
              type: "submit",
            }}
            label={i18n.t("collect_payment")}
          />
        </div>
      </ValidatorForm>
    </>
  )
}

const mapStateToProps = (state) => ({
  loading: state.giftCards.isFetching,
})

const action = { soldGiftCardRequest, getRequest }

export default connect(mapStateToProps, action)(CreateGiftCardInvoice)
