import { TableCell, TableRow } from "@material-ui/core"
import moment from "moment"
import React from "react"
import { useSelector } from "react-redux"
import BasicTable from "../../../../components/Core/BasicTable"
import i18n from "../../../../i18n"
export default function ActivityAttendanceReportTables() {
  const categoryReport = useSelector(
    (s) => s.activityScheduleEvent.attendanceReport
  )

  return (
    <div className="d-flex flex-column gap-1">
      <BasicTable
        prependRows={
          <TableRow>
            <TableCell colSpan={9}>
              <div>{categoryReport.length} Results</div>
            </TableCell>
          </TableRow>
        }
        rows={categoryReport}
        schema={[
          {
            header: i18n.t("date"),
            field: "date",
            accessor: ({ startDate }) => {
              const momentedStart = moment(startDate)
              return momentedStart.format("MM-DD-YYYY hh:mm a")
            },
          },
          {
            header: i18n.t("activity"),
            field: "activity",
            accessor: ({ activityId }) => {
              return activityId.name
            },
          },
          {
            header: i18n.t("attended"),
            field: "attended",
            accessor: ({ customer }) => {
              return i18n.t(customer.attended ? i18n.t("yes") : i18n.t("no"))
            },
          },
          {
            header: i18n.t("name"),
            field: "name",
            accessor: ({ customer }) => {
              return (`${customer._id.firstName || ""} ${customer._id.name}`).trim()
            },
          },
          {
            header: i18n.t("phone_number"),
            field: "phoneNo",
            accessor: ({ customer }) => {
              return customer._id.phoneNo
            },
          },
          {
            header: i18n.t("container.profile_email"),
            field: "email",
            accessor: ({ customer }) => {
              return customer._id.email
            },
          },
        ]}
      />
    </div>
  )
}
