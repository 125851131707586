import { before } from "lodash"
import styled from "styled-components/macro"

export const CheckboxForm = styled.form`
  display: flex;
  align-items: flex-start;

  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
  }
`
export const CheckboxLabel = styled.label`
  margin-left: 1em;
  margin-bottom: 0;
`

export const EmployeeHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2em;

  @media (max-width: 480px) {
    gap: 1em;
    flex-wrap: wrap;
  }
`

export const CheckboxInput = styled.input`
  appearance: none;
  width: 4em;
  height: 2em;
  border: 1px solid #ccc;
  outline: none;
  border-radius: 30px;
  transition: 0.3s;
  cursor: pointer;
  &:before {
    content: "";
    position: absolute;
    top: 1.9em;
    left: 2em;
    width: 1.1em;
    height: 1.1em;
    background: #cccccc;
    border-radius: 50%;
    transition: 0.3s ease-in-out;
    @media (max-width: 480px) {
      top: 1em;
      left: 1em;
    }
  }
  &:checked:before {
    transform: translateX(1.9em);
    background: #3399ff;

    @media (max-width: 480px) {
      transform: translateX(1.9em);
    }
  }
  &:checked {
    border-color: #3399ff;
  }
`
export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 1.5em;
  position: relative;

  @media (max-width: 480px) {
    padding: 0.5em;
    flex-direction: column;
    align-items: start;
  }
  /* grid-column: ${(props) => {
    return props.fieldName == "displayWorkingHours" && "2/-1"
  }}; */

  /* width: ${(props) => {
    return props.fieldName == "displayWorkingHours" && "49%"
  }}; */
`

export const WorkingDay = styled.div`
  display: grid;
  grid-template-columns: 2fr 0.5fr 0.5fr 0.5fr;
  align-items: center;
  padding: 0.5em 0;
  gap: 1em;
  @media (max-width: 875px) {
    grid-template-columns: 120px 100px 120px 120px;
    gap: 0.5rem;
  }
`

export const ServicesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    width: max-content;
  }
`

export const WorkingDaysWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  @media (max-width: 875px) {
    width: max-content;
  }
`
