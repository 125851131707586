import React, { useEffect, useState } from "react"
import TouchKeyPad from "../../TouchKeyPad/TouchKeyPad"
import i18n from "../../../i18n"
import TouchChip from "../../Partner/CreateAppointment/Support/TouchChip"
import ThreeDButton from "../../Core/3DButton/ThreeDButton"
import { ErrorHelper } from "../../../helpers"
import Swal from "sweetalert2"
import Select from "../../Core/Select/index.jsx"
import {
  getRequest,
  removeRequest,
  updateRequest,
  insertRequest,
  setDefaultRequest,
} from "../../../redux/actions/CompanyCustomerCard"
import { connect } from "react-redux"
import CardManagement from "../../Card/CardManagment"
const CollectKeyPad = ({
  onSubmit,
  giftCardRemaining,
  setGiftCardRemaining,
  cards = [],
  open = false,

  customerId,
  getRequest,
  removeRequest,
  updateRequest,
  insertRequest,
  defaultCard,

  setDefaultRequest,
}) => {
  const [type, setType] = useState("cash")
  const [value, setValue] = useState(0)
  const [showCards, setShowCards] = useState(false)
  const [selectedCard, setSelectedCard] = useState(null)
  const [showInsertCard, setShowInsertCard] = useState(false)
  useEffect(() => {
    if (!open) {
      setValue(0)
      setType("cash")
      onSubmit([])
    }
  }, [open])
  useEffect(() => {
    if (cards.length > 0 && type == "credit_debit") {
      setShowCards(true)
    } else {
      setShowCards(false)
    }
  }, [cards])
  useEffect(() => {
    getRequest({ _id: customerId })
  }, [customerId])
  useEffect(() => {
    if (type == "credit_debit") {
      Swal.fire({
        title:
          cards.length > 0
            ? i18n.t("use_saved_card")
            : i18n.t("do_you_want_to_save_card"),
        cancelButtonText: i18n.t("no"),
        showCancelButton: true,
        confirmButtonColor: "#ff3600",
        cancelButtonColor: "#354e68",
        confirmButtonText: i18n.t("yes"),
      }).then((result) => {
        if (result.value) {
          if (cards.length > 0) {
            setShowCards(true)
          } else {
            setShowInsertCard(true)
          }
        }
      })
    } else {
      setShowCards(false)
      setSelectedCard(null)
    }
  }, [type])
  const handleSubmit = () => {
    if (type == "gift_card" && value > giftCardRemaining) {
      ErrorHelper.handleErrors(
        i18n.t("must_be_less_or_equal", {
          amount: giftCardRemaining.toFixed(2),
        }),
        true
      )
    } else if (showCards && !selectedCard) {
      ErrorHelper.handleErrors(i18n.t("select_card"), true)
    } else if (showCards && selectedCard && value < 0.6) {
      ErrorHelper.handleErrors(
        i18n.t("must_be_greater_or_equal", {
          amount: (0.6).toFixed(2),
        }),
        true
      )
    } else if (value) {
      const data = {
        method: type,
        amount: value,
      }
      if (selectedCard) {
        data.cardId = selectedCard
      }

      onSubmit((prevEntries) => [...prevEntries, data])
      setValue(0)
      if (type == "gift_card") {
        setGiftCardRemaining(giftCardRemaining - value)
      }
    }
  }
  return (
    <>
      {showInsertCard ? (
        <CardManagement
          removeDefault={false}
          defaultCard={defaultCard}
          cards={cards}
          getRequest={getRequest}
          removeRequest={removeRequest}
          updateRequest={updateRequest}
          insertRequest={insertRequest}
          setDefaultRequest={setDefaultRequest}
          showAddModal={showInsertCard}
          setShowAddModal={(val) => setShowInsertCard(val)}
          id={customerId}
        />
      ) : null}
      <div className="d-sm-flex mx-0" style={{ flexWrap: "wrap" }}>
        <div className="col-sm-6 px-sm-1 mb-2">
          <TouchChip
            label={i18n.t("cash")}
            onClick={() => setType("cash")}
            selected={type == "cash"}
          />
        </div>
        <div className="col-sm-6 px-sm-1 mb-2">
          <TouchChip
            label={i18n.t("credit_debit")}
            onClick={() => {
              setType("credit_debit")
            }}
            selected={type == "credit_debit"}
          />
        </div>
        {giftCardRemaining ? (
          <div className="col-sm-6 px-sm-1 mb-2">
            <TouchChip
              label={`${i18n.t("gift_card")} ($ ${giftCardRemaining.toFixed(
                2
              )})`}
              onClick={() => setType("gift_card")}
              selected={type == "gift_card"}
            />
          </div>
        ) : null}
      </div>
      {showCards && cards.length ? (
        <div className="my-3 px-2">
          <Select
            variant="outlined"
            label={i18n.t("card")}
            onChange={(e) => setSelectedCard(e.target.value)}
            options={cards.map((card) => ({
              name: card.card.last4,
              value: card.id,
            }))}
          />
        </div>
      ) : null}

      <TouchKeyPad
        value={value}
        setValue={(value) => {
          setValue(value ? parseFloat(value) : 0)
        }}
      />
      <div className="d-flex justify-content-end">
        <div className="col-4 p-2">
          <ThreeDButton
            onClick={handleSubmit}
            wrapperStyle={{ height: 34, fontSize: 16, borderRadius: 7 }}
          >
            {i18n.t("submit")}
          </ThreeDButton>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    cards: state.companyCustomerCard.data,
    defaultCard: state.companyCustomerCard.defaultCard,
  }
}

const action = {
  getRequest,
  removeRequest,
  updateRequest,
  insertRequest,

  setDefaultRequest,
}

export default connect(mapStateToProps, action)(CollectKeyPad)
