import React, { useEffect, useMemo, useRef, useState, useCallback } from "react"
import { getRequest as getCustomerGroups } from "../../../redux/actions/Saloon/CompanyCustomerGroups"
import {
  getRequest as getSchedule,
  applyTemplateRequest,
  upsertEventRequest,
  setCustomersRequest,
  removeEventRequest,
  setAttendanceRequest,
} from "../../../redux/actions/Saloon/ActivityScheduleEvent"
import scrollGridPlugin from "@fullcalendar/scrollgrid"
import {
  getEmployeeDetails,
  getSaloonSchedule,
} from "../../../config/simpleApiCalls"
import { getRequest as getTemplates } from "../../../redux/actions/Saloon/ActivityScheduleTemplate"
import ActivityActions from "../../../redux/actions/Saloon/Activity"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import FullCalendar from "@fullcalendar/react"
import interactionPlugin from "@fullcalendar/interaction"
import timeGridPlugin from "@fullcalendar/timegrid"
import dayGridPlugin from "@fullcalendar/daygrid"
import resourceTimeline from "@fullcalendar/resource-timeline"
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid"
import resourceDayGridPlugin from "@fullcalendar/resource-daygrid"
import listPlugin from "@fullcalendar/list"
import useActiveMenuType from "../../../context/ActiveMenuType"
import "./ActivityScheduleBuilder.scss"
import UpsertScheduleEvent from "./Components/UpsertScheduleEvent/UpsertScheduleEvent"
import i18n from "../../../i18n"
import { ErrorHelper, SuccessHelper } from "../../../helpers"
import deleteConfirmation from "../../../helpers/DeleteHelper"
import ReactDatePicker from "react-datepicker"
import { IconButton, TextField } from "@material-ui/core"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import ApplyTemplate from "./Components/ApplyTemplate"
import useCache from "../../../context/LocalCache"
import { BaseModal, DotsLoader } from "../../../components"
import PrimaryButton from "../../../components/Core/PrimaryButton"
import SecondaryButton from "../../../components/Core/SecondaryButton/SecondaryButton"
import { v4 as uuidv4 } from "uuid"
import useGoogleCalendar, {
  googleButton,
} from "../../../context/GoogleCalendar"
import useOutlookCalendar, {
  outLookButton,
} from "../../../context/OutlookCalendar"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import SharedStorage from "../../../helpers/Storage"
import useScreenSize from "../../../context/ScreenSize"
const { getRequest: getActivities } = ActivityActions

const getContrastYIQ = (hexcolor) => {
  if (hexcolor) {
    let color = hexcolor.replace("#", "")
    const r = parseInt(color.substr(0, 2), 16)
    const g = parseInt(color.substr(2, 2), 16)
    const b = parseInt(color.substr(4, 2), 16)
    const yiq = (r * 299 + g * 587 + b * 114) / 1000
    return yiq >= 128 ? "black" : "white"
  }
  return "#000"
}

export default function ActivityScheduleBuilder() {
  const dispatch = useDispatch()
  const { getResource } = useCache()
  const allServices = getResource((cache) => cache.company.services)
  const calendarRef = useRef(null)
  const employees = getResource((cache) => cache.company.employees)
  const filteredEmployees = useMemo(() =>
    employees.filter(({ isActive }) => isActive)
  )
  const [defaults, setDefaults] = useState({})
  const [addEventOpen, setAddEventOpen] = useState(false)
  const [applyTemplateOpen, setApplyTemplateOpen] = useState(false)
  const [employeeDetails, setemployeeDetails] = useState()
  const [start, setStart] = useState("00")
  const [end, setEnd] = useState("24:00:00")
  const { type, functionRole } = useActiveMenuType()
  const isSalon = useMemo(() => {
    return type && type != "employee"
  }, [type])
  const [index, setIndex] = useState()
  const [active, setActive] = useState()
  const [date, setDate] = useState(new Date())
  const [openTab, setOpenTab] = useState()
  const events = useSelector((s) => s.activityScheduleEvent.data)
  const activities = useSelector((s) => s.activity.data)
  const calViews = ["day", "work week", "week", "month"]
  const [visibleWeekEnds, setVisibleWeekends] = useState(true)
  const [selectedCalView, setSelectedCalView] = useState("day")
  const [calendarView, setCalendarView] = useState(selectedCalView)
  const [calView, setCalView] = useState(() => {
    if (selectedCalView == "day")
      return isSalon && functionRole != "garage"
        ? "resourceTimeGrid"
        : "timeGridDay"
    else if (selectedCalView == "month") return "dayGridMonth"
    else if (selectedCalView == "work week") return "timeGridWorkWeek"
    else return "timeGridWeek"
  })
  const [openFiltersModal, setOpenFiltersModal] = useState(false)
  const [openOptionsModal, setOpenOptionsModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const {
    googleEvents,
    setViewGoogleEvent,
    setTimeMin,
    setTimeMax,
    setEmployeeForGoogle,
  } = useGoogleCalendar()
  const {
    outlookEvents,
    setViewOutlookEvent,
    setEmployeeForOutlook,
    setMinStartTime,
    setMaxStartTime,
  } = useOutlookCalendar()
  const [listView, setListView] = useState(false)
  const { screenSize } = useScreenSize()
  useEffect(async () => {
    setEmployeeForGoogle(JSON.parse(await SharedStorage.getItem("employee")))
    setEmployeeForOutlook(JSON.parse(await SharedStorage.getItem("employee")))
    setSelectedCalView(
      JSON.parse(await SharedStorage.getItem("employee"))?.selectedCalView ||
        "day"
    )
  }, [])
  useEffect(() => {
    setIndex(calViews.indexOf(selectedCalView))
  }, [selectedCalView])
  const [activitySessionData, setActivitySessionData] = useState({})
  const resources = useMemo(() => {
    return filteredEmployees.map((employee) => ({
      id: employee.employeeId._id,
      title: `${employee.user.firstName} ${employee.user.lastName}`.trim(),
    }))
  }, [filteredEmployees])

  useEffect(async () => {
    if (!isSalon) {
      getEmployeeDetails({
        user: JSON.parse(await SharedStorage.getItem("employee")),
      }).then(({ data }) => {
        setemployeeDetails(data.data)
      })
    }
  }, [isSalon])
  useEffect(() => {
    dispatch(getCustomerGroups({}))
    dispatch(getTemplates())
    dispatch(getActivities())

    getSaloonSchedule()
      .then((res) => {
        if (res.data.success && res.data.data) {
          let start = undefined
          let end = undefined
          res.data.data.weekPlans.map((dayPlan) => {
            const { checkIn, checkOut } = dayPlan

            if (checkIn) {
              let hour = parseInt(checkIn.split(":")[0])
              if (start) {
                if (start > hour) {
                  start = hour
                }
              } else {
                start = hour
              }
            }
            if (checkOut) {
              let hour = checkOut
              if (end) {
                if (end < hour) {
                  end = hour
                }
              } else {
                end = hour
              }
            }
          })
          setEnd(end)
          setStart(start)
        }
      })
      .catch((error) => {
        console.log(error, "error")
      })
  }, [])

  useEffect(() => {
    if (!addEventOpen) {
      setActive()
    }
  }, [addEventOpen])

  useEffect(() => {
    setCalendarView(selectedCalView)
  }, [selectedCalView])

  useEffect(() => {
    handleCalendarView(calendarView)
  }, [listView, calendarView])
  const handleCalendarView = (value) => {
    setCalendarView(value)
    switch (value) {
      case "day":
        setCalView(
          listView ? "listDay" : isSalon ? "resourceTimeGrid" : "timeGridDay"
        )
        break
      case "work week":
        setCalView(listView ? "listWorkWeek" : "timeGridWorkWeek")
        break
      case "week":
        setCalView(listView ? "listWeek" : "timeGridWeek")
        break
      case "month":
        setCalView(listView ? "listMonth" : "dayGridMonth")
        break
      default:
        setCalView(listView ? "listWorkWeek" : "timeGridWorkWeek")
        break
    }
  }

  const [employeeMap = {}, workingDayUnavailabilities] = useMemo(() => {
    const map = {}
    const workingDayUnavailabilities = []
    filteredEmployees.map((employee) => {
      const tempDate = moment(date)
      map[employee._id] = employee
      if (employee.displayWorkingHours) {
        employee.weekPlans.map((workingDay, index) => {
          const formatted = tempDate.day(index).format("MM-DD-YYYY")
          const common = {
            date,
            companyEmployeeId: employee._id,
            allDay: false,
            type: "unavailability",
            className: "unavailability-event",
          }
          if (workingDay?.availableStatus) {
            workingDayUnavailabilities.push({
              start: moment(`${formatted} 00:00`, "MM-DD-YYYY HH:mmm").toDate(),
              end: moment(
                `${formatted} ${workingDay.checkIn}`,
                "MM-DD-YYYY HH:mmm"
              ).toDate(),
              ...common,
            })
            workingDayUnavailabilities.push({
              start: moment(
                `${formatted} ${workingDay.checkOut}`,
                "MM-DD-YYYY HH:mmm"
              ).toDate(),
              end: moment(`${formatted} 23:59`, "MM-DD-YYYY HH:mmm").toDate(),
              ...common,
            })
          } else {
            workingDayUnavailabilities.push({
              start: moment(`${date} 00:00`, "MM-DD-YYYY HH:mmm").toDate(),
              end: moment(`${date} 23:59`, "MM-DD-YYYY HH:mmm").toDate(),
              ...common,
            })
          }
        })
      }
    })
    return [map, workingDayUnavailabilities]
  }, [filteredEmployees])
  const renderEventContent = useCallback((eventInfo) => {
    const { timeText, event, view } = eventInfo
    const employeeId =
      event._def.extendedProps?.companyEmployeeId ||
      event._def.extendedProps.event?.companyEmployeeId
    const activityId = event._def.extendedProps.event?.activityId
    const type = event._def.extendedProps.type
    const activity = activities?.find((activity) => activity._id == activityId)
    const monthViewStyles = {
      backgroundColor:
        event.backgroundColor != "null" ? event.backgroundColor : "#3788d8",
      color: event.textColor ? event.textColor : "white",
      fontSize: "0.8rem",
      whiteSpace: "normal",
    }
    const googleEventName = event._def.extendedProps.summary
    const outLookEventName = event._def.extendedProps.subject
    const monthViewPosition = view.type == "dayGridMonth" ? "0" : ""

    return (
      <>
        <div
          className={`text-wrapper w-100 ${
            view.type == "dayGridMonth"
              ? `p-1 ${type === "unavailability" && "unavailability-event"}`
              : ""
          }`}
          style={
            type !== "unavailability" ||
            (type === "unavailability" && view.type == "dayGridMonth")
              ? monthViewStyles
              : null
          }
        >
          {type == "outlookCalendar" ? (
            <FontAwesomeIcon
              icon={["fab", "microsoft"]}
              className="notes-icon"
              style={{
                color: "white",
                backgroundColor: "#7FBA00",
                padding: "3px",
                right: monthViewPosition,
                bottom: monthViewPosition,
                width: view.type == "dayGridMonth" ? "1.3em" : "",
              }}
            />
          ) : null}
          {type == "googleCalendar" ? (
            <FontAwesomeIcon
              icon={["fab", "google"]}
              className="notes-icon"
              style={{
                color: "white",
                backgroundColor: "#4285F4",
                padding: "3px",
                right: monthViewPosition,
                bottom: monthViewPosition,
                width: view.type == "dayGridMonth" ? "1.3em" : "",
              }}
            />
          ) : null}
          {timeText}
          <br />
          {employeeMap[employeeId]?.employeeId?.userId?.firstName}
          {!activityId ? (
            `
             - ${googleEventName || outLookEventName || i18n.t("unavailable")}`
          ) : (
            <b>{` - ${activity?.name}`}</b>
          )}
        </div>
      </>
    )
  })
  const formattedEvents = useMemo(() => {
    const unavailability = listView ? [] : workingDayUnavailabilities

    return [
      ...events
        .map((event) => {
          const startDateTime = moment(
            moment(event.startDate).format("MM-DD-YYYY HH:mm")
          )
          const endDateTime = moment(
            moment(event.endDate).format("MM-DD-YYYY HH:mm")
          )
          const companyEmployee =
            employeeMap[event.companyEmployeeId] ||
            employeeDetails?.companyEmployee
          const color = companyEmployee?.color || "#000"
          return {
            title: companyEmployee?.employeeId?.userId?.firstName,
            start: startDateTime.toDate(),
            end: endDateTime.toDate(),
            backgroundColor: color,
            textColor: getContrastYIQ(color),
            resourceId: companyEmployee?.employeeId._id,
            event: {
              ...event,
            },
          }
        })
        .filter((event) => {
          if (isSalon) {
            return !!event
          } else if (
            event.event.companyEmployeeId ==
            employeeDetails?.companyEmployee._id
          ) {
            return !!event
          }
        }),
      ...unavailability.map((element) => {
        const companyEmployee = employeeMap[element.companyEmployeeId]
        const color = companyEmployee?.color || "#000"
        return {
          ...element,
          textColor: getContrastYIQ(color),
          color: color,
          resourceId: companyEmployee?.employeeId._id,
          title: ` ${companyEmployee?.employeeId?.userId?.firstName} - ${i18n.t(
            "unavailable"
          )}`,
        }
      }),
      ...googleEvents,
      ...outlookEvents,
    ]
  }, [
    events,
    employeeMap,
    workingDayUnavailabilities,
    employeeDetails,
    googleEvents,
    outlookEvents,
  ])
  const onEventMouseEnter = (e) => {}
  const onEventMouseLeave = (e) => {}
  const onEvenClick = (e) => {
    const props = e.event._def.extendedProps
    if (props.type == "googleCalendar") {
      setViewGoogleEvent(props)
    } else if (props.type == "outlookCalendar") {
      setViewOutlookEvent(props)
    } else if (props.type !== "unavailability") {
      setAddEventOpen(true)
      setActive(props.event)
    }
  }
  const onAddEvent = (data, sessions) => {
    setLoading(true)
    const sessionsData = Object.values(sessions)
    if (sessionsData.length) {
      data.activityReference = uuidv4()
      sessionsData.map((session, i) => {
        data.startDate = moment(
          `${moment(session.date).format("MM-DD-YYYY")} ${session.startTime}`,
          "MM-DD-YYYY HH:mm"
        ).toDate()
        data.endDate = moment(
          `${moment(session.date).format("MM-DD-YYYY")} ${session.endTime}`,
          "MM-DD-YYYY HH:mm"
        ).toDate()
        dispatch(
          upsertEventRequest(
            {
              ...data,
            },
            {
              success: ({ data }) => {
                if (i == 0) {
                  SuccessHelper.handleSuccess(
                    i18n.t("successfully_added"),
                    true
                  )
                  setActive(data)
                  setOpenTab(i18n.t("customer"))
                  setLoading(false)
                }
              },
              failure: () => {
                ErrorHelper.handleErrors(i18n.t("failed_to_add"), true)
                setLoading(false)
              },
            }
          )
        )
      })
    } else {
      const { date, startTime, endTime, _id } = data
      data.startDate = moment(
        `${moment(date).format("MM-DD-YYYY")} ${startTime}`,
        "MM-DD-YYYY HH:mm"
      ).toDate()
      data.endDate = moment(
        `${moment(date).format("MM-DD-YYYY")} ${endTime}`,
        "MM-DD-YYYY HH:mm"
      ).toDate()
      dispatch(
        upsertEventRequest(
          {
            ...data,
          },
          {
            success: ({ data }) => {
              SuccessHelper.handleSuccess(i18n.t("successfully_added"), true)
              setActive(data)
              if (!_id) {
                setOpenTab(i18n.t("customer"))
              }
              setLoading(false)
            },
            failure: () => {
              ErrorHelper.handleErrors(i18n.t("failed_to_add"), true)
              setLoading(false)
            },
          }
        )
      )
    }
  }

  const onDeleteEvent = (id) => {
    setLoading(true)
    deleteConfirmation(() => {
      dispatch(
        removeEventRequest(
          { id },
          {
            success: () => {
              SuccessHelper.handleSuccess(i18n.t("successfully_deleted"), true)
              setAddEventOpen(false)
              setLoading(false)
            },
            failure: () => {
              ErrorHelper.handleErrors(i18n.t("failed_to_delete"), true)
              setLoading(false)
            },
          }
        )
      )
    })
  }

  const onApply = (activityScheduleTemplateId) => {
    setLoading(true)
    dispatch(
      applyTemplateRequest(
        { activityScheduleTemplateId, startDate: date },
        {
          success: () => {
            SuccessHelper.handleSuccess(i18n.t("applied_template"), true)
            setApplyTemplateOpen(false)
            setLoading(false)
          },
          failure: () => {
            ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true)
            setLoading(false)
          },
        }
      )
    )
  }

  const onSetCustomers = (toAdd, toRemove, setOpen, data) => {
    setLoading(true)
    const sessionEnrollement = data._id == active._id

    dispatch(
      setCustomersRequest(
        { _id: sessionEnrollement ? active._id : data._id, toAdd, toRemove },
        {
          success: (data) => {
            SuccessHelper.handleSuccess(i18n.t("successfully_updated"), true)
            if (sessionEnrollement) {
              setActive(data.data)
            } else {
              setActivitySessionData(data.data)
            }
            setOpen(false)
            setLoading(false)
          },
          failure: () => {
            ErrorHelper.handleErrors(i18n.t("failed_to_update"), true)
            setLoading(false)
          },
        }
      )
    )
  }

  const onSetAttendance = (attendanceMap, activeSession) => {
    setLoading(true)
    dispatch(
      setAttendanceRequest(
        { _id: activeSession._id || active._id, attendanceMap },
        {
          success: (data) => {
            SuccessHelper.handleSuccess(i18n.t("successfully_updated"), true)
            if (!activeSession) {
              setActive(data.data)
            } else {
              setActivitySessionData(data.data)
            }
            setLoading(false)
          },
          failure: () => {
            ErrorHelper.handleErrors(i18n.t("failed_to_update"), true)
            setLoading(false)
          },
        }
      )
    )
  }

  const onArrowClick = (forward) => {
    if (calendarView === "month") {
      setDate(
        moment(date)
          .add(forward ? 1 : -1, "months")
          .startOf("month")
          .toDate()
      )
    } else {
      const displacement = calendarView == "day" ? 1 : 7
      setDate(
        moment(date)
          .add((forward ? 1 : -1) * displacement, "d")
          .toDate()
      )
    }
  }
  useEffect(() => {
    if (date) {
      if (calendarRef.current) {
        if (calendarView === "work week") {
          setVisibleWeekends(false)
        } else {
          setVisibleWeekends(true)
        }
        try {
          calendarRef.current
            .getApi()
            .changeView(
              calendarView === "work week"
                ? listView
                  ? "listWeek"
                  : "timeGridWeek"
                : calView,
              date
            )
        } catch (error) {
          console.log(error)
        }
      }
      let start
      let end
      if (calendarView === "month") {
        start = moment(date).startOf("month").toDate()
        end = moment(date).endOf("month").toDate()
      } else if (calendarView === "day") {
        start = moment(date).startOf("day").toDate()
        end = moment(date).endOf("day").toDate()
      } else {
        start = moment(date).startOf("week").toDate()
        end = moment(date).endOf("week").toDate()
      }
      setMinStartTime(moment(date).startOf("month").toISOString())
      setMaxStartTime(moment(date).endOf("month").toISOString())

      setTimeMin(moment(date).startOf("month").toISOString())
      setTimeMax(moment(date).endOf("month").toISOString())

      dispatch(getSchedule({ start, end }))
    }
  }, [date, calView, calendarView, addEventOpen])

  const renderFilters = () => {
    return (
      <>
        <div className="d-flex align-items-center gap-1">
          <IconButton
            aria-label="previous"
            className="arrow-btn"
            onClick={() => onArrowClick()}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <ReactDatePicker
            selected={date}
            dateFormat="MM/dd/yyyy"
            onChange={(val) => {
              setDate(val)
            }}
            customInput={
              <TextField
                value={date}
                className="full-width"
                readOnly={true}
                variant="outlined"
              />
            }
          />
          <IconButton
            aria-label="next"
            className="arrow-btn"
            onClick={() => onArrowClick(true)}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </div>
        <div className="d-flex align-items-center gap-1">
          {calViews.map((view, i) => {
            return (
              <div
                style={{ minWidth: "fit-content" }}
                name={i18n.t(view)}
                onClick={() => {
                  handleCalendarView(view)
                  setIndex(i)
                }}
              >
                <SecondaryButton
                  defaultValue={selectedCalView}
                  className={`${index === i && "secondary-active active"}`}
                >
                  {i18n.t(view)}
                </SecondaryButton>
              </div>
            )
          })}
        </div>
      </>
    )
  }
  const renderButtons = () => {
    return (
      <>
        <div id="sync_button_wrapper" className="row gap-1">
          {outLookButton()}
          {googleButton()}
        </div>
        {screenSize !== "mobile" ? (
          <PrimaryButton
            label={listView ? i18n.t("calendar") : i18n.t("list")}
            onClick={() => {
              setListView(!listView)
            }}
            className="ml-1"
          />
        ) : null}

        <PrimaryButton
          className="ml-1"
          label={i18n.t("apply_template")}
          onClick={() => {
            setApplyTemplateOpen(true)
          }}
        />
        <PrimaryButton
          className="ml-1"
          label={i18n.t("add_event")}
          onClick={() => {
            setAddEventOpen(true)
          }}
        />
      </>
    )
  }
  return (
    <div className="content-container p-4">
      <DotsLoader isloading={loading} />
      <div className="row align-items-center justify-content-between mx-0 ScheduleTemplateBuilder mb-4">
        <h1 className="saloon-dashboard-heading">{i18n.t("schedule")}</h1>
      </div>
      <div className="row mx-0 ScheduleBuilder">
        <UpsertScheduleEvent
          openTab={openTab}
          setOpenTab={setOpenTab}
          onAdd={onAddEvent}
          allServices={allServices}
          employees={filteredEmployees}
          employeeDetails={employeeDetails}
          open={addEventOpen}
          setOpen={setAddEventOpen}
          defaults={defaults}
          active={active}
          setActive={setActive}
          onDelete={onDeleteEvent}
          onSetCustomers={onSetCustomers}
          onSetAttendance={onSetAttendance}
          setActivitySessionData={setActivitySessionData}
          activitySessionData={activitySessionData}
        />
        <ApplyTemplate
          open={applyTemplateOpen}
          setOpen={setApplyTemplateOpen}
          onApply={onApply}
        />

        <div className="d-sm-flex TopBar justify-content-between w-100 mb-3">
          {openFiltersModal ? (
            <BaseModal
              open={openFiltersModal}
              onClose={() => setOpenFiltersModal(false)}
              title={i18n.t("container.advancedSearchDialog_filter")}
              content={
                <div className="d-flex flex-column gap-2">
                  {renderFilters()}
                  <PrimaryButton
                    label={listView ? i18n.t("calendar") : i18n.t("list")}
                    onClick={() => {
                      setListView(!listView)
                    }}
                    className="ml-1"
                  />
                </div>
              }
            />
          ) : null}
          {openOptionsModal ? (
            <BaseModal
              open={openOptionsModal}
              onClose={() => setOpenOptionsModal(false)}
              title="Options"
              content={
                <div className="d-flex flex-column gap-2">
                  {renderButtons()}
                </div>
              }
            />
          ) : null}

          <div className="d-sm-flex align-items-center gap-1">
            {screenSize !== "mobile" ? (
              <>{renderFilters()}</>
            ) : (
              <div className="d-flex justify-content-center gap-1">
                <PrimaryButton
                  label={i18n.t("filters")}
                  className="w-128"
                  onClick={() => setOpenFiltersModal(true)}
                />
                <PrimaryButton
                  label="Options"
                  className="w-128"
                  onClick={() => setOpenOptionsModal(true)}
                />
              </div>
            )}
          </div>
          {screenSize !== "mobile" ? (
            <div className="row align-items-center mx-0 gap-1 flex-wrap">
              {renderButtons()}
            </div>
          ) : null}
        </div>
        <div className="CalendarContainer plan-calendar-wrapper w-100">
          <FullCalendar
            events={formattedEvents}
            eventMouseEnter={onEventMouseEnter}
            eventMouseLeave={onEventMouseLeave}
            eventClick={onEvenClick}
            slotDuration="00:15:00"
            contentHeight={
              calendarRef.current?._calendarApi.currentDataManager.data
                .currentViewType == "dayGridMonth"
                ? null
                : 2000
            }
            datesAboveResources
            resources={resources}
            expandRows
            allDaySlot={false}
            weekends={visibleWeekEnds}
            dayMaxEvent={true}
            dayMaxEvents={3}
            displayEventEnd={{ month: true }}
            slotMinTime={`${start}:00:00`}
            slotMaxTime={end}
            eventContent={renderEventContent}
            stickyHeaderDates
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              resourceTimeGridPlugin,
              resourceTimeline,
              resourceDayGridPlugin,
              listPlugin,
              scrollGridPlugin,
            ]}
            initialView="timeGridWeek"
            dateClick={async (e) => {
              const date = moment(e.date)
              setDefaults({
                startTime: date.format("HH:mm"),
                date: date.toDate(),
                companyEmployeeId: employeeDetails?.companyEmployee._id,
              })
              setAddEventOpen(true)
            }}
            stickyFooterScrollbar={true}
            dayMinWidth={screenSize === "mobile" ? 150 : undefined}
            headerToolbar={false}
            eventOverlap={false}
            ref={calendarRef}
            timeZone="local"
            eventTimeFormat={{
              hour: "numeric",
              minute: "2-digit",
              meridiem: "short",
            }}
          />
        </div>
      </div>
    </div>
  )
}
