import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import styled from "styled-components";

import i18n from "../../../i18n";

const RequireBox = styled.section``;
export default function Required({ onChange, value }) {
  return (
    <RequireBox>
      <FormControl>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox onChange={onChange} checked={value} />}
            label={i18n.t("required_question")}
          ></FormControlLabel>
        </FormGroup>
      </FormControl>
    </RequireBox>
  );
}
