import React, { useCallback, useMemo } from "react"
import DotsLoader from "../DotsLoader"
import { connect } from "react-redux"
import { soldPackageRequest, getRequest } from "../../redux/actions/Packages"
import { useEffect } from "react"
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator"
import MenuItem from "@material-ui/core/MenuItem"
import PrimaryButton from "../Core/PrimaryButton"
import i18n from "../../i18n"
import { useState } from "react"
import { ErrorHelper, SuccessHelper } from "../../helpers"
import InsertPayment from "./components/InsertPayment.component"
import { createCartSale } from "../../config/simpleApiCalls"
import Autocomplete from "react-autocomplete"
import TextField from "@material-ui/core/TextField"
import FormHelperText from "@material-ui/core/FormHelperText"
import BasicTable from "../Core/BasicTable"
import { IconButton, TableRow } from "@material-ui/core"
import { TableCell } from "@material-ui/core"
import { Images } from "../../theme"
import MUAutocomplete from "@material-ui/lab/Autocomplete"
import { TouchApp } from "@material-ui/icons"
import CustomerSearchUp from "../TouchCustomerSeachUp/CustomerSearchUp"
import useCache from "../../context/LocalCache"
import useActiveMenuType from "../../context/ActiveMenuType"
import restoreConfirmation from "../../helpers/RestoreHelper"
import { deleteCustomer } from "../../config/simpleApiCalls"
const telephoneRegex = /[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]/g

const CreatePackageInvoice = ({
  close,
  loading,
  companies,
  companyTaxMap = {},
  scannerInput,
}) => {
  const { getResource } = useCache()
  const { type } = useActiveMenuType()
  const [giftCardBalance, setGiftCardBalance] = useState(0)
  const [email, setEmail] = useState("")
  const [phoneNo, setPhoneNo] = useState("")
  const [company, setCompany] = useState("")
  const [taxes, setTaxes] = useState([])
  const [isAddTransactionOpen, setIsAddTransactionOpen] = useState(false)
  const [includeTaxes, setIncludeTaxes] = useState(true)
  const [activeProduct, setActiveProduct] = useState()
  const [cart, setCart] = useState([])
  const [customerId, setCustomerId] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [customers, inactiveCustomers] = getResource((cache) => {
    let customers
    if (type != "employee") {
      customers = cache.company.customers
    } else {
      customers = cache.employee.customers
    }
    return [
      customers.filter(({ isActive }) => isActive),
      customers.filter(({ isActive }) => !isActive),
    ]
  })
  const [phoneNoError, setPhoneNoError] = useState("")
  const [name, setName] = useState("")
  const [showCollection, setShowCollection] = useState("")
  const products = getResource((cache) => {
    if (type != "employee") {
      return cache.company.products
    } else {
      return cache.employee.productMap[company]
    }
  })
  const productMap = useMemo(() => {
    const productMap = {}
    products.map((element) => {
      productMap[element._id] = {
        value: element._id,
        ...element,
      }
    })
    return productMap
  }, [products])
  const employees = useMemo(() => {
    return getResource((cache) => cache.company.employees)
      .filter(({ isActive }) => isActive)
      .map((employee) => ({
        value: employee.employee._id,
        name: employee.employee.userId.firstName,
      }))
  }, [getResource((cache) => cache.company.employees)])
  const [employee, setEmployee] = useState()
  const [touchSearchOpen, setTouchSearchOpen] = useState(false)

  useEffect(() => {
    if (companies.length == 1) {
      onCompanySelect(companies[0]._id)
    }
  }, [])

  const onProductAdd = (productToAdd) => {
    const product = productMap[productToAdd._id]
    const addedIndex = cart.findIndex((element) => {
      return element._id == product._id
    })
    if (addedIndex == -1) {
      setCart([
        ...cart,
        {
          quantity: 1,
          name: product.name,
          _id: productToAdd._id,
          price: product.charge,
          taxable: product.taxable,
        },
      ])
    } else {
      let copy = [...cart]
      const found = copy[addedIndex]
      copy[addedIndex] = {
        ...found,
        quantity: found.quantity + 1,
      }
      setCart(copy)
    }
  }
  useEffect(() => {
    if (scannerInput) {
      const product = Object.values(productMap).find((product) => {
        return product.barCode == scannerInput
      })
      if (product) {
        onProductAdd(product)
      }
    }
  }, [scannerInput])
  const onCompanySelect = async (companyId) => {
    setCompany(companyId)
    setTaxes(companyTaxMap[companyId] ? companyTaxMap[companyId] : [])
    setActiveProduct()
  }
  const onSave = ({
    note,
    cash,
    creditDebit,
    giftCard,
    tip,
    discount,
    chargedAmountByCard = [],
  }) => {
    setIsLoading(true)
    createCartSale({
      email,
      phoneNo,
      name,
      note,
      cash,
      creditDebit,
      giftCard,
      includeTaxes,
      companyId: company,
      products: cart,
      tip,
      discount,
      employeeId: employee,
      chargedAmountByCard,
    })
      .then(() => {
        close()
        SuccessHelper.handleSuccess(i18n.t("successfully_added"), true)
        setIsLoading(false)
      })
      .catch((e) => {
        setIsLoading(false)
        if (e.code == "insufficient_quantities") {
          return ErrorHelper.handleErrors(
            `${e.data?.inventory?.name}: ${i18n.t("insufficient_quantities", {
              remainder: e.data.remainder,
            })}`,
            true
          )
        }
        ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true)
      })
  }
  const validatePhoneNo = () => {
    if (phoneNo && !phoneNo.replace(/\s/g, "").match(telephoneRegex)) {
      setPhoneNoError(i18n.t("invalid_phone_number"))
      return
    }
    setPhoneNoError("")
    return true
  }
  const handleSubmit = () => {
    const inactiveCustomer = inactiveCustomers.find(
      (customer) => customer.phoneNo === phoneNo
    )
    if (validatePhoneNo() && !inactiveCustomer) {
      setShowCollection(!!cart.length)
    } else if (inactiveCustomer) {
      restoreConfirmation(
        () =>
          deleteCustomer({ ids: inactiveCustomer._id })
            .then((res) => {
              if (res.data.success) {
                inactiveCustomer.email && setEmail(inactiveCustomer.email)
                setName(
                  `${inactiveCustomer.firstName || ""} ${
                    inactiveCustomer.name
                  }`.trim()
                )
                setGiftCardBalance(inactiveCustomer.giftCardBalance)
                setShowCollection(!!cart.length)
              } else if (!res.data.success) {
                ErrorHelper.handleErrors(res.data.msg, true)
              }
            })
            .catch((error) => {
              console.log(error, "errorrrrrrrrrrrrrrrrrrr")
            }),
        i18n.t("this_is_inactive", { name: i18n.t("customer").toLowerCase() })
      )
    }
  }
  const cummulativeTaxRate =
    taxes.reduce((accum, tax) => accum + tax.percentage, 0) / 100
  let taxableAmount = 0
  const subtotal = cart.reduce((accum, element) => {
    const amount = element.quantity * element.price
    if (element.taxable) {
      taxableAmount += amount
    }
    return accum + amount
  }, 0)
  const taxDue = cummulativeTaxRate * taxableAmount
  const grandTotal =
    (includeTaxes ? cummulativeTaxRate : 0) * taxableAmount + subtotal

  return (
    <>
      <DotsLoader isloading={loading || isLoading} />
      <div>
        <ValidatorForm
          onSubmit={handleSubmit}
          className="w-100"
          onError={() => {}}
        >
          {employees.length ? (
            <div className="col-12 d-flex align-items-center mb-3 px-0">
              <label className="font-weight-bold" style={{ minWidth: "120px" }}>
                {i18n.t("sold_by")}
              </label>
              <div className="w-100">
                <SelectValidator
                  label={i18n.t("sold_by")}
                  onChange={(e) => {
                    setEmployee(e.target?.value)
                  }}
                  value={employee}
                  select
                  variant="outlined"
                >
                  {employees.map((employee) => {
                    return (
                      <MenuItem value={employee.value}>
                        {employee.name}
                      </MenuItem>
                    )
                  })}
                </SelectValidator>
              </div>
            </div>
          ) : null}
          {companies.length && companies.length != 1 ? (
            <div className="col-12 d-flex align-items-center mb-3 px-0">
              <label className="font-weight-bold" style={{ minWidth: "120px" }}>
                {i18n.t("company")}
              </label>
              <div className="w-100">
                <SelectValidator
                  label={i18n.t("company")}
                  onChange={(e) => {
                    onCompanySelect(e.target?.value)
                  }}
                  value={company}
                  select
                  variant="outlined"
                >
                  {companies.map((company) => {
                    return (
                      <MenuItem value={company._id}>{company.name}</MenuItem>
                    )
                  })}
                </SelectValidator>
              </div>
            </div>
          ) : null}
          <div className="row mx-0 px-0">
            <div className="col-12 d-flex align-items-center mb-3 px-0">
              <label className="font-weight-bold" style={{ minWidth: "120px" }}>
                {i18n.t("container.employe_phone_number")}
              </label>
              <Autocomplete
                placeholder={i18n.t("enter_phone_number_placeholder_type")}
                renderInput={(props) => {
                  return (
                    <TextField
                      id="phoneNumber"
                      label={i18n.t("container.employe_phone_number")}
                      error={!!phoneNoError}
                      {...props}
                      variant="outlined"
                    />
                  )
                }}
                items={
                  phoneNo
                    ? customers.map(
                        ({
                          name = "",
                          phoneNo = "",
                          firstName = "",
                          email = "",
                          giftCardBalance,
                          userId,
                          isBlackListed,
                          _id,
                        }) => ({
                          name,
                          phoneNo,
                          email,
                          firstName,
                          giftCardBalance,
                          userId,
                          isBlackListed,
                          _id,
                        })
                      )
                    : []
                }
                shouldItemRender={(item, value) =>
                  item.phoneNo.toLowerCase().indexOf(value.toLowerCase()) >
                    -1 ||
                  item.name.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
                  item.firstName?.toLowerCase().indexOf(value.toLowerCase()) >
                    -1
                }
                getItemValue={(item) => item.phoneNo}
                renderItem={(item, highlighted) => (
                  <MenuItem key={item._id}>
                    {`${item.firstName ? item.firstName : ""} ${
                      item.name
                    }`.trim()}
                  </MenuItem>
                )}
                menuStyle={{
                  zIndex: "9998",
                  position: "absolute",
                  background: "white",
                  top: 64,
                  left: 0,
                }}
                wrapperStyle={{
                  width: "calc(100% - 120px)",
                }}
                renderMenu={(children) => {
                  return <div className="menu">{children.slice(0, 15)}</div>
                }}
                value={phoneNo}
                onChange={(e) => setPhoneNo(e.target.value)}
                onSelect={(
                  value,
                  { phoneNo, email, firstName, name, giftCardBalance, _id }
                ) => {
                  setCustomerId(_id)
                  setPhoneNo(phoneNo)
                  setEmail(email)
                  setName(`${firstName || ""} ${name}`.trim())
                  setGiftCardBalance(giftCardBalance)
                }}
              />
              <IconButton
                aria-label="search"
                className="ml-2 p-2"
                style={{ backgroundColor: "#3f51b5" }}
                onClick={() => setTouchSearchOpen(true)}
              >
                <TouchApp
                  fontSize="medium"
                  color="white"
                  style={{ color: "white" }}
                />
              </IconButton>
            </div>
            <CustomerSearchUp
              open={touchSearchOpen}
              setOpen={setTouchSearchOpen}
              customers={customers || []}
              onDone={(
                phoneNo,
                { email, firstName, name, giftCardBalance }
              ) => {
                setPhoneNo(phoneNo)
                setEmail(email)
                setName(`${firstName || ""} ${name}`.trim())
                setGiftCardBalance(giftCardBalance)
                setTouchSearchOpen(false)
              }}
            />
            {!!phoneNoError && (
              <FormHelperText error>{phoneNoError}</FormHelperText>
            )}
            <div className="col-12 d-flex align-items-center mb-3 px-0">
              <label className="font-weight-bold" style={{ minWidth: "120px" }}>
                {i18n.t("name")}
              </label>
              <div className="w-100">
                <TextValidator
                  label={i18n.t("name")}
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  validators={["required"]}
                  errorMessages={[i18n.t("name_required")]}
                  variant="outlined"
                />
              </div>
            </div>
            <div className="col-12 d-flex align-items-center mb-3 px-0">
              <label className="font-weight-bold" style={{ minWidth: "120px" }}>
                {i18n.t("email_to_invoice")}
              </label>
              <div className="w-100">
                <TextValidator
                  label={i18n.t("email_to_invoice")}
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  validators={["required", "isEmail"]}
                  errorMessages={[
                    i18n.t("email_required"),
                    i18n.t("invalid_email"),
                  ]}
                  variant="outlined"
                />
              </div>
            </div>
            <div className="col-12 row mx-0 px-0 align-items-center mb-3">
              <label className="font-weight-bold" style={{ minWidth: "120px" }}>
                {i18n.t("products")}
              </label>
              <div
                className="mobile-w-100"
                style={{ width: "calc(100% - 200px)" }}
              >
                <MUAutocomplete
                  options={Object.values(productMap)}
                  groupBy={(option) => {
                    return option.subcategory
                  }}
                  blurOnSelect
                  value={activeProduct}
                  onChange={(e, value) => {
                    setActiveProduct(value)
                  }}
                  getOptionLabel={(option) => option.name}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={i18n.t("products")}
                      fullWidth
                      variant="outlined"
                    />
                  )}
                />
              </div>
              <PrimaryButton
                className="ml-sm-3 mt-3 mt-sm-0"
                label={i18n.t("add")}
                onClick={() => {
                  if (activeProduct) {
                    onProductAdd(activeProduct)
                  }
                }}
              />
            </div>
          </div>
        </ValidatorForm>
        <div className="mt-3">
          <BasicTable
            rows={cart}
            schema={[
              {
                header: i18n.t("name"),
                field: "name",
              },
              {
                header: i18n.t("quantity"),
                field: "quantity",
                render: (row, index) => {
                  return (
                    <TextField
                      type="number"
                      variant="outlined"
                      style={{ width: "60px" }}
                      className="TableInput"
                      value={row.quantity}
                      min={1}
                      onChange={(e) => {
                        let quantity = parseInt(e.target.value)
                        if (quantity < 1) {
                          quantity = 1
                        }
                        let newCart = [...cart]
                        newCart[index] = {
                          ...newCart[index],
                          quantity,
                        }
                        setCart(newCart)
                      }}
                    />
                  )
                },
              },
              {
                header: i18n.t("price"),
                field: "price",
              },
              {
                header: i18n.t("total"),
                field: "price",
                accessor: (row) => (row.price * row.quantity).toFixed(2),
              },
              {
                header: "",
                field: "delete",
                style: { width: 30 },
                render: (data, index) => {
                  return (
                    <img
                      onClick={() => {
                        let newCart = [...cart]
                        newCart.splice(index, 1)
                        setCart(newCart)
                      }}
                      className="category-table-action-icon"
                      src={Images.saloon_working_hour_delete}
                    />
                  )
                },
              },
            ]}
            appendRows={
              subtotal ? (
                <>
                  <TableRow>
                    <TableCell className="font-weight-bold">
                      {i18n.t("total")}
                    </TableCell>
                    <TableCell />
                    <TableCell />

                    <TableCell className="font-weight-bold">
                      {subtotal.toFixed(2)}
                    </TableCell>
                    <TableCell style={{ width: 30 }} />
                  </TableRow>
                  {taxDue ? (
                    <TableRow>
                      <TableCell className="font-weight-bold">
                        {i18n.t("tax_total")}
                      </TableCell>
                      <TableCell />
                      <TableCell />

                      <TableCell className="font-weight-bold">
                        {taxDue.toFixed(2)}
                      </TableCell>
                      <TableCell style={{ width: 30 }} />
                    </TableRow>
                  ) : null}
                  <TableRow>
                    <TableCell className="font-weight-bold">
                      {i18n.t("grand_total")}
                    </TableCell>
                    <TableCell />
                    <TableCell />

                    <TableCell className="font-weight-bold">
                      {grandTotal.toFixed(2)}
                    </TableCell>
                    <TableCell style={{ width: 30 }} />
                  </TableRow>
                </>
              ) : null
            }
          />
        </div>
      </div>
      <div className="row mx-0 px-0 justify-content-end my-4">
        {showCollection ? (
          <InsertPayment
            defaultAmount={grandTotal}
            open={showCollection}
            onSubmit={onSave}
            handleClose={() => setShowCollection(false)}
            toggleTaxes={() => setIncludeTaxes(!includeTaxes)}
            items={cart}
            subtotal={subtotal}
            taxDue={taxDue}
            includeTaxes={includeTaxes}
            grandTotal={grandTotal}
            giftCardBalance={giftCardBalance}
            customerId={customerId}
            type={"product"}
          />
        ) : null}
        <PrimaryButton
          props={{
            type: "submit",
          }}
          onClick={handleSubmit}
          label={i18n.t("collect_payment")}
        />
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  loading: state.packages.isFetching,
})

const action = { soldPackageRequest, getRequest }

export default connect(mapStateToProps, action)(CreatePackageInvoice)
