import React, { useState } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import AddModal from "./AddModal.component";
import i18n from "../../../../i18n";
import { Translation } from "react-i18next";
function AddFundsRequest({ accountDetails }) {
  const { totalAvailable = 0 } = accountDetails;
  const [open, setOpen] = useState(false);
  const onAddClick = () => {
    setOpen(true);
  };
  const available = totalAvailable;
  return (
    <>
      <div className="row mx-0 justify-content-between">
        <div>
          <h1 className="saloon-dashboard-heading">
            <Translation>{(t) => t("container.request_funds")}</Translation>
          </h1>
        </div>
        <Button className="primary-button" onClick={onAddClick}>
          {i18n.t("request")}
        </Button>
      </div>

      <AddModal
        open={open}
        handleClose={() => setOpen(false)}
        available={available > 0 ? available : 0}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  accountDetails: state.employeeFundsRequest.accountDetails,
});

const action = {};

export default connect(mapStateToProps, action)(AddFundsRequest);
