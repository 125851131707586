import React, { useState, useEffect, useRef } from "react"
import { connect } from "react-redux"
import SharedStorage from "../../../helpers/Storage"
import {
  ExpandLess,
  ExpandMore,
  CloseOutlined,
  Send as SendIcon,
} from "@material-ui/icons"
import i18n from "../../../i18n"
import { Images } from "../../../theme"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Collapse from "@material-ui/core/Collapse"
import { SuccessHelper } from "../../../helpers"
import { request as saloon_logout } from "../../../redux/actions/Saloon/LogOut"
import { request as employee_logout } from "../../../redux/actions/Employee/LogOut"
import { clearLogout as clear_logout } from "../../../redux/actions/LogOut"
import { Translation, withTranslation } from "react-i18next"
import useActiveMenuType from "../../../context/ActiveMenuType"
import "./styles.scss"
import { useHistory } from "react-router"
import useScreenSize from "../../../context/ScreenSize"
import { ArrowBackSharp } from "@material-ui/icons"

import SlidingPane from "react-sliding-pane"
import "react-sliding-pane/dist/react-sliding-pane.css"
import { ROLES } from "../../../util/enum"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import { Button, Fab, IconButton } from "@material-ui/core"

const {
  activities_svg_dark,
  ai_booking_svg_dark,
  arrow_svg_dark,
  billing_svg_dark,
  customers_svg_dark,
  dashboard_svg_dark,
  employees_svg_dark,
  forms_svg_dark,
  logout_svg_dark,
  marketing_svg_dark,
  my_appointment_svg_dark,
  my_business_svg_dark,
  products_and_services_svg_dark,
  profile_svg_dark,
  programs_svg_dark,
  purchase_svg_dark,
  rating_svg_dark,
  reports_svg_dark,
  taxes_svg_dark,
  wallet_svg_dark,

  activities_svg_light,
  ai_booking_svg_light,
  arrow_active_svg,
  billing_svg_light,
  customers_svg_light,
  dashboard_svg_light,
  employees_svg_light,
  forms_svg_light,
  logout_svg_light,
  marketing_svg_light,
  my_appointment_svg_light,
  my_business_svg_light,
  products_and_services_svg_light,
  profile_svg_light,
  programs_svg_light,
  purchase_svg_light,
  rating_svg_light,
  reports_svg_light,
  taxes_svg_light,
  wallet_svg_light,

  active_salon_dark_svg,
  request_funds_dark_svg,
  social_links_dark_svg,
  upload_media_dark_svg,
  working_hours_dark_svg,

  active_salon_light_svg,
  request_funds_light_svg,
  social_links_light_svg,
  upload_media_light_svg,
  working_hours_light_svg,
} = Images

export const functionHiddenItemsMap = {
  garage: {
    EmployeeList: true,
    AddEmployee: true,
    FundRequests: true,
    Roles: true,
    EmployeeReport: true,
    PayrollReport: true,
    Class: true,
    Program: true,
    Student: true,
    StudentGroup: true,
    CoursePlanner: true,
    Semester: true,
  },
  gym: {
    EmployeeList: true,
    AddEmployee: true,
    FundRequests: true,
    Roles: true,
    EmployeeReport: true,
    PayrollReport: true,
  },
}
export const resourceToConfig = {
  Dashboard: [
    "/saloon-dashboard",
    dashboard_svg_light,
    dashboard_svg_dark,
    "container.dashboard",
  ],
  Profile: [
    "/saloon-edit-profile",
    profile_svg_light,
    profile_svg_dark,
    "container.profile",
  ],
  WorkingHours: [
    "/saloon-working-hours",
    null,
    null,
    "container.working_hours",
  ],
  UploadMedia: ["/saloon-upload-media", null, null, "container.upload_media"],
  SocialLinks: [
    "/saloon-link-social-accounts",
    null,
    null,
    "container.dashboard_social_media_icons",
  ],
  SiteSetUp: ["/saloon-my-site", null, null, "container.site_set_up"],
  RefundPolicy: ["/saloon-refund-policy", null, null, "refund_policy"],
  OnlinePayments: [
    "/saloon-online-payments",
    null,
    null,
    "container.online_payments",
  ],
  BlackListSettings: [
    "/saloon-black-listing-settings",
    null,
    null,
    "container.black_listing_settings",
  ],
  LiveDisplaySettings: [
    "/saloon-live-display-settings",
    null,
    null,
    "container.live_display_settings",
  ],
  Security: ["/saloon-security", null, null, "container.security"],
  CommunicationSettings: [
    "/saloon-communication-settings",
    null,
    null,
    "container.communication_settings",
  ],
  EmployeeList: [
    "/saloon-company-employee",
    null,
    null,
    "container.dashboard_employe_list",
  ],
  AddEmployee: [
    "/saloon-add-employee",
    null,
    null,
    "container.add_employee_add_employe",
  ],
  FundRequests: ["/saloon-all-funds-requests", null, null, "funds_requests"],
  Roles: ["/saloon-roles", null, null, "container.roles"],
  Calendar: ["/saloon-my-appointment", null, null, "container.my_calendar"],
  BookingHistory: [
    "/saloon-booking",
    null,
    null,
    "container.profile_booking_history",
  ],
  WaitingList: ["/saloon-waiting-list", null, null, "waiting_list"],
  Inventory: [
    "/saloon-my-inventory",
    null,
    null,
    "container.dashboard_inventory_list",
  ],
  AddInvetory: ["/saloon-add-inventory", null, null, "container.add_product"],
  AllServices: [
    "/saloon-all-services",
    null,
    null,
    "container.dashboard_services_list",
  ],
  AddService: ["/saloon-add-service", null, null, "container.add_service"],
  Packages: ["/saloon-packages", null, null, "packages_classes"],
  GiftCards: ["/saloon-gift-cards", null, null, "GiftCards"],
  Subscriptions: ["/saloon-subscriptions", null, null, "subscriptions"],
  Orders: ["/saloon-orders", null, null, "container.orders"],
  Vendor: ["/saloon-vendor", null, null, "vendor"],
  Expense: ["/saloon-expense", null, null, "expense"],
  Form: ["/saloon-forms", forms_svg_light, forms_svg_dark, "forms"],
  Customers: ["/saloon-customers", null, null, "container.customers_list"],
  AddCustomer: ["/saloon-add-customer", null, null, "container.add_customer"],
  ImportCustomer: [
    "/saloon-import-customers",
    null,
    null,
    "container.customer_import",
  ],
  CustomerGroups: ["/saloon-customer-groups", null, null, "customer_groups"],
  AiSchedule: ["/saloon-ai-schedule", null, null, "ai_booking_schedule"],
  AiScheduleApproval: [
    "/saloon-ai-approval",
    null,
    null,
    "ai_booking_approval",
  ],
  AiScheduleTemplate: [
    "/saloon-ai-schedule-templates",
    null,
    null,
    "ai_booking_schedule_template",
  ],
  AiSettings: ["/saloon-ai-settings", null, null, "ai_settings"],
  Chat: ["/saloon-online-support", null, null, "container.chat_box"],
  BookingReport: [
    "/saloon-booking-report",
    null,
    null,
    "container.booking_report",
  ],
  EmployeeReport: [
    "/saloon-employee-report",
    null,
    null,
    "container.employee_report",
  ],
  TaxReport: ["/saloon-tax-report", null, null, "tax_report"],
  SalesReport: ["/saloon-sales-report", null, null, "sales_report"],
  PayrollReport: ["/saloon-payroll-report", null, null, "payroll_report"],
  ExpenseReport: ["/saloon-expense-report", null, null, "expense_report"],
  ProductReport: ["/saloon-product-report", null, null, "product_report"],
  PackageReport: ["/saloon-package-report", null, null, "package_report"],
  GiftCardReport: ["/saloon-gift-card-report", null, null, "gift_card_report"],
  CommissionReport: [
    "/saloon-commission-report",
    null,
    null,
    "commission_report",
  ],
  NoShowReport: ["/saloon-no-show-report", null, null, "no_show_report"],
  Rating: ["/saloon-rating", null, null, "ratings"],
  RatingForm: ["/saloon-rating-form", null, null, "rating_form"],
  Wallet: [
    "/saloon-wallet",
    wallet_svg_light,
    wallet_svg_dark,
    "container.wallet",
  ],
  PricingPlans: [
    "/saloon-pricing-plains",
    null,
    null,
    "container.pricing_plans",
  ],
  BillingHistory: [
    "/saloon-billing-history",
    null,
    null,
    "container.billing_history",
  ],
  AccountSettings: [
    "/saloon-account-settings",
    null,
    null,
    "container.account_settings",
  ],
  DevicesAndEquipment: [
    "/saloon-devices-and-equipment",
    null,
    null,
    "container.devices_and_equipment",
  ],
  TaxList: ["/saloon-taxes", null, null, "container.taxe_list"],
  AddTax: ["/saloon-add-tax", null, null, "container.add_tax"],
  Class: ["/saloon-classes", null, null, "classes"],
  Program: ["/saloon-programs", null, null, "programs"],
  Student: ["/saloon-students", null, null, "students"],
  StudentGroup: ["/saloon-student-groups", null, null, "student_groups"],
  CoursePlanner: ["/saloon-program-planner", null, null, "course_planner"],
  Semester: ["/saloon-semester", null, null, "semesters"],
  CommunicationMarkettingAccount: [
    "/saloon-comm-marketing-account",
    null,
    null,
    "communication_marketing_account",
  ],
  CommunicationMarkettingCampaign: [
    "/saloon-comm-marketing-campaigns",
    null,
    null,
    "communication_marketing_campaigns",
  ],
  CommunicationMarkettingTemplates: [
    "/saloon-comm-marketing-templates",
    null,
    null,
    "communication_marketing_templates",
  ],
  Promotions: ["/saloon-promotions", null, null, "promotions"],
  Discounts: ["/saloon-discount-codes", null, null, "discount_codes"],
  IntakeCategories: [
    "/saloon-intake-categories",
    null,
    null,
    "intake_categories",
  ],
  CustomerAgeGroups: ["/saloon-age-groups", null, null, "age_groups"],
  CustomerDistricts: ["/saloon-districts", null, null, "districts"],
  CustomerOrigins: ["/saloon-origins", null, null, "origins"],
  CustomerStatuses: ["/saloon-customer-statuses", null, null, "statuses"],
  TimePeriods: ["/saloon-time-periods", null, null, "time_periods"],
  IntakeReport: ["/saloon-intake-report", null, null, "intake_report"],
  CustomerStats: ["/saloon-customer-stats", null, null, "customer_stats"],
  IntakeType: ["/saloon-intake-type", null, null, "intake_types"],
  CustomerReferences: [
    "/saloon-customer-references",
    null,
    null,
    "customer_references",
  ],
  Activities: ["/saloon-activity", null, null, "activities"],
  ActivityCategories: [
    "/saloon-activity-category",
    null,
    null,
    "activity_categories",
  ],
  ActivityScheduleTemplate: [
    "/saloon-activity-schedule-templates",
    null,
    null,
    "activities_schedule_template",
  ],
  ActivitiesSchedule: [
    "/saloon-activity-schedule-builder",
    null,
    null,
    "activities_calendar",
  ],
  ActivityCategoryReport: [
    "/saloon-activity-category-report",
    null,
    null,
    "activity_category_report",
  ],
  ActivityReport: ["/saloon-activity-report", null, null, "activity_report"],
  ActivityAttendanceReport: [
    "/saloon-activity-attendance-report",
    null,
    null,
    "activity_attendance_report",
  ],
  SubscriptionReport: [
    "/saloon-subscription-report",
    null,
    null,
    "subscription_report",
  ],
  StaffMeeting: ["/saloon-staff-meeting", null, null, "staff_meetings"],
}

export const employeeResourceToConfig = {
  EmployeeDashboard: [
    "/employee-dashboard",
    dashboard_svg_light,
    dashboard_svg_dark,
    "container.dashboard",
  ],
  EmployeeProfile: [
    "/employee-edit-profile",
    profile_svg_light,
    profile_svg_dark,
    "container.profile",
  ],
  EmployeeActivitiesSchedule: [
    "/employee-activity-schedule-builder",
    activities_svg_light,
    activities_svg_dark,
    "activities_calendar",
  ],
  EmployeeMyAppointment: [
    "/employee-my-appointment",
    null,
    null,
    "container.my_calendar",
  ],
  EmployeeBookingHistory: [
    "/employee-booking",
    null,
    null,
    "container.profile_booking_history",
  ],
  EmployeeWorkingHours: [
    "/employee-add-working-hour",
    working_hours_light_svg,
    working_hours_dark_svg,
    "container.working_hours",
  ],
  EmployeeRating: [
    "/employee-rating",
    rating_svg_light,
    rating_svg_dark,
    "container.rating",
  ],
  EmployeeActiveSaloon: [
    "/employee-active-saloon",
    active_salon_light_svg,
    active_salon_dark_svg,
    "container.active_salon",
  ],
  EmployeeAllCustomers: [
    "/employee-customers",
    customers_svg_light,
    customers_svg_dark,
    "Customers",
  ],
  EmployeeUploadMedia: [
    "/employee-upload-media",
    upload_media_light_svg,
    upload_media_dark_svg,
    "container.upload_media",
  ],
  EmployeeSocialMedia: [
    "/employee-social-media",
    social_links_light_svg,
    social_links_dark_svg,
    "container.dashboard_social_media_icons",
  ],
  EmployeeWallet: [
    "/employee-wallet",
    wallet_svg_light,
    wallet_svg_dark,
    "container.wallet",
  ],
  EmployeeRequestFunds: [
    "/employee-request-funds",
    request_funds_light_svg,
    reports_svg_dark,
    "container.request_funds",
  ],
}
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
    partialVisibilityGutter: 12, // this is needed to tell the amount of px that should be visible.
    slidesToSlide: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    partialVisibilityGutter: 12, // this is needed to tell the amount of px that should be visible.
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
    partialVisibilityGutter: 12, // this is needed to tell the amount of px that should be visible.
    slidesToSlide: 2,
  },
}

function SaloonSidebarMenu(props) {
  const history = useHistory()
  const { screenSize } = useScreenSize()
  const {
    receptionistAllowedResource,
    managerAllowedResource,
    ownerAllowedResource,
    instructorAllowedResource,
    clear_logout,
    saloonLogout,
    employeeLogout,
  } = props
  const { functionRole } = useActiveMenuType()
  const [openDropdown, setOpenDropdown] = useState("")
  const [routeName, setRouteName] = useState("")
  const [isGym, setIsGym] = useState(false)
  const [hasEmployeeAccess, setHasEmployeeAccess] = useState(false)
  const [hasSaloonAccess, setHasSaloonAccess] = useState(false)
  const [hasReceptionAccess, setHasReceptionAccess] = useState(false)
  const [hasManagerAccess, setHasManagerAccess] = useState(false)
  const [hasInstructorAccess, setHasInstructorAccess] = useState(false)
  const {
    type,
    setType,
    isInstructor,
    setIsInstructor,
    hideEmployeeProfile,
    accountAccess,
  } = useActiveMenuType()
  const [menuItems, setMenuItems] = useState([])
  const [selectedMenu, setSelectedMenu] = useState("")
  const [isInitialRender, setIsInitialRender] = useState(true)
  const paneWidth = screenSize == "desktop" ? "486px" : "100%"
  const carouselRef = useRef()
  const [paneOpen, setPaneOpen] = useState({
    isPaneOpen: false,
  })

  const onCurrentChange = (type) => {
    if (type == "instructor") {
      setType("employee")
      setIsInstructor(true)
    } else {
      setType(type)
      setIsInstructor(false)
    }

    SharedStorage.setItem("menu_type", type)
  }
  const onMount = async () => {
    const saloonData = JSON.parse(await SharedStorage.getItem("saloon"))
    setRouteName(history.location.pathname)
    setIsGym(
      JSON.parse(await SharedStorage.getItem("company"))?.function == "gym"
    )
    const hasEmployeeAccess =
      (await SharedStorage.getItem("employee")) &&
      !!Object.keys(JSON.parse(await SharedStorage.getItem("employee"))).length
    setHasEmployeeAccess(hasEmployeeAccess)

    const employeeData = JSON.parse(await SharedStorage.getItem("employee"))
    let hasSaloonAccess =
      saloonData &&
      !!Object.keys(saloonData).length &&
      saloonData.role.includes(ROLES.OWNER)
    setHasSaloonAccess(hasSaloonAccess)
    let hasReceptionAccess = employeeData.role.includes(ROLES.RECEPTIONIST)
    setHasReceptionAccess(hasReceptionAccess)
    let hasManagerAccess = employeeData.role.includes(ROLES.MANAGER)
    setHasManagerAccess(hasManagerAccess)
    let hasInstructorAccess = employeeData.role.includes(ROLES.INSTRUCTOR)
    setHasInstructorAccess(hasInstructorAccess)
    let type = ""
    const menuItems = []

    if (accountAccess) {
      if (hasInstructorAccess) {
        type = "instructor"
        menuItems.push("instructor")
      }
      if (hasEmployeeAccess && !hideEmployeeProfile) {
        type = "employee"
        menuItems.push("employee")
      }

      if (hasReceptionAccess) {
        type = "receptionist"
        menuItems.push("receptionist")
      }
      if (hasManagerAccess) {
        type = "manager"
        menuItems.push("manager")
      }
      if (hasSaloonAccess) {
        type = "salon"
        menuItems.push("salon")
      }
    } else {
      type = "employee"
      menuItems.push("employee")
    }
    setMenuItems(menuItems.reverse())
    onCurrentChange(
      menuItems.includes(await SharedStorage.getItem("menu_type"))
        ? await SharedStorage.getItem("menu_type")
        : type
    )
    setSelectedMenu(
      menuItems.includes(await SharedStorage.getItem("menu_type"))
        ? await SharedStorage.getItem("menu_type")
        : type
    )
  }
  useEffect(() => {
    onMount()
  }, [accountAccess, hideEmployeeProfile])

  useEffect(() => {
    if (!paneOpen.isPaneOpen) {
      setOpenDropdown("")
    }
  }, [paneOpen.isPaneOpen])

  useEffect(() => {
    if (selectedMenu && isInitialRender) {
      const index = menuItems.findIndex((item) => item === selectedMenu)

      let slide = Math.floor(index / 2) * 2
      if (index === menuItems.length - 1 && menuItems.length % 2 !== 0) {
        slide -= 1
      }
      carouselRef?.current?.goToSlide(slide)
      setIsInitialRender(false)
    }
  }, [selectedMenu, isInitialRender])

  const handleNavigation = (routeName) => {
    history.push(routeName)
    setRouteName(routeName)
  }
  useEffect(() => {
    setRouteName(`/${history.location.pathname.split("/")?.[1]}`)
  }, [history.path])

  useEffect(() => {
    if (saloonLogout) {
      if (
        !saloonLogout.failure &&
        !saloonLogout.isFetching &&
        !saloonLogout.errorMessage &&
        saloonLogout.data &&
        saloonLogout.data.success
      ) {
        clear_logout()
        handleNavigation("/login")
      }
    }
  }, [saloonLogout])

  useEffect(() => {
    if (employeeLogout) {
      if (
        !employeeLogout.failure &&
        !employeeLogout.isFetching &&
        !employeeLogout.errorMessage &&
        employeeLogout.data &&
        employeeLogout.data.success
      ) {
        clear_logout()
        handleNavigation("/login")
      }
    }
  }, [employeeLogout])

  const handleClick = (open) => {
    setOpenDropdown(open)
  }

  const renderItem = (
    route,
    lightIcon = SendIcon,
    darkIcon,
    title,
    className
  ) => {
    return (
      <ListItem
        key={route}
        button
        className={`side-bar-item ${className} ${
          (routeName == route && !openDropdown) ||
          (routeName == route && className)
            ? "active"
            : ""
        }`}
        onClick={() => {
          if (!className) {
            setPaneOpen(false)
            setOpenDropdown(false)
          }

          handleNavigation(route)
        }}
      >
        <img
          data-active={routeName == route ? true : false}
          data-dark-icon={darkIcon}
          src={routeName == route ? lightIcon : darkIcon}
          alt=""
        />
        <ListItemText primary={i18n.t(title)} />
      </ListItem>
    )
  }
  const renderListItem = (a, className) => {
    let b = a
    if (typeof a == "object") {
      b = a.resource
      a = a.key
    }
    if (accountAccess === false) {
      if (a === "EmployeeActiveSaloon")
        return renderItem(
          ...employeeResourceToConfig["EmployeeActiveSaloon"],
          className
        )
    } else if (
      !functionHiddenItemsMap[functionRole]?.[b] &&
      ((hasSaloonAccess && ownerAllowedResource?.[b] && type == "salon") ||
        (hasReceptionAccess &&
          receptionistAllowedResource?.[b] &&
          type == "receptionist") ||
        (hasManagerAccess && managerAllowedResource?.[b] && type == "manager"))
    ) {
      return renderItem(...resourceToConfig[a], className)
    } else if (
      (hasInstructorAccess && instructorAllowedResource?.[b]) ||
      (!isInstructor && type == "employee")
    ) {
      return renderItem(...employeeResourceToConfig[a], className)
    }

    return null
  }

  const renderDropdownListItem = (key, Icon, title, children = []) => {
    children = children
      .map((element, ind) => {
        return renderListItem(element, "nested", ind)
      })
      .filter((element) => !!element)
    if (children.length) {
      return (
        <div>
          <ListItem
            button
            onClick={() => {
              handleClick(openDropdown == key ? "" : key)
              setPaneOpen({ isPaneOpen: true })
            }}
            className={`side-bar-item ${openDropdown == key ? "p-active" : ""}`}
          >
            <ListItemIcon>
              <img src={Icon} alt="" width={24} />
            </ListItemIcon>
            <ListItemText primary={title} />
            {openDropdown == key ? (
              <ExpandLess />
            ) : (
              <ExpandMore className="sidebar-arrow" />
            )}
          </ListItem>
          <Collapse in={openDropdown == key} timeout="auto" unmountOnExit>
            <SlidingPane
              className="sliding-pane"
              isOpen={paneOpen.isPaneOpen}
              from="left"
              hideHeader
              onRequestClose={() => {
                setPaneOpen({ isPaneOpen: false })
              }}
              title={title}
              width={paneWidth}
              children={
                <List
                  component="div"
                  disablePadding
                  className={openDropdown == key ? "active" : ""}
                >
                  <div className="nested-menu mt-0 mt-xl-5 pt-0 pt-xl-5">
                    {screenSize != "desktop" && (
                      <Fab
                        className="mb-4"
                        size="small"
                        onClick={() => {
                          setPaneOpen({ isPaneOpen: false })
                        }}
                      >
                        <ArrowBackSharp />
                      </Fab>
                    )}
                    <h4 className="nested-title">{title}</h4>
                    {children}
                  </div>
                </List>
              }
            />
          </Collapse>
        </div>
      )
    }
    return null
  }
  const handleLogout = (type) => {
    if (type == "employee") {
      props.employee_logout({})
    } else {
      props.saloon_logout({})
    }
    history.push("/")
  }
  const renderCurrentMenu = () => {
    switch (type) {
      case "salon":
      case "receptionist":
      case "manager": {
        return (
          <ul>
            {renderListItem("Dashboard")}
            {renderListItem("Profile")}
            {renderDropdownListItem(
              "myBusiness",
              my_business_svg_dark,
              i18n.t("container.my_business"),
              [
                "WorkingHours",
                "UploadMedia",
                "SocialLinks",
                "SiteSetUp",
                "RefundPolicy",
                "OnlinePayments",
                "BlackListSettings",
                "LiveDisplaySettings",
                "Security",
                "CommunicationSettings",
                { key: "IntakeCategories", resource: "Customers" },
                { key: "IntakeType", resource: "Customers" },
                { key: "TimePeriods", resource: "TimePeriod" },
              ]
            )}
            {renderDropdownListItem(
              "employee",
              employees_svg_dark,
              i18n.t("container.employee"),
              ["EmployeeList", "AddEmployee", "FundRequests", "Roles"]
            )}
            {renderDropdownListItem(
              "appointments",
              my_appointment_svg_dark,
              i18n.t("container.my_appointments"),
              [
                { key: "Calendar", resource: "Booking" },
                { key: "BookingHistory", resource: "Booking" },
                ...(!isGym ? ["WaitingList"] : []),
                { key: "StaffMeeting", resource: "Booking" },
              ]
            )}
            {renderDropdownListItem(
              "products_and_services",
              products_and_services_svg_dark,
              i18n.t("container.products_and_services"),
              [
                "Inventory",
                "AddInvetory",
                "AllServices",
                "AddService",
                "Packages",
                "GiftCards",
                "Subscriptions",
                "Orders",
              ]
            )}
            {renderDropdownListItem(
              "programs",
              programs_svg_dark,
              i18n.t("programs"),
              [
                { key: "Program", resource: "Program" },
                { key: "Class", resource: "Program" },
                { key: "Semester", resource: "Program" },
                { key: "StudentGroup", resource: "Program" },
                { key: "Student", resource: "Program" },
                { key: "CoursePlanner", resource: "Program" },
              ]
            )}
            {renderDropdownListItem(
              "marketing",
              marketing_svg_dark,
              i18n.t("marketing"),
              [
                {
                  key: "Promotions",
                  resource: "Promotion",
                },
                {
                  key: "Discounts",
                  resource: "DiscountCode",
                },
                {
                  key: "CommunicationMarkettingAccount",
                  resource: "Marketing",
                },
                {
                  key: "CommunicationMarkettingCampaign",
                  resource: "Marketing",
                },
                {
                  key: "CommunicationMarkettingTemplates",
                  resource: "Marketing",
                },
              ]
            )}
            {renderDropdownListItem(
              "purchase",
              purchase_svg_dark,
              i18n.t("purchase"),
              ["Vendor", "Expense"]
            )}
            {renderDropdownListItem(
              "customers",
              customers_svg_dark,
              i18n.t("container.customers"),
              [
                "Customers",
                "AddCustomer",
                { key: "CustomerReferences", resource: "Customers" },
                { key: "CustomerStats", resource: "Customers" },
                "ImportCustomer",
                { key: "CustomerGroups", resource: "Customers" },
                { key: "CustomerAgeGroups", resource: "Customers" },
                { key: "CustomerDistricts", resource: "Customers" },
                { key: "CustomerOrigins", resource: "Customers" },
                { key: "CustomerStatuses", resource: "Customers" },
                { key: "SubscriptionReport", resource: "Customers" },
              ]
            )}
            {renderDropdownListItem(
              "ai_booking",
              ai_booking_svg_dark,
              i18n.t("ai_booking"),
              [
                "AiScheduleTemplate",
                "AiSchedule",
                "AiScheduleApproval",
                "AiSettings",
              ]
            )}
            {renderDropdownListItem(
              "activities",
              activities_svg_dark,
              i18n.t("activities"),
              [
                "Activities",
                "ActivityCategories",
                "ActivityScheduleTemplate",
                "ActivitiesSchedule",
                "ActivityCategoryReport",
                "ActivityAttendanceReport",
                "ActivityReport",
              ]
            )}
            {renderListItem("Form")}
            {renderDropdownListItem(
              "reports",
              reports_svg_dark,
              i18n.t("container.reports"),
              [
                "BookingReport",
                ...(isGym
                  ? []
                  : [
                      "EmployeeReport",
                      "TaxReport",
                      "SalesReport",
                      "PayrollReport",
                    ]),
                "ExpenseReport",
                "ProductReport",
                "PackageReport",
                "GiftCardReport",
                "CommissionReport",
                "NoShowReport",
                { key: "IntakeReport", resource: "Customers" },
              ]
            )}

            {!isGym &&
              renderDropdownListItem(
                "rating",
                rating_svg_dark,
                i18n.t("container.rating"),
                ["Rating", "RatingForm"]
              )}
            {!isGym && renderListItem("Wallet")}
            {renderDropdownListItem(
              "billing",
              billing_svg_dark,
              i18n.t("container.billing"),
              [
                "PricingPlans",
                "BillingHistory",
                "AccountSettings",
                "DevicesAndEquipment",
              ]
            )}
            {renderDropdownListItem(
              "taxes",
              taxes_svg_dark,
              i18n.t("container.taxes"),
              ["TaxList", "AddTax"]
            )}
            <ListItem
              button
              className={`side-bar-item`}
              onClick={() => handleLogout()}
            >
              <ListItemIcon>
                <img src={logout_svg_dark} />
              </ListItemIcon>
              <ListItemText primary={i18n.t("logout")} />
            </ListItem>
          </ul>
        )
      }
      case "employee": {
        return (
          <ul>
            {renderListItem("EmployeeDashboard")}
            {renderListItem("EmployeeProfile")}
            {renderDropdownListItem(
              "appointments",
              my_appointment_svg_dark,
              i18n.t("container.my_appointments"),
              ["EmployeeMyAppointment", "EmployeeBookingHistory"]
            )}
            {renderListItem("EmployeeWorkingHours")}
            {renderListItem("EmployeeRating")}
            {renderListItem("EmployeeActiveSaloon")}
            {renderListItem("EmployeeAllCustomers")}
            {renderListItem("EmployeeSocialMedia")}
            {renderListItem("EmployeeUploadMedia")}
            {renderListItem("EmployeeActivitiesSchedule")}
            {renderListItem("EmployeeWallet")}
            {renderListItem("EmployeeRequestFunds")}
            <ListItem
              button
              className={`side-bar-item`}
              onClick={() => handleLogout("employee")}
            >
              <ListItemIcon>
                <img src={logout_svg_dark} />
              </ListItemIcon>
              <ListItemText primary={i18n.t("logout")} />
            </ListItem>
          </ul>
        )
      }
    }
  }
  return (
    <div className="custom-sidebar">
      <div className="text-center py-2 side-bar-logo">
        <img src={Images.easy1_logo} style={{ height: 40 }} />
      </div>
      <div
        className="sidebar-list"
        style={{
          paddingBottom: screenSize == "mobile" ? "var(--appbar-height)" : 0,
        }}
      >
        {menuItems?.length > 1 ? (
          <Carousel
            responsive={responsive}
            partialVisible={menuItems?.length > 2 ? true : false}
            ref={carouselRef}
            sliderClass={menuItems?.length === 2 ? "w-100" : ""}
          >
            {menuItems?.map((menuItem, index) => (
              <Button
                key={index}
                className={`carousel-item ${
                  menuItem === selectedMenu ? "selected" : ""
                }`}
                onClick={() => {
                  onCurrentChange(menuItem)
                  setSelectedMenu(menuItem)
                }}
              >
                {i18n.t(`module_selector_${menuItem}`)}
              </Button>
            ))}
          </Carousel>
        ) : (
          <div className="mt-5"></div>
        )}

        {renderCurrentMenu()}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  saloonLogout: state.saloonLogout,
  employeeLogout: state.employeeLogout,
  receptionistAllowedResource: state.receptionistAccess.data?.resourceMap,
  managerAllowedResource: state.managerAccess.data?.resourceMap,
  ownerAllowedResource: state.ownerAccess.data?.resourceMap,
  instructorAllowedResource: state.instructorAccess.data?.resourceMap,
})

const action = {
  employee_logout,
  saloon_logout,
  clear_logout,
}

export default connect(mapStateToProps, action)(SaloonSidebarMenu)
