import Swal from "sweetalert2"
import i18n from "../i18n"

export default function deleteConfirmation(callBack) {
  Swal.fire({
    title: i18n.t("are_you_sure_delete"),
    text: i18n.t("cannmot_reverse"),
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#ff3600",
    cancelButtonColor: "#354e68",
    confirmButtonText: i18n.t("yes"),
  }).then((result) => {
    if (result.value) {
      callBack()
    }
  })
}
