import React, { useEffect, useMemo, useRef, useState, useCallback } from "react"
import i18n from "../../../../../i18n"
import PrimaryButton from "../../../../Core/PrimaryButton"
import Autocomplete from "@material-ui/lab/Autocomplete"
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator"
import { BaseModal } from "../../../.."
import { InputAdornment, TextField } from "@material-ui/core"
import MenuItem from "@material-ui/core/MenuItem"
import {
  insertRequest,
  updateRequest,
} from "../../../../../redux/actions/Saloon/CompanyCustomerSubscription"
import { useDispatch, useSelector } from "react-redux"
import { ErrorHelper, SuccessHelper } from "../../../../../helpers"
import Datepicker from "../../../../Core/Datepicker/Datepicker"
import CounterInput from "../../../../CounterInput"
import useScreenSize from "../../../../../context/ScreenSize"
import ToggleSelect from "../../../../ToggleSelect"
import Swal from "sweetalert2"
import Select from "../../../../Core/Select/index.jsx"

import {
  getRequest,
  insertRequest as insertCardRequest,
} from "../../../../../redux/actions/CompanyCustomerCard"

import CardManagement from "../../../../Card/CardManagment"

const frequencies = ["Monthly", "Quarterly", "Every 6 Months", "Yearly"]
export default function CustomerSubscriptionUpsert({
  open,
  setOpen,
  customer,
  active,
  allServices = [],
  setLoading,
}) {
  const dispatch = useDispatch()
  const form = useRef()
  const [data, setData] = useState({})

  const [showCards, setShowCards] = useState(false)
  const [selectedCard, setSelectedCard] = useState(null)
  const [showInsertCard, setShowInsertCard] = useState(false)
  const [companySubscription, setCompanySubscription] = useState({})
  const companySubscriptions = useSelector(
    (s) => s.companySubscription.data || []
  )
  const cards = useSelector((state) => state.companyCustomerCard.data || [])
  const statuses = useSelector((state) => state.customerStatus.data)
  const { screenSize } = useScreenSize()
  const serviceMap = useMemo(() => {
    const map = {}
    allServices.map((service) => {
      map[service._id] = service
    })
    return map
  }, [allServices])

  const handleInsertCard = useCallback(
    (payload, callbackMap) => {
      dispatch(
        insertCardRequest(payload, {
          ...callbackMap,
          success: async (data) => {
            if (callbackMap.success) {
              await callbackMap.success(data)

              dispatch(
                getRequest(
                  { _id: customer?._id },
                  {
                    success: () => {
                      setShowCards(true)
                    },
                    failure: () => {
                      ErrorHelper.handleErrors(
                        i18n.t("someting_went_wrong"),
                        true
                      )
                    },
                  }
                )
              )
            }
          },
        })
      )
    },
    [dispatch, customer]
  )

  useEffect(() => {
    if (open) {
      if (active?.cardId) {
        setSelectedCard(active.cardId)
        setShowCards(true)
      }
      setData({
        numberOfSessionsPerWeek: 1,
        ...active,

        services: [
          ...(active?.services || []).map((serviceId) => serviceMap[serviceId]),
        ],
      })
    } else {
      setData({})
      setShowCards(false)
      setSelectedCard(null)
      setShowInsertCard(false)
      setCompanySubscription({})
    }
  }, [open])

  useEffect(() => {
    dispatch(getRequest({ _id: customer?._id }))
  }, [customer])

  const handleAdd = () => {
    const { services } = data
    setLoading(true)
    dispatch(
      (data._id ? updateRequest : insertRequest)(
        {
          ...data,
          companyCustomerId: customer._id,
          cardId: selectedCard,
          _id: data._id,
          services: services.map((service) => service._id),
        },
        {
          success() {
            SuccessHelper.handleSuccess(
              i18n.t(data._id ? "successfully_updated" : "successfully_added"),
              true
            )
            setOpen(false)
            setLoading(false)
          },
          failure() {
            ErrorHelper.handleErrors(
              i18n.t(data._id ? "failed_to_update" : "failed_to_add"),
              true
            )
            setLoading(false)
          },
        }
      )
    )
  }

  return (
    <>
      <BaseModal
        title={i18n.t(data._id ? "edit_subscription" : "add_subscription")}
        containerClassName="half-container"
        content={
          <div className="row mx-0">
            {showInsertCard ? (
              <CardManagement
                removeDefault={false}
                cards={cards}
                getRequest={() => {}}
                insertRequest={handleInsertCard}
                showAddModal={showInsertCard}
                setShowAddModal={(val) => {
                  setShowInsertCard(val)
                  if (!cards.length) {
                    setShowCards(false)
                  }
                }}
                id={customer?._id}
              />
            ) : null}
            <ValidatorForm
              onSubmit={handleAdd}
              ref={form}
              className="row col-12 px-0"
              onError={(error) => {
                console.log(error)
              }}
            >
              <div className="row col-12 px-0 pb-4 align-items-center inline-custom-form inline-custom-form1">
                <label
                  className={`${
                    screenSize === "mobile" ? "col-12 " : ""
                  } font-weight-bold`}
                >
                  {i18n.t("existing_subscription")}
                </label>
                <div className={screenSize === "mobile" ? "col-12" : ""}>
                  <SelectValidator
                    onChange={(e) => {
                      const { services } = e.target.value
                      setCompanySubscription(e.target.value)
                      setData({
                        ...data,
                        ...e.target.value,
                        services: [
                          ...services.map((serviceId) => serviceMap[serviceId]),
                        ],
                        _id: null,
                      })
                    }}
                    select
                    value={companySubscription}
                    variant="outlined"
                  >
                    {companySubscriptions.map((companySubscription) => {
                      return (
                        <MenuItem value={companySubscription}>
                          {companySubscription.name}
                        </MenuItem>
                      )
                    })}
                  </SelectValidator>
                </div>
              </div>
              <div className="row col-12 px-0 pb-4 align-items-center inline-custom-form inline-custom-form1">
                <label
                  className={`${
                    screenSize === "mobile" ? "col-12 " : ""
                  } font-weight-bold`}
                >
                  {i18n.t("price")}
                </label>
                <div className={screenSize === "mobile" ? "col-12" : ""}>
                  <TextValidator
                    type="number"
                    label={i18n.t("price")}
                    value={data.price || ""}
                    onChange={(e) =>
                      setData({ ...data, price: e.target.value })
                    }
                    validators={["required"]}
                    errorMessages={[i18n.t("this_field_is_required")]}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    variant="outlined"
                  />
                </div>
              </div>
              <div className="row col-12 px-0 pb-4 justify-content-center justify-content-md-start align-items-center inline-custom-form inline-custom-form1">
                <label className={`font-weight-bold`}>
                  {i18n.t("sessionsPerWeek")}
                </label>
                <CounterInput
                  value={data.numberOfSessionsPerWeek}
                  onChange={(e) =>
                    setData({ ...data, numberOfSessionsPerWeek: e })
                  }
                />
              </div>
              <div className="row col-12 px-0 pb-4 align-items-center inline-custom-form inline-custom-form1">
                <label
                  className={`${
                    screenSize === "mobile" ? "col-12 " : ""
                  } font-weight-bold`}
                >
                  {i18n.t("frequency")}
                </label>
                <div className={screenSize === "mobile" ? "col-12" : ""}>
                  <SelectValidator
                    label={i18n.t("frequency")}
                    onChange={(e) =>
                      setData({ ...data, frequency: e.target.value })
                    }
                    value={data.frequency || ""}
                    select
                    validators={["required"]}
                    errorMessages={[i18n.t("this_field_is_required")]}
                    variant="outlined"
                  >
                    {frequencies.map((frequency) => {
                      return (
                        <MenuItem key={frequency} value={frequency}>
                          {i18n.t(frequency)}
                        </MenuItem>
                      )
                    })}
                  </SelectValidator>
                </div>
              </div>
              <div className="row col-12 px-0 pb-4 align-items-center inline-custom-form inline-custom-form1">
                <label
                  className={`${
                    screenSize === "mobile" ? "col-12 " : ""
                  } font-weight-bold`}
                >
                  {i18n.t("status")}
                </label>
                <div className={screenSize === "mobile" ? "col-12" : ""}>
                  <SelectValidator
                    label={i18n.t("status")}
                    onChange={(e) =>
                      setData({ ...data, status: e.target.value })
                    }
                    value={data.status || ""}
                    select
                    validators={["required"]}
                    errorMessages={[i18n.t("this_field_is_required")]}
                    variant="outlined"
                  >
                    <MenuItem value="Active">{i18n.t("Active")}</MenuItem>
                    <MenuItem value="Inactive">{i18n.t("Inactive")}</MenuItem>
                  </SelectValidator>
                </div>
              </div>

              <div className="row col-12 px-0 pb-4 align-items-center inline-custom-form inline-custom-form1">
                <label
                  className={`${
                    screenSize === "mobile" ? "col-12 " : ""
                  } font-weight-bold`}
                >
                  {i18n.t("all_services")}
                </label>
                <div className={screenSize === "mobile" ? "col-12" : ""}>
                  <SelectValidator
                    label={i18n.t("all_services")}
                    onChange={(e) =>
                      setData({ ...data, allServices: e.target.value })
                    }
                    value={data.allServices || false}
                    select
                    variant="outlined"
                  >
                    <MenuItem value={true}>{i18n.t("yes")}</MenuItem>
                    <MenuItem value={false}>{i18n.t("no")}</MenuItem>
                  </SelectValidator>
                </div>
              </div>
              <div className="row col-12 px-0 pb-4 align-items-center inline-custom-form inline-custom-form1">
                <label
                  className={`${
                    screenSize === "mobile" ? "col-12 " : ""
                  } font-weight-bold`}
                >
                  {i18n.t("services")}
                </label>
                <Autocomplete
                  className={screenSize === "mobile" ? "col-12" : ""}
                  multiple
                  options={allServices}
                  getOptionLabel={(option) => option.name}
                  value={data.services || []}
                  onChange={(e, value = []) => {
                    setData({ ...data, services: value })
                  }}
                  disableCloseOnSelect={true}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label={i18n.t("services")}
                      variant="outlined"
                    />
                  )}
                />
              </div>
              {!data._id && (
                <>
                  <div className="row col-12 px-0 pb-4 align-items-center inline-custom-form inline-custom-form1">
                    <label
                      className={`${
                        screenSize === "mobile" ? "col-12 " : ""
                      } font-weight-bold`}
                    >
                      {i18n.t("when_payment_due")}
                    </label>
                    <div className={screenSize === "mobile" ? "col-12" : ""}>
                      <SelectValidator
                        label={i18n.t("when_payment_due")}
                        onChange={(e) =>
                          setData({ ...data, onPaymentDue: e.target.value })
                        }
                        value={data.onPaymentDue}
                        select
                        variant="outlined"
                      >
                        <MenuItem value="Autocharge">
                          {i18n.t("autocharge")}
                        </MenuItem>
                        <MenuItem value="Change Status">
                          {i18n.t("change_status")}
                        </MenuItem>
                      </SelectValidator>
                    </div>
                  </div>
                  {data.onPaymentDue == "Change Status" && (
                    <div className="row col-12 px-0 pb-4 align-items-center inline-custom-form inline-custom-form1">
                      <label
                        className={`${
                          screenSize === "mobile" ? "col-12 " : ""
                        } font-weight-bold`}
                      >
                        {i18n.t("change_status_to")}
                      </label>
                      <div className={screenSize === "mobile" ? "col-12" : ""}>
                        <SelectValidator
                          label={i18n.t("change_status_to")}
                          onChange={(e) =>
                            setData({ ...data, changeStatusTo: e.target.value })
                          }
                          value={data.changeStatusTo}
                          select
                          variant="outlined"
                        >
                          {statuses.map((element) => {
                            return (
                              <MenuItem value={element._id} key={element._id}>
                                {element.name}
                              </MenuItem>
                            )
                          })}
                        </SelectValidator>
                      </div>
                    </div>
                  )}
                  <div className="row col-12 px-0 pb-4 align-items-center inline-custom-form inline-custom-form1">
                    <label
                      className={`${
                        screenSize === "mobile" ? "col-12 " : ""
                      } font-weight-bold`}
                    >
                      {i18n.t("start_date")}
                    </label>
                    <div className={screenSize === "mobile" ? "col-12" : ""}>
                      <Datepicker
                        label={i18n.t("start_date")}
                        minDate={new Date()}
                        selected={data.startDate || ""}
                        onChange={(date) => {
                          setData({ ...data, startDate: date })
                        }}
                        wrapperClassName="col-12 mb-1 p-0"
                      />
                    </div>
                  </div>
                </>
              )}
              <div className="row col-12 px-0 pb-4 align-items-center inline-custom-form inline-custom-form1">
                <label className="font-weight-bold">
                  {i18n.t("pay_with_saved_card")}
                </label>
                <ToggleSelect
                  value={showCards}
                  onChange={(e) => {
                    setSelectedCard("")
                    setShowCards(e)
                    if (cards?.length <= 0 && e) {
                      Swal.fire({
                        title: i18n.t("do_you_want_to_save_card"),
                        cancelButtonText: i18n.t("no"),
                        showCancelButton: true,
                        confirmButtonColor: "#ff3600",
                        cancelButtonColor: "#354e68",
                        confirmButtonText: i18n.t("yes"),
                      }).then((result) => {
                        if (result.value) {
                          setShowInsertCard(true)
                        } else {
                          setShowCards(false)
                        }
                      })
                    }
                  }}
                  active={i18n.t("enable")}
                  inactive={i18n.t("disable")}
                />
              </div>
              {showCards && cards.length ? (
                <div className="row col-12 px-0 pb-4 align-items-center inline-custom-form inline-custom-form1">
                  <label className="font-weight-bold">{i18n.t("cards")}</label>
                  <Select
                    variant="outlined"
                    label={i18n.t("card")}
                    value={selectedCard}
                    onChange={(e) => setSelectedCard(e.target.value)}
                    options={cards.map((card) => ({
                      name: card.card.last4,
                      value: card.id,
                    }))}
                  />
                </div>
              ) : null}
              <div className="row mx-0 px-0 justify-content-end mt-3 mb-4 col-12">
                <PrimaryButton
                  className="w-128"
                  props={{
                    type: "submit",
                  }}
                  label={data._id ? i18n.t("save") : i18n.t("create")}
                />
              </div>
            </ValidatorForm>
          </div>
        }
        onClose={() => setOpen(false)}
        open={open}
      />
    </>
  )
}
