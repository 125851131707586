import { connect } from "react-redux"
import React, { useEffect, useMemo, useRef, useState } from "react"
import i18n from "../../../i18n"
import BaseModal from "../../BaseModal"
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator"
import PrimaryButton from "../../Core/PrimaryButton"
import CustomPlacesAutocomplete from "../../PlacesAutocomplete"
import TextField from "@material-ui/core/TextField"
import { FormHelperText } from "@material-ui/core"
import AppBar from "@material-ui/core/AppBar"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import PurchaseClassModal from "../../PurchaseClassModal/PurchaseClassModal"
import { getRequest as getCourseRequest } from "../../../redux/actions/Saloon/Program"
import { removeRequest as removeEnrollmentRequest } from "../../../redux/actions/Saloon/ProgramEnrollment"
import BasicTable from "../../Core/BasicTable"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ErrorHelper, SuccessHelper } from "../../../helpers"
import CollectPaymentModal from "../../PurchaseClassModal/CollectPaymentModal/CollectPaymentModal"
import deleteConfirmation from "../../../helpers/DeleteHelper"
import TabPanel from "../../Core/Tabs/TabPanel"

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

const telephoneRegex = /[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]/g

function StudentUpsert({
  open,
  onClose,
  onSave,
  active = {},
  customers,
  getCourseRequest,
  removeEnrollmentRequest,
  taxes,
}) {
  const form = useRef()
  const [data, setData] = useState({})
  const [phoneNoError, setPhoneNoError] = useState("")
  const [initialized, setInitialized] = useState(false)
  const [addEnrollmentOpen, setAddEnrollmentOpen] = useState(false)
  const [collectPaymentModalOpen, setCollectPaymentModalOpen] = useState(false)
  const [enrollmentToCollect, setEnrollmentToCollect] = useState()
  const [tab, setTab] = useState(0)
  const handleTabChange = (event, newValue) => {
    setTab(newValue)
  }
  console.log(customers)
  const customer =
    customers != undefined && customers.length > 0
      ? customers.find(
          (customer) =>
            customer.phoneNo == data.phoneNo &&
            (customer.email == data.email || customer.email == null) &&
            customer.name == data.name
        )
      : []

  const init = (data) => {
    setData({ ...data })
  }
  useEffect(() => {
    init(active)
    setInitialized(true)
    getCourseRequest({})
  }, [])
  useEffect(() => {
    console.log(active)
    if (active._id != data._id) {
      init(active)
    }
  }, [active])
  const handleChange = (key, value) => {
    data[key] = value
    setData({ ...data })
  }
  const handleSubmit = () => {
    if (validatePhoneNo()) {
      onSave(data)
    }
  }
  const handleEnrollmentDelete = (_id) => {
    removeEnrollmentRequest(
      {
        ids: _id,
      },
      {
        success: (result) => {
          SuccessHelper.handleSuccess(i18n.t("successfully_deleted"), true)
          data.enrollments = data.enrollments.filter(
            (element) => element._id != result.data._id
          )
          setData({ ...active })
        },
        failure: () => {
          ErrorHelper.handleErrors(i18n.t("failed_to_delete"), true)
        },
      }
    )
  }
  const handleTransactionAdd = (newEnrollement) => {
    const index = data.enrollments.findIndex(
      (element) => element._id == newEnrollement._id
    )
    if (index != -1) {
      const newEnrollments = [...data.enrollments]
      newEnrollments[index] = newEnrollement
      setData({ ...data, enrollments: newEnrollments })
    }
  }
  const validatePhoneNo = () => {
    if (
      !data.phoneNo ||
      !data.phoneNo.replace(/\s/g, "").match(telephoneRegex)
    ) {
      setPhoneNoError(i18n.t("invalid_phone_number"))
      return
    }
    setPhoneNoError("")
    return true
  }

  if (!initialized) {
    return null
  }

  return (
    <div>
      {open ? (
        <BaseModal
          open={open}
          title={data._id ? i18n.t("edit_student") : i18n.t("add_student")}
          containerClassName="small-container"
          content={
            <>
              <AppBar position="static" className="custom-tab">
                <Tabs
                  value={tab}
                  onChange={handleTabChange}
                  aria-label="simple tabs example"
                >
                  <Tab label={i18n.t("details")} {...a11yProps(0)} />
                  <Tab
                    label={i18n.t("enrollments")}
                    {...a11yProps()}
                    disabled={!data._id}
                  />
                </Tabs>
              </AppBar>
              <TabPanel value={tab} index={0}>
                <div className="d-flex">
                  <ValidatorForm
                    onSubmit={handleSubmit}
                    ref={form}
                    className="w-100 row mx-0"
                    onError={(error) => {
                      validatePhoneNo()
                    }}
                  >
                    <div className="col-12 px-0 flex-column flex-md-row d-flex align-items-md-center mb-3">
                      <label
                        className="font-weight-bold"
                        style={{ minWidth: "120px" }}
                      >
                        {i18n.t("phone_number")}
                      </label>
                      <div className="w-100">
                        <TextField
                          label={i18n.t("phone_number")}
                          fullWidth
                          value={data.phoneNo}
                          onChange={(e) =>
                            handleChange("phoneNo", e.target.value)
                          }
                          variant="outlined"
                          type="number"
                        />
                        {!!phoneNoError && (
                          <FormHelperText error>{phoneNoError}</FormHelperText>
                        )}
                      </div>
                    </div>
                    <div className="col-12 flex-column flex-md-row px-0 d-flex align-items-md-center mb-3">
                      <label
                        className="font-weight-bold"
                        style={{ minWidth: "120px" }}
                      >
                        {i18n.t("name")}
                      </label>
                      <div className="w-100">
                        <TextValidator
                          className="w-100"
                          label={i18n.t("name")}
                          onChange={(e) => handleChange("name", e.target.value)}
                          value={data.name || ""}
                          validators={["required"]}
                          errorMessages={[i18n.t("name_required")]}
                          variant="outlined"
                        />
                      </div>
                    </div>
                    <div className="col-12 flex-column flex-md-row px-0 d-flex align-items-md-center mb-3">
                      <label
                        className="font-weight-bold"
                        style={{ minWidth: "120px" }}
                      >
                        {i18n.t("profile_email")}
                      </label>
                      <TextValidator
                        containerProps={{ className: "w-100" }}
                        label={i18n.t("profile_email")}
                        onChange={(e) => handleChange("email", e.target.value)}
                        value={data.email || ""}
                        validators={["isEmail"]}
                        errorMessages={[i18n.t("invalid_email")]}
                        variant="outlined"
                      />
                    </div>
                    <div className="col-12 px-0 flex-column flex-md-row d-flex align-items-md-center mb-3">
                      <label
                        className="font-weight-bold"
                        style={{ minWidth: "120px" }}
                      >
                        {i18n.t("address")}
                      </label>
                      <div className="w-100">
                        <CustomPlacesAutocomplete
                          defaultAddress={data.addressText || ""}
                          addressSelected={(newMap) =>
                            handleChange("address", newMap)
                          }
                          variant="outlined"
                        />
                      </div>
                    </div>
                    <div className="col-12 row mx-0 justify-content-end my-4 col-12">
                      <PrimaryButton
                        className="w-128"
                        props={{
                          type: "submit",
                        }}
                        label={data._id ? i18n.t("save") : i18n.t("create")}
                      />
                    </div>
                  </ValidatorForm>
                </div>
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <div className="row mx-0 px-0 justify-content-end mb-2">
                  <PrimaryButton
                    className="w-98"
                    label={i18n.t("add")}
                    onClick={() => {
                      setAddEnrollmentOpen(true)
                    }}
                  />
                </div>
                <BasicTable
                  rows={data.enrollments}
                  schema={[
                    {
                      header: i18n.t("program"),
                      field: "program",
                      accessor: (val) =>
                        val.programId?.name || i18n.t("custom"),
                    },
                    {
                      header: i18n.t("collected"),
                      field: "collected",
                      accessor: (val) =>
                        (val.amount - val.balanceDue).toFixed(2),
                    },
                    {
                      header: i18n.t("balance_due"),
                      field: "balanceDue",
                      accessor: (val) => val.balanceDue.toFixed(2),
                    },
                    {
                      key: "action",
                      header: "",
                      style: { width: 120 },
                      render: (val) => {
                        return (
                          <div className="row mx-0">
                            <FontAwesomeIcon
                              className="view-icon"
                              style={{ backgroundColor: "#F6943B" }}
                              color="white"
                              icon={["fas", "hand-holding-usd"]}
                              onClick={() => {
                                setEnrollmentToCollect(val)
                                setCollectPaymentModalOpen(true)
                              }}
                            />
                            <FontAwesomeIcon
                              className="view-icon"
                              style={{ backgroundColor: "#F6943B" }}
                              color="white"
                              icon={["fas", "trash"]}
                              onClick={() => {
                                deleteConfirmation(() =>
                                  handleEnrollmentDelete(val._id)
                                )
                              }}
                            />
                          </div>
                        )
                      },
                    },
                  ]}
                />
              </TabPanel>
            </>
          }
          onClose={onClose}
        />
      ) : null}
      {addEnrollmentOpen && (
        <PurchaseClassModal
          open={addEnrollmentOpen}
          setOpen={setAddEnrollmentOpen}
          onClose={() => {
            setAddEnrollmentOpen(false)
          }}
          student={data}
          taxes={taxes}
        />
      )}
      {collectPaymentModalOpen ? (
        <CollectPaymentModal
          open={collectPaymentModalOpen}
          customer={customer}
          setOpen={setCollectPaymentModalOpen}
          enrollment={enrollmentToCollect}
          onSuccess={handleTransactionAdd}
        />
      ) : null}
    </div>
  )
}

const mapStateToProps = (state) => ({
  loading: state.vendor.isFetching,
})

const action = { getCourseRequest, removeEnrollmentRequest }

export default connect(mapStateToProps, action)(StudentUpsert)
