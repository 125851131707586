import React, { useEffect, useState } from "react"
import i18n from "../../../../../i18n"
import { BaseModal } from "../../../../../components"
import moment from "moment"
import Tabs from "../../../../../components/Core/Tabs/Tabs"
import "./CampaignUpsertPopup.css"
import CampaignDetails from "./Components/CampaignDetails"
import CampaignCustomers from "./Components/CampaignCustomers"
import CampaignSummary from "./Components/CampaignSummary"
import { useDispatch, useSelector } from "react-redux"
import { getOneRequest } from "../../../../../redux/actions/Saloon/CommunicationsMarketingCampaign"
import CampaignResults from "./Components/CampaignResults"
import CampaignResultCustomers from "./Components/CampaignResultCustomers"

const CampaignUpsertPopup = ({ open, setOpen, active }) => {
  const dispatch = useDispatch()
  const [data, setData] = useState({})
  const title = data._id ? "update_campaign" : "create_campaign"
  const activeCampaign = useSelector(
    (s) => s.communicationsMarketingCampaign.activeCampaign
  )

  const getOne = (_id) => {
    dispatch(getOneRequest({ _id }))
  }

  useEffect(() => {
    if (open) {
      const data = { ...(active || {}) }
      const sendOn = moment(data.sendOn)
      getOne(data._id)

      setData({
        ...data,
        date: sendOn.toDate(),
        time: sendOn.format("HH:mm"),
      })
    }
  }, [open])
  return (
    <BaseModal
      open={open}
      onClose={() => setOpen(false)}
      className="CampaignUpsertPopup"
      containerClassName={"half-container custom-tab"}
      title={
        <div className="row justify-content-between gap-1 w-100">
          <div>{i18n.t(title)}</div>
        </div>
      }
      content={
        <Tabs
          visible={open}
          tabs={[
            {
              title: i18n.t("details"),
              value: "details",
              content: () => (
                <CampaignDetails
                  data={data}
                  setData={setData}
                  getOne={getOne}
                />
              ),
            },
            {
              title: i18n.t("customers"),
              value: "customers",
              hidden: !data._id,
              disabled: !data._id,
              content: () =>
                data.status == "Complete" ? (
                  <CampaignResultCustomers />
                ) : (
                  <CampaignCustomers
                    data={data}
                    setData={setData}
                    getOne={getOne}
                  />
                ),
            },
            {
              title:
                activeCampaign?.status == "Complete"
                  ? i18n.t("results")
                  : i18n.t("summary"),
              value: "summary",
              hidden: !data._id,
              disabled: !data._id,
              content: () =>
                activeCampaign?.status != "Complete" ? (
                  <CampaignSummary
                    data={data}
                    setData={setData}
                    getOne={getOne}
                  />
                ) : (
                  <CampaignResults />
                ),
            },
          ]}
        />
      }
    />
  )
}

export default CampaignUpsertPopup
