import React, { useEffect, useState } from "react";
import { connect } from "react-redux"
import i18n from "../../../i18n";
import BasicTable from "../../Core/BasicTable";
import moment from "moment";
import { getUserWithCompanyGiftCardsRequest } from "../../../redux/actions/GiftCards";
function CustomerGiftCards({
  active = {},
  getUserWithCompanyGiftCardsRequest,
  setLoading
}) {
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoading(true)
    getUserWithCompanyGiftCardsRequest(
      { userId: active.userId },
      {
        success: ({ data, giftCardTransactions }) => {
          setData([...data, ...giftCardTransactions]
            .sort((a, b) => b.createdDate.localeCompare(a.createdDate)))
          setLoading(false)
        },
        failure: () => {
          setLoading(false)
        },
      }
    )
  }, [])

  return (
    <BasicTable
      rows={data || []}
      schema={[
        {
          header: i18n.t("amount"),
          field: "amount",
          accessor: (val) => {
            return `$ ${val.companyCustomerId ? "-" : ""}${val.price?.toFixed(2) || val.amount?.toFixed(2)}`;
          },
        },
        {
          header: i18n.t("type"),
          field: "type",
          accessor: (val) => {
            return `${val.companyCustomerId ? (val.wasReversed ? i18n.t("cancelled") : i18n.t("usage")) : i18n.t("purchase")
              }`;

          },
        },
        {
          header: i18n.t("name"),
          field: "name",
          accessor: (val) => {
            return i18n.t(val.giftCardId?.name);
          },
        },
        {
          header: i18n.t("buyer"),
          field: "buyer",
          accessor: (val) => {
            return val.userId?.userName || val.userId?.firstName;
          },
        },
        {
          header: i18n.t("purchased_date"),
          field: "purchased_date",
          accessor: (val) => {
            if (val.userId)
              return moment(val.createdDate).format("MM-DD-YYYY HH:mm");
          },
        },
        {
          header: i18n.t("usage_date"),
          field: "usage_date",
          accessor: (val) => {
            if (val.companyCustomerId)
              return moment(val.createdDate).format("MM-DD-YYYY HH:mm");
          },
        },
        {
          header: i18n.t("created_by"),
          field: "created_by",
          accessor: (val) => {
            return val.createdBy?.firstName;
          },
        },
      ]}
    />
  );
}
const mapStateToProps = (state) => ({
})

const action = {
  getUserWithCompanyGiftCardsRequest,
}

export default connect(mapStateToProps, action)(CustomerGiftCards)