// @flow
import Immutable from "seamless-immutable"
import * as types from "../actions/ActionTypes"

const initialState = Immutable({
  isFetching: false,
  errorMessage: "",
  data: [],
  accountDetails: {},
})

export default (state = initialState, action) => {
  switch (action.type) {
  case types.REOCCURING_COMPANY_UNAVAILABILITIES.GET_SAGA:
    return Immutable.merge(state, {
      isFetching: true,
    })
  case types.REOCCURING_COMPANY_UNAVAILABILITIES.GET:
    return Immutable.merge(state, {
      isFetching: false,
      data: action.data,
    })
  case types.REOCCURING_COMPANY_UNAVAILABILITIES.INSERT: {
    const newState = { ...state, isFetching: false, data: [...state.data] }
    const { _id } = action.data
    const index = state.data.findIndex((element) => element._id == _id)
    newState.data.unshift(action.data)
    return newState
  }
  case types.REOCCURING_COMPANY_UNAVAILABILITIES.DELETE: {
    const newState = { ...state, isFetching: false, data: [...state.data] }
    const { _id } = action.data
    const index = state.data.findIndex((element) => element._id == _id)
    if (index != -1) {
      newState.data.splice(index, 1)
    }
    return newState
  }
  case types.REOCCURING_COMPANY_UNAVAILABILITIES.DELETE_SAGA:
    return Immutable.merge(state, {
      isFetching: true,
    })

  case types.LOGOUT:
    return initialState
  case types.CLEAR_LOGOUT:
    return initialState
  default:
    return state
  }
}
