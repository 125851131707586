import React, { useState } from "react";
import TouchKeyPad from "../../TouchKeyPad/TouchKeyPad";
import i18n from "../../../i18n";
import TouchChip from "../../Partner/CreateAppointment/Support/TouchChip";

const DiscountKeyPad = ({
  dollarAmount = 0,
  onChangeDollarAmount,
  percentAmount = 0,
  onChangePercentAmount,
}) => {
  const [type, setType] = useState("dollar");

  return (
    <div>
      <div className="d-sm-flex mx-0">
        <div className="col-sm-6 px-sm-1 mb-2">
          <TouchChip
            label={`${i18n.t("amount")} ($ ${dollarAmount.toFixed(2)})`}
            onClick={() => setType("dollar")}
            selected={type == "dollar"}
          />
        </div>
        <div className="col-sm-6 px-sm-1 mb-2">
          <TouchChip
            label={`${i18n.t("percent")} (% ${percentAmount.toFixed(2)})`}
            onClick={() => setType("percent")}
            selected={type == "percent"}
          />
        </div>
      </div>

      <TouchKeyPad
        inputType={type}
        value={type === "percent" ? percentAmount : dollarAmount}
        setValue={(value) => {
          if (type === "percent") {
            onChangePercentAmount(value ? parseFloat(value) : 0);
          } else {
            onChangeDollarAmount(value ? parseFloat(value) : 0);
          }
        }}
      />
    </div>
  );
};

export default DiscountKeyPad;
