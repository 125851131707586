import React, { useState } from "react";
import ThreeDButton from "../../Core/3DButton/ThreeDButton";
import TouchKeyPad from "../../TouchKeyPad/TouchKeyPad";

const TipKeyPad = ({ handleTipPercent, value = 0, setValue, readOnly }) => {
  const buttonStyle = { height: 34, fontSize: 16 };
  const buttonWrapperStyke = { borderRadius: 7 };
  return (
    <div>
      <TouchKeyPad
        value={value}
        setValue={(value) => {
          setValue(value ? parseFloat(value) : 0);
        }}
        renderPreKeyPad={() => {
          if (readOnly) {
            return null;
          }
          return (
            <div className="row mx-0">
              <div className="col-4 p-2">
                <ThreeDButton
                  label="15%"
                  style={buttonStyle}
                  wrapperStyle={buttonWrapperStyke}
                  onClick={() => handleTipPercent(15)}
                />
              </div>
              <div className="col-4 p-2">
                <ThreeDButton
                  label="25%"
                  style={buttonStyle}
                  wrapperStyle={buttonWrapperStyke}
                  onClick={() => handleTipPercent(25)}
                />
              </div>
              <div className="col-4 p-2">
                <ThreeDButton
                  label="35%"
                  style={buttonStyle}
                  wrapperStyle={buttonWrapperStyke}
                  onClick={() => handleTipPercent(35)}
                />
              </div>
            </div>
          );
        }}
      />
    </div>
  );
};

export default TipKeyPad;
