// @flow
import Immutable from "seamless-immutable"
import * as types from "../../actions/ActionTypes"

const initialState = Immutable({
  isFetching: false,
  errorMessage: "",
  data: [],
})

export default (state = initialState, action) => {
  switch (action.type) {
  case types.AUTO_BOOKING_SCHEDULE.GET:
    return Immutable.merge(state, {
      isFetching: false,
      data: action.data,
    })
  case types.AUTO_BOOKING_SCHEDULE.GET_SAGA:
  case types.AUTO_BOOKING_SCHEDULE.INSERT_SAGA:
  case types.AUTO_BOOKING_SCHEDULE.DELETE_SAGA:
  case types.AUTO_BOOKING_SCHEDULE.APPLY_TEMPLATE_SAGA:
  case types.AUTO_BOOKING_SCHEDULE.UPDATE_SAGA:
  case types.AUTO_BOOKING_SCHEDULE.DELETE_ALL_EVENTS_SAGA:
    return Immutable.merge(state, {
      isFetching: true,
    })
  case types.AUTO_BOOKING_SCHEDULE.APPLY_TEMPLATE:
  case types.AUTO_BOOKING_SCHEDULE.INSERT: {
    const newState = { ...state, isFetching: false, data: [...state.data] }
    const normalized = Array.isArray(action.data)
      ? action.data
      : [action.data]
    normalized.map((element) => {
      const { _id } = element
      const index = state.data.findIndex((element) => element._id == _id)
      if (index != -1) {
        newState.data[index] = element
      } else {
        newState.data.push(element)
      }
    })
    return newState
  }
  case types.AUTO_BOOKING_SCHEDULE.DELETE: {
    const newState = { ...state, isFetching: false, data: [...state.data] }
    const { _id } = action.data
    const index = state.data.findIndex((element) => element._id == _id)
    if (index != -1) {
      newState.data.splice(index, 1)
    }
    return newState
  }
  case types.AUTO_BOOKING_SCHEDULE.UPSERT_EVENT: {
    const newState = { ...state, isFetching: false, data: [...state.data] }
    const normalized = Array.isArray(action.data)
      ? action.data
      : [action.data]
    normalized.map((element) => {
      const { autoBookingScheduleId, _id } = element
      const index = state.data.findIndex(
        (element) => element._id == autoBookingScheduleId
      )
      if (index != -1) {
        const eventIndex = newState.data[index].events.findIndex(
          (element) => element._id == _id
        )
        if (eventIndex == -1) {
          newState.data[index] = {
            ...newState.data[index],
            events: [...newState.data[index].events, ...normalized],
          }
        } else {
          const newEvents = [...newState.data[index].events]
          newEvents[eventIndex] = element
          newState.data[index] = {
            ...newState.data[index],
            events: newEvents,
          }
        }
      }
    })
    return newState
  }
  case types.AUTO_BOOKING_SCHEDULE.DELETE_EVENT: {
    const newState = { ...state, isFetching: false, data: [...state.data] }
    const { autoBookingScheduleId, _id } = action.data
    const index = state.data.findIndex(
      (element) => element._id == autoBookingScheduleId
    )
    if (index != -1) {
      const eventIndex = newState.data[index].events.findIndex(
        (element) => element._id == _id
      )
      if (eventIndex != -1) {
        const copy = {
          ...newState.data[index],
          events: [...newState.data[index].events],
        }
        copy.events.splice(eventIndex, 1)
        newState.data[index] = copy
      }
    }
    return newState
  }
  case types.AUTO_BOOKING_SCHEDULE.DELETE_ALL_EVENTS: {
    const newState = { ...state, isFetching: false, data: [...state.data] }
    const { _id } = action.data
    const index = state.data.findIndex((element) => element._id == _id)
    if (index != -1) {
      newState.data[index].events = []
    }
    return newState
  }
  case types.AUTO_BOOKING_SCHEDULE.UPDATE: {
    const newState = { ...state, isFetching: false, data: [...state.data] }
    const { _id } = action.data
    const index = state.data.findIndex((element) => element._id == _id)
    if (index != -1) {
      newState.data[index] = { ...newState.data[index], ...action.data }
    }
    return newState
  }
  case types.AUTO_BOOKING_SCHEDULE.REQUEST_FAILURE:
    return Immutable.merge(state, {
      isFetching: false,
    })

  case types.LOGOUT:
    return initialState
  case types.CLEAR_LOGOUT:
    return initialState
  default:
    return state
  }
}
