import React, { Component } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import i18n from "../../../i18n";
import moment from "moment";
import "../styles/wallet.sass";
class Wallet extends Component {
  renderTableHead = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell className="tableHead">
            {i18n.t("container.profile_my_wallet")}
          </TableCell>
          <TableCell className="tableHead">{i18n.t("profile_date")}</TableCell>
          <TableCell className="tableHead">
            {i18n.t("container.profile_points_left")}
          </TableCell>
          <TableCell className="tableHead">
            {i18n.t("container.profile_points_remaining")}
          </TableCell>
        </TableRow>
      </TableHead>
    );
  };

  renderTableBody = () => {
    const { allWalletInfo } = this.props;

    return (
      <TableBody>
        {allWalletInfo &&
          allWalletInfo.map((waletInfo) => {
            return (
              <TableRow>
                <TableCell>
                  {waletInfo.bookingId?.companyId?.name
                    ? waletInfo.bookingId?.companyId?.name
                    : "REMOVED"}
                </TableCell>
                <TableCell>
                  {moment().format("DD-MM-YYYY", waletInfo.createdDate)}
                </TableCell>
                <TableCell>
                  {waletInfo.type == "Credit"
                    ? `+${waletInfo.paidAmount}`
                    : `-${waletInfo.paidAmount}`}
                </TableCell>
                <TableCell>{waletInfo.totalAmount}</TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    );
  };
  renderTable = () => {
    return (
      <TableContainer>
        <Table stickyHeader component={Paper}>
          {this.renderTableHead()}
          {this.renderTableBody()}
        </Table>
      </TableContainer>
    );
  };
  render() {
    return (
      <div className="profileWallet">
        <h2>Wallet</h2>

        {this.renderTable()}
      </div>
    );
  }
}

export default Wallet;
