// @flow

import { RECEPTIONIST_ACCESS } from "./ActionTypes"

export function getRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: RECEPTIONIST_ACCESS.GET_SAGA,
  }
}

export function get(data) {
  return {
    data,
    type: RECEPTIONIST_ACCESS.GET,
  }
}

export function updateRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: RECEPTIONIST_ACCESS.UPDATE_SAGA,
  }
}

export function update(data) {
  return {
    data,
    type: RECEPTIONIST_ACCESS.UPDATE,
  }
}

export function requestFailure() {
  return {
    type: RECEPTIONIST_ACCESS.REQUEST_FAILURE,
  }
}
