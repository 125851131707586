import styled from "styled-components";

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ spacing = 1 }) => spacing * 0.25}rem!important;
`;

export default FormContainer;
