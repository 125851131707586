// @flow

import { SUBSCRIPTION } from "../ActionTypes"

export function getRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: SUBSCRIPTION.GET_SAGA
  }
}

export function set(data) {
  return {
    data,
    type: SUBSCRIPTION.SET
  }
}

export function removeRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: SUBSCRIPTION.DELETE_SAGA
  }
}

export function remove(data) {
  return {
    data,
    type: SUBSCRIPTION.DELETE
  }
}

export function insertRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: SUBSCRIPTION.INSERT_SAGA
  }
}

export function insert(data) {
  return {
    data,
    type: SUBSCRIPTION.INSERT
  }
}

export function updateRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: SUBSCRIPTION.UPDATE_SAGA
  }
}

export function update(data) {
  return {
    data,
    type: SUBSCRIPTION.UPDATE
  }
}

export function requestFailure() {
  return {
    type: SUBSCRIPTION.REQUEST_FAILURE
  }
}
