import { useDispatch } from "react-redux"
import React, { useEffect, useState } from "react"
import i18n from "../../../i18n"
import {
  getRequest,
  upsertRequest,
} from "../../../redux/actions/Saloon/CompanyCustomerGroups"
import PrimaryButton from "../../../components/Core/PrimaryButton"
import { DotsLoader } from "../../../components"
import { ErrorHelper, SuccessHelper } from "../../../helpers"
import CustomerGroupUpsert from "./Components/CustomerGroupUpsert"
import CustomerTable from "./Components/CustomerTable"
import { useSelector } from "react-redux"
import useCache from "../../../context/LocalCache"

export default function CustomerGroups({}) {
  const { getResource } = useCache()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [active, setActive] = useState(false)
  const customerGroups = useSelector((s) => s.companyCustomerGroups.data || [])
  const loading = useSelector((s) => s.companyCustomerGroups.isFetching)
  useEffect(() => {
    dispatch(getRequest({}))
  }, [])
  const customers = getResource((cache) => cache.company.customers)
  const onClose = () => {
    setOpen(false)
    setActive({})
  }
  const onSave = (data) => {
    dispatch(
      upsertRequest(data, {
        success: ({ data }) => {
          SuccessHelper.handleSuccess(
            i18n.t(data._id ? "successfully_updated" : "successfully_added"),
            true
          )
          setActive(data)
        },
        failure: () => {
          ErrorHelper.handleErrors(
            i18n.t(data._id ? "failed_to_update" : "failed_to_add"),
            true
          )
        },
      })
    )
  }
  const onEdit = (data) => {
    setActive(data)
    setOpen(true)
  }
  return (
    <div className="content-container p-4">
      <DotsLoader isloading={loading} />
      <div className="d-flex flex-column flex-md-row gap-2 justify-content-between align-items-center mb-3">
        <h1 className="saloon-dashboard-heading">
          {i18n.t("customer_groups")}
        </h1>
        <PrimaryButton
          className="w-128"
          label={i18n.t("create")}
          onClick={() => {
            setOpen(true)
          }}
        />
      </div>
      <CustomerTable rows={customerGroups} onEdit={onEdit} />

      <CustomerGroupUpsert
        open={open}
        active={active}
        onClose={onClose}
        onSave={onSave}
        customers={customers}
      />
    </div>
  )
}
