import { connect } from "react-redux"
import React, { Component } from "react"
import { Translation } from "react-i18next"
import i18n from "../../i18n"

import NumberFormat from "react-number-format"
import DatePicker from "react-datepicker"
import moment from "moment"
import "./styles.css"
import BaseModal from "../BaseModal/index.jsx"
import TextField from "@material-ui/core/TextField"
import Select from "../Core/Select/index.jsx"
import PrimaryButton from "../../../src/components/Core/PrimaryButton/index.jsx"

class AdvancedSearchDialog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      date: "",
      name: "",
      employee: "",
      service: "",
      status: "",
    }
    this.phoneNumber = React.createRef()
  }

  onChangeSelectDateAndTime = (date) => {
    this.setState({ date: moment(date).format("MM-DD-YYYY") })
  }

  setStartDate = (val) => {
    const { endDate } = this.state
    if (val > endDate) {
      this.setState({
        startDate: endDate,
        endDate: val,
      })
    } else {
      this.setState({
        startDate: val,
      })
    }
  }

  setEndDate = (val) => {
    const { startDate } = this.state
    if (val < startDate) {
      this.setState({
        startDate: val,
        endDate: startDate,
      })
    } else {
      this.setState({
        endDate: val,
      })
    }
  }

  render = () => {
    const {
      hideFitlerDetails,
      services = [],
      employees,
      extraStatues = [],
    } = this.props
    const { startDate, endDate } = this.state
    const statuses = [
      { text: "Done", value: 4 },
      { text: "Cancel", value: 3 },
      ...extraStatues,
    ]
    return (
      <BaseModal
        open={true}
        // containerClassName={"half-container"}
        {...{
          title: i18n.t("container.advancedSearchDialog_advanced_search"),
          content: (
            <>
              <div className="row">
                <div className=" col-md-6 col-xs-12">
                  <TextField
                    type="text"
                    className="mt-1"
                    label={i18n.t("container.advancedSearchDialog_name")}
                    onChange={(e) => this.setState({ name: e.target.value })}
                  />
                </div>
                <div className=" col-md-6 col-xs-12">
                  <NumberFormat
                    type="text"
                    className=" mt-1"
                    ref={this.phoneNumber}
                    format="### ### ####"
                    customInput={TextField}
                    label={i18n.t(
                      "container.advancedSearchDialog_phone_number"
                    )}
                  />
                </div>
                <div className="col-md-6 col-xs-12">
                  <Select
                    id="function-role"
                    // ref={this.service}
                    onChange={(e) => this.setState({ service: e.target.value })}
                    label={i18n.t("container.profile_service")}
                    options={services.map((service) => ({
                      name: service,
                      value: service,
                    }))}
                  />
                </div>
                <div className="col-md-6 col-xs-12 row">
                  <div className="col-6 pl-0">
                    <DatePicker
                      onChange={(data) => this.setStartDate(data)}
                      dateFormat="MM/dd/yyyy"
                      wrapperClassName="w-100"
                      selected={startDate}
                      customInput={
                        <TextField
                          style={{ width: "100%" }}
                          label="Start Date"
                        />
                      }
                    />
                  </div>
                  <div className="col-6 pr-0">
                    <DatePicker
                      onChange={(data) => this.setEndDate(data)}
                      dateFormat="MM/dd/yyyy"
                      wrapperClassName="w-100"
                      selected={endDate}
                      customInput={
                        <TextField style={{ width: "100%" }} label="End Date" />
                      }
                    />
                  </div>
                </div>
                {employees && (
                  <div className="col-md-6 col-xs-12">
                    <Select
                      id="function-role"
                      label={i18n.t("container.employee")}
                      onChange={(e) =>
                        this.setState({ employee: e.target.value })
                      }
                      options={employees.map((employee) => ({
                        name: employee.text,
                        value: employee.value,
                      }))}
                    />
                  </div>
                )}
                <div className="col-md-6 col-xs-12">
                  <Select
                    id="function-role"
                    onChange={(e) => this.setState({ status: e.target.value })}
                    label={i18n.t("container.profile_status")}
                    options={statuses.map((status) => ({
                      name: status.text,
                      value: status.value,
                    }))}
                  />
                </div>
              </div>
            </>
          ),
          actions: (
            <div
              className="col-12"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <PrimaryButton
                onClick={() => {
                  const extra = {}
                  if (this.state.employee) {
                    extra.employee = this.state.employee
                  }
                  this.props.onFilter({
                    name: this.state.name,
                    phoneNumber: this.phoneNumber.current
                      ? this.phoneNumber.current.state.numAsString
                      : "",
                    service: this.state.service,
                    startDate: this.state.startDate,
                    endDate: this.state.endDate,
                    status: this.state.status,
                    ...extra,
                  })
                }}
                type="button"
                id="saloon-save-social-link"
              >
                <Translation>
                  {(t) => t("container.advancedSearchDialog_filter")}
                </Translation>
              </PrimaryButton>
            </div>
          ),
        }}
        onClose={() => this.props.onClose()}
      />
    )
  }
}

const mapStateToProps = (state) => ({})

const action = {}

export default connect(mapStateToProps, action)(AdvancedSearchDialog)
