import { Translation, withTranslation } from "react-i18next"
import i18n from "../../i18n"
import { CircularProgress } from "@material-ui/core"
import { connect } from "react-redux"
import React, { Component, useRef } from "react"
import Pagination from "react-js-pagination"
import { DotsLoader } from "../../components"
import { Images } from "../../theme"
import { Footer, Sidebar } from "../../components"
import { request as user_logout } from "../../redux/actions/LogOut"
import { request as get_home_page_info } from "../../redux/actions/GetHomePageInfo"
import { request as get_home } from "../../redux/actions/GetHome"
import { clearLogout as clear_logout } from "../../redux/actions/LogOut"
import { SuccessHelper } from "../../helpers"
import homeBackground from "../../../src/assets/Images/home_background.png"
import InfiniteScroll from "react-infinite-scroll-component"
import { baseUrl } from "../../config/WebServices"
import {
  saloonNearby,
  getAllSaloons,
  locationSuggestions,
  getServices,
  getSaloonsByCategory,
  searchByName,
} from "../../config/simpleApiCalls"
import { FormControlLabel, Checkbox } from "@material-ui/core"
import "./styles.css"
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete"
import Slider from "react-slick"

import ReactStars from "react-rating-stars-component"
import { Button, MenuList, MenuItem } from "@material-ui/core"
import PrimaryButton from "../../components/Core/PrimaryButton/index.jsx"
import SharedStorage from "../../helpers/Storage"
import Broswer from "../../helpers/Browser"
import {
  categoryIcons,
  categoryIconsActive,
  infoIcons,
  infoIconsActive,
} from "../../assets/market-icons/marketIcons"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"
const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#137cbd",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  },
})

// Inspired by blueprintjs
function StyledCheckbox(props) {
  const classes = useStyles()

  return (
    <Checkbox
      className={`${classes.root} py-1`}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      inputProps={{ "aria-label": "decorative checkbox" }}
      {...props}
    />
  )
}
class Home extends Component {
  constructor(props) {
    super(props)
    this.searchSection = React.createRef()
    this.state = {
      showSidebar: false,
      user: null,
      isloading: false,
      latitude: null,
      longitude: null,
      radius: null,
      allSaloons: [],
      sortedSaloonsByCategory: [],
      templateList: [],
      userName: "",
      isCheckedGeo: false,
      checkedStars: [],
      showDrop: false,
      locationPredictionData: [],
      searchSalonNameText: "",
      searchLocationText: "",
      searchCategoryText: "",
      searchCategoryList: [],
      serviceList: [],
      filteredSalon: [],
      selectedCategory: null,
      selectedTab: "",
      address: "",
      homePage: {
        _id: "1234342",
        header: {
          img_url: "https://example.com/test.png",
          title: "string",
          subTitle: "string",
          des: "string",
          _id: "23234",
        },
        body: {
          mainTitle: "String",
          img_url: "https://example.com/test.png",
          listItem: [
            {
              img_url: "https://example.com/test.png",
              title: "string",
              des: "string",
              _id: "213123123",
            },
            {
              img_url: "https://example.com/test.png",
              title: "string",
              des: "string",
              _id: "",
            },
          ],
        },
        muchMore: {
          mainTitle: "String",
          listItem: [
            {
              img_url: "https://example.com/test.png",
              title: "string",
              des: "string",
              _id: "213123123",
            },
            {
              img_url: "https://example.com/test.png",
              title: "string",
              des: "string",
              _id: "",
            },
          ],
        },
        footer: {
          mainTitle: "String",
          img_url: "https://example.com/test.png",
          des: "String",
          _id: "string",
          listItem: [
            {
              title: "string",
              appUrl: "com.apple.com",
              icon: "https://IOS",
              _id: "string",
            },
            {
              title: "string",
              appUrl: "com.android.com",
              icon: "https://android",
              _id: "string",
            },
          ],
        },
      },
      showDropdownCategory: false,
      ratings: [
        { isChecked: false, value: 1 },
        { isChecked: false, value: 2 },
        { isChecked: false, value: 3 },
        { isChecked: false, value: 4 },
        { isChecked: false, value: 5 },
      ],
      hasMore: true,
      index: 1,
    }
  }

  async componentDidMount() {
    this.setState({ isloading: true })

    const userData = JSON.parse(await SharedStorage.getItem("user"))

    if (userData) {
      this.setState({
        user: userData,
        userName: userData.firstName,
        language: localStorage.getItem("language") == "en" ? "en" : "fr",
      })
    }
    this.getAllServices()
    this.getAllSalonWithoutLocation()

    if (!this.props.getHomePageInfo?.data?.data) {
      this.props.get_home_page_info()
    }
    if (!this.props.getHome?.data) {
      this.props.get_home()
    }
  }

  LanguageSelector = () => {
    const changeLanguage = (language) => {
      i18n.changeLanguage(language)
      this.setState({ language })
      SharedStorage.setItem("language", language)
      localStorage.setItem("language", language)
    }

    return (
      <Button
        onClick={() =>
          changeLanguage(this.state.language === "en" ? "fr" : "en")
        }
      >
        {this.state.language === "en" ? "fr" : "en"}
      </Button>
    )
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.logout) {
      if (
        !nextProps.logout.failure &&
        !nextProps.logout.isFetching &&
        !nextProps.logout.errorMessage &&
        nextProps.logout.data &&
        nextProps.logout.data.success
      ) {
        nextProps.clear_logout()

        this.setState({ user: null })
      } else if (
        nextProps.logout.failure &&
        !nextProps.logout.isFetching &&
        nextProps.logout.errorMessage
      ) {
        this.setState({ isloading: false })
      }
    }
  }

  fetchMoreData = () => {
    const { index, allSaloons } = this.state
    getAllSaloons({
      skip: `${index}0`,
      limit: 10,
    })
      .then(({ data }) => {
        this.setState({ allSaloons: [...allSaloons, ...data.saloons] })
        data.saloons.length > 0
          ? this.setState({ hasMore: true })
          : this.setState({ hasMore: false })
      })
      .catch((err) => console.log(err))
    this.setState({ index: index + 1 })
  }
  getAllServices = () => {
    getServices()
      .then((res) => {
        if (res.data.success) {
          this.setState({
            isloading: false,
            serviceList: res.data.data,
            searchCategoryList: res.data.data,
          })
        }
      })
      .catch((error) => {
        this.setState({ isloading: false })
        console.log(error, "errorrrrrrrrrrrrrrrrrrrqqqqqqqqqq")
      })
  }

  getGeolocation = () => {
    let $this = this

    navigator.geolocation.getCurrentPosition(function (position) {
      $this.setState(
        {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          radius: 5,
        },
        () => $this.handleSaloonNearby()
      )
    })
  }

  getAllSalonWithoutLocation = () => {
    getAllSaloons({ skip: 0, limit: 10 })
      .then((res) => {
        if (res.data.success) {
          this.setState({ isloading: false, allSaloons: res.data.saloons })
        }
      })
      .catch((error) => {
        this.setState({ isloading: false })

        console.log(error, "errorrrrrrrrrrrrrrrrrrrrrrrrrrr")
      })
  }

  handleSaloonNearby = async () => {
    //this.setState({ isloading: true })
    const { latitude, longitude, radius } = this.state
    const payload = { latitude, longitude }
    this.handleApplyFilter()
  }

  handlePreviewBtn = async (data) => {
    const user = await JSON.parse(await SharedStorage.getItem("user"))
    let additional = ""
    if (user) {
      additional = `&phoneNo=${user ? user.phoneNo : ""}&name=${
        user ? user.userName : ""
      }`
    }
    window.location.assign(
      `${process.env.REACT_APP_IFRAME_URL}${data.shortenedId}${additional}`
    )
  }

  handleNavigation = (route, paramsData) => {
    this.props.history.push(route, paramsData)
  }

  handleSearchCategory = (event) => {
    this.setState({
      searchCategoryText: event.target.value,
    })
  }

  handleSelectedCategory = (selectedCategory) => {
    this.setState(
      {
        isloading: true,
        selectedCategory,
        searchCategoryText: selectedCategory.name,
      },
      () => {
        this.handleApplyFilter()
      }
    )
  }

  handleSalonByName(searchByName) {
    this.setState(
      {
        searchSalonNameText: searchByName.target.value,
      },
      () => this.handleApplyFilter()
    )
  }

  handleLogout = () => {
    const { user } = this.state
    this.props.user_logout({})
    this.setState({ user: null })
    this.handleNavigation("/")
  }

  handleCheckGeoInput = (event) => {
    this.setState(
      { isloading: true, isCheckedGeo: event.target.checked },
      () => {
        this.getGeolocation()
      }
    )
  }

  handleReset = () => {
    this.setState(
      {
        isloading: true,
        searchCategoryText: "",
        selectedCategory: null,
        searchSalonNameText: "",
        searchLocationText: "",
        address: "",
        isCheckedGeo: false,
        filteredSalon: [],
        ratings: [
          { isChecked: false, value: 1 },
          { isChecked: false, value: 2 },
          { isChecked: false, value: 3 },
          { isChecked: false, value: 4 },
          { isChecked: false, value: 5 },
        ],
        // isCheckedGeo: false,
      },
      () => this.handleApplyFilter()
    )
  }

  renderJumbotron = () => {
    const { homeInfo } = this.props

    return (
      <div
        className="container-fluid px-lg-4"
        style={{ backgroundColor: "#F7F8FA" }}
      >
        {this.renderHeaderSm()}
        {this.renderHeaderLg()}
      </div>
    )
  }

  renderOurCategories = () => {
    const { serviceList, language } = this.state
    const windowWindth = window.innerWidth
    let slidesToShow = 8
    let toDevideBy = windowWindth >= 768 ? 115 : 75
    const settings = {
      dots: false,
      arrows: false,
      infinite: false,
      speed: 500,
      slidesToShow: windowWindth / toDevideBy,
      slidesToScroll: -2,
    }
    return (
      <div className="fullwidth">
        <Slider {...settings}>
          {serviceList.map((service, ind) => {
            return (
              <div className="serviceCard my-3">
                <div
                  className="service-card-image-container-wrapper"
                  onClick={() => {
                    this.handleSelectedCategory(service)
                  }}
                >
                  <div
                    className="p-0 service-card-image-container text-center row mx-0"
                    style={{}}
                  >
                    <img
                      style={{
                        width: `65%`,
                        paddingTop: "8%",
                        paddingBottom: "8%",
                      }}
                      src={categoryIcons[ind]}
                      alt="Card image cap"
                    />
                  </div>
                </div>
                <p className="text-center pt-2 px-1 responsive-text-size">
                  {service.translations
                    ? language === "en"
                      ? service.translations.en
                      : service.translations.fr
                    : service.name}
                </p>
              </div>
            )
          })}
        </Slider>
      </div>
    )
  }

  openInNewTab = (url) => {
    const newWindow = Broswer.open(url, "_blank", "noopener,noreferrer")
    if (newWindow) newWindow.opener = null
  }

  renderHeaderLg = () => {
    const { user, showDrop, showSidebar, isLoginMenuOpen } = this.state
    const options = [
      <Translation>{(t) => t("english")}</Translation>,
      <Translation>{(t) => t("french")}</Translation>,
    ]
    const defaultOption = options[0]
    return (
      <div
        className="header-lg row py-3 "
        style={{ marginRight: "15px", marginLeft: "15px" }}
      >
        <img
          alt="easy1 logo"
          src={Images.easy1_logo}
          className="cursor-pointer"
          style={{ zIndex: 2, height: 48, objectFit: "contain" }}
          onClick={() => this.handleNavigation("/")}
        />
        <span className="d-flex align-items-center" style={{ zIndex: 2 }}>
          <Button
            onClick={() =>
              this.openInNewTab("https://www.easy1booking-business.com/")
            }
          >
            <Translation>{(t) => t("home.for_business")}</Translation>
          </Button>
          <span>{this.LanguageSelector()}</span>

          {!user && (
            <Button
              type="button"
              style={{
                backgroundColor: "#ff3600",
                color: "white",
              }}
              onClick={() => {
                this.setState({ showSidebar: false })
                this.handleNavigation("/login")
              }}
            >
              <Translation>{(t) => t("login")}</Translation>
            </Button>
          )}
          {user && (
            <div className="dropdown show" style={{ paddingBottom: "0px" }}>
              <a
                style={{
                  backgroundColor: "#ff3600",
                  borderColor: "#ff3600",
                  marginBottom: "0px",
                }}
                class="btn btn-secondary dropdown-toggle"
                href="#"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {user.firstName
                  ? user.firstName + " " + user.lastName
                  : user.userName}
              </a>

              <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <ul
                  style={{ marginBottom: "0px", padding: "0px", marginLeft: 0 }}
                >
                  {this.renderLogout(Images.white_profile, "Profile", () =>
                    this.handleNavigation("/user-profile")
                  )}
                  {this.renderLogout(
                    Images.saloon_drawer_log_out,
                    <Translation>{(t) => t("log_out")}</Translation>,
                    this.handleLogout
                  )}
                </ul>
              </div>
            </div>
          )}
          <span
            className="d-flex align-items-center pl-2"
            onClick={() =>
              this.setState({
                showSidebar: !showSidebar,
                isLoginMenuOpen: false,
              })
            }
          >
            <img
              src={Images.saloon_navbar_menu}
              style={{ width: 32, height: 44, cursor: "pointer" }}
            />
            {showSidebar && (
              <div className="dropdown-nav-menu">
                <MenuList>
                  <MenuItem
                    onClick={() =>
                      this.handleNavigation("/demonstration-reservation")
                    }
                  >
                    {i18n.t("book_a_demo")}
                  </MenuItem>
                  <MenuItem
                    onClick={() => this.handleNavigation("/careers.html")}
                  >
                    {i18n.t("careers")}
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      this.handleNavigation("/our-services", {
                        state: this.props.homeInfo,
                      })
                    }
                  >
                    {i18n.t("our_services")}
                  </MenuItem>
                </MenuList>
              </div>
            )}
          </span>
        </span>
      </div>
    )
  }

  renderLogout = (image, value, func) => {
    return (
      // <ul className='li-items'>
      <li className="li-items" onClick={() => func()}>
        <img src={image} style={{ width: 30, height: "auto" }} />
        <span style={{ padding: 3 }}>{value}</span>
      </li>
      // </ul>
    )
  }

  renderHeaderSm = () => {
    const { showSidebar, user } = this.state
    return (
      <div className="header-sm">
        <div
          className="row px-4 py-3 d-flex align-items-center justify-content-between"
          style={{ backgroundColor: "#F7F8FA" }}
        >
          <img
            alt="easy1 logo"
            src={Images.easy1_logo}
            className="logo cursor-pointer"
            style={{ zIndex: 2, height: 42, width: 20 }}
            onClick={() => this.handleNavigation("/")}
          />
          <span className="d-flex align-items-center" style={{ zIndex: 2 }}>
            <span>{this.LanguageSelector()}</span>
            {!user && (
              <PrimaryButton
                type="button"
                style={{
                  backgroundColor: "#ff3600",
                  color: "white",
                }}
                onClick={() => {
                  this.handleNavigation("/login")
                  this.setState({ isLoginMenuOpen: true, showSidebar: false })
                }}
              >
                <Translation>{(t) => t("login")}</Translation>
              </PrimaryButton>
            )}
            <span
              className="pl-2"
              onClick={() => this.setState({ showSidebar: !showSidebar })}
            >
              <img
                src={Images.saloon_navbar_menu}
                style={{ width: 35, height: 35, cursor: "pointer" }}
              />
            </span>
          </span>
        </div>
        <Sidebar {...this.props} showSidebar={showSidebar} />
      </div>
    )
  }

  renderContentSection = () => {
    return (
      <div className="p-3" ref={this.searchSection}>
        <div className="lg-margin-test">
          {this.renderOurCategories()}
          <div className="row scrollable-client">
            <div className="col-xl-2 service-heading px-0 mt-2">
              {this.renderSearchSection()}
            </div>
            <div className="col px-1">
              <InfiniteScroll
                dataLength={this.state.allSaloons.length}
                next={this.fetchMoreData}
                height={600}
                hasMore={this.state.hasMore}
                loader={
                  <div className="row justify-content-center">
                    <CircularProgress />
                  </div>
                }
              >
                {this.renderSellingCards()}
              </InfiniteScroll>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderSearchSection = () => {
    const {
      showDropdownCategory,
      searchSalonNameText,
      locationPredictionData,
      searchCategoryList,
      searchCategoryText,
      checkedStars,
    } = this.state

    const placehoser = <Translation>{(t) => t("by_category")}</Translation>
    const { ratings } = this.state

    return (
      <div className="col px-0 mb-2">
        <div className="py-4" id="search-section">
          <p className="col-12 font-bold pt-2">
            <Translation>{(t) => t("rate")}</Translation>
          </p>
          <div className="col-12 col-md-12 mb-3">
            {ratings.map((rate, ind) => {
              return (
                <div className="row ">
                  <FormControlLabel
                    control={
                      <StyledCheckbox
                        checked={rate.isChecked}
                        onChange={(e) => {
                          ratings[ind].isChecked = e.target.checked
                          this.setState({ isLoading: true, ratings }, () =>
                            this.handleApplyFilter()
                          )
                        }}
                        name={i18n.t("by_rating")}
                      />
                    }
                    label={
                      <ReactStars
                        key={ind}
                        count={5}
                        size={12}
                        edit={false}
                        value={rate.value}
                        color={"#E5EBF1"}
                        activeColor="#DE1784"
                      />
                    }
                  />
                </div>
              )
            })}
          </div>
          <div className="mb-3">
            <p className="col-12 font-bold pt-2">{i18n.t("points")}</p>
            <div className="row mb-2">
              <div className="col-6 pr-2">
                <input type="number" className="form-control" />
              </div>
              <div className="col-6 pl-2">
                <input type="number" className="form-control" />
              </div>
            </div>
          </div>

          <div className="mb-3">
            <p className="col-12 font-bold pt-2">{i18n.t("by_price")}</p>
            <div className="row mb-2">
              <div className="col-6 pr-2">
                <input type="number" className="form-control" />
              </div>
              <div className="col-6 pl-2">
                <input type="number" className="form-control" />
              </div>
            </div>
          </div>

          <div className="row mb-2">
            <p className="col-12 font-bold pt-2">{i18n.t("area")}</p>
          </div>

          <div className="row col-12 col-md-12 mb-3">
            <div class="input-group mb-3 mb-md-0">
              <input
                value={searchCategoryText}
                type="text"
                className="form-control"
                id="search-bar"
                placeholder={i18n.t("by_category")}
                onFocus={() => {
                  this.setState({ showDropdownCategory: true })
                }}
                onBlur={() => {
                  setTimeout(
                    () => this.setState({ showDropdownCategory: false }),
                    100
                  )
                }}
                onChange={(event) => this.handleSearchCategory(event)}
              />

              {showDropdownCategory && (
                <div
                  id="search-location-dropdown"
                  style={{
                    position: "absolute",
                    top: 40,
                    height:
                      searchCategoryList.length <= 1
                        ? 60
                        : searchCategoryList.length === 2
                        ? 100
                        : 150,
                  }}
                >
                  {searchCategoryList.length > 0 ? (
                    searchCategoryList.map((val) => (
                      <p
                        key={val._id}
                        onClick={() => {
                          this.handleSelectedCategory(val)
                        }}
                        className="search-location-item"
                      >
                        {val.name}
                      </p>
                    ))
                  ) : (
                    <p className="p-2 mb-1 text-danger">
                      <Translation>
                        {(t) => t("search_category_not_found")}
                      </Translation>
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="col-12 col-md-12 mb-3">
            <div class="input-group">
              <input
                value={searchSalonNameText}
                type="text"
                class="form-control"
                id="search-bar"
                placeholder={i18n.t("by_salon")}
                onKeyDown={(e) => {
                  if (e.key === "Enter") this.handleApplyFilter()
                }}
                onChange={(event) => this.handleSalonByName(event)}
              />
            </div>
          </div>
          {this.renderFilterSectionLg()}
        </div>
      </div>
    )
  }

  handleChange = (address) => {
    this.setState({ address }, () => this.handleApplyFilter())
  }

  handleSelect = async (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        let { lat, lng } = latLng
        this.setState(
          {
            latitude: lat || this.state.latitude,
            longitude: lng || this.longitude,
            radius: 100,
          },
          this.handleSaloonNearby
        )
      })
      .catch((error) => console.error("Error", error))

    this.setState({ address })
  }

  handleApplyFilter = () => {
    const {
      selectedCategory,
      searchSalonNameText,
      address,
      isCheckedGeo,
      latitude,
      longitude,
      ratings,
    } = this.state

    getAllSaloons({
      skip: 0,
      limit: 10,
      name: searchSalonNameText,
      service_id: selectedCategory?._id,
      ratings: ratings.filter((x) => x.isChecked).map((x) => x.value),
      latitude,
      longitude,
      isCheckedGeo,
      address,
    })
      .then(({ data }) => {
        document.querySelector(".infinite-scroll-component").scrollTop = 0
        this.setState({ allSaloons: data.saloons })
        data.saloons.length >= 10
          ? this.setState({ hasMore: true })
          : this.setState({ hasMore: false })

        data.saloons.length === 0
          ? this.setState({ notFound: true })
          : this.setState({ notFound: false })
      })
      .catch((err) => console.log(err))
      .finally(() => this.setState({ isloading: false }))

    this.setState({ index: 1 })
  }

  // For large screen
  renderFilterSectionLg = () => {
    const {
      searchLocationText,
      locationPredictionData,
      searchCategoryList,
      searchCategoryText,
      isCheckedGeo,
    } = this.state

    const filterTags = [i18n.t("in_radius_5km"), i18n.t("by_rating")]

    const byPlaceTag = <Translation>{(t) => t("by_places")}</Translation>
    const TranslatedFormControl = withTranslation(["by_places"])
    return (
      <div id="filter-section-lg">
        <div className="">
          <div class="col-12 pb-3 mb-3">
            <PlacesAutocomplete
              value={this.state.address}
              onChange={this.handleChange}
              onSelect={this.handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <input
                    {...getInputProps({
                      placeholder: i18n.t("by_places"),
                      className: "form-control",
                      id: "search-bar",
                    })}
                  />

                  <div className="autocomplete-dropdown-container">
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item"
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? { backgroundColor: "#fafafa", cursor: "pointer" }
                        : { backgroundColor: "#ffffff", cursor: "pointer" }

                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>

            {searchLocationText && (
              <div
                id="search-location-dropdown"
                style={{
                  height:
                    searchCategoryList.length <= 1
                      ? 60
                      : searchCategoryList.length === 2
                      ? 100
                      : 150,
                  marginTop: "0px",
                }}
              >
                {locationPredictionData.length > 0 ? (
                  locationPredictionData.map((val) => (
                    <p className="search-location-item">
                      4k chorangi, Sector 2, North Karachi.
                    </p>
                  ))
                ) : (
                  <p className="p-2 mb-1 text-danger">No location found.</p>
                )}
              </div>
            )}
          </div>
          <FormControlLabel
            control={
              <StyledCheckbox
                checked={isCheckedGeo}
                onChange={this.handleCheckGeoInput}
                name={i18n.t("in_radius_5km")}
              />
            }
            className="row col-12 col-md-12 pb-3 mb-3"
            label={i18n.t("in_radius_5km")}
          />
        </div>

        <div className="row col-12 justify-content-center mb-2">
          <Button
            className="col btn mr-2"
            id="apply-filter"
            onClick={this.handleReset}
          >
            {i18n.t("reset")}
          </Button>
          <Button
            className="col btn"
            id="apply-filter"
            onClick={this.handleApplyFilter}
          >
            {i18n.t("apply")}
          </Button>
        </div>
      </div>
    )
  }

  renderSellingCards = () => {
    const { allSaloons, notFound } = this.state

    const createAddress = (val) => {
      const items = []
      if (val.address) {
        items.push(val.address)
      }
      if (val.city) {
        items.push(val.city)
      }
      if (val.province) {
        items.push(val.province)
      }
      return items.join(", ")
    }
    return (
      <div class="">
        <div className="row mb-3 mx-1">
          {notFound ? (
            <h2>{i18n.t("nothing_found")}</h2>
          ) : (
            allSaloons.map((val, ind) => {
              let coverImage
              if (val.websiteUrl && typeof val.previewImageUrl === "object") {
                coverImage = val.previewImageUrl.url
              } else {
                coverImage = val?.customTemplateId?.headerImage
                  ? val.customTemplateId.headerImage
                  : val?.templateID
                  ? val.templateID.coverImage.url
                  : val?.template?.coverImage.url
              }

              return (
                <div className="col-12 col-md-6 col-lg-4 px-2 py-2">
                  <div class="card selling-card">
                    <img
                      className="salon-card-image"
                      src={coverImage}
                      alt="Card image cap"
                    />
                    <div class="card-body px-0 py-2">
                      <h5 class="card-title">
                        <strong>
                          {val.name
                            ? val.name || val?.template.name
                            : val?.company?.name}
                        </strong>
                      </h5>
                      <div className="row">
                        <ReactStars
                          key={val.id}
                          count={5}
                          value={val?.averageRating || 0}
                          size={13}
                          edit={false}
                          color={"#E5EBF1"}
                          activeColor="#DE1784"
                          isHalf={true}
                          halfIcon={<i className="fa fa-star-half-alt"></i>}
                        />
                        <span className="responsive-text-size mt-1 pl-3">
                          {`${val?.NumberOfReviews} ${i18n.t("reviews")}`}
                        </span>
                      </div>

                      <div className="d-flex mt-1 justify-content-between align-items-center">
                        <span className="rating-text">
                          <p class="card-address">{createAddress(val)}</p>
                        </span>
                        {!val.disableOnlineBooking ? (
                          <button
                            type="button"
                            className="btn preview-btn"
                            onClick={() => this.handlePreviewBtn(val)}
                          >
                            <Translation>
                              {(t) => t("book_with_us")}
                            </Translation>
                          </button>
                        ) : null}
                      </div>
                      {val.disableOnlineBooking ? (
                        <div className="d-flex mt-1 justify-content-between align-items-center">
                          <div>
                            {this.formatPhoneNumber(val.businessTelephone)}
                          </div>
                          <a
                            className="btn btn-outline-light btn-sm callToBookButton"
                            href={`tel:${val.businessTelephone}`}
                            style={{
                              width: "max-content",
                            }}
                          >
                            <span className="w-100 h-100 row align-items-center justify-content-center text-no-wrap">
                              <Translation>
                                {(t) => t("call_to_book")}
                              </Translation>
                            </span>
                          </a>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              )
            })
          )}
        </div>
      </div>
    )
  }

  formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "")
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      var intlCode = match[1] ? "+1 " : ""
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("")
    }
    return ""
  }

  renderLoader = () => {
    const { isloading } = this.state
    return <DotsLoader isloading={isloading} />
  }

  renderBookingFrame = () => {
    const { companyId } = this.state
    if (companyId) {
      return <iframe src={baseUrl + "/booking/getForm?_id=companyId"}></iframe>
    } else {
      return null
    }
  }

  render() {
    const { getHomePageInfo, homeInfo } = this.props
    return (
      <div>
        {this.renderLoader()}
        {this.renderJumbotron()}
        {this.renderContentSection()}
        {this.renderBookingFrame()}
        <Footer
          homePageData={getHomePageInfo}
          cardDetail={homeInfo.homePage}
          renderHeaderInfo={false}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    getHomePageInfo: state.getHomePageInfo,
    homeInfo: state.getHome?.data,
    transferBookingId: state.validateBookingTransfer?.data?.id,
    transferDisplayType: state.validateBookingTransfer?.data?.type,
  }
}

const action = {
  user_logout,
  clear_logout,
  get_home_page_info,
  get_home,
}

export default connect(mapStateToProps, action)(Home)
