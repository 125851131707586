import React, { Component } from "react"
import i18n from "../../../i18n"
import { DotsLoader } from "../../../components"
import { upsertCustomer } from "../../../config/simpleApiCalls"
import { ErrorHelper, SuccessHelper } from "../../../helpers"

import "./styles.css"
import CustomerDetails from "../../../components/Saloon/Customer/CustomerDetails"
import useCache from "../../../context/LocalCache"

class _AddCustomer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isloading: false,
    }
  }

  handleSaloonCreateCustomer = (payload) => {
    this.setState({ isloading: true })

    return upsertCustomer(payload)
      .then((res) => {
        if (res.data.success) {
          SuccessHelper.handleSuccess("Customer successfully added.", true)
          this.setState({
            isloading: false,
          })
        } else {
          ErrorHelper.handleErrors(res.data.msg, true)
          this.setState({ isloading: false })
        }
      })
      .catch((error) => {
        ErrorHelper.handleErrors("An error occured", true)
        this.setState({ isloading: false })
      })
  }

  renderLoader = () => {
    const { isloading } = this.state
    return <DotsLoader isloading={isloading} />
  }

  renderSaloonDashboardHeading = () => {
    return (
      <div className="col-12 px-0 mb-5">
        <h1 className="saloon-dashboard-heading">
          {i18n.t("container.add_customer")}
        </h1>
      </div>
    )
  }

  renderAddCustomerForm = () => {
    const { customers = [] } = this.props
    return (
      <CustomerDetails
        onSave={this.handleSaloonCreateCustomer}
        allCustomers={customers}
      />
    )
  }

  render() {
    return (
      <div className="col-sm-5 px-0 mb-5 mb-md-0">
        {this.renderLoader()}
        <div className="row p-4">
          {this.renderSaloonDashboardHeading()}
          {this.renderAddCustomerForm()}
        </div>
      </div>
    )
  }
}

export default function AddCustomer(props) {
  const { getResource } = useCache()
  const customers = getResource((cache) => cache.company.customers)
  return <_AddCustomer {...props} customers={customers} />
}
