import { connect } from "react-redux"
import React, { useEffect, useState } from "react"
import moment from "moment"
import MenuItem from "@material-ui/core/MenuItem"
import FormHelperText from "@material-ui/core/FormHelperText"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "react-autocomplete"

import "./styles.scss"
import i18n from "../../../../i18n"
import { Translation } from "react-i18next"
import { getUserPackagesByPhoneRequest as getUserPackagesRequest } from "../../../../redux/actions/Packages"
import PrimaryButton from "../../../Core/PrimaryButton"
import BaseModal from "../../../BaseModal"
import UserPackages from "../../../UserPackages/UserPackages.component"
import { DotsLoader } from "../../.."
import CustomerEdit from "../../../Saloon/Customer/CustomerEdit"
import { InputAdornment } from "@material-ui/core"
import useBooking from "../../../../context/Booking"
import { CustomerSearchUp } from "../../../TouchCustomerSeachUp/CustomerSearchUp"
import { getRequest as getCustomerCars } from "../../../../redux/actions/Saloon/CompanyCustomerCars"
import { Images } from "../../../../theme"

moment.locale("en")

const CreateAppointment = ({
  customers,
  formErrors,
  getSalonServices,
  allSaloons = [],
  isGym,
  isSalon,
  getUserPackagesRequest,
  userPackages = [],
  getCustomerCars,
  setCollectCustomer = () => {},
  handleDrawerClose,
  collectCustomer,
  showCustomerCurrentBookings,
  setShowCustomerCurrentBookings = () => {},
}) => {
  const { onChange, data, setData, functionRole, selectBooking } = useBooking()
  const [packageModalOpen, setPackageModalOpen] = useState(false)
  const [touchSearchOpen, setTouchSearchOpen] = useState(false)
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [showCustomerModal, setShowCustomerModal] = useState(null)
  const [activeCustomers, setActiveCustomers] = useState([])
  const inputAutocomplete = !!window.chrome ? "chrome-off" : "off"
  const [loading, setLoading] = useState(false)

  const subcategoryServicesMap = {}

  ;(getSalonServices || []).map((service) => {
    if (!subcategoryServicesMap[service.subcategory]) {
      subcategoryServicesMap[service.subcategory] = []
    }
    subcategoryServicesMap[service.subcategory].push(service)
  })
  useEffect(() => {
    setActiveCustomers(customers.filter((customer) => customer.isActive))
  }, [customers])
  useEffect(() => {
    if (showCustomerCurrentBookings) {
      setShowCustomerModal(showCustomerCurrentBookings)
    }
  }, [showCustomerCurrentBookings])
  useEffect(() => {
    if (selectBooking) {
      var customer = activeCustomers.find(
        (e) =>
          e.name == selectBooking?.name &&
          (e.email == selectBooking?.email || e.email == null) &&
          e.phoneNo == selectBooking?.phoneNo
      )
      setData({
        ...data,
        phoneNumber: customer?.phoneNo || selectBooking.phoneNo,
        name: customer
          ? `${customer?.firstName ? customer?.firstName : ""} ${
              customer?.name
            }`.trim()
          : selectBooking.name,
        email: customer?.email || selectBooking.email,
      })

      if (customer) {
        customerSelect(customer)
        setCollectCustomer(customer)
        console.log(customer)
      }
    } else {
      getUserPackagesRequest(null)
    }
  }, [selectBooking, activeCustomers, data.phoneNumber])
  const customerSelect = (data) => {
    const { phoneNo, email, name, firstName, isBlackListed } = data

    setSelectedCustomer(data)
    if (functionRole == "garage") {
      getCustomerCars({
        companyCustomerId: data._id,
      })
    }
    getUserPackagesRequest(
      {
        phoneNo,
        companyIds: isSalon
          ? data.companyId
          : allSaloons.map((saloon) => saloon._id).join(","),
      },
      {}
    )
    onChange({
      name: `${firstName ? firstName : ""} ${name}`.trim(),
      phoneNumber: phoneNo,
      email,
      isBlackListed,
      userPackageId: null,
    })
  }

  const phoneNoLookUp = (phoneNo) => {
    getUserPackagesRequest(
      {
        phoneNo: phoneNo || data.phoneNumber,
        companyIds: isSalon
          ? data.companyId
          : allSaloons.map((saloon) => saloon._id).join(","),
      },
      {}
    )
  }

  return (
    <div className="create-appointment">
      <DotsLoader isloading={loading} />
      {showCustomerModal ? (
        <CustomerEdit
          open={showCustomerModal}
          setOpen={() => {
            setShowCustomerModal(null)
            setShowCustomerCurrentBookings(false)
          }}
          active={collectCustomer || selectedCustomer}
          isGym={isGym}
          openTab={
            showCustomerCurrentBookings &&
            i18n.t("current_and_recurring_bookings")
          }
          setShowCustomerCurrentBookings={setShowCustomerCurrentBookings}
          handleEdit={() => {}}
          setAccountBalance={() => {}}
          handleDrawerClose={handleDrawerClose}
        />
      ) : null}
      <BaseModal
        open={packageModalOpen}
        onClose={() => setPackageModalOpen(false)}
        contentClassName="package-table"
        {...{
          content: (
            <UserPackages isSalon={isSalon} setOpen={setPackageModalOpen} />
          ),
          title: i18n.t("packages"),
        }}
      />
      <div>
        <div>
          <div className="row mx-0 justify-content-between align-items-center">
            <div
              style={{
                fontSize: 18,
                marginTop: 10,
                marginBottom: 10,
                fontWeight: "bold",
              }}
            >
              <Translation>
                {(t) => t("container.enter_customer_details")}
              </Translation>
            </div>
            {userPackages.length ? (
              <PrimaryButton
                label={i18n.t("packages")}
                onClick={() => setPackageModalOpen(true)}
              />
            ) : null}
          </div>
          <div className="row mx-0">
            <div className="form-label-group mb-3 col-12 p-0 ">
              <div className="row mx-0 align-items-center flex-wrap p-0">
                <div className="col-12 col-md-4 p-0">
                  <label>{i18n.t("container.employe_phone_number")}</label>
                </div>
                <div className="col-12 col-md-8 p-0">
                  <div className="d-flex auto-container">
                    <Autocomplete
                      renderInput={(props) => {
                        return (
                          <TextField
                            id="phoneNumber"
                            variant="outlined"
                            {...props}
                            {...(isSalon && selectedCustomer
                              ? {
                                  InputProps: {
                                    endAdornment: <InputAdornment />,
                                    ...props.InputProps,
                                  },
                                }
                              : {})}
                          />
                        )
                      }}
                      items={data.phoneNumber ? activeCustomers : []}
                      shouldItemRender={(item, value) =>
                        item.phoneNo
                          .toLowerCase()
                          .indexOf(value.toLowerCase()) > -1 ||
                        item.name.toLowerCase().indexOf(value.toLowerCase()) >
                          -1 ||
                        item.firstName
                          ?.toLowerCase()
                          .indexOf(value.toLowerCase()) > -1
                      }
                      getItemValue={(item) => item.phoneNo}
                      renderItem={(item, highlighted) => (
                        <MenuItem key={item._id}>
                          {`${item.firstName ? item.firstName : ""} ${
                            item.name
                          }`.trim()}
                        </MenuItem>
                      )}
                      menuStyle={{
                        zIndex: "9998",
                        position: "absolute",
                        background: "white",
                        top: 64,
                        left: 0,
                      }}
                      inputProps={{
                        className: "full-width",
                        autoComplete: inputAutocomplete,
                        autocomplete: inputAutocomplete,
                        error: !!formErrors?.phoneNumberError,
                      }}
                      wrapperStyle={{
                        width: "100%",
                      }}
                      renderMenu={(children) => {
                        return (
                          <div className="menu">{children.slice(0, 15)}</div>
                        )
                      }}
                      value={data.phoneNumber || ""}
                      onChange={(e) =>
                        onChange({ phoneNumber: e.target.value })
                      }
                      onSelect={(value, data) => {
                        customerSelect(data)
                        setCollectCustomer(data)
                      }}
                    />
                    <PrimaryButton
                      onClick={() => setShowCustomerModal(true)}
                      style={{
                        width: "30px",
                        height: "30px",
                        minWidth: "auto",
                        marginLeft: "1rem",
                      }}
                    >
                      <img
                        alt="profile"
                        width="18px"
                        src={Images.profile_svg_light}
                      />
                    </PrimaryButton>
                  </div>
                </div>
              </div>
              {!!formErrors?.phoneNumberError && (
                <FormHelperText error>
                  {formErrors?.phoneNumberError}
                </FormHelperText>
              )}
            </div>

            <CustomerSearchUp
              open={touchSearchOpen}
              setOpen={setTouchSearchOpen}
              customers={activeCustomers || []}
              onDone={(phoneNo, selected) => {
                if (selected) {
                  customerSelect(selected)
                  setCollectCustomer(selected)
                } else {
                  onChange({ phoneNo })
                }
                phoneNoLookUp(phoneNo)
                setTouchSearchOpen(false)
              }}
            />
            <div className="row align-items-center flex-wrap form-label-group col-12 p-0">
              <div className="col-12 col-md-4 p-0">
                <label>{i18n.t("name")}</label>
              </div>
              <div className="col-12 col-md-8 p-0">
                <Autocomplete
                  renderInput={(props) => {
                    return <TextField variant="outlined" id="name" {...props} />
                  }}
                  items={data.name ? customers : []}
                  shouldItemRender={(item, value) =>
                    item.name.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
                    item.firstName?.toLowerCase().indexOf(value.toLowerCase()) >
                      -1
                  }
                  getItemValue={(item) => item.name}
                  renderItem={(item, highlighted) => (
                    <MenuItem key={item._id}>
                      {`${item.firstName ? item.firstName : ""} ${
                        item.name
                      }`.trim()}
                    </MenuItem>
                  )}
                  menuStyle={{
                    zIndex: "9998",
                    position: "absolute",
                    background: "white",
                    top: 64,
                    left: 0,
                  }}
                  inputProps={{
                    className: "full-width",
                    autoComplete: inputAutocomplete,
                    autocomplete: inputAutocomplete,
                    error: !!formErrors?.nameError,
                  }}
                  wrapperStyle={{
                    width: "100%",
                  }}
                  renderMenu={(children) => {
                    return <div className="menu">{children.slice(0, 15)}</div>
                  }}
                  value={data.name || ""}
                  onChange={(e) => onChange({ name: e.target.value })}
                  onSelect={(value, data) => {
                    customerSelect(data)
                    setCollectCustomer(data)
                  }}
                />
                {!!formErrors?.nameError && (
                  <FormHelperText error>{formErrors?.nameError}</FormHelperText>
                )}
              </div>
            </div>
            <div className="row align-items-center flex-wrap form-label-group mb-3 col-12 p-0">
              <div className="col-12 col-md-4 p-0">
                <label>{i18n.t("container.profile_email")}</label>
              </div>
              <div className="col-12 col-md-8 p-0">
                <TextField
                  id="email"
                  variant="outlined"
                  value={data.email || ""}
                  placeholder={i18n.t("enter_email_placeholder")}
                  onChange={(e) => onChange({ email: e.target.value })}
                  helperText={formErrors?.emailError}
                  error={!!formErrors?.emailError}
                />
              </div>
            </div>
            {!isGym && (
              <div className="row align-items-center flex-wrap form-label-group mb-3 col-12 p-0">
                <div className="col-12 col-md-4 p-0">
                  <label>{i18n.t("total_amount")}</label>
                </div>
                <div className="col-12 col-md-8 p-0">
                  <TextField
                    id="totalAmount"
                    variant="outlined"
                    value={data.totalAmount || ""}
                    placeholder={0}
                    helperText={formErrors?.totalAmountError}
                    error={!!formErrors?.totalAmountError}
                  />
                </div>
              </div>
            )}
            <div className="row align-items-center flex-wrap form-label-group mb-3 col-12 p-0">
              <div className="col-12 col-md-4 p-0">
                <label>{i18n.t("notes")}</label>
              </div>
              <div className="col-12 col-md-8 p-0">
                <TextField
                  id="notes"
                  variant="outlined"
                  value={data.notes || ""}
                  onChange={(e) => onChange({ notes: e.target.value })}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userPackages: state.packages.userPackages,
})

const action = { getUserPackagesRequest, getCustomerCars }

export default connect(mapStateToProps, action)(CreateAppointment)
