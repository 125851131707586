const easy1_logo_800x300 = require("../assets/Images/easy1_logo_800x300.png")
const easy1_logo = require("../assets/Images/easy1_logo.png")
const selling_card_img_1 = require("../assets/Images/selling_card_img_1.jpg")
const selling_card_img_2 = require("../assets/Images/selling_card_img_2.jpg")

const order_sort = require("../assets/Images/Icons/order_sort.png")
const show_list_icon = require("../assets/Images/Icons/show_list_icon.png")
const close_icon = require("../assets/Images/Icons/close.png")
const show_grid_icon = require("../assets/Images/Icons/show_grid_icon.png")
const rating_star = require("../assets/Images/Icons/rating_star.png")
const rating_star_blue = require("../assets/Images/Icons/rating_star_blue.png")
const pagination_forward = require("../assets/Images/Icons/pagination_forward.png")
const pagination_back = require("../assets/Images/Icons/pagination_back.png")
const setting_icon = require("../assets/Images/Icons/setting_icon.png")
const download_ios = require("../assets/Images/Icons/download_ios.png")
const download_andriod = require("../assets/Images/Icons/download_andriod.png")
const email_icon = require("../assets/Images/Icons/email_icon.png")
const facebook_icon = require("../assets/Images/Icons/facebook_icon.png")
const instagram_icon = require("../assets/Images/Icons/instagram_icon.png")
const youtube_icon = require("../assets/Images/Icons/youtube_icon.png")
const google_signin = require("../assets/Images/Icons/google_signin.png")
const facebook_signin = require("../assets/Images/Icons/facebook_signin.png")
const mail_signin = require("../assets/Images/Icons/mail_signin.png")
const check = require("../assets/Images/Icons/profile/CHECK.svg")
const step_done_tick = require("../assets/Images/Icons/step_done_tick.png")
const step_done_tick_orange = require("../assets/Images/Icons/step_done_tick_orange.png")
const saloon_form_submit = require("../assets/Images/Icons/saloon_form_submit.png")
const saloon_footer_facebook = require("../assets/Images/Icons/saloon_footer_facebook.png")
const saloon_footer_instagram = require("../assets/Images/Icons/saloon_footer_instagram.png")
const saloon_footer_twitter = require("../assets/Images/Icons/saloon_footer_twitter.png")
const saloon_footer_youtube = require("../assets/Images/Icons/saloon_footer_youtube.png")
const saloon_form_visa = require("../assets/Images/Icons/saloon_form_visa.png")
const saloon_form_american_express = require("../assets/Images/Icons/saloon_form_american_express.png")
const saloon_form_credit_card = require("../assets/Images/Icons/saloon_form_credit_card.png")
const saloon_form_master_card = require("../assets/Images/Icons/saloon_form_master_card.png")
const saloon_form_paypal = require("../assets/Images/Icons/saloon_form_paypal.png")
const _preview = require("../assets/Images/6609538_preview.png")
const dashboard_employees = require("../assets/Images/Icons/dashboard company/EMPLOYEES.svg")
const dashboard_services = require("../assets/Images/Icons/dashboard company/SERVICES.svg")
const dashboard_appointments = require("../assets/Images/Icons/dashboard company/APPOINTMENTS.svg")
const dots = require("../assets/Images/dots.png")
const Layer5 = require("../assets/Images/Layer 5.png")
const Layer6 = require("../assets/Images/Layer 6.png")
const tick = require("../assets/Images/tick.png")
const pic1 = require("../assets/Images/pic1.png")
const viewAll = require("../assets/Images/viewAll.png")
const clock = require("../assets/Images/clock.png")
const calendar = require("../assets/Images/calendar.png")
const star_empty = require("../assets/Images/Icons/star-empty.png")
const star_yellow = require("../assets/Images/Icons/star-yellow.png")
const bookingClock = require("../assets/Images/bookingclock.png")
const price_tag = require("../assets/Images/price_tag.png")
const tag_grey = require("../assets/Images/Icons/tag_grey.png")
const saloon_plus = require("../assets/Images/plus.png")
const saloon_dashboard_wallet = require("../assets/Images/Wallethub.png")
const saloon_headingDropdown = require("../assets/Images/Icons/dropdown.png")
const white_profile = require("../assets/Images/Icons/profile_white.png")
const down_white = require("../assets/Images/Icons/headingdropdown.png")
const saloon_search = require("../assets/Images/admin_search.png")
const saloon_addEmployee_plus = require("../assets/Images/Icons/plus.png")
const saloon_addEmployee_tooltiparrow = require("../assets/Images/Icons/tooltiparrow.png")
const service_bulb = require("../assets/Images/Icons/service_bulb.png")
const template_selling_card_img_1 = require("../assets/Images/template_selling_card_img_1.png")

// For Saloon Dashboard
const saloon_profile_icon = require("../assets/Images/Icons/saloon_profile_icon.png")
const saloon_navbar_menu = require("../assets/Images/Icons/saloon_navbar_menu.png")
const saloon_navbar_profile_dropdown = require("../assets/Images/Icons/saloon_navbar_profile_dropdown.png")
const saloon_chat_send = require("../assets/Images/Icons/saloon_chat_send.png")
const saloon_chat_clip = require("../assets/Images/Icons/saloon_chat_clip.png")
const saloon_upload_media_icon = require("../assets/Images/Icons/ui/UPLOAD_BIG.svg")

const saloon_social_facebook = require("../assets/Images/Icons/social/FB.svg")
const saloon_social_instagram = require("../assets/Images/Icons/social/INSTAGRAM.svg")
const saloon_social_linkedin = require("../assets/Images/Icons/social/LINKEDIN.svg")
const saloon_social_snapchat = require("../assets/Images/Icons/social/SNAPCHAT.svg")
const saloon_social_twitter = require("../assets/Images/Icons/social/TWITTER.svg")
const saloon_social_youtube = require("../assets/Images/Icons/social/YOUTUBE.svg")

const saloon_link_social_facebook = require("../assets/Images/Icons/saloon_link_social_facebook.png")
const saloon_link_social_instagram = require("../assets/Images/Icons/saloon_link_social_instagram.png")
const saloon_link_social_linkedin = require("../assets/Images/Icons/saloon_link_social_linkedin.png")
const saloon_link_social_snapchat = require("../assets/Images/Icons/saloon_link_social_snapchat.png")
const saloon_link_social_twitter = require("../assets/Images/Icons/saloon_link_social_twitter.png")
const saloon_link_social_youtube = require("../assets/Images/Icons/saloon_link_social_youtube.png")
const saloon_working_hour_edit = require("../assets/Images/Icons/saloon_working_hour_edit.png")
const saloon_working_hour_delete = require("../assets/Images/Icons/saloon_working_hour_delete.png")
const saloon_category_pic = require("../assets/Images/saloon_category_pic.png")
const saloon_edit_employee_check_icon = require("../assets/Images/Icons/saloon_edit_employee_check_icon.png")
const saloon_available_services_date = require("../assets/Images/Icons/date.png")
const saloon_available_services_time = require("../assets/Images/Icons/time.png")
const saloon_add = require("../assets/Images/Icons/saloon_add.png")
const saloon_check = require("../assets/Images/Icons/saloon_check.png")

// For Saloon Dashboard Company Sidebar Menu
const activities_svg_dark = require("../assets/Images/Icons/main-menu/company/DARK/Activities.svg")
const ai_booking_svg_dark = require("../assets/Images/Icons/main-menu/company/DARK/AiBooking.svg")
const billing_svg_dark = require("../assets/Images/Icons/main-menu/company/DARK/Billing.svg")
const customers_svg_dark = require("../assets/Images/Icons/main-menu/company/DARK/Customers.svg")
const dashboard_svg_dark = require("../assets/Images/Icons/main-menu/company/DARK/Dashboard.svg")
const employees_svg_dark = require("../assets/Images/Icons/main-menu/company/DARK/Employees.svg")
const forms_svg_dark = require("../assets/Images/Icons/main-menu/company/DARK/Forms.svg")
const logout_svg_dark = require("../assets/Images/Icons/main-menu/company/DARK/Logout.svg")
const marketing_svg_dark = require("../assets/Images/Icons/main-menu/company/DARK/Marketing.svg")
const my_appointment_svg_dark = require("../assets/Images/Icons/main-menu/company/DARK/MyAppointments.svg")
const my_business_svg_dark = require("../assets/Images/Icons/main-menu/company/DARK/MyBusiness.svg")
const products_and_services_svg_dark = require("../assets/Images/Icons/main-menu/company/DARK/Products_Services.svg")
const profile_svg_dark = require("../assets/Images/Icons/main-menu/company/DARK/Profile.svg")
const programs_svg_dark = require("../assets/Images/Icons/main-menu/company/DARK/Programs.svg")
const purchase_svg_dark = require("../assets/Images/Icons/main-menu/company/DARK/Purchase.svg")
const rating_svg_dark = require("../assets/Images/Icons/main-menu/company/DARK/Rating.svg")
const reports_svg_dark = require("../assets/Images/Icons/main-menu/company/DARK/Reports.svg")
const taxes_svg_dark = require("../assets/Images/Icons/main-menu/company/DARK/Taxes.svg")
const wallet_svg_dark = require("../assets/Images/Icons/main-menu/company/DARK/Wallet.svg")
const arrow_svg_dark = require("../assets/Images/Icons/main-menu/arrow/arrow.svg")

const activities_svg_light = require("../assets/Images/Icons/main-menu/company/LIGHT/Activities.svg")
const ai_booking_svg_light = require("../assets/Images/Icons/main-menu/company/LIGHT/AiBooking.svg")
const billing_svg_light = require("../assets/Images/Icons/main-menu/company/LIGHT/Billing.svg")
const customers_svg_light = require("../assets/Images/Icons/main-menu/company/LIGHT/Customers.svg")
const dashboard_svg_light = require("../assets/Images/Icons/main-menu/company/LIGHT/Dashboard.svg")
const employees_svg_light = require("../assets/Images/Icons/main-menu/company/LIGHT/Employees.svg")
const forms_svg_light = require("../assets/Images/Icons/main-menu/company/LIGHT/Forms.svg")
const logout_svg_light = require("../assets/Images/Icons/main-menu/company/LIGHT/Logout.svg")
const marketing_svg_light = require("../assets/Images/Icons/main-menu/company/LIGHT/Marketing.svg")
const my_appointment_svg_light = require("../assets/Images/Icons/main-menu/company/LIGHT/MyAppointments.svg")
const my_business_svg_light = require("../assets/Images/Icons/main-menu/company/LIGHT/MyBusiness.svg")
const products_and_services_svg_light = require("../assets/Images/Icons/main-menu/company/LIGHT/Products_Services.svg")
const profile_svg_light = require("../assets/Images/Icons/main-menu/company/LIGHT/Profile.svg")
const programs_svg_light = require("../assets/Images/Icons/main-menu/company/LIGHT/Programs.svg")
const purchase_svg_light = require("../assets/Images/Icons/main-menu/company/LIGHT/Purchase.svg")
const rating_svg_light = require("../assets/Images/Icons/main-menu/company/LIGHT/Rating.svg")
const reports_svg_light = require("../assets/Images/Icons/main-menu/company/LIGHT/Reports.svg")
const taxes_svg_light = require("../assets/Images/Icons/main-menu/company/LIGHT/Taxes.svg")
const wallet_svg_light = require("../assets/Images/Icons/main-menu/company/LIGHT/Wallet.svg")
const arrow_active_svg = require("../assets/Images/Icons/main-menu/arrow/arrow_active.svg")

// For Saloon Dashboard Employee Sidebar Menu
const active_salon_dark_svg = require("../assets/Images/Icons/main-menu/employee/dark/ActiveSalon.svg")
const request_funds_dark_svg = require("../assets/Images/Icons/main-menu/employee/dark/RequestFunds.svg")
const social_links_dark_svg = require("../assets/Images/Icons/main-menu/employee/dark/UploadMedia.svg")
const upload_media_dark_svg = require("../assets/Images/Icons/main-menu/employee/dark/UploadMedia.svg")
const working_hours_dark_svg = require("../assets/Images/Icons/main-menu/employee/dark/WorkingHours.svg")

const active_salon_light_svg = require("../assets/Images/Icons/main-menu/employee/light/ActiveSalon.svg")
const request_funds_light_svg = require("../assets/Images/Icons/main-menu/employee/light/RequestFunds.svg")
const social_links_light_svg = require("../assets/Images/Icons/main-menu/employee/light/UploadMedia.svg")
const upload_media_light_svg = require("../assets/Images/Icons/main-menu/employee/light/UploadMedia.svg")
const working_hours_light_svg = require("../assets/Images/Icons/main-menu/employee/light/WorkingHours.svg")

const img = require("../assets/Images/img.jpg")

// Icons For Table
const info_svg = require("../assets/Images/Icons/iconsForTable/INFO.svg")
const note_svg = require("../assets/Images/Icons/iconsForTable/NOTES.svg")
const edit_svg = require("../assets/Images/Icons/iconsForTable/EDIT.svg")

// For Saloon Dashboard Drawer Icons
const saloon_drawer_profile = require("../assets/Images/Icons/saloon_drawer_profile.png")
const saloon_drawer_chat_box = require("../assets/Images/Icons/saloon_drawer_chat_box.png")
const saloon_drawer_category = require("../assets/Images/Icons/saloon_drawer_category.png")
const saloon_drawer_link_social = require("../assets/Images/Icons/saloon_drawer_link_social.png")
const saloon_drawer_log_out = require("../assets/Images/Icons/saloon_drawer_log_out.png")
const saloon_drawer_rating = require("../assets/Images/Icons/saloon_drawer_rating.png")
const saloon_drawer_reports = require("../assets/Images/Icons/saloon_drawer_reports.png")
const saloon_drawer_booking = require("../assets/Images/Icons/saloon_drawer_booking.png")

//For Company Setup
const saloon_companysetup_edit = require("../assets/Images/Icons/saloon_comapnysetup_edit.png")
const saloon_companysetup_delete = require("../assets/Images/Icons/saloon_companysetup_delete.png")

const user_profile_image = require("../assets/Images/user_profile_image.png")
const add_profile = require("../assets/Images/Icons/profile/ADD_PROFILE.svg")
//For Template
const saloon_template_barber = require("../assets/Images/slider.jpg")
const saloon_template_nav_img = require("../assets/Images/navlogo.png")
const saloon_template_nav_phone = require("../assets/Images/Icons/phone.png")
const saloon_template_nav_mail = require("../assets/Images/Icons/email.png")
const saloon_template_nav_time = require("../assets/Images/Icons/navtime.png")
const saloon_template_service_hair = require("../assets/Images/Icons/hair.png")
const saloon_template_service_haircolor = require("../assets/Images/Icons/haircolor.png")
const saloon_template_service_facemask = require("../assets/Images/Icons/facemask.png")
const saloon_template_service_shave = require("../assets/Images/Icons/shave.png")
const saloon_template_service_scisssor = require("../assets/Images/Icons/scissor.png")
const saloon_template_service_mustache = require("../assets/Images/Icons/mustache.png")
const saloon_template_about = require("../assets/Images/templateabout.png")
const saloon_template_price = require("../assets/Images/price.jpg")
const saloon_template_employee1 = require("../assets/Images/employee1.jpg")
const saloon_template_employee2 = require("../assets/Images/employee2.jpg")
const saloon_template_employee3 = require("../assets/Images/employee3.jpg")
const saloon_template_employee4 = require("../assets/Images/employee4.jpg")
const saloon_template_employee5 = require("../assets/Images/employee5.jpg")
const saloon_template_facebook = require("../assets/Images/facebook.png")
const saloon_template_youtube = require("../assets/Images/youtube.png")
const saloon_template_twitter = require("../assets/Images/twitter.png")
const saloon_template_insta = require("../assets/Images/insta.png")
const saloon_template_sponser1 = require("../assets/Images/sponsor-1.png")
const saloon_template_sponser2 = require("../assets/Images/sponsor-2.png")
const saloon_template_sponser3 = require("../assets/Images/sponsor-3.png")
const saloon_template_sponser4 = require("../assets/Images/sponsor-4.png")
const saloon_template_sponser5 = require("../assets/Images/sponsor-5.png")
const saloon_template_footer_phone = require("../assets/Images/phone.png")
const saloon_template_footer_email = require("../assets/Images/email.png")
const saloon_template_footer_location = require("../assets/Images/location.png")
const saloon_template_footer_nav = require("../assets/Images/footer_nav.png")
const saloon_template_dropdown = require("../assets/Images/down.png")

//Saloon Template 2
const saloon_template2_nav = require("../assets/Images/logo.png")
const saloon_template2_slider = require("../assets/Images/slide-1.jpg")
const saloon_template2_aboutus = require("../assets/Images/aboutus.png")
const saloon_template2_aboutlogo = require("../assets/Images/about-logo.png")
const saloon_template2_headline = require("../assets/Images/heading-line.png")
const saloon_template2_service_razor = require("../assets/Images/razor.png")
const saloon_template2_service_razor_1 = require("../assets/Images/razor-1.png")
const saloon_template2_service_haircut = require("../assets/Images/hair-cut.png")
const saloon_template2_service_cream = require("../assets/Images/cream.png")
const saloon_template2_service_electric_razor = require("../assets/Images/electric-razor.png")
const saloon_template2_service_brush = require("../assets/Images/brush.png")
const saloon_template2_service_straightener = require("../assets/Images/straightener.png")
const saloon_template2_service_hair_dryer = require("../assets/Images/hairdryer.png")
const saloon_template2_img1 = require("../assets/Images/img1.jpg")
const saloon_template2_img2 = require("../assets/Images/img2.jpg")
const saloon_template_border = require("../assets/Images/border.png")
const saloon_template2_star = require("../assets/Images/fill.png")
const saloon_template2_mustache = require("../assets/Images/mustache-border.png")

//Saloon Template 3
const saloon_template3_main = require("../assets/Images/template3_main.jpg")
const saloon_template3_about = require("../assets/Images/template3_about.png")
const saloon_template3_seprator = require("../assets/Images/dark_seperator.png")
const saloon_template3_service1 = require("../assets/Images/template3_service1.png")
const saloon_template3_service2 = require("../assets/Images/template3_service2.png")
const saloon_template3_service3 = require("../assets/Images/template3_service3.png")
const saloon_template3_service5 = require("../assets/Images/template3_service5.png")
const saloon_template3_service6 = require("../assets/Images/template3_service6.png")
const saloon_template3_service7 = require("../assets/Images/template3_service7.png")
const saloon_template3_service8 = require("../assets/Images/template3_service8.png")
const saloon_template3_service9 = require("../assets/Images/template3_service9.png")
const saloon_template3_menu = require("../assets/Images/MenuBackrground.jpg")
const saloon_template3_menu_seprator = require("../assets/Images/white_seprator.png")
const saloon_template3_employee1 = require("../assets/Images/member1.jpg")
const saloon_template3_employee2 = require("../assets/Images/member2.jpg")
const saloon_template3_employee3 = require("../assets/Images/member3.jpg")
const saloon_template3_employee4 = require("../assets/Images/member4.jpg")
const my_appointment = require("../assets/Images/Icons/myappointment.png")
const wallet_icon = require("../assets/Images/Icons/wallet.png")
const mark_tick = require("../assets/Images/Icons/mark.png")

//Customer
const saloon_customer_name = require("../assets/Images/custname.png")
const saloon_customer_phone = require("../assets/Images/custphone.png")
const saloon_customer_email = require("../assets/Images/custemail.png")
const saloon_customer_postal = require("../assets/Images/custpostal.png")

const interac_etransfer_logo = require("../assets/Images/interac_etransfer_logo.png")

const saloon_delete_icon = require("../assets/Images/Icons/delete_icon.png")
const saloon_edit_icon = require("../assets/Images/Icons/edit_icon.png")
const table_delete_icon = require("../assets/Images/Icons/iconsForTable/DELETE.svg")
const table_edit_icon = require("../assets/Images/Icons/iconsForTable/EDIT.svg")
const table_clock_icon = require("../assets/Images/Icons/iconsForTable/CLOCK.svg")
const table_view_icon = require("../assets/Images/Icons/iconsForTable/PREVIEW.svg")
const saloon_transaction_list_icon = require("../assets/Images/Icons/iconsForTable/LISTOFTRANSACTIONS.svg")
const saloon_transaction_table_icon = require("../assets/Images/Icons/iconsForTable/TRANSACTIONS.svg")
const saloon_transaction_icon = require("../assets/Images/Icons/transaction.png")
const saloon_view_transaction_icon = require("../assets/Images/Icons/search.png")
const reuse = require("../assets/Images/Icons/reuse.png")

const microsoft_logo = require("../assets/Images/microsoft_logo.svg")
const funds_by_pending = require("../assets/Images/Icons/request funds/by/PENDING.svg")
const funds_by_available = require("../assets/Images/Icons/request funds/by/AVAILABLE.svg")
const funds_to_pending = require("../assets/Images/Icons/request funds/to/PENDING.svg")
const funds_to_available = require("../assets/Images/Icons/request funds/to/AVAILABLE.svg")

const attach_file = require("../assets/Images/Icons/popup screens icons/ATTACH.svg")
const favicon = require("../assets/Images/favicon.ico")
// Demo vid
export default {
  wallet_icon,
  easy1_logo_800x300,
  selling_card_img_1,
  selling_card_img_2,
  template_selling_card_img_1,
  favicon,
  my_appointment,
  close_icon,
  order_sort,
  show_list_icon,
  show_grid_icon,
  rating_star,
  rating_star_blue,
  pagination_forward,
  pagination_back,
  setting_icon,
  download_ios,
  download_andriod,
  email_icon,
  facebook_icon,
  instagram_icon,
  youtube_icon,
  google_signin,
  facebook_signin,
  mail_signin,
  step_done_tick,
  step_done_tick_orange,
  saloon_form_submit,
  saloon_footer_facebook,
  saloon_footer_instagram,
  saloon_footer_twitter,
  saloon_footer_youtube,
  saloon_form_visa,
  saloon_form_american_express,
  saloon_form_credit_card,
  saloon_form_master_card,
  saloon_form_paypal,
  _preview,
  dots,
  Layer5,
  Layer6,
  tick,
  pic1,
  viewAll,
  clock,
  calendar,
  bookingClock,
  price_tag,
  tag_grey,
  star_empty,
  star_yellow,
  saloon_plus,
  saloon_dashboard_wallet,
  saloon_headingDropdown,
  white_profile,
  down_white,
  saloon_search,
  saloon_addEmployee_plus,
  saloon_addEmployee_tooltiparrow,
  service_bulb,

  // For Saloon Dashboard
  saloon_profile_icon,
  saloon_navbar_menu,
  saloon_navbar_profile_dropdown,
  saloon_chat_send,
  saloon_chat_clip,
  saloon_upload_media_icon,
  saloon_social_facebook,
  saloon_social_instagram,
  saloon_social_linkedin,
  saloon_social_snapchat,
  saloon_social_twitter,
  saloon_social_youtube,
  saloon_link_social_facebook,
  saloon_link_social_instagram,
  saloon_link_social_linkedin,
  saloon_link_social_snapchat,
  saloon_link_social_twitter,
  saloon_link_social_youtube,
  saloon_working_hour_edit,
  saloon_working_hour_delete,
  saloon_category_pic,
  saloon_edit_employee_check_icon,
  saloon_available_services_date,
  saloon_available_services_time,
  img,
  saloon_add,
  saloon_check,

  // For Saloon Dashboard Drawer Icons
  saloon_drawer_profile,
  saloon_drawer_chat_box,
  saloon_drawer_category,
  saloon_drawer_link_social,
  saloon_drawer_log_out,
  saloon_drawer_rating,
  saloon_drawer_reports,
  saloon_drawer_booking,

  // For Saloon Dashboard Company Sidebar Menu
  activities_svg_dark,
  ai_booking_svg_dark,
  arrow_svg_dark,
  billing_svg_dark,
  customers_svg_dark,
  dashboard_svg_dark,
  employees_svg_dark,
  forms_svg_dark,
  logout_svg_dark,
  marketing_svg_dark,
  my_appointment_svg_dark,
  my_business_svg_dark,
  products_and_services_svg_dark,
  profile_svg_dark,
  programs_svg_dark,
  purchase_svg_dark,
  rating_svg_dark,
  reports_svg_dark,
  taxes_svg_dark,
  wallet_svg_dark,

  activities_svg_light,
  ai_booking_svg_light,
  arrow_active_svg,
  billing_svg_light,
  customers_svg_light,
  dashboard_svg_light,
  employees_svg_light,
  forms_svg_light,
  logout_svg_light,
  marketing_svg_light,
  my_appointment_svg_light,
  my_business_svg_light,
  products_and_services_svg_light,
  profile_svg_light,
  programs_svg_light,
  purchase_svg_light,
  rating_svg_light,
  reports_svg_light,
  taxes_svg_light,
  wallet_svg_light,

  // For Saloon Dashboard Employee Sidebar Menu
  active_salon_dark_svg,
  request_funds_dark_svg,
  social_links_dark_svg,
  upload_media_dark_svg,
  working_hours_dark_svg,

  active_salon_light_svg,
  request_funds_light_svg,
  social_links_light_svg,
  upload_media_light_svg,
  working_hours_light_svg,

  // Icons For Table
  info_svg,
  note_svg,
  edit_svg,

  // For Company Setup
  saloon_companysetup_edit,
  saloon_companysetup_delete,

  //For Template
  saloon_template_barber,
  saloon_template_nav_img,
  saloon_template_nav_phone,
  saloon_template_nav_mail,
  saloon_template_nav_time,
  saloon_template_service_hair,
  saloon_template_service_haircolor,
  saloon_template_service_facemask,
  saloon_template_service_shave,
  saloon_template_service_scisssor,
  saloon_template_service_mustache,
  saloon_template_about,
  saloon_template_price,
  saloon_template_employee1,
  saloon_template_employee2,
  saloon_template_employee3,
  saloon_template_employee4,
  saloon_template_employee5,
  saloon_template_facebook,
  saloon_template_youtube,
  saloon_template_twitter,
  saloon_template_insta,
  saloon_template_sponser1,
  saloon_template_sponser2,
  saloon_template_sponser3,
  saloon_template_sponser4,
  saloon_template_sponser5,
  saloon_template_footer_phone,
  saloon_template_footer_email,
  saloon_template_footer_location,
  saloon_template_footer_nav,
  saloon_template_dropdown,

  user_profile_image,

  //For Saloon Template 3
  saloon_template3_main,
  saloon_template3_about,
  saloon_template3_seprator,
  saloon_template3_service1,
  saloon_template3_service2,
  saloon_template3_service3,
  saloon_template3_service5,
  saloon_template3_service6,
  saloon_template3_service7,
  saloon_template3_service8,
  saloon_template3_service9,
  saloon_template3_menu,
  saloon_template3_menu_seprator,
  saloon_template3_employee1,
  saloon_template3_employee2,
  saloon_template3_employee3,
  saloon_template3_employee4,
  //Template 2
  saloon_template2_nav,
  saloon_template2_slider,
  saloon_template2_aboutus,
  saloon_template2_aboutlogo,
  saloon_template2_headline,
  saloon_template2_service_razor,
  saloon_template2_service_razor_1,
  saloon_template2_service_haircut,
  saloon_template2_service_cream,
  saloon_template2_service_electric_razor,
  saloon_template2_service_brush,
  saloon_template2_service_straightener,
  saloon_template2_service_hair_dryer,
  saloon_template2_img1,
  saloon_template2_img2,
  saloon_template_border,
  saloon_template2_star,
  saloon_template2_mustache,
  mark_tick,
  //Customer
  saloon_customer_name,
  saloon_customer_phone,
  saloon_customer_email,
  saloon_customer_postal,

  interac_etransfer_logo,
  easy1_logo,
  saloon_edit_icon,
  saloon_delete_icon,
  table_edit_icon,
  table_delete_icon,
  table_clock_icon,
  table_view_icon,
  saloon_transaction_list_icon,
  saloon_transaction_table_icon,
  saloon_transaction_icon,
  saloon_view_transaction_icon,
  reuse,
  microsoft_logo,
  dashboard_appointments,
  dashboard_employees,
  dashboard_services,
  add_profile,
  check,
  funds_by_pending,
  funds_by_available,
  funds_to_pending,
  funds_to_available,
  attach_file
}
