import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import i18n from '../../i18n';
import { DotsLoader, Header } from '../../components'
import { request as verify_reset_code_request } from '../../redux/actions/VerifyResetCode'
import { SuccessHelper } from "../../helpers";

import './styles.css'
import SharedStorage from "../../helpers/Storage";

class VerifyResetCode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            code: "",
            isloading: false,
        };
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.verifyResetCode) {
            if (
                !nextProps.verifyResetCode.failure &&
                !nextProps.verifyResetCode.isFetching &&
                nextProps.verifyResetCode.data &&
                nextProps.verifyResetCode.data.success
            ) {
                SuccessHelper.handleSuccess(<Translation>{t => t('container.verify_reset_code_email_verified')}</Translation>, true);
                this.props.history.push('/reset-password')
                this.setState({ isloading: false, email: '' });
            } else if (
                nextProps.verifyResetCode.failure &&
                !nextProps.verifyResetCode.isFetching &&
                nextProps.verifyResetCode.errorMessage
            ) {
                this.setState({ isloading: false });
            }
        }
    }

    async componentDidMount() {
        const user = JSON.parse(await SharedStorage.getItem("user"));
        if (user && user.access_token) {
            this.handleNavigation('/')
        }
    }

    handleNavigation = (route) => {
        this.props.history.push(route);
    };

    onChangeCode = (value) => this.setState({ code: value })

    handleresetPasswordCode = () => {
        this.setState({ isloading: true });
        const { code } = this.state;
        const { forgotPassword } = this.props;
        const payload = {
            token: code,
            userId: forgotPassword.data.userId,
        }
        this.props.verify_reset_code_request(payload)
    }

    renderLoader = () => {
        const { isloading } = this.state
        return (
            <DotsLoader isloading={isloading} />
        )
    }

    render() {
        return (
            <div className="container-fluid">
                <h1 className="h1"><Translation>{t => t('container.verify_reset_code_verify_code')}</Translation></h1>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({ verifyResetCode: state.verifyResetCode, forgotPassword: state.forgotPassword });

const action = { verify_reset_code_request };

export default connect(mapStateToProps, action)(VerifyResetCode);