import React, { useState, useEffect } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DetailsField from "./DetailsField.component";
import styled from "styled-components";

const MultipleChoiceWrapper = styled.div`
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 11px;
  width: 100%;
`;
const GenerateMultipleChoiceField = ({
  field,
  handleSubmit,
  readonly,
  answer,
  detailsRequired,
  details,
}) => {
  const [userChoices, setUserChoices] = useState({});
  useEffect(() => {
    handleSubmit(null, field._id);
  }, []);
  const handleOnChange = (e, choice, type) => {
    if (choice) {
      let userChoices2 = { ...userChoices };
      userChoices2[choice.label] = e.target.checked;
      setUserChoices(userChoices2);
      handleSubmit(userChoices2, field._id, type);
    } else {
      handleSubmit(e.target.value, field._id, type);
    }
  };

  const { fieldOptions = "" } = field;
  const inputTypes = fieldOptions.split("-");

  const inputTypeOptions = inputTypes.map((option) => {
    const cap = option.charAt(0).toUpperCase() + option.slice(1);
    return { value: option, label: cap };
  });
  return (
    <MultipleChoiceWrapper>
      <h6>{field.fieldName}</h6>
      <FormGroup>
        {inputTypeOptions.map((choice) => (
          <FormControlLabel
            key={`${choice}-key`}
            control={
              <Checkbox
                disabled={readonly}
                defaultChecked={answer?.[choice.label]}
                onChange={(e) => handleOnChange(e, choice, "answer")}
              />
            }
            label={choice.label}
          />
        ))}
        {detailsRequired && (
          // <TextField
          //   onChange={(e) => handleOnChange(e, undefined, "details")}
          //   value={details}
          //   type="text"
          //   variant="outlined"
          //   inputProps={{ readOnly: readonly }}
          // />
          <DetailsField
            handleOnChange={handleOnChange}
            details={details}
            readonly={readonly}
          />
        )}
      </FormGroup>
    </MultipleChoiceWrapper>
  );
};

export default GenerateMultipleChoiceField;
