import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback,
} from "react"
import { useSelector } from "react-redux"
import {
  getAllEmployeeAssociatedWithCompany,
  getSaloonCategories,
  getCompanyTaxes,
  getProduct,
  getPackage,
  getGetGiftCard,
  saloonGetAllService,
  getCompanyThatEmployeeAdd,
  getEmployeeCustomers,
  getCompanyCustomers,
  getIntakeCategories,
} from "../config/simpleApiCalls"
import useActiveMenuType from "./ActiveMenuType"
import Pusher from "pusher-js"
var pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
  cluster: "us2",
})

import { ROLES } from "../util/enum"
export const LocalCacheContext = createContext({
  getResource: () => { },
})

const defaultReturn = []

export function LocalCacheProvider({ children }) {
  const { type } = useActiveMenuType()
  const computedType = useMemo(() => {
    if (type == "employee") {
      return "employee"
    }
    return "company"
  }, [type])
  const [companyCachedResourceMap, setCompanyCachedResourceMap] = useState({})
  const [employeeCachedResourceMap, setEmployeeCachedResourceMap] = useState({})
  const [companyId, setCompanyId] = useState("")
  const [channelsMap, setChannelsMap] = useState({})

  const employeeData = useSelector((s) => s.user.data.employee)
  const companyData = useSelector((s) => s.user.data.company)
  const parsedEmployeeData = useMemo(() => {
    if (employeeData) {
      return JSON.parse(employeeData)
    }
  }, [employeeData])

  useEffect(() => {
    if (companyData) {
      const company = JSON.parse(companyData)
      setCompanyId(company._id)
    } else {
      setCompanyId("")
    }
  }, [companyData])

  const hasCompanyAccess = useMemo(() => {
    if (!parsedEmployeeData) {
      return false
    }
    const { role } = parsedEmployeeData
    return role.includes(ROLES.RECEPTIONIST) || role.includes(ROLES.MANAGER) || role.includes(ROLES.OWNER)
  }, [parsedEmployeeData])

  const fetchCategories = () => {
    return getSaloonCategories({ companyId }).then((res) => res.data.data)
  }

  const fetchEmployees = () => {
    return getAllEmployeeAssociatedWithCompany({ companyId }).then(
      (res) => res.data.Data
    )
  }

  const fetchTaxes = () => {
    return getCompanyTaxes({ companyId }).then((res) => res.data.data)
  }

  const fetchProducts = () => {
    return getProduct({ companyId }).then((res) => res.data.data)
  }
  const fetchPackages = () => {
    return getPackage({ companyId }).then((res) => res.data.data)
  }
  const fetchGiftCards = () => {
    return getGetGiftCard({ companyId }).then((res) => res.data.data)
  }
  const fetchServices = () => {
    return saloonGetAllService().then((res) => res.data.data)
  }

  const fetchCompanyCustomers = () => {
    return getCompanyCustomers({ companyId })
  }

  const fetchIntakeCategories = () => {
    return getIntakeCategories().then((res) => res.data.data)
  }

  const populateCompanyCache = async () => {
    const [
      categories,
      employees,
      taxes,
      products,
      packages,
      giftCards,
      services,
      customers,
      intakeCategories,
    ] = await Promise.all([
      fetchCategories(),
      fetchEmployees(),
      fetchTaxes(),
      fetchProducts(),
      fetchPackages(),
      fetchGiftCards(),
      fetchServices(),
      fetchCompanyCustomers(),
      fetchIntakeCategories(),
    ])

    setCompanyCachedResourceMap({
      ...companyCachedResourceMap,
      categories,
      employees,
      taxes,
      products,
      packages,
      giftCards,
      services,
      customers,
      intakeCategories,
    })
  }
  useEffect(() => {
    if (companyId) {
      setCompanyCachedResourceMap({})
      if (hasCompanyAccess) {
        populateCompanyCache()
      }
    }
  }, [hasCompanyAccess, companyId])

  useEffect(() => {
    if (companyId) {
      if (!channelsMap[companyId]) {
        const channel = pusher.subscribe(companyId)
        setChannelsMap({
          ...channelsMap,
          [companyId]: channel,
        })
      }
    }
    if (employeeCachedResourceMap.companies) {
      employeeCachedResourceMap.companies.map(
        ({ companyId: { _id: companyId } }) => {
          if (!channelsMap[companyId]) {
            const channel = pusher.subscribe(companyId)
            setChannelsMap({
              ...channelsMap,
              [companyId]: channel,
            })
          }
        }
      )
    }
  }, [companyId, employeeCachedResourceMap.companies])

  useEffect(() => {
    if (!hasCompanyAccess) {
      setCompanyCachedResourceMap({})
    }
  }, [hasCompanyAccess])

  useEffect(() => {
    setCompanyCachedResourceMap({
      ...companyCachedResourceMap,
      activeTaxes: (companyCachedResourceMap.taxes || []).filter(
        ({ isActive }) => isActive
      ),
    })
  }, [companyCachedResourceMap.taxes])

  const fetchEmployeeData = () => {
    const params = {
      relations: JSON.stringify([
        "services",
        "taxes",
        "products",
        "categories",
      ]),
    }
    return getCompanyThatEmployeeAdd(params).then((res) => {
      const { companyTaxes, products, data: companies } = res.data
      const serviceMap = {}
      const categoryMap = {}
      const categoryServiceMap = {}
      companies.map((company) => {
        const { companyId, companyServices } = company
          ; (companyServices || []).map(({ serviceId }) => {
            if (!categoryServiceMap[companyId._id]) {
              categoryServiceMap[companyId._id] = {}
              categoryMap[companyId._id] = {}
            }
            if (!categoryServiceMap[companyId._id][serviceId.serviceId._id]) {
              categoryServiceMap[companyId._id][serviceId.serviceId._id] = []
              categoryMap[companyId._id][serviceId.serviceId._id] =
                serviceId.serviceId
            }
            categoryServiceMap[companyId._id][serviceId.serviceId._id].push(
              serviceId
            )
          })
        serviceMap[companyId._id] = company.companyServices
      })

      Object.keys(categoryMap).map((companyId) => {
        categoryMap[companyId] = Object.values(categoryMap[companyId])
      })

      const taxMap = {}
      companyTaxes.map((tax) => {
        if (!taxMap[tax.companyId]) {
          taxMap[tax.companyId] = []
        }
        taxMap[tax.companyId].push(tax)
      })

      const productMap = {}
      products.map((product) => {
        if (!productMap[product.companyId]) {
          productMap[product.companyId] = []
        }
        productMap[product.companyId].push(product)
      })
      return {
        companies,
        taxMap,
        productMap,
        serviceMap,
        categoryServiceMap,
        categoryMap,
      }
    })
  }

  const fetchEmployeeCustomers = () => {
    return getEmployeeCustomers()
  }

  const populateEmployeeCache = async () => {
    const [generalData, customers, intakeCategories] = await Promise.all([
      fetchEmployeeData(),
      fetchEmployeeCustomers(),
      fetchIntakeCategories(),
    ])

    setEmployeeCachedResourceMap({
      ...employeeCachedResourceMap,
      ...generalData,
      customers,
      intakeCategories,
    })
  }

  useEffect(() => {
    if (parsedEmployeeData) {
      populateEmployeeCache()
    } else {
      setEmployeeCachedResourceMap({})
    }
  }, [parsedEmployeeData])

  useEffect(() => {
    const updateEmployee = async (shouldFetchCustomer) => {
      const [generalData, customers] = await Promise.all([
        fetchEmployeeData(),
        new Promise(async (resolve) => {
          if (shouldFetchCustomer) {
            resolve(await fetchEmployeeCustomers())
          }
          resolve(employeeCachedResourceMap.customers)
        }),
      ])
      setEmployeeCachedResourceMap({
        ...employeeCachedResourceMap,
        ...generalData,
        customers,
      })
    }
    Object.keys(channelsMap).map((companyId) => {
      const channel = channelsMap[companyId]
      channel.unbind("cache-update")
      const onCacheUpdate = async ({ type }) => {
        if (type == "employees") {
          if (hasCompanyAccess) {
            const employees = await fetchEmployees()
            setCompanyCachedResourceMap({
              ...companyCachedResourceMap,
              employees,
            })
          }
          updateEmployee(true)
          return
        }
        if (type == "taxes") {
          if (hasCompanyAccess) {
            const taxes = await fetchTaxes()
            setCompanyCachedResourceMap({
              ...companyCachedResourceMap,
              taxes,
            })
          }
          updateEmployee()
        }
        if (type == "products") {
          if (hasCompanyAccess) {
            const products = await fetchProducts()
            setCompanyCachedResourceMap({
              ...companyCachedResourceMap,
              products,
            })
          }
          updateEmployee()
        }
        if (type == "packages") {
          if (hasCompanyAccess) {
            const packages = await fetchPackages()
            setCompanyCachedResourceMap({
              ...companyCachedResourceMap,
              packages,
            })
          }
          updateEmployee()
        }
        if (type == "giftCards") {
          if (hasCompanyAccess) {
            const giftCards = await fetchGiftCards()
            setCompanyCachedResourceMap({
              ...companyCachedResourceMap,
              giftCards,
            })
          }
          updateEmployee()
        }
        if (type == "customers") {
          if (hasCompanyAccess) {
            const customers = await fetchCompanyCustomers()
            setCompanyCachedResourceMap({
              ...companyCachedResourceMap,
              customers,
            })
          }
          const employeeCustomers = await fetchEmployeeCustomers()
          setEmployeeCachedResourceMap({
            ...employeeCachedResourceMap,
            customers: employeeCustomers,
          })
        }
        if (type == "services") {
          if (hasCompanyAccess) {
            const services = await fetchServices()
            setCompanyCachedResourceMap({
              ...companyCachedResourceMap,
              services,
            })
          }
          updateEmployee()
        }
        if (type == "intakeCategories") {
          if (hasCompanyAccess) {
            const intakeCategories = await fetchIntakeCategories()
            setCompanyCachedResourceMap({
              ...companyCachedResourceMap,
              intakeCategories,
            })
          }
          updateEmployee()
        }
      }
      channel.bind("cache-update", onCacheUpdate)
    })
  }, [employeeCachedResourceMap, companyCachedResourceMap, channelsMap])

  const getResource = useCallback(
    (selector, formatter) => {
      let map = {
        company: companyCachedResourceMap,
        employee: employeeCachedResourceMap,
      }

      const toReturn = selector(map)
      if (!toReturn) {
        return defaultReturn
      }
      if (formatter) {
        if (typeof formatter == "function") {
          return toReturn.map(formatter)
        } else {
          console.warn("Formatter must be a function")
        }
      }
      return toReturn
    },
    [companyCachedResourceMap, employeeCachedResourceMap, computedType]
  )

  return (
    <LocalCacheContext.Provider value={{ getResource }}>
      {children}
    </LocalCacheContext.Provider>
  )
}

//Hook
function useCache() {
  return useContext(LocalCacheContext)
}

export default useCache
