import React from "react"
import { useMemo } from "react"
import { useSelector } from "react-redux"
import BasicTable from "../../../../../../components/Core/BasicTable"
import i18n from "../../../../../../i18n"
import { Bar } from "react-chartjs-2"

export default function CampaignResults() {
  const activeCampaign = useSelector(
    (s) => s.communicationsMarketingCampaign.activeCampaign
  )
  const [resultRow, moneyRow] = useMemo(() => {
    if (!activeCampaign) return []
    const totalSent = activeCampaign.recipients.filter(
      (recipient) => recipient.status == "Sent"
    ).length
    const totalDelivered = activeCampaign.recipients.filter(
      (recipient) => recipient.status == "Delivered"
    ).length
    const totalOpened = activeCampaign.recipients.filter(
      (recipient) => recipient.status == "Opened"
    ).length
    const totalBooked = activeCampaign.recipients.filter(
      (recipient) => recipient.status == "Booked"
    ).length
    const totalFailed = activeCampaign.recipients.filter(
      (recipient) => recipient.status == "Failed"
    ).length
    const totalRevenue = Math.round(
      activeCampaign.bookings.reduce(
        (accum, booking) => accum + booking.totalAmount,
        0
      ) * 100
    )
    const totalSpent = activeCampaign.totalCharge
    const roi = `${((totalRevenue / totalSpent) * 100).toFixed(2)}%`
    return [
      [totalSent, totalDelivered, totalOpened, totalBooked, totalFailed],
      [
        {
          totalSpent: `$${(totalSpent / 100).toFixed(2)}`,
          totalRevenue: `$${(totalRevenue / 100).toFixed(2)}`,
          totalBooked,
          roi,
        },
      ],
    ]
  }, [activeCampaign])
  const barChartData = useMemo(() => {
    const datasets = [
      {
        data: resultRow,
        backgroundColor: [
          "#6B6CD92E",
          "rgba(75, 0, 192, 1)",
          "rgba(0, 192, 192, 1)",
          "rgba(75, 192, 0, 1)",
          "rgba(255, 25, 25, 1)",
        ]
      },
    ]
    const data = {
      label: "",
      labels: [
        i18n.t("sent"),
        i18n.t("delivered"),
        i18n.t("opened"),
        i18n.t("booked"),
        i18n.t("failed"),
      ],
      datasets,
    }

    return data
  }, [resultRow])
  return (
    <div>
      <div className="dashboard-box d-flex justify-content-center mb-3">
        <Bar
          data={barChartData}
          options={{
            legend: {
              display: false,
            },
          }}
        />
      </div>
      <div className="dashboard-box d-flex justify-content-center">
        <BasicTable
          rows={moneyRow}
          schema={[
            {
              header: i18n.t("total_spent"),
              field: "totalSpent",
            },
            {
              header: i18n.t("total_revenue"),
              field: "totalRevenue",
            },
            {
              header: i18n.t("total_booked"),
              field: "totalBooked",
            },
            {
              header: i18n.t("roi"),
              field: "roi",
            },
          ]}
        />
      </div>
    </div>
  )
}
