import React, { useState, useEffect } from "react"
import { DotsLoader } from "../../../components"
import i18n from "../../../i18n"
import {
  getSocialLinks,
  UpdateSaloonSocialMedia,
  createSaloonSocialMediaLink,
} from "../../../config/simpleApiCalls"
import PrimaryButton from "../../../components/Core/PrimaryButton/index"
import SocialMediaForm from "./SocialMediaForm"
import SuccessHelper from "../../../helpers/SuccessHelper"
import ErrorHelper from "../../../helpers/ErrorHelper"
import SharedStorage from "../../../helpers/Storage"

export default function SaloonSocialMedia() {
  const [isLoading, setIsLoading] = useState(false)
  const [companyId, setCompanyId] = useState("")
  const [socialLinks, setSocialLinks] = useState({
    facebook: undefined,
    instagram: undefined,
    twitter: undefined,
    youtube: undefined,
    snapchat: undefined,
    linkedin: undefined,
  })

  const onMount = async () => {
    const companyData = JSON.parse(await SharedStorage.getItem("company"))
    if (companyData) {
      setCompanyId(companyData._id)
    }
    getAllSocialMedia()
  }
  useEffect(() => {
    onMount()
  }, [])

  const getAllSocialMedia = () => {
    setIsLoading(true)
    getSocialLinks()
      .then((res) => {
        if (res.data.success && res.data.data) {
          const socialMap = {}
          res.data.data.socialURL.map((url) => {
            socialMap[url.socialType] = url.url
          })
          setSocialLinks({ ...socialLinks, ...socialMap, hasLink: true })
          setIsLoading(false)
        } else {
          setIsLoading(false)
        }
      })
      .catch((error) => {
        console.log(error, "errorrrrrrrrrrrrrrrrrrr")
        setIsLoading(false)
      })
  }

  const handleChange = (e) => {
    setSocialLinks({
      ...socialLinks,
      [e.target.name]: e.target.value,
    })
  }

  const onUpdateSocialMedia = async () => {
    setIsLoading(true)
    const payload = { companyId, socialURL: [] }
    Object.keys(socialLinks).map((key) => {
      if (socialLinks[key] && key !== "hasLink") {
        payload.socialURL.push({
          socialType: key,
          url: socialLinks[key],
        })
      }
    })
    UpdateSaloonSocialMedia(payload)
      .then((res) => {
        if (res.data.success) {
          SuccessHelper.handleSuccess(i18n.t("successfully_updated"), true)
          getAllSocialMedia()
        } else {
          setIsLoading(false)
        }
      })
      .catch((error) => {
        console.log(error, "errorrrrrrrrrrrrrrrrrrr")
        setIsLoading(false)
        ErrorHelper.handleErrors(i18n.t("failed_to_update"), true)
      })
  }

  const onCreateSocialMedia = async () => {
    setIsLoading(true)
    const payload = { companyId, socialURL: [] }
    Object.keys(socialLinks).map((key) => {
      if (socialLinks[key] && key !== "hasLink") {
        payload.socialURL.push({
          socialType: key,
          url: socialLinks[key],
        })
      }
    })
    createSaloonSocialMediaLink(payload)
      .then((res) => {
        if (res.data.success) {
          SuccessHelper.handleSuccess(i18n.t("successfully_added"), true)
          getAllSocialMedia()
        } else {
          setIsLoading(false)
        }
      })
      .catch((error) => {
        console.log(error, "errorrrrrrrrrrrrrrrrrrr")
        ErrorHelper.handleErrors(i18n.t("failed_to_add"), true)
        setIsLoading(false)
      })
  }

  return (
    <>
      <DotsLoader isloading={isLoading} />
      <div className="content-container">
        <div className="row mx-0">
          <div className="col-12 my-3">
            <h1 className="saloon-dashboard-heading">
              {i18n.t("container.dashboard_social_media_icons")}
            </h1>
          </div>
          <div className="col-12 py-3 px-0">
            <div className="col-12 bg-white social-container">
              <SocialMediaForm {...{ handleChange, socialLinks }} />

              <div className="col-12 px-0 d-flex justify-content-center justify-content-md-start py-4">
                <div className="py-3 pt-6">
                  {!socialLinks.hasLink && (
                    <PrimaryButton
                      className="w-128"
                      onClick={() => onCreateSocialMedia()}
                      label={i18n.t("container.save_changes")}
                    />
                  )}
                  {socialLinks.hasLink && (
                    <PrimaryButton
                      className="w-128"
                      onClick={() => onUpdateSocialMedia()}
                      label={i18n.t("container.update_changes")}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
