// @flow

import { SEMESTER } from "../ActionTypes"

export function getRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: SEMESTER.GET_SAGA,
  }
}

export function get(data) {
  return {
    data,
    type: SEMESTER.GET,
  }
}

export function removeRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: SEMESTER.DELETE_SAGA,
  }
}

export function remove(data) {
  return {
    data,
    type: SEMESTER.DELETE,
  }
}

export function upsertRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: SEMESTER.UPSERT_SAGA,
  }
}

export function upsert(data) {
  return {
    data,
    type: SEMESTER.UPSERT,
  }
}

export function removeClassRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: SEMESTER.DELETE_CLASS_SAGA,
  }
}

export function removeClass(data) {
  return {
    data,
    type: SEMESTER.DELETE_CLASS,
  }
}

export function addClassRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: SEMESTER.ADD_CLASS_SAGA,
  }
}

export function addClass(data) {
  return {
    data,
    type: SEMESTER.ADD_CLASS,
  }
}

export function removeStudentFromClassRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: SEMESTER.DELETE_STUDENT_FROM_CLASS_SAGA,
  }
}

export function removeStudentFromClass(data) {
  return {
    data,
    type: SEMESTER.DELETE_STUDENT_FROM_CLASS,
  }
}

export function addStudentToClassRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: SEMESTER.ADD_STUDENT_TO_CLASS_SAGA,
  }
}

export function addStudentToClass(data) {
  return {
    data,
    type: SEMESTER.ADD_STUDENT_TO_CLASS,
  }
}

export function addStudentGroupToClassRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: SEMESTER.ADD_STUDENT_GROUP_TO_CLASS_SAGA,
  }
}

export function addStudentGroupToClass(data) {
  return {
    data,
    type: SEMESTER.ADD_STUDENT_GROUP_TO_CLASS,
  }
}

export function requestFailure() {
  return {
    type: SEMESTER.REQUEST_FAILURE,
  }
}
