import React from "react";
import { getNestedValue } from "../../util/objectMethods";
export default function DevicesAndEquipmentOrder({
  handleDeviceQuantityChange,
  equipement,
  selectDeviceQuantityMap,
}) {
  equipement = equipement.map(element => ({ price_id: element.prices[0].id, ...element }))
  return (

    <table className="table table-borderless table-layout-fixed">
      <tbody>
        {equipement.map((val) => {
          const unitAmount = getNestedValue(val, ['prices', 0, 'unit_amount'], 0)
          const formattedUnitValue = unitAmount ? `$${(unitAmount / 100).toFixed(2)}` : 0
          const deviceQuantity = getNestedValue(selectDeviceQuantityMap, [val.price_id], 0)
          return (
            <div className="d-flex align-items-center gap-5 mb-3">
              <h6 className="mb-0 font-weight-bold">{val.name} ({formattedUnitValue})</h6>
              <div className="d-flex align-items-center" key={val.id}>
                <span
                  className="qty-btn1"
                  onClick={() =>
                    handleDeviceQuantityChange(val.price_id, deviceQuantity - 1)
                  }
                >
                  &minus;
                </span>
                <h5 className="mx-4 mb-0">{deviceQuantity}</h5>
                <span
                  className="qty-btn1"
                  onClick={() =>
                    handleDeviceQuantityChange(val.price_id, deviceQuantity + 1)
                  }
                >
                  &#43;
                </span>
              </div>
            </div>
          );
        })}
      </tbody>
    </table>
  )
}