import { time_period } from "../../../config/WebServices"
import SagaBuilder from "../../builders/SagaBuilder"
import actions from "../../actions/Saloon/TimePeriod"

const timePeriod = SagaBuilder.build({
  type: "TIME_PERIOD",
  endpoint: time_period,
  actions,
})

export default timePeriod
