import { Capacitor } from "@capacitor/core";
import { Browser as browser } from "@capacitor/browser";

const Broswer = {
  open: async (url, ...args) => {
    console.log({ url });
    if (Capacitor.platform == "web") {
      return window.open(url, ...args);
    } else {
      return browser.open({ url });
    }
  },
};

export default Broswer;
