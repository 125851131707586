import React, { useEffect, useMemo, useRef, useState } from "react";
import i18n from "../../../../i18n";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import PrimaryButton from "../../../../components/Core/PrimaryButton";
import { BaseModal } from "../../../../components";
import Tabs from "../../../../components/Core/Tabs/Tabs";
import { Checkbox, TextField } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import styled from "styled-components/macro";

const CustomersHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
  margin-bottom: 1em;
`;

export default function CustomerGroupUpsert({
  open,
  onClose,
  onSave,
  active = {},
  customers,
}) {
  const form = useRef();
  const [data, setData] = useState({});
  const [selectedCustomerMap, setSelectedCustomerMap] = useState({});
  const [searchPhrase, setSearchPhrase] = useState("");
  const filteredCustomers = useMemo(
    () => handleSearch(searchPhrase),
    [searchPhrase, customers]
  );

  useEffect(() => {
    if (open) {
      const map = {};
      const customers = active.customers || [];
      customers.map((customer) => {
        map[customer] = true;
      });
      setSelectedCustomerMap(map);
      setData({ customers, ...active });
    }
  }, [open, active._id]);

  const handleChange = (key, value) => {
    data[key] = value;
    setData({ ...data });
  };
  function handleSearch(searchPhrase) {
    if (!searchPhrase) return customers;
    return customers.filter((customer) => {
      return (
        customer.name.toLowerCase().includes(searchPhrase.toLowerCase()) ||
        customer.phoneNo.includes(searchPhrase.toLowerCase())
      );
    });
  }
  const disabledCustomerSelect = useMemo(() => {
    return !data._id;
  }, [data._id]);

  const handleSubmit = (toSave) => {
    onSave({ ...data, ...toSave });
  };

  const tabs = [
    {
      title: i18n.t("details"),
      content: () => (
        <div className="d-flex">
          <ValidatorForm
            onSubmit={() => handleSubmit({ name: data.name })}
            ref={form}
            className="w-100 row mx-0"
            onError={(error) => {
              if (!error.length) {
                onSave(data);
              }
            }}
          >
            <div className="col-12 px-0 d-flex align-items-center mb-3">
              <label className="font-weight-bold mr-3">{i18n.t("name")}</label>
              <div className="w-100">
                <TextValidator
                  label={i18n.t("name")}
                  onChange={(e) => handleChange("name", e.target.value)}
                  value={data.name || ""}
                  validators={["required"]}
                  errorMessages={[i18n.t("name_is_required")]}
                  variant="outlined"
                />
              </div>
            </div>
            <div className="row mx-0 px-0 justify-content-end my-3 col-12">
              <PrimaryButton
                className="w-128"
                props={{
                  type: "submit",
                }}
                label={data._id ? i18n.t("save") : i18n.t("create")}
              />
            </div>
          </ValidatorForm>
        </div>
      ),
    },
    {
      title: i18n.t("container.customers"),
      disabled: disabledCustomerSelect,
      content: () => (
        <>
          <CustomersHeader>
            <label className="font-weight-bold mr-3">{i18n.t("search")}</label>
            <div style={{minWidth: "calc(100% - 180px)"}}>
              <TextField
                placeholder={i18n.t("search")}
                onChange={(e) => setSearchPhrase(e.target.value)}
                variant="outlined"
              />
            </div>
            <PrimaryButton
              className="w-98"
              label={i18n.t("search")}
              onClick={() => {
                handleSubmit({ customers: Object.keys(selectedCustomerMap) });
              }}
            />
          </CustomersHeader>
          <List className="col-12 px-0">
            {filteredCustomers.map((customer) => {
              return (
                <div>
                  <ListItem
                    key={customer._id}
                    dense
                    onClick={() => {
                      setSelectedCustomerMap({
                        ...selectedCustomerMap,
                        [customer._id]: !selectedCustomerMap[customer._id],
                      });
                    }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={selectedCustomerMap[customer._id] || false}
                        tabIndex={-1}
                      />
                    </ListItemIcon>
                    <ListItemText id={customer._id} primary={customer.name} style={{maxWidth: "30%"}} />
                    <ListItemText primary={customer.phoneNo} />
                  </ListItem>
                </div>
              );
            })}
          </List>
        </>
      ),
    },
  ];

  return (
    <div>
      {open ? (
        <BaseModal
          open={open}
          title={
            data._id
              ? i18n.t("edit_customer_group")
              : i18n.t("add_customer_group")
          }
          containerClassName="half-container custom-tab"
          content={<Tabs tabs={tabs} />}
          onClose={onClose}
        />
      ) : null}
    </div>
  );
}
