import { connect } from "react-redux"
import React, { Component } from "react"
import i18n from "../../../i18n"
import { DotsLoader } from "../../../components"
import Avatar from "react-avatar"
import { getWallet } from "../../../config/simpleApiCalls"
import { Images } from "../../../theme"

class Wallet extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isloading: true,
      walletInfo: [],
    }
  }

  async componentDidMount() {
    this.setState({ isloading: true })

    getWallet()
      .then((res) => {
        this.setState({ walletInfo: res.data.data, isloading: false })
      })
      .catch((error) => console.log("errrr", error))
  }

  renderLoader = () => {
    const { isloading } = this.state
    return <DotsLoader isloading={isloading} />
  }

  TemplateOption = () => {
    return (
      <ul
        style={{ marginLeft: "220px" }}
        class="list-group list-group-horizontal"
      >
        <li class="list-group-item li-padding li-radius-left">Day</li>
        <li class="list-group-item li-padding">Month</li>
        <li class="list-group-item li-padding li-radius-right">Year</li>
      </ul>
    )
  }

  renderSearchBar = () => {
    const { walletInfo } = this.state
    // const { walletInfo } = reciver;

    return (
      <>
        <h1 className="saloon-dashboard-heading mb-4">
          {i18n.t("container.profile_my_wallet")}
        </h1>
        <div
          className="d-flex align-items-center py-2 px-4"
          style={{ width: 226, backgroundColor: "#F4F6F8", borderRadius: 7 }}
        >
          <img src={Images.wallet_svg_dark} />
          <div className="ml-3">
            <h3 className="font-weight-bolder mb-0">
              {walletInfo?.totalAmount} Pts
            </h3>
            <small>1$ = 1000 Points</small>
          </div>
        </div>
      </>
    )
  }

  renderTableBody = (v) => {
    let { walletInfo } = this.state

    let name = v.reciver.userName
      ? v.reciver.userName
      : v.reciver.firstName + " " + v.reciver?.lastName

    return (
      <tbody>
        <tr className="border-top">
          <td>
            <div className="d-flex align-items-center">
              <Avatar
                name={name}
                size="30"
                color="#4862FF"
                round={true}
                textSizeRatio={2.0}
              />
              <span
                style={{ marginLeft: "10px" }}
                className="appointment-details-employee-name"
              >
                {name}
              </span>
            </div>
          </td>
          <td className=" Table_data">
            <div className="d-flex align-items-center mt-1">
              <span className="appointment-details-employee-name">
                {v.type}
              </span>
            </div>
          </td>
          <td className=" Table_data">
            <div className="d-flex align-items-center mt-1">
              <span className="appointment-details-employee-name">
                {v.paidAmount}
              </span>
            </div>
          </td>
          <td className=" Table_data">
            <div className="d-flex align-items-center mt-1">
              <span className="appointment-details-employee-name">
                {walletInfo.totalAmount}
              </span>
            </div>
          </td>
        </tr>
      </tbody>
    )
  }

  renderUserTable = () => {
    let { walletInfo } = this.state

    return (
      <div className="col-12 p-4 ">
        <div className="table-responsive">
          <table className="table table-borderless bg-white mobile-max-content">
            <thead>
              <tr>
                <th scope="col">
                  <span className="appointment-details-th-text">
                    {i18n.t("container.dashboard_customer_name")}
                  </span>
                </th>
                <th scope="col">
                  <span className="appointment-details-th-text">Type</span>
                </th>
                <th scope="col">
                  <span className="appointment-details-th-text">
                    {i18n.t("container.profile_add_points")}
                  </span>
                </th>
                <th scope="col">
                  <span className="appointment-details-th-text">
                    {i18n.t("container.profile_points_remaining")}
                  </span>
                </th>
              </tr>
            </thead>
            {walletInfo?.transactions &&
              walletInfo.transactions.map((v, i) => this.renderTableBody(v))}
          </table>
        </div>
      </div>
    )
  }

  render() {
    const { isloading } = this.state

    return (
      <div>
        {isloading ? (
          this.renderLoader()
        ) : (
          <div className="content-container">
            <div className="p-4">
              {this.renderSearchBar()}
              {this.renderUserTable()}
            </div>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

const action = {}

export default connect(mapStateToProps, action)(Wallet)
