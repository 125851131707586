import React, { useEffect, useState } from "react"
import i18n from "../../../i18n"
import BasicTable from "../../Core/BasicTable"
import moment from "moment"
import CustomerCollectPayment from "./CustomerCollectPayment"
import PrimaryButton from "../../Core/PrimaryButton"
import { ErrorHelper } from "../../../helpers"
import { getCustomerById } from "../../../config/simpleApiCalls"
export default function CustomerAccountTransactions({
  active = {},
  accountBalance,
  setAccountBalance,
  setLoading,
  giftCardBalance,
}) {
  const [data, setData] = useState({})
  const [collectPaymentOpen, setCollectPaymentOpen] = useState(false)
  const init = (data) => {
    setData({ ...data })
  }
  useEffect(() => {
    setLoading(true)
    getCustomerById({ _id: active._id })
      .then(({ data }) => {
        const clone = Object.assign({}, data.data)
        clone.transactions?.reverse()
        init(clone)
        setLoading(false)
      })
      .catch(() => {
        ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true)
      })
  }, [active])
  return (
    <>
      <div className="row mx-0 justify-content-end mb-3">
        <PrimaryButton
          className="w-128"
          label={i18n.t("collect")}
          onClick={() => setCollectPaymentOpen(true)}
        />
      </div>
      <BasicTable
        rows={data.transactions || []}
        schema={[
          {
            header: i18n.t("amount"),
            field: "amount",
            accessor: (val) => {
              return `$ ${val.amount.toFixed(2)}`
            },
          },
          {
            header: i18n.t("type"),
            field: "type",
            accessor: (val) => {
              return i18n.t(val.type)
            },
          },
          {
            header: i18n.t("charge_from"),
            field: "charge_from",
            accessor: (val) => {
              return val.sourceCustomerId
                ? `${val.sourceCustomerId?.firstName || ""} ${
                    val.sourceCustomerId?.name
                  }`.trim()
                : `${data.firstName || ""}  ${data.name}`.trim()
            },
          },
          {
            header: i18n.t("created_by"),
            field: "created_by",
            accessor: (val) => {
              return `${val.createdBy?.firstName || ""} ${
                val.createdBy?.lastName || ""
              }`.trim()
            },
          },
          {
            header: i18n.t("created_date"),
            field: "created_date",
            accessor: (val) => {
              return moment(val.createdDate).format("MM-DD-YYYY HH:mm")
            },
          },
        ]}
      />
      {collectPaymentOpen ? (
        <CustomerCollectPayment
          setLoading={setLoading}
          open={collectPaymentOpen}
          handleClose={() => {
            setCollectPaymentOpen(false)
          }}
          onSuccess={(result) => {
            setCollectPaymentOpen(false)
            setAccountBalance(result.companyCustomer?.accountBalance)
          }}
          userId={data.userId}
          customerId={data?._id}
          accountBalance={accountBalance}
          giftCardBalance={giftCardBalance}
        />
      ) : null}
    </>
  )
}
