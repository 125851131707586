import { connect } from "react-redux";
import React, { Component } from "react";
import { Translation } from "react-i18next";

import { DotsLoader } from "../../components";
import { ErrorHelper } from "../../helpers";
import "./styles.css";
import { getLegalInfo } from "../../config/simpleApiCalls";

class LegalInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getTermData: [],
      textArea: "",
      isloading: true,
    };
  }

  onChangeTextArea = (text) => this.setState({ textArea: text.target.value });

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  onSetSidebarOpen = (open) => {
    this.setState({ sidebarOpen: open });
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  componentDidMount() {
    const superAdminData = JSON.parse(sessionStorage.getItem("superAdmin"));
    this.setState({ superAdminData }, () => {
      this.getTermAndContition();
    });
  }
  getTermAndContition = (el) => {
    const { superAdminData } = this.state;

    getLegalInfo()
      .then((res) => {
        if (res.data.success) {
          this.setState({
            isloading: false,
            getTermData: res.data.data,
          });
        } else {
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        ErrorHelper.handleErrors("Something Went Wrong", true);

        this.setState({ isloading: false });
        console.log(error);
      });
  };

  renderDetail = () => {
    const { getTermData } = this.state;

    return (
      <div className="m-3">
        <div className="pb-3" style={{ color: "#A1A1A1", overflow: "hidden" }}>
          {getTermData?.title}
          <p>{getTermData?.description}</p>

          <br />
        </div>
      </div>
    );
  };

  render() {
    const { columns, data } = this.state;
    return (
      <div>
        {this.renderLoader()}
        <div
          className="container-fluid min-vh-100 px-0"
          style={{ backgroundColor: "#F7F7F7" }}
        >
          <div className="container py-5 mb-4 mb-md-5">
            <div className="row">
              <h2>
                <Translation>{(t) => t("legal_infos")}</Translation>
              </h2>
              <div className="col-12 mt-4 bg-white shadow rounded px-3">
                {this.renderDetail()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(LegalInfo);
