import React, { useState } from "react"
import { useRef } from "react"
import { useEffect } from "react"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import { BaseModal } from "../../../../components"
import PrimaryButton from "../../../../components/Core/PrimaryButton"
import { upsertIntakeCategoryItem } from "../../../../config/simpleApiCalls"
import { ErrorHelper, SuccessHelper } from "../../../../helpers"
import i18n from "../../../../i18n"
import useScreenSize from "../../../../context/ScreenSize"
export default function IntakeCategoryItemUpsertPopup({
  open,
  setOpen,
  active = {},
  intakeCategoryId,
}) {
  const [data, setData] = useState({})
  const form = useRef()
  const { screenSize } = useScreenSize()
  useEffect(() => {
    setData({ ...active })
  }, [open])

  const successMessage = data._id
    ? "successfully_updated"
    : "successfully_added"
  const failureMessage = data._id ? "failed_to_update" : "failed_to_add"
  const onSubmit = () => {
    upsertIntakeCategoryItem({ ...data, intakeCategoryId })
      .then(({ data: res }) => {
        setData(res)
        SuccessHelper.handleSuccess(i18n.t(successMessage), true)
        setOpen(false)
      })
      .catch(() => {
        ErrorHelper.handleErrors(i18n.t(failureMessage), true)
      })
  }
  const title = data._id ? "update_item" : "create_item"
  return (
    <BaseModal
      open={open}
      showCloseBtn={screenSize !== "mobile" ? true : false}
      onClose={() => setOpen(false)}
      title={i18n.t(title)}
      containerClassName={"half-container"}
      content={
        <ValidatorForm onSubmit={onSubmit} ref={form} className="w-100">
          <div className="validate-flex col-12 px-0 d-flex align-items-center justify-content-between mb-3">
            <label className="font-weight-bold mr-3">{i18n.t("name")}</label>
            <div
              className="validate-input"
              style={{ width: "calc(100% - 220px)" }}
            >
              <TextValidator
                label={i18n.t("name")}
                onChange={(e) => setData({ ...data, name: e.target.value })}
                value={data.name || ""}
                validators={["required"]}
                errorMessages={[i18n.t("this_field_is_required")]}
                variant="outlined"
              />
            </div>
            <div
              className={
                screenSize !== "mobile"
                  ? ""
                  : "d-flex gap-2 justify-content-center w-100"
              }
            >
              {screenSize === "mobile" && (
                <PrimaryButton
                  className="w-128 ml-3"
                  onClick={() => setOpen(false)}
                  label={i18n.t("cancel")}
                />
              )}

              <PrimaryButton
                className="w-128 ml-3"
                props={{
                  type: "submit",
                }}
                label={i18n.t("save")}
              />
            </div>
          </div>
        </ValidatorForm>
      }
    />
  )
}
