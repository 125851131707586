import { Button } from "@material-ui/core"
import React from "react"
import { Translation } from "react-i18next"
import i18n from "../../../../i18n"
import { Images } from "../../../../theme"
import styled from "styled-components"

const WrapperComponent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`

function Chip({ val, renderChip, selected, selectedMap }) {
  let chipContent = val?.name ? (
    val.name
  ) : (
    <div>
      <Translation>{(t) => "container.no_category"}</Translation>
    </div>
  )
  if (renderChip) {
    chipContent = renderChip(val)
  }

  return (
    <div className="py-0">
      {chipContent}
      {(
        val._id
          ? selected?._id == val._id || selectedMap[val._id]
          : selected?.name == val.name || selectedMap[val]
      ) ? (
        <img
          src={Images.step_done_tick_orange}
          style={{
            height: 20,
            width: 20,
            zIndex: 3,
            position: "absolute",
            right: "-2px",
            top: "-2px",
            backgroundColor: "white",
            borderRadius: "1000px",
          }}
        // styles={ImagePosition}
        />
      ) : null}
    </div>
  )
}

export function TouchList({
  options,
  onChange,
  selected,
  noOptionsMessage,
  chipClassName = "px-1",
  style = {},
  selectedMap = {},
  renderChip,
  disableRipple
}) {

  return (
    <>
      {options.length ? (
        <WrapperComponent className="row mx-0" style={style}>
          {options.map((val) => {
            return (
              <div className={chipClassName}>
                <Button
                  disableRipple={disableRipple}
                  onClick={(e) => {
                    onChange(val, e)
                  }}
                  style={{
                    backgroundColor: "var(--warning-color)",
                    color: "white",
                    width: "100%",
                  }}
                  className="full-width"
                >
                  <Chip
                    selected={selected}
                    selectedMap={selectedMap}
                    renderChip={renderChip}
                    val={val}
                  />
                </Button>
              </div>
            )
          })}
        </WrapperComponent>
      ) : (
        <div>{i18n.t(noOptionsMessage)}</div>
      )}
    </>
  )
}

export default TouchList
