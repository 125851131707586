import React, { Component } from "react"
import { Translation } from "react-i18next"
import i18n from "../../../i18n"
import { Images } from "../../../theme"
import {
  gitAllSaloonAppoinments,
  getSalesReport,
} from "../../../config/simpleApiCalls"
import { DotsLoader } from "../../../components"
// import { adminGetUsers } from '../../../config/simpleApiCalls';

import { Doughnut } from "react-chartjs-2"
import { Line } from "react-chartjs-2"
import { Bar } from "react-chartjs-2"

import "./styles.scss"
import { getNestedValue } from "../../../util/objectMethods"
import Button from "@material-ui/core/Button"
import moment from "moment"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import PrimaryButton from "../../../components/Core/PrimaryButton/index.jsx"
import SharedStorage from "../../../helpers/Storage"
import useCache from "../../../context/LocalCache"
import useScreenSize from "../../../context/ScreenSize"

class _Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sidebarOpen: false,
      usersList: [],
      blockUsersList: [],
      unblockUsersList: [],
      totalCategories: [],
      totalAppointment: "",
    }
  }

  componentDidMount = async () => {
    const saloonData = await JSON.parse(await SharedStorage.getItem("saloon"))
    const isGym =
      JSON.parse(await SharedStorage.getItem("company"))?.function == "gym"
    this.setState({ isGym })
    if (saloonData) {
      this.setSelectedValue(1)
    }
  }
  /**
   * Takes a string phrase and breaks it into separate phrases
   * no bigger than 'maxwidth', breaks are made at complete words.
   */
  formatLabel = (str, maxwidth) => {
    var sections = []
    var words = str.split(" ")
    var temp = ""

    words.forEach(function (item, index) {
      if (temp.length > 0) {
        var concat = temp + " " + item

        if (concat.length > maxwidth) {
          sections.push(temp)
          temp = ""
        } else {
          if (index == words.length - 1) {
            sections.push(concat)
            return
          } else {
            temp = concat
            return
          }
        }
      }

      if (index == words.length - 1) {
        if (item.length > maxwidth) {
          sections.push(item.slice(0, item.length / 2) + "-")
          sections.push(item.slice(item.length / 2, item.length))
        } else {
          sections.push(item)
        }
        return
      }

      if (item.length < maxwidth) {
        temp = item
      } else {
        sections.push(item.slice(0, item.length / 2) + "-")
        sections.push(item.slice(item.length / 2, item.length))
      }
    })

    for (var i = 1; i < sections.length - 1; i++) {
      while (sections[i].length <= i && typeof sections[i + 1] != "undefined") {
        sections[i] = sections[i] + " " + sections[i + 1]
        sections.splice(i + 1, 1)
      }
    }

    return sections
  }

  convertToBarChartData = (map, label) => {
    const sortedData = Object.values(map).sort((a, b) =>
      a.amount < b.amount ? 1 : -1
    )
    const data = {
      label,
      labels: [],
    }
    const colors = [
      "#6B6CD92E",
      "#F7C0012E",
      "#67DFBB2E",
      "#F33AAF2E",
      "#F6943B2E",
    ]
    const borderColor = ["#6B6CD9", "#F7C001", "#67DFBB", "#F33AAF", "#F6943B"]
    const dataset = {
      data: [],
      backgroundColor: [],
      borderColor: [],
      borderWidth: 1,
    }
    sortedData.splice(0, 5).map((element, index) => {
      data.labels.push(
        this.formatLabel(
          element.name,
          window.matchMedia("(min-width: 1000px)").matches ? 15 : 10
        )
      )
      dataset.data.push(element.amount)
      dataset.backgroundColor.push(colors[index])
      dataset.borderColor.push(borderColor[index])
    })

    data.datasets = [dataset]
    return data
  }

  getAllAppointment() {
    const convertedStart = moment(this.state.startDate).format("MM-DD-YYYY")
    const convertedEnd = moment(this.state.endDate).format("MM-DD-YYYY")
    let data = {
      start: convertedStart,
      end: convertedEnd,
      relations: ["cart"],
    }
    return new Promise((resolve, reject) => {
      gitAllSaloonAppoinments(data)
        .then((res) => {
          const employeeMap = {}
          const productAmountMap = {}
          const serviceBookingAmountMap = {}
          if (res.data.data.length) {
            res.data.data.map((booking) => {
              const employee = getNestedValue(
                booking,
                ["services", 0, "employeeId"],
                {}
              )
              const service = getNestedValue(
                booking,
                ["services", 0, "serviceId"],
                {}
              )
              if (service?._id) {
                if (!serviceBookingAmountMap[service._id]) {
                  serviceBookingAmountMap[service._id] = {
                    name: service.name,
                    amount: 0,
                  }
                }
                serviceBookingAmountMap[service._id].amount++
              }
              if (booking.cartId) {
                getNestedValue(booking.cartId, ["products"], []).map(
                  (product) => {
                    if (!productAmountMap[product.productId._id]) {
                      productAmountMap[product.productId._id] = {
                        name: product.productId.name,
                        amount: 0,
                      }
                    }
                    productAmountMap[product.productId._id].amount++
                  }
                )
              }
              if (employee?._id) {
                if (!employeeMap[employee._id]) {
                  employeeMap[employee._id] = {
                    numberOfBookings: 0,
                    label: getNestedValue(
                      employee,
                      ["userId", "firstName"],
                      i18n.t("name")
                    ),
                  }
                }
                employeeMap[employee._id].numberOfBookings++
              }
            })
          }
          const topProductData = this.convertToBarChartData(
            productAmountMap,
            i18n.t("top_products")
          )
          const topServiceData = this.convertToBarChartData(
            serviceBookingAmountMap,
            i18n.t("top_services")
          )
          this.setState({
            totalAppointment: res.data.data.length,
            topProductData,
            topServiceData,
          })
          resolve(employeeMap)
        })
        .catch((error) => {
          this.setState({ isLoading: false })
          console.log("err", error)
          if (error.response) {
            alert("Error", error.response.data.msg)

            // Request made and server responded
          } else if (error.request) {
            alert("Error", "Something Went Wrong ")

            // The request was made but no response was received
          } else {
            alert("Error", error.msg)
            // Something happened in setting up the request that triggered an Error
          }
          reject()
        })
    })
  }

  handleNavigation = (route) => {
    this.props.history.push(route)
  }

  renderLoader = () => {
    const { isloading } = this.state
    return <DotsLoader isloading={isloading} />
  }

  _renderEmployeeCard = () => {
    const { employees } = this.props

    return (
      <>
        <div className="pr-3">
          <div
            className="py-3 h-100"
            style={{
              backgroundColor: "var(--lightblue2-color)",
              borderRadius: 7,
            }}
          >
            <div className="d-flex flex-wrap align-items-center justify-content-center px-3 px-md-4">
              <div className="d-flex align-items-center gap-2">
                <div
                  className="text-center p-2 rounded"
                  style={{ backgroundColor: "#E5EBF1" }}
                >
                  <img src={Images.dashboard_employees} />
                </div>
                <span className="dashboard-card-number mobile-only">
                  {employees?.length ? employees.length : "0"}
                </span>
              </div>
              <div className="ml-0 ml-sm-3">
                <div>
                  <span className="dashboard-card-number desktop-only">
                    {employees?.length ? employees.length : "0"}
                  </span>
                </div>
                <span className="dashboard-card-text mt-1">
                  {i18n.t("container.add_employe_employees")}
                </span>
              </div>
              <div>
                <div className="ml-0 ml-sm-3 text-center ">
                  <Button
                    onClick={() =>
                      this.props.history.push("saloon-company-employee")
                    }
                    style={{
                      backgroundColor: "var(--warning-color)",
                      color: "var(--white-color)",
                      textTransform: "capitalize",
                    }}
                    className="my-1"
                  >
                    {i18n.t("container.dashboard_view_all")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
  baseCard = (content, title) => (
    <div className="my-4">
      <span className="dashboard-card-title font-weight-bold">
        <Translation>{(t) => t(title)}</Translation>
      </span>
      {content}
    </div>
  )
  _renderCategoriesCard = () => {
    const { services: totalCategories } = this.props

    return (
      <>
        <div className="pr-3">
          <div
            className="py-3 px-3 px-md-4 h-100"
            style={{
              backgroundColor: "var(--lightblue2-color)",
              borderRadius: 7,
            }}
          >
            <div className="row align-items-center justify-content-center dashboard-card-service">
              <div className="d-flex align-items-center gap-2">
                <div
                  className="text-center px-2 py-1 rounded"
                  style={{ backgroundColor: "#E5EBF1" }}
                >
                  <img className="my-1" src={Images.dashboard_services} />
                </div>
                <span className="dashboard-card-number mobile-only">
                  {totalCategories?.length ? totalCategories.length : "0"}
                </span>
              </div>
              <div className="ml-0 ml-sm-3">
                <div className="text-left">
                  <span className="dashboard-card-number desktop-only">
                    {totalCategories?.length ? totalCategories.length : "0"}
                  </span>
                </div>
                <span className="dashboard-card-text mt-1">
                  <Translation>{(t) => t("container.services")}</Translation>
                </span>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
  _renderSupportCard = () => {
    const { totalAppointment } = this.state

    return (
      <>
        <div>
          <div
            className="py-3"
            style={{
              backgroundColor: "var(--lightblue2-color)",
              borderRadius: 7,
            }}
          >
            <div className="d-flex flex-wrap align-items-center justify-content-center px-3 px-md-4">
              <div className="d-flex align-items-center gap-2">
                <div
                  className="text-center p-2 rounded"
                  style={{ backgroundColor: "#E5EBF1" }}
                >
                  <img className="my-1" src={Images.dashboard_appointments} />
                </div>
                <span className="dashboard-card-number mobile-only">
                  {totalAppointment}
                </span>
              </div>
              <div className="ml-0 ml-sm-3">
                <div className="text-left">
                  <span className="dashboard-card-number desktop-only">
                    {totalAppointment}
                  </span>
                </div>
                <span className="dashboard-card-text mt-1">
                  <Translation>
                    {(t) => t("container.appointments")}
                  </Translation>
                </span>
              </div>
              <div className="ml-0 ml-sm-3 text-center">
                <Button
                  onClick={() =>
                    this.props.history.push(
                      "saloon-my-appointment?displayTable=true"
                    )
                  }
                  style={{
                    backgroundColor: "var(--warning-color)",
                    color: "var(--white-color)",
                    textTransform: "capitalize",
                  }}
                  className="my-1"
                >
                  {i18n.t("container.dashboard_view_all")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
  _renderCard = () => {
    const { isGym } = this.state
    return (
      <>
        <div className="dashboard-card-container col-12 row mx-0 my-4">
          {!isGym && this._renderEmployeeCard()}
          {this._renderCategoriesCard()}
          {this._renderSupportCard()}
        </div>
      </>
    )
  }

  setSelectedValue = (val, previous) => {
    let currentDate = moment(Date.now())
    this.setState({ isloading: true })
    const callback = () => {
      Promise.all([this.getAllAppointment(), this.getSalesReport()]).then(
        (res) => {
          const data = {
            labels: [],
          }
          const employeeMap = res[0]
          const dataset = {
            data: [],
            backgroundColor: [],
            hoverBackgroundColor: [],
          }
          const { employees } = this.props
          Object.keys(employeeMap).map((employeeId) => {
            if (employeeId) {
              data.labels.push(employeeMap[employeeId].label)
              dataset.data.push(employeeMap[employeeId].numberOfBookings)
              const companyEmployee = employees.find(
                (element) =>
                  element.employeeId?._id == employeeId ||
                  element.archivedEmployeeId?._id == employeeId
              )
              if (companyEmployee) {
                dataset.backgroundColor.push(companyEmployee.color)
                dataset.hoverBackgroundColor.push(companyEmployee.color)
              }
            }
          })
          data.datasets = [dataset]
          this.setState({
            bookingByEmployeeData: data,
            isloading: false,
            salesReportData: res[1],
          })
        }
      )
    }
    if (val == 1) {
      if (previous) {
        currentDate.subtract(1, "M")
      }
      const startOfMonth = moment(currentDate).startOf("month")
      const endOfMonth = moment(currentDate).endOf("month")
      this.setState(
        { startDate: startOfMonth.toDate(), endDate: endOfMonth.toDate() },
        callback
      )
    } else if (val == 2) {
      if (previous) {
        currentDate.subtract(1, "quarter")
      }
      const startOfQuarter = moment(currentDate).startOf("quarter")
      const endOfQuarter = moment(currentDate).endOf("quarter")
      this.setState(
        { startDate: startOfQuarter.toDate(), endDate: endOfQuarter.toDate() },
        callback
      )
    } else if (val == 3) {
      if (previous) {
        currentDate.subtract(1, "y")
      }
      const convertedStart = moment(`01-01-${currentDate.year()}`, "MM-DD-YYYY")
      const convertedEnd = moment(`12-31-${currentDate.year()}`, "MM-DD-YYYY")
      this.setState(
        { startDate: convertedStart.toDate(), endDate: convertedEnd.toDate() },
        callback
      )
    }
  }

  getSalesReport = () => {
    this.setState({ isLoading: true }, () => {})
    const convertedStart = moment(this.state.startDate).format("MM-DD-YYYY")
    const convertedEnd = moment(this.state.endDate).format("MM-DD-YYYY")
    let data = {
      start: convertedStart,
      end: convertedEnd,
    }

    return new Promise((resolve, reject) => {
      getSalesReport(data)
        .then((res) => {
          const start = moment(this.state.startDate)
          const today = moment(moment.now()).format("MM-DD-YYYY")
          if (res.data.success) {
            this.setState({ isLoading: false })
          }
          const transactionDateMap = {}
          const baseAmounts = {
            extrasTotal: 0,
            servicesTotal: 0,
            productsTotal: 0,
            subtotal: 0,
            tip: 0,
          }
          getNestedValue(res.data, ["data", "transactions"], []).map(
            (transaction) => {
              const formattedDate = moment(transaction.createdDate).format(
                "MM-DD-YYYY"
              )
              if (!transactionDateMap[formattedDate]) {
                transactionDateMap[formattedDate] = { ...baseAmounts }
              }
              transactionDateMap[formattedDate].extrasTotal += parseFloat(
                transaction.extrasTotal
              )
              transactionDateMap[formattedDate].servicesTotal += parseFloat(
                transaction.servicesTotal
              )
              transactionDateMap[formattedDate].productsTotal += parseFloat(
                transaction.productsTotal
              )
              transactionDateMap[formattedDate].subtotal += parseFloat(
                transaction.subtotal
              )
              transactionDateMap[formattedDate].tip += parseFloat(
                transaction.tip
              )
            }
          )
          const labels = []
          do {
            labels.push(start.format("MM-DD-YYYY"))
            start.add(1, "days")
          } while (
            start.format("MM-DD-YYYY") != convertedEnd &&
            start.format("MM-DD-YYYY") != today
          )
          const salesReportData = {
            labels,
            datasets: [
              {
                label: i18n.t("extras"),
                data: labels.map((date) =>
                  getNestedValue(transactionDateMap, [date, "extrasTotal"], 0)
                ),
                backgroundColor: "#6B6CD92E",
                borderColor: "#6B6CD9",
                borderWidth: 1,
                fill: true,
                pointBorderColor: "rgba(160, 39, 164,1)",
                pointHoverBackgroundColor: "rgba(160, 39, 164,1)",
                pointHoverBorderColor: "rgba(160, 39, 164,1)",
              },
              {
                label: i18n.t("services"),
                data: labels.map((date) =>
                  getNestedValue(transactionDateMap, [date, "servicesTotal"], 0)
                ),
                backgroundColor: "#F7C0012E",
                borderColor: "#F7C001",
                borderWidth: 1,
                fill: true,
                pointBorderColor: "rgba(75,0,192,1)",
                pointHoverBackgroundColor: "rgba(75,0,192,1)",
                pointHoverBorderColor: "rgba(75,0,192,1)",
              },
              {
                label: i18n.t("products"),
                data: labels.map((date) =>
                  getNestedValue(transactionDateMap, [date, "productsTotal"], 0)
                ),
                backgroundColor: "#67DFBB2E",
                borderColor: "#67DFBB",
                borderWidth: 1,
                fill: true,
                pointBorderColor: "rgba(75,192,0,1)",
                pointHoverBackgroundColor: "rgba(75,192,0,1)",
                pointHoverBorderColor: "rgba(75,192,0,1)",
              },
              {
                label: i18n.t("subtotal"),
                data: labels.map((date) =>
                  getNestedValue(transactionDateMap, [date, "subtotal"], 0)
                ),
                backgroundColor: "#F33AAF2E",
                borderColor: "#F33AAF",
                borderWidth: 1,
                fill: true,
                pointBorderColor: "rgba(0,192,192,1)",
                pointHoverBackgroundColor: "rgba(0,192,192,1)",
                pointHoverBorderColor: "rgba(0,192,192,1)",
              },
              {
                label: i18n.t("tip"),
                data: labels.map((date) =>
                  getNestedValue(transactionDateMap, [date, "tip"], 0)
                ),
                backgroundColor: "#F6943B2E",
                borderColor: "#F6943B",
                borderWidth: 1,
                fill: true,
                pointBorderColor: "rgba(255, 73, 91,1)",
                pointHoverBackgroundColor: "rgba(255, 73, 91,1)",
                pointHoverBorderColor: "rgba(255, 73, 91,1)",
              },
            ],
          }
          resolve(salesReportData)
        })
        .catch((err) => {
          this.setState({ isLoading: false })
          reject()
        })
    })
  }
  z

  openMenu = (event, menuItems) => {
    this.setState({ anchor: event.currentTarget, menuItems })
  }

  render() {
    const {
      usersList,
      blockUsersList,
      unblockUsersList,
      bookingByEmployeeData = {},
      salesReportData = {},
      topProductData = {},
      topServiceData = {},
      anchor,
      menuItems = [],
    } = this.state
    return (
      <div>
        {this.renderLoader()}
        <div>
          <div className="row mx-0">
            <div className="dashboard-heading col-12 pt-3 row mx-o justify-content-between">
              <h1 className="Dashboard_heading">
                {i18n.t("container.dashboard_for_salon")}
              </h1>
              <div className="row mx-0">
                <Menu
                  id="simple-menu"
                  anchorEl={anchor}
                  keepMounted
                  open={Boolean(anchor)}
                  onClose={() =>
                    this.setState({ anchor: undefined, menuItems: [] })
                  }
                >
                  {menuItems.map((item) => (
                    <MenuItem
                      onClick={() => {
                        this.setState({ anchor: undefined, menuItems: [] })
                        this.setSelectedValue(...item.params)
                      }}
                    >
                      {item.label}
                    </MenuItem>
                  ))}
                </Menu>
                <Button
                  onClick={(ev) =>
                    this.openMenu(ev, [
                      {
                        label: i18n.t("container.previous"),
                        params: [1, true],
                      },
                      {
                        label: i18n.t("container.current"),
                        params: [1, false],
                      },
                    ])
                  }
                  style={{
                    backgroundColor: "var(--lightblue1-color)",
                    color: "var(--black-color)",
                    border: "solid 1px #E5EBF1",
                    borderRadius: 7,
                    textTransform: "capitalize",
                  }}
                  className="mr-1 mt-1"
                >
                  {i18n.t("container.dashboard_month")}
                </Button>
                <Button
                  onClick={(ev) =>
                    this.openMenu(ev, [
                      {
                        label: i18n.t("container.previous"),
                        params: [2, true],
                      },
                      {
                        label: i18n.t("container.current"),
                        params: [2, false],
                      },
                    ])
                  }
                  style={{
                    backgroundColor: "var(--lightblue1-color)",
                    color: "var(--black-color)",
                    border: "solid 1px #E5EBF1",
                    borderRadius: 7,
                    textTransform: "capitalize",
                  }}
                  className="mr-1 mt-1"
                >
                  {i18n.t("container.dashboard_quarter")}
                </Button>
                <Button
                  onClick={(ev) =>
                    this.openMenu(ev, [
                      {
                        label: i18n.t("container.previous"),
                        params: [3, true],
                      },
                      {
                        label: i18n.t("container.current"),
                        params: [3, false],
                      },
                    ])
                  }
                  style={{
                    backgroundColor: "var(--lightblue1-color)",
                    color: "var(--black-color)",
                    border: "solid 1px #E5EBF1",
                    borderRadius: 7,
                    textTransform: "capitalize",
                  }}
                  className="mt-1"
                >
                  {i18n.t("container.dashboard_year")}
                </Button>
              </div>
            </div>
            {this._renderCard()}
            <div className="col-md-6 col-xs-12 pt-3">
              {this.baseCard(
                <Doughnut
                  data={bookingByEmployeeData}
                  height={this.props.screenSize === "mobile" && 300}
                />,
                "booking_by_employee"
              )}
            </div>
            <div className="col-md-6 col-xs-12 pt-3">
              {this.baseCard(
                <Line
                  height={this.props.screenSize === "mobile" && 300}
                  options={{
                    title: {
                      display: true,
                      text: `${moment(this.state.startDate).format(
                        "DD/MM/YYYY"
                      )} - ${moment(this.state.endDate).format("DD/MM/YYYY")}`,
                      position: "bottom",
                    },
                    scales: {
                      xAxes: [
                        {
                          type: "time",
                          time: {
                            displayFormats: {
                              day: "DD",
                              month: "MMM",
                              year: "YYYY",
                            },
                            tooltipFormat: "dddd, MMMM DD, YYYY",
                          },
                        },
                      ],
                    },
                  }}
                  data={salesReportData}
                  style={{ height: 700 }}
                />,
                "sales_revenue"
              )}
            </div>
            <div className="col-md-6 col-xs-12 pt-3">
              {this.baseCard(
                <Bar
                  data={topProductData}
                  height={this.props.screenSize === "mobile" && 300}
                  options={{
                    legend: {
                      display: false,
                    },
                  }}
                />,
                "top_products"
              )}
            </div>
            <div className="col-md-6 col-xs-12 pt-3">
              {this.baseCard(
                <Bar
                  height={this.props.screenSize === "mobile" && 300}
                  data={topServiceData}
                  options={{
                    legend: {
                      display: false,
                    },
                  }}
                />,
                "top_services"
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default function Dashboard(props) {
  const { getResource } = useCache()
  const services = getResource((cache) => cache.company.services)
  const employees = getResource((cache) => cache.company.employees)
  const { screenSize } = useScreenSize()
  return (
    <_Dashboard
      {...props}
      screenSize={screenSize}
      services={services}
      employees={employees}
    />
  )
}
