// @flow
import Immutable from "seamless-immutable"
import * as types from "../../actions/ActionTypes"

const initialState = Immutable({
  isFetching: false,
  errorMessage: "",
  data: {},
})

export default (state = initialState, action) => {
  switch (action.type) {
  case types.EASY1_PRODUCTS.GET_SAGA:
    return Immutable.merge(state, {
      isFetching: true,
    })
  case types.EASY1_PRODUCTS.SET:
    return Immutable.merge(state, {
      isFetching: false,
      data: action.data,
    })
  case types.EASY1_PRODUCTS.REQUEST_FAILURE:
    return Immutable.merge(state, {
      isFetching: false,
    })
  case types.LOGOUT:
    return initialState
  case types.CLEAR_LOGOUT:
    return initialState
  default:
    return state
  }
}
