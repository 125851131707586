import { TableCell, TableRow } from "@material-ui/core"
import React from "react"
import { useSelector } from "react-redux"
import BasicTable from "../../../../components/Core/BasicTable"
import i18n from "../../../../i18n"
export default function ActivityCategoryReportTables() {
  const categoryReport = useSelector(
    (s) => s.activityScheduleEvent.categoryReport
  )

  return (
    <div className="d-flex flex-column gap-1">
      <BasicTable
        maxContentWidth={false}
        prependRows={
          <TableRow>
            <TableCell>
              <div>{categoryReport.length} Results</div>
            </TableCell>
            <TableCell>
              <div>
                Total :{" "}
                {categoryReport.reduce(
                  (acc, element) => acc + element[1].length,
                  0
                )}
              </div>
            </TableCell>
          </TableRow>
        }
        rows={categoryReport}
        schema={[
          {
            header: i18n.t("name"),
            field: "name",
            accessor: (val) => val[0],
          },
          {
            header: i18n.t("customers"),
            field: "customers",
            accessor: (val) => val[1].length,
          },
        ]}
      />
    </div>
  )
}
