import { connect } from "react-redux";
import React, { Component } from "react";

import { request as login_user } from "../../redux/actions/Login";
import { DotsLoader, Navbar, Footer } from "../../components";
import { SuccessHelper, ErrorHelper } from "../../helpers";

import { resetPassword } from "../../config/simpleApiCalls";

import "./styles.css";

class UpdatePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      isloading: false,
    };
  }

  handleResetPassword = () => {
    // this.handleNavigation("/update-password", res.data.data);
    //
    const { password } = this.state;

    const payload = {
      userId: this.props.location.state.userObj._userId,
      password,
    };

    this.setState({ isloading: true });

    resetPassword(payload)
      .then((res) => {
        console.log("res: ", res);
        if (res.data.success) {
          SuccessHelper.handleSuccess(res.data.msg, true);
          this.handleNavigation("/login");
        }
      })
      .catch((err) => {
        ErrorHelper.handleErrors("Something Went Wrong", true);
      });

    this.setState({ isloading: false });
  };

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  onPasswordChanged = (text) => {
    this.setState({
      // formError: "Please enter a valid phone no",
      password: text.target.value,
    });
  };

  renderInput = () => {
    const { password } = this.state;
    return (
      <div className="form-label-group mb-4 ">
        <label for="inputemail">New Password</label>
        <input
          type="password"
          id="inputemail"
          className="form-control mt-1"
          placeholder="Enter New Password..."
          onChange={(text) => this.onPasswordChanged(text)}
          value={password}
        />
        {/* {formError ? (
          <small className="form-text text-danger ml-3">{formError}</small>
        ) : null} */}
      </div>
    );
  };

  renderLoginBtn = () => {
    return (
      <button
        className="login-btn btn btn-lg"
        type="submit"
        onClick={() => this.handleResetPassword()}
      >
        Reset
      </button>
    );
  };

  renderOtpForm = () => {
    return (
      <form action="javascript:void(0)" className="form-signin  px-4">
        {this.renderInput()}
        {this.renderLoginBtn()}
      </form>
    );
  };

  renderLoginCard = () => {
    return (
      <div className="card card-signin my-5">
        <div className="card-body px-0">
          <h5 className="card-title text-center">Enter New Password</h5>
          <div className="row">
            <hr className="my-4 mx-3 " style={{ width: "100%" }} />
          </div>
          {this.renderOtpForm()}
        </div>
      </div>
    );
  };

  render() {
    return (
      <>
        <div className="container-fluid">
          <div className="container">
            {this.renderLoader()}
            <Navbar {...this.props} isBackgroundColor={true} />
          </div>
          <div className="container" style={{ backgroundColor: "#F8FDFF" }}>
            <div className="row mt-5">
              <div className="col-sm-9 col-md-7 col-lg-5 mx-auto mt-5">
                {this.renderLoginCard()}
              </div>
            </div>
          </div>
        </div>
        <Footer {...this.props} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({ login: state.login });

const action = { login_user };

export default connect(mapStateToProps, action)(UpdatePassword);
