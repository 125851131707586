import React, { useState } from "react"
import { BaseModal, DotsLoader } from "../../../components"
import i18n from "../../../i18n"
import IntakeFilters from "./components/IntakeFilters/IntakeFilters"
import IntakeTables from "./components/IntakeTables"
import actions from "../../../redux/actions/Saloon/Intake"
import intakeTypeActions from "../../../redux/actions/Saloon/IntakeType"
import { useDispatch, useSelector } from "react-redux"
import { ErrorHelper } from "../../../helpers"
import { useEffect } from "react"
import { exportTableToPDF } from "../../../util/exportToPDF"
import PrimaryButton from "../../../components/Core/PrimaryButton"
const { getRequest } = actions
import useScreenSize from "../../../context/ScreenSize"
export default function IntakeReport() {
  const dispatch = useDispatch()
  const loading = useSelector((s) => s.intake.isFetching)
  const [filters, setFilters] = useState({})
  const { screenSize } = useScreenSize()
  const [openFiltersModal, setOpenFiltersModal] = useState(false)
  const [openOptionsModal, setOpenOptionsModal] = useState(false)
  useEffect(() => {
    onFilter()
    dispatch(intakeTypeActions.getRequest())
  }, [])

  const onFilter = (exportToXlsx) => {
    const params = { filters }
    if (exportToXlsx) {
      params.exportToXlsx = true
    }
    dispatch(
      getRequest(params, {
        failure() {
          ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true)
        },
      })
    )
  }
  const renderFilter = () => {
    return (
      <IntakeFilters
        filters={filters}
        setFilters={setFilters}
        onFilter={onFilter}
      />
    )
  }
  const renderButtons = () => {
    return (
      <div className="d-flex flex-column flex-md-row gap-2 flex-wrap">
        <PrimaryButton
          className={screenSize === "mobile" ? "" : "w-128"}
          label={i18n.t("export_to_pdf")}
          onClick={() => exportTableToPDF(["#table"], i18n.t("intake_report"))}
        />
        <PrimaryButton
          className="px-4"
          label={i18n.t("export_to_excel")}
          onClick={() => onFilter(true)}
        />
        {screenSize !== "mobile" ? (
          <PrimaryButton
            className="w-128"
            label={i18n.t("submit")}
            onClick={() => onFilter()}
          />
        ) : null}
      </div>
    )
  }
  return (
    <div className="p-4">
      <DotsLoader isloading={loading} />
      <div className="col-12 px-0 flex-column flex-md-row gap-2 d-flex align-items-center justify-content-between mb-4">
        <span className="saloon-dashboard-heading pt-0">
          {i18n.t("intake_report")}
        </span>
        {screenSize !== "mobile" ? (
          <div className="d-flex gap-1 flex-wrap">
            <PrimaryButton
              className="w-128"
              label={i18n.t("export_to_pdf")}
              onClick={() =>
                exportTableToPDF(["#table"], i18n.t("intake_report"))
              }
            />
            <PrimaryButton
              className="px-4"
              label={i18n.t("export_to_excel")}
              onClick={() => onFilter(true)}
            />
            <PrimaryButton
              className="w-128"
              label={i18n.t("submit")}
              onClick={() => onFilter()}
            />
          </div>
        ) : (
          <div className="d-flex gap-2 justify-content-center">
            <PrimaryButton
              className="w-128"
              label={i18n.t("filters")}
              onClick={() => setOpenFiltersModal(true)}
            />
            <PrimaryButton
              className="w-128"
              label="Options"
              onClick={() => setOpenOptionsModal(true)}
            />
          </div>
        )}
      </div>
      {screenSize !== "mobile" ? <>{renderFilter()}</> : null}
      <IntakeTables />
      <BaseModal
        open={openFiltersModal}
        onClose={() => setOpenFiltersModal(false)}
        title={i18n.t("filters")}
        content={<>{renderFilter()}</>}
        actions={
          <div className="d-flex justify-content-center">
            <PrimaryButton
              className="w-128"
              label={i18n.t("submit")}
              onClick={() => onFilter()}
            />
          </div>
        }
      />
      <BaseModal
        open={openOptionsModal}
        onClose={() => setOpenOptionsModal(false)}
        title="Options"
        content={<>{renderButtons()}</>}
      />
    </div>
  )
}
