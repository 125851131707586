import { useDispatch } from "react-redux"
import React, { useMemo, useState } from "react"
import i18n from "../../i18n"
import DatePicker from "react-datepicker"
import moment from "moment"
import { insertRequest } from "../../redux/actions/ReoccuringUnavailabilties"
import { insertRequest as companyInsertRequest } from "../../redux/actions/ReoccuringCompanyUnavailabilties"
import Select from "../Core/Select"
import "./styles.scss"
import { TextField } from "@material-ui/core"
import PrimaryButton from "../Core/PrimaryButton"
import { ErrorHelper, SuccessHelper } from "../../helpers"
import useActiveMenuType from "../../context/ActiveMenuType"
function ReoccuringUnavailabilities({ onClose, employeeId }) {
  const dispatch = useDispatch()
  const { type } = useActiveMenuType()
  const isSalon = useMemo(() => {
    return type != "employee"
  }, [type])
  const [startDate, setStartDate] = useState(moment(moment.now()).toDate())
  const [endDate, setEndDate] = useState(
    moment(moment.now()).add(1, "w").toDate()
  )
  const [startTime, setStartTime] = useState()
  const [endTime, setEndTime] = useState()
  const [frequency, setFrequency] = useState(1)
  const [formErrors, setFormErrors] = useState({})
  const [note, setNote] = useState("")
  const onStartDateChange = (val) => {
    if (val > endDate) {
      setStartDate(endDate)
      setEndDate(val)
    } else {
      setStartDate(val)
    }
  }
  const onEndDateChange = (val) => {
    if (val < startDate) {
      setStartDate(val)
      setEndDate(startDate)
    } else {
      setEndDate(val)
    }
  }

  const onStartTimeChange = (val) => {
    const start = moment(val, "HH:mm")
    const remainder = start.minute() % 30
    start.add(remainder <= 15 ? -1 * remainder : 30 - remainder, "m")
    setStartTime(start.format("HH:mm"))
  }
  const onStartTimeBlur = (val) => {
    if (val > endTime) {
      setStartTime(endTime)
      setEndTime(val)
    } else {
      setStartTime(val)
    }
  }
  const onEndTimeChange = (val) => {
    const end = moment(val, "HH:mm")
    const remainder = end.minute() % 30
    end.add(remainder <= 15 ? -1 * remainder : 30 - remainder, "m")
    setEndTime(end.format("HH:mm"))
  }
  const onEndTimeBlur = (val) => {
    if (val < startTime) {
      setStartTime(val)
      setEndTime(startTime)
    } else {
      setEndTime(val)
    }
  }

  const onSubmit = () => {
    let hasErrors = false
    let newFormErrors = {}
    if (!startDate) {
      newFormErrors.startDate = i18n.t("required")
      hasErrors = true
    }
    if (!endDate) {
      newFormErrors.endDate = i18n.t("required")
      hasErrors = true
    }
    if (!startTime) {
      newFormErrors.startTime = i18n.t("required")
      hasErrors = true
    }
    if (!endTime) {
      newFormErrors.endTime = i18n.t("required")
      hasErrors = true
    }
    if (hasErrors) {
      setFormErrors(newFormErrors)
      setTimeout(() => {
        setFormErrors({})
      }, 6000)
    } else {
      const selectedInsertFunction =
        isSalon && !employeeId ? companyInsertRequest : insertRequest
      const data = {
        startDate,
        endDate,
        startTime,
        endTime,
        frequency,
        employeeId,
      }
      if (employeeId && note) {
        data.note = note
      }
      dispatch(
        selectedInsertFunction(
          data,
          {
            success: () => {
              SuccessHelper.handleSuccess(i18n.t("successfully_added"), true)
              onClose()
            },
            failure: () => {
              ErrorHelper.handleErrors(i18n.t("failed_to_add"), true)
            },
          }
        )
      )
    }
  }
  return (
    <div className="reoccuring-unavailabilities text-left">
      <div className="employee-report-filters row mx-0 px-0">
        <div className="col-6 mb-3 px-0">{i18n.t("start_date")}</div>
        <div className="col-6 mb-3 px-0">
          <DatePicker
            placeholderText="Select Start Date"
            selected={startDate}
            onChange={onStartDateChange}
            dateFormat="MM/dd/yyyy"
            customInput={
              <TextField
                value={startDate}
                className="full-width"
                readOnly={true}
                error={!!formErrors.startDate}
                helperText={formErrors.startDate}
                variant="outlined"
              />
            }
          />
        </div>
        <div className="col-6 mb-3 px-0">{i18n.t("end_date")}</div>
        <div className="col-6 mb-3 px-0">
          <DatePicker
            placeholderText="Select End Date"
            selected={endDate}
            onChange={onEndDateChange}
            dateFormat="MM/dd/yyyy"
            customInput={
              <TextField
                value={endDate}
                className="full-width"
                readOnly={true}
                error={!!formErrors.endDate}
                helperText={formErrors.endDate}
                variant="outlined"
              />
            }
          />
        </div>
      </div>
      <div className="employee-report-filters row mx-0 px-0">
        <div className="col-6 mb-3 px-0">{i18n.t("start_time")}</div>
        <div className="col-6 mb-3 px-0">
          <TextField
            type="time"
            value={startTime}
            onChange={(e) => onStartTimeChange(e.target.value)}
            onBlur={(e) => onStartTimeBlur(e.target.value)}
            step="1800"
            error={!!formErrors.startTime}
            helperText={formErrors.startTime}
            variant="outlined"
          />
        </div>
        <div className="col-6 mb-3 px-0">{i18n.t("end_time")}</div>
        <div className="col-6 mb-3 px-0">
          <TextField
            type="time"
            value={endTime}
            onChange={(e) => onEndTimeChange(e.target.value)}
            onBlur={(e) => onEndTimeBlur(e.target.value)}
            step="1800"
            error={!!formErrors.endTime}
            helperText={formErrors.endTime}
            variant="outlined"
          />
        </div>
      </div>
      <div className="employee-report-filters mb-3 row mx-0 px-0">
        <div className="col-6 px-0">{i18n.t("frequency")}</div>
        <div className="col-6 px-0">
          <Select
            value={frequency}
            defaultValue={frequency}
            onChange={(e) => {
              setFrequency(e.target.value)
            }}
            options={[
              { name: i18n.t("every_week"), value: 1 },
              { name: i18n.t("every_x_weeks", { weeks: 2 }), value: 2 },
              { name: i18n.t("every_x_weeks", { weeks: 3 }), value: 3 },
              { name: i18n.t("every_x_weeks", { weeks: 4 }), value: 4 },
            ]}
            variant="outlined"
          />
        </div>
      </div>
      {
        employeeId ?
          <div className="mt-4 ">
            <TextField variant="outlined" multiline
              value={note}
              onChange={(e) => setNote(e.target.value)}
              minRows={4}
              maxRows={4}
              label={i18n.t("note")} />
          </div> : null
      }
      <div className="row mx-0 justify-content-end mt-4 mb-3 col-12 px-0">
        <PrimaryButton className="w-128" label={i18n.t("save")} onClick={onSubmit} />
      </div>

    </div>
  )
}

export default ReoccuringUnavailabilities
