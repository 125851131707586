import React, { Component } from "react";
import "../styles/profileSection.sass";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import i18n from "../../../i18n";

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
    },

    "& .MuiOutlinedInput-input": {
      color: "black",
    },

    "& .MuiInputLabel-outlined": {
      color: "black",
    },
  },
});
const ProfileSection = ({ user, editProfile, handleFields }) => {
  const classes = useStyles();
  const generateField = (lable, inputType, val, keyName, name) => {
    return (
      <TextField
        className={classes.root}
        id="outlined-basic"
        label={lable}
        variant="outlined"
        type={inputType}
        defaultValue={val}
        onChange={(e) => handleFields(e.target.value, keyName)}
        disabled={name == "email" ? true : false}
      />
    );
  };

  return (
    <div className="profileSection">
      <form className="profileSectionContent" noValidate autoComplete="on">
        <div className="profileTitle">Edit Your Profile:</div>
        {user &&
          generateField(
            i18n.t("first_name"),
            "text",
            user.firstName || "",
            false,
            "firstName"
          )}

        {user &&
          generateField(
            i18n.t("last_name"),
            "text",
            user.lastName || "",
            false,
            "lastName"
          )}
        {user &&
          generateField(
            i18n.t("employe_phone_number"),
            "text",
            user.phoneNo || "",
            false,
            "lastName"
          )}
        {user &&
          generateField(
            i18n.t("profile_email"),
            "text",
            user.email || "",
            true,
            "email"
          )}
        {user &&
          generateField(
            i18n.t("profile_postal_code"),
            "text",
            user.postalCode || "",
            false,
            "postalCode"
          )}

        <Button
          onClick={() => editProfile()}
          variant="contained"
          // color="#97D1F6"
        >
          Update Profile
        </Button>
      </form>
    </div>
  );
};
export default ProfileSection;
