import moment from "moment"
import React from "react"
import { useSelector } from "react-redux"
import BasicTable from "../../../../components/Core/BasicTable"
import i18n from "../../../../i18n"
export default function CustomerSubscriptionReportTables() {
  const subscriptionReport = useSelector(
    (s) => s.companyCustomerSubscription.report
  )

  return (
    <div className="table-responsive">
      <BasicTable
        rows={subscriptionReport}
        schema={[
          {
            header: "ID",
            field: "shortId",
          },
          {
            header: i18n.t("expires"),
            field: "nextPaymentDueOn",
            accessor: ({ nextPaymentDueOn }) =>
              moment(nextPaymentDueOn).format("MM-DD-YYYY"),
          },
          {
            header: i18n.t("name"),
            field: "name",
            accessor: ({ companyCustomerId }) => companyCustomerId.name,
          },
          {
            header: i18n.t("phone_number"),
            field: "phoneNo",
            accessor: ({ companyCustomerId }) => companyCustomerId.phoneNo,
          },
          {
            header: i18n.t("container.profile_email"),
            field: "email",
            accessor: ({ companyCustomerId }) => companyCustomerId.email,
          },
          {
            header: i18n.t("customer_status"),
            field: "email",
            accessor: ({ companyCustomerId }) =>
              companyCustomerId.statusId?.name,
          },
          {
            header: i18n.t("price"),
            field: "price",
            accessor: ({ price }) => `$${price}`,
          },
          {
            header: i18n.t("frequency"),
            field: "frequency",
            accessor: ({ frequency }) => i18n.t(frequency),
          },
        ]}
      />
    </div>
  )
}
