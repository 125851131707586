// @flow

import { GETAVAILABLETIMESLOTS } from "./ActionTypes";

export function request(payload, index) {
  return {
    payload,
    index,
    type: GETAVAILABLETIMESLOTS.REQUEST,
  };
}

export function success(data: Object, index) {
  return {
    data,
    index,
    type: GETAVAILABLETIMESLOTS.SUCCESS,
  };
}

export function failure(errorMessage) {
  return {
    errorMessage,
    type: GETAVAILABLETIMESLOTS.FAILURE,
  };
}
