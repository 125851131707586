import React, { createContext, useContext, useEffect, useState, useMemo } from "react"
import useActiveMenuType from "./ActiveMenuType"
import CreateInvoice from "../components/CreateInvoice/CreateInvoice.component"
import SharedStorage from "../helpers/Storage"
import useCache from "./LocalCache"

const InvoiceContext = createContext({ addOpen: false })

export function InvoiceProvider({ children }) {
  const { getResource } = useCache()
  const [addOpen, setAddOpen] = useState(false)
  const { type } = useActiveMenuType()
  const [companies, setCompanies] = useState([])
  const [companyTaxMap, setCompanyTaxMap] = useState({})

  useEffect(() => {
    let isMounted = true; // Flag to track component's mount status

    const fetchData = async () => {
      try {
        const result = await getResource(async (cache) => {
          if (type === "employee") {
            const { companies = [], taxMap } = cache.company
            return {
              companies: companies.map(({ companyId }) => companyId),
              companyTaxMap: taxMap,
            }
          } else {
            const company = JSON.parse(await SharedStorage.getItem("company"))

            const { taxes } = cache.company
            return {
              companies: [company],
              companyTaxMap: {
                [company?._id]: taxes,
              },
            }
          }
        })

        if (isMounted) {
          setCompanies(result.companies)
          setCompanyTaxMap(result.companyTaxMap)
        }
      } catch (error) {
        // Handle error if the getResource or SharedStorage.getItem fails
        console.error(error)
      }
    }

    fetchData();

    return () => {
      isMounted = false; // Update the mount status on component unmount
    };
  }, [getResource, type])

  const isSalon = useMemo(() => {
    return type !== "employee"
  }, [type])

  return (
    <InvoiceContext.Provider value={{ addOpen, setAddOpen }}>
      <CreateInvoice
        close={() => setAddOpen(false)}
        open={addOpen}
        companies={companies}
        companyTaxMap={companyTaxMap}
        isSalon={isSalon}
      />
      {children}
    </InvoiceContext.Provider>
  )
}

// Hook
function useInvoice() {
  return useContext(InvoiceContext)
}

export default useInvoice