import { put, takeEvery } from "redux-saga/effects"

import * as types from "../../actions/ActionTypes"
import {
  upsert,
  get,
  remove,
  requestFailure,
} from "../../actions/Saloon/DiscountCode"

import axios from "axios"
import { discount_code } from "../../../config/WebServices"

function* upsertSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.post(`${discount_code}`, payload)
    data = data.data
    if (callbackMap.success && typeof callbackMap.success == "function") {
      callbackMap.success(data)
    }
    yield put(upsert(data.data))
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* getSaga(action) {
  const { callbackMap = {} } = action
  let data
  try {
    data = yield axios.get(`${discount_code}`)
    data = data.data
    if (callbackMap.success && typeof callbackMap.success == "function") {
      callbackMap.success(data)
    }
    yield put(get(data.data))
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* removeSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.delete(`${discount_code}`, {
      params: payload,
    })
    data = data.data
    if (callbackMap.success && typeof callbackMap.success == "function") {
      callbackMap.success(data)
    }
    yield put(remove(data.data))
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

export default function* root() {
  yield takeEvery(types.DISCOUNT_CODE.UPSERT_SAGA, upsertSaga)
  yield takeEvery(types.DISCOUNT_CODE.GET_SAGA, getSaga)
  yield takeEvery(types.DISCOUNT_CODE.REMOVE_SAGA, removeSaga)
}
