import { connect } from "react-redux"
import React, { Component } from "react"
import i18n from "../../i18n"

import NumberFormat from "react-number-format"
import DatePicker from "react-datepicker"
import moment from "moment"
import "./styles.css"
import TextField from "@material-ui/core/TextField"
import Select from "../Core/Select/index.jsx"

class AdvancedSearchBox extends Component {
  constructor(props) {
    super(props)
    this.state = {
      date: "",
      name: "",
      employee: "",
      service: "",
      status: "",
      phoneNumber: "",
    }
    /*   this.phoneNumber = React.createRef() */
  }

  componentDidUpdate(prevProps) {
    const { filteredBookings } = this.props
    if (filteredBookings !== prevProps.filteredBookings) {
      if (!filteredBookings) {
        this.setState({
          endDate: "",
          startDate: "",
          name: "",
          employee: "",
          service: "",
          status: "",
          phoneNumber: "",
        })
      }
    }
  }
  onChangeSelectDateAndTime = (date) => {
    this.setState({ date: moment(date).format("MM-DD-YYYY") })
  }

  setStartDate = (val) => {
    const { endDate } = this.state
    if (val > endDate) {
      this.setState({
        startDate: endDate,
        endDate: val,
      })
    } else {
      this.setState({
        startDate: val,
      })
    }
  }

  setEndDate = (val) => {
    const { startDate } = this.state
    if (val < startDate) {
      this.setState({
        startDate: val,
        endDate: startDate,
      })
    } else {
      this.setState({
        endDate: val,
      })
    }
  }

  filter = () => {
    const extra = {}
    if (this.state.employee) {
      extra.employee = this.state.employee
    }

    this.props.onFilter({
      name: this.state.name,
      phoneNumber: this.state.phoneNumber,
      service: this.state.service,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      status: this.state.status,
      ...extra,
    })
  }

  render = () => {
    const { services = [], employees, extraStatues = [] } = this.props
    const { startDate, endDate } = this.state
    const statuses = [
      { text: "Done", value: 4 },
      { text: "Cancel", value: 3 },
      ...extraStatues,
    ]
    return (
      <>
        <div className="form-box">
          <label>{i18n.t("container.advancedSearchDialog_name")}</label>
          <TextField
            type="text"
            value={this.state.name}
            label={i18n.t("container.advancedSearchDialog_name")}
            onChange={(e) => {
              this.setState({ name: e.target.value }, () => {
                this.filter()
              })
            }}
            variant="outlined"
          />
        </div>
        <div className="form-box">
          <label>{i18n.t("phone")}</label>
          <NumberFormat
            type="text"
            value={this.state.phoneNumber}
            onValueChange={({ value }) => {
              this.setState({ phoneNumber: value }, () => {
                this.filter()
              })
            }}
            ref={this.phoneNumber}
            format="### ### ####"
            customInput={TextField}
            label={i18n.t("phone")}
            variant="outlined"
          />
        </div>
        <div className="form-box">
          <label>{i18n.t("container.profile_service")}</label>
          <Select
            id="function-role"
            // ref={this.service}
            onChange={(e) => {
              this.setState({ service: e.target.value }, () => {
                this.filter()
              })
            }}
            value={this.state.service}
            label={i18n.t("container.profile_service")}
            options={services.map((service) => ({
              name: service,
              value: service,
            }))}
            variant="outlined"
          />
        </div>
        <div className="form-box">
          <label>{i18n.t("date")}</label>
          <div className="d-flex">
            <DatePicker
              value={startDate}
              onChange={(data) => {
                this.setStartDate(data), this.filter()
              }}
              dateFormat="MM/dd/yyyy"
              selected={startDate}
              customInput={
                <TextField
                  style={{ width: "100%" }}
                  label="Start Date"
                  variant="outlined"
                />
              }
            />
            <DatePicker
              value={endDate}
              onChange={(data) => {
                this.setEndDate(data), this.filter()
              }}
              dateFormat="MM/dd/yyyy"
              selected={endDate}
              customInput={
                <TextField
                  style={{ width: "100%" }}
                  label="End Date"
                  variant="outlined"
                />
              }
            />
          </div>
        </div>
        {employees && (
          <div className="form-box">
            <label>{i18n.t("container.employee")}</label>
            <Select
              id="function-role"
              label={i18n.t("container.employee")}
              onChange={(e) => {
                this.setState({ employee: e.target.value }, () => {
                  this.filter()
                })
              }}
              options={employees.map((employee) => ({
                name: employee.text,
                value: employee.value,
              }))}
              variant="outlined"
              value={this.state.employee}
            />
          </div>
        )}
        <div className="form-box">
          <label>{i18n.t("container.profile_status")}</label>
          <Select
            id="function-role"
            onChange={(e) => {
              this.setState({ status: e.target.value }, () => {
                this.filter()
              })
            }}
            label={i18n.t("container.profile_status")}
            options={statuses.map((status) => ({
              name: status.text,
              value: status.value,
            }))}
            variant="outlined"
            value={this.state.status}
          />
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({})

const action = {}

export default connect(mapStateToProps, action)(AdvancedSearchBox)
