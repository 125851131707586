import React, { Component } from "react"
import { DotsLoader } from "../../../components"
import {
  getAllSaloonsForEmployee,
  addCompanyByEmployee,
  deleteSalonByEmployee,
  editCompanyEmploye,
} from "../../../config/simpleApiCalls"
import { SuccessHelper } from "../../../helpers"
import Swal from "sweetalert2"
import { getNestedValue } from "../../../util/objectMethods"
import { Translation } from "react-i18next"
import i18n from "../../../i18n"
import "./styles.css"
import _ from "lodash"
import deleteConfirmation from "../../../helpers/DeleteHelper"
import { TextField } from "@material-ui/core"
import PrimaryButton from "../../../components/Core/PrimaryButton"
import BaseModal from "../../../components/BaseModal/index.jsx"
import SecondaryButton from "../../../components/Core/SecondaryButton/SecondaryButton"
import EditIcon from "../../../components/Core/BasicTable/Actions/EditIcon.jsx"
import DeleteIcon from "../../../components/Core/BasicTable/Actions/DeleteIcon.jsx"
import useCache from "../../../context/LocalCache"

class _ActiveSaloon extends Component {
  constructor(props) {
    super(props)
    this.state = {
      employeeSalon: [],
      isloading: false,
      showAddEmployeePopup: false,
      allSaloons: [],
      searchText: "",
      isPopupOpen: false,
      companyToEdit: undefined,
    }
  }

  async componentDidMount() {
    this.gettingAllSaloons()
  }

  addCompanies = () => {
    const { allSaloons } = this.state

    let companyId = ""

    allSaloons.map((v) => {
      if (v.isSelected) {
        companyId = v._id
      }
    })

    const payload = {
      companyId,
    }

    addCompanyByEmployee(payload)
      .then((res) => {
        if (res.data.success) {
          SuccessHelper.handleSuccess(res.data.msg, true)
          this.setState({ showAddEmployeePopup: false })
        }
      })
      .catch((error) => {
        console.log("error", error)
        // if (error?.data?.success === false) {
        Swal.fire({
          title: error.data.msg,
          text: "-",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#ff3600",
          cancelButtonColor: "#354e68",
          confirmButtonText: "Okay",
        }).then((result) => {
          if (result.value) {
            this.closeEditServiceCard()
          }
        })
        // }
        console.log(error, "error")
      })
  }

  editCompanies = () => {
    const { companyToEdit } = this.state
    this.setState({ isloading: true })
    const payload = {
      ...companyToEdit,
      companyServices: companyToEdit.companyServices.map((companyService) => ({
        ...companyService,
        serviceId: companyService.serviceId._id,
      })),
    }
    editCompanyEmploye(payload)
      .then((res) => {
        if (res.data.success) {
          this.setState({ isloading: false, isPopupOpen: false })
          SuccessHelper.handleSuccess("Update Succesful", true)
        }
      })
      .catch((error) => {
        this.setState({ isloading: false })
      })
  }

  gettingAllSaloons = () => {
    this.setState({ isloading: true })

    getAllSaloonsForEmployee()
      .then((res) => {
        if (res.data.success) {
          console.log(res.data.saloons, "res.data.data,res.data.data,")
          this.setState({
            allSaloons: res.data.saloons,
            isloading: false,
          })
        }
      })
      .catch((error) => {
        if (error.data.success === false) {
          Swal.fire({
            title: error,
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#ff3600",
            cancelButtonColor: "#354e68",
            confirmButtonText: "Error!",
          })
        }
      })
  }

  filterAppoinment = (searchString) => {
    const { allSaloons } = this.state
    let text = searchString.toLowerCase()
    let filteredAppoinment = allSaloons.filter((item) => {
      let CompanyName = item?.name
      return CompanyName && CompanyName.toString().toLowerCase().includes(text)
    })
    if (searchString) {
      this.setState({ filteredAppoinment })
    } else {
      this.setState({ filteredAppoinment: null })
    }
  }

  deleteSalon = (item) => {
    this.setState({ isloading: true })

    deleteSalonByEmployee(item._id)
      .then((res) => {
        console.log("deleteSalon -> resssssssssssssssss", res)
        if (res.data.success) {
          this.setState({ isloading: false })
          SuccessHelper.handleSuccess("Delete Succesful", true)
        }
      })
      .catch((error) => {
        if (error.data.success === false) {
          Swal.fire({
            title: error,
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#ff3600",
            cancelButtonColor: "#354e68",
            confirmButtonText: "Error!",
          })
        }
      })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { companies } = nextProps
    return {
      employeeSalon: companies,
    }
  }

  renderLoader = () => {
    const { isloading } = this.state
    return <DotsLoader isloading={isloading} />
  }
  _renderTable = () => {
    const { employeeSalon, searchText, saloonId } = this.state
    let newArr = employeeSalon.filter((val) =>
      val.companyId.name
        ? val.companyId.name.toLocaleLowerCase().includes(searchText)
        : "".toLocaleLowerCase().includes(searchText)
    )

    return (
      <div className="col-12 px-0 px-md-3 active_saloon_header">
        <div className="form-group col-12 col-md-4 p-0">
          <div className="pt-3 px-3 px-md-0 justify-content-center align-items-center">
            <TextField
              id="addEmployee_search_bar"
              label={i18n.t("search_here")}
              onChange={(e) =>
                this.setState({
                  searchText: e.target.value.toLocaleLowerCase(),
                })
              }
            />
            {/* <img
              src={Images.saloon_search}
              className="search-bar-active-saloon-icon"
            /> */}
          </div>
        </div>
        <div className="table-responsive">
          <table className="table w-100  d-md-table">
            <thead>
              <tr style={{ borderTop: "3px solid #F7F7F7" }}>
                <th scope="col">Salon Name</th>
                {/* <th scope="col">ID</th> */}
                <th scope="col">
                  <Translation>
                    {(t) => t("container.employe_phone_number")}
                  </Translation>
                </th>
                <th scope="col"></th>
              </tr>
            </thead>
            {newArr?.map((val, index) => {
              return (
                <tbody>
                  <tr>
                    <td>
                      <div className="d-flex align-items-center">
                        <img
                          src={val.companyId.companyLogo}
                          className="appointment-details-employee-pic"
                          style={{ width: 50, height: 50, borderRadius: 30 }}
                        />
                        <span
                          style={{ fontWeight: "bold" }}
                          className="appointment-details-employee-name"
                        >
                          {val?.companyId?.name}
                        </span>
                      </div>
                    </td>
                    <td>{val?.companyId?.businessTelephone}</td>
                    <td>
                      <EditIcon
                        onClick={() => {
                          this.setState({
                            isPopupOpen: true,
                            companyToEdit: {
                              ..._.pick(val, [
                                "companyId",
                                "employeeId",
                                "companyServices",
                                "weekPlans",
                                "isEmployeeIndependent",
                              ]),
                            },
                          })
                        }}
                      />
                      {(!saloonId ||
                        saloonId !=
                          getNestedValue(val, ["companyId", "_id"])) && (
                        <DeleteIcon
                          onClick={() => {
                            deleteConfirmation(() =>
                              this.deleteSalon(val?.companyId)
                            )
                          }}
                        />
                      )}
                    </td>
                  </tr>
                </tbody>
              )
            })}
          </table>
        </div>
      </div>
    )
  }

  closeEditServiceCard = () => {
    this.setState({
      showAddEmployeePopup: false,
    })
  }

  renderServicesWorkingHours = () => {
    const { allSaloons, filteredAppoinment } = this.state
    return (
      <div className="col-12 px-0 px-md-3">
        {/* <pre>{JSON.stringify(filteredAppoinment, null, 2)}</pre>
        <pre>{JSON.stringify(allSaloons, null, 2)}</pre> */}

        <h3 className="form-heading">{i18n.t("select_salon")}</h3>

        {filteredAppoinment
          ? this.renderSaloons(filteredAppoinment, true)
          : allSaloons.length > 0 && this.renderSaloons(allSaloons, false)}
        {/* {this.renderServicesTable()} */}
        <div className="col-12 text-right mt-2 px-0">
          <SecondaryButton
            onClick={() => this.closeEditServiceCard()}
            className="mb-2"
          >
            {i18n.t("cancel")}
          </SecondaryButton>
          <PrimaryButton
            onClick={() => this.addCompanies()}
            className="mb-2 ml-2"
            id="saloon-save-social-link"
          >
            {i18n.t("add")}
          </PrimaryButton>
        </div>
      </div>
    )
  }

  onChangeCheckbox = (e, ind, filtered, id) => {
    if (filtered) {
      let { filteredAppoinment } = this.state
      const { allSaloons } = this.state

      let newArr = filteredAppoinment.map((val) => {
        if (val._id === id) {
          return { ...val, isSelected: e.target.checked }
        } else {
          return { ...val, isSelected: false }
        }
      })
      let newArrsaloon = allSaloons.map((val) => {
        if (val._id === id) {
          return { ...val, isSelected: e.target.checked }
        } else {
          return { ...val, isSelected: false }
        }
      })
      this.setState({ filteredAppoinment: newArr, allSaloons: newArrsaloon })
    } else {
      const { allSaloons } = this.state
      let newArr = allSaloons.map((val) => {
        if (val._id === id) {
          return { ...val, isSelected: e.target.checked }
        } else {
          return { ...val, isSelected: false }
        }
      })
      this.setState({ allSaloons: newArr })
    }
  }

  onChangeShouldSendTextReminders = (e, index) => {
    const { companyToEdit } = this.state
    companyToEdit.companyServices[index] = {
      ...companyToEdit.companyServices[index],
      shouldSendTextReminders: e.target.checked,
    }
    this.setState({ companyToEdit })
  }

  onChangeFrequency = (e, index) => {
    const { companyToEdit } = this.state
    companyToEdit.companyServices[index] = {
      ...companyToEdit.companyServices[index],
      textReminderFrequency: parseInt(e.target.value),
    }
    this.setState({ companyToEdit })
  }

  renderSaloons = (allSaloons, filtered) => {
    return (
      <div>
        <div class="form-group">
          <TextField
            label={i18n.t("search_salon_title")}
            id="usr"
            onChange={(searchString) => {
              this.filterAppoinment(searchString.target.value)
            }}
          />
        </div>
        <div style={{ overflowY: "scroll" }}>
          <div
            className="col-12"
            style={{
              height: 300,
              backgroundColor: "white",
            }}
          >
            <div className="row">
              {allSaloons?.map((val, i) => {
                return (
                  <div className="col-12 custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id={val?._id}
                      checked={val.isSelected ? true : false}
                      onChange={(e) =>
                        this.onChangeCheckbox(e, i, filtered, val._id)
                      }
                    />
                    <label class="custom-control-label" for={val?._id}>
                      {val.name}
                    </label>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderSelectSaloonPopup = () => {
    const { showAddEmployeePopup } = this.state
    return (
      <BaseModal
        open={showAddEmployeePopup}
        {...{
          title: <div>{i18n.t("add_salon")}</div>,
          content: (
            <>
              <div className="container ">
                <div
                  className="col-12 col-xs-6 p-3 my-5 bg-white pop_container"
                  style={{ borderRadius: "1rem" }}
                >
                  {this.renderServicesWorkingHours()}
                </div>
              </div>
            </>
          ),
        }}
        onClose={() => this.closeEditServiceCard()}
      />
    )
  }

  renderEditCompanyPopup = () => {
    const { isPopupOpen, companyToEdit } = this.state
    return (
      isPopupOpen && (
        <BaseModal
          open={isPopupOpen}
          title={i18n.t("container.dashboard_edit")}
          onClose={() =>
            this.setState({
              isPopupOpen: false,
              companyToEdit: undefined,
            })
          }
          content={
            <>
              <div className="table-responsive">
                <table className="table mobile-max-content">
                  <thead>
                    <tr style={{ borderTop: "3px solid #F7F7F7" }}>
                      <th scope="col">
                        {i18n.t("container.profile_service")} Name
                      </th>
                      <th scope="col">Text Reminder Frequency</th>
                    </tr>
                  </thead>
                  <tbody>
                    {companyToEdit &&
                      companyToEdit.companyServices &&
                      companyToEdit.companyServices.map((service, index) => {
                        return (
                          service?.serviceId?.name && (
                            <tr>
                              <td scope="col">{service?.serviceId?.name}</td>
                              <td scope="col">
                                {this.renderShouldSendTextReminderInput(
                                  service,
                                  index
                                )}
                              </td>
                              <td scope="col">
                                {this.renderServiceTextFrequencyInput(
                                  service,
                                  index
                                )}
                              </td>
                            </tr>
                          )
                        )
                      })}
                  </tbody>
                </table>
              </div>
              <div className="col-12 text-right mt-3">
                <PrimaryButton
                  type="button"
                  className="mr-2"
                  id="active-saloon-save-social-link"
                  onClick={() => {
                    this.setState({
                      isPopupOpen: false,
                      companyToEdit: undefined,
                    })
                  }}
                >
                  {i18n.t("cancel")}
                </PrimaryButton>
                <PrimaryButton
                  onClick={() => this.editCompanies()}
                  type="button"
                  id="saloon-save-social-link"
                >
                  {i18n.t("save")}
                </PrimaryButton>
              </div>
            </>
          }
        />
      )
    )
  }

  renderShouldSendTextReminderInput = (service, index) => {
    const { companyToEdit } = this.state
    let value = false
    let disabled = false
    if (companyToEdit.isEmployeeIndependent) {
      value =
        typeof service?.shouldSendTextReminders === "boolean"
          ? service.shouldSendTextReminders
          : service?.serviceId?.shouldSendTextReminders
    } else {
      disabled = true
      value = service?.serviceId?.shouldSendTextReminders
        ? service?.serviceId?.shouldSendTextReminders
        : false
    }
    return (
      <div class="custom-control custom-checkbox form-group">
        <input
          type="checkbox"
          class="custom-control-input"
          id={"shouldSendTextReminders" + index}
          checked={value}
          disabled={disabled}
          onChange={(e) => this.onChangeShouldSendTextReminders(e, index)}
        />
        <label
          class="custom-control-label"
          for={"shouldSendTextReminders" + index}
        >
          {i18n.t("container.services_send_text_reminders")}
        </label>
      </div>
    )
  }

  renderServiceTextFrequencyInput = (service, index) => {
    const { companyToEdit } = this.state
    let value = 0
    let disabled = false
    if (companyToEdit.isEmployeeIndependent) {
      value = service?.textReminderFrequency
        ? service.textReminderFrequency
        : service?.serviceId?.textReminderFrequency
    } else {
      disabled = true
      value = service?.serviceId?.textReminderFrequency || 0
    }
    const frequencies = []
    for (let i = 2; i < 26; i++) {
      frequencies.push(
        <option key={i} value={i}>
          {i18n.t("container.every_x_weeks", { value: i })}
        </option>
      )
    }

    return (
      <select
        id="frequencies"
        disabled={disabled}
        value={value}
        class="form-control saloon-edit-category-input"
        onChange={(e) => this.onChangeFrequency(e, index)}
      >
        {frequencies}
      </select>
    )
  }

  render() {
    return (
      <div>
        {this.renderLoader()}
        <div className="content-container">
          <div className="row mx-0 flex-column flex-md-row justify-content-between align-items-center flex-no-wrap pb-3">
            <h1 className="Dashboard_heading">
              <Translation>{(t) => t("container.active_salon")}</Translation>
            </h1>
            <PrimaryButton
              className="align-items-center justify-content-center"
              onClick={() => this.setState({ showAddEmployeePopup: true })}
            >
              + Add Salon
            </PrimaryButton>
          </div>
          {this.renderSelectSaloonPopup()}
          {this.renderEditCompanyPopup()}
          {this._renderTable()}
        </div>
      </div>
    )
  }
}

export default function ActiveSaloon(props) {
  const { getResource } = useCache()
  const companies = getResource((cache) => cache.employee.companies)
  return <_ActiveSaloon {...props} companies={companies} />
}
