import { activity_category } from "../../../config/WebServices"
import SagaBuilder from "../../builders/SagaBuilder"
import actions from "../../actions/Saloon/ActivityCategory"

const activityCategory = SagaBuilder.build({
  type: "ACITVITY_CATEGORY",
  endpoint: activity_category,
  actions,
})

export default activityCategory
