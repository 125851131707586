import crudBuilder from "../../../builders/CrudBuilder/CrudBuilder"
import actions from "../../../redux/actions/Saloon/CustomerDistrict"
const { upsertRequest, getRequest, removeRequest } = actions

const CustomerDistrict = crudBuilder.build({
  type: "customerDistrict",
  title: "districts",
  actionMap: {
    upsert: upsertRequest,
    get: getRequest,
    remove: removeRequest,
  },
})

export default CustomerDistrict
