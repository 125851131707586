// @flow
import Immutable from "seamless-immutable"
import * as types from "../actions/ActionTypes"

const initialState = Immutable({
  isFetching: false,
  errorMessage: "",
  data: [],
})

export default (state = initialState, action) => {
  switch (action.type) {
  case types.COMPANY_CARD.GET_SAGA:
    return Immutable.merge(state, {
      isFetching: true,
    })
  case types.COMPANY_CARD.SET:
    return Immutable.merge(state, {
      isFetching: false,
      data: action.data,
      defaultCard: action.defaultCard,
    })
  case types.COMPANY_CARD.DELETE: {
    const newState = { ...state, isFetching: false, data: [...state.data] }
    const { _id } = action.data
    const index = state.data.findIndex((element) => element._id == _id)
    if (index != -1) {
      newState.data.splice(index, 1)
    }
    return newState
  }
  case types.COMPANY_CARD.DELETE_SAGA:
    return Immutable.merge(state, {
      isFetching: true,
    })
  case types.COMPANY_CARD.INSERT: {
    const newState = { ...state, isFetching: false, data: [...state.data] }
    const { _id } = action.data
    const index = state.data.findIndex((element) => element._id == _id)
    newState.data.push(action.data)
    return newState
  }
  case types.COMPANY_CARD.INSERT_SAGA:
    return Immutable.merge(state, {
      isFetching: true,
    })
  case types.COMPANY_CARD.UPDATE: {
    const newState = { ...state, isFetching: false, data: [...state.data] }
    const { _id } = action.data
    const index = state.data.findIndex((element) => element._id == _id)
    if (index != -1) {
      newState.data[index] = action.data
    } else {
      newState.data.push(action.data)
    }
    return newState
  }
  case types.COMPANY_CARD.UPDATE_SAGA:
    return Immutable.merge(state, {
      isFetching: true,
    })
  case types.COMPANY_CARD.REQUEST_FAILURE:
    return Immutable.merge(state, {
      isFetching: false,
    })

  case types.LOGOUT:
    return initialState
  case types.CLEAR_LOGOUT:
    return initialState
  default:
    return state
  }
}
