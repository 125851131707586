import { put, takeEvery } from "redux-saga/effects"

import * as types from "../../actions/ActionTypes"
import {
  get,
  requestFailure,
} from "../../actions/Saloon/GeneratedBookingSchedule"

import axios from "axios"
import { auto_booking_schedule } from "../../../config/WebServices"

function* getSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.post(
      `${auto_booking_schedule}/generatePossibleSchedule`,
      payload
    )
    data = data.data
    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data)
      }
      yield put(get(data.data))
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data)
      }
      yield put(requestFailure(data))
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

export default function* root() {
  yield takeEvery(types.GENERATED_BOOKING_SCHEDULE.GET_SAGA, getSaga)
}
