import { put, takeEvery } from "redux-saga/effects"

import * as types from "../../actions/ActionTypes"
import {
  insert,
  update,
  get,
  remove,
  requestFailure,
  collectOverdue,
  subscriptionReport,
} from "../../actions/Saloon/CompanyCustomerSubscription"

import axios from "axios"
import { company_customer_subscription } from "../../../config/WebServices"
import fileDownload from "js-file-download"

function* getSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.get(company_customer_subscription, {
      params: payload,
    })
    data = data.data
    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data)
      }
      yield put(get(data.data))
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data)
      }
      yield put(requestFailure(data))
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* insertSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.post(company_customer_subscription, payload)
    data = data.data
    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data)
      }
      yield put(insert(data.data))
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data)
      }
      yield put(requestFailure(data))
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* updateSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.put(company_customer_subscription, payload)
    data = data.data
    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data)
      }
      yield put(update(data.data))
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data)
      }
      yield put(requestFailure(data))
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* removeSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.delete(company_customer_subscription, {
      params: payload,
    })
    data = data.data
    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data)
      }
      yield put(remove(data.data))
    } else {
      console.log("test")
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data)
      }
      yield put(requestFailure(data))
    }
  } catch (err) {
    console.log({ err })
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* collectSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.post(
      `${company_customer_subscription}/collectOverdue`,
      payload
    )
    data = data.data
    if (callbackMap.success && typeof callbackMap.success == "function") {
      callbackMap.success(data)
    }
    yield put(collectOverdue(data.data))
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* subscriptionReportSaga(action) {
  const { payload, callbackMap = {} } = action
  const additional = {}
  if (payload.exportToXlsx) {
    additional.responseType = "blob"
  }
  let data
  try {
    data = yield axios.get(
      `${company_customer_subscription}/subscriptionReport`,
      {
        params: payload,
        ...additional,
      }
    )
    data = data.data
    if (callbackMap.success && typeof callbackMap.success == "function") {
      callbackMap.success(data)
    }
    if (payload.exportToXlsx) {
      fileDownload(data, "report.xlsx")
      yield put(requestFailure(data))
    } else {
      yield put(subscriptionReport(data.data))
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

export default function* root() {
  yield takeEvery(types.COMPANY_CUSTOMER_SUBSCRIPTION.GET_SAGA, getSaga)
  yield takeEvery(types.COMPANY_CUSTOMER_SUBSCRIPTION.INSERT_SAGA, insertSaga)
  yield takeEvery(types.COMPANY_CUSTOMER_SUBSCRIPTION.UPDATE_SAGA, updateSaga)
  yield takeEvery(types.COMPANY_CUSTOMER_SUBSCRIPTION.DELETE_SAGA, removeSaga)
  yield takeEvery(
    types.COMPANY_CUSTOMER_SUBSCRIPTION.COLLECT_OVERDUE_SAGA,
    collectSaga
  )
  yield takeEvery(
    types.COMPANY_CUSTOMER_SUBSCRIPTION.SUBSCRIPTION_REPORT_SAGA,
    subscriptionReportSaga
  )
}
