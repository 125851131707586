// @flow

import { REOCCURING_UNAVAILABILITIES } from "./ActionTypes"

export function getRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: REOCCURING_UNAVAILABILITIES.GET_SAGA,
  }
}

export function get(data) {
  return {
    data,
    type: REOCCURING_UNAVAILABILITIES.GET,
  }
}

export function removeRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: REOCCURING_UNAVAILABILITIES.DELETE_SAGA,
  }
}

export function remove(data) {
  return {
    data,
    type: REOCCURING_UNAVAILABILITIES.DELETE,
  }
}

export function updateNoteRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: REOCCURING_UNAVAILABILITIES.UPDATE_NOTE_SAGA,
  }
}
export function updateNote(data) {
  return {
    data,
    type: REOCCURING_UNAVAILABILITIES.UPDATE_NOTE,
  }
}


export function insertRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: REOCCURING_UNAVAILABILITIES.INSERT_SAGA,
  }
}

export function insert(data) {
  return {
    data,
    type: REOCCURING_UNAVAILABILITIES.INSERT,
  }
}

export function requestFailure() {
  return {
    type: REOCCURING_UNAVAILABILITIES.REQUEST_FAILURE,
  }
}
