import React, { useEffect, useMemo, useState } from "react"
import { DotsLoader } from "../../../components"
import PrimaryButton from "../../../components/Core/PrimaryButton"
import { CircularProgress } from "@material-ui/core"
import i18n from "../../../i18n"
import { ReactFormGenerator } from "react-form-builder2"
import "react-form-builder2/dist/app.css"
import { getCompanyRatings } from "../../../config/simpleApiCalls"
import { Autocomplete } from "@material-ui/lab"
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core"
import useCache from "../../../context/LocalCache"
import moment from "moment"
import ReactStars from "react-rating-stars-component"
import { BaseModal } from "../../../components"
import Table from "../../../components/Core/BasicTable/index"
import InfiniteScroll from "react-infinite-scroll-component"

export default function EmployeeRatings({ companyEmployee }) {
  const [items, setItems] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const [index, setIndex] = useState(1)
  const [open, setOpen] = useState(false)
  const [ratingFormAnswer, setRatingFormAnswer] = useState({
    _id: null,
    answers: [],
    questions: [],
  })
  const { getResource } = useCache()
  const employees = useMemo(() => {
    return getResource((cache) => cache.company.employees)
      .filter(({ isActive }) => isActive)
      .map((employee) => ({
        value: employee._id,
        name: `${employee.employee.userId.firstName || ""} ${
          employee.employee.userId.lastName || ""
        }`,
      }))
  }, [getResource((cache) => cache.company.employees)])

  const [selectedEmployee, setSelectedEmployee] = useState("")
  const [selectedRating, setSelectedRating] = useState("")
  const ratings = [1, 2, 3, 4, 5]
  const [isLoading, setIsLoading] = useState(false)

  const fetchDataWithFilter = () => {
    setIsLoading(true)

    getCompanyRatings({
      skip: 0,
      limit: 10,
      companyEmployeeId: companyEmployee?._id || selectedEmployee?.value,
      rating: selectedRating,
    })
      .then(({ data }) => {
        document.querySelector(".infinite-scroll-component").scrollTop = 0
        setItems(data.data)
        data.data.length >= 10 ? setHasMore(true) : setHasMore(false)
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false))

    setIndex(1)
  }
  const reset = () => {
    setSelectedEmployee()
    setSelectedRating()
  }
  useEffect(() => {
    fetchDataWithFilter()
  }, [selectedEmployee, selectedRating])

  const fetchMoreData = () => {
    getCompanyRatings({
      skip: `${index}0`,
      limit: 10,
      companyEmployeeId: companyEmployee?._id || selectedEmployee?.value,
      rating: selectedRating,
    })
      .then(({ data }) => {
        setItems((prevItems) => [...prevItems, ...data.data])

        data.data.length > 0 ? setHasMore(true) : setHasMore(false)
      })
      .catch((err) => console.log(err))

    setIndex((prevIndex) => prevIndex + 1)
  }
  const tableContent = () => {
    return (
      <Table
        rows={items}
        schema={[
          {
            header: i18n.t("container.profile_employe_name"),
            field: "name",
            accessor: (data) => {
              return `${
                data.bookingId.services[0].employeeId.userId.firstName || ""
              } ${data.bookingId.services[0].employeeId.userId.lastName}`
            },
          },
          {
            header: i18n.t("container.profile_service"),
            field: "service",
            accessor: (data) => {
              return data.bookingId.services[0].serviceId.name
            },
          },
          {
            header: i18n.t("container.average_rating"),
            field: "averageRating",
            render: (data) => {
              return (
                <ReactStars
                  key={data.id}
                  count={5}
                  value={data.averageRating || 0}
                  edit={false}
                  isHalf={true}
                  halfIcon={<i className="fa fa-star-half-alt"></i>}
                />
              )
            },
          },
          {
            header: i18n.t("created_date"),
            field: "date",
            accessor: (data) => {
              return moment(data.createdDate).format("MM-DD-YYYY")
            },
          },
          {
            key: "action",
            header: i18n.t("actions"),
            render: (val) => {
              return (
                <PrimaryButton
                  onClick={() => {
                    setRatingFormAnswer(val)
                    setOpen(true)
                  }}
                >
                  {i18n.t("view")}
                </PrimaryButton>
              )
            },
          },
        ]}
      ></Table>
    )
  }

  return (
    <>
      <DotsLoader isloading={isLoading} />
      <div className="d-flex flex-column gap-2 flex-md-row mb-3">
        {!companyEmployee && (
          <div className="col-12 col-md-4">
            <Autocomplete
              options={employees}
              value={selectedEmployee}
              onChange={(e, value) => {
                setSelectedEmployee(value)
              }}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} label={i18n.t("employees")} fullWidth />
              )}
            />
          </div>
        )}
        <div
          className={`${
            companyEmployee ? "col-12 col-md-4" : "col-12 col-md-4"
          } `}
        >
          <FormControl>
            <InputLabel>{i18n.t("container.rating")}</InputLabel>
            <Select
              value={selectedRating}
              fullWidth
              onChange={(e) => setSelectedRating(e.target.value)}
            >
              <MenuItem value="" className="p-4"></MenuItem>
              {ratings.map((rating) => (
                <MenuItem key={rating} value={rating}>
                  <ReactStars
                    key={rating}
                    count={5}
                    value={rating}
                    edit={false}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="col-3 col-md-4 mt-auto ">
          <PrimaryButton onClick={reset}>{i18n.t("reset")}</PrimaryButton>
        </div>
      </div>
      <InfiniteScroll
        dataLength={items.length}
        next={fetchMoreData}
        height={400}
        hasMore={hasMore}
        loader={
          <div className="row justify-content-center">
            <CircularProgress />
          </div>
        }
      >
        {tableContent()}
      </InfiniteScroll>
      <BaseModal
        open={open}
        onClose={() => setOpen(false)}
        title={`${ratingFormAnswer.bookingId?.services[0].serviceId.name} - ${
          ratingFormAnswer.bookingId?.services[0].employeeId.userId.firstName ||
          ""
        } ${
          ratingFormAnswer.bookingId?.services[0].employeeId.userId.lastName
        }`}
        content={
          <ReactFormGenerator
            key={ratingFormAnswer._id}
            hide_actions={true}
            read_only={true}
            answer_data={ratingFormAnswer.answers}
            data={ratingFormAnswer.questions}
          />
        }
      />
    </>
  )
}
