import React, { useEffect } from "react"
import { connect } from "react-redux"
import Header from "./components/Header.component"
import ListFundsRequests from "./components/ListFundsRequests.component"
import BalanceCards from "./components/BalanceCards.component"
import { getAccountDetailsRequest } from "../../../redux/actions/Employee/EmployeeFundsRequest"
import "./styles.scss"
import DotsLoader from "../../DotsLoader"
import i18n from "../../../i18n"
function RequestFunds({ accountDetails, getAccountDetailsRequest, isloading }) {
  useEffect(() => {
    getAccountDetailsRequest({})
  }, [])
  const {
    totalAvailable = 0,
    combinedPending = 0,
    employeePending = 0,
    employeeToClientAvailable = 0,
    employeeToClientPending = 0,
  } = accountDetails
  return (
    <div className="employee-request-funds">
      <DotsLoader isloading={isloading} />
      <div className="col-12 p-3">
        <Header />
      </div>
      <div className="col-12">{i18n.t("owed_by_owner")}</div>
      <BalanceCards
        pending={employeePending}
        available={totalAvailable}
        total={totalAvailable + employeePending}
      />
      <div className="col-12">{i18n.t("owed_to_owner")}</div>
      <BalanceCards
        pending={employeeToClientPending}
        available={employeeToClientAvailable}
        total={employeeToClientPending + employeeToClientAvailable}
      />
      <ListFundsRequests />
    </div>
  )
}

const mapStateToProps = (state) => ({
  accountDetails: state.employeeFundsRequest.accountDetails,
  isloading: state.employeeFundsRequest.isFetching,
})

const action = { getAccountDetailsRequest }

export default connect(mapStateToProps, action)(RequestFunds)
