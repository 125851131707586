import { connect } from "react-redux";
import React, { Component } from "react";

import i18n from "../../i18n";
import { Translation, withTranslation } from "react-i18next";

import { request as saloon_user_id } from "./../../redux/actions/Saloon/SaloonByUserId";
import { DotsLoader, Navbar, Footer } from "./../../components";
import { SuccessHelper, ErrorHelper } from "./../../helpers";
import { Images } from "./../../theme";

import "./styles.css";

class AvailableService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isloading: false,
    };
  }

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  renderSocialbutton = () => {
    return (
      <div>
        <div
          className="row px-3"
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <span className=" mb-2 google-btn">
            <img className="ml-0 google-btn-img" src={Images.google_signin} />
            <span className="google-btn-text ">
              <Translation>{(t) => t("login_with_google")}</Translation>
            </span>
          </span>
        </div>
        <div
          className="row px-3"
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          <span className=" mb-2 facebook-btn">
            <img className="facebook-btn-img" src={Images.facebook_signin} />
            <span className="facebook-btn-text">
              <Translation>{(t) => t("login_with_facebook")}</Translation>
            </span>
          </span>
        </div>
        <div
          className="row px-3"
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          <span className="  py-1 py-md-2 py-lg-2  email-btn">
            <img className="email-btn-img" src={Images.mail_signin} />
            <span className="email-btn-text">
              <Translation>{(t) => t("login_with_email")}</Translation>
            </span>
          </span>
        </div>
      </div>
    );
  };

  renderLoginBtn = () => {
    return (
      <div className="row d-flex justify-content-center">
        <div className="col-12 col-lg-3 col-md-6 mt-2 mrg-bottom">
          <span
            className="add_conform_btn px-5 px-lg-5 px-md-4 mr-2"
            style={{ paddingTop: 10, paddingBottom: 10 }}
          >
            <img
              src={Images.saloon_available_services_date}
              style={{ width: 17, height: 17, color: "white", marginRight: 7 }}
            ></img>
            <Translation>
              {(t) => t("container.availableService_confirm_date")}
            </Translation>
          </span>
        </div>
        <div className="col-12 col-lg-3 col-md-6 mt-2 mrg-bottom">
          <span
            className="add_conform_btn px-5 px-lg-5 px-md-4 mr-2"
            style={{ paddingTop: 10, paddingBottom: 10 }}
          >
            <img
              src={Images.saloon_available_services_time}
              style={{ width: 20, height: 20, color: "white", marginRight: 7 }}
            ></img>
            <Translation>
              {(t) => t("container.availableService_confirm_time")}
            </Translation>
          </span>
        </div>
        <button className="btn btn-lg" type="submit" style={{ width: "20%" }}>
          <Translation>
            {(t) => t("container.availableService_next_steps")}
          </Translation>
        </button>
      </div>
    );
  };
  renderEnterTelephoneNo = () => {
    const sellingItems = [
      {
        img: Images.template_selling_card_img_1,
        service: "Hair Cutting",
        serviceCharges: "$09.00 - $12.00",
        tick: Images.step_done_tick_orange,
      },
      {
        img: Images.template_selling_card_img_1,
        service: "Nail Cutting",
        serviceCharges: "$09.00 - $12.00",
      },
    ];

    return (
      <div className="row">
        {sellingItems.map((val, ind) => {
          return (
            <div className="col-12 col-md-6 col-lg-6">
              <div className="mb-4 mt-1 mr-0 ml-0 mr-md-4 ml-md-4 mrg-media ">
                <img
                  src={val.tick}
                  className="child1"
                  style={{ width: 35 }}
                ></img>
                <div className="services-border">
                  <div className="d-flex pt-3 pl-3">
                    <img
                      src={Images.pic1}
                      className="mr-2"
                      style={{ width: 78, height: 78 }}
                    ></img>
                    <div className="pl-3">
                      <h4 class="card-title feature-heading font-media">
                        {val.service}
                      </h4>
                      <h4 class="card-title tag-text ml-0 font-media">
                        <img src={Images.tag_grey} className="mr-2"></img>
                        {val.serviceCharges}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  renderAvailableServicesForm = () => {
    return (
      <form action="javascript:void(0)" className="form-signin  px-4">
        {this.renderEnterTelephoneNo()}
        {this.renderEnterTelephoneNo()}
        {this.renderEnterTelephoneNo()}
        {this.renderLoginBtn()}
      </form>
    );
  };

  renderLoginCard = () => {
    return (
      <div className="card card-signin my-5">
        <div className="card-body px-0">
          <h5 className="card-title text-center">
            <Translation>
              {(t) => t("container.availableService_availability_services")}
            </Translation>
          </h5>
          {/* {this.renderSocialbutton()} */}
          <div className="row">
            <hr className="my-4 mx-3 " style={{ width: "100%" }} />
          </div>
          {this.renderAvailableServicesForm()}
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="container-fluid px-0">
        {this.renderLoader()}
        <Navbar {...this.props} isBackgroundColor={true} />
        <div className="container" style={{ backgroundColor: "#F8FDFF" }}>
          <div className="row mt-5">
            <div className="col-sm-12 col-md-12 col-lg-12 mx-auto mt-5">
              {this.renderLoginCard()}
            </div>
          </div>
        </div>
        <Footer {...this.props} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  login: state.saloonLogin,
  saloonUserId: state.saloonUserId,
});

const action = { saloon_user_id };

export default connect(mapStateToProps, action)(AvailableService);
