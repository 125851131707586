import { connect } from "react-redux";
import React, { Component } from "react";
import i18n from "../../../i18n";
import { Images } from "../../../theme";
import { DotsLoader } from "../../../components";
import { request as SaloonAppointment } from "../../../redux/actions/Saloon/SaloonAppointment";
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import { request as get_available_timeslots } from "../../../redux/actions/GetAvailableTimeslots.js";
import { request as get_gym_timeslots } from "../../../redux/actions/GetGymTimeslots.js";
import { importCustomers } from "../../../config/simpleApiCalls";
import "./styles.css";

class LiveDisplaySettings extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onSetSidebarOpen = (open) => {
    this.setState({ sidebarOpen: open });
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  fileSelectedHandler = (event) => {
    const file = URL.createObjectURL(event.target.files[0]);
    this.setState({ imageUrl: file, imageFile: event.target.files[0] });
  };

  renderFileAddBtn = () => {
    return (
      <div
        onClick={() => this.fileInput.click()}
        style={{
          width: 200,
          height: 200,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "1rem",
          backgroundColor: "#EEEEEE ",
          borderRadius: "0.5rem",
        }}
      >
        <img src={Images.saloon_add} style={{ width: 50, height: 50 }} />
      </div>
    );
  };

  renderImageFile = () => {
    const { imageUrl } = this.state;
    return (
      <div
        className=""
        style={{
          backgroundImage: `url(${imageUrl})`,
          width: 200,
          height: 200,
          margin: "1rem",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          borderRadius: "0.5rem",
          border: "2px solid #448EF7",
        }}
      />
    );
  };

  download = (type) => {
    if (type == "csv") {
    } else {
    }
  };

  renderUploadFileCard = () => {
    return (
      <div className="col-12 p-0 pb-3">
        <div
          className="col-12 bg-white"
          style={{ border: "2px dotted #E0E2E4", borderRadius: "7px" }}
        >
          <div className="col-12 p-4 col-md-10 mx-auto">
            <input
              type="file"
              style={{ display: "none" }}
              onChange={this.fileSelectedHandler}
              ref={(fileInput) => (this.fileInput = fileInput)}
              accept=""
            />
            <div
              className="col-12"
              id="upload-box"
              onClick={() => this.fileInput.click()}
            >
              <img
                src={Images.saloon_upload_media_icon}
                style={{ width: 128 }}
              />
              <div className="mt-3">
                <p id="drop-file-text">
                  {i18n.t("container.dashboard_drop_files")}{" "}
                  <span style={{ color: "#F6943B" }}>
                    {i18n.t("container.dashboard_browse")}
                  </span>
                </p>
                <div className="text-center font-weight-bolder">
                  {i18n.t("download_example")} (
                  <a
                    href="https://storage.googleapis.com/easy1_booking/samples/customer_sample_csv.csv"
                    download
                    className="download-sample"
                  >
                    CSV
                  </a>{" "}
                  {i18n.t("or")}{" "}
                  <a
                    href="https://storage.googleapis.com/easy1_booking/samples/customer_sample.xlsx"
                    download
                    className="download-sample"
                  >
                    XLSX
                  </a>
                  )
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderAddedFilesCard = () => {
    return (
      <div
        className="px-0 full-width"
        style={{ borderRadius: "1rem", overflow: "hidden" }}
      >
        <div className="row">{this.renderPreviewSection()}</div>
      </div>
    );
  };

  renderPreviewSection = () => {
    return (
      <div
        className="col-12 full-width p-3 import-preview bg-white"
        style={{ backgroundColor: "#EEEEEE" }}
      >
        <h6>Are you sure you want to import this file?</h6>
        <div className="row">
          <button
            type="button"
            id="saloon-upload"
            onClick={() => this.handleDelete()}
            className="d-block btn btn-light btn-sm ml-1 saloon-category-service-btn saloon-upload"
          >
            Cancel
          </button>
          <button
            type="button"
            id="saloon-upload"
            onClick={() => this.handleImport()}
            className="d-block btn btn-light btn-sm ml-1 saloon-category-service-btn saloon-upload"
          >
            Import
          </button>
        </div>
      </div>
    );
  };

  handleImport = () => {
    this.setState({ isloading: true });
    const { imageFile } = this.state;
    let payloadFormdata = new FormData();
    payloadFormdata.append("import", imageFile);
    // payloadFormdata.append('video', videoFile);
    importCustomers(payloadFormdata).then(({ data }) => {
      console.log(data);
      if (data.success) {
        SuccessHelper.handleSuccess(
          `Import successful. ${data.data.success} successful. ${data.data.fail} failed. `,
          true
        );
        this.setState({
          isloading: false,
          imageFile: undefined,
        });
      } else {
        ErrorHelper.handleErrors("Failed to import customers", true);
        this.setState({
          isloading: false,
        });
      }
    });
  };

  handleDelete = (e) => {
    this.setState({
      imageUrl: null,
      imageFile: null,
      imageName: null,
      imageTitle: null,
      imageDescription: null,
    });
  };

  render() {
    const { imageFile } = this.state;
    return (
      <div>
        {this.renderLoader()}
        <div className="content-container">
          <div className="row mx-0 p-4">
            <div className="col-12 px-0 mb-4">
              <span className="saloon-dashboard-heading">
                {i18n.t("container.customer_import")}
              </span>
            </div>
            {imageFile
              ? this.renderAddedFilesCard()
              : this.renderUploadFileCard()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  booking: state.SalonAppointment,
  getAvailableTimeslots: state.getAvailableTimeslots,
  getGymTimeslots: state.getGymTimeslots,
});

const action = {
  SaloonAppointment,
  get_available_timeslots,
  get_gym_timeslots,
};

export default connect(mapStateToProps, action)(LiveDisplaySettings);
