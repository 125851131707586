import React, { useEffect } from "react"
import BaseModal from "../../../components/BaseModal/index"
import i18n from "../../../i18n"
import Paper from "@material-ui/core/Paper"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import AppBar from "@material-ui/core/AppBar"
import EditEmployeeWorkingHours from "./EditEmployeeWorkingHours"
import EmployeeSettings from "./EmployeeSettings"
import EmployeeServices from "./EmployeeServices"
import ManageUnavailibilites from "../../Employee/ManageUnavailibilites"
import TabPanel from "../../../components/Core/Tabs/TabPanel"

export default function EditEmployeeModal({
  employee,
  open,
  onClose,
  handleSave,
}) {
  const [value, setValue] = React.useState(0)
  useEffect(() => {
    if (!open) {
      setValue(0)
    }
  }, [open])
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const handleChangeIndex = (index) => {
    setValue(index)
  }
  return (
    <BaseModal
      {...{ open, onClose }}
      content={
        <>
          <AppBar position="static" color="default" className="custom-tab">
            <Paper>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label={i18n.t("employee_settings")} />
                <Tab label={i18n.t("container.services")} />
                <Tab label={i18n.t("working_hours")} />
                <Tab label={i18n.t("unavailabilities")} />
              </Tabs>
            </Paper>
          </AppBar>
          <TabPanel value={value} index={0}>
            <EmployeeSettings {...{ employee, handleSave }} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <EmployeeServices {...{ employee, handleSave }} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <EditEmployeeWorkingHours
              {...{ employee, handleSave, hideTitle: true }}
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <ManageUnavailibilites employeeId={employee?.employeeId?._id} />
          </TabPanel>
        </>
      }
      title={i18n.t("edit_Employee")}
    />
  )
}
