import { EMPLOYEE_REGISTER, EMPLOYEE_LOGOUT } from "../ActionTypes"

export function request(payload) {
  return {
    payload,
    type: EMPLOYEE_REGISTER.REQUEST,
  }
}

export function success(data) {
  return {
    data,
    type: EMPLOYEE_REGISTER.SUCCESS,
  }
}

export function failure(errorMessage) {
  return {
    errorMessage,
    type: EMPLOYEE_REGISTER.FAILURE,
  }
}

export function logout() {
  return {
    type: EMPLOYEE_LOGOUT,
  }
}
