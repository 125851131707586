import React, { useEffect, useRef } from "react";
import SecondaryButton from "../Core/SecondaryButton/SecondaryButton";
import i18n from "../../i18n";
import { useState } from "react";
import ThreeDButton from "../Core/3DButton/ThreeDButton";
import DollarInput from "../DollarInput";
import PercentInput from "../PercentInput";
import BackspaceIcon from "@material-ui/icons/Backspace";

const TouchKeyPad = ({
  inputType = "dollar",
  renderPreKeyPad = () => { },
  value,
  setValue = () => { },
}) => {
  const [input, setInput] = useState(0);
  const [intialized, setInitialized] = useState(false);
  const buttonStyle = { height: 34, fontSize: 16 };
  const buttonWrapperStyke = { borderRadius: 7 };
  const handleNumberButtonClick = (char) => {
    let newInput = "";
    if (!input) {
      newInput = "0.0" + char;
    } else {
      newInput = input.toString().replace(".", "");
      const decimals = newInput.slice(newInput.length - 1, newInput.length);
      const integers = newInput.slice(0, newInput.length - 1);
      newInput = `${parseInt(integers)}.${decimals + char}`;
    }
    setInput(newInput);
  };
  const handleBackSpace = () => {
    let newInput = input.toString().slice(0, -1);
    newInput = newInput.toString().replace(".", "");
    const decimals = newInput.slice(newInput.length - 2, newInput.length);
    const integers = newInput.slice(0, newInput.length - 2);
    newInput = `${parseInt(integers || 0)}.${decimals}`;
    setInput(newInput);
  };

  useEffect(() => {
    if (intialized && value != input) {
      setInput(value);
    }
  }, [value]);

  useEffect(() => {
    if (intialized && value != input) {
      setValue(input);
    }
  }, [input]);
  useEffect(() => {
    setInput(value);
    setInitialized(true);
  }, []);

  return (
    <div>
      <div className="row mx-0 px-2 align-items-center flex-nowrap">
        {inputType == "dollar" ? (
          <DollarInput value={input} onChange={setInput} type="text" />
        ) : (
          <PercentInput value={input} onChange={setInput} type="text" />
        )}
      </div>
      {renderPreKeyPad()}
      <div className="row mx-0">
        <div className="col-4 p-2">
          <ThreeDButton
            label="1"
            style={buttonStyle}
            wrapperStyle={buttonWrapperStyke}
            onClick={() => handleNumberButtonClick("1")}
          />
        </div>
        <div className="col-4 p-2">
          <ThreeDButton
            label="2"
            style={buttonStyle}
            wrapperStyle={buttonWrapperStyke}
            onClick={() => handleNumberButtonClick("2")}
          />
        </div>
        <div className="col-4 p-2">
          <ThreeDButton
            label="3"
            style={buttonStyle}
            wrapperStyle={buttonWrapperStyke}
            onClick={() => handleNumberButtonClick("3")}
          />
        </div>
        <div className="col-4 p-2">
          <ThreeDButton
            label="4"
            style={buttonStyle}
            wrapperStyle={buttonWrapperStyke}
            onClick={() => handleNumberButtonClick("4")}
          />
        </div>
        <div className="col-4 p-2">
          <ThreeDButton
            label="5"
            style={buttonStyle}
            wrapperStyle={buttonWrapperStyke}
            onClick={() => handleNumberButtonClick("5")}
          />
        </div>
        <div className="col-4 p-2">
          <ThreeDButton
            label="6"
            style={buttonStyle}
            wrapperStyle={buttonWrapperStyke}
            onClick={() => handleNumberButtonClick("6")}
          />
        </div>
        <div className="col-4 p-2">
          <ThreeDButton
            label="7"
            style={buttonStyle}
            wrapperStyle={buttonWrapperStyke}
            onClick={() => handleNumberButtonClick("7")}
          />
        </div>
        <div className="col-4 p-2">
          <ThreeDButton
            label="8"
            style={buttonStyle}
            wrapperStyle={buttonWrapperStyke}
            onClick={() => handleNumberButtonClick("8")}
          />
        </div>
        <div className="col-4 p-2">
          <ThreeDButton
            label="9"
            style={buttonStyle}
            wrapperStyle={buttonWrapperStyke}
            onClick={() => handleNumberButtonClick("9")}
          />
        </div>
        <div className="col-4 p-2" />
        <div className="col-4 p-2">
          <ThreeDButton
            label="0"
            style={buttonStyle}
            wrapperStyle={buttonWrapperStyke}
            onClick={() => handleNumberButtonClick("0")}
          />
        </div>
        <div className="col-4 p-2">
          <ThreeDButton
            label={<BackspaceIcon />}
            style={buttonStyle}
            wrapperStyle={buttonWrapperStyke}
            onClick={handleBackSpace}
          />
        </div>
      </div>
    </div>
  );
};

export default TouchKeyPad;
