import React, { Component, useEffect, useRef, useState } from "react"
import { DotsLoader } from "../../../components"
import PrimaryButton from "../../../components/Core/PrimaryButton"
import { ErrorHelper, SuccessHelper } from "../../../helpers"
import "./styles.css"
import i18n from "../../../i18n"
import {
  ReactFormBuilder,
  ReactFormGenerator,
  ElementStore,
} from "react-form-builder2"
import "react-form-builder2/dist/app.css"
import { BaseModal } from "../../../components"
import { upsertRatingForm } from "../../../config/simpleApiCalls"
import SharedStorage from "../../../helpers/Storage"
import FormElementsEdit from "./editForm/form-elements-edit"
export default function RatingForm() {
  const [data, setData] = useState([])
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [companyShortenedId, setCompanyShortenedId] = useState(null)
  useEffect(async () => {
    const companyShortenedId = JSON.parse(
      await SharedStorage.getItem("company")
    ).shortenedId
    setCompanyShortenedId(companyShortenedId)
    ElementStore.subscribe((state) => onChange(state.data))
  }, [])
  const onChange = (data) => {
    setData(data)
  }

  const onSubmit = () => {
    setIsLoading(true)

    upsertRatingForm(data)
      .then(() => {
        SuccessHelper.handleSuccess(i18n.t("successfully_saved"), true)
      })
      .catch(() => {
        ErrorHelper.handleErrors(i18n.t("someting_went_wrong"), true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }
  var items = [
    {
      key: "Rating",
      label: i18n.t("placeholder_label"),
      name: i18n.t("rating"),
    },
    {
      key: "TextArea",
      label: i18n.t("placeholder_label"),
      name: i18n.t("multiline_input"),
    },
  ]

  return (
    <>
      <DotsLoader isLoading={isLoading} />
      <div className="container my-3">
        {companyShortenedId && (
          <ReactFormBuilder
            toolbarItems={items}
            url={`${process.env.REACT_APP_BASE_URL}rating/getRatingFormByCompany?companyShortenedId=${companyShortenedId}`}
            renderEditForm={(props) => <FormElementsEdit {...props} />}
          />
        )}

        <div className="row justify-content-center justify-content-md-start mt-3 gap-2">
          <PrimaryButton onClick={() => setOpen(true)}>
            {i18n.t("container.dashboard_preview")}
          </PrimaryButton>
          <PrimaryButton onClick={() => onSubmit()}>
            {i18n.t("save")}
          </PrimaryButton>
        </div>
        <BaseModal
          open={open}
          onClose={() => setOpen(false)}
          title={i18n.t("container.dashboard_preview")}
          content={
            <ReactFormGenerator
              hide_actions={true}
              read_only={true}
              data={data}
            />
          }
        />
      </div>
    </>
  )
}
