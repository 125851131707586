import React, { useState, useEffect } from "react"
import Table from "../../../components/Core/BasicTable/index"
import {
  deleteEmployeeFromCompany,
  editCompanyEmploye,
  getEmployeeNotAssociatedWithCompany,
  hireCompanyEmployee,
} from "../../../config/simpleApiCalls.js"
import i18n from "../../../i18n"
import { EmployeeHeader } from "./employeeSection.styles"
import EditEmployeeModal from "./EditEmployeeModal"
import { BaseModal, DotsLoader } from "../../../components"
import { ErrorHelper, SuccessHelper } from "../../../helpers"
import PrimaryButton from "../../../components/Core/PrimaryButton"
import DeleteIcon from "../../../components/Core/BasicTable/Actions/DeleteIcon"
import EditIcon from "../../../components/Core/BasicTable/Actions/EditIcon"
import SharedStorage from "../../../helpers/Storage"
import useCache from "../../../context/LocalCache"
import EmployeeRegister from "./EmployeeRegister"
import deleteConfirmation from "../../../helpers/DeleteHelper"
import { Images } from "../../../theme"

import ReuseIcon from "../../../components/Core/BasicTable/Actions/ReuseIcon"
import restoreConfirmation from "../../../helpers/RestoreHelper"
import Tabs from "../../../components/Core/Tabs/Tabs"
import useScreenSize from "../../../context/ScreenSize.jsx"
const daysOfWeek = {
  1: "Sunday",
  2: "Monday",
  3: "Tuesday",
  4: "Wednesday",
  5: "Thursday",
  6: "Friday",
  7: "Saturday",
}
export default function EmployeeSection({ history }) {
  const [employees, setEmployees] = useState([])
  const [archivedEmployees, setArchivedEmployees] = useState([])
  const { getResource } = useCache()
  const cachedEmployees = getResource((cache) => cache.company.employees)
  const [type, setType] = useState("")
  const [filteredEmployees, setFilteredEmployees] = useState(null)
  const [editEmployeeModalState, setEditEmployeeModalState] = useState(false)
  const [editingEmployee, setEditingEmployee] = useState(null)
  const [workingHoursPopupOpen, setWorkingHoursPopupOpen] = useState(false)
  const [workingHoursPopupData, setWorkingHoursPopupData] = useState([])
  const [search, setSearch] = useState("")
  const [loading, setLoading] = useState("")
  const [userId, setUserId] = useState("")
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const { screenSize } = useScreenSize()
  const onMount = async () => {
    const companyData = JSON.parse(await SharedStorage.getItem("company"))
    setUserId(companyData?.userId)
  }

  useEffect(() => {
    onMount()
  }, [])
  useEffect(() => {
    if (type == "edit") {
      setEmployees(cachedEmployees?.filter(({ isActive }) => isActive))
      setArchivedEmployees(
        cachedEmployees?.filter(
          ({ isActive, isAccepted }) => !isActive && isAccepted
        )
      )
    }
  }, [cachedEmployees, type])

  useEffect(() => {
    setType(
      history.location.pathname == "/saloon-company-employee" ? "edit" : "add"
    )
  }, [history.location.pathname])

  useEffect(() => {
    if (type) {
      setFilteredEmployees(null)
      setSearch("")
      getEmployees()
    }
  }, [type])

  useEffect(() => {
    if (!search) {
      return setFilteredEmployees(null)
    }
    let filterUsersList = (
      activeTabIndex == 0 ? employees : archivedEmployees
    ).filter((val) => {
      if (!val.user) {
        return false
      }
      let firstName = val.user.firstName.toUpperCase()
      let lastName = val.user.lastName.toUpperCase()
      let email = val.user.email.toUpperCase()
      let phoneNo = `${val.user.phoneNo}`
      let searchText = search.toUpperCase()
      return (
        firstName.indexOf(searchText) > -1 ||
        lastName.indexOf(searchText) > -1 ||
        phoneNo.indexOf(searchText) > -1 ||
        email.indexOf(searchText) > -1
      )
    })
    setFilteredEmployees(filterUsersList)
  }, [employees, search, activeTabIndex])

  const getEmployees = () => {
    if (type !== "edit") {
      setLoading(true)
      getEmployeeNotAssociatedWithCompany().then((response) => {
        setEmployees(response.data.data)
        setLoading(false)
      })
    }
  }

  const handleDelete = (employeeId, text) => {
    const payload = { employeeId }
    setLoading(true)
    deleteEmployeeFromCompany(payload)
      .then((res) => {
        if (res.data.success) {
          SuccessHelper.handleSuccess(i18n.t(`successfully_${text}`), true)
          getEmployees()
        }
      })
      .catch((e) => {
        ErrorHelper.handleErrors(i18n.t(e.data.msg), true)
      })
      .finally((error) => {
        setLoading(false)
      })
  }

  const closeEditModal = () => {
    setEditingEmployee(null)
    setEditEmployeeModalState(false)
  }
  const openEditModal = (employee) => {
    setEditingEmployee(employee)
    setEditEmployeeModalState(true)
  }
  const handleSave = (newData) => {
    const {
      color,
      allowCheckIn,
      isEmployeeIndependent,
      isReceptionist,
      isManager,
      isInstructor,
      displayWorkingHours,
      seat,
      split,
      baseProductCommission,
      packageCommission,
      pinCode,
      priority,
    } = editingEmployee
    const toSave = {
      employeeId: editingEmployee.employeeId._id
        ? editingEmployee.employeeId._id
        : editingEmployee.employeeId,
      companyServices: editingEmployee.companyServices,
      weekPlans: editingEmployee.weekPlans,
      color,
      allowCheckIn,
      isEmployeeIndependent,
      isReceptionist,
      isManager,
      isInstructor,
      displayWorkingHours,
      seat,
      split,
      baseProductCommission,
      packageCommission,
      pinCode,
      priority,
      ...newData,
    }
    setLoading(true)
    editCompanyEmploye(toSave)
      .then((res) => {
        if (res.data.success) {
          getEmployees()
          setEditingEmployee({ ...editingEmployee, ...toSave })
          SuccessHelper.handleSuccess(
            "Employee has been successfully Updated!",
            true
          )
        }
      })
      .catch((error) => {
        ErrorHelper.handleErrors(i18n.t(error.data.code), true)
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const tableContent = (data) => {
    return (
      <Table
        rows={data}
        schema={[
          {
            header: i18n.t("container.advancedSearchDialog_name"),
            field: "name",
            accessor: (data) => {
              if (data.userId) {
                return `${data.userId.firstName} ${data.userId.lastName}`
              }
              if (!data.user) {
                return ""
              }
              return `${data.user.firstName} ${data.user.lastName}`
            },
          },
          {
            header: i18n.t("see_working_hours"),
            field: "name",
            style: screenSize === "mobile" ? { width: 160 } : {},
            render: (data) => {
              return (
                <span className="appointment-details-employee-other">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setWorkingHoursPopupOpen(true)
                      setWorkingHoursPopupData(data.weekPlans)
                    }}
                  >
                    {i18n.t("see_working_hours")}
                  </span>
                </span>
              )
            },
          },
          {
            header: i18n.t("container.employe_phone_number"),
            field: "phoneNumber",
            accessor: (data) => {
              if (data.userId) {
                return data.userId.phoneNo
              }
              if (!data.user) {
                return ""
              }
              return data.user.phoneNo
            },
          },
          {
            header: i18n.t("container.profile_email"),
            field: "email",
            accessor: (data) => {
              if (data.userId) {
                return data.userId.email
              }
              if (!data.user) {
                return ""
              }
              return `${data.user.email}`
            },
          },
          {
            key: "action",
            header: i18n.t("actions"),
            style: { width: 120 },
            render: (val) => {
              if (type == "edit") {
                return (
                  <>
                    {val.isActive ? (
                      <EditIcon
                        onClick={() => {
                          openEditModal(val)
                        }}
                      />
                    ) : (
                      <ReuseIcon
                        onClick={() =>
                          restoreConfirmation(
                            () =>
                              handleDelete(
                                val.archivedEmployeeId._id,
                                "restored"
                              ),
                            i18n.t("this_is_inactive", {
                              name: i18n.t("employee").toLowerCase(),
                            })
                          )
                        }
                      />
                    )}

                    {userId != val?.user?._id && val.isActive ? (
                      <DeleteIcon
                        onClick={() =>
                          deleteConfirmation(() =>
                            handleDelete(val.employeeId._id, "deleted")
                          )
                        }
                      />
                    ) : null}
                  </>
                )
              } else {
                return (
                  <div className="form-button-query">
                    <PrimaryButton
                      label={i18n.t("add")}
                      onClick={() => handleAdd(val)}
                    />
                  </div>
                )
              }
            },
          },
        ]}
      ></Table>
    )
  }
  const handleAdd = (employee) => {
    const {
      color,
      allowCheckIn,
      isEmployeeIndependent,
      isReceptionist,
      isManager,
      displayWorkingHours,
      seat,
      split,
      baseProductCommission,
      pinCode,
      priority,
    } = employee
    const payload = {
      employeeId: employee._id,
      companyServices: [],
      weekPlans: employee.weekPlans,
      color,
      allowCheckIn,
      isEmployeeIndependent,
      isReceptionist,
      isManager,
      displayWorkingHours,
      seat,
      split,
      baseProductCommission,
      pinCode,
      priority,
    }

    setLoading(true)
    hireCompanyEmployee(payload)
      .then((res) => {
        if (res.data.success) {
          SuccessHelper.handleSuccess(
            i18n.t("container.add_employe_success"),
            true
          )
          history.push("/saloon-company-employee")
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            const data = JSON.parse(error.response.data.error)
            ErrorHelper.handleErrors(
              i18n.t(data.code, { totalEmployees: data.total_employees }),
              true,
              {
                showCancelButton: true,
                cancelButtonText: "Ok",
                confirmButtonText: i18n.t("upgrade"),
              }
            ).then(({ value }) => {
              if (value) {
                history.push("/saloon-company-employee")
              }
            })
          } else {
            ErrorHelper.handleErrors(error.response.data.msg, true)
          }
        } else if (error.request) {
          ErrorHelper.handleErrors("Something Went Wrong", true)
        } else {
          ErrorHelper.handleErrors(error.msg, true)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const tabs = [
    {
      title: i18n.t("active"),
      content: () => tableContent(filteredEmployees || employees),
    },
    {
      title: i18n.t("inactive"),
      content: () => tableContent(filteredEmployees || archivedEmployees),
    },
  ]
  return (
    <div className="content-container p-4">
      <DotsLoader isloading={loading} />
      {type == "edit" ? (
        <>
          <EmployeeHeader>
            <span className="saloon-dashboard-heading mb-0 pt-0 pr-3">
              {i18n.t("container.employee")}
            </span>

            <PrimaryButton
              className="w-128"
              label={i18n.t("container.add_employee_add_employe")}
              onClick={() => history.push("saloon-add-employee")}
            />
          </EmployeeHeader>
          <EditEmployeeModal
            employee={editingEmployee}
            open={editEmployeeModalState}
            onClose={closeEditModal}
            handleSave={handleSave}
          />
          <BaseModal
            title={i18n.t("working_hours")}
            open={workingHoursPopupOpen}
            onClose={() => {
              setWorkingHoursPopupOpen(false)
              setWorkingHoursPopupData([])
            }}
            showCloseBtn={screenSize === "mobile" ? false : true}
            containerClassName={"half-container"}
            content={
              <>
                <Table
                  maxContentWidth={false}
                  rows={workingHoursPopupData}
                  schema={[
                    {
                      header: i18n.t("day"),
                      field: "day",
                      accessor: (data) => {
                        return `${i18n.t(daysOfWeek[data.dayOfWeek])}`
                      },
                    },
                    {
                      header: i18n.t("start_date"),
                      field: "startTime",
                      accessor: (data) => {
                        return data.checkIn || ""
                      },
                    },
                    {
                      header: i18n.t("end_date"),
                      field: "endTime",
                      accessor: (data) => {
                        return data.checkOut || ""
                      },
                    },
                  ]}
                ></Table>
                {screenSize === "mobile" && (
                  <div className="d-flex justify-content-center">
                    <PrimaryButton
                      label={i18n.t("close")}
                      className="w-128"
                      onClick={() => {
                        setWorkingHoursPopupOpen(false)
                        setWorkingHoursPopupData([])
                      }}
                    />
                  </div>
                )}
              </>
            }
          />
          <Tabs
            tabs={tabs}
            activeTab={(value) => {
              setActiveTabIndex(value)
            }}
          />
        </>
      ) : (
        <>
          {employees?.length > 0 && (
            <div className="mb-2">
              {tableContent(filteredEmployees || employees)}
            </div>
          )}
          <EmployeeRegister handleAdd={handleAdd} />
        </>
      )}
    </div>
  )
}
