import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import styled from "styled-components";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const TableHeadItems = styled.span`
  font-weight: 900;
`;

const StyledTableHead = withStyles(() => ({
  root: {
    backgroundColor: "var(--primary-color)",
    color: "white",
  },
}))(DialogTitle);

const DialogBox = ({ open, selectBooking, onClose }) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="booking-details-popup"
      >
        <StyledTableHead>
          <div
            style={{
              display: "flex",
              width: "100%",
              "justify-content": "space-between",
            }}
          >
            <div>Details</div>
            <div style={{ cursor: "pointer" }} onClick={onClose}>
              X
            </div>
          </div>
        </StyledTableHead>
        {selectBooking?.services?.map((service, index) => {
          return (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableHeadItems>service</TableHeadItems>
                    </TableCell>
                    <TableCell>
                      <TableHeadItems>Schedule</TableHeadItems>
                    </TableCell>
                    <TableCell>
                      <TableHeadItems>employee name</TableHeadItems>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{service?.serviceId?.name}</TableCell>
                    <TableCell>
                      {moment(`${service.date} ${service.time}`).format(
                        "MMMM Do YYYY h:mm a"
                      )}
                    </TableCell>
                    <TableCell>
                      {service.employeeId.userId.firstName || ""}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          );
        })}
      </Dialog>
    </div>
  );
};

export default DialogBox;
