import { connect } from "react-redux"
import React, { Component, useMemo } from "react"
import i18n from "../../i18n"
import { TextField } from "@material-ui/core"
import { DotsLoader } from ".."
import {
  deleteBookingReminder,
  getBookingReminder,
  getCustomerByUserId,
} from "../../config/simpleApiCalls"
import { ErrorHelper, SuccessHelper } from "../../helpers"
import moment from "moment"
import "./styles.scss"
import PrimaryButton from "../Core/PrimaryButton"
import ReminderModal from "../ReminderModal"
import CustomerEdit from "../Saloon/Customer/CustomerEdit"
import Swal from "sweetalert2"
import BaseModal from "../BaseModal"
import SecondaryButton from "../Core/SecondaryButton/SecondaryButton"

class BookingReminders extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reminder: null,
      isloading: false,
    }
  }

  async componentDidMount() {
    const { booking, reminders = [], reminder, employeeId } = this.props

    const bookingId = booking?._id
    if (bookingId) {
      this.setState({ isloading: true })

      getBookingReminder({ bookingId }).then(({ data }) => {
        this.setState({ isloading: false })
        if (data.success) {
          this.setState({ reminders: data.data ? data.data : [] })
        } else {
          this.setState({ reminders: [] })
        }
      })
    } else if (reminder) {
      this.setState({ reminders: [reminder] })
    } else if (employeeId) {
      this.setState({ isloading: true })
      getBookingReminder({ employeeId: employeeId }).then(({ data }) => {
        this.setState({ isloading: false })
        if (data.success) {
          this.setState({ reminders: data.data ? data.data : [], employeeId })
        } else {
          this.setState({ reminders: [] })
        }
      })
    } else {
      this.setState({ reminders })
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.reminders != prevProps.reminders) {
      this.setState({ reminders: this.props.reminders })
    }
  }
  openCustomerProfile = () => {
    const { booking, isSalon } = this.props
    const employeeId = booking.services[0].employeeId._id

    if (isSalon) {
      this.setState({ isloading: true })

      getCustomerByUserId({ userId: booking.userId?._id }).then(({ data }) => {
        this.setState({ customer: data.data, showCustomerModal: true })
      })
    } else {
      this.setState({ isloading: true })
      getBookingReminder({ employeeId }).then(({ data }) => {
        this.setState({ isloading: false })
        if (data.success) {
          this.setState({
            employeeReminders: data.data ? data.data : [],
            showEmployeeReminders: true,
            employeeId,
          })
        } else {
          this.setState({ employeeReminders: [] })
        }
      })
    }
  }
  renderLoader = () => {
    const { isloading } = this.state
    return <DotsLoader isloading={isloading} />
  }
  handleUpdate = (reminder) => {
    this.setState({ showReminderModal: true, reminder })
  }
  handleDelete = (id, index) => {
    Swal.fire({
      title: i18n.t("are_you_sure_delete"),
      showCancelButton: true,
      cancelButtonText: i18n.t("no"),
      confirmButtonText: i18n.t("yes"),
    }).then(async (e) => {
      const { isConfirmed } = e
      if (isConfirmed) {
        const { reminders } = this.state
        this.setState({ isloading: true })
        deleteBookingReminder({ id })
          .then(({ data }) => {
            this.setState({ isloading: false })
            if (data.success) {
              reminders.splice(index, 1)
              this.setState({ reminders })
              if (this.props.reminder) {
                this.props.closeOnDelete()
              }
              SuccessHelper.handleSuccess(i18n.t("successfully_deleted"), true)
            } else {
              ErrorHelper.handleErrors(i18n.t("failed_to_delete"), true)
            }
          })
          .catch(() => {
            this.setState({ isloading: false })
            ErrorHelper.handleErrors("An Error Occured", true)
          })
      }
    })
  }

  renderExistingReminders = (reminders = []) => {
    const { hasEditPermission } = this.props
    return (
      <div className="w-100 notes">
        {reminders.map((reminder, index) => {
          return (
            <div
              className="row p-3 mt-3 note-container"
              key={reminder._id}
            >
              <p className="col-12 stamp text-dark">
                {i18n.t("created_by") + " "}
                {`${reminder.createdBy.firstName} ${reminder.createdBy.lastName} ${i18n.t("at")} `}
                {moment(reminder.createdDate).format("MM-DD-YYYY hh:mm a")}
              </p>
              <p className="col-12 stamp text-dark">
                {i18n.t("updated_by") + " "}
                {`${reminder.updatedBy.firstName} ${reminder.updatedBy.lastName} ${i18n.t("at")} `}
                {moment(reminder.updatedDate).format("MM-DD-YYYY hh:mm a")}
              </p>

              <div className="row mx-0 p-0 w-100 mt-3">
                <div className="col-6 pl-0 pr-3">
                  <div className="reminder-box">
                    <label style={{ minWidth: "100px" }}>{i18n.t("subject")}</label>
                    <TextField
                      id="subject"
                      label={i18n.t("subject")}
                      value={reminder.subject}
                      disabled
                      variant="outlined"
                    />
                  </div>
                </div>
                <div className="col-6 pr-0 pl-3">
                  <div className="reminder-box">
                    <label style={{ minWidth: "100px" }}>{i18n.t("due_date")}</label>
                    <TextField
                      id="dueDate"
                      label={i18n.t("due_date")}
                      value={moment(reminder.dueDate).format("MM-DD-YYYY")}
                      disabled
                      variant="outlined"
                    />
                  </div>
                </div>
                <div className="col-6 pl-0 pr-3">
                  <div className="reminder-box">
                    <label style={{ minWidth: "100px" }}>{i18n.t("reminder_on")}</label>
                    <TextField
                      id="reminderOn"
                      label={i18n.t("reminder_on")}
                      value={moment(reminder.reminderOn).format(
                        "MM-DD-YYYY hh:mm a"
                      )}
                      disabled
                      variant="outlined"
                    />
                  </div>
                </div>
                <div className="col-6 pr-0 pl-3">
                  <div className="reminder-box">
                    <label style={{ minWidth: "100px" }}>{i18n.t("priority")}</label>
                    <TextField
                      id="priority"
                      label={i18n.t("priority")}
                      value={reminder.priorityId.name}
                      disabled
                      variant="outlined"
                    />
                  </div>
                </div>
                <div className="col-12 p-0">
                  <div className="reminder-box">
                    <label style={{ minWidth: "100px" }}>{i18n.t("description")}</label>
                    <textarea
                      id="description"
                      label={i18n.t("description")}
                      className=" w-100 form-control"
                      value={reminder.description}
                      readOnly
                      rows="5"
                    ></textarea>
                  </div>
                </div>
              </div>
              {hasEditPermission && (
                <div className="row mx-0 mt-3 text-right d-block w-100">
                  <SecondaryButton
                    className="w-98 mr-2"
                    style={{ fontSize: 12 }}
                    onClick={() => this.handleDelete(reminder._id, index)}
                  >
                    {i18n.t("delete")}
                  </SecondaryButton>
                  <PrimaryButton
                    className="w-98"
                    onClick={() => this.handleUpdate(reminder)}
                  >
                    {i18n.t("update")}
                  </PrimaryButton>
                </div>
              )}
            </div>
          )
        })}
      </div>
    )
  }
  render() {
    const {
      showReminderModal,
      reminders,
      reminder,
      showCustomerModal,
      employeeReminders,
      employeeId,
      showEmployeeReminders,
      customer,
    } = this.state
    const {
      booking,
      companyCustomerId,
      isSalon,
      reminders: propsReminders,
      reminder: propsReminder,
    } = this.props
    return (
      <div>
        {this.renderLoader()}
        {showCustomerModal ? (
          <CustomerEdit
            open={showCustomerModal}
            setOpen={() => this.setState({ showCustomerModal: false })}
            active={customer}
            handleEdit={() => { }}
            setAccountBalance={() => { }}
            openTab={i18n.t("tasks_and_reminders")}
            isloading={() => {
              this.setState({ isloading: false })
            }}
          />
        ) : null}
        {showEmployeeReminders ? (
          <BaseModal
            open={showEmployeeReminders}
            containerClassName="large-container"
            {...{
              title: i18n.t("my_reminders"),
              content: (
                <div className="row mx-0 p-0">
                  {employeeId || booking?.status <= 2 ? (
                    <PrimaryButton
                      className="w-128"
                      onClick={() => this.setState({ showReminderModal: true })}
                      label={i18n.t("create_reminder")}
                    />
                  ) : null}

                  {this.renderExistingReminders(employeeReminders)}
                </div>
              ),
            }}
            onClose={() => this.setState({ showEmployeeReminders: false })}
          />
        ) : null}
        {showReminderModal ? (
          <ReminderModal
            reminder={reminder}
            booking={employeeReminders ? null : booking}
            companyCustomerId={companyCustomerId}
            _employeeId={employeeId}
            successCallback={(data) => {
              if (reminder) {
                const updatedReminders = reminders.map((reminder) => {
                  if (reminder._id === data._id) reminder = data
                  return reminder
                })
                this.setState(
                  employeeReminders
                    ? { employeeReminders: updatedReminders }
                    : { reminders: updatedReminders }
                )
              } else {
                this.setState(
                  employeeReminders
                    ? { employeeReminders: [data, ...reminders] }
                    : { reminders: [data, ...reminders] }
                )
              }
            }}
            handleClose={() => {
              this.setState({ showReminderModal: false, reminder: null })
            }}
          />
        ) : null}
        <div className="row mx-0 p-0 mb-4">
          {!propsReminder && (!booking || booking?.status <= 2) ? (
            <PrimaryButton
              className="w-128"
              onClick={() => this.setState({ showReminderModal: true })}
              label={i18n.t("create_reminder")}
            />
          ) : null}
          {booking?.status > 2 ? (
            <PrimaryButton
              style={{ textTransfom: "none" }}
              onClick={() => {
                this.openCustomerProfile()
              }}
              label={
                isSalon
                  ? i18n.t("view_customer_reminders")
                  : i18n.t("view_my_reminders")
              }
            />
          ) : null}

          {this.renderExistingReminders(reminders || propsReminders)}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

const action = {}

export default connect(mapStateToProps, action)(BookingReminders)
