import { connect } from "react-redux"
import React, { useEffect, useState } from "react"
import i18n from "../../../i18n"
import {
  updateRequest as updateReceptionistRequest,
  getRequest as getReceptionistAccess,
} from "../../../redux/actions/ReceptionistAccess"
import {
  updateRequest as updateManagerRequest,
  getRequest as getManagerAccess,
} from "../../../redux/actions/ManagerAccess"
import {
  updateRequest as updateOwnerRequest,
  getRequest as getOwnerAccess,
} from "../../../redux/actions/OwnerAccess"
import {
  updateRequest as updateInstructorRequest,
  getRequest as getInstructorAccess,
} from "../../../redux/actions/InstructorAccess"
import PrimaryButton from "../../../components/Core/PrimaryButton"
import { DotsLoader } from "../../../components"
import { ErrorHelper, SuccessHelper } from "../../../helpers"
import AppBar from "@material-ui/core/AppBar"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import TabPanel from "../../../components/TabPanel/TabPanel.component"
import Checkbox from "@material-ui/core/Checkbox"
import MenuItem from "@material-ui/core/MenuItem"
import "./styles.css"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

const validResources = [
  { Dashboard: [] },
  { Profile: [], owner: true },
  {
    MyBusiness: [
      { any: "WorkingHours" },
      { any: "UploadMedia" },
      { any: "SocialLinks" },
      { any: "SiteSetUp" },
      { any: "RefundPolicy" },
      { owner: "OnlinePayments" },
      { any: "BlackListSettings" },
      { any: "LiveDisplaySettings" },
      { owner: "Security" },
      { any: "CommunicationSettings" },
      { owner: "TimePeriod" },
    ],
  },
  {
    employees: [
      { any: "EmployeeList" },
      { any: "AddEmployee" },
      { any: "FundRequests" },
    ],
  },
  {
    MyAppointments: [{ any: "Booking" }, { any: "WaitingList" }],
  },
  {
    Products_and_Services: [
      { any: "Inventory" },
      { any: "AddInvetory" },
      { any: "AllServices" },
      { any: "AddService" },
      { any: "Packages" },
      { any: "GiftCards" },
      { owner: "Subscriptions" },
      { any: "Orders" },
    ],
  },
  { Program: [] },
  {
    marketing: [
      { owner: "Promotion" },
      { owner: "DiscountCode" },
      { owner: "Marketing" },
    ],
    owner: true,
  },
  {
    purchase: [{ any: "Vendor" }, { any: "Expense" }],
  },
  {
    Customers: [
      { any: "Customers" },
      { any: "AddCustomer" },
      { any: "ImportCustomer" },
    ],
  },
  {
    ai_booking: [
      { any: "AiScheduleTemplate" },
      { any: "AiSchedule" },
      { any: "AiScheduleApproval" },
      { any: "AiSettings" },
    ],
  },
  {
    Activities: [
      { any: "Activities" },
      { any: "ActivityCategories" },
      { any: "ActivityScheduleTemplate" },
      { any: "ActivitiesSchedule" },
      { any: "ActivityCategoryReport" },
      { any: "ActivityAttendanceReport" },
      { any: "ActivityReport" },
    ],
  },
  { Form: [] },
  {
    Reports: [
      { any: "BookingReport" },
      { any: "EmployeeReport" },
      { any: "TaxReport" },
      { any: "SalesReport" },
      { any: "PayrollReport" },
      { any: "ExpenseReport" },
      { any: "ProductReport" },
      { any: "PackageReport" },
      { any: "GiftCardReport" },
      { any: "CommissionReport" },
      { owner: "NoShowReport" },
    ],
  },
  { Rating: [{ any: "Rating" }, { any: "RatingForm" }] },
  { Wallet: [] },
  {
    Billing: [
      { owner: "PricingPlans" },
      { owner: "BillingHistory" },
      { owner: "AccountSettings" },
      { owner: "DevicesAndEquipment" },
    ],
    owner: true,
  },
  {
    taxes: [{ any: "TaxList" }, { any: "AddTax" }],
  },
]
const employeeValidResources = [
  { EmployeeDashboard: [] },
  { EmployeeProfile: [] },
  {
    MyAppointments: [
      { any: "EmployeeMyAppointment" },
      { any: "EmployeeBookingHistory" },
    ],
  },
  { EmployeeWorkingHours: [] },
  { EmployeeRating: [] },
  { EmployeeActiveSaloon: [] },
  { EmployeeAllCustomers: [] },
  { EmployeeSocialMedia: [] },
  { EmployeeUploadMedia: [] },
  { EmployeeActivitiesSchedule: [] },
  { EmployeeWallet: [] },
  { EmployeeRequestFunds: [] },
]
const Roles = ({
  updateReceptionistRequest,
  updateManagerRequest,
  updateOwnerRequest,
  updateInstructorRequest,
  loading: propsLoading,
  receptionistAccess,
  managerAccess,
  ownerAccess,
  instructorAccess,
  getReceptionistAccess,
  getManagerAccess,
  getOwnerAccess,
  getInstructorAccess,
}) => {
  const [loading, setLoading] = useState(false)
  const [localManagerAccess, setLocalManagerAccess] = useState({})
  const [localReceptionistAccess, setLocalReceptionistAccess] = useState({})
  const [localOwnerAccess, setLocalOwnerAccess] = useState({})
  const [localInstructorAccess, setLocalInstructorAccess] = useState({})
  const [expandedPanel, setExpandedPanel] = useState(false)

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : false)
  }

  const selectAll = (subMenus, resourceMap) => {
    let selected = true
    subMenus.map((subMenu) => {
      if (!Boolean(resourceMap?.[Object.values(subMenu)[0]])) {
        selected = false
      }
    })
    return selected
  }
  useEffect(() => {
    if (!receptionistAccess?.resourceMap) {
      getReceptionistAccess({})
    }
    if (!managerAccess?.resourceMap) {
      getManagerAccess({})
    }
    if (!ownerAccess?.resourceMap) {
      getOwnerAccess({})
    }
    if (!instructorAccess?.resourceMap) {
      getInstructorAccess({})
    }
  }, [])
  useEffect(() => {
    const { _id, resourceMap = {} } = managerAccess
    setLocalManagerAccess({ _id, resourceMap })
  }, [managerAccess])
  useEffect(() => {
    const { _id, resourceMap = {} } = receptionistAccess
    setLocalReceptionistAccess({ _id, resourceMap })
  }, [receptionistAccess])
  useEffect(() => {
    const { _id, resourceMap = {} } = ownerAccess
    setLocalOwnerAccess({ _id, resourceMap })
  }, [ownerAccess])
  useEffect(() => {
    const { _id, resourceMap = {} } = instructorAccess
    setLocalInstructorAccess({ _id, resourceMap })
  }, [instructorAccess])
  const onSave = (data, action) => {
    action(
      {
        ...data,
      },
      {
        success: () => {
          SuccessHelper.handleSuccess(i18n.t("successfully_updated"), true)
        },
        failure: () => {
          ErrorHelper.handleErrors(i18n.t("failed_to_update"), true)
        },
      }
    )
  }
  const onSaveManager = () => {
    const { _id, resourceMap = {} } = localManagerAccess
    onSave({ _id, resourceMap }, updateManagerRequest)
  }
  const onSaveReceptionist = () => {
    const { _id, resourceMap = {} } = localReceptionistAccess
    onSave({ _id, resourceMap }, updateReceptionistRequest)
  }
  const onSaveOwner = () => {
    const { _id, resourceMap = {} } = localOwnerAccess
    onSave({ _id, resourceMap }, updateOwnerRequest)
  }
  const onSaveInstructor = () => {
    const { _id, resourceMap = {} } = localInstructorAccess
    onSave({ _id, resourceMap }, updateInstructorRequest)
  }
  const [tab, setTab] = React.useState(0)
  const handleTabChange = (event, newValue) => {
    setExpandedPanel(false)
    setTab(newValue)
  }
  const togglePermissionSelection = (role, permission, selectAll) => {
    if (role == "manager") {
      const copy = { ...(localManagerAccess.resourceMap || {}) }
      if (typeof permission == "string") {
        copy[permission] = !copy[permission]
      } else {
        permission.map((submenus) => {
          copy[Object.values(submenus)[0]] = selectAll
        })
      }

      setLocalManagerAccess({ ...localManagerAccess, resourceMap: copy })
    } else if (role == "owner") {
      const copy = { ...(localOwnerAccess.resourceMap || {}) }
      if (typeof permission == "string") {
        copy[permission] = !copy[permission]
      } else {
        permission.map((submenus) => {
          copy[Object.values(submenus)[0]] = selectAll
        })
      }
      setLocalOwnerAccess({ ...localOwnerAccess, resourceMap: copy })
    } else if (role == "instructor") {
      const copy = { ...(localInstructorAccess.resourceMap || {}) }
      if (typeof permission == "string") {
        copy[permission] = !copy[permission]
      } else {
        permission.map((submenus) => {
          copy[Object.values(submenus)[0]] = selectAll
        })
      }
      setLocalInstructorAccess({ ...localInstructorAccess, resourceMap: copy })
    } else {
      const copy = { ...(localReceptionistAccess.resourceMap || {}) }
      if (typeof permission == "string") {
        copy[permission] = !copy[permission]
      } else {
        permission.map((submenus) => {
          copy[Object.values(submenus)[0]] = selectAll
        })
      }
      setLocalReceptionistAccess({
        ...localReceptionistAccess,
        resourceMap: copy,
      })
    }
  }
  return (
    <div className="content-container p-4  intake-categories-table">
      <DotsLoader isloading={loading || propsLoading} />
      <h1 className="saloon-dashboard-heading mb-4">
        {i18n.t("container.roles")}
      </h1>
      <AppBar position="static" className="custom-tab">
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          value={tab}
          onChange={handleTabChange}
          aria-label="simple tabs example"
        >
          <Tab label={i18n.t("manager")} {...a11yProps(0)} />
          <Tab label={i18n.t("receptionist")} {...a11yProps(1)} />
          <Tab label={i18n.t("owner")} {...a11yProps(2)} />
          <Tab label={i18n.t("instructor")} {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <TabPanel value={tab} index={0}>
        <div className="role-container mt-5">
          {validResources.map((value) => {
            if (!Object.values(value)[1]) {
              return (
                <Accordion
                  expanded={
                    Object.values(value)[0].length > 0
                      ? expandedPanel === Object.keys(value)[0]
                      : false
                  }
                  onChange={handleAccordionChange(Object.keys(value)[0])}
                >
                  <AccordionSummary
                    expandIcon={
                      Object.values(value)[0].length > 0 && <ExpandMoreIcon />
                    }
                    aria-label="Expand"
                    aria-controls="additional-actions3-content"
                    id="additional-actions3-header"
                  >
                    <FormControlLabel
                      aria-label="Acknowledge"
                      onClick={(event) => {
                        event.stopPropagation()
                      }}
                      onFocus={(event) => event.stopPropagation()}
                      control={
                        <Checkbox
                          checked={
                            !Object.values(value)[0].length > 0
                              ? Boolean(
                                  localManagerAccess?.resourceMap?.[
                                    Object.keys(value)[0]
                                  ]
                                )
                              : selectAll(
                                  Object.values(value)[0]?.filter(
                                    (x) => Object.keys(x)[0] != "owner"
                                  ),
                                  localManagerAccess?.resourceMap
                                )
                          }
                          onChange={(e, checked) =>
                            !Object.values(value)[0].length > 0
                              ? togglePermissionSelection(
                                  "manager",
                                  Object.keys(value)[0]
                                )
                              : togglePermissionSelection(
                                  "manager",
                                  Object.values(value)[0]?.filter(
                                    (x) => Object.keys(x)[0] != "owner"
                                  ),
                                  checked
                                )
                          }
                        />
                      }
                      label={
                        Object.keys(value)[0] == "Dashboard"
                          ? i18n.t("ViewSaloonDashboard")
                          : i18n.t(Object.keys(value)[0])
                      }
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    {Object.values(value)[0]?.map((subMenu, index) => {
                      if (Object.keys(subMenu)[0] != "owner") {
                        return (
                          <MenuItem
                            key={Object.values(subMenu)[0]}
                            onClick={() =>
                              togglePermissionSelection(
                                "manager",
                                Object.values(subMenu)[0]
                              )
                            }
                            disableGutters
                          >
                            <div className="row mx-0 px-0"></div>
                            <Checkbox
                              className={Object.keys(value)[0]}
                              checked={Boolean(
                                localManagerAccess?.resourceMap?.[
                                  Object.values(subMenu)[0]
                                ]
                              )}
                              onChange={() =>
                                togglePermissionSelection(
                                  "manager",
                                  Object.values(subMenu)[0]
                                )
                              }
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                            {i18n.t(Object.values(subMenu)[0])}
                          </MenuItem>
                        )
                      }
                    })}
                  </AccordionDetails>
                </Accordion>
              )
            }
          })}
        </div>
        <div className="my-5">
          <PrimaryButton
            className="w-128"
            label={i18n.t("save")}
            onClick={onSaveManager}
          />
        </div>
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <div className="role-container mt-5">
          {validResources.map((value) => {
            if (!Object.values(value)[1]) {
              return (
                <Accordion
                  expanded={
                    Object.values(value)[0].length > 0
                      ? expandedPanel === Object.keys(value)[0]
                      : false
                  }
                  onChange={handleAccordionChange(Object.keys(value)[0])}
                >
                  <AccordionSummary
                    expandIcon={
                      Object.values(value)[0].length > 0 && <ExpandMoreIcon />
                    }
                    aria-label="Expand"
                    aria-controls="additional-actions3-content"
                    id="additional-actions3-header"
                  >
                    <FormControlLabel
                      aria-label="Acknowledge"
                      onClick={(event) => {
                        event.stopPropagation()
                      }}
                      onFocus={(event) => event.stopPropagation()}
                      control={
                        <Checkbox
                          checked={
                            !Object.values(value)[0].length > 0
                              ? Boolean(
                                  localReceptionistAccess?.resourceMap?.[
                                    Object.keys(value)[0]
                                  ]
                                )
                              : selectAll(
                                  Object.values(value)[0]?.filter(
                                    (x) => Object.keys(x)[0] != "owner"
                                  ),
                                  localReceptionistAccess?.resourceMap
                                )
                          }
                          onChange={(e, checked) =>
                            !Object.values(value)[0].length > 0
                              ? togglePermissionSelection(
                                  "receptionist",
                                  Object.keys(value)[0]
                                )
                              : togglePermissionSelection(
                                  "receptionist",
                                  Object.values(value)[0]?.filter(
                                    (x) => Object.keys(x)[0] != "owner"
                                  ),
                                  checked
                                )
                          }
                        />
                      }
                      label={
                        Object.keys(value)[0] == "Dashboard"
                          ? i18n.t("ViewSaloonDashboard")
                          : i18n.t(Object.keys(value)[0])
                      }
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    {Object.values(value)[0]?.map((subMenu, index) => {
                      if (Object.keys(subMenu)[0] != "owner") {
                        return (
                          <MenuItem
                            key={Object.values(subMenu)[0]}
                            onClick={() =>
                              togglePermissionSelection(
                                "receptionist",
                                Object.values(subMenu)[0]
                              )
                            }
                            disableGutters
                          >
                            <div className="row mx-0 px-0"></div>
                            <Checkbox
                              checked={Boolean(
                                localReceptionistAccess?.resourceMap?.[
                                  Object.values(subMenu)[0]
                                ]
                              )}
                              onChange={() =>
                                togglePermissionSelection(
                                  "receptionist",
                                  Object.values(subMenu)[0]
                                )
                              }
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                            {i18n.t(Object.values(subMenu)[0])}
                          </MenuItem>
                        )
                      }
                    })}
                  </AccordionDetails>
                </Accordion>
              )
            }
          })}
        </div>
        <div className="my-5">
          <PrimaryButton
            className="w-128"
            label={i18n.t("save")}
            onClick={onSaveReceptionist}
          />
        </div>
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <div className="role-container mt-5">
          {validResources.map((value) => {
            return (
              <Accordion
                expanded={
                  Object.values(value)[0].length > 0
                    ? expandedPanel === Object.keys(value)[0]
                    : false
                }
                onChange={handleAccordionChange(Object.keys(value)[0])}
              >
                <AccordionSummary
                  expandIcon={
                    Object.values(value)[0].length > 0 && <ExpandMoreIcon />
                  }
                  aria-label="Expand"
                  aria-controls="additional-actions3-content"
                  id="additional-actions3-header"
                  onClick={(event) => {
                    event.stopPropagation()
                  }}
                >
                  <FormControlLabel
                    aria-label="Acknowledge"
                    onClick={(event) => {
                      event.stopPropagation()
                    }}
                    onFocus={(event) => event.stopPropagation()}
                    control={
                      <Checkbox
                        checked={
                          !Object.values(value)[0].length > 0
                            ? Boolean(
                                localOwnerAccess?.resourceMap?.[
                                  Object.keys(value)[0]
                                ]
                              )
                            : selectAll(
                                Object.values(value)[0],
                                localOwnerAccess?.resourceMap
                              )
                        }
                        onChange={(e, checked) =>
                          !Object.values(value)[0].length > 0
                            ? togglePermissionSelection(
                                "owner",
                                Object.keys(value)[0]
                              )
                            : togglePermissionSelection(
                                "owner",
                                Object.values(value)[0],
                                checked
                              )
                        }
                      />
                    }
                    label={i18n.t(Object.keys(value)[0])}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  {Object.values(value)[0]?.map((subMenu, index) => {
                    return (
                      <MenuItem
                        key={Object.values(subMenu)[0]}
                        onClick={() =>
                          togglePermissionSelection(
                            "owner",
                            Object.values(subMenu)[0]
                          )
                        }
                        disableGutters
                      >
                        <div className="row mx-0 px-0"></div>
                        <Checkbox
                          className={value.menu}
                          checked={Boolean(
                            localOwnerAccess?.resourceMap?.[
                              Object.values(subMenu)[0]
                            ]
                          )}
                          onChange={() =>
                            togglePermissionSelection(
                              "owner",
                              Object.values(subMenu)[0]
                            )
                          }
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                        {i18n.t(Object.values(subMenu)[0])}
                      </MenuItem>
                    )
                  })}
                </AccordionDetails>
              </Accordion>
            )
          })}
        </div>
        <div className="my-5">
          <PrimaryButton
            className="w-128"
            label={i18n.t("save")}
            onClick={onSaveOwner}
          />
        </div>
      </TabPanel>
      <TabPanel value={tab} index={3}>
        <div className="role-container">
          {employeeValidResources.map((value) => {
            return (
              <Accordion
                expanded={
                  Object.values(value)[0].length > 0
                    ? expandedPanel === Object.keys(value)[0]
                    : false
                }
                onChange={handleAccordionChange(Object.keys(value)[0])}
              >
                <AccordionSummary
                  expandIcon={
                    Object.values(value)[0].length > 0 && <ExpandMoreIcon />
                  }
                  aria-label="Expand"
                  aria-controls="additional-actions3-content"
                  id="additional-actions3-header"
                  onClick={(event) => {
                    event.stopPropagation()
                  }}
                >
                  <FormControlLabel
                    aria-label="Acknowledge"
                    onClick={(event) => {
                      event.stopPropagation()
                    }}
                    onFocus={(event) => event.stopPropagation()}
                    control={
                      <Checkbox
                        checked={
                          !Object.values(value)[0].length > 0
                            ? Boolean(
                                localInstructorAccess?.resourceMap?.[
                                  Object.keys(value)[0]
                                ]
                              )
                            : selectAll(
                                Object.values(value)[0],
                                localInstructorAccess?.resourceMap
                              )
                        }
                        onChange={(e, checked) =>
                          !Object.values(value)[0].length > 0
                            ? togglePermissionSelection(
                                "instructor",
                                Object.keys(value)[0]
                              )
                            : togglePermissionSelection(
                                "instructor",
                                Object.values(value)[0],
                                checked
                              )
                        }
                      />
                    }
                    label={i18n.t(Object.keys(value)[0])}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  {Object.values(value)[0]?.map((subMenu, index) => {
                    return (
                      <MenuItem
                        key={Object.values(subMenu)[0]}
                        onClick={() =>
                          togglePermissionSelection(
                            "instructor",
                            Object.values(subMenu)[0]
                          )
                        }
                        className={`${
                          index == 0 && "light-border-t"
                        } light-border-x light-border-b `}
                        disableGutters
                      >
                        <div className="row mx-0 px-0"></div>
                        <Checkbox
                          className={value.menu}
                          checked={Boolean(
                            localInstructorAccess?.resourceMap?.[
                              Object.values(subMenu)[0]
                            ]
                          )}
                          onChange={() =>
                            togglePermissionSelection(
                              "instructor",
                              Object.values(subMenu)[0]
                            )
                          }
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                        {i18n.t(Object.values(subMenu)[0])}
                      </MenuItem>
                    )
                  })}
                </AccordionDetails>
              </Accordion>
            )
          })}
        </div>
        <div className="my-3">
          <PrimaryButton
            className="w-128"
            label={i18n.t("save")}
            onClick={onSaveInstructor}
          />
        </div>
      </TabPanel>
    </div>
  )
}

const mapStateToProps = (state) => ({
  loading:
    state.receptionistAccess.isUpdating ||
    state.managerAccess.isUpdating ||
    state.ownerAccess.isUpdating ||
    state.instructorAccess.isUpdating,
  receptionistAccess: state.receptionistAccess.data,
  managerAccess: state.managerAccess.data,
  ownerAccess: state.ownerAccess.data,
  instructorAccess: state.instructorAccess.data,
})

const action = {
  getReceptionistAccess,
  getManagerAccess,
  getOwnerAccess,
  getInstructorAccess,
  updateReceptionistRequest,
  updateManagerRequest,
  updateOwnerRequest,
  updateInstructorRequest,
}

export default connect(mapStateToProps, action)(Roles)
