import { connect } from "react-redux";
import React from "react";
import moment from "moment";

import i18n from "../../i18n";
import { getNestedValue } from "../../util/objectMethods";
import { getUserPackagesRequest } from "../../redux/actions/Packages";
import PrimaryButton from "../Core/PrimaryButton";
import CollapsibleTable from "../CollapsibleTable";
import { request as getAvailableTimeslots } from "../../redux/actions/GetAvailableTimeslots.js";
import useBooking from "../../context/Booking";
import { getEmployeesBySaloonAndService } from "../../config/simpleApiCalls";

moment.locale("en");

const UserPackages = ({ userPackages = [], isSalon, setOpen }) => {
  const {
    onChange,
    data,
    categoryList,
    serviceList,
    allSaloonMap,
    setCurrentOpen,
    setEmployeeList,
    setAddOpen,
  } = useBooking();
  const { employeeId, selectSalon } = data;

  const onSelect = async (val, row) => {
    const service = val.serviceId;
    const selectedCategory = categoryList.find(
      (element) => element._id == service.serviceId
    );
    if (isSalon) {
      onChange({
        selectSalon,
        selectedCategory,
        selectServices: service,
        userPackageId: row._id,
        selectEmployee: null,
        newDate: "",
        selectedTime: undefined,
        availableTimeslots: {},
        selectDateAndTime: undefined,
        selectedTime: undefined,
      });
      const res = await getEmployeesBySaloonAndService({
        companyId: row.companyId,
        serviceId: service._id,
      });
      setEmployeeList(
        res.data.data.map((employee) => ({
          ...employee,
          name: `${employee.employeeId.userId.firstName} ${employee.employeeId.userId.lastName}`,
        }))
      );
      setCurrentOpen("employees");
      setOpen(false);
    } else {
      const selectSalon = allSaloonMap[row.companyId];
      onChange({
        selectSalon,
        selectedCategory,
        selectServices: service,
        userPackageId: row._id,
        selectEmployee: null,
        newDate: "",
        selectedTime: undefined,
        availableTimeslots: {},
        selectDateAndTime: undefined,
        selectedTime: undefined,
      });
      getAvailableTimeslots({
        data: {
          companyId: row.companyId,
          companyServiceId: service._id,
          companyEmployeeId: employeeId,
        },
        resolve: () => {
          setOpen(false);
        },
      });
      setCurrentOpen("dateTime");
    }
    setAddOpen(true);
  };
  return (
    <div className="my-5">
      <CollapsibleTable
        rows={userPackages}
        childKey="services"
        schema={[
          {
            key: "package",
            accessor: (val) => getNestedValue(val, ["packageId", "name"], ""),
          },
          {
            key: "numberOfSessions",
            accessor: (val) =>
              val.numberOfSessions - val.sessionBooked - val.sessionCompleted,
          },
          {
            key: "expiryDate",
            accessor: (val) =>
              val.expiryDate
                ? moment(val.expiryDate).format("MM-DD-YYYY hh:mm A")
                : "",
          },
        ]}
        childSchema={{
          columns: [
            {
              key: "name",
              accessor: (val) => getNestedValue(val, ["serviceId", "name"], ""),
            },
            {
              key: "action",
              header: "",
              render: (val, row) => {
                const available =
                  row.numberOfSessions -
                  row.sessionBooked -
                  row.sessionCompleted;
                if (available > 0) {
                  return (
                    <PrimaryButton
                      className="w-98"
                      label={i18n.t("redeem")}
                      onClick={() => {
                        onSelect(val, row);
                      }}
                    />
                  );
                }
              },
            },
          ],
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  userPackages: state.packages.userPackages,
});

const action = { getUserPackagesRequest };

export default connect(mapStateToProps, action)(UserPackages);
