import { TextField } from "@material-ui/core"
import React from "react"
import { useEffect } from "react"
import ReactDatePicker from "react-datepicker"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import PrimaryButton from "../../../../../components/Core/PrimaryButton"
import statusActions from "../../../../../redux/actions/Saloon/CustomerStatus"
import Select from "../../../../../components/Core/Select"
import i18n from "../../../../../i18n"
import "./ActivityCategoryReportFilters.css"
import useScreenSize from "../../../../../context/ScreenSize"
export default function ActivityCategoryReportFilters({ filters, setFilters }) {
  const dispatch = useDispatch()
  const categories = useSelector((state) => state.activityCategory.data)
  const { screenSize } = useScreenSize()
  useEffect(() => {
    dispatch(statusActions.getRequest())
  }, [])

  return (
    <div className="row ActivityCategoryReportFilters mb-4">
      <div className="d-flex flex-column flex-md-row  align-items-md-center col-sm-4 mb-3 pl-0 pr-0 pr-sm-3">
        <label className="font-weight-bolder mr-3">
          {i18n.t("container.categories")}
        </label>
        <div
          style={
            screenSize === "mobile"
              ? { width: "100%" }
              : { width: "calc(100% - 180px)" }
          }
        >
          <Select
            label={i18n.t("category")}
            options={categories.map((element) => ({
              value: element._id,
              name: element.name,
            }))}
            onChange={(e) => {
              if (!e.target.value) {
                delete filters.categoryId
                return setFilters({ ...filters })
              }
              setFilters({ ...filters, categoryId: e.target.value })
            }}
            variant="outlined"
          />
        </div>
        <label className="font-weight-bolder mx-3">
          {i18n.t("date_range")}
        </label>
      </div>
      <div className="d-flex align-items-center col-sm-4 pl-0">
        <div className="row negativeMargins">
          <div className="col-6 pr-1">
            <ReactDatePicker
              selected={filters.startDate}
              dateFormat="MM/dd/yyyy"
              onChange={(val) => {
                setFilters({ ...filters, startDate: val })
              }}
              customInput={
                <TextField
                  label={i18n.t("start_date")}
                  value={filters.startDate}
                  className="full-width"
                  readOnly={true}
                  variant="outlined"
                />
              }
            />
          </div>
          <div className="col-6 pl-1">
            <ReactDatePicker
              selected={filters.endDate}
              dateFormat="MM/dd/yyyy"
              onChange={(val) => {
                setFilters({ ...filters, endDate: val })
              }}
              customInput={
                <TextField
                  label={i18n.t("end_date")}
                  value={filters.endDate}
                  className="full-width"
                  readOnly={true}
                  variant="outlined"
                />
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}
