import initFirebase from "../services/firebase";
import firebase from "firebase/app";
import { Capacitor } from "@capacitor/core";
import React, {
  createContext,
  ReactNode,
  useContext,
  useState,
  useEffect,
} from "react";
import { SuccessHelper } from "../helpers";
import { request as getNotifications } from "../redux/actions/GetNotifications";
import { useDispatch } from "react-redux";
import { PushNotifications } from "@capacitor/push-notifications";
import SharedStorage from "../helpers/Storage";

export const FirebaseContext = createContext({
  token: "",
  setToken: () => { },
});

export function FirebaseProvider({ children }) {
  const dispatch = useDispatch();
  const [token, setToken] = useState("");

  useEffect(() => {
    if (Capacitor.platform == "web") {
      if (firebase.messaging.isSupported()) {
        const { getToken, onMessageListener } = initFirebase();
        getToken((token) => {
          setToken(token);
          SharedStorage.setItem("messaging_token", token);
        });
        onMessageListener().then((message) => {
          dispatch(getNotifications());
          SuccessHelper.handleToastSuccess(message?.notification?.title, true);
        });
      }
    } else if (Capacitor.platform == "android") {
      const register = () => {
        PushNotifications.register();
        PushNotifications.addListener("registration", (token) => {
          setToken(token?.value);
          SharedStorage.setItem("messaging_token", token?.value);
        });

        PushNotifications.addListener(
          "pushNotificationReceived",
          (notification) => {
            console.log(notification);
          }
        );
      };
      PushNotifications.checkPermissions().then((res) => {
        if (res.receive !== "granted") {
          PushNotifications.requestPermissions().then((res) => {
            if (res.receive !== "denied") {
              register();
            }
          });
        } else {
          register();
        }
      });
    } else if (Capacitor.platform == "ios") {
    }
  }, []);

  return (
    <FirebaseContext.Provider value={{ token, setToken }}>
      {children}
    </FirebaseContext.Provider>
  );
}

//Hook
function useFirebase() {
  return useContext(FirebaseContext);
}

export default useFirebase;
