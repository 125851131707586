import React, { useEffect, useState } from "react"
import i18n from "../../../i18n"
import actions from "../../../redux/actions/Saloon/TimePeriod"
import PrimaryButton from "../../../components/Core/PrimaryButton"
import { DotsLoader } from "../../../components"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import TimePeriodUpsertPopup from "./Components/TimePeriodUpsertPopup"
import BasicTable from "../../../components/Core/BasicTable"
import moment from "moment"
import EditIcon from "../../../components/Core/BasicTable/Actions/EditIcon"
import DeleteIcon from "../../../components/Core/BasicTable/Actions/DeleteIcon"
import { ErrorHelper, SuccessHelper } from "../../../helpers"
import deleteConfirmation from "../../../helpers/DeleteHelper"
import useScreenSize from "../../../context/ScreenSize"
const { getRequest, removeRequest } = actions

const TimePeriod = () => {
  const dispatch = useDispatch()
  const [upsertPopupOpen, setUpsertPopupOpen] = useState(false)
  const [active, setActive] = useState(null)
  const loading = useSelector((s) => s.timePeriod.isFetching)
  const timePeriods = useSelector((s) => s.timePeriod.data)
  const { screenSize } = useScreenSize()
  useEffect(() => {
    dispatch(getRequest())
  }, [])

  const onEdit = (val) => {
    setUpsertPopupOpen(true)
    setActive(val)
  }

  useEffect(() => {
    if (!upsertPopupOpen) {
      setActive(null)
    }
  }, [upsertPopupOpen])

  const handleDelete = (id) => {
    dispatch(
      removeRequest(
        { id },
        {
          success: () => {
            SuccessHelper.handleSuccess(i18n.t("successfully_deleted"), true)
          },
          failure: () => {
            ErrorHelper.handleErrors(i18n.t("failed_to_delete"), true)
          },
        }
      )
    )
  }

  return (
    <div className="content-container p-4">
      <DotsLoader isloading={loading} />
      <div className="d-flex pb-4 justify-content-between align-items-center flex-wrap gap-2">
        <h1 className="saloon-dashboard-heading">{i18n.t("time_periods")}</h1>
        <PrimaryButton
          className="w-128"
          label={i18n.t("create")}
          onClick={() => setUpsertPopupOpen(true)}
        />
      </div>
      <div className="table-responsive">
        <BasicTable
          rows={timePeriods || []}
          schema={[
            {
              header: i18n.t("name"),
              field: "name",
              style: screenSize === "mobile" ? { width: 130 } : {},
            },
            {
              header: i18n.t("start_date"),
              field: "startDate",
              accessor: ({ startDate }) => {
                return moment(startDate).format("MM-DD-YYYY HH:mm")
              },
              style: screenSize === "mobile" ? { width: 130 } : {},
            },
            {
              header: i18n.t("end_date"),
              field: "endDate",
              accessor: ({ endDate }) => {
                return moment(endDate).format("MM-DD-YYYY HH:mm")
              },
              style: screenSize === "mobile" ? { width: 130 } : {},
            },
            {
              key: "action",
              header: i18n.t("actions"),
              style: { width: 160 },
              render: (val) => {
                return (
                  <div className="row">
                    <EditIcon onClick={() => onEdit(val)} />
                    <DeleteIcon
                      onClick={() =>
                        deleteConfirmation(() => handleDelete(val._id))
                      }
                    />
                  </div>
                )
              },
            },
          ]}
        />
      </div>
      <TimePeriodUpsertPopup
        open={upsertPopupOpen}
        setOpen={setUpsertPopupOpen}
        active={active}
      />
    </div>
  )
}

export default TimePeriod
