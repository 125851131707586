import { ToastContainer, toast } from "react-toastify"

class Toastr {
  success(msg) {
    toast.success(msg, {
      position: "top-right",
      autoClose: 3000,
      closeOnClick: true,
      pauseOnHover: true,
    })
  }
  error(msg) {
    toast.error(msg, {
      position: "top-right",
      autoClose: 3000,
      closeOnClick: true,
      pauseOnHover: true,
    })
  }
}

export default new Toastr()
