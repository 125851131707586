// @flow

import { REOCCURING_COMPANY_UNAVAILABILITIES } from "./ActionTypes"

export function getRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: REOCCURING_COMPANY_UNAVAILABILITIES.GET_SAGA,
  }
}

export function get(data) {
  return {
    data,
    type: REOCCURING_COMPANY_UNAVAILABILITIES.GET,
  }
}

export function removeRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: REOCCURING_COMPANY_UNAVAILABILITIES.DELETE_SAGA,
  }
}

export function remove(data) {
  return {
    data,
    type: REOCCURING_COMPANY_UNAVAILABILITIES.DELETE,
  }
}

export function insertRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: REOCCURING_COMPANY_UNAVAILABILITIES.INSERT_SAGA,
  }
}

export function insert(data) {
  return {
    data,
    type: REOCCURING_COMPANY_UNAVAILABILITIES.INSERT,
  }
}

export function requestFailure() {
  return {
    type: REOCCURING_COMPANY_UNAVAILABILITIES.REQUEST_FAILURE,
  }
}
