// @flow

import { STUDENT } from "../ActionTypes"

export function getRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: STUDENT.GET_SAGA,
  }
}

export function get(data) {
  return {
    data,
    type: STUDENT.GET,
  }
}

export function removeRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: STUDENT.DELETE_SAGA,
  }
}

export function remove(data) {
  return {
    data,
    type: STUDENT.DELETE,
  }
}

export function upsertRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: STUDENT.UPSERT_SAGA,
  }
}

export function upsert(data) {
  return {
    data,
    type: STUDENT.UPSERT,
  }
}

export function setGradeRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: STUDENT.SET_GRADE_SAGA,
  }
}

export function setGrade(data) {
  return {
    data,
    type: STUDENT.SET_GRADE,
  }
}

export function setFinalizedRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: STUDENT.SET_FINALIZED_SAGA,
  }
}

export function setFinalized(data) {
  return {
    data,
    type: STUDENT.SET_FINALIZED,
  }
}

export function requestFailure() {
  return {
    type: STUDENT.REQUEST_FAILURE,
  }
}
