import React, { Component } from "react"
import { Translation } from "react-i18next"
import i18n from "../../../../i18n"
import {
  resetPassword,
  upsertCheckinSettings,
} from "../../../../config/simpleApiCalls"

import { DotsLoader } from "../../../../components"
import { SuccessHelper, ErrorHelper } from "../../../../helpers"

import "./styles.css"
import CustomInput from "../../../../components/Core/TextField/customInput"
import PrimaryButton from "../../../../components/Core/PrimaryButton"
import SharedStorage from "../../../../helpers/Storage"
class LinkSocialMedia extends Component {
  constructor(props) {
    super(props)
    this.state = {
      facebookLink: "",
      instagramLink: "",
      twitterLink: "",
      youtubeLink: "",
      snapchatLink: "",
      linkedinLink: "",
      // facebookLink: 'https://www.facebook.com/test-user-91',
      // instagramLink: 'https://www.instagram.com/test-user-91',
      // twitterLink: 'https://twitter.com/test-user-91',
      // youtubeLink: 'https://www.youtube.com/test-user-91',
      // snapchatLink: 'https://www.snapchat.com/test-user-91',
      // linkedinLink: 'https://www.linkedin.com/test-user-91',
      hasLink: false,
      companyId: "",
      isloading: false,
      formErrors: {},
    }
  }

  componentDidMount = async () => {
    const companyData = await JSON.parse(await SharedStorage.getItem("company"))
    this.setState({
      companyId: companyData?._id,
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.createSaloonSocialMedia) {
      console.log(
        nextProps.createSaloonSocialMedia,
        "nextProps.createSaloonSocialMedia"
      )
      if (
        !nextProps.createSaloonSocialMedia.failure &&
        !nextProps.createSaloonSocialMedia.isFetching &&
        !nextProps.createSaloonSocialMedia.errorMessage &&
        nextProps.createSaloonSocialMedia.data &&
        nextProps.createSaloonSocialMedia.data.data &&
        nextProps.createSaloonSocialMedia.data.success
      ) {
        this.getAllSocialMedia()
        SuccessHelper.handleSuccess("You have successfully added.", true)
      } else if (
        nextProps.createSaloonSocialMedia.failure &&
        !nextProps.createSaloonSocialMedia.isFetching &&
        nextProps.createSaloonSocialMedia.errorMessage
      ) {
        this.setState({ isloading: false })
      }
    }
  }

  savePin = () => {
    const { newPin, companyId } = this.state
    const pinRegex = /^[0-9]{4,6}$}/g
    console.log(newPin)
    if (!newPin) {
      this.setState({
        formErrors: {
          newPin: <Translation>{(t) => t("pin_required")}</Translation>,
        },
      })
      setTimeout(() => {
        this.setState({
          formErrors: {
            newPin: "",
          },
        })
      }, 6000)
    } else if (newPin.match(pinRegex)) {
      this.setState({
        formErrors: {
          newPin: <Translation>{(t) => t("pin_length")}</Translation>,
        },
      })
      setTimeout(() => {
        this.setState({
          formErrors: {
            newPin: "",
          },
        })
      }, 6000)
    } else {
      this.setState({ isloading: true })

      const payload = {
        companyId,
        pin: newPin,
      }

      upsertCheckinSettings(payload)
        .then((res) => {
          console.log("res: ", res)
          if (res.data.success) {
            this.setState({ isloading: false, confirmNewPin: "", newPin: "" })

            SuccessHelper.handleSuccess(i18n.t("successfully_updated"), true)
          }
        })
        .catch((err) => {
          this.setState({ isloading: false })

          ErrorHelper.handleErrors("Something Went Wrong", true)
        })

      this.setState({ isloading: false })
    }
  }

  savePassword = async () => {
    const { newPassword, confirmNewPassword } = this.state
    if (!newPassword) {
      this.setState({
        formErrors: {
          newPassword: (
            <Translation>{(t) => t("password_required")}</Translation>
          ),
        },
      })
      setTimeout(() => {
        this.setState({
          formErrors: {
            newPassword: "",
          },
        })
      }, 6000)
    } else if (newPassword.length < 6) {
      this.setState({
        formErrors: {
          newPassword: (
            <Translation>{(t) => t("password_min_length")}</Translation>
          ),
        },
      })
      setTimeout(() => {
        this.setState({
          formErrors: {
            newPassword: "",
          },
        })
      }, 6000)
    } else if (!confirmNewPassword) {
      this.setState({
        formErrors: {
          confirmNewPassword: (
            <Translation>{(t) => t("confirm_password_required")}</Translation>
          ),
        },
      })
      setTimeout(() => {
        this.setState({
          formErrors: {
            confirmNewPassword: "",
          },
        })
      }, 6000)
    } else if (confirmNewPassword !== newPassword) {
      this.setState({
        formErrors: {
          confirmNewPassword: (
            <Translation>{(t) => t("confirm_password_not_match")}</Translation>
          ),
        },
      })
      setTimeout(() => {
        this.setState({
          formErrors: {
            confirmNewPassword: "",
          },
        })
      }, 6000)
    } else {
      this.setState({ isloading: true })

      const payload = {
        userId: JSON.parse(await SharedStorage.getItem("saloon"))._id,
        password: newPassword,
      }

      resetPassword(payload)
        .then((res) => {
          console.log("res: ", res)
          if (res.data.success) {
            this.setState({
              isloading: false,
              confirmNewPassword: "",
              newPassword: "",
            })

            SuccessHelper.handleSuccess(res.data.msg, true)
          }
        })
        .catch((err) => {
          this.setState({ isloading: false })

          ErrorHelper.handleErrors("Something Went Wrong", true)
        })

      this.setState({ isloading: false })
    }
  }

  handleNavigation = (route) => {
    this.props.history.push(route)
  }

  onChange = (field, text) => this.setState({ [field]: text.target.value })

  renderLoader = () => {
    const { isloading } = this.state
    return <DotsLoader isloading={isloading} />
  }

  renderSaloonDashboardHeading = () => {
    return (
      <h1 className="saloon-dashboard-heading">
        {i18n.t("container.security")}
      </h1>
    )
  }

  renderPinSection() {
    return (
      <>
        <div className="col-12 px-0 mt-5">
          <p id="social-media-text" className="mb-3">
            {i18n.t("pin_code")}
          </p>
        </div>
        <CustomInput
          type="numeric"
          label={i18n.t("new_pin_code")}
          value={this.state["newPin"]}
          onChange={(text) => this.onChange("newPin", text)}
        />
        <div className="mt-5 d-flex justify-content-center justify-content-md-start">
          <PrimaryButton
            className="w-128"
            label={i18n.t("save")}
            onClick={() => this.savePin()}
          />
        </div>
      </>
    )
  }
  renderPasswordSection() {
    return (
      <>
        <div className="col-12 px-0">
          <p id="social-media-text" className="mb-3 mt-5">
            {i18n.t("password")}
          </p>
        </div>
        <CustomInput
          type="password"
          label={i18n.t("new_password")}
          value={this.state["newPassword"]}
          onChange={(text) => this.onChange("newPassword", text)}
        />
        <CustomInput
          type="password"
          label={i18n.t("confirm_password")}
          value={this.state["confirmNewPassword"]}
          onChange={(text) => this.onChange("confirmNewPassword", text)}
        />
        <div className="mt-5">
          <PrimaryButton
            className="w-128"
            label={i18n.t("save")}
            onClick={() => this.savePassword()}
          />
        </div>
      </>
    )
  }
  renderSocialLinkCard = () => {
    return (
      <>
        <div className="col-12 px-0 bg-white" style={{ borderRadius: "1rem" }}>
          {this.renderPasswordSection()}
        </div>
        <div
          className="col-12 px-0 bg-white"
          style={{ borderRadius: "1rem", marginTop: "1rem" }}
        >
          {this.renderPinSection()}
        </div>
      </>
    )
  }

  render() {
    return (
      <div>
        {this.renderLoader()}
        <div className="content-container">
          <div className="p-4">
            {this.renderSaloonDashboardHeading()}
            {this.renderSocialLinkCard()}
          </div>
        </div>
      </div>
    )
  }
}

export default LinkSocialMedia
