import React, { Component } from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";

import "./styles.css";

class DotsLoader extends Component {
  static propTypes = {
    isloading: PropTypes.bool,
  };

  static defaultProps = {
    isloading: false,
  };

  render() {
    const { isloading } = this.props;
    return (
      <div className="loader" style={{ display: isloading ? "flex" : "none" }}>
        <CircularProgress
          className="spinner-loader"
          size={130}
          thickness={4.2}
        />
      </div>
    );
  }
}

export default DotsLoader;
