import { put, takeEvery } from "redux-saga/effects"

import * as types from "../actions/ActionTypes"
import {
  getById,
  get,
  remove,
  submitFilledForm,
  markAsViewed,
  requestFailure,
  getByCustomer,
} from "../actions/FormAnswer"

import axios from "axios"
import { formAnswer } from "../../config/WebServices"

function* getByIdSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.get(`${formAnswer}/getById`, {
      params: payload,
    })
    data = data.data

    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data)
      }
      yield put(getById(data.data))
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data)
      }
      yield put(requestFailure(data))
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* getSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.get(formAnswer, {
      params: payload,
    })
    data = data.data

    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data)
      }
      yield put(get(data.data))
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data)
      }
      yield put(requestFailure(data))
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* getByCustomerSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.get(`${formAnswer}/getByCustomer`, {
      params: payload,
    })
    data = data.data

    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data)
      }
      yield put(getByCustomer(data.data))
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data)
      }
      yield put(requestFailure(data))
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* submitFilledFormSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.post(`${formAnswer}/submitFilledForm`, payload)
    data = data.data

    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data)
      }
      yield put(submitFilledForm(data.data))
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data)
      }
      yield put(requestFailure(data))
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}

function* markAsViewedSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.post(`${formAnswer}/markAsViewed`, payload)
    data = data.data

    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data)
      }
      yield put(markAsViewed(data.data))
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data)
      }
      yield put(requestFailure(data))
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}
function* removeSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.delete(formAnswer, {
      params: payload,
    })
    data = data.data
    if (data.success) {

      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data)
      }
      yield put(remove(data.data))
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data)
      }
      yield put(requestFailure(data))
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data)
    }
    yield put(requestFailure(data))
  }
}



export default function* root() {
  yield takeEvery(types.FORM_ANSWER.GET_BY_ID_SAGA, getByIdSaga)
  yield takeEvery(types.FORM_ANSWER.GET_SAGA, getSaga)
  yield takeEvery(types.FORM_ANSWER.GET_BY_CUSTOMER_SAGA, getByCustomerSaga)
  yield takeEvery(
    types.FORM_ANSWER.SUBMIT_FILLED_FORM_SAGA,
    submitFilledFormSaga
  )
  yield takeEvery(types.FORM_ANSWER.DELETE_SAGA, removeSaga)
  yield takeEvery(types.FORM_ANSWER.MARK_AS_VIEWED_SAGA, markAsViewedSaga)
}
