import { connect } from "react-redux";
import React, { useEffect, useMemo, useState } from "react";
import FormTable from "../../../components/Saloon/Form/FormTable";
import FilledFormTable from "../../../components/Saloon/Form/FilledFormTable";
import FormInsert from "../../../components/Saloon/Form/FormInsert";
import i18n from "../../../i18n";
import {
  getRequest,
  insertRequest,
  removeRequest,
} from "../../../redux/actions/Saloon/FormBuilder";
import { getRequest as getFilledFromsRequest } from "../../../redux/actions/FormAnswer";
import PrimaryButton from "../../../components/Core/PrimaryButton";
import { BaseModal, DotsLoader } from "../../../components";
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import FormGenerator from "../../../components/FormBuilder/Components/FormGenerator.component";
import _ from "lodash";
import { TextField } from "@material-ui/core";
import SharedStorage from "../../../helpers/Storage";
import Broswer from "../../../helpers/Browser";
import deleteConfirmation from "../../../helpers/DeleteHelper";

const { REACT_APP_WEB_APP_URL: webAppUrl } = process.env;
const Form = ({
  forms = [],
  getRequest,
  insertRequest,
  removeRequest,
  loading,
  history,
  getFilledFromsRequest,
  filledForms = [],
}) => {
  const [companyId, setCompanyId] = useState("");
  const [addOpen, setAddOpen] = useState(false);
  const [previewForm, setPreviewForm] = useState(false);
  const [fieldsToRender, setFieldsToRender] = useState({});
  const [formTitle, setFormTitle] = useState("");
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false)

  const onMount = async () => {
    const company = JSON.parse(await SharedStorage.getItem("company"));
    setCompanyId(company._id);
    getRequest({
      companyId: company._id,
    });
    getFilledFromsRequest({});
  };
  useEffect(() => {
    onMount();
  }, []);
  const onAdd = (data) => {
    insertRequest(
      { ...data, companyId },
      {
        success: ({ data }) => {
          history.push(`/saloon-form-builder/${data._id}`);
          SuccessHelper.handleSuccess(i18n.t("add_succesfully"), true);
          setAddOpen(false);
        },
        failure: () => {
          ErrorHelper.handleErrors(i18n.t("failed_to_add"), true);
        },
      }
    );
  };
  const onDelete = (form) => {
    removeRequest({ _id: form._id });
    SuccessHelper.handleSuccess(i18n.t("successfully_deleted"), true);
  };
  const onEdit = (form) => {
    history.push(`/saloon-form-builder/${form._id}`);
  };
  const handleView = (form) => {
    Broswer.open(`${webAppUrl}/form-fill/${form._id}`, "_blank");
  };

  const handlePreview = (input) => {
    let sortedListToPreview = _.sortBy(input.fieldsToRender, ["displayOrder"]);

    setFieldsToRender(sortedListToPreview);
    setPreviewForm(!previewForm);
    setFormTitle(input.formTitle);
  };

  const filteredForms = useMemo(() => {
    if (!search) {
      return forms;
    }
    return forms.filter((form) =>
      form.formTitle.toLowerCase().includes(search.toLowerCase())
    );
  }, [forms, search]);
  const filteredFilledForms = useMemo(() => {
    if (!search) {
      return filledForms;
    }
    return filledForms.filter(
      (form) =>
        form.formId.formTitle.toLowerCase().includes(search.toLowerCase()) ||
        (form.userId?.firstName || "")
          .toLowerCase()
          .includes(search.toLowerCase())
    );
  }, [filledForms, search]);
  return (
    <div className="content-container p-4">
      <DotsLoader isloading={loading} />
      <div className="row mx-0 justify-content-between">
        <h1 className="saloon-dashboard-heading pb-4">{i18n.t("forms")}</h1>
        <PrimaryButton
          className="w-128"
          label={i18n.t("create")}
          onClick={() => setAddOpen(true)}
        />
      </div>
      <div className="d-sm-flex align-items-center col-12 px-0 mb-3">
        <label className="font-weight-bolder mr-3 mb-3">{i18n.t("search")}</label>
        <TextField
          type="text"
          placeholder={i18n.t("Search")}
          onChange={(e) => setSearch(e.target.value)}
          variant={"outlined"}
          className="mobile-w-100"
          style={{ width: 400, marginBottom: '1rem' }}
        />
        <PrimaryButton onClick={() => setSearch("")} className="ml-sm-3 mb-3" style={{ minWidth: "fit-content" }}>
          {i18n.t("search_reset")}
        </PrimaryButton>
      </div>
      <FormTable
        handlePreview={handlePreview}
        data={filteredForms}
        handleDelete={(form) => deleteConfirmation(() => onDelete(form))
        }
        handleEdit={onEdit}
      />
      <div className="mt-5">
        <DotsLoader isloading={isLoading} />
        <FilledFormTable data={filteredFilledForms} handleView={handleView} setLoading={setIsLoading} />
      </div>
      {addOpen && (
        <FormInsert
          open={addOpen}
          onClose={() => setAddOpen(false)}
          onSave={onAdd}
        />
      )}
      {previewForm && (
        <BaseModal
          open={previewForm}
          onClose={() => {
            setPreviewForm();
          }}
          {...{
            title: formTitle,
            content: (
              <FormGenerator
                fieldsToRender={fieldsToRender}
                preview={previewForm}
                readonly={true}
              />
            ),
          }}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  forms: state.formBuilder.data,
  filledForms: state.formAnswer.data,
  loading: state.formBuilder.isFetching,
});

const action = {
  getRequest,
  insertRequest,
  removeRequest,
  getFilledFromsRequest,
};

export default connect(mapStateToProps, action)(Form);
