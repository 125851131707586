import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react"
import { useSelector } from "react-redux"
import { DotsLoader } from "../components"
import CreateAppointment from "../components/Partner/CreateAppointment/CreateAppointment"
import useActiveMenuType from "./ActiveMenuType"
import useCache from "./LocalCache"
import SharedStorage from "../helpers/Storage"

export const BookingContext = createContext({
  addOpen: false,
  setAddOpen: () => { },
  lockFields: false,
  setLockFields: () => { },

  data: {},
  onChange: () => { },
  categoryList: [],
  serviceList: [],
  setServiceList: () => { },
  allSaloonMap: {},
  setAllSaloonMap: () => { },
  currentOpen: "category",
  setCurrentOpen: () => { },
  employeeList: [],
  setEmployeeList: () => { },
  functionRole: "",
  setFunctionRole: () => { },
  loading: false,
  setLoading: () => { },
  selectBooking: null,
  setSelectBooking: () => { },
})

export function BookingProvider({
  open: addOpen,
  setOpen: setAddOpen,
  setIntakeOpen,
  children,
}) {
  const { getResource } = useCache()
  const [lockFields, setLockFields] = useState(false)
  const [employeeData, setEmployeeData] = useState(null)
  const [data, setData] = useState({})
  const [categoryList, setCategoryList] = useState([])
  const [serviceList, setServiceList] = useState([])
  const [allSaloonMap, setAllSaloonMap] = useState({})
  const [currentOpen, setCurrentOpen] = useState("category")
  const [employeeList, setEmployeeList] = useState([])
  const [functionRole, setFunctionRole] = useState("")
  const [loading, setLoading] = useState(false)
  const [selectBooking, setSelectBooking] = useState(null)
  const { type } = useActiveMenuType()
  const isSalon = useMemo(() => {
    return type && type != "employee"
  }, [type])

  const categories = getResource((cache) => cache.company.categories)
  useEffect(() => {
    if (type != "employee") {
      setCategoryList(categories)
    }
  }, [type, addOpen, categories])
  const onChange = (newData) => {
    setData({ ...data, ...newData })
  }

  const saloonData = useSelector(
    (s) => s.user.data.saloon && JSON.parse(s.user.data.saloon)
  )
  const onTypeChange = async () => {
    setEmployeeData(JSON.parse(await SharedStorage.getItem("employee")));
  };
  useEffect(() => {
    onTypeChange();
  }, [type]);
  useEffect(() => {
    if (!addOpen) {
      setData({})
    }
  }, [addOpen])

  const clearAppointmentDetails = () => {
    setLockFields(false)
    setData({
      ...data,
      selectedCategory: null,
      newDate: null,
      selectServices: null,
      selectEmployee: null,
      selectDateAndTime: null,
      newTime: null,
      formattedDate: null,
      recurringBookingDates: [],
      totalAmount: null,
      notes: null,
      depositAmount: null,
      startTime: null,
      endTime: null,
      adjustedTime: 0,
      userPackageId: null,
    })
  }

  return (
    <BookingContext.Provider
      value={{
        addOpen,
        setAddOpen,
        lockFields,
        setLockFields,
        clearAppointmentDetails,
        data,
        setData,
        onChange,
        categoryList,
        setCategoryList,
        serviceList,
        setServiceList,
        allSaloonMap,
        setAllSaloonMap,
        currentOpen,
        setCurrentOpen,
        employeeList,
        setEmployeeList,
        functionRole,
        setFunctionRole,
        loading,
        setLoading,
        selectBooking,
        setSelectBooking
      }}
    >
      <DotsLoader isloading={loading} />
      {type && (
        <CreateAppointment
          companyId={saloonData?.companyId}
          isSalon={isSalon}
        />
      )}
      {children}
    </BookingContext.Provider>
  )
}

//Hook
function useBooking() {
  return useContext(BookingContext)
}

export default useBooking
