import React from "react"
import Box from "@material-ui/core/Box"
import Collapse from "@material-ui/core/Collapse"
import IconButton from "@material-ui/core/IconButton"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Typography from "@material-ui/core/Typography"
import Paper from "@material-ui/core/Paper"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import i18n from "../../i18n"
import useScreenSize from "../../context/ScreenSize"
function Row(props) {
  const {
    row = {},
    schema,
    childSchema,
    childrenGetter,
    prependChildren,
    childKey,
    index,
    style = {},
  } = props
  const [open, setOpen] = React.useState(false)
  const children = childrenGetter ? childrenGetter(row) : row[childKey]
  return (
    <React.Fragment>
      <TableRow style={style}>
        <TableCell style={{ width: 50 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {schema.map((element) => {
          const { key, accessor = (v) => v[key], render, style } = element
          if (render) {
            return <TableCell style={style}>{render(row, index)}</TableCell>
          } else {
            return <TableCell>{accessor(row, index)}</TableCell>
          }
        })}
      </TableRow>
      {children && open ? (
        <TableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={schema.length + 1}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                {prependChildren ? prependChildren(row) : null}
                <Typography variant="h6" gutterBottom component="div">
                  {childSchema.title}
                </Typography>
                <Table
                  size="small"
                  aria-label="purchases"
                  className={`${childSchema.className} table`}
                >
                  <TableHead>
                    <TableRow>
                      {childSchema.columns.map((element) => {
                        const { header, key, style } = element
                        return (
                          <TableCell style={style}>
                            {i18n.t(
                              header && typeof header != "string" ? header : key
                            )}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {children.map((item, index) => (
                      <TableRow
                        key={index}
                        style={{
                          backgroundColor: index % 2 == 0 && "#F4F6F8",
                          borderTop: "1px solid #E5EBF1",
                          borderBottom:
                            children.length - 1 == index && "1px solid #E5EBF1",
                        }}
                      >
                        {childSchema.columns.map((element) => {
                          const {
                            key,
                            accessor = (v) => v[key],
                            render,
                            style,
                          } = element
                          if (render) {
                            return (
                              <TableCell style={style}>
                                {render(item, row)}
                              </TableCell>
                            )
                          } else {
                            return (
                              <TableCell style={style}>
                                {accessor(item, row)}
                              </TableCell>
                            )
                          }
                        })}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      ) : null}
    </React.Fragment>
  )
}

export default function CollapsibleTable({
  rows,
  schema = [],
  childSchema = {},
  childrenGetter,
  childKey = "children",
  prependChildren,
  id,
  maxContentWidth = true,
}) {
  const { screenSize } = useScreenSize()
  const tableStyle =
    screenSize === "mobile" && maxContentWidth ? { width: "max-content" } : {}
  return (
    <TableContainer style={tableStyle} component={Paper}>
      <Table
        id={`employee-${id}`}
        aria-label="collapsible table"
        className="table table-borderless"
      >
        <TableHead>
          <TableRow>
            <TableCell />
            {schema.map((element) => {
              const { header, key } = element
              return (
                <TableCell>
                  {i18n.t(header && typeof header != "string" ? header : key)}
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => {
            return (
              <Row
                key={`table-row-${index}-`}
                row={row}
                style={{
                  backgroundColor: index % 2 == 0 && "#F4F6F8",
                  borderTop: "1px solid #E5EBF1",
                  borderBottom: rows.length - 1 == index && "1px solid #E5EBF1",
                }}
                index={index}
                schema={schema}
                childSchema={childSchema}
                childKey={childKey}
                childrenGetter={childrenGetter}
                prependChildren={prependChildren}
              />
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
