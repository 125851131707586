import { connect } from "react-redux"
import React, { Component } from "react"
import MyRoutes from "./routes"
import { ErrorHelper, SuccessHelper } from "./helpers"
import { request as get_notifications } from "./redux/actions/GetNotifications"
import { set } from "./redux/actions/User"
import "./i18n"
import "./App.css"
import { ActiveMenuTypeProvider } from "./context/ActiveMenuType"
import { FirebaseProvider } from "./context/Firebase"
import i18n from "./i18n"
import { ValidatorForm } from "react-material-ui-form-validator"
import SharedStorage from "./helpers/Storage"
import { ScreenSizeProvider } from "./context/ScreenSize"
import { LocalCacheProvider } from "./context/LocalCache"
import { GoogleCalendarProvider } from "./context/GoogleCalendar"
import { OutlookCalendarProvider } from "./context/OutlookCalendar"
import AppUpdateAnnouncement from "./components/Partner/AppUpdateAnnouncement/AppUpdateAnnouncement"
class App extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  async componentDidMount() {
    i18n.changeLanguage(localStorage.getItem("language") == "en" ? "en" : "fr")
    if (window.api) {
      window.api.on("printer-error", () =>
        ErrorHelper.handleToastError(i18n.t("printer_error"), true)
      )
    }
    const [user, employee, saloon, company, access_token, impersonationId] =
      await Promise.all([
        SharedStorage.getItem("user"),
        SharedStorage.getItem("employee"),
        SharedStorage.getItem("saloon"),
        SharedStorage.getItem("company"),
        SharedStorage.getItem("access_token"),
        SharedStorage.getItem("impersonationId"),
      ])

    this.props.set({
      user,
      employee,
      saloon,
      company,
      access_token,
      impersonationId,
    })
    setTimeout(() => {
      this.props.get_notifications()
      this.setState({ isInitialized: true })
    }, 0)
    ValidatorForm.addValidationRule("hasArrayElements", (value) => {
      if (value && !value.length) {
        return false
      }
      return true
    })
  }

  componentWillUnmount() {
    ValidatorForm.removeValidationRule("hasArrayElements")
  }

  render() {
    const { isInitialized } = this.state

    return (
      <ScreenSizeProvider>
        <FirebaseProvider>
          <ActiveMenuTypeProvider>
            <AppUpdateAnnouncement>
              <LocalCacheProvider>
                <GoogleCalendarProvider>
                  <OutlookCalendarProvider>
                    {isInitialized ? <MyRoutes /> : null}
                  </OutlookCalendarProvider>
                </GoogleCalendarProvider>
              </LocalCacheProvider>
            </AppUpdateAnnouncement>
          </ActiveMenuTypeProvider>
        </FirebaseProvider>
      </ScreenSizeProvider>
    )
  }
}

const mapStateToProps = (state) => ({})

const action = { get_notifications, set }

export default connect(mapStateToProps, action)(App)
