// @flow

import { SALOON_USER_ID, SALOON_LOGOUT } from "../ActionTypes"

export function request(payload) {
  return {
    payload,
    type: SALOON_USER_ID.REQUEST,
  }
}

export function success(data) {
  return {
    data,
    type: SALOON_USER_ID.SUCCESS,
  }
}

export function failure(errorMessage) {
  return {
    errorMessage,
    type: SALOON_USER_ID.FAILURE,
  }
}

export function logout() {
  return {
    type: SALOON_LOGOUT,
  }
}
