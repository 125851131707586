import React, {
  createContext,
  ReactNode,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react"
import { useSelector } from "react-redux"
import { ROLES } from "../util/enum"
import SharedStorage from "../helpers/Storage"
import {
  getEmployeeDetails,
  getCompanyThatEmployeeAdd,
} from "../config/simpleApiCalls"
export const ActiveMenuTypeContext = createContext({
  type: "",
  setType: () => {},
  functionRole: "",
  setFunctionRole: () => {},
})

export function ActiveMenuTypeProvider({ children }) {
  const [type, setType] = useState("")
  const [functionRole, setFunctionRole] = useState("")
  const [isInstructor, setIsInstructor] = useState(false)
  const [hideEmployeeProfile, setHideEmployeeProfile] = useState(false)
  const [accountAccess, setAccountAccess] = useState(true)

  const onMount = async () => {
    const company = JSON.parse(await SharedStorage.getItem("company"))
    setFunctionRole(company?.function)
  }
  useEffect(async () => {
    onMount()
    getEmployeeDetails({
      user: JSON.parse(await SharedStorage.getItem("employee")),
    }).then(({ data }) => {
      setHideEmployeeProfile(data.data.companyEmployee?.hideEmployeeProfile)
    })
    getCompanyThatEmployeeAdd().then(({ data }) => {
      setAccountAccess(data.data[0]?.companyId.accountAccess)
    })
  }, [])

  const saloonData = useSelector((s) => s.user.data.saloon)
  const parsedSaloonDate = useMemo(() => {
    if (saloonData) {
      return JSON.parse(saloonData)
    }
  }, [saloonData])
  const employeeData = useSelector((s) => s.user.data.employee)
  const parsedEmployeeData = useMemo(() => {
    if (employeeData) {
      return JSON.parse(employeeData)
    }
  }, [employeeData])
  useEffect(async () => {
    let type = ""
    let hasSaloonAccess =
      parsedSaloonDate &&
      !!Object.keys(parsedSaloonDate).length &&
      parsedSaloonDate.role.includes(ROLES.OWNER)
    if (parsedEmployeeData) {
      const hasEmployeeAccess = !!Object.keys(parsedEmployeeData).length
      let hasReceptionAccess = parsedEmployeeData.role.includes(
        ROLES.RECEPTIONIST
      )
      let hasManagerAccess = parsedEmployeeData.role.includes(ROLES.MANAGER)
      let hasInstructorAccess = parsedEmployeeData.role.includes(
        ROLES.INSTRUCTOR
      )

      if (hasInstructorAccess) {
        type = "employee"
      }
      if (hasEmployeeAccess) {
        type = "employee"
      }
      if (hasReceptionAccess) {
        type = "receptionist"
      }
      if (hasManagerAccess) {
        type = "manager"
      }
    }
    if (hasSaloonAccess) {
      type = "salon"
    }
    const menuType = await SharedStorage.getItem("menu_type")
    if (menuType) {
      type = menuType === "instructor" ? "employee" : menuType
    }
    setType(accountAccess === false ? "employee" : type)
  }, [parsedSaloonDate, parsedEmployeeData])

  return (
    <ActiveMenuTypeContext.Provider
      value={{
        type,
        setType,
        isInstructor,
        setIsInstructor,
        hideEmployeeProfile,
        setHideEmployeeProfile,
        functionRole,
        setFunctionRole,
        setAccountAccess,
        accountAccess,
      }}
    >
      {children}
    </ActiveMenuTypeContext.Provider>
  )
}

//Hook
function useActiveMenuType() {
  return useContext(ActiveMenuTypeContext)
}

export default useActiveMenuType
