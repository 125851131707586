import React from "react";
import TextField from "@material-ui/core/TextField";

export default function DetailsField({ handleOnChange, readonly, details }) {
  return (
    <TextField
      onChange={(e) => handleOnChange(e, undefined, "details")}
      value={details}
      type="text"
      variant="outlined"
      inputProps={{ readOnly: readonly }}
      label="Additional Details"
      style={{ marginTop: ".8em" }}
    />
  );
}
