import React, { useEffect, useRef, useState } from "react"

import i18n from "../../../i18n"
import Select from "../../Core/Select"
import CounterInput from "../../CounterInput"
import ToggleSelect from "../../ToggleSelect"
import Autocomplete from "@material-ui/lab/Autocomplete"
import TextField from "@material-ui/core/TextField"
import { Images } from "../../../theme"
import PrimaryButton from "../../Core/PrimaryButton"

const hoursOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
  (element) => ({ name: element, value: element })
)

export default ({
  defaultValues = {},
  timeSlotLength = 5,
  categories = [],
  products = [],
  formOptions = [],
  isGym,
  onSubmit = () => {},
}) => {
  let fileInput = useRef()
  const [name, setName] = useState("")
  const [hours, setHours] = useState()
  const [minutes, setMinutes] = useState()
  const [price, setPrice] = useState(0)
  const [category, setCategory] = useState("")
  const [subcategory, setSubcategory] = useState("")
  const [status, setStatus] = useState(1)
  const [shouldSendTextReminders, setShouldSendTextReminders] = useState(false)
  const [textReminderFrequency, setTextReminderFrequency] = useState(2)
  const [requireDeposit, setRequireDeposit] = useState(false)
  const [depositTarget, setDepositTarget] = useState("everyone")
  const [depositAmount, setDepositAmount] = useState(0)
  const [relatedProductIds, setRelatedProductIds] = useState([])
  const [forms, setForms] = useState([])
  const [imageFile, setImageFile] = useState()
  const [imageUrl, setImageUrl] = useState()
  const [description, setDescription] = useState("")
  const [hiddenToCustomers, setHiddenToCustomers] = useState(false)
  const [hidePrice, setHidePrice] = useState(false)

  useEffect(() => {
    console.log("defaultValues", defaultValues)
    setStatus(defaultValues.isActive)
    setName(defaultValues.name || "")
    setHours(defaultValues.hours)
    setMinutes(defaultValues.minutes)
    setPrice(defaultValues.price || 0)
    setCategory(defaultValues.category || "")
    setSubcategory(defaultValues.subcategory || "")
    setShouldSendTextReminders(defaultValues.shouldSendTextReminders || false)
    setTextReminderFrequency(defaultValues.textReminderFrequency || 2)
    setRequireDeposit(defaultValues.requireDeposit || false)
    setDepositTarget(defaultValues.depositTarget || "everyone")
    setDepositAmount(defaultValues.depositAmount || 0)
    setRelatedProductIds(defaultValues.relatedProductIds || [])
    setForms(defaultValues.forms || [])
    setImageFile(defaultValues.imageFile)
    setImageUrl(defaultValues.imageUrl)
    setDescription(defaultValues.description || "")
    setHiddenToCustomers(defaultValues.hiddenToCustomers || false)
    setHidePrice(defaultValues.hidePrice || false)
  }, [defaultValues])

  const frequencies = []
  for (let i = 2; i < 26; i++) {
    frequencies.push({
      name: i18n.t("container.every_x_weeks", { value: i }),
      value: i,
    })
  }
  const minuteOptions = []
  for (let i = 1; i < parseInt(60 / timeSlotLength); i++) {
    minuteOptions.push({ value: i * timeSlotLength, name: i * timeSlotLength })
  }
  const statuses = [
    { value: true, name: i18n.t("container.active") },
    { value: false, name: i18n.t("container.inactive") },
  ]

  const [formErrors, setFormErrors] = useState({})

  const validate = () => {
    const newFormErrors = {}
    if (!name) {
      newFormErrors.nameError = i18n.t("container.add_service_name_required")
    }
    if (!isGym && !hours && !minutes) {
      newFormErrors.minimumTimeError = i18n.t(
        "container.add_service_minimum_time_required"
      )
    }
    if (!category) {
      newFormErrors.categoryError = i18n.t(
        "container.add_service_select_category"
      )
    }
    if (requireDeposit == true && !depositAmount) {
      newFormErrors.depositAmountError = i18n.t("requireDepositError")
    }
    setFormErrors(newFormErrors)
    if (!Object.keys(newFormErrors).length) {
      let minimumTime = parseInt((hours || 0) * 60) + parseInt(minutes || "0")
      let payload = {
        name,
        price,
        category,
        status,
        minimumTime,
        imageFile,
        shouldSendTextReminders,
        textReminderFrequency,
        subcategory,
        relatedProductIds,
        description,
        depositAmount,
        requireDeposit,
        forms,
        depositTarget,
        hiddenToCustomers,
        hidePrice,
      }
      onSubmit(payload)
    } else {
      setTimeout(() => {
        setFormErrors({})
      }, 6000)
    }
  }
  return (
    <div className="container row mx-0 px-0">
      <div className="col-12 d-md-flex align-items-md-center text-center justify-content-center mb-5">
        <input
          type="file"
          style={{ display: "none" }}
          onChange={(event) => {
            const file = URL.createObjectURL(event.target.files[0])
            setImageUrl(file)
            setImageFile(event.target.files[0])
          }}
          ref={fileInput}
          accept="image/*"
        />
        <span
          onClick={() => fileInput.current.click()}
          style={{
            backgroundImage: `url(${
              imageUrl ? imageUrl : Images.saloon_upload_media_icon
            })`,
            backgroundSize: imageUrl ? "cover" : "24px",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
          id="saloon-edit-profile"
        />
      </div>
      <div className="col-md-6 px-0 pr-sm-3">
        <div className="d-sm-flex align-items-center mb-3">
          <label
            className="font-weight-bold mobile-w-100"
            style={{ minWidth: "160px" }}
          >
            {i18n.t("container.profile_service")}
          </label>
          <TextField
            field="name"
            value={name}
            label={i18n.t("container.profile_service")}
            variant="outlined"
            onChange={(e) => setName(e.target.value)}
            error={formErrors.nameError}
            className="w-100"
          />
        </div>
        <div className="d-sm-flex align-items-center mb-3">
          <label
            className="font-weight-bold mobile-w-100"
            style={{ minWidth: "160px" }}
          >
            {i18n.t("container.add_service_estimate_time")}
          </label>
          <div className="d-flex gap-1 w-100">
            <div className="w-50">
              <Select
                options={hoursOptions}
                value={hours}
                onChange={(e) => setHours(e.target.value)}
                error={formErrors.minimumTimeError}
                variant="outlined"
              />
            </div>
            <div className="w-50">
              <Select
                options={minuteOptions}
                value={minutes}
                onChange={(e) => setMinutes(e.target.value)}
                error={!!formErrors.minimumTimeError}
                variant="outlined"
              />
            </div>
          </div>
        </div>
        <div className="d-sm-flex align-items-center mb-3">
          <label
            className="font-weight-bold mobile-w-100"
            style={{ minWidth: "160px" }}
          >
            {i18n.t("starting_price")}
          </label>
          <CounterInput
            value={price}
            label={i18n.t("starting_price")}
            onChange={(e) => setPrice(e)}
          />
        </div>
        <div className="d-sm-flex align-items-center mb-3">
          <label
            className="font-weight-bold mobile-w-100"
            style={{ minWidth: "160px" }}
          >
            {i18n.t("container.categories")}
          </label>
          <Select
            options={categories}
            label={i18n.t("container.categories")}
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            error={formErrors.categoryError}
            variant="outlined"
          />
        </div>
        <div className="d-sm-flex align-items-center mb-3">
          <label
            className="font-weight-bold mobile-w-100"
            style={{ minWidth: "160px" }}
          >
            {i18n.t("subcategory")}
          </label>
          <TextField
            field="subcategory"
            value={subcategory}
            label={i18n.t("subcategory")}
            variant="outlined"
            onChange={(e) => setSubcategory(e.target.value)}
          />
        </div>
        <div className="d-sm-flex align-items-center mb-3">
          <label
            className="font-weight-bold mobile-w-100"
            style={{ minWidth: "160px" }}
          >
            {i18n.t("status")}
          </label>

          <ToggleSelect
            value={status === 1 ? true : false}
            onChange={(e) => setStatus(e ? 1 : 0)}
            active="Active"
            inactive="Inactive"
          />
        </div>
        <div className="d-sm-flex align-items-center mb-3">
          <label
            className="font-weight-bold mr-3"
            style={{ minWidth: "fit-content" }}
          >
            {i18n.t("container.services_send_text_reminders")}
          </label>
          <ToggleSelect
            value={shouldSendTextReminders}
            onChange={(e) => setShouldSendTextReminders(e)}
            active="Yes"
            inactive="No"
          />
        </div>
        <div className="d-sm-flex align-items-center mb-3">
          <label
            className="font-weight-bold mr-3"
            style={{ minWidth: "fit-content" }}
          >
            {i18n.t("text_reminder_frequency")}
          </label>
          <Select
            options={frequencies}
            label={i18n.t("text_reminder_frequency")}
            value={textReminderFrequency}
            onChange={(e) => setTextReminderFrequency(e.target.value)}
            variant="outlined"
          />
        </div>
      </div>
      <div className="col-md-6 px-0 pl-sm-3">
        <div className="d-sm-flex align-items-center mb-3">
          <label
            className="font-weight-bold mobile-w-100"
            style={{ minWidth: "160px" }}
          >
            {i18n.t("does_require_deposit")}
          </label>
          <ToggleSelect
            value={requireDeposit}
            onChange={(e) => setRequireDeposit(e)}
            active="Yes"
            inactive="No"
          />
        </div>
        <div className="d-sm-flex align-items-center mb-3">
          <label
            className="font-weight-bold mobile-w-100"
            style={{ minWidth: "160px" }}
          >
            {i18n.t("deposit_target")}
          </label>
          <ToggleSelect
            value={depositTarget === "everyone" ? true : false}
            onChange={(e) => setDepositTarget(e ? "everyone" : "watch_list")}
            active={i18n.t("watch_list")}
            inactive={i18n.t("everyone")}
          />
        </div>
        <div className="d-sm-flex align-items-center mb-3">
          <label
            className="font-weight-bold mobile-w-100"
            style={{ minWidth: "160px" }}
          >
            {i18n.t("deposit_amount")}
          </label>
          <CounterInput
            value={depositAmount}
            label={i18n.t("deposit_amount")}
            onChange={(e) => setDepositAmount(e)}
          />
        </div>
        <div className="d-sm-flex align-items-center mb-3">
          <label
            className="font-weight-bold mr-3"
            style={{ minWidth: "fit-content" }}
          >{`${i18n.t("related_products")} (${i18n.t("maximum_3")})`}</label>
          <Autocomplete
            multiple
            id="related-products-standard"
            options={products}
            getOptionLabel={(option) => option.text}
            value={relatedProductIds}
            onChange={(e, value) => {
              setRelatedProductIds(value)
            }}
            getOptionDisabled={(e) => relatedProductIds.length >= 3}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={`${i18n.t("related_products")} (${i18n.t("maximum_3")})`}
              />
            )}
            className="w-100"
          />
        </div>
        <div className="d-sm-flex align-items-center mb-3">
          <label
            className="font-weight-bold mobile-w-100"
            style={{ minWidth: "160px" }}
          >
            {i18n.t("select_form")}
          </label>
          <Autocomplete
            multiple
            id="forms-standard"
            options={formOptions}
            getOptionLabel={(option) => option.formTitle}
            value={forms}
            onChange={(e, value) => setForms(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={i18n.t("select_form")}
              />
            )}
            className="w-100"
          />
        </div>
        <div className="d-sm-flex align-items-center mb-3">
          <label
            className="font-weight-bold mobile-w-100"
            style={{ minWidth: "160px" }}
          >
            {i18n.t("description")}
          </label>
          <TextField
            value={description}
            label={i18n.t("description")}
            variant="outlined"
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div className="d-sm-flex align-items-center mb-3">
          <label
            className="font-weight-bold mobile-w-100"
            style={{ minWidth: "160px" }}
          >
            {i18n.t("hidden_to_customers")}
          </label>
          <ToggleSelect
            value={hiddenToCustomers}
            onChange={(e) => setHiddenToCustomers(e)}
            active="Yes"
            inactive="No"
          />
        </div>
        <div className="d-sm-flex align-items-center mb-3">
          <label
            className="font-weight-bold mobile-w-100"
            style={{ minWidth: "160px" }}
          >
            {i18n.t("hide_price")}
          </label>
          <ToggleSelect
            value={hidePrice}
            onChange={(e) => setHidePrice(e)}
            active="Yes"
            inactive="No"
          />
        </div>
      </div>
      <div className="mt-5 mb-4 col-12 px-0 text-sm-right">
        <PrimaryButton
          className="w-128"
          label={i18n.t("save")}
          onClick={validate}
        />
      </div>
    </div>
  )
}
