import React, { useEffect, useRef, useState } from "react"
import i18n from "../../../../../i18n"
import PrimaryButton from "../../../../Core/PrimaryButton"
import {
  ValidatorForm,
  SelectValidator,
} from "react-material-ui-form-validator"
import { BaseModal } from "../../../.."
import MenuItem from "@material-ui/core/MenuItem"
import { useDispatch, useSelector } from "react-redux"
import { ErrorHelper, SuccessHelper } from "../../../../../helpers"
import { collectOverdueRequest } from "../../../../../redux/actions/Saloon/CompanyCustomerSubscription"

export default function CustomerSubscriptionOverduePopup({
  open,
  setOpen,
  customer,
  active,
}) {
  const dispatch = useDispatch()
  const form = useRef()
  const [data, setData] = useState({})

  const statuses = useSelector((state) => state.customerStatus.data)

  useEffect(() => {
    if (open) {
      setData({})
    }
  }, [open])

  const handleCollect = () => {
    dispatch(
      collectOverdueRequest(
        {
          ...data,
          companyCustomerId: customer._id,
          _id: active._id,
        },
        {
          success() {
            SuccessHelper.handleSuccess(i18n.t("successfully_updated"), true)
            setOpen(false)
          },
          failure() {
            ErrorHelper.handleErrors(i18n.t("failed_to_update"), true)
          },
        }
      )
    )
  }

  return (
    <>
      <BaseModal
        title={i18n.t("collect_subscription")}
        content={
          <div className="row mx-0">
            <ValidatorForm
              onSubmit={handleCollect}
              ref={form}
              className="row col-12 mx-0"
              onError={(error) => {
                console.log(error)
              }}
            >
              <div className="col-12 px-0 mb-1">
                <SelectValidator
                  label={i18n.t("change_status_to")}
                  onChange={(e) =>
                    setData({ ...data, newStatusId: e.target.value })
                  }
                  value={data.newStatusId}
                  select
                >
                  {statuses.map((element) => {
                    return (
                      <MenuItem value={element._id} key={element._id}>
                        {element.name}
                      </MenuItem>
                    )
                  })}
                </SelectValidator>
              </div>

              <div className="row mx-0 px-0 justify-content-end mt-2 col-12">
                <PrimaryButton
                  props={{
                    type: "submit",
                  }}
                  label={i18n.t("save")}
                />
              </div>
            </ValidatorForm>
          </div>
        }
        onClose={() => setOpen(false)}
        open={open}
      />
    </>
  )
}
