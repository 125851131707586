// @flow

import { ALL_FUNDS_REQUEST } from "../ActionTypes"
export function getRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: ALL_FUNDS_REQUEST.GET_SAGA,
  }
}

export function get(data) {
  return {
    data,
    type: ALL_FUNDS_REQUEST.GET,
  }
}

export function insertRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: ALL_FUNDS_REQUEST.INSERT_SAGA,
  }
}

export function insert(data) {
  return {
    data,
    type: ALL_FUNDS_REQUEST.INSERT,
  }
}

export function updateRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: ALL_FUNDS_REQUEST.UPDATE_SAGA,
  }
}

export function update(data) {
  return {
    data,
    type: ALL_FUNDS_REQUEST.UPDATE,
  }
}

export function getEmployeeAccountDetailsRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: ALL_FUNDS_REQUEST.EMPLOYEE_ACCOUNT_DETAILS_SAGA,
  }
}

export function getEmployeeAccountDetails(data) {
  return {
    data,
    type: ALL_FUNDS_REQUEST.EMPLOYEE_ACCOUNT_DETAILS,
  }
}

export function requestFailure() {
  return {
    type: ALL_FUNDS_REQUEST.REQUEST_FAILURE,
  }
}
