import { TextField } from "@material-ui/core"
import React from "react"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

export default function Datepicker({
  placeholderText,
  selected,
  onChange,
  error,
  helperText,
  minDate,
  wrapperClassName,
  ...rest
}) {
  return (
    <DatePicker
      minDate={minDate}
      placeholderText={placeholderText}
      selected={selected}
      onChange={onChange}
      wrapperClassName={wrapperClassName}
      dateFormat="MM/dd/yyyy"
      customInput={
        <TextField
          value={selected}
          className="w-100"
          readOnly
          {...rest}
          error={!!error}
          helperText={error || helperText}
          variant="outlined"
        />
      }
    />
  )
}
