import React from "react";
import { connect } from "react-redux";
import i18n from "../../../../i18n";

function BalanceCards({ available = 0, pending = 0, total = 0 }) {
  return (
    <div className="row mx-0 col-12 pb-3 px-0">
      <div className="col-4 text-center balance-card">
        <div className="balance-card-body">
          <div className="balance-card-body-title">{i18n.t("pending")}</div>
          <div className="balance-card-body-subtitle">
            $ {pending.toFixed(2)}
          </div>
        </div>
      </div>
      <div className="col-4 text-center balance-card pl-0">
        <div className="balance-card-body">
          <div className="balance-card-body-title">{i18n.t("available")}</div>
          <div className="balance-card-body-subtitle">
            $ {available.toFixed(2)}
          </div>
        </div>
      </div>
      <div className="col-4 text-center balance-card pr-0">
        <div className="balance-card-body">
          <div className="balance-card-body-title"> {i18n.t("total")}</div>
          <div className="balance-card-body-subtitle">$ {total.toFixed(2)}</div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(BalanceCards);
