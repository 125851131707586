import { fork } from "redux-saga/effects"

// CUSTOMER
import login from "./Login"
import register from "./Register"
import forgotPassword from "./ForgotPassword"
import verifyResetCode from "./VerifyResetCode"
import resetPassword from "./ResetPassword"
// import socialLogin from "./SocialLogin";
import logout from "./LogOut"
import getHomePageInfo from "./GetHomePageInfo"
import getHome from "./GetHome"
import getNotifications from "./GetNotifications"
import updateNotifications from "./UpdateNotification"

// SALOON
import saloonLogin from "./Saloon/Login"
import saloonRegister from "./Saloon/Register"
import createSaloon from "./Saloon/CreateSaloon"
import createSaloonPlan from "./Saloon/CreateSaloonPlan"
import saloonPaymentMethod from "./Saloon/SaloonPaymentMethod"
import saloonUserId from "./Saloon/SaloonByUserId"
import saloonAppointment from "./Saloon/saloonAppointment"
import saloonLogout from "./Saloon/LogOut"
import billing from "./Saloon/Billing"
import easy1Products from "./Saloon/Easy1Products"
import getAvailableTimeslots from "./GetAvailableTimeslots"
import getGymTimeslots from "./GetGymTimeslots"
import subscription from "./Saloon/Subscription"
import allFundsRequest from "./Saloon/AllFundsRequest"
import orders from "./Saloon/Orders"
import formBuilder from "./Saloon/FormBuilder"
import vendor from "./Saloon/Vendor"
import expense from "./Saloon/Expense"
import communicationSettings from "./Saloon/CommunicationSettings"
import program from "./Saloon/Program"
import _class from "./Saloon/Class"
import student from "./Saloon/Student"
import studentGroup from "./Saloon/StudentGroup"
import coursePlanner from "./Saloon/CoursePlanner"
import ProgramEnrollment from "./Saloon/ProgramEnrollment"
import semester from "./Saloon/Semester"
import noShowReport from "./Saloon/NoShowReport"
import companyCustomerCars from "./Saloon/CompanyCustomerCars"
import companyCustomerSubscription from "./Saloon/CompanyCustomerSubscription"
import companyCustomerGroups from "./Saloon/CompanyCustomerGroups"
import companySubscription from "./Saloon/CompanySubscription"
import autoBookingSchedule from "./Saloon/AutoBookingSchedule"
import autoBookingScheduleTemplate from "./Saloon/AutoBookingScheduleTemplate"
import generatedBookingSchedule from "./Saloon/GeneratedBookingSchedule"
import communicationsMarketing from "./Saloon/CommunicationsMarketing"
import communicationsMarketingCampaign from "./Saloon/CommunicationsMarketingCampaign"
import communicationsMarketingTemplate from "./Saloon/CommunicationsMarketingTemplate"
import promotion from "./Saloon/Promotion"
import discountCode from "./Saloon/DiscountCode"
import customerAgeGroup from "./Saloon/CustomerAgeGroup"
import customerDistrict from "./Saloon/CustomerDistrict"
import customerOrigin from "./Saloon/CustomerOrigin"
import customerStatus from "./Saloon/CustomerStatus"
import intake from "./Saloon/Intake"
import activityScheduleTemplate from "./Saloon/ActivityScheduleTemplate"
import activityScheduleEvent from "./Saloon/ActivityScheduleEvent"
import activity from "./Saloon/Activity"
import activityCategory from "./Saloon/ActivityCategory"

// EMPLOYEE
import employeeRegister from "./Employee/Register"
import employeeLogin from "./Employee/Login"
import employeeLogout from "./Employee/LogOut"
import employeeFundsRequest from "./Employee/EmployeeFundsRequest"

// IMPERSONATE
import impersonateEmployee from "./Employee/ImpersonateEmployee"
import impersonateSalon from "./Saloon/ImpersonateSalon"
import impersonateUser from "./ImpersonateUser"

import announcements from "./Announcements"
import card from "./Card"
import bookingTransaction from "./BookingTransaction"
import companyCard from "./CompanyCard"
import companyCustomerCard from "./CompanyCustomerCard"
import validateBookingTransfer from "./ValidateBookingTransfer"
import Packages from "./Packages"
import GiftCards from "./GiftCards"
import formAnswer from "./FormAnswer"
import reoccuringUnavailabilties from "./ReoccuringUnavailabilties"
import receptionistAccess from "./ReceptionistAccess"
import managerAccess from "./ManagerAccess"
import ownerAccess from "./OwnerAccess"
import instructorAccess from "./InstructorAccess"
import reoccuringCompanyUnavailabilties from "./ReoccuringCompanyUnavailabilties"
import timePeriod from "./Saloon/TimePeriod"
import intakeType from "./Saloon/IntakeType"
import customerReference from "./Saloon/CustomerReference"
export default function* rootSaga() {
  // CUSTOMER
  yield fork(login)
  yield fork(register)
  yield fork(forgotPassword)
  yield fork(verifyResetCode)
  yield fork(resetPassword)
  // yield fork(socialLogin);
  yield fork(logout)
  yield fork(getHome)
  yield fork(getHomePageInfo)
  yield fork(getNotifications)
  yield fork(updateNotifications)

  // SALOON
  yield fork(saloonLogin)
  yield fork(saloonRegister)
  yield fork(createSaloon)
  yield fork(createSaloonPlan)
  yield fork(saloonPaymentMethod)
  yield fork(saloonUserId)
  yield fork(saloonAppointment)
  yield fork(saloonLogout)
  yield fork(billing)
  yield fork(easy1Products)
  yield fork(subscription)
  yield fork(allFundsRequest)
  yield fork(orders)
  yield fork(formBuilder)
  yield fork(vendor)
  yield fork(expense)
  yield fork(communicationSettings)
  yield fork(program)
  yield fork(_class)
  yield fork(student)
  yield fork(studentGroup)
  yield fork(coursePlanner)
  yield fork(ProgramEnrollment)
  yield fork(semester)
  yield fork(noShowReport)
  yield fork(reoccuringCompanyUnavailabilties)
  yield fork(companyCustomerCars)
  yield fork(companyCustomerSubscription)
  yield fork(companyCustomerGroups)
  yield fork(companySubscription)
  yield fork(autoBookingSchedule)
  yield fork(autoBookingScheduleTemplate)
  yield fork(generatedBookingSchedule)
  yield fork(communicationsMarketing)
  yield fork(communicationsMarketingCampaign)
  yield fork(communicationsMarketingTemplate)
  yield fork(promotion)
  yield fork(discountCode)
  yield fork(customerAgeGroup)
  yield fork(customerDistrict)
  yield fork(customerOrigin)
  yield fork(customerStatus)
  yield fork(timePeriod)
  yield fork(intake)
  yield fork(intakeType)
  yield fork(customerReference)
  yield fork(activity)
  yield fork(activityCategory)
  yield fork(activityScheduleTemplate)
  yield fork(activityScheduleEvent)

  // EMPLOYEE
  yield fork(employeeRegister)
  yield fork(employeeLogin)
  yield fork(employeeLogout)
  yield fork(getAvailableTimeslots)
  yield fork(getGymTimeslots)

  // IMPERSONATE
  yield fork(impersonateEmployee)
  yield fork(impersonateSalon)
  yield fork(impersonateUser)

  yield fork(announcements)
  yield fork(card)
  yield fork(bookingTransaction)
  yield fork(employeeFundsRequest)
  yield fork(companyCard)
  yield fork(companyCustomerCard)
  yield fork(validateBookingTransfer)
  yield fork(Packages)
  yield fork(GiftCards)
  yield fork(formAnswer)
  yield fork(reoccuringUnavailabilties)
  yield fork(receptionistAccess)
  yield fork(managerAccess)
  yield fork(ownerAccess)
  yield fork(instructorAccess)
}
