import { connect } from "react-redux";
import React, { useState } from "react";
import i18n from "../../../i18n";
import DotsLoader from "../../DotsLoader";
import VendorUpsert from "./VendorUpsert.component";
import Swal from "sweetalert2";
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import { removeRequest } from "../../../redux/actions/Saloon/vendor";
import BasicTable from "../../Core/BasicTable";
import DeleteIcon from "./../../Core/BasicTable/Actions/DeleteIcon";
import EditIcon from "./../../Core/BasicTable/Actions/EditIcon";
import deleteConfirmation from "../../../helpers/DeleteHelper";

function PackagesTable({ rows, onEdit, removeRequest }) {
  const [open, setOpen] = useState(false);
  const [element, setElement] = useState();
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState();
  const onClose = () => {
    setElement();
    setOpen(false);
  };
  const handleDelete = (_id) => {
    setLoading(true);
    removeRequest(
      { ids: _id },
      {
        success: () => {
          setLoading(false);
          SuccessHelper.handleSuccess(
            "Your service is successfully deleted.",
            true
          );
        },
        failure: () => {
          setLoading(false);
          ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
        },
      }
    );
  };
  return (
    <>
      <DotsLoader isloading={loading} />
      <div className="table-responsive">
        <BasicTable
          rows={rows}
          schema={[
            {
              header: i18n.t("name"),
              field: "vendorName",
            },
            {
              header: i18n.t("contact"),
              field: "primaryContact",
            },
            {
              header: i18n.t("container.profile_email"),
              field: "email",
            },
            {
              header: i18n.t("phoneNo"),
              field: "phoneNo",
            },
            {
              header: i18n.t("mobile_phone_no"),
              field: "mobilePhoneNo",
            },
            {
              key: "action",
              header: i18n.t("actions"),
              render: (val) => {
                return (
                  <>
                    <EditIcon onClick={() => onEdit(val)} />
                    <DeleteIcon
                      onClick={() =>
                        deleteConfirmation(() => handleDelete(val._id))
                      }
                    />
                  </>
                );
              },
            },
          ]}
        />
      </div>
      {open && (
        <VendorUpsert
          open={open}
          onClose={onClose}
          element={element}
          onSave={() => { }}
          id={id}
        />
      )}
    </>
  );
}
const mapStateToProps = (state) => ({});

const action = {
  removeRequest,
};

export default connect(mapStateToProps, action)(PackagesTable);
