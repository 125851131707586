import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import "./ThreeDButton.css";

export default function ThreeDButton({
  label,
  onClick = () => {},
  props,
  style = {},
  className = "",
  wrapperClassName = "",
  wrapperStyle = {},
  children,
}) {
  const [hovered, setHovered] = useState(false);
  return (
    <div
      className={`${wrapperClassName} ThreeDButtonWrapper ${
        hovered ? "Hovered" : ""
      }`}
      style={wrapperStyle}
    >
      <span className="ThreeDButtonHelper" style={wrapperStyle}></span>
      <Button
        {...props}
        onClick={onClick}
        style={style}
        className={`${className} ThreeDButton`}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        disableRipple
      >
        {label || children}
      </Button>
    </div>
  );
}
