import React, { useEffect, useRef, useState } from "react"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import { BaseModal } from "../../../../components"
import PrimaryButton from "../../../../components/Core/PrimaryButton"
import i18n from "../../../../i18n"

export default function UpsertTemplate({
  open,
  setOpen,
  onAdd,
  onDelete,
  active = {},
}) {
  const form = useRef()
  const [data, setData] = useState({})

  useEffect(() => {
    if (open) {
      setData({
        ...active,
      })
    }
  }, [open, active?._id])

  const handleAdd = () => {
    onAdd(data)
  }

  return (
    <BaseModal
      open={open}
      contentContainerStyle={{
        overflowY: "visible",
      }}
      title={data._id ? i18n.t("edit_template") : i18n.t("add_new_template")}
      onClose={() => setOpen(false)}
      containerClassName={"half-container"}
      content={
        <div className="row mx-0 ScheduleBuilder">
          <ValidatorForm
            onSubmit={handleAdd}
            ref={form}
            className="row col-12 px-0 mx-0"
            onError={(error) => {
              console.log(error)
            }}
          >
            <div className="d-sm-flex align-items-center col-12 px-0">
              <label className="mr-3">{i18n.t("name")}</label>
              <TextValidator
                containerProps={{ className: "w-100" }}
                label={i18n.t("name")}
                onChange={(e) => setData({ ...data, name: e.target.value })}
                type="name"
                value={data.name || ""}
                validators={["required"]}
                errorMessages={[i18n.t("this_field_is_required")]}
                variant="outlined"
              />
            </div>


            <div className="row mx-0 px-0 justify-content-between mt-4 col-12">
              {data._id ? (
                <PrimaryButton
                  className="w-128"
                  onClick={() => onDelete(data._id)}
                  label={i18n.t("delete")}
                />
              ) : (
                <div />
              )}
              <PrimaryButton
                className="w-128"
                props={{
                  type: "submit",
                }}
                label={data._id ? i18n.t("save") : i18n.t("create")}
              />
            </div>
          </ValidatorForm>
        </div>
      }
    />
  )
}
